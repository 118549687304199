import React, { useRef, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Stack, Form, Button, InputGroup } from "react-bootstrap";
import { copyBillingToShippingAddress, toggleEditPartyModal, updateShippingAddressField } from "../store/voucherEntitySlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquareXmark } from "@fortawesome/free-solid-svg-icons";
import { placeOfSupplyList } from "../../shared/config/placeOfSupply";
import { find } from "lodash";
import { i18n, placeholderText } from "../../shared/helpers/sharedMethods";
import { Menu, MenuItem, Typeahead } from "react-bootstrap-typeahead";

const ShippingAddressBlock = ({ editSale }) => {
  const dispatch = useDispatch();
  const shippingAddress = useSelector((state) => state.voucherEntity.addresses.shipping);
  const billingAddress = useSelector((state) => state.voucherEntity.addresses.billing);
  const partyAddresses = useSelector((state) => state.voucherEntity.party?.party_addresses || []);
  const typeaheadRef = useRef(null);

  const [isSameAsBilling, setIsSameAsBilling] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedItem, setSelectedItem] = useState(null);
  const [showSearch, setShowSearch] = useState(false);

  useEffect(() => {
    if (editSale) {
      if (editSale?.address[0]?.address === editSale?.address[1]?.address) {
        setIsSameAsBilling(true);
        dispatch(copyBillingToShippingAddress(billingAddress));
      } else {
        dispatch(updateShippingAddressField(editSale?.address[1] || {}));
        setIsSameAsBilling(false);
      }
    } else {
      setIsSameAsBilling(true);
    }
  }, [editSale,  billingAddress]); 

  useEffect(() => {
    if (isSameAsBilling) {
      dispatch(copyBillingToShippingAddress(billingAddress));
    } else if(editSale?.address[1]) {
      dispatch(updateShippingAddressField(editSale?.address[1] || {}))
    }
  }, [isSameAsBilling, billingAddress, editSale]);

  
  const handleSameAsBilling = () => {
    if (!isSameAsBilling) {
      dispatch(copyBillingToShippingAddress(billingAddress));
      setIsSameAsBilling(true);
      setShowSearch(false);
      setSelectedItem(null);
    } else {
      dispatch(updateShippingAddressField({}));
      setIsSameAsBilling(false);
      setShowSearch(true);
    }
  };

  const handleOnSearch = (string) => {
    setSearchTerm(string);
    setSelectedItem(null);
  };

  const handleOnSelect = (selected) => {
    const item = selected[0];
    if (item) {
      dispatch(updateShippingAddressField(item));
      const formattedAddress = `${item.name || ""}, ${item.address || ""}, ${item.city || ""}, ${find(placeOfSupplyList, { value: item.state })?.label || "Unknown State"}, ${item.pincode || ""}`;
      setSearchTerm(formattedAddress);
      setSelectedItem(item);
      setShowSearch(false);
    }
  };

  const handleClearSelection = () => {
    setSelectedItem(null);
    setSearchTerm("");
    setShowSearch(true);
  };

  const handleChangeButtonClick = () => {
    setSelectedItem(null);
    setSearchTerm("");
    setShowSearch(true);
  };

  return (
    <Stack direction="vertical" className="px-1 py-1" >
    <Form.Label className="d-flex align-items-center justify-content-between mb-0">
    <span className="bold" style={{ display: 'flex', alignItems: 'center', color: "#4682B4", fontSize: "12px" }}>
        {i18n("SHIP TO")}
        <Form.Check
            type="checkbox"
            id="same-as-billing"
            className="pb-0"
            style={{ fontWeight: "normal", fontSize: "10px", marginLeft: "5px", position:'relative', top:'3px', color:'grey' }} // Adjust margin
            label={i18n("Same As Billing")}
            checked={isSameAsBilling}
            onChange={handleSameAsBilling}
        />
    </span>

      <div className="d-flex align-items-center">
      
        {!isSameAsBilling && (
          <>
            {shippingAddress?.address && (
              <Button
                variant="link"
                size="sm"
                className="pb-0 ms-2" // Adjusted margin for spacing
                onClick={handleChangeButtonClick}
              >
                {i18n("Clear")}
              </Button>
            )}
            <Button
              variant="link"
              size="sm"
              className="pb-0 ms-2" // Adjusted margin for spacing
              onClick={() => {
                dispatch(
                  toggleEditPartyModal({
                    toggle: true,
                    activeEditPartyTab: "address",
                  })
                );
              }}
            >
              {i18n("Edit")}
            </Button>
          </>
        )}
      </div>
    </Form.Label>
  
    <Form.Group>
      {isSameAsBilling ? null : (
        <>
          {shippingAddress?.address ? (
            showSearch ? (
              <Stack direction="vertical" className="ms-auto mt-2">
                <InputGroup>
                  <Typeahead
                    ref={typeaheadRef}
                    allowNew
                    id="address-typeahead"
                    options={partyAddresses}
                    labelKey="name"
                    placeholder={placeholderText("Search for address...")}
                    onInputChange={handleOnSearch}
                    onChange={handleOnSelect}
                    selected={selectedItem ? [selectedItem] : []}
                    filterBy={["name", "address", "city", "state"]}
                    size="md"
                    renderMenu={(results, menuProps) => (
                      <Menu {...menuProps} className="">
                        {results.length > 0 ? (
                          results.map((result, index) => (
                            <MenuItem key={index} option={result} position={index}>
                              <div>
                                <p style={{ fontWeight: "bold", margin: 0 }}>
                                  {result.name || ""}
                                </p>
                                <p style={{ margin: 0 }}>
                                  {result.phone_number || ""}
                                </p>
                                <p style={{ margin: 0 }}>
                                  {result.address || ""}
                                  {result.city ? `, ${result.city}` : ""}
                                  {result.state
                                    ? `, ${find(placeOfSupplyList, { value: result.state })?.label || ""}`
                                    : ""}
                                  {result.pincode ? ` - ${result.pincode}` : ""}
                                </p>
                              </div>
                            </MenuItem>
                          ))
                        ) : (
                          <MenuItem disabled>No results found</MenuItem>
                        )}
                      </Menu>
                    )}
                  />
                  {selectedItem && (
                    <InputGroup.Text
                      className="cursor-pointer fs-2"
                      style={{ background: "none" }}
                      onClick={handleClearSelection}
                    >
                      <FontAwesomeIcon
                        icon={faSquareXmark}
                        title="Clear"
                        className="text-danger"
                      />
                    </InputGroup.Text>
                  )}
                </InputGroup>
              </Stack>
            ) : (
              <Stack direction="vertical" className="ms-auto">
                <p className="m-0" style={{ fontWeight: 600, fontSize: "12px" }}>
                  {shippingAddress?.name || ""}
                </p>
                <p className="m-0" style={{ fontSize: "12px", color: "#6c757d" }}>
                  {shippingAddress?.address ? `${shippingAddress.address}, ` : ""}
                  {shippingAddress?.city ? `${shippingAddress.city}, ` : ""}
                  {shippingAddress?.state
                    ? `${find(placeOfSupplyList, { value: shippingAddress.state })?.label || ""} - `
                    : ""}
                  {shippingAddress?.pincode || ""}
                </p>
                {shippingAddress?.phone_number && (
                  <p className="m-0" style={{ fontSize: "12px", color: "#6c757d" }}>
                    <span className="text-black-50 bold">{i18n("Phone:")} </span>
                    {shippingAddress?.phone_number}
                  </p>
                )}
              </Stack>
            )
          ) : partyAddresses && partyAddresses.length === 0 || partyAddresses === undefined ? (
            <div
              className="d-flex justify-content-center py-1"
              style={{ minHeight: "50px", border: "1px dashed #ccc", paddingTop: "8px" }}
            >
              <Button
                variant="link"
                size="sm"
                onClick={() => {
                  dispatch(
                    toggleEditPartyModal({
                      toggle: true,
                      activeEditPartyTab: "address",
                    })
                  );
                }}
              >
                +{i18n("Add Address")}
              </Button>
            </div>
          ) : (
            <Stack direction="vertical" className="ms-auto mt-2">
              <InputGroup>
                <Typeahead
                  id="address-typeahead"
                  options={partyAddresses}
                  labelKey="name"
                  placeholder={placeholderText("Search for address...")}
                  onInputChange={handleOnSearch}
                  onChange={handleOnSelect}
                  selected={selectedItem ? [selectedItem] : []}
                  filterBy={["name", "address", "city", "state"]}
                  size="md"
                  renderMenu={(results, menuProps) => (
                    <Menu {...menuProps} >
                      {results.length > 0 ? (
                        results.map((result, index) => (
                          <MenuItem key={index} option={result} position={index}>
                            <div>
                              <p style={{ fontWeight: "bold", margin: 0 }}>{result.name || ""}</p>
                              <p style={{ margin: 0 }}>{result.phone_number || ""}</p>
                              <p style={{ margin: 0 }}>
                                {result.address || ""}
                                {result.city ? `, ${result.city}` : ""}
                                {result.state
                                  ? `, ${find(placeOfSupplyList, { value: result.state })?.label || ""}`
                                  : ""}
                                {result.pincode ? ` - ${result.pincode}` : ""}
                              </p>
                            </div>
                          </MenuItem>
                        ))
                      ) : (
                        <MenuItem disabled>No results found</MenuItem>
                      )}
                    </Menu>
                  )}
                />
              </InputGroup>
            </Stack>
          )}
        </>
      )}
    </Form.Group>
  </Stack>
  
  
  );
};

export default ShippingAddressBlock;
