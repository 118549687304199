import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router";
import {
  Col,
  Row,
  Card,
  Form,
  Container,
  Button,
  Stack,
  Spinner,
  Image,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Formik } from "formik";
import * as Yup from "yup";
import Layout from "../../shared/components/Layout";
import NavbarSetting from "./NavbarSettings";
import { cloneDeep } from "lodash";
import SettingsLayout from "./SettingsLayout";
import {
  editSetting,
  initSettingEdit,
  IsThermalPrintEnabled,
  resetError,
  resetStatus,
  setSignature,
  setTermsAndConditions,
} from "../store/settingSlice";
import { toast, ToastContainer } from "react-toastify";
import Header from "../../shared/components/Header";
import { ErrorMessage } from "formik";
import { i18n, placeholderText } from "../../shared/helpers/sharedMethods";
import ShortcutKeys from "../../shared/components/ShortcutKeys";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faX } from "@fortawesome/free-solid-svg-icons";
import TermsAndCondition from "../components/TermsAndConditions";
import Signature from "../components/Signature";
import AccountDetailsBlock from "../components/AccountDetailsBlock";
import PaymentBlock from "../components/PaymentBlock";
import { fetchSetting } from "../store/getSettingSlice";
import NotesBlock from "../components/NotesBlock";
import LoadingSpinner from '../../shared/components/LoadingSpinner';

const InvoiceSettings = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const selectedBusiness = useSelector(
    (state) => state?.auth?.selectedBusiness
  );

  const { accessToken } = useSelector((state) => state?.auth);

  const { billing_settings, error } = useSelector((state) => state.setting);
  const status = useSelector((state) => state.setting.status);
  const invoiceLoading = useSelector((state) => state.getSetting.loading)
  const getSetting = useSelector((state) => state.getSetting?.dataSetting);
  const thermalprint = useSelector((state) => state.getSetting?.dataSetting?.is_thermal_print);
  const [loading, setLoading] = useState(false);
  const [isChecked, setIsChecked] = useState(0);
  const [isOnline, setIsOnline] = useState(navigator.onLine);

useEffect(() => {
if(thermalprint ) {
  setIsChecked(thermalprint ==1?true:false);
}

}, [thermalprint]);

  useEffect(() => {
    if (isChecked) {
      dispatch(IsThermalPrintEnabled(1));
    } else {
      dispatch(IsThermalPrintEnabled(0));
    }
  }, [isChecked]);

  useEffect(() => {
    if (getSetting) {
      dispatch(initSettingEdit(getSetting));
    }
  }, [getSetting]);

  useEffect(() => {
    dispatch(
      fetchSetting({
        accessToken: accessToken.access_token,
        tenant_id: selectedBusiness?.pivot?.tenant_id,
      })
    );
  }, [status]);

  useEffect(() => {
    if (error) {
      toast.error(error?.props?.id);
      dispatch(resetError());
    }
  }, [error]);

  useEffect(() => {
    if (status && isOnline ) {
      toast.success(i18n("Bank Details Updated Successfully"));
      dispatch(resetStatus());
    }
  }, [status, isOnline]);

  const handleChecked = (event) => {
    setIsChecked(event.target.checked);
  };

  const handleCancel = () => {
    navigate("/dashboard/sales");
  };
  return (
    <div style={{ overflow: 'scroll' }}>
    <Formik
      initialValues={billing_settings}
      enableReinitialize={true}
      validationSchema={Yup.object().shape({
        account_number: Yup.string().min(
          8,
          "Account Number must be at least 8 digits"
        ),
        ifsc_code: Yup.string().matches(
          /^[A-Za-z]{4}0[A-Z0-9a-z]{6}$/,
          "Invalid IFSC Code"
        ),
      })}
      onSubmit={async (values) => {
        try {
          const formData = cloneDeep(values);
          formData.created_by = selectedBusiness?.pivot?.user_id;
          formData.tenant_id = selectedBusiness?.pivot?.tenant_id;
          formData.accessToken = accessToken.access_token;
          delete formData.id;
          if (!isOnline) {
            toast.error(i18n("You are Offline. Please Connect to the Internet"));
            return;
          }
          dispatch(editSetting(formData))
            .then((res) => {
              ;
              dispatch(
                fetchSetting({
                  accessToken: accessToken.access_token,
                  tenant_id: selectedBusiness?.pivot?.tenant_id,
                })
              );
            })
            .catch((err) => {
              ;
            });
        } catch (error) {
          ;
        }
      }}
    >
      {({ values, errors, handleSubmit, handleChange }) => (
        <>
          <ToastContainer />
          <SettingsLayout title="Invoice Settings"
            onSave={handleSubmit}
            onCancel={handleCancel}>
            {invoiceLoading && <LoadingSpinner />}
            <Col>
              <Row>
                <Col lg={12}>
                  <NotesBlock />
                </Col>
              </Row>
              <Row className="mt-5">
                <Col lg={6}>
                  <TermsAndCondition
                    values={values}
                    handleChange={handleChange}
                  />
                </Col>
                <Col lg={6} className="">
                  <Signature />
                </Col>
                <Form>
          <Form.Check 
          name="is_thermal_print"
            id={`Enable Thermal Printer`}
            label={`Enable Thermal Printer`}
            checked={isChecked}
            onChange={handleChecked}
          />
    </Form>
              </Row>
              <Row className="mt-5">
                <Col lg={6}>
                  <AccountDetailsBlock
                    values={values}
                    handleChange={handleChange}
                    errors={errors}
                  />
                </Col>
                <Col lg={6} className="">
                  <PaymentBlock
                    values={values}
                    handleChange={handleChange}
                  />
                </Col>
              </Row>{" "}
            </Col>
          </SettingsLayout>
        </>
      )}
    </Formik>
    </div>
  );
};

export default InvoiceSettings;
