import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Layout from "../../shared/components/Layout";
import { useLocation, useNavigate, useParams } from "react-router";
import {
  Dropdown,
  ButtonGroup,
  Button,
  Col,
  Container,
  Form,
  Stack,
  InputGroup,
  Badge,
} from "react-bootstrap";
import { i18n } from "../../shared/helpers/sharedMethods";
import DeleteModel from "../../shared/components/DeleteModel";
import {
  deletesales,
  selectSalesById,
  selectSalesByType,
  updateSale,
} from "../store/voucherSlice";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import DefaultStyle from "../../shared/components/invoiceLayouts/default";
import StyleOne from "../../shared/components/invoiceLayouts/Theme1";
import StyleTwo from "../../shared/components/invoiceLayouts/Theme2";
import {
  addVoucher,
  editVoucher,
  resetOrder,
  undoVoucherError,
  updateVoucherPayment,
} from "../store/voucherEntitySlice";
import { resetVoucherItem } from "../store/voucherItemsSlice";
import { resetCharges } from "../store/additionalChargesSlice";
import TwoStageLayoutHeader from "../../shared/components/TwoStatgeLayoutHeader";
import Header from "../../shared/components/Header";
import { ToastContainer, toast } from "react-toastify";
import InvoiceTab from "../../shared/components/invoiceLayouts/invoiceTab";
import { fetchSetting } from "../../settings/store/getSettingSlice";
import ThermalInvoice from "../../shared/components/invoiceLayouts/ThermalInvoice";
import {
  UilEdit,
  UilTrashAlt,
  UilPrint,
  UilPlus,
  UilAngleDown,
  UilListUl,
} from "@iconscout/react-unicons";
import { changeToggleShow } from "../../shared/store/sharedSlice";
import {
  convertToSalesProcesses,
  createProcesses,
} from "../helpers/VouchersHelper";
import { cloneDeep } from "lodash";
import AlertMessage from "../../shared/components/AlertMessage";
import ShortcutKeys from "../../shared/components/ShortcutKeys";
import { resetPayment } from "../../payment/store/paymentEntitySlice";
import { resetPartyPayment } from "../../payment/store/partyPaymentsSlice";
import PaymentModal from "../../party/components/payment/PaymentModal";
import { selectpartyById } from "../../party/store/getPartySlice";
import ReceivedPaymentModel from "./ReceivedPaymentModel";
import "../../../assets/css/style.css";
const VoucherInvoiceView = (props) => {
  const { type, title, button, path } = props;
  const { id } = useParams();

  const targetRef = useRef();
  const componentRef = useRef();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const selectedSale = useSelector((state) => selectSalesById(state, id));
  console.log("selected sale", selectedSale);
  const allSales = useSelector((state) => selectSalesByType(state, type));
  const invoicesSetting = useSelector((state) => state.setting.invoicesType);
  const printThermal = useSelector(
    (state) => state.setting.IsThermalPrintEnabled
  );
  const { errorStatus, error } = useSelector(
    (state) => state.voucherEntity.status
  );
  const status = useSelector((state) => state.voucherEntity?.status?.isOrdered);
  const selectedBusiness = useSelector(
    (state) => state?.auth?.selectedBusiness
  );
  const accessToken = useSelector(
    (state) => state?.auth?.accessToken?.access_token
  );
  const thermalenabled = useSelector(
    (state) => state?.getSetting.dataSetting.is_thermal_print
  );
  const [showPayment, setShowPayment] = useState(false);
  const [PaymentType, setPaymentType] = useState("");
  const [PaymentId, setPaymentId] = useState();
  const [paymentName, setPaymentName] = useState();
  const [show, setShow] = useState(false);
  const [deleteId, setDeleteId] = useState();
  const [showThermal, setShowThermal] = useState(false);
  const [showPaymentModel, setShowPaymentModel] = useState(false);
  const formatString = (str) => {
    return str
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };
  const { terminalId } = useSelector((state) => state.initInstall);
  const tenantId = selectedBusiness?.pivot?.tenant_id;

  const formattedTypeString = formatString(type);
console.log('selectedSale',selectedSale);
  useEffect(() => {
    if (selectedSale?.voucher_payments && selectedSale?.voucher_payments.length > 0) {
      dispatch(updateVoucherPayment(selectedSale?.voucher_payments));
    } else {
      dispatch(updateVoucherPayment(selectedSale?.voucher_payments));
    }
  }, [selectedSale]);

  let saleTabs;
  switch (true) {
    case invoicesSetting?.invoice_1 === true:
      saleTabs = [
        {
          key: "1",
          title: "Default",
          component: (
            <StyleOne name="Sales Invoice" pdf={targetRef} voucherId={id} />
          ),
        },
      ];
      break;
    case invoicesSetting?.invoice_2 === true:
      saleTabs = [
        {
          key: "2",
          title: "Theme1",
          component: (
            <DefaultStyle name="Sales Invoice" pdf={targetRef} voucherId={id} />
          ),
        },
      ];
      break;
    case invoicesSetting?.invoice_3 === true:
      saleTabs = [
        {
          key: "3",
          title: "Theme2",
          component: (
            <StyleTwo name="Sales Invoice" pdf={targetRef} voucherId={id} />
          ),
        },
      ];
      break;
    default:
      saleTabs = [
        {
          key: "1",
          title: "Default",
          component: (
            <StyleOne name="Sales Invoice" pdf={targetRef} voucherId={id} />
          ),
        },
      ];
      break;
  }

  useEffect(() => {
    dispatch(
      fetchSetting({
        accessToken: accessToken,
        tenant_id: selectedBusiness?.pivot?.tenant_id,
      })
    );
  }, []);

  const handleClick = () => {
    dispatch(resetOrder());
    dispatch(resetVoucherItem());
    dispatch(resetCharges());
    navigate(`/${path}/create`);
  };

  const convertToSalesInvoice = async (sales) => {
    let newType;
    if (sales.type === "quote" || sales.type === "proforma_invoice") {
      newType = "sales_invoice";
    } else if (sales.type === "purchase_order") {
      newType = "purchase_invoice";
    }
    const convertType = sales?.type;
    navigate(`/voucher/${newType}/edit/${sales.id}`, {
      state: { convertType },
    });
  };

  const handleAlertClose = () => {
    dispatch(undoVoucherError());
  };
  const DownloadPdf = () => {
    html2canvas(componentRef.current, {
      scale: 2,
      useCORS: true,
      allowTaint: true,
      logging: true,
    }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png", 1.0);
      const pdf = new jsPDF("p", "mm", "a4", true);

      let selectedLayout;
      if (invoicesSetting && invoicesSetting.invoice_1) {
        selectedLayout = saleTabs.find((tab) => tab.key === "1");
      } else if (invoicesSetting && invoicesSetting.invoice_2) {
        selectedLayout = saleTabs.find((tab) => tab.key === "2");
      } else if (invoicesSetting && invoicesSetting.invoice_3) {
        selectedLayout = saleTabs.find((tab) => tab.key === "3");
      } else {
        selectedLayout = saleTabs[0];
      }

      const imgWidth = 210; // A4 width in mm
      const imgHeight = (canvas.height * imgWidth) / canvas.width;

      pdf.addImage(imgData, "PNG", 0, 0, imgWidth, imgHeight);

      const timeStamp = Date.now();
      pdf.save(`sales_invoice_${timeStamp}`);
    });
  };
  const handleEdit = (data) => {
    if (selectedSale.is_subscription == 1) {
      toast.error("Generated invoice cannot be edited");
    } else {
      if (selectedSale.grand_total - selectedSale.paid_amount == 0) {
        toast.error(i18n("You cannot edit a paid/closed order"));
        return;
      }
      if (selectedSale.grand_total - selectedSale.totalPaidAmount == 0) {
        toast.error(i18n("You cannot edit a paid/closed order"));
        return;
      }
      dispatch(resetOrder());
      dispatch(resetVoucherItem());
      dispatch(resetCharges());
      navigate(`/${path}/edit/` + data);
    }
  };

  const handlePrint = () => {
    const padding = 6;
    const imgWidth = 210 - 2 * padding;
    const pageHeight = 297 - 2 * padding;

    html2canvas(componentRef.current, {
      scale: 2,
      useCORS: true,
      allowTaint: true,
      logging: true,
    }).then((canvas) => {
      const pdf = new jsPDF("p", "mm", "a4", true);
      let position = 0;

      while (position < canvas.height) {
        const remainingHeight = canvas.height - position;
        const pageContentHeight = Math.min(
          remainingHeight,
          canvas.width * (pageHeight / imgWidth)
        );

        const pageCanvas = document.createElement("canvas");
        pageCanvas.width = canvas.width;
        pageCanvas.height = pageContentHeight;

        const ctx = pageCanvas.getContext("2d");
        ctx.drawImage(
          canvas,
          0,
          position,
          canvas.width,
          pageContentHeight,
          0,
          0,
          canvas.width,
          pageContentHeight
        );

        const pageImgData = pageCanvas.toDataURL("image/png", 1.0);
        const pageImgHeight = (pageContentHeight * imgWidth) / canvas.width;

        if (position === 0) {
          pdf.addImage(
            pageImgData,
            "PNG",
            padding,
            padding,
            imgWidth,
            pageImgHeight
          );
        } else {
          pdf.addPage();
          pdf.addImage(
            pageImgData,
            "PNG",
            padding,
            padding,
            imgWidth,
            pageImgHeight
          );
        }

        position += pageContentHeight;
      }

      const timeStamp = Date.now();
      const pdfBlob = pdf.output("blob");
      const blobUrl = URL.createObjectURL(pdfBlob);
      window.open(blobUrl);
    });
  };

  const handleDelete = async (id) => {
    setShow(!show);
    setDeleteId(id);
  };

  const onDelete = () => {
    console.log('deleted');
    if (selectedSale.is_subscription == 1) {
      toast.error("Generated invoice cannot be deleted");
    } else {
      dispatch(deletesales({ 
        data: { id: deleteId },
        token: accessToken, 
        tenant_id: tenantId, 
        terminal_id: terminalId 
      }));   
      setShow(false);
      navigate(`/${path}`);
    }
  };
  const handlePrintThermal = () => {
    setShowThermal(true);
    setTimeout(() => {
      handlePrint();
      setShowThermal(false);
    }, 0);
  };

  const handlePayIn = (data) => {
    setShowPayment(true);
    setPaymentType("payment_in");
    setPaymentId(data?.party_id);
    setPaymentName(data?.party?.name);

    dispatch(resetPayment());
    dispatch(resetPartyPayment());
  };
  const handleSaleClick = (data) => {
    navigate(`/${path}/view/` + data);
  };
  const handleReturnToGrid = () => {
    navigate(`/${path}`);
  };

  const header = (
    <TwoStageLayoutHeader
      ItemActions={
        <Stack
          // gap={3}
          direction="horizontal"
          className="mt-2 mb-2 float-end"
        >
          {(type === "quote" ||
            type === "proforma_invoice" ||
            type === "purchase_order") && (
            <Button
              className="btn-success me-2"
              onClick={() => convertToSalesInvoice(selectedSale)}
            >
              {type === "quote" || type === "proforma_invoice"
                ? i18n("Convert to invoice")
                : i18n("Convert to Purchase Invoice")}
            </Button>
          )}

          <InputGroup className="me-3 custom-input">
            <Button
              variant="outline-secondary"
              onClick={() => handlePayIn(selectedSale)}
              style={{ background: "none" }}
            >
              {i18n("Payments")}
            </Button>
            {/* <Dropdown as={ButtonGroup}>
                <Dropdown.Toggle variant="outline-secondary" id="dropdown-custom" size="sm" style={{ height: '36px', }}>
                  <UilAngleDown size="16" />
                </Dropdown.Toggle>

                <Dropdown.Menu className="custom-dropdown-menu">
                  <Dropdown.Item className="invoice-dropdown-item" onClick={() => setShowPaymentModel(!showPaymentModel)}>
                    <UilListUl className="mx-2 mb-1" size="16" />
                    {i18n("Payments")}
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown> */}
          </InputGroup>

          <InputGroup className="me-3 custom-input">
            {selectedSale?.is_subscription === 1 ||
            selectedSale?.grand_total - selectedSale?.paid_amount === 0 ||
            selectedSale?.grand_total - selectedSale?.totalPaidAmount === 0 ? (
              <Button
                variant="outline-secondary"
                disabled={true}
                size="sm"
                style={{ padding: "0.5rem", background: "none" }}
              >
                <UilEdit className="mx-2 mb-1" size="16" />
                {i18n("Edit")}
              </Button>
            ) : (
              <Button
                variant="outline-secondary"
                onClick={() => handleEdit(id)}
                size="sm"
                style={{ padding: "0.5rem", background: "none" }}
              >
                <UilEdit className="mx-2 mb-1" size="16" />
                {i18n("Edit")}
              </Button>
            )}

            <Dropdown as={ButtonGroup}>
              <Dropdown.Toggle
                variant="outline-secondary"
                id="dropdown-custom"
                size="sm"
                style={{ height: "36px" }}
              >
                <UilAngleDown size="16" />
              </Dropdown.Toggle>

              <Dropdown.Menu className="custom-dropdown-menu">
                {selectedSale?.is_subscription === 1 ?
               <Dropdown.Item
               className="invoice-dropdown-item"
              disabled={true}
              style={{ color: "lightgray" }}
             >
               <UilTrashAlt
                 className="mx-2 mb-1"
                 size="16"
                 style={{ color: "lightgray" }}
               />
               {i18n("Delete")}
             </Dropdown.Item>: <Dropdown.Item
                  className="invoice-dropdown-item"
                  onClick={() => handleDelete(id)}
                  style={{ color: "red" }}
                >
                  <UilTrashAlt
                    className="mx-2 mb-1"
                    size="16"
                    style={{ color: "red" }}
                  />
                  {i18n("Delete")}
                </Dropdown.Item> }
              </Dropdown.Menu>
            </Dropdown>
          </InputGroup>

          <InputGroup className="d-flex align-items-end custom-input">
            <Button
              variant="outline-secondary"
              onClick={handlePrint}
              size="sm"
              style={{ padding: "0.5rem ", background: "none" }}
            >
              <UilPrint className="mx-2 mb-1" size="16" />
              {i18n("Print PDF")}
            </Button>
            {thermalenabled && (
              <Dropdown as={ButtonGroup}>
                <Dropdown.Toggle
                  variant="outline-secondary"
                  id="dropdown-custom"
                  size="sm"
                  style={{ height: "36px" }}
                >
                  <UilAngleDown size="16" />
                </Dropdown.Toggle>
                <Dropdown.Menu className="custom-dropdown-menu ">
                  <Dropdown.Item
                    className="invoice-dropdown-item"
                    onClick={handlePrintThermal}
                  >
                    <UilPrint className="mx-2 mb-1" size="16" />
                    {i18n("Print Thermal")}
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            )}
          </InputGroup>

          <div></div>
        </Stack>
      }
      // currentTitle={`#${selectedSale?.voucher_number}`}
      returnToGrid={handleReturnToGrid}
      adjustment="sales"
    />
  );

  return (
    <Layout>
      <ToastContainer />
      <Header
        currentTitle={
          <div className="d-flex justify-content-start align-items-center p-0">
            <span className="">
              {formattedTypeString} : {selectedSale?.voucher_number}
            </span>
            <Badge
              bg={
                selectedSale?.paid_amount === 0
                  ? "danger"
                  : selectedSale?.paid_amount < selectedSale?.grand_total
                  ? "warning"
                  : "success"
              }
              className={`ms-2 bold ${
                selectedSale?.paid_amount < selectedSale?.grand_total &&
                selectedSale?.paid_amount > 0
                  ? "text-black"
                  : ""
              }`}
              style={{ fontSize: "13px" }}
            >
              {selectedSale?.paid_amount === 0
                ? "Unpaid"
                : selectedSale?.paid_amount < selectedSale?.grand_total
                ? "Partially Paid"
                : "Paid"}
            </Badge>
          </div>
        }
        ItemActions={
          <Stack
            gap={3}
            direction="horizontal"
            className="my-2  justify-content-end"
          >
            <ShortcutKeys />
          </Stack>
        }
      />

      <Container fluid className="px-5">
        {errorStatus && (
          <AlertMessage
            errorStatus={errorStatus}
            error={error}
            handleAlertClose={handleAlertClose}
          />
        )}
        <Col
          className="border "
          lg={12}
          md={9}
          xs={8}
          style={{ background: "white" }}
        >
          <div className="px-3">{header}</div>
          {/* <div style={{ borderBottom: '1px solid #ccc', margin: '10px 0' }} /> */}
          <InvoiceTab
            print={componentRef}
            pdf={componentRef}
            tabs={saleTabs}
            type={type}
          />
          {showThermal && (
            <ThermalInvoice
              name="Sales Invoice"
              pdf={componentRef}
              voucherId={id}
            />
          )}
        </Col>
        {show && (
          <DeleteModel
            deleteUserClick={onDelete}
            onClickDeleteModel={setShow}
            name="Sale"
          />
        )}
      </Container>
      <Container>
        {showPayment && (
          <PaymentModal
            show={showPayment}
            setShow={setShowPayment}
            type={PaymentType}
            title={PaymentType == "payment_in" ? "Payments For" : "Payment Out"}
            name={paymentName}
            partyId={PaymentId}
            Id={id}
          />
        )}
      </Container>
    </Layout>
  );
};

export default VoucherInvoiceView;
