import React, { useState } from "react";
import { Nav, Tab } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { NavLink } from "react-router-dom";

const AllTabs = ({ type, tabs, route, title }) => {
  const { pathname } = useLocation();

  const [key, setKey] = useState(
    route && tabs.find((tab) => tab.key === route) ? route : tabs[0]?.key
  ); // Use the first tab as default if route doesn't match

  const handleTabSelect = (k) => {
    setKey(k);
  };

  return (
    <div>
      <Tab.Container activeKey={key} onSelect={handleTabSelect}>
        <Nav
          variant="underline"
          style={{
            borderBottom: "1px solid lightgray",
            lineHeight: "30px",
          }}
        >
          {tabs &&
            tabs.map((tabItem, index) => {
              return (
                <Nav.Item key={index}>
                  <NavLink
                    to={`/${route}/${tabItem.key}`} // Use dynamic 'route' instead of hardcoded 'setting'
                    className="nav-link fs-4" // Add your desired class for styling
                  >
                    {tabItem.icon} {tabItem.title}
                  </NavLink>
                </Nav.Item>
              );
            })}
        </Nav>
      </Tab.Container>
    </div>
  );
};

export default AllTabs;
