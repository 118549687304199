import { Col, Form, FormControl, InputGroup, Row } from "react-bootstrap";
import { ErrorMessage } from "formik";
import { useDispatch } from "react-redux";
import { changeSalePrice } from "../../../store/subscription_item_store/SetupFeesEntitySlice";
import { i18n, numValidate } from "../../../../shared/helpers/sharedMethods";
import { changePlanFreetrialDays } from "../../../store/subscription_item_store/PlanEntitySlice";

const TrialDays = ({ values, handleChange, touched, errors }) => {
    const dispatch = useDispatch()
  return (
    <Row className="py-5">
    <Col lg={3} className="">
      <Form.Group>
        <Form.Label className="form-text-font">
          {i18n("Free Trial")}{" "}
        </Form.Label>
      </Form.Group>
    </Col>
    <Col lg={4} className="">
      <FormControl
        type="text"
        name="free_trial_days"
        value={values.free_trial_days||''}
        onChange={(e) => {
          handleChange(e);
          dispatch(changePlanFreetrialDays(e.target.value));
        }}
        onKeyPress={numValidate}
      />
    </Col>
  </Row>
  );
};

export default TrialDays;