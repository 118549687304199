import React from 'react';
import { Modal, Row, Col } from 'react-bootstrap'; 
import { Link } from "react-router-dom";
import '../css/sale.css';
import { i18n } from '../../shared/helpers/sharedMethods';

const PurchaseModal = ({ show, setShow }) => {
  return (
    <Modal className="modal-sm" show={show} onHide={() => setShow(false)}>
      <Modal.Header closeButton>
        <Modal.Title>{i18n("Please Select a Purchase Type")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Col>
          <Row className='py-2'>
            <Link to="/voucher/purchase-invoice/create" className="sale-link">{i18n("Purchase Invoice")}</Link>
          </Row>
          <Row className='py-2'>
            <Link to="/voucher/purchase-return/create" className="sale-link">{i18n("Purchase Return")}</Link>
          </Row>
          <Row className='py-2'>
            <Link to="/voucher/debit-note/create" className="sale-link">{i18n("Debit Note")}</Link>
          </Row>
          <Row className='py-2'>
            <Link to="/voucher/purchase-order/create" className="sale-link">{i18n("Purchase Order")}</Link>
          </Row>
        </Col>
      </Modal.Body>
    </Modal>
  );
};

export default PurchaseModal;
