import { createSlice, createAsyncThunk, createEntityAdapter,
    createSelector, } from "@reduxjs/toolkit";
import { axiosRequest } from "../../shared/helpers/axiosHelper";
import { filterDeletedSales, preprocessorDeletedSales, preprocessorDeletedSalesType } from "../helpers/VouchersHelper";

const voucherDeletedAdapter = createEntityAdapter();

const initialState = voucherDeletedAdapter.getInitialState({
    status: false,
    error: null,
    saleType: [],
});

export const getAllDeletedVoucher = createAsyncThunk(
    'VoucherDeletedRecords/fetch',
    async ({ token, tenantId }) => {
        try {
            const response = await axiosRequest({
                method: "GET",
                url: 'deleted/vouchers',
                headers: {
                    Authorization: `Bearer ${token}`,
                    "X-Tenant": tenantId,
                },
            });
            console.log('Fetched deleted vouchers:', response.data);
            return response.data;
        } catch (error) {
            await window.log.logToFile({ message: "Error while fetching all deleted vouchers", level: "error" });
            throw new Error('Error fetching deleted vouchers');
        }
    }
);

const voucherDeletedSlice = createSlice({
    name: "VoucherDeletedRecords",
    initialState,
    reducers: {
        resetDeletedVoucher: () => initialState,
        addVoucherType(state, action) {
          state.saleType = action.payload
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getAllDeletedVoucher.pending, (state) => {
                state.status = false;
                state.error = null;
            })
            .addCase(getAllDeletedVoucher.fulfilled, (state, action) => {
                state.status = true;
                voucherDeletedAdapter.upsertMany(state, action.payload);
            })
            .addCase(getAllDeletedVoucher.rejected, (state, action) => {
                state.status = false;
                state.error = action.error.message;
            });
    },
});

export const deletedVoucherRestore = createAsyncThunk(
    'VoucherDeletedRecords/recreate',
    async ({ id, token, tenantId }) => {
        try {
            const response = await axiosRequest({
                method: "POST",
                url: `restore/vouchers/${id}`,
                headers: {
                    Authorization: `Bearer ${token}`,
                    "X-Tenant": tenantId,
                },
            });
            return response.data;
        } catch (error) {
            await window.log.logToFile({ message: "Error while restoring deleted voucher", level: "error" });
            throw new Error('Error restoring voucher');
        }
    }
);
  
export const deleteVoucherPermanently = createAsyncThunk(
    'VoucherDeletedRecords/permanatdelete',
    async ({ id, token, tenantId }) => {
        try {
            const response = await axiosRequest({
                method: "DELETE",
                url: `permanent-delete/vouchers/${id}`,
                headers: {
                    Authorization: `Bearer ${token}`,
                    "X-Tenant": tenantId,
                },
            });
            return response.data;
        } catch (error) {
            await window.log.logToFile({ message: "Error while deleting voucher permanently", level: "error" });
            throw new Error('Error deleting voucher');
        }
    }
);

export default voucherDeletedSlice.reducer;

export const { 
    resetDeletedVoucher,
    addVoucherType } = voucherDeletedSlice.actions;

export const { selectAll: selectAllDeletedVoucher } = voucherDeletedAdapter.getSelectors(
  (state) => state.VoucherDeletedRecords
);

export const selectSales = createSelector(
    [selectAllDeletedVoucher],
    (vouchers) => {
        return vouchers
          .filter(voucher => voucher && ["sales_invoice", "sales_return", "credit_note", "quote", "proforma_invoice"].includes(voucher.type))
          .map(voucher => ({
            id: voucher.id,
            voucher_number: voucher.voucher_number,
            party: voucher?.party?.name,
            paid_amount: voucher?.paid_amount,
            created_at: voucher?.created_at,
            deleted_at: voucher?.deleted_at,
          }));
    }
  );
  export const selectPurchase = createSelector(
    [selectAllDeletedVoucher],
    (vouchers) => {
        return vouchers
          .filter(voucher => voucher && ["purchase_invoice", "purchase_return", "debit_note", "purchase_order"].includes(voucher.type))
          .map(voucher => ({
            id: voucher.id,
            voucher_number: voucher.voucher_number,
            party: voucher?.party?.name,
            paid_amount: voucher?.paid_amount,
            created_at: voucher?.created_at,
            deleted_at: voucher?.deleted_at,
          }));
    }
  );

  export const selectSalesByType = createSelector(
    [selectAllDeletedVoucher, (state, type) => type],
    (vouchers, type) => {
      let processedVouchers = [];
  
      if (type === "All_sales") {
        const salesTypes = ["sales_invoice", "sales_return", "credit_note", "quote", "proforma_invoice"];
        const filteredSales = filterDeletedSales(vouchers, salesTypes);
        processedVouchers = preprocessorDeletedSalesType(filteredSales);
  
      } else if (type === "All_purchase") {
        const purchaseTypes = ["purchase_invoice", "purchase_return", "debit_note", "purchase_order"];
        const filteredPurchases = filterDeletedSales(vouchers, purchaseTypes);
        processedVouchers = preprocessorDeletedSalesType(filteredPurchases);
  
      } else {
        const filteredVouchers = filterDeletedSales(vouchers, [type]);
        processedVouchers = preprocessorDeletedSalesType(filteredVouchers);
      }
  
      return processedVouchers;
    }
  );
