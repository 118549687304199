import { Form } from "react-bootstrap";
import { useEffect } from "react";
import {  taxListShort, taxList} from "../../../shared/config/taxConfig";
import { useDispatch, useSelector } from "react-redux";
import { filterTaxListShort } from "../../helpers/VouchersHelper";
import { updateItemField } from "../../store/voucherItemsSlice";

const TaxRateSelect = ({ index, item, discountEnabled, is_same_state }) => {
  const dispatch = useDispatch();

  const getSetting = useSelector((state) => state.getSetting?.dataSetting);
  const getTax = (getSetting?.tax || []);
  const taxList = filterTaxListShort(taxListShort, getTax);


  return (
    <Form.Select name="tax_code" aria-label="Tax Rate" className="form-control small-text w-50" defaultValue={item?.tax_code} style={{ borderRight : "0.5px solid #CED4DA"}} onChange={(e) => {
      let data = {
        fieldName: "tax_code",
        fieldValue: e.target.value,
        index,
        discountEnabled,
        is_same_state
      }
      dispatch(updateItemField(data));
    }}>
      {taxList && taxList.length ? (
        taxList.map((tax, index) => {
          
          return <option value={tax.code} selected={item?.tax_code == tax.code}>{tax.label}</option>;
        })
      ) : (
        <option value="">No Tax options</option>
      )}
    </Form.Select>
  );
};

export default TaxRateSelect;