import React, { useEffect, useState } from 'react';
import { Container, ListGroup, Stack } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faPenToSquare
} from '@fortawesome/free-solid-svg-icons';
import { useSelector } from 'react-redux';
import EditPartyModal from '../modals/EditParty';
import { i18n } from '../../../../../shared/helpers/sharedMethods';

const CustomerDetails = () => {
    const { party  } = useSelector(state => state.posOrder)

    const [partyModalShow, setPartyModalShow] = useState(false);

  
    const togglePartyModal = (visibility) => {
        
        setPartyModalShow(visibility)
    };

    return (
        <Container className='p-0 mb-3'>
            <ListGroup>
                <ListGroup.Item className='list-detail-header'>{i18n("Customer Details")} <span className='shortcut-color float-end'>{i18n("[F5]")}</span></ListGroup.Item>
                <ListGroup.Item className='list-detail-row'>
                    <Stack gap={2} direction="horizontal">
                    <h3 className='text-capitalize'>{party && party.name}</h3>
                    <span className='ms-auto clickable-action'>
                    <FontAwesomeIcon size='xl' color='gray' icon={faPenToSquare} onClick={() => { togglePartyModal(true) }}/>
                    </span>
                    </Stack>
                </ListGroup.Item>
            </ListGroup>
            <EditPartyModal partyModalShow={partyModalShow} togglePartyModal={togglePartyModal} />
        </Container>
    )
}

export default CustomerDetails;