import React from "react";
import { useSelector } from "react-redux";
import { Card, Row, Col, Form } from "react-bootstrap";
import { UilEdit, UilTrashAlt } from "@iconscout/react-unicons";
import { find } from "lodash";
import { placeOfSupplyList } from "../../shared/config/placeOfSupply";
import { i18n } from "../../shared/helpers/sharedMethods";

const MultipleAddress = ({ handleEdit, handleDelete }) => {
  const { party_address } = useSelector((state) => state.partyEntity.party);

  return (
    <Card
      className="mx-4 p-2"
      style={{
        background: "white",
        height: "38vh",
        overflowY: "auto",
      }}
    >
      <Card.Body className="p-1">
        {party_address && party_address.length > 0 ? (
          <Row>
            {party_address.map((value, i) => (
              <Col xs={6} key={i} className="mb-3">
                <div
                  className="p-3 border rounded position-relative d-flex"
                  style={{
                    fontSize: "0.9rem",
                    height: "100px", 
                    alignItems: "center", 
                  }}
                >
                  {value?.status === true && (
                    <Form.Label
                      className="badge bg-info bg-gradient position-absolute"
                      style={{ top: "8px", right: "8px" }}
                    >
                      Default
                    </Form.Label>
                  )}
                  <div style={{ flex: 1 }}>
                    <div>{value?.name ? `${value.name},` : ""}</div>
                    <div>
                      {value?.address ? `${value.address},` : ""}
                    </div>
                    <div>
                    {value?.city ? `${value.city},` : ""}
                      {value?.state
                        ? `${find(placeOfSupplyList, { value: value.state }).label} - `
                        : ""}
                      {value?.pincode || ""}
                    </div>
                    {value?.phone_number && 
                    <div>
                    <span className="text-black-50 bold">{i18n("PHONE :")} </span>
                    {value?.phone_number ? `${value.phone_number}` : ""}</div>}
                  </div>
                  <div className="d-flex justify-content-end align-items-center">
                    <UilEdit
                      size="16"
                      className="mx-2"
                      aria-hidden="true"
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        handleEdit({
                          value: value,
                          id: i + 1,
                        })
                      }
                    />
                    <UilTrashAlt
                      size="16"
                      className="mx-2"
                      aria-hidden="true"
                      style={{ cursor: "pointer" }}
                      onClick={() => handleDelete(i + 1)}
                    />
                  </div>
                </div>
              </Col>
            ))}
          </Row>
        ) : (
          <div className="text-center">No addresses available</div>
        )}
      </Card.Body>
    </Card>
  );
};

export default MultipleAddress;
