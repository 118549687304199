import {
    createSlice,
    createAsyncThunk,
  } from "@reduxjs/toolkit";
import { i18n } from "../../shared/helpers/sharedMethods";
import { axiosRequest } from "../../shared/helpers/axiosHelper";

  const initialState = {
    status:false,
    reportItems:[],
    error:false,
    errorMessage:'',
    loading:false,
  };

export const fetchRateListReport = createAsyncThunk(
    "rateListReport/fetch",
    async ({ token, tenant_id }) => {
      try{
      const rateListReportsResponse = await axiosRequest({
        method: "GET",
        url: `get-rate-list-report`,
        headers: { Authorization: `Bearer ${token}`, "X-Tenant": tenant_id },
      });
      return rateListReportsResponse?.data;
    }catch(err){
      await window.log.logToFile({message: "Error while fetching Rate List Report", level: "error" });
      throw new Error("Error while fetching Rate list report")
    }
    }
  );

const rateListReportSlice = createSlice({
    name: "rateListReport",
    initialState,
    reducers: {},
    extraReducers(builder) {
      builder
      .addCase(fetchRateListReport.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchRateListReport.fulfilled, (state, action) => {
        state.status = true;
        state.loading = false;
        state.reportItems = action.payload;
      })
      .addCase(fetchRateListReport.rejected, (state, action) => {
          state.error = true;
          state.loading = false; 
          state.errorMessage= (i18n("Error while fetching Rate list report. Please try again later."));
          state.status = false;
      })
    },
});

export default rateListReportSlice.reducer;
