import {
  createSlice,
  createAsyncThunk,
} from "@reduxjs/toolkit";
import { i18n } from "../../../shared/helpers/sharedMethods";

const initialState = {
  status: false,
  reportItems: [],
  error: false,
  errorMessage: {}
};

export const fetchHourlySalesChart = createAsyncThunk('hourlySalesChart/fetch', async (param) => {
  try {
    const hourlySalesChartResponse = await window.api.hourlySalesChart(param);
    return hourlySalesChartResponse.data;
  } catch (err) {
    await window.log.logToFile({ message: "Error while fetching Hourly Sales Chart", level: "error" });
    throw new Error("Error while fetching Hourly Sales chart")
  }
});

const hourlySalesChartSlice = createSlice({
  name: "hourlyChart",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchHourlySalesChart.fulfilled, (state, action) => {
        state.status = true;
        state.reportItems = action.payload;
      })
      .addCase(fetchHourlySalesChart.rejected, (state, action) => {
        state.error = true;
        state.errorMessage = (i18n("Error while fetching Hourly Sales chart. Please try again later."));
        state.status = false;
      })

  },
});

export default hourlySalesChartSlice.reducer;
