import { Form } from "react-bootstrap";
import { deleteVoucherItem, selectVoucherItemById, updateItemField } from "../../store/voucherItemsSlice";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";

const LineIndex = ({ index, serial, item }) => {
    const dispatch = useDispatch();
    const [isHovered, setIsHovered] = useState(false);
    let lineIndex = serial + 1;
    return (
        <>
            <td onMouseEnter={(e) => {
                setIsHovered(true)
            }}
                onMouseLeave={(e) => {
                    setIsHovered(false)
                }}
            >{!isHovered ? lineIndex :
                <FontAwesomeIcon className="text-danger cursor-pointer" icon={faTrash} title="Delete Line Item"
                    onClick={(e) => {
                        var rowCheck = true;
                        if (item && item?.product_id != '') {
                            // rowCheck = confirm('Are you sure to clear the line item?');
                        }
                        if (rowCheck) {
                            dispatch((deleteVoucherItem(index)));
                        }

                    }} />}
            </td>
        </>

    );
};

export default LineIndex;