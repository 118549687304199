import React, { useCallback, useEffect } from 'react';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { i18n } from '../helpers/sharedMethods';

const DeleteModel = (props) => {
  const { onClickDeleteModel, deleteUserClick, name } = props;

  const escFunction = useCallback((event) => {
    if (event.keyCode === 27) {
      onClickDeleteModel(false);
    }
  }, [onClickDeleteModel]);

  useEffect(() => {
    document.addEventListener('keydown', escFunction, false);
    return () => {
      document.removeEventListener('keydown', escFunction, false);
    };
  }, [escFunction]);

  const showConfirm = useCallback(() => {
    confirmDialog({
      message: i18n(`Are you sure want to delete?`),
      header: i18n('Delete'),
      icon: 'pi pi-exclamation-triangle',
      acceptClassName: 'p-button-danger',
      rejectClassName: 'p-button-secondary',
      acceptLabel: i18n('Yes, Delete'),
      rejectLabel: i18n('No, Cancel'),
      accept: deleteUserClick,
      reject: () => {
        onClickDeleteModel(false);
        setTimeout(() => {
          onClickDeleteModel(false); // Ensure the state is fully reset
        }, 0);
      },
      onHide: () => {
        onClickDeleteModel(false); // Reset state when the dialog is closed
      },
    });
  }, [name, deleteUserClick, onClickDeleteModel]);

  useEffect(() => {
    showConfirm();
  }, [showConfirm]);

  return <ConfirmDialog />;
};

export default DeleteModel;
