import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { axiosRequest } from "../../../shared/helpers/axiosHelper";
import { i18n } from "../../../shared/helpers/sharedMethods";

const initialState = {
  data: false,
  status: false,
  savedEntity: {},
  error: {},
  type: "",
  isEdit: false
};

export const AddItem = createAsyncThunk(
  "createSubscriptionItem/create",
  async (args) => {
    try {
      const token = args.token;
      const data = args.data;
      const tenantId = args.tenant_id;
      console.log("token", token);
      console.log("token", tenantId);

      const response = await axiosRequest({
        method: "POST",
        url: "subscriptions/product",
        data: data,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "X-Tenant": tenantId,
        },
      });
      ;
      return response.data;
    } catch (error) {
      await window.log.logToFile({ message: "Error while creating subscription item", level: "error" });
      throw error;
    }
  }
);

export const EditItem = createAsyncThunk(
  "createSubscriptionItem/edit",
  async (args) => {
    try {
      const token = args.token;
      const data = args.data;
      const id = args.id;
      const tenantId = args.tenant_id;
      const response = await axiosRequest({
        method: "PUT",
        url: `subscriptions/product/${id}`,
        data: data,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "X-Tenant": tenantId,
        },
      });
      return response.data;
    } catch (error) {
      await window.log.logToFile({ message: "Error while updating subscription item", level: "error" });
      throw error;
    }
  }
);

const CreatePackageSlice = createSlice({
  name: "createPackage",
  initialState,
  reducers: {
    addNewParentProduct(state, action) {
      state.parentProduct = action.payload;
      state.data = true;
    },
    resetPackage: () => initialState,
  },
  extraReducers(builder) {
    builder
      .addCase(AddItem.fulfilled, (state, action) => {
        state.savedEntity = action.payload;
        state.status = true;
        state.type = action?.payload?.sub_type;


      })
      .addCase(AddItem.rejected, (state, action) => {
        state.status = false;
        state.error = (i18n("Item Creation failed. Please try again later."));
      })
      .addCase(EditItem.fulfilled, (state, action) => {
        console.log("saved entity", action.payload)
        state.savedEntity = action.payload;
        state.status = true;
        state.type = action?.payload?.sub_type;
        state.isEdit = true;
      })
      .addCase(EditItem.rejected, (state, action) => {

        state.status = false;
        state.error = (i18n("Item update failed. Please try again later."));
      });
  },
});

export default CreatePackageSlice.reducer;

export const { addNewItem, resetPackage, addOnNew, addNewParentProduct } =
  CreatePackageSlice.actions;
