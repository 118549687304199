import { Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { i18n } from "../../../../../shared/helpers/sharedMethods";
import { changeSubscriptionNumber } from "../../../../store/CreateSubscriptionSlice";
import { UilEdit } from "@iconscout/react-unicons";
import { useState } from "react";
import { Modal,Button } from "react-bootstrap";

const SubscriptionNumber = ({handleChange,values}) =>{
  const dispatch = useDispatch();
    const {subscription_number} = useSelector((state) => state.createSubscription);
    const [isEditable, setIsEditable] = useState(false);
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);

    const handleEditConfirm = () => {
      setIsEditable(true);
      setShowConfirmationModal(false);
    };
  
    const handleSave = () => {
      dispatch(changeSubscriptionNumber(subscription_number));
      setIsEditable(false);
    };
  
    const handleKeyDown = (e) => {
      if (e.key === 'Enter') {
        setIsEditable(false);
        handleSave();
      }
    };

    return(
        <>
         <Form.Group>
              <Form.Label>{i18n("Subscription Number")}</Form.Label>
              <UilEdit size={"20px"}
        onClick={() => {
          setShowConfirmationModal(true);
        }}
        style={{ cursor: "pointer" }}
      />
            </Form.Group>
            <Form.Control
              type="text"
              name="subscription_number"
              value={subscription_number}
              readOnly={!isEditable}
              onChange={(e) => {
                if (isEditable) {
                  dispatch(changeSubscriptionNumber(e.target.value));
                }
              }}
              onKeyDown={handleKeyDown}
            />
                   <Modal show={showConfirmationModal} onHide={() => setShowConfirmationModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{i18n("Confirm Voucher Number Edit")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        {i18n("This will affect document uniqueness, Are you sure want to edit?")}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowConfirmationModal(false)}>
            {i18n("No")}
          </Button>
          <Button variant="primary" onClick={handleEditConfirm}>
          {i18n("Yes")}
          </Button>
        </Modal.Footer>
      </Modal>
        </>
    )
}

export default SubscriptionNumber