import React, { useState, useRef } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { Row, Col, Image, Button, Alert } from "react-bootstrap";
import bill from "../../../assets/logo/logo6-small.png";
import OTPInput from "react-otp-input";
import { i18n } from "../../shared/helpers/sharedMethods";

const UnLockScreen = () => {
  const navigate = useNavigate();
  const pin = useSelector((state) => state.auth.pin);
console.log("pin", pin)
  const [showAlert, setShowAlert] = useState("");
  const [enteredPin, setEnteredPin] = useState("");
  const pinInputRef = useRef(null);

  const handlePinChange = (newPin) => {
    setEnteredPin(newPin);
  };

  const handleUnlock = () => {
    if (enteredPin === pin) {
      navigate("/dashboard");
    } else {
      setShowAlert(true);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleUnlock();
    }
  };

  return (
    <Row
      className="border d-flex flex-column justify-content-center align-items-center h-100 w-100"
      style={{
        borderRadius: "5px",
        height: "100vh",
        overflow: "scroll",
        overflowX: "hidden",
      }}
    >
      {showAlert && (
        <Col lg={6} className="d-flex justify-content-center">
          <Alert
            variant="danger"
            onClose={() => setShowAlert(false)}
            dismissible
          >
            <Alert.Heading className="mt-2 me-4 d-flex justify-content-center">
              {i18n("Please enter correct PIN.")}
            </Alert.Heading>
          </Alert>
        </Col>
      )}

      <Col className="mb-5 d-flex justify-content-center col-lg-6 px-4">
        <Image src={bill} alt="Bill Now" style={{ height: "90px" }} />
      </Col>
      <Col className="col-md-3 mb-5 me-4">
        <h2 className="text-center">{i18n("Locked")}</h2>
      </Col>
      <Col className="col-md-3 mb-5">
        <div className="flex-grow-1 d-flex flex-column justify-content-center align-items-center me-6">
          <p className="text-center mb-3">
            {i18n("To unlock, Please enter correct PIN")}
          </p>
          <OTPInput
            ref={pinInputRef}
            value={enteredPin}
            onChange={handlePinChange}
            numInputs={4}
            separator={<span></span>}
            shouldAutoFocus
            renderInput={(props) => (
              <input
                {...props}
                onKeyPress={handleKeyPress}
                style={{
                  width: "50px",
                  marginLeft: "5px",
                  height: "40px",
                  border: "1px solid lightgray",
                  borderRadius: "4px",
                  textAlign: "center",
                }}
              />
            )}
          />
        </div>
      </Col>

      <Col className="col-lg-3 d-flex justify-content-center me-5">
        <Button className="w-40" onClick={handleUnlock}>
          {i18n("Unlock")}
        </Button>
      </Col>
    </Row>
  );
};
export default UnLockScreen;
