import { Button, Col, Container, Row, Alert, Stack, Card, Form,Image, ButtonGroup,InputGroup,Dropdown} from "react-bootstrap";
import Header from "../../../shared/components/Header";
import Layout from "../../../shared/components/Layout";
import { i18n } from "../../../shared/helpers/sharedMethods";
import PackageLayout from "../../components/PackageLayout/PackageLayout";
import PlanTable from "../../components/package_table/PlanTable";
import AddonTable from "../../components/package_table/AddonTable";
import SetupFeesTable from "../../components/package_table/setupFeesTable";
import PackageHeader from "../../components/PackageHeader";
import { useEffect, useState } from "react";
import EditPackage from "./EditPackage";
import { useLocation, useNavigate, useParams } from "react-router";
import {
  currentProductAsSubscriptionItems,
  Deleteplan,
  planData,
  selectProductAsSubscriptionItems,
  resetStatus,
} from "../../store/subscription_item_store/GetPackagesItemSlice";
import { useDispatch, useSelector } from "react-redux";
import DeleteModel from "../../../shared/components/DeleteModel";
import ShortcutKeys from "../../../shared/components/ShortcutKeys";
import { resetPackage } from "../../store/subscription_item_store/CreatePackageSlice";
import { faCircle } from "@fortawesome/free-solid-svg-icons";
import { UilFileExclamationAlt,UilAngleDown, UilEdit, UilTrashAlt } from "@iconscout/react-unicons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LoadingSpinner from '../../../shared/components/LoadingSpinner';
import { fetchSetting } from "../../../settings/store/getSettingSlice";
import { capitalizeMethod } from "../../../shared/helpers/captilize";
import { toast } from "react-toastify";

const PackageDetails = ({ featureName }) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const location = useLocation();
  ;
  const { accessToken, isLoggedIn } = useSelector((state) => state?.auth);
  const selectedBusiness = useSelector(
    (state) => state?.auth?.selectedBusiness
  );
  const errorMessage = useSelector((state) => state.getPackage.errorMessage);
  const status = useSelector((state) => state.getPackage.status);
  const loading = useSelector((state) => state.getPackage.loading);
  const allPackages = useSelector((state) =>
    selectProductAsSubscriptionItems(state, null)
  );

  const {type,isEdit} = useSelector((state)=> state?.createPackage)

  const tenantId = selectedBusiness?.pivot?.tenant_id;
  const selectPackage = useSelector((state) =>
    currentProductAsSubscriptionItems(state, id)
  );
  
  const globalPlanFeatures =  useSelector(
    (state) => state?.globalPlan?.features
  );

  useEffect(()=>{
    if(type == "plan" && !isEdit){
      toast.success("Plan created successfulyy")
      dispatch(resetPackage());
    }else if(type == "addon" && !isEdit){
      toast.success("Addon created successfulyy")
      dispatch(resetPackage());
    }else if(type == "setup_fee" && !isEdit){
      toast.success("Setup fees created successfulyy")
      dispatch(resetPackage());
    }
  },[type,isEdit])

  useEffect(()=>{
    if(type == "plan" && isEdit){
      toast.success("Plan updated successfulyy")
      dispatch(resetPackage());
    }else if(type == "addon" && isEdit){
      toast.success("Addon updated successfulyy")
      dispatch(resetPackage());
    }else if(type == "setup_fee" && isEdit){
      toast.success("Setup fees updated successfulyy")
      dispatch(resetPackage());
    }
  },[type,isEdit])

  useEffect(() => {
    if (featureName && globalPlanFeatures && !globalPlanFeatures.includes(featureName)) {
      navigate("/setting/not-in-plan");
    }
  },[])

  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [deletePackage, setDeletePackage] = useState(false);
  const [packageData, setPackageData] = useState();
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  

  useEffect(() => {
    const handleOnlineStatusChange = () => {
      setIsOnline(navigator.onLine);
    };

    window.addEventListener("online", handleOnlineStatusChange);
    window.addEventListener("offline", handleOnlineStatusChange);

    return () => {
      window.removeEventListener("online", handleOnlineStatusChange);
      window.removeEventListener("offline", handleOnlineStatusChange);
    };
  }, []);

  useEffect(() => {
    if (!isOnline) {
      navigate("/dashboard/sales", { state: true });
    }
  }, [isOnline]);

  ;
  useEffect(() => {
    dispatch(
      fetchSetting({
        accessToken: accessToken.access_token,
        tenant_id: selectedBusiness?.pivot?.tenant_id,
      })
    );
  }, [status]);

  useEffect(() => {
    if (isOnline) {
      if (location?.state?.success) {
        if (allPackages && allPackages.length > 0) {
          navigate("/subscription/packages/" + id);
        }
      } else {
        if (allPackages && allPackages.length > 0) {
          const firstItemId = allPackages[0].id;
          navigate("/subscription/packages/" + firstItemId);
        }
      }
    }
  }, []);
  const handleEdit = (data) => {
    setShow(true);
    dispatch(resetPackage());
    setPackageData(data);
  };
  const handleDelete = () => {
    setDeletePackage(true);
  };
  const onDelete = () => {
    dispatch(
      Deleteplan({
        id: id,
        token: accessToken.access_token,
        tenant_id: tenantId,
      })
    ).then((res) => {
      ;
      dispatch(
        planData({ token: accessToken.access_token, tenant_id: tenantId })
      );
    });
    setDeletePackage(false);
  };
  const handleAlertClose = () => {
    dispatch(resetStatus());
  };
  
  const ItemActions = selectPackage.length > 0 &&(
    <Stack direction="horizontal" className="mt-2 mb-2 float-end">
      <InputGroup className="me-3 custom-input" >
            <Button variant="outline-secondary" onClick={() => handleEdit(id)} size="sm" style={{ padding: '0.5rem ', background: 'none' }}>
              <UilEdit className="mx-2 mb-1" size="16" />
              {i18n("Edit")}
            </Button>

            <Dropdown as={ButtonGroup}>
              <Dropdown.Toggle variant="outline-secondary" id="dropdown-custom" size="sm" style={{ height: '36px', }}>
                <UilAngleDown size="16" />
              </Dropdown.Toggle>

              <Dropdown.Menu className="custom-dropdown-menu" >
                <Dropdown.Item className="invoice-dropdown-item" onClick={() => handleDelete(id)} style={{ color: 'red' }}>
                  <UilTrashAlt className="mx-2 mb-1" size="16" style={{ color: 'red' }} />
                  {i18n("Delete")}
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </InputGroup>
    </Stack>
  );
  return (
    
    <Layout currentTitle={i18n("Packages")}>
         {loading && <LoadingSpinner />}

      <Header
        currentTitle={i18n("Manage Packages")}
        ItemActions={
          <Stack
            gap={3}
            direction="horizontal"
            className="my-2 justify-content-end"
          >
            <ShortcutKeys />
          </Stack>
        }
      />
      <PackageLayout currentItemId={id}>
        <PackageHeader
          currentTitle={capitalizeMethod(selectPackage[0]?.name)}
          ItemActions={ItemActions}
        />
        <Container fluid className="px-2">
        {status && errorMessage && (
            <Stack
              className="pt-3"
              direction="horizontal"
              style={{ paddingLeft: "250px", width: "90%" }}
            >
              <Alert
                show={status}
                onClose={handleAlertClose}
                dismissible
                size="md"
                variant="danger"
              >
                <Alert.Heading className="fw-bolder text-black">Error</Alert.Heading>
                <Stack
                  gap={1}
                  className="d-flex justify-content-start"
                  direction="horizontal"
                >
                  <FontAwesomeIcon
                    icon={faCircle}
                    className="px-1"
                    style={{ fontSize: "6px" }}
                  />
                  {errorMessage}
                </Stack>                
              </Alert>
            </Stack>
          )}
          {selectPackage?.length >= 1 &&
          <Row>
            <Col lg={12}>
              <PlanTable id={id} />
            </Col>
            <Col lg={12}>
              <AddonTable id={id} />
            </Col>
            <Col lg={12}>
              <SetupFeesTable id={id} />
            </Col>
          </Row>}
          {selectPackage.length === 0 && (
              <Card className="h-75 w-100">
                <div className="d-flex  justify-content-center align-items-center" style={{height:'50vh'}}>
                  <div style={{ textAlign: "center"}}>
                    <UilFileExclamationAlt size="50" color="gray" />
                    <Form.Group className="mt-3" style={{ textAlign: "center" }}>
                      <Form.Text
                        className=""
                        style={{ fontSize: "12px", color: "gray" }}
                      >
                        Please select a package first..!
                      </Form.Text>
                    </Form.Group>
                  </div>
                </div>
              </Card>
            )}
        </Container>
      </PackageLayout>

      {show && (
        <EditPackage
          show={show}
          setShow={setShow}
          packageData={packageData}
          id={id}
        />
      )}
      {deletePackage && (
        <DeleteModel
          name={selectPackage[0]?.name}
          deleteUserClick={onDelete}
          onClickDeleteModel={setDeletePackage}
        />
      )}
         {/* {errorMessage && (
        <Alert variant="danger" onClose={handleAlertClose} dismissible>
          {errorMessage}
        </Alert>
      )} */}
    </Layout>
  );
};


export default PackageDetails;
