import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { i18n } from '../../shared/helpers/sharedMethods';

const ReactiveSubscription = ({ show, handleClose, handleReactivate }) => {
  return (
    <Modal show={show} style={{ zIndex: 2500 }} dialogClassName="modal-dialog modal-dialog-centered mw-700px" onHide={handleClose}>
      <div style={{ borderRadius: '5px', boxShadow: '0 0 500px 500px rgba(0, 0, 0, 0.3)' }}>
        <Modal.Header closeButton>
          <Modal.Title>{i18n('Reactivate Subscription')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {i18n('Are you sure you want to reactivate this subscription?')}
        </Modal.Body>
        <Modal.Footer>
        <Button variant="primary" onClick={handleReactivate}>
            {i18n('Reactive')}
          </Button>
          <Button variant="secondary" onClick={handleClose}>
            {i18n('Close')}
          </Button>
        </Modal.Footer>
      </div>
    </Modal>
  );
};

export default ReactiveSubscription;
