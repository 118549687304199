import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import SubscriptionNotes from "./components/SubacriptionNotes";
import SubscriptionSummary from "./components/SubscriptionSummary";


const PaymentSectionForm = (props) => {
  const { touched, errors, handleChange, values, setFieldValue } = props;
  return (
    <>
      <Card>
        <Row>
          <Col lg={6}  style={{ borderRight: "1px solid lightgray" }} >
            <Row>
              <Col lg={12}>
                <SubscriptionNotes handleChange={handleChange} values={values} />
              </Col>
              {/* <Col className="mt-2" lg={12}>
                {" "}
                <SubscriptionBankDetails />
              </Col> */}
            </Row>
          </Col>
          <Col lg={6}>
            <Row className="" >
              {/* <Col lg={12} >
                <SubscriptionAmountReceived />
              </Col> */}
              <Col lg={12}>
                <SubscriptionSummary />
              </Col>
              <Col lg={12}>
                {/* <Row>
                  <Col lg={8}>
                    <SubscriptionTermsAndCondition />
                  </Col>
                  <Col lg={4}>
                    <SubscriptionSignBlock />
                  </Col>
                </Row> */}
              </Col>
            </Row>
          </Col>
        </Row>
      </Card>
    </>
  );
};

export default PaymentSectionForm;
