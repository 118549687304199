import axios from 'axios';
import { environment } from '../config/environment';


axios.defaults.baseURL = environment.URL;

export const axiosRequest = async (params) => {
  console.log('url',params);
    try {
      params.headers = { ...params.headers };
      const result = await axios.request(params);
      
      return result?.data;
     } catch(error) {
     // 
       let customErr = new Error(error.response.data.message);
       customErr.status  = error.response.status;
       throw customErr;
     } 
 };