import { faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import React from "react";
import { Card, Col, Form, Row, Stack } from "react-bootstrap";
import { i18n } from "../../../../../shared/helpers/sharedMethods";
import { useSelector } from "react-redux";
import { format } from "date-fns";
import GlobalCurrency from "../../../../../shared/components/GlobalCurrency";

const SubscriptionDetails = ({ data }) => {
 
  return (
    <>
      <Row className="mt-3">
        <Col md={3}>
          <Form.Label
            className=""
            style={{ fontWeight: 500, fontSize: "14px" }}
          >
            {" "}
            {i18n("Start Billing Date")}
          </Form.Label>{" "}
          <br />
          <Form.Label
            className=""
            style={{ fontWeight: 400, fontSize: "12px" }}
          >
            {data?.start_date
              ? format(new Date(data?.start_date), "dd-MM-yyyy ")
              : "-"}
          </Form.Label>
        </Col>

        <Col md={3}>
          {" "}
          <Form.Label
            className=""
            style={{ fontWeight: 500, fontSize: "14px" }}
          >
            {" "}
            {i18n("Next Billing Date")}
          </Form.Label>
          <br />{" "}
          <Form.Label
            className=""
            style={{ fontWeight: 400, fontSize: "12px" }}
          >
            {data?.bill_queue?.next_bill_date
              ? format(
                new Date(data?.bill_queue?.next_bill_date),
                "dd-MM-yyyy "
              )
              : "-"}
          </Form.Label>
        </Col>
        <Col md={3}>
          {" "}
          <Form.Label
            className=""
            style={{ fontWeight: 500, fontSize: "14px" }}
          >
            {" "}
            {i18n("Last Billing Date")}
          </Form.Label>
          <br />{" "}
          <Form.Label
            className=""
            style={{ fontWeight: 400, fontSize: "12px" }}
          >
            {data?.last_bill_date ? data?.last_bill_date : "-"}
          </Form.Label>
        </Col>
        <Col md={3}>
          {" "}
          <Form.Label
            className=""
            style={{ fontWeight: 500, fontSize: "14px" }}
          >
            {" "}
            {i18n("Subscription Amount")}
          </Form.Label>
          <br />{" "}
          <Form.Label
            className=""
            style={{ fontWeight: 400, fontSize: "12px" }}
          >
            <GlobalCurrency price={data?.grand_total} />
          </Form.Label>
        </Col>
      </Row>
    </>
  );
};

export default SubscriptionDetails;
