import GSTPurchaseReport from "../screens/GSTPurchaseReport"
import GSTR1SalesReport from "../screens/GSTR1SalesReport"
import GSTR2PurchaseReport from "../screens/GSTR2PurchaseReport"
import GSTSalesReport from "../screens/GSTSalesReport"
import HSNWiseSaleSummaryReport from "../screens/HSNWiseSaleSummary"

const rootPath = 'reports'


export const gstreportRoutes = [
    {
        path: rootPath+ '/gst/purchase',
        element: <GSTR2PurchaseReport/>,
        isProtected: true
  
     },
     {
        path: rootPath+ '/gst/sales',
        element: <GSTR1SalesReport/>,
        isProtected: true
  
     },
     {
        path: rootPath+ '/gst/purchase-with-hsn',
        element: <GSTPurchaseReport/>,
        isProtected: true
  
     },
     {
        path: rootPath+ '/gst/sales-with-hsn',
        element: <GSTSalesReport/>,
        isProtected: true
  
     },
     
     {
        path: rootPath+ '/gst/hsn-wise-sales-summary',
        element: <HSNWiseSaleSummaryReport/>,
        isProtected: true
  
     },
   
     
]