import {
    createSlice,
    createAsyncThunk
  } from '@reduxjs/toolkit';
  import { axiosRequest } from "../../shared/helpers/axiosHelper"
  import { i18n } from '../../shared/helpers/sharedMethods';

  const initialState = {
    tenantLocalStatus:false,
    terminalId: '',
    terminalCode : '',
    error:false,
    errorMessage:{},
    initInstallStatus:false,
    initInstallError : '',
    initInstallErrorMessage : '',
    permissionStatus:false,
    userId :{},
    role : null,
    permissions : []
   
};

export const createTenantLocal = createAsyncThunk('initInstall/createTenantLocal',async(data) => {
  try{
    
   const response = await window.api.createTenant({
      businessId : data.businessId,
       userId : data.userId,
   })

   return response
  }catch(error){
    await window.log.logToFile({message: "Unable to create tenant", level: "error" });
   throw new Error(error?.response.message)
  }
  
})

  export const initInstall = createAsyncThunk('initInstall/install', async (data) => {
    try {
      ;
      const sendData = data.installData;
      sendData.device_type ='desktop';
  
      const response = await axiosRequest({
        method: 'POST',
        url: 'tenants/init-install',
        data: sendData,
        headers: {
          'Content-Type': 'application/json',
          "X-Tenant": data.tenant_id,
        },
      });
       const returnData= {
        terminalId:response?.terminal_id,
        terminalCode: response?.terminal_code,
        userId:sendData.user_id,
        role:response?.acl?.role,
        permissions:response?.acl?.permissions
       }
     console.log('return',returnData);
      return returnData;
    } catch (error) {
      await window.log.logToFile({message: "Error initializing installation", level: "error" });
      throw error; 
    }
  });

  export const fetchPermission = createAsyncThunk(
    "fetchPermission/fetch",
    async (args) => {
      try {
        const token = args.token;
        const tenantId = args.tenant_id;
        const datas = {
          device_id: args.device_id, 
          user_id: args.user_id 
        };
  
        console.log('args int', args);
  
        const response = await axiosRequest({
          method: "PUT",
          url: "tenants/get-acl",
          data: datas, // Send the updated data structure
          headers: {
            Authorization: `Bearer ${token}`,
            "X-Tenant": tenantId,
          },
        });
  
          const returnData = {
            terminalId: response?.terminal_id,
            userId: datas.user_id,
            role: response?.acl?.role,
            permissions: response?.acl?.permissions,
          };

           return returnData;
       }catch (error) {
  console.log(error);
  await window.log.logToFile({
    message: "Error Fetch Permission",
    level: "error",
  });
  throw error;
}
     }
   );

const initInstallSlice = createSlice({
    name: 'initInstall',
    initialState,
    reducers: {
      resetInitInstall(state, action) {
        state.errorMessage={}
        state.error=false
        state.initInstallStatus=false
        state.tenantLocalStatus = false
        state.initInstallError = false
        state.initInstallErrorMessage = ''
     },
    },
    extraReducers(builder) {
        builder
          .addCase(initInstall.fulfilled, (state, action) => {
            ;
            state.terminalId = action.payload.terminalId;
            state.terminalCode = action.payload.terminalCode;
            state.userId = action.payload.userId;
            state.role = action.payload.role;
            state.permissions = action.payload.permissions;
            state.initInstallStatus = true;
          })
          .addCase(initInstall.rejected, (state, action) => {
            state.initInstallStatus = false;
            state.initInstallError = true;
            state.initInstallErrorMessage = action.error.message;
          })
          .addCase(createTenantLocal.pending, (state) => {
            state.loading = true;
            state.error = null;
          })
          .addCase(createTenantLocal.fulfilled, (state, action) => {
            state.tenantLocalStatus = true;
            state.loading = false;
          })
          .addCase(createTenantLocal.rejected, (state, action) => {
            state.error = i18n(
              "Couldn't create the tenant. Please try again later"
            );
            state.tenantLocalStatus = false;
            state.loading = false;
          })
          .addCase(fetchPermission.fulfilled, (state, action) => {
            state.permissionStatus = true;
            state.terminalId = action.payload.terminalId;
            state.userId = action.payload.userId;
            state.role = action.payload.role;
            state.permissions = action.payload.permissions;
            
          })
          .addCase(fetchPermission.rejected, (state, action) => {
            state.error = true;
            state.permissionStatus = false;
          });
    }
})

export default initInstallSlice.reducer;

export const  { resetInitInstall } = initInstallSlice.actions
