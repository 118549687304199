import { Button, Col, Dropdown, Form, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import {
  resetAuth,
  setCurrentTenantLocal,
  setSelectedBusiness,
  createAppConfig,
  logout,
  addResourcesBackUp,
  resetAllStatus,
  logOutSyncStatus,
  logoutInServer,
} from "../../auth/store/authSlice";
import {
  initInstall,
  resetInitInstall,
} from "../../auth/store/initInstallSlice";
import { resetCategory } from "../../settings/store/categorysSlice";
import { resetProduct } from "../../catalog/store/productsSlice";
import { resetSales } from "../../voucher/store/voucherSlice";
import { resetPayment } from "../../payment/store/paymentEntitySlice";
import { resetCartVoucherPayment } from "../../payment/store/paymentSlice";
import { resetParty } from "../../party/store/getPartySlice";
import { resetDashboard } from "../../dashboard/store/LatestTransactionSlice";
import { resetSubscription } from "../../subscription/store/SubscriptionSlice";
import { resetSubscriptionItem } from "../../subscription/store/subscription_item_store/GetPackagesItemSlice";
import { resetSubscriptionParty } from "../../subscription/store/LivePartyGetsSlice";
import { resetSetting } from "../../settings/store/getSettingSlice";
import { resetSubscriptionVoucher } from "../../subscription/store/getAllSubscriptionVoucherSlice";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { i18n } from "../helpers/sharedMethods";
import { resetEnquiry } from "../../enquiry/store/EnquirySlice";
import { resetEditSetting } from "../../settings/store/settingSlice";
import LogoutModal from "./LogoutModal";
import { UilSetting, UilCheck, UilBag } from "@iconscout/react-unicons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { resetAccount } from "../../manageAccount/store/manageAccountSlice";
import { UilSignOutAlt } from "@iconscout/react-unicons";
import { aboutDetails } from "../store/sharedSlice";
import { ToastContainer, toast } from "react-toastify";
import { slice } from "lodash";

const BusinessSwitchDropdown = () => {
  const {
    createConfigStatus,
    tenants,
    deviceId,
    businessSelectedStatus,
    setTenantStatus,
  } = useSelector((state) => state?.auth);
  const { backUpStatus } = useSelector((state) => state.auth);
  const accessToken = useSelector(
    (state) => state?.auth?.accessToken?.access_token
  );

  const { user } = useSelector(
    (state) => state?.auth
  );
  const terminalNumber = useSelector((state) => state?.auth?.terminalNumber);
  const selectedBusiness = useSelector(
    (state) => state?.auth?.selectedBusiness
  );
  const {syncCount} = useSelector((state) =>state?.auth)
  
  const [selectedUser, setSelectedUser] = useState();
  const [loading, setLoading] = useState(false);
  const [logOut, setLogOut] = useState(false);
  const { terminalId, terminalCode, initInstallStatus, initInstallError, initInstallErrorMessage } = useSelector(
    (state) => state?.initInstall
  );

  const navigate = useNavigate();
  const dispatch = useDispatch();



  const handleLogoutModal = () => {
    setLogOut(!logOut);
    dispatch(
      logOutSyncStatus()
    )
  };

  const handleLogoutInServer = () => {
    console.log('server logout',selectedBusiness)
    const logoutData = {
      user_id: selectedBusiness?.pivot?.user_id,
      location: selectedBusiness?.business_address,
      device_id: deviceId,
    };
    dispatch(
      logoutInServer({
        tenant_id: selectedBusiness?.pivot?.tenant_id,
        logoutData,
      })
    );
  }

  const handleLogout = () => {
    handleLogoutInServer();
    // dispatch(logout());
    //kill sync process while logout
    // window.sync.killProcesses();
    // window.api.logout();
    dispatch(resetCategory());
    dispatch(resetProduct());
    dispatch(resetSales());
    dispatch(resetPayment());
    dispatch(resetAuth());
    dispatch(resetInitInstall());
    dispatch(resetCartVoucherPayment());
    dispatch(resetParty());
    dispatch(resetSetting());
    dispatch(resetEditSetting());
    dispatch(resetSubscriptionVoucher());
    dispatch(resetDashboard());
    dispatch(resetSubscription());
    dispatch(resetSubscriptionItem());
    dispatch(resetEnquiry());
    dispatch(resetAccount());
   
  };




  useEffect(() => {
    if (initInstallStatus) {
      if(selectedUser?.business_id&&selectedUser?.pivot?.user_id){
      dispatch(
        setCurrentTenantLocal({
          businessId: selectedUser?.business_id,
          userId: selectedUser?.pivot?.user_id,
        })
      );
      }
     
    }
  }, [initInstallStatus]);

  useEffect(() => {
    if (setTenantStatus) {
      const appConfigData = {
        terminal_id: terminalId,
        terminal_code: terminalCode,
        token: accessToken,
        tenant_id: selectedUser?.pivot?.tenant_id,
        is_installed: 1,
        user: selectedUser?.pivot?.user_id,
        id: 1,
      };
      ;

      dispatch(createAppConfig(appConfigData));
    }
  }, [setTenantStatus]);

  useEffect(() => {
    if(initInstallError){
      let toastErrorMsg = (initInstallErrorMessage != '') ? initInstallErrorMessage : "Failed to login! Access might be forbidden!";
      toast.error(toastErrorMsg);
      dispatch(resetInitInstall());
      dispatch(resetAuth());
      setTimeout(() => {
        navigate(0)
      }, 1000);
    }
  },[initInstallError])

  useEffect(() => {
    if (createConfigStatus) {
      dispatch(setSelectedBusiness(selectedUser));
    }
  }, [createConfigStatus]);

  // useEffect(async () => {
  //   if (businessSelectedStatus) {
  //     try {
  //       const appVersion = await window.ele.getVersion();
  //       const data = {
  //         isAbout: false,
  //         version: appVersion,
  //       };

  //       dispatch(aboutDetails(data));
  //     } catch (error) {
  //       console.error("Failed to get app version:", error);
  //     }
  //   }
  // }, [businessSelectedStatus]);

  useEffect(() => {
    if (businessSelectedStatus) {
      dispatch(resetProduct());
      dispatch(resetSales());
      dispatch(resetPayment());
      dispatch(resetCategory());
      dispatch(resetCartVoucherPayment());
      dispatch(resetParty());
      dispatch(resetDashboard());
      dispatch(resetSubscription());
      dispatch(resetSubscriptionParty());
      dispatch(resetSubscriptionItem());
      dispatch(resetAuth());
      dispatch(resetSetting());
      dispatch(resetEditSetting());
      dispatch(resetSubscriptionVoucher());
      dispatch(resetInitInstall());
      dispatch(resetEnquiry());
      navigate("/dashboard/sales");
    }
  }, [businessSelectedStatus]);

  useEffect(() => {
    if (selectedUser) {
      
      if (selectedBusiness?.id !== selectedUser?.id) {
        setLoading(true);
        const checkTerminalData = {
          user_id: selectedUser?.pivot?.user_id,
          location: selectedUser?.business_address,
          device_id: deviceId,
        };
        dispatch(
          initInstall({
            tenant_id: selectedUser?.pivot?.tenant_id,
            installData: checkTerminalData,
          })
        );
    
      }
    }
  }, [selectedUser]);

  const handleCreateBusiness = () => {
    dispatch(resetAuth());
    navigate("/ManageAccounts/");
  };

  const [show, setShow] = useState(false);

  useEffect(() => {
    if (backUpStatus) {
      dispatch(logout());
      dispatch(resetCategory());
      dispatch(resetProduct());
      dispatch(resetSales());
      dispatch(resetPayment());
      dispatch(resetAuth());
      dispatch(resetInitInstall());
      dispatch(resetCartVoucherPayment());
      dispatch(resetParty());
      dispatch(resetSetting());
      dispatch(resetEditSetting());
      dispatch(resetSubscriptionVoucher());
      dispatch(resetDashboard());
      dispatch(resetSubscription());
      dispatch(resetSubscriptionItem());
      dispatch(resetAccount());
    }
  }, [backUpStatus]);

  const tenantId = selectedBusiness?.pivot?.tenant_id;

const formatBusinessName = (businessName) => {
  if (businessName.length <= 18) return businessName;
  return businessName.slice(0, 18) + "...";
};

  return (
    <>
          <ToastContainer />
      <Dropdown.Menu align={"end"} className="switch-dropdown-menu">
        <Dropdown.ItemText
          bsPrefix="custom-dropdown-item"
          className="mx-2 px-1"
          style={{
            fontSize: "0.8rem",
            fontWeight: 400,
          }}
        >
          {i18n("Switch Business")}
        </Dropdown.ItemText>
        <Dropdown.Divider />
        {tenants &&
          tenants.length > 0 &&
          tenants.map((section, index) => (
           
            <div className="px-2" key={index} style={{}}>
              <Dropdown.Item
                className="px-7"
                style={{
                  borderRadius: "8px",
                  backgroundColor:
                    (selectedBusiness?.id == section?.id) ? "#e9ecef" : "white",
                }}
                onClick={() => {
                  dispatch(resetAllStatus());
                  dispatch(resetInitInstall());
                  setSelectedUser(section);
                }}
              >
                <div className="d-flex justify-content-between align-items-center px-2">
                  <div>
                    <UilBag size="18" color="#6C757D" />
                    <Form.Label className="mx-2 cursor-pointer mb-0">
                      {formatBusinessName(section?.business_name)}
                    </Form.Label>
                  </div>

                  {selectedBusiness?.id == section?.id && (
                    <FontAwesomeIcon
                      icon={faCircleCheck}
                      style={{ color: "#6571FF" }}
                    />
                  )}
                </div>
              </Dropdown.Item>
            </div>
          ))}

        <Dropdown.Divider />
        <Dropdown.Item className="px-3 " style={{cursor:'none', backgroundColor: "transparent"}}>
          <div className="d-flex justify-content-start align-items-center col-lg-12">
            <div
              className="col-lg-3 border d-flex justify-content-center align-items-center"
              style={{ height: 35, width: 35, borderRadius: "30px" }}
            >
              <Form.Label
                className="mb-0 mt-1"
                style={{ fontSize: "18px", fontWeight: 500 }}
              >
                {" "}
                {user?.name?.charAt(0)}
              </Form.Label>
            </div>
            <div className="mx-2">
              <Form.Label className="mb-0">
                {user?.name}
              </Form.Label>
            </div>
          </div>
        </Dropdown.Item>
        <Dropdown.Item className=" px-2 " onClick={handleCreateBusiness}>
          <div
            className="d-flex justify-content-start align-items-center px-4 mt-2"
            style={{ cursor: "pointer" }}
          >
            <UilSetting size="18" className="" color="#6C757D" />
            <Form.Label className="mb-0 mx-2 cursor-pointer">
              {i18n("Manage Account")}
            </Form.Label>
          </div>
        </Dropdown.Item>

        <Dropdown.Item className="px-2 " onClick={handleLogoutModal}>
          <div
            className="d-flex col-lg-12 justify-content-start align-items-center px-4 cursor-pointer"
            style={{ color: "red" }}
          >
            <UilSignOutAlt size="18" />
            <Form.Label
              className="mb-0 mx-2"
              style={{ color: "red", cursor: "pointer" }}
            >
              {" "}
              {i18n("Logout")}
            </Form.Label>
          </div>
        </Dropdown.Item>
      </Dropdown.Menu>
      {logOut && <LogoutModal onConfirm={handleLogout} onCancel={setLogOut} syncCount={syncCount} />}
    </>
  );
};

export default BusinessSwitchDropdown;
