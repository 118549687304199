import {
    createSlice,
    createAsyncThunk,
    createEntityAdapter,
} from '@reduxjs/toolkit';
import { prepareCartItem, processCartItem } from '../helpers/cartHelper';

const cartItemsAdapter = createEntityAdapter();

const initialState = cartItemsAdapter.getInitialState({
    status: 'idle',
    error: null
});

export const addNew = createAsyncThunk(
    'cartItems/addNew',
    async (selectedProduct) => {
        try {
            
            return prepareCartItem(selectedProduct);
        } catch (error) {
            await window.log.logToFile({ message: "Error while adding cart items in voucher", level: "error" });
            throw error;
        }
    }
)

const cartItemsSlice = createSlice({
    name: 'cartItems',
    initialState,
    reducers: {
        quantityUpdated(state, action) {
            const { cartItemId, quantity } = action.payload

            const existingItem = state.entities[cartItemId]

            if (existingItem && (existingItem.quantity != quantity)) {
                existingItem.quantity = quantity;
                state.entities[cartItemId] = processCartItem(existingItem);
            }
        },
        deleteCartItem: cartItemsAdapter.removeOne,
        resetCart: () => initialState
    },
    extraReducers(builder) {
        builder
            .addCase(addNew.fulfilled, (state, action) => {
                const { id } = action.payload
                const existingItem = state.entities[id]

                if (existingItem) {
                    existingItem.quantity++
                } else {

                    cartItemsAdapter.addOne(state, processCartItem(action.payload))
                }
            })
    }
});

export default cartItemsSlice.reducer;

export const { quantityUpdated, deleteCartItem, resetCart } = cartItemsSlice.actions;


export const {
    selectAll: selectAllCartItems,
    selectById: selectCartItemById,
    selectIds: selectCartItemIds
} = cartItemsAdapter.getSelectors((state) => state.cartItems);