import React, { useCallback, useEffect, useRef } from 'react';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { i18n } from '../helpers/sharedMethods';

const RestoreModel = (props) => {
  const { onClickRestoreModel, restoreUserClick, name } = props;
  const dialogVisible = useRef(false);

  const escFunction = useCallback((event) => {
    if (event.keyCode === 27) {
      onClickRestoreModel(false);
    }
  }, [onClickRestoreModel]);

  useEffect(() => {
    document.addEventListener('keydown', escFunction, false);
    return () => {
      document.removeEventListener('keydown', escFunction, false);
    };
  }, [escFunction]);

  const showConfirm = useCallback(() => {
    if (!dialogVisible.current) {
      dialogVisible.current = true;
      confirmDialog({
        message: i18n(`Are you sure want to restore this item?`),
        header: i18n('Restore'),
        icon: 'pi pi-exclamation-triangle',
        acceptClassName: 'p-button-danger',
        rejectClassName: 'p-button-secondary',
        acceptLabel: i18n('Yes, Restore'),
        rejectLabel: i18n('No, Cancel'),
        accept: () => {
          dialogVisible.current = false;
          restoreUserClick();
        },
        onHide: () => {
          dialogVisible.current = false;
          onClickRestoreModel(false); // Reset the dialog state when closed
        }
      });
    }
  }, [name, restoreUserClick, onClickRestoreModel]);

  useEffect(() => {
    showConfirm();
  }, [showConfirm]);

  return <ConfirmDialog />;
};

export default RestoreModel;
