import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { format } from "date-fns";
import { fetchPartyLedger } from "../../../store/PartyLedgerSlice";
import { Card, Col, Row, Table } from "react-bootstrap";
import { i18n } from "../../../../shared/helpers/sharedMethods";
import { selectpartyById } from "../../../store/getPartySlice";
import GlobalCurrency from "../../../../shared/components/GlobalCurrency";

const Ledger = (props) => {
  const { partyId } = props;
  const dispatch = useDispatch();
  const accessToken = useSelector((state) => state?.auth?.accessToken?.access_token);
  const selectedBusiness = useSelector((state) => state?.auth?.selectedBusiness);
  const tenantId = selectedBusiness?.pivot?.tenant_id;
  const openingBalanceById = useSelector((state) => selectpartyById(state, partyId));

  useEffect(() => {
    if (partyId) {
      dispatch(fetchPartyLedger({ id: partyId, token: accessToken, tenant_id: tenantId }));
    }
  }, [partyId]);

  const getPartyLedgerData = useSelector(
    (state) => state.partyLedger.reportItems
  );

  const openingBalance = openingBalanceById?.opening_balance;
  let processingBalance = openingBalance;
  console.log('process', processingBalance);
  return (
    <Row className="px-3 mt-5 scrollable-table">
      <Card.Body className="table-responsive">
        <Table bordered hover responsive>
          <thead>
            <tr style={{ color: '#495057' }}>
              <th className="px-2" style={{ backgroundColor: '#F9FAFB' }}>{i18n("Date")}</th>
              <th className="px-2" style={{ backgroundColor: '#F9FAFB' }}>{i18n("Voucher Type")}</th>
              <th className="px-2" style={{ backgroundColor: '#F9FAFB' }}>{i18n("Voucher Number")}</th>
              <th className="px-2" style={{ backgroundColor: '#F9FAFB' }}>{i18n("Credit")}</th>
              <th className="px-2" style={{ backgroundColor: '#F9FAFB' }}>{i18n("Debit")}</th>
              <th className="px-2" style={{ backgroundColor: '#F9FAFB' }}>{i18n("Balance")}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="p-1 px-2 m-1 mx-3">-</td>
              <td className="p-1 px-2 m-1 mx-3">Opening Balance</td>
              <td className="p-1 px-2 m-1 mx-3">-</td>
              <td className="p-1 px-2 m-1 mx-3">
                {openingBalance < 0
                  ? <GlobalCurrency price={openingBalance} />
                  : "-"}{" "}
              </td>
              <td className="p-1 px-2 m-1 mx-3">
                {openingBalance > 0
                  ? <GlobalCurrency price={openingBalance} />
                  : "-"}{" "}
              </td>
              <td className="p-1 px-2 m-1 mx-3">
                <GlobalCurrency price={openingBalance} />
              </td>
            </tr>
            {getPartyLedgerData &&
              getPartyLedgerData.length > 0 &&
              getPartyLedgerData.map((data, index) => {
                const isCredit = [
                  "purchase_invoice",
                  "sales_return",
                  "credit_note",
                  "payment_in"
                ].includes(data.voucherType);

                let balance = processingBalance;
                if (isCredit) {
                  balance -= data.credit;
                } else {
                  balance += data.debit;
                }
                processingBalance = balance;

                return (
                  <tr key={index}>
                    <td className="p-1 px-2 m-1 mx-3">
                      {data.voucher_date
                        ? format(new Date(data.voucher_date), "dd/MM/yyyy")
                        : " "}
                    </td>
                    <td className="p-1 px-2 m-1 mx-3">
                      {data.voucherType
                        ? data.voucherType
                          .replace(/_/g, " ")
                          .replace(/\b\w/g, (match) => match.toUpperCase())
                        : "-"}
                    </td>
                    <td className="p-1 px-2 m-1 mx-3">{data.voucher_number}</td>
                    <td className="p-1 px-2 m-1 mx-3">
                      <GlobalCurrency price={data?.credit} />
                    </td>
                    <td className="p-1 px-2 m-1 mx-3">
                      <GlobalCurrency price={data?.debit} />
                    </td>
                    <td className="p-1 px-2 m-1 mx-3">
                      <GlobalCurrency price={processingBalance} />
                    </td>
                  </tr>
                );
              })}
            <tr>
              <td className="p-1 px-2 m-1 mx-3">-</td>
              <td className="p-1 px-2 m-1 mx-3">Closing Balance</td>
              <td className="p-1 px-2 m-1 mx-3">-</td>
              <td className="p-1 px-2 m-1 mx-3">
                {processingBalance < 0 ? <GlobalCurrency price={processingBalance} /> : "-"}{" "}
              </td>
              <td className="p-1 px-2 m-1 mx-3">
                {processingBalance > 0 ? <GlobalCurrency price={processingBalance} /> : "-"}{" "}
              </td>
              <td className="p-1 px-2 m-1 mx-3"><GlobalCurrency price={processingBalance} /> </td>
            </tr>
          </tbody>
        </Table>
      </Card.Body>
    </Row>
  );
};

export default Ledger;
