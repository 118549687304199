import { useDispatch, useSelector } from "react-redux";
import { Table } from "react-bootstrap";
import SubscriptionCartHeader from "./SubscriptionCartHeader";
import SubscriptionCartRow from "./SubscriptionCartRow";
import SubscriptionTotal from "./SubscriptionTotal";
import { selectSubscriptionPlanItemIds } from "../../../store/subscriptionProductEntitySlice";

const SubscriptionCartBlock = ({
  values,
  handleChange,
  cessEnabled,
  discountEnabled,
  isEdit,
  item,
}) => {
  const dispatch = useDispatch();

  const cartIds = useSelector(selectSubscriptionPlanItemIds);
  return (
    <Table
      className="my-0 voucher-cart-table"
      bordered
      style={{ border: "1px solid #e1e1e1" }}
    >
      <SubscriptionCartHeader
        cessEnabled={cessEnabled}
        discountEnabled={discountEnabled}
      />
      <tbody>
        {cartIds.map((value, i) => (
          <SubscriptionCartRow
          key={value}
            index={value}
            serial={i}
            cessEnabled={cessEnabled}
            discountEnabled={discountEnabled}
            item={item}
          />
        ))}
      </tbody>

      <SubscriptionTotal
        noOfItems={cartIds.length}
        cessEnabled={cessEnabled}
        discountEnabled={discountEnabled}
      />
    </Table>
  );
};

export default SubscriptionCartBlock;
