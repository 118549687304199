import { i18n, placeholderText } from "../../../shared/helpers/sharedMethods";
import { Unit } from "../../../shared/config/Units";
import {
  Card,
  Col,
  Form,
  InputGroup,
  Row,
  Button,
  Stack,
} from "react-bootstrap";
import { ErrorMessage, Field } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { changeCode, changeUnit } from "../../store/productEntitySlice";

const StockDetailsForm = (props) => {
  const {
    values,
    handleChange,
    errors,
    touched,
    handleBlur,
    handleViewCode,
    handleStock,
  } = props;
  const dispatch = useDispatch();

  const stockInitialState = useSelector((state) => state.stockEntity.stock);

  const getSetting = useSelector((state) => state.getSetting?.dataSetting);

const getUnits = getSetting?.units ? JSON.parse(getSetting?.units) : [];
  const UnitList = Unit.filter((unit) => getUnits.includes(unit.value));

  function handleGenerateCode(length) {
    const chars = "0123456789";
    let result = "";
    for (let i = 0; i < 12; i++) {
      result += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    dispatch(changeCode(result));
  }
  return (
    <Card className="px-4">
      <Row className="py-2">
        <Col lg={6}>
          <Form.Group>
            <Form.Label className="required">{i18n("Stock")}</Form.Label>

            <Stack direction="horizontal" className="px-1">
              <h5 className="pt-2" style={{ fontSize: "13px" }}>
                {stockInitialState?.quantity ? stockInitialState?.quantity : 0}
              </h5>
              <Button variant="link" onClick={handleStock}>
                {i18n("+ Adjust")}
              </Button>
            </Stack>
            <ErrorMessage
              name="quantity"
              component="span"
              className="text-danger d-block fw-400 fs-small mt-2"
            />
          </Form.Group>
        </Col>
        <Col lg={6} md={6} sm={12}>
          <Form.Label className="form-label">{i18n("Product Code")}</Form.Label>
          <Form.Text className="required" />
          <InputGroup>
            <Form.Control
              type="text"
              name="code"
              placeholder={placeholderText("ex:ITM12549")}
              onBlur={handleBlur}
              onChange={(e) => {
                handleChange(e);
                dispatch(changeCode(e.target.value));
              }}
              isInvalid={touched.code && errors.code}
              value={values.code}
            />
            {values.code === "" || values.code === null ? (
              <Button
                variant="primary"
                id="button-addon1"
                onClick={handleGenerateCode}
              >
                {i18n("Generate Code")}
              </Button>
            ) : (
              <Button
                variant="primary"
                id="button-addon1"
                onClick={handleViewCode}
              >
                {i18n("View Code")}
              </Button>
            )}
          </InputGroup>
          <Form.Text className="text-danger d-block fw-400 fs-small mt-2">
            {touched.code && errors.code}
          </Form.Text>
        </Col>
      </Row>
      <Row className="py-2">
        <Col lg={6}>
          <Form.Group>
            <Form.Label>{i18n("Unit")}</Form.Label>
            <Field name="unit">
              {({ field, form }) => (
                <Form.Select
                  {...field}
                  onChange={(e) => {
                    const value = e.target.value;
                    form.setFieldValue("unit", value ? value : "PCS");
                    dispatch(changeUnit(value));
                  }}
                  value={values.unit}
                >
                  {UnitList.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </Form.Select>
              )}
            </Field>
          </Form.Group>
        </Col>
      </Row>
    </Card>
  );
};
export default StockDetailsForm;
