import {
  createSlice,
  createAsyncThunk,
} from "@reduxjs/toolkit";
import { i18n } from "../../shared/helpers/sharedMethods";
import { axiosRequest } from "../../shared/helpers/axiosHelper";

const initialState = {
  status: false,
  reportItems: [],
  error: false,
  errorMessage: '',
  loading:false,
};

export const fetchPartyReportByItem = createAsyncThunk(
  "partyReportByItem/fetch",
  async ({ token, tenant_id, startDate, endDate, productData, categoryData }) => {
    try {
      const partyReportByItemResponse =  await axiosRequest({
        method: "GET",
        url: `get-party-report-by-item-report`,
        params: { startDate, endDate, productData, categoryData  },
        headers: { Authorization: `Bearer ${token}`, "X-Tenant": tenant_id },
      });
      return partyReportByItemResponse.data;
    } catch (err) {
      await window.log.logToFile({ message: "Error while fetching party report by item", level: "error" });
      throw new Error("Error while fetching Party report by Item")
    }
  }
);

const partyReportByItemSlice = createSlice({
  name: "partyReportByItem",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
    .addCase(fetchPartyReportByItem.pending, (state) => {
      state.loading = true;
    })
      .addCase(fetchPartyReportByItem.fulfilled, (state, action) => {
        state.status = true;
        state.loading = false;
        state.reportItems = action.payload;
      })
      .addCase(fetchPartyReportByItem.rejected, (state, action) => {
        state.error = true;
        state.loading = false;
        state.errorMessage = (i18n("Error while fetching Party report by Item. Please try again later"));
        state.status = false;
      })
  },
});

export default partyReportByItemSlice.reducer;
