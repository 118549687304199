import React from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faFileInvoice,
} from "@fortawesome/free-solid-svg-icons";
import { Card } from "react-bootstrap";

const GSTReportList = () => {
  const navigate = useNavigate();

  const handleGSTPurchase = () => {
    navigate("/reports/gstPurchase");
  };

  const handleGSTSales = () => {
    navigate("/reports/gstSales");
  };

  const handleHSNWiseSaleSummary = () => {
    navigate("/reports/hsnWiseSale");
  };

  const handleGSTR2Purchase = () => {
    navigate("/reports/gstr2Purchase");
  };
  const handleGSTR1Sales = () => {
    navigate("/reports/gst/sales");
  };
  return (
    <>
      <span
              className="section-headings p-2 px-4 mt-1 d-flex flex-direction-row"
              style={{ borderBottom: "1px solid lightgray" }}
            >
              <FontAwesomeIcon
                className="px-1 fs-4"
                icon={faFileInvoice}
              />{" "}
              <h4>GST</h4>
            </span>
            <Card.Body className="py-5">
            <h6
                className="pt-5 fs-4"
                onClick={handleGSTR1Sales}
                style={{ cursor: "pointer" }}
              >
                GSTR-1 (Sales)
              </h6>
              <h6
                className="pt-5 fs-4"
                onClick={handleGSTR2Purchase}
                style={{ cursor: "pointer" }}
              >
                GSTR-2 (Purchase)
              </h6>
             <h6
                className="pt-5 fs-4"
                style={{ cursor: "pointer" }}
                onClick={handleGSTPurchase}
              >
                GST Purchase (With HSN)
              </h6>
              
               <h6
                className="pt-5 fs-4"
                onClick={handleGSTSales}
               style={{ cursor: "pointer" }}
              >
                GST Sales (With HSN)
              </h6>
  
              <h6
                className="pt-5 fs-4"
                onClick={handleHSNWiseSaleSummary}
                style={{ cursor: "pointer" }}
              >
                HSN wise sales summary
              </h6>
              
              
            </Card.Body>
    </>
  )
}

export default GSTReportList;