import React from "react";
import { useSelector } from "react-redux";
import { i18n } from "../../../../shared/helpers/sharedMethods";

const SubscriptionCartHeader = ({ discountEnabled, cessEnabled }) => {
  const currencySymbol = useSelector(
    (state) => state.getSetting?.dataSetting.currency
  );
  ;

  return (
    <thead className="voucher-cart-header">
      <tr>
        <th rowSpan={2} style={{ width: "40px" }}>
          {i18n("#")}
        </th>
        <th colSpan={3} className="w-25" rowSpan={2}>
          {i18n("Item")}
        </th>
        <th rowSpan={2} style={{ width: "60px" }}>
          {i18n("Qty")}
        </th>
        <th rowSpan={2} style={{ width: "200px" }}>
          {i18n("Price")}({currencySymbol})
        </th>
        {discountEnabled && (
          <>
            {" "}
            <th colSpan={2} rowSpan={1} style={{ width: "10%" }}>
              {i18n("Discount")}
            </th>{" "}
          </>
        )}
        <th rowSpan={2} style={{ width: !discountEnabled ? "150px" : "170px" }}>
          {i18n("Tax")} ({i18n("GST")})
        </th>
        {cessEnabled && (
          <>
            <th colSpan={2} rowSpan={1} style={{ width: "10%" }}>
              {i18n("CESS")}
            </th>
          </>
        )}

        <th rowSpan={2} style={{ width: "200px" }}>
          {i18n("Total")}
        </th>
      </tr>

      <tr className="small-text">
        {discountEnabled && (
          <>
            {" "}
            <th>{i18n("Rate")}</th> <th>{i18n("Amount")}</th>{" "}
          </>
        )}
        {/* <th style={{ width : "115px"}}>{i18n("Rate")}</th><th style={{ width : "115px"}}>{i18n("Amount")}</th> */}
        {cessEnabled && (
          <>
            {" "}
            <th>{i18n("Rate")}</th>
            <th> {i18n("Amount")}</th>
          </>
        )}
      </tr>
    </thead>
  );
};

export default SubscriptionCartHeader;
