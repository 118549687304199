import {
  Button,
  Col,
  Container,
  Form,
  Modal,
  Row,
  Stack,
} from "react-bootstrap";
import { Formik } from "formik";
import { i18n } from "../../../shared/helpers/sharedMethods";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { useEffect, useState } from "react";
import {
  AddItem,
  EditItem,
  resetPackage,
} from "../../store/subscription_item_store/CreatePackageSlice";
import {
  changeSetupFeesIsRefundable,
  initEditSetupFee,
} from "../../store/subscription_item_store/SetupFeesEntitySlice";
import {
  addNewProduct,
  planData,
  resetStatus,
  selectAllSetupFees,
  selectProductAsSubscriptionSetupFee,
} from "../../store/subscription_item_store/GetPackagesItemSlice";
import ProductName from "../../components/packages_fields/setufee_fields/ProductName";
import ProductCode from "../../components/packages_fields/setufee_fields/ProductCode";
import ProductPrice from "../../components/packages_fields/setufee_fields/ProductPrice";
import ProductTaxCode from "../../components/packages_fields/setufee_fields/ProductTaxCode";
import Layout from "../../../shared/components/Layout";
import Header from "../../../shared/components/Header";
import PackageLayout from "../../components/PackageLayout/PackageLayout";
import PackageHeader from "../../components/PackageHeader";
import { useLocation, useNavigate, useParams } from "react-router";
import ShortcutKeys from "../../../shared/components/ShortcutKeys";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


const EditSetupFees = ({ currentItemId, show, setShow, editData }) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const location = useLocation();
  const dispatch = useDispatch();
  const { currentId } = location.state;
  const { accessToken, isLoggedIn } = useSelector((state) => state?.auth);

  const selectedBusiness = useSelector(
    (state) => state?.auth?.selectedBusiness
  );

  const currentData = useSelector((state) => selectAllSetupFees(state, id));
  ;

  const tenantId = selectedBusiness?.pivot?.tenant_id;
  const status = useSelector((state) => state.createPackage.status);
  const savedEntity = useSelector((state) => state.createPackage.savedEntity);
  const setupFees = useSelector((state) => state?.setupFees);

  ;
  const [isChecked, setIsChecked] = useState(false);

  useEffect(() => {
    if (status && savedEntity) {
      toast.success("Setup fees updated successfully")
      dispatch(addNewProduct(savedEntity));
      dispatch(
        planData({ token: accessToken.access_token, tenant_id: tenantId })
      );

      navigate("/subscription/packages/" + currentId, {
        state: { success: true },
      });
    }
  }, [status, savedEntity]);

  useEffect(() => {
    dispatch(initEditSetupFee(currentData[0]));
    setIsChecked(currentData[0].setup_fee?.is_refundable == 1 ? true : false);
  }, []);

  useEffect(() => {
    if (isChecked) {
      dispatch(changeSetupFeesIsRefundable(1));
    } else {
      dispatch(changeSetupFeesIsRefundable(0));
    }
  }, [isChecked]);

  const handleChecked = (event) => {
    setIsChecked(event.target.checked);
  };

  return (
    <>
      <ToastContainer />
      <Formik
        initialValues={setupFees}
        enableReinitialize={true}
        validationSchema={Yup.object().shape({
          name: Yup.string().required(i18n("Name is required")),
          code: Yup.string().required(i18n("Code is required")),
          sale_price: Yup.string().required(i18n("Price is required")),
        })}
        onSubmit={async (values) => {
          ;
          try {
            let formData = { ...values };
            ;

            formData = {
              ...formData,
              parent_product: currentId,
              sub_type: "setup_fee",
              type: "service",
              setup_fee: {
                is_refundable: formData.is_refundable,
              },
            };
            delete formData.is_refundable;

            ;
            dispatch(
              EditItem({
                data: formData,
                token: accessToken.access_token,
                tenant_id: tenantId,
                id: id,
              })
            );
          } catch (error) { }
        }}
      >
        {({
          values,
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue,
          isSubmitting,
          touched,
        }) => (
          <Layout currentTitle={i18n("Packages")}>
            <Header
              currentTitle={i18n("Manage Packages")}
              ItemActions={
                <Stack
                  gap={3}
                  direction="horizontal"
                  className="my-2 justify-content-end"
                >
                  <ShortcutKeys />
                </Stack>
              }
            />
            <PackageLayout currentItemId={currentId}>
              <PackageHeader
                currentTitle="Edit Setup Fees"
                ItemActions={
                  <Stack
                    gap={3}
                    direction="horizontal"
                    className="mt-2 mb-2 float-end"
                  >
                    <div>
                      <Button assName="" variant="primary" onClick={handleSubmit}>
                        {i18n("Save")}
                      </Button>
                    </div>
                    <div>
                      <Button
                        className=""
                        variant="outline-secondary"
                        onClick={() =>
                          navigate("/subscription/packages/" + currentId, {
                            state: { success: true },
                          })
                        }
                      >
                        {i18n("Cancel")}
                      </Button>
                    </div>
                  </Stack>
                }
              />
              <Container
                fluid
                className="px-5 "
                style={{
                  height: "calc(100vh - 150px",
                  overflow: "auto",
                  overflowX: "hidden",
                }}
              >
                <Row>
                  <Col lg={12} className="">
                    <ProductName
                      values={values}
                      handleChange={handleChange}
                      errors={errors}
                      touched={touched}
                    />
                  </Col>
                  <Col lg={12} className="">
                    <ProductCode
                      values={values}
                      handleChange={handleChange}
                      errors={errors}
                      touched={touched}
                    />
                  </Col>
                  <Col lg={12} className="">
                    <ProductPrice
                      values={values}
                      handleChange={handleChange}
                      errors={errors}
                      touched={touched}
                    />
                  </Col>
                  <Col lg={12} className="">
                    <ProductTaxCode
                      values={values}
                      handleChange={handleChange}
                      errors={errors}
                      setFieldValue={setFieldValue}
                      touched={touched}
                    />
                  </Col>
                  <Col lg={12} className="mt-8 d-flex ">
                    <Col lg={3} className=" d-flex">
                      <Form.Check
                        id="refund-check"
                        aria-label="Is refundable"
                        checked={isChecked}
                        onClick={handleChecked}
                      />
                      <Form.Label htmlFor="refund-check" className="mb-0 px-5 fs-4">
                        Is refundable ?
                      </Form.Label>
                    </Col>
                    <Col lg={3} className="">
                      <Form.Label
                        className="mb-0 fs-5"
                        style={{
                          color: isChecked ? "green" : "red",
                          fontWeight: 500,
                        }}
                      >
                        {isChecked ? "YES" : "NO"}
                      </Form.Label>
                    </Col>
                  </Col>
                </Row>
              </Container>
            </PackageLayout>
          </Layout>
        )}
      </Formik>
    </>
  );
};

export default EditSetupFees;
