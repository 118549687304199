import {
    createSlice,
    createAsyncThunk,
  } from "@reduxjs/toolkit";
  import { i18n } from "../../shared/helpers/sharedMethods";
  
  const initialState = {
    status:false,
    reportItems:[],
    error:false,
    errorMessage:{}
  };

  export const fetchProductTotalSoldQuantityChart = createAsyncThunk('productTotalSoldQuantityChart/fetch', async (param) => {
  try{
    const productTotalSoldQuantityChartResponse  = await window.api.getProductTotalSoldQuantityChart(param);
    return productTotalSoldQuantityChartResponse.data;
  }catch(err){
    await window.log.logToFile({message: "Unable to fetch Product Sold quantity", level: "error" });
    throw new Error("Error while fetching product Summary Chart")
  }
  });
  
  const productTotalSoldQuantityChartSlice = createSlice({
    name: "productTotalSoldQuantityChart",
    initialState,
    reducers: {},
    extraReducers(builder) {
      builder
      .addCase(fetchProductTotalSoldQuantityChart.fulfilled, (state, action) => {
        state.status = true;
        state.reportItems =action.payload;
      })
      .addCase(fetchProductTotalSoldQuantityChart.rejected, (state, action) => {
        state.error = true; 
        state.errorMessage = i18n('Unable to retrieve the product total sold quantity chart. Please try again later.');
        state.status = false;
      })
  
    },
  });
  
  export default productTotalSoldQuantityChartSlice.reducer;
  