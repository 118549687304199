import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  i18n,
  numValidate,
  placeholderText,
} from "../../shared/helpers/sharedMethods";
import Form from "react-bootstrap/Form";
import { Formik } from "formik";
import * as Yup from "yup";
import CreateCategory from "../../settings/components/CreateCategoryModel";
import {
  pushNewCategory,
  selectAllCategories,
} from "../../settings/store/categorysSlice";
import ProductDetailsForm from "../components/product_form/ProductDetailsForm";
import StockDetailsForm from "../components/product_form/StockDetailsForm";
import PriceDetailsForm from "../components/product_form/PriceDetailsForm";
import { Alert, Button, Col, Modal, Row, Spinner, Tab, Tabs } from "react-bootstrap";
import {
  fetchProducts,
  pushNewProduct,
  selectAllProducts,
} from "../store/productsSlice";
import { cloneDeep } from "lodash";
import {
  addProduct,
  changeDescription,
  resetCreateProduct,
  undoProductError,
} from "../store/productEntitySlice";
import ViewBarCode from "../components/ViewBarCode";
import CreateStock from "../components/stock/CreateStock";
import { resetStockAdjust } from "../store/stockEntitySlice";
import CancelAlerts from "../components/cancelAlerts";
import ErrorModal from "../../shared/components/ErrorModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBullseye, faCircle } from "@fortawesome/free-solid-svg-icons";
import AlertMessage from "../../shared/components/AlertMessage";
import { getUtcDate } from "../../shared/helpers/localizationHelper";
import { toast, ToastContainer } from "react-toastify";

const CreateProduct = ({ show, handleClose, setShowCreate }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const selectedBusiness = useSelector(
    (state) => state?.auth?.selectedBusiness
  );
  const { currency } = useSelector((state) => state.getSetting?.dataSetting);
  const fetchCategory = useSelector(selectAllCategories);
  const filterData = fetchCategory.filter((item) => item?.type === "product");
  const productState = useSelector((state) => state.productEntity.product);
  ;
  const status = useSelector((state) => state.productEntity.status);
  const { errorStatus, error } = useSelector((state) => state.productEntity);
  ;
  const createdEntity = useSelector((state) => state.productEntity.savedEntity);
  const stockInitialState = useSelector((state) => state.stockEntity.stock);
  const [showCategory, setShowCategory] = useState(false);
  const [showViewCode, setShowViewCode] = useState(false);
  const [barCodeItemData, setBarCodeItemData] = useState("");
  const [showStock, setShowStock] = useState(false);
  const products = useSelector(selectAllProducts);
  const [escKey, setEscKey] = useState(false);
  const [loading, setLoading] = useState(false);
  ; 
   const { accessToken } = useSelector((state) => state?.auth);
   const tenantId = selectedBusiness?.pivot?.tenant_id;
   const { terminalId } = useSelector((state) => state.initInstall);

   useEffect(() => {
    if (errorStatus) {
      setLoading(false)
    }
  }, [errorStatus])

  useEffect(() => {
    if (productState) {
      setBarCodeItemData(productState);
    }
  }, [productState]);

  const handleViewCode = () => {
    setShowViewCode(true);
  };

  const handleCategory = () => {
    setShowCategory(true);
  };
  const handleStock = () => {
    setShowStock(true);
  };

  useEffect(() => {
    if (status && createdEntity) {
      dispatch(pushNewProduct(createdEntity));
      dispatch(fetchProducts());
      setShowCreate(false);
      toast.success(i18n("Product created successfully"));
    }
  }, [status, createdEntity]);
  const handleCancel = () => {
    ;
    //navigate("/catalog/products");
    setShowCreate(false);
    dispatch(resetCreateProduct());
    dispatch(resetStockAdjust());
  };

  const [key, setKey] = useState("general");
  //const [hasError, setHasError] = useState(false);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Enter") {
        event.preventDefault();
        const submitButton = document.getElementById("submitButton");
        if (submitButton) {
          submitButton.click();
        }
      }
    };

    if (!showCategory && !showStock) {
      document.addEventListener("keydown", handleKeyDown);
    }

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [showCategory,showStock]);

  // useHotkeys('esc', (event) => {
  //   event.preventDefault();
  //     handleCancel();
  //   }
  // );

  //   function handleKeyDown(event) {
  //     if (event.key === "Escape") {
  //         alert("Are you sure you want to leave this page?");
  //        handleCancel();
  //        document.removeEventListener("keydown", handleKeyDown);
  //     }
  // }

  document.addEventListener("keydown", (event) => {
    if (event.key === "Escape") {
      setEscKey(true);
      ;
    }
  });

  const alertClose = () => {
    handleClose();
  };
  const handleAlertClose = () => {
    setLoading(false)
    dispatch(undoProductError());
  };

  return (
    <Formik
      initialValues={productState}
      enableReinitialize={true}
      validationSchema={Yup.object().shape({
        name: Yup.string().required(i18n("Please enter Product name")),
        code: Yup.string()
          .test("unique-code", "Code already exists", function (value) {
            const existingProduct = products.find(
              (product) => product.code === value
            );
            return !existingProduct;
          })
          .required(i18n("Please enter product code")),
        cost_price: Yup.number().required(i18n("Please enter purchase price")),
        sale_price: Yup.number().required(i18n("Sale price is required")),
        quantity: Yup.number().required(i18n("Stock is required")),
      })}
      onSubmit={async (values) => {
        ;
        try {
          setLoading(true);
          let formData = cloneDeep(values);
          formData.created_by = selectedBusiness?.pivot?.user_id;
          formData.updated_by = selectedBusiness?.pivot?.user_id;
          formData.created_at = getUtcDate();
          formData.updated_at = getUtcDate();

          // formData.inventories = [
          //   {
          //     quantity: stockInitialState?.quantity,
          //     warehouse_id: formData.warehouse_id,
          //   },
          // ];
          formData.inventories = [
            {
              id: stockInitialState.id,
              product_id: stockInitialState.product_id,
              warehouse_id: stockInitialState.warehouse_id,
              quantity: stockInitialState?.quantity,
              remarks: stockInitialState?.remarks
                ? stockInitialState?.remarks
                : "Stock Open",
              created_by: stockInitialState?.created_by,
              updated_by: stockInitialState.updated_by,
            },
          ];
          delete formData.id;
          delete formData.quantity;
          delete formData.warehouse_id;
          ;
        await dispatch(addProduct({data:formData,token: accessToken.access_token,
          tenant_id: tenantId, terminal_id:terminalId }));
        } catch (error) {}
        finally {
          setLoading(false);
        }
      }}
    >
      {({
        values,
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue,
        touched,
      }) => (
        <>
          <CancelAlerts
            handleClose={alertClose}
            show={escKey}
            setShow={setEscKey}
          />
          <CreateCategory
            show={showCategory}
            handleClose={() => setShowCategory(false)}
            type="product"
            hideTypeDropdown={true}
            caller="part2"
          />
          <ViewBarCode
            show={showViewCode}
            barCodeItemData={barCodeItemData}
            handleClose={() => setShowViewCode(false)}
          />
          <CreateStock
            show={showStock}
            handleClose={() => setShowStock(false)}
            numValidate={numValidate}
          />

          <Modal
            show={show}
            onHide={handleClose}
            keyboard={true}
            size="lg"
            dialogClassName="modal-dialog modal-dialog-centered"
          >
            <Modal.Header closeButton>
              <h2 className="bold">{i18n("Create Product")}</h2>
            </Modal.Header>
            {errorStatus && (
              <AlertMessage
                errorStatus={errorStatus}
                error={error}
                handleAlertClose={handleAlertClose}
              />
            )}
            <Modal.Body className="py-2 " style={{ height: "265px" }}>
              <Tabs
                id="controlled-tab-example"
                activeKey={key}
                onSelect={(k) => setKey(k)}
                className={`mb-3 custom-nav-css `}
                variant="underline"
                style={{
                  borderBottom: "1px solid lightgray",
                  lineHeight: "30px",
                  gap: "15px",
                }}
              >
                <Tab
                  className=""
                  eventKey="general"
                  title={
                    <div
                      style={{
                        color: `${
                          touched.name && errors.name ? "#EE5B50" : ""
                        }`,
                      }}
                    >
                      {i18n("General Details")}
                      <span className="required" />
                    </div>
                  }
                >
                  <ProductDetailsForm
                    handleChange={handleChange}
                    values={values}
                    filterData={filterData}
                    errors={errors}
                    touched={touched}
                    setFieldValue={setFieldValue}
                    handleCategory={handleCategory}
                    numValidate={numValidate}
                    handleBlur={handleBlur}
                  />
                </Tab>
                <Tab
                  eventKey="stock"
                  title={
                    <div
                      style={{
                        color: `${
                          touched.code && errors.code ? "#EE5B50" : ""
                        }`,
                      }}
                    >
                      {i18n("Stock Details")} <span className="required" />
                    </div>
                  }
                >
                  <StockDetailsForm
                    values={values}
                    handleChange={handleChange}
                    setFieldValue={setFieldValue}
                    errors={errors}
                    touched={touched}
                    numValidate={numValidate}
                    handleBlur={handleBlur}
                    handleViewCode={handleViewCode}
                    handleStock={handleStock}
                  />
                </Tab>
                <Tab
                  eventKey="price"
                  title={
                    <div
                      style={{
                        color: `${
                          (touched.cost_price && errors.cost_price) ||
                          (touched.sale_price && errors.sale_price)
                            ? "#EE5B50"
                            : ""
                        }`,
                      }}
                    >
                      {i18n("Price Details")}
                      <span className="required" />
                    </div>
                  }
                >
                  <PriceDetailsForm
                    handleChange={handleChange}
                    values={values}
                    errors={errors}
                    touched={touched}
                    setFieldValue={setFieldValue}
                    currencySymbol={currency}
                    numValidate={numValidate}
                    handleBlur={handleBlur}
                  />
                </Tab>
                <Tab eventKey="Description" title={i18n("Description")}>
                  <Row className="py-2 px-4">
                    <Form.Group>
                      <Form.Label>{i18n("Product Description")}</Form.Label>
                    </Form.Group>

                    <Col sm={12} lg={10}>
                      <Form.Control
                        as="textarea"
                        name="description"
                        rows={7}
                        placeholder={placeholderText("Enter Description")}
                        onChange={(e) => {
                          handleChange(e);
                          dispatch(changeDescription(e.target.value));
                        }}
                        value={values.description|| ""}
                        style={{ resize: "none" }}
                      />
                    </Col>
                  </Row>
                </Tab>
              </Tabs>
            </Modal.Body>
            <Modal.Footer style={{ borderTop: "1px solid lightgray" }}>
              <Row className=" py-1">
                {" "}
                <Col className="d-flex justify-content-end">
                  {" "}
                  {!loading ? (
                    <Button
                      id="submitButton"
                      variant="primary mx-2"
                      onClick={handleSubmit}
                      disabled={loading}
                    >
                      {i18n("Save")}
                    </Button>
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginRight: "25%",
                      }}
                    >
                      <Spinner animation="border" size="md" />
                    </div>
                  )}
                  <Button
                    variant="outline-secondary mx-2"
                    onClick={handleClose}
                  >
                    {i18n("Cancel")}
                  </Button>
                </Col>
              </Row>
            </Modal.Footer>
          </Modal>
        </>
      )}
    </Formik>
  );
};
export default CreateProduct;
