import React ,{useEffect, useState} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {Button} from 'react-bootstrap';
import { selectCartItemById, quantityUpdated, deleteCartItem } from '../../../../store/cartItemsSlice';

const CartItem = ({cartItemId, index}) => {

    const dispatch = useDispatch();
    const selectedCartItem = useSelector((state) => selectCartItemById(state, cartItemId))
    
    const [itemQty, setItemQty] = useState(0);

    useEffect(() => {
        if(selectedCartItem.quantity > 0){
            setItemQty(selectedCartItem.quantity);
        }
    },[selectedCartItem])

    const handleQtyChange = (e) => {
        e.preventDefault();
        const {value} = e.target;
        // check if value includes a decimal point
        if (value.match(/\./g)) {
            const [, decimal] = value.split('.');
            // restrict value to only 2 decimal places
            if (decimal?.length > 2) {
                // do nothing
                return;
            }
        }
        setItemQty(e.value);
        dispatch(quantityUpdated({
            cartItemId : cartItemId,
            quantity : value
        }));
        
    };

    const handleDeleteCartItem = (e) => {
        dispatch(deleteCartItem(cartItemId))
    }

    return (
        <tr key={index}>
            <td>
                {index + 1}
            </td>
            <td>
                <h4>{selectedCartItem.product_name}</h4>
            </td>
            <td>
                {selectedCartItem.product_code}
            </td>
            <td className="text-nowrap">{selectedCartItem.item_price}</td>
            <td>
                <input type='number' size="xs" className='pos-qty-box w-50' 
                value={itemQty}
                onChange={(e) => handleQtyChange(e)}
                 />
            </td>
           
            <td className="text-nowrap">{selectedCartItem.subtotal}</td>
            <td className='text-align-center remove-button pe-0'>
                <Button className='p-0 bg-transparent border-0' onClick={() =>handleDeleteCartItem(cartItemId)}>
                    <i className='bi bi-trash3 text-danger' style={{ fontSize : '18px' }}/>
                </Button>
            </td>
            
        </tr>
    )
}

export default CartItem;