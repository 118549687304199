import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
  createSelector
} from "@reduxjs/toolkit";
import { axiosRequest } from "../../shared/helpers/axiosHelper";
import { i18n } from "../../shared/helpers/sharedMethods";
import { filteredParty, preProcessorParty, processSubscriptions } from "../components/helpers/subscriptionHelpers";

const subscriptionAdapter = createEntityAdapter({});

const initialState = subscriptionAdapter.getInitialState({
  status: "idle",
  error: null,
  loading:false,
});

export const SubscriptionData = createAsyncThunk(
  "subscription/fetch",
  async (args) => {
    const token = args.token;
    const tenantId = args.tenant_id;
    try {
      const response = await axiosRequest({
        method: "GET",
        url: "subscriptions",
        headers: {
          Authorization: `Bearer ${token}`,
          "X-Tenant": tenantId,
        },
      });
      return response.data;
    }
    catch (err) {
      await window.log.logToFile({ message: "Error while fetching subscription", level: "error" });
      throw err;
    }
  }
);

export const DeleteSubscription = createAsyncThunk(
  "subscription/delete",
  async (args) => {
    const token = args.token;
    const id = args.id;
    const tenantId = args.tenant_id;

    try {

      const response = await axiosRequest({
        method: "DELETE",
        url: `subscriptions/${id}`,
        headers: {
          Authorization: `Bearer ${token}`,
          "X-Tenant": tenantId,
        },
      });
      return id;
    } catch (err) {
      await window.log.logToFile({ message: "Error while deleting subscription", level: "error" });
      throw err;
    }
  }
);

export const fetchSubscriptionByMemberId = createAsyncThunk(
  "subscription/member_subs",
  async (args) => {
    const { token, id, tenant_id } = args;

    try {
      const response = await axiosRequest({
        method: "GET",
        url: `subscriptions/member-subscription/${id}`,
        headers: {
          Authorization: `Bearer ${token}`,
          "X-Tenant": tenant_id,
        },
      });

      return response.data;
    } catch (err) {
      await window.log.logToFile({ message: "Error while getting member subscription", level: "error" });
      throw err;
    }
  }
);

const subscriptionSlice = createSlice({
  name: "subscription",
  initialState,
  reducers: {
    addPartyName(state, action) {
      ;
      state.partyName = action.payload
    },
    pushNewSubscription: subscriptionAdapter.addOne,
    UpdateSubscriptionData(state, action) {
      const { id } = action.payload;
      state.entities[id] = action.payload;
    },
    resetSubscription: () => initialState,
  },
  extraReducers(builder) {
    builder
    .addCase(SubscriptionData.pending, (state) => {
      state.loading = true;
      state.status = "loading";
    })
      .addCase(SubscriptionData.fulfilled, (state, action) => {
        subscriptionAdapter.upsertMany(state, action.payload);
        state.status = true
        state.loading = false;
      })
      .addCase(SubscriptionData.rejected, (state, action) => {
        state.status = false;
        state.loading = false;
        state.error = (i18n("Unable to add sales. Please try again later."));
      })
      .addCase(DeleteSubscription.fulfilled, subscriptionAdapter.removeOne)

      .addCase(fetchSubscriptionByMemberId.fulfilled, (state, action) => {
        state.status = true
        state.subscriptionItems = action.payload;
      })
      .addCase(fetchSubscriptionByMemberId.rejected, (state, action) => {
        state.error = true; 
        state.errorMessage=(i18n("Error while fetching member based Subscription data. Please try again later"));
        state.status = false;
      })
  },
});

export default subscriptionSlice.reducer;

export const {
  pushNewSubscription,
  resetSubscription,
  UpdateSubscriptionData,
  addPartyName
} = subscriptionSlice.actions;

export const {
  selectAll: selectAllSubscriptionData,
  selectById: selectSubscriptionDataById,
  selectIds: selectSubscriptionDataIds,
} = subscriptionAdapter.getSelectors((state) => state.subscription);

export const selectPartyByName = createSelector(
  [selectAllSubscriptionData, (state, name) => name],
  (subscriptions, name) => {
    ;
    if (!name) {
      return [];
    }
    const SubData = processSubscriptions(subscriptions);

    const subscriptionVouchers = SubData && SubData.length > 0 && SubData.map((item) => {
      return item?.subscription_vouchers;
    })

    let empty = [];
    {
      subscriptionVouchers.map((subscription, index) => (
        subscription.map((item, index) => {
            empty.push(item);
        })))
    }

    if (name === 'All') {
      const processedParty = preProcessorParty(empty);
      return processedParty;
    } else {
      const filteredParties = filteredParty(empty, name);
      
      const processedParty = preProcessorParty(filteredParties);
      
      return processedParty;
    }
  }
);

