import { faSquareXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Col, Form, InputGroup } from "react-bootstrap";
import { Menu, MenuItem, Typeahead } from "react-bootstrap-typeahead";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useRef, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import { ErrorMessage } from "formik";

import {
  i18n,
  placeholderText,
} from "../../../../../shared/helpers/sharedMethods";
import { fetchInviteTrainer } from "../../../../../settings/store/inviteUserSlice";
import { fetchMemberById, initMemberEdit, selectMemberDataById, EditMemberById } from "../../../../store/MemberSlice";
import { cloneDeep } from "lodash";

const SubscriptionMemberBaseTrainer = ({ isEdit, errors, memberId, handleChange }) => {
  const dispatch = useDispatch();

  const selectedBusiness = useSelector((state) => state?.auth?.selectedBusiness);
  const tenantId = selectedBusiness?.pivot?.tenant_id;
  const { accessToken } = useSelector((state) => state?.auth);

  const [selectedTrainer, setSelectedTrainer] = useState([]);
  const typeaheadRef = useRef(null);

  const { trainersList } = useSelector((state) => state.inviteUser);

  useEffect(() => {
    if (tenantId && accessToken?.access_token) {
      dispatch(fetchInviteTrainer({
        tenant_id: tenantId,
        token: accessToken?.access_token,
      }));
    }
  }, [dispatch, tenantId, accessToken]);

  const filterData = Array.isArray(trainersList) ? trainersList : [];

  // Fetch member data by id
  useEffect(() => {
    if (memberId?.id) {
      dispatch(fetchMemberById({
        id: memberId.id,
        token: accessToken.access_token,
        tenant_id: tenantId,
      }));
    }
  }, [dispatch, memberId?.id, accessToken.access_token, tenantId]);

  // Prepopulate selectedTrainer if in edit mode and trainersList is loaded
  useEffect(() => {
    if (isEdit && memberId?.trainer_id && filterData.length > 0) {
      const selected = filterData.find(trainer => trainer.id === memberId.trainer_id);
      if (selected) {
        setSelectedTrainer([selected]);
      }
    }
  }, [isEdit, memberId?.trainer_id, filterData]);

  const handleTrainerSelect = async (selected) => {
    if (selected && selected.length > 0) {
      const selectedOption = selected[0];

      let formData = { ...memberId, trainer_id: selectedOption.id, updated_by: selectedBusiness?.pivot?.user_id };

      try {
        await dispatch(EditMemberById({
          id: memberId.id,
          data: formData,
          token: accessToken.access_token,
          tenant_id: tenantId,
        })).unwrap();

        setSelectedTrainer(selected); // Update UI with selected trainer
      } catch (error) {
        console.error("Error updating trainer:", error);
      }
    } else {
      // Reset the selected trainer
      handleChange({ target: { name: 'trainer_id', value: '' } });
      setSelectedTrainer([]);
    }
  };

  return (
    <>
      <ToastContainer />
      <Form.Group as={Col} lg={12}>
        <Form.Label>{i18n("Assign Trainer(optional)")}</Form.Label>
        <InputGroup>
          <Typeahead
            id={"party-typeahead"}
            ref={typeaheadRef}
            labelKey="name"
            options={filterData}
            placeholder={placeholderText("Search and assign trainer")}
            onChange={handleTrainerSelect}
            selected={selectedTrainer}
            filterBy={["name"]}
            renderMenu={(results, menuProps) => (
              <Menu {...menuProps}>
                {results.map((result, position) => (
                  <MenuItem key={position} option={result} position={position}>
                    {result.name}
                  </MenuItem>
                ))}
              </Menu>
            )}
          />

          {selectedTrainer.length > 0 && (
            <InputGroup.Text
              className="cursor-pointer fs-2"
              style={{ background: "none" }}
              onClick={() => {
                setSelectedTrainer([]);
                handleChange({ target: { name: 'trainer_id', value: '' } });
                typeaheadRef.current.clear(); // Clear the Typeahead input box
              }}
            >
              <FontAwesomeIcon
                icon={faSquareXmark}
                title="Clear"
                className="text-danger"
              />
            </InputGroup.Text>
          )}
        </InputGroup>
        <ErrorMessage
          name="product_id"
          component="span"
          className="text-danger d-block fw-400 fs-small mt-2"
        />
      </Form.Group>
    </>
  );
};

export default SubscriptionMemberBaseTrainer;

