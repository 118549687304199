import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";

const initialState = {
  parentProduct: [],
  childProduct: [],
  addonProduct: [],
  party: [],
  currentParty: {},
  currentProduct: {},
  currentChildProduct: {},
  currentAddonProduct: {},
  findProduct: false,
};

const getProductSlice = createSlice({
  name: "getProduct",
  initialState,
  reducers: {
    storeParty(state, action) {
      state.party = action.payload;
    },
    storeCurrentParty(state, action) {
      state.currentParty = action.payload.party;
    },
    storeCurrentProduct(state, action) {
      ;
      const { id, product } = action.payload;

      const currentparentData =
        product &&
        product.length > 0 &&
        product.filter((value) => value.id == id?.product_id);
      ;
      state.currentProduct = currentparentData;
    },
    storeCurrentChildProduct(state, action) {
      const { id, product } = action.payload;
      const planData =
        product &&
        product.length > 0 &&
        product.filter((value) => value.sub_type == "plan");

      const currentData =
        planData &&
        planData.length > 0 &&
        planData.filter(
          (value) => value.id == id?.subscription_items[0]?.product_id
        );
   
      ;
      const editPlan = {
        id: id?.subscription_items[0]?.product_id,
        name: id?.subscription_items[0]?.product?.name,
        code: id?.subscription_items[0]?.product_code,
        tax_code: id?.subscription_items[0]?.tax_code,
        sale_price: id?.subscription_items[0]?.price,
        quantity: id?.subscription_items[0]?.quantity,
        is_tax_included: id?.subscription_items[0]?.is_tax_included,
        item_discount: id?.subscription_items[0]?.item_discount,
        item_discount_amount: id?.subscription_items[0]?.item_discount_amount,
        cess: id?.subscription_items[0]?.cess,
        cess_rate: id?.subscription_items[0]?.cess_rate,
        plan: {
          free_trial_days: currentData[0].plan.free_trial_days,
          setup_fee: currentData[0].plan.setup_fee,
        },
      };
      ;
      state.currentChildProduct = editPlan;
    },

    storeCurrentAddonProduct(state, action) {
      const { id, product } = action.payload;
      const addonData =
        product &&
        product.length > 0 &&
        product.filter((value) => value.sub_type == "addon");
      const currentData =
        addonData &&
        addonData.length > 0 &&
        addonData.filter(
          (value) => value.id == id?.subscription_items[1]?.product_id
        );

      //;
      const editAddon = {
        id: id?.subscription_items[1]?.product_id,
        name: id?.subscription_items[1]?.product?.name,
        code: id?.subscription_items[1]?.product_code,
        tax_code: id?.subscription_items[1]?.tax_code,
        sale_price: id?.subscription_items[1]?.price,
        quantity: id?.subscription_items[1]?.quantity,
        is_tax_included: id?.subscription_items[1]?.is_tax_included,
        item_discount: id?.subscription_items[1]?.item_discount,
        item_discount_amount: id?.subscription_items[1]?.item_discount_amount,
        cess: id?.subscription_items[1]?.cess,
        cess_rate: id?.subscription_items[1]?.cess_rate,
      };
      //;

      state.currentAddonProduct = editAddon;
    },
    storeProduct(state, action) {
      ;
      const findProduct = action.payload;
      const getElement =
        findProduct &&
        findProduct.length > 0 &&
        findProduct.filter((value) => value.parent_product == null);
      state.parentProduct = getElement;
    },
    storeChildProduct(state, action) {
      const findProduct = action.payload.product;
      const id = action.payload.id;

      const getElement =
        findProduct &&
        findProduct.length > 0 &&
        findProduct.filter((value) => value.parent_product == null);

      const checkElement =
        getElement &&
        getElement.length > 0 &&
        getElement.filter((value) => value.id == id);

      const getChildproduct =
        checkElement &&
        checkElement.length >= 0 &&
        checkElement[0].child_products;

      const planData =
        getChildproduct?.length > 0 &&
        getChildproduct?.filter((item) => item.sub_type == "plan");

      const addonData =
        getChildproduct?.length > 0 &&
        getChildproduct?.filter((item) => item.sub_type == "addon");

      ;

      state.findProduct = true;
      state.childProduct = planData ? planData : [];
      state.addonProduct = addonData ? addonData : [];
    },
    initEditsubscription(state, action) {
      const findProduct = action.payload.parentProduct;
      const getElement =
        findProduct &&
        findProduct.length > 0 &&
        findProduct.filter((value) => value.parent_product == null);

      const getChildproduct =
        getElement && getElement.length > 0 && getElement.child_products;

      ;
      state.parentProduct = getElement;
      state.childProduct = getChildproduct;
      state.addonProduct = getChildproduct;
    },
    resetProductDropdown: () => initialState,
    resetAddon(state, action) {
      state.currentAddonProduct = {};
    },
  },
  extraReducers: (builder) => {},
})

export default getProductSlice.reducer;

export const {
  storeProduct,
  storeChildProduct,
  resetProductDropdown,
  initEditsubscription,
  storeParty,
  storeCurrentParty,
  storeCurrentProduct,
  storeCurrentChildProduct,
  storeCurrentAddonProduct,
  resetAddon,
} = getProductSlice.actions;
