import React, { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import { format } from "date-fns";
import {
  differenceInDays,
  addDays,
  addMonths,
  getMonth,
  getYear,
} from "date-fns";

const Chart = (props) => {
  const { reportItems, startDate, endDate } = props;

  const [labels, setLabels] = useState([]);
  const [values, setValues] = useState([]);

  useEffect(() => {
    const start = new Date(startDate);
    const end = new Date(endDate);

    const daysCount = differenceInDays(end, start) + 1;

    let tempList = [];

    if (daysCount <= 31) {
      for (let i = 0; i < daysCount; i++) {
        const currentDay = format(addDays(start, i), "yyyy-MM-dd");
        tempList.push({ label: currentDay, value: 0 });
      }
    } else if (daysCount > 31) {
      
      let currentMonth = getMonth(start);
      let currentYear = getYear(start);

      let tempStart = start;

      while (tempStart <= end) {
        const label = `${currentYear}-${String(currentMonth + 1).padStart(2, '0')}`;        
        tempList.push({ label: label, value: 0 });
        tempStart = addMonths(tempStart, 1);
        currentMonth = getMonth(tempStart);
        currentYear = getYear(tempStart);
      }
    }

    if (reportItems && reportItems?.getResult) {
      
      const updatedList = tempList.map((item) => {
        const matchingData = reportItems?.getResult?.find(
          (data) => data?.group_day === item?.label
        );

        if (matchingData) {
          return { ...item, value: matchingData.total_value };
        }
        return item;
      });

      if (daysCount <= 31) {
        const newLabels = updatedList.map((item) => {
          return format(new Date(item.label), "d MMM");
        });

        setLabels(newLabels);
      } else if (daysCount > 31) {
        const newLabels = updatedList.map((item) => {
          return format(new Date(item.label), "MMM");
        });

        setLabels(newLabels);
      }
      const newValues = updatedList.map((item) => item.value);      
      setValues(newValues);
    }
  }, [startDate, endDate, reportItems]);

  const [state, setState] = useState([]);
  const [option, setOption] = useState({});
  useEffect(() => {
    const formattedSalesAmount = values.map((amount) =>
      parseFloat(amount).toFixed(2)
    );

    setState([
      {
        name: "Sales Revenue",
        data: formattedSalesAmount,
      },
    ]);
  }, [values]);
  useEffect(() => {
    setOption({
      chart: {
        type: "bar",
        height: 350,
        toolbar: {
          show: false,
        },
      },
      colors: ["darkorange"],
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "55%",
          endingShape: "rounded",
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
        colors: "#25b97b",
      },
      xaxis: {
        categories: labels?.map((label) => label) || [],
        labels: {
            show: true,
            rotate: 0,
            trim: false,
            minHeight: undefined,
            maxHeight: 120,
            style: {
                fontSize: "12px",
            },
        },
    },
      yaxis: {
        title: {},
      },
      fill: {
        opacity: 1,
      },
      title: {
        style: {
          color: "#444",
          fontWeight: 600,
        },
      },
      tooltip: {
        y: {
          formatter: function (val, { seriesIndex, dataPointIndex, w }) {
            return "₹ " + val;
          },
        },
      },
    });
  }, [labels]);

  return (
    <>
      <ReactApexChart options={option} series={state} type="bar" height={240} />
    </>
  );
};

export default Chart;
