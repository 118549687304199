import { useSelector } from "react-redux";
import { Stack, Form, Image, Col } from "react-bootstrap";
import { i18n } from "../../shared/helpers/sharedMethods";
import { isEmpty } from "lodash";

const SignBlock = () => {

    const signature = useSelector((state) => state.setting?.billing_settings?.signature);


    return (
        <>
        {!isEmpty(signature) &&  <Stack direction='vertical ' className='my-3' gap={1}>
            <Form.Label style={{color:"#4682B4"}}> 
                <span className='bold'>{i18n('AUTHORIZED SIGNATURE')}</span>
            </Form.Label>
            <Image src={signature} className="mt-2" style={{ width: "175px", height: "100px" }}  />

        </Stack>
}
        </>
       

    );
}

export default SignBlock; 