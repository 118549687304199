import {
  createSlice,
  createAsyncThunk,
} from "@reduxjs/toolkit";
import { i18n } from "../../../shared/helpers/sharedMethods";

const initialState = {
  status: false,
  reportItems: [],
  error: false,
  errorMessage: {}
};

export const fetchNumOfSalesCountChart = createAsyncThunk('numOfSalesCountChart/fetch', async (param) => {
  try {
    const numOfSalesCountChartResponse = await window.api.numOfSalesCountChart(param);
    return numOfSalesCountChartResponse.data;
  } catch (err) {
    await window.log.logToFile({ message: "Error while fetching NumOf Sales count chart", level: "error" });
    throw new Error("Error while fetching NumOf Sales Count Chart")
  }
});

const numberOfSalesChartSlice = createSlice({
  name: "numOfSalesCountChart",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchNumOfSalesCountChart.fulfilled, (state, action) => {
        state.status = true;
        state.reportItems = action.payload;
      })
      .addCase(fetchNumOfSalesCountChart.rejected, (state, action) => {
        state.error = true;
        state.errorMessage = (i18n("Error while fetching NumOf Sales Count Chart. Please try again later."));
        state.status = false;
      })

  },
});

export default numberOfSalesChartSlice.reducer;
