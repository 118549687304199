import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCartShopping,
  faUsd,
  faBagShopping,
  faIndianRupeeSign,
  faArrowUp,
  faArrowDown,
  faExchange,
  faCreditCard,
  faFileInvoice,
  faPlus,
  faQuoteLeft,
  faStore,
  faNoteSticky,
  faShoppingBag,
  faArrowAltCircleDown,
} from "@fortawesome/free-solid-svg-icons";
import { selectAllSales, fetchTotalAmount } from "../store/voucherSlice";
import Layout from "../../shared/components/Layout";
import VoucherHeader from "./VoucherHeader";
import { Card, Col, Container, Form, Row } from "react-bootstrap";
import AverageSalesChartIndex from "./charts/average_sales_chart/AverageSalesChartIndex";
import SalesTaxChart from "./charts/tax_total_chart/SalesTaxChart";
import TotalInvoiceBlock from "./blocks/TotalInvoiceBlock";
import PaidBlock from "./blocks/PaidBlock";
import UnPaidBlock from "./blocks/UnPaidBlock";
import {
  fetchPurchaseOverviewBlocks,
  fetchSalesOverviewBlocks,
} from "../store/salesOverviewBlocksSlice";
import TopTenPurchasePartyIndex from "./charts/purchase_party_chart/TopTenPurchasePartyIndex";
import TopTenPurchaseItemIndex from "./charts/purchase_Item_chart/TopTenPurchaseItemIndex";
import NumberOfCountChart from "./charts/no_of_count_chart/NumberOfCountChart";
import ReturnBlock from "./blocks/ReturnBlock";
import CreditOrDebitNoteBlock from "./blocks/CreditOrDebitNoteBlock";
import SaleOrPurchaseInvoiceBlock from "./blocks/SaleOrPurchaseInvoiceBlock";
import PaymentInOrOutBlock from "./blocks/PaymentInOrOutBlock";
import QuotationOrOrderBlock from "./blocks/QuotationOrOrderBlock";
import AveragePurchaseChartIndex from "./charts/average_purchase_chart/AveragePurchaseChartIndex";
import Header from "../../shared/components/Header";
import HourlySalesChart from "./charts/hourly_sales_chart/HourlySalesChart";
import VoucherHighlightsBlock from "./blocks/VoucherHighlightsBlock";
import { i18n } from "../../shared/helpers/sharedMethods";

const Overview = (props) => {
  const { type } = props;
  const dispatch = useDispatch();

  const currencySymbol  = useSelector((state) => state.getSetting?.dataSetting.currency);
  ;

  useEffect(() => {
    if (type === "Sales") {
      dispatch(fetchSalesOverviewBlocks());
    } else {
      dispatch(fetchPurchaseOverviewBlocks());
    }
  }, [type]);
  const reportItems = useSelector(
    (state) => state.salesOverviewBlocks.reportItems
  );
  ;
  const purchaseReportItems = useSelector(
    (state) => state.salesOverviewBlocks.purchaseReportItems
  );
  ;
  // const salesTotalAmount = useSelector((state) => state.sales.totalAmountData);
  // const salesPaid = salesTotalAmount?.getSalesPaidAmount;
  // const salesUnpaid = salesTotalAmount?.getSalesUnPaidAmount;

  // const purchaseTotalAmount = useSelector((state) => state.sales.totalAmountData);
  // const purchasePaid = purchaseTotalAmount?.getPurchasePaidAmount;
  // const purchaseUnpaid = purchaseTotalAmount?.getPurchaseUnPaidAmount;

  // const overAllSales = salesTotalAmount?.overallSales;
  // const overAllPurchase = purchaseTotalAmount?.overallPurchase;

  if (type === "Sales") {
    return (
      <Layout>
        <Header currentTitle={i18n("Overview")} />
        <Container fluid className="px-5">
          <div className="border"
            style={{ background: "white", height: "95vh", overflow: "auto", overflowX: "hidden" }}
          >
            <VoucherHeader type="Sales" />
            <Row className="px-2 mx-1 mt-3 mb-1">
              <VoucherHighlightsBlock reportItems={reportItems} />
            </Row>

            <Row className="p-3 mt-1 mb-4">
              <Col xl={6} md={6} sm={12}>
                <AverageSalesChartIndex />
              </Col>
              <Col xl={6} md={6} sm={12}>
                <NumberOfCountChart type={type} />
              </Col>
              <Col xl={6} md={6} sm={12}>
                <HourlySalesChart />
              </Col>
              <Col xl={6} md={6} sm={12}>
                <SalesTaxChart />
              </Col>

            </Row>
          </div>
        </Container>
      </Layout>
    );
  } else {
    return (
      <Layout>
        <Header currentTitle={i18n("Overview")} />
        <Container fluid className="px-5">
          <div className="border"
            style={{ background: "white", height: "95vh", overflow: "auto", overflowX: "hidden" }}
          >
            <VoucherHeader type="purchase" />
            <Row className="px-2 mx-1 mt-3 mb-1">
              <VoucherHighlightsBlock reportItems={purchaseReportItems} />
            </Row>
            <Row className="p-3 mb-4">
              <Col xl={6} md={6} sm={12}>
                <TopTenPurchasePartyIndex />
              </Col>
              <Col xl={6} md={6} sm={12}>
                <NumberOfCountChart type={type} />
              </Col>
              <Col xl={6} md={6} sm={12}>
                <AveragePurchaseChartIndex />
              </Col>
              <Col xl={6} md={6} sm={12}>
                <TopTenPurchaseItemIndex />
              </Col>
            </Row>
          </div>
        </Container>
      </Layout>
    );
  }
};

export default Overview;
