import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { i18n, placeholderText, numValidate } from '../../shared/helpers/sharedMethods';
import { Form, Row, Modal, Button, Col } from 'react-bootstrap';
import { createInviteUser, resetStatusAndError, fetchInviteUser } from '../store/inviteUserSlice';
import { rolesList } from '../../shared/config/rolesConfig';
import { toast, ToastContainer } from "react-toastify";
import { fetchSetting } from "../../settings/store/getSettingSlice";


const InviteUser = (props) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { handleClose, show, featureNames } = props;
    const selectedBusiness = useSelector(
        (state) => state?.auth?.selectedBusiness
    );
    const tenantId = selectedBusiness?.pivot?.tenant_id;
    const currentUserId = selectedBusiness?.pivot?.user_id;
    const { accessToken } = useSelector((state) => state?.auth);
    const globalPlanFeatures =  useSelector(
          (state) => state?.globalPlan?.features
      );
  
    const filteredRoles = !globalPlanFeatures.includes(featureNames)
        ? rolesList.filter(role => [1, 2, 3].includes(role.id))
        : rolesList;

    const [user, setUser] = useState({
        name: '',
        phone: '',
        role_id: filteredRoles.length === 3 ? 3 : 4
    })

    const [errors, setErrors] = useState({
        name: '',
        phone: '',
        role_id: ''
    })
    const { status, error } = useSelector((state) => state.inviteUser);

    useEffect(() => {
        dispatch(
          fetchSetting({
            accessToken: accessToken.access_token,
            tenant_id: selectedBusiness?.pivot?.tenant_id,
          })
        );
      }, [status]);
    useEffect(() => {
        if (!status && error) {
            toast.error(error);
            dispatch(resetStatusAndError());
        }
    }, [status, error]);

    useEffect(() => {
        if (status) {
            dispatch(fetchInviteUser({
                tenant_id: tenantId,
                token: accessToken?.access_token,
                currentUserId: currentUserId
            }));
            toast.success("User invited successfully..!")
            handleClose();
            dispatch(resetStatusAndError());
        }
    }, [status]);

    useEffect(() => {
        const handleKeyDown = (event) => {
          if (event.key === "Enter") {
            event.preventDefault();
            document.getElementById("submitButton").click();
          }
        };
    
        document.addEventListener("keydown", handleKeyDown);
    
        return () => {
          document.removeEventListener("keydown", handleKeyDown);
        };
      }, []);
      
    const handleValidation = () => {
        let errorss = {};
        let isValid = false;

        if (!user['phone']) {
            errorss['phone'] = i18n('Please Enter Mobile Number')
        }

        else {
            isValid = true;
        }
        setErrors(errorss);
        return isValid;

    }
    const onChangeInput = (e) => {
        e.preventDefault();
        const { value } = e.target;

        setUser(inputs => ({ ...inputs, [e.target.name]: value && value }));
    };

    const onSubmit = () => {
        const valid = handleValidation();
        
        if (valid) {
            dispatch(createInviteUser(
                {
                    tenant_id: tenantId,
                    phone: user?.phone,
                    role_id: user?.role_id,
                    name: user?.name
                }
            ));
        }
    };
    const clearField = () => {
        handleClose()
    }
    return (
        <>
            <ToastContainer />
            <Modal show={show} dialogClassName='modal-dialog modal-dialog-centered mw-700px'
                onHide={handleClose}
                keyboard={true}
            >
                <Modal.Header closeButton>
                    <Modal.Title>{i18n("Add User")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Row>
                            <Form.Group className="">
                                <Form.Label>{i18n("Name")}</Form.Label>

                                <Form.Control
                                    autoFocus
                                    type='text'
                                    name='name'
                                    placeholder={placeholderText("Please Enter Name")}

                                    onChange={(e) => onChangeInput(e)}
                                    value={user.name}
                                />
                                <span
                                    className='text-danger d-block fw-400 fs-small mt-2'>{errors['name'] ? errors['name'] : null}</span>
                            </Form.Group>
                        </Row>
                        <Row>
                            <Form.Group className="">
                                <Form.Label className='required'>{i18n("Mobile Number")}</Form.Label>
                                <Form.Control
                                    type='text'
                                    name='phone'
                                    placeholder={placeholderText("Please Enter Mobile Number")}
                                    pattern="[0-9]*"
                                    min={0}
                                    maxLength="10"
                                    onKeyPress={(event) => numValidate(event)}
                                    onChange={(e) => onChangeInput(e)}
                                    value={user.phone}
                                />
                                <span
                                    className='text-danger d-block fw-400 fs-small mt-2'>{errors['phone'] ? errors['phone'] : null}</span>
                            </Form.Group>
                        </Row>



                        <Row className='row'>
                            <Form.Group className="">
                                <Form.Label>{i18n("Role")}</Form.Label>
                                <Form.Select
                                    className='form-control'
                                    aria-label="Payment-mode"
                                    name="role_id"
                                    onChange={(e) => {
                                        onChangeInput(e)
                                    }}
                                    value={user.role_id}
                                >
                                    {filteredRoles && filteredRoles.length ? (
                                        filteredRoles.map((role, index) => {
                                            return (
                                                <option key={index} value={role.id}>
                                                    {role.label}
                                                </option>
                                            );
                                        })
                                    ) : (
                                        <option value="">{placeholderText("No Role")}</option>
                                    )}
                                </Form.Select>
                            </Form.Group>
                        </Row>
                    </Form>
                </Modal.Body>

                <Col className='d-flex justify-content-end px-5 mb-5 mt-3 mx-2' style={{ columnGap: 10 }}>
                    <Button id="submitButton" onClick={onSubmit} className='btn btn-primary fw-bold' style={{ color: 'white' }}>{i18n("Add")}</Button>
                    <Button className='btn btn-secondary fw-bold' onClick={clearField} style={{ color: 'white' }}>{i18n("Cancel")}</Button>
                </Col>
            </Modal>
        </>
    )
};


export default InviteUser;