import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Card, Stack } from "react-bootstrap";
import { i18n } from "../../../shared/helpers/sharedMethods";
import { UilArrowGrowth, UilChartDown } from "@iconscout/react-unicons";
import GlobalCurrency from "../../../shared/components/GlobalCurrency";

const ProfitBlock = () => {
  const reportItems = useSelector((state) => state.profitBlock?.reportDatas);

  const [reportStatus, setReportStatus] = useState();
  useEffect(() => {
    let status;
    switch (true) {
      case reportItems?.netProfit < 0:
        status = "red";
        break;
      case reportItems?.netProfit >= 0:
        status = "green";
        break;
      // default:
      //   status = "black";
      //   break;
    }
    setReportStatus(status);
  }, [reportItems]);

  return (
    <>
      {reportStatus === "green" ? (
        <Card
          className="h-100 shadow-sm border"
          style={{ backgroundColor: "", lineHeight: "5px", minHeight: "155px" }}
        >
          <Card.Body className="d-flex justify-content-center text-start flex-column p-md-5 p-3">
            <span className="mb-3 mb-md-5 ">
              <UilArrowGrowth className="text-success" />
            </span>
            <span
              className="fs-responsive mb-8 mt-2"
              style={{ color: "GrayText" }}
            >
              {" "}
              {i18n("Profit")}
            </span>
            <Stack direction="vertical" gap={3}>
              <span className="fs-responsive-datas fw-bolder mb-3 mb-md-4">
                <GlobalCurrency price={reportItems?.netProfit} />
              </span>
            </Stack>
          </Card.Body>
        </Card>
      ) : (
        <Card
          className="h-100 shadow-sm border"
          style={{ backgroundColor: "", lineHeight: "5px", minHeight: "155px" }}
        >
          <Card.Body className="d-flex justify-content-center text-start flex-column p-md-5 p-3">
            <span className="mb-3 mb-md-5">
              <UilChartDown
                style={{ color: "#FF474C" }}
              />
            </span>
            <span
              className="fs-responsive mb-8 mt-2"
              style={{ color: "GrayText" }}
            >
              {" "}
              {i18n("Profit")}
            </span>
            <Stack direction="vertical" gap={3}>
              <span className="fs-responsive-datas fw-bolder mb-3 mb-md-4">
                <GlobalCurrency price={reportItems?.netProfit} />
              </span>
            </Stack>
          </Card.Body>
        </Card>
      )}
    </>
  );
};

export default ProfitBlock;
