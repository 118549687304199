import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useEffect } from "react";
import { i18n } from "../helpers/sharedMethods";
import { Container, OverlayTrigger, Stack, Tooltip } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { logout, resetAuth } from "../../auth/store/authSlice";
import { resetProduct } from "../../catalog/store/productsSlice";
import { resetSales } from "../../voucher/store/voucherSlice";
import { resetPayment } from "../../payment/store/paymentEntitySlice";
import { resetParty } from "../../party/store/getPartySlice";
import { resetDashboard } from "../../dashboard/store/LatestTransactionSlice";
import { resetSubscription } from "../../subscription/store/SubscriptionSlice";
import { resetCartVoucherPayment } from "../../payment/store/paymentSlice";
import { resetInitInstall } from "../../auth/store/initInstallSlice";
import { resetSubscriptionItem } from "../../subscription/store/subscription_item_store/GetPackagesItemSlice";
import { resetCategory } from "../../settings/store/categorysSlice";
import { resetSetting } from "../../settings/store/getSettingSlice";
import { resetSubscriptionVoucher } from "../../subscription/store/getAllSubscriptionVoucherSlice";
import { changeToggleShow } from "../store/sharedSlice";
import { UilCalendarAlt, UilBars } from "@iconscout/react-unicons";

const Header = ({ currentTitle, ItemActions, refresh }) => {
  const dispatch = useDispatch();

  const currentUser = useSelector((state) => state?.auth?.user);

  const selectedBusiness = useSelector(
    (state) => state?.auth?.selectedBusiness
  );
  const isMenuCollapse = useSelector((state) => state.sharedSlice.isToggleShow);
  ;

  const [show, setShow] = useState(false);
  const [open, setOpen] = useState(false);

  const handleSearch = () => {
    setShow(!show);
  };
  const handleClick = () => {
    setShow(!show);
    dispatch(resetAuth());
    dispatch(resetInitInstall());
  };
  const handleNotification = () => {
    setOpen(!open);
  };
  const menuClick = () => {
    dispatch(changeToggleShow(true));
  };

  const menuCancel = () => {
    dispatch(changeToggleShow(false));
  };

  const output = selectedBusiness?.business_name?.[0];

  const handleLogout = () => {
    dispatch(logout());
    dispatch(resetCategory());
    dispatch(resetProduct());
    dispatch(resetSales());
    dispatch(resetPayment());
    dispatch(resetAuth());
    dispatch(resetInitInstall());
    dispatch(resetCartVoucherPayment());
    dispatch(resetParty());
    dispatch(resetDashboard());
    dispatch(resetSubscription());
    dispatch(resetSetting());
    dispatch(resetSubscriptionVoucher());
    dispatch(resetSubscriptionItem());
  };

  const navigate = useNavigate();
  const toggle = <Tooltip id="button-tooltip">Toggle Sidebar</Tooltip>;
  return (
    <div
      className="mb-3 px-6"
      style={{
        height: "50px",
        borderBottom: "1px solid #ddd",
        background: "white",
      }}
    >
      <Stack direction="horizontal" className="d-flex justify-content-between px-0">
        <Stack direction="horizontal" className="d-flex justify-content-start align-items-center p-0">
          <OverlayTrigger placement="bottom" overlay={toggle}>
            <div>
              <UilBars
                size="18"
                className="cursor-pointer mt-1"
                onClick={isMenuCollapse ? menuCancel : menuClick}
              />
            </div>
          </OverlayTrigger>
          <h2 className="mt-3 bold mx-5">{currentTitle}</h2>
          {refresh}
        </Stack>

        <Stack direction="horizontal" className="">
          {ItemActions}
        </Stack>
      </Stack>

    </div>
  );
};

export default Header;
