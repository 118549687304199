import { i18n } from "../shared/helpers/sharedMethods"
import CreatePayment from "./screens/CreatePayment"
import EditPayment from "./screens/EditPayment"
import VoucherPaymentStack from "./screens/VoucherPaymentStack"



const rootPath ='voucher'


export const paymentRoutes = [

    {
        path:rootPath+'/payment-in/create',
        element:<CreatePayment title={i18n("Record Payment In")} type="payment_in" path='/voucher/payment-in'/>,
        isProtected:true
    },
    {
        path:rootPath+'/payment-in/edit/:id',
        element:<EditPayment title={i18n("Payment In")} type="payment_in" path='/voucher/payment-in'/>,
        isProtected:true
    },
    {
        path: rootPath+'/payment-out/create',
        element: <CreatePayment title={i18n("Record Payment Out")} type="payment_out" path='/voucher/payment-out'/>,
        isProtected: true
    },
    {
        path: rootPath+'/payment-out/edit/:id',
        element: <EditPayment title={i18n("Payment Out")} type="payment_out" path='/voucher/payment-out'/>,
        isProtected: true
    },
    {
        path: rootPath+ '/payment-in',
        element: <VoucherPaymentStack title={i18n("Payment In")} button={i18n("Create Payment In")} type="payment_in"  voucherType ="Sales" path={rootPath+ '/payment-in'}/>,
        isProtected: true
    },
    {
        path: rootPath+ '/payment-out',
        element: <VoucherPaymentStack title={i18n("Payment Out")} button={i18n("Create Payment Out")} type="payment_out" voucherType ="Purchase"  path={rootPath+ '/payment-out'} />,
        isProtected: true
    },


];
