import React, { useState, useEffect } from "react";
import { UilEdit, UilTrashAlt, UilCheck } from "@iconscout/react-unicons";
import { Form, Modal } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { format } from "date-fns";
import DataTable from "react-data-table-component";
import { i18n } from "../../shared/helpers/sharedMethods";
import { toast, ToastContainer } from "react-toastify";
import { deletePayment, editPaymentForSubscription, undoStatus, undoDelStatus } from "../store/CreateSubscriptionReceivedAmount";
import { getSubscriptionVoucher, selectSubscriptionVoucherById } from "../store/getAllSubscriptionVoucherSlice";
import LoadingSpinner from "../../shared/components/LoadingSpinner";

const PaymentModelSubscription = (props) => {
  const dispatch = useDispatch();

  const { Id } = props;
  const data = useSelector((state) =>
    selectSubscriptionVoucherById(state, Id)
  );

  const { updateAmountStatus, deleteAmountStatus} = useSelector((state) => state.subscriptionReceivedAmount)
  const selectedBusiness = useSelector((state) => state?.auth?.selectedBusiness);
  const accessToken = useSelector((state) => state?.auth?.accessToken?.access_token);
  const tenantId = selectedBusiness?.pivot?.tenant_id;

  const [submitId, setSubmitId] = useState(0);
  const [sumOfValue, setSumOfValue] = useState(0);
  const [payment, setPayment] = useState(data?.voucher_payments || []);
  const grand_total = data?.grand_total;
  const [loading, setLoading] = useState(false); 

  const handleEdit = (row) => {
    setSubmitId(row.id);
  };

  useEffect(() => {
    if (data?.voucher_payments) {
      setPayment(data.voucher_payments);
    }
  }, [data]);

  useEffect(() => {
    if (updateAmountStatus) {
      setSubmitId(0);
      dispatch(undoStatus());
    }
  }, [updateAmountStatus]);
  

  useEffect(() => {
    if (deleteAmountStatus) {
      dispatch(undoDelStatus());
    }
  }, [deleteAmountStatus]);
  
  
  const handleDelete = async (row) => {
    setLoading(true);
    await dispatch(deletePayment({
      data: {id : row?.payment_id},
      token: accessToken, 
      tenantId: tenantId}));
      await dispatch(
        getSubscriptionVoucher({
          id: data?.id,
          tenantId: tenantId,
          token: accessToken,
        })
      );
      setTimeout(() => {
        setLoading(false);
      }, 100);
  }
  useEffect(()=>{
    if(payment){
      const sum = payment.reduce((partialSum, a) => partialSum + a.amount, 0);
     
      setSumOfValue(sum)
    }

  },[payment])

  const handleAmountChange = (e, id) => {
    let { value, name } = e.target;
  
 
   
      setPayment((data) => {
        return data.map((amount) => {
          if (amount.id === id) {
            return {
              ...amount,
              amount: parseInt(value),
            };
          }

          return amount;
        });
      });
    
  };

  const handleSubmit = async (row, index) => {

    if(isNaN(payment[index].amount)){
      toast.error('please enter the amount');
      setLoading(false);
     }
     else if (payment[index].amount == 0) {
      toast.error('please enter the  valid amount');
      setLoading(false);
    }
     else if (sumOfValue > grand_total) {
        toast.error('Please enter a valid balance amount');
        setLoading(false);
        return;
      }
      if(sumOfValue <= grand_total) {
      try {    
        setLoading(true);

        await dispatch(editPaymentForSubscription({
          data: { id: row.id, voucher_id: row?.voucher_id, payment_id: row?.payment_id, amount: payment[index].amount },
          token: accessToken,
          tenantId: tenantId,
        }));
        
        await dispatch(
          getSubscriptionVoucher({
            id: data?.id,
            tenantId: tenantId,
            token: accessToken,
          })
        );
        
        toast.success("Payment updated successfully!");
        setTimeout(() => {
          setLoading(false);
        }, 100);
      } catch (error) {
        setLoading(false);
        console.error("Error submitting payment:", error);
        toast.error("Error updating payment.");
      }
      }
    }; 

    const handleKeyDown = (event, row, index) => {
      if (event.key === "Enter") {
      
        if (row.id == submitId) {
          handleSubmit(row, index);
        }

      }
    };

  const columns = [
    {
      id: "payment_date",
      name: i18n("DATE"),
      selector: (row) => format(new Date(row.created_at), "dd-MM-yyyy "),
      sortable: true,
    },
    {
      id: "payment_number",
      name: "Payment#",
      width: "120px",
      sortable: true,
      selector: (row) => row?.payment?.payment_number,
    },
    {
      id: "amount",
      name: "Amount",
      width: "120px",
      sortable: true,
      selector: (row, index) => (
        <Form.Control
          size="sm"
          type="number"
          value={payment[index]?.amount}
          readOnly={submitId !== row.id} 
          onChange={(e) => handleAmountChange(e, row.id)}
          onKeyDown={(event) => handleKeyDown(event, row, index)}
        />
      ),
    },
    {
      id: "actions",
      name: i18n("Actions"),
      cell: (row, index) => (
        <div className="d-flex justify-content-evenly align-items-center gap-4">
          {submitId === row.id ? (
            <UilCheck size={"20px"} onClick={() => handleSubmit(row, index)} />
          ) : (
            <UilEdit size={"20px"} onClick={() => handleEdit(row)} />
          )}
          <UilTrashAlt size={"20px"} onClick={() => handleDelete(row)} />
        </div>
      ),
    },
  ];

  return (
    <>
      <div className="col-md-12 mb-5">
      {loading && <LoadingSpinner />}
        <DataTable
          data={payment}
          columns={columns}
          paginationRowsPerPageOptions={[50, 100, 150, 200, 250]}
          paginationTotalRows={payment?.length}
          responsive
          highlightOnHover
          pointerOnHover
        />
      </div>
    </>
  );
};

export default PaymentModelSubscription;
