import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from "@reduxjs/toolkit";
import axios from "axios";
import { environment } from "../../shared/config/environment";

const initialState = {
  status: false,
  error: "",
  token: {},
  listOfTenant: {},
  userData: {},
  successMessage: "",
};

export const fetchAccountDetails = createAsyncThunk(
  "manageAccount/fetchToken",
  async (args, { rejectWithValue }) => {
    ;
    try {
      var config = {
        method: "GET",
        url: environment.URL + `authentication/get-authenticate/${args?.id}`,
        headers: {
          "Content-Type": "application/json",
        },
      };
      const response = await axios(config);
      ;
      return response.data;
    } catch (error) {
      ;
      await window.log.logToFile({message: "Error while fetching account details", level: "error" });
      return rejectWithValue(error);
    }
  }
);



const manageAccountSlice = createSlice({
  name: "manageAccount",
  initialState,
  reducers: {
    resetAccount: () => initialState,
  },
  extraReducers(builder) {
    builder
      .addCase(fetchAccountDetails.fulfilled, (state, action) => {
        state.status = true;
        state.userData = action.payload;
      })
      .addCase(fetchAccountDetails.rejected, (state, action) => {
        ;
        state.error = action.payload.message;
        state.userData = {};
      });
  },
});

export const { resetAccount } = manageAccountSlice.actions;

export default manageAccountSlice.reducer;
