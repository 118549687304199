import { Form } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { updateItemFieldSubscription } from "../../../../store/subscriptionProductEntitySlice";

const SubscriptionQuantity = ({ index, item }) => {
  const dispatch = useDispatch();

  ;

  return (
    <>
      <Form.Control
        style={{ textAlign: "end", border: "none" }}
        type="number"
        min={0}
        name="quantity"
        value={item?.quantity ? item?.quantity : ""}
        placeholder="0"
        onChange={(e) => {
          // handleChange(e);
          if (e.target.value <= 0) {
            e.target.value = 0;
          }
          let data = {
            fieldName: "quantity",
            fieldValue: parseFloat(e.target.value),
            index,
          };
          dispatch(updateItemFieldSubscription(data));
        }}
      />
    </>
  );
};

export default SubscriptionQuantity;
