import ReportList from "./screens/ReportList"
import BillWiseReport from "./screens/BillWiseReport"
import DayBookReport from "./screens/DayBookReport"
import ItemReportByParty from "./screens/ItemReportByParty"
import ItemSaleAndPurchaseSummary from "./screens/ItemSaleAndPurchaseSummary"
import PartyReportByItem from "./screens/PartyReportByItem"
import PartyWiseOutstanding from "./screens/PartyWiseOutstanding"
import ProfitAndLoss from "./screens/ProfitAndLoss"
import PurchaseSummary from "./screens/PurchaseSummary"
import RateListReport from "./screens/RateListReport"
import SalesSummary from "./screens/SalesSummary"
import StockSummary from "./screens/StockSummary"
import PartyLedgerReport from "./screens/PartyLedgerReport"
import GSTPurchaseReport from "./screens/GSTPurchaseReport"
import GSTSalesReport from "./screens/GSTSalesReport"
import HSNWiseSaleSummaryReport from "./screens/HSNWiseSaleSummary"
import StockDetailReport from "./screens/StockDetailReport"
import GSTR2PurchaseReport from "./screens/GSTR2PurchaseReport"
import GSTR1SalesReport from "./screens/GSTR1SalesReport"
import SaleSummaryCategoryWise from "./screens/SaleSummaryCategoryWise"
import ReceivableAgeingReport from "./screens/ReceivableAgeingReport"
import ProductsImport from "../settings/screens/ProductsImport"
import { gstreportRoutes } from "./routes/GstReportRoutes"
import { transactionreportRoutes } from "./routes/TransactionReportRoutes"
import { itemreportRoutes } from "./routes/ItemsReportRoutes"
import { partyreportRoutes } from "./routes/PartyReportRoutes"


const rootPath = 'reports'


export const reportRoutes = [
   {
      path: rootPath,
      element: <ReportList />,
      isProtected: true
   },

   ...gstreportRoutes,
   ...transactionreportRoutes,
   ...itemreportRoutes,
   ...partyreportRoutes

]