import { useEffect, useState } from "react";
import { FormControl } from "react-bootstrap";
import { placeholderText } from "../helpers/sharedMethods";

const GlobalSearch = (props) => {
  const { addFilteredData, setFilteredData, allData, filteredData } = props;
  ;
  const [searchString, setSearchString] = useState("");

  useEffect(() => {
    if (searchString.length == 0) {
      setFilteredData(allData);
    } else {
      setFilteredData(filteredData);
    }
  }, [searchString]);

  const handleSearch = (searchParam) => {
    const lowerCaseSearchParam = searchParam.toLowerCase();

    setSearchString(searchParam);
    const filtered = allData.filter(
      (datum) =>
        (datum.name &&
          datum.name.toLowerCase().includes(lowerCaseSearchParam)) ||
        (datum.party &&
          datum.party.toLowerCase?.().includes(lowerCaseSearchParam)) ||
        (datum.party?.name &&
          datum.party.name.toLowerCase().includes(lowerCaseSearchParam)) ||
        (datum.phone_number &&
          datum.phone_number.toLowerCase().includes(lowerCaseSearchParam)) ||
        (datum.party?.phone_number &&
          datum.party?.phone_number
            .toLowerCase()
            .includes(lowerCaseSearchParam)) ||
        (datum.voucher_number &&
          datum.voucher_number.toLowerCase().includes(lowerCaseSearchParam)) ||
        (datum.type &&
          datum.type.toLowerCase().includes(lowerCaseSearchParam)) ||
        (datum.voucher?.voucher_number &&
          datum.voucher?.voucher_number
            .toLowerCase()
            .includes(lowerCaseSearchParam)) ||
        (datum.gstin &&
          datum.gstin.toLowerCase().includes(lowerCaseSearchParam)) ||
        (datum.pincode &&
          datum.pincode.toLowerCase().includes(lowerCaseSearchParam)) ||
        (datum.code &&
          datum.code.toLowerCase().includes(lowerCaseSearchParam)) ||
        (datum?.voucher_number &&
          datum?.voucher_number.toLowerCase().includes(lowerCaseSearchParam)) ||
        (datum?.phone &&
          datum.phone.toLowerCase().includes(lowerCaseSearchParam)) ||
        (datum?.name &&
          datum?.name.toLowerCase().includes(lowerCaseSearchParam)) ||
          (datum?.message &&
            datum?.message.toLowerCase().includes(lowerCaseSearchParam)) ||
            (datum?.resource_type &&
              datum?.resource_type.toLowerCase().includes(lowerCaseSearchParam)) 
    );

    setFilteredData(filtered);
    addFilteredData(filtered);

    ;
  };

  return (
    <FormControl
      type="text"
      id="search"
      name="search"
      placeholder={placeholderText("Search")}
      onChange={(e) => {
        handleSearch(e.target.value);
      }}
      value={searchString}
      style={{ marginRight: "10px" }}
    />
  );
};
export default GlobalSearch;
