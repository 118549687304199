import { Col, Form, InputGroup } from "react-bootstrap";
import { useSelector } from "react-redux";
import { selectPartByType } from "../store/partyPaymentsSlice";
import { i18n } from "../../shared/helpers/sharedMethods";
import { selectVoucherPaymentById } from "../store/paymentSlice";
import GlobalCurrency from "../../shared/components/GlobalCurrency";

const BalanceAmount = () => {
  const { processPayment, selectedPayment } = useSelector(
    (state) => state.partyPayments
  );

  const totalBalanceAmount =
    selectedPayment?.reduce((sum, item) => {
      return sum + (parseFloat(item?.amount) || 0);
    }, 0) || 0;

  const balanceAmount = processPayment - totalBalanceAmount;

  return (
    <Col className="col-md-2 my-2">
      <Form.Group>
        <Form.Label
          style={{ color: "#838587", fontSize: "15px", color: "green" }}
          className="mb-2"
        >
          {i18n("Balance Amount :")}
        </Form.Label>
        <Form.Text
          className="mt-2 px-2"
          style={{ fontSize: "15px", color: "green" }}
        >
          <GlobalCurrency
            price={totalBalanceAmount ? balanceAmount : processPayment}
          />
        </Form.Text>
      </Form.Group>
    </Col>
  );
};
export default BalanceAmount;
