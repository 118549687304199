import React, { useEffect, useRef, useState } from "react";
import bill from "../../../assets/logo/logo6-small.png";
import {
  Row,
  Col,
  Button,
  Image,
  InputGroup,
  FormControl,
  Spinner,
  Form,
  Container,
  Card,
} from "react-bootstrap";
import "react-phone-input-2/lib/style.css";
import OTPInput from "react-otp-input";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import {
  createAppConfig,
  getTerminalNumber,
  resetAuth,
  setSelectedBusiness,
} from "../store/authSlice";
import { logout, otpVerify, register } from "../store/authSlice";
import { fetchDeviceId } from "../store/authSlice";
import {
  createTenantLocal,
  initInstall,
  resetInitInstall,
} from "../store/initInstallSlice";
import { i18n, placeholderText } from "../../shared/helpers/sharedMethods";
import packageJson from "../../../../package.json";
import { toast, ToastContainer } from "react-toastify";
import LoadingSpinner from '../../shared/components/LoadingSpinner';
import backgroundSvg from "../../../assets/logo/dweb-botton-vector.svg";


const RegisterScreen = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isOtpVerify, tenants, otpErrorMessage, error, status, otpStatus } = useSelector(
    (state) => state?.auth
  );
  ;
  const deviceId = useSelector((state) => state?.auth.deviceId);

  const { initInstallStatus, initInstallError, initInstallErrorMessage, terminalId, terminalCode, tenantLocalStatus } = useSelector(
    (state) => state?.initInstall
  );

  const configStatus = useSelector((state) => state?.auth?.createConfigStatus);
  const accessToken = useSelector(
    (state) => state?.auth?.accessToken?.access_token
  );

  const [getOtp, setGetOtp] = useState(false);
  const [otp, setOtp] = useState("");
  const [otpError, setOtpError] = useState("");
  const otpInputRef = useRef(null);
  const [phoneError, setPhoneError] = useState("");
  const [formData, setFormData] = useState({ phone: "" });
  const [loading, setLoading] = useState(false);
  const [verify, setVerify] = useState(false);
  const [timer, setTimer] = useState(0);
  const [canResend, setCanResend] = useState(false);
  const [success, setSuccess] = useState(false);

  console.log("otp ", otp);

  useEffect(() => {
    if (error) {
      toast.error(otpErrorMessage?.props?.id || error?.props?.id);
      setCanResend(true);
      //  setTimeout(() => {
      setGetOtp(false);
      setOtp("");
      setLoading(false);
      setVerify(false);

      dispatch(resetAuth());
      //  }, 1000);
    }
  }, [error]);

  useEffect(() => {
    dispatch(fetchDeviceId());
    // dispatch(getTerminalNumber())
  }, []);

  useEffect(() => {
    if (isOtpVerify == true) {
      if (tenants.length > 1) {
        setCanResend(false);
        navigate("/auth/multiple-tenant", { state: tenants });
      } else if (tenants.length > 0) {
        const terminalData = tenants[0];
        const tenantId = terminalData.pivot?.tenant_id;
        const checkTerminalData = {
          user_id: terminalData?.pivot?.user_id,
          location: terminalData?.business_address,
          device_id: deviceId,
        };
        dispatch(
          initInstall({
            tenant_id: tenantId,
            installData: checkTerminalData,
          })
        );

        dispatch(setSelectedBusiness(terminalData));
        navigate("/dashboard/sales");

      } else {
        dispatch(resetInitInstall());
        dispatch(resetAuth());
        setCanResend(true);
        navigate("/auth/create-business");
      }
    }
  }, [isOtpVerify]);

  useEffect(() => {
    if (initInstallStatus) {
      const terminalData = tenants[0];
      if (terminalData) {
        dispatch(
          createTenantLocal({
            businessId: terminalData?.business_id,
            userId: terminalData?.pivot?.user_id,
          })
        );
      }
    }
  }, [initInstallStatus]);

  useEffect(() => {
    if (tenantLocalStatus) {
      const terminalData = tenants[0];
      const tenantId = terminalData.pivot?.tenant_id;
      const user_id = terminalData.pivot?.user_id;
      const appConfigData = {
        terminal_id: terminalId,
        terminal_code: terminalCode,
        token: accessToken,
        tenant_id: tenantId,
        is_installed: 1,
        user: user_id,
        id: 1,
      };
      dispatch(createAppConfig(appConfigData));
    }

  }, [tenantLocalStatus]);

  useEffect(() => {
    if (initInstallError) {

      let toastErrorMsg = (initInstallErrorMessage && initInstallErrorMessage != '') ? initInstallErrorMessage : "Failed to login! Access might be forbidden!";
      toast.error(toastErrorMsg);
      dispatch(resetInitInstall());
      dispatch(resetAuth());
      setTimeout(() => {
        navigate(0)
      }, 1000);
    }
  }, [initInstallError])

  useEffect(() => {
    if (configStatus) {
      setCanResend(false);
      window.sync.startProcesses();
      navigate("/dashboard/sales");
    }
  }, [configStatus]);

  const handlePhoneInputChange = (event) => {
    const inputValue = event.target.value.replace(/\D/g, ""); // Replace any non-digit character with an empty string
    setFormData({ ...formData, phone: inputValue });
    setPhoneError("");
  };

  const handleGetOtp = () => {
    if (!formData.phone || formData.phone.length < 10) {
      setPhoneError(i18n("Please enter the phone number"));
      return;
    }
    setTimer(30);
    setGetOtp(true);
    setVerify(true);
    setCanResend(false);
    if (otpInputRef.current) {
      otpInputRef.current.focus();
    }
    dispatch(register(formData));

  };

  useEffect(() => {
    if (otpStatus == "sent" && timer) {
      setTimer(120);
      setCanResend(false);
    } else if (otpStatus == "verified" && timer) {
      setTimer(0);
      setCanResend(false);
    } else if (otpStatus == "error") {
      setTimer(0);
      setCanResend(true);
    }
  }, [otpStatus]);

  useEffect(() => {

    if (timer > 0) {
      const countdown = setTimeout(() => setTimer(timer - 1), 1000);
      return () => clearTimeout(countdown);
    } else if (timer === 0 && getOtp) {
      setCanResend(true);

    }


  }, [timer, getOtp]);

  const handleVerify = () => {
    if (!otp || otp.length < 6) {
      setOtpError(i18n("Please enter OTP"));
    } else {
      setLoading(true);
      setVerify(false);

      dispatch(otpVerify({ phone: formData.phone, otp: otp }));
    }
  };

  const handleOtpChange = (e) => {
    console.log("otp value", e.target.value)
    setOtp(e.target.value);
    // setOtpError("");
  };
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleGetOtp();
    }
  };
  
  const handleVerifyKeyPress = (event) => {
    if (otp.length > 5 && event.key === "Enter") {
      handleVerify();
    }
  };
  
  const handleKeyDown = (e) => {

    if (!/[0-9]/.test(e.key) && e.key !== 'Backspace' && e.key !== 'Delete') {
      e.preventDefault();
    }

    if (otp.length > 5 && e.key === 'Enter') {
      handleVerify();
    }
  };

  return (
    <>
      <ToastContainer />
      <Container fluid className="bg-white"
        style={{
          height: "calc(100vh - 10px)",
          overflowX: "hidden",
          overflowY: "auto",
          background: `url(${backgroundSvg})`,
          backgroundRepeat: "repeat",
          backgroundSize: "auto",
        }}>
        <div className="d-flex justify-content-center align-items-center " style={{ height: 'calc(100vh - 100px)' }}>
          <Card className="  " style={{
            width: '35%', boxShadow: "1.0px 3.0px 8.0px hsl(0deg 0% 0% / 0.38)",
            //borderRadius: "20px",
          }}>
            <Card.Header>
              <Col className="mb-5 d-flex justify-content-center col-lg-12">
                <Image src={bill} alt="Billplus+" style={{ height: "75px"}} />
              </Col>
            </Card.Header>
            <Card.Body className="">
              <Row>
                <Col md={1}></Col>
                <Col lg={10} >
                  <Form.Label className="" style={{ fontSize: '14px' }}>
                    {i18n("Enter your mobile number")}
                  </Form.Label>
                </Col>
                <Col md={1}></Col>
              </Row>
              <Row className="mt-1">
                <Col md={1}></Col>
                <Col lg={10} >
                  <InputGroup className="mb-3">
                    <InputGroup.Text id="phone-prefix">+91</InputGroup.Text>
                    <FormControl
                      type="text"
                      name="phone"
                      pattern="[0-9]*"
                      min={0}
                      maxLength={10}
                      placeholder={placeholderText("Please enter your mobile number")}
                      onChange={handlePhoneInputChange}
                      onKeyPress={handleKeyPress}
                      value={formData.phone}
                      autoFocus={true}
                    />
                  </InputGroup>
                  {phoneError && <div className="text-danger">{phoneError}</div>}
                </Col>
                <Col md={1}></Col>
              </Row>
              {!getOtp && (
                <Row className="mt-3">
                  <Col md={1}></Col>
                  <Col className="" lg={10}>
                    <Button className="w-50" onClick={handleGetOtp}>
                      {i18n("Get OTP")}
                    </Button>

                  </Col>
                  <Col md={1}></Col>
                </Row>)}
              {getOtp && (
                <Row className="mt-3">
                  <Col md={1}></Col>
                  <Col className="" lg={10} >
                    <Form.Label style={{ fontSize: '14px' }}>
                      {i18n("Enter OTP")}
                    </Form.Label>
                    <FormControl
                      type="text"
                      name="otp"
                      pattern="[0-9]*"
                      minLength={6}
                      maxLength={6}
                      ref={otpInputRef}
                      value={otp}
                      onChange={(e) => handleOtpChange(e)} autoFocus={true}
                      onKeyDown={handleKeyDown}
                      onKeyPress={handleVerifyKeyPress} />
                    {otpError && <div className="text-danger">{otpError}</div>}
                  </Col>
                  <Col md={1}></Col>
                </Row>)}

              {verify && (
                <Row className="mt-6">
                  <Col md={1}></Col>
                  <Col className="" lg={10}>
                    <Button className="w-50" onClick={handleVerify} >
                      {i18n("Verify")}
                    </Button>
                    {canResend && (<Button variant="link " onClick={handleGetOtp}>
                      Resend OTP
                    </Button>)}
                    {!canResend && (<Form.Label className="text-success mx-3" style={{fontSize:'14px'}}>
                      OTP sent
                    </Form.Label>)}
                    {!canResend && (<Form.Label className=" mx-3">
                      {timer} Sec
                    </Form.Label>)}
                  </Col>
                  <Col md={1}></Col>
                </Row>
              )}
              {loading && <LoadingSpinner />}
            </Card.Body>
            <Card.Footer>

              <div className="d-flex justify-content-center">
                <label className="form-label">{`V - ${packageJson.version}`}</label>
              </div>
            </Card.Footer>

          </Card>


        </div>
        <div className="d-flex justify-content-center">
          <Form.Label className="text-center " style={{ color: 'grey', fontSize: '12px' }}>
            By Clicking "Get OTP", You Agree to BillPlus User Agreement and Privacy Policy<br />
            we prioritize your privacy guiding you through innovative interations while<br />
            safeguarding your personal information.
          </Form.Label>
        </div>

      </Container>

    </>
  );
};

export default RegisterScreen;
