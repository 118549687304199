import React, { useEffect, useState } from "react";
import Layout from "../../shared/components/Layout";
import Header from "../../shared/components/Header";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Row,
  Stack,
  Spinner,
  Image,
} from "react-bootstrap";
import NavbarSetting from "./NavbarSettings";
import { useDispatch, useSelector } from "react-redux";
import {
  editSetting,
  initSettingEdit,
  resetError,
  resetStatus,
  resetTaxStatus,
  setTax,
  setDefaultTax,
  updateTaxes,
} from "../store/settingSlice";
import { Formik } from "formik";
import { cloneDeep } from "lodash";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router";
import ShortcutKeys from "../../shared/components/ShortcutKeys";
import { i18n } from "../../shared/helpers/sharedMethods";
import { fetchSetting } from "../store/getSettingSlice";
import { taxList } from "../../shared/config/taxConfig";
import { filterTaxList } from "../components/helpers/settingHelpers";
import SettingsLayout from "./SettingsLayout";
import LoadingSpinner from '../../shared/components/LoadingSpinner';
import { placeholderText } from "../../shared/helpers/sharedMethods";

const TaxSettings = () => {
  const selectedBusiness = useSelector(
    (state) => state?.auth?.selectedBusiness
  );
  ;

  const { accessToken } = useSelector((state) => state?.auth);

  const getSetting = useSelector((state) => state.getSetting?.dataSetting);
  const { billing_settings, error } = useSelector((state) => state.setting);
  ;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const getTax = JSON?.parse(getSetting?.tax);
  const TaxList = filterTaxList(taxList, getTax);

  const [selectedTax, setSelectedTax] = useState([...getTax]);
  const status = useSelector((state) => state.setting.status);
  const loading = useSelector((state) => state.getSetting.loading)
  const isOnline = navigator.onLine;

  useEffect(() => {
    dispatch(
      fetchSetting({
        accessToken: accessToken.access_token,
        tenant_id: selectedBusiness?.pivot?.tenant_id,
      })
    );
  }, [status]);

  useEffect(() => {
    if (getSetting) {
      dispatch(initSettingEdit(getSetting));
    }
  }, [getSetting]);
  useEffect(() => {
    if (getSetting) {
      const parsedTax = JSON.parse(getSetting.tax);
      const filteredTaxList = filterTaxList(taxList, parsedTax);
      setSelectedTax(filteredTaxList.map(tax => tax.code));
    }
  }, [getSetting]);

  useEffect(() => {
    if (status && isOnline) {
      toast.success("Tax Updated Successfully");
      dispatch(resetStatus());
    }
  }, [status, isOnline]);

  ;
  const handleCheckboxChange = (event) => {
    const { value, checked } = event.target;
    if (checked) {
      setSelectedTax([...selectedTax, value]);
    } else {
      setSelectedTax(selectedTax.filter((tax) => tax !== value));
    }
  };

  useEffect(() => {
    if (error) {
      toast.error(error?.props?.id);
      dispatch(resetError());
    }
  }, [error]);

  useEffect(() => {
    if (selectedTax) {
      dispatch(setTax(selectedTax));
    }
  }, [selectedTax]);

  const columns = [];
  for (let i = 0; i < taxList.length; i += 23) {
    columns.push(taxList.slice(i, i + 23));
  }

  const handleCancel = () => {
    navigate("/dashboard");
  };
  return (
    <>
      <Formik
        initialValues={billing_settings}
        enableReinitialize={true}
        onSubmit={async (values) => {
          try {
            if (selectedTax.length == 0) {
              toast.error(i18n("At least one tax must be selected"));
            } else {
              const formData = cloneDeep(values);
              ;
              formData.created_by = selectedBusiness?.pivot?.user_id;
              formData.tenant_id = selectedBusiness?.pivot?.tenant_id;
              formData.accessToken = accessToken.access_token;
              delete formData.id;
              
              if (!isOnline) {
                toast.error(i18n("You are Offline. Please Connect to the Internet"));
                return;
              }
              dispatch(editSetting(formData)).then((res) => {
                ;
                dispatch(
                  fetchSetting({
                    accessToken: accessToken.access_token,
                    tenant_id: selectedBusiness?.pivot?.tenant_id,
                  })
                );
              });
              dispatch(
                updateTaxes({
                  taxData: TaxList,
                  accessToken: accessToken.access_token,
                  tenant_id: selectedBusiness?.pivot?.tenant_id,
                })
              );
            }
          } catch (error) {
            toast.error("Error updating tax settings");
          }
        }}
      >
        {({ values, errors, handleChange, handleSubmit }) => (
          <>
            <ToastContainer />
            <SettingsLayout title="Tax"
              onSave={handleSubmit}
              onCancel={handleCancel}>
               {loading && <LoadingSpinner />}
              <Row className="mt-3">
                <Col lg={12}>
                  <Form.Group className="">
                    <Form.Label className="fs-2">
                      <span
                        className="pb-2"
                        style={{
                          borderBottom: "3px solid #1a237e",
                          fontWeight: 600,
                          fontSize: "15px",
                        }}
                      >
                        {i18n("Active Tax Options")}
                      </span>
                    </Form.Label>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md={3} className="mt-3">
                  <Form.Group controlId="exampleForm.ControlInput1">
                    <Form.Label> {i18n("Default Tax")}</Form.Label>

                    <Form.Select
                      aria-label="Payment-mode"
                      name="default_tax"
                      onChange={(e) => {
                        handleChange(e);
                        dispatch(setDefaultTax(e.target.value));
                      }}
                      value={values.default_tax}
                    >
                      {TaxList && TaxList.length ? (
                        TaxList.map((tax) => (
                          <option key={tax.code} value={tax.code}>
                            {tax.label}
                          </option>
                        ))
                      ) : (
                        <option value="">{placeholderText("No Tax options")}</option>
                      )}
                    </Form.Select>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col lg={12} style={{ height: "90vh", overflow: "auto" }}>
                  <Form className="mt-2">
                    <Row className="">
                      {columns.map((column, index) => (
                        <Col key={index} className="py-2">
                          {column.map((tax) => (
                            <Form.Check
                              className="py-2"
                              key={`${index}-${tax.code}`}
                              type="checkbox"
                              label={`${tax.label}`}
                              value={tax.code||""}
                              checked={selectedTax.includes(tax.code)}
                              onChange={handleCheckboxChange}
                            />
                          ))}
                        </Col>
                      ))}
                    </Row>
                  </Form>
                </Col>
              </Row>
            </SettingsLayout>
          </>
        )}
      </Formik>
    </>
  );
};
export default TaxSettings;
