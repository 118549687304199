import React, { useEffect } from "react";
import Form from "react-bootstrap/Form";
import { useSelector } from "react-redux";
import { Col, Row, Card, InputGroup } from "react-bootstrap";
import { changeParty } from "../store/PartyEntitySlice";
import { useDispatch } from "react-redux";
import Select from "react-select";
import { placeOfSupplyList } from "../../shared/config/placeOfSupply";
const BusinessDetails = (props) => {
  const {
    handleChange,
    i18n,
    placeholderText,
    setFieldValue,
    errors,
    values,
    type,
  } = props;
  const dispatch = useDispatch();
  const getSetting = useSelector((state) => state.getSetting?.dataSetting);
  const getTax = getSetting?.place_of_supply;
  const currency = useSelector((state) => state.getSetting?.dataSetting?.currency);
  const placeOfSupply = getTax
    ? placeOfSupplyList.find((item) => item.value == getTax)?.label || ""
    : "";


  useEffect(() => {
    if (type === "supplier") {
      dispatch(changeParty({ value: "supplier", name: "type" }));
    } else {
      dispatch(changeParty({ value: "customer", name: "type" }));
    }
  }, []);

  useEffect(() => {
    if (getTax) {
      const initialOption = placeOfSupplyList.find(option => option.value == getTax);
        setFieldValue("place_of_supply", initialOption.value);
        dispatch(changeParty({ value: initialOption.value, name: "place_of_supply" }));
    }
  }, []);

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      border: "1px solid #ccc",
      borderRadius: "4px",
      boxShadow: state.isFocused ? "0 0 7px rgb(77,144,254,0.5)" : null,
      height: "40px",
    }),
  };

  return (
    <Card className="px-4">
      <Row className="py-2">
        <Col lg={6}>
          {" "}
          <Form.Group>
            <Form.Label className="form-label-item">{i18n("GSTIN")}</Form.Label>
          </Form.Group>{" "}
          <Form.Control
            type="text"
            name="gstin"
            placeholder={placeholderText("Enter your GST Number")}
            className={`${
              errors["gstin"] ? "error-highlight" : "form-control"
            }`}
            onChange={(e) => {
              const { value, name } = e.target;
              handleChange(e);
              dispatch(changeParty({ value: value.toUpperCase(), name }));
            }}
            value={values.gstin}
            maxLength={16}
            pattern="^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[0-9]{1}[A-Z]{1}[0-9A-Z]{1}$"
          />
          {errors.gstin && (
            <span className="" style={{ color: "#EE5B50" }}>
              {errors.gstin}
            </span>
          )}
        </Col>
        <Col lg={6}>
          {" "}
          <Form.Group>
            <Form.Label className="form-label-item">
              {i18n("PAN Number")}
            </Form.Label>
          </Form.Group>{" "}
          <Form.Control
            type="text"
            name="pan"
            placeholder={placeholderText("Enter Party PAN Number")}
            className={`${errors["pan"] ? "error-highlight" : "form-control"}`}
            value={values.pan}
            minLength={10}
            maxLength={10}
            onChange={(e) => {
              const { value, name } = e.target;
              handleChange(e);
              dispatch(changeParty({ value: value.toUpperCase(), name }));
            }}
          />
          {errors.pan && (
            <span className="" style={{ color: "#EE5B50" }}>
              {errors.pan}
            </span>
          )}
        </Col>
      </Row>
      <Row className="py-2">
        <Col lg={6}>
          <Form.Group>
            <Form.Label>{i18n("Party Type")}</Form.Label>
            <span className="required" />
          </Form.Group>
          <Form.Select
            aria-label="type"
            name="type"
            value={values.party_type}
            onChange={(e) => {
              handleChange(e);
              const { value, name } = e.target;
              dispatch(changeParty({ value, name }));
            }}
          >
            {type == "supplier" ? (
              <>
                <option value="supplier">{placeholderText("Supplier")}</option>
                <option value="customer">{placeholderText("Customer")}</option>
              </>
            ) : (
              <>
                <option value="customer">{placeholderText("Customer")}</option>
                <option value="supplier">{placeholderText("Supplier")}</option>
              </>
            )}
          </Form.Select>
          <Form.Text className="text-danger d-block fw-400 fs-small mt-2">
            {errors["type"] ? errors["type"] : null}
          </Form.Text>
        </Col>

        <Col lg={6}>
          <Form.Group>
            <Form.Label>{"Opening Balance"}</Form.Label>
          </Form.Group>

          <InputGroup className="mb-3">
            <InputGroup.Text id="basic-addon1">{currency}</InputGroup.Text>
            <Form.Control
              type="number"
              name="opening_balance"
              value={
                values.opening_balance < 0
                  ? values.opening_balance * -1
                  : values.opening_balance
              }
              style={{ width: "150px" }}
              placeholder="Opening Balance "
              aria-label="Opening Balance"
              onChange={(e) => {
                handleChange(e);
                const { value, name } = e.target;
                dispatch(changeParty({ value, name }));
              }}
            />
            <Form.Select
              aria-label="type"
              name="ledger_type"
              value={values.ledger_type}
              onChange={(e) => {
                handleChange(e);
                const { value, name } = e.target;
                dispatch(changeParty({ value, name }));
              }}
            >
              <>
                <option value="toPay">{"To Pay"}</option>
                <option value="toCollect">{"To Collect"}</option>
              </>
            </Form.Select>
          </InputGroup>
          <Form.Text className="text-danger d-block fw-400 fs-small mt-2">
            {errors["type"] ? errors["type"] : null}
          </Form.Text>
        </Col>
        <Col lg={6}>
          {" "}
          <Form.Group>
            <Form.Label className="form-label-item">
              {i18n("Place Of Supply")}
            </Form.Label>
          </Form.Group>{" "}
          <Select
            name="place_of_supply"
            options={placeOfSupplyList}
            styles={customStyles}
            value={placeOfSupplyList.find(
              (option) => option.value == values.place_of_supply
            )}
            onChange={(selectedOption) => {
              setFieldValue("place_of_supply", selectedOption.value);
              dispatch(
                changeParty({
                  value: selectedOption.value,
                  name: "place_of_supply",
                })
              );
            }}
          />
        </Col>
      </Row>
    </Card>
  );
};

export default BusinessDetails;
