import { Form } from "react-bootstrap";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectAllSubscriptionData,selectSubscriptionDataById, SubscriptionData } from "../../store/SubscriptionSlice";
import { placeholderText } from "../../../shared/helpers/sharedMethods";

const SubscriptionFilter = (props) => {
    const dispatch = useDispatch();
    const accessToken = useSelector((state) => state?.auth?.accessToken?.access_token);
    const selectedBusiness = useSelector((state) => state?.auth?.selectedBusiness);
    const tenantId = selectedBusiness?.pivot?.tenant_id;

    const { subscriptionData, setSubscriptionData } = props;
    const filteredSubscription = useSelector(selectAllSubscriptionData);

    useEffect(() => {
        dispatch(
          SubscriptionData({
            token: accessToken,
            tenant_id: tenantId,
          })
        );
      }, [tenantId, dispatch]);

    return (
        <Form.Select
            className="rounded-0"
            value={subscriptionData}
            onChange={(e) => setSubscriptionData(e.target.value)}
            style={{ cursor: "pointer" }}
        >
            <option value="" disabled  hidden>
                {placeholderText("Select Subscription")}
            </option>
            <option value="">{placeholderText("All Subscription")}</option>
            {filteredSubscription && filteredSubscription.length > 0 ? (
                filteredSubscription.map((subscription) => (
                    
                    <option key={subscription.subscription_number} value={subscription.id}>
                        {subscription.subscription_number}
                    </option>
                ))
            ) : (
                <option disabled>{placeholderText("No Subscription available")}</option>
            )}
        </Form.Select>
    );
};

export default SubscriptionFilter;
