import React from "react";
import { Card, Stack } from "react-bootstrap";
import { useSelector } from "react-redux";
import Chart from "./Chart";
import { i18n } from "../../../shared/helpers/sharedMethods";
import { UilChartBar } from "@iconscout/react-unicons";
import GlobalCurrency from "../../../shared/components/GlobalCurrency";

function SalesSummaryChartIndex(props) {
  const { reportPeriod, startDate, endDate } = props;

  const reportItems = useSelector(
    (state) => state.saleSummaryChart?.reportItems || {}
  );
  const reportDatas = useSelector((state) => state.profitBlock?.reportDatas);
  const reportItemsOrder = useSelector(
    (state) => state.orderAndRevenue?.reportItems || []
  );

  const getResult = reportItems.getResult || []; // Default to an empty array

  return (
    <Card id="chart" className="border mb-3 shadow-sm">
      <Stack className=" mt-4 px-6 d-flex " gap={3} direction="horizontal">
        <Stack direction="vertical" gap={1}>
          <span className="fs-responsive mb-2" style={{ color: "GrayText" }}>
            {i18n("Revenue")}
          </span>
          <span
            className="fs-responsive-summary fw-bolder "
            style={{ color: "black" }}
          >
            <GlobalCurrency price={reportDatas.formattedResult?.revenue} />
          </span>
        </Stack>

        <Stack direction="vertical" gap={1}>
          <span className="fs-responsive mb-2" style={{ color: "GrayText" }}>
            {i18n("Orders")}
          </span>
          <span
            className="fs-responsive-summary fw-bolder "
            style={{ color: "black" }}
          >
            {reportItemsOrder.length > 0 ? (
              reportItemsOrder.map((value, index) => (
                <span key={index} className=" fs-3 fw-bolder text-black ">
                  {value?.order ? value.order : 0}
                </span>
              ))
            ) : (
              0
            )}
          </span>
        </Stack>
      </Stack>

      <Card.Body className="p-2">
        {getResult.length === 0 ? (
          <Stack
            className=" d-flex justify-content-center align-items-center "
            style={{ height: "253px" }}
          >
            <UilChartBar size="45" color="gray" />
            <p className="mt-2" style={{ fontSize: "12px", color: "gray" }}>
              {i18n("Please create your first transaction to see the insights.")}
            </p>
          </Stack>
        ) : (
          <Chart
            reportItems={reportItems}
            reportPeriod={reportPeriod}
            startDate={startDate}
            endDate={endDate}
          />
        )}
      </Card.Body>
    </Card>
  );
}

export default SalesSummaryChartIndex;