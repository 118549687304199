import {
  createSlice,
  createAsyncThunk,
} from "@reduxjs/toolkit";
import { i18n } from "../../shared/helpers/sharedMethods";
import { axiosRequest } from "../../shared/helpers/axiosHelper";

const initialState = {
  status: false,
  reportItems: [],
  openingBalance: 0, 
  error: false,
  errorMessage: '',
  loading:false,
};

export const fetchPartyLedgerReport = createAsyncThunk(
  "partyLedgerReport/fetch",
  async ({ token, tenant_id, startDate, endDate, partyData }) => {
    try {
      const partyLedgerReportResponse = await axiosRequest({
        method: "GET",
        url: `get-party-ledger-report`,
        params: { startDate, endDate, partyData },
        headers: { Authorization: `Bearer ${token}`, "X-Tenant": tenant_id },
      });
      return partyLedgerReportResponse.data;
    } catch (err) {
      await window.log.logToFile({ message: "Error while fetching Party Ledger", level: "error" });
      throw new Error("Error while fetching Party ledger report")
    }
  }
);

const partyLedgerReportSlice = createSlice({
  name: "partyLedgerReport",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
    .addCase(fetchPartyLedgerReport.pending, (state) => {
      state.loading = true;
    })
      .addCase(fetchPartyLedgerReport.fulfilled, (state, action) => {
        console.log('action',action.payload)
        state.status = true;
        state.loading = false;
        state.reportItems = action.payload.ledger;
        state.openingBalance = action.payload.openingBalance;  })
      .addCase(fetchPartyLedgerReport.rejected, (state, action) => {
        state.error = true;
        state.loading = false;
        state.errorMessage = (i18n("Unable to retrieve the party ledger report. Please try again later."));
        state.status = false;
      })
  },
});

export default partyLedgerReportSlice.reducer;
