import {
    createSlice,
    createAsyncThunk,
      } from '@reduxjs/toolkit';
import { i18n } from '../../shared/helpers/sharedMethods';

const initialState = {
  status:false,
  reportItems:{},
  error:false,
  errorMessage:{}
};

export const fetchPartyOverview = createAsyncThunk('partyOverview/fetch', async () => {
  try{
    const partyOverviewResponse  = await window.api.getPartyOverview();
    return partyOverviewResponse.data;
  }catch(err){
    await window.log.logToFile({message: "Error while fetching Party Overview", level: "error" });
    throw new Error("Error while fetching party Overview ")
  }
});

const partyOverviewSlice = createSlice({
    name: 'partyOverview',
    initialState,
    reducers: {
        resetDashboard : () => initialState,
    },
    extraReducers(builder) {
        builder
        .addCase(fetchPartyOverview.fulfilled, (state, action) => {
          state.status = true
          state.reportItems =action.payload
        })
        .addCase(fetchPartyOverview.rejected, (state, action) => {
            state.error = true; 
            state.errorMessage=(i18n("Error while fetching party Overview. Please try again later."));
            state.status = false;
        })
      
    }
});

export default partyOverviewSlice.reducer;


