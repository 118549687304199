import React, { useState, useEffect } from "react";
import { Button, Row, Col, Stack, Container, Image } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Layout from "../../shared/components/Layout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faDownload,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import { downloadCsvFile, handleExportClick } from "../../shared/export";
import DataGrid from "../../shared/components/DataGrid";
import { format } from "date-fns";
import ReportPeriod from "../../shared/components/ReportPeroid";
import Header from "../../shared/components/Header";
import { fetchStockDetailReport } from "../store/stockDetailReportSlice";
import ProductFilter from "../components/Filters/ProductFilter";
import ItemNavbar from "../components/ItemNavbar";
import { i18n } from "../../shared/helpers/sharedMethods";
import ShortcutKeys from "../../shared/components/ShortcutKeys";
import ReportDateRange from "../../shared/components/date_range/ReportDateRange";
import DatePicker from "../../shared/components/date_range/DatePicker";
import { CurrentWeekDateRange } from "../../shared/components/DateRangeYAxis";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import CustomeSortIcon from "../../shared/components/CustomeSortIcon";
import { UilImport } from "@iconscout/react-unicons";
import ReportHeader from "../components/ReportHeader";
import { getLocalizedDate } from '../../shared/helpers/localizationHelper';
import { getStockReport, selectAllStockReportData, resetStockData } from "../../catalog/store/stockReportSlice";
import LoadingSpinner from '../../shared/components/LoadingSpinner';
import { fetchProducts } from "../../catalog/store/productsSlice";

const StockDetailReport = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [reportPeriod, setReportPeriod] = useState("current-week");
  const [productData, setProductData] = useState("");
  const [csvData, setCsvData] = useState("");
  const [startDate, setStartDate] = useState(null); // State for start date
  const [endDate, setEndDate] = useState(null);
  const [sortField, setSortField] = useState(null);
  const [sortOrder, setSortOrder] = useState(1);

  const { accessToken } = useSelector((state) => state?.auth);
  const selectedBusiness = useSelector((state) => state?.auth?.selectedBusiness);
  const tenantId = selectedBusiness?.pivot?.tenant_id;


  const handleReset = () => {
    const salesDateLimits = ReportDateRange({
      reportPeriod: reportPeriod,
    });
    setStartDate(salesDateLimits.startDate);
    setEndDate(salesDateLimits.endDate);
    setReportPeriod("current-week");
    setProductData("");
  };

  useEffect(() => {
    dispatch(resetStockData());
    dispatch(getStockReport({ startDate: startDate, endDate: endDate, id: productData, token: accessToken.access_token, tenantId: tenantId }));
  }, [startDate, endDate, productData]);
  useEffect(() => {
    dispatch(fetchProducts({ token: accessToken.access_token, tenant_id: tenantId }));
  }, [accessToken, tenantId, dispatch]);

  useEffect(() => {
    const dateLimits = ReportDateRange({ reportPeriod });
    setStartDate(dateLimits.startDate);
    setEndDate(dateLimits.endDate);
  }, [reportPeriod]);

  const reportItems = useSelector(selectAllStockReportData);
  const status = useSelector((state) => state.stockDetailReport.status);
  const loading = useSelector((state) => state.stockDetailReport.loading);
  const currencySymbol = useSelector((state) => state.getSetting?.dataSetting.currency);
  ;

  const dateTimeString = new Date()
    .toLocaleString()
    .replace(/[-]/g, " ")
    .replace(/:/g, "-");
  const fileName = `Stock_detail_report_${dateTimeString}`;

  const handleExportButtonClick = () => {
    const exportData =
      reportItems &&
      Array.isArray(reportItems) &&
      reportItems.map((val) => ({
        "DATE": val.created_at ? val.created_at : "-",
        "TRANSACTION TYPE": val.type ? val.type : "Stock Adjustment",
        "QTY": val.quantity ? val.quantity : "-",
        "CLOSING STOCK": val.current_stock ? val.current_stock : "-",
        "NOTE": "-",

      }));
    handleExportClick(
      exportData,
      setCsvData,
      downloadCsvFile,
      fileName
    );
  };
  const customSort = (field) => {
    setSortField(field);
    setSortOrder(sortOrder === 1 ? -1 : 1);
  };
  const sortedData = [...reportItems].sort((a, b) => {
    if (sortField) {
      const compareValueA = a[sortField];
      const compareValueB = b[sortField];
      if (compareValueA < compareValueB) {
        return sortOrder;
      }
      if (compareValueA > compareValueB) {
        return -sortOrder;
      }
    }
    return 0;
  });
  const headerStyle = {
    backgroundColor: "white",
  };
  const headerCellStyle = {
    fontWeight: "bold",
    fontSize: "11px",
  };
  return (
    <Layout>
      {loading && <LoadingSpinner />}
      <Header
        currentTitle={i18n("Stock Detail Report")}
        ItemActions={
          <Stack gap={3} direction="horizontal" className="mt-1 mb-1 float-end">
            <ShortcutKeys />

          </Stack>
        }
      />
      <Container fluid className="px-5">
        <div className="border " style={{ background: "white" }}>
          <ItemNavbar />
          {/* {status ? (
                reportItems && reportItems.length > 0 ? (
                  <DataGrid
                    allData={reportItems}
                    data={reportItems}
                    columns={gridColumns}
                    isPaginated={true}
                    title="Report"
                  />
                ) : (
                  <Stack
                    direction="horizontal"
                    className="d-flex justify-content-center p-2 fs-3 fw-bolder"
                  >
                    <FontAwesomeIcon className="mx-2" icon={faSearch} />
                    {i18n("Select an Item first to see the reports")}
                  </Stack>
                )
              ) : (
                <p className="d-flex justify-content-center p-2 fs-3 fw-bolder">
                  {i18n("Error while fetching Stock Detail Report")}
                </p>
              )} */}
          <ReportHeader
            ItemActions={
              <Stack gap={2} direction="horizontal" className="pt-3 px-3 mb-3">
                <Stack direction="horizontal">
                  <DatePicker
                    startDate={startDate}
                    setStartDate={setStartDate}
                    endDate={endDate}
                    setEndDate={setEndDate}
                  />
                </Stack>
                <Stack className=" ">
                  <ReportPeriod
                    reportPeriod={reportPeriod}
                    setReportPeriod={setReportPeriod}
                  />
                </Stack>
                <Stack className=" ">
                  <ProductFilter
                    productData={productData}
                    setProductData={setProductData}
                    title="stock Detail"
                  />
                </Stack>
                <Stack
                  gap={1}
                  direction="horizontal"
                  style={{ marginLeft: "1px" }}
                >
                  <Button
                    className="rounded-0"
                    variant="outline-danger"
                    onClick={handleReset}
                  >
                     {i18n("Reset")}
                  </Button>
                </Stack>
                <Button
                  className="fw-bolder d-flex align-items-center rounded-0"
                  onClick={handleExportButtonClick}
                >
                  <UilImport
                    size="18"
                    className="mx-2"
                    style={{ cursor: "pointer" }}
                  />
                  {i18n("Export")}
                </Button>
              </Stack>
            }
          />
          <Container fluid className="px-3 mb-4">
            <div className="bordered-container">
              {productData && reportItems ? (
                reportItems && reportItems.length > 0 ? (
                  <DataTable
                    value={sortedData}
                    headerStyle={headerStyle}
                    paginator
                    rows={10}
                    dataKey="id"
                    emptyMessage="No Stock Report found."
                    className="p-datatable-gridlines full-width-table custom-datatable p-component"
                    scrollable
                    scrollHeight="400px"
                    sortMode="multiple"
                    removableSort
                  >
                    <Column
                      field="date"
                      header={() => (
                        <div
                          className={`custom-header-${sortField === "date"
                            ? sortOrder === 1
                              ? "up"
                              : "down"
                            : ""
                            }`}
                          onClick={() => customSort("date")}
                          style={{
                            cursor: "pointer",
                            display: "flex",
                            alignItems: "center",
                            fontSize: "11px",
                            lineHeight: "11px",
                          }}
                        >
                          {i18n("DATE")}
                          <CustomeSortIcon
                            sortOrder={sortField === "date" ? sortOrder : 0}
                            style={{
                              marginLeft: "4px",
                              verticalAlign: "middle",
                            }}
                          />
                        </div>
                      )}
                      body={(row) =>
                        row.created_at ? (
                          <span>{format(new Date(row.created_at), 'dd/MM/yyyy')}</span>
                        ) : (
                          "-"
                        )
                      }
                      sortable
                      headerStyle={headerCellStyle}
                    />
                    <Column
                      field="type"
                      header={() => (
                        <div
                          className={`custom-header-${sortField === "type"
                            ? sortOrder === 1
                              ? "up"
                              : "down"
                            : ""
                            }`}
                          onClick={() => customSort("type")}
                          style={{
                            cursor: "pointer",
                            display: "flex",
                            alignItems: "center",
                            fontSize: "11px",
                            lineHeight: "11px",
                          }}
                        >
                          {i18n("TRANSACTION TYPE")}
                          <CustomeSortIcon
                            sortOrder={sortField === "type" ? sortOrder : 0}
                            style={{
                              marginLeft: "4px",
                              verticalAlign: "middle",
                            }}
                          />
                        </div>
                      )}
                      body={(row) =>
                        row.type ?
                          row.type.replace(/_/g, " ")
                            .replace(/\b\w/g, (match) => match.toUpperCase())
                          : 'Stock Adjustment'
                      }
                      sortable
                      headerStyle={headerCellStyle}
                    />
                    <Column
                      field="quantity"
                      header={() => (
                        <div
                          className={`custom-header-${sortField === "qty"
                            ? sortOrder === 1
                              ? "up"
                              : "down"
                            : ""
                            }`}
                          onClick={() => customSort("qty")}
                          style={{
                            cursor: "pointer",
                            display: "flex",
                            alignItems: "center",
                            fontSize: "11px",
                            lineHeight: "11px",
                          }}
                        >
                          {i18n("Qty")}
                          <CustomeSortIcon
                            sortOrder={sortField === "qty" ? sortOrder : 0}
                            style={{
                              marginLeft: "4px",
                              verticalAlign: "middle",
                            }}
                          />
                        </div>
                      )}
                      body={(row) => (row.quantity ? <span>{row.quantity}</span> : "-")}
                      sortable
                      headerStyle={headerCellStyle}
                    />

                    <Column
                      field="stock"
                      header={() => (
                        <div
                          className={`custom-header-${sortField === "stock"
                            ? sortOrder === 1
                              ? "up"
                              : "down"
                            : ""
                            }`}
                          onClick={() => customSort("stock")}
                          style={{
                            cursor: "pointer",
                            display: "flex",
                            alignItems: "center",
                            fontSize: "11px",
                            lineHeight: "11px",
                          }}
                        >
                          {i18n("CLOSING STOCK")}
                          <CustomeSortIcon
                            sortOrder={sortField === "stock" ? sortOrder : 0}
                            style={{
                              marginLeft: "4px",
                              verticalAlign: "middle",
                            }}
                          />
                        </div>
                      )}
                      body={(row) =>
                        row.current_stock ? row.current_stock : '-'}
                      sortable
                      headerStyle={headerCellStyle}
                    />
                  </DataTable>
                ) : (
                  <Stack
                    direction="horizontal"
                    className="d-flex justify-content-center p-2 fs-3 fw-bolder"
                  >
                    <FontAwesomeIcon className="mx-2" icon={faSearch} />
                    {i18n("No reports found for the selected product ")}
                  </Stack>
                )
              ) : (
                <Stack
                  direction="horizontal"
                  className="d-flex justify-content-center p-2 fs-3 fw-bolder"
                >
                  <FontAwesomeIcon className="mx-2" icon={faSearch} />
                  {i18n("Select an Item first to see the reports")}
                </Stack>
              )}
            </div>
          </Container>
        </div>
      </Container>
    </Layout>
  );
};

export default StockDetailReport;
