import VoucherIndex from "../screens/VoucherIndex";
import CreateVoucher from "../screens/CreateVoucher";
import EditVoucher from "../screens/EditVoucher";
import VoucherDetail from "../components/VoucherDetails";
import { i18n } from "../../shared/helpers/sharedMethods";
import VoucherInvoiceView from "../components/VoucherInvoiceView";
import VoucherImport from "../screens/VoucherImport";

const rootPath = "voucher";

export const purchaseOrderRoutes = [
  {
    path: rootPath + "/purchase-order",
    element: (
      <VoucherIndex
        title={i18n("Purchase Order")}
        button={i18n("Create Purchase Order")}
        type="purchase_order"
        voucherType="Purchase"
        path={rootPath + "/purchase-order"}
      />
    ),
    isProtected: true,
  },
  {
    path: rootPath + "/purchase-order/create",
    element: (
      <CreateVoucher
        voucherType="Purchase"
        purchase={true}
        title={i18n("Create Purchase Order")}
        type="purchase_order"
        path={rootPath + "/purchase-order"}
        no="Purchase Order No"
        date="Purchase Order Date"
      />
    ),
    isProtected: true,
  },
  {
    path: rootPath + "/purchase-order/edit/:id",
    element: (
      <EditVoucher
        path={rootPath + "/purchase-order"}
        title={i18n("Edit Purchase Order")}
        type="purchase_order"
      />
    ),
    isProtected: true,
  },
  // {
  //   path: rootPath + "/purchase-order/view/:id",
  //   element: (
  //     <VoucherDetail
  //       path={rootPath + "/purchase-order"}
  //       button={i18n("Create Purchase Order")}
  //       type="purchase_order"
  //       title={i18n("Manage Purchase Order")}
  //     />
  //   ),
  //   isProtected: true,
  // },
  {
    path: rootPath + "/purchase-order/view/:id",
    element: (
      <VoucherInvoiceView
        path={rootPath + "/purchase-order"}
        button={i18n("Create Purchase Order")}
        type="purchase_order"
        title={i18n("Manage Purchase Order")}
      />
    ),
    isProtected: true,
  },
  {
    path: rootPath + "/purchase-order/import",
    element: (
      <VoucherImport
      path={rootPath + "/purchase-order"}
      type="purchase_order"
      title={i18n("Import Purchase Order")}
      voucherType="Sales"
      />
    ),
    isProtected: true,
  },
];
