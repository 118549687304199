import { Button, Card, Form } from 'react-bootstrap';
import { UilPadlock } from "@iconscout/react-unicons";
import Layout from './Layout';
import { i18n } from '../helpers/sharedMethods';
import { useNavigate } from 'react-router';


function NotinPlanCard({ noLayout }) {


    const navigate = useNavigate();

    const goToDashboard = () => {
        navigate('/');
    }

    return (
        <Card className="w-100" style={{ height: "100vh" }}>
            <div className="d-flex  justify-content-center align-items-center h-100 w-100">
                <div></div>
                <div style={{ textAlign: "center" }}>
                    <UilPadlock size="50" color="gray" />
                    <Form.Group>
                        <Form.Text
                            style={{ fontSize: "12px", color: "gray" }}
                        >
                            <h3 className="mt-5">Please upgrade to view this page..!</h3>
                            <br />
                            {!noLayout &&
                                <>
                                    <Button variant="outline-secondary" onClick={goToDashboard}>Home</Button>{' '}
                                </>
                            }

                        </Form.Text>
                    </Form.Group>
                </div>
            </div>
        </Card>
    )
}

function NotInPlanAlert({ noLayout }) {
    return (
        !noLayout ? (
            <Layout currentTitle={i18n("Feature locked")}>
                <NotinPlanCard noLayout={noLayout} />
            </Layout>
        ) : (
            <NotinPlanCard noLayout={noLayout} />
        )
    );
}

export default NotInPlanAlert;