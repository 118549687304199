import React, { useEffect, useState } from "react";
import Layout from "../../shared/components/Layout";
import Header from "../../shared/components/Header";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Row,
  Stack,
  Spinner,
  Image,
} from "react-bootstrap";
import NavbarSetting from "./NavbarSettings";
import { Unit } from "../../shared/config/Units";
import { DefaultUnits } from "../../shared/config/defaultUnits";
import { useDispatch, useSelector } from "react-redux";
import {
  editSetting,
  initSettingEdit,
  resetError,
  resetStatus,
  setSignature,
  setUnits,
} from "../store/settingSlice";
import { Formik } from "formik";
import { cloneDeep } from "lodash";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router";
import ShortcutKeys from "../../shared/components/ShortcutKeys";
import { i18n } from "../../shared/helpers/sharedMethods";
import { fetchSetting } from "../store/getSettingSlice";
import SettingsLayout from "./SettingsLayout";
import LoadingSpinner from '../../shared/components/LoadingSpinner';
const UnitSettings = () => {
  const selectedBusiness = useSelector(
    (state) => state?.auth?.selectedBusiness
  );
  ;

  const { accessToken } = useSelector((state) => state?.auth);

  const getSetting = useSelector((state) => state.getSetting?.dataSetting);
  const { billing_settings, error } = useSelector((state) => state.setting);
  ;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const getUnits = JSON.parse(getSetting?.units);

  const [selectedUnits, setSelectedUnits] = useState([...getUnits]);
  const status = useSelector((state) => state.setting.status);
  const loading = useSelector((state) => state.getSetting.loading);
  const isOnline = navigator.onLine;

  useEffect(() => {
    dispatch(
      fetchSetting({
        accessToken: accessToken.access_token,
        tenant_id: selectedBusiness?.pivot?.tenant_id,
      })
    );
  }, [status]);

  useEffect(() => {
    if (getSetting) {
      dispatch(initSettingEdit(getSetting));
      const getUnits = JSON.parse(getSetting?.units);
      setSelectedUnits(getUnits);
    }
  }, [getSetting, dispatch]);

  useEffect(() => {
    if (status && isOnline) {
      toast.success("Units Updated Successfully");

      dispatch(resetStatus());
    }
  }, [status, isOnline]);
  ;
  const handleCheckboxChange = (event) => {
    const { value, checked } = event.target;
    if (checked) {
      setSelectedUnits([...selectedUnits, value]);
    } else {
      setSelectedUnits(selectedUnits.filter((unit) => unit !== value));
    }
  };

  useEffect(() => {
    if (error) {
      toast.error(error?.props?.id);
      dispatch(resetError());
    }
  }, [error]);

  useEffect(() => {
    if (selectedUnits) {
      dispatch(setUnits(selectedUnits));
    }
  }, [selectedUnits]);

  const columns = [];
  for (let i = 0; i < Unit.length; i += 23) {
    columns.push(Unit.slice(i, i + 23));
  }

  const handleCancel = () => {
    navigate("/dashboard/sales");
  };

  return (
    <>
      <Formik
        initialValues={billing_settings}
        enableReinitialize={true}
        onSubmit={async (values) => {
          try {
            if (selectedUnits.length == 0) {
              toast.error(i18n("At least one unit must be selected"));
            } else {
              const formData = cloneDeep(values);
              ;
              formData.created_by = selectedBusiness?.pivot?.user_id;
              formData.tenant_id = selectedBusiness?.pivot?.tenant_id;
              formData.accessToken = accessToken.access_token;
              delete formData.id;

              if (!isOnline) {
                toast.error(i18n("You are Offline. Please Connect to the Internet"));
                return;
              }
              dispatch(editSetting(formData)).then((res) => {
                ;
                dispatch(
                  fetchSetting({
                    accessToken: accessToken.access_token,
                    tenant_id: selectedBusiness?.pivot?.tenant_id,
                  })
                );
              });
              ;
            }
          } catch (error) {
          }
        }}
      >
        {({ values, errors, handleChange, handleSubmit }) => (
          <>
            <ToastContainer />
            <SettingsLayout title="Units"
              onSave={handleSubmit}
              onCancel={handleCancel}>
               {loading && <LoadingSpinner />}
              <Row>
                <Col lg={12} style={{ height: "90vh", overflow: "auto" }}>
                  <Form className="mt-2">
                    <Row className="">
                      {columns.map((column, index) => (
                        <Col key={index} className="py-2">
                          {column.map((unit) => (
                            <Form.Check
                              className="py-2"
                              key={`${index}-${unit.value}`}
                              type="checkbox"
                              label={`${unit.value} - ${unit.label}`}
                              value={unit.value}
                              checked={selectedUnits.includes(unit.value)}
                              onChange={handleCheckboxChange}
                            />
                          ))}
                        </Col>
                      ))}
                    </Row>
                  </Form>
                </Col>
              </Row>
            </SettingsLayout>
          </>
        )}
      </Formik>
    </>
  );
};
export default UnitSettings;
