import React from "react";
import CustomerDetails from "./overviewSubscription/CustomerDetail";
import OverViewDetails from "./overviewSubscription/OverviewDetails";
import { Col, Row } from "react-bootstrap";
import SubscriptionDetails from "./overviewSubscription/SubscriptionDetails";
import PlanProductDetails from "./overviewSubscription/PlanProductDetails";
import AddonProductDetails from "./overviewSubscription/AddonProductDetails";
import SetupFeesDetails from "./overviewSubscription/SetupFeesDetails";

const OverViewSubscription = ({ data }) => {
  return (
    <>
      <Row className="px-2">
        <Col lg={10} className="p-2 mt-2">
          <SubscriptionDetails data={data} />
        </Col>
        <Col
          lg={10}
          className="p-2 mt-2"
          //style={{ borderRight: "1px solid lightgray" }}
        >
          <CustomerDetails data={data} />
        </Col>

        {/* <Col lg={6} className="p-1">
          <OverViewDetails data={data} />
        </Col> */}
        {data?.subscription_items?.map((item, index) => (
          <Col lg={4} className="p-2 mt-2" key={index}>
            {item.type === "plan" ? (
              <PlanProductDetails data={item} />
            ) : item.type === "setup_fee" ? (
              <SetupFeesDetails data={item} />
            ) : item.type === "addon" ? (
              <AddonProductDetails data={item} />
            ) : null}
          </Col>
        ))}
      </Row>
    </>
  );
};

export default OverViewSubscription;
