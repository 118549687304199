import { Badge, Card, Col, Form, Row } from "react-bootstrap";
import { i18n } from "../../../../shared/helpers/sharedMethods";

const SubsAttendanceDetails = ({ data }) => {

  const Subscriptions = new Set(data?.map(item => item.subscription_id));
  const TotalSubscription = Subscriptions.size || 0;
  
  const Attendance = new Set(data?.map(item => item.id));
  const TotalAttendance = Attendance.size || 0;

  const totalHours = data?.reduce((acc, item) => acc + (item.hours || 0), 0);

  const avgHours = TotalAttendance > 0 ? (totalHours / TotalAttendance) : 0;

  return (
    <Card className="border px-3" style={{ borderRadius: "5px" }}>
      <Row
        className=" mb-3"
        style={{
          borderBottom: "1px solid lightgray",
          backgroundColor: "#f8f9fa",
          borderTopLeftRadius: "5px",
          borderTopRightRadius: "5px",
        }}
      >
        <Col lg={6} className="py-2">
          <Form.Label
            className="mb-0"
            style={{ fontWeight: 600, fontSize: "13px" }}
          >
            {" "}
            {i18n("Summary")}
          </Form.Label>
        </Col>
      </Row>
      <Row>
        <Col lg={6}>
          {" "}
          <Form.Label
            className=""
            style={{ fontWeight: 500, fontSize: "12px" }}
          >
            {" "}
            {i18n("Entries")}
          </Form.Label>
        </Col>
        <Col lg={6}>
          <Form.Label
            className=""
            style={{ fontWeight: 400, fontSize: "12px" }}
          >
            {TotalAttendance}
          </Form.Label>
        </Col>
      </Row>

      <Row>
        <Col lg={6}>
          <Form.Label
            className=""
            style={{ fontWeight: 500, fontSize: "12px" }}
          >
            {i18n("Hours")}
          </Form.Label>
        </Col>
        <Col lg={6}>
          <Form.Label
            className=""
            style={{ fontWeight: 400, fontSize: "12px" }}
          >
            {totalHours}
          </Form.Label>
        </Col>
      </Row>

      <Row>
        <Col lg={6}>
          {" "}
          <Form.Label
            className=""
            style={{ fontWeight: 500, fontSize: "12px" }}
          >
            {" "}
            {i18n("Avg Hours")}
          </Form.Label>
        </Col>
        <Col lg={6}>
          <Form.Label
            className=""
            style={{ fontWeight: 400, fontSize: "12px" }}
          >
            {avgHours}
          </Form.Label>
        </Col>
      </Row>
    </Card>
  );
};

export default SubsAttendanceDetails;
