import { Col, Form, FormControl, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { ErrorMessage, Field } from "formik";
import { i18n,placeholderText} from "../../../../shared/helpers/sharedMethods";
import { changePlanInterval, changePlanIntervalUnit } from "../../../store/subscription_item_store/PlanEntitySlice";

const BillEvery = ({ values, handleChange, touched, errors,setFieldValue }) => {
  const dispatch = useDispatch();

  const billOptions = [
    {
      value: "day",
      label:placeholderText("Day(s)"),
    },
    {
      value: "week",
      label: placeholderText("Week(s)"),
    },
    {
      value: "month",
      label: placeholderText("Month(s)"),
    },
    {
      value: "year",
      label: placeholderText("Year(s)"),
    },
  ];

  return (
    <Row className="py-4">
      <Col lg={3} className="">
        <Form.Group>
          <Form.Label className="form-text-font">
            {i18n("Bill Every")}{" "}
          </Form.Label>
          <span className="required" />
        </Form.Group>
      </Col>
      <Col lg={4}>
        <Row>
          <Col lg={5}>
            <FormControl
              type="number"
              name="interval"
              value={values.interval||''}
              onChange={(e) => {
                handleChange(e);
                dispatch(changePlanInterval(e.target.value));
              }}
              className={` ${
                touched.interval && errors.interval ? "is-invalid" : ""
              }`}
            />
          </Col>
          <Col lg={7}>
            <Field name="interval_unit">
              {({ field, form }) => (
                <Form.Select
                  {...field}
                  onChange={(e) => {
                    const value = e.target.value;
                    form.setFieldValue("interval_unit", value);
                    dispatch(changePlanIntervalUnit(value));
                  }}
                >
                  {billOptions.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </Form.Select>
              )}
            </Field>
          </Col>
          <ErrorMessage
            name="interval"
            component="span"
            className="text-danger d-block fw-400 fs-small mt-2"
          />
        </Row>
      </Col>
    </Row>
  );
};

export default BillEvery;
