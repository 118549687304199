import React from 'react';
import './css/LoadingSpinner.css'; 

const LoadingSpinner = () => (
  <div className="loading-spinner-container">
    <div className="loading-spinner">
    </div>
  </div>
);

export default LoadingSpinner;
