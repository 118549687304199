import { ErrorMessage } from "formik";
import React, { useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import SubscriptionPartyTypeAhead from "./components/fields/SubscriptionPartyTypeAhead";
import SubscriptionMemberAhead from "./components/fields/SubscriptionMemberAhead";
import SubscriptionMemberBaseTrainer from "./components/fields/SubscriptionMemberBaseTrainer";


const CustomerDetailsForm = (props) => {
  const { touched, errors, handleChange, values, setFieldValue, isEdit } =
    props;
    const [partyData, setPartyData] = useState("");
    const [memberId, setMemberId] = useState("");

  return (
    <>
      <Card >
        <Row className="py-3">
          <Col md={3}>
            <SubscriptionPartyTypeAhead isEdit={isEdit} errors={errors}
            setPartyData={setPartyData} />
          </Col>
          <Col md={3}>
            <SubscriptionMemberAhead isEdit={isEdit} errors={errors}
            partyData={partyData}
            setMemberId={setMemberId}
            handleChange={handleChange} />
          </Col>
        <Col lg={3}>
          <SubscriptionMemberBaseTrainer isEdit={isEdit} errors={errors}
            memberId={memberId}
            handleChange={handleChange} />
        </Col>
          
        </Row>
      </Card>
    </>
  );
};

export default CustomerDetailsForm;
