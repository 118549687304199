import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { i18n } from '../../shared/helpers/sharedMethods';

const CancelSubscription = ({ handleClose, show, setShow }) => {

  const handleCancel = () => {
    setShow(false);
    handleClose();
  };

  return (
    <Modal show={show}  style={{ zIndex: 2500 }} dialogClassName="modal-dialog modal-dialog-centered mw-700px " onHide={() => setShow(false)}>
        <div className="" style={{borderRadius:'5px',boxShadow: '0 0 500px 500px rgba(0, 0, 0, 0.3)'}}>
      <Modal.Header closeButton>
        <Modal.Title>{i18n('Cancel Subscription')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {i18n('Are you sure you want to cancel this subscription?')}
      </Modal.Body>
      <Modal.Footer>
      <Button variant="danger" onClick={handleCancel}>
          {i18n('Cancel')}
        </Button>
        <Button variant="secondary" onClick={() => setShow(false)}>
          {i18n('Close')}
        </Button>
      </Modal.Footer>
      </div>
    </Modal>
  );
};

export default CancelSubscription;
