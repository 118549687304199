import React from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { i18n } from "../helpers/sharedMethods";

const LogoutModal = ({onCancel,onConfirm,syncCount }) => {
  return (
    <SweetAlert
    title=""
      custom
      confirmBtnBsStyle="danger mb-3 fs-5 rounded"
      cancelBtnBsStyle="secondary mb-3 fs-5 rounded text-white"
      confirmBtnText={i18n("Logout")}
      cancelBtnText={i18n("No, Cancel")}
      onConfirm={onConfirm}
      onCancel={onCancel}
      showCancel
      focusCancelBtn
    >
      <span className="sweet-text">
     {syncCount>0?'Sync data is still bending Are you sure you want to force logout?':i18n("Are you sure you want to log out?")}   
      </span>
    </SweetAlert>
  );
};

export default LogoutModal;
