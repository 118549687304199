import React, { useState, useEffect } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Chart from "./Chart";
import { fetchAvgSalesChart } from "../../../store/sales_chart/averageSaleChartSlice";
import SummaryReportPeriod from "../../../../shared/components/SummaryReportPeriod";

function AverageSalesChartIndex() {
  const dispatch = useDispatch();
 
  const [reportPeriod, setReportPeriod] = useState("current-week");

  useEffect(() => {
    dispatch(fetchAvgSalesChart(reportPeriod));
  }, [reportPeriod]);
  const reportItems = useSelector((state) => state.avgSalesChart.reportItems);

  return (
    <Card id="chart" className="border mb-3">
      <Card.Header className="border-0 pt-0"></Card.Header>
      <Card.Body className="py-0">
        <Row className="row px-0">
          <Col xl={9} md={9} sm={9}>
            <Chart 
             reportItems= {reportItems} 
             reportPeriod={reportPeriod} 
            />
          </Col>
          <Col xl={3} md={3} sm={3} className="d-flex justify-content-end">
            <Col >

            <SummaryReportPeriod
            reportPeriod={reportPeriod}
            setReportPeriod={setReportPeriod}
          />

            </Col>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
}

export default AverageSalesChartIndex;
