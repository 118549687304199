import {
  Button,
  Col,
  Form,
  InputGroup,
  ListGroup,
  Row,
  Stack,
} from "react-bootstrap";
import { i18n } from "../../../shared/helpers/sharedMethods";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { faSquareXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { updateTnc } from "../../store/voucherEntitySlice";

const TermsAndCondition = () => {
  const termsAndCondition = useSelector(
    (state) => state.setting?.billing_settings?.terms_and_conditions
  );

  const tnc = useSelector((state) => state?.voucherEntity?.tnc);
  ;
  const dispatch = useDispatch();

  const [show, setShow] = useState(false);

  const handleShow = () => {
    setShow(true);
    dispatch(updateTnc(termsAndCondition ? termsAndCondition : tnc));
  };

  const handleCancel = () => {
    setShow(false);
  };

  return (
    <>
      {!show && (
        <div
          className="d-flex justify-content-center p-2 my-3 cursor-pointer"
          style={{ border: "1px dashed lightgray" }}
        >
          <Button variant="link" onClick={handleShow}>
             {i18n("+ Add Terms and Conditions")}
          </Button>
        </div>
      )}
      {show && (
        <Stack direction="vertical" className="my-3" gap={3} as={Col} lg={12}>
          <Row>
            <Col lg={10}>
              <Form.Label>
                <span
                  className="bold pb-2"
                  style={{ borderBottom: "3px solid #1a237e", color:"#4682B4" }}
                >
                  {i18n("TERMS AND CONDITIONS")}
                </span>
              </Form.Label>
            </Col>
            <Col lg={1}>
              <InputGroup.Text
                className="cursor-pointer fs-2 py-0"
                style={{ background: "none", border: "none" }}
                onClick={handleCancel}
              >
                <FontAwesomeIcon
                  icon={faSquareXmark}
                  title="Clear Item"
                  className="text-danger px-6"
                />
              </InputGroup.Text>
            </Col>
          </Row>

          <Form.Control
            as="textarea"
            type="text"
            name="terms_and_condition"
            placeholder=""
            value={tnc}
            onChange={(e) => {
              ;
              dispatch(updateTnc(e.target.value));
            }}
          />
        </Stack>
      )}
    </>
  );
};
export default TermsAndCondition;
