import React from 'react';
import { Container, ListGroup } from 'react-bootstrap';
import {  i18n } from '../../../../../shared/helpers/sharedMethods';
import { useSelector } from 'react-redux';

const BillDetails = () => {
   
    const { subtotal, taxable_value, tax_total, cgst_total, sgst_total, total_quantity, grand_total, charges  } = useSelector(state => state.posOrder)

    return (
        <Container className='p-0 mb-5'>
            <ListGroup className='rounded-1'>
                <ListGroup.Item className='list-detail-header'>{i18n("Bill Details")}</ListGroup.Item>
               
                <ListGroup.Item className='list-detail-row bill-mid-row'>{i18n('Sub Total')} :
                    <span className='float-end'>
                        {subtotal}
                    </span>
                </ListGroup.Item>
               
                <ListGroup.Item className='list-detail-row bill-mid-row'>{i18n('Tax')} :
                    <span className='float-end'>
                        {tax_total}
                    </span>
                </ListGroup.Item>
                {charges.charge_value && (charges.charge_value > 0) ?
                <ListGroup.Item className='list-detail-row bill-mid-row'> {charges.charge_type} :
                    <span className='float-end'>
                        {charges.charge_value}
                    </span>
                </ListGroup.Item>
                : <></>
                }
                <ListGroup.Item className='bill-detail-footer'>
                    {i18n('Total')} :
                    <span className='float-end'>
                        {grand_total}
                    </span>
                </ListGroup.Item>
            </ListGroup>
        </Container>
    )
}

export default BillDetails;