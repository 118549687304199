import React from "react";
import { Card, Stack } from "react-bootstrap";
import { useSelector } from "react-redux";
import Chart from "./Chart";
import { i18n } from "../../../shared/helpers/sharedMethods";
import { UilChartLine, UilSquare } from "@iconscout/react-unicons";

function SubscriptionSummaryChart(props) {
  const { reportPeriod, startDate, endDate } = props;

  const reportItems = useSelector(
    (state) => state.subscriptionDashboard?.savedEntity
  );

  return (
    <Card id="chart" className="border shadow-sm">
      <Stack className=" mt-4 px-6 d-flex " gap={3} direction="horizontal">
        <Stack direction="vertical" gap={1}>
          <span
            className="fs-responsive text-start mb-2"
            style={{ color: "GrayText" }}
          >
            <UilSquare
              className="px-1"
              style={{ color: "#90dfbe", fontSize: "1.2rem" }}
            />
            {i18n("Signups")}
          </span>
          <span
            className="fs-responsive-summary fw-bolder px-6"
            style={{ color: "black" }}
          >
            {" "}
            {reportItems?.totalSignUps ? reportItems?.totalSignUps : "0"}
          </span>
        </Stack>

        <Stack direction="vertical" gap={1}>
          <span className="fs-responsive mb-2" style={{ color: "GrayText" }}>
            <UilSquare
              className="px-1"
              style={{ color: "#f9b9b9", fontSize: "1.2rem" }}
            />
            {i18n("Cancellations")}
          </span>
          <span
            className="fs-responsive-summary fw-bolder px-6"
            style={{ color: "black" }}
          >
            {" "}
            {reportItems?.totalDeactivation
              ? reportItems?.totalDeactivation
              : "0"}
          </span>
        </Stack>

        <Stack direction="vertical" gap={1}>
          <span className="fs-responsive mb-2" style={{ color: "GrayText" }}>
            <UilSquare
              className="px-1"
              style={{ color: "#8FDDE7", fontSize: "1.2rem" }}
            />
            {i18n("Reactivations")}
          </span>
          <span
            className="fs-responsive-summary fw-bolder px-6"
            style={{ color: "black" }}
          >
            {reportItems?.reactivation ? reportItems?.reactivation : "0"}
          </span>
        </Stack>
      </Stack>
      <Card.Body className="p-2">
        {!reportItems?.subscriptionSummary?.length > 0 && (
          <Stack
            className=" d-flex justify-content-center align-items-center "
            style={{ height: "253px" }}
          >
            <UilChartLine size="45" color="gray" />
            <p className="mt-2" style={{ fontSize: "12px", color: "gray" }}>
              {i18n("No subscription insights yet.")}
            </p>
          </Stack>
        )}
        {reportItems?.subscriptionSummary?.length > 0 && (
          <Chart
            reportItems={reportItems}
            reportPeriod={reportPeriod}
            startDate={startDate}
            endDate={endDate}
          />
        )}
      </Card.Body>
    </Card>
  );
}

export default SubscriptionSummaryChart;
