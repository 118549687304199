import React from "react";
import { Row, Col, Card, Stack, Button } from "react-bootstrap";
import ItemTabs from "./sections/ItemTabs";
import ItemHeader from "./sections/ItemHeader";
import TwoStageLayoutHeader from "../TwoStatgeLayoutHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare, faTrash } from "@fortawesome/free-solid-svg-icons";

const ItemDetailLayout = ({
  itemStack,
  type,
  tabs,
  ItemTitle,
  ItemActions,
  returnToGrid,
  print,
  pdf,
  summary,
  header,
}) => {
  //;

  return (
    <Row className=" px-1"  >
      {/* <ItemHeader
        ItemTitle={ItemTitle}
        ItemActions={ItemActions}
        returnToGrid ={returnToGrid}
      /> */}

      <Col lg={3} md={3} xs={3}>
        <Card className="border " style={{ borderRadius: "5px" }}>
          {itemStack}
        </Card>
      </Col>
      <Col className="border " lg={9} md={9} xs={8} style={{ background : "white", height:"90vh", overflow: "auto",  overflowX: "hidden", }}>
        {header}
        {summary}
        <ItemTabs print={print} pdf={pdf} tabs={tabs} type={type} title={"Invoice History"}/>
      </Col>
    </Row>
  );
};

export default ItemDetailLayout;
