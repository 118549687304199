import { Col, Form, Row } from "react-bootstrap";
import { useErrorBoundary } from "react-error-boundary";
import { UilExclamationTriangle } from '@iconscout/react-unicons'

function ErrorFallback({ error }) {
    const { resetBoundary } = useErrorBoundary();

    return (
        <div role="alert" className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>

            <div className="py-10 ">
                <Row className="">
                    <Col lg={12} >
                        <UilExclamationTriangle size="135" color="#EE5B50" className=" " />
                    </Col>
                </Row>
            </div>
            <div className="p-10 ">
                <Row className="mb-2">
                    <Col lg={12} >
                        <Form.Label className="fs-1" style={{ color: '#EE5B50' }}>App Crashed</Form.Label>
                    </Col>
                </Row>
                <Row>
                    <Col lg={12} >
                        <Form.Label className="fs-4" style={{fontWeight:500}}>Please try the following</Form.Label>
                    </Col>
                </Row>
                <Row>
                    <Col lg={12}>
                        <ul className="fs-4" style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
                            <li className="mb-2">Please try clearing app data from Settings menu</li>
                            <li className="mb-2">Uninstalling and Reinstalling the app</li>
                            <li className="mb-2">Contacting support</li>
                        </ul>
                    </Col>
                </Row>
            </div>
        </div>
    );
}

export default ErrorFallback;