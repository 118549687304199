import React from "react";
import { Route, Navigate, Routes } from "react-router-dom";
import { merge } from "lodash";
import { useSelector } from "react-redux";
import ProtectedRoute from "./ProtectedRoute";
//import module routes
import { authRoutes } from "./modules/auth/AuthRoutes";
import { posRoutes } from "./modules/pos/posRoutes";
import { dashboardRoutes } from "./modules/dashboard/DashboardRoutes";
import { voucherRoutes } from "./modules/voucher/VoucherRoutes";
import { catalogRoutes } from "./modules/catalog/catalogRoutes";
import { partyRoutes } from "./modules/party/PartyRoutes";
import { settingRouter } from "./modules/settings/settingRouter";
import { reportRoutes } from "./modules/reports/reportsRouter";
import { paymentRoutes } from "./modules/payment/paymentRoutes";
import { EnquiryRoute } from "./modules/enquiry/EnquiryRoutes";
import { AccountRoutes } from "./modules/manageAccount/AccountRoutes";
import { PackageRoutes } from "./modules/subscription/routes/packageRoutes";
import { SubscriptionRoute } from "./modules/subscription/SubscriptionRoutes";
import { sharedRouter } from "./modules/shared/sharedRoutes";

const AppRouter = () => {
  //const { isLoggedIn, currentUser } = useSelector(state => state.auth)

  //const { getAppConfig,isLoggedIn } = useSelector((state) => state?.appConfig)
  //
  const { accessToken, isLoggedIn } = useSelector((state) => state?.auth);
  const permissions = useSelector((state) => state?.initInstall?.permissions);

  // 

  const protectedRoutes = [
    ...dashboardRoutes,
    ...posRoutes,
    ...voucherRoutes,
    ...catalogRoutes,
    ...partyRoutes,
    ...settingRouter,
    ...reportRoutes,
    ...partyRoutes,
    ...paymentRoutes,
    ...SubscriptionRoute,
    ...EnquiryRoute,
    ...AccountRoutes,
    ...sharedRouter
  ];
  // 
  // 

  const installRoutes = [...authRoutes];

  return (
    <Routes>
      {
        //unprotected or auth routes
        installRoutes.map((route, index) => {
          return route.element ? (
            <Route
              key={index}
              exact={true}
              path={route.path}
              element={route.element}
            />
          ) : null;
        })
      }
      {protectedRoutes.map((route, index) => {
        return route.element ? (
          <Route
            key={index}
            exact={true}
            path={route.path}
            element={
              route.isProtected ? (
                <ProtectedRoute
                  isLoggedIn={isLoggedIn}
                  permissions={permissions}
                >
                  {route.element}
                </ProtectedRoute>
              ) : (
                <Navigate replace to={"auth/register"} />
              )
            }
          />
        ) : null;
      })}
      <Route
        path="/"
        exact={true}
        element={
          <Navigate
            replace
            to={
              isLoggedIn &&
              accessToken?.access_token &&
              accessToken?.tenants.length > 0 
                ? "dashboard/sales"
                : "auth/register"
            }
          />
        }
      />
      <Route path="*" element={<Navigate replace to={"/"} />} />
    </Routes>
  );
};

export default AppRouter;
