import React, { useEffect } from "react";
import { fetchProducts } from "../../catalog/store/productsSlice";
import { useDispatch, useSelector } from "react-redux";
import { fetchsales, getAllVoucher } from "../store/voucherSlice";
import { fetchparty } from "../../party/store/getPartySlice";
import TabLayout from "../../shared/components/item-detail/TabLayout";
import { getPayment } from "../../payment/store/paymentSlice";
import { i18n } from "../../shared/helpers/sharedMethods";
import LoadingSpinner from '../../shared/components/LoadingSpinner';
import {Image} from "react-bootstrap";
const VoucherHeader = ({ type }) => {
  ;

  const dispatch = useDispatch();
  const importData = useSelector((state) => state.voucher.importData);
  const loading = useSelector((state)=> state.payment.loading);
  useEffect(() => {
    ;
    //dispatch(getAllVoucher());
    dispatch(fetchProducts());
    dispatch(fetchparty());
    dispatch(getPayment());
  }, []);
  useEffect(() => {
    dispatch(getAllVoucher());
  }, [importData]);
  const SaleTabs = [
    // {
    //   key: 'sales_overview',
    //   title: (i18n('Overview')),

    // },
    // {
    //   key: 'all_sales',
    //   title: (i18n('All Sales')),

    // },
    {
      key: "sales-invoice",
      title: i18n("Sales Invoice"),
    },
    {
      key: "payment-in",
      title: i18n("Payment In"),
    },
    {
      key: "sales-return",
      title: i18n("Sales Return"),
    },
    {
      key: "quotation",
      title: i18n("Quotation"),
    },
    {
      key: "credit-note",
      title: i18n("Credit Note"),
    },
    {
      key: "proforma-invoice",
      title: i18n("Proforma Invoice"),
    },
  ];
  const PurchaseTabs = [
    //  {
    //   key: 'purchase_overview',
    //   title: (i18n('Overview')),
    // },
    // {
    //   key: 'all_purchase',
    //   title: (i18n('All Purchase')),

    // },
    {
      key: "purchase-order",
      title: i18n("Purchase Order"),
    },
    {
      key: "purchase-invoice",
      title: i18n("Purchase"),
    },
    {
      key: "payment-out",
      title: i18n("Payment Out"),
    },
    {
      key: "purchase-return",
      title: i18n("Purchase Return"),
    },
    {
      key: "debit-note",
      title: i18n("Debit Note"),
    },
  ];
  ;

  return (
    <>
      {loading && <LoadingSpinner />}
      {type == "Sales" ? (
        <TabLayout type="voucher" tabs={SaleTabs} route="voucher" title="Sales" />
      ) : (
        <TabLayout
          type="voucher"
          tabs={PurchaseTabs}
          route="voucher"
          title="Purchase"
        />
      )}
    </>
  );
};

export default VoucherHeader;
