import React, { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import { i18n } from "../../../../shared/helpers/sharedMethods";

const Chart = (props) => {
  const { labelName, salesAmount } = props;

  // Default state to handle invalid data
  const [chartData, setChartData] = useState({
    series: [],
    options: {
      chart: {
        type: "donut",
      },
      labels: [],
      colors: ['#8FDDE7', '#EEB5EB', '#FFC2C7', '#60A3D9', '#FBE5C8'],
      responsive: [
        {
          breakpoint: 1920,
          options: {
            chart: {
              width: 220,
              height: 250,
            },
          },
        },
        {
          breakpoint: 1440,
          options: {
            chart: {
              width: 130,
              height: 170,
            },
          },
        },
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 100,
              height: 120,
            },
            legend: {
              position: "top",
            },
          },
        },
      ],
      dataLabels: {
        enabled: false, // Hide labels
      },
      legend: {
        show: false,
      },
    },
  });

  // Only update chart data when inputs are valid
  const isValidData =
    Array.isArray(salesAmount) &&
    Array.isArray(labelName) &&
    salesAmount.length === labelName.length;

  useEffect(() => {
    if (isValidData) {
      const parsedSalesAmount = salesAmount.map((amount) =>
        parseFloat(amount)
      );
      setChartData((prevChartData) => ({
        ...prevChartData,
        series: parsedSalesAmount,
        options: {
          ...prevChartData.options,
          labels: labelName,
        },
      }));
    }
  }, [labelName, salesAmount, isValidData]);

  // Render error if data is invalid
  if (!isValidData) {
    return (
      <div>
        {i18n("Error")} : {i18n("Invalid data")}
      </div>
    );
  }

  // Render chart when data is valid
  return (
    <>
      <ReactApexChart
        options={chartData.options}
        series={chartData.series}
        type="donut"
      />
    </>
  );
};

export default Chart;
