import { createSlice, createAsyncThunk, createEntityAdapter,
    createSelector, } from "@reduxjs/toolkit";
import { axiosRequest } from "../../shared/helpers/axiosHelper";

const productDeletedAdapter = createEntityAdapter();

const initialState = productDeletedAdapter.getInitialState({
    status: false,
    error: null,
});

export const getAllDeletedProduct = createAsyncThunk(
    'ProductDeletedRecords/fetch',
    async ({ token, tenantId }) => {
        try {
            const response = await axiosRequest({
                method: "GET",
                url: 'catalog/deleted/products',
                headers: {
                    Authorization: `Bearer ${token}`,
                    "X-Tenant": tenantId,
                },
            });
            console.log('Fetched deleted product:', response.data);
            return response.data;
        } catch (error) {
            await window.log.logToFile({ message: "Error while fetching all deleted products", level: "error" });
            throw new Error('Error fetching deleted products');
        }
    }
);

const productDeletedSlice = createSlice({
    name: "ProductDeletedRecords",
    initialState,
    reducers: {
        resetDeletedProduct: () => initialState,
    },
    extraReducers: (builder) => {
        builder
            .addCase(getAllDeletedProduct.pending, (state) => {
                state.status = false;
                state.error = null;
            })
            .addCase(getAllDeletedProduct.fulfilled, (state, action) => {
                state.status = true;
                productDeletedAdapter.upsertMany(state, action.payload);
            })
            .addCase(getAllDeletedProduct.rejected, (state, action) => {
                state.status = false;
                state.error = action.error.message;
            });
    },
});

export const deletedProductRestore = createAsyncThunk(
    'ProductDeletedRecords/recreate',
    async ({ id, token, tenantId }) => {
        try {
            const response = await axiosRequest({
                method: "POST",
                url: `catalog/restore/products/${id}`,
                headers: {
                    Authorization: `Bearer ${token}`,
                    "X-Tenant": tenantId,
                },
            });
            return response.data;
        } catch (error) {
            await window.log.logToFile({ message: "Error while restoring deleted product", level: "error" });
            throw new Error('Error restoring product');
        }
    }
);
  
export const deleteProductPermanently = createAsyncThunk(
    'ProductDeletedRecords/permanatdelete',
    async ({ id, token, tenantId }) => {
        try {
            const response = await axiosRequest({
                method: "DELETE",
                url: `catalog/permanent-delete/products/${id}`,
                headers: {
                    Authorization: `Bearer ${token}`,
                    "X-Tenant": tenantId,
                },
            });
            return response.data;
        } catch (error) {
            await window.log.logToFile({ message: "Error while deleting product permanently", level: "error" });
            throw new Error('Error deleting product');
        }
    }
);

export default productDeletedSlice.reducer;

export const { 
    resetDeletedProduct } = productDeletedSlice.actions;

export const { selectAll: selectAllDeletedProduct } = productDeletedAdapter.getSelectors(
  (state) => state.ProductDeletedRecords
);
