import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Formik } from "formik";
import * as Yup from "yup";
import { cloneDeep, isNumber, trim } from "lodash";
import { Button, Col, Modal, Row, Tab, Tabs } from "react-bootstrap";
import MemberDetails from "../../components/MemberDetails";
import { AddMember, changeMember, getMemberNumber, resetMemberState } from "../../store/MemberSlice";
import { i18n, placeholderText, numValidate } from "../../../shared/helpers/sharedMethods";
import { fetchInviteTrainer, fetchInviteUser } from "../../../settings/store/inviteUserSlice";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const CreateMember = ({ setShowMemberCreate,  isTriggeredFromMember,    memberQuickCreate, autoFill, }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const saveButtonRef = useRef(null);
  const { accessToken, isLoggedIn } = useSelector((state) => state?.auth);

  const selectedBusiness = useSelector((state) => state?.auth?.selectedBusiness);
  const tenantId = selectedBusiness?.pivot?.tenant_id;
  const terminalNumber = useSelector((state) => state?.auth?.terminalNumber);
  const memberState = useSelector((state) => state.members);
  const memberStates = useSelector((state) => state.members.member);

  const { status, savedEntity, error, errorStatus } = useSelector((state) => state.members);
  const [selectedId, setSelectedId] = useState();
  const [key, setKey] = useState("general");

  const [show, setShow] = useState(true);

  const handleCancel = () => {
    setShow(false);
    setShowMemberCreate(false);
  };
  
  const { fetchTrainerStatus, trainersList } = useSelector((state) => state.inviteUser);
  useEffect(() => {
    dispatch(fetchInviteTrainer({
      tenant_id: tenantId,
      token: accessToken?.access_token,
    }));
  }, []);

  const filterData = Array.isArray(trainersList) ? trainersList.map((value) => value) : [];

  // Fetch member number on component mount
  useEffect(() => {
    if (accessToken && selectedBusiness?.pivot?.tenant_id) {
      dispatch(
        getMemberNumber({
          token: accessToken.access_token,
          tenant_id: selectedBusiness?.pivot?.tenant_id,
        })
      );
    }
  }, [accessToken, selectedBusiness?.pivot?.tenant_id, dispatch]);
  
  useEffect(() => {
    if (autoFill && autoFill != "") {
      let cleanedAutoFill = trim(autoFill);
      if (
        isNumber(parseInt(cleanedAutoFill)) &&
        cleanedAutoFill.toString().length == 10
      ) {
        dispatch(changeMember({ value: cleanedAutoFill, name: "phone_number" }));
      } else {
        dispatch(changeMember({ value: cleanedAutoFill, name: "member_name" }));
      }
    }
  }, []);

  useEffect(() => {
    if (status === true && savedEntity) {
      toast.success("Member created successfully")
      setShow(false);
      setShowMemberCreate(false);
      if (isTriggeredFromMember) {
        memberQuickCreate([savedEntity]);
      }
      dispatch(resetMemberState())
    }
  }, [status, savedEntity]);

  const handleSubmitForm = async (values) => {
    let formData = cloneDeep(memberStates);
    formData.member_id = memberState.member_id;
    formData.created_by = selectedBusiness?.pivot?.user_id;
  
    await dispatch(
      AddMember({ 
        data: formData, 
        token: accessToken.access_token, 
        tenant_id: tenantId,
        terminalNumber: terminalNumber,
      })
    );
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.altKey && event.key === "Enter") {
        event.preventDefault();
        document.getElementById("submitButton").click();
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  return (
    <>
    <ToastContainer />
      <Formik
          initialValues={{
            member_name: '',
            mobile: '',
            age: '',
            weight: '',
            height: '',
            trainer_id: '',
          }}
          enableReinitialize={true}
          validationSchema={Yup.object().shape({
            member_name: Yup.string()
              .required(i18n("Please enter member name"))
              .min(2, "Please enter at least 2 characters"),
            mobile: Yup.string()
              .required(i18n("Please enter member mobile number"))
              .min(10, "Mobile number must be 10 digits").matches(/^[0-9]*$/, i18n("Invalid mobile number")),
          })}
          onSubmit={handleSubmitForm} 
        >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          handleBlur,
        }) => (
          <>
            <Modal
              show={show}
              keyboard={true}
              onHide={handleCancel}
              size="lg"
              dialogClassName="modal-dialog modal-dialog-centered"
            >
              <Modal.Header closeButton>
                <h2 className="bold">{i18n("Create Member")}</h2>
              </Modal.Header>

              <Modal.Body className="py-2" style={{ height: "500px" }}>
                <Tabs
                  id="controlled-tab-example"
                  activeKey={key}
                  onSelect={(k) => setKey(k)}
                  className="mb-3 custom-nav-css"
                  variant="underline"
                  style={{
                    borderBottom: "1px solid lightgray",
                    lineHeight: "30px",
                    gap: "30px",
                  }}
                >
                  <Tab eventKey="general" title={<div>{i18n("General Details")}</div>}>
                    <MemberDetails
                      handleChange={handleChange}
                      i18n={i18n}
                      placeholderText={placeholderText}
                      errors={errors}
                      values={values}
                      touched={touched}
                      handleBlur={handleBlur}
                      numValidate={numValidate}
                      filterData={filterData}
                    />
                  </Tab>
                </Tabs>
              </Modal.Body>

              <Modal.Footer style={{ borderTop: "1px solid lightgray" }}>
                <Row className="py-1">
                  <Col className="d-flex justify-content-end">
                    <Button variant="primary mx-2"
                        id="submitButton"
                       ref={saveButtonRef} onClick={handleSubmit}>
                      {i18n("Save")}
                    </Button>
                    <Button variant="outline-secondary mx-2" onClick={handleCancel}>
                      {i18n("Cancel")}
                    </Button>
                  </Col>
                </Row>
              </Modal.Footer>
            </Modal>
          </>
        )}
      </Formik>
    </>
  );
};

export default CreateMember;
