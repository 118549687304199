import {
    createSlice,
    createAsyncThunk,
  } from "@reduxjs/toolkit";
import { i18n } from "../../shared/helpers/sharedMethods";
import { axiosRequest } from "../../shared/helpers/axiosHelper";

  const initialState = {
    status:false,
    reportItems:[],
    error:false,
    errorMessage:{},
    loading: false,
  };

export const fetchSalesSummaryChart = createAsyncThunk('salesSummaryChart/fetchSalesSummaryChart', async (param) => {
 console.log('param2',param);
 
  try{
    const token = param.token;
    const tenantId = param.tenant_id;
    const startDate = param.startDate;
    const endDate = param.endDate;
    const salesSummaryChartResponse  = await axiosRequest({
      method: "GET",
      url: `get-sales-summary`,
      params: {
        start_date: startDate,
        end_date: endDate,
      },
      headers: {
        Authorization: `Bearer ${token}`,
        "X-Tenant": tenantId,
      },
    });
   if (salesSummaryChartResponse?.success) {
    ;
    return salesSummaryChartResponse?.data;
    } else {
       throw new Error("Error while fetching Sales summary chart");
        
      }
  }catch(err){
    await window.log.logToFile({message: "Error while fetching Sales summary", level: "error" });
    throw new Error("Error while fetching Sales summary chart")
  }
});

const saleSummaryChartSlice = createSlice({
    name: "saleSummaryChart",
    initialState,
    reducers: {},
    extraReducers(builder) {
      builder
      .addCase(fetchSalesSummaryChart.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchSalesSummaryChart.fulfilled, (state, action) => {
        state.status = true;
        state.loading = false;
        state.reportItems =action?.payload;
      })
      .addCase(fetchSalesSummaryChart.rejected, (state, action) => {
        state.error = true; 
        state.loading = false;
        state.errorMessage=`${i18n("Unable to retrieve Sales Summary chart. Please try again later.")}`;
        state.status = false;
      })

    },
});

export default saleSummaryChartSlice.reducer;
