import React, { useState, useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { ReactSearchAutocomplete } from "react-search-autocomplete";
import { selectAllparty } from "../../party/store/getPartySlice";
import {
  placeholderText,
  i18n,
} from "../../shared/helpers/sharedMethods";
import { Modal, Button, Col } from "react-bootstrap";
import { updateParty } from "../store/voucherEntitySlice";
import { toast, ToastContainer } from "react-toastify";
import { numValidate } from "../../shared/helpers/sharedMethods";

const PartyModel = (props) => {
  const dispatch = useDispatch();
  const { handleClose, show } = props;

  const partyValue = useSelector((state) => state.voucherEntity.party);
  const [showAdditionalFields, setShowAdditionalFields] = useState("");
  const [AdditionalFields, setAdditionalFields] = useState({
    name: "",
    phone_number: "",
  });
  const [errors, setErrors] = useState({
    name: "",
    phone_number: "",
  });

  const handleDone = () => {
    handleClose();
  };

  const onPartyChange = (value) => {
    ;
    dispatch(updateParty(value));
    handleClose();
  };

  const onPartyModelChange = (e) => {
    setAdditionalFields({
      ...AdditionalFields,
      [e.target.name]: e.target.value,
    });
    setErrors((prevErrors) => ({ ...prevErrors, name: "" }));
  };

  const handleOnSearch = (string, results) => {
    ;
   // onPartySearch(string);
  };

  const handleOnSelect = (item) => {
    dispatch(updateParty(item));
    //setSearchString("");
    ;
  };

  const handleParty = () => {
    setShowAdditionalFields(!showAdditionalFields);
  };

  const handleValidation = () => {
    let errorss = {};
    let isValid = false;
    if (!AdditionalFields["name"]) {
      errorss["name"] = i18n("Please Enter Name");
    } else if (!AdditionalFields["phone_number"]) {
      errorss["phone_number"] = i18n(
        "Please Enter Phone Number"
      );
    } else {
      isValid = true;
    }
    setErrors(errorss);
    return isValid;
  };

  const handlePartyDone = () => {
    const validation = handleValidation();
    setShowAdditionalFields(false);

    if (validation) {
      const data = {
        name: AdditionalFields.name,
        phone_number: AdditionalFields.phone_number,
      };
      dispatch(updateParty(data));
      handleClose();
    }
  };

  const fetchSales = useSelector(selectAllparty);
  ;

  return (
    <>
      <ToastContainer />
      <Modal
        show={show}
        dialogClassName="modal-dialog modal-dialog-centered mw-700px"
        onHide={handleClose}
        keyboard={true}
      >
        <Modal.Header closeButton>
          <Modal.Title>{i18n("Add Party")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-md-12 mb-2">
              {showAdditionalFields ? (
                <div></div>
              ) : (
                <div className="mb-5">
                  <Col className="position-relative my-3 search-bar col-xxl-8 col-lg-12 col-12">
                    <ReactSearchAutocomplete
                      style={{ borderRadius: "5px" }}
                      placeholder={placeholderText(
                        "Search by Party Name/Phone number"
                      )}
                      items={
                        fetchSales.length > 1 &&
                        fetchSales.map((item) => ({
                          ...item,
                          combinedNameAndPhone: `${item && item.name} - ${
                            item && item.phone_number
                          }`,
                        }))
                      }
                      onSearch={handleOnSearch}
                      fuseOptions={{
                        keys: ["name", "phone_number"],
                        inputDebounce: 10000,
                      }}
                      resultStringKeyName="combinedNameAndPhone"
                      onSelect={handleOnSelect}
                      value={partyValue}
                      showIcon={false}
                      showClear={false}
                      autoFocus
                    />
                  </Col>
                </div>
              )}
              {showAdditionalFields && (
                <div>
                  <div className="col-md-12 mb-2">
                    <label className="form-label">{i18n("Name")}: </label>
                    <span className="required" />
                    <input
                      type="text"
                      name="name"
                      autoComplete="off"
                      placeholder={placeholderText("Enter the Name")}
                      className="form-control"
                      onChange={onPartyModelChange}
                      value={AdditionalFields.name}
                    />
                    <span className="text-danger d-block fw-400 fs-small mt-2">
                      {errors["name"] ? errors["name"] : null}
                    </span>
                  </div>

                  <div className="col-md-12 mb-2">
                    <label className="form-label">{i18n("Phone Number")}: </label>
                    <span className="required" />
                    <input
                      type="text"
                      name="phone_number"
                      autoComplete="off"
                      placeholder={placeholderText("Enter the Phone Number")}
                      className="form-control"
                      pattern="[0-9]*"
                      min={0}
                      maxLength="10"
                      onKeyPress={(event) => numValidate(event)}
                      onChange={onPartyModelChange}
                      value={AdditionalFields.phone_number}
                    />
                    <span className="text-danger d-block fw-400 fs-small mt-2">
                      {errors["phone_number"] ? errors["phone_number"] : null}
                    </span>
                  </div>
                </div>
              )}

              <div className="col-sm-12">
                {partyValue ? (
                  <div className="col-md-12 mb-2">
                    <label className="form-label">{i18n("Parties")}: </label>
                    <span className="required" />
                    <input
                      type="text"
                      name="name"
                      autoComplete="off"
                      placeholder={placeholderText("Enter the Name")}
                      className="form-control"
                      onChange={onPartyChange}
                      value={partyValue.name}
                    />
                  </div>
                ) : !showAdditionalFields ? (
                  <div
                    className="d-flex justify-content-end"
                    style={{ paddingTop: "15px", paddingRight: "25px" }}
                  >
                    <Button variant="outline-primary" onClick={handleParty}>
                      {i18n("Create Party")}
                    </Button>
                  </div>
                ) : !showAdditionalFields ? (
                  <div
                    className="d-flex justify-content-end"
                    style={{ paddingTop: "15px", paddingRight: "25px" }}
                  >
                    <Button variant="outline-primary" onClick={handleParty}>
                    {i18n("Create Party")}
                    </Button>
                  </div>
                ) : (
                  <div
                    className="d-flex justify-content-end"
                    style={{ paddingTop: "10px", paddingRight: "25px" }}
                  >
                    {AdditionalFields.name && AdditionalFields.phone_number && (
                      <Button
                        variant="outline-primary"
                        onClick={handlePartyDone}
                      >
                        {i18n("Done")}
                      </Button>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </Modal.Body>
        <div className="bottom-row d-flex justify-content-between">
          {partyValue ? (
            <div style={{ padding: "10px" }}>
              <div
                style={{ color: "blue", paddingTop: "7px", cursor: "pointer" }}
                onClick={handleParty}
              >
                {i18n("+ Create Party")}
              </div>
            </div>
          ) : (
            <div></div>
          )}

          {partyValue ? (
            <div style={{ padding: "10px" }}>
              <Button variant="outline-primary" onClick={handleDone}>
                {i18n("Done")}
              </Button>
            </div>
          ) : (
            <div></div>
          )}
        </div>
      </Modal>
    </>
  );
};

export default connect(null, {})(PartyModel);
