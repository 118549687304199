import { Container, Stack } from "react-bootstrap";

const PackageHeader = ({ItemActions,currentTitle}) => {
  return (
    <Container
      fluid
      className="mb-3 px-3"
      style={{
        height: "50px",
        borderBottom: "1px solid #ddd",
        background: "white",
      }}
    >
      <Stack
        direction="horizontal"
        className="d-flex justify-content-between px-0"
      >
        <Stack
          direction="horizontal"
          className="d-flex justify-content-start align-items-center p-0"
        >
          <h2 className="mt-3 bold ">{currentTitle}</h2>
        </Stack>

        <Stack direction="horizontal" className="">
          {ItemActions}
        </Stack>
      </Stack>
    </Container>
  );
};

export default PackageHeader
