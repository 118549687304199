import React, { useState, useEffect } from "react";
import { Button, Row, Col, Stack, Container, Image } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Layout from "../../shared/components/Layout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowDown,
  faArrowLeft,
  faArrowUp,
  faChevronLeft,
  faDownload,
} from "@fortawesome/free-solid-svg-icons";
import { downloadCsvFile, handleExportClick } from "../../shared/export";
import DataGrid from "../../shared/components/DataGrid";
import { format } from "date-fns";
import ReportPeriod from "../../shared/components/ReportPeroid";
import Header from "../../shared/components/Header";
import { fetchGSTR2PurchaseReport } from "../store/gstr2PurchaseReportSlice";
import PurchaseTypeFilter from "../components/Filters/PurchaseTypeFilter";
import GSTNavbar from "../components/GSTNavbar";
import { i18n } from "../../shared/helpers/sharedMethods";
import { changeToggleShow } from "../../shared/store/sharedSlice";
import ShortcutKeys from "../../shared/components/ShortcutKeys";
import ReportDateRange from "../../shared/components/date_range/ReportDateRange";
import DatePicker from "../../shared/components/date_range/DatePicker";
import { CurrentWeekDateRange } from "../../shared/components/DateRangeYAxis";
import ReportHeader from "../components/ReportHeader";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import CustomeSortIcon from "../../shared/components/CustomeSortIcon";
import { UilImport } from "@iconscout/react-unicons";
import { formatAmount } from "../../pos/helpers/cartHelper";
import { getLocalizedDate } from '../../shared/helpers/localizationHelper';
import LoadingSpinner from '../../shared/components/LoadingSpinner';
import { placeOfSupplyList } from "../../shared/config/placeOfSupply";
import GlobalCurrency from "../../shared/components/GlobalCurrency";

const GSTR2PurchaseReport = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const accessToken = useSelector((state) => state?.auth?.accessToken?.access_token);
  const selectedBusiness = useSelector((state) => state?.auth?.selectedBusiness);
  const tenantId = selectedBusiness?.pivot?.tenant_id;
  const [reportPeriod, setReportPeriod] = useState("current-week");
  const [reportType, setReportType] = useState("purchase_invoice");
  const [csvData, setCsvData] = useState("");
  const [startDate, setStartDate] = useState(null); // State for start date
  const [endDate, setEndDate] = useState(null);
  const [sortField, setSortField] = useState(null);
  const [sortOrder, setSortOrder] = useState(1);
  const loading = useSelector((state) => state.gstr2PurchaseReport.loading);

    const handleReset = () => {
      const salesDateLimits = ReportDateRange({
        reportPeriod: reportPeriod,
      });
      setStartDate(salesDateLimits.startDate);
      setEndDate(salesDateLimits.endDate);
      setReportPeriod("current-week");
      setReportType("purchase_invoice");
    };

  useEffect(() => {
    if (startDate && endDate) {
      dispatch(fetchGSTR2PurchaseReport({  startDate: startDate, endDate: endDate, reportType : reportType,token:accessToken,tenant_id:tenantId})); 
    }
  }, [startDate, endDate, reportType]);
  useEffect(() => {
    const dateLimits = ReportDateRange({ reportPeriod });
    setStartDate(dateLimits.startDate);
    setEndDate(dateLimits.endDate);
  }, [reportPeriod]);

  const reportItems = useSelector((state) => state.gstr2PurchaseReport.reportItems);
  console.log('reportItems ',reportItems );
  const status = useSelector((state) => state.gstr2PurchaseReport.status);
  const currencySymbol = useSelector((state) => state.getSetting?.dataSetting.currency);
  ;
  const dateTimeString = new Date()
    .toLocaleString()
    .replace(/[-]/g, " ")
    .replace(/:/g, "-");
  const fileName = `GSTR-2_purchase_report_${dateTimeString}`;

  const handleExportButtonClick = () => {
    const exportData =
      reportItems &&
      Array.isArray(reportItems) &&
      reportItems.map((val) => ({
        "GSTIN": val.gstin ? val.gstin : "-",
        "CUSTOMER NAME": val.partyName ? val.partyName : "-",
        "STATE CODE": val.place_of_supply ? val.place_of_supply : "-",
        "STATE NAME": val.place_of_supply
          ? placeOfSupplyList.find((item) => item.value === val.place_of_supply)
              ?.label: "",
        "INVOICE NO.": val.invNo ? val.invNo : "-",
        "INVOICE DATE": val.date ? val.date : "-",
        " INVOICE VALUE": val.amount
          ? parseFloat(val.amount).toFixed(2)
          : "0",
        "TOTAL TAX": val.taxRate ? val.taxRate : '0',
        "TAXABLE VALUE": val?.taxable_value ? parseFloat(val.taxable_value).toFixed(2) : "0",
        "SGST": val.sgst ? val.sgst : "0",
        "CGST": val.cgst ? val.cgst : "0",
        "IGST": val.igst ? val.igst : "0",
        "CESS": val.cess ? val.cess : "0",
        " TOTAL TAX": val.taxTotal
          ? parseFloat(val.taxTotal).toFixed(2)
          : "0",
      }));
    handleExportClick(
      exportData,
      setCsvData,
      downloadCsvFile,
      fileName
    );
  };
  const customSort = (field) => {
    setSortField(field);
    setSortOrder(sortOrder === 1 ? -1 : 1);
  };
  const sortedData = [...reportItems].sort((a, b) => {
    if (sortField) {
      const compareValueA = a[sortField];
      const compareValueB = b[sortField];
      if (compareValueA < compareValueB) {
        return sortOrder;
      }
      if (compareValueA > compareValueB) {
        return -sortOrder;
      }
    }
    return 0;
  });
  const headerStyle = {
    backgroundColor: "white",
  };
  const headerCellStyle = {
    fontWeight: "bold",
    fontSize: "11px",
  };
  const gridColumns = [
    {
      id: "gstin",
      name: (i18n("gstin")),
      // width:'100px',
      sortable: true,
      selector: (row) => row.gstin,
      cell: (row) =>
        row.gstin ? (
          <span>
            {row.gstin}
          </span>
        ) : (
          "-"
        ),
    },
    {
      id: "partyName",
      name: (i18n("CUSTOMER NAME")),
      //width:'140px',
      sortable: true,
      selector: (row) => row.partyName,
    },
    {
      id: "",
      name: (i18n("STATE CODE")),
      // width:'110px',
      sortable: true,
      selector: (row) => row,
      cell: (row) =>
        row ? (
          <span>
            33
          </span>
        ) : (
          "-"
        ),
    },
    {
      id: "",
      name: (i18n("STATE NAME")),
      // width:'110px',
      sortable: true,
      selector: (row) => row,
      cell: (row) =>
        row ? (
          <span>
            Tamil Nadu
          </span>
        ) : (
          "-"
        ),
    },
    {
      id: "invNo",
      name: (i18n("invoice NO.")),
      // width:'110px',
      sortable: true,
      selector: (row) => row.invNo,
    },
    {
      id: "date",
      name: (i18n("INVOICE DATE")),
      //width:'120px',
      selector: (row) => format(new Date(row.date), "dd-MM-yyyy "),
      sortable: true,
    },

    {
      id: "amount",
      name: (i18n("INVOICE VALUE")),
      //width:'140px',
      sortable: true,
      selector: (row) => row.amount,
      cell: (row) =>
        row.amount ? (
          <span>
            {currencySymbol} {parseFloat(row.amount).toFixed(2)}
          </span>
        ) : (
          "0"
        ),
    },

    {
      id: "taxRate",
      name: (i18n("TOTAL TAX %")),
      //width:'120px',
      sortable: true,
      selector: (row) => row.taxRate,
      cell: (row) =>
        row.taxRate ? (
          <span>
            {row.taxRate}%
          </span>
        ) : (
          "0%"
        ),
    },

    {
      id: "taxable_value",
      name: (i18n("TAXABLE VALUE")),
      // width:'140px',
      sortable: true,
      selector: (row) => row.taxable_value,
      cell: (row) =>
        row.taxable_value ? (
          <span>
            {currencySymbol} {parseFloat(row.taxable_value).toFixed(2)}
          </span>
        ) : (
          "0"
        ),
    },
    {
      id: "sgst",
      name: (i18n("SGST")),
      //width:'80px',
      sortable: true,
      selector: (row) => row.sgst,
      cell: (row) =>
        row.sgst ? (
          <span>
            {currencySymbol} {row.sgst}
          </span>
        ) : (
          "-"
        ),
    },
    {
      id: "cgst",
      name: (i18n("CGST")),
      // width:'80px',
      sortable: true,
      selector: (row) => row.cgst,
      cell: (row) =>
        row.cgst ? (
          <span>
            {currencySymbol} {row.cgst}
          </span>
        ) : (
          "-"
        ),
    },
    {
      id: "igst",
      name: (i18n("IGST")),
      //width:'80px',
      sortable: true,
      selector: (row) => row.igst,
      cell: (row) =>
        row.igst ? (
          <span>
            {currencySymbol} {row.igst}
          </span>
        ) : (
          "-"
        ),
    },
    {
      id: "cess",
      name: (i18n("cess")),
      // width:'80px',
      sortable: true,
      selector: (row) => row.cess,
      cell: (row) =>
        row.cess ? (
          <span>
            {currencySymbol} {row.cess}
          </span>
        ) : (
          "-"
        ),
    },
    {
      id: "taxTotal",
      name: (i18n("TOTAL TAX")),
      sortable: true,
      selector: (row) => row.taxTotal,
      cell: (row) =>
        row.taxTotal ? (
          <span>
            {currencySymbol} {parseFloat(row.taxTotal).toFixed(2)}
          </span>
        ) : (
          "0"
        ),
    },

  ];
  return (
    <Layout>
       {loading && <LoadingSpinner />}
      <Header
        currentTitle={i18n("GSTR-2 (Purchase)")}
        ItemActions={
          <Stack gap={3} direction="horizontal" className="mt-1 mb-1 ">
            <ShortcutKeys />
          </Stack>
        }
      />
      <Container fluid className="px-5">
        <div className="border " style={{ background: "white",  width: window.innerWidth <= 1496 ? "91.5%" : "100%", }}>
          <GSTNavbar />
          <ReportHeader
            ItemActions={
              <Stack gap={2} direction="horizontal" className="pt-3 px-3 mb-3">
                <Stack direction="horizontal">
                  <DatePicker
                    startDate={startDate}
                    setStartDate={setStartDate}
                    endDate={endDate}
                    setEndDate={setEndDate}
                  />
                </Stack>
                <Stack className=" ">
                  <ReportPeriod
                    reportPeriod={reportPeriod}
                    setReportPeriod={setReportPeriod}
                  />
                </Stack>
                <Stack>
                  <PurchaseTypeFilter
                    reportType={reportType}
                    setReportType={setReportType}
                  />
                </Stack>
                <Stack
                  gap={1}
                  direction="horizontal"
                  style={{ marginLeft: "1px" }}
                >
                  <Button
                    className="rounded-0"
                    variant="outline-danger"
                    onClick={handleReset}
                  >
                    {i18n("Reset")}
                  </Button>
                </Stack>
                <Button
                  className="fw-bolder d-flex align-items-center rounded-0"
                  onClick={handleExportButtonClick}
                >
                  <UilImport
                    size="18"
                    className="mx-2"
                    style={{ cursor: "pointer" }}
                  />
                  {i18n("Export")}
                </Button>
              </Stack>
            }
          />
          {/* {status ? (
            <DataGrid
              allData={reportItems}
              data={reportItems}
              columns={gridColumns}
              isPaginated={true}
              title="Report"
            />
          ) : (
            <p className="d-flex justify-content-center p-2 fs-3 fw-bolder">
              {i18n("Error while fetching GSTR-2 Purchase report")}
            </p>
          )} */}
          <Container fluid className="px-3 mb-4" width>
            <div className="bordered-container">
              {status && reportItems ? (
                <DataTable
                  value={sortedData}
                  headerStyle={headerStyle}
                  paginator
                  rows={10}
                  dataKey="id"
                  emptyMessage={i18n("No Purchase Summary found")}
                  className="p-datatable-gridlines full-width-table custom-datatable p-component"
                  scrollable
                  scrollHeight="400px"
                  sortMode="multiple"
                  removableSort
                >
                  <Column
                    field="gstin"
                    header={() => (
                      <div
                        className={`custom-header-${sortField === "gstin"
                            ? sortOrder === 1
                              ? "up"
                              : "down"
                            : ""
                          }`}
                        onClick={() => customSort("gstin")}
                        style={{
                          cursor: "pointer",
                          display: "flex",
                          alignItems: "center",
                          fontSize: "11px",
                          lineHeight: "11px",
                        }}
                      >
                        {i18n("gstin")}
                        <CustomeSortIcon
                          sortOrder={sortField === "gstin" ? sortOrder : 0}
                          style={{ marginLeft: "4px", verticalAlign: "middle" }}
                        />
                      </div>
                    )}
                    body={(row) => (row.gstin ? <span>{row.gstin}</span> : "-")}
                    sortable
                    headerStyle={headerCellStyle}
                  />

                  <Column
                    field="partyName"
                    header={() => (
                      <div
                        className={`custom-header-${sortField === "partyName"
                            ? sortOrder === 1
                              ? "up"
                              : "down"
                            : ""
                          }`}
                        onClick={() => customSort("partyName")}
                        style={{
                          cursor: "pointer",
                          display: "flex",
                          alignItems: "center",
                          fontSize: "11px",
                          lineHeight: "11px",
                        }}
                      >
                        {i18n("CUSTOMER NAME")}
                        <CustomeSortIcon
                          sortOrder={sortField === "partyName" ? sortOrder : 0}
                          style={{ marginLeft: "4px", verticalAlign: "middle" }}
                        />
                      </div>
                    )}
                    sortable
                    headerStyle={headerCellStyle}
                  />
                  <Column
                    field=""
                    header={() => (
                      <div
                        className={`custom-header-${sortField === ""
                            ? sortOrder === 1
                              ? "up"
                              : "down"
                            : ""
                          }`}
                        onClick={() => customSort("")}
                        style={{
                          cursor: "pointer",
                          display: "flex",
                          alignItems: "center",
                          fontSize: "11px",
                          lineHeight: "11px",
                        }}
                      >
                        {i18n("STATE CODE")}
                        <CustomeSortIcon
                          sortOrder={sortField === "" ? sortOrder : 0}
                          style={{ marginLeft: "4px", verticalAlign: "middle" }}
                        />
                      </div>
                    )}
                    body={(row) => (row?.place_of_supply ? <span>{row?.place_of_supply}</span> : "-")}
                    sortable
                    headerStyle={headerCellStyle}
                  />
                  <Column
                    field=""
                    header={() => (
                      <div
                        className={`custom-header-${sortField === ""
                            ? sortOrder === 1
                              ? "up"
                              : "down"
                            : ""
                          }`}
                        onClick={() => customSort("")}
                        style={{
                          cursor: "pointer",
                          display: "flex",
                          alignItems: "center",
                          fontSize: "11px",
                          lineHeight: "11px",
                          minWidth: '90px',
                        }}
                      >
                        {i18n("STATE NAME")}
                        <CustomeSortIcon
                          sortOrder={sortField === "" ? sortOrder : 0}
                          style={{ marginLeft: "4px", verticalAlign: "middle" }}
                        />
                      </div>
                    )}
                    body={(row) => (row?.place_of_supply 
                      ? <span>{placeOfSupplyList.find((item) => item.value === row.place_of_supply)?.label || "-"}</span> 
                      : "-"
                  )}
                    sortable
                    headerStyle={headerCellStyle}
                  />
                  <Column
                    field="invNo"
                    header={() => (
                      <div
                        className={`custom-header-${sortField === "invNo"
                            ? sortOrder === 1
                              ? "up"
                              : "down"
                            : ""
                          }`}
                        onClick={() => customSort("invNo")}
                        style={{
                          cursor: "pointer",
                          display: "flex",
                          alignItems: "center",
                          fontSize: "11px",
                          lineHeight: "11px",
                          minWidth: '70px',
                        }}
                      >
                        {i18n("Invoice No")}
                        <CustomeSortIcon
                          sortOrder={sortField === "invNo" ? sortOrder : 0}
                          style={{ marginLeft: "4px", verticalAlign: "middle" }}
                        />
                      </div>
                    )}
                    sortable
                    headerStyle={headerCellStyle}
                  />

                  <Column
                    field="date"
                    header={() => (
                      <div
                        className={`custom-header-${sortField === "date"
                            ? sortOrder === 1
                              ? "up"
                              : "down"
                            : ""
                          }`}
                        onClick={() => customSort("date")}
                        style={{
                          cursor: "pointer",
                          display: "flex",
                          alignItems: "center",
                          fontSize: "11px",
                          lineHeight: "11px",
                        }}
                      >
                        {i18n("INVOICE DATE")}
                        <CustomeSortIcon
                          sortOrder={sortField === "date" ? sortOrder : 0}
                          style={{ marginLeft: "4px", verticalAlign: "middle" }}
                        />
                      </div>
                    )}
                    body={(row) =>
                      row.date ? (
                        <span>{getLocalizedDate(row.date, 'en-IN').split(',')[0]}</span>
                      ) : (
                        "-"
                      )
                    }
                    sortable
                    headerStyle={headerCellStyle}
                  />
                  <Column
                    field="amount"
                    header={() => (
                      <div
                        className={`custom-header-${sortField === "amount"
                            ? sortOrder === 1
                              ? "up"
                              : "down"
                            : ""
                          }`}
                        onClick={() => customSort("amount")}
                        style={{
                          cursor: "pointer",
                          display: "flex",
                          alignItems: "center",
                          fontSize: "11px",
                          lineHeight: "11px",
                          minWidth: '100px',
                        }}
                      >
                        {i18n("INVOICE VALUE")}
                        <CustomeSortIcon
                          sortOrder={sortField === "amount" ? sortOrder : 0}
                          style={{ marginLeft: "4px", verticalAlign: "middle" }}
                        />
                      </div>
                    )}
                    body={(row) =>
                      row.amount ? (
                        <GlobalCurrency price={row.amount} />
                      ) : (
                        <GlobalCurrency price={0} />
                    )}  
                    sortable
                    headerStyle={headerCellStyle}
                  />

                  <Column
                    field="taxRate"
                    header={() => (
                      <div
                        className={`custom-header-${sortField === "taxRate"
                            ? sortOrder === 1
                              ? "up"
                              : "down"
                            : ""
                          }`}
                        onClick={() => customSort("taxRate")}
                        style={{
                          cursor: "pointer",
                          display: "flex",
                          alignItems: "center",
                          fontSize: "11px",
                          lineHeight: "11px",
                        }}
                      >
                        {i18n("TOTAL TAX %")}
                        <CustomeSortIcon
                          sortOrder={sortField === "taxRate" ? sortOrder : 0}
                          style={{ marginLeft: "4px", verticalAlign: "middle" }}
                        />
                      </div>
                    )}
                    body={(row) =>
                      row.taxRate ? <span>{row.taxRate}%</span> : "-"
                    }
                    sortable
                    headerStyle={headerCellStyle}
                  />
                  <Column
                    field="taxable_value"
                    header={() => (
                      <div
                        className={`custom-header-${sortField === "taxable_value"
                            ? sortOrder === 1
                              ? "up"
                              : "down"
                            : ""
                          }`}
                        onClick={() => customSort("taxable_value")}
                        style={{
                          cursor: "pointer",
                          display: "flex",
                          alignItems: "center",
                          fontSize: "11px",
                          lineHeight: "11px",
                          minWidth: '80px',
                        }}
                      >
                        {i18n("TAXABLE VALUE")}
                        <CustomeSortIcon
                          sortOrder={
                            sortField === "taxable_value" ? sortOrder : 0
                          }
                          style={{ marginLeft: "4px", verticalAlign: "middle" }}
                        />
                      </div>
                    )}
                    body={(row) =>
                      row.taxable_value ? (
                        <GlobalCurrency price={row.taxable_value} />
                      ) : (
                        <GlobalCurrency price={0} />
                    )}  
                    sortable
                    headerStyle={headerCellStyle}
                  />
                  <Column
                    field="sgst"
                    header={() => (
                      <div
                        className={`custom-header-${sortField === "sgst"
                            ? sortOrder === 1
                              ? "up"
                              : "down"
                            : ""
                          }`}
                        onClick={() => customSort("sgst")}
                        style={{
                          cursor: "pointer",
                          display: "flex",
                          alignItems: "center",
                          fontSize: "11px",
                          lineHeight: "11px",
                          minWidth: '50px',
                        }}
                      >
                        {i18n("SGST")}
                        <CustomeSortIcon
                          sortOrder={sortField === "sgst" ? sortOrder : 0}
                          style={{ marginLeft: "4px", verticalAlign: "middle" }}
                        />
                      </div>
                    )}
                    body={(row) =>
                      row.sgst ? (
                        <GlobalCurrency price={row.sgst} />
                      ) : (
                        <GlobalCurrency price={0} />
                    )}  
                    sortable
                    headerStyle={headerCellStyle}
                  />
                  <Column
                    field="cgst"
                    header={() => (
                      <div
                        className={`custom-header-${sortField === "cgst"
                            ? sortOrder === 1
                              ? "up"
                              : "down"
                            : ""
                          }`}
                        onClick={() => customSort("cgst")}
                        style={{
                          cursor: "pointer",
                          display: "flex",
                          alignItems: "center",
                          fontSize: "11px",
                          lineHeight: "11px",
                          minWidth: '50px',
                        }}
                      >
                        {i18n("CGST")}
                        <CustomeSortIcon
                          sortOrder={sortField === "cgst" ? sortOrder : 0}
                          style={{ marginLeft: "4px", verticalAlign: "middle" }}
                        />
                      </div>
                    )}
                    body={(row) =>
                      row.cgst ? (
                        <GlobalCurrency price={row.cgst} />
                      ) : (
                        <GlobalCurrency price={0} />
                    )}  
                    sortable
                    headerStyle={headerCellStyle}
                  />
                  <Column
                    field="igst"
                    header={() => (
                      <div
                        className={`custom-header-${sortField === "igst"
                            ? sortOrder === 1
                              ? "up"
                              : "down"
                            : ""
                          }`}
                        onClick={() => customSort("igst")}
                        style={{
                          cursor: "pointer",
                          display: "flex",
                          alignItems: "center",
                          fontSize: "11px",
                          lineHeight: "11px",
                          minWidth: '50px',
                        }}
                      >
                        {i18n("IGST")}
                        <CustomeSortIcon
                          sortOrder={sortField === "igst" ? sortOrder : 0}
                          style={{ marginLeft: "4px", verticalAlign: "middle" }}
                        />
                      </div>
                    )}
                    body={(row) =>
                      row.igst ? (
                        <GlobalCurrency price={row.igst} />
                      ) : (
                        <GlobalCurrency price={0} />
                    )}  
                    sortable
                    headerStyle={headerCellStyle}
                  />
                  <Column
                    field="cess"
                    header={() => (
                      <div
                        className={`custom-header-${sortField === "cess"
                            ? sortOrder === 1
                              ? "up"
                              : "down"
                            : ""
                          }`}
                        onClick={() => customSort("cess")}
                        style={{
                          cursor: "pointer",
                          display: "flex",
                          alignItems: "center",
                          fontSize: "11px",
                          lineHeight: "11px",
                          minWidth: '50px',
                        }}
                      >
                        {i18n("cess")}
                        <CustomeSortIcon
                          sortOrder={sortField === "cess" ? sortOrder : 0}
                          style={{ marginLeft: "4px", verticalAlign: "middle" }}
                        />
                      </div>
                    )}
                    body={(row) =>
                      row.cess ? (
                        <GlobalCurrency price={row.cess} />
                      ) : (
                        <GlobalCurrency price={0} />
                    )}  
                    sortable
                    headerStyle={headerCellStyle}
                  />
                  <Column
                    field="taxTotal"
                    header={() => (
                      <div
                        className={`custom-header-${sortField === "taxTotal"
                            ? sortOrder === 1
                              ? "up"
                              : "down"
                            : ""
                          }`}
                        onClick={() => customSort("taxTotal")}
                        style={{
                          cursor: "pointer",
                          display: "flex",
                          alignItems: "center",
                          fontSize: "11px",
                          lineHeight: "11px",
                          minWidth: '70px',
                        }}
                      >
                        {i18n("TOTAL TAX")}
                        <CustomeSortIcon
                          sortOrder={sortField === "taxTotal" ? sortOrder : 0}
                          style={{ marginLeft: "4px", verticalAlign: "middle" }}
                        />
                      </div>
                    )}
                    body={(row) =>
                      row.taxTotal ? (
                        <GlobalCurrency price={row.taxTotal} />
                      ) : (
                        <GlobalCurrency price={0} />
                    )}  
                    sortable
                    headerStyle={headerCellStyle}
                  />
                </DataTable>
              ) : (
                <p>{i18n("Loading")}</p>
              )}
            </div>
          </Container>
        </div>
      </Container>
    </Layout>
  );
};

export default GSTR2PurchaseReport;
