import React, { useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Container,
  Card,
  Row,
  Col,
  Button,
  Form,
  InputGroup,
  Alert,
  ProgressBar,
  Collapse,
  Stack,
  Spinner,
} from "react-bootstrap";
import Papa from "papaparse";
import {
  importParty,
  partyImportError,
  resetParty,
  updatePartyImport,
  undoPartyImportError,
  handlePartyOneByOne,
  fetchparty,
} from "../store/getPartySlice";
import { useNavigate } from "react-router-dom";
import Layout from "../../shared/components/Layout";
import { i18n } from "../../shared/helpers/sharedMethods";
import { getUtcDate } from "../../shared/helpers/localizationHelper";
import { ConfirmPopup } from "primereact/confirmpopup";
import Header from "../../shared/components/Header";
import ShortcutKeys from "../../shared/components/ShortcutKeys";
import LoadingSpinner from "../../shared/components/LoadingSpinner";

const PartyImport = (props) => {
  const { title,type, path, partyType } = props;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const selectedBusiness = useSelector(
    (state) => state?.auth?.selectedBusiness
  );
  const bulkImport = useSelector((state) => state.getParty.bulkImport);
  const papaparseOptions = {
    header: true,
    skipEmptyLines: true,
  };

  const [selectedFileName, setSelectedFileName] = useState("");
  const [showPage, setShowPage] = useState(false);
  const [validCount, setValidCount] = useState(0);
  const [invalidCount, setInvalidCount] = useState(0);
  const [errors, setErrors] = useState([]);
  const [importing, setImporting] = useState(false);
  const [importSuccess, setImportSuccess] = useState(false);
  const [progress, setProgress] = useState(0);
  const [recordsProcessed, setRecordsProcessed] = useState(0);
  const [errorsVisible, setErrorsVisible] = useState(false);
  const [confirmVisible, setConfirmVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const { accessToken } = useSelector((state) => state?.auth);
  const tenantId = selectedBusiness?.pivot?.tenant_id;
  const fileInputRef = useRef(null);
  const buttonRef = useRef(null);

  const handleToggleErrors = () => {
    setErrorsVisible(!errorsVisible);
  };

  const handleFileLoaded = (data, fileInfo) => {
    const iterateBulkImport = data.map((item) => ({
      name: item?.name,
      phone_number: item["phone number"],
      email: item?.email,
      gstin: item?.gstin,
      type: "customer",
      category: item?.category
    }));
    dispatch(updatePartyImport(iterateBulkImport));
    setSelectedFileName(fileInfo.name);
  };

  const handleDownloadSample = () => {
    const sampleColumns = [
      "name",
      "phone number",
      "email",
      "category",
      "gstin"
    ];

    const sampleData = [
      Object.fromEntries(sampleColumns.map((col) => [col, ""])),
    ];
    const csv = Papa.unparse(sampleData, { header: true });
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.setAttribute("href", url);
    const timeStamp = Date.now();
    link.setAttribute("download", `sample_party_${timeStamp}.csv `);

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  const handleValidation = () => {
    const newErrors = [];
    let localValidCount = 0;
    let localInvalidCount = 0;

    const idPresent = bulkImport && bulkImport.some((item) => item.id);
    const expectedColumns = [
      "name",
      "phone_number",
      "email",
      "category",
      "gstin",
    ];
    const columnNamesMatch =
      bulkImport &&
      bulkImport.length > 0 &&
      expectedColumns.every((columnName) =>
        Object.keys(bulkImport[0]).includes(columnName)
      );

    if (idPresent || !columnNamesMatch) {
      alert(
        `Error: ${
          idPresent ? "ID should not be present" : "Column names do not match"
        } in the bulk data!`
      );
      return false;
    }
    const validItems = [];

    bulkImport?.forEach((item, index) => {
      const lineNumber = index + 1;
      let itemIsValid = true;

      if (typeof item.name !== "string" || item.name.trim() === "") {
        newErrors.push({
          Line: lineNumber,
          Field: "name",
          Message: "Name should only contain strings!",
        });
        itemIsValid = false;
      }
      if (!/^\d{10}$/.test(item.phone_number)) {
        newErrors.push({
          Line: lineNumber,
          Field: "phone number",
          Message: "Phone Number should be a valid 10-digit number!",
        });
        itemIsValid = false;
      }
   
      if (itemIsValid) {
        validItems.push(item);
        localValidCount++;
      } else {
        localInvalidCount++;
      }
    });

    dispatch(partyImportError(newErrors));
    setErrors(newErrors);
    setValidCount(localValidCount);
    setInvalidCount(localInvalidCount);

    return { isValid: localInvalidCount === 0, validItems };
  };
  const clickImport = () => {
    handleValidation();
    setLoading(true);
  
    setTimeout(() => {
      setLoading(false);
      setShowPage(true); 
    }, 2000);
  };
  const clearField = () => {
    navigate(`/${path}`);
    dispatch(undoPartyImportError());
  };

  const handleImport = async () => {
    setImporting(true);
    setImportSuccess(false);
    setProgress(0);
    setRecordsProcessed(0);

    const totalRecords = bulkImport ? bulkImport.length : 0;
    const size = 10;
    const totalChunks = Math.ceil(totalRecords / size);

    let recordsProcessed = 0;

    const updateProgress = (chunk) => {
      const newProgress = ((chunk + 1) / totalChunks) * 100;
      const recordsInCurrentChunk = Math.min(
        size,
        totalRecords - recordsProcessed
      );
      setRecordsProcessed((prev) => prev + recordsInCurrentChunk);

      setProgress(newProgress);
    };

    for (let chunk = 0; chunk < totalChunks; chunk++) {
      await new Promise((resolve) => {
        setTimeout(() => {
          updateProgress(chunk);
          resolve();
        }, chunk * 500);
      });

      if (chunk === totalChunks - 1) {
        setImporting(false);
        setImportSuccess(true);
        dispatch(updatePartyImport([]));

        if (onSubmit) {
          onSubmit();
        }
      }
    }
  };

  const acceptImport = () => {
    handleImport();
    setConfirmVisible(false);
  };

  const rejectImport = () => {
    setConfirmVisible(false);
  };

  const onSubmit = async () => {
    const { validItems } = handleValidation();
     
    try {
      if (validItems) {
        for (let i = 0; i < validItems.length; i++) {
          const party = {
            ...validItems[i],
            type: "customer",
            created_by: selectedBusiness?.pivot?.user_id,
            updated_by: selectedBusiness?.pivot?.user_id,
            created_at: getUtcDate(),
            updated_at: getUtcDate(),
          };
  
         await dispatch(importParty({data:party,token: accessToken.access_token,
          tenant_id: tenantId}));
         await dispatch(handlePartyOneByOne(party));
        }
  
        setTimeout(() => {
          dispatch(fetchparty());
          navigate(`/${path}`);
        }, 2000);
      }
    } catch (error) {
      console.error("Error during import:", error);
    }
  };
  
  return (
    <>
     <Layout>
    <Header
          type={type}
          currentTitle={partyType}
          ItemActions={
            <Stack
              gap={3}
              direction="horizontal"
              className="my-2 justify-content-end"
            >
              <ShortcutKeys />
            </Stack>
          }
        />
      <Container className="px-5" style={{ width: "100%" }} fluid>
        <Card style={{ height: "330px", position: "relative" }}>
          <Card.Header className="px-6 py-2">
            <h2 className="px-3 py-1">{title}</h2>
            <Button
              variant="outline-secondary"
              className="justify-content-end"
              onClick={clearField}
            >
              {i18n("Back")}
            </Button>
          </Card.Header>
          <Card.Body className="align-items-center justify-content-center pb-7">
            <Row className="nb-4">
              <Col lg={12}>
                <p style={{ color: "red" }}>
                  {i18n(
                    "The first line in downloaded csv file should remain as it is."
                  )}{" "}
                  {i18n("Please do not change the order of columns.")}
                </p>
              </Col>
            </Row>
            <Row>
              <Col lg={6}>
                {i18n("The Correct Column Order is,")}{" "}
                <p
                  style={{
                    color: "grey",
                    textAlign: "justify",
                    fontSize: "13px",
                  }}
                >
                  ( Party Name, Party Phone number, Party email, GSTIN, Category )
                </p>
              </Col>
              <Col lg={6} className="">
                <Button
                  variant="outline-secondary"
                  style={{
                    width: "144px",
                    height: "50px",
                    backgroundColor: "#F5F6FC",
                    borderRadius: "5px",
                    display: "flex",
                    alignItems: "center",
                  }}
                  onClick={handleDownloadSample}
                >
                  <span>
                    <i
                      className="fas fa-download mx-2"
                      style={{ color: "grey" }}
                    ></i>
                    {i18n("Download Sample File")}
                  </span>
                </Button>
              </Col>
            </Row>
            <Row>
              <Col lg={12}>
                <Form.Label>
                  <b>
                    {i18n("Upload file")}
                    <span style={{ color: "red" }}>*</span>
                  </b>
                </Form.Label>
                <InputGroup style={{ width: "63%" }}>
                  <Form.Control type="text" value={selectedFileName} readOnly />
                  <label htmlFor="fileInput" className="btn btn-primary m-0">
                    {i18n("Browse")}
                  </label>
                  <Form.Control
                    type="file"
                    id="fileInput"
                    ref={fileInputRef}
                    onChange={(e) => {
                      const file = e.target.files[0];
                      if (file) {
                        Papa.parse(file, {
                          ...papaparseOptions,
                          complete: (results) =>
                            handleFileLoaded(results.data, file),
                        });
                      }
                    }}
                    style={{ display: "none" }}
                  />
                </InputGroup>
              </Col>
            </Row>
            <Row className="mt-6">
              <Col lg={12} className="d-flex justify-content-start p-3">
              {!loading && (
                  <Button variant="primary" onClick={clickImport}>
                    {i18n("Import")}
                  </Button>
                )}
                {loading && <LoadingSpinner />}
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Container>

      {showPage && (
        <Container
          style={{ backgroundColor: "#F6F8FA", height: "15px" }}
          fluid
        ></Container>
      )}

      <Container className="px-5" fluid>
        {showPage && (
          <Card style={{ height:"345px"}}>
            <Card.Body>
              <Col className="mb-4">
                <Row lg={4}>
                  <Stack direction="horizontal" gap={10}>
                    <h4 className="pt-1">Total Items</h4>
                    <span className="mb-1">
                      {bulkImport ? bulkImport.length : 0}
                    </span>
                  </Stack>
                </Row>
                <Row lg={4}>
                  <Stack direction="horizontal" gap={10}>
                    <h4 className="pt-1">Valid Items</h4>
                    <span className="mb-1">{validCount ? validCount : 0}</span>
                  </Stack>
                </Row>
                <Row lg={4}>
                  <Stack direction="horizontal" gap={10}>
                    <h4 className="mb-1">Invalid Items</h4>
                    <Button
                      variant="link"
                      className="pt-1"
                      style={{ marginLeft: "-12%" }}
                      onClick={handleToggleErrors}
                    >
                      {invalidCount ? invalidCount : 0} Export Errors
                    </Button>
                  </Stack>
                </Row>
              </Col>

              {errorsVisible && (
                <Collapse
                  className="d-flex justify-content-start mb-2"
                  in={errorsVisible}
                >
                  <div>
                    {errors.length > 0 && (
                      <Alert className="p-2"
                        style={{
                         maxHeight: "50px",
                          overflow: "auto",
                          width:"40%",
                          backgroundColor:"#ffe2d2",
                          borderColor:"#FFCFB3"
                        }}
                      >
                        <ul
                          style={{
                            listStyleType: "disc",
                            paddingLeft: "15px",
                          }}
                        >
                          {errors.map((error, index) => (
                            <li key={index}>
                              Row {error.Line}: {error.Field} - {error.Message}
                            </li>
                          ))}
                        </ul>
                      </Alert>
                    )}
                  </div>
                </Collapse>
              )}
              <Row className="pb-4">
                <Col className="d-flex justify-content-start px-1">
                  <Button
                    variant="primary"
                    ref={buttonRef}
                    onClick={() => setConfirmVisible(true)}
                  >
                    {i18n("Proceed to Import")}
                  </Button>
                </Col>
              </Row>
              <Row>
                <Col className="d-flex justify-content-start px-2">
                  <p className="text-muted">
                    <b>Note:</b> Only Valid Items will be Imported.
                  </p>
                </Col>
              </Row>

              <ConfirmPopup
                target={buttonRef.current}
                visible={confirmVisible}
                onHide={() => setConfirmVisible(false)}
                message="Are you sure you want to proceed?"
                icon="pi pi-exclamation-triangle"
                accept={acceptImport}
                reject={rejectImport}
              />

              {importing && (
                <div className="mt-8">
                  <ProgressBar
                    style={{ width: "63%" }}
                    variant="success"
                    now={progress}
                    label={`${Math.round(progress)}%`}
                  />
                  {progress < 100 && (
                    <p>
                      Importing {recordsProcessed} / {bulkImport.length} records
                    </p>
                  )}
                </div>
              )}

              {importSuccess && progress === 100 && (
                <Alert variant="success" style={{ width: "63%" }}>
                  <i
                    className="bi bi-check-circle-fill"
                    style={{ marginRight: "8px" }}
                  ></i>
                  Import successfully completed!
                </Alert>
              )}
              {importSuccess && (
              <p>You will be redirected in 2 seconds...</p>
            )}
            </Card.Body>
          </Card>
        )}
      </Container>
      </Layout>
    </>
  );
};

export default PartyImport;
