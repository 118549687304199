import { useState, useEffect } from "react";
import DeleteModel from "../../shared/components/DeleteModel";
import Header from "../../shared/components/Header";
import Layout from "../../shared/components/Layout";
import { Button, Container, Form, Stack, Image, InputGroup, ButtonGroup } from "react-bootstrap";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { i18n } from "../../shared/helpers/sharedMethods";
import {
  deteteEnquiry,
  getEnquiry,
  selectAllEnquiries,
  resetEnquiry,
} from "../store/EnquirySlice";
import CreateEnquiry from "./CreateEnquiry";
import { ToastContainer, toast } from "react-toastify";
import {
  EnquiryToPartyReq,
  resetCreateEnquiry,
} from "../store/CreateEnquirySlice";
import EnquiryDeails from "./EnquiryDetails";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare, faTrash } from "@fortawesome/free-solid-svg-icons";
import TwoStageLayoutHeader from "../../shared/components/TwoStatgeLayoutHeader";
import EditEnquiry from "./EditEnquiry";
import { Dropdown } from "react-bootstrap";
import { downloadCsvFile, handleExportClick } from "../../shared/export";
import { exportPreProcessor } from "../helper/enquiryHelper";
import { UilEdit, UilTrashAlt,UilAngleDown } from "@iconscout/react-unicons";
import ShortcutKeys from "../../shared/components/ShortcutKeys";
import DataGridHeader from "../../shared/components/DataGridHeader";
import { UilUserPlus } from "@iconscout/react-unicons";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import CustomeSortIcon from "../../shared/components/CustomeSortIcon";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import LoadingSpinner from "../../shared/components/LoadingSpinner";
import { Tag } from "primereact/tag";
import { format } from "date-fns";
import { Calendar } from "primereact/calendar";
import { MultiSelect } from "primereact/multiselect";
import { getLocalizedDate } from "../../shared/helpers/localizationHelper";
const EnquiryStack = ({ featureName }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { accessToken, isLoggedIn } = useSelector((state) => state?.auth);
  const createdEnquiry = useSelector(
    (state) => state.createEnquiry?.savedEnquiryEntity
  );
  const isConvertedToParty = useSelector(
    (state) => state.createEnquiry.isConvertedToParty
  );
  const selectedBusiness = useSelector(
    (state) => state?.auth?.selectedBusiness
  );
  const tenantId = selectedBusiness?.pivot?.tenant_id;
  const loading = useSelector((state) => state.enquiry.loading);
  const status = useSelector((state) => state.createEnquiry.status);
  const isEdit = useSelector((state) => state.createEnquiry.isEdit);
  const isDelete = useSelector((state) => state.enquiry.isEdit);
  const [deleteName, setDeleteName] = useState();
  const allData = useSelector(selectAllEnquiries);
  const [enquiries, setEnquiries] = useState([]);
  const [deleteId, setDeteteId] = useState();
  const [showDetails, setShowDetails] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [showEnquiryEdit, setShowEnquiryEdit] = useState(false);
  const [showEnquiryCreate, setShowEnquiryCreate] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});
  const [refreshKey, setRefreshKey] = useState(0);
  const [sortField, setSortField] = useState(null);
  const [sortOrder, setSortOrder] = useState(1);
  const [selectedstatus, setSelectedStatus] = useState([]);
  const [filters, setFilters] = useState(null);
  const [statusOptions, setStatusOptions] = useState([]);
  const [globalFilter, setGlobalFilter] = useState("");
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const globalPlanFeatures = useSelector(
    (state) => state?.globalPlan?.features
  );


  useEffect(() => {
    if (!globalPlanFeatures.includes(featureName)) {
      navigate("/setting/not-in-plan");
    }
  }, [])

  useEffect(() => {
    if (allData) {
      setEnquiries(allData);
    }
  }, [allData]);

  const handleEnquiryRefresh = () => {
    setEnquiries([])
    dispatch(getEnquiry({
      token: accessToken.access_token,
      tenant_id: tenantId,
    }));
    dispatch(resetEnquiry());
    setRefreshKey((prevKey) => prevKey + 1);
  };
  useEffect(() => {
    // if (status) {
    //   ;
    //   setShowEnquiryCreate(false);
    //   toast.success("Enquiry created Successfully");
    //   //dispatch(pushNewEnquiry(createdEnquiry))
    //   dispatch(resetCreateEnquiry());

    dispatch(
      getEnquiry({
        token: accessToken.access_token,
        tenant_id: tenantId,
      })
    );
    // }
  }, [selectedBusiness]);

  // useEffect(() => {
  //     if (isEdit) {
  //         
  //         toast.success("Enquiry Edited Successfully")
  //         setShowEnquiryEdit(false)
  //         dispatch(resetCreateEnquiry())
  //         dispatch(getEnquiry({
  //             token: accessToken.access_token,
  //             tenant_id: tenantId,
  //         }))
  //     }
  // }, [isEdit])

  useEffect(() => {
    setShowDelete(false);
  }, [isDelete]);

  useEffect(() => {
    if (isConvertedToParty) {
      ;
      toast.success("Enquiry Converted to Party Successfully");
      dispatch(
        getEnquiry({
          token: accessToken.access_token,
          tenant_id: tenantId,
        })
      );
    }
  }, [isConvertedToParty]);

  // const filterData =
  //     allData &&
  //     allData.length > 0 &&
  //     allData.filter((item) => item != undefined);

  useEffect(() => {
    // 
    dispatch(
      getEnquiry({
        token: accessToken.access_token,
        tenant_id: tenantId,
      })
    );
  }, []);
  const handleClick = () => {
    // navigate("/enquiry/createEnquiry");
    setShowEnquiryCreate(true);
  };

  const handleRowClick = (row) => {
    // 
    setSelectedRow(row);
    setShowDetails(!showDetails);
  };
  const dateTimeString = new Date()
    .toLocaleString()
    .replace(/[-]/g, " ")
    .replace(/:/g, "-");
  const fileName = `party_${dateTimeString}`;

  const handleDelete = async (data) => {
    setDeteteId(data.id);
    setDeleteName(data.name);
    setShowDelete(!showDelete);
  };
  const handleEdit = (data) => {
    setSelectedRow(data);
    setShowEnquiryEdit(true);
    setShowDetails(false);
  };


  const setLeadStatusLabel = (data) => {
    const severityClass = getSeverity(data.lead_status);
    const statusMessage = data.status === 1 ? "open" : "closed";
    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Tag value={statusMessage} className={severityClass} />
      </div>
    );
  };

  const actionBodyTemplate = (row) => {
    return (
      <div className="">
        <UilEdit
          className="mx-2"
          size="13"
          style={{ cursor: "pointer" }}
          onClick={(e) => {
            e.stopPropagation();
            handleEdit(row);
          }}
        />
        &nbsp;
        <UilTrashAlt
          className="m-0"
          size="13"
          style={{ cursor: "pointer" }}
          onClick={(e) => {
            e.stopPropagation();
            handleDelete(row);
          }}
        />
      </div>
    );
  };

  const onDelete = () => {
    dispatch(
      deteteEnquiry({
        token: accessToken.access_token,
        tenant_id: tenantId,
        id: deleteId,
      })
    );
    setShowDelete(false);
    setShowDetails(false);
  };

  const closeDetailModel = () => {
    setShowDetails(false);
  };

  const handleConvertToParty = (data) => {
    var payload = {
      name: data["name"],
      mobile: data["mobile"],
      id: data["id"],
      lead_status: data["lead_status"],
      created_by: data["created_by"],
    };
    dispatch(
      EnquiryToPartyReq({
        token: accessToken.access_token,
        tenant_id: tenantId,
        data: payload,
      })
    );
    setShowDetails(false);
  };

  const [exportData, setExportData] = useState([]);
  const [csvData, setCsvData] = useState("");

  const handleEnquiryExport = () => {
    const exportData = exportPreProcessor(allData);
    setExportData(exportData);
    const filename = `enquiries_${dateTimeString}`;
    handleExportClick(
      exportData,
      (exportData) => {
        setCsvData(exportData);
      },
      downloadCsvFile,
      filename
    );
  };
  const headerCellStyle = {
    fontWeight: "bold",
    fontSize: "11px",
  };

// Enquiry lead status filter

const getSeverity = (status) => {
  switch (status) {
    case "new":
      return 'custom-tag-new';
    case "open":
      return 'custom-tag-open';
    case "closed":
      return 'custom-tag-closed';
    case "interested":
      return 'custom-tag-interested';
    case "not_interested":
      return 'custom-tag-not-interested';
    case "converted":
      return 'custom-tag-converted';
    default:
      return '';
  }
};

  const leadStatusLabel = {
    'new' : 'New', 
    'open' : 'Open', 
    'interested':'Interested', 
    'not_interested' : 'Not Interested', 
    'converted' : 'Converted', 
    'closed' : 'Closed'
  }

  useEffect(() => {
    const options = [
      {name : 'new', label: 'New'}, 
      {name : 'open',label:'Open'}, 
      {name:'interested',label:'Interested'}, 
      {name : 'not_interested',label:'Not Interested'}, 
      {name : 'converted',label:'Converted'}, 
      {name : 'closed',label:'Closed'},
    ];
    setStatusOptions(options);
  }, []);

  const statusItemTemplate = (option) => {
    const severityClass = getSeverity(option.name);
    return (
      <div className={`d-flex align-items-center ${severityClass}`}>
        <Tag value={option.label} className={severityClass} />
      </div>
    );
  };

  const handleStatusChange = (e) => {
    setSelectedStatus(e.value);    
    if (!Array.isArray(e.value)) {
      console.error('Expected e.value to be an array, but got:', e.value);
      return;
    }
  
    if (e.value.length === 0) {
      setEnquiries(allData);
    } else {
      const statusNames = e.value.map(item => item.name);  
      const filteredStatus = allData.filter(data => {
        return statusNames.includes(data.lead_status);
      });
  
      setEnquiries(filteredStatus);
    }
  };

  const statusFilterTemplate = (options) => {
    return (
      <MultiSelect
        value={selectedstatus}
        options={statusOptions}
        optionLabel="label"
        itemTemplate={statusItemTemplate}
        onChange={handleStatusChange}
        className="p-column-filter custom-multiselect"
        style={{ fontSize: '11px' }}
      />
    );
  };

  // Enquiry lead status filter logic ends here

  const DateFormatChange = (allData) => {
    return format(new Date(allData.created_at), 'dd/MM/yyyy');
  };

  const statusBodyTemplate = (allData) => {
    const severityClass = getSeverity(allData.lead_status);
    const statusMessage = leadStatusLabel[allData.lead_status]
    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Tag value={statusMessage} className={severityClass} />
      </div>
    );
  };
  
  
  const handleCreatedAtDateFilterChange = (value, filterCallback) => {
    filterCallback(value);

    if (value) {
      const filtered = allData.filter((allData) => {
        const createdAtDate = new Date(allData.created_at);
        const selectedDate = new Date(value);
        return createdAtDate.toDateString() === selectedDate.toDateString();
      });
      setEnquiries(filtered);
    } else {
      setEnquiries(allData);
    }
  };
  const dateCreatedAtBodyTemplate = (rowData) => {
    return getLocalizedDate(rowData.created_at);
  };
  const dateFilterTemplate = (options) => {
    return (
      <Calendar
        value={options.value}
        onChange={(e) => handleCreatedAtDateFilterChange(e.value, options.filterCallback)}
        dateFormat="dd/mm/yy"
        mask="99/99/9999"
      />
    );
  };

  const customSort = (field) => {
    if (field === 'created_at') {
      setSortOrder(-1); 
    } else {
      setSortField(field);
      setSortOrder(-1);
    }
  };

  const sortedData =  [...enquiries].sort((a, b) => {
    const dateA = new Date(a.created_at);
    const dateB = new Date(b.created_at);
    
    return dateB - dateA; 
  });

  const handleClearFilters = () => {
    setSelectedStatus([]);
    setFilters({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      "id": { value: null, matchMode: FilterMatchMode.CONTAINS },
      "name": { value: null, matchMode: FilterMatchMode.CONTAINS },
      "mobile": { value: null, matchMode: FilterMatchMode.CONTAINS },
      "lead_status": { value: null, matchMode: FilterMatchMode.CONTAINS },
     'created_at': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
     status: { value: null, matchMode: 'in' },
    });
    setGlobalFilter("");
    setGlobalFilterValue("");
    setEnquiries(allData);
  };

  const header = (
    <TwoStageLayoutHeader backButtonRemove={true}
      ItemActions={
        <Stack gap={3} direction="horizontal" className="mt-2 mb-2 float-end">
          {/* {selectedRow.status ? <Button
                        className=""
                        variant="outline-secondary"
                        onClick={() => handleConvertToParty(selectedRow)}
                    >
                        <FontAwesomeIcon className="mx-2 mb-1" icon={faPenToSquare} />
                        {"Convert to Party"}
                    </Button> : <></>} */}

          <InputGroup className="me-3 custom-input">
            <Button
              variant="outline-secondary"
              onClick={() => handleEdit(selectedRow)}
              size="sm"
              style={{ padding: '0.5rem', background: 'none' }}
            >
              <FontAwesomeIcon className="mx-2 mb-1" icon={faPenToSquare} />
              {i18n("Edit")}
            </Button>

            <Dropdown as={ButtonGroup}>
              <Dropdown.Toggle
                variant="outline-secondary"
                id="dropdown-basic"
                size="sm"
                style={{ height: '36px' }}
              >
                <UilAngleDown size="16" />
              </Dropdown.Toggle>

              <Dropdown.Menu className="custom-dropdown-menu">
                <Dropdown.Item
                  as="button"
                  onClick={() => handleDelete(selectedRow)}
                  style={{ color: 'red' }}
                >
                  <span className="d-flex align-items-center">
                    <FontAwesomeIcon className="mx-2 mb-1" icon={faTrash} style={{ color: 'red' }} />
                    {i18n("Delete")}
                  </span>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </InputGroup>
        </Stack>
      }
      currentTitle={
        <>
          {selectedRow?.name}
          {selectedRow?.status ? (
            <Form.Label className="mx-2 badge bg-primary bg-gradient">
              Open
            </Form.Label>
          ) : (
            <Form.Label className="mx-2 badge bg-info bg-gradient">
              Closed
            </Form.Label>
          )}
        </>
      }
      returnToGrid={closeDetailModel}
    />
  );

  return (
    <>
      <ToastContainer />
      <Layout>
        {loading && <LoadingSpinner />}
        <Header
          currentTitle={i18n("Enquiries")}
          ItemActions={
            <Stack
              gap={3}
              direction="horizontal"
              className="my-2 justify-content-end"
            >
              <ShortcutKeys />
            </Stack>
          }
        />
        <Container fluid className="px-5">
          <div className="border" style={{ background: "white" }}>
            <DataGridHeader
              allData={allData}
              data={enquiries}
              addFilteredData={setEnquiries}
              clearFilter={handleClearFilters}
              handleEnquiryRefresh={handleEnquiryRefresh}
              handleEnquiryExport={handleEnquiryExport}
              title="Enquiries"
              icons={<UilUserPlus size="50" color="gray" />}
              ItemActions={
                <Stack
                  gap={3}
                  direction="horizontal"
                  className="justify-content-end"
                >
                  <Button
                    onClick={handleClick}
                  >
                    {i18n("Create Enquiry")}
                  </Button>
                </Stack>
              }
            />
            <Container fluid className="px-4 mb-4">
              <div className="bordered-container">
                <DataTable
                  value={sortedData}
                  // isAllData={"All Enquiries"}
                  button="Create Enquiry"
                  title="Enquiry"
                  paginator
                  globalFilter={globalFilter}
                  filterDisplay="row"
                  rows={10}
                  dataKey="id"
                  filters={filters}
                  // defaultSortField="created_at"
                  onFilter={(e) => setFilters(e.filters)}
                  // handleEnquiryRefresh={handleEnquiryRefresh}
                  // handleEnquiryExport={handleEnquiryExport}
                  emptyMessage={i18n(
                    "No Records Found."
                  )}
                  className="p-datatable-gridlines full-width-table custom-datatable p-component"
                  onRowClick={(e) => handleRowClick(e.data)}
                  scrollable
                  sortMode="multiple"
                  scrollHeight="400px"
                  removableSort
                  style={{ cursor: "pointer" }}

                >
                  <Column
                    field="id"
                    header={() => (
                      <div
                        className={`custom-header-${sortField === "id"
                          ? sortOrder === 1
                            ? "up"
                            : "down"
                          : ""
                          }`}
                        onClick={() => customSort("id")}
                        style={{
                          cursor: "pointer",
                          display: "flex",
                          alignItems: "center",
                          fontSize: "11px",
                          lineHeight: "11px",
                        }}
                      >
                        {i18n("#")}
                        <CustomeSortIcon
                          sortOrder={sortField === "id" ? sortOrder : 0}
                          style={{ marginLeft: "4px", verticalAlign: "middle" }}
                        />
                      </div>
                    )}
                    sortable
                    filter
                    filterPlaceholder=""
                    headerStyle={headerCellStyle}
                    showFilterMatchModes={false}
                    showClearButton={false}
                    showFilterOperator={false}
                    showAddButton={false}
                    filterMatchMode={FilterMatchMode.CONTAINS}
                    showApplyButton={false}
                    showFilterMenu={false}
                  />

                  <Column
                    field="name"
                    header={() => (
                      <div
                        className={`custom-header-${sortField === "name"
                          ? sortOrder === 1
                            ? "up"
                            : "down"
                          : ""
                          }`}
                        onClick={() => customSort("name")}
                        style={{
                          cursor: "pointer",
                          display: "flex",
                          alignItems: "center",
                          fontSize: "11px",
                          lineHeight: "11px",
                        }}
                      >
                        {i18n("Name")}
                        <CustomeSortIcon
                          sortOrder={sortField === "name" ? sortOrder : 0}
                          style={{ marginLeft: "4px", verticalAlign: "middle" }}
                        />
                      </div>
                    )}
                    sortable
                    filter
                    filterPlaceholder=""
                    headerStyle={headerCellStyle}
                    showFilterMatchModes={false}
                    showClearButton={false}
                    showFilterOperator={false}
                    showAddButton={false}
                    filterMatchMode={FilterMatchMode.CONTAINS}
                    showApplyButton={false}
                    showFilterMenu={false}
                  />

                  <Column
                    field="mobile"
                    header={() => (
                      <div
                        className={`custom-header-${sortField === "mobile"
                          ? sortOrder === 1
                            ? "up"
                            : "down"
                          : ""
                          }`}
                        onClick={() => customSort("mobile")}
                        style={{
                          cursor: "pointer",
                          display: "flex",
                          alignItems: "center",
                          fontSize: "11px",
                          lineHeight: "11px",
                        }}
                      >
                        {i18n("Mobile")}
                        <CustomeSortIcon
                          sortOrder={sortField === "mobile" ? sortOrder : 0}
                          style={{ marginLeft: "4px", verticalAlign: "middle" }}
                        />
                      </div>
                    )}
                    sortable
                    filter
                    filterPlaceholder=""
                    headerStyle={headerCellStyle}
                    showFilterMatchModes={false}
                    showClearButton={false}
                    showFilterOperator={false}
                    showAddButton={false}
                    filterMatchMode={FilterMatchMode.CONTAINS}
                    showApplyButton={false}
                    showFilterMenu={false}
                  />

                  <Column
                    field="lead_status"
                    body={statusBodyTemplate}
                    header={() => (
                      <div
                        className={`custom-header-${sortField === "lead_status"
                          ? sortOrder === 1
                            ? "up"
                            : "down"
                          : ""
                          }`}
                        onClick={() => customSort("lead_status")}
                        style={{
                          cursor: "pointer",
                          display: "flex",
                          alignItems: "center",
                          fontSize: "11px",
                          lineHeight: "11px",
                        }}
                      >
                        {i18n("Lead Status")}
                        <CustomeSortIcon
                          sortOrder={sortField === "lead_status" ? sortOrder : 0}
                          style={{ marginLeft: "4px", verticalAlign: "middle" }}
                        />
                      </div>
                    )}
                    sortable
                    filter
                    filterPlaceholder=""
                    headerStyle={headerCellStyle}
                    filterElement={statusFilterTemplate}
                    showFilterMatchModes={false}
                    showClearButton={false}
                    showFilterOperator={false}
                    showAddButton={false}
                    filterMatchMode={FilterMatchMode.CONTAINS}
                    showApplyButton={false}
                    showFilterMenu={false}
                  />
                  <Column
                    field="product.name"
                    header={() => (
                      <div
                        className={`custom-header-${sortField === "product.name" ? (sortOrder === 1? "up": "down"): "" }`}
                        onClick={() => customSort("product.name")}
                        style={{
                          cursor: "pointer",
                          display: "flex",
                          alignItems: "center",
                          fontSize: "11px",
                          lineHeight: "11px",
                        }}
                      >
                        {i18n("Product")}
                        <CustomeSortIcon
                          sortOrder={sortField === "product.name" ? sortOrder : 0}
                          style={{ marginLeft: "4px", verticalAlign: "middle" }}
                        />
                      </div>
                    )}
                    sortable
                    filter
                    filterPlaceholder=""
                    headerStyle={headerCellStyle}
                    showFilterMatchModes={false}
                    showClearButton={false}
                    showFilterOperator={false}
                    showAddButton={false}
                    filterMatchMode={FilterMatchMode.CONTAINS}
                    showApplyButton={false}
                    showFilterMenu={false}
                  />
                  <Column
                    field="created_at"
                    header={() => (
                      <div
                        className={`custom-header-${sortField === 'created_at' ? (sortOrder === 1 ? 'up' : 'down') : ''}`}
                        onClick={() => customSort('created_at')}
                        style={{
                          cursor: 'pointer',
                          display: 'flex',
                          alignItems: 'center',
                          fontSize: '11px',
                          lineHeight: '11px',
                        }}
                      >
                        {i18n("Created On")}
                        <CustomeSortIcon sortOrder={sortField === 'created_at' ? sortOrder : 0}
                          style={{ marginLeft: '4px', verticalAlign: 'middle' }} />
                      </div>
                    )}
                    sortable
                    // body={DateFormatChange}
                    body={dateCreatedAtBodyTemplate}
                    filter
                    filterElement={dateFilterTemplate}
                    filterPlaceholder=""
                    headerStyle={headerCellStyle}
                    showFilterMatchModes={false}
                    showClearButton={false}
                    showFilterOperator={false}
                    showAddButton={false}
                    filterMatchMode={FilterMatchMode.DATE_IS}
                    showFilterMenu={false}
                    bodyStyle={{ width: '15%' }}
                  />
                </DataTable>
              </div>
            </Container>
            {showEnquiryCreate && (
              <CreateEnquiry
                show={showEnquiryCreate}
                setShowEnquiryCreate={setShowEnquiryCreate}
              />
            )}

            {showEnquiryEdit && (
              <EditEnquiry
                data={selectedRow}
                show={showEnquiryEdit}
                propInitialValues={selectedRow}
                setShowEnquiryEdit={setShowEnquiryEdit}
              />
            )}

            {showDetails && (
              <EnquiryDeails
                details={selectedRow}
                show={showDetails}
                setShow={setShowDetails}
                header={header}
              />
            )}

            {showDelete && (
              <DeleteModel
                deleteUserClick={onDelete}
                onClickDeleteModel={setShowDelete}
                setShow={setShowDetails}
                name={deleteName}
              />
            )}
          </div>
        </Container>
      </Layout>
    </>
  );
};

export default EnquiryStack;
