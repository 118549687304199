import React, { useEffect, useState } from "react";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  ListGroup,
  Button,
  Form,
  Row,
  Col,
  Tooltip,
  OverlayTrigger,
  InputGroup,
} from "react-bootstrap";
import {
  planData,
  selectAllPackageAddon,
  selectAllPackagePlan,
  selectProductAsSubscriptionAddon,
  selectProductAsSubscriptionItems,
  selectProductAsSubscriptionPlans,
  selectProductAsSubscriptionSetupFee,
} from "../../store/subscription_item_store/GetPackagesItemSlice";
import { i18n, placeholderText } from "../../../shared/helpers/sharedMethods";
import { useDispatch, useSelector } from "react-redux";
import { UilParcel } from "@iconscout/react-unicons";
import { useNavigate } from "react-router";
import CreatePackage from "../../screens/package_screen/CreatePackage";
import { resetParentProduct } from "../../store/subscription_item_store/PackageEntitySlice";
import { resetPackage } from "../../store/subscription_item_store/CreatePackageSlice";
import { key } from "localforage";
import { capitalizeMethod } from "../../../shared/helpers/captilize";
const PackageList = ({ currentItemId }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const count = useSelector(
    (state) => state.getPackage?.count?.subscription_counts
  );

  const allPackages = useSelector((state) =>
    selectProductAsSubscriptionItems(state, null)
  );
  const { accessToken, isLoggedIn } = useSelector((state) => state?.auth);
  const selectedBusiness = useSelector(
    (state) => state?.auth?.selectedBusiness
  );

  const tenantId = selectedBusiness?.pivot?.tenant_id;
  ;
  const [searchString, setSearchString] = useState("");
  const [filterItems, setFilteredItems] = useState([allPackages]);
  const [show, setShow] = useState(false);

  useEffect(() => {
    dispatch(
      planData({ token: accessToken.access_token, tenant_id: tenantId })
    );
  }, []);

  useEffect(() => {
    setFilteredItems(allPackages);
  }, [allPackages]);

  const handlePackageCreate = () => {
    setShow(true);
    dispatch(resetPackage());
    dispatch(resetParentProduct());
  };

  const handleClick = (id) => {
    ;
    navigate("/subscription/packages/" + id);
  };

  useEffect(() => {
    const updatedItems =
    allPackages &&
    allPackages.length > 0 &&
    allPackages.map((item) => {
        const matchedCount =
          count &&
          count?.length > 0 &&
          count?.find((c) => c.product_id == item.id);

        return {
          ...item,
          subscription_count: matchedCount ? matchedCount?.count : 0,
        };
      });

    setFilteredItems(updatedItems);
  }, [count,allPackages]);

  

  const handleSearch = (searchParam) => {
    const searchLowercase = searchParam.toLowerCase();
    setSearchString(searchParam);
    const filtered =
      allPackages &&
      allPackages.filter((datum) =>
        datum?.name.toLowerCase().includes(searchLowercase)
      );
    setFilteredItems(filtered || []);
  };
  ;
  const Back = <Tooltip id="button-tooltip">{i18n("Back")}</Tooltip>;
  const Search = <Tooltip id="button-tooltip">{i18n("Search")}</Tooltip>;
  const Create = <Tooltip id="button-tooltip">{i18n("Create")}</Tooltip>;
  return (
    <>
      <Row className="">
        <InputGroup size="">
          <OverlayTrigger placement="bottom" overlay={Search}>
            <Form.Control
              type="text"
              id="search"
              name="search"
              placeholder={placeholderText("Search by Name")}
              onChange={(e) => {
                handleSearch(e.target.value);
              }}
              value={searchString}
              className=""
              style={{
                borderTop: "none",
                borderBottom: "none",
                borderLeft: "none",
                fontSize:'1rem'
              }}
              
            />
          </OverlayTrigger>
          <OverlayTrigger placement="bottom" overlay={Create}>
            <Button
              variant="outline-primary"
              onClick={handlePackageCreate}
              style={{ border: "none",fontSize:'inherit' }}
            >
              <FontAwesomeIcon className="mb-1" icon={faPlus} />{" "}
              {i18n("Create")}
            </Button>
          </OverlayTrigger>
        </InputGroup>
      </Row>

      <ListGroup
        className="px-2"
        style={{
          height: "85vh",
          overflow: "auto",
          //gap: "4px",
          overflowX: "hidden",
          borderTop: "1px solid lightgray",
        }}
      >
        {filterItems.length > 0 ? (
          filterItems &&
          filterItems.map((item, index) => {
            
            const plan = item?.child_products?.filter(
              (child) => child.sub_type == "plan"
            );

            const addon = item?.child_products?.filter(
              (child) => child.sub_type == "addon"
            );
            return (
              
                <ListGroup.Item
                key={item.id ? item.id : `item-${index}`}
                  className="mt-3 voucherlist cursor-pointer"
                  active={item.id == currentItemId}
                  onClick={() => {
                    handleClick(item.id);
                  }}
                >
                  <Form.Group>
                    <Form.Label style={{fontSize:'1rem'}}>{item.name ? capitalizeMethod(item.name) : ""}</Form.Label>
                  </Form.Group>
                  <Form.Group>
                    <Row className="twostage-sidebar-list">
                      <Col lg={12}>
                        <Form.Label className=" mb-0">
                          {" "}
                          <Form.Text style={{fontSize:'1rem'}}>
                            {plan?.length > 0 ? plan?.length : 0}
                          </Form.Text>
                        </Form.Label>
                        <Form.Label className="mx-1 mb-0">
                          {" "}
                          <Form.Text style={{fontSize:'1rem'}}>{i18n("Plans ")} &#9679;</Form.Text>
                        </Form.Label>
                        <Form.Label className=" mb-0">
                          {" "}
                          <Form.Text style={{fontSize:'1rem'}}>
                            {addon?.length > 0 ? addon?.length : 0}
                          </Form.Text>
                        </Form.Label>
                        <Form.Label className="mx-1 mb-0">
                          {" "}
                          <Form.Text style={{fontSize:'1rem'}}>{i18n("Addons")} &#9679;</Form.Text>
                        </Form.Label>
                        <Form.Label className="mb-0">
                          {" "}
                          <Form.Text style={{fontSize:'1rem'}}>{item?.subscription_count}</Form.Text>
                        </Form.Label>
                        <Form.Label className="mx-1 mb-0">
                          {" "}
                          <Form.Text style={{fontSize:'1rem'}}> {i18n("Subscriptions")}</Form.Text>
                        </Form.Label>
                      </Col>
                    </Row>
                  </Form.Group>
                </ListGroup.Item>
              
            );
          })
        ) : (
          <div
            className="d-flex  justify-content-center align-items-center w-100"
            style={{ height: "65%" }}
          >
            <div style={{ textAlign: "center" }}>
              <UilParcel size="50" color="gray" />
              <Form.Group className="mt-3" style={{ textAlign: "center" }}>
                <Form.Text
                  className=""
                  style={{ fontSize: "12px", color: "gray" }}
                >
                  {i18n("Please create new package.")}
                </Form.Text>
              </Form.Group>
            </div>
          </div>
        )}
      </ListGroup>
      {show && <CreatePackage show={show} setShow={setShow} />}
    </>
  );
};

export default PackageList;
