import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
} from "@reduxjs/toolkit";
import { axiosRequest } from "../../shared/helpers/axiosHelper";
import { i18n } from "../../shared/helpers/sharedMethods";
import { preprocessorParty } from "../../party/helpers/partyHelper";

const partyAdapter = createEntityAdapter({});

const initialState = partyAdapter.getInitialState({
  status: "idle",
  error: null,
});

export const partyData = createAsyncThunk("partyGet/fetchparty", async (args) => {
  try {
    const token = args.token;
    const tenantId = args.tenant_id;
    const response = await axiosRequest({
      method: "GET",
      url: "parties",
      headers: {
        Authorization: `Bearer ${token}`,
        "X-Tenant": tenantId,
      },
    });
    return response.data;
  } catch (error) {
    await window.log.logToFile({ message: "Error while fetching party", level: "error" });
    throw { message: error.message || "Unknown error occurred" };
  }

});

const partySlice = createSlice({
  name: "partyGet",
  initialState,
  reducers: {
    resetSubscriptionParty: () => initialState,
  },
  extraReducers(builder) {
    builder
      .addCase(partyData.fulfilled, (state, action) => {
        state.status = "succeeded";
        partyAdapter.upsertMany(state, action.payload);
      })
      .addCase(partyData.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message || i18n("Unable to retrieve party data. Please try again later.");
      });
  },
});

export default partySlice.reducer;

export const { resetSubscriptionParty } = partySlice.actions;
export const {
  selectAll: selectAllPartyData,
  selectById: selectPartyDataById,
  selectIds: selectPartyDataIds,
} = partyAdapter.getSelectors((state) => state.partyGet);


export const selectPartyByType = createSelector(
  [selectAllPartyData, (state, type) => type],
  (partys, type) => {
      if (type === "All") {
        const processedParty = preprocessorParty(partys);
        return processedParty;
      }
    }
);