import { Col, Form, FormControl, Row } from "react-bootstrap";

import { useDispatch } from "react-redux";
import { ErrorMessage, Field } from "formik";
import { changeCode } from "../../../store/subscription_item_store/SetupFeesEntitySlice";
import { i18n } from "../../../../shared/helpers/sharedMethods";
import { taxList } from "../../../../shared/config/taxConfig";
import { filterTaxList } from "../../../../settings/components/helpers/settingHelpers";
import { Unit } from "../../../../shared/config/Units";
import { useEffect } from "react";
import { changeAddonUnit } from "../../../store/subscription_item_store/AddonEntitySlice";

const AddonUnits = ({ values, handleChange, touched, errors }) => {
  const dispatch = useDispatch();
  // const getSetting = useSelector((state) => state.getSetting?.dataSetting);
  // ;

  // const getUnits = JSON.parse(getSetting?.units);
  // ;

  // const UnitList = Unit.filter((unit) => getUnits.includes(unit.value));
  // ;

  // const getTax = JSON.parse(getSetting?.tax);
  // const TaxList = filterTaxList(taxList, getTax);
  // const defaultTax = getSetting?.default_tax ;


  return (
    <Row className="py-4">
    <Col lg={3}>
      <Form.Group>
        <Form.Label className="form-text-font">
          {i18n("Unit Name")}{" "}
        </Form.Label>
        <span className="required" />
      </Form.Group>
    </Col>
    <Col lg={4}>
      <Field name="unit">
        {({ field, form }) => (
          <Form.Select
            {...field}
            onChange={(e) => {
              const value = e.target.value;
              form.setFieldValue("unit", value ? value : "PCS");
              dispatch(changeAddonUnit(value));
            }}
            value={values.unit}
          >
            {Unit.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </Form.Select>
        )}
      </Field>
    </Col>
    <Col className=" text-color" style= {{marginTop: '1.2%'}}>
      {i18n("A name of your choice to refer to one unit of the plan.")}
    </Col>
  </Row>
  );
};

export default AddonUnits;