import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { axiosRequest } from "../../shared/helpers/axiosHelper";
import { i18n } from "../../shared/helpers/sharedMethods";

const initialState = {
  data: false,
  status: false,
  savedEntity: {},
  error: null,
  loading: false,
};

export const SubscriptionDashboard = createAsyncThunk(
  "subscriptionDashboard/fetch",
  async ({ id, token, tenantId, startDate, endDate }) => {
    try {
      const response = await axiosRequest({
        method: "GET",
        url: `subscriptions-summary/?startDate=${startDate}&endDate=${endDate}`,
        headers: {
          Authorization: `Bearer ${token}`,
          "X-Tenant": tenantId,
        },
      });
      return response;
    } catch (error) {
      await window.log.logToFile({
        message: "Error while fetching subscription summary",
        level: "error",
      });
      return { error: true, message: "Error submitting subscription" };
    }
  }
);

const subscriptionDashboardSlice = createSlice({
  name: "subscriptionDashboard",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
    .addCase(SubscriptionDashboard.pending, (state) => {
      state.loading = true; 
      state.error = null;
    })
      .addCase(SubscriptionDashboard.fulfilled, (state, action) => {
        state.savedEntity = action.payload;
        state.status = true;
        state.loading = false;
        state.error = null;
      })
      .addCase(SubscriptionDashboard.rejected, (state, action) => {
        state.status = false;
        state.loading = false;
        state.error = i18n("Item Creation failed. Please try again later.");
      })
  },
});

export default subscriptionDashboardSlice.reducer;
