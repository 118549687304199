import { Col, Container, Form, ListGroup } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { i18n, placeholderText } from "../../../../shared/helpers/sharedMethods";
import { useEffect, useState } from "react";
import { roundOffGrandTotal, unroundOffGrandTotal } from "../../../store/CreateSubscriptionSlice";
import GlobalCurrency from "../../../../shared/components/GlobalCurrency";


const SubscriptionSummary = () => {

  const currencySymbol = useSelector(
    (state) => state.getSetting?.dataSetting.currency
  );
  const {
    tax_total,
    grand_total,
    discount_amount,
    total_quantity,
    cess_total,
    addonShow,
    roundoff
  } = useSelector((state) => state.createSubscription);
  
  const [roundOff, setRoundOff] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    if (roundOff) {
      dispatch(roundOffGrandTotal());
    } else {
      dispatch(unroundOffGrandTotal());
    }
  }, [roundOff]);

  const handleRoundOff = (e) => {
    setRoundOff(!roundOff)
  };

  return (
    <ListGroup variant="flush" className=" rounded-1">
      <ListGroup.Item>
        <Form.Check
          inline
          label={placeholderText("Round Off")}
          name="round_off"
          type="checkbox"
          id="round_off_check"
          className="float-end mx-2"
          onChange={(e) => {
            setRoundOff(!roundOff);
          }}
          value={roundOff}
        />
      </ListGroup.Item>
      <ListGroup.Item className="balance-total px-0">
        {i18n("Balance")} :
        <span className="float-end">
<GlobalCurrency price = {grand_total} />
        </span>
      </ListGroup.Item>
    </ListGroup>
  );
};
export default SubscriptionSummary;
