export const exportPreProcessor = (data) => {
  if (!Array.isArray(data) || data.length === 0) {
    return [];
  }

  const productData = data.map((sales) => ({
    id: sales?.id || "-",
    name: sales?.name || "-",
    mobile: sales?.mobile || "-",
    product_id: sales?.product_id || "-",
    product: sales?.product?.name || "-",
    status: sales?.status || "-",
  }));

  return productData;
};
