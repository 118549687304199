
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import "../../../assets/css/frontend.css";
import { useLocation, useNavigate } from "react-router-dom";
import PermanentDeleteModel from "../../shared/components/PermanentDeleteModel";
import DataGridHeader from "../../shared/components/DataGridHeader";
// import PartyImport from "./PartyImport";
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Calendar } from 'primereact/calendar';
import Layout from "../../shared/components/Layout";
import { selectAllparty } from "../../party/store/getPartySlice";
import {
  Container,
  OverlayTrigger,
  Stack,
  Tooltip,
} from "react-bootstrap";
import Header from "../../shared/components/Header";
import { i18n } from "../../shared/helpers/sharedMethods";
import { ToastContainer, toast } from "react-toastify";
import ShortcutKeys from "../../shared/components/ShortcutKeys";
import { UilFilePlusAlt } from "@iconscout/react-unicons";
import { UilEdit, UilTrashAlt, UilHistory } from "@iconscout/react-unicons";
import { getLocalizedDate } from "../../shared/helpers/localizationHelper";
import CustomeSortIcon from "../../shared/components/CustomeSortIcon";
import { selectAllDeletedVoucher, getAllDeletedVoucher, deletedVoucherRestore, deleteVoucherPermanently, resetDeletedVoucher, selectSalesByType, selectPurchase } from "../../voucher/store/voucherDeletedSlice";
import DeletedRecordHeader from "../components/DeletedRecordHeader";
import RestoreModel from "../../shared/components/RestoreModel";
import GlobalCurrency from "../../shared/components/GlobalCurrency";
import PurchaseType from "../../voucher/components/PurchaseType";

const DeletedPurchaseIndex = (props) => {
  const { title, type, button, path, rootPath } = props;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const allPurchases = useSelector(state => selectPurchase(state, type));
  const saleType = useSelector((state) => state.VoucherDeletedRecords.saleType);
  const saleByType = useSelector((state) => selectSalesByType(state, saleType)) || [];
  const [show, setShow] = useState(false);

  const [displayedPurchase, setDisplayedPurchase] = useState(allPurchases);
  const [balanceFrozen, setBalanceFrozen] = useState(false);
  const [globalFilter, setGlobalFilter] = useState('');
  const [globalFilterValue, setGlobalFilterValue] = useState('');
  const [filters, setFilters] = useState(null);
  const [sortField, setSortField] = useState(null);
  const [sortOrder, setSortOrder] = useState(1);
  const [deleteId, setDeleteId] = useState();
  const [deleteName, setDeleteName] = useState();
  const [restoreShow, setShowRestore] = useState(false);
  const [restoreId, setRestoreId] = useState();
  const [restoreName, setRestoreName] = useState();

  const selectedBusiness = useSelector((state) => state?.auth?.selectedBusiness);
  const tenantId = selectedBusiness?.pivot?.tenant_id;
  const accessToken = useSelector((state) => state?.auth?.accessToken?.access_token);

  useEffect(() => {
    dispatch(getAllDeletedVoucher({ token: accessToken, tenantId: tenantId }));
  }, [dispatch, accessToken, tenantId]);

  useEffect(() => {
    setDisplayedPurchase(saleByType);
  }, [saleByType]);

  useEffect(() => {
    setDisplayedPurchase(allPurchases);
  }, [allPurchases]);

  const handleClearFilters = () => {
    setFilters({
      "party.name": { value: null, matchMode: FilterMatchMode.CONTAINS },
      voucher_number: { value: null, matchMode: FilterMatchMode.CONTAINS },
      paid_amount: { value: null, matchMode: FilterMatchMode.EQUALS },
      created_at: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
      deleted_at: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
    });
    setGlobalFilter('');
    setGlobalFilterValue('');
    setDisplayedPurchase(allPurchases);
  };

  const handleRestore = async () => {
    try {
      await dispatch(deletedVoucherRestore({ id: restoreId, token: accessToken, tenantId: tenantId }));
      setShowRestore(false);
      setDisplayedPurchase(prev => prev.filter(voucher => voucher.id !== restoreId));
      navigate("/setting/deleted-items/purchase");
      toast.success("Sale restored successfully");
    } catch (error) {
      toast.error("Error restoring sale");
    }
  };

  const handleParmanentDelete = async () => {
    try {
      await dispatch(deleteVoucherPermanently({ id: deleteId, token: accessToken, tenantId: tenantId }));
      setShow(false);
      setDisplayedPurchase(prev => prev.filter(voucher => voucher.id !== deleteId));
      navigate("/setting/deleted-items/purchase");
      toast.success("Sale deleted permanently");
    } catch (error) {
      toast.error("Error deleting sale");
    }
  };
  const handleRefreshVoucher = () => {
    dispatch(getAllDeletedVoucher({ token: accessToken, tenantId: tenantId }));
    dispatch(resetDeletedVoucher());
  };

  const onDelete = async (data) => {
    setDeleteId(data.id);
    setDeleteName(data.party);
    setShow(!show);
    navigate("/setting/deleted-items/purchase");
  };

  const onRestore = async (data) => {
    setRestoreId(data.id);
    setRestoreName(data.party);
    setShowRestore(!restoreShow);
    navigate("/setting/deleted-items/purchase");
  };

  const actionBodyTemplate = (row) => {
    return (
    <div className="d-flex justify-content-evenly align-items-center">
      <div title="Restore">
        <UilHistory
          className="mx-2 mb-1"
          size="15"
          style={{ cursor: "pointer" }}
          onClick={(e) => onRestore(row)}
        />
      </div>
      <div title="Delete">
        <UilTrashAlt
          className="mx-2 mb-1"
          size="15"
          style={{ cursor: "pointer" }}
          onClick={() => onDelete(row)}
        />
      </div>
    </div>
    );
  };

  const headerStyle = {
    backgroundColor: 'white',
  };

  const headerCellStyle = {
    fontWeight: 'bold',
    fontSize: '11px',
  };


  const dateBodyTemplate = (rowData) => {
    return getLocalizedDate(rowData.created_at);
  };

  const handleCreatedAtDateFilterChange = (value, filterCallback) => {
    filterCallback(value);

    if (value) {
      const filtered = allPurchases.filter((item) => {
        const createdAtDate = new Date(item.created_at);
        const selectedDate = new Date(value);
        return createdAtDate.toDateString() === selectedDate.toDateString();
      });
      setDisplayedPurchase(filtered);
    } else {
      setDisplayedPurchase(allPurchases);
    }
  };

  const dateFilterTemplate = (options) => {
    return (
      <Calendar
        value={options.value}
        onChange={(e) => handleCreatedAtDateFilterChange(e.value, options.filterCallback)}
        dateFormat="dd/mm/yy"
        mask="99/99/9999"
      />
    );
  };

  const deletedDateBodyTemplate = (rowData) => {
    return getLocalizedDate(rowData?.deleted_at);
  };

  const handleDeletedAtDateFilterChange = (value, filterCallback) => {
    filterCallback(value);

    if (value) {
      const filtered = allPurchases.filter((item) => {
        const deletedAtDate = new Date(item.deleted_at);
        const selectedDate = new Date(value);
        return deletedAtDate.toDateString() === selectedDate.toDateString();
      });
      setDisplayedPurchase(filtered);
    } else {
      setDisplayedPurchase(allPurchases);
    }
  };

  const deletedDateFilterTemplate = (options) => {
    return (
      <Calendar value={options.value} onChange={(e) => handleDeletedAtDateFilterChange(e.value, options.filterCallback)} dateFormat="dd/mm/yy" mask="99/99/9999" />
    );
  };

  const customSort = (field) => {
    setSortField(field);
    setSortOrder(sortOrder === 1 ? -1 : 1);
  };

  const sortedData = [...displayedPurchase].sort((a, b) => {
    if (sortField) {
      const compareValueA = a[sortField];
      const compareValueB = b[sortField];
      if (compareValueA < compareValueB) {
        return sortOrder;
      }
      if (compareValueA > compareValueB) {
        return -sortOrder;
      }
    }
    return 0;
  });

  return (
    <div>
      <Layout>
        <ToastContainer />
        <Header
          currentTitle={i18n("Purchases")}
          ItemActions={
            <Stack
              gap={3}
              direction="horizontal"
              className="my-2 justify-content-end"
            >
              <ShortcutKeys />
            </Stack>
          }
        />
        <Container fluid className="px-5">
          <div className="bordered-container container-spacing">
          <DeletedRecordHeader />
            <DataGridHeader
              handleRefreshVoucher={handleRefreshVoucher}
              clearFilter={handleClearFilters}
              allData={allPurchases}
              data={displayedPurchase}
              addFilteredData={setDisplayedPurchase}
              // datas={exportData}
              button={button}
              title={title}
              icons={<UilFilePlusAlt size="50" color="gray" />}
              PurchaseFilterActions={
                <Stack
                  gap={3}
                  direction="horizontal"
                  className=" justify-content-end"
                >
                  <PurchaseType />
                </Stack>
              }
            />
            <Container fluid className="px-4 mb-4">
              <div className="bordered-container">

                <DataTable
                  value={sortedData}
                  // headerStyle={headerStyle}
                  paginator
                  globalFilter={globalFilter}
                  filterDisplay="row"
                  // globalFilterFields={['due_date', 'voucher_number', 'status', 'party', 'grand_total', 'created_at', 'paid_amount']}
                  rows={10}
                  dataKey="id"
                  filters={filters}
                  emptyMessage={i18n("No deleted purchases found.")}
                  onFilter={(e) => setFilters(e.filters)}
                  className="p-datatable-gridlines full-width-table custom-datatable p-component"
                  checked={balanceFrozen}
                  defaultSortField="created_at"
                  defaultSortAsc={false}
                  scrollable
                  sortMode="multiple"
                  scrollHeight="400px"
                  removableSort
                  style={{ cursor: "pointer" }}
                >

                  <Column field="party"
                    header={() => (
                      <div
                        className={`custom-header-${sortField === 'party' ? (sortOrder === 1 ? 'up' : 'down') : ''}`}
                        onClick={() => customSort('party')}
                        style={{
                          cursor: 'pointer',
                          display: 'flex',
                          alignItems: 'center',
                          fontSize: '11px',
                          lineHeight: '11px',
                        }}
                      >
                        {i18n("Party")}
                        <CustomeSortIcon sortOrder={sortField === 'party' ? sortOrder : 0}
                          style={{ marginLeft: '4px', verticalAlign: 'middle' }} />
                      </div>
                    )} sortable filter filterPlaceholder=""
                    headerStyle={headerCellStyle}
                    showFilterMatchModes={false}
                    showClearButton={false}
                    showFilterOperator={false}
                    showAddButton={false}
                    filterMatchMode={FilterMatchMode.CONTAINS}
                    showApplyButton={false}
                    showFilterMenu={false}
                  /><Column field="voucher_number"
                  header={() => (
                    <div
                      className={`custom-header-${sortField === 'v' ? (sortOrder === 1 ? 'up' : 'down') : ''}`}
                      onClick={() => customSort('v')}
                      style={{
                        cursor: 'pointer',
                        display: 'flex',
                        alignItems: 'center',
                        fontSize: '11px',
                        lineHeight: '11px',
                      }}
                    >
                      {i18n("TXN No")}
                      <CustomeSortIcon sortOrder={sortField === 'v' ? sortOrder : 0}
                        style={{ marginLeft: '4px', verticalAlign: 'middle' }} />
                    </div>
                  )} sortable filter filterPlaceholder=""
                  headerStyle={headerCellStyle}
                  showFilterMatchModes={false}
                  showClearButton={false}
                  showFilterOperator={false}
                  showAddButton={false}
                  filterMatchMode={FilterMatchMode.CONTAINS}
                  showApplyButton={false}
                  showFilterMenu={false}
                />

                <Column field="paid_amount"
                  header={() => (
                    <div
                      className={`custom-header-${sortField === 'paid_amount' ? (sortOrder === 1 ? 'up' : 'down') : ''}`}
                      onClick={() => customSort('paid_amount')}
                      style={{
                        cursor: 'pointer',
                        display: 'flex',
                        alignItems: 'center',
                        fontSize: '11px',
                        lineHeight: '11px',
                      }}
                    >
                      {i18n("Paid Amount")}
                      <CustomeSortIcon sortOrder={sortField === 'paid_amount' ? sortOrder : 0}
                        style={{ marginLeft: '4px', verticalAlign: 'middle' }} />
                    </div>
                  )} sortable filter filterPlaceholder=""
                  headerStyle={headerCellStyle}
                  showFilterMatchModes={false}
                  showClearButton={false}
                  showFilterOperator={false}
                  showAddButton={false}
                  filterMatchMode={FilterMatchMode.CONTAINS}
                  showApplyButton={false}
                  showFilterMenu={false}
                  body={(rowData) => (
                    <span>
                      <GlobalCurrency price={rowData?.paid_amount} />
                    </span>
                  )}
                />

                  <Column
                    field="created_at"
                    header={() => (
                      <div
                        className={`custom-header-${sortField === 'created_at' ? (sortOrder === 1 ? 'up' : 'down') : ''}`}
                        onClick={() => customSort('created_at')}
                        style={{
                          cursor: 'pointer',
                          display: 'flex',
                          alignItems: 'center',
                          fontSize: '11px',
                          lineHeight: '11px',
                        }}
                      >
                        {i18n("Created At")}
                        <CustomeSortIcon sortOrder={sortField === 'created_at' ? sortOrder : 0}
                          style={{ marginLeft: '4px', verticalAlign: 'middle' }} />
                      </div>
                    )}
                    sortable
                    body={dateBodyTemplate}
                    filter
                    filterElement={dateFilterTemplate}
                    filterPlaceholder=""
                    headerStyle={headerCellStyle}
                    showFilterMatchModes={false}
                    showClearButton={false}
                    showFilterOperator={false}
                    showAddButton={false}
                    filterMatchMode={FilterMatchMode.DATE_IS}
                    // showApplyButton={false}
                    showFilterMenu={false}
                  />

                  <Column
                        field="deleted_at"
                        header={() => (
                          <div
                            className={`custom-header-${sortField === 'deleted_at' ? (sortOrder === 1 ? 'up' : 'down') : ''}`}
                            onClick={() => customSort('deleted_at')}
                            style={{
                              cursor: 'pointer',
                              display: 'flex',
                              alignItems: 'center',
                              fontSize: '11px',
                              lineHeight: '11px',
                            }}
                          >
                            {i18n("Deleted At")}
                            <CustomeSortIcon sortOrder={sortField === 'deleted_at' ? sortOrder : 0}
                              style={{ marginLeft: '4px', verticalAlign: 'middle' }} />
                          </div>
                        )}
                        sortable
                        body={deletedDateBodyTemplate}
                        filter
                        filterElement={deletedDateFilterTemplate}
                        filterPlaceholder=""
                        headerStyle={headerCellStyle}
                        showFilterMatchModes={false}
                        showClearButton={false}
                        showFilterOperator={false}
                        showAddButton={false}
                        filterMatchMode={FilterMatchMode.DATE_IS}
                        // showApplyButton={false}
                        showFilterMenu={false}
                      />

                  <Column header={i18n("Actions")} body={actionBodyTemplate}
                    headerStyle={headerCellStyle} />
                </DataTable>
              </div>
            </Container>
            {show && (
                <PermanentDeleteModel
                  onClickDeleteModel={setShow}
                  deleteUserClick={handleParmanentDelete}
                  name={deleteName}
                  show={show}

                />
              )}
              {restoreShow && (
                <RestoreModel
                  onClickRestoreModel={setShowRestore}
                  restoreUserClick={handleRestore}
                  name={restoreName}
                  show={restoreShow}

                />
              )}
          </div>
        </Container>
      </Layout>
    </div>
  );
};

export default DeletedPurchaseIndex;