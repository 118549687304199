import React, { useState } from 'react';
import { Modal, Button, Form, FormControl, FormLabel } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { addAddlCharges } from '../../../../store/posOrderSlice';
import { i18n, placeholderText } from '../../../../../shared/helpers/sharedMethods';

const EditAddlCharges = (props) => {
    const { charges  } = useSelector(state => state.posOrder)

    const { addlChargeModalShow, toggleAddlChargeModal } = props;
    const [chargeValue, setChargeValue] = useState('');
    const dispatch = useDispatch();

    const handleAddlChargeSave = () => {

        if(chargeValue == '' || chargeValue < 0){
            alert('Please enter valid amount');
            return;
        }

        let charge = {
            voucher_id : null,
            charge_type : charges.charge_type,
            charge_value : chargeValue,
            tax_code : null,
            tax_total : 0,
            net_total : chargeValue
        }

        dispatch(addAddlCharges(charge));
        toggleAddlChargeModal(false);
    }

    return (
        <Modal show={addlChargeModalShow} onHide={() => {toggleAddlChargeModal(false)}}>
            <Modal.Header closeButton>
                <Modal.Title>{i18n("Add Charges")}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <Form.Group className='calculation__filed-grp mb-2'>
                    <FormLabel>
                        {charges.charge_type}
                    </FormLabel>
                    <FormControl type='text' id='tax' name='tax' min='0' step='.01' placeholder={placeholderText("Charge Amount")}
                        onChange={(e) => { setChargeValue(e.target.value) }}
                        value={chargeValue}
                        className='rounded-1 pe-8'
                        autoFocus
                    />

                </Form.Group>


            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" type='submit' onClick={handleAddlChargeSave}>
                {i18n("Save Changes")}
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default EditAddlCharges;