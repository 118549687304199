import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
} from "@reduxjs/toolkit";
import { axiosRequest } from "../../shared/helpers/axiosHelper";
import { i18n } from "../../shared/helpers/sharedMethods";
const enquiryAdapter = createEntityAdapter({});

const initialState = enquiryAdapter.getInitialState({
  status: "idle",
  error: null,
  isDeleted: false,
  enquiriesData: [],
  loading:false,
});

export const getEnquiry = createAsyncThunk("enquiry/fetch", async (args) => {
  try {
  const token = args.token;
  const tenantId = args.tenant_id;

  const response = await axiosRequest({
    method: "GET",
    url: "enquiry",
    headers: {
      Authorization: `Bearer ${token}`,
      "X-Tenant": tenantId,
    },
  });
  ;
  return response.data;
} catch (error) {
  await window.log.logToFile({message: "Error while fetching enquiry", level: "error" });
  return error;
}
});

export const deteteEnquiry = createAsyncThunk("enquiry/deete", async (args) => {
  try {
  const token = args.token;
  const tenantId = args.tenant_id;
  const id = args.id;
  const response = await axiosRequest({
    method: "DELETE",
    url: `enquiry/${id}`,
    headers: {
      Authorization: `Bearer ${token}`,
      "X-Tenant": tenantId,
    },
  });
  ;
  return id;
} catch (error) {
  await window.log.logToFile({message: "Error while deleting enquiry", level: "error" });
  return error;
}
});

const enquirySlice = createSlice({
  name: "enquiry",
  initialState,
  reducers: {
    pushNewEnquiry: enquiryAdapter.addOne,
    updateEnquiry(state, action) {
      const { id } = action.payload;
      state.entities[id] = action.payload;
    },
    resetEnquiry: () => initialState,
  },
  extraReducers(builder) {
    builder
    .addCase(getEnquiry.pending, (state, action) => {
      state.loading=true;
     })
      .addCase(getEnquiry.fulfilled, (state, action) => {
        state.loading=false;
        state.status = true;
        if(action.payload.length>=1){
          state.enquiriesData = action.payload;
          enquiryAdapter.upsertMany(state, action.payload);
        }
       
      })
      .addCase(getEnquiry.rejected, (state, action) => {
        state.status = false;
        state.loading=false;
        state.error = i18n(
          "Unable to retrieve subscription. Please try again later."
        );
      })
      .addCase(deteteEnquiry.fulfilled, (state, action) => {
        state.isDeleted = true;
        state.loading=false;
        enquiryAdapter.removeOne(state, action.payload);
      });
  },
});

export default enquirySlice.reducer;
export const {
  selectAll: selectAllEnquiries,
  selectById: selectEnquiryById,
  selectIds: selectEnquiryIds,
} = enquiryAdapter.getSelectors((state) => state.enquiry);

export const { pushNewEnquiry, updateEnquiry,resetEnquiry } = enquirySlice.actions;
