import React, { useState, useEffect } from "react";
import {
  FormControl,
  Dropdown,
  InputGroup,
  Stack,
  Spinner,
} from "react-bootstrap";
import { Button } from 'primereact/button';
import CreateButton from "./CreateButton";
import GlobalSearch from "./globalSearch";
import GlobalAction from "./globalAction";
import DatePicker from "../../voucher/components/DatePicker";
import { useDispatch, useSelector } from "react-redux";
import { fetchProducts } from "../../catalog/store/productsSlice";
import { fetchPartyDetails } from "../../party/store/PartyDetails";
import { getAllVoucher } from "../../voucher/store/voucherSlice";
import { fetchparty } from "../../party/store/getPartySlice";
import { fetchCategories } from "../../settings/store/categorysSlice";
import { partyData } from "../../subscription/store/LivePartyGetsSlice";
import { useLocation } from 'react-router-dom';
import { getAllDeletedProduct, resetDeletedProduct } from "../../catalog/store/productDeletedSlice";
import { getAllDeletedPartys } from "../../party/store/partyDeletedSlice";
import { getAllDeletedVoucher } from "../../voucher/store/voucherDeletedSlice";
import { getAllSubscriptionVoucher } from "../../subscription/store/getAllSubscriptionVoucherSlice";
import { SubscriptionData } from "../../subscription/store/SubscriptionSlice";
import '../../../assets/css/frontend.css';
import { i18n } from "../helpers/sharedMethods";

const DataGridHeader = ({
  category,
  filterType,
  stockFilter,
  statusFilter,
  type,
  allData,
  data,
  columns,
  isAllData,
  isPaginated = true,
  handleRowClick,
  handleCreate,
  titles,
  paymentTitle,
  addFilteredData,
  handleExportClick,
  button,
  title,
  payment,
  bulkAction,
  handleRefreshProduct,
  handleRefreshParties,
  handleRefreshPayment,
  handleRefreshVoucher,
  handleRefreshParty,
  clearFilter,
  ItemActions,
  FilterActions,
  VoucherFilterActions,
  PurchaseFilterActions,
  handleRefreshProducts,
  handleRefreshSales,
  handleRefreshInvoice,
  handleRefreshMembership,
  handleRefreshMembers,
  handleEnquiryRefresh,
  handleEnquiryExport,
}) => {
  const [filteredData, setFilteredData] = useState(data ? data : []);
  const [loading, setLoading] = useState(false);
  const location = useLocation();

  const selectedBusiness = useSelector(
    (state) => state?.auth?.selectedBusiness
  );
  const accessToken = useSelector(
    (state) => state?.auth?.accessToken?.access_token
  );
  const tenantId = selectedBusiness?.pivot?.tenant_id;

  const isDeletedRecordsRoute = location.pathname.includes('/deleted_party');
  const isDeletedRecordsProduct = location.pathname.includes('/deleted_catalog');
  const isDeletedRecordsSales = location.pathname.includes('/deleted_sales');
  const isDeletedRecordsPurchase = location.pathname.includes('/deleted_purchase');

  useEffect(() => {
    if (data) {
      setFilteredData(data);
    }
  }, [data]);

  const dispatch = useDispatch();
  const handleRefresh = () => {
    setLoading(true);
    setTimeout(() => {
      if (handleRefreshProducts){
          handleRefreshProducts();
      }else if(handleRefreshParty){
        handleRefreshParty();
      }else if(handleRefreshVoucher){
        handleRefreshVoucher();
      }else if(handleRefreshPayment){
        handleRefreshPayment();
      }else if(handleRefreshParties){
        handleRefreshParties();
      }else if(handleRefreshProduct){
        handleRefreshProduct();
      }else if(handleRefreshSales){
        handleRefreshSales();
      }else if(handleRefreshInvoice){
        handleRefreshInvoice();
      }else if(handleRefreshMembership){
        handleRefreshMembership();
      }else if(handleRefreshMembers){
        handleRefreshMembers();
      }
      setLoading(false);
    }, 300);
  };

  return (
    <Stack direction="horizontal" className="px-4 py-2 w-100" gap={2}>
      {!loading && (
        <Button
          label={i18n("Refresh")}
          outlined
          onClick={handleEnquiryRefresh || handleRefresh }  //handleEnquiryRefresh Function is placed on EnquiryStack.js
          style={{borderRadius: '5px' }}
        />
      )}
      {loading && <Spinner animation="border" />}

      <Button
        type="button"
        icon="pi pi-filter-slash"
        label={i18n("Reset Filters")}
        severity="secondary"
        outlined
        onClick={clearFilter}
        style={{ borderRadius: '5px' }}
      />

      <div className="ms-auto mt-0.5 p-1 flex-row">         

        {!isDeletedRecordsRoute && !isDeletedRecordsProduct && !isDeletedRecordsSales && !isDeletedRecordsPurchase &&(
        <GlobalAction
          bulkAction={bulkAction}
          handleExportClick={handleEnquiryExport || handleExportClick} //handleEnquiryExport Function is placed on EnquiryStack.js
          title={title}
          type={type}
        />
      )}
    <div className="ms-2">
        {ItemActions}
    </div>
      </div>

      {isDeletedRecordsRoute && (
        <div className="ms-auto mt-0.5 p-1">
          {FilterActions}
        </div>
      )}

      {isDeletedRecordsSales && (
        <div className="ms-auto mt-0.5 p-1">
          {VoucherFilterActions}
        </div>
      )}

      {isDeletedRecordsPurchase && (
        <div className="ms-auto mt-0.5 p-1">
          {PurchaseFilterActions}
        </div>
      )}
    </Stack>

  );
};

export default DataGridHeader;
