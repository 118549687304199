import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosRequest } from "../../shared/helpers/axiosHelper";

const initialState = {
    loading: false,
    error: false,
    packedStatus :false,
    isUpdateRequired: false,
    isUpdateDownloading: false,
    isUpdateDownloaded: false

}

export const checkVersion = createAsyncThunk("config/checkVersion", async (versionNumber) => {
    try {

        const response = await axiosRequest({
            method: "POST",
            url: "config/check-version",
            data: { current_version: versionNumber },
            headers: {
                "Content-Type": "application/json",
            },
        });

        return response;
    } catch (error) {
        await window.log.logToFile({message: "Unable to check version", level: "error" });
        throw error;
    }

});

export const appPackedStatus = createAsyncThunk("config/appPackedStatus",async ()=>{
    try{
        ;
        const packStatus = await window.config.appPackedStatus()
        
        return packStatus

    } catch(error){
        await window.log.logToFile({message: "Unable to check app status", level: "error" });
        throw error;
    }
})

export const updateApp = createAsyncThunk("config/updateApp", async () => {
    try {

        const updateApp = await window.config.updateApp();
        
        return updateApp;
    } catch (error) {
        await window.log.logToFile({message: "Unable to update app", level: "error" });
        throw error;
    }

});

export const restartApp = createAsyncThunk("config/restartApp", async () => {
    try {

        await window.config.restartApp();
        
        return true;
    } catch (error) {
        await window.log.logToFile({message: "Unable to restart app", level: "error" });
        throw error;
    }

});

const configSlice = createSlice({
    name: "config",
    initialState,
    reducers: {
        updateAvailable: (state, action) => {
            state.isUpdateDownloading = true;
        },
        updateDownloaded: (state, action) => {
            state.isUpdateDownloading = false
            state.isUpdateDownloaded = true
        },
        resetUpdates: (state, action) => {
            state.isUpdateDownloading = false
            state.isUpdateDownloaded = false
        },
        resetUpdateStatus:(state,action) =>{
           state.isUpdateRequired = false
        },
        resetConfig:()=> initialState
    },
    extraReducers(builder) {
        builder
        .addCase(appPackedStatus.fulfilled, (state, action) => {
            state.packedStatus = action.payload
        })
            .addCase(checkVersion.fulfilled, (state, action) => {
                
                if (!action.payload.data) {
                    
                    state.isUpdateRequired = true
                } else {
                    state.isUpdateRequired = false
                }

            })
            .addCase(checkVersion.rejected, (state, action) => {
                state.error = 'Error while checking version. Please try again later.';
            })
            .addCase(restartApp.fulfilled, (state, action) => {
                state.isUpdateRequired = false
                    state.isUpdateDownloading = false
                    state.isUpdateDownloaded = false
            })
    }

})

export default configSlice.reducer;
export const { updateAvailable, updateDownloaded, resetUpdates,resetUpdateStatus,resetConfig } = configSlice.actions;