import {
    createAsyncThunk,
    createSlice
  } from '@reduxjs/toolkit';
import { cloneDeep } from 'lodash';
//import voucherItemSlice from '../../voucher/store/VoucherItemSlice';
import { i18n } from '../../shared/helpers/sharedMethods';
import { axiosRequest } from "../../shared/helpers/axiosHelper";

const initialState = {
     seletedVoucher:[],
     party:"",
     paymentType:'cash',
     status: false,
     editStatus:false,
     error: {},
     errorStatus:false,
     paymentDate:Date.now(),
     paymentNumber:null,
     getVoucherDataByid:[],
     voucherPayment: [],  
     totalReceivedAmount: 0,
     totalAmount:0
     
    
}

export const fetchSalesbyParty = createAsyncThunk('paymentEntity/fetchsalesbyParty', async (args) => {
  try {
    const token = args?.token;
    const type = args?.data?.payment_type
    const id = args?.data.id;
    const tenantId = args?.tenant_id;
  const salesResponse  = await axiosRequest({
    method: "GET",
    url : `get-sales-by-party/${id}/${type}`,
    headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "X-Tenant": tenantId,
    },
});
  return salesResponse.data;
  } catch {
    await window.log.logToFile({message: "Error while fetching Sales by Party", level: "error" });
    throw new Error('Error in fetch sales by party:' ); 
  }
});

export const fetchSalesbyId = createAsyncThunk('payment/fetchsalesbyId', async (args) => {
  try {
    console.log('args_payment2',args);

    const token = args?.token;
    const type = args?.data?.payment_type
    const id = args?.data.party_id;
    const tenantId = args?.tenant_id;

  const salesResponse  = await axiosRequest({
    method: "GET",
    url : `get-sales-by-party/${id}/${type}`,
    headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "X-Tenant": tenantId,
    },
});
  return salesResponse.data;
  } catch {
    await window.log.logToFile({message: "Error while fetching Sales by Party Id", level: "error" });
    throw new Error('Error in fetch sales by party: '); 
  }
});

export const createPayment= createAsyncThunk('payment/createPayment', async (data) => {
    try {
    const createPayment  = await window.api.createPayment(data);
    if(createPayment.success){
      return createPayment.data;
  }
  else {
    const errorMessage = createPayment?.data?.map((d) => d.message).join(", ");
    throw errorMessage;
        }
} catch (error) {
  await window.log.logToFile({message: "Error while creating Payment", level: "error" });
  throw new Error (error) ;
}

});

export const createPaymentParty= createAsyncThunk('payment/createPaymentParty', async (args) => {
    try {
      const token = args.token;
      const data = args.data;
      const tenantId = args.tenant_id;
      const resourceType = 'payment';
      const terminalNumber = args.terminal_id;   
      const createPaymentParty  =   await axiosRequest({
      method: "POST",
      url: `resource/${resourceType}/create/${terminalNumber}`,
      data: data,
      headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "X-Tenant": tenantId,
      },
  });
    if(createPaymentParty.success){
      return createPaymentParty.data;
  }
  else {
    const errorMessage = createPaymentParty?.data?.map((d) => d.message).join(", ");
    throw errorMessage;
        }
} catch (error) {
  await window.log.logToFile({message: "Error while creating Payment", level: "error" });
  throw new Error (error) ;
}

});

export const editPayment= createAsyncThunk('payment/updatePayment', async (args) => {
    try {
      console.log('args',args);
      const token = args.token;
      const data = args.data;
      const tenantId = args.tenant_id;
      const resourceType = 'payment';
      const terminalNumber = args.terminal_id;   
      const editPayment  = await axiosRequest({
        method: "POST",
        url: `resource/${resourceType}/create/${terminalNumber}`,
        data: data,
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            "X-Tenant": tenantId,
        },
    });
    if (editPayment.success) {
      return editPayment.data;
  } else {
    const errorMessage = editPayment?.data?.map((d) => d.message).join(", ");
    throw errorMessage;
  }
} catch (error) {
  await window.log.logToFile({message: "Error while updating Payment", level: "error" });
  throw new Error (error) ;
}
});

export const getPaymentNumber =createAsyncThunk(
  "paymentEntity/getPaymentNumber",
  async (args) => {
    try {
      console.log('get',args);
      const token = args.token;
      const tenantId = args.tenant_id;
      const resourceType = args.data; 
      const paymentNumberResponse = await axiosRequest({
        method: "POST",
        url: `get-doc-number/${resourceType}`,
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            "X-Tenant": tenantId,
        },
    });
      if (paymentNumberResponse.success) {
        return paymentNumberResponse.data;
      } else {
        throw new Error("Unable to get payment number" + paymentNumberResponse.data);
      }
    } catch (err) {
      await window.log.logToFile({message: "Error while fetching Payment Number", level: "error" });
      throw new Error("Error while getting paymentNumber : " + err);
    }
  }
);

export const getPaymentId =createAsyncThunk(
  "paymentEntity/getPaymentId",
  async (args) => {
    try {
      console.log('get',args);
      const token = args.token;
      const tenantId = args.tenant_id;
      const resourceType = args.data; 
      const terminalNumber = args.terminal_id
      const paymentIdResponse = await axiosRequest({
        method: "POST",
        url: `get-next-id/${resourceType}/${terminalNumber}`,
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            "X-Tenant": tenantId,
        },
    });
      if (paymentIdResponse.success) {
        return paymentIdResponse.data;
      } else {
        throw new Error("Unable to get payment number" + paymentIdResponse.data);
      }
    } catch (err) {
      await window.log.logToFile({message: "Error while fetching Payment Number", level: "error" });
      throw new Error("Error while getting paymentNumber : " + err);
    }
  }
);


const paymentEntitySlice = createSlice({
    name:'paymentEntity',
    initialState,
    reducers:{
        addPartyInPayment(state,action){
          
        state.party=action.payload
        },
        resetPaymentNotes : () => initialState,
        addPaymentDate(state,action){
        state.paymentDate =action.payload
        },
        addPaymentType(state,action){
          
          state.paymentType=action.payload
        },
        addPaymentNumber(state,action){
          state.paymentNumber=action.payload
        },
        sumOfAmount(state,action){
          const {payments} = action.payload;
          const sumOfBalance = payments.reduce((sum,voucher) => sum +voucher.grand_total,0)
          state.totalAmount = sumOfBalance;
        },
        resetPayment:()=> initialState,
        undoPaymentError(state, action) {
          state.errorStatus = false;
        },
     },
     extraReducers(builder){
        builder
        .addCase(fetchSalesbyParty.fulfilled, (state, action) => {
          
         // state.status = 'succeeded'
        })
        .addCase(createPayment.fulfilled, (state, action) => {
          state.status = true;
          ;
          state.savedEntity = action.payload;
        })
        .addCase(createPayment.rejected, (state, action) => {
          state.status = false;
          state.errorStatus = true;
          state.error = action?.error?.message;
        })
        .addCase(createPaymentParty.fulfilled, (state, action) => {
            state.status = true;
            ;
            state.savedEntity = action.payload;
          })
          .addCase(createPaymentParty.rejected, (state, action) => {
            state.status = false;
            state.errorStatus = true;
            state.error = action?.error?.message;
          })
          .addCase(editPayment.fulfilled, (state, action) => {
            ;
            state.savedEntity = action.payload
            state.editStatus =true
          })
          .addCase(editPayment.rejected, (state, action) => {
            state.status = false;
            state.errorStatus = true;
            state.error = action?.error?.message;
          })
          .addCase(fetchSalesbyId.fulfilled, (state, action) => {
            state.status = 'succeeded'
            state.getVoucherDataByid=action.payload
          })
          .addCase(getPaymentNumber.fulfilled, (state, action) => {
            state.paymentNumber = action.payload;
          })
          .addCase(getPaymentNumber.rejected, (state, action) => {
            state.error = i18n(
              "Error while getting payment number. Please try again later."
            );
          })
          .addCase(getPaymentId.fulfilled, (state, action) => {
            state.paymentNumber = action.payload;
          })
          .addCase(getPaymentId.rejected, (state, action) => {
            state.error = i18n(
              "Error while getting payment number. Please try again later."
            );
          });
    }
})
export default paymentEntitySlice.reducer;
export const{addPartyInPayment,voucherPaymentStructure,undoPaymentError,resetPayment,voucherPaymentUpdate,resetPaymentNotes,addPaymentDate,addPaymentType,addPaymentNumber,sumOfAmount} = paymentEntitySlice.actions