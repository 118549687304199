import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { cloneDeep } from "lodash";
import { i18n } from '../../shared/helpers/sharedMethods';
import { axiosRequest } from "../../shared/helpers/axiosHelper";

const initialState = {
  category: {
    id: '',
    name: "",
    type: "product",
    created_by: null
  },
  status: false,
  errorStatus: false,
  error: {},
  savedEntity: {},
};

export const addCategory = createAsyncThunk('category/addCategory',
   async (args) => {
  
    try {
      const token = args.token;
      const data = args.data;
      const tenantId = args.tenant_id;
      const resourceType = 'category';
      const terminalNumber = args.terminal_id;
      const addCategoryResponse = await axiosRequest({
        method: "POST",
        url: `resource/${resourceType}/create/${terminalNumber}`,
        data: data,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "X-Tenant": tenantId,
        },
    });
    
    if(addCategoryResponse.success){
      return addCategoryResponse.data;
    }
    else{
      const errorMessage = addCategoryResponse?.data;
      throw errorMessage;
    }
    
  } catch (error) {
    
    await window.log.logToFile({ message: "Error while creating category", level: "error" });
    throw new Error (error) ;
  }
});

export const editCategory = createAsyncThunk('category/editCategory', async (args) => {
try {
    const token = args.token;
    const data = args.data;
    const tenantId = args.tenant_id;
    const resourceType = 'category';
    const terminalNumber = args.terminal_id;
    const categoryResponse = await axiosRequest({
      method: "POST",
      url: `resource/${resourceType}/create/${terminalNumber}`,
      data: data,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "X-Tenant": tenantId,
      },
    });

    if(categoryResponse.success){
      return categoryResponse.data;
    }
    else{
      const errorMessage = categoryResponse.message;
      throw errorMessage;
    }
  } catch (error) {
    await window.log.logToFile({ message: "Error while updating category", level: "error" });
    throw new Error (error);
  }
})

const categoryEntitySlice = createSlice({
  name: 'categoryEntity',
  initialState,
  reducers: {
    changeType(state, action) {
      state.category.type = action.payload;
    },
    changeName(state, action) {
      state.category.name = action.payload;
    },
    initCategoryEdit(state, action) {
      let tempCategory = action.payload;
      let processedCategory = cloneDeep(tempCategory);
      delete processedCategory.id;
      delete processedCategory.image;
      delete processedCategory.updated_at;
      delete processedCategory.updated_by;
      delete processedCategory.created_at;
      state.category = processedCategory;

    },
    resetCart: (state, action) => {
      state.isUpdate = false
    },
    resetCreateCategory: () => initialState,
    undoCategoryError(state, action) {
      state.errorStatus = false;
      state.error=""
    },
    resetCategoryStatus(state,action){
      state.status = false
      state.savedEntity = {}
    },
  },
  extraReducers(builder) {
    builder

      .addCase(addCategory.fulfilled, (state, action) => {
        state.status = true;
        state.savedEntity = action.payload;

      })
      .addCase(addCategory.rejected, (state, action) => {
        
        state.errorStatus = true;
        state.error = action?.error?.message;
      })
      .addCase(editCategory.fulfilled, (state, action) => {
        state.isUpdate = true
        state.editStatus = true
        state.updatePartyData = action.payload
      })

      .addCase(editCategory.rejected, (state, action) => {
        state.errorStatus = true;
        state.error = action?.error?.message;
      })


  },
});

export default categoryEntitySlice.reducer;
export const { resetCart, resetCreateCategory,resetCategoryStatus, changeType, changeName, initCategoryEdit,undoCategoryError } = categoryEntitySlice.actions;
