import React from "react";
import { useSelector } from "react-redux";
import { i18n } from "../../shared/helpers/sharedMethods";
import TaxSummary from "./fields/TaxSummary";
import { useState } from "react";

const CartItemHeader = ({ cessEnabled, discountEnabled,  is_same_state }) => {
    const currencySymbol = useSelector((state) => state.getSetting?.dataSetting?.currency);
    ;
    const [showModal, setShowModal] = useState(false);

    const handleClose = () => setShowModal(false);
    const handleShow = () => setShowModal(true);
    return (
    <>
        <thead className="voucher-cart-header">
            <tr>
                <th rowSpan={2} style={{ width: "40px", backgroundColor: '#F9FAFB' }}>#</th>
                <th colSpan={5} rowSpan={2} style={{ backgroundColor: '#F9FAFB' }}>{i18n("Item")}</th>
                <th rowSpan={2} style={{ width: "120px", backgroundColor: '#F9FAFB' }}>{i18n("Type")}</th>
                <th rowSpan={2} style={{ width: "100px", backgroundColor: '#F9FAFB' }}>{i18n("HSN")}</th>
                <th rowSpan={2} style={{ width: "80px", backgroundColor: '#F9FAFB' }}>{i18n("Qty")}</th>
                <th rowSpan={2} style={{ width: "40px", backgroundColor: '#F9FAFB' }}>{i18n("Unit")}</th>
                <th rowSpan={2} style={{ width: !discountEnabled ? "110px" : "100px", backgroundColor: '#F9FAFB' }}>
                    {i18n("Price")}({currencySymbol})
                </th>
                {discountEnabled && (<> <th colSpan={2} rowSpan={1} style={{ width: "10%", backgroundColor: '#F9FAFB' }}>{i18n("Discount")}</th> </>)}
                <th rowSpan={2} style={{ width: !discountEnabled ? "150px" : "170px", backgroundColor: '#F9FAFB' }}>
                <div style={{ fontWeight: 'bold' }}>
                    {i18n("Tax")} ({i18n("GST")})
                </div>
                <p onClick={handleShow} style={{cursor: "pointer", textDecoration: "underline", margin: "5px 0 0 0", fontWeight: 'normal', fontSize: '10px', color: '#6571FF'}}>Summary</p>
                </th>

                {cessEnabled && (
                    <>
                        <th colSpan={2} rowSpan={1} style={{ width: "10%", backgroundColor: '#F9FAFB' }}>{i18n("CESS")}</th>
                    </>
                )}

                <th rowSpan={2} style={{ width: "100px", backgroundColor: '#F9FAFB' }}>{i18n("Total")}</th>
            </tr>
            <tr className="small-text">
                {discountEnabled && (<> <th style={{ backgroundColor: '#F9FAFB' }}>{i18n("Rate")}</th > <th style={{ backgroundColor: '#F9FAFB' }}>{i18n("Amount")}</th>  </>)}
                {/* <th style={{ width : "115px"}}>{i18n("Rate")}</th><th style={{ width : "115px"}}>{i18n("Amount")}</th> */}
                {cessEnabled && (<> <th style={{ backgroundColor: '#F9FAFB' }}>{i18n("Rate")}</th><th style={{ backgroundColor: '#F9FAFB' }}> {i18n("Amount")}</th></>)}
            </tr>
        </thead>
    <TaxSummary show={showModal} handleClose={handleClose} is_same_state={is_same_state} /> 
    </>
    );
};

export default CartItemHeader;
