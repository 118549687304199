import React, { useRef, useState, useEffect } from "react";
import Layout from "../../../shared/components/Layout";
import InvoiceLayout from "../../../shared/components/invoiceLayouts/invoiceLayout";
import {
  Button,
  Container,
  Stack,
  Dropdown,
  ButtonGroup,
  ToastContainer,
} from "react-bootstrap";
import SubscriptionList from "./SubscriptionList";
import { useLocation, useNavigate, useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import {
  selectAllSubscriptionData,
  selectSubscriptionDataById,
} from "../../store/SubscriptionSlice";
import {
  faDownload,
  faPenToSquare,
  faPrint,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { UilPrint, UilImport, UilEdit } from "@iconscout/react-unicons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { resetOrder } from "../../../voucher/store/voucherEntitySlice";
import html2canvas from "html2canvas";
import { useReactToPrint } from "react-to-print";
import jsPDF from "jspdf";
import SubscriptionAllInvoiceStylish from "./subscription_invoice/SubscriptionAllInvoiceStylish";
import SubscriptionAllInvoiceGST from "./subscription_invoice/SubscriptionAllInvoiceGst";
import SubscriptionAllInvoiceDefault from "./subscription_invoice/SubscriptionAllInvoiceDefault";
import { resetCreateSubscription } from "../../store/CreateSubscriptionSlice";
import TwoStageLayoutHeader from "../../../shared/components/TwoStatgeLayoutHeader";
import Header from "../../../shared/components/Header";
import { i18n, placeholderText } from "../../../shared/helpers/sharedMethods";
import SubscriptionReceivedAmount from "../SubscriptionReceivedAmountModel";
import {
  resetSubscription,
} from "../../store/CreateSubscriptionReceivedAmount";
import {
  selectSubscriptionVoucherById,
  getSubscriptionVoucher,
} from "../../store/getAllSubscriptionVoucherSlice";
import ShortcutKeys from "../../../shared/components/ShortcutKeys";
import PaymentModelForSubscription from "../PaymentModelForSubscription";

const SubscriptionInvoiceView = () => {
  const location = useLocation();
  const { id } = useParams();
  const subsData = useSelector((state) =>
    selectSubscriptionVoucherById(state, id)
  );
  const invoice = subsData?.voucher_number;
  const subsId = subsData?.voucher_subscription?.subscription_id;
  const voucherItems = location?.state?.value?.voucher?.voucher_items;
  const { payment_number } = useSelector(
    (state) => state.subscriptionReceivedAmount
  );
  const { accessToken, isLoggedIn } = useSelector((state) => state?.auth);

  const selectedBusiness = useSelector(
    (state) => state?.auth?.selectedBusiness
  );
  

  const tenantId = selectedBusiness?.pivot?.tenant_id;

  const allData = useSelector(selectAllSubscriptionData);
  const [showReceivedAmountModal, setShowReceivedAmountModal] = useState(false);
  const [showPaymentModel, setShowPaymentModel] = useState(false);

  const [delId, setDelId] = useState();

  const toggleReceivedAmountModal = () => {
    setShowReceivedAmountModal(true);
  };

  const handleClose = () => {
    setShowReceivedAmountModal(false);
  };

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const planfilterData =
    allData.length > 0 && allData.filter((item) => item != undefined);

  useEffect(() => {
    dispatch(
      getSubscriptionVoucher({ id: id, token: accessToken, tenantId: tenantId })
    );
  }, [dispatch]);

  const invoicesSetting = useSelector((state) => state.setting.invoicesType);

  let subscriptionTabs;
  switch (true) {
    case invoicesSetting?.invoice_1 === true:
      subscriptionTabs = [
        {
          key: "1",
          title: "Default",
          component: (
            <SubscriptionAllInvoiceStylish
              name="Subscription"
              id={id}
              data={subsData}
            />
          ),
        },
      ];
      break;
    case invoicesSetting?.invoice_2 === true:
      subscriptionTabs = [
        {
          key: "2",
          title: "Theme1",
          component: (
            <SubscriptionAllInvoiceDefault
              name="Subscription"
              id={id}
              data={subsData}
            />
          ),
        },
      ];
      break;
    case invoicesSetting?.invoice_3 === true:
      subscriptionTabs = [
        {
          key: "3",
          title: "Theme2",
          component: (
            <SubscriptionAllInvoiceGST
              name="Subscription"
              id={id}
              data={subsData}
            />
          ),
        },
      ];
      break;
    default:
      subscriptionTabs = [
        {
          key: "1",
          title: "Default",
          component: (
            <SubscriptionAllInvoiceStylish
              name="Subscription"
              id={id}
              data={subsData}
            />
          ),
        },
      ];
      break;
  }

  const componentRef = useRef();

  const DownloadPdf = () => {
    html2canvas(componentRef.current, {
      scale: 2,
      useCORS: true,
      allowTaint: true,
      logging: true,
    }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png", 0.8);
      const pdf = new jsPDF("p", "mm", "a4", true);

      let selectedLayout;
      if (invoicesSetting && invoicesSetting.invoice_1) {
        selectedLayout = subscriptionTabs.find((tab) => tab.key === "1");
      } else if (invoicesSetting && invoicesSetting.invoice_2) {
        selectedLayout = subscriptionTabs.find((tab) => tab.key === "2");
      } else if (invoicesSetting && invoicesSetting.invoice_3) {
        selectedLayout = subscriptionTabs.find((tab) => tab.key === "3");
      } else {
        selectedLayout = subscriptionTabs[0];
      }

      pdf.addImage(imgData, "PNG", 10, 10, 150, 0);

      const timeStamp = Date.now();
      pdf.save(`subscription_invoice_${timeStamp}`);
    });
  };

  const fileName = Date.now();
  const handlePrint = () => {
    html2canvas(componentRef.current, {
      scale: 2,
      useCORS: true,
      allowTaint: true,
      logging: true,
    }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png", 1.0);
      const pdf = new jsPDF("p", "mm", "a4", true);

      const imgWidth = 210;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;

      pdf.addImage(imgData, "PNG", 0, 0, imgWidth, imgHeight);
      const timeStamp = Date.now();
      const filename = `sales_invoice_${timeStamp}.pdf`;
      const pdfBlob = pdf.output("blob");
      const blobUrl = URL.createObjectURL(pdfBlob);
      window.open(blobUrl);
    });
  };

  const handleEdit = (id) => {
    dispatch(resetCreateSubscription());
    navigate("/subscription/editSubscription/" + id);
  };

  const handleItemClick = (data) => {
    navigate("/subscription/membership/view/" + data);
  };

  const handleReturnToGrid = () => {
    navigate("/subscription/membership");
  };
  const handleClick = () => {
    dispatch(resetCreateSubscription());
    navigate("/subscription/membership/create");
  };

  const invoiceStack = (
    <Stack>
      <SubscriptionList
        returnToGrid={handleReturnToGrid}
        handleItemClick={handleItemClick}
        currentItemId={id}
        filteredItems={planfilterData}
        handleCreate={handleClick}
        data={location}
      />
    </Stack>
  );

  const header = (
    <TwoStageLayoutHeader
    
      ItemActions={
        <Stack
          gap={3}
          direction="horizontal"
          className="mt-2 mb-2 ml-3 float-end"
        >
          <Dropdown as={ButtonGroup}>
            <Dropdown.Toggle variant="outline-primary" id="dropdown-custom" onClick={toggleReceivedAmountModal}>
              {i18n("Payments")}
            </Dropdown.Toggle>

            {/* <Dropdown.Menu> */}
             
                  {/* <Dropdown.Item >
                    <UilImport className="mx-2 mb-1" size="16" />
                    {i18n("Add Payment")}
                  </Dropdown.Item>
                

              <Dropdown.Item
                onClick={() => {
                  setShowPaymentModel(!showPaymentModel);
                }}
              >
                <UilEdit className="mx-2 mb-1" size="16" />
                {i18n("View Payments")}
              </Dropdown.Item>
            </Dropdown.Menu> */}
          </Dropdown>
          <div>
            {/* <Button
              className=""
              variant="outline-secondary"
              onClick={DownloadPdf}
            >
              <FontAwesomeIcon className="mx-2 mb-1" icon={faDownload} />
              {i18n("Download PDF")}
            </Button> */}
          </div>
          <div>
            <Button
              className=""
              variant="outline-secondary"
              onClick={handlePrint}
            >
              <FontAwesomeIcon className="mx-2 mb-1" icon={faPrint} />
              {i18n("Print Pdf")}
            </Button>
          </div>
        </Stack>
      }
      returnToGrid={handleReturnToGrid}
      adjustment="subscription"
    />
  );

  return (
    <Layout>
      <ToastContainer />
      <PaymentModelForSubscription
        showModel={showPaymentModel}
        setShowPaymentModel={setShowPaymentModel}
        data={subsData}
      />
      <Header
        currentTitle={`#${invoice}`}
        ItemActions={
          <Stack
            gap={3}
            direction="horizontal"
            className="my-2 justify-content-end"
          >
            <ShortcutKeys />
          </Stack>
        }
      />
      <Container fluid>
        <SubscriptionReceivedAmount
          showReceivedAmountModal={showReceivedAmountModal}
          handleClose={handleClose}
          data={subsData}
          payment_number={payment_number}
        />
        <InvoiceLayout
          tabs={subscriptionTabs}
          handleItemClick={handleItemClick}
          currentItemId={id}
          returnToGrid={handleReturnToGrid}
          print={componentRef}
          header={header}
        />
      </Container>
    </Layout>
  );
};

export default SubscriptionInvoiceView;
