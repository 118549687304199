import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import React from 'react';


function CancelAlerts(props) {
    const{show,handleClose,setShow}=props
   
  
    return (
      <>
       
  
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Alert</Modal.Title>
          </Modal.Header>
          <Modal.Body>Are you sure you want to leave this page?</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={()=>{setShow(false)}}>
              No
            </Button>
            <Button variant="primary" onClick={handleClose}>
             Yes
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
}

export default CancelAlerts;