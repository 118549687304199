import React, { useEffect } from "react";
import { Container, Row, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { i18n } from "../../../../shared/helpers/sharedMethods";
import { fetchPartyReport } from "../../../store/partyWiseReportSlice";
import { formatAmount } from "../../../../pos/helpers/cartHelper";
import GlobalCurrency from "../../../../shared/components/GlobalCurrency";

const PartywiseReport = ({ productId }) => {
  const partyReport = useSelector((state) => state.partyWiseReport.data);

  const accessToken = useSelector((state) => state?.auth?.accessToken?.access_token);
  const selectedBusiness = useSelector((state) => state?.auth?.selectedBusiness);
  const tenantId = selectedBusiness?.pivot?.tenant_id;
  const dispatch = useDispatch();
  useEffect(() => {
    if (productId) {
      dispatch(fetchPartyReport({ product_id: productId, token:accessToken,tenant_id:tenantId}));
    }
  }, [productId]);
  return (
    <>
      <Row className=" scrollable-table">
        {partyReport ? (
          <Table className="mt-5" bordered hover responsive>
            <thead >
              <tr style={{color:'#495057'}}>
                <th className="px-2" style={{ backgroundColor: '#F9FAFB' }}>{i18n("Party Name")}</th>
                <th className="px-2" style={{ backgroundColor: '#F9FAFB' }}>{i18n("Sales Quantity")}</th>
                <th className="px-2" style={{ backgroundColor: '#F9FAFB' }}>{i18n("Sales Amount")}</th>
                <th className="px-2" style={{ backgroundColor: '#F9FAFB' }}>{i18n("Purchase Quantity")}</th>
                <th className="px-2" style={{ backgroundColor: '#F9FAFB' }}>{i18n("Purchase Amount")}</th>
              </tr>
            </thead>

            <tbody>
              {partyReport.length > 0 &&
                partyReport.map((data, index) => {
                  return (
                    <tr key={index}>
                      <td className="px-4">{data?.party_value?.name}</td>
                      <td className="px-4">
                        {data?.sales_count} {data?.sales_unit}
                      </td>
                      <td className="px-4">
                        <GlobalCurrency price={data?.sales_total} />
                      </td>
                      <td className="px-4">
                        {data?.purchase_count} {data?.purchase_unit}
                      </td>
                      <td className="px-4">
                        <GlobalCurrency price={data?.purchase_total} />
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </Table>
        ) : (
          <Table className="mt-5 cursor-pointer" bordered>
            <thead className="tableheading">
              <tr>
                <th className="px-2">{i18n("Party Name")}</th>
                <th className="px-2">{i18n("Sales Quantity")}y</th>
                <th className="px-2">{i18n("Sales Amount")}</th>
                <th className="px-2">{i18n(" Purchase Quantity")}</th>
                <th className="px-2">{i18n("Purchase Amount")}</th>
              </tr>
            </thead>
          </Table>
        )}
        {partyReport.length == 0 && (
          <div className="d-flex flex-column justify-content-center align-items-center w-100 h-100">
            <p> {i18n("No transactions available for selected item")} </p>
          </div>
        )}
      </Row>
    </>
  );
};

export default PartywiseReport;
