import { format } from "date-fns";
import { getUtcDate } from "../../shared/helpers/localizationHelper";

export const exportPreProcessor =(data)=>{
    ;
    const  paymentData = data.map((payments) => ({
        Id: payments.id,
                Date: payments?.created_at ? format(new Date(payments?.created_at), 'dd-MM-yyyy') : '-',
                Payment_Number: payments?.payment_number,
                Type: payments?.type,
                Party_name:payments.party?.name,
                Amount: payments?.amount
          }));
      return  paymentData
}
export const addPaymentPreproceesor = (data) => {
    const {
        partyDetails,
        partyPayments,
        type,
        totalAmount
    } = data;

    const paymentAmount = partyPayments?.processPayment || 0;
    const balance = Math.max(totalAmount - paymentAmount, 0);
    const status = paymentAmount <= totalAmount ? 'open' : 'close';

    const addData = {
        payment_number: partyDetails?.paymentNumber,
        type: type,
        amount: paymentAmount,
        status: status,
        balance: balance,
        mode: partyDetails?.paymentType,
        selectedVoucher: partyPayments?.selectedPayment,
    };
    
    return addData;
};

export const iteratePartyPayment = (payment) => {
    if (!Array.isArray(payment)) {
        return []; 
    }
    return payment?.map((data) => {
        const balance_amount = data?.grand_total - data?.paid_amount;
        return {
            ...data,
            balance_amount: balance_amount
        };
    });
};

export const editCopyPaymentProcessor = (iteratePayments, amount, voucher_ids) => {
    let totalAmount = amount;
    
    let voucherIds = voucher_ids;
    

    let returnData;

if(iteratePayments.length>1){
    const data = iteratePayments?.map((data) => {
        
        let updatedPaidAmount = data?.paid_amount;
        
        if (voucherIds.includes(data.id)) {
            updatedPaidAmount =totalAmount - data?.paid_amount>=0?0:Math.abs(totalAmount - data?.paid_amount);
            totalAmount -= updatedPaidAmount;
        }
        
        return {
            ...data,
            paid_amount:updatedPaidAmount,
            balance_amount: data?.grand_total - (updatedPaidAmount)
        };
    });
    
    return data
}
else{
    const data = iteratePayments?.map((data) => {
        
        let updatedPaidAmount = data?.paid_amount;
        if (voucherIds.includes(data?.id)) {
            updatedPaidAmount =Math.abs (totalAmount - data?.paid_amount);
            totalAmount -= updatedPaidAmount;
        }
        return {
            ...data,
            paid_amount:updatedPaidAmount,
            balance_amount: data?.grand_total - updatedPaidAmount
        };
    });
    
    return data
}
    
};

export const defaultSelectedPayment = (payment, selectedBusiness, amount) => {
    ;
    let totalAmount = amount;
    ;

    return payment?.map((data) => {
        let updatedPaidAmount =  data?.voucher?.paid_amount;
        //totalAmount -= updatedPaidAmount;
        return {
            id: data?.voucher_id,
            payment_id: data?.payment_id,
            amount: data?.amount,
            paid_amount: updatedPaidAmount,
            created_by: selectedBusiness?.pivot?.user_id,
            updated_by: selectedBusiness?.pivot?.user_id,
            created_at: getUtcDate(),
            updated_at: getUtcDate(),
        };
    });
};



