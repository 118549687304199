import { useEffect, useState } from "react";
import {
  Container,
  Col,
  Card,
  Row,
  Form,
  Stack,
  Button,
  Spinner,
  Alert,
  Image
} from "react-bootstrap";
import ModelFooter from "../../shared/components/modelFooter";
import CompanyDetails from "../components/CompanyDetails";
import {
  addSetting,
  editSetting,
  selectSettingById,
  initSettingEdit,
  resetStatus,
  setSignature,
  resetError,
} from "../store/settingSlice";
import { useDispatch, useSelector } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import { setLogo } from "../store/settingSlice";
import Layout from "../../shared/components/Layout";
import { Formik } from "formik";
import { i18n } from "../../shared/helpers/sharedMethods";
// import { getAppConfig } from "../../auth/store/appConfigSlice";
import { cloneDeep } from "lodash";
import NavbarSetting from "./NavbarSettings";
import { useNavigate } from "react-router";
import Header from "../../shared/components/Header";
import { fetchSetting } from "../store/getSettingSlice";
import ShortcutKeys from "../../shared/components/ShortcutKeys";
import BusinessAddress from "../components/Address";
import * as Yup from "yup";
import SettingsLayout from "./SettingsLayout";
import LoadingSpinner from '../../shared/components/LoadingSpinner';
const CompanySettings = () => {
  const selectedBusiness = useSelector(
    (state) => state?.auth?.selectedBusiness
  );

  const { accessToken } = useSelector((state) => state?.auth);
  const loading = useSelector((state) => state.getSetting.loading)
  const getSetting = useSelector((state) => state.getSetting?.dataSetting);
  const { billing_settings, error } = useSelector((state) => state.setting);
  const dispatch = useDispatch();
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  ;

  const onImageSelect = (selectedFile) => {
    if (selectedFile) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64data = reader.result;
        dispatch(setLogo(base64data));
      };
      reader.readAsDataURL(selectedFile);
    }
  };
  const status = useSelector((state) => state.setting.status);
  ;
  useEffect(() => {
    if (getSetting) {
      dispatch(initSettingEdit(getSetting));
    }
  }, [getSetting]);

  useEffect(() => {
    if (status === true && isOnline) {
      toast.success(i18n("Company Details Updated Successfully"));
      dispatch(resetStatus());
    }
  }, [status, isOnline]);

  useEffect(() => {
    if (error) {
      toast.error(error?.props?.id);
      dispatch(resetError());
    }
  }, [error]);

  useEffect(() => {
    ;
    const data = {
      accessToken: accessToken.access_token,
      tenant_id: selectedBusiness?.pivot?.tenant_id,
    };
    dispatch(
      fetchSetting({
        accessToken: accessToken.access_token,
        tenant_id: selectedBusiness?.pivot?.tenant_id,
      })
    );
  }, [status]);

  useEffect(() => {
    const handleOnlineStatusChange = () => {
      setIsOnline(navigator.onLine);
    };

    window.addEventListener("online", handleOnlineStatusChange);
    window.addEventListener("offline", handleOnlineStatusChange);

    return () => {
      window.removeEventListener("online", handleOnlineStatusChange);
      window.removeEventListener("offline", handleOnlineStatusChange);
    };
  }, []);

  const navigate = useNavigate();

  const handleCancel = () => {
    navigate("/dashboard/sales");
  };

  const gstRegex =
    /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[0-9]{1}[A-Z]{1}[0-9A-Z]{1}$/;
  const panRegex = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;
  const phoneRegex = /^[0-9]{10}$/;

  const validationSchema = Yup.object().shape({
    gstin: Yup.string().matches(gstRegex, "Invalid GSTIN"),
    pan: Yup.string().matches(panRegex, "Invalid PAN number"),
    business_phone: Yup.string()
    .matches(phoneRegex, "  Phone number must be 10 digits")
  });

  return (
    <>
      <Formik
        initialValues={billing_settings}
        enableReinitialize={true}
        validationSchema={validationSchema}
        onSubmit={async (values) => {
          try {
            const formData = cloneDeep(values);
            formData.created_by = selectedBusiness?.pivot?.user_id;
            formData.tenant_id = selectedBusiness?.pivot?.tenant_id;
            formData.accessToken = accessToken.access_token;
            delete formData.id;
            dispatch(setSignature(formData.signature));

            if (formData.logo === null) {
              toast.error(i18n("Please upload an image"));
              return;
            }
            if (!isOnline) {
              toast.error(i18n("You are Offline. Please Connect to the Internet"));
              return;
            }
            
            dispatch(editSetting(formData))
              .then((res) => {
                dispatch(
                  fetchSetting({
                    accessToken: accessToken.access_token,
                    tenant_id: selectedBusiness?.pivot?.tenant_id,
                  })
                );
              })
              
              .catch((error) => {
                ;
              });
            ;
            
          } catch (error) { }
        }}
      >
        {({ values, errors, handleChange, handleSubmit, setFieldValue }) => (
          <>
            <ToastContainer />
            <SettingsLayout title="Business Settings"
              onSave={handleSubmit}
              onCancel={handleCancel}>
               {loading && <LoadingSpinner />}
              <Row>
                <Col lg={12}>
                  <Row
                    style={{
                      height: "80vh",
                      overflow: "scroll",
                      overflowX: "hidden",
                    }}
                  >
                    <Col lg={6} className="">
                      <CompanyDetails
                        values={values}
                        handleChange={handleChange}
                        errors={errors}
                        setFieldValue={setFieldValue}
                        onImageSelect={onImageSelect}
                      />
                    </Col>
                    <Col lg={6}>
                      <BusinessAddress
                        values={values}
                        handleChange={handleChange}
                        setFieldValue={setFieldValue}
                        errors={errors}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </SettingsLayout>
          </>
        )}
      </Formik>
    </>
  );
};

export default CompanySettings;
