import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { i18n } from "../../shared/helpers/sharedMethods";

const initialState = {
  stock: {
    id: "",
    product_id:null,
    warehouse_id: 1,
    quantity: 0,
    remarks:'Opening stock',
    created_by: null,
    updated_by: null,

  },
  status: false,
  error: {},
  savedEntity: {},

};

export const addStock = createAsyncThunk(
  "stockEntity/add",
  async (stock) => {
    try {
      // const response = await window.api.saveStock({
      //   stock: stock,
      // });

      // if (response.success) {
      //   return response.data;
      // } else {
      //   throw new Error("add products failed" + response.data);
      // }
    } catch (error) {
      await window.log.logToFile({message: "Error while adding stock", level: "error" });
      throw error;
    }
  }
);


const stockEntitySlice = createSlice({
  name: "stockEntity",
  initialState,
  reducers: {
    changeProductId(state, action) {
      state.stock.product_id = action.payload;
    },
    changeQty(state, action) {
      const {mode,quantity}  = action.payload;
     
      let qty =quantity
       if(typeof(qty) === 'string'){
        qty = parseInt(quantity)
       }
      
       if(qty<0){
        qty=Math.abs(qty)
       }

      if(mode=='sub' && !quantity==0){
        state.stock.quantity = -qty;
      }
      
      else{
        state.stock.quantity = qty;
      }
        
    },

    changeRemarks(state, action) {
      state.stock.remarks = action.payload;
    },
    changeStockCreatedBy(state, action) {
      state.stock.created_by = action.payload;
    },
    changestockUpdatedBy(state, action) {
      state.stock.updated_by = action.payload;
    },
    resetStockAdjust: () => initialState,
  },
  extraReducers(builder) {
    builder
      .addCase(addStock.fulfilled, (state, action) => {
        state.status = true;
        state.savedEntity = action.payload;
      })
      .addCase(addStock.rejected, (state, action) => {
        state.status = false;
        state.error = i18n('An error occurred while adding the product. Please try again later.');
      })

  },
});

export default stockEntitySlice.reducer;
export const {
  resetStockAdjust,
  changeProductId,
  changeQty,
  changeRemarks,
  changeStockCreatedBy,
  changestockUpdatedBy,

} = stockEntitySlice.actions;
