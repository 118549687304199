import { Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { taxList } from "../../../../../shared/config/taxConfig";
import {
  selectSubscriptionPlanItemById,
  updateItemFieldSubscription,
} from "../../../../store/subscriptionProductEntitySlice";
import { filterTaxList } from "../../../../../settings/components/helpers/settingHelpers";

const SubscriptionTaxSelected = ({ item, index, discountEnabled }) => {
  const dispatch = useDispatch();

  const getSetting = useSelector((state) => state?.getSetting?.dataSetting);
  const getTax = (getSetting?.tax || []);
  ;
  const TaxList = filterTaxList(taxList, getTax);
  ;

  return (
    <Form.Select
      name="tax_code"
      aria-label="Tax Rate"
      className="form-control small-text"
      onChange={(e) => {
        // handleChange(e);
        let data = {
          fieldName: "tax_code",
          fieldValue: e.target.value,
          index,
          discountEnabled,
        };
        dispatch(updateItemFieldSubscription(data));
      }}
    >
      {TaxList && TaxList.length ? (
        TaxList.map((tax, index) => {
          return (
            <option
            key={tax.code}
              value={tax.code}
              selected={item && item?.tax_code == tax.code}
            >
              {tax.label}
            </option>
          );
        })
      ) : (
        <option value="">No Tax options</option>
      )}
    </Form.Select>
  );
};

export default SubscriptionTaxSelected;
