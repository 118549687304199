import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { Stack, Table, Container, Button } from "react-bootstrap";
import DataGridHeader from "../../shared/components/DataGridHeader";
import "../../../assets/css/frontend.css";
import { DataTable } from 'primereact/datatable';
import { classNames } from 'primereact/utils';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { IconField } from 'primereact/iconfield';
import { InputIcon } from 'primereact/inputicon';
import { Dropdown } from 'primereact/dropdown';
import { InputNumber } from 'primereact/inputnumber';
//import { Button } from 'primereact/button';
import { ProgressBar } from 'primereact/progressbar';
import { Calendar } from 'primereact/calendar';
import { MultiSelect } from 'primereact/multiselect';
import { Slider } from 'primereact/slider';
import DeleteModel from "../../shared/components/DeleteModel";
import {
  deletePayment,
  fetchSalesbyParty,
  getPayment,
  resetCartVoucherPayment,
  resetDeleteStatus,
  selectAllVoucherPayment,
  selectPaymentByType,
  selectVoucherPaymentById,
} from "../store/paymentSlice";
// import { setCurrency,selectAllSetting } from "../../settings/store/settingSlice";
import { format } from "date-fns";
import { downloadCsvFile, handleExportClick } from "../../shared/export";
import VoucherIndex from "../../voucher/components/VoucherHeader";
import Layout from "../../shared/components/Layout";
import { resetPayment } from "../store/paymentEntitySlice";
import { exportPreProcessor } from "../helpers/paymentHelper";
import { resetPartyPayment } from "../store/partyPaymentsSlice";
import Header from "../../shared/components/Header";
import PaymentDetailsModal from "../components/payment_views/PaymentDetailsModal";
import { i18n } from "../../shared/helpers/sharedMethods";
import ShortcutKeys from "../../shared/components/ShortcutKeys";
import { useHotkeys } from "react-hotkeys-hook";
import { UilFilePlusAlt } from "@iconscout/react-unicons";
import { changeToggleShow } from "../../shared/store/sharedSlice";
import { UilEdit, UilTrashAlt } from "@iconscout/react-unicons";
import { formatAmount } from "../../pos/helpers/cartHelper";
import CustomeSortIcon from "../../shared/components/CustomeSortIcon";
import { values } from "lodash";
import { getLocalizedDate } from "../../shared/helpers/localizationHelper";
import SyncStatus from "../../shared/helpers/syncStatus";
import SyncStatusFilter from "../../catalog/helper/syncStatusHelper";
import GlobalCurrency from "../../shared/components/GlobalCurrency";
import { Tag } from 'primereact/tag';

const VoucherPaymentStack = (props) => {
  const { type, title, voucherType, path, button } = props;
  const navigate = useNavigate();
  const selectAllPayment = useSelector(selectAllVoucherPayment);
  const allPayment = useSelector((state) => selectPaymentByType(state, type));
  const { deleteStatus } = useSelector((state) => state.payment);
  console.log("all payment", selectAllPayment)
  const [payments, setPayments] = useState("");
  const [gridKey, setGridKey] = useState(Date.now());
  const [csvData, setCsvData] = useState("");
  const [exportData, setExportData] = useState([]);
  const [showModel, setShowModel] = useState(false);
  const [paymentViewId, setPaymentViewId] = useState();
  const [rowData, setRowData] = useState();


  useEffect(() => {
    if (allPayment.length > 0) {
      setPayments(allPayment);
    }
  }, [allPayment]);
  ;

  const [globalFilter, setGlobalFilter] = useState('');
  const [globalFilterValue, setGlobalFilterValue] = useState('');
  const [filters, setFilters] = useState(null);
  const [sortField, setSortField] = useState(null);
  const [sortOrder, setSortOrder] = useState(1);
  const [modeOptions, setModeOptions] = useState([]);
  const [selectedmode, setSelectedMode] = useState([]);
  const { accessToken, isLoggedIn } = useSelector((state) => state?.auth);
  const selectedBusiness = useSelector(
    (state) => state?.auth?.selectedBusiness
  );
  const tenantId = selectedBusiness?.pivot?.tenant_id;
  const { terminalId } = useSelector((state) => state.initInstall);

  const handleClearFilters = () => {
    setSelectedMode([]);
    setFilters({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      created_at: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
      payment_number: { value: null, matchMode: FilterMatchMode.CONTAINS },
      mode: { value: null, matchMode: 'in' },
      'party.name': { value: null, matchMode: FilterMatchMode.EQUALS },
      amount: { value: null, matchMode: FilterMatchMode.EQUALS },
    });
    setGlobalFilter('');
    setGlobalFilterValue('');
    setPayments(allPayment);
  };
  const [balanceFrozen, setBalanceFrozen] = useState(false);
  const currencySymbol = useSelector((state) => state.getSetting?.dataSetting.currency);
  ;

  const actionBodyTemplate = (row) => {
    return (
      <div className="d-flex justify-content-evenly align-items-center">
        <UilEdit
          className="mx-2 mb-1"
          size="15"
          style={{ cursor: "pointer" }}
          onClick={(e) => {
            e.stopPropagation(); // Prevents row click from firing
            handleEdit(row);
          }}
        />
        &nbsp;
        {/* <i className="fa fa-trash mx-2 " aria-hidden="true"></i> */}
        <UilTrashAlt
          className="mx-2 mb-1"
          size="15"
          style={{ cursor: "pointer" }}
          onClick={(e) => {
            e.stopPropagation(); // Prevents row click from firing
            handleDelete(row);
          }}
        />
      </div>
    );
  };

  const handleEdit = (data) => {
    dispatch(resetPayment());
    dispatch(resetPartyPayment());
    ;
    const voucher_ids = [];
    data.voucher_payment.length > 0 &&
      data.voucher_payment.map((data, index) => {
        const voucher_id = data.voucher_id;
        voucher_ids.push(voucher_id);
      });
    ;

    navigate(`/${path}/edit`, {
      state: { editData: data, voucher_ids: voucher_ids },
    });
  };

  const dispatch = useDispatch();

  const [show, setShow] = useState(false);
  const [id, setId] = useState();
  const [name, setName] = useState();

  const handleDelete = (delRow) => {
    ;
    setShow(!show);
    setId(delRow.id);
    setName(delRow.name);
  };

  const handleClick = () => {
    dispatch(resetPayment());
    dispatch(resetPartyPayment());
    dispatch(changeToggleShow(false));
    ;
    navigate(`/${path}/create`);
  };


  const onDelete = () => {
    dispatch(deletePayment({
      data: { id: id },
      token: accessToken.access_token,
      tenant_id: tenantId,
      terminal_id: terminalId
    }));
    setShow(false);
    navigate(`/${path}`);
  };
  const handleRefreshPayment = () => {
    setPayments([]);
    dispatch(getPayment({ token: accessToken.access_token, tenant_id: selectedBusiness?.pivot?.tenant_id }));
    dispatch(resetCartVoucherPayment());
  };
  useEffect(() => {
    dispatch(getPayment({ token: accessToken.access_token, tenant_id: selectedBusiness?.pivot?.tenant_id }));
  }, [accessToken, selectedBusiness, dispatch]);
  const handlePaymentView = (row) => {
    setShowModel(true);
    setPaymentViewId(row.id);
    setRowData(row)
  };
  const dateTimeString = new Date()
    .toLocaleString()
    .replace(/[-]/g, " ")
    .replace(/:/g, "-");

  const handleExportPayment = () => {
    const processedExportData = exportPreProcessor(payments);
    setExportData(processedExportData);
    handleExportClick(
      processedExportData,
      (exportData) => {
        setCsvData(exportData);
      },
      downloadCsvFile,
      `${type}_${dateTimeString}`
    );
  };
  const handleClose = () => {
    setShowModel(!showModel);
  };

  useHotkeys("alt+o", (event) => {
    event.preventDefault();
    navigate('/voucher/payment-out/create')
  });

  useHotkeys("alt+i", (event) => {
    event.preventDefault();
    navigate('/voucher/payment-in/create')
  });

  const headerCellStyle = {
    fontWeight: 'bold',
    fontSize: '11px',
  };

  const dateBodyTemplate = (rowData) => {
    return getLocalizedDate(rowData.created_at);
  };

  const handleCreatedAtDateFilterChange = (value, filterCallback) => {
    filterCallback(value);

    if (value) {
      const filtered = allPayment.filter((item) => {
        const createdAtDate = new Date(item.created_at);
        const selectedDate = new Date(value);
        return createdAtDate.toDateString() === selectedDate.toDateString();
      });
      setPayments(filtered);
    } else {
      setPayments(allPayment);
    }
  };

  const dateFilterTemplate = (options) => {
    return (
      <Calendar
        value={options.value}
        onChange={(e) => handleCreatedAtDateFilterChange(e.value, options.filterCallback)}
        dateFormat="dd/mm/yy"
        mask="99/99/9999"
      />
    );
  };

  const customSort = (field) => {
    setSortField(field);
    setSortOrder(sortOrder === 1 ? -1 : 1);
  };

  const sortedData = [...payments].sort((a, b) => {
    if (sortField) {
      const compareValueA = a[sortField];
      const compareValueB = b[sortField];
      if (compareValueA < compareValueB) {
        return sortOrder;
      }
      if (compareValueA > compareValueB) {
        return -sortOrder;
      }
    }
    return 0;
  });

  useEffect(() => {
    const options = [
      { name: 'cash', label: 'Cash' },
      { name: 'upi', label: 'UPI' },
      { name: 'card', label: 'Card' },
    ];
    setModeOptions(options);
  }, []);

  const handleModeChange = (e) => {
    setSelectedMode(e.value);

    if (!Array.isArray(e.value)) {
      console.error('Expected e.value to be an array, but got:', e.value);
      return;
    }

    if (e.value.length === 0) {
      setPayments(allPayment);
    } else {
      const filteredStatus = allPayment.filter(payment =>
        e.value.some(mode => payment.mode.toLowerCase() === mode.name.toLowerCase())
      );
      setPayments(filteredStatus);
    }
  };

  const representativesItemTemplate = (option) => {
    return (
      <div className="flex align-items-center gap-2">
        <span>{option.name}</span>
      </div>
    );
  };

  const modeBodyTemplate = (rowData) => {
    return (
      <div className="flex align-items-center gap-2">
        <span>{rowData.mode}</span>
      </div>
    );
  };

  const representativeRowFilterTemplate = (options) => {
    return (
      <MultiSelect
        value={selectedmode}
        options={modeOptions}
        itemTemplate={representativesItemTemplate}
        onChange={handleModeChange}
        optionLabel="label"
        className="p-column-filter custom-multiselect"
        style={{ fontSize: '11px' }}
      />
    );
  };

  const getSeverity = (status) => {
    switch (status) {
      case 'close':
        return 'custom-tag-success';
      case 'open':
        return 'custom-tag-warning';
      default:
        return '';
    }
  };


  const statusBodyTemplate = (rowData) => {
    const severityClass = getSeverity(rowData.status);
    return <Tag value={rowData.status} className={severityClass} />;
  };


  return (
    <Layout>
      <PaymentDetailsModal
        show={showModel}
        handleClose={handleClose}
        id={paymentViewId}
        rowData={rowData}
      />
      <Header
        currentTitle={title}
        ItemActions={
          <Stack
            gap={3}
            direction="horizontal"
            className="my-2 justify-content-end"
          >
            <ShortcutKeys />
          </Stack>
        }
      />
      <Container fluid className="px-5">
        <div className="border" style={{ background: "white" }}>
          <VoucherIndex type={voucherType} />
          <DataGridHeader
            handleRefreshPayment={handleRefreshPayment}
            data={payments}
            allData={allPayment}
            clearFilter={handleClearFilters}
            handleCreate={handleClick}
            addFilteredData={setPayments}
            handleExportClick={handleExportPayment}
            datas={exportData}
            button="Create Payment"
            title="payment"
            icons={<UilFilePlusAlt size="50" color="gray" />}
            message={i18n(
              "Create your first payment to see your data."
            )}
            ItemActions={
              <Stack
                gap={3}
                direction="horizontal"
                className=" justify-content-end"
              >
                <Button severity="" style={{ borderRadius: '5px', backgroundColor: '#1a237e', border: '1px solid #1a237e' }} onClick={handleClick}>{button}</Button>
              </Stack>
            }
          />

          <Container fluid className="px-4 mb-4">
            <div className="bordered-container">
              <DataTable
                value={sortedData}
                isAllData={"All Payments"}
                button="Create Payment"
                title="payment"
                paginator
                globalFilter={globalFilter}
                // globalFilterFields={['id', 'created_at','payment_number','type', 'name', 'amount']}
                filterDisplay="row"
                rows={10}
                dataKey="id"
                filters={filters}
                emptyMessage={i18n("No payments found. Please create new payments.")}
                onFilter={(e) => setFilters(e.filters)}
                className="p-datatable-gridlines full-width-table custom-datatable p-component"
                onRowClick={(e) => handlePaymentView(e.data)}
                checked={balanceFrozen}
                scrollable
                sortMode="multiple"
                scrollHeight="400px"
                removableSort
                style={{ cursor: "pointer" }}
              >
                <Column field="payment_number"
                  header={() => (
                    <div
                      className={`custom-header-${sortField === 'payment_number' ? (sortOrder === 1 ? 'up' : 'down') : ''}`}
                      onClick={() => customSort('payment_number')}
                      style={{
                        cursor: 'pointer',
                        display: 'flex',
                        alignItems: 'center',
                        fontSize: '11px',
                        lineHeight: '11px',
                      }}
                    >
                      {i18n("TXN No")}
                      <CustomeSortIcon sortOrder={sortField === 'payment_number' ? sortOrder : 0}
                        style={{ marginLeft: '4px', verticalAlign: 'middle' }} />
                    </div>
                  )} sortable filter filterPlaceholder=""
                  headerStyle={headerCellStyle}
                  showFilterMatchModes={false}
                  showClearButton={false}
                  showFilterOperator={false}
                  showAddButton={false}
                  filterMatchMode={FilterMatchMode.CONTAINS}
                  showApplyButton={false}
                  showFilterMenu={false}
                />

                <Column field="mode"
                  header={() => (
                    <div
                      className={`custom-header-${sortField === 'mode' ? (sortOrder === 1 ? 'up' : 'down') : ''}`}
                      onClick={() => customSort('mode')}
                      style={{
                        cursor: 'pointer',
                        display: 'flex',
                        alignItems: 'center',
                        fontSize: '11px',
                        lineHeight: '11px',
                      }}
                    >
                      {i18n("Mode")}
                      <CustomeSortIcon sortOrder={sortField === 'mode' ? sortOrder : 0}
                        style={{ marginLeft: '4px', verticalAlign: 'middle' }} />
                    </div>
                  )}
                  sortable
                  filter
                  body={modeBodyTemplate}
                  filterPlaceholder=""
                  filterElement={representativeRowFilterTemplate}
                  headerStyle={headerCellStyle}
                  showFilterMatchModes={false}
                  showClearButton={false}
                  showFilterOperator={false}
                  showAddButton={false}
                  filterMatchMode={FilterMatchMode.CONTAINS}
                  showApplyButton={false}
                  showFilterMenu={false}
                />
                <Column field="payment_party.name"
                  header={() => (
                    <div
                      className={`custom-header-${sortField === 'payment_party.name' ? (sortOrder === 1 ? 'up' : 'down') : ''}`}
                      onClick={() => customSort('party.name')}
                      style={{
                        cursor: 'pointer',
                        display: 'flex',
                        alignItems: 'center',
                        fontSize: '11px',
                        lineHeight: '11px',
                      }}
                    >
                      {i18n("Name")}
                      <CustomeSortIcon sortOrder={sortField === 'payment_party.name' ? sortOrder : 0}
                        style={{ marginLeft: '4px', verticalAlign: 'middle' }} />
                    </div>
                  )} sortable filter filterPlaceholder=""
                  headerStyle={headerCellStyle}
                  showFilterMatchModes={false}
                  showClearButton={false}
                  showFilterOperator={false}
                  showAddButton={false}
                  filterMatchMode={FilterMatchMode.CONTAINS}
                  showApplyButton={false}
                  showFilterMenu={false}
                />
                <Column
                  field="amount"
                  header={() => (
                    <div
                      className={`custom-header-${sortField === 'amount' ? (sortOrder === 1 ? 'up' : 'down') : ''}`}
                      onClick={() => customSort('amount')}
                      style={{
                        cursor: 'pointer',
                        display: 'flex',
                        alignItems: 'center',
                        fontSize: '11px',
                        lineHeight: '11px',
                      }}
                    >
                      {i18n("Amount")}
                      <CustomeSortIcon
                        sortOrder={sortField === 'amount' ? sortOrder : 0}
                        style={{ marginLeft: '4px', verticalAlign: 'middle' }}
                      />
                    </div>
                  )}
                  sortable
                  filter
                  filterPlaceholder=""
                  headerStyle={headerCellStyle}
                  showFilterMatchModes={false}
                  showClearButton={false}
                  showFilterOperator={false}
                  showAddButton={false}
                  filterMatchMode={FilterMatchMode.CONTAINS}
                  showApplyButton={false}
                  showFilterMenu={false}
                  body={(rowData) => (
                    <GlobalCurrency price={rowData.amount} />
                  )}
                />

                <Column field="balance"
                  header={() => (
                    <div
                      className={`custom-header-${sortField === 'balance' ? (sortOrder === 1 ? 'up' : 'down') : ''}`}
                      onClick={() => customSort('balance')}
                      style={{
                        cursor: 'pointer',
                        display: 'flex',
                        alignItems: 'center',
                        fontSize: '11px',
                        lineHeight: '11px',
                      }}
                    >
                      Balance
                      <CustomeSortIcon sortOrder={sortField === 'balance' ? sortOrder : 0}
                        style={{ marginLeft: '4px', verticalAlign: 'middle' }} />
                    </div>
                  )} sortable filter filterPlaceholder=""
                  headerStyle={headerCellStyle}
                  showFilterMatchModes={false}
                  showClearButton={false}
                  showFilterOperator={false}
                  showAddButton={false}
                  filterMatchMode={FilterMatchMode.CONTAINS}
                  showApplyButton={false}
                  showFilterMenu={false}
                  body={(rowData) => (
                    <span>
                      {rowData.balance ? `${currencySymbol} ${rowData.balance}` : `${currencySymbol} ${0}`}
                    </span>
                  )}
                />

                <Column field="status"
                  header={() => (
                    <div
                      className={`custom-header-${sortField === 'balance' ? (sortOrder === 1 ? 'up' : 'down') : ''}`}
                      onClick={() => customSort('status')}
                      style={{
                        cursor: 'pointer',
                        display: 'flex',
                        alignItems: 'center',
                        fontSize: '11px',
                        lineHeight: '11px',
                      }}
                    >
                      Balance
                      <CustomeSortIcon sortOrder={sortField === 'status' ? sortOrder : 0}
                        style={{ marginLeft: '4px', verticalAlign: 'middle' }} />
                    </div>
                  )} sortable filter filterPlaceholder=""
                  headerStyle={headerCellStyle}
                  showFilterMatchModes={false}
                  showClearButton={false}
                  showFilterOperator={false}
                  showAddButton={false}
                  filterMatchMode={FilterMatchMode.CONTAINS}
                  showApplyButton={false}
                  showFilterMenu={false}
                  body={statusBodyTemplate}
                />

                <Column
                  field="created_at"
                  header={() => (
                    <div
                      className={`custom-header-${sortField === 'created_at' ? (sortOrder === 1 ? 'up' : 'down') : ''}`}
                      onClick={() => customSort('created_at')}
                      style={{
                        cursor: 'pointer',
                        display: 'flex',
                        alignItems: 'center',
                        fontSize: '11px',
                        lineHeight: '11px',
                      }}
                    >
                      {i18n("Created at")}
                      <CustomeSortIcon sortOrder={sortField === 'created_at' ? sortOrder : 0}
                        style={{ marginLeft: '4px', verticalAlign: 'middle' }} />
                    </div>
                  )}
                  sortable
                  body={dateBodyTemplate}
                  filter
                  filterElement={dateFilterTemplate}
                  filterPlaceholder=""
                  headerStyle={headerCellStyle}
                  showFilterMatchModes={false}
                  showClearButton={false}
                  showFilterOperator={false}
                  showAddButton={false}
                  filterMatchMode={FilterMatchMode.CONTAINS}
                  // showApplyButton={false}
                  showFilterMenu={false}
                />
                <Column field="sync_status"
                  header={() => (
                    <div
                      className={`custom-header-${sortField === 'sync_status' ? (sortOrder === 1 ? 'up' : 'down') : ''}`}
                      onClick={() => customSort('sync_status')}
                      style={{
                        cursor: 'pointer',
                        display: 'flex',
                        alignItems: 'center',
                        fontSize: '11px',
                        lineHeight: '11px',
                      }}

                    >
                      {i18n("Sync Status")}
                      <CustomeSortIcon sortOrder={sortField === 'sync_status' ? sortOrder : 0}
                        style={{ marginLeft: '4px', verticalAlign: 'middle' }} />
                    </div>
                  )}
                  sortable
                  body={(rowData) => {
                    const { sync_status } = rowData;

                    return (
                      <>
                        <SyncStatus status={sync_status} />
                      </>
                    );
                  }}
                  headerStyle={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    textAlign: 'center',
                    width: 'auto',
                  }}
                  bodyStyle={{ width: '9.7%' }}
                  showFilterMatchModes={false}
                  showClearButton={false}
                  showFilterOperator={false}
                  showAddButton={false}
                  filterMatchMode={FilterMatchMode.CONTAINS}
                  showApplyButton={false}
                  showFilterMenu={false}
                />
              </DataTable>
            </div>
          </Container>

        </div>
      </Container>
      {show && (
        <DeleteModel
          deleteUserClick={onDelete}
          onClickDeleteModel={setShow}
          name={name}
        />
      )}
    </Layout>
  );
};
export default VoucherPaymentStack;