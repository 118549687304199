import { useSelector } from "react-redux";

const GlobalCurrency = ({ price }) => {
    const currency = useSelector((state) => state.getSetting?.dataSetting?.currency);
    
    // Ensure price is a number and format it
    const formattedPrice = price 
        ? parseFloat(price).toLocaleString('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) 
        : "0.00";

    return (
        <>
            {currency} {formattedPrice}
        </>
    );
}

export default GlobalCurrency;