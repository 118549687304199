import React from "react";
import {  Container, Row, Col } from "react-bootstrap";
import MemberGeneralInfo from "./party_profile_info/MemberGeneralInfo";
import MemberTrainerInfo from "./party_profile_info/MemberTrainerInfo";

const MemberProfileInfo = ({selectedMember}) => {
   
    return (
        
        <Container className='p-0 mt-5' fluid>
            
            <Row>
                <Col lg={6} md={6} sm={6}>
                    <MemberGeneralInfo selectedMember={selectedMember} />
                </Col>
                <Col lg={6} md={6} sm={6}>
                   <MemberTrainerInfo selectedMember={selectedMember} />
                </Col>
            </Row>
           
        </Container>
    )
}




export default MemberProfileInfo;