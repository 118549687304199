import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { i18n, placeholderText, numValidate } from '../../shared/helpers/sharedMethods';
import { Form, Row, Modal, Button, Col, Stack } from 'react-bootstrap';
import { createInviteUser, reset, fetchInviteUser, resetStatusAndError, updateInviteUser } from '../store/inviteUserSlice';
import { rolesList } from '../../shared/config/rolesConfig';
import { toast, ToastContainer } from "react-toastify";


const EditUserModal = (props) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { handleClose, show, selectedUser } = props;
    const selectedBusiness = useSelector(
        (state) => state?.auth?.selectedBusiness
    );
    const tenantId = selectedBusiness?.pivot?.tenant_id;
    const currentUserId = selectedBusiness?.pivot?.user_id;
    const { accessToken } = useSelector((state) => state?.auth);

    const [user, setUser] = useState({
        name: (selectedUser?.name) ? selectedUser?.name : '',
        phone: (selectedUser?.phone) ? selectedUser?.phone : '',
        role_id: (selectedUser?.role_id) ? selectedUser?.role_id : 3
    })

    const [errors, setErrors] = useState({
        name: '',
        phone: '',
        role_id: ''
    })
    const { status, error } = useSelector((state) => state.inviteUser);
    ;

    useEffect(() => {
        if (status) {
            dispatch(fetchInviteUser({
                tenant_id: tenantId,
                token: accessToken?.access_token,
                currentUserId: currentUserId
            }));
            toast.success("User updated successfully..!")
            handleClose();
            dispatch(resetStatusAndError());
        }
    }, [status]);

    const handleValidation = () => {
        let errorss = {};
        let isValid = false;

        if (!user['phone']) {
            errorss['phone'] = i18n('Please Enter Mobile Number')
        }

        else {
            isValid = true;
        }
        setErrors(errorss);
        return isValid;

    }
    const onChangeInput = (e) => {
        e.preventDefault();
        const { value } = e.target;

        setUser(inputs => ({ ...inputs, [e.target.name]: value && value }));
    };

    useEffect(() => {
        if (!status && error) {
            toast.error(error);
            dispatch(resetStatusAndError());
        }
    }, [status, error]);


    const onSubmit = () => {

        dispatch(updateInviteUser({ tenant_id: tenantId, id: selectedUser?.id, role_id: user?.role_id, token: accessToken.access_token }));

    };
    const clearField = () => {
        handleClose()
    }
    return (
        <>
          <ToastContainer />
            <Modal show={show} dialogClassName='modal-dialog modal-dialog-centered mw-700px'
                onHide={handleClose}
                keyboard={true}
            >
                <Modal.Header closeButton>
                    <Modal.Title>{i18n("Update User")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Stack direction='horizontal' gap={2}>
                            <Stack>
                                <Form.Label className='fw-bolder text-muted'>{i18n("Name")}</Form.Label>
                                <Form.Label>{user.name}</Form.Label>
                            </Stack>
                            <Stack>
                                <Form.Label className='fw-bolder text-muted'>{i18n("Mobile Number")}</Form.Label>
                                <Form.Label>{user.phone}</Form.Label>
                            </Stack>
                        </Stack>
                        <Row>
                            <Form.Group className="pt-2">
                                <Form.Label>{i18n("Role")}</Form.Label>
                                <Form.Select
                                    aria-label="Payment-mode"
                                    name="role_id"
                                    onChange={(e) => {
                                        onChangeInput(e)
                                    }}
                                    value={user.role_id}
                                >
                                    {rolesList && rolesList.length ? (
                                        rolesList.map((role, index) => {
                                            return <option key={role.id} value={role.id}>{role.label}</option>;
                                        })
                                    ) : (
                                        <option value="">{placeholderText("No Role")}</option>
                                    )}
                                </Form.Select>
                            </Form.Group>
                        </Row>
                    </Form>

                </Modal.Body>

                <Col className='d-flex justify-content-end px-5 mb-5 mt-3 mx-2' style={{ columnGap: 10 }}>
                    <Button onClick={onSubmit} className='btn btn-primary fw-bold' style={{ color: 'white' }}>{i18n("Update")}</Button>
                    <Button className='btn btn-secondary fw-bold' onClick={clearField} style={{ color: 'white' }}>{i18n("Cancel")}</Button>
                </Col>
            </Modal>
        </>
    )
};


export default EditUserModal;