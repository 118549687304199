import {
    createSlice,
    createAsyncThunk,
  } from "@reduxjs/toolkit";
  import { i18n } from "../../shared/helpers/sharedMethods";

  const initialState = {
    status:false,
    reportItems:[],
    error:false,
    errorMessage:{}
  };

export const fetchDailyChart = createAsyncThunk('dailyChart/fetch', async (param) => {
  try{
    const dailyChartResponse  = await window.api.dashboardDailyChart(param);

    return dailyChartResponse.data;
  }catch(err){
    await window.log.logToFile({message: "Error while fetching Daily Chart", level: "error" });
    throw new Error("Error while fetching Daily Chart report")
  }
});

const dailyChartSlice = createSlice({
    name: "dailyChart",
    initialState,
    reducers: {},
    extraReducers(builder) {
      builder
      .addCase(fetchDailyChart.fulfilled, (state, action) => {
        state.status = true
        state.reportItems =action.payload
      })
      .addCase(fetchDailyChart.rejected, (state, action) => {
        state.error = true; 
        state.errorMessage=i18n("Unable to retrieve Daily Chart report. Please try again later.");
        state.status = false;
      })

    },
});

export default dailyChartSlice.reducer;

