import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { ListGroup } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { i18n } from "../../shared/helpers/sharedMethods";
import BillWiseReport from "../screens/BillWiseReport";
import DayBookReport from "../screens/DayBookReport";
import ProfitAndLoss from "../screens/ProfitAndLoss";
import SalesSummary from "../screens/SalesSummary";
import PurchaseSummary from "../screens/PurchaseSummary";
import TabLayout from "../../shared/components/item-detail/TabLayout";


const TransactionNavbar = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

     const transactionTabs = [
       {
         key: "transaction/bill-wise-profit",
         title: i18n("Bill Wise Profit"),
         component: <BillWiseReport />,
       },
       {
         key: "transaction/day-book",
         title: i18n("Daybook Report"),
         component: <DayBookReport />,
       },
       {
         key: "transaction/profit-and-loss",
         title: i18n("Profit And Loss Report"),
         component: <ProfitAndLoss />,
       },
       {
         key: "transaction/sales-summary",
         title: i18n("Sales Summary"),
         component: <SalesSummary />,
       },
       {
         key: "transaction/purchase-summary",
         title: i18n("Purchase Summary"),
         component: <PurchaseSummary />,
       },
     ];

  return (
    <>
      {/* <div
        className="col-lg-12 "
        style={{ height: "90vh", borderRight: "1px solid lightgray" }}
      >
        <ListGroup className="custom-listgroup-css py-2" variant="flush">
          <ListGroup.Item
            className={location.pathname == "/reports/billWise" ? "active-highlight" : ""}
            onClick={() => {
              navigate("/reports/billWise");
            }}
          >
            <Link className="d-flex " to="/reports/billWise">
              {" "}

            {i18n("Bill Wise Profit")}
            </Link>
          </ListGroup.Item>
          <ListGroup.Item   className={location.pathname == "/reports/dayBook" ? "active-highlight" : ""}
            onClick={() => {
              navigate("/reports/dayBook");
            }}
          >
            <Link className="d-flex " to="/reports/dayBook">
            {i18n("Daybook")}
            </Link>
          </ListGroup.Item>
          <ListGroup.Item className={location.pathname == "/reports/profitAndLoss" ? "active-highlight" : ""}
            onClick={() => {
              navigate("/reports/profitAndLoss");
            }}
          >
            <Link className="d-flex " to="/reports/profitAndLoss">
            {i18n("Profit And Loss Report")}
            </Link>
          </ListGroup.Item>
          <ListGroup.Item className={location.pathname == "/reports/salesSummary" ? "active-highlight" : ""}
            onClick={() => {
              navigate("/reports/salesSummary");
            }}
          >
            <Link className="d-flex " to="/reports/salesSummary">
            {i18n("Sales Summary")}
            </Link>
          </ListGroup.Item>

          <ListGroup.Item className={location.pathname == "/reports/purchaseSummary" ? "active-highlight" : ""}
            onClick={() => {
              navigate("/reports/purchaseSummary");
            }}
          >
            <Link className="d-flex " to="/reports/purchaseSummary">
            {i18n("Purchase Summary")}
            </Link>
          </ListGroup.Item>

        </ListGroup>
      </div> */}

      <TabLayout
        type="reports"
        tabs={transactionTabs}
        route="reports"
        title="Report"
      />
    </>
  );
};

export default TransactionNavbar;
