import React, { useState, useEffect } from "react";
import { Button, Row, Col, Stack, Container,Image, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Layout from "../../shared/components/Layout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowDown,
  faArrowLeft,
  faArrowUp,
  faChevronLeft,
  faDownload,
} from "@fortawesome/free-solid-svg-icons";
import { downloadCsvFile, handleExportClick } from "../../shared/export";
import DataGrid from "../../shared/components/DataGrid";
import { format } from "date-fns";
import { fetchDayBookReport } from "../store/dayBookReportSlice";
import ReportPeriod from "../../shared/components/ReportPeroid";
import TypeFilter from "../components/Filters/TypeFilter";
import Header from "../../shared/components/Header";
import { i18n } from "../../shared/helpers/sharedMethods";
import TransactionNavbar from "../components/TransactionNavbar";
import ShortcutKeys from "../../shared/components/ShortcutKeys";
import { formatAmount } from "../../pos/helpers/cartHelper";
import ReportDateRange from "../../shared/components/date_range/ReportDateRange";
import DatePicker from "../../shared/components/date_range/DatePicker";
import { CurrentWeekDateRange } from "../../shared/components/DateRangeYAxis";
import ReportHeader from "../components/ReportHeader";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import CustomeSortIcon from "../../shared/components/CustomeSortIcon";
import { UilImport } from "@iconscout/react-unicons";
import { getLocalizedDate } from '../../shared/helpers/localizationHelper';
import LoadingSpinner from '../../shared/components/LoadingSpinner';
import GlobalCurrency from "../../shared/components/GlobalCurrency";
import { formatVoucherType } from "../../shared/helpers/typeHelper";
const DayBookReport = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const accessToken = useSelector((state) => state?.auth?.accessToken?.access_token);
  const selectedBusiness = useSelector((state) => state?.auth?.selectedBusiness);
  const tenantId = selectedBusiness?.pivot?.tenant_id;
  const [reportPeriod, setReportPeriod] = useState("current-week");
  const [reportType, setReportType] = useState("");
  const [reportStatus, setReportStatus] = useState();
  const [csvData, setCsvData] = useState("");
  const [startDate, setStartDate] = useState(null); // State for start date
  const [endDate, setEndDate] = useState(null);
  const [sortField, setSortField] = useState(null);
  const [sortOrder, setSortOrder] = useState(1);

  const handleReset = () => {
    const salesDateLimits = ReportDateRange({
      reportPeriod: reportPeriod,
    });
    setStartDate(salesDateLimits.startDate);
    setEndDate(salesDateLimits.endDate);
    setReportPeriod("current-week");
  };


  useEffect(() => {
    dispatch(fetchDayBookReport({startDate: startDate, endDate: endDate,reportType: reportType, token:accessToken,tenant_id:tenantId})); 
  }, [startDate, endDate, reportType]);

  useEffect(() => {
    const dateLimits = ReportDateRange({ reportPeriod });
    setStartDate(dateLimits.startDate);
    setEndDate(dateLimits.endDate);
  }, [reportPeriod]);

  const reportItems = useSelector((state) => state.dayBookReport.reportItems);
  const status = useSelector((state) => state.dayBookReport.status);
  const loading = useSelector((state) => state.dayBookReport.loading);
  const errorMessage = useSelector((state) => state.dayBookReport.errorMessage);
  const currencySymbol = useSelector((state) => state.getSetting?.dataSetting.currency);
  ;

  let totalMoneyIn = 0;
  let totalMoneyOut = 0;

  if (status && Array.isArray(reportItems)) {
    for (const item of reportItems) {
      totalMoneyIn += item.moneyIn || 0;
      totalMoneyOut += item.moneyOut || 0;
    }
  }

  const totalProfit = totalMoneyIn - totalMoneyOut;

  useEffect(() => {
    if (totalProfit < 0) {
      setReportStatus("red");
    } else if (totalProfit > 0) {
      setReportStatus("green");
    } else {
      setReportStatus("black");
    }
  }, [totalProfit]);
  const customSort = (field) => {
    setSortField(field);
    setSortOrder(sortOrder === 1 ? -1 : 1);
  };

  const sortedData = [...reportItems].sort((a, b) => {
    if (sortField) {
      const compareValueA = a[sortField];
      const compareValueB = b[sortField];
      if (compareValueA < compareValueB) {
        return sortOrder;
      }
      if (compareValueA > compareValueB) {
        return -sortOrder;
      }
    }
    return 0;
  });
  const headerStyle = {
    backgroundColor: "white",
  };
  const headerCellStyle = {
    fontWeight: "bold",
    fontSize: "11px",
  };
  const dateTimeString = new Date()
    .toLocaleString()
    .replace(/[-]/g, " ")
    .replace(/:/g, "-");
  const fileName = `Day_book_report_${dateTimeString}`;

  const handleExportButtonClick = () => {
    const exportData =
      reportItems &&
      Array.isArray(reportItems) &&
      reportItems.map((val) => ({
        "DATE": val.createdAt ? format(new Date(val.createdAt), "dd-MM-yyyy ") : "-",
        "PARTY NAME": val.partyName ? val.partyName : "-",
        "TRANSACTION TYPE": val.transactionType ? formatVoucherType(val.transactionType) : "-",
        "TRANSACTION NO.": val.srNo ? val.srNo : "-",
        "TOTAL AMOUNT": val?.total ? parseFloat(val.total).toFixed(2) : "0",
        "MONEY IN": val.moneyIn ? val.moneyIn : "0",
        "MONEY OUT": val.moneyOut ? val.moneyOut : "0",
        "BALANCE AMOUNT": val.balanceAmount
          ? formatAmount(parseFloat(val.balanceAmount))
          : "0",
      }));
    handleExportClick(
      exportData,
      setCsvData,
      downloadCsvFile,
      fileName
    );
  };

  return (
    <Layout>
          {loading && <LoadingSpinner />}
      <Header
        currentTitle={i18n("Daybook Report")}
        ItemActions={
          <Stack gap={3} direction="horizontal" className="mt-1 mb-1 float-end">
            <ShortcutKeys />
          </Stack>
        }
      />
      <Container fluid className="px-5">
        <div className="border " style={{ background: "white" }}>
          <TransactionNavbar />

          <label className="d-flex flex-direction-row p-4 fs-3">
            {i18n("Net Amount")}{" "}&nbsp;:&nbsp;
            {reportStatus === "red" ? (
              // <label className=" fw-bolder fs-2  " style={{ color: "red" }}>
              //   {" "}

              //   {totalProfit < 0 ? "-" : ""} {currencySymbol}{" "}
              //   {Math.abs(totalProfit) || 0}
              <div className="d-flex align-items-center">
                <FontAwesomeIcon
                  className="px-1 fs-4"
                  icon={faArrowDown}
                  style={{ color: "red", width: "16px", }}
                />{" "}
                <Form.Label className="mb-0 fs-3 " style={{ color: 'red' }}>
                  <GlobalCurrency price={totalProfit} />
                </Form.Label>
              </div>
              // </label>
            ) : reportStatus === "green" ? (

              <div className="d-flex align-items-center" >
                <FontAwesomeIcon
                  className="px-1 fs-4"
                  icon={faArrowUp}
                  style={{
                    color: "green",
                    width: "16px",
                    // paddingTop: "3px",
                  }}
                />
                <Form.Label className="mb-0 fs-3 " style={{ color: 'green' }}>
                  <GlobalCurrency price={totalProfit} />
                </Form.Label>
              </div>
            ) : (
              <label className="fw-bolder fs-2 ">
                {" "}
                {/* {formatAmount(totalProfit) || 0} */}
                <GlobalCurrency price={totalProfit} />
              </label>
            )}
          </label>

          <ReportHeader
            ItemActions={
              <Stack gap={2} direction="horizontal" className="pt-3 px-4 mb-3">
                <Stack direction="horizontal">
                  <DatePicker
                    startDate={startDate}
                    setStartDate={setStartDate}
                    endDate={endDate}
                    setEndDate={setEndDate}
                  />
                </Stack>
                <Stack className=" ">
                  <ReportPeriod
                    reportPeriod={reportPeriod}
                    setReportPeriod={setReportPeriod}
                  />
                </Stack>
                <Stack className=" ">
                  <TypeFilter
                    reportType={reportType}
                    setReportType={setReportType}
                  />
                </Stack>
                <Stack
                  gap={1}
                  direction="horizontal"
                  style={{ marginLeft: "1px" }}
                >
                  <Button
                    className="rounded-0"
                    variant="outline-danger"
                    onClick={handleReset}
                  >
                      {i18n("Reset")}
                  </Button>
                </Stack>
                <Button
                  className="fw-bolder d-flex align-items-center rounded-0"
                  onClick={handleExportButtonClick}
                >
                  <UilImport
                    size="18"
                    className="mx-2"
                    style={{ cursor: "pointer" }}
                  />
                  {i18n("Export")}
                </Button>
              </Stack>
            }
          />
          <Container fluid className="px-4 mb-4">
            <div className="bordered-container">
              {status && reportItems ? (
                <DataTable
                  value={sortedData}
                  headerStyle={headerStyle}
                  paginator
                  rows={10}
                  dataKey="id"
                  emptyMessage="No DayBook Report found."
                  className="p-datatable-gridlines full-width-table custom-datatable p-component"
                  scrollable
                  scrollHeight="400px"
                  sortMode="multiple"
                  removableSort
                >
                  <Column
                    field="createdAt"
                    header={() => (
                      <div
                        className={`custom-header-${sortField === "createdAt"
                          ? sortOrder === 1
                            ? "up"
                            : "down"
                          : ""
                          }`}
                        onClick={() => customSort("createdAt")}
                        style={{
                          cursor: "pointer",
                          display: "flex",
                          alignItems: "center",
                          fontSize: "11px",
                          lineHeight: "11px",
                        }}
                      >
                        {i18n("DATE")}
                        <CustomeSortIcon
                          sortOrder={sortField === "createdAt" ? sortOrder : 0}
                          style={{ marginLeft: "4px", verticalAlign: "middle" }}
                        />
                      </div>
                    )}
                    body={(row) =>
                      row.createdAt ? (
                        <span>{getLocalizedDate(row.createdAt, 'en-IN').split(',')[0]}</span>) : (
                        "-"
                      )
                    }
                    sortable
                    headerStyle={headerCellStyle}
                  />
                  <Column
                    field="partyName"
                    header={() => (
                      <div
                        className={`custom-header-${sortField === "partyName"
                          ? sortOrder === 1
                            ? "up"
                            : "down"
                          : ""
                          }`}
                        onClick={() => customSort("partyName")}
                        style={{
                          cursor: "pointer",
                          display: "flex",
                          alignItems: "center",
                          fontSize: "11px",
                          lineHeight: "11px",
                        }}
                      >
                        {i18n("Party Name")}
                        <CustomeSortIcon
                          sortOrder={sortField === "partyName" ? sortOrder : 0}
                          style={{ marginLeft: "4px", verticalAlign: "middle" }}
                        />
                      </div>
                    )}
                    sortable
                    headerStyle={headerCellStyle}
                  />
                  <Column
                    field="transactionType"
                    header={() => (
                      <div
                        className={`custom-header-${sortField === "transactionType"
                          ? sortOrder === 1
                            ? "up"
                            : "down"
                          : ""
                          }`}
                        onClick={() => customSort("transactionType")}
                        style={{
                          cursor: "pointer",
                          display: "flex",
                          alignItems: "center",
                          fontSize: "11px",
                          lineHeight: "11px",
                        }}
                      >
                        {i18n("TRANSACTION TYPE")}
                        <CustomeSortIcon
                          sortOrder={
                            sortField === "transactionType" ? sortOrder : 0
                          }
                          style={{ marginLeft: "4px", verticalAlign: "middle" }}
                        />
                      </div>
                    )}
                    body={(row) => (
                      <span>{row.transactionType ? formatVoucherType(row.transactionType) : '-'}</span>
                    )}
                    sortable
                    headerStyle={headerCellStyle}
                  />
                  <Column
                    field="srNo"
                    header={() => (
                      <div
                        className={`custom-header-${sortField === "srNo"
                          ? sortOrder === 1
                            ? "up"
                            : "down"
                          : ""
                          }`}
                        onClick={() => customSort("srNo")}
                        style={{
                          cursor: "pointer",
                          display: "flex",
                          alignItems: "center",
                          fontSize: "11px",
                          lineHeight: "11px",
                        }}
                      >
                        {i18n("TRANSACTION NO.")}
                        <CustomeSortIcon
                          sortOrder={sortField === "srNo" ? sortOrder : 0}
                          style={{ marginLeft: "4px", verticalAlign: "middle" }}
                        />
                      </div>
                    )}
                    sortable
                    headerStyle={headerCellStyle}
                  />
                  <Column
                    field="total"
                    header={() => (
                      <div
                        className={`custom-header-${sortField === "total"
                          ? sortOrder === 1
                            ? "up"
                            : "down"
                          : ""
                          }`}
                        onClick={() => customSort("total")}
                        style={{
                          cursor: "pointer",
                          display: "flex",
                          alignItems: "center",
                          fontSize: "11px",
                          lineHeight: "11px",
                        }}
                      >
                        {i18n("TOTAL AMOUNT")}
                        <CustomeSortIcon
                          sortOrder={sortField === "total" ? sortOrder : 0}
                          style={{ marginLeft: "4px", verticalAlign: "middle" }}
                        />
                      </div>
                    )}
                    body={(row) =>
                      row.total ? (
                        <span>
                          <GlobalCurrency price={row.total} />
                        </span>
                      ) : (
                        <span>
                          <GlobalCurrency price={0} />
                        </span>
                      )
                    }
                    sortable
                    headerStyle={headerCellStyle}
                  />
                  <Column
                    field="moneyIn"
                    header={() => (
                      <div
                        className={`custom-header-${sortField === "moneyIn"
                          ? sortOrder === 1
                            ? "up"
                            : "down"
                          : ""
                          }`}
                        onClick={() => customSort("moneyIn")}
                        style={{
                          cursor: "pointer",
                          display: "flex",
                          alignItems: "center",
                          fontSize: "11px",
                          lineHeight: "11px",
                        }}
                      >
                        {i18n("MONEY IN")}
                        <CustomeSortIcon
                          sortOrder={sortField === "moneyIn" ? sortOrder : 0}
                          style={{ marginLeft: "4px", verticalAlign: "middle" }}
                        />
                      </div>
                    )}
                    body={(row) =>
                      row.moneyIn ? (
                        <span>
                          <GlobalCurrency price={row.moneyIn} />
                        </span>
                      ) : (
                        <span>
                          <GlobalCurrency price={0} />
                        </span>
                      )
                    }          
                    sortable
                    headerStyle={headerCellStyle}
                  />
                  <Column
                    field="moneyOut"
                    header={() => (
                      <div
                        className={`custom-header-${sortField === "moneyOut"
                          ? sortOrder === 1
                            ? "up"
                            : "down"
                          : ""
                          }`}
                        onClick={() => customSort("moneyOut")}
                        style={{
                          cursor: "pointer",
                          display: "flex",
                          alignItems: "center",
                          fontSize: "11px",
                          lineHeight: "11px",
                        }}
                      >
                        {i18n("MONEY OUT")}
                        <CustomeSortIcon
                          sortOrder={sortField === "moneyOut" ? sortOrder : 0}
                          style={{ marginLeft: "4px", verticalAlign: "middle" }}
                        />
                      </div>
                    )}
                    body={(row) =>
                      row.moneyOut ? (
                        <span>
                          <GlobalCurrency price={row.moneyOut} />
                        </span>
                      ) : (
                        <span>
                          <GlobalCurrency price={0} />
                        </span>
                      )
                    }
                    sortable
                    headerStyle={headerCellStyle}
                  />
                  <Column
                    field="balanceAmount"
                    header={() => (
                      <div
                        className={`custom-header-${sortField === "balanceAmount"
                          ? sortOrder === 1
                            ? "up"
                            : "down"
                          : ""
                          }`}
                        onClick={() => customSort("balanceAmount")}
                        style={{
                          cursor: "pointer",
                          display: "flex",
                          alignItems: "center",
                          fontSize: "11px",
                          lineHeight: "11px",
                        }}
                      >
                        {i18n("BALANCE AMOUNT")}
                        <CustomeSortIcon
                          sortOrder={
                            sortField === "balanceAmount" ? sortOrder : 0
                          }
                          style={{ marginLeft: "4px", verticalAlign: "middle" }}
                        />
                      </div>
                    )}
                    body={(row) => (
                      <span>
                        {row.balanceAmount ? (
                          <>
                            {row.balanceAmount < 0 ? "-" : ""}
                            <GlobalCurrency price={Math.abs(row.balanceAmount)} />
                          </>
                        ) : (
                          "-"
                        )}
                      </span>
                    )}
                    sortable
                    headerStyle={headerCellStyle}
                  />
                </DataTable>
              ) : (
                <p>{i18n("Loading")}</p>
              )}
            </div>
          </Container>
        </div>
      </Container>
    </Layout>
  );
};

export default DayBookReport;