import React from 'react';
import {  Row, Col, Container } from 'react-bootstrap';
import AllTabs from './sections/tabs';

const TabLayout = ({type, title,tabs,route }) => {
  
    return (
            
            <Row>
                <Col lg={12} md={6} xs={8}> 
                    <AllTabs type={type}  title={title} tabs={tabs} route={route} />
                </Col>
            </Row>
    

      
    )
}

export default TabLayout;