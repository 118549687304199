import {
  Button,
  ButtonGroup,
  Dropdown,
  InputGroup,
  OverlayTrigger,
  Row,
  Tooltip,
  Col,
  Image,
} from "react-bootstrap";
import { useSelector } from "react-redux";
import { faExchange } from "@fortawesome/free-solid-svg-icons";
import BusinessSwitchDropdown from "../BusinessSwitchDropdown";
import QuickAddDropdown from "../QuickAddDropdown";
import { i18n } from "../../helpers/sharedMethods";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const SideBarHeader = (props) => {
  const { setIsHovered, isHovered, handleSwitch } = props;
  ;
  const getSetting = useSelector((state) => state.getSetting?.dataSetting);
  const isMenuCollapse = useSelector((state) => state.sharedSlice.isToggleShow);
  const selectedBusiness = useSelector(
    (state) => state?.auth?.selectedBusiness
  );
  return (
    <Col
      className="custom-business-header mt-1 p-1"
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        borderBottom: "1px solid lightgray",
      }}
    >
      {getSetting?.logo ? (
        <Image
          className="py-2 mt-1 mb-1"
          style={{ maxWidth: isMenuCollapse ? "50px" : "150px",objectFit: "contain", maxHeight: "70px", width: "100%",
            height: "auto",}}
          src={getSetting ? getSetting?.logo : Image}
          fluid
        />
      ) : (
        <div
          style={{
            maxWidth: isMenuCollapse ? "50px" : "125px",
            height: "75px",
          }}
        ></div>
      )}
    </Col>
  );
};
export default SideBarHeader;
