import {
    createSlice,
    createAsyncThunk,
  } from "@reduxjs/toolkit";
import { i18n } from "../../shared/helpers/sharedMethods";

  const initialState = {
    status:false,
    reportItems:[],
    error:false,
    errorMessage:{}
  };

export const fetchProductCategoryFilterChart = createAsyncThunk('ProductCategoryFilterChart/fetch', async (param) => {
  try{
    const ProductCategoryFilterChartResponse  = await window.api.productCategoryFilterChart(param);

    return ProductCategoryFilterChartResponse.data;
  }catch(err){
    await window.log.logToFile({message: "Unable to fetch Product Category filter", level: "error" });
    throw new Error("Error while fetching Product Category Filter Chart")
  }
});

const productCategoryFilterChartSlice = createSlice({
    name: "productCategoryFilterChart",
    initialState,
    reducers: {},
    extraReducers(builder) {
      builder
      .addCase(fetchProductCategoryFilterChart.fulfilled, (state, action) => {
        state.status = true
        state.reportItems =action.payload
      })
      .addCase(fetchProductCategoryFilterChart.rejected, (state, action) => {
        state.error = true; 
        state.errorMessage = i18n('Unable to load product categories right now. Please try again later.');
        state.status = false;
      })

    },
});

export default productCategoryFilterChartSlice.reducer;
