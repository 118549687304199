import React, { useState, useEffect } from "react";
import { Card, Col, Form, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Chart from "./Chart";
import ReportPeriod from "../../../../shared/components/ReportPeroid";
import DateRangeIndex from "../../../../shared/components/date_range/DateRangeIndex";
import { fetchTopTenPurchaseParty } from "../../../store/purchase_chart/topTenPurchasePartySlice";


function TopTenPurchasePartyIndex() {
  const dispatch = useDispatch();
  const [reportPeriod, setReportPeriod] = useState("current-week");

  useEffect(() => {
    dispatch(fetchTopTenPurchaseParty(reportPeriod));
  }, [reportPeriod]);
  const [labelName, setLabelName] = useState([]);
  const [purchaseAmount, setPurchaseAmount] = useState([]);

  const reportItems = useSelector((state) => state.topTenPurchaseParty.reportItems);

 useEffect(() => {
  if (Array.isArray(reportItems)) {
      const partyNames = reportItems && reportItems.map(item => item.PartyName.charAt(0).toUpperCase() + item.PartyName.slice(1));
      setLabelName(partyNames);
      const purchaseAmounts = reportItems && reportItems.map(item => item.purchaseAmount);
      setPurchaseAmount(purchaseAmounts);
  }
}, [reportPeriod,reportItems]);

  return (
    <Card id="chart" className="border mb-3">
      <Card.Header className="border-0 pt-0"></Card.Header>
      <Card.Body className="py-0">
        <Row className="row">
          <Col xl={9} md={9} sm={9}>
            <Chart 
             labelName= {labelName} 
             purchaseAmount={purchaseAmount}
             reportPeriod={reportPeriod} 
            />
          </Col>
          <Col xl={3} md={3} sm={3} className="d-flex justify-content-end">
            <Col >

                   <ReportPeriod
                      reportPeriod={reportPeriod}
                      setReportPeriod={setReportPeriod}
                    />
              <Col className="pt-8">
              <DateRangeIndex reportPeriod={reportPeriod} />
              </Col>
            </Col>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
}

export default TopTenPurchasePartyIndex;
