import React from 'react';
import { Container, Row, Stack, Button } from 'react-bootstrap';
import SummaryTopActions from './fragments/SummaryTopActions';
import BillDetails from './fragments/BillDetails';
import ReceivedAmount from './fragments/ReceivedAmount';
import CustomerDetails from './fragments/CustomerDetails';
import SummaryActions from './fragments/SummaryActions';

const SummaryIndex = () => {
    
    return (
        <Container className='summary-section' fluid>
            <Row >
                <SummaryTopActions />
            </Row>
            <Row className='mx-0'>
                <BillDetails />
            </Row>
            <Row className='mx-0'>
                <ReceivedAmount />
            </Row>
            <Row className='mx-0'>
                <CustomerDetails />
            </Row>
            <Row className='mx-0'>
                <SummaryActions />
            </Row>
        </Container>
    )
}

export default SummaryIndex;