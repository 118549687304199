import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchProducts } from '../store/productsSlice';
import { getStockReport } from '../store/stockReportSlice'
import ProductOverView from '../screens/ProductOverview';
import TabLayout from '../../shared/components/item-detail/TabLayout';
import ProductStack from '../screens/ProductIndex';
import { i18n } from '../../shared/helpers/sharedMethods';


const ProductHeader = () => {

  const { accessToken,isLoggedIn} = useSelector((state) => state?.auth)
  
  const dispatch = useDispatch();

  useEffect(() => {
    //fetching all products from sqlite
    dispatch(fetchProducts());
  }, [])

 

  const productTabs = [
  
    // {
    //   key: 'OverView',
    //   title: (i18n('Overview')),
    //   component:<ProductOverView />

    // },
    {
      key: 'products',
      title: (i18n('All Products')),
      component:  <ProductStack />
      
    },

   
  ]

  return (
    

      <TabLayout type="catalog" tabs={productTabs} route="catalog" title="Products" />
    
     
  )
}

export default ProductHeader;