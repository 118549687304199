import {
  createSlice,
  createAsyncThunk,
} from "@reduxjs/toolkit";
import { i18n } from "../../shared/helpers/sharedMethods";
import { axiosRequest } from "../../shared/helpers/axiosHelper";

const initialState = {
  status:false,
  error:false,
  productUnitSold:[],
  productUnitsReturned: [],
  lifeTimeSales:0,
  unitPerDay:0,
  errorMessage:{}
};

export const fetchProductTotalSoldQuantity = createAsyncThunk('productTotalSoldQuantity/fetch', async (param) => {
try{
  const token = param.token;
  const tenantId = param.tenant_id;
  const id = param.id;
  const productTotalSoldQuantityResponse  = await axiosRequest({
    method: "GET",
    url: `get-total-sold-quantity`,
    params: {
      product_id: id,
    },
    headers: {
      Authorization: `Bearer ${token}`,
      "X-Tenant": tenantId,
    },
  });
  
  return productTotalSoldQuantityResponse.data;
}catch(err){
  await window.log.logToFile({message: "Error while fetching product units sold", level: "error" });
  throw new Error("Error while fetching product units sold")
}
});

export const productUnitsReturnedQuantity = createAsyncThunk('productSoldQuantity/unitsReturned', async (param) => {
  try {
    const token = param.token;
    const tenantId = param.tenant_id;
    const id = param.id;
    const productUnitsReturnedResponse = await axiosRequest({
      method: "GET",
      url: `get-total-return-quantity`,
      params: {
        product_id: id,
      },
      headers: {
        Authorization: `Bearer ${token}`,
        "X-Tenant": tenantId,
      },
    });
    
    return productUnitsReturnedResponse.data;
  } catch(err) {
    await window.log.logToFile({message: "Error while fetching product units returned", level: "error" });
    throw new Error("Error while fetching product units returned")
  }
})

export const lifeTimeSales = createAsyncThunk('productTotalSoldQuantity/lifeTimeSales', async (param) => {
  try{
    const token = param.token;
    const tenantId = param.tenant_id;
    const id = param.id;
    const lifeTimeSalesResponse  = await await axiosRequest({
      method: "GET",
      url: `get-life-time-sales`,
      params: {
        product_id: id,
      },
      headers: {
        Authorization: `Bearer ${token}`,
        "X-Tenant": tenantId,
      },
    });
    
    return lifeTimeSalesResponse?.data
  }catch(err){
    
    await window.log.logToFile({message: "Error while fetching product Summary Chart", level: "error" });
    throw new Error("Error while fetching product Summary Chart")
  }
  });

  export const unitPerDay = createAsyncThunk('productTotalSoldQuantity/unitPerDay', async (param) => {
    try{
      const token = param.token;
      const tenantId = param.tenant_id;
      const id = param.id;
      const unitPerDayResponse  = await axiosRequest({
        method: "GET",
        url: `get-units-per-day`,
        params: {
          product_id: id,
        },
        headers: {
          Authorization: `Bearer ${token}`,
          "X-Tenant": tenantId,
        },
      });
      
      return unitPerDayResponse?.data;
    }catch(err){
      await window.log.logToFile({message: "Error while fetching product Summary Chart", level: "error" });
      throw new Error("Error while fetching product Summary Chart")
    }
    });

const productTotalSoldQuantitySlice = createSlice({
  name: "productTotalSoldQuantity",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
    .addCase(fetchProductTotalSoldQuantity.fulfilled, (state, action) => {
      
      state.productUnitSold =action.payload;
    })
    .addCase(fetchProductTotalSoldQuantity.rejected, (state, action) => {
      state.error = true; 
      state.errorMessage = i18n('Unable to retrieve the product total sold quantity. Please try again later.');
      state.status = false;
    })
    .addCase(productUnitsReturnedQuantity.fulfilled, (state, action) => {
      
      state.productUnitsReturned =action.payload;
    })
    .addCase(productUnitsReturnedQuantity.rejected, (state, action) => {
      state.error = true; 
      state.errorMessage = i18n('Error while fetching product unit returned quantity. Please try again later.');
      state.status = false;
    })

    .addCase(lifeTimeSales.fulfilled, (state, action) => {
     state.lifeTimeSales = action.payload
    })
    .addCase(lifeTimeSales.rejected, (state, action) => {
      state.error = true; 
      state.errorMessage = i18n('Error while get life time sales');
       state.status = false;
     })
     .addCase(unitPerDay.fulfilled, (state, action) => {
       state.unitPerDay = action.payload
     })
     .addCase(unitPerDay.rejected, (state, action) => {
       state.error = true; 
       state.errorMessage = i18n('Error while get unit per day');
       state.status = false;
     })

  },
});

export default productTotalSoldQuantitySlice.reducer;
