import React, { useState } from "react";
import Header from "./Header";
import Navbar from "./Navbar";
import "./sidebar.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLeftLong, faRightLong } from "@fortawesome/free-solid-svg-icons";
import CreateProduct from "../../catalog/screens/CreateProduct";
import { useDispatch, useSelector } from "react-redux";
import { changeToggleShow } from "../store/sharedSlice";

const Layout = ({ children }) => {
  const [isNavBtnClicked, setIsNavBtnClicked] = useState(false);
 // const [isMenuCollapse, setIsMenuCollapse] = useState(false);
  const [showParty, setShowParty] = useState(false);
  const [showProduct, setShowProduct] = useState(false);
  const dispatch = useDispatch();
  const isMenuCollapse = useSelector((state) => state.sharedSlice.isToggleShow);
  ;

  const menuClick = () => {
    dispatch(changeToggleShow(true));
  };

  const menuCancel = () => {
    dispatch(changeToggleShow(false));
  };

  return (
    <div className="">
      <div className="d-flex col-lg-12" style={{ width: "100%" }}>
        <div className={`${isMenuCollapse ? "" : ""} `}>
          <Navbar />
        </div>
        {/* <div
          className="d-flex justify-content-center align-items-center  rounded-circle"
          onClick={isMenuCollapse ? menuCancel : menuClick}
          style={{
            cursor: "pointer",
            position: "absolute",
            top: isMenuCollapse ? "42px" : "82px",
            left: isMenuCollapse ? "60px" : "230px",
            zIndex: 100,
            background: "white",
            right: "0",
            height: "40px",
            width: "40px",
            // borderRadius: "5px",
            boxShadow: "0px 8px 14px 0px rgba(15, 42, 81, 0.04)",
            border: "1px solid #dee2e6",
            //padding: "5px",
          }}
        >
          <FontAwesomeIcon
            className="fs-6 "
            icon={isMenuCollapse ? faRightLong : faLeftLong}
            size="md"
            cursor="pointer"
            color="#1a237e"
          />
        </div> */}
        <div className={`${isMenuCollapse ? "w-100" : "w-100"}`}>
          <div
            style={{
              // height: "calc(100vh - 30px)",
              // overflow: "scroll",
              // overflowX: "hidden",
            }}
          >
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Layout;