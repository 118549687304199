import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCocktail, faBox, faDollarSign, faShoppingCart,
  faExchange,
  faBook,
  faFileInvoice,
} from "@fortawesome/free-solid-svg-icons";
import { Link, useLocation } from "react-router-dom";
import {
  Sidebar,
  Menu,
  MenuItem,
  SubMenu,
  sidebarClasses,
} from "react-pro-sidebar";
import { useDispatch, useSelector } from "react-redux";
import Image from "../../../assets/images/bill.png";
import BusinessSwitchScrren from "../../auth/screens/BusinessSwitchScreen";
import SaleModal from "../../voucher/components/SaleModal";
import PurchaseModal from "../../voucher/components/PurchaseModal";
import {
  Button,
  ButtonGroup,
  Col,
  Dropdown,
  Form,
  InputGroup,
  OverlayTrigger,
  Row,
  Tooltip,
} from "react-bootstrap";
import bill from "../../../assets/logo/logo6-small.png";
import QuickAddDropdown from "./QuickAddDropdown";
import BusinessSwitchDropdown from "./BusinessSwitchDropdown";
import { i18n } from "../helpers/sharedMethods";
import { aboutDetails, changeSubmenu } from "../store/sharedSlice";
import { changeToggleShow } from "../store/sharedSlice";
import {
  UilFileAlt,
  UilTachometerFast,
  UilUser,
  UilTruck,
  UilPackage,
  UilTruckLoading,
  UilUsersAlt,
  UilFileImport,
  UilFileRedoAlt,
  UilFileBlank,
  UilNotes,
  UilFileExport,
  UilListOl,
  UilArchiveAlt,
  UilParcel,
  UilUserPlus,
  UilInvoice,
  UilUserArrows,
  UilSitemap,
  UilBag,
  UilSetting,
  UilBell,
  UilStore,
  UilNotebooks,
  UilFileQuestion,
  UilFileBookmarkAlt,
  UilSignOutAlt,
  UilInfoCircle,
  UilTrash,
  UilLock
} from "@iconscout/react-unicons";
import SideBarHeader from "./sidebarComponenents/SideBarHeader";
import BusinessSwitch from "./sidebarComponenents/BusinessSwitch";
import {
  addResourcesBackUp,
  logout,
  resetAuth,
} from "../../auth/store/authSlice";
import LogoutModal from "./LogoutModal";
import { resetSales } from "../../voucher/store/voucherSlice";
import { resetPayment } from "../../payment/store/paymentEntitySlice";
import { resetInitInstall } from "../../auth/store/initInstallSlice";
import { resetCartVoucherPayment } from "../../payment/store/paymentSlice";
import { resetParty } from "../../party/store/getPartySlice";
import { resetDashboard } from "../../dashboard/store/LatestTransactionSlice";
import { resetSubscription } from "../../subscription/store/SubscriptionSlice";
import { resetSubscriptionItem } from "../../subscription/store/subscription_item_store/GetPackagesItemSlice";
import { resetSubscriptionVoucher } from "../../subscription/store/getAllSubscriptionVoucherSlice";
import { resetEnquiry } from "../../enquiry/store/EnquirySlice";
import { getSettingsConfig, resetSetting } from "../../settings/store/getSettingSlice";
import { resetCategory } from "../../settings/store/categorysSlice";
import { resetProduct } from "../../catalog/store/productsSlice";
import { resetEditSetting } from "../../settings/store/settingSlice";
import { fetchPermission } from "../../auth/store/initInstallSlice";
import { resetAccount } from "../../manageAccount/store/manageAccountSlice";
import AboutModal from "./AboutModal";
import packageJson from "../../../../package.json";

const Navbar = ({ toggleMenuCollapse, isNavBtnClicked, menuCancel }) => {
  const dispatch = useDispatch();
  const getUser = useSelector((state) => state.appConfig?.getAppConfig);
  const getSetting = useSelector((state) => state.getSetting);
  const isMenuCollapse = useSelector((state) => state.sharedSlice.isToggleShow);
  ;
  const { accessToken, isLoggedIn } = useSelector((state) => state?.auth);
  const terminalNumber = useSelector((state) => state?.auth?.terminalNumber);
  const selectedBusiness = useSelector(
    (state) => state?.auth?.selectedBusiness
  );
  const tenantId = selectedBusiness?.pivot?.tenant_id;
  const { isKeyboard, isAbout, isVersion } = useSelector(
    (state) => state.sharedSlice
  );
  // const [isAbout, setIsAbout] = useState(false);

  const activeMenu = useSelector((state) => state?.sharedSlice?.activeSubmenu);
  const { permissions } = useSelector((state) => state?.initInstall);
  const trainer = useSelector((state) => state?.initInstall?.role?.name);
  console.log('permissions', permissions);
  console.log('selectedBusiness', selectedBusiness?.pivot)
  const admin = selectedBusiness?.pivot?.is_owner;
  const { backUpStatus } = useSelector((state) => state.auth);
  const [show, setShow] = useState(false);
  const [showPurchases, setShowPurchase] = useState(false);
  const [addShow, setAddShow] = useState(false);
  const [logOut, setLogOut] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [showBusiness, setShowBusinee] = useState(false);
  const { deviceId, saveSettingsStatus } = useSelector((state) => state?.auth);
  const [selectedUser, setSelectedUser] = useState();
  const location = useLocation();
  ;
  const handleSwitch = () => {
    //setAddShow(true);
    setShowBusinee(true);
  };

  const handleLogoutModal = () => {
    setLogOut(!logOut);
  };

  const handleLogout = () => {
    dispatch(
      addResourcesBackUp({
        token: accessToken,
        tenant_id: tenantId,
        user_id: terminalNumber,
      })
    );
  };
  useEffect(() => {
    dispatch(
      fetchPermission({
        token: accessToken,
        tenant_id: selectedBusiness?.pivot?.tenant_id,
        device_id: deviceId,
        user_id: selectedBusiness?.pivot?.user_id,
      })
    );
  }, [accessToken, selectedBusiness, dispatch]);
  useEffect(() => {
    if (backUpStatus) {
      dispatch(logout());
      dispatch(resetCategory());
      dispatch(resetProduct());
      dispatch(resetSales());
      dispatch(resetPayment());
      dispatch(resetAuth());
      dispatch(resetInitInstall());
      dispatch(resetCartVoucherPayment());
      dispatch(resetParty());
      dispatch(resetSetting());
      dispatch(resetEditSetting());
      dispatch(resetSubscriptionVoucher());
      dispatch(resetDashboard());
      dispatch(resetSubscription());
      dispatch(resetSubscriptionItem());
      dispatch(resetEnquiry());
      dispatch(resetAccount());
    }
  }, [backUpStatus]);

  const MenuStyle = {
    backgroundColor: "white",
    color: "black",
    "&:hover": {
      backgroundColor: "white",
      color: "black",
      //borderLeft: "8px solid #1a237e",
    },
    //borderLeft: "8px solid transparent",
  };

  ;

  const Switch = <Tooltip id="button-tooltip">{i18n("Switch")}</Tooltip>;

  const getIconForSection = (sectionText) => {
    const iconMap = {
      Party: faCocktail,
      Product: faBox,
      Sales: faDollarSign,
      Purchase: faShoppingCart,
    };

    return iconMap[sectionText] || null;
  };

  return (
    <div>
      <Sidebar
        width="230px"
        collapsed={isMenuCollapse}
        rootStyles={{
          [`.${sidebarClasses.container}`]: {
            backgroundColor: "white",
            height: "100%",
            // paddingLeft: "5px",
            // paddingRight: "5px",
            // borderRight:'1px solid lightgray',
            boxShadow: "3px 0.125rem 0.25rem rgba(6, 9, 23, 0.075)",
          },
        }}
      >
        <Col style={{ display: "flex", flexDirection: "column" }}>
          <SideBarHeader
            setIsHovered={setIsHovered}
            isHovered={isHovered}
            handleSwitch={handleSwitch}
          />

          <QuickAddDropdown isMenuCollapse={isMenuCollapse} />
          <div
            className="col-lg-12 mt-2"
            style={{
              height: isMenuCollapse
                ? "calc(100vh - 120px)"
                : "calc(100vh - 260px)",
              overflowY: "auto",
            }}
          >
            <Menu
              closeOnClick={false}
              menuItemStyles={{
                button: ({ level, disabled }) => {
                  // ;
                  if (level === 0)
                    return {
                      //color: disabled ? "white" : "white",
                      backgroundColor: "white",
                      transition: "0.5s ease",
                      // backgroundColor: "rgb(40,40,40)",
                      // color: "white",

                      ":hover": {
                        // backgroundColor: "#e9ecef",
                        //color: "white",
                        //borderLeft: "8px solid #1a237e",
                        borderRadius: "10px",
                      },
                      fontSize: "13px",
                      whiteSpace: "nowrap",
                      height: "35px",
                      opacity: 1,
                      fontWeight: 400,
                      //borderLeft: "8px solid transparent",
                    };
                },
              }}
            >
              <MenuItem
                className={
                  location.pathname === "/dashboard/sales"
                    ? "active-highlight-sidebar"
                    : ""
                }
                icon={
                  <UilTachometerFast
                    size="18"
                    onClick={() => {
                      dispatch(changeToggleShow(false));
                    }}
                  />
                }
                component={<Link to="/dashboard/sales" />}
                onClick={() => {
                  dispatch(changeSubmenu("dashboard"));
                }}
              >
                {" "}
                {i18n("Dashboard")}{" "}
              </MenuItem>
              {/* <MenuItem icon={<FontAwesomeIcon icon={faCashRegister} />} component={<Link to='/pos' />}> POS </MenuItem> */}
              {permissions && permissions.includes("parties") && (
                <SubMenu
                  label={i18n("Parties")}
                  icon={<UilUsersAlt size="18" />}
                  defaultOpen={activeMenu == "Parties"}
                // active={activeMenu == "Parties" ? "active-highlight" : ""}
                // onClick={() => {
                //   dispatch(changeSubmenu("Parties"));
                // }}
                >
                  {/* <MenuItem
                  rootStyles={MenuStyle}
                  icon={<FontAwesomeIcon icon={faUsers} />}
                  component={<Link to="/parties/allparties" />}
                >
                  {" "}
                  {i18n("All Parties")}{" "}
                </MenuItem> */}
                  <MenuItem
                    rootStyles={MenuStyle}
                    className={
                      location.pathname === "/parties/customer"
                        ? "active-highlight-sidebar"
                        : ""
                    }
                    style={{ height: "35px" }}
                    icon={<UilUser size="18" />}
                    component={<Link to="/parties/customer" />}
                    onClick={() => {
                      dispatch(changeSubmenu("Parties"));
                    }}
                  >
                    {" "}
                    {i18n("Customers")}{" "}
                  </MenuItem>
                  <MenuItem
                    rootStyles={MenuStyle}
                    className={
                      location.pathname === "/parties/supplier"
                        ? "active-highlight-sidebar"
                        : ""
                    }
                    style={{ height: "35px" }}
                    icon={<UilTruck size="18" />}
                    component={<Link to="/parties/supplier" />}
                    onClick={() => {
                      changeSubmenu("Parties");
                    }}
                  >
                    {" "}
                    {i18n("Suppliers")}{" "}
                  </MenuItem>
                </SubMenu>
              )}
              {permissions && permissions.includes("catalog") && (
                <SubMenu
                  label={i18n("Catalog")}
                  icon={<UilTruckLoading size="18" />}
                  defaultOpen={activeMenu == "Catalog"}
                // onClick={() => {
                //   dispatch(changeSubmenu("Catalog"));
                // }}
                >
                  <MenuItem
                    rootStyles={MenuStyle}
                    className={
                      location.pathname === "/catalog/products"
                        ? "active-highlight-sidebar"
                        : ""
                    }
                    style={{ height: "35px" }}
                    icon={<UilPackage size="18" />}
                    component={<Link to="/catalog/products" />}
                    onClick={() => {
                      dispatch(changeSubmenu("Catalog"));
                    }}
                  >
                    {i18n("Products")}{" "}
                  </MenuItem>
                </SubMenu>
              )}
              {permissions && permissions.includes("voucher") && (
                <SubMenu
                  label={i18n("Sales")}
                  icon={<UilFileAlt size="18" />}
                  defaultOpen={activeMenu == "Sales"}
                // onClick={() => {
                //   dispatch(changeSubmenu("Sales"));
                // }}
                >
                  {/* <MenuItem
                  rootStyles={MenuStyle}
                  icon={<FontAwesomeIcon icon={faList} />}
                  component={<Link to="/voucher/all_sales" />}
                >
                {i18n("All Sales")}
                </MenuItem> */}
                  <MenuItem
                    style={{ height: "35px" }}
                    rootStyles={MenuStyle}
                    className={
                      location.pathname === "/voucher/sales-invoice"
                        ? "active-highlight-sidebar"
                        : ""
                    }
                    icon={<UilFileAlt size="18" />}
                    component={<Link to="/voucher/sales-invoice" />}
                    onClick={() => {
                      dispatch(changeSubmenu("Sales"));
                    }}
                  >
                    {i18n("Sales Invoices")}
                  </MenuItem>
                  <MenuItem
                    rootStyles={MenuStyle}
                    style={{ height: "35px" }}
                    className={
                      location.pathname === "/voucher/payment-in"
                        ? "active-highlight-sidebar"
                        : ""
                    }
                    icon={<UilFileImport size="18" />}
                    component={<Link to="/voucher/payment-in" />}
                    onClick={() => {
                      dispatch(changeSubmenu("Sales"));
                    }}
                  >
                    {i18n("Payment In")}{" "}
                  </MenuItem>
                  <MenuItem
                    style={{ height: "35px" }}
                    rootStyles={MenuStyle}
                    className={
                      location.pathname === "/voucher/sales-return"
                        ? "active-highlight-sidebar"
                        : ""
                    }
                    icon={<UilFileRedoAlt size="18" />}
                    component={<Link to="/voucher/sales-return" />}
                    onClick={() => {
                      dispatch(changeSubmenu("Sales"));
                    }}
                  >
                    {i18n("Sales Return")}
                  </MenuItem>
                  <MenuItem
                    style={{ height: "35px" }}
                    rootStyles={MenuStyle}
                    className={
                      location.pathname === "/voucher/quotation"
                        ? "active-highlight-sidebar"
                        : ""
                    }
                    icon={<UilFileBlank size="18" />}
                    component={<Link to="/voucher/quotation" />}
                    onClick={() => {
                      dispatch(changeSubmenu("Sales"));
                    }}
                  >
                    {i18n("Quotation / Estimate")}{" "}
                  </MenuItem>
                  <MenuItem
                    style={{ height: "35px" }}
                    rootStyles={MenuStyle}
                    className={
                      location.pathname === "/voucher/credit-note"
                        ? "active-highlight-sidebar"
                        : ""
                    }
                    icon={<UilNotes size="18" />}
                    component={<Link to="/voucher/credit-note" />}
                    onClick={() => {
                      dispatch(changeSubmenu("Sales"));
                    }}
                  >
                    {i18n("Credit Note")}
                  </MenuItem>

                  <MenuItem
                    style={{ height: "35px" }}
                    rootStyles={MenuStyle}
                    className={
                      location.pathname === "/voucher/proforma-invoice"
                        ? "active-highlight-sidebar"
                        : ""
                    }
                    icon={<UilInvoice size="18" />}
                    component={<Link to="/voucher/proforma-invoice" />}
                    onClick={() => {
                      dispatch(changeSubmenu("Sales"));
                    }}
                  >
                    {i18n("Proforma Invoice")}
                  </MenuItem>
                </SubMenu>
              )}
              {permissions && permissions.includes("voucher") && (
                <SubMenu
                  label={i18n("Purchases")}
                  icon={<UilFileBookmarkAlt size="18" />}
                  defaultOpen={activeMenu == "Purchases"}
                // onClick={() => {
                //   dispatch(changeSubmenu("Purchases"));
                // }}
                >
                  {/* <MenuItem
                  rootStyles={MenuStyle}
                  icon={<FontAwesomeIcon icon={faList} />}
                  component={<Link to="/voucher/all_purchase" />}
                >
                  {" "}
                  {i18n("All Purchases")}
                </MenuItem> */}
                  <MenuItem
                    rootStyles={MenuStyle}
                    style={{ height: "35px" }}
                    className={
                      location.pathname === "/voucher/purchase-order"
                        ? "active-highlight-sidebar"
                        : ""
                    }
                    icon={<UilFileBlank size="18" />}
                    component={<Link to="/voucher/purchase-order" />}
                    onClick={() => {
                      dispatch(changeSubmenu("Purchases"));
                    }}
                  >
                    {" "}
                    {i18n("Purchase Orders")}{" "}
                  </MenuItem>
                  <MenuItem
                    rootStyles={MenuStyle}
                    style={{ height: "35px" }}
                    className={
                      location.pathname === "/voucher/purchase-invoice"
                        ? "active-highlight-sidebar"
                        : ""
                    }
                    icon={<UilFileAlt size="18" />}
                    component={<Link to="/voucher/purchase-invoice" />}
                    onClick={() => {
                      dispatch(changeSubmenu("Purchases"));
                    }}
                  >
                    {" "}
                    {i18n("Purchase Invoices")}
                  </MenuItem>
                  <MenuItem
                    rootStyles={MenuStyle}
                    style={{ height: "35px" }}
                    className={
                      location.pathname === "/voucher/payment-out"
                        ? "active-highlight-sidebar"
                        : ""
                    }
                    icon={<UilFileExport size="18" />}
                    component={<Link to="/voucher/payment-out" />}
                    onClick={() => {
                      dispatch(changeSubmenu("Purchases"));
                    }}
                  >
                    {" "}
                    {i18n("Payment Out")}{" "}
                  </MenuItem>
                  <MenuItem
                    rootStyles={MenuStyle}
                    style={{ height: "35px" }}
                    className={
                      location.pathname === "/voucher/purchase-return"
                        ? "active-highlight-sidebar"
                        : ""
                    }
                    icon={<UilFileRedoAlt size="18" />}
                    component={<Link to="/voucher/purchase-return" />}
                    onClick={() => {
                      dispatch(changeSubmenu("Purchases"));
                    }}
                  >
                    {" "}
                    {i18n("Purchase Return")}{" "}
                  </MenuItem>
                  <MenuItem
                    rootStyles={MenuStyle}
                    style={{ height: "35px" }}
                    className={
                      location.pathname === "/voucher/debit-note"
                        ? "active-highlight-sidebar"
                        : ""
                    }
                    icon={<UilNotes size="18" />}
                    component={<Link to="/voucher/debit-note" />}
                    onClick={() => {
                      dispatch(changeSubmenu("Purchases"));
                    }}
                  >
                    {" "}
                    {i18n("Debit Note")}{" "}
                  </MenuItem>
                </SubMenu>
              )}
              {permissions && permissions.includes("reports") && (
                <SubMenu
                  label={i18n("Reports")}
                  icon={<UilNotebooks size="18" />}
                  defaultOpen={activeMenu == "Reports"}
                // onClick={() => {
                //   dispatch(changeSubmenu("Reports"));
                // }}
                >
                  {/* <MenuItem
                icon={<FontAwesomeIcon icon={faBook} />}
                component={<Link to="/reports" />}
              >
                {" "}
                Reports{" "}
              </MenuItem> */}
                  <MenuItem
                    rootStyles={MenuStyle}
                    style={{ height: "35px" }}
                    className={
                      location.pathname === "/reports/gst/sales" ||
                        location.pathname === "/reports/gst/purchase" ||
                        location.pathname === "/reports/gst/purchase-with-hsn" ||
                        location.pathname === "/reports/gst/sales-with-hsn" ||
                        location.pathname === "/reports/gst/hsn-wise-sales-summary"
                        ? "active-highlight-sidebar"
                        : ""
                    }
                    icon={<UilListOl size="18" />}
                    component={<Link to="/reports/gst/sales" />}
                    onClick={() => {
                      dispatch(changeSubmenu("Reports"));
                    }}
                  >
                    {" "}
                    {i18n("GST")}
                  </MenuItem>
                  <MenuItem
                    rootStyles={MenuStyle}
                    style={{ height: "35px" }}
                    className={
                      location.pathname === "/reports/transaction/bill-wise-profit" ||
                        location.pathname === "/reports/transaction/day-book" ||
                        location.pathname === "/reports/transaction/profit-and-loss" ||
                        location.pathname === "/reports/transaction/sales-summary" ||
                        location.pathname === "/reports/transaction/purchase-summary"
                        ? "active-highlight-sidebar"
                        : ""
                    }
                    icon={<UilFileAlt size="18" />}
                    component={<Link to="/reports/transaction/bill-wise-profit" />}
                    onClick={() => {
                      dispatch(changeSubmenu("Reports"));
                    }}
                  >
                    {" "}
                    {i18n("Transaction")}
                  </MenuItem>
                  <MenuItem
                    rootStyles={MenuStyle}
                    style={{ height: "35px" }}
                    className={
                      location.pathname === "/reports/item/item-report-by-party" ||
                        location.pathname === "/reports/item/sales-and-purchase-summary" ||
                        location.pathname === "/reports/item/rate-list" ||
                        location.pathname === "/reports/item/stock-detail" ||
                        location.pathname === "/reports/item/stock-summary"
                        ? "active-highlight-sidebar"
                        : ""
                    }
                    icon={<UilArchiveAlt size="18" />}
                    component={<Link to="/reports/item/item-report-by-party" />}
                    onClick={() => {
                      dispatch(changeSubmenu("Reports"));
                    }}
                  >
                    {" "}
                    {i18n("Item")}
                  </MenuItem>
                  <MenuItem
                    rootStyles={MenuStyle}
                    style={{ height: "35px" }}
                    className={
                      location.pathname === "/reports/party/receivable-agening" ||
                        location.pathname === "/reports/party/party-report-by-item" ||
                        location.pathname === "/reports/party/party-ledger" ||
                        location.pathname === "/reports/party/party-wise-outstanding" ||
                        location.pathname === "/reports/party/sales-sumary-category-wise"
                        ? "active-highlight-sidebar"
                        : ""
                    }
                    icon={<UilUsersAlt size="18" />}
                    component={<Link to="/reports/party/receivable-agening" />}
                    onClick={() => {
                      dispatch(changeSubmenu("Reports"));
                    }}
                  >
                    {" "}
                    {i18n("Party")}
                  </MenuItem>
                </SubMenu>
              )}
              {permissions && permissions.includes("subscription") && (
                <SubMenu
                  label={i18n("Subscriptions")}
                  icon={<UilBell size="18" />}
                  defaultOpen={activeMenu == "Subscriptions"}
                // onClick={() => {
                //   dispatch(changeSubmenu("Subscriptions"));
                // }}
                >
                  <MenuItem
                    rootStyles={MenuStyle}
                    style={{ height: "35px" }}
                    className={
                      location.pathname === "/subscription/packages"
                        ? "active-highlight-sidebar"
                        : ""
                    }
                    component={<Link to="/subscription/packages" />}
                    icon={<UilParcel size="18" />}
                    onClick={() => {
                      dispatch(changeSubmenu("Subscriptions"));
                    }}
                  >
                    {i18n("Packages")}
                  </MenuItem>
                  <MenuItem
                    rootStyles={MenuStyle}
                    style={{ height: "35px" }}
                    className={
                      location.pathname === "/subscription/membership"
                        ? "active-highlight-sidebar"
                        : ""
                    }
                    component={<Link to="/subscription/membership" />}
                    icon={<UilUserPlus size="18" />}
                    onClick={() => {
                      dispatch(changeSubmenu("Subscriptions"));
                    }}
                  >
                    {i18n("Memberships")}
                  </MenuItem>
                  <MenuItem
                    rootStyles={MenuStyle}
                    style={{ height: "35px" }}
                    className={
                      location.pathname === "/subscription/invoices"
                        ? "active-highlight-sidebar"
                        : ""
                    }
                    component={<Link to="/subscription/invoices" />}
                    icon={<UilInvoice size="18" />}
                    onClick={() => {
                      dispatch(changeSubmenu("Subscriptions"));
                    }}
                  >
                    {i18n("Invoices")}
                  </MenuItem>
                  <MenuItem
                    rootStyles={MenuStyle}
                    style={{ height: "35px" }}
                    className={
                      location.pathname === "/subscription/enquiries"
                        ? "active-highlight-sidebar"
                        : ""
                    }
                    component={<Link to="/subscription/enquiries" />}
                    icon={<UilFileQuestion size="18" />}
                    onClick={() => {
                      dispatch(changeSubmenu("Subscriptions"));
                    }}
                  >
                    {i18n("Enquiries")}
                  </MenuItem>
                  {admin === 1 && (
                    <MenuItem
                      rootStyles={MenuStyle}
                      style={{ height: "35px" }}
                      className={
                        location.pathname === "/subscription/members"
                          ? "active-highlight-sidebar"
                          : ""
                      }
                      component={<Link to="/subscription/members" />}
                      icon={<UilUsersAlt size="18" />}
                      onClick={() => {
                        dispatch(changeSubmenu("Subscriptions"));
                      }}
                    >
                      {i18n("Members")}
                    </MenuItem>
                  )}
                  {trainer === "trainer" && (
                    <MenuItem
                      rootStyles={MenuStyle}
                      style={{ height: "35px" }}
                      className={
                        location.pathname === "/subscription/members"
                          ? "active-highlight-sidebar"
                          : ""
                      }
                      component={<Link to="/subscription/members" />}
                      icon={<UilUsersAlt size="18" />}
                      onClick={() => {
                        dispatch(changeSubmenu("Subscriptions"));
                      }}
                    >
                      {i18n("Assigned Members")}
                    </MenuItem>
                  )}
                </SubMenu>
              )}
              {permissions && permissions.includes("setting") && (
                <SubMenu
                  label={i18n("Settings")}
                  icon={<UilSetting size="18" />}
                  defaultOpen={activeMenu == "Settings"}
                // onClick={() => {
                //   dispatch(changeSubmenu("Settings"));
                // }}
                >
                  <MenuItem
                    rootStyles={MenuStyle}
                    style={{ height: "35px" }}
                    className={
                      location.pathname === "/setting/receivableAgeing" ||
                        location.pathname === "/setting/business" ||
                        location.pathname === "/setting//invoice-themes" ||
                        location.pathname === "/setting/membership-card" ||
                        location.pathname === "/setting/language-and-currency" ||
                        location.pathname === "/setting/invoice" ||
                        location.pathname === "/setting/units" ||
                        location.pathname === "/settings/tax"
                        ? "active-highlight-sidebar"
                        : ""
                    }
                    icon={<UilBag size="18" />}
                    component={<Link to="/setting/business" />}
                    onClick={() => {
                      dispatch(changeSubmenu("Settings"));
                    }}
                  >
                    {" "}
                    {i18n("Manage Settings")}
                  </MenuItem>

                  <MenuItem
                    rootStyles={MenuStyle}
                    style={{ height: "35px" }}
                    className={
                      location.pathname === "/setting/categories"
                        ? "active-highlight-sidebar"
                        : ""
                    }
                    icon={<UilSitemap size="18" />}
                    component={<Link to="/setting/categories" />}
                    onClick={() => {
                      dispatch(changeSubmenu("Settings"));
                    }}
                  >
                    {" "}
                    {i18n("Manage Categories")}
                  </MenuItem>

                  {permissions &&
                    permissions.includes("setting") &&
                    admin === 1 && (
                      <MenuItem
                        rootStyles={MenuStyle}
                        style={{ height: "35px" }}
                        className={
                          location.pathname === "/setting/users"
                            ? "active-highlight-sidebar"
                            : ""
                        }
                        icon={<UilUserArrows size="18" />}
                        component={<Link to="/setting/users" />}
                        onClick={() => {
                          dispatch(changeSubmenu("Settings"));
                        }}
                      >
                        {" "}
                        {i18n("Manage Users")}
                      </MenuItem>
                    )}
                  {permissions &&
                    permissions.includes("setting") &&
                    admin === 1 && (
                      <MenuItem
                        rootStyles={MenuStyle}
                        style={{ height: "35px" }}
                        className={
                          location.pathname === "/setting/deleted-items/parties" || 
                          location.pathname === "/setting/deleted-items/products" || 
                          location.pathname === "/setting/deleted-items/sales" ||
                          location.pathname === "/setting/deleted-items/purchase"
                            ? "active-highlight-sidebar"
                            : ""
                        }
                        icon={<UilTrash size="18" />}
                        component={<Link to="/setting/deleted-items/parties" />}
                        onClick={() => {
                          dispatch(changeSubmenu("Settings"));
                        }}
                      >
                        {" "}
                        {i18n("Deleted Items")}
                      </MenuItem>
                    )}

                  {permissions &&
                    permissions.includes("setting") &&
                    admin === 1 && (
                      <MenuItem
                        rootStyles={MenuStyle}
                        style={{ height: "35px" }}
                        className={
                          location.pathname === "/setting/import-export/products" ||
                           location.pathname === "/setting/import-export/parties" ||
                           location.pathname === "/setting/import-export/sales"
                            ? "active-highlight-sidebar"
                            : ""
                        }
                        icon={<UilFileImport size="18" />}
                        component={<Link to="/setting/import-export/products" />}
                        onClick={() => {
                          dispatch(changeSubmenu("Settings"));
                        }}
                      >
                        {" "}
                        {i18n("Import Export")}
                      </MenuItem>
                    )}
                </SubMenu>
              )}
            </Menu>
          </div>

          <Col className=" py-1 d-flex flex-column justify-content-center align-items-center">
            <div
              className="  ,b-4"
              style={{
                width: "100%",
                borderBottom: "1px solid lightgray",
              }}
            >
              <BusinessSwitch
                setIsHovered={setIsHovered}
                isHovered={isHovered}
                handleSwitch={handleSwitch}
              />
            </div>

            <div className=" mt-3">
              <img
                src={bill}
                //max={isMenuCollapse ? 60 : '125px'}
                style={{
                  maxWidth: isMenuCollapse ? "50px" : "125px",
                }}
              />
            </div>
            <div className="mb-3 ">
              <Form.Label className="mb-0 " style={{ fontSize: "11px" }}>
                {`V - ${packageJson.version}`}
              </Form.Label>
            </div>

            {/* )} */}
          </Col>
        </Col>
      </Sidebar>

      <SaleModal show={show} setShow={setShow} />
      <PurchaseModal show={showPurchases} setShow={setShowPurchase} />
      {addShow && <BusinessSwitchScrren show={addShow} setShow={setAddShow} />}
      {logOut && <LogoutModal onConfirm={handleLogout} onCancel={setLogOut} />}
      {isAbout && <AboutModal show={isAbout} />}
    </div>
  );
};

export default Navbar;
