import React, { useState } from "react";
import {
  UilFileAlt,
  UilFileImport,
  UilFileRedoAlt,
  UilFileBlank,
  UilNotes,
  UilFileExport,
  UilUserPlus,
  UilDropbox,
  UilPlus,
} from "@iconscout/react-unicons";
import {
  ButtonGroup,
  Dropdown,
  Form,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import CreateProduct from "../../catalog/screens/CreateProduct";
import CreateParty from "../../party/screens/CreateParty";
import { resetCreateProduct } from "../../catalog/store/productEntitySlice";
import { useDispatch } from "react-redux";
import { resetCreateParty } from "../../party/store/PartyEntitySlice";
import { i18n } from "../helpers/sharedMethods";
import { changeToggleShow } from "../store/sharedSlice";
import { resetOrder } from "../../voucher/store/voucherEntitySlice";
import { resetVoucherItem } from "../../voucher/store/voucherItemsSlice";
import { resetCharges } from "../../voucher/store/additionalChargesSlice";

const QuickAddDropdown = ({ isMenuCollapse }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showProductModal, setShowProductModal] = useState(false);
  const [showPartyModal, setShowPartyModal] = useState(false);

  const handleShowProductModal = () => {
    dispatch(resetCreateProduct());
    setShowProductModal(true);
  };
  const handleCloseProductModal = () => setShowProductModal(false);

  const handleShowPartyModal = () => {
    dispatch(resetCreateParty());
    setShowPartyModal(true);
  };
  const handleClosePartyModal = () => setShowPartyModal(false);

  const QuickAdd = <Tooltip id="button-tooltip">{i18n("Quick Add")}</Tooltip>;

  const handleLinkClick = (link) => {
    ;
    dispatch(resetOrder());
    dispatch(resetVoucherItem());
    dispatch(resetCharges());
    dispatch(changeToggleShow(true));
    navigate(`${link}/create`);
  };

  const DropDownMenu = [
    {
      text: i18n("General"),
      items: [
        {
          label: i18n("Create Party"),
          onClick: handleShowPartyModal,
          icon: UilUserPlus,
        },
        {
          label: i18n("Create Product"),
          onClick: handleShowProductModal,
          icon: UilDropbox,
        },
      ],
    },
    {
      text: i18n("Sales"),
      items: [
        {
          label: i18n("Create Sales"),
          link: "/voucher/sales-invoice",
          icon: UilFileAlt,
        },
        {
          label: i18n("Create Payment In"),
          link: "/voucher/payment-in",
          icon: UilFileImport,
        },
        {
          label: i18n("Create Sales Return"),
          link: "/voucher/sales-return",
          icon: UilFileRedoAlt,
        },
        {
          label: i18n("Create Quotation"),
          link: "/voucher/quotation",
          icon: UilFileBlank,
        },
        {
          label: i18n("Create Credit Note"),
          link: "/voucher/credit-note",
          icon: UilNotes,
        },
      ],
    },
    {
      text: i18n("Purchase"),
      items: [
        {
          label: i18n("Create Purchase Order"),
          link: "/voucher/purchase-order",
          icon: UilFileBlank,
        },
        {
          label: i18n("Create Purchase"),
          link: "/voucher/purchase-invoice",
          icon: UilFileAlt,
        },
        {
          label: i18n("Create Payment Out"),
          link: "/voucher/payment-out",
          icon: UilFileExport,
        },
        {
          label: i18n("Create Purchase Return"),
          link: "/voucher/purchase-return",
          icon: UilFileAlt,
        },
        {
          label: i18n("Create Debit Note"),
          link: "/voucher/debit-note",
          icon: UilFileRedoAlt,
        },
      ],
    },
  ];

  return (
    <>
      {!isMenuCollapse && (
        <Dropdown
          as={ButtonGroup}
          className="outline-primary px-3 mt-2"
          size="sm"
        >
          <OverlayTrigger placement="right" overlay={QuickAdd}>
            <Dropdown.Toggle
              variant="outline-primary"
              id="dropdown-basic"
              className="custom-dropdown-toggle  "
            >
              <UilPlus className="mx-1 fw-bolder " size="18" />{" "}
              {i18n("Quick Add")}
            </Dropdown.Toggle>
          </OverlayTrigger>
          <Dropdown.Menu
            className="mt-2  custom-dropdown"
            align={"end"}
            style={{}}
          >
            {DropDownMenu.map((section, index) => (
              <div className="px-6 " key={index}>
                <Dropdown.ItemText
                  bsPrefix="custom-dropdown-item"
                  className=""
                  style={{
                    fontSize: "14px",
                    fontWeight: 500,
                  }}
                >
                  {section.text}
                </Dropdown.ItemText>
                <Dropdown.Divider />
                {section.items.map((item, itemIndex) => (
                  <Dropdown.Item
                    className="hover-color py-2"
                    key={itemIndex}
                    onClick={() => item.link && handleLinkClick(item.link)}
                  >
                    {item.onClick ? (
                      <span
                        className="cursor-pointer mb-0"
                        onClick={item.onClick}
                        style={{
                          color: "#667085",
                          fontSize: "12.8px",
                          border: "none",
                          background: "none",
                          textDecoration: "none",
                        }}
                        onMouseEnter={(e) => {
                          e.target.style.textDecoration = "underline";
                        }}
                        onMouseLeave={(e) => {
                          e.target.style.textDecoration = "none";
                        }}
                      >
                        <item.icon
                          className="mx-3 icon-hover "
                          size="18"
                          style={{ color: "#667085" }}
                        />
                        {item.label}
                      </span>
                    ) : (
                      <span
                        className="cursor-pointer"
                        style={{ color: "#667085", fontSize: "12.8px" }}
                      >
                        <item.icon
                          className="mx-3 icon-hover "
                          size="18"
                          style={{ color: "#667085" }}
                        />
                        {item.label}
                      </span>
                    )}
                  </Dropdown.Item>
                ))}
                {index !== DropDownMenu.length - 1 && <Dropdown.Divider />}
              </div>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      )}
      {showProductModal && (
        <CreateProduct
          show={showProductModal}
          handleClose={handleCloseProductModal}
          setShowCreate={setShowProductModal}
        />
      )}
      {showPartyModal && (
        <CreateParty
          handleClose={handleClosePartyModal}
          show={showPartyModal}
          setShowPartyCreate={setShowPartyModal}
        />
      )}
    </>
  );
};

export default QuickAddDropdown;
