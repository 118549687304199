import React, { useEffect, useState } from "react";
import { useDispatch,useSelector } from "react-redux";
import { useNavigate } from "react-router"
import TabLayout from "../../shared/components/item-detail/TabLayout";
import { i18n } from "../../shared/helpers/sharedMethods";
import DashboardIndex from "../screens/DashboardIndex";
import {
  fetchSetting,
  getSettingsConfig,
} from "../../settings/store/getSettingSlice";
import SalesTab from "./SalesTab";


const DashBoardHeaderTab = ({reportPeriod}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const selectedBusiness = useSelector(
    (state) => state?.auth?.selectedBusiness
  );
  const accessToken = useSelector(
    (state) => state?.auth?.accessToken?.access_token
  );
  const admin = selectedBusiness?.pivot?.is_owner;
  const trainer = useSelector((state) => state?.initInstall?.role?.name);
  useEffect(() => {
    dispatch(
      fetchSetting({
        accessToken: accessToken,
        tenant_id: selectedBusiness?.pivot?.tenant_id,
      })
    );
  }, [accessToken, selectedBusiness, dispatch]);

  const HomeTabs = [
    ... (admin ? [{
      key: "sales",
      title: i18n("Sales"),
      component:  <DashboardIndex />
    }] : []),
    ... (admin  ? [{
      key: "subscription",
      title: i18n("Subscription"),
      component:  <DashboardIndex />
    }] : []),
  ];

  return <TabLayout type="dashboard" tabs={HomeTabs} route="dashboard" title="dashboard" />;
};

export default DashBoardHeaderTab;
