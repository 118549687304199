import React, { useState, useEffect } from "react";
import { Card, Col, Form, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Chart from "./Chart";
import { fetchPartySummaryChart } from "../../../store/PartySummaryChartSlice";
import ReportPeriod from "../../../../shared/components/ReportPeroid";
import DateRangeIndex from "../../../../shared/components/date_range/DateRangeIndex";

function SummaryChartIndex() {
  const dispatch = useDispatch();
  const [reportPeriod, setReportPeriod] = useState("current-week");

  useEffect(() => {
    dispatch(fetchPartySummaryChart(reportPeriod));
  }, [reportPeriod]);
  const [labelName, setLabelName] = useState([]);
  const [salesAmount, setSalesAmount] = useState([]);

  const reportItems = useSelector(
    (state) => state.partySummaryChart.reportItems
  );
  ;
  useEffect(() => {
    if (Array.isArray(reportItems)) {
      const partyNames =
        reportItems &&
        reportItems.map(
          (item) =>
            item.PartyName.charAt(0).toUpperCase() + item.PartyName.slice(1)
        );
      setLabelName(partyNames);
      const saleAmounts =
        reportItems && reportItems.map((item) => item.saleAmount);
      setSalesAmount(saleAmounts);
    }
  }, [reportPeriod, reportItems]);

  return (
    <Card id="chart" className="border mb-3">
      <Card.Body className="p-2">
        <Row>
          <Col xl={9} md={9} sm={9}>
            <Chart
              labelName={labelName}
              salesAmount={salesAmount}
              reportPeriod={reportPeriod}
            />
          </Col>
          <Col xl={3} md={3} sm={3} className="d-flex justify-content-end">
            <div style={{ width: "75%" }}>
              <ReportPeriod
                reportPeriod={reportPeriod}
                setReportPeriod={setReportPeriod}
              />
            </div>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
}

export default SummaryChartIndex;
