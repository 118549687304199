import {
  Row,
} from "react-bootstrap";

import { ToastContainer } from "react-toastify";
import { useNavigate } from "react-router";
import LoadingSpinner from "../../shared/components/LoadingSpinner";
import ImportLayout from "./ImportLayout";
import ProductsImport from "../screens/ProductsImport";
import { useSelector } from "react-redux";
import TabLayout from "../../shared/components/item-detail/TabLayout";
import ProductExport from "../screens/ProductExport";
import { i18n } from "../../shared/helpers/sharedMethods";
import ItemTabs from "../../shared/components/item-detail/sections/ItemTabs";
const ProductImportExport = () => {
   const loading = useSelector((state) => state.getSetting.loading);


  const navigate = useNavigate();

  const handleCancel = () => {
    navigate("/dashboard/sales");
  };


  return (
    <>
      <ToastContainer />
      <ImportLayout
        title="Product"
      >
        {loading && <LoadingSpinner />}
        <Row>
          <ProductsImport />
          {/* <ItemTabs
            type="Setting"
            tabs={productstabs}
            route="0"
            title="SettingsImport"
          /> */}
        </Row>
      </ImportLayout>
    </>
  );
};

export default ProductImportExport;
