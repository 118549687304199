import React, { useState } from 'react';
import { Container, Row, Stack, Button,Col } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from "react-router";
import Layout from '../../shared/components/Layout';
import { getAllDeletedPartys } from '../../party/store/partyDeletedSlice';
import TabLayout from '../../shared/components/item-detail/TabLayout';
// import PartyIndex from '../screens/PartyIndex';
import { i18n } from '../../shared/helpers/sharedMethods';
import DeletedPartyIndex from '../screens/DeletedPartyIndex';
import DeletedProductIndex from '../screens/DeletedProductIndex';
import DeletedVoucherIndex from '../screens/DeletedVoucherIndex';
import DeletedPurchaseIndex from '../screens/DeletedPurchseIndex';

const DeletedRecordHeader = () => {
    const navigate =useNavigate()
    const dispatch = useDispatch();
    
    React.useEffect(() => {
        ;
        dispatch(getAllDeletedPartys());
      }, [])
     
      const deletedTabs = [

        {
          key: 'deleted-items/parties',
          title: (i18n('Parties')),
         component:  <DeletedPartyIndex />
        },
        {
          key: 'deleted-items/products',
          title: (i18n('Catalog')),
         component:  <DeletedProductIndex />
        },
        {
          key: 'deleted-items/sales',
          title: (i18n('Sales')),
         component:  <DeletedVoucherIndex />
        },
        {
          key: 'deleted-items/purchase',
          title: (i18n('Purchases')),
         component:  <DeletedPurchaseIndex />
        },
    
      ]

    return (
   
       <TabLayout type="setting" tabs={deletedTabs} route="setting" title="Deleted" />
    
    )
}

export default DeletedRecordHeader;