import React, { useEffect, useState } from "react";
import ReactDatePicker from "react-datepicker";
import { placeholderText } from "../../shared/helpers/sharedMethods";

const DatePicker = ({ data, allData, addFilteredData, setFilteredData}) => {
    
  const [startDate, setStartDate] = useState(null); // State for start date
  const [endDate, setEndDate] = useState(null);
  
  useEffect(() => {
    if (data) {
      setFilteredData(data);
    }
  }, [data]);

  const handleDateRangeChange = (dates) => {
    
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);

    if (!start && !end) {
      setFilteredData(allData);
      addFilteredData(allData);
      return;
    }

    const filtered = allData.filter((datum) => {
      const voucherDate = new Date(datum?.voucher_date|| datum?.start_date || datum?.created_at);

      const startDate = start && new Date(start.setHours(0, 0, 0, 0));
      const endDate = end && new Date(end.setHours(23, 59, 59, 999));

      if (startDate && endDate) {
        return voucherDate >= startDate && voucherDate <= endDate;
      } else if (startDate) {
        return voucherDate >= startDate;
      } else if (endDate) {
        return voucherDate <= endDate;
      }

      return true;
    });

    setFilteredData(filtered);
    addFilteredData(filtered);

  };

  return (
    <>
      <ReactDatePicker
        selected={startDate}
        onChange={(date) => handleDateRangeChange([date, endDate])}
        selectsStart
        startDate={startDate}
        endDate={endDate}
        placeholderText={placeholderText("Start Date")}
        className="rounded-0 rounded-start form-control"
        isClearable
      />
      <ReactDatePicker
        selected={endDate}
        onChange={(date) => handleDateRangeChange([startDate, date])}
        selectsEnd
        startDate={startDate}
        endDate={endDate}
        placeholderText={placeholderText("End Date")}
        className="rounded-0 rounded-end form-control"
        isClearable
      />
    </>
  );
};

export default DatePicker;
