import { Form } from "react-bootstrap";
import React from "react";
import { placeholderText } from "../../../../shared/helpers/sharedMethods";


const ReportPeriod = (props) => {
   
    const {reportPeriod,setReportPeriod} = props;
    return (
        <Form.Select
        className=""
        value={reportPeriod}
        onChange={(e) => setReportPeriod(e.target.value)}
        style={{ cursor: "pointer" }}
      >
 
                <option value="current-month">{placeholderText("This Month")}</option>
                <option value="previous-month">{placeholderText("Last Month")}</option>
                <option value="last-three-month">{placeholderText("Last Three Month")}</option>
                <option value="last-six-month">{placeholderText("Last Six Month")}</option>
                <option value="current-year">{placeholderText("This Year")}</option>
                <option value="previous-year">{placeholderText("Last Year")}</option>
      </Form.Select>
    )

}

export default ReportPeriod;