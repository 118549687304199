import { Button, Col, Form, Row, Table } from "react-bootstrap";
import { i18n } from "../../../shared/helpers/sharedMethods";
import { useNavigate } from "react-router";
import {
  Deleteplan,
  planData,
  selectProductAsSubscriptionPlans,
} from "../../store/subscription_item_store/GetPackagesItemSlice";
import { useDispatch, useSelector } from "react-redux";
import { UilEdit, UilTrashAlt } from "@iconscout/react-unicons";
import { resetPlan } from "../../store/subscription_item_store/PlanEntitySlice";
import { useEffect, useState } from "react";
import DeleteModel from "../../../shared/components/DeleteModel";
import { formatAmount } from "../../../pos/helpers/cartHelper";
import GlobalCurrency from '../../../shared/components/GlobalCurrency'
import { resetPackage } from "../../store/subscription_item_store/CreatePackageSlice";

const PlanTable = ({ id }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const plan = useSelector((state) =>
    selectProductAsSubscriptionPlans(state, id)
  );
  ;

  const { accessToken, isLoggedIn } = useSelector((state) => state?.auth);
  const selectedBusiness = useSelector(
    (state) => state?.auth?.selectedBusiness
  );

  const tenantId = selectedBusiness?.pivot?.tenant_id;

  const [deletePlan, setDeletePlan] = useState(false);
  const [deleteName, setDeleteName] = useState();
  const [filterItems, setFilterItems] = useState([plan]);

  useEffect(() => {
    setFilterItems(plan);
  }, [plan]);
  const handleCreate = () => {
    dispatch(resetPlan());
    dispatch(resetPackage());
    navigate("/subscription/packages/plan/create", { state: { id: id } });
  };

  const handlePlanEdit = (item) => {
    dispatch(resetPlan());
    navigate("/subscription/packages/plan/edit/" + item?.id, {
      state: { currentId: id },
    });
  };

  const handleDelete = (item) => {
    ;
    setDeletePlan(true);
    setDeleteName(item);
  };
  const onDelete = () => {
    dispatch(
      Deleteplan({
        id: deleteName?.id,
        token: accessToken.access_token,
        tenant_id: tenantId,
      })
    ).then((res) => {
      ;
      dispatch(
        planData({ token: accessToken.access_token, tenant_id: tenantId })
      );
    });

    setDeletePlan(false);
  };
  return (
    <Row className="mb-6 pt-4">
      <Col
        lg={12}
        className="d-flex justify-content-between align-items-center"
      >
        <div>
          <Form.Group className="px-1">
            <Form.Label className="fs-2">
              <span
                className="pb-2"
                style={{
                  borderBottom: "3px solid #1a237e",
                  fontWeight: "bold",
                  fontSize: "15px",
                }}
              >
                {i18n("Plans")}
              </span>
            </Form.Label>
          </Form.Group>
        </div>
        <div>
          <Button className="px-2" variant="link" onClick={handleCreate} style={{ fontSize: '13px' }}>
            {i18n("+Add Plan")}
          </Button>
        </div>
      </Col>
      <Col lg={12}>
        <div className="mt-5 px-1">
          <Table bordered hover responsive>
            <thead>
              <tr className="text-center" style={{ color: '#495057' }}>
                <th className="px-2" style={{ fontSize: '13px', backgroundColor: 'rgb(249, 250, 251)' }}>{i18n("Name")}</th>
                <th className="px-2" style={{ fontSize: '13px', backgroundColor: 'rgb(249, 250, 251)' }}>{i18n("Code")}</th>
                <th className="px-2" style={{ fontSize: '13px', backgroundColor: 'rgb(249, 250, 251)' }}>{i18n("Free Trial Days")}</th>
                <th className="px-2" style={{ fontSize: '13px', backgroundColor: 'rgb(249, 250, 251)' }}>{i18n("Price")}</th>
                <th className="px-2" style={{ fontSize: '13px', backgroundColor: 'rgb(249, 250, 251)' }}>{i18n("Actions")}</th>
              </tr>
            </thead>
            <tbody>
              {filterItems && filterItems.length > 0 ? (filterItems.map((item, index) => (
                <tr className="text-center" key={index} style={{ fontSize: '13px' }}>
                  <td className="px-2" style={{ fontSize: '13px' }}>{item?.product_name}</td>
                  <td className="px-2" style={{ fontSize: '13px' }}>{item?.product_code}</td>
                  <td className="px-2" style={{ fontSize: '13px' }}>
                    {item?.plan?.free_trial_days || "-"}
                  </td>
                  <td className="px-2" style={{ fontSize: '13px' }}><GlobalCurrency price={item?.item_price} /></td>
                  <td className="px-2" >
                    <UilEdit
                      size="16"
                      className=" mx-1"
                      aria-hidden="true"
                      style={{ cursor: "pointer" }}
                      onClick={() => handlePlanEdit(item)}
                    />
                    &nbsp;
                    <UilTrashAlt
                      size="16"
                      className=" mx-1 "
                      aria-hidden="true"
                      style={{ cursor: "pointer" }}
                      onClick={() => handleDelete(item)}
                    />
                  </td>
                </tr>
              ))) : <tr>
                <td className="text-center px-2" colSpan="6" style={{ fontSize: '13px' }}>
                
                  {i18n("No Plans yet!")}
                </td>
              </tr>}
            </tbody>
          </Table>

        </div>
      </Col>
      {deletePlan && (
        <DeleteModel
          name={deleteName?.product_name}
          deleteUserClick={onDelete}
          onClickDeleteModel={setDeletePlan}
        />
      )}
    </Row>
  );
};

export default PlanTable;
