import React, { useEffect, useMemo, useRef, useState } from "react";
import { Button, Card, Col, Form, InputGroup, Row, Alert } from "react-bootstrap";
import { ErrorMessage } from "formik";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { changeMember, changeMemberNumber } from "../store/MemberSlice";
import { i18n, numValidate, placeholderText } from "../../shared/helpers/sharedMethods";
import { Menu, MenuItem, Typeahead } from "react-bootstrap-typeahead";
import { faSquareXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import 'react-bootstrap-typeahead/css/Typeahead.css';
import "../../../assets/css/custom.css";

const MemberDetails = (props) => {
  const {
    handleChange,    
    errors,
    values,
    touched,
    handleBlur,
    filterData
  } = props;
  const dispatch = useDispatch(); 
  const {member_id} = useSelector((state) => state.members);
  const [selectedTrainer, setSelectedTrainer] = useState([]);
  const [showWarning, setShowWarning] = useState(false);
  const typeaheadRef = useRef(null);

  const handleIdChange = (e) => {
    const newValue = e.target.value;

    // Show the warning message when the input value changes
    if (newValue !== member_id && newValue !== values.member_id) {
      setShowWarning(true); // Show warning message
    } else {
      setShowWarning(false); // Hide warning if value is unchanged
    }

    dispatch(changeMemberNumber(newValue)); // Dispatch the action
  };

  const selectedBusiness = useSelector(
    (state) => state?.auth?.selectedBusiness
  );

  const admin = selectedBusiness?.pivot?.is_owner;
  const trainer = useSelector((state) => state?.initInstall?.role?.name);
  const trainerId = useSelector((state) => state?.initInstall?.role?.id);

  // Handle when trainer is automatically assigned based on role
  useEffect(() => {
    if (trainer === "trainer") {
      handleChange({ target: { name: 'trainer_id', value: trainerId } });
      dispatch(changeMember({ value: trainerId, name: 'trainer_id' }));
    }
  }, [trainer, trainerId, handleChange, dispatch]);

  const handleTrainerSelect = (selected) => {
    if (selected && selected.length > 0) {
      const selectedOption = selected[0];
      const event = {
        target: {
          name: 'trainer_id',
          value: selectedOption.id,
        },
      };
      handleChange(event);
      dispatch(changeMember({ value: selectedOption.id, name: 'trainer_id' }));
      setSelectedTrainer(selected);
    } else {
      handleChange({ target: { name: 'trainer_id', value: '' } });
      setSelectedTrainer([]);
    }
  };  

  useEffect(() => {
    if (values.trainer_id && filterData.length > 0) {
      const selected = filterData.find(trainer => trainer.id === values.trainer_id);
      if (selected) {
        setSelectedTrainer([selected]);
      }
    }
  }, [values.trainer_id, filterData]);

  useEffect(() => {
    if (!values.trainer_id) {
      setSelectedTrainer([]);
    }
  }, [values.trainer_id]);
  
  

  return (
    <Card className="px-4">
      <Row className="py-2">
        <Col lg={6}>
          <Form.Group>
            <Form.Label>{i18n("Member name")}</Form.Label>
            <span className="required" />
          </Form.Group>
          <Form.Control
            type="text"
            name="member_name"
            onBlur={handleBlur}
            placeholder={placeholderText("Enter the Name")}
            className="form-control"
            onChange={(e) => {
              handleChange(e);
              const { value, name } = e.target
              dispatch(changeMember({ value, name }));
            }}
            isInvalid={touched.member_name && errors.member_name}
            value={values.member_name|| ''}
            autoFocus={true}
          />
          <Form.Text className="text-danger d-block fw-400 fs-small mt-2">
          {touched.member_name && errors.member_name}
          </Form.Text>
        </Col>
        <Col lg={6}>
          <Form.Group>
            <Form.Label>{i18n("Mobile Number")}</Form.Label>
            <span className="required" />
          </Form.Group>
          <Form.Control
            type="text"
            name="mobile"
            placeholder={placeholderText("Enter the Mobile Number")}
            className="form-control"
            pattern="[0-9]*"
            minLength={10}
            maxLength={10}
            onBlur={handleBlur}
            isInvalid={touched.mobile && errors.mobile}
            onKeyPress={(event) => numValidate(event)}
            onChange={(e) => {
              handleChange(e);
              const { value, name } = e.target
              dispatch(changeMember({ value, name }));
            }}
            value={values.mobile|| ''}
          />
          <ErrorMessage
            name="mobile"
            component="span"
            className="text-danger d-block fw-400 fs-small mt-2"
          />
        </Col>
      </Row>

      <Row className="py-2">
        <Col lg={6}>
          <Form.Group>
            <Form.Label>{i18n("age")}</Form.Label>
          </Form.Group>
          <Form.Control
            type="age"
            name="age"
            placeholder={placeholderText("Enter Age")}
            className={`${errors["age"] ? "error-highlight" : "form-control"
              }`}
            onChange={(e) => {
              handleChange(e);
              const { value, name } = e.target
              dispatch(changeMember({ value, name }));
            }}
            value={values.age|| ''}
          />
        </Col>
        
        <Col lg={6}>
      <Form.Group>
        <Form.Label>{i18n("MEMBER NO")}</Form.Label>
      </Form.Group>
      <Form.Control
        type="text"
        name="member_id"
        placeholder={placeholderText("Enter No")}
        onChange={handleIdChange}
        value={member_id || values.member_id|| ''}
      />
      {showWarning && (
        <Alert variant="warning" className="mt-2">
        {i18n(" Warning, This will affect document uniqueness !")}
        </Alert>
      )}
    </Col>
      </Row>

      <Row className="py-2">
        <Col lg={6}>
          <Form.Group>
            <Form.Label>{i18n("weight")}</Form.Label>
          </Form.Group>
          <Form.Control
            type="weight"
            name="weight"
            placeholder={placeholderText("Enter Weight")}
            className={`${errors["weight"] ? "error-highlight" : "form-control"
              }`}
            onChange={(e) => {
              handleChange(e);
              const { value, name } = e.target
              dispatch(changeMember({ value, name }));
            }}
            value={values.weight|| ''}
          />
          {errors.weight && (
            <span className="" style={{ color: "#EE5B50" }}>
              {errors.weight}
            </span>
          )}
        </Col>
        <Col lg={6}>
          <Form.Group>
            <Form.Label>{i18n("height")}</Form.Label>
          </Form.Group>
          <Form.Control
            type="height"
            name="height"
            placeholder={placeholderText("Enter Height")}
            className={`${errors["height"] ? "error-highlight" : "form-control"
              }`}
            onChange={(e) => {
              handleChange(e);
              const { value, name } = e.target
              dispatch(changeMember({ value, name }));
            }}
            value={values.height|| ''}
          />
        </Col>
      </Row>
      { admin === 1 && (
        <Row className="py-2">
          <Col lg={6}>
              <Form.Label className="form-label-item mb-2">
                {i18n("Trainer")}
              </Form.Label>
            <Form.Group as={Col} lg={12}>
            <InputGroup>
              <Typeahead
                id={"party-typeahead"}
                ref={typeaheadRef}
                labelKey="name"
                options={filterData}
                placeholder={placeholderText("Search and select trainer")}
                onChange={handleTrainerSelect}
                selected={selectedTrainer}
                // isInvalid={touched.trainer_id && errors.trainer_id}
                filterBy={["name"]}
                renderMenu={(results, menuProps) => (
                  <Menu {...menuProps}>
                    {results.map((result, position) => (
                      <MenuItem key={position} option={result} position={position}>
                        {result.name}
                      </MenuItem>
                    ))}
                  </Menu>
                )}
              />

              {/* Clear button to reset the selectedTrainer state */}
              {selectedTrainer && selectedTrainer.length > 0 && (
                <InputGroup.Text
                  className="cursor-pointer fs-2"
                  style={{ background: "none" }}
                  onClick={() => {
                    setSelectedTrainer([]);
                    handleChange({ target: { name: 'trainer_id', value: '' } });
                    typeaheadRef.current.clear(); // Clear the Typeahead input box
                  }}
                >
                  <FontAwesomeIcon
                    icon={faSquareXmark}
                    title="Clear"
                    className="text-danger"
                  />
                </InputGroup.Text>
              )}
            </InputGroup>
            </Form.Group>

          </Col>
        </Row>
      
      )}
      { trainer === "trainer" && (
        <Row className="py-2">
        <Col lg={6}>
          <Form.Label className="form-label-item mb-2">
            {i18n("Trainer")}
          </Form.Label>
          <Form.Group as={Col} lg={12}>
            <Form.Control
              type="text"
              readOnly
              value={selectedTrainer?.length > 0 ? selectedTrainer[0].name : ""}
              placeholder="No trainer selected"
            />
          </Form.Group>
        </Col>
      </Row>
      
      )}
    </Card>
  );
};

export default MemberDetails;
