import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from "@reduxjs/toolkit";
import { i18n } from "../../shared/helpers/sharedMethods";
import { axiosRequest } from "../../shared/helpers/axiosHelper";

const partyReportAdapter = createEntityAdapter({});

const initialState = partyReportAdapter.getInitialState({
  status: true,
  error: null,
  data: {},
});

export const fetchPartyReport = createAsyncThunk(
  "partyWiseReport/fetchparty",
  async (args) => {
    const token = args.token;
    const tenantId = args.tenant_id;
    const id = args.product_id;
    console.log('paras',args);
    try {
      const response  = await axiosRequest({
        method: "GET",
        url: `get-product-party-report`,
        params: {
          product_id: id,
        },
        headers: {
          Authorization: `Bearer ${token}`,
          "X-Tenant": tenantId,
        },
      });
    
      return response.data;
    } catch (error) {
      await window.log.logToFile({message: "Unable to fetch Party Report", level: "error" });
      throw error;
    }
  }
);

const partyWiseReportSlice = createSlice({
  name: "partyWiseReport",
  initialState,
  reducers: {
  
  },
  extraReducers(builder) {
    builder
      .addCase(fetchPartyReport.fulfilled, (state, action) => {
        state.status = true;
        state.data = action.payload;
       
      })
      .addCase(fetchPartyReport.rejected, (state, action) => {
        state.status = false;
        state.error = i18n('Unable to get the party report right now . Please try again later.');
      });
  },
});

export default partyWiseReportSlice.reducer;

export const {
  selectAll: selectAllpartyReportData,
  selectById: selectPartyReportDataById,
  selectIds: selectPartyReportDataIds,
} = partyReportAdapter.getSelectors((state) => state.partyWiseReport);
