import CreateProduct from "./screens/CreateProduct"
import EditProduct from "./screens/EditProduct"
import ProductOverview from "./screens/ProductOverview"
import ProductView from "./screens/ProductView"
import ProductIndex from "./screens/ProductIndex"
import ProductImport from "./screens/ProductImport"


const rootPath = 'catalog';
const featureName = 'catalog';

export const catalogRoutes =  [
    {
        path: rootPath+'/products',
        element: <ProductIndex featureName={featureName} />,
        isProtected: true
    },
    {
        path: rootPath+'/overview',
        element: <ProductOverview />,
        isProtected: true
    },
    {
        path: rootPath+ '/products/create',
        element: <CreateProduct />,
        isProtected: true
    },
    {
        path: rootPath+ '/products/view/:productId',
        element: <ProductView />,
        isProtected: true
    },
    {
        path : rootPath+ '/products/edit/:productId',
        element : <EditProduct />,
        isProtected: true
    },
    {
        path : rootPath+ '/products/import',
        element : <ProductImport title='products'/>,
        isProtected: true
    }
]