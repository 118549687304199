import { Button, Row, Stack } from "react-bootstrap";
import { downloadCsvFile, handleExportClick } from "../../shared/export";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { UilImport } from "@iconscout/react-unicons";
import { i18n } from "../../shared/helpers/sharedMethods";
import { exportPreProcessor } from "../../voucher/helpers/VouchersHelper";
import { getAllVoucher, selectSalesByType } from "../../voucher/store/voucherSlice";

const SalesExport = (props) => {
  const { type } = props;
  const dispatch = useDispatch();
  const allSales = useSelector((state) => selectSalesByType(state, type));
  const [displayedSales, setDisplayedSales] = useState(allSales);
  const [exportData, setExportData] = useState([]);
  const accessToken = useSelector((state) => state?.auth?.accessToken?.access_token);
  const selectedBusiness = useSelector((state) => state?.auth?.selectedBusiness);
  const tenantId = selectedBusiness?.pivot?.tenant_id;
  const [csvData, setCsvData] = useState("");

 useEffect(() => {
    dispatch(getAllVoucher({ token: accessToken, tenant_id: tenantId }));
    }, [type]);

  useEffect(() => {
    if (allSales.length > 0) {
      setDisplayedSales(allSales);
    }
  }, [allSales]);

  const dateTimeString = new Date()
    .toLocaleString()
    .replace(/[-]/g, " ")
    .replace(/:/g, "-");

  const handleExportSales = () => {
    const processedExportData = exportPreProcessor(allSales);
    setExportData(exportData);
    handleExportClick(
      processedExportData,
      (exportData) => {
        setCsvData(exportData);
      },
      downloadCsvFile,
      `${type}_${dateTimeString}`
    );
  };
  return (
    <>
      <Stack
        gap={2}
        direction="horizontal"
        className="pt-3 px-3 mb-3 d-flex align-items-center justify-content-center"
      >
        <Button
          className="fw-bolder d-flex align-items-center rounded-0"
          onClick={handleExportSales}
        >
          <UilImport size="18" className="mx-2" style={{ cursor: "pointer" }} />
          {i18n("Export")}
        </Button>
      </Stack>
    </>
  );
};

export default SalesExport;
