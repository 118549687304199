import React, { useState } from "react";
import {
  faArrowAltCircleDown,
  faCreditCard,
  faCubes,
  faExchange,
  faFileInvoice,
  faKeyboard,
  faNoteSticky,
  faPlus,
  faQuoteLeft,
  faShoppingBag,
  faUserPlus,
  faX,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  ButtonGroup,
  Card,
  Col,
  Dropdown,
  Form,
  InputGroup,
  OverlayTrigger,
  Row,
  Tooltip,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useHotkeys } from "react-hotkeys-hook";
import { i18n } from "../helpers/sharedMethods";

const ShortcutKeys = () => {
  const dispatch = useDispatch();
  const [showDropdown, setShowDropdown] = useState(false);
  const shortcut = <Tooltip id="shortcut-tooltip">{i18n("Keyboard shorctcuts")}</Tooltip>;

  useHotkeys("alt+k", (event) => {
    event.preventDefault();
    setShowDropdown((prevShow) => !prevShow);
  });

  const DropDownMenu = [
    {
      key: 1,
      text: i18n("Create"),
      items: [
        {
          label: i18n("Sales Invoice"),
          key: "Alt + S",
        },
        {
          label: i18n("Purchase Invoice"),
          key: "Alt + P",
        },
        {
          label: i18n("Payment In"),
          key: "Alt + I",
        },
        {
          label: i18n("Payment Out"),
          key: "Alt + O",
        },
        {
          label: i18n("Sales return"),
          key: "Alt + C",
        },
        {
          label: i18n("Purchase Return"),
          key: "Alt + R",
        },
        {
          label: i18n("Quotations"),
          key: "Alt + Q",
        },
        {
          label: i18n("Party"),
          key: "Alt + Y",
        },
        {
          label: i18n("Product"),
          key: "Alt + M",
        },
      ],
    },
    {
      key: 2,
      text: i18n("Create Invoice Form Actions"),
      items: [
        {
          label: i18n("Add Party"),
          key: "Shift + Y",
        },
        {
          label: i18n("Add Item"),
          key: "Shift + M",
        },
        {
          label: i18n("Save"),
          key: "Alt + Enter",
        },
        {
          label: i18n("Cancel"),
          key: "Escape",
        },
        {
          label: i18n("Go to next field"),
          key: "Tab",
        },
        {
          label:i18n( "Go to prev field"),
          key: "Shift + Tab",
        },
      ],
    },
  ];

  return (
    <>
      <Dropdown
        as={ButtonGroup}
        className="outline-primary p-0 mt-2"
        size="sm"
        show={showDropdown}
        onToggle={() => setShowDropdown(!showDropdown)}
      >
        <OverlayTrigger placement="left" overlay={shortcut}>
          <Dropdown.Toggle
            variant="link p-0"
            id="dropdown-basic"
            className="custom-dropdown-toggle text-light "
          >
            <FontAwesomeIcon
              icon={faKeyboard}
              className="fs-1  text-secondary cursor-pointer"
            />
          </Dropdown.Toggle>
        </OverlayTrigger>
        <Dropdown.Menu className="mt-2 dropdown-menu-custom" align={"end"}>
          <Dropdown.Header
            className="px-2  py-1 mb-2 m-0"
            style={{ borderBottom: "1px solid lightgray" }}
          >
            <Form.Label className="fs-4 " style={{ fontWeight: 600 }}>
              {i18n("Keyboard shortcuts")}
            </Form.Label>
          </Dropdown.Header>
          <div
            className=""
            style={{ height: "600px", overflow: "auto", overflowX: "hidden" }}
          >
            {DropDownMenu.map((section, index) => (
              <div className="" key={index}>
                <Dropdown.ItemText
                  bsPrefix="custom-dropdown-item"
                  className="px-2"
                  style={{
                    fontSize: "14px",
                    fontWeight: 500,
                  }}
                >
                  {section.text}
                </Dropdown.ItemText>
                <Dropdown.Divider />
                {section.items.map((item, itemIndex) => (
                  <Dropdown.Item className="hover-color" key={itemIndex}>
                    <Row>
                      <Col lg={8}>{item.label}</Col>
                      <Col lg={4}>
                        <InputGroup.Text className="px-3 py-0">
                          {item.key}
                        </InputGroup.Text>
                      </Col>
                    </Row>
                  </Dropdown.Item>
                ))}
                {index !== DropDownMenu.length - 1 && <Dropdown.Divider />}
              </div>
            ))}
          </div>
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
};

export default ShortcutKeys;
