import React from "react";
import { useSelector } from "react-redux";
import {
  Col,
  Container,
  Badge,
  Row,
  Table,
  Form,
  Image,
} from "react-bootstrap";
import { selectSelectedImage } from "../../../settings/store/settingSlice";
import logo from "../../../../assets/images/bill.png";
import { i18n } from "../../helpers/sharedMethods";
import { QRCode } from "react-qr-code";
import { placeOfSupplyList } from "../../config/placeOfSupply";

const InvoiceThemes = ({ pdf }) => {
  const selectImage = useSelector(selectSelectedImage);
  const selectedBusiness = useSelector(
    (state) => state?.auth?.selectedBusiness
  );
  ;
  const currencySymbol = useSelector(
    (state) => state.getSetting?.dataSetting.currency
  );
  ;
  const termsAndCondition = useSelector(
    (state) => state.setting?.billing_settings?.terms_and_conditions
  );
  ;
  const BankDetails = useSelector((state) => state.setting?.billing_settings);
  const UPIPayment = useSelector(
    (state) => state.setting?.billing_settings?.upi_payment
  );
  const fetchData = useSelector((state) => state.getSetting?.dataSetting);
  ;
  const generateQRCode = (UPIPayment) => {
    const upiLink = `upi://pay?pa=${UPIPayment}`;
    ;
    return (
      <QRCode
        value={upiLink}
        size={60}
        bgColor={"#ffffff"}
        fgColor={"#000000"}
        level={"L"}
      />
    );
  };
  const state = placeOfSupplyList.find(
    (option) => option.value == fetchData?.state
  );
  const bankDetailsCheck =
    fetchData?.card_holder_name ||
    fetchData?.account_number ||
    fetchData?.ifsc_code ||
    fetchData?.bank ||
    fetchData?.branch_name;

  return (
    <>
      <Row className="p-4 mt-3" ref={pdf} style={{ backgroundColor: "white" }}>
        <Row>
          <Col>
            <Row>
              <Col className=" p-2 m-0">
                <p style={{ fontWeight: "bolder" }}>
                  {i18n("TAX INVOICE")}
                  <span
                    className="border border-secondary px-2 mx-2"
                    style={{ fontWeight: 500 }}
                  >
                    {i18n("ORIGINAL FOR RECIPIENT")}
                  </span>
                </p>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col lg={3} className="d-flex align-items-center justify-content-center">
            <img
              src={selectImage ? selectImage : logo}
              width={120}
              height={80}
              alt="Logo"
              style={{
                objectFit: "contain",
              }}
            />
          </Col>
          <Col lg={9}>
            <Row className="mt-5 ">
              <Row>
                <Col>
                  <Form.Label
                    className=""
                    style={{ fontSize: "22px", fontWeight: 600 }}
                  >
                    {fetchData?.business_name}
                  </Form.Label>
                  <Form.Group>
                    <div className="mb-0">
                      {fetchData?.business_address},&nbsp; {fetchData?.city}
                      ,&nbsp; {state?.label}&nbsp;-&nbsp; {fetchData?.pincode}
                    </div>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                {fetchData?.business_phone && (
                  <Col lg={12}>
                    <Form.Label className="mb-0">{i18n("Phone")} : </Form.Label>{" "}
                    &nbsp;{" "}
                    <Form.Label className="mb-0">
                      {" "}
                      {fetchData?.business_phone
                        ? fetchData?.business_phone
                        : ""}
                    </Form.Label>
                  </Col>
                )}
              </Row>
              <Row>
                {fetchData?.gstin && (
                  <Col lg={12}>
                    <Form.Label className="mb-0">{i18n("GSTIN")} :</Form.Label>{" "}
                    <Form.Label className="mb-0">
                      {" "}
                      &nbsp; {fetchData?.gstin ? fetchData?.gstin : ""}
                    </Form.Label>
                  </Col>)}
                {fetchData?.pan && (
                  <Col lg={12}>
                    <Form.Label className="mb-0">
                      {i18n("PAN Number")} :{" "}
                    </Form.Label>{" "}
                    &nbsp; {fetchData?.pan ? fetchData?.pan : ""}
                  </Col>)}
              </Row>
            </Row>
          </Col>
        </Row>
        <Row className="mt-5">
          <Col>
            <Row>
              <Col
                style={{
                  height: "8px",
                  width: "100%",
                  backgroundColor: "black",
                }}
              ></Col>
            </Row>
            <Row
              style={{
                height: "40px",
                backgroundColor: "#E2E2E2",
                alignItems: "center",
                justifyContent: "space-between",
                fontSize: "14px",
              }}
            >
              <Col>
                <b>{i18n("Invoice No")} :</b> 117642
              </Col>
              <Col>
                <b>{i18n("Invoice Date")} :</b> 12-02-2024
              </Col>

              <Col>
                <b>{i18n("Due Date")} :</b> 13-04-2024
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="mt-2">
          <Col lg={6} py={2}>
            <Row>
              <Col
                style={{
                  fontSize: "13px",
                  fontWeight: "500",
                  marginBottom: "5px",
                }}
              >
                {i18n("BILL TO")}
              </Col>
            </Row>
            <Row>
              <Col style={{ fontWeight: "600", fontSize: "14px" }}>
                {i18n("Sample Party")}
              </Col>
            </Row>
            <Row>
              <Col>Sample Steet</Col>
            </Row>
            <Row>
              <Col>{i18n("Mobile No")} : 9789524432</Col>
            </Row>
            <Row>
              <Col>{i18n("GSTIN")} : 29GGGGG1314R9Z6</Col>
            </Row>
            <Row>
              <Col>{i18n("PAN Number")} : ABCTY1234D</Col>
            </Row>
          </Col>
          <Col lg={6} py={2}>
            <Col
              style={{
                fontSize: "13px",
                fontWeight: "500",
                marginBottom: "5px",
              }}
            >
              {i18n("SHIP TO")}
            </Col>
            <Row>
              <Col style={{ fontWeight: "600", fontSize: "14px" }}>
                {i18n("Sample Party")}
              </Col>
            </Row>
            <Row>
              <Col>Sample2 Street</Col>
            </Row>
            <Row>
              <Col>{i18n("Mobile No")} : 9789524432</Col>
            </Row>
          </Col>
        </Row>
        <Row className="mt-5">
          <Table className="table table-borderless">
            <thead>
              <tr
                style={{
                  backgroundColor: "#E2E2E2",
                  borderBottom: "1px solid black",
                  borderTop: "1px solid black",
                  fontSize: "13px",
                  fontWeight: 500,
                }}
              >
                <th style={{ textAlign: "start" }}>{i18n("ITEMS")}</th>
                <th style={{ textAlign: "center" }}>{i18n("HSN")}</th>
                <th style={{ textAlign: "center" }}>{i18n("QTY")}</th>
                <th style={{ textAlign: "center" }}>{i18n("RATE")}</th>
                <th style={{ textAlign: "center" }}>{i18n("DISC")}</th>
                <th style={{ textAlign: "center" }}>{i18n("TAX")}</th>
                <th style={{ textAlign: "end" }}>{i18n("AMOUNT")}</th>
              </tr>
            </thead>
            <tbody>
              <tr
                style={{
                  fontSize: "13px",
                  fontWeight: 400,
                  borderBottom: "1px solid #E2E2E2",
                }}
              >
                <td>Barley Rice</td>
                <td style={{ textAlign: "center" }}>123 </td>
                <td style={{ textAlign: "center" }}>3 PCS</td>
                <td style={{ textAlign: "center" }}>{currencySymbol} 1,200.00</td>
                <td style={{ textAlign: "center" }}>{currencySymbol} 100.00</td>
                <td style={{ textAlign: "center" }}>
                  {currencySymbol} 216{" "}
                  <span style={{ fontSize: "11px", color: "#6c757d" }}>
                    (6%)
                  </span>{" "}
                </td>
                <td style={{ textAlign: "end" }}>{currencySymbol} 3,816.00</td>
              </tr>
              <tr
                style={{
                  fontSize: "13px",
                  fontWeight: 400,
                  borderBottom: "1px solid #E2E2E2",
                }}
              >
                <td>Hot Snacks</td>
                <td style={{ textAlign: "center" }}>903 </td>
                <td style={{ textAlign: "center" }}>6 PCS</td>
                <td style={{ textAlign: "center" }}>{currencySymbol} 100.00</td>
                <td style={{ textAlign: "center" }}>{currencySymbol} 0</td>
                <td style={{ textAlign: "center" }}>
                {currencySymbol} 1.5{" "}
                  <span style={{ fontSize: "11px", color: "#6c757d" }}>
                    (0.25%)
                  </span>
                </td>
                <td style={{ textAlign: "end" }}>{currencySymbol} 601.50</td>
              </tr>
            </tbody>
            <tfoot>
              <Col style={{ minHeight: "100px" }}> </Col>
              <tr
                className="bold"
                style={{
                  borderTop: "1px solid black",
                  borderBottom: "1px solid black",
                }}
              >
                <td style={{ fontSize: "13px", fontWeight: 600 }}>
                  {i18n("SUB TOTAL")}
                </td>
                <td></td>
                <td style={{ textAlign: "center" }}>9</td>
                <td></td>
                <td style={{ textAlign: "center" }}>{currencySymbol} 100.00</td>
                <td style={{ textAlign: "center" }}>{currencySymbol} 217.5</td>
                <td style={{ textAlign: "end" }}>{currencySymbol} 4,417.5</td>
              </tr>
            </tfoot>
          </Table>
        </Row>
        <Row>
          <Col lg={6}>
            {bankDetailsCheck && (
              <Col className="mb-3">
                <Form.Label
                  className=""
                  style={{ fontSize: "13px", fontWeight: 600 }}
                >
                  {i18n("BANK DETAILS")}
                </Form.Label>{" "}
                <br />
                {fetchData?.card_holder_name && (
                  <Form.Label className="mb-1">
                    {i18n("Name")}: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{fetchData?.card_holder_name}{" "}
                  </Form.Label>
                )}
                <br />
                {fetchData?.ifsc_code && (
                  <Form.Label className="mb-1">
                    {i18n("IFSC Code")}: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{fetchData?.ifsc_code}{" "}
                  </Form.Label>
                )}
                <br />
                {fetchData?.account_number && (
                  <Form.Label className="mb-1">
                    {i18n("Account No")}: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{fetchData?.account_number}{" "}
                  </Form.Label>
                )}
                <br />
                {fetchData?.bank || fetchData?.branch_name ? (
                  <Form.Label className="mb-1">
                    {i18n("Bank")}: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{fetchData?.bank}, {fetchData?.branch_name}{" "}
                  </Form.Label>
                ) : ''}
                <br />
              </Col>
            )}
            {fetchData?.upi_payment && (
              <Col className={`${bankDetailsCheck ? "" : "mb-3"}`}>
                <Row lg={12}>
                  <Col lg={6}>
                    <Form.Group>
                      <Form.Label style={{ fontSize: "13px", fontWeight: 600 }}>
                        {i18n("PAYMENT QR CODE")}
                      </Form.Label>
                      <p>
                        {i18n("UPI ID")} : <br /> {UPIPayment}{" "}
                      </p>
                    </Form.Group>
                  </Col>

                  <Col lg={6}>{UPIPayment && generateQRCode(UPIPayment)}</Col>
                </Row>
              </Col>
            )}
            {termsAndCondition && (
              <Col
                className={`${bankDetailsCheck || fetchData?.upi_payment ? "" : ""
                  }`}
              >
                <Form.Label style={{ fontSize: "13px", fontWeight: 600 }}>
                  {i18n("TERMS AND CONDITIONS")}
                </Form.Label>
                <p>{termsAndCondition} </p>
              </Col>
            )}
          </Col>
          <Col lg={6} style={{ fontSize: "12px" }}>
            <Row className="px-1">
              <Col lg={7} style={{ textAlign: "center" }}>
                {i18n("TAXABLE VALUE")}
              </Col>
              <Col lg={5} style={{ textAlign: "end" }}>
                {currencySymbol} 3,882.5{" "}
              </Col>
            </Row>
            <Row className="px-1">
              <Col lg={7} style={{ textAlign: "center" }}>
                {i18n("CGST")} @0.125%
              </Col>
              <Col lg={5} style={{ textAlign: "end" }}>
                {currencySymbol} 108
              </Col>
            </Row>
            <Row className="px-1">
              <Col lg={7} style={{ textAlign: "center" }}>
                {i18n("SGST")} @0.125%
              </Col>
              <Col lg={5} style={{ textAlign: "end" }}>
                {currencySymbol} 108
              </Col>
            </Row>
            <Row className="px-1">
              <Col lg={7} style={{ textAlign: "center" }}>
                {i18n("CGST")} @3%
              </Col>
              <Col lg={5} style={{ textAlign: "end" }}>
                {currencySymbol} 0.75
              </Col>
            </Row>
            <Row className="px-1">
              <Col lg={7} style={{ textAlign: "center" }}>
                {i18n("SGST")} @3%
              </Col>
              <Col lg={5} style={{ textAlign: "end" }}>
                {currencySymbol} 0.75
              </Col>
            </Row>
            <Row
              className="px-1 bold"
              style={{
                borderTop: "1px solid black",
                borderBottom: "1px solid black",
              }}
            >
              <Col lg={7} style={{ textAlign: "center" }}>
                {i18n("TOTAL AMOUNT")}
              </Col>
              <Col style={{ fontWeight: "bold", textAlign: "end" }} lg={5}>
                {currencySymbol} 4,317.5
              </Col>
            </Row>
            <Row className="px-1">
              <Col lg={7} style={{ textAlign: "center" }}>
                {i18n("RECEIVED AMOUNT")}
              </Col>
              <Col lg={5} style={{ textAlign: "end" }}>
                {currencySymbol} 1,000
              </Col>
            </Row>

            <Row className="px-1">
              <Col lg={7} style={{ textAlign: "center" }}>
                {" "}
                {i18n("BALANCE AMOUNT")}
              </Col>
              <Col lg={5} style={{ textAlign: "end" }}>
                {currencySymbol} 3,317
              </Col>
            </Row>

            <Row>
              <Col
                lg={12}
                className="px-4 py-1 mt-2"
                style={{ textAlign: "end" }}
              >
                <b>{i18n("Four Thousand THREE HUNDRED SEVENTEEN RUPEES ONLY")}</b> <br />
              </Col>
            </Row>
          </Col>
        </Row>

        <Row className="">
          <Col lg={7}>
            <p style={{ width: 130, height: 30 }}></p>
          </Col>
          {!fetchData?.signature && (
            <Col
              lg={5}
              className="border  d-flex justify-content-end"
              style={{ height: "50px" }}
            ></Col>
          )}
          {fetchData?.signature && (
            <Col lg={5} className="  d-flex justify-content-end">
              <Image
                src={fetchData?.signature}
                alt="signature"
                style={{ width: "200px", height: "50px" }}
              />
            </Col>
          )}
        </Row>
        <Row>
          <Col
            lg={12}
            className="mt-2 d-flex flex-column align-items-end"
            height={100}
          >
            <Form.Label
              className="mb-0"
              style={{
                fontSize: "13px",
                fontWeight: 600,
              }}
            >
              {i18n("Authorized Signatory For")}
            </Form.Label>

            <Form.Label style={{ fontSize: "13px", fontWeight: 600 }}>
              {selectedBusiness?.business_name}{" "}
            </Form.Label>
          </Col>
        </Row>
      </Row>
    </>
  );
};

export default InvoiceThemes;
