import React, { useEffect, useState } from "react";
import { Card, Col, Form, InputGroup, Row } from "react-bootstrap";
import SubscriptionProductTypeAhead from "./components/fields/SubscriptionProductTypeahead";
import ExpiresAfter from "./components/fields/ExpiresAfter";
import FreeTrialDays from "./components/fields/FreeTrialDays";
import SubscriptionNumber from "./components/fields/SubscriptionNumber";
import SubscriptionStartDate from "./components/fields/SubscriptionStartDate"

const PackageDetailsForm = ({ handleChange, values, isEdit, errors ,onProductSelect,setFieldValue}) => {
  return (
    <Card>
      <Row>
        <Col lg={3}>
          <SubscriptionProductTypeAhead isEdit={isEdit} errors={errors}  onProductSelect={onProductSelect}  />
        </Col>
        <Col md={3}>
          <SubscriptionNumber handleChange={handleChange} values={values} />
        </Col>
        <Col md={3}>
        <SubscriptionStartDate
              handleChange={handleChange}
              values={values}
              setFieldValue={setFieldValue}
              errors={errors}
            />
        </Col>
        {/* <Col lg={3}>
          <ExpiresAfter handleChange={handleChange} values={values} />
        </Col> */}
          {/* <Col lg={3}>
          <FreeTrailDays handleChange={handleChange} values={values} />
        </Col> */}
      </Row>
    </Card>
  );
};

export default PackageDetailsForm;
