import React, { useState, useEffect } from "react";
import {
  Button,
  Row,
  Col,
  Card,
  Table,
  Stack,
  Container,
  Form,
  Image
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchProfitAndLoss } from "../store/profitAndLossSlice";
import Layout from "../../shared/components/Layout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowDown,
  faArrowLeft,
  faArrowUp,
  faChevronLeft,
  faDownload,
} from "@fortawesome/free-solid-svg-icons";
import { downloadCsvFile, handleExportClick } from "../../shared/export";
import ReportPeriod from "../../shared/components/ReportPeroid";
import Header from "../../shared/components/Header";
import { i18n } from "../../shared/helpers/sharedMethods";
import TransactionNavbar from "../components/TransactionNavbar";
import ShortcutKeys from "../../shared/components/ShortcutKeys";
import DatePicker from "../../shared/components/date_range/DatePicker";
import ReportDateRange from "../../shared/components/date_range/ReportDateRange";
import { CurrentWeekDateRange } from "../../shared/components/DateRangeYAxis";
import ReportHeader from "../components/ReportHeader";
import { UilImport } from "@iconscout/react-unicons";
import GlobalCurrency from "../../shared/components/GlobalCurrency";
import LoadingSpinner from '../../shared/components/LoadingSpinner';

const ProfitAndLoss = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const accessToken = useSelector((state) => state?.auth?.accessToken?.access_token);
  const selectedBusiness = useSelector((state) => state?.auth?.selectedBusiness);
  const tenantId = selectedBusiness?.pivot?.tenant_id;
  const [reportPeriod, setReportPeriod] = useState("current-week");
  const [reportStatus, setReportStatus] = useState();
  const [csvData, setCsvData] = useState("");
  const [startDate, setStartDate] = useState(null); // State for start date
  const [endDate, setEndDate] = useState(null);
  
    const handleReset = () => {
      const salesDateLimits = ReportDateRange({
        reportPeriod: reportPeriod,
      });
      setStartDate(salesDateLimits.startDate);
      setEndDate(salesDateLimits.endDate);
      setReportPeriod("current-week");
    
    };
  useEffect(() => {
    dispatch(fetchProfitAndLoss({startDate: startDate, endDate: endDate,token:accessToken,tenant_id:tenantId})); 
  }, [startDate, endDate]);
  useEffect(() => {
    const dateLimits = ReportDateRange({ reportPeriod });
    setStartDate(dateLimits.startDate);
    setEndDate(dateLimits.endDate);
  }, [reportPeriod]);

  const currencySymbol = useSelector((state) => state.getSetting?.dataSetting.currency);
  ;
  const reportItems = useSelector((state) => state.profitAndLoss.reportItems);
  const status = useSelector((state) => state.profitAndLoss.status);
  const loading = useSelector((state) => state.profitAndLoss.loading);
  const errorMessage = useSelector((state) => state.profitAndLoss.errorMessage);

  const typesToSum = ["sales_return", "credit_note"];
  const typesToPurchaseAdd = ["debit_note", "purchase_return"];
  const typesToPurchase = ["purchase_invoice"];
  const typesToSalesAdd = ["sales_invoice"];

  const separateAmounts =
    reportItems && Array.isArray(reportItems)
      ? reportItems.reduce((acc, voucher) => {
        if (typesToSum.includes(voucher.type)) {
          acc[voucher.type] = (acc[voucher.type] || 0) + voucher.amount;
        }
        return acc;
      }, {})
      : 0;

  const separatePurchaseAdd =
    reportItems && Array.isArray(reportItems)
      ? reportItems.reduce((acc, voucher) => {
        if (typesToPurchaseAdd.includes(voucher.type)) {
          acc[voucher.type] = (acc[voucher.type] || 0) + voucher.amount;
        }
        return acc;
      }, {})
      : 0;

  const separatePurchase =
    reportItems && Array.isArray(reportItems)
      ? reportItems.reduce((acc, voucher) => {
        if (typesToPurchase.includes(voucher.type)) {
          acc[voucher.type] = (acc[voucher.type] || 0) + voucher.amount;
        }
        return acc;
      }, {})
      : 0;

    const taxPayableSales =  Array.isArray(reportItems)
    ? reportItems
      .filter((voucher) => voucher.type === "sales_invoice")
      .reduce((sum, voucher) => sum + voucher.tax_amount, 0)
    : 0;

    const  taxPayableSalesTypesSum=
    reportItems && Array.isArray(reportItems)
      ? reportItems.reduce((acc, voucher) => {
        if (typesToSum.includes(voucher.type)) {
          acc[voucher.type] = (acc[voucher.type] || 0) + voucher.tax_amount;
        }
        return acc;
      }, {})
      : 0;

      const totalOftaxPayableSalesTypes= Object.values(taxPayableSalesTypesSum).reduce(
        (sum, tax_amount) => sum + tax_amount,
        0
      );

      const taxPayable =taxPayableSales -  totalOftaxPayableSalesTypes; 

      const taxPurchase =  Array.isArray(reportItems)
      ? reportItems
        .filter((voucher) => voucher.type === "purchase_invoice")
        .reduce((sum, voucher) => sum + voucher.tax_amount, 0)
      : 0;
      const  PurchaseTypesTaxSum=
      reportItems && Array.isArray(reportItems)
        ? reportItems.reduce((acc, voucher) => {
          if (typesToPurchaseAdd.includes(voucher.type)) {
            acc[voucher.type] = (acc[voucher.type] || 0) + voucher.tax_amount;
          }
          return acc;
        }, {})
        : 0;
  
        const totalOfPurchaseTypesTax = Object.values(PurchaseTypesTaxSum).reduce(
          (sum, tax_amount) => sum + tax_amount,
          0
        );
  
        const taxReceivable = taxPurchase - totalOfPurchaseTypesTax; 
  // Sum of the three types
  const totalOfTypes = Object.values(separateAmounts).reduce(
    (sum, amount) => sum + amount,
    0
  );
  const typesOfPurchaseAdd = Object.values(separatePurchaseAdd).reduce(
    (sum, amount) => sum + amount,
    0
  );
  const typesOfPurchase = Object.values(separatePurchase).reduce(
    (sum, amount) => sum + amount,
    0
  );

  const salesInvoiceTotal = Array.isArray(reportItems)
    ? reportItems
      .filter((voucher) => voucher.type === "sales_invoice")
      .reduce((sum, voucher) => sum + voucher.amount, 0)
    : 0;

  const netProfit =
    salesInvoiceTotal + typesOfPurchaseAdd - (totalOfTypes + typesOfPurchase) - taxPayable + taxReceivable;
  ;
  useEffect(() => {
    if (netProfit < 0) {
      setReportStatus("red");
    } else if (netProfit > 0) {
      setReportStatus("green");
    } else {
      setReportStatus("black");
    }
  }, [netProfit]);

  const dateTimeString = new Date()
    .toLocaleString()
    .replace(/[-]/g, " ")
    .replace(/:/g, "-");
  const fileName = `Profit_and_Loss_${dateTimeString}`;

  const handleExportButtonClick = () => {
    const exportData = [
      {
        PARTICULARS: "Sale(+)",
        AMOUNT: salesInvoiceTotal
          ? `${currencySymbol} ${salesInvoiceTotal}`
          : `${currencySymbol} 0`,
      },
      {
        PARTICULARS: "Cr. Note/Sale Return(-)",
        AMOUNT: totalOfTypes
          ? `${currencySymbol} ${totalOfTypes}`
          : `${currencySymbol} 0`,
      },
      {
        PARTICULARS: "Purchase(-)",
        AMOUNT: typesOfPurchase
          ? `${currencySymbol} ${typesOfPurchase}`
          : `${currencySymbol} 0`,
      },
      {
        PARTICULARS: "Dr. Note/Purchase Return(+)",
        AMOUNT: typesOfPurchaseAdd
          ? `${currencySymbol} ${typesOfPurchaseAdd}`
          : `${currencySymbol} 0`,
      },
       {
        PARTICULARS: "Tax Payable(-)",
        AMOUNT: taxPayable
          ? `${currencySymbol} ${taxPayable}`
          : `${currencySymbol} 0`,
      },
      {
        PARTICULARS:"Tax Receivable(+)" ,
        AMOUNT: taxReceivable
          ? `${currencySymbol} ${taxReceivable}`
          : `${currencySymbol} 0`,
      }, 
      {
        PARTICULARS: "Net Profit",
        AMOUNT: netProfit
          ? parseFloat(netProfit).toFixed(2)
          : 0,
      },
    ];
    handleExportClick(
      exportData,
      setCsvData,
      downloadCsvFile,
      fileName
    );
  };

  return (
    <Layout>
       {loading && <LoadingSpinner />}
      <Header
        currentTitle={i18n("Profit And Loss Report")}
        ItemActions={
          <Stack gap={3} direction="horizontal" className="mt-1 mb-1 float-end">
            <ShortcutKeys />
          </Stack>
        }
      />
      <Container fluid className="px-5">
        <div className="border " style={{ background: "white" }}>
          <TransactionNavbar />
          <ReportHeader
            ItemActions={
              <Stack gap={2} direction="horizontal" className="pt-3 px-4 mb-3">
                <Stack direction="horizontal">
                  <DatePicker
                    startDate={startDate}
                    setStartDate={setStartDate}
                    endDate={endDate}
                    setEndDate={setEndDate}
                  />
                </Stack>
                <Stack className=" ">
                  <ReportPeriod
                    reportPeriod={reportPeriod}
                    setReportPeriod={setReportPeriod}
                  />
                </Stack>
                <Stack
                  gap={1}
                  direction="horizontal"
                  style={{ marginLeft: "1px" }}
                >
                  <Button
                    className="rounded-0"
                    variant="outline-danger"
                    onClick={handleReset}
                  >
                    {i18n("Reset")}
                    </Button>
                </Stack>
                <Button
                  className="fw-bolder d-flex align-items-center rounded-0"
                  onClick={handleExportButtonClick}
                >
                  <UilImport
                    size="18"
                    className="mx-2"
                    style={{ cursor: "pointer" }}
                  />
                  {i18n("Export")}
                </Button>
              </Stack>
            }
          />
          <Card className=" p-3">
            <div className="px-4 mt-4">
              <Row className="" style={{ borderTop: '1px solid lightgray', borderBottom: '1px solid lightgray',backgroundColor:'#F9FAFB' }}>
                <Col lg={6} className="px-0 py-2" >
                  <Form.Label className="mx-2" style={{ color: '#495057', fontSize: '1rem', fontWeight: 600 }}>
                    {i18n("PARTICULARS")}
                  </Form.Label>
                </Col>
                <Col lg={6} className="px-0 py-2" >
                  <Form.Label style={{ color: '#495057', fontSize: '1rem', fontWeight: 600 }}>
                    {i18n("AMOUNT")}
                  </Form.Label></Col>
              </Row>
              <Row className="" style={{ borderBottom: '1px solid lightgray' }}>
                <Col lg={6} className="px-0 py-2">
                  <Form.Label className="mx-2" style={{ fontSize: '1rem', fontWeight: 400 }}>
                    {i18n("Sale(+)")}
                  </Form.Label>
                </Col>
                <Col lg={6} className="px-0 py-2">
                  <Form.Label style={{ fontSize: '1rem', fontWeight: 400 }}>
                    <GlobalCurrency price={salesInvoiceTotal} />
                  </Form.Label></Col>
              </Row>
              <Row className="" style={{ borderBottom: '1px solid lightgray' }}>
                <Col lg={6} className="px-0 py-2">
                  <Form.Label className="mx-2" style={{ fontSize: '1rem', fontWeight: 400 }}>
                    {i18n("Cr. Note/Sale Return(-)")}
                  </Form.Label>
                </Col>
                <Col lg={6} className="px-0 py-2">
                  <Form.Label style={{ fontSize: '1rem', fontWeight: 400 }}>
                    <GlobalCurrency price={totalOfTypes} />
                  </Form.Label></Col>
              </Row>
              <Row className="" style={{ borderBottom: '1px solid lightgray' }}>
                <Col lg={6} className="px-0 py-2">
                  <Form.Label className="mx-2" style={{ fontSize: '1rem', fontWeight: 400 }}>
                    {i18n("Purchase(-)")}
                  </Form.Label>
                </Col>
                <Col lg={6} className="px-0 py-2">
                  <Form.Label style={{ fontSize: '1rem', fontWeight: 400 }}>
                    <GlobalCurrency price={typesOfPurchase} />
                  </Form.Label></Col>
              </Row>
              <Row className="" style={{ borderBottom: '1px solid lightgray' }}>
                <Col lg={6} className="px-0 py-2">
                  <Form.Label className="mx-2" style={{ fontSize: '1rem', fontWeight: 400 }}>
                    {i18n("Dr. Note/Purchase Return(+)")}
                  </Form.Label>
                </Col>
                <Col lg={6} className="px-0 py-2">
                  <Form.Label style={{ fontSize: '1rem', fontWeight: 400 }}>
                    <GlobalCurrency price={typesOfPurchaseAdd} />
                  </Form.Label></Col>
              </Row>
              <Row className="" style={{ borderBottom: '1px solid lightgray' }}>
                <Col lg={6} className="px-0 py-2">
                  <Form.Label className="mx-2" style={{ fontSize: '1rem', fontWeight: 400 }}>
                    {i18n("Tax Payable(-)")}
                  </Form.Label>
                </Col>
                <Col lg={6} className="px-0 py-2">
                  <Form.Label style={{ fontSize: '1rem', fontWeight: 400 }}>
                    <GlobalCurrency price={taxPayable} />
                  </Form.Label></Col>
              </Row>   
               <Row className="" style={{ borderBottom: '1px solid lightgray' }}>
                <Col lg={6} className="px-0 py-2">
                  <Form.Label className="mx-2" style={{ fontSize: '1rem', fontWeight: 400 }}>
                    {i18n("Tax Receivable(+)")}
                  </Form.Label>
                </Col>
                <Col lg={6} className="px-0 py-2">
                  <Form.Label style={{ fontSize: '1rem', fontWeight: 400 }}>
                    <GlobalCurrency price={taxReceivable} />
                  </Form.Label></Col>
              </Row>
            
              <Row className="" >
                <Col lg={6} className="px-0 py-2">
                  <Form.Label className="mx-2" style={{ fontSize: '1rem', fontWeight: 400 }}>
                    {i18n("Net Profit")}
                  </Form.Label>
                </Col>
                <Col lg={6} className="px-0 py-2">
                  <Form.Label style={{ fontSize: '1rem', fontWeight: 400 }}>
                    {reportStatus === "red" ? (
                      <label className=" fw-bolder fs-2" style={{ color: "red" }}>
                        {netProfit < 0 ? "-" : ""}
                        <GlobalCurrency price={Math.abs(netProfit)} />
                        <FontAwesomeIcon
                          className="px-1"
                          icon={faArrowDown}
                          style={{
                            color: "red",
                            width: "16px",
                            paddingTop: "3px",
                          }}
                        />
                      </label>
                    ) : reportStatus === "green" ? (
                      <label className="fw-bolder fs-2" style={{ color: "green" }}>
                        <GlobalCurrency price={netProfit} />
                        <FontAwesomeIcon
                          className="px-1"
                          icon={faArrowUp}
                          style={{
                            color: "green",
                            width: "16px",
                            paddingTop: "3px",
                          }}
                        />
                      </label>
                    ) : (
                      <label className="fw-bolder fs-2">
                        <GlobalCurrency price={netProfit} />
                      </label>
                    )}
                  </Form.Label>
                </Col>
              </Row>
            </div>
          </Card>
        </div>
      </Container>
    </Layout>
  );
};

export default ProfitAndLoss;
