import { Row } from "react-bootstrap";

import { ToastContainer } from "react-toastify";
import { useNavigate } from "react-router";
import LoadingSpinner from "../../shared/components/LoadingSpinner";
import ImportLayout from "./ImportLayout";
import { useSelector } from "react-redux";
import PartyImport from "./PartysImport";
import PartyExport from "./PartyExport";
import ItemTabs from "../../shared/components/item-detail/sections/ItemTabs";
import { i18n } from "../../shared/helpers/sharedMethods";
import { useState } from "react";
const PartyImportExport = () => {
  const navigate = useNavigate();
  const loading = useSelector((state) => state.getSetting.loading);
  const [reportType, setReportType] = useState("customers");


const tabs = [
  {
    key: "partyImport ",
    title: i18n("Import"),
    component: <PartyImport type={reportType} />,
  },
  {
    key: "partyExport",
    title: i18n("Export"),
    component: <PartyExport type={reportType} />,
  },
];
  return (
    <>
      <ToastContainer />
      <ImportLayout
        title="Party"
        setReportType={setReportType}
        reportType={reportType}
      >
        {loading && <LoadingSpinner />}
        <Row>
          <PartyImport type={reportType} />
          {/* <ItemTabs
            type="Setting"
            tabs={tabs}
            route="0"
            title="SettingsImport"
          /> */}
        </Row>
      </ImportLayout>
    </>
  );
};

export default PartyImportExport;
