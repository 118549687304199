import {
    createAsyncThunk,
    createSlice
  } from "@reduxjs/toolkit";
  import { axiosRequest } from "../../shared/helpers/axiosHelper";
  import { i18n } from "../../shared/helpers/sharedMethods";
  import { environment } from "../../shared/config/environment";

const initialState = {
    subscription : {},
    plan : {},
    features : [],
    featureStatus : false
};

export const fetchFeaturesForTenant = createAsyncThunk(
  "globalPlan/fetchFeaturesForTenant",
  async(args) => {
    try{
      console.log('plan triggere');
      const token = args.token;
      const tenantId = args.tenantId;

      const response = await axiosRequest({
        method: "GET",
        url: `tenants/plan-features/${tenantId}`,
        headers: {
          Authorization: `Bearer ${token}`
        },
      });
      console.log('plan response',response);
      return response;

    }catch(error){
      throw new Error(error?.response.message);
    }
  }
)

const globalPlanSlice = createSlice({
  name: "globalPlan",
  initialState,
  reducers:{
    resetGlobalPlan: () => initialState
  },
  extraReducers(builder){
    builder
    .addCase(fetchFeaturesForTenant.fulfilled, (state, action) => {
      state.featureStatus = true;
      state.subscription = action.payload?.subscription;
      state.features = action.payload?.features;
      state.plan = action.payload?.plan;
     
    })
    .addCase(fetchFeaturesForTenant.rejected, (state, action) => {
      state.status = false;
      state.error = i18n("Error while getting features for tenant. Please try again later.");
    })
  }
});

export default globalPlanSlice.reducer;

export const { resetGlobalPlan } = globalPlanSlice.actions;