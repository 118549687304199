import React from "react";
import { Modal, Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom"; 
import { resetAuth } from "../../auth/store/authSlice";

const SettingsOfflineModel = ({ emptyModel, setEmptyModel }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate(); 

  const handleOk = () => {
    dispatch(resetAuth()); 
    navigate('/auth/register'); 
  };

  return (
    <Modal
      show={emptyModel}
      dialogClassName="modal-dialog modal-dialog-centered"
      onHide={() => setEmptyModel(false)}
      keyboard={true}
    >
      <Modal.Header closeButton>
        <Modal.Title>{"Error"}</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ padding: "20px" }}>
        <p>Invalid Settings, Please Login again.</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={handleOk}>
          Proceed to login
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default SettingsOfflineModel;
