import { faSquareXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Col, Form, InputGroup } from "react-bootstrap";
import { Menu, MenuItem, Typeahead } from "react-bootstrap-typeahead";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { isEmpty } from "lodash";
import { toast, ToastContainer } from "react-toastify";
import { ErrorMessage } from "formik";
import { i18n,placeholderText} from "../../../../../shared/helpers/sharedMethods";
import { currentProductAsSubscriptionItems, selectProductAsSubscriptionItems } from "../../../../store/subscription_item_store/GetPackagesItemSlice";
import { changeParentProduct } from "../../../../store/CreateSubscriptionSlice";

const SubscriptionProductTypeAhead = ({ isEdit, errors, onProductSelect }) => {
  const [selectedItem, setSelectedItem] = useState([]);
  const dispatch = useDispatch();

  const allProduct = useSelector((state) => selectProductAsSubscriptionItems(state, null));
  const { product_id } = useSelector((state) => state.createSubscription);
  const currentProduct = useSelector((state) => currentProductAsSubscriptionItems(state, product_id));

  useEffect(() => {
    if (isEdit && currentProduct && !isEmpty(currentProduct)) {
      setSelectedItem(currentProduct);
    }
  }, [isEdit, currentProduct]);

  useEffect(() => {
    if (selectedItem.length > 0) {
      if (selectedItem[0]?.child_products && selectedItem[0].child_products.length === 0) {
        toast.error('Please select a plan for this Package');
      } else {
        dispatch(changeParentProduct(selectedItem[0]?.id));
        onProductSelect(selectedItem[0]);
      }
    }
  }, [selectedItem]);

  const handleProductSelect = (item) => {
    setSelectedItem(item);
    onProductSelect(item.length > 0 ? item[0] : null);
  };

  const filterByFields = ["name", "code"];
  return (
    <>
      <ToastContainer />
      <Form.Group as={Col} lg={12}>
        <Form.Label>{i18n("Select Package")}</Form.Label>
        <span className="required" />
        <InputGroup>
          <Typeahead
            renderMenu={(results, menuProps) => (
              <Menu {...menuProps}>
                {results.map((result, position) => (
                  <MenuItem key={position} option={result} position={position}>
                    <div>
                      {result.name}
                      <div>
                        {result.code && (
                          <small>
                            <b>{i18n("Code")}:</b> {result.code}
                          </small>
                        )}
                      </div>
                    </div>
                  </MenuItem>
                ))}
              </Menu>
            )}
            id={"package-typeahead"}
            newSelectionPrefix="Add a new party : "
            options={allProduct}
            placeholder={placeholderText("Search by Name")}
            onChange={handleProductSelect}
            filterBy={filterByFields}
            selected={selectedItem}
            labelKey="name"
            isInvalid={errors.product_id}
            disabled={isEdit}
          />
          {!isEdit && selectedItem.length > 0 && (
            <InputGroup.Text
              className="cursor-pointer fs-2"
              style={{ background: "none" }}
              onClick={() => {
                setSelectedItem([]);
                handleProductSelect([]);
              }}
            >
              <FontAwesomeIcon icon={faSquareXmark} title="Clear Item" className="text-danger" />
            </InputGroup.Text>
          )}
        </InputGroup>
        <ErrorMessage
          name="product_id"
          component="span"
          className="text-danger d-block fw-400 fs-small mt-2"
        />
      </Form.Group>
    </>
  );
};

export default SubscriptionProductTypeAhead;
