import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal, Button, Col, Form, Row } from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import BillingAddress from "./BillingAddress";
import {
  updateBillingAddress,
  selectPartyAddressById,
  deletePartyAddress,
  selectAllPartyAddress,
} from "../store/partyAddressSlice";
import { i18n, placeholderText } from "../../shared/helpers/sharedMethods";
import {
  createParty,
  resetUpdateStatus,
  updateAddressStatus,
  updatePartyAddress,
} from "../store/PartyEntitySlice";
import { getUtcDate } from "../../shared/helpers/localizationHelper";
import { preparePartyData } from "../helpers/partyHelper";
import { ErrorMessage ,Formik } from "formik";
import { numValidate } from "../../shared/helpers/sharedMethods";
import * as Yup from "yup";

const Address = ({
  id,
  show,
  editParty,
  setShow,
  click,
  isEdit = false,
  handleChange,
  setFieldValue,
  errors,
  values,
  handleBlur,
  touched,
}) => {
  const dispatch = useDispatch();
  const selectedData = useSelector((state) =>
    selectPartyAddressById(state, id)
  );
  const partyState = useSelector((state) => state.partyEntity.party);
  const selectedBusiness = useSelector(
    (state) => state?.auth?.selectedBusiness
  );
  const updatePartyStatus = useSelector(
    (state) => state.partyEntity.updateStatus
  );
  const { accessToken} = useSelector((state) => state?.auth);
  const tenantId = selectedBusiness?.pivot?.tenant_id;
  const { terminalId } = useSelector((state) => state.initInstall);
  
  useEffect(() => {
    if (isEdit && updatePartyStatus) {
      preparePartyData(partyState, selectedBusiness, dispatch, accessToken, tenantId, terminalId);
      setShow(false);
      dispatch(resetUpdateStatus()); 
    }
  }, [isEdit, updatePartyStatus]);
  
  const handleSave = async (validateForm) => {
    const formErrors = await validateForm();

    if (Object.keys(formErrors).length > 0) {
      return; 
    }
    if (!selectedData?.address) {
      toast.error("Please Fill The Details");
      return;
    }
    if (!selectedData?.pincode) {
      toast.error("Please Fill The Details");
      return;
    }
    const data = {
      created_by: selectedBusiness?.pivot?.user_id,
      updated_by: selectedBusiness?.pivot?.user_id,
      created_at: getUtcDate(),
      updated_at: getUtcDate(),
    };
    if (selectedData?.status) {
      dispatch(updateAddressStatus(id));
    }
    if (isEdit) {
      dispatch(updatePartyAddress({ allAddress: [selectedData], data: data }));
      
      if (updatePartyStatus) {
        preparePartyData(partyState, selectedBusiness, dispatch, accessToken, tenantId, terminalId);
        setShow(false);
      }
    } else {
    
      dispatch(updatePartyAddress({ allAddress: [selectedData], data: data }));
      setShow(false);
    }
  };
  
  
  const handleCancel = () => {
    dispatch(deletePartyAddress(id));
    setShow(false);
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Enter") {
        
        event.preventDefault();
        const saveButton = document.getElementById("saveButton");
       if (saveButton) {
            saveButton.click();
        }
      }
     };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);
  
  return (
     <Formik
        initialValues={partyState}
        enableReinitialize={true}
        validationSchema={Yup.object().shape({
          phone_number: Yup.string()
            .min(10, "Phone number must be 10 digits")
            .matches(/^[0-9]*$/, i18n("Invalid phone number")),

        })}
        onSubmit={handleSave}
      >
        {({ validateForm, handleChange, handleBlur, touched, errors }) => (
            <>
      <ToastContainer />
      <Modal
        show={show}
        onHide={handleCancel}
        size="lg"
        dialogClassName="modal-dialog modal-dialog-centered"
      >
        <div
          style={{
            borderRadius: "5px",
            boxShadow: "0 0 500px 500px rgba(0, 0, 0, 0.3)",
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>Address Details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
         

    <Form>
      <Row className="py-2 px-4">
        <Col lg={4}>
          <Form.Group>
            <Form.Label>{i18n("Name")}</Form.Label>
            <Form.Control
              autoFocus
              placeholder={placeholderText("Enter Name")}
              name="name"
              value={selectedData?.name}
              onChange={(e) => {
                const data = {
                  fieldName: "name",
                  fieldValue: e.target.value,
                  id: id,
                };
                dispatch(updateBillingAddress(data));
              }}
            />
          </Form.Group>
        </Col>
        <Col className="px-4" lg={4}>
          <Form.Group>
            <Form.Label>{i18n("Mobile number")}</Form.Label>
            <Form.Control
              placeholder={placeholderText("Enter Number")}
              name="phone_number"
              value={selectedData?.phone_number}
              pattern="[0-9]*"
              minLength={10}
              maxLength={10}
              onBlur={handleBlur}
              isInvalid={touched.phone_number && errors.phone_number}
              onKeyPress={(event) => numValidate(event)}
              onChange={(e) => {
                handleChange(e);
                const data = {
                  fieldName: "phone_number",
                  fieldValue: e.target.value,
                  id: id,
                };
                dispatch(updateBillingAddress(data));
              }}
            />
            <ErrorMessage
            name="phone_number"
            component="span"
            className="text-danger d-block fw-400 fs-small mt-2"
          />
          </Form.Group>
        </Col>
        <Col className="px-4" lg={4}>
          <Form.Group>
            <Form.Label>{i18n("Label")}</Form.Label>
            <Form.Control
              placeholder={placeholderText("Enter Label")}
              name="label"
              value={selectedData?.label}
              onChange={(e) => {
                const data = {
                  fieldName: "label",
                  fieldValue: e.target.value,
                  id: id,
                };
                dispatch(updateBillingAddress(data));
              }}            />
          </Form.Group>
        </Col>
      </Row>
      <div className="Address-Section">
      <BillingAddress
        click={click}
        handleChange={handleChange}
        i18n={i18n}
        placeholderText={placeholderText}
        values={selectedData}
        editParty={editParty}
        setFieldValue={setFieldValue}
        id={id}
      />
      </div>
    </Form>

          </Modal.Body>
          <Modal.Footer>
            <Form.Group className="px-4 d-flex align-items-center me-auto">
              <Form.Check
                type="checkbox"
                id="status"
                name="status"
                checked={selectedData?.status || false}
                onChange={() => {
                  if (selectedData?.status) {
                    dispatch(updateAddressStatus(null));
                  } else {
                    dispatch(updateAddressStatus(id));
                  }
                  dispatch(
                    updateBillingAddress({
                      fieldName: "status",
                      fieldValue: !selectedData?.status,
                      id: id,
                    })
                  );
                }}
                className="me-2"
              />
              <Form.Label
                htmlFor="status"
                className="me-2 mb-0"
                style={{ fontWeight: "normal", cursor: "pointer" }}
              >
                {i18n("Mark as Default")}
              </Form.Label>
            </Form.Group>
            <Button variant="secondary" onClick={handleCancel}>
              Close
            </Button>
            <Button 
            id="saveButton" variant="primary" onClick={() => handleSave(validateForm)}>
              Save
            </Button>
          </Modal.Footer>
        </div>
      </Modal>
    </>
    )}
    </Formik>
  );
};

export default Address;
