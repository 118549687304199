import {
    createSlice,
    createAsyncThunk,
    createEntityAdapter,
    createSelector
      } from '@reduxjs/toolkit';
import { i18n } from '../../shared/helpers/sharedMethods';
import { axiosRequest } from "../../shared/helpers/axiosHelper";

const latestTransactionAdapter = createEntityAdapter();

const initialState = latestTransactionAdapter.getInitialState({
  status:false,
  reportItems:{},
  error:false,
  errorMessage:{}
});

export const fetchLatestTransaction = createAsyncThunk('latestTransaction/fetch', async (args) => {
  try{
    const token = args.token;
    const tenantId = args.tenant_id;
    console.log('args oof lt',args);
    const latestTransactionResponse = await axiosRequest({
      method: "GET",
      url: `latest-transactions`,
      headers: {
          Authorization: `Bearer ${token}`,
          "X-Tenant": tenantId,
      },
  });
  console.log('latestTransactionResponse',latestTransactionResponse);
    return latestTransactionResponse?.data;
  }catch(err){
    await window.log.logToFile({message: "Error while fetching latest transaction", level: "error" });
    throw new Error("Error while fetching lastest transaction report")
  }
});


const latestTransactionSlice = createSlice({
    name: 'latestTransaction',
    initialState,
    reducers: {
        resetDashboard : () => initialState,
    },
    extraReducers(builder) {
        builder
        .addCase(fetchLatestTransaction.fulfilled, (state, action) => {
          state.status = true
          state.reportItems =action.payload
        })
        .addCase(fetchLatestTransaction.rejected, (state, action) => {
            state.error = true; 
            state.errorMessage=(i18n("Error while fetching latest transaction report. Please try again later."));
            state.status = false;
        })
      
    }
});

export default latestTransactionSlice.reducer;

export const {
    selectAll: selectAllLatestTransaction,
    selectById: selectLatestTransactionById,
    selectIds: selectLatestTransactionIds
} = latestTransactionAdapter.getSelectors((state) => state.latestTransaction);

export const { resetDashboard } = latestTransactionSlice.actions;
export const selectvoucherByParty = createSelector(
    
    [selectAllLatestTransaction, (state, party_id) => party_id],
    (vouchers, party_id) => vouchers.filter(voucher => voucher.party_id === party_id)
  );
