import { Form } from "react-bootstrap";
import React from "react";
import { placeholderText } from "../helpers/sharedMethods";


const ReportPeriod = (props) => {
   
    const {reportPeriod,setReportPeriod} = props;
    return (
        <Form.Select
        className=" border "
        value={reportPeriod}
        onChange={(e) => setReportPeriod(e.target.value)}
        style={{ cursor: "pointer",backgroundColor:'white',borderRadius:1 ,color:'GrayText' }}
      >
        <option value="today">{placeholderText("Today")}</option>
        <option value="yesterday">{placeholderText("Yesterday")}</option>
        <option value="current-week">{placeholderText("This Week")}</option>
        <option value="previous-week">{placeholderText("Last Week")}</option>
        <option value="current-month">{placeholderText("This Month")}</option>
        <option value="previous-month">{placeholderText("Last Month")}</option>
        <option value="current-year">{placeholderText("This Year")}</option>
        <option value="previous-year">{placeholderText("Last Year")}</option>
      </Form.Select>
    )

}

export default ReportPeriod;