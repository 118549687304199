import React from "react";
import { format } from "date-fns";
import { useSelector } from "react-redux";
import { selectSalesById } from "../../../voucher/store/voucherSlice";
import { selectSelectedImage } from "../../../settings/store/settingSlice";
import { Container, Row, Col, Table, Form, Image } from "react-bootstrap";
import "../css/invoice.css";
import { ToWords } from "to-words";
import {
  calculateTotalQuantity,
  calculateTotalTaxAmount,
  calculateTaxAmountWithSameTaxRate,
  calculateTotalTaxableAmount,
  calculateGrandTotalTax,
} from "../../helpers/invoiceHelper";
import logo from "../../../../assets/images/bill.png";
import { i18n } from "../../helpers/sharedMethods";
import { QRCode } from "react-qr-code";
import { placeOfSupplyList } from "../../config/placeOfSupply";

const StyleTwo = ({ pdf, voucherId }) => {
  const selectImage = useSelector(selectSelectedImage);
  const selectedBusiness = useSelector(
    (state) => state?.auth?.selectedBusiness
  );
  const currencySymbol = useSelector(
    (state) => state.getSetting?.dataSetting.currency
  );
  ;

  const termsAndCondition = useSelector(
    (state) => state.setting?.billing_settings?.terms_and_conditions
  );
  ;
  const BankDetails = useSelector((state) => state.setting?.billing_settings);
  const fetchData = useSelector((state) => state.getSetting?.dataSetting);
  ;
  const UPIPayment = useSelector(
    (state) => state.setting?.billing_settings?.upi_payment
  );
  const bankDetailsCheck =
    fetchData?.card_holder_name ||
    fetchData?.account_number ||
    fetchData?.ifsc_code ||
    fetchData?.bank ||
    fetchData?.branch_name;

  const generateQRCode = (UPIPayment) => {
    const upiLink = `upi://pay?pa=${UPIPayment}`;
    ;
    return (
      <QRCode
        value={upiLink}
        size={90}
        bgColor={"#ffffff"}
        fgColor={"#000000"}
        level={"L"}
      />
    );
  };

  const state = placeOfSupplyList.find(
    (option) => option.value == fetchData?.state
  );

  return (
    <>
      <Container
        className="pb-5"
        fluid
        style={{ backgroundColor: "white" }}
        ref={pdf}
      >
        <Row>
          <Col>
            <Row>
              <Col className="border-bottom p-2 m-0">
                <p style={{ fontWeight: "bolder" }}>
                  {i18n("TAX INVOICE")}
                  <span
                    className="border border-secondary px-2 mx-2"
                    style={{ fontWeight: 500 }}
                  >
                    {i18n("ORIGINAL FOR RECIPIENT")}
                  </span>
                </p>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col lg={6} className="border-left border-bottom p-2 m-0">
            <Row>
              <Col lg={4} className="d-flex align-items-center justify-content-center">
                <img
                  src={selectImage ? selectImage : logo}
                  width={100}
                  height={80}
                  alt="Logo"
                  style={{
                    objectFit: "contain",
                    maxWidth: "100%",
                    height: "auto",
                  }}
                />
              </Col>
              <Col lg={8}>
                <Row className="mt-3">
                  <Row>
                    <Col lg={12}>
                      <Form.Label
                        className=""
                        style={{ fontSize: "22px", fontWeight: 600 }}
                      >
                        {fetchData?.business_name}
                      </Form.Label>
                      <Form.Group>
                        <div className="mb-0">
                          {fetchData?.business_address},&nbsp; {fetchData?.city}
                          ,&nbsp; {state?.label}&nbsp;-&nbsp;{" "}
                          {fetchData?.pincode}
                        </div>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    {fetchData?.business_phone && (
                      <Col lg={12}>
                        <Form.Label className="mb-0">
                          {i18n("Phone")} :{" "}
                        </Form.Label>{" "}
                        &nbsp;{" "}
                        <Form.Label className="mb-0">
                          {" "}
                          {fetchData?.business_phone
                            ? fetchData?.business_phone
                            : ""}
                        </Form.Label>
                      </Col>
                    )}
                  </Row>
                  <Row>
                    {fetchData?.gstin && (
                      <Col lg={12}>
                        <Form.Label className="mb-0">
                          {i18n("GSTIN")} :
                        </Form.Label>{" "}
                        <Form.Label className="mb-0">
                          {" "}
                          &nbsp; {fetchData?.gstin ? fetchData?.gstin : ""}
                        </Form.Label>
                      </Col>
                    )}
                    {fetchData?.pan && (
                      <Col lg={12}>
                        <Form.Label className="mb-0">
                          {i18n("PAN Number")} :{" "}
                        </Form.Label>{" "}
                        &nbsp; {fetchData?.pan ? fetchData?.pan : ""}
                      </Col>
                    )}
                  </Row>
                </Row>
              </Col>
            </Row>
          </Col>
          <Col
            lg={6}
            className="border-right border-left border-bottom p-2 m-0"
          >
            <Row className="mt-3" style={{ fontSize: "14px" }}>
              <Col lg={4} style={{ flex: 1 }}>
                <Form.Label
                  className="mb-0"
                  style={{ fontSize: "13px", fontWeight: 600 }}
                >
                  {i18n("Invoice No")}
                </Form.Label>
              </Col>
              <Col lg={4} style={{ flex: 1 }}>
                <Form.Label
                  className="mb-0"
                  style={{ fontSize: "13px", fontWeight: 600 }}
                >
                  {i18n("Invoice Date")}
                </Form.Label>
              </Col>

              <Col lg={4} style={{ flex: 1 }}>
                <Form.Label
                  className="mb-0"
                  style={{ fontSize: "13px", fontWeight: 600 }}
                >
                  {i18n("Due Date")}
                </Form.Label>
              </Col>
            </Row>

            <Row>
              <Col lg={4} style={{ flex: 1 }}>
                1234
              </Col>
              <Col lg={4} style={{ flex: 1 }}>
                09-02-2024
              </Col>

              <Col lg={4} style={{ flex: 1 }}>
                09-03-2024
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col lg={6} className="border-left p-2 m-0">
            <Row>
              <Col
                style={{
                  fontSize: "13px",
                  fontWeight: "500",
                  marginBottom: "5px",
                }}
              >
                {i18n("BILL TO")}
              </Col>
            </Row>
            <Row>
              <Col style={{ fontWeight: "600", fontSize: "14px" }}>
              {i18n("Sample Party")}
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group>
                  <Form.Label className="mb-0">
                    144,East street,saravanampatty,coimbatore,Tamil Nadu -
                    641035
                  </Form.Label>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group>
                  <Form.Label className="mb-0">{i18n("Phone")} :</Form.Label>
                  &nbsp; 9789524432
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="">
                  <Form.Label className="mb-0">{i18n("GSTIN")} :</Form.Label>
                  &nbsp;29GGGGG1314R9Z6
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group>
                  <Form.Label className="mb-0">
                    {i18n("PAN Number")} :
                  </Form.Label>
                  &nbsp; ABCTY1234D
                </Form.Group>
              </Col>
            </Row>
          </Col>
          <Col lg={6} className="border-right border-left p-2 m-0">
            <Row>
              <Col
                style={{
                  fontSize: "13px",
                  fontWeight: "500",
                  marginBottom: "5px",
                }}
              >
                {i18n("SHIP TO")}
              </Col>
            </Row>
            <Row>
              <Col style={{ fontWeight: "600", fontSize: "14px" }}>
              {i18n("Sample Party")}
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group>
                  <Form.Label className="mb-0">
                    144,East street,saravanampatty,coimbatore,Tamil Nadu -
                    641035
                  </Form.Label>
                </Form.Group>
              </Col>
            </Row>
            <Row>
  <Col>
    <Form.Group>
      <Form.Label className="mb-0">
        {i18n("Phone")} : 9753424432
      </Form.Label>
    </Form.Group>
  </Col>
</Row>
          </Col>
        </Row>
        <Row>
          <Col className="border-col p-0 m-0">
            <Table className="table table-borderless">
              <thead>
                <tr style={{ textAlign: "center" }}>
                  <th className="table-border-head">{i18n("S.No")}</th>
                  <th className="table-border-head">{i18n("ITEMS")}</th>
                  <th className="table-border-head">{i18n("HSN")}</th>
                  <th className="table-border-head">{i18n("QTY")}</th>
                  <th className="table-border-head">{i18n("RATE")}</th>

                  <th className="table-border-head">{i18n("DISC")}</th>

                  <th className="table-border-last">{i18n("AMOUNT")}</th>
                </tr>
              </thead>
              <tbody>
                <tr style={{ textAlign: "center" }}>
                  <td className="border-black">1</td>
                  <td className="border-black">Monitor</td>
                  <td className="border-black">12345</td>
                  <td className="border-black">20 PCS</td>
                  <td className="border-black">{currencySymbol} 10.00</td>
                  <td className="border-black">{currencySymbol} 100.00</td>
                  <td>{currencySymbol} 1,145.00</td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <td className="border-black"></td>
                  <td className="border-black"></td>
                  <td className="border-black"></td>
                  <td className="border-black"></td>
                  <td className="border-black"></td>
                  <td className="border-black"></td>
                  <td></td>
                </tr>
                <tr>
                  <td className="border-black">&nbsp;</td>
                  <td className="border-black"></td>
                  <td className="border-black"></td>
                  <td className="border-black"></td>
                  <td className="border-black"></td>
                  <td className="border-black"></td>
                  <td></td>
                </tr>
                <tr>
                  <td className="border-black">&nbsp;</td>
                  <td className="border-black"></td>
                  <td className="border-black"></td>
                  <td className="border-black"></td>
                  <td className="border-black"></td>
                  <td className="border-black"></td>
                  <td></td>
                </tr>
                <tr>
                  <td className="border-black">&nbsp;</td>
                  <td className="border-black"></td>
                  <td className="border-black"></td>
                  <td className="border-black"></td>
                  <td className="border-black"></td>
                  <td className="border-black"></td>
                  <td></td>
                </tr>
                <tr>
                  <td className="border-black">&nbsp;</td>
                  <td className="border-black"></td>
                  <td className="border-black"></td>
                  <td className="border-black"></td>
                  <td className="border-black"></td>
                  <td className="border-black"></td>
                  <td></td>
                </tr>

                <tr>
                  <td className="border-black px-1"></td>
                  <td
                    className="border-black bold px-3"
                    align="right"
                    style={{ fontSize: "13px" }}
                  >
                    <i></i>
                  </td>
                  <td className="border-black"></td>
                  <td className="border-black"></td>
                  <td className="border-black"></td>

                  <td className="border-black"></td>

                  <td></td>
                </tr>

                <React.Fragment>
                  <tr>
                    <td className="border-black">&nbsp;</td>
                    <td align="right" className="border-black px-3">
                      <i>{i18n("CGST")} @1.5%</i>
                    </td>
                    <td className="border-black"></td>
                    <td className="border-black"></td>
                    <td className="border-black"></td>

                    <td className="border-black"></td>

                    <td style={{ textAlign: "center" }}>
                      {currencySymbol} 22.5
                    </td>
                  </tr>
                  <tr>
                    <td className="border-black">&nbsp;</td>
                    <td align="right" className="border-black px-3">
                      <i>{i18n("SGST")} @%1.5%</i>
                    </td>
                    <td className="border-black"></td>
                    <td className="border-black"></td>
                    <td className="border-black"></td>

                    <td className="border-black">-</td>

                    <td style={{ textAlign: "center" }}>
                      {currencySymbol} 22.5
                    </td>
                  </tr>
                </React.Fragment>

                <tr>
                  <td
                    className="table-border-head"
                    style={{ background: "#F8F9FA" }}
                  ></td>
                  <td
                    align="right"
                    className="table-border-head bold px-3"
                    style={{ background: "#F8F9FA" }}
                  >
                    {i18n("TOTAL")}
                  </td>
                  <td
                    className="table-border-head"
                    style={{ background: "#F8F9FA" }}
                  ></td>
                  <td
                    className="table-border-head bold"
                    style={{ background: "#F8F9FA", textAlign: "center" }}
                  >
                    1
                  </td>
                  <td
                    className="table-border-head"
                    style={{ background: "#F8F9FA", textAlign: "center" }}
                  >
                   {currencySymbol} 10.00
                  </td>

                  <td
                    className="table-border-head"
                    style={{ background: "#F8F9FA", textAlign: "center" }}
                  >
                   {currencySymbol} 100.00
                  </td>

                  <td
                    className="table-border-last bold"
                    style={{ background: "#F8F9FA", textAlign: "center" }}
                  >
                    {currencySymbol}&nbsp; 1145.00
                  </td>
                </tr>
              </tfoot>
            </Table>
          </Col>
        </Row>
        <Row>
          <Col className="border-col p-0 m-0">
            <Table className="table table-borderless mt-2">
              <thead>
                <tr align="center">
                  <th className="table-border-head" rowSpan="2">
                    {i18n("HSN/SAC")}
                  </th>
                  <th className="table-border-head" rowSpan="2">
                    {i18n("TAXABLE VALUE")}
                  </th>
                  <th className="table-border-head" colSpan="2">
                    {i18n("CGST")}
                  </th>
                  <th className="table-border-head" colSpan="2">
                    {i18n("SGST")}
                  </th>
                  <th className="table-border-last" rowSpan="2">
                    {i18n("TOTAL TAX AMOUNT")}
                  </th>
                </tr>
                <tr align="center">
                  <th className="border-tax">{i18n("RATE")}</th>
                  <th className="border-tax">{i18n("AMOUNT")}</th>
                  <th className="border-tax">{i18n("RATE")}</th>
                  <th className="border-tax">{i18n("AMOUNT")}</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  align="right"
                  className="p-2"
                  style={{ borderBottom: "1px solid black", textAlign: 'center' }}
                >
                  <td className="border-tax">{"1234"}</td>
                  <td className="border-tax">{currencySymbol} {"1,000.00"}</td>
                  <td className="border-tax">{"1.5%"}</td>
                  <td className="border-tax">{currencySymbol} {"22.5"}</td>
                  <td className="border-tax">{"1.5%"}</td>
                  <td className="border-tax">{currencySymbol} {"22.5"}</td>
                  <td className="border-bottom">
                    {currencySymbol} {"45.00"}
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <tr
                  align="right"
                  className="p-2"
                  style={{ borderBottom: "1px solid black", textAlign: 'center' }}
                >
                  <td className="border-for-received bold">{i18n("Total")}</td>
                  <td className="border-for-received"></td>
                  <td className="border-for-received"> </td>{" "}
                  <td className="border-for-received">{currencySymbol} 22.5</td>
                  <td className="border-for-received"> </td>
                  <td className="border-for-received">{currencySymbol} 22.5</td>
                  <td
                    className="border-bottom bold"
                    style={{ textAlign: "center" }}
                  >
                    {currencySymbol} 45.00
                  </td>
                </tr>
              </tfoot>
            </Table>
          </Col>
        </Row>
        <Row>
          <Col lg={12} className="border-col border-bottom p-2 m-0">
            <b>{i18n("TOTAL AMOUNT (IN WORDS)")}</b> <br />
            {i18n("ONE THOUSAND ONE HUNDRED AND FIVE RUPEES ONLY")}
          </Col>
        </Row>
        <Row
          style={{
            borderRight: "1px solid black",
            borderBottom:
              fetchData?.upi_payment || bankDetailsCheck
                ? "1px solid black"
                : "",
            borderLeft: "1px solid black",
          }}
        >
          {bankDetailsCheck && (
            <Col lg={6} className=" p-2 mb-0">
              <Form.Group>
                <Form.Label
                  className=""
                  style={{ fontSize: "13px", fontWeight: 600 }}
                >
                  {i18n("BANK DETAILS")}
                </Form.Label>{" "}
                <br />
                {fetchData?.card_holder_name && (
                  <Form.Label className="mb-1">
                    {i18n("Name")}: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{fetchData?.card_holder_name}{" "}
                  </Form.Label>
                )}
                <br />
                {fetchData?.ifsc_code && (
                  <Form.Label className="mb-1">
                    {i18n("IFSC Code")}: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{fetchData?.ifsc_code}{" "}
                  </Form.Label>
                )}
                <br />
                {fetchData?.account_number && (
                  <Form.Label className="mb-1">
                    {i18n("Account No")}: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{fetchData?.account_number}{" "}
                  </Form.Label>
                )}
                <br />
                {fetchData?.bank || fetchData?.branch_name ? (
                  <Form.Label className="mb-1">
                    {i18n("Bank")}: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{fetchData?.bank}, {fetchData?.branch_name}{" "}
                  </Form.Label>
                ) : ''}
              </Form.Group>
            </Col>
          )}

          {fetchData?.upi_payment && (
            <Col
              lg={6}
              className=" p-2 mb-0"
              style={{
                borderLeft: bankDetailsCheck ? "1px solid black" : "",
              }}
            >
              <Row>
                <Col lg={6} className="pr-lg-2">
                  <Form.Group>
                    <Form.Label style={{ fontSize: "13px", fontWeight: 600 }}>
                      {i18n("PAYMENT QR CODE")}
                    </Form.Label>
                    <p>
                      {i18n("UPI ID")}:<br />
                      {fetchData?.upi_payment}
                    </p>
                  </Form.Group>
                </Col>
                <Col lg={6} className="pl-lg-2 px-16">
                  {fetchData?.upi_payment &&
                    generateQRCode(fetchData?.upi_payment)}
                </Col>
              </Row>
            </Col>
          )}
        </Row>
        <Row
          className=""
          style={{
            borderBottom: "1px solid black",
            borderLeft: "1px solid black",
            borderRight: "1px solid black",
          }}
        >
          {termsAndCondition && (
            <Col lg={6} className=" p-2">
              <Form.Label
                className="mb-0"
                style={{ fontSize: "13px", fontWeight: 600 }}
              >
                {i18n("TERMS AND CONDITIONS")}
              </Form.Label>{" "}
              <br />
              <p>{termsAndCondition} </p>
            </Col>
          )}
          <Col
            lg={6}
            className=" p-2"
            style={{
              borderLeft: termsAndCondition ? "1px solid black" : "",
            }}
          >
            <p
              style={{
                fontSize: "13px",
                fontWeight: 600,
                textAlign: termsAndCondition ? "start" : "start",
              }}
            >
              {i18n("Authorized Signatory For")}
              <br />
              <Form.Label className="mb-0" style={{ fontSize: "13px" }}>
                {selectedBusiness?.business_name}
              </Form.Label>
            </p>
            {!fetchData?.signature && (
              <Col lg={11} className="border " style={{ height: "60px" }}></Col>
            )}
            <Col lg={11} className=" d-flex justify-content-start">
              {fetchData?.signature && (
                <Image
                  src={fetchData?.signature}
                  alt="signature"
                  style={{ width: "200px", height: "60px" }}
                />
              )}
            </Col>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default StyleTwo;
