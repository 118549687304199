import React, { useState, useEffect } from "react";
import { Button, Row, Col, Stack, Container ,Image } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Layout from "../../shared/components/Layout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faChevronLeft,
  faDownload,
} from "@fortawesome/free-solid-svg-icons";
import { downloadCsvFile, handleExportClick } from "../../shared/export";
import DataGrid from "../../shared/components/DataGrid";
import { fetchPartyReportByItem } from "../store/partyReportByItemSlice";
import ReportPeriod from "../../shared/components/ReportPeroid";
import ProductFilter from "../components/Filters/ProductFilter";
import CategoryFilter from "../components/Filters/CategoryFilter";
import Header from "../../shared/components/Header";
import { i18n } from "../../shared/helpers/sharedMethods";
import PartyNavbar from "../components/PartyNavbar";
import ShortcutKeys from "../../shared/components/ShortcutKeys";
import ReportDateRange from "../../shared/components/date_range/ReportDateRange";
import DatePicker from "../../shared/components/date_range/DatePicker";
import { CurrentWeekDateRange } from "../../shared/components/DateRangeYAxis";
import ReportHeader from "../components/ReportHeader";
import { Column } from "primereact/column";
import CustomeSortIcon from "../../shared/components/CustomeSortIcon";
import { DataTable } from "primereact/datatable";
import { UilAngleLeft, UilImport } from "@iconscout/react-unicons";
import LoadingSpinner from '../../shared/components/LoadingSpinner';
import GlobalCurrency from "../../shared/components/GlobalCurrency";
import { fetchCategories } from "../../settings/store/categorysSlice";
import { fetchProducts } from "../../catalog/store/productsSlice";




const PartyReportByItem = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const accessToken = useSelector((state) => state?.auth?.accessToken?.access_token);
  const selectedBusiness = useSelector((state) => state?.auth?.selectedBusiness);
  const tenantId = selectedBusiness?.pivot?.tenant_id;
  const [reportPeriod, setReportPeriod] = useState("current-week");
  const [productData, setProductData] = useState("");
  const [categoryData, setCategoryData] = useState("");
  const [csvData, setCsvData] = useState("");
  const [startDate, setStartDate] = useState(null); // State for start date
  const [endDate, setEndDate] = useState(null);
  const [sortField, setSortField] = useState(null);
  const [sortOrder, setSortOrder] = useState(1);
console.log('category',categoryData);

    const handleReset = () => {
      const salesDateLimits = ReportDateRange({
        reportPeriod: reportPeriod,
      });
      setStartDate(salesDateLimits.startDate);
      setEndDate(salesDateLimits.endDate);
      setReportPeriod("current-week");
      setProductData("");
      setCategoryData("");
    };

  const handleGoBack = () => {
    navigate("/dashboard/sales");
  };
  useEffect(() => {
    dispatch(fetchProducts({token: accessToken, tenant_id: selectedBusiness?.pivot?.tenant_id }));
  }, [accessToken, selectedBusiness])
  useEffect(() => {
    dispatch(fetchCategories({ token: accessToken, tenant_id: tenantId }));
}, [accessToken, tenantId]);

  useEffect(() => {
    dispatch(
      fetchPartyReportByItem({ startDate:startDate, endDate:endDate, productData:productData, categoryData:categoryData, token:accessToken,tenant_id:tenantId})); 
  }, [startDate, endDate, productData, categoryData]);

  useEffect(() => {
    const dateLimits = ReportDateRange({ reportPeriod });
    setStartDate(dateLimits.startDate);
    setEndDate(dateLimits.endDate);
  }, [reportPeriod]);

  const reportItems = useSelector(
    (state) => state.partyReportByItem.reportItems
  );
  const status = useSelector((state) => state.partyReportByItem.status);
  const loading = useSelector((state) => state.partyReportByItem.loading);
  const errorMessage = useSelector(
    (state) => state.partyReportByItem.errorMessage
  );
  const currencySymbol  = useSelector((state) => state.getSetting?.dataSetting.currency);
  ;

  const dateTimeString = new Date()
  .toLocaleString()
  .replace(/[-]/g, " ")
  .replace(/:/g, "-");
const fileName = `Part_report_by_item_${dateTimeString}`;

  const handleExportButtonClick = () => {
    const exportData =
      reportItems &&
      Array.isArray(reportItems) &&
      reportItems?.map((val) => ({
        "PARTY NAME": val.PartyName ? val.PartyName : "-",
        "SALES AMOUNT": val.saleAmount ? val.saleAmount : "0",
        "SALES QUANTITY": val?.saleQuantity ? val?.saleQuantity : "0",
        "PURCHASE AMOUNT": val.purchaseAmount ? val.purchaseAmount : "0",
        "PURCHASE QUANTITY": val.purchaseQuantity ? val.purchaseQuantity : "0",
      }));
    handleExportClick(
      exportData,
      setCsvData,
      downloadCsvFile,
      fileName
    );
  };
  const customSort = (field) => {
    setSortField(field);
    setSortOrder(sortOrder === 1 ? -1 : 1);
  };

  const sortedData = [...reportItems].sort((a, b) => {
    if (sortField) {
      const compareValueA = a[sortField];
      const compareValueB = b[sortField];
      if (compareValueA < compareValueB) {
        return sortOrder;
      }
      if (compareValueA > compareValueB) {
        return -sortOrder;
      }
    }
    return 0;
  });
  const headerStyle = {
    backgroundColor: "white",
  };
  const headerCellStyle = {
    fontWeight: "bold",
    fontSize: "11px",
  };
  return (
    <Layout>
      {loading && <LoadingSpinner />}
     <Header
        currentTitle={i18n("Party Report By Item")}
        ItemActions={
          <Stack
            gap={2}
            direction="horizontal"
            className="mt-1 mb-1 float-end ms-auto"
          >
            <ShortcutKeys />
          </Stack>
        }
      />
      <Container fluid className="px-5">
        <div className="border " style={{ background: "white" }}>
            <PartyNavbar />
            <ReportHeader 
            ItemActions={
            <Stack gap={2} direction="horizontal" className="pt-3 px-3 mb-3">
            <Stack direction="horizontal">
              <DatePicker
                startDate={startDate}
                setStartDate={setStartDate}
                endDate={endDate}
                setEndDate={setEndDate}
              /> 
              </Stack>
            <Stack className=" ">
              <ReportPeriod
                reportPeriod={reportPeriod}
                setReportPeriod={setReportPeriod}
              />
            </Stack>
            <Stack className=" ">
              <ProductFilter
                productData={productData}
                setProductData={setProductData}
              />
            </Stack>
            <Stack className=" ">
              <CategoryFilter
                categoryData={categoryData}
                setCategoryData={setCategoryData}
                title="party"
              />
            </Stack>
            <Stack
                  gap={1}
                  direction="horizontal"
                  style={{ marginLeft: "1px" }}
                >
                  <Button
                    className="rounded-0"
                    variant="outline-danger"
                    onClick={handleReset}
                  >
                      {i18n("Reset")}
                  </Button>
                </Stack>
                <Button
                  className="fw-bolder d-flex align-items-center rounded-0"
                  onClick={handleExportButtonClick}
                >
                  <UilImport
                    size="18"
                    className="mx-2"
                    style={{ cursor: "pointer" }}
                  />
                  {i18n("Export")}
                </Button>
                </Stack>
                }
             />
              {/* {status ? (
                <DataGrid
                  allData={reportItems}
                  data={reportItems}
                  columns={gridColumns}
                  isPaginated={true}
                  title="Report"
                />
              ) : (
                <p className="d-flex justify-content-center p-2 fs-3 fw-bolder">
                  {i18n("Error while fetching Party report by Item")}
                </p>
              )} */}

    <Container fluid className="px-3 mb-4">
      <div className="bordered-container">
              {status && reportItems ? (
                <DataTable
                  value={sortedData}
                  headerStyle={headerStyle}
                  paginator
                  rows={10}
                  dataKey="id"
                  emptyMessage={i18n("No Records Found.")}
                  className="p-datatable-gridlines full-width-table custom-datatable p-component"
                  scrollable
                  scrollHeight="400px"
                  sortMode="multiple"
                  removableSort
                >
                  <Column
                    field="PartyName"
                    header={() => (
                      <div
                        className={`custom-header-${sortField === "PartyName"
                            ? sortOrder === 1
                              ? "up"
                              : "down"
                            : ""
                          }`}
                        onClick={() => customSort("PartyName")}
                        style={{
                          cursor: "pointer",
                          display: "flex",
                          alignItems: "center",
                          fontSize: "11px",
                          lineHeight: "11px",
                        }}
                      >
                        {i18n("Party Name")}
                        <CustomeSortIcon
                          sortOrder={sortField === "partyName" ? sortOrder : 0}
                          style={{ marginLeft: "4px", verticalAlign: "middle" }}
                        />
                      </div>
                    )}
                    sortable
                    headerStyle={headerCellStyle}
                  />

<Column
  field="purchaseAmount"
  header={() => (
    <div
      className={`custom-header-${sortField === "purchaseAmount" ? (sortOrder === 1 ? "up" : "down") : ""}`}
      onClick={() => customSort("purchaseAmount")}
      style={{
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        fontSize: "11px",
        lineHeight: "11px",
      }}
    >
      {i18n("PURCHASE AMOUNT")}
      <CustomeSortIcon
        sortOrder={sortField === "purchaseAmount" ? sortOrder : 0}
        style={{ marginLeft: "4px", verticalAlign: "middle" }}
      />
    </div>
  )}
  body={(row) => (
<span>
  {row.purchaseAmount < 0 ? "-" : ""}
  <GlobalCurrency price={Math.abs(row.purchaseAmount || 0)} />
</span>
  )}
  sortable
  headerStyle={headerCellStyle}
/>

<Column
  field="purchaseQuantity"
  header={() => (
    <div
      className={`custom-header-${sortField === "purchaseQuantity" ? (sortOrder === 1 ? "up" : "down") : ""}`}
      onClick={() => customSort("purchaseQuantity")}
      style={{
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        fontSize: "11px",
        lineHeight: "11px",
      }}
    >
      {i18n("PURCHASE QUANTITY")}
      <CustomeSortIcon
        sortOrder={sortField === "purchaseQuantity" ? sortOrder : 0}
        style={{ marginLeft: "4px", verticalAlign: "middle" }}
      />
    </div>
  )}
  body={(row) => (
    row.purchaseQuantity && row.purchaseUnit ? (
      <span>{row.purchaseQuantity} {row.purchaseUnit}</span>
    ) : row.purchaseQuantity ? (
      <span>{row.purchaseQuantity}</span>
    ) : (
      "-"
    )
  )}
  sortable
  headerStyle={headerCellStyle}
/>

<Column
  field="saleAmount"
  header={() => (
    <div
      className={`custom-header-${sortField === "saleAmount" ? (sortOrder === 1 ? "up" : "down") : ""}`}
      onClick={() => customSort("saleAmount")}
      style={{
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        fontSize: "11px",
        lineHeight: "11px",
      }}
    >
      {i18n("SALES AMOUNT")}
      <CustomeSortIcon
        sortOrder={sortField === "saleAmount" ? sortOrder : 0}
        style={{ marginLeft: "4px", verticalAlign: "middle" }}
      />
    </div>
  )}
  body={(row) => (
    <span>
      {row.saleAmount < 0 ? "-" : ""}
      <GlobalCurrency price={Math.abs(row.saleAmount || 0)} />
    </span>
      )}
  sortable
  headerStyle={headerCellStyle}
/>

<Column
  field="saleQuantity"
  header={() => (
    <div
      className={`custom-header-${sortField === "saleQuantity" ? (sortOrder === 1 ? "up" : "down") : ""}`}
      onClick={() => customSort("saleQuantity")}
      style={{
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        fontSize: "11px",
        lineHeight: "11px",
      }}
    >
      {i18n("SALES QUANTITY")}
      <CustomeSortIcon
        sortOrder={sortField === "saleQuantity" ? sortOrder : 0}
        style={{ marginLeft: "4px", verticalAlign: "middle" }}
      />
    </div>
  )}
  body={(row) => (
    row.saleQuantity && row.saleUnit ? (
      <span>{row.saleQuantity} {row.saleUnit}</span>
    ) : row.saleQuantity ? (
      <span>{row.saleQuantity}</span>
    ) : (
      "-"
    )
  )}
  sortable
  headerStyle={headerCellStyle}
/>  </DataTable>
              ) : (
                <p>{i18n("Loading")}</p>
              )}
            </div>
            </Container>
            </div>
      </Container>
    </Layout>
  );
};

export default PartyReportByItem;
