import {
    createSlice,
    createAsyncThunk,
  } from "@reduxjs/toolkit";
import { i18n } from "../../shared/helpers/sharedMethods";

const initialState = {
  status:false,
  reportItems:[],
  error:false,
  errorMessage:''
};

export const fetchPartySalesChart = createAsyncThunk("partySalesChart/fetch",
  async (param) => {
    try{
        const partySalesChartResponse = await window.api.getPartySalesChart(param);
        return partySalesChartResponse.data;
    }catch(err){
      await window.log.logToFile({message: "Error while fetching Party Sales chart", level: "error" });
      throw new Error("Error while fetching party sales total")
    }
  }
);

const partySalesChartSlice = createSlice({
    name: "partySalesChart",
    initialState,
    reducers: {},
    extraReducers(builder) {
      builder
        .addCase(fetchPartySalesChart.fulfilled, (state, action) => {
          state.status = true;
          state.reportItems = action.payload; 
        })
        .addCase(fetchPartySalesChart.rejected, (state, action) => {
          state.error = true; 
          state.errorMessage = (i18n("Error while fetching party sales. Please try again later."));
          state.status = false;
        });
    },
    
});

export default partySalesChartSlice.reducer;
