import React, { useEffect, useReducer, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Container,
  Col,
  Row,
  Form,
  Image,
  Card,
  Stack,
  Button,
  Spinner,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import ModelFooter from "../../shared/components/modelFooter";
import { invoiceSelection } from "../store/settingSlice";
import Layout from "../../shared/components/Layout";
import { toast, ToastContainer } from "react-toastify";
import InvoiceThemes from "../../shared/components/invoiceLayouts/invoice";
import ThemeOne from "../../shared/components/invoiceLayouts/invoice1";
import ThemeTwo from "../../shared/components/invoiceLayouts/invoice2";
import NavbarSetting from "./NavbarSettings";
import Header from "../../shared/components/Header";
import { i18n } from "../../shared/helpers/sharedMethods";
import ShortcutKeys from "../../shared/components/ShortcutKeys";
import { fetchSetting } from "../store/getSettingSlice";
import SettingsLayout from "./SettingsLayout";
import LoadingSpinner from '../../shared/components/LoadingSpinner';
const InvoiceTheme = () => {
  const dispatches = useDispatch();
  const navigate = useNavigate();


  const invoicesSetting = useSelector((state) => state.setting?.invoicesType);
  ;

  const selectedBusiness = useSelector(
    (state) => state?.auth?.selectedBusiness
  );
  ;

  const { accessToken } = useSelector((state) => state?.auth);
  const status = useSelector((state) => state.setting.status);
  const loading = useSelector((state) => state.getSetting.loading)
  const isOnline = navigator.onLine;
  
  useEffect(() => {
    dispatches(
      fetchSetting({
        accessToken: accessToken.access_token,
        tenant_id: selectedBusiness?.pivot?.tenant_id,
      })
    );
  }, []);

  let initialState;

  switch (true) {
    case invoicesSetting?.invoice_1 === true:
      initialState = {
        invoice_1: true,
        invoice_2: false,
        invoice_3: false,
        key: 1,
      };
      break;
    case invoicesSetting?.invoice_2 === true:
      initialState = {
        invoice_1: false,
        invoice_2: true,
        invoice_3: false,
        key: 2,
      };
      break;
    case invoicesSetting?.invoice_3 === true:
      initialState = {
        invoice_1: false,
        invoice_2: false,
        invoice_3: true,
        key: 3,
      };
      break;
    default:
      initialState = {
        invoice_1: true,
        invoice_2: false,
        invoice_3: false,
        key: 1,
      };
      break;
  }

  useEffect(() => {
    dispatches(invoiceSelection(initialState));
  }, []);

  const reducer = (state, action) => {
    switch (action.type) {
      case "invoice_1":
        return {
          ...state,
          invoice_1: true,
          invoice_2: false,
          invoice_3: false,
          key: 1,
        };
      case "invoice_2":
        return {
          ...state,
          invoice_1: false,
          invoice_2: true,
          invoice_3: false,
          key: 2,
        };
      case "invoice_3":
        return {
          ...state,
          invoice_1: false,
          invoice_2: false,
          invoice_3: true,
          key: 3,
        };
      default:
        return state;
    }
  };

  const [state, dispatch] = useReducer(reducer, initialState);
  ;

  const handleInvoiceChange = (invoice) => {
    dispatch({ type: invoice });
  };

  const onSubmit = () => {
    ;
    if (!isOnline) {
      toast.error(i18n("You are Offline. Please Connect to the Internet"));
      return;
    }
    dispatches(invoiceSelection(state));
    toast.success(i18n("Settings Saved Successfully"));
  };

  const handleCancel = () => {
    navigate("/dashboard/sales");
  };
  return (
    <>
      <ToastContainer />
      <SettingsLayout title="Invoice Themes"
        onSave={onSubmit}
        onCancel={handleCancel}>
           {loading && <LoadingSpinner />}

        <Row>
          <Col
            lg={8}
            style={{
              height: "85vh",
              overflow: "auto",
              overflowX: "hidden",
            }}
          >
            {state.invoice_1 && <ThemeOne />}
            {state.invoice_2 && <InvoiceThemes />}
            {state.invoice_3 && <ThemeTwo />}
          </Col>
          <Col lg={4}>
            <Row className="p-1">
              <Col lg={4}>
                <Form.Group className="text-center ">
                  <div style={{ fontSize: "12px", fontWeight: 600 }}>
                    {i18n("Default")}
                  </div>
                  <Image
                    className=""
                    onClick={() => {
                      handleInvoiceChange("invoice_1");
                    }}
                    style={{
                      height: "100px",
                      width: "100px",
                      border: state.invoice_1 && "2px solid blue",
                    }}
                    src={require("../../../assets/images/general.png")}
                    thumbnail
                  />
                </Form.Group>
              </Col>
              <Col lg={4}>
                <Form.Group className="text-center">
                  <div style={{ fontSize: "12px", fontWeight: 600 }}>
                    {i18n("Stylish")}
                  </div>
                  <Image
                    className=""
                    onClick={() => {
                      handleInvoiceChange("invoice_2");
                    }}
                    style={{
                      height: "100px",
                      width: "100px",
                      border: state.invoice_2 && "2px solid blue",
                    }}
                    src={require("../../../assets/images/outline.png")}
                    thumbnail
                  />
                </Form.Group>
              </Col>
              <Col lg={4}>
                <Form.Group className="text-center">
                  <div style={{ fontSize: "12px", fontWeight: 600 }}>
                    {i18n("GST")}
                  </div>
                  <Image
                    className=""
                    onClick={() => {
                      handleInvoiceChange("invoice_3");
                    }}
                    style={{
                      height: "100px",
                      width: "100px",
                      border: state.invoice_3 && "2px solid blue",
                    }}
                    src={require("../../../assets/images/advance.png")}
                    thumbnail
                  />
                </Form.Group>
              </Col>
            </Row>
          </Col>
        </Row>
      </SettingsLayout>
    </>
  );
};

export default InvoiceTheme;
