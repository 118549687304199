import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import SettingsLayout from "./SettingsLayout";
import {  Button, Col, Container, Form, Row, Stack } from "react-bootstrap";
import { i18n } from "../../shared/helpers/sharedMethods";
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { useDispatch } from "react-redux";
import { logout } from "../../auth/store/authSlice";

const AdvanceSettings = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [clearDataStatus, setClearDataStatus] = useState(false);

    const handleCancel = () => {
        navigate('/dashboard/sales')
    }

    useEffect(() => {
        if(clearDataStatus){
            dispatch(logout());
        }
    },[clearDataStatus])

   
    const accept = async() => {
       
        const response =  await window.config.clearAllData();
        if(response.success){
            setClearDataStatus(true);
        }
    }

    const confirm = (position) => {
        confirmDialog({
            message: 'Do you want to clear all data and logout?',
            header: 'Clear Data',
            icon: 'pi pi-info-circle',            
            acceptClassName: 'p-button-danger',
            className:'p-danger',
            position,
            accept
        });
    };

    return (
        <SettingsLayout title="Advance Settings"
            onCancel={handleCancel}>
                <ConfirmDialog acceptClassName="danger"/>

            <Container fluid className="p-5">
                
                <Row>
                    <Col md={2} className="mt-3">
                        <Stack direction="vertical" gap={2}>
                            <Form.Label className="bold">{i18n("Clear Data")}</Form.Label>
                          
                            <Button variant="outline-danger" onClick={() => confirm('Are you sure? This will clear all data.')}> Clear</Button>
                        </Stack>
                    </Col>
                </Row>
            </Container>
        </SettingsLayout>
    )
}

export default AdvanceSettings;