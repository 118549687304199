import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
} from "@reduxjs/toolkit";
import {
  prepareCartShellItem,
  prepareCartShellSubscriptionItem,
  processCartItem,
  subscriptionProcessCartItem,
} from "../../pos/helpers/cartHelper";
//   import {
//     prepareAndProcessCartItem,
//     prepareCartShellItem,
//     processCartItem,
//   } from "../../pos/helpers/cartHelper";

const subscriptionItemsAdapter = createEntityAdapter();

const initialState = subscriptionItemsAdapter.getInitialState({
  status: "idle",
  error: null,
});

export const addNew = createAsyncThunk(
  "subscriptionPlanItems/addNew",
  async (args) => {
    ;
  
    const response = prepareCartShellSubscriptionItem(args);
    ;
    return response;
  }
);

const subscriptionPlanItemsSlice = createSlice({
  name: "subscriptionPlanItems",
  initialState,
  reducers: {
    setItemFieldSubscription(state, action) {
      const { index, item } = action.payload;
      state.entities[index] = subscriptionProcessCartItem(item);
    },
    unsetProductForSubscriptionItem(state, action) {
    
      let index = action.payload;
      ;
      state.entities[index?.index] = prepareCartShellSubscriptionItem(index);
    },
    updateItemFieldSubscription(state, action) {
      const { index, fieldName, fieldValue, discountEnabled } = action.payload;
      const item = state.entities[index];
      item[fieldName] = fieldValue;
      state.entities[index] = subscriptionProcessCartItem(
        item,
        discountEnabled
      );
    },

    initEditSubscriptionItems(state, action) {
      let items = action.payload;
      ;

      if (items && Array.isArray(items) && items.length > 0) {
        items.map((element, index) => {
          element.id = index + 1;
          subscriptionItemsAdapter.addOne(state, element);
        });
      }
    },
    deleteSubscriptionItem: subscriptionItemsAdapter.removeOne,
    resetSubscriptionItem: () => initialState,
  },
  extraReducers(builder) {
    builder.addCase(addNew.fulfilled, (state, action) => {
      subscriptionItemsAdapter.addOne(state, action.payload);
    });
  },
});

export default subscriptionPlanItemsSlice.reducer;

export const {
  deleteSubscriptionItem,
  updateItemFieldSubscription,
  setItemFieldSubscription,
  unsetProductForSubscriptionItem,
  resetSubscriptionItem,
  initEditSubscriptionItems,
} = subscriptionPlanItemsSlice.actions;

export const {
  selectAll: selectAllSubscriptionPlanItems,
  selectById: selectSubscriptionPlanItemById,
  selectIds: selectSubscriptionPlanItemIds,
} = subscriptionItemsAdapter.getSelectors(
  (state) => state.subscriptionPlanItems
);
