import React, { useEffect } from "react";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { UilExclamationOctagon } from '@iconscout/react-unicons'
import { UilImport } from '@iconscout/react-unicons'
import Image from "react-bootstrap/Image";
import { i18n } from "../helpers/sharedMethods";
import downloadingGif from '../../../assets/downloading.gif'
import { UilSync } from '@iconscout/react-unicons'
import { resetUpdateStatus, restartApp } from "../../auth/store/configSlice";
import { useDispatch, useSelector } from "react-redux";
import { UilExclamationCircle } from '@iconscout/react-unicons'
const UpdateAppModal = (props) => {
  const dispatch =useDispatch()
  const {onAppUpdate,isUpdateDownloading,isUpdateDownloaded,setShowUpdate,show}=props;
  
  const isUpdateRequired = useSelector(state => state.config.isUpdateRequired)
  

  
  
  let  message ;
  let action;
  let title;
  let progressAction;

  if(isUpdateDownloading&&!isUpdateDownloaded){
    title="App Update"
    message = "Update file is being downloaded. Please don't close the app."
    progressAction =( <Image   width={100} height={100}
    src=  {downloadingGif}
    />
   
   

  
  )
  }
  else if(!isUpdateDownloading&&isUpdateDownloaded){
    title="App Update"
    message = "Update file downloaded successfully. Please restart to install the update."
    progressAction =(<Button  onClick={()=>{
      setShowUpdate(false)
      // dispatch(resetUpdateStatus())
      dispatch(restartApp())  
    }} variant="outline-warning"><UilSync size={15} />  Restart</Button> )
  }
  else{
    title="Alert" 
    message = "New update available. Please download and install to open the app." 
    action =( <Button onClick={onAppUpdate} variant="outline-primary"><UilImport size={15} />  Download</Button>)
    progressAction =(<UilExclamationCircle color={'red'} size={70}/>)
  }

  return (
    // <SweetAlert
    //   custom
    //   confirmBtnBsStyle="success mb-3 fs-5 rounded"
    //   cancelBtnBsStyle="secondary mb-3 fs-5 rounded text-white"
    //   confirmBtnText={i18n("Update")}
    //   cancelBtnText={i18n("Cancel")}
    //   onConfirm={onConfirm}
    //   onCancel={onCancel}
    //   showCancel
    //   focusCancelBtn
    // >
    //   <span className="sweet-text">
    //     {"App Update Required!"}
    //   </span>
    // </SweetAlert>
    <Modal
    {...props}
    size="lg"
    aria-labelledby="contained-modal-title-vcenter"
    centered
  >
    <Modal.Header >
      <Modal.Title id="contained-modal-title-vcenter">
       {title} {!isUpdateDownloading&&!isUpdateDownloaded}  
      </Modal.Title>
     </Modal.Header>
     <Modal.Body style={{display:"flex",flexDirection:'column',alignItems:'center',gap:"10px"}}>
      {progressAction}
        <p style={{fontSize:"16px"}}>
       {message}
       </p>
     
      
    </Modal.Body>
    <Modal.Footer>
  {action}
     </Modal.Footer>
  </Modal>
  );
};

export default UpdateAppModal;
