import React, { useEffect } from "react";
import { Button, Card, Col, Container, Row, Stack } from "react-bootstrap";
import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import {
  planData,
  selectAllPackagePlan,
  selectProductAsSubscriptionPlans,
  updateProduct,
} from "../../store/subscription_item_store/GetPackagesItemSlice";
import * as Yup from "yup";
import {
  EditItem,
  resetPackage,
} from "../../store/subscription_item_store/CreatePackageSlice";
import { i18n } from "../../../shared/helpers/sharedMethods";
import Layout from "../../../shared/components/Layout";
import Header from "../../../shared/components/Header";
import PackageLayout from "../../components/PackageLayout/PackageLayout";
import PackageHeader from "../../components/PackageHeader";
import PlanName from "../../components/packages_fields/plan_fields/PlanName";
import PlanCode from "../../components/packages_fields/plan_fields/PlanCode";
import PlanUnits from "../../components/packages_fields/plan_fields/PlanUnits";
import PlanPrice from "../../components/packages_fields/plan_fields/PlanPrice";
import BillEvery from "../../components/packages_fields/plan_fields/BillEvery";
import PlanIntervalType from "../../components/packages_fields/plan_fields/PlanIntervalType";
import PlanExpiresAfter from "../../components/packages_fields/plan_fields/PlanExpiresAfter";
import PlanTaxCode from "../../components/packages_fields/plan_fields/PlanTaxCode";
import TrialDays from "../../components/packages_fields/plan_fields/TrialDays";
import PlanType from "../../components/packages_fields/plan_fields/PlanType";
import { useLocation, useNavigate, useParams } from "react-router";
import {
  initEditPlan,
  resetPlan,
} from "../../store/subscription_item_store/PlanEntitySlice";
import ShortcutKeys from "../../../shared/components/ShortcutKeys";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


const EditPlan = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const location = useLocation();
  const dispatch = useDispatch();
  const { currentId } = location.state;
  const { accessToken, isLoggedIn } = useSelector((state) => state?.auth);
  const selectPlan = useSelector((state) => selectAllPackagePlan(state, id));
  ;
  const selectedBusiness = useSelector(
    (state) => state?.auth?.selectedBusiness
  );

  const tenantId = selectedBusiness?.pivot?.tenant_id;

  const plan = useSelector((state) => state.plan);
  const CurrentStatus = useSelector((state) => state.createPackage.status);
  const status = useSelector((state) => state.createPackage.status);
  const savedEntity = useSelector((state) => state.createPackage.savedEntity);

  useEffect(() => {
    dispatch(initEditPlan(selectPlan[0]));
  }, []);

  useEffect(() => {
    if (status && savedEntity) {
      toast.success("Plan updated successfully")
      dispatch(updateProduct(savedEntity));
      dispatch(
        planData({ token: accessToken.access_token, tenant_id: tenantId })
      );

      dispatch(resetPlan());
      navigate("/subscription/packages/" + currentId, {
        state: { success: true },
      });
    }
  }, [status, savedEntity]);

  return (
    <>
      <ToastContainer />
      <Formik
        initialValues={plan}
        enableReinitialize={true}
        validationSchema={Yup.object().shape({
          name: Yup.string().required(i18n("Plan name is required")),
          code: Yup.string().required(i18n("Plan code is required")),
          sale_price: Yup.string().required(i18n("Plan Price is required")),
          interval: Yup.number().required(i18n("Bill Every is required")),
        })}
        onSubmit={async (values) => {
          try {
            ;
            let formData = values;
            formData = { ...formData, parent_product: currentId };
            formData.plan = {
              interval: formData.interval,
              interval_unit: formData.interval_unit,
              expires_after: formData.expires_after,
              free_trial_days: formData.free_trial_days,
            };
            delete formData.interval;
            delete formData.interval_unit;
            delete formData.expires_after;
            delete formData.free_trial_days;
            delete formData.setup_fee;
            delete formData.is_value;

            ;

            dispatch(
              EditItem({
                data: formData,
                token: accessToken.access_token,
                tenant_id: tenantId,
                id: id,
              })
            );
          } catch (error) { }
        }}
      >
        {({
          values,
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue,
          isSubmitting,
          touched,
        }) => (
          <Layout currentTitle={i18n("Packages")}>
            <Header
              currentTitle={i18n("Manage Packages")}
              ItemActions={
                <Stack
                  gap={3}
                  direction="horizontal"
                  className="my-2 justify-content-end"
                >
                  <ShortcutKeys />
                </Stack>
              }
            />
            <PackageLayout currentItemId={currentId}>
              <PackageHeader
                currentTitle="Edit plan"
                ItemActions={
                  <Stack
                    gap={3}
                    direction="horizontal"
                    className="mt-2 mb-2 float-end"
                  >
                    <div>
                      <Button assName="" variant="primary" onClick={handleSubmit}>
                        {i18n("Save")}
                      </Button>
                    </div>
                    <div>
                      <Button
                        className=""
                        variant="outline-secondary"
                        onClick={() =>
                          navigate("/subscription/packages/" + currentId, {
                            state: { success: true },
                          })
                        }
                      >
                        {i18n("Cancel")}
                      </Button>
                    </div>
                  </Stack>
                }
              />
              <Container
                fluid
                className="px-5 "
                style={{
                  height: "calc(100vh - 150px",
                  overflow: "auto",
                  overflowX: "hidden",
                }}
              >
                <Row>
                  <Col lg={12}>
                    <PlanName
                      values={values}
                      handleChange={handleChange}
                      errors={errors}
                      touched={touched}
                    />
                  </Col>
                  <Col lg={12}>
                    <PlanCode
                      values={values}
                      handleChange={handleChange}
                      errors={errors}
                      touched={touched}
                    />
                  </Col>
                  <Col lg={12}>
                    <PlanUnits
                      values={values}
                      handleChange={handleChange}
                      errors={errors}
                      touched={touched}
                      setFieldValue={setFieldValue}
                    />
                  </Col>
                  <Col lg={12}>
                    <PlanPrice
                      values={values}
                      handleChange={handleChange}
                      errors={errors}
                      touched={touched}
                    />
                  </Col>
                  <Col lg={12}>
                    <BillEvery
                      values={values}
                      handleChange={handleChange}
                      errors={errors}
                      touched={touched}
                      setFieldValue={setFieldValue}
                    />
                  </Col>
                  <Col lg={12}>
                    <PlanIntervalType
                      values={values}
                      handleChange={handleChange}
                      errors={errors}
                      touched={touched}
                    />
                  </Col>
                  {values?.is_value == 1 && (
                    <Col lg={12}>
                      <PlanExpiresAfter
                        values={values}
                        handleChange={handleChange}
                        errors={errors}
                        touched={touched}
                      />
                    </Col>
                  )}
                  <Col lg={12}>
                    <PlanTaxCode
                      values={values}
                      handleChange={handleChange}
                      errors={errors}
                      touched={touched}
                    />
                  </Col>
                  <Col lg={12}>
                    <TrialDays
                      values={values}
                      handleChange={handleChange}
                      errors={errors}
                      touched={touched}
                    />
                  </Col>
                  <Col lg={12}>
                    <PlanType
                      values={values}
                      handleChange={handleChange}
                      errors={errors}
                      touched={touched}
                    />
                  </Col>
                </Row>
              </Container>
            </PackageLayout>
          </Layout>
        )}
      </Formik>
    </>
  );
};

export default EditPlan;
