import { Button, Form, ListGroup, Accordion } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { i18n, placeholderText } from "../../shared/helpers/sharedMethods";
import { roundOffGrandTotal, updateTotals } from "../store/voucherEntitySlice";
import { useEffect, useState } from "react";
import AmountReceived from "./fields/AmountReceived";
import GlobalCurrency from "../../shared/components/GlobalCurrency";
import TaxSummary from "./fields/TaxSummary";

const VoucherSummary = (props) => {
  const { type,is_same_state } = props;
  const {
    grand_total,
    subtotal,
    paid_amount,
    roundoff,
    tax_total,
    totalAddlCharges,
    items,
  } = useSelector((state) => state.voucherEntity);

  const currencySymbol = useSelector(
    (state) => state.getSetting?.dataSetting?.currency
  );

  const [roundOff, setRoundOff] = useState(false);

  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);

  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);
  useEffect(() => {
    if (roundOff) {
      dispatch(roundOffGrandTotal(true));
    } else {
      dispatch(updateTotals(items));
    }
  }, [roundOff, dispatch, items]);

  useEffect(() => {
    if (roundoff) {
      setRoundOff(true);
    }
  }, [roundoff]);

  const handleRoundOff = (e) => {
    setRoundOff(e.target.checked);
  };

  return (
    <ListGroup variant="flush" className="px-0">
      {/* <ListGroup.Item> */}
      {/* {!paid_amount > 0 && <Form.Check
          inline
          label={placeholderText("Round Off")}
          name="round_off"
          type='checkbox'
          id='round_off_check'
          className="float-end mx-0"
          onChange={handleRoundOff}
          checked={roundOff}
          />} */}
      {/* </ListGroup.Item> */}

      <ListGroup.Item className="grand-total-block px-0">
        <div className="d-flex justify-content-between align-items-center w-100">
          <div className="d-flex justify-content-between align-items-center col-5">
            <span>{i18n("TOTAL")}:</span>
            <Form.Check
              inline
              label={placeholderText("Round Off")}
              name="round_off"
              type="checkbox"
              id="round_off_check"
              onChange={handleRoundOff}
              checked={roundOff}
              style={{
                fontSize: "10px",
                fontWeight: "normal",
                color: "black",
                marginLeft:"20px", marginRight:"10px"
              }}
            />
          </div>

          <span className="grand-total-txt">
            <GlobalCurrency price={grand_total} />
          </span>
        </div>
      </ListGroup.Item>


      {/* {roundOff && (
       <ListGroup.Item className='px-0 my-2'>
         {i18n('Round Off')} :
         <span className='float-end adjusted-total-txt'>
           {currencySymbol} {parseFloat(roundoff).toFixed(2)}
         </span>
       </ListGroup.Item>
     )} */}

      {!(
        type === "proforma_invoice" ||
        type === "quote" ||
        type === "purchase_order"
      ) ? (
        <ListGroup.Item className="my-0 py-2">
          <AmountReceived currencySymbol={currencySymbol} />
        </ListGroup.Item>
      ) : null}
      {!(
        type === "proforma_invoice" ||
        type === "quote" ||
        type === "purchase_order"
      ) ? (
        <ListGroup.Item
          className="balance-total px-0"
          style={{ color: "#1a237e" }}
        >
          {i18n("BALANCE")} :
          <span
            className="float-end balance-amt-txt"
            style={{ color: "#1a237e" }}
          >
<GlobalCurrency price = {grand_total - paid_amount} />

          </span>
        </ListGroup.Item>
      ) : null}
   
    </ListGroup>
  );
};

export default VoucherSummary;
