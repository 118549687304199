import React, { useEffect, useState } from "react";

import { useNavigate } from "react-router";
import "../../../../assets/css/frontend.css";
import { DataTable } from 'primereact/datatable';
import { classNames } from 'primereact/utils';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { IconField } from 'primereact/iconfield';
import { InputIcon } from 'primereact/inputicon';
import { Dropdown } from 'primereact/dropdown';
import { InputNumber } from 'primereact/inputnumber';
import { Button } from 'primereact/button';
import { ProgressBar } from 'primereact/progressbar';
import { Calendar } from 'primereact/calendar';
import { MultiSelect } from 'primereact/multiselect';
import { Slider } from 'primereact/slider';
import { Tag } from 'primereact/tag';
import {
  selectAllSubscriptionData,
  selectPartyByName,
} from "../../store/SubscriptionSlice";
import { useSelector, useDispatch } from "react-redux";
import { Badge, Container, Stack ,Image} from "react-bootstrap";
import { format } from "date-fns";
import { placeholderText } from "../../../shared/helpers/sharedMethods";
import Layout from "../../../shared/components/Layout";
import Header from "../../../shared/components/Header";
import { i18n } from "../../../shared/helpers/sharedMethods";
import PartyName from "../../components/party/PartyName";
import {
  selectAllSubscriptionVoucher,
  getAllSubscriptionVoucher,
} from "../../store/getAllSubscriptionVoucherSlice";
import { UilFilePlusAlt } from "@iconscout/react-unicons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileInvoice } from "@fortawesome/free-solid-svg-icons";
import { UilInvoice } from "@iconscout/react-unicons";
import { getLocalizedDate } from "../../../shared/helpers/localizationHelper";
import ShortcutKeys from "../../../shared/components/ShortcutKeys";
import { resetSubscriptionVoucher } from "../../store/getAllSubscriptionVoucherSlice";

import DataGridHeader from "../../../shared/components/DataGridHeader";
import CustomeSortIcon from "../../../shared/components/CustomeSortIcon";
import GlobalCurrency from "../../../shared/components/GlobalCurrency";
import LoadingSpinner from '../../../shared/components/LoadingSpinner';
import { fetchSetting } from "../../../settings/store/getSettingSlice";
import { handleExportClick, downloadCsvFile } from "../../../shared/export";
const SubscriptionInvoice = ({ featureName }) => {
  const subscriptionVoucher = useSelector(selectAllSubscriptionVoucher);
  const currencySymbol = useSelector(
    (state) => state.getSetting?.dataSetting.currency
  );
console.log("subscriptionVoucher", subscriptionVoucher)
  const selectedBusiness = useSelector(
    (state) => state?.auth?.selectedBusiness
  );
  const accessToken = useSelector(
    (state) => state?.auth?.accessToken?.access_token
  );
  const tenantId = selectedBusiness?.pivot?.tenant_id;
  // const currencySymbol  = useSelector((state) => state.getSetting?.dataSetting.currency);
  //   ;
  const loading = useSelector((state) => state.subscriptionVoucher.loading);
  const partyName = useSelector((state) => state.subscription.partyName);

  const partyByName = useSelector((state) =>
    selectPartyByName(state, partyName)
  );

  const dispatch = useDispatch();

  const [invoice, setInvoice] = useState(subscriptionVoucher);
  console.log("invoice",invoice)
  const [refreshKey, setRefreshKey] = useState(0);
  const [csvData, setCsvData] = useState('');

  const globalPlanFeatures =  useSelector(
    (state) => state?.globalPlan?.features
  );


  useEffect(() => {
    if(!globalPlanFeatures.includes(featureName)){
      navigate("/setting/not-in-plan");
    }
  },[])

  useEffect(()=>{
    setInvoice(subscriptionVoucher)
      
  },[subscriptionVoucher])

  useEffect(() => {
    dispatch(
      getAllSubscriptionVoucher({ token: accessToken, tenantId: tenantId })
    );
  }, [dispatch]);

  useEffect(() => {
    if (partyByName) {
      setInvoice(partyByName);
    }
  }, [partyByName]);

  const handleRefreshInvoice = () => {
    dispatch(
      getAllSubscriptionVoucher({ token: accessToken, tenantId: tenantId })
    );
    dispatch(resetSubscriptionVoucher());
    setRefreshKey((prevKey) => prevKey + 1);
  };
  useEffect(() => {
    if (subscriptionVoucher.length > 0) {
      setInvoice(subscriptionVoucher);
    }
  }, []);

  /* eslint-disable no-restricted-globals */
 useEffect(() => {
   dispatch(
     fetchSetting({
       accessToken: accessToken,
       tenant_id: selectedBusiness?.pivot?.tenant_id,
     })
   );
 }, [status]);
 /* eslint-enable no-restricted-globals */

  const [globalFilter, setGlobalFilter] = useState('');
  const [globalFilterValue, setGlobalFilterValue] = useState('');
  const [filters, setFilters] = useState(null);
  const [statusOptions, setStatusOptions] = useState([]);
  const [selectedstatus, setSelectedStatus] = useState([]);

  const handleClearFilters = () => {
      setSelectedStatus([]);
      setFilters({
          global: { value: null, matchMode: FilterMatchMode.CONTAINS },
          voucher_date: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
          'voucher_subscription?.subscription?.member?.member_name': { value: null, matchMode: FilterMatchMode.CONTAINS },
          voucher_number: { value: null, matchMode: FilterMatchMode.CONTAINS },
          grand_total: { value: null, matchMode: FilterMatchMode.CONTAINS },
          created_at: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
          paid_amount: { value: null, matchMode: FilterMatchMode.EQUALS },
          status: { value: null, matchMode: 'in' },
      });
      setGlobalFilter('');
      setGlobalFilterValue('');
      setInvoice(subscriptionVoucher);
  };
  const [balanceFrozen, setBalanceFrozen] = useState(false);
  const [sortField, setSortField] = useState("created_at");
  const [sortOrder, setSortOrder] = useState(-1);

  const [filteredData, setFilteredData] = useState(subscriptionVoucher);
  const navigate = useNavigate();

  const handleView = (data) => {
    ;
    navigate("/subscription/invoice/view/" + data?.id);
  };

  const dateBodyTemplate = (rowData) => {
      return getLocalizedDate(rowData.voucher_date);
  };
  
  const handleDateFilterChange = (value, field, filterCallback) => {
    filterCallback(value);
  
    if (value) {
      const filtered = subscriptionVoucher.filter((invoice) => {
        const invoiceDate = new Date(invoice[field]);
        const selectedDate = new Date(value);
        return invoiceDate.toDateString() === selectedDate.toDateString();
      });
      setInvoice(filtered);
    } else {
      setInvoice(subscriptionVoucher);
    }
  };
  
  const dateFilterTemplate = (options) => {
    return (
      <Calendar 
        value={options.value}
        onChange={(e) => handleDateFilterChange(e.value, 'voucher_date', options.filterCallback)}
        dateFormat="dd-mm-yy"
        mask="99-99-9999"
      />
    );
  };
  
  const dateCreatedAtBodyTemplate = (rowData) => {
    return getLocalizedDate(rowData.created_at);
  };
  
  const handleCreatedAtDateFilterChange = (value, filterCallback) => {
    filterCallback(value);

    if (value) {
        const filtered = subscriptionVoucher.filter((item) => {
            const createdAtDate = new Date(item.created_at);
            const selectedDate = new Date(value);
            return createdAtDate.toDateString() === selectedDate.toDateString();
        });
        setInvoice(filtered);
    } else {
      setInvoice(subscriptionVoucher);
    }
  };
  
  const dateCreatedAtFilterTemplate = (options) => {
    return (
        <Calendar 
            value={options.value}
            onChange={(e) => handleCreatedAtDateFilterChange(e.value, options.filterCallback)}
            dateFormat="dd/mm/yy"
            mask="99/99/9999"
        />
    );
  };

  const headerCellStyle = {
    fontWeight: 'bold',
    fontSize: '11px',
  };
  
  const handleStatusChange = (e) => {
    setSelectedStatus(e.value);
  
    if (!Array.isArray(e.value)) {
      console.error('Expected e.value to be an array, but got:', e.value);
      return;
    }
  
    const selectedStatuses = e.value.map(option => option.name.toLowerCase());
  
    if (selectedStatuses.length === 0) {
      setInvoice(subscriptionVoucher);
    } else {
      const filteredStatus = subscriptionVoucher.filter(sales => {
        const status = sales.paid_amount === sales.grand_total
          ? "Paid"
          : sales.paid_amount > 0 && sales.paid_amount < sales.grand_total
          ? "Partially Paid"
          : "Unpaid";
        return selectedStatuses.includes(status.toLowerCase());
      });
      setInvoice(filteredStatus);
    }
  };  
  
  const statusItemTemplate = (option) => {
    const severityClass = getSeverity(option.name);
    return (
      <div className={`d-flex align-items-center ${severityClass}`}>
        <Tag value={option.label} className={severityClass} />
      </div>
    );
  };
  
  const getSeverity = (status) => {
    switch (status) {
      case 'Paid':
        return 'custom-tag-success';
      case 'Partially Paid':
        return 'custom-tag-warning';
      case 'Unpaid':
        return 'custom-tag-danger';
      default:
        return '';
    }
  };

  useEffect(() => {
      const options = [
        { name: 'Paid', label: 'Paid' },
        { name: 'Partially Paid', label: 'Partially Paid' },
        { name: 'Unpaid', label: 'Unpaid' },
      ];
      setStatusOptions(options);
    }, []);

    const statusBodyTemplate = (rowData) => {
      const status = rowData.paid_amount === rowData.grand_total
        ? "Paid"
        : rowData.paid_amount > 0 && rowData.paid_amount < rowData.grand_total
        ? "Partially Paid"
        : "Unpaid";
    
      const severityClass = status === "Paid" ? "custom-tag-success" : getSeverity(status);
    
      return <Tag value={status} className={severityClass} />;
    };
    

  const statusFilterTemplate = (options) => {
    return (
      <MultiSelect
          value={selectedstatus}
          options={statusOptions}
        optionLabel="label"
          itemTemplate={statusItemTemplate}
          onChange={handleStatusChange}
            className="p-column-filter custom-multiselect" 
          style={{ fontSize: '11px' }}
      />
    );
  };

  const customSort = (field) => {
    if (field === 'created_at') {
      setSortOrder(-1); 
    } else {
      setSortField(field);
      setSortOrder(-1);
    }
  };

  const sortedData = [...invoice].sort((a, b) => {
    const dateA = new Date(a.created_at);
    const dateB = new Date(b.created_at);
    
    return dateB - dateA; 
  });

  const prepareExportData = () => {
    return invoice.map(subscription => ({
      ID: subscription.id,
      Date: subscription.bill_queue.start_date? format(new Date(subscription.bill_queue.start_date), "dd-MM-yyyy")  : "-",
      Member: subscription.member?.member_name, 
      TxnNo: subscription.voucher_number,
      GrandTotal: subscription.grand_total,
      PaidAmount: subscription.paid_amount,
      Status: subscription.paid_amount === subscription.grand_total
        ? "Paid"
        : subscription.paid_amount > 0 && subscription.paid_amount < subscription.grand_total
        ? "Partially Paid"
        : "Unpaid",
      CreatedAt:subscription.created_at,
    }));
  };

  const handleExport = () => {
    const exportData = prepareExportData();
    const fileName = 'Manage Invoices';
    handleExportClick(exportData, setCsvData, downloadCsvFile, fileName);
  };
  

  return (
    <Layout>
           {loading && <LoadingSpinner />}
      <Header
        currentTitle={i18n("Manage Invoices")}
        ItemActions={
          <Stack
            gap={3}
            direction="horizontal"
            className="my-2 justify-content-end"
          >
            <ShortcutKeys />
          </Stack>
        }
      />
      <Container fluid className="px-5">
        <div className="border" style={{ background: "white" }}>
          <DataGridHeader
            handleRefreshInvoice = {handleRefreshInvoice}
            data={invoice}
            allData={subscriptionVoucher}
            clearFilter={handleClearFilters}
            addFilteredData={setInvoice}
            handleExportClick={handleExport}
            title="Manage Invoices"
            icons={<UilInvoice size="50" color="gray" />}
            message=" No invoices found. Please create a new invoices."
          />
          
          <Container fluid className="px-4 mb-4">
            <div className="bordered-container">

              <DataTable
                value={sortedData}
                isAllData={"All Invoice"}
                title="Manage Invoices"
                paginator
                globalFilter={globalFilter}
                // globalFilterFields={['id', 'voucher_date','name','subscription_number', 'grand_total', 'paid_amount','status']}
                rows={10}
                dataKey="id"
                filterDisplay="row"
                filters={filters}
                emptyMessage={i18n("No Invoice found. Please create a new Invoice.")}
                onFilter={(e) => setFilters(e.filters)}
                className="p-datatable-gridlines full-width-table custom-datatable p-component"
                onRowClick={(e) => handleView(e.data)}
                checked={balanceFrozen}
                scrollable
                scrollHeight="400px"
                sortMode="multiple"
                removableSort
                style={{ cursor: "pointer" }}
              >                
                <Column
                  field="voucher_date"
                  header={() => (
                    <div
                      className={`custom-header-${sortField === 'voucher_date' ? (sortOrder === 1 ? 'up' : 'down') : ''}`}
                      onClick={() => customSort('voucher_date')}
                      style={{
                        cursor: 'pointer',
                        display: 'flex',
                        alignItems: 'center',
                        fontSize: '11px',
                        lineHeight: '11px',
                      }}
                    >
                      {i18n("TXN DATE")}
                      <CustomeSortIcon sortOrder={sortField === 'voucher_date' ? sortOrder : 0}
                              style={{ marginLeft: '4px', verticalAlign: 'middle' }} />
                    </div>
                  )}
                  body={(row) =>
                    row.voucher_date ? (
                      <span>{getLocalizedDate(row.voucher_date, 'en-IN').split(',')[0]}</span>
                      ) : (
                      "-"
                    )
                  }
                  sortable
                  //body={dateBodyTemplate}
                  filter
                  filterElement={dateFilterTemplate}
                  headerStyle={headerCellStyle}
                  showFilterMatchModes={false}
                  showClearButton={false}
                  showFilterOperator={false}
                  showAddButton={false}
                  filterMatchMode={FilterMatchMode.DATE_IS}
                  showApplyButton={false}
                  showFilterMenu={false}
                />
                <Column
                  field="voucher_subscription?.subscription?.member?.member_name"
                  header={() => (
                    <div
                      className={`custom-header-${sortField === 'voucher_subscription?.subscription?.member?.member_name' ? (sortOrder === 1 ? 'up' : 'down') : ''}`}
                      onClick={() => customSort('voucher_subscription?.subscription?.member?.member_name')}
                      style={{
                        cursor: 'pointer',
                        display: 'flex',
                        alignItems: 'center',
                        fontSize: '11px',
                        lineHeight: '11px',
                      }}
                    >
                      {i18n("Member")}
                      <CustomeSortIcon 
                        sortOrder={sortField === 'voucher_subscription?.subscription?.member?.member_name' ? sortOrder : 0}
                        style={{ marginLeft: '4px', verticalAlign: 'middle' }} 
                      />
                    </div>
                  )}
                  sortable
                  filter
                  filterPlaceholder=""
                  headerStyle={headerCellStyle}
                  showFilterMatchModes={false}
                  showClearButton={false}
                  showFilterOperator={false}
                  showAddButton={false}
                  filterMatchMode={FilterMatchMode.CONTAINS}
                  showApplyButton={false}
                  showFilterMenu={false}
                  body={(rowData) => rowData?.voucher_subscription?.subscription?.member?.member_name || rowData?.voucher_subscription?.subscription?.member?.member_name}
                />

                <Column field="voucher_number"
                    header={() => (
                      <div
                        className={`custom-header-${sortField === 'voucher_number' ? (sortOrder === 1 ? 'up' : 'down') : ''}`}
                        onClick={() => customSort('voucher_number')}
                        style={{
                          cursor: 'pointer',
                          display: 'flex',
                          alignItems: 'center',
                          fontSize: '11px',
                          lineHeight: '11px',
                        }}
                      >
                        {i18n("TXN NO")}
                        <CustomeSortIcon sortOrder={sortField === 'voucher_number' ? sortOrder : 0}
                              style={{ marginLeft: '4px', verticalAlign: 'middle' }} />
                      </div>
                    )} sortable filter filterPlaceholder="" 
                    headerStyle={headerCellStyle}
                    showFilterMatchModes={false}
                    showClearButton={false}
                    showFilterOperator={false}
                    showAddButton={false}
                    filterMatchMode={FilterMatchMode.CONTAINS}
                    showApplyButton={false}
                    showFilterMenu={false}
                />
                <Column field="grand_total"
                    header={() => (
                      <div
                        className={`custom-header-${sortField === 'grand_total' ? (sortOrder === 1 ? 'up' : 'down') : ''}`}
                        onClick={() => customSort('grand_total')}
                        style={{
                          cursor: 'pointer',
                          display: 'flex',
                          alignItems: 'center',
                          fontSize: '11px',
                          lineHeight: '11px',
                        }}
                      >
                        {i18n("grand total")}
                        <CustomeSortIcon sortOrder={sortField === 'grand_total' ? sortOrder : 0}
                              style={{ marginLeft: '4px', verticalAlign: 'middle' }} />
                      </div>
                    )} sortable filter filterPlaceholder="" 
                    headerStyle={headerCellStyle}
                    showFilterMatchModes={false}
                    showClearButton={false}
                    showFilterOperator={false}
                    showAddButton={false}
                    filterMatchMode={FilterMatchMode.CONTAINS}
                    showApplyButton={false}
                    showFilterMenu={false}
                    body={(rowData) => (
                      <span>                      
                        <GlobalCurrency price={rowData.grand_total} />
                      </span>
                    )}
                />

                <Column field="paid_amount"
                    header={() => (
                      <div
                        className={`custom-header-${sortField === 'paid_amount' ? (sortOrder === 1 ? 'up' : 'down') : ''}`}
                        onClick={() => customSort('paid_amount')}
                        style={{
                          cursor: 'pointer',
                          display: 'flex',
                          alignItems: 'center',
                          fontSize: '11px',
                          lineHeight: '11px',
                        }}
                      >
                        {i18n("Paid Amount")}
                        <CustomeSortIcon sortOrder={sortField === 'paid_amount' ? sortOrder : 0}
                              style={{ marginLeft: '4px', verticalAlign: 'middle' }} />
                      </div>
                    )} sortable filter filterPlaceholder=""
                    headerStyle={headerCellStyle}
                    showFilterMatchModes={false}
                    showClearButton={false}
                    showFilterOperator={false}
                    showAddButton={false}
                    filterMatchMode={FilterMatchMode.CONTAINS}
                    showApplyButton={false}
                    showFilterMenu={false}
                    body={(rowData) => (
                      <span>
                        <GlobalCurrency price={rowData.paid_amount} />
                      </span>
                    )}
                    />

                <Column
                  field="status"
                  header={() => (
                    <div
                      className={`custom-header-${sortField === 'status' ? (sortOrder === 1 ? 'up' : 'down') : ''}`}
                      onClick={() => customSort('status')}
                      style={{
                        cursor: 'pointer',
                        display: 'flex',
                        alignItems: 'center',
                        fontSize: '11px',
                        lineHeight: '11px',
                      }}
                    >
                      {i18n("Status")}
                      <CustomeSortIcon sortOrder={sortField === 'status' ? sortOrder : 0}
                              style={{ marginLeft: '4px', verticalAlign: 'middle' }} />
                    </div>
                  )}
                  sortable
                  body={statusBodyTemplate}
                  filter
                  filterElement={statusFilterTemplate}
                  filterPlaceholder=""
                  headerStyle={headerCellStyle}
                  showFilterMatchModes={false}
                  showClearButton={false}
                  showFilterOperator={false}
                  showAddButton={false}
                  filterMatchMode={FilterMatchMode.CONTAINS}
                  showApplyButton={false}
                  showFilterMenu={false}
                />

                <Column
                  field="created_at"
                  header={() => (
                    <div
                      className={`custom-header-${sortField === 'created_at' ? 'up' :''}`}
                      onClick={() => customSort('created_at')}
                      style={{
                        cursor: 'pointer',
                        display: 'flex',
                        alignItems: 'center',
                        fontSize: '11px',
                        lineHeight: '11px',
                      }}
                    >
                      {i18n("Created At")}
                      <CustomeSortIcon sortOrder={sortField === 'created_at' ? sortOrder : 0}
                            style={{ marginLeft: '4px', verticalAlign: 'middle' }} />
                    </div>
                  )}
                  sortable
                  body={dateCreatedAtBodyTemplate}
                  filter
                  filterElement={dateCreatedAtFilterTemplate}
                  filterPlaceholder=""
                  headerStyle={headerCellStyle}
                  showFilterMatchModes={false}
                  showClearButton={false}
                  showFilterOperator={false}
                  showAddButton={false}
                  filterMatchMode={FilterMatchMode.DATE_IS}
                  // showApplyButton={false}
                    showFilterMenu={false}
                />
            </DataTable>
            </div>
          </Container>
        </div>
      </Container>
    </Layout>
  );
};

export default SubscriptionInvoice;
