import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const initialState = {
  isToggleShow: false,
  activeSubmenu: {},
  isKeyboard: false,
  isAbout: false,
  isVersion: "",
};

function getKeyboardShortcutData() {
  return new Promise((resolve) => {
    window.ele.onKeyboardShortcutsEvent((data) => {
      ;
      resolve(data);
    });
  });
}

export const keyboardShortcut = createAsyncThunk(
  "sharedSlice/keyboard",
  async () => {
    try {
    const customData = await getKeyboardShortcutData();
    ;
    return customData;
    } catch (error) {
      await window.log.logToFile({message: "Error while receiving shortcuts", level: "error" });
      throw error;
    }
  }
);

const SharedSlice = createSlice({
  name: "sharedSlice",
  initialState,
  reducers: {
    changeToggleShow(state, action) {
      ;
      state.isToggleShow = action.payload;
    },
    changeSubmenu(state, action) {
      //;
      state.activeSubmenu = action.payload;
    },
    updateKeyboard(state, action) {
      ;
      state.isKeyboard = action.payload;
    },
    aboutDetails(state, action) {
      ;
      state.isAbout = action?.payload?.isAbout;
      state.isVersion = action.payload.version;
    },
    resetSubmenu: () => initialState,
  },
  extraReducers(builder) {
    builder
      .addCase(keyboardShortcut.fulfilled, (state, action) => {
        ;
        state.isKeyboard = action.payload;
      })
      .addCase(keyboardShortcut.rejected, (state, action) => {
        ;
      });
  },
  extraReducers: (builder) => {
  },
});

export default SharedSlice.reducer;

export const {
  changeToggleShow,
  changeSubmenu,
  resetSubmenu,
  updateKeyboard,
  aboutDetails,
} = SharedSlice.actions;
