import { Dropdown } from "react-bootstrap";
import { i18n } from "../helpers/sharedMethods";
import "../../../assets/css/frontend.css";

const GlobalAction = (props) => {
  const { bulkAction, handleExportClick, title, type } = props;

  return (
    <Dropdown className="float-end mx-3">
      <Dropdown.Toggle variant="outline-success" id="dropdown-basic">
        {i18n("Bulk Actions")}
      </Dropdown.Toggle>

      <Dropdown.Menu className="dropdown-menu">
        {title === "Parties" && (
          <Dropdown.Item onClick={bulkAction}>{i18n("Import")}</Dropdown.Item>
        )}
        {title == "Products" && (
          <Dropdown.Item
            onClick={bulkAction}
            className=""
            variant="outline-success"
          >
            {i18n("Import")}
          </Dropdown.Item>
        )}
        {type && (
          <Dropdown.Item
            onClick={bulkAction}
            className=""
            variant="outline-success"
          >
            Import
          </Dropdown.Item>
        )}

        <Dropdown.Item onClick={handleExportClick}>
          {i18n("Export")}
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};
export default GlobalAction;
