import ItemReportByParty from "../screens/ItemReportByParty"
import ItemSaleAndPurchaseSummary from "../screens/ItemSaleAndPurchaseSummary"
import RateListReport from "../screens/RateListReport"
import StockDetailReport from "../screens/StockDetailReport"
import StockSummary from "../screens/StockSummary"


const rootPath = 'reports'


export const itemreportRoutes = [
    {
        path: rootPath+ '/item/sales-and-purchase-summary',
        element: <ItemSaleAndPurchaseSummary />,
        isProtected: true

     },
     {
        path: rootPath+ '/item/item-report-by-party',
        element: <ItemReportByParty />,
        isProtected: true

     },
     {
        path: rootPath+'/item/rate-list',
        element: <RateListReport />,
        isProtected: true
    },
    {
        path: rootPath+ '/item/stock-detail',
        element: <StockDetailReport/>,
        isProtected: true
  
     },
     {
        path: rootPath+ '/item/stock-summary',
        element: <StockSummary />,
        isProtected: true
  
     },
    
   
     
]