import PartyLedgerReport from "../screens/PartyLedgerReport"
import PartyReportByItem from "../screens/PartyReportByItem"
import PartyWiseOutstanding from "../screens/PartyWiseOutstanding"
import ReceivableAgeingReport from "../screens/ReceivableAgeingReport"
import SaleSummaryCategoryWise from "../screens/SaleSummaryCategoryWise"


const rootPath = 'reports'


export const partyreportRoutes = [
    {
        path: rootPath+ '/party/receivable-agening',
        element: <ReceivableAgeingReport/>,
        isProtected: true
  
     },
     {
        path: rootPath+ '/party/party-report-by-item',
        element: <PartyReportByItem />,
        isProtected: true
  
     },
     {
        path: rootPath+ '/party/party-wise-outstanding',
        element: <PartyWiseOutstanding/>,
        isProtected: true
  
     },
     {
        path: rootPath+ '/party/party-ledger',
        element: <PartyLedgerReport/>,
        isProtected: true
  
     },
     {
        path: rootPath+ '/party/sales-sumary-category-wise',
        element: <SaleSummaryCategoryWise/>,
        isProtected: true
  
     },
   
     
]