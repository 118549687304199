import React, { useState, useEffect, useRef } from "react";
import {
  i18n,
  placeholderText,
  numValidate,
} from "../../shared/helpers/sharedMethods";
import { useNavigate } from "react-router-dom";
import {
  changeParty,
  createParty,
  removeAddress,
  resetCreateParty,
  undoError,
} from "../store/PartyEntitySlice";
import { useDispatch, useSelector } from "react-redux";
import CreateGeneralDetails from "../components/GeneralDetails";
import CreateCategory from "../../settings/components/CreateCategoryModel";
//import { toast, ToastContainer } from "react-toastify";
import { Formik } from "formik";
import * as Yup from "yup";
import CreateBusinessDetails from "../components/BusinessDetails";
import { selectAllCategories } from "../../settings/store/categorysSlice";
import { cloneDeep, isNumber, trim } from "lodash";
import { fetchparty, pushNewParty } from "../store/getPartySlice";
import {
  Button,
  Col,
  Modal,
  Row,
  Spinner,
  Tab,
  Tabs,
} from "react-bootstrap";
import { useHotkeys } from "react-hotkeys-hook";
import AlertMessage from "../../shared/components/AlertMessage";
import Address from "../components/Address";
import {
  addNewAddress,
  deletePartyAddress,
  initEdit,
  resetAddressStatus,
  selectAllPartyAddress,
  selectPartyAddressIds,
} from "../store/partyAddressSlice";
import { getUtcDate } from "../../shared/helpers/localizationHelper";
import MultipleAddress from "../components/MultipleAddress";
import { toast, ToastContainer } from "react-toastify";

const CreateParty = (props) => {
  const {
    show,
    setShowPartyCreate,
    isTriggeredFromVoucher,
    voucherQuickCreate,
    autoFill,
    type,
  } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const selectedBusiness = useSelector(
    (state) => state?.auth?.selectedBusiness
  );
  const allAddress = useSelector(selectAllPartyAddress);

  const cartId = useSelector(selectPartyAddressIds);

  const { addAddressStatus } = useSelector((state) => state.partyAddress);

  const partyState = useSelector((state) => state.partyEntity.party);
  const { party_address } = useSelector((state) => state.partyEntity.party);

  const status = useSelector((state) => state.partyEntity.createStatus);
  const createdEntity = useSelector(
    (state) => state.partyEntity.savedPartyEntity
  );
  const { errorStatus, error } = useSelector((state) => state.partyEntity);

  const fetchCategory = useSelector(selectAllCategories);
  const filterData = fetchCategory.filter((item) => item?.type === "party");


  const [showCategory, setShowCategory] = useState(false);
  const [key, setKey] = useState("general");
  const [click, setClick] = useState(false);
  const [showAddress, setShowAddress] = useState(false);
  const [addressId, setAddressId] = useState();
  const [selectedId, setSelectedId] = useState(null);
  const [loading, setLoading] = useState(false);
  const { accessToken } = useSelector((state) => state?.auth);
  const tenantId = selectedBusiness?.pivot?.tenant_id;
  const { terminalId } = useSelector((state) => state.initInstall);


  useEffect(() => {
    if (errorStatus) {
      setLoading(false)
    }
  }, [errorStatus])

  useEffect(() => {
    if (status && createdEntity) {
      dispatch(pushNewParty(createdEntity));
      toast.success("Party created Successfully");
      dispatch(fetchparty({ token: accessToken.access_token, tenant_id: selectedBusiness?.pivot?.tenant_id }));
      setShowPartyCreate(false);
      dispatch(resetCreateParty());
      if (isTriggeredFromVoucher) {
        voucherQuickCreate([createdEntity]);
      }
    }
  }, [status, createdEntity]);

  useEffect(() => {
    if (autoFill && autoFill != "") {
      let cleanedAutoFill = trim(autoFill);
      if (
        isNumber(parseInt(cleanedAutoFill)) &&
        cleanedAutoFill.toString().length == 10
      ) {
        dispatch(changeParty({ value: cleanedAutoFill, name: "phone_number" }));
      } else {
        dispatch(changeParty({ value: cleanedAutoFill, name: "name" }));
      }
    }
  }, []);

  useEffect(() => {
    if (addAddressStatus) {
      let getAddressDetail = allAddress[allAddress.length - 1];

      setShowAddress(true);
      setAddressId(getAddressDetail.id);
      dispatch(resetAddressStatus());
    }
  }, [addAddressStatus]);

  const saveButtonRef = useRef(null);

  useHotkeys("esc", (event) => {
    event.preventDefault();
    handleCancel();
  });

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Enter") {
        event.preventDefault();
        const saveButton = document.getElementById("submitButton");
        if (saveButton) {
          saveButton.click();
        }
      }
    };

    if (!showCategory && !showAddress) {
      document.addEventListener("keydown", handleKeyDown);
    }
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [showCategory, showAddress]);

  const gstRegex =
    /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[0-9]{1}[A-Z]{1}[0-9A-Z]{1}$/;
  const panRegex = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  const handleMultipleAddressClick = () => {
    dispatch(addNewAddress(cartId.length + 1));
  };

  const handleCategory = () => {
    setShowCategory(true);
  };

  const handleCancel = () => {
    setShowPartyCreate(false);
    dispatch(resetCreateParty());
  };

  const handleEdit = (args) => {
    dispatch(initEdit({ addressData: args?.value, id: args?.id }));
    setAddressId(args?.id);
    setShowAddress(true);
  };

  const handleDelete = (i) => {
    dispatch(deletePartyAddress(i));
    dispatch(removeAddress(i));
  };


  const handleAlertClose = () => {
    setLoading(false)
    dispatch(undoError());
  };
  return (
    <>
      <Formik
        initialValues={partyState}
        enableReinitialize={true}
        validationSchema={Yup.object().shape({
          name: Yup.string()
            .required(i18n("Please enter party name"))
            .min(2, "Please enter at least 2 characters"),
          phone_number: Yup.string()
            .required(i18n("Please enter party phone number"))
            .min(10, "Phone number must be 10 digits")
            .matches(/^[0-9]*$/, i18n("Invalid phone number")),
          gstin: Yup.string().nullable().matches(gstRegex, "Invalid GSTIN"),
          pan: Yup.string().nullable().matches(panRegex, "Invalid PAN number"),
          email: Yup.string().nullable().matches(emailRegex, "Invalid Email"),
        })}
        onSubmit={async (values) => {
          try {
            setLoading(true);
            let formData = cloneDeep(partyState);

            if (formData.ledger_type == 'toCollect') {
              formData.opening_balance = formData.opening_balance
            }
            else {
              formData.opening_balance = -formData.opening_balance
            }
            formData.is_same_as_billing = click;
            formData.created_by = selectedBusiness?.pivot?.user_id;
            formData.updated_by = selectedBusiness?.pivot?.user_id;
            formData.created_at = getUtcDate();
            formData.updated_at = getUtcDate();
            formData.party_address.forEach((address) => {
              delete address.id;
            });
            delete formData.id;
            delete formData.status;
            delete formData.shippingAddressBackup;
            //delete formData.ledger_type
            ;
            dispatch(createParty({
              data: formData, token: accessToken.access_token,
              tenant_id: tenantId, terminal_id: terminalId
            }));
          } catch (error) {
            setLoading(false);
          }
        }}
      >
        {({
          values,
          errors,
          handleChange,
          handleSubmit,
          touched,
          setFieldValue,
          handleBlur,
        }) => (
          <>

            <Modal
              show={show}
              keyboard={true}
              onHide={handleCancel}
              size="lg"
              dialogClassName="modal-dialog modal-dialog-centered"
            >
              <Modal.Header closeButton>
                <h2 className="bold">{i18n("Create Party")}</h2>
              </Modal.Header>
              {errorStatus && (
                <AlertMessage
                  errorStatus={errorStatus}
                  error={error}
                  handleAlertClose={handleAlertClose}
                />
              )}

              <Modal.Body className="py-2" style={{ height: "500px" }}>
                <Tabs
                  id="controlled-tab-example"
                  activeKey={key}
                  onSelect={(k) => setKey(k)}
                  className="mb-3 custom-nav-css"
                  variant="underline"
                  style={{
                    borderBottom: "1px solid lightgray",
                    lineHeight: "30px",
                    gap: "30px",
                  }}
                >
                  <Tab
                    className=""
                    eventKey="general"
                    title={
                      <div
                        style={{
                          color: `${(touched.name && errors.name) ||
                              (touched.phone_number && errors.phone_number)
                              ? "rgb(246, 41, 71)"
                              : ""
                            }`,
                        }}
                      >
                        {i18n("General Details")} <span className="required" />
                      </div>
                    }
                  >
                    <CreateGeneralDetails
                      handleChange={handleChange}
                      i18n={i18n}
                      placeholderText={placeholderText}
                      errors={errors}
                      values={values}
                      touched={touched}
                      handleBlur={handleBlur}
                      numValidate={numValidate}
                      handleCategory={handleCategory}
                      filterData={filterData}
                    />
                    <CreateBusinessDetails
                      handleChange={handleChange}
                      i18n={i18n}
                      placeholderText={placeholderText}
                      errors={errors}
                      values={values}
                      filterData={filterData}
                      handleCategory={handleCategory}
                      setFieldValue={setFieldValue}
                      type={type}
                    />
                  </Tab>

                  <Tab eventKey="address" title={i18n("Address Details")}>
                    <Col className="px-0 text-end">
                      <Button
                        className="px-4"
                        variant="link"
                        onClick={handleMultipleAddressClick}
                        style={{ fontSize: "15px" }}
                      >
                        {i18n("+ Add")}
                      </Button>
                    </Col>
                    {party_address.length == 0 && (
                      <Col className="d-flex justify-content-center align-items-center">
                        No Address
                      </Col>
                    )}

                    {party_address.length > 0 && (
                      <MultipleAddress
                        handleEdit={handleEdit}
                        handleDelete={handleDelete}
                      />
                    )}
                  </Tab>
                </Tabs>
              </Modal.Body>
              <Modal.Footer style={{ borderTop: "1px solid lightgray" }}>
                <Row className="py-1">
                  {values && (
                    <Col className="d-flex justify-content-end">
                      {!loading ? (
                        <Button
                          id="submitButton"
                          ref={saveButtonRef}
                          variant="primary mx-2"
                          onClick={handleSubmit}
                          disabled={loading}
                        >
                          {i18n("Save")}
                        </Button>
                      ) : (
                        <div className="d-flex align-items-center mx-2">
                          <Spinner animation="border" size="md" />
                          <span className="ml-2">{i18n("Saving...")}</span>
                        </div>
                      )}
                      <Button
                        variant="outline-secondary mx-2"
                        onClick={handleCancel}
                        disabled={loading}
                      >
                        {i18n("Cancel")}
                      </Button>
                    </Col>
                  )}
                </Row>
              </Modal.Footer>
            </Modal>
            <CreateCategory
              show={showCategory}
              handleClose={() => setShowCategory(false)}
              type="party"
              hideTypeDropdown={true}
              caller="part1"

            />
            {showAddress && (
              <Address
                show={showAddress}
                setShow={setShowAddress}
                id={addressId}
                click={click}
                handleChange={handleChange}
                setClick={setClick}
                i18n={i18n}
                placeholderText={placeholderText}
                setFieldValue={setFieldValue}
                errors={errors}
                values={values}
                touched={touched}
                handleBlur={handleBlur}
                numValidate={numValidate}
              />
            )}
          </>
        )}
      </Formik>
    </>
  );
};

export default CreateParty;
