import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import { axiosRequest } from "../../shared/helpers/axiosHelper";

const initialState = {
  product: {
    id: "",
    type: "goods",
    name: "",
    category_id: null,
    code: "",
    cost_price: 0,
    sale_price: 0,
    description: "",
    hsn: "",
    tax_code: "GST_NONE",
    unit: "PCS",
    is_tax_included: 1,
    is_tax_included_for_purchase: 1,
    created_by: null,
    updated_by: null,
    brand_id: null, 
    warehouse_id: 1, 
    quantity: 0,
    inventories: [],
    stock:[]
  },
  status: false,
  errorStatus:false,
  error: {},
  savedEntity: {},
  importData: {},
  bulkImport: {},
};

export const addProduct = createAsyncThunk(
  "productEntity/addProduct",
  async (args) => {
    try {
            const token = args.token;
            const data = args.data;
            console.log("data",data)
            const tenantId = args.tenant_id;
            const resourceType = 'catalog_product';
            const terminalNumber = args.terminal_id;            
            const addProductResponse = await axiosRequest({
                method: "POST",
                url: `resource/${resourceType}/create/${terminalNumber}`,
                data: data,
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                    "X-Tenant": tenantId,
                },
            });
 
      if (addProductResponse.success) {
        return addProductResponse.data;
      } else {
        const errorMessage = addProductResponse?.data?.map((d) => d.message).join(", ");
        throw errorMessage;
        
      }
    } catch (error) {
      // await window.log.logToFile({message: "Unable to create product", level: "error" });
      throw new Error (error);
    }
  }
);

export const editProduct = createAsyncThunk(
  "productEntity/edit",
  async (args) => {
    try {
            const token = args.token;
            const data = args.data;
            const tenantId = args.tenant_id;
            const resourceType = 'catalog_product';
            const terminalNumber = args.terminal_id;  
      const updateProductresponse =await axiosRequest({
        method: "POST",
        url: `resource/${resourceType}/create/${terminalNumber}`,
        data: data,
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            "X-Tenant": tenantId,
        },
    });
      if (updateProductresponse.success) {
      return updateProductresponse.data;
    } else {
      const errorMessage = updateProductresponse?.data?.map((d) => d.message).join(", ");
      throw errorMessage;
    }
    } catch (error) {
      await window.log.logToFile({message: "Unable to update Product", level: "error" });
      throw new Error (error);
    }
  }
);

const productEntitySlice = createSlice({
  name: "productEntity",
  initialState,
  reducers: {
    changeType(state, action) {
      state.product.type = action.payload;
    },
    changeName(state, action) {
      state.product.name = action.payload;
    },
    changeCategory(state, action) {
      state.product.category_id = action.payload;
    },
    changeCode(state, action) {
      state.product.code = action.payload;
    },
    changeDescription(state, action) {
      state.product.description = action.payload;
    },
    changeQuantity(state, action) {
      state.product.quantity = action.payload;
    },
    changeUnit(state, action) {
      state.product.unit = action.payload;
    },
    changeSalePrice(state, action) {
      state.product.sale_price = action.payload;
    },
    changeCostPrice(state, action) {
      state.product.cost_price = action.payload;
    },
    changeHsnCode(state, action) {
      state.product.hsn = action.payload;
    },
    changeTaxCode(state, action) {
      
      state.product.tax_code = action.payload;
    },
    changeIsTaxInclude(state, action) {
      state.product.is_tax_included = action.payload;
    },
    changeIsTaxIncludePurchase(state, action) {
      state.product.is_tax_included_for_purchase = action.payload;
    },
    updateProductImport(state, action) {
      state.bulkImport = action.payload;
    },
    handleProductOneByOne: (state, action) => {
      if (!Array.isArray(state.bulkImport)) {
        state.bulkImport = [];
      }
      state.bulkImport.push(action.payload);
    },
    initProductEdit(state, action) {
      let tempProduct = action.payload;
      let processedProduct = cloneDeep(tempProduct);
      delete processedProduct.inventories;
      delete processedProduct.id;
      delete processedProduct.category_product;
      processedProduct.quantity =
        tempProduct.inventories && tempProduct.inventories[0]?.quantity
          ? tempProduct.inventories[0]?.quantity
          : 0;
      processedProduct.category_id = tempProduct?.category_product?.id;
      processedProduct.warehouse_id = 1; 
      state.product = processedProduct;
    },
    undoProductError(state,action){
      state.errorStatus= false
      },
    resetCreateProduct: () => initialState,
  },
  extraReducers(builder) {
    builder
      .addCase(addProduct.fulfilled, (state, action) => {
        state.status = true;
        state.savedEntity = action.payload;
      })
      .addCase(addProduct.rejected, (state, action) => {
        state.errorStatus = true;
        state.error = action?.error?.message;
      })
      .addCase(editProduct.fulfilled, (state, action) => {
        state.status = true;
        state.savedEntity = action.payload;
      })
      .addCase(editProduct.rejected, (state, action) => {
        state.errorStatus = true;
        state.error = action?.error?.message;
      });
  },
});

export default productEntitySlice.reducer;
export const {
  resetCreateProduct,
  changeType,
  changeCategory,
  changeName,
  changeCode,
  changeDescription,
  changeTaxCode,
  changeHsnCode,
  changeCostPrice,
  changeSalePrice,
  changeUnit,
  changeQuantity,
  initProductEdit,
  changeIsTaxInclude,
  changeIsTaxIncludePurchase,
  updateProductImport,
  handleProductOneByOne,
  undoProductError,

} = productEntitySlice.actions;
