import {
    createSlice,
    createAsyncThunk,
  } from "@reduxjs/toolkit";


  const initialState = {
    status:false,
    reportItems:[],
    error:false,
    errorMessage:{}
  };

export const fetchPartyCategoryFilterChart = createAsyncThunk('partyCategoryFilterChart/fetch', async (param) => {
  try{
    const partyCategoryFilterChartResponse  = await window.api.partyCategoryFilterChart(param);
    return partyCategoryFilterChartResponse.data;
  }catch(err){
    await window.log.logToFile({message: "Error while fetching Party Category filter", level: "error" });
    throw new Error("Error while fetching Party Category Filter Chart")
  }
});

const partyCategoryFilterChartSlice = createSlice({
    name: "partyCategoryChart",
    initialState,
    reducers: {},
    extraReducers(builder) {
      builder
      .addCase(fetchPartyCategoryFilterChart.fulfilled, (state, action) => {
        state.status = true
        state.reportItems =action.payload
      })
      .addCase(fetchPartyCategoryFilterChart.rejected, (state, action) => {
        state.error = true; 
        state.errorMessage=action.error
        state.status = false;
      })

    },
});

export default partyCategoryFilterChartSlice.reducer;
