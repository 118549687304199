import React, { useEffect, useState } from "react";
import { Col, Container, Modal, Row, Card, Table, Form, Button, Dropdown, ButtonGroup, InputGroup } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { deletePayment, getPayment, removePaymentData, resetDeleteStatus, selectVoucherPaymentById } from "../../store/paymentSlice";
import { format } from "date-fns";
import { i18n } from "../../../shared/helpers/sharedMethods";
import { formatAmount } from "../../../pos/helpers/cartHelper";
import { getDate } from "../../../shared/helpers/localizationHelper";
import GlobalCurrency from "../../../shared/components/GlobalCurrency";
import { UilEdit, UilTrashAlt, UilUsersAlt, UilAngleDown } from "@iconscout/react-unicons";
import DeleteModel from "../../../shared/components/DeleteModel";
import { useNavigate } from "react-router";
import { resetPayment } from "../../store/paymentEntitySlice";
import { resetPartyPayment } from "../../store/partyPaymentsSlice";
import { cloneDeep } from "lodash";
import { selectSalesById, updateVoucherPartialPayment } from "../../../voucher/store/voucherSlice";

const PaymentDetailsModal = (props) => {
  const { show, handleClose, id, rowData } = props;
  const rootPath ='voucher'
  const payment_in_path = rootPath+'/payment-in'
  const payment_out_path = rootPath+'/payment-out'
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [Id, setId] = useState();
  const [showDelete, setShowDelete] = useState(false);
  const [name, setName] = useState();
  const { accessToken, isLoggedIn } = useSelector((state) => state?.auth);
  const { terminalId } = useSelector((state) => state.initInstall);
  const selectedBusiness = useSelector(
    (state) => state?.auth?.selectedBusiness
  );
  const tenantId = selectedBusiness?.pivot?.tenant_id;
  const selectedSale = useSelector((state) => selectSalesById(state, rowData?.vouchers[0]?.voucher_id));

  const { deleteStatus } = useSelector((state) => state.payment);
  //console.log("deleteStatus", deleteStatus)


  useEffect(() => {
    if (deleteStatus) {
      console.log("selectedSale payment", selectedSale)
      console.log("selectedSale payment", Id)
      const updatedData = selectedSale?.voucher_payments.filter(item => item.payment_id !== Id);

      let paymentData = cloneDeep(selectedSale);
      paymentData?.voucher_items?.forEach((item) => {
        item.id = item.product_id;
      });
      delete paymentData.voucher_payments;

      paymentData.voucher_payments = updatedData

      paymentData.paid_amount = updatedData.length > 0 ? updatedData.reduce((sum, item) => sum + item.payment.amount, 0) : 0;

      console.log("selectedSale payment", paymentData);

      dispatch(updateVoucherPartialPayment({ data: paymentData, token: accessToken.access_token, tenant_id: tenantId, terminal_id: terminalId }));
      dispatch(resetDeleteStatus())
      handleClose();
    }
  }, [deleteStatus])

  const handleDelete = (delRow) => {
    ;
    setShowDelete(!showDelete);
    setId(delRow.id);
    setName(delRow.name);
  };

  const onDelete = () => {
    dispatch(deletePayment({
      data: { id: Id },
      token: accessToken.access_token,
      tenant_id: tenantId,
      terminal_id: terminalId
    }));
    setShowDelete(false);

  };

  const selectPaymentById = useSelector((state) =>
    selectVoucherPaymentById(state, id)
  );
  console.log("selectPaymentById", selectPaymentById)
  const currencySymbol = useSelector((state) => state.getSetting?.dataSetting.currency);
  ;


  const handleEdit = () => {
    const { type } = rowData;
    dispatch(resetPayment());
    dispatch(resetPartyPayment());
    ;
    const voucher_ids = [];
    rowData.vouchers.length > 0 &&
      rowData.vouchers.map((data, index) => {
        const voucher_id = data.voucher_id;
        voucher_ids.push(voucher_id);
      });
    ;
if(type === 'payment_in'){
    navigate(`/${payment_in_path}/edit/${rowData?.id}`, {
      state: { editData: rowData, voucher_ids: voucher_ids },
    });
  }
  else {
    navigate(`/${payment_out_path}/edit/${rowData?.id}`, {
      state: { editData: rowData, voucher_ids: voucher_ids },
    });  }
  };


  const fontStyle = { fontSize: '13px', fontWeight: 600, color: "#687E96" }
  const dataStyle = { fontSize: '13px', fontWeight: 400, color: "black" }


  return (
    <Modal
      show={show}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={handleClose}

    >
      {/* <Modal.Header closeButton> */}
      {/* <Modal.Title>{i18n("Payment")} #{selectPaymentById?.payment_number}</Modal.Title> */}
      {/* </Modal.Header> */}
      <Modal.Body>
        <Row className="mb-5">
          <Col lg={6} className="d-flex align-items-center ">
            <Form.Label className="mb-0" style={{ fontSize: '15px', fontWeight: 600, }}>{i18n("Payment")}&nbsp; -&nbsp; #{selectPaymentById?.payment_number}</Form.Label>
          </Col>
          <Col lg={6} className="d-flex align-items-center justify-content-end">
            <InputGroup className=" custom-input mx-4">
              <Button
                variant="outline-secondary"
                onClick={handleEdit}
                size="sm"
                style={{ padding: '0.5rem', background: 'none' }}
              >
                <UilEdit className="mx-2 mb-1" size="16" />
                {i18n("Edit")}
              </Button>

              <Dropdown as={ButtonGroup}>
                <Dropdown.Toggle
                  variant="outline-secondary"
                  id="dropdown-custom"
                  size="sm"
                  style={{ height: '36px' }}
                >
                  <UilAngleDown size="16" />
                </Dropdown.Toggle>

                <Dropdown.Menu className="custom-dropdown-menu">
                  <Dropdown.Item
                    className="invoice-dropdown-item"
                    onClick={() => handleDelete(selectPaymentById)}
                    style={{ color: 'red' }}
                  >
                    <UilTrashAlt className="mx-2 mb-1" size="16" style={{ color: 'red' }} />
                    {i18n("Delete")}
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </InputGroup>
            <Button
              variant=""
              className="btn-close "
              onClick={handleClose}
              aria-label="Close"

            />
          </Col>
        </Row>
        <Card className="border">

          <Card.Body className="" >
            <Row>
              <Col lg={3} >
                <Form.Label style={fontStyle}>{i18n("Name")}</Form.Label>
              </Col>
              <Col lg={3} >
                <Form.Label style={fontStyle}>{i18n("Phone")}</Form.Label>
              </Col>
              <Col lg={3} >
                <Form.Label style={fontStyle}>{i18n("Type")}</Form.Label>
              </Col>
              <Col lg={3} >
                <Form.Label style={fontStyle}>{i18n("Amount")}</Form.Label>
              </Col>
            </Row>
            <Row>
              <Col lg={3} >
                <Form.Label style={dataStyle}>{selectPaymentById?.payment_party?.name}</Form.Label>
              </Col>
              <Col lg={3} >
                <Form.Label style={dataStyle}>{selectPaymentById?.payment_party?.phone_number}</Form.Label>
              </Col>
              <Col lg={3} >
                <Form.Label style={dataStyle}>{selectPaymentById?.payment_party?.type}</Form.Label>
              </Col>
              <Col lg={3} >
                <Form.Label style={dataStyle}> <GlobalCurrency price={selectPaymentById?.amount} /> </Form.Label>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col lg={3} >
                <Form.Label style={fontStyle}>{i18n("Payment Mode")}</Form.Label>
              </Col>
              <Col lg={3} >
                <Form.Label style={fontStyle}>{i18n("Payment Date")}</Form.Label>
              </Col>
              <Col lg={3} >
                <Form.Label style={fontStyle}>{i18n("Notes")}</Form.Label>
              </Col>
            </Row>
            <Row className="">
              <Col lg={3} >
                <Form.Label style={dataStyle}>{selectPaymentById?.mode}</Form.Label>
              </Col>
              <Col lg={3} >
                <Form.Label style={dataStyle}>{getDate(selectPaymentById?.created_at)}</Form.Label>
              </Col>
              <Col lg={3} >
                <Form.Label style={dataStyle}></Form.Label>
              </Col>

            </Row>
          </Card.Body>
          <Card.Footer className="" style={{ borderTop: '1px solid lightgray' }} >
            <Col className="">


              <div
                className="align-items-start d-flex flex-direction-row  mb-2"
                style={{ color: "#687E96", fontSize: "13px" }}
              >
                <span className="fw-bold">{i18n("Settled Payments")}</span>
              </div>


              <div className="table-responsive">
                {selectPaymentById?.vouchers?.length > 0 ? <Table bordered hover responsive>
                  <thead>
                    <tr className="fw-bold">
                      <th className="text-center" style={{ backgroundColor: '#F9FAFB' }}>{i18n("DATE")}</th>
                      <th className="text-center" style={{ backgroundColor: '#F9FAFB' }}>{i18n("TYPE")}</th>
                      <th className="text-center" style={{ backgroundColor: '#F9FAFB' }}>{i18n("VOUCHER ID")}</th>
                      <th className="text-center" style={{ backgroundColor: '#F9FAFB' }}>{i18n("TXN NO")}</th>
                      <th className="text-center" style={{ backgroundColor: '#F9FAFB' }}>{i18n("AMOUNT")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {selectPaymentById.vouchers?.map((value, index) => (
                      <tr key={index}>
                        <td className="text-center fs-6">
                          {value.created_at ? format(new Date(value.created_at), 'dd-MM-yyyy') : format(new Date(selectPaymentById.created_at), 'dd-MM-yyyy')}
                        </td>
                        <td className="text-center fs-6">
                          {selectPaymentById.type ? selectPaymentById.type.replace(/_/g, ' ') : '-'}
                        </td>
                        <td className="text-center fs-6">
                          {value.voucher_id ? value.voucher_id : '-'}
                        </td>
                        <td className="text-center fs-6">
                          {selectPaymentById?.voucher_number}
                        </td>
                        <td className="text-center fs-6">
                          <GlobalCurrency price={value.amount ? (value.amount) : '-'} />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                  :
                  <Col className="mb-8">
                    {i18n("There is no more voucher payment")}
                  </Col>

                }

              </div>
            </Col>

          </Card.Footer>
        </Card>
      </Modal.Body>

      {showDelete && (
        <DeleteModel
          deleteUserClick={onDelete}
          onClickDeleteModel={setShowDelete}
          name={name}
        />
      )}
    </Modal>
  );
};
export default PaymentDetailsModal;