import React, { useState, useEffect } from "react";
import { Col, Container, Row, Table, Form, Image } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { ToWords } from "to-words";
import moment from "moment";
import { i18n } from "../../../../shared/helpers/sharedMethods";
import {
  getSubscriptionVoucher,
  selectSubscriptionVoucherById,
} from "../../../store/getAllSubscriptionVoucherSlice";
import QRCode from "react-qr-code";
import { calculateTotalQuantity } from "../../../../shared/helpers/invoiceHelper";
import { placeOfSupplyList } from "../../../../shared/config/placeOfSupply";
import { selectSelectedImage } from "../../../../settings/store/settingSlice";
import logo from "../../../../../assets/images/bill.png";
import { formatAmount } from "../../../../pos/helpers/cartHelper";
import GlobalCurrency from "../../../../shared/components/GlobalCurrency";

const SubscriptionAllInvoiceDefault = ({ pdf, id, data }) => {
  const dispatch = useDispatch();

  const selectedBusiness = useSelector(
    (state) => state?.auth?.selectedBusiness
  );

  const accessToken = useSelector(
    (state) => state?.auth?.accessToken?.access_token
  );
  const tenantId = selectedBusiness?.pivot?.tenant_id;

  const subData = useSelector(
    (state) => state.subscriptionVoucher.subscriptionVoucherById
  );
  const [subsData, setSubsData] = useState(subData);
  const updateStatus = useSelector(
    (state) => state.subscriptionReceivedAmount.status
  );
  const subscriptionTotal = subsData?.voucher_subscription?.subscription;
  useEffect(() => {
    if (subData) {
      setSubsData(subData[0]);
    }
  }, [subData]);

  useEffect(
    () => {
      dispatch(
        getSubscriptionVoucher({
          id: id,
          tenantId: tenantId,
          token: accessToken,
        })
      );
    },
    { id, updateStatus }
  );

  const grandTotal = formatAmount(subsData?.grand_total) ?? 0;
  const paidAmount = formatAmount(subsData?.paid_amount) ?? 0;
  const balanceAmount = formatAmount(grandTotal - paidAmount);
  const words = new ToWords().convert(grandTotal, { currency: true });
  const totalQuantity = calculateTotalQuantity(subsData?.voucher_items);

  const selectImage = useSelector(selectSelectedImage);
  const termsAndCondition = useSelector(
    (state) => state.setting?.billing_settings?.terms_and_conditions
  );
  const fetchData = useSelector((state) => state.getSetting?.dataSetting);
  const customerState = placeOfSupplyList.find(
    (option) => option.value == subsData?.party?.party_addresses?.[0]?.state
  );
  const state = placeOfSupplyList.find(
    (option) => option.value == fetchData?.state
  );

  const bankDetailsCheck =
    fetchData?.card_holder_name ||
    fetchData?.account_number ||
    fetchData?.ifsc_code ||
    fetchData?.bank ||
    fetchData?.branch_name;

  const generateQRCode = (UPIPayment) => {
    const upiLink = `upi://pay?pa=${UPIPayment}`;
    return (
      <QRCode
        value={upiLink}
        size={90}
        bgColor={"#ffffff"}
        fgColor={"#000000"}
        level={"L"}
      />
    );
  };
  return (
    <>
      <Container
        fluid
        className="pb-5"
        ref={pdf}
        style={{ backgroundColor: "white" }}
      >
        <Row>
          <Col className=" p-2 m-0">
            <p style={{ fontWeight: "bolder" }}>
              {i18n("TAX INVOICE")}
              <span
                className="border border-secondary px-2 mx-2"
                style={{ fontWeight: 500 }}
              >
                {i18n("ORIGINAL FOR RECIPIENT")}
              </span>
            </p>
          </Col>
        </Row>
        <Row>
          <Col
            lg={3}
            className="d-flex align-items-center justify-content-center"
          >
            <img
              src={selectImage ? selectImage : logo}
              width={120}
              height={80}
              alt="Logo"
              style={{
                objectFit: "contain",
              }}
            />
          </Col>
          <Col lg={9}>
            <Row className="mt-5">
              <Row>
                <Col>
                  <Form.Label
                    className=""
                    style={{ fontSize: "28px", fontWeight: 600 }}
                  >
                    {fetchData?.business_name}
                  </Form.Label>
                  <Form.Group>
                    <Form.Label className="mb-0">
                      {fetchData?.business_address
                        ? fetchData?.business_address + ","
                        : ""}
                      &nbsp; {fetchData?.city ? fetchData?.city + "," : ""}
                      &nbsp; {state?.label ? state?.label + "-" : ""}
                      &nbsp;&nbsp; {fetchData?.pincode}
                    </Form.Label>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                {fetchData?.business_phone && (
                  <Col lg={4}>
                    <Form.Label style={{ fontWeight: 600 }} className="mb-0">
                      {i18n("Phone")} :{" "}
                    </Form.Label>{" "}
                    &nbsp;{" "}
                    <Form.Label>
                      {" "}
                      {fetchData?.business_phone
                        ? fetchData?.business_phone
                        : ""}
                    </Form.Label>
                  </Col>
                )}
                {fetchData?.gstin && (
                  <Col lg={4}>
                    <Form.Label className="mb-0" style={{ fontWeight: 600 }}>
                      {i18n("GSTIN")} :
                    </Form.Label>{" "}
                    <Form.Label>
                      {" "}
                      &nbsp; {fetchData?.gstin ? fetchData?.gstin : ""}
                    </Form.Label>
                  </Col>
                )}
                {fetchData?.pan && (
                  <Col lg={4}>
                    <Form.Label className="mb-0" style={{ fontWeight: 600 }}>
                      {i18n("PAN Number")} :{" "}
                    </Form.Label>{" "}
                    &nbsp; {fetchData?.pan ? fetchData?.pan : ""}
                  </Col>
                )}
              </Row>
            </Row>
          </Col>
        </Row>
        <Row className="mt-5">
          <Col>
            <Row>
              <Col
                style={{
                  height: "6px",
                  width: "100%",
                  backgroundColor: "black",
                }}
              ></Col>
            </Row>
            <Row
              style={{
                height: "40px",
                backgroundColor: "#E2E2E2",
                alignItems: "center",
                justifyContent: "space-between",
                fontSize: "14px",
              }}
            >
              <Col>
                <b>{i18n("Invoice No")} :</b> {subsData?.voucher_number}
              </Col>
              <Col>
                <b>{i18n("Invoice Date")} :</b>{" "}
                {moment(subsData?.voucher_subscription?.start_date).format(
                  "DD-MM-YYYY"
                )}
              </Col>
              <Col>
                <b>{i18n("Subscription No")} :</b>{" "}
                {
                  subsData?.voucher_subscription?.subscription
                    ?.subscription_number
                }
              </Col>

              {subsData?.due_date && (
                <Col>
                  <b>{i18n("Due Date")} :</b>{" "}
                  {moment(
                    subsData?.voucher_subscription?.next_bill_date
                  ).format("DD-MM-YYYY")}
                </Col>
              )}
            </Row>
          </Col>
        </Row>
        <Row className="mt-2">
          <Col
            md={subsData?.voucher_subscription?.subscription?.member ? 5 : 6}
            py={2}
            className="px-0 py-3"
          >
            <Row>
              <Col
                style={{
                  fontSize: "13px",
                  fontWeight: "500",
                  marginBottom: "5px",
                }}
              >
                {i18n("BILL TO")}
              </Col>
            </Row>
            <Row>
              <Col style={{ fontWeight: "600", fontSize: "14px" }}>
                {subsData?.party?.name ? subsData?.party?.name : "-"}
              </Col>
            </Row>
            <Row>
              <Col>
                {subsData?.party?.party_addresses?.[0]?.address && (
                  <Form.Group>
                    <Form.Label className="mb-0">
                      {subsData?.party?.party_addresses?.[0]?.address}, &nbsp;{" "}
                      {subsData?.party?.party_addresses?.[0]?.city}, &nbsp;{" "}
                      {customerState?.label} &nbsp;- &nbsp;{" "}
                      {subsData?.party?.party_addresses?.[0]?.pincode}
                    </Form.Label>
                  </Form.Group>
                )}
              </Col>
            </Row>
            <Row>
              <Col>
                {subsData?.party?.phone_number && (
                  <Form.Group>
                    <Form.Label className="mb-0">{i18n("Phone")} :</Form.Label>
                    &nbsp; {subsData?.party?.phone_number}
                  </Form.Group>
                )}
              </Col>
            </Row>
            <Row>
              <Col>
                {subsData?.party?.gstin && (
                  <Form.Group className="">
                    <Form.Label className="mb-0">{i18n("GSTIN")} :</Form.Label>
                    &nbsp; {subsData?.party?.gstin}
                  </Form.Group>
                )}
              </Col>
            </Row>
            <Row>
              <Col>
                {subsData?.party?.pan && (
                  <Form.Group>
                    <Form.Label className="mb-0">
                      {i18n("PAN Number")} :
                    </Form.Label>
                    &nbsp; {subsData?.party?.pan}
                  </Form.Group>
                )}
              </Col>
            </Row>
          </Col>
          <Col
            md={subsData?.voucher_subscription?.subscription?.member ? 5 : 6}
            py={2}
            className="px-0 py-3"
          >
            <Col
              style={{
                fontSize: "13px",
                fontWeight: "500",
                marginBottom: "5px",
              }}
            >
              {i18n("SHIP TO")}
            </Col>
            <Row>
              <Col style={{ fontWeight: "600", fontSize: "14px" }}>
                {subsData?.party?.name ? subsData?.party?.name : "-"}
              </Col>
            </Row>
            <Row>
              <Col>
                {subsData?.party?.party_addresses?.[0]?.address && (
                  <Form.Group>
                    <Form.Label className="mb-0">
                      {subsData?.party?.party_addresses?.[0]?.address}, &nbsp;{" "}
                      {subsData?.party?.party_addresses?.[0]?.city}, &nbsp;{" "}
                      {customerState?.label} &nbsp;- &nbsp;{" "}
                      {subsData?.party?.party_addresses?.[0]?.pincode}
                    </Form.Label>
                  </Form.Group>
                )}
              </Col>
            </Row>
            <Row>
              <Col>
                {subsData?.party?.phone_number && (
                  <Form.Group>
                    <Form.Label>{i18n("Phone")} :</Form.Label>
                    &nbsp; {subsData?.party?.phone_number}
                  </Form.Group>
                )}
              </Col>
            </Row>
          </Col>
          {subsData?.voucher_subscription?.subscription?.member && (
            <Col className="p-2 m-0">
              <Row>
                <Col
                  style={{
                    fontSize: "13px",
                    fontWeight: "500",
                    marginBottom: "5px",
                  }}
                >
                  {i18n("Member")}
                </Col>
              </Row>
              <Row>
                <Col style={{ fontWeight: "600", fontSize: "14px" }}>
                  {subsData?.voucher_subscription?.subscription?.member
                    ?.member_name
                    ? subsData?.voucher_subscription?.subscription?.member
                        ?.member_name
                    : "-"}
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group>
                    <Form.Label className="mb-0">{i18n("Phone")} :</Form.Label>
                    &nbsp;{" "}
                    {
                      subsData?.voucher_subscription?.subscription?.member
                        ?.mobile
                    }
                  </Form.Group>
                </Col>
              </Row>
            </Col>
          )}
        </Row>
        <Row className="mt-5">
          <Table className="table table-borderless">
            <thead>
              <tr
                style={{
                  backgroundColor: "#E2E2E2",
                  borderBottom: "1px solid black",
                  borderTop: "1px solid black",
                }}
              >
                <th
                  style={{
                    fontSize: "13px",
                    fontWeight: 500,
                  }}
                >
                  {i18n("ITEMS")}
                </th>
                <th
                  style={{
                    textAlign: "center",
                    fontSize: "13px",
                    fontWeight: 500,
                  }}
                >
                  {i18n("QTY")}
                </th>
                <th
                  style={{
                    textAlign: "center",
                    fontSize: "13px",
                    fontWeight: 500,
                  }}
                >
                  {i18n("RATE")}
                </th>
                {subsData?.discount_amount > 0 && (
                  <th
                    style={{
                      textAlign: "center",
                      fontSize: "13px",
                      fontWeight: 500,
                    }}
                  >
                    {i18n("DISC")}
                  </th>
                )}
                {subsData?.tax_total > 0 && (
                  <th
                    style={{
                      textAlign: "center",
                      fontSize: "13px",
                      fontWeight: 500,
                    }}
                  >
                    {i18n("TAX")}
                  </th>
                )}
                {subsData?.cess_total > 0 && (
                  <th
                    style={{
                      textAlign: "center",
                      fontSize: "13px",
                      fontWeight: 500,
                    }}
                  >
                    {i18n("CESS")}
                  </th>
                )}
                <th
                  style={{
                    textAlign: "end",
                    fontSize: "13px",
                    fontWeight: 500,
                  }}
                >
                  {i18n("AMOUNT")}
                </th>
              </tr>
            </thead>
            <tbody>
              {subsData?.voucher_items &&
                subsData?.voucher_items?.length > 0 &&
                subsData?.voucher_items?.map((data, index) => (
                  <tr key={index}>
                    <td
                      className=""
                      style={{
                        fontSize: "13px",
                        fontWeight: 400,
                        borderBottom: "1px solid #E2E2E2",
                      }}
                    >
                      {data?.product_name}
                    </td>
                    <td
                      className=""
                      style={{
                        textAlign: "center",
                        fontSize: "13px",
                        fontWeight: 400,
                        borderBottom: "1px solid #E2E2E2",
                      }}
                    >
                      {data?.quantity} {data?.unit}
                    </td>
                    <td
                      className=""
                      style={{
                        textAlign: "center",
                        fontSize: "13px",
                        fontWeight: 400,
                        borderBottom: "1px solid #E2E2E2",
                      }}
                    >
                      <GlobalCurrency price={data?.taxable_value} />
                    </td>
                    {subsData?.discount_amount > 0 && (
                      <td
                        className=""
                        style={{
                          textAlign: "center",
                          fontSize: "13px",
                          fontWeight: 400,
                          borderBottom: "1px solid #E2E2E2",
                        }}
                      >
                        <GlobalCurrency price={data?.item_discount_amount} />
                        <span style={{ fontSize: "13px", color: "grey" }}>
                          &nbsp;
                          {data?.item_discount
                            ? `(${data?.item_discount}%)`
                            : "(0%)"}
                        </span>
                      </td>
                    )}
                    {subsData?.tax_total > 0 && (
                      <td
                        className=""
                        style={{
                          textAlign: "center",
                          fontSize: "13px",
                          fontWeight: 400,
                          borderBottom: "1px solid #E2E2E2",
                        }}
                      >
                        <span style={{ marginTop: "0px" }}>
                          <GlobalCurrency price={data?.tax_total} />
                        </span>
                        <span style={{ fontSize: "11px", color: "#6c757d" }}>
                          {data?.tax_rate ? `(${data?.tax_rate}%)` : "(0%) "}
                        </span>
                      </td>
                    )}
                    {subsData?.cess_total > 0 && (
                      <td
                        className=""
                        style={{
                          textAlign: "center",
                          fontSize: "13px",
                          fontWeight: 400,
                          borderBottom: "1px solid #E2E2E2",
                        }}
                      >
                        <span style={{ marginTop: "0px" }}>
                          <GlobalCurrency price={data?.cess} />
                        </span>
                        <span style={{ fontSize: "11px", color: "#6c757d" }}>
                          {data?.tax_rate ? `(${data?.cess_rate}%)` : "(0%) "}
                        </span>
                      </td>
                    )}

                    <td
                      className=""
                      style={{
                        textAlign: "end",
                        borderBottom: "13px",
                        fontWeight: 400,
                        borderBottom: "1px solid #E2E2E2",
                      }}
                    >
                      <GlobalCurrency price={data?.net_total} />
                    </td>
                  </tr>
                ))}
            </tbody>
            <tfoot>
              <Col style={{ minHeight: "100px" }}> </Col>
              <tr className="bold">
                <td
                  className="border-top-bottom"
                  style={{ fontSize: "13px", fontWeight: 600 }}
                >
                  {i18n("SUB TOTAL")}
                </td>
                <td
                  className="border-top-bottom"
                  style={{
                    fontSize: "13px",
                    fontWeight: 600,
                    textAlign: "center",
                  }}
                >
                  {totalQuantity}
                </td>
                <td
                  className="border-top-bottom"
                  style={{ fontSize: "13px", fontWeight: 600 }}
                ></td>
                {subsData?.discount_amount > 0 && (
                  <td
                    className="border-top-bottom"
                    style={{ fontSize: "13px", fontWeight: 600, textAlign: "center", }}
                  >
                    <GlobalCurrency price={subscriptionTotal?.discount_amount} />
                  </td>
                )}
                {subsData?.tax_total > 0 && (
                  <td
                    className="border-top-bottom"
                    style={{
                      fontSize: "13px",
                      fontWeight: 600,
                      textAlign: "center",
                    }}
                  >
                    <GlobalCurrency price={subscriptionTotal?.tax_total} />
                  </td>
                )}
                 {subsData?.cess_total > 0 && (
                  <td
                    className="border-top-bottom"
                    style={{
                      fontSize: "13px",
                      fontWeight: 600,
                      textAlign: "center",
                    }}
                  >
                    <GlobalCurrency price={subscriptionTotal?.cess_total} />
                  </td>
                )}

                <td
                  className="border-top-bottom"
                  style={{
                    fontSize: "13px",
                    fontWeight: 600,
                    textAlign: "end",
                  }}
                >
                  <GlobalCurrency price={subscriptionTotal?.grand_total} />
                </td>
              </tr>
            </tfoot>
          </Table>
        </Row>

        <Row classname="">
          <Col lg={6}>
            {bankDetailsCheck && (
              <Col className="mb-3">
                <Form.Label
                  className=""
                  style={{ fontSize: "13px", fontWeight: 600 }}
                >
                  {i18n("BANK DETAILS")}
                </Form.Label>{" "}
                <br />
                {fetchData?.card_holder_name && (
                  <>
                    <Form.Label className="mb-1">
                      {i18n("Name")}:
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      {fetchData?.card_holder_name}{" "}
                    </Form.Label>{" "}
                    <br />
                  </>
                )}
                {fetchData?.ifsc_code && (
                  <>
                    <Form.Label className="mb-1">
                      {i18n("IFSC Code")}:
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      {fetchData?.ifsc_code}{" "}
                    </Form.Label>{" "}
                    <br />
                  </>
                )}
                {fetchData?.account_number && (
                  <>
                    <Form.Label className="mb-1">
                      {i18n("Account No")}: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      {fetchData?.account_number}{" "}
                    </Form.Label>{" "}
                    <br />
                  </>
                )}
                {fetchData?.bank || fetchData?.branch_name ? (
                  <>
                    <Form.Label className="mb-1">
                      {i18n("Bank")}:
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      {fetchData?.bank}, {fetchData?.branch_name}{" "}
                    </Form.Label>{" "}
                    <br />
                  </>
                ) : (
                  ""
                )}
              </Col>
            )}
            {fetchData?.upi_payment && (
              <Col className={`${bankDetailsCheck ? "" : "mb-3"}`}>
                <Row lg={12}>
                  <Col lg={6}>
                    <Form.Group>
                      <Form.Label style={{ fontSize: "13px", fontWeight: 600 }}>
                        {i18n("PAYMENT QR CODE")}
                      </Form.Label>
                      <p>
                        {i18n("UPI ID")}: <br /> {fetchData?.upi_payment}{" "}
                      </p>
                    </Form.Group>
                  </Col>
                  <Col lg={6}>
                    {fetchData?.upi_payment &&
                      generateQRCode(fetchData?.upi_payment)}
                  </Col>
                </Row>
              </Col>
            )}
            {subsData?.tnc ||
              (termsAndCondition && (
                <Col
                  className={`${
                    bankDetailsCheck || fetchData?.upi_payment ? "" : ""
                  }`}
                >
                  <Form.Label style={{ fontSize: "13px", fontWeight: 600 }}>
                    {i18n("TERMS AND CONDITIONS")}
                  </Form.Label>
                  <p>{subsData?.tnc ? subsData?.tnc : termsAndCondition} </p>
                </Col>
              ))}
          </Col>
          <Col lg={6} style={{ fontSize: "12px" }}>
            {subsData?.charges &&
              subsData?.charges.length > 0 &&
              subsData?.charges.map((data, index) => (
                <Row className="px-1 " key={index}>
                  <Col lg={7}>{data?.charge_type}</Col>
                  <Col lg={5}>
                    <GlobalCurrency price={data?.charge_value} />
                  </Col>
                </Row>
              ))}
            <Row className="px-0">
              <Col lg={7} className="px-0" style={{ textAlign: "start" }}>
                {i18n("TAXABLE AMOUNT")}
              </Col>
              <Col lg={5} className="p-0" style={{ textAlign: "end" }}>
                <GlobalCurrency price={subsData?.taxable_value} />
              </Col>
            </Row>
          
              {subsData?.voucher_items?.map((data, index) => (
                <React.Fragment key={index}>
                  {data?.cgst && data?.sgst > 0 ? (
                  <>
                  <Row className="px-1">
                    <Col lg={7} className="px-0" style={{ textAlign: "start" }}>
                      {" "}
                      {i18n("CGST")} @{data?.tax_rate / 2}%
                    </Col>
                    <Col lg={5} className="p-0" style={{ textAlign: "end" }}>
                      <GlobalCurrency price={data?.cgst ? data?.cgst : ""} />
                    </Col>
                  </Row>
                  <Row className="px-1">
                    <Col lg={7} className="px-0" style={{ textAlign: "start" }}>
                      {i18n("SGST")} @{data?.tax_rate / 2}%
                    </Col>
                    <Col className="p-0" lg={5} style={{ textAlign: "end" }}>
                      <GlobalCurrency price={data?.sgst ? data?.sgst : ""} />
                    </Col>
                  </Row>
                  </>
                  ) : ""}
                  
                </React.Fragment>
              ))}
              {subsData?.voucher_items?.map((taxRow, index) => {
              if (taxRow.cess > 0) {
                return (
                  <Row key={`cess-${index}`} className="px-1">
                    <Col lg={7} className="px-0" style={{ textAlign: "start" }}>
                      {i18n("Cess")} @{taxRow.cess_rate}%
                    </Col>
                    <Col className="p-0" lg={5} style={{ textAlign: "end" }}>
                      <GlobalCurrency price={taxRow.cess} />
                    </Col>
                  </Row>
                );
              }
            })}
            <Row
              className="px-1 py-2 bold"
              style={{
                borderTop: "1px solid black",
                borderBottom: "1px solid black",
              }}
            >
              <Col lg={7} className="px-0" style={{ textAlign: "start" }}>
                {i18n("TOTAL AMOUNT")}{" "}
              </Col>
              <Col
                className="p-0"
                style={{ fontWeight: "bold", textAlign: "end" }}
                lg={5}
              >
                <GlobalCurrency price={subsData?.grand_total} />
              </Col>
            </Row>
            <Row className="px-1 mt-1">
              <Col lg={7} className="px-0" style={{ textAlign: "start" }}>
                {i18n("Recieved Amount")}
              </Col>
              <Col lg={5} className="p-0" style={{ textAlign: "end" }}>
                <GlobalCurrency price={subsData?.paid_amount} />
              </Col>
            </Row>
            {subsData?.paid_amount ? (
              <Row className="px-0">
                <Col lg={7} className="px-0" style={{ textAlign: "start" }}>
                  {i18n("Balance Amount")}
                </Col>
                <Col lg={5} className="p-0" style={{ textAlign: "end" }}>
                  <GlobalCurrency price={Math.abs(balanceAmount)} />
                </Col>
              </Row>
            ) : (
              ""
            )}
            <Row>
              <Col
                style={{ textAlign: "start" }}
                lg={12}
                className="px-0 py-1 mt-2"
              >
                <Form.Label style={{ fontSize: "13px", fontWeight: 500 }}>
                  {i18n("TOTAL AMOUNT (IN WORDS)")}
                </Form.Label>{" "}
                <br />
                {words ? `${words.toUpperCase()}` : "ZERO RUPEES"}
              </Col>
            </Row>
            <Row className="mt-3">
              {!fetchData?.signature && (
                <Col
                  lg={12}
                  className="border  d-flex justify-content-start"
                  style={{ height: "60px" }}
                ></Col>
              )}
              {fetchData?.signature && (
                <Col lg={12} className="  d-flex justify-content-start">
                  <Image
                    src={fetchData?.signature}
                    alt="signature"
                    style={{ width: "200px", height: "60px" }}
                  />
                </Col>
              )}
            </Row>
            <Row>
              <Col
                lg={12}
                className="mt-3 px-0 d-flex flex-column align-items-start"
                height={100}
              >
                <Form.Label
                  className="mb-0"
                  style={{
                    fontSize: "13px",
                    fontWeight: 600,
                  }}
                >
                  {i18n("Authorized Signatory For")}
                </Form.Label>

                <Form.Label style={{ fontSize: "13px" }}>
                  {selectedBusiness?.business_name}{" "}
                </Form.Label>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default SubscriptionAllInvoiceDefault;
