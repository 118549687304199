import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, Col, Row, Stack } from "react-bootstrap";
import { i18n } from "../../../shared/helpers/sharedMethods";
import ProgressBar from "react-bootstrap/ProgressBar";
import { UilChartGrowthAlt } from "@iconscout/react-unicons";
import GlobalCurrency from "../../../shared/components/GlobalCurrency";

function ProductCategoryChartIndex(props) {
  const { reportPeriod } = props;
  const dispatch = useDispatch();

  const [labelName, setLabelName] = useState([]);
  const [salesAmount, setSalesAmount] = useState([]);
  const currency = useSelector((state) => state.getSetting?.dataSetting?.currency);

  const reportItems = useSelector(
    (state) => state.productCategoryChart.reportItems
  );

  useEffect(() => {
    if (Array.isArray(reportItems)) {
      const categoryName = reportItems.map(
        (item) =>
          item.categoryName.charAt(0).toUpperCase() + item.categoryName.slice(1)
      );
      const totalValue = reportItems.map((item) =>
        parseFloat(item.totalValue.replace(/[^0-9.-]+/g, '')) // Remove commas and parse to number
      );
      setLabelName(categoryName);
      setSalesAmount(totalValue);
    }
  }, [reportItems]);

  const totalSales = salesAmount.reduce((accumulator, currentValue) => {
    return accumulator + currentValue;
  }, 0);

  console.log('totalSales', totalSales);

  const categoryPercentages = salesAmount.map((amount) => {
    return ((amount / totalSales) * 100).toFixed(2); // Calculate percentage
  });


  return (
    <Card
      id="chart"
      className="border mb-3 shadow-sm"
      style={{ minHeight: "330px" }}
    >
      <span className="h4 fw-bolder mt-4 px-4">
        {" "}
        {i18n("Top 5 Product Categories")}
      </span>
      {!salesAmount.length > 0 && (
        <Stack
          className=" d-flex justify-content-center align-items-center "
          style={{ height: "253px" }}
        >
          <UilChartGrowthAlt size="45" color="gray" />
          <p className="mt-2" style={{ fontSize: "12px", color: 'gray' }}>
            {i18n("No top product categories yet.")}
          </p>
        </Stack>
      )}
      {salesAmount.length > 0 && (
        <>
          <Card.Body className="p-5">
            {salesAmount.map((amount, index) => {
              const percentage = totalSales
                ? ((amount / totalSales) * 100)
                : 0;
              return (
                <div key={index} className="mb-3">
                  <Stack
                    gap={3}
                    direction="horizontal"
                    className="mt-1 d-flex justify-content-between px "
                  >
                    <h5>{labelName[index]}</h5>
                    <h5>
                      {" "}
                      <GlobalCurrency price={amount} />
                    </h5>
                  </Stack>
                  <ProgressBar
                    now={percentage}
                    label={`${categoryPercentages[index]}%`}
                    striped
                    variant="info"
                  />
                </div>
              );
            })}
          </Card.Body>
        </>
      )}
    </Card>
  );
}

export default ProductCategoryChartIndex;
