import CreateEnquiry from "./screens/CreateEnquiry"
import EnquiryStack from "./screens/EnquiryStack"


const roortPath = 'subscription'
const featureName = 'subscription';

export const EnquiryRoute = [
    {
        path: roortPath + '/enquiries',
        element: <EnquiryStack featureName={featureName}/>,
        isProtected: true
    },
    {
        path: roortPath + '/createEnquiry',
        element: <CreateEnquiry />,
        isProtected: true
    }
]