import React, { useState, useEffect } from "react";
import { Button, Row, Col, Stack, Container,Image, Dropdown } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Layout from "../../shared/components/Layout";
import { downloadCsvFile, handleExportClick } from "../../shared/export";
import DataGrid from "../../shared/components/DataGrid";
import { format } from "date-fns";
import ReportPeriod from "../../shared/components/ReportPeroid";
import Header from "../../shared/components/Header";
import SalesTypeFilter from "../components/Filters/SalesTypeFilter";
import { fetchGSTR1SalesReport } from "../store/gstr1SalesReportSlice";
import GSTNavbar from "../components/GSTNavbar";
import { i18n } from "../../shared/helpers/sharedMethods";
import { changeToggleShow } from "../../shared/store/sharedSlice";
import ShortcutKeys from "../../shared/components/ShortcutKeys";
import ReportDateRange from "../../shared/components/date_range/ReportDateRange";
import DatePicker from "../../shared/components/date_range/DatePicker";
import { CurrentWeekDateRange } from "../../shared/components/DateRangeYAxis";
import ReportHeader from "../components/ReportHeader";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import CustomeSortIcon from "../../shared/components/CustomeSortIcon";
import { UilImport } from "@iconscout/react-unicons";
import { formatAmount } from "../../pos/helpers/cartHelper";
import { getLocalizedDate } from "../../shared/helpers/localizationHelper";
import GSTDetailLayout from "../../shared/components/item-detail/GSTDetailLayout";
import B2B from "../components/tabs/B2B";
import B2CL from "../components/tabs/B2CL";
import B2CS from "../components/tabs/B2CS";
import CDNR from "../components/tabs/CDNR";
import CDNUR from "../components/tabs/CDNUR";
import EXEMP from "../components/tabs/EXEMP";
import HSN from "../components/tabs/HSN";
import { handleExportInJSON } from "../components/ExportJSON";
import { fetchCDNRReport } from "../store/CDNRSlice";
import { fetchCDNURReport } from "../store/CDNURSlice";
import { fetchB2BReport } from "../store/B2BSlice";
import { fetchB2CSReport } from "../store/B2CSSlice";
import { fetchB2CLReport } from "../store/B2CLSlice";
import { fetchExempReport } from "../store/ExempSlice";
import { fetchHSNReport } from "../store/HSNSlice";
import { placeOfSupplyList } from "../../shared/config/placeOfSupply";
  import LoadingSpinner from '../../shared/components/LoadingSpinner';
import GlobalCurrency from "../../shared/components/GlobalCurrency";
const GSTR1SalesReport = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const accessToken = useSelector((state) => state?.auth?.accessToken?.access_token);
  const selectedBusiness = useSelector((state) => state?.auth?.selectedBusiness);
  const tenantId = selectedBusiness?.pivot?.tenant_id;
  const [reportPeriod, setReportPeriod] = useState("current-week");
  const [reportType, setReportType] = useState("sales_invoice");
  const [csvData, setCsvData] = useState("");
  const [startDate, setStartDate] = useState(null); // State for start date
  const [endDate, setEndDate] = useState(null);
  const [sortField, setSortField] = useState(null);
  const [sortOrder, setSortOrder] = useState(1);
  const [showGSTTabs, setShowGSTTabs] = useState(false);

    const handleReset = () => {
      const salesDateLimits = ReportDateRange({
        reportPeriod: reportPeriod,
      });
      setStartDate(salesDateLimits.startDate);
      setEndDate(salesDateLimits.endDate);
      setReportPeriod("current-week");
      setReportType("sales_invoice");
    };

  useEffect(() => {
    if (startDate && endDate) {
      dispatch(fetchGSTR1SalesReport({startDate: startDate, endDate: endDate, reportType : reportType,token:accessToken,tenant_id:tenantId})); 
    }
  }, [startDate, endDate, reportType]);  

  useEffect(() => {
    const dateLimits = ReportDateRange({ reportPeriod });
    setStartDate(dateLimits.startDate);
    setEndDate(dateLimits.endDate);
  }, [reportPeriod]);
  const reportItems = useSelector(
    (state) => state.gstr1SalesReport.reportItems
  );
  const status = useSelector((state) => state.gstr1SalesReport.status);
  const currencySymbol = useSelector(
    (state) => state.getSetting?.dataSetting.currency
  );
  const loading = useSelector((state) => state.gstr1SalesReport.loading);
  const dateTimeString = new Date()
    .toLocaleString()
    .replace(/[-]/g, " ")
    .replace(/:/g, "-");
  const fileName = `GSTR-1_sales_report_${dateTimeString}`;

  const handleExportButtonClick = () => {
    const exportData =
      reportItems &&
      Array.isArray(reportItems) &&
      reportItems.map((val) => ({
        GSTIN: val.gstin ? val.gstin : "-",
        "CUSTOMER NAME": val.partyName ? val.partyName : "-",
        "STATE CODE": val.place_of_supply ? val.place_of_supply : "-",
        "STATE NAME": val.place_of_supply
          ? placeOfSupplyList.find((item) => item.value === val.place_of_supply)
              ?.label: "",
        "INVOICE NO.": val.invNo ? val.invNo : "-",
        "INVOICE DATE": val.date ? val.date : "-",
        " INVOICE VALUE": val.amount ? parseFloat(val.amount).toFixed(2) : "0",
        "TOTAL TAX": val.taxRate ? val.taxRate : "0",
        "TAXABLE VALUE": val?.taxable_value
          ? parseFloat(val.taxable_value).toFixed(2)
          : "0",
        SGST: val.sgst ? val.sgst : "0",
        CGST: val.cgst ? val.cgst : "0",
        IGST: val.igst ? val.igst : "0",
        CESS: val.cess ? val.cess : "0",
        " TOTAL TAX": val.taxTotal ? parseFloat(val.taxTotal).toFixed(2) : "0",
      }));
    handleExportClick(exportData, setCsvData, downloadCsvFile, fileName);
  };

  const b2b = useSelector((state) => state.b2bSalesReport?.reportItems);
  const b2cs = useSelector((state) => state.b2CSSalesReport.reportItems);
  const b2cl = useSelector((state) => state.b2CLSalesReport.reportItems);
  const cdnr = useSelector((state) => state.CDNRSalesReport.reportItems);
  const hsn = useSelector((state) => state.HSNSalesReport.reportItems);
  const cdnur = useSelector((state) => state.CDNURSalesReport.reportItems);
  const gst = useSelector((state) => state.getSetting?.dataSetting.gstin);
  const getTransaction = useSelector(
    (state) => state.ExempSalesReport.reportItems
  );
  const withGSTIN = getTransaction?.withGSTIN || [];
  const withoutGSTIN = getTransaction?.withoutGSTIN || [];
  const intrawithReg = getTransaction?.intrawithReg || [];
  const intrawithoutReg = getTransaction?.intrawithoutReg || [];

  const NilRatedWithReg = withGSTIN.reduce((sum, item) => {
    return sum + (item.taxRate === 0 || 0);
  }, 0);
  const ExemptWithReg = withGSTIN.reduce((sum, item) => {
    return sum + (item.taxRate > 0 || 0);
  }, 0);
  const NonGSTWithReg = withGSTIN.reduce((sum, item) => {
    return sum + (item.taxRate == null || 0);
  }, 0);
  const InterNilRatedWithoutGST = withoutGSTIN.reduce((sum, item) => {
    return sum + (item.taxRate === 0 || 0);
  }, 0);
  const ExemptWithoutReg = withoutGSTIN.reduce((sum, item) => {
    return sum + (item.taxRate > 0 || 0);
  }, 0);
  const NonGSTWithoutReg = withoutGSTIN.reduce((sum, item) => {
    return sum + (item.taxRate == null || 0);
  }, 0);

  const IntraNilRatedWithReg = intrawithReg.reduce((sum, item) => {
    return sum + (item.taxRate === 0 || 0);
  }, 0);
  const IntraExemptWithReg = intrawithReg.reduce((sum, item) => {
    return sum + (item.taxRate > 0 || 0);
  }, 0);
  const IntraNonGSTWithReg = intrawithReg.reduce((sum, item) => {
    return sum + (item.taxRate == null || 0);
  }, 0);

  const IntraNilRatedWithoutReg = intrawithoutReg.reduce((sum, item) => {
    return sum + (item.taxRate === 0 || 0);
  }, 0);
  const IntraExemptWithoutReg = intrawithoutReg.reduce((sum, item) => {
    return sum + (item.taxRate > 0 || 0);
  }, 0);
  const IntraNonGSTWithoutReg = intrawithoutReg.reduce((sum, item) => {
    return sum + (item.taxRate == null || 0);
  }, 0);

  useEffect(() => {
    dispatch(fetchB2BReport({ startDate, endDate }));
    dispatch(fetchB2CSReport({ startDate, endDate }));
    dispatch(fetchB2CLReport({ startDate, endDate }));
    dispatch(fetchCDNRReport({ startDate, endDate }));
    dispatch(fetchCDNURReport({ startDate, endDate }));
    dispatch(fetchExempReport({ startDate, endDate }));
    dispatch(fetchHSNReport({ startDate, endDate }));
  }, [startDate, endDate]);

  const customSort = (field) => {
    setSortField(field);
    setSortOrder(sortOrder === 1 ? -1 : 1);
  };
  const sortedData = [...reportItems].sort((a, b) => {
    if (sortField) {
      const compareValueA = a[sortField];
      const compareValueB = b[sortField];
      if (compareValueA < compareValueB) {
        return sortOrder;
      }
      if (compareValueA > compareValueB) {
        return -sortOrder;
      }
    }
    return 0;
  });
  const headerStyle = {
    backgroundColor: "white",
  };
  const headerCellStyle = {
    fontWeight: "bold",
    fontSize: "11px",
  };

  const GSTTabs = [
    {
      key: "B2B",
      title: i18n("B2B"),
      component: (
        <B2B startDate={startDate} endDate={endDate} />
      ),
    },
    {
      key: "B2CL",
      title: i18n("B2CL"),
      component: (
        <B2CL startDate={startDate} endDate={endDate} />
      ),
    },
    {
      key: "B2CS",
      title: i18n("B2CS"),
      component: (
        <B2CS startDate={startDate} endDate={endDate}  />
      ),
    },
    {
      key: "CDNR",
      title: i18n("CDNR"),
      component: <CDNR startDate={startDate} endDate={endDate} />,
    },
    {
      key: "CDNUR",
      title: i18n("CDNUR"),
      component: <CDNUR startDate={startDate} endDate={endDate} />,
    },
    {
      key: "EXEMP",
      title: i18n("EXEMP"),
      component: (
        <EXEMP
          startDate={startDate}
          endDate={endDate}
        />
      ),
    },
    {
      key: "HSN",
      title: i18n("HSN"),
      component: (
        <HSN startDate={startDate} endDate={endDate} />
      ),
    },
  ];

  const handleInvoiceViewClick = () => {
    setShowGSTTabs(false);
  };
  const handleGSTPortalViewClick = () => {
    setShowGSTTabs(true);
  };
  const handleJSON = () => {
    handleExportInJSON(
      b2b,
      b2cs,
      b2cl,
      cdnr,
      cdnur,
      hsn,
      gst,
      NilRatedWithReg,
      ExemptWithReg,
      NonGSTWithReg,
      InterNilRatedWithoutGST,
      ExemptWithoutReg,
      NonGSTWithoutReg,
      IntraNilRatedWithReg,
      IntraExemptWithReg,
      IntraNonGSTWithReg,
      IntraNilRatedWithoutReg,
      IntraExemptWithoutReg,
      IntraNonGSTWithoutReg
    );
  };
  return (
    <Layout>
           {loading && <LoadingSpinner />}
      <Header
        currentTitle={i18n("GSTR-1 (Sales)")}
        ItemActions={
          <Stack gap={3} direction="horizontal" className="mt-1 mb-1">
            <ShortcutKeys />
          </Stack>
        }
      />

      <Container fluid className="px-5" >
        <div className="border" style={{
            background: "white",
            width: window.innerWidth <= 1496 ? "91.5%" : "100%",
          }}>
          <GSTNavbar />

          <ReportHeader
            ItemActions={
              <Stack gap={2} direction="horizontal" className="pt-3 px-3 mb-3">
                <Stack direction="horizontal">
                  <DatePicker
                    startDate={startDate}
                    setStartDate={setStartDate}
                    endDate={endDate}
                    setEndDate={setEndDate}
                  />
                </Stack>
                <Stack>
                  <ReportPeriod
                    reportPeriod={reportPeriod}
                    setReportPeriod={setReportPeriod}
                  />
                </Stack>
                {!showGSTTabs &&<Stack>
                  <SalesTypeFilter
                    reportType={reportType}
                    setReportType={setReportType}
                  />
                </Stack>}
                <Stack
                  direction="horizontal"
                  gap={1}
                  style={{ marginLeft: "1px" }}
                >
                  <Button
                    className="rounded-0"
                    variant="outline-danger"
                    onClick={handleReset}
                  >
                      {i18n("Reset")}
                  </Button>
                </Stack>
                <Dropdown>
                  <Dropdown.Toggle
                    className="fw-bolder d-flex align-items-center rounded-0"
                    variant="success"
                    id="export-dropdown"
                  >
                    <UilImport
                      size="18"
                      className="mx-2"
                      style={{ cursor: "pointer" }}
                    />
                    {i18n("Export")}
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item onClick={handleJSON}>
                      {i18n("Export as JSON")}
                    </Dropdown.Item>
                    <Dropdown.Item onClick={handleExportButtonClick}>
                      {i18n("Export as Excel")}
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                <Dropdown>
                  <Dropdown.Toggle
                    className="fw-bolder d-flex align-items-center rounded-0"
                    variant="success"
                  >
                    {i18n("View Options")}
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item onClick={handleInvoiceViewClick}>
                      {i18n("Invoice View")}
                    </Dropdown.Item>
                    <Dropdown.Item onClick={handleGSTPortalViewClick}>
                      {i18n("GST Portal View")}
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </Stack>
            }
          />
          {showGSTTabs && <GSTDetailLayout tabs={GSTTabs} />}
          {/* {status ? (
                // <DataGrid
                //   allData={reportItems}
                //   data={reportItems}
                //   columns={gridColumns}
                //   isPaginated={true}
                //   title="Report"
                // />
                <DataTable
                  data={reportItems}
                  columns={gridColumns}
                  pagination={true}
                  paginationPerPage={50}
                  paginationRowsPerPageOptions={[50, 100, 150, 200, 250]}
                  paginationTotalRows={reportItems && reportItems.length}
                  //onRowClicked={handleRowClick}
                  responsive
                  highlightOnHover
                  pointerOnHover
                  handleSearch
                  // defaultSortAsc={defaultSortAsc}
                  // defaultSortFieldId={defaultSortField}
                />
              ) : (
                <p className="d-flex justify-content-center p-2 fs-3 fw-bolder">
                  {i18n("Error while fetching GSTR-1 Sales report")}
                </p>
              )} */}
          <Container fluid className="px-3 mb-4" >
            <div className="bordered-container">
              {status && reportItems ? (
                <DataTable
                  value={sortedData}
                  headerStyle={headerStyle}
                  paginator
                  rows={10}
                  dataKey="id"
                  emptyMessage={i18n("No Sales Summary found")}
                  className="p-datatable-gridlines full-width-table custom-datatable p-component"
                  scrollable
                  scrollHeight="400px"
                  sortMode="multiple"
                  removableSort
                >
                  <Column
                    field="gstin"
                    header={() => (
                      <div
                        className={`custom-header-${
                          sortField === "gstin"
                            ? sortOrder === 1
                              ? "up"
                              : "down"
                            : ""
                        }`}
                        onClick={() => customSort("gstin")}
                        style={{
                          cursor: "pointer",
                          display: "flex",
                          alignItems: "center",
                          fontSize: "11px",
                          lineHeight: "11px",
                        }}
                      >
                        {i18n("gstin")}
                        <CustomeSortIcon
                          sortOrder={sortField === "gstin" ? sortOrder : 0}
                          style={{ marginLeft: "4px", verticalAlign: "middle" }}
                        />
                      </div>
                    )}
                    body={(row) => (row.gstin ? <span>{row.gstin}</span> : "-")}
                    sortable
                    headerStyle={headerCellStyle}
                  />

                  <Column
                    field="partyName"
                    header={() => (
                      <div
                        className={`custom-header-${
                          sortField === "partyName"
                            ? sortOrder === 1
                              ? "up"
                              : "down"
                            : ""
                        }`}
                        onClick={() => customSort("partyName")}
                        style={{
                          cursor: "pointer",
                          display: "flex",
                          alignItems: "center",
                          fontSize: "11px",
                          lineHeight: "11px",
                        }}
                      >
                        {i18n("CUSTOMER NAME")}
                        <CustomeSortIcon
                          sortOrder={sortField === "partyName" ? sortOrder : 0}
                          style={{ marginLeft: "4px", verticalAlign: "middle" }}
                        />
                      </div>
                    )}
                    sortable
                    headerStyle={headerCellStyle}
                  />
                  <Column
                    field=""
                    header={() => (
                      <div
                        className={`custom-header-${
                          sortField === ""
                            ? sortOrder === 1
                              ? "up"
                              : "down"
                            : ""
                        }`}
                        onClick={() => customSort("")}
                        style={{
                          cursor: "pointer",
                          display: "flex",
                          alignItems: "center",
                          fontSize: "11px",
                          lineHeight: "11px",
                        }}
                      >
                        {i18n("STATE CODE")}
                        <CustomeSortIcon
                          sortOrder={sortField === "" ? sortOrder : 0}
                          style={{ marginLeft: "4px", verticalAlign: "middle" }}
                        />
                      </div>
                    )}
                    body={(row) => (row?.place_of_supply ?<span>{row?.place_of_supply}</span> : "-")}
                    sortable
                    headerStyle={headerCellStyle}
                  />
                  <Column
                    field=""
                    header={() => (
                      <div
                        className={`custom-header-${
                          sortField === ""
                            ? sortOrder === 1
                              ? "up"
                              : "down"
                            : ""
                        }`}
                        onClick={() => customSort("")}
                        style={{
                          cursor: "pointer",
                          display: "flex",
                          alignItems: "center",
                          fontSize: "11px",
                          lineHeight: "11px",
                          minWidth: '90px',
                        }}
                      >
                        {i18n("STATE NAME")}
                        <CustomeSortIcon
                          sortOrder={sortField === "" ? sortOrder : 0}
                          style={{ marginLeft: "4px", verticalAlign: "middle" }}
                        />
                      </div>
                    )}
                    body={(row) => (row?.place_of_supply 
                        ? <span>{placeOfSupplyList.find((item) => item.value === row.place_of_supply)?.label || "-"}</span> 
                        : "-"
                    )}
                    sortable
                    headerStyle={headerCellStyle}
                  />
                  <Column
                    field="invNo"
                    header={() => (
                      <div
                        className={`custom-header-${
                          sortField === "invNo"
                            ? sortOrder === 1
                              ? "up"
                              : "down"
                            : ""
                        }`}
                        onClick={() => customSort("invNo")}
                        style={{
                          cursor: "pointer",
                          display: "flex",
                          alignItems: "center",
                          fontSize: "11px",
                          lineHeight: "11px",
                          minWidth: '70px',
                        }}
                      >
                        {i18n("Invoice No")}
                        <CustomeSortIcon
                          sortOrder={sortField === "invNo" ? sortOrder : 0}
                          style={{ marginLeft: "4px", verticalAlign: "middle" }}
                        />
                      </div>
                    )}
                    sortable
                    headerStyle={headerCellStyle}
                  />

                  <Column
                    field="date"
                    header={() => (
                      <div
                        className={`custom-header-${
                          sortField === "date"
                            ? sortOrder === 1
                              ? "up"
                              : "down"
                            : ""
                        }`}
                        onClick={() => customSort("date")}
                        style={{
                          cursor: "pointer",
                          display: "flex",
                          alignItems: "center",
                          fontSize: "11px",
                          lineHeight: "11px",
                        }}
                      >
                        {i18n("INVOICE DATE")}
                        <CustomeSortIcon
                          sortOrder={sortField === "date" ? sortOrder : 0}
                          style={{ marginLeft: "4px", verticalAlign: "middle" }}
                        />
                      </div>
                    )}
                    body={(row) =>
                      row.date ? (
                        <span>
                          {getLocalizedDate(row.date, "en-IN").split(",")[0]}
                        </span>
                      ) : (
                        "-"
                      )
                    }
                    sortable
                    headerStyle={headerCellStyle}
                  />
                  <Column
                    field="amount"
                    header={() => (
                      <div
                        className={`custom-header-${
                          sortField === "amount"
                            ? sortOrder === 1
                              ? "up"
                              : "down"
                            : ""
                        }`}
                        onClick={() => customSort("amount")}
                        style={{
                          cursor: "pointer",
                          display: "flex",
                          alignItems: "center",
                          fontSize: "11px",
                          lineHeight: "11px",
                          minWidth: '100px',
                        }}
                      >
                        {i18n("INVOICE VALUE")}
                        <CustomeSortIcon
                          sortOrder={sortField === "amount" ? sortOrder : 0}
                          style={{ marginLeft: "4px", verticalAlign: "middle" }}
                        />
                      </div>
                    )}
                    body={(row) =>
                      row.amount ? (
                        <GlobalCurrency price={row.amount} />
                      ) : (
                        <GlobalCurrency price={0} />
                    )}  
                    sortable
                    headerStyle={headerCellStyle}
                  />

                  <Column
                    field="taxRate"
                    header={() => (
                      <div
                        className={`custom-header-${
                          sortField === "taxRate"
                            ? sortOrder === 1
                              ? "up"
                              : "down"
                            : ""
                        }`}
                        onClick={() => customSort("taxRate")}
                        style={{
                          cursor: "pointer",
                          display: "flex",
                          alignItems: "center",
                          fontSize: "11px",
                          lineHeight: "11px",
                        }}
                      >
                        {i18n("TOTAL TAX %")}
                        <CustomeSortIcon
                          sortOrder={sortField === "taxRate" ? sortOrder : 0}
                          style={{ marginLeft: "4px", verticalAlign: "middle" }}
                        />
                      </div>
                    )}
                    body={(row) =>
                      row.taxRate ? <span>{row.taxRate}%</span> : "-"
                    }
                    sortable
                    headerStyle={headerCellStyle}
                  />
                  <Column
                    field="taxable_value"
                    header={() => (
                      <div
                        className={`custom-header-${
                          sortField === "taxable_value"
                            ? sortOrder === 1
                              ? "up"
                              : "down"
                            : ""
                        }`}
                        onClick={() => customSort("taxable_value")}
                        style={{
                          cursor: "pointer",
                          display: "flex",
                          alignItems: "center",
                          fontSize: "11px",
                          lineHeight: "11px",
                          minWidth: '80px',
                        }}
                      >
                        {i18n("TAXABLE VALUE")}
                        <CustomeSortIcon
                          sortOrder={
                            sortField === "taxable_value" ? sortOrder : 0
                          }
                          style={{ marginLeft: "4px", verticalAlign: "middle",minWidth:'70px' }}
                        />
                      </div>
                    )}
                    body={(row) =>
                      row.taxable_value ? (
                        <GlobalCurrency price={row.taxable_value} />
                      ) : (
                        <GlobalCurrency price={0} />
                    )}  
                    sortable
                    headerStyle={headerCellStyle}
                  />
                  <Column
                    field="sgst"
                    header={() => (
                      <div
                        className={`custom-header-${
                          sortField === "sgst"
                            ? sortOrder === 1
                              ? "up"
                              : "down"
                            : ""
                        }`}
                        onClick={() => customSort("sgst")}
                        style={{
                          cursor: "pointer",
                          display: "flex",
                          alignItems: "center",
                          fontSize: "11px",
                          lineHeight: "11px",
                          minWidth: '50px',
                        }}
                      >
                        {i18n("SGST")}
                        <CustomeSortIcon
                          sortOrder={sortField === "sgst" ? sortOrder : 0}
                          style={{ marginLeft: "4px", verticalAlign: "middle" }}
                        />
                      </div>
                    )}
                    body={(row) =>
                      row.sgst ? (
                        <GlobalCurrency price={row.sgst} />
                      ) : (
                        <GlobalCurrency price={0} />
                    )}  
                    sortable
                    headerStyle={headerCellStyle}
                  />
                  <Column
                    field="cgst"
                    header={() => (
                      <div
                        className={`custom-header-${
                          sortField === "cgst"
                            ? sortOrder === 1
                              ? "up"
                              : "down"
                            : ""
                        }`}
                        onClick={() => customSort("cgst")}
                        style={{
                          cursor: "pointer",
                          display: "flex",
                          alignItems: "center",
                          fontSize: "11px",
                          lineHeight: "11px",
                          minWidth: '50px',

                        }}
                      >
                        {i18n("CGST")}
                        <CustomeSortIcon
                          sortOrder={sortField === "cgst" ? sortOrder : 0}
                          style={{ marginLeft: "4px", verticalAlign: "middle" }}
                        />
                      </div>
                    )}
                    body={(row) =>
                      row.cgst ? (
                        <GlobalCurrency price={row.cgst} />
                      ) : (
                        <GlobalCurrency price={0} />
                    )}  
                    sortable
                    headerStyle={headerCellStyle}
                  />
                  <Column
                    field="igst"
                    header={() => (
                      <div
                        className={`custom-header-${
                          sortField === "igst"
                            ? sortOrder === 1
                              ? "up"
                              : "down"
                            : ""
                        }`}
                        onClick={() => customSort("igst")}
                        style={{
                          cursor: "pointer",
                          display: "flex",
                          alignItems: "center",
                          fontSize: "11px",
                          lineHeight: "11px",
                          minWidth: '50px',
                        }}
                      >
                        {i18n("IGST")}
                        <CustomeSortIcon
                          sortOrder={sortField === "igst" ? sortOrder : 0}
                          style={{ marginLeft: "4px", verticalAlign: "middle" }}
                        />
                      </div>
                    )}
                    body={(row) =>
                      row.igst ? (
                        <GlobalCurrency price={row.igst} />
                      ) : (
                        <GlobalCurrency price={0} />
                    )}  
                    sortable
                    headerStyle={headerCellStyle}
                  />
                  <Column
                    field="cess"
                    header={() => (
                      <div
                        className={`custom-header-${
                          sortField === "cess"
                            ? sortOrder === 1
                              ? "up"
                              : "down"
                            : ""
                        }`}
                        onClick={() => customSort("cess")}
                        style={{
                          cursor: "pointer",
                          display: "flex",
                          alignItems: "center",
                          fontSize: "11px",
                          lineHeight: "11px",
                          minWidth: '50px',
                        }}
                      >
                        {i18n("cess")}
                        <CustomeSortIcon
                          sortOrder={sortField === "cess" ? sortOrder : 0}
                          style={{ marginLeft: "4px", verticalAlign: "middle" }}
                        />
                      </div>
                    )}
                    body={(row) =>
                      row.cess ? (
                        <GlobalCurrency price={row.cess} />
                      ) : (
                        <GlobalCurrency price={0} />
                    )}  
                    sortable
                    headerStyle={headerCellStyle}
                  />
                  <Column
                    field="taxTotal"
                    header={() => (
                      <div
                        className={`custom-header-${
                          sortField === "taxTotal"
                            ? sortOrder === 1
                              ? "up"
                              : "down"
                            : ""
                        }`}
                        onClick={() => customSort("taxTotal")}
                        style={{
                          cursor: "pointer",
                          display: "flex",
                          alignItems: "center",
                          fontSize: "11px",
                          lineHeight: "11px",
                          minWidth: '70px',

                        }}
                      >
                        {i18n("TOTAL TAX")}
                        <CustomeSortIcon
                          sortOrder={sortField === "taxTotal" ? sortOrder : 0}
                          style={{ marginLeft: "4px", verticalAlign: "middle" }}
                        />
                      </div>
                    )}
                    body={(row) =>
                      row.taxTotal ? (
                        <GlobalCurrency price={row.taxTotal} />
                      ) : (
                        <GlobalCurrency price={0} />
                    )}  
                    sortable
                    headerStyle={headerCellStyle}
                  />
                </DataTable>
              ) : (
                <p>{i18n("Loading")}</p>
              )}
            </div>
          </Container>
        </div>
      </Container>
    </Layout>
  );
};

export default GSTR1SalesReport;
