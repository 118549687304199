import React, { useEffect } from 'react';
import {  Stack, Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { addNew, resetOrder } from '../../../../store/posOrderSlice';
import { cloneDeep } from 'lodash';
import { resetCart } from '../../../../store/cartItemsSlice';
import { i18n, placeholderText } from "../../../../../shared/helpers/sharedMethods";

const SummaryActions = () => {
   
    const  posOrder  = useSelector(state => state.posOrder)
    const currentUser = useSelector(state => state?.businessSwitch?.selectedBusiness);
    
    
    const dispatch = useDispatch();

    const handleSaveBill = () =>{
        if(posOrder.items && posOrder.items.length > 0){
            if(posOrder.payment.amount <= 0){
                alert('Please receive payment first');
                return;
            }
            let tempOrder = cloneDeep(posOrder);
            ;
            let tempDate = new Date();
    
            tempOrder.created_by = currentUser.id;
            tempOrder.updated_by = currentUser.id;
            tempOrder.payment.created_by = currentUser.id;
            tempOrder.voucher_date = tempDate.toISOString();
    
            delete tempOrder.status
            delete tempOrder.total_quantity
            delete tempOrder.received_amount
            delete tempOrder.change_return;
    
            dispatch(addNew(tempOrder));
        }else{
            alert('Please add products first');
        }
      
    }

    useEffect(() => {
        let orderStatus = posOrder?.status
        if(orderStatus?.isOrderPlaced){
            alert('Order Placed!')
            dispatch(resetCart())
            dispatch(resetOrder())
        }
        if(orderStatus?.errMessage && orderStatus?.errMessage != ''){
            alert(orderStatus.errMessage)
        }
    },[posOrder])

    return (
        <Stack gap={3} direction="horizontal" className="mt-2 mb-2 p-0">
            {/* <Button variant="outline-primary rounded-1" > Save & Print <span className='shortcut-color'>[F6]</span></Button> */}
            <Button variant="primary ms-auto rounded-1" onClick={handleSaveBill}>{i18n("Save")}<span className='shortcut-color text-secondary'>[F7]</span></Button>
        </Stack>
    )
}

export default SummaryActions;