import { ErrorMessage } from "formik";
import { Col, Form, FormControl, Row } from "react-bootstrap";
import { i18n } from "../../../shared/helpers/sharedMethods";
import { changePackageCode } from "../../store/subscription_item_store/PackageEntitySlice";
import { useDispatch } from "react-redux";

const PackageCode = ({ values, handleChange, touched, errors }) => {
    const dispatch = useDispatch();
  return (
     <Row className="mb-3">
    <Col>
      <Form.Group>
        <Form.Label> {i18n("Code")} </Form.Label>
        <span className="required" />
      </Form.Group>
      <FormControl
        className={`form-control ${
          touched.code && errors.code ? "is-invalid" : ""
        }`}
        type="text"
        name="code"
        value={values.code}
        onChange={(e) => {
          handleChange(e);
          dispatch(changePackageCode(e.target.value));
        }}
      />
      <ErrorMessage
        name="code"
        component="span"
        className="text-danger d-block fw-400 fs-small mt-2"
      />
    </Col>
  </Row>
  );
};

export default PackageCode;
