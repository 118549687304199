import { Col, Form, FormControl, Row } from "react-bootstrap";

import { useDispatch } from "react-redux";
import { changeName } from "../../../store/subscription_item_store/SetupFeesEntitySlice";
import { i18n } from "../../../../shared/helpers/sharedMethods";
import { changeAddonName } from "../../../store/subscription_item_store/AddonEntitySlice";

const AddonName = ({ values, handleChange, touched, errors }) => {
  const dispatch = useDispatch();
  return (
    <Row className="py-4">
      {" "}
      <Col lg={3}>
        {" "}
        <Form.Group>
          <Form.Label>{i18n("Name")} </Form.Label>
          <span className="required" />
        </Form.Group>
      </Col>
      <Col lg={4}>
        <FormControl
        autoFocus
            className={`form-control ${
              touched.name && errors.name ? "is-invalid" : ""
            }`}
          type="text"
          name="name"
          value={values.name}
          onChange={(e) => {
            handleChange(e);
            dispatch(changeAddonName(e.target.value));
          }}
        />
      </Col>
    </Row>
  );
};

export default AddonName;