import SalesTab from "./components/SalesTab";
import DashboardIndex from "./screens/DashboardIndex";
const rootPath = "dashboard";
const subscriptionFeatureName = "subscription";

export const dashboardRoutes = [
//   {
//     path: rootPath,
//     element: <DashboardIndex />,
//     isProtected: true,
//   },
  {
    path: rootPath + "/sales",
    element: <DashboardIndex type="sales" />,
    isProtected: true,
  },

  {
    path: rootPath + "/subscription",
    element: <DashboardIndex type="subscription"  featureName={subscriptionFeatureName}/>,
    isProtected: true,
  },
];
