import { useEffect } from "react";
import PackageList from "./PackageList";
import { useDispatch, useSelector } from "react-redux";
import { currentSubscriptionCount, planData } from "../../store/subscription_item_store/GetPackagesItemSlice";

const PackageLayout = ({ children,currentItemId }) => {
  
  const dispatch = useDispatch();

  const { accessToken, isLoggedIn } = useSelector((state) => state?.auth);
  const selectedBusiness = useSelector(
    (state) => state?.auth?.selectedBusiness
  );

  const tenantId = selectedBusiness?.pivot?.tenant_id;

  useEffect(() => {
    dispatch(
      planData({ token: accessToken.access_token, tenant_id: tenantId })
    );
    dispatch(
      currentSubscriptionCount({
        token: accessToken.access_token,
        tenant_id: tenantId,
      })
    );
  }, []);

  return (
    <div className="px-5 py-4">
      <div className="d-flex   ">
        <div className="col-lg-3 border mx-2 bg-white">
          <PackageList currentItemId={currentItemId} />
        </div>
        <div className="col-lg-9 border bg-white">{children}</div>
      </div>
    </div>
  );
};

export default PackageLayout;
