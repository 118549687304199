import React, { useState } from 'react';
import { Container, ListGroup, Form, Stack } from 'react-bootstrap';
import {  i18n } from '../../../../../shared/helpers/sharedMethods';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faPenToSquare
} from '@fortawesome/free-solid-svg-icons';
import { useSelector } from 'react-redux';
import EditReceivedAmount from '../modals/EditReceivedAmount';


const ReceivedAmount = () => {
    
    const {  grand_total, received_amount, change_return, payment  } = useSelector(state => state.posOrder)
    const [receivedAmountModalShow, setReceivedAmountModalShow] = useState(false);

    const toggleReceivedAmountModal = (visibility) => {
        
        setReceivedAmountModalShow(visibility)
    };

    return (
        <Container className='p-0 mb-6'>
            <ListGroup>
                <ListGroup.Item className='list-detail-header'>
                    <Stack gap={2} direction="horizontal">
                        <span>{i18n("Received Amount")}</span>
                        <span className='ms-auto clickable-action'>
                            <FontAwesomeIcon size='xl' color='gray' icon={faPenToSquare} onClick={() => { toggleReceivedAmountModal(true) }}  />
                        </span>
                        <span className='shortcut-color float-end'>{i18n("[F4]")}</span>
                    </Stack>
                </ListGroup.Item>
                <ListGroup.Item className='list-detail-row'>
                    <Stack gap={2} direction="horizontal">
                        <text>
                            {received_amount}
                        </text>
                        <span className='ms-auto text-capitalize'>
                            {payment.mode}
                        </span>
                    </Stack>
                </ListGroup.Item>
                <ListGroup.Item className='list-detail-row'>
                    <Stack gap={2} direction="horizontal">
                        <text>
                            { change_return }
                        </text>
                        <span className='ms-auto'>
                            {i18n('Change Return')}
                        </span>
                    </Stack>
                </ListGroup.Item>
            </ListGroup>
            <EditReceivedAmount receivedAmountModalShow={receivedAmountModalShow} toggleReceivedAmountModal={toggleReceivedAmountModal} />
        </Container>
    )
}

export default ReceivedAmount;