import React, { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import { format } from "date-fns";
import {
  CurrentMonthDates,
  LastMonthDates,
  YearOfMonthName,
  PreviousMonthDates,
  Weekdays,
} from "../../../../shared/components/DateRangeXAxis";
import {
  CurrentAndLastYearMonths,
  CurrentMonthDateRange,
  CurrentWeekDateRange,
  LastThreeAndSixMonths,
  PreviousMonthDateRange,
  PreviousWeekDateRange,
  getFormattedDate,
} from "../../../../shared/components/DateRangeYAxis";
import { placeholderText } from "../../../../shared/helpers/sharedMethods";

const Chart = (props) => {
  const { reportItems, reportPeriod } = props;
  const [dateRangeX, setDateRangeX] = useState([]);
  const [salesAmount, setSalesAmount] = useState([]);
  const textTitle = placeholderText("Average Purchase")

  useEffect(() => {
    let itemDatas;

    switch (reportPeriod) {
      case "current-month":
        itemDatas = CurrentMonthDates();
        break;

      case "previous-month":
        itemDatas = LastMonthDates();
        break;

      case "current-week":
      case "previous-week":
        itemDatas = Weekdays();
        break;
      case "last-three-month":
        itemDatas = PreviousMonthDates(3);
        break;
      case "last-six-month":
        itemDatas = PreviousMonthDates(6);
        break;
      case "current-year":
        const currentYear = new Date().getFullYear();
        const currentYearStartMonth = new Date(currentYear, 0, 1).getMonth(); // January is 0
        itemDatas = YearOfMonthName(currentYear, currentYearStartMonth, 12);
        break;

      // Case for the previous year
      case "previous-year":
        const previousYear = new Date().getFullYear() - 1;
        const previousYearStartMonth = new Date(previousYear, 0, 1).getMonth(); // January is 0
        itemDatas = YearOfMonthName(
          previousYear,
          previousYearStartMonth,
          12
        );
        break;
      default:
        itemDatas = [];
        break;
    }

    setDateRangeX(itemDatas);
  }, [reportPeriod]);

  useEffect(() => {
    let start, end, lastMonths;

    switch (reportPeriod) {
      case "current-week":
        ({ start, end } = CurrentWeekDateRange());
        break;

      case "current-month":
        ({ start, end } = CurrentMonthDateRange());
        break;

      case "previous-week":
        ({ start, end } = PreviousWeekDateRange());
        break;
      case "previous-month":
        ({ start, end } = PreviousMonthDateRange());
        break;
      case "last-three-month":
        lastMonths = LastThreeAndSixMonths(3);
        break;
      case "last-six-month":
        lastMonths = LastThreeAndSixMonths(6);
        break;
      case "current-year":
        const currentYear = new Date().getFullYear();
        const currentYearStartMonth = 0; // January
        lastMonths = CurrentAndLastYearMonths(
          currentYear,
          currentYearStartMonth,
          12
        );
        break;

      case "previous-year":
        const previousYear = new Date().getFullYear() - 1;
        const previousYearStartMonth = 0; // January
        lastMonths = CurrentAndLastYearMonths(
          previousYear,
          previousYearStartMonth,
          12
        );
        break;

      default:
        start = new Date();
        end = new Date();
        break;
    }

    const dates = [];

    const currentDate = new Date(start);

    while (currentDate <= end) {
      let formattedDate;
      if (reportPeriod === "current-week" || reportPeriod === "previous-week") {
        formattedDate = currentDate.toISOString().split("T")[0];
      } else {
        formattedDate = format(currentDate, "yyyy-MM-dd");
      }
      dates.push(formattedDate);
      currentDate.setDate(currentDate.getDate() + 1);
    }

    if (
      reportPeriod === "last-three-month" ||
      reportPeriod === "last-six-month" ||
      reportPeriod === "current-year" ||
      reportPeriod === "previous-year"
    ) {
      if (reportItems && reportItems.length > 0) {
        const graphAmountData = Array(lastMonths.length).fill(0);
        reportItems.forEach((dataItem) => {
          const voucherDate = dataItem.group_day;
          const subtotal = dataItem.average_value;
          const dateIndex = lastMonths.indexOf(voucherDate);
          if (dateIndex !== -1) {
            graphAmountData[dateIndex] = subtotal;
          }
        });
        setSalesAmount(graphAmountData);
      }
    } else {
      const graphAmountData = Array(dates.length).fill(0);
      if (reportItems && reportItems) {
        reportItems.forEach((dataItem) => {
          const voucherDate = dataItem.group_day;
          const subtotal = dataItem.average_value;

          const dateIndex = dates.findIndex((date) => date === voucherDate);
          if (dateIndex !== -1) {
            graphAmountData[dateIndex] = subtotal;
          }
        });
      }
      setSalesAmount(graphAmountData);
    }
  }, [reportItems, reportPeriod]);

  const [state, setState] = useState([]);
  const [option, setOption] = useState({});
  useEffect(() => {
    const formattedSalesAmount = salesAmount.map((amount) =>
      parseFloat(amount).toFixed(2)
    );

    setState([
      {
        name: "Avg Purchase Amount",
        data: formattedSalesAmount,
      },
    ]);
  }, [salesAmount]);
  useEffect(() => {
    setOption({
      chart: {
        type: "bar",
        height: 350,
        toolbar: {
          show: false,
        },
      },
      colors: ["#94C973"],
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "75%",
          endingShape: "rounded",
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
        colors: "#25b97b",
      },
      xaxis: {
        categories: dateRangeX,
        labels: {
          show: true, // Set to true to show the labels
          rotate: 0, // You can adjust the rotation angle of the labels
          trim: false, // Trim the labels if they overflow
          minHeight: undefined, // Minimum height for labels
          maxHeight: 120, // Maximum height for labels
          style: {
            fontSize: "12px", // Set the font size for labels
          },
        },
      },
      yaxis: {
        title: {
          // text: '$ (thousands)'
        },
      },
      fill: {
        opacity: 1,
      },
      title: {
        text: textTitle,
        style: {
          color: "#444",
        },
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return " ₹ " + val;
          },
        },
      },
    });
  }, [dateRangeX]);

  return (
    <>
      <ReactApexChart
        options={option}
        series={state}
        type="bar"
        height={350}
      />
    </>
  );
};

export default Chart;
