import React from "react";
import { Container, Row, Col } from 'react-bootstrap';
import { i18n } from "../../../../shared/helpers/sharedMethods";
import GlobalCurrency from "../../../../shared/components/GlobalCurrency";
import { placeOfSupplyList } from "../../../../shared/config/placeOfSupply";
import { find } from "lodash";

const BusinessInfo = ({ selectedParty }) => {
 

  return (

    <>
      <div className="d-flex p-1 px-4 ">
        <Col lg={5} style={{ fontWeight: 600 }}>
          {i18n("Business Details")}
        </Col>
      </div>
      <div className=" mt-1 p-1 px-2 mx-2" style={{ borderBottom: '1px solid rgba(10, 10, 10, 0.1)', fontSize: '13px', color: '#76797C' }}>
        <Row>
          <Col lg={6}>
            {i18n("GSTIN")}
          </Col>
          <Col lg={6} style={{ color: 'black' }}>
            {selectedParty?.gstin ? selectedParty?.gstin : '-'}
          </Col>
        </Row>
      </div>
      <div className=" mt-1 p-1 px-2 mx-2" style={{ borderBottom: '1px solid rgba(10, 10, 10, 0.1)', fontSize: '13px', color: '#76797C' }}>
        <Row>
          <Col lg={6}>
            {i18n("PAN Number")}
          </Col>
          <Col lg={6} style={{ color: 'black' }}>
            {selectedParty?.pan ? selectedParty?.pan : '-'}
          </Col>
        </Row>
      </div>        
      {/* </Row>
      </div>
      <div className=" mt-1 p-1 px-2 mx-2" style={{ borderBottom: '1px solid rgba(10, 10, 10, 0.1)', fontSize: '13px', color: '#76797C' }}>
        <Row>
          <Col lg={6}>
            {i18n("Credit Period")}
          </Col>
          <Col lg={6} style={{ color: 'black' }}>
            {selectedParty.credit_period ? selectedParty.credit_period : '-'} Days
          </Col>
        </Row>
      </div>
      <div className=" mt-1 p-1 px-2 mx-2" style={{ fontSize: '13px', color: '#76797C' }}>
        <Row>
          <Col lg={6}>
            {i18n("Credit Limit")}
          </Col>
          <Col lg={6} style={{ color: 'black' }}>
            <GlobalCurrency price={selectedParty.credit_limit} />
          </Col> */}
    </>

  )
}

export default BusinessInfo;