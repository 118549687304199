import InviteUserStack from "./screens/InviteUserStack";
import CategoryStack from "./screens/CategoryStack";
import CurrencyDetails from "./screens/CurrencyDetails";
import UnitSettings from "./screens/UnitSettings";
import InvoiceTheme from "./screens/InvoiceTheme";
import InvoiceSettings from "./screens/InvoiceSettings";
import CompanySettings from "./screens/CompanySettings";
import TaxSettings from "./screens/TaxSettings";
import NotInPlanAlert from "../shared/components/NotInPlanAlert";
import AdvanceSettings from "./screens/AdvanceSettings";
import DeletedPartyIndex from "./screens/DeletedPartyIndex";
import DeletedProductIndex from "./screens/DeletedProductIndex";
import DeletedVoucherIndex from "./screens/DeletedVoucherIndex";
import DeletedPurchaseIndex from "./screens/DeletedPurchseIndex";
import MembershipCardPreview from "./screens/MembershipCardPreview";
import ProductImportExport from "./screens/ProductImportExport";
import PartyImportExport from "./screens/PartyImportExport";
import SalesImportExport from "./screens/SalesImportExport";
import ProductsImport from "./screens/ProductsImport";
import ProductExport from "./screens/ProductExport";


const rootPath = "setting";
const inviteUserFeature = "invite_users";

export const settingRouter = [
  {
    path: rootPath + "/business",
    element: <CompanySettings />,
    isProtected: true,
  },
  {
    path: rootPath + "/invoice-themes",
    element: <InvoiceTheme />,
    isProtected: true,
  },
  {
    path: rootPath + "/language-and-currency",
    element: <CurrencyDetails />,
    isProtected: true,
  },
  {
    path: rootPath + "/invoice",
    element: <InvoiceSettings />,
    isProtected: true,
  },
  {
    path: rootPath + "/users",
    element: <InviteUserStack featureName={inviteUserFeature} />,
    isProtected: true,
  },
  {
    path: rootPath + "/categories",
    element: <CategoryStack />,
    isProtected: true,
  },
  {
    path: rootPath + "/units",
    element: <UnitSettings />,
    isProtected: true,
  },
  {
    path: rootPath + "/tax",
    element: <TaxSettings />,
    isProtected: true,
  },
  {
    path: rootPath + "/not-in-plan",
    element: <NotInPlanAlert />,
    isProtected: true,
  },
  {
    path: rootPath + "/advance-settings",
    element: <AdvanceSettings />,
    isProtected: true,
  },
  {
    path: rootPath + "/deleted-items/parties",
    element: <DeletedPartyIndex />,
    isProtected: true,
  },
  {
    path: rootPath + "/deleted-items/products",
    element: <DeletedProductIndex />,
    isProtected: true,
  },
  {
    path: rootPath + "/deleted-items/sales",
    element: <DeletedVoucherIndex />,
    isProtected: true,
  },
  {
    path: rootPath + "/deleted-items/purchase",
    element: <DeletedPurchaseIndex />,
    isProtected: true,
  },
  {
    path: rootPath + "/membership-card",
    element: <MembershipCardPreview />,
    isProtected: true,
  },
  {
    path: rootPath + "/import-export/products",
    element: <ProductImportExport />,
    isProtected: true,
  },
  {
    path: rootPath + "/product-import",
    element: <ProductsImport />,
    isProtected: true,
  },
  {
    path: rootPath + "/product-export",
    element: <ProductExport />,
    isProtected: true,
  },
  {
    path: rootPath + "/import-export/parties",
    element: <PartyImportExport />,
    isProtected: true,
  },
  {
    path: rootPath + "/import-export/sales",
    element: <SalesImportExport />,
    isProtected: true,
  },
];
