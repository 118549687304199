import {
  createSlice,
  createAsyncThunk,
} from "@reduxjs/toolkit";
import { i18n } from "../../shared/helpers/sharedMethods";
  import { axiosRequest } from "../../shared/helpers/axiosHelper";

const initialState = {
  status: false,
  reportItems: [],
  error: false,
  errorMessage: '',
  loading:false,
};

export const fetchPartyWiseOutstanding = createAsyncThunk(
  "partyWiseOutstanding/fetch",
  async ({ token, tenant_id, startDate, endDate, categoryData }) => {
    try {
      const partyWiseResponse = await axiosRequest({
        method: "GET",
        url: `get-party-wise-outstanding-report`,
        params: { startDate, endDate,categoryData },
        headers: { Authorization: `Bearer ${token}`, "X-Tenant": tenant_id },
      });
      return partyWiseResponse.data;
    } catch (err) {
      await window.log.logToFile({ message: "Error while fetching Party wise outstanding", level: "error" });
      throw new Error("Error while fetching Party wise outstanding report")
    }
  }
);

const partyWiseOutstandingSlice = createSlice({
  name: "partyWiseOutstanding",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
    .addCase(fetchPartyWiseOutstanding.pending, (state) => {
      state.loading = true;
    })
      .addCase(fetchPartyWiseOutstanding.fulfilled, (state, action) => {
        state.status = true;
        state.loading = false;
        state.reportItems = action.payload;
      })
      .addCase(fetchPartyWiseOutstanding.rejected, (state, action) => {
        state.error = true;
        state.loading = false;
        state.errorMessage = (i18n("Error while fetching Party wise outstanding report. Please try again later."));
        state.status = false;
      });
  },
});

export default partyWiseOutstandingSlice.reducer;
