import { Col } from "react-bootstrap";
import DataGrid from "../../shared/components/DataGrid";
import { useDispatch, useSelector } from "react-redux";
import {
  editpaymentSelected,
  paymentSelected,
  selectAllPartyPayment,
  selectPartByType,
} from "../store/partyPaymentsSlice";
import { i18n } from "../../shared/helpers/sharedMethods";
import { formatAmount } from "../../pos/helpers/cartHelper";
import { useEffect } from "react";
import { sumOfAmount } from "../store/paymentEntitySlice";
import DataTable from "react-data-table-component";

const PaymentStack = (props) => {
  const { edit, editData, type } = props;
  const dispatch = useDispatch();

  const checkedItems = useSelector((state) => state.partyPayments.checkedItems);
  
  const partyPayments = useSelector((state) => selectPartByType(state, type));
  console.log('partyPayments',partyPayments);

  useEffect(()=>{
    if(partyPayments){
    dispatch(sumOfAmount({payments:partyPayments}))
    }
  },[partyPayments])
  
  
  const currencySymbol  = useSelector((state) => state.getSetting?.dataSetting.currency);
  

  const handlePaymentSelected = (rowId, index) => {
    const foundItem = partyPayments.find((item) => item.id === rowId);
    
    if(!editData){
      dispatch(paymentSelected({ rowId, index }));
    } else {
      dispatch(editpaymentSelected({ rowId, index, foundItem, editData }));
    }
  };

  const columns = [
    {
      id: "settle_invoice",
      name: i18n("SETTLE INVOICE"),
      cell: (row, index) => {
        return (
          <div>
            <input
              type="checkbox"
              checked={checkedItems[row?.id] || false}
              onChange={() => {
                let rowId = row.id;
                handlePaymentSelected(rowId, index);
              }}
            />
          </div>
        );
      },
    },
    {
      id: "date",
      name: i18n("Date"),
      selector: (row) => {
        const date = new Date(row.voucher_date);
        return date.toLocaleDateString(); 
      },
    },
    {
      id: "invoice_number",
      name: i18n("INVOICE NUMBER"),
      selector: (row) => row.voucher_number,
    },
    {
      id: "invoice_amount",
      name: i18n("INVOICE AMOUNT"),
      selector: (row) => row?.grand_total,
      cell: (row) =>
        row?.grand_total ? (
          <span>
            {currencySymbol}{" "}
            {parseFloat(row?.grand_total).toLocaleString("en-IN", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </span>
        ) : (
          <span>{currencySymbol} 0.00</span>
        ),
    },
    {
      id: "paid_amount",
      name: i18n("PAID AMOUNT"),
      selector: (row) => row?.paid_amount,
      cell: (row) =>
        row?.paid_amount ? (
          <span>
            {currencySymbol}{" "}
            {parseFloat(row?.paid_amount).toLocaleString("en-IN", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </span>
        ) : (
          <span>{currencySymbol} 0.00</span>
        ),
    },
    {
      id: "ibalance_amount",
      name: i18n("BALANCE AMOUNT"),
      selector: (row) => row?.balance_amount,
      cell: (row) =>
        row?.balance_amount ? (
          <span>
            {currencySymbol}{" "}
            {parseFloat(row?.balance_amount).toLocaleString("en-IN", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </span>
        ) : (
          <span>{currencySymbol} 0.00</span>
        ),
    },
  ];

  return (
    <Col
      className="col-md-12 mb-5"
    >
      <DataTable
        titles={"Payment In"}
        data={partyPayments}
        columns={columns}
        paginationRowsPerPageOptions={[50, 100, 150, 200, 250]}
        paginationTotalRows={partyPayments && partyPayments.length}
        responsive
        highlightOnHover
        pointerOnHover
        handleSearch
      />
    </Col>
  );
};
export default PaymentStack;
