import React, { useRef, useState, useEffect } from "react";
import Layout from "../../shared/components/Layout";
import InvoiceLayout from "../../shared/components/invoiceLayouts/invoiceLayout";
import {
  Button,
  Container,
  Form,
  Stack,
  Dropdown,
  ButtonGroup,
  Modal,
} from "react-bootstrap";
import { useLocation, useNavigate, useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import html2canvas from "html2canvas";
import { useReactToPrint } from "react-to-print";
import jsPDF from "jspdf";
import TwoStageLayoutHeader from "../../shared/components/TwoStatgeLayoutHeader";
import Header from "../../shared/components/Header";
import { i18n, placeholderText } from "../../shared/helpers/sharedMethods";
import SubscriptionAllInvoiceStylish from "./subscription_order/subscription_invoice/SubscriptionAllInvoiceStylish";
import SubscriptionAllInvoiceDefault from "./subscription_order/subscription_invoice/SubscriptionAllInvoiceDefault";
import SubscriptionAllInvoiceGST from "./subscription_order/subscription_invoice/SubscriptionAllInvoiceGst";
import SubscriptionReceivedAmount from "./SubscriptionReceivedAmountModel";
import { UilPrint, UilImport, UilEdit } from "@iconscout/react-unicons";
import { resetSubscription } from "../store/SubscriptionSlice";
import {
  getSubscriptionVoucher,
  selectSubscriptionVoucherById,
} from "../store/getAllSubscriptionVoucherSlice";
import ShortcutKeys from "../../shared/components/ShortcutKeys";
import PaymentModelForSubscription from "./PaymentModelForSubscription";
import { resetCreateSubscription } from "../store/CreateSubscriptionSlice";

const SubscriptionAllInvoiceView = () => {
  const { id } = useParams();
  ;
  const subsData = useSelector((state) =>
    selectSubscriptionVoucherById(state, id)
  );
  ;
  const location = useLocation();
  const selectedBusiness = useSelector(
    (state) => state?.auth?.selectedBusiness
  );
  const accessToken = useSelector(
    (state) => state?.auth?.accessToken?.access_token
  );
  const tenantId = selectedBusiness?.pivot?.tenant_id;
  const { status, error, savedEntity } = useSelector(
    (state) => state.createSubscription
  );

  const [showReceivedAmountModal, setShowReceivedAmountModal] = useState(false);
  const [showPaymentModel, setShowPaymentModel] = useState(false);


  useEffect(() => {
    console.log("sub status", status);
    console.log("savedEntity", savedEntity);
    if (status && savedEntity) {
      toast.success("Subscription created successfully!");
      dispatch(resetCreateSubscription());
    }
  }, [status, savedEntity]);

  const toggleReceivedAmountModal = () => {
    dispatch(resetSubscription());
    setShowReceivedAmountModal(true);
  };

  const handleClose = () => {
    dispatch(resetSubscription());
    setShowReceivedAmountModal(false);
  };

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      getSubscriptionVoucher({ id: id, token: accessToken, tenantId: tenantId })
    );
  }, [dispatch]);

  const invoicesSetting = useSelector((state) => state.setting.invoicesType);

  let subscriptionTabs;
  switch (true) {
    case invoicesSetting?.invoice_1 === true:
      subscriptionTabs = [
        {
          key: "1",
          title: "Default",
          component: (
            <SubscriptionAllInvoiceStylish
              name="Subscription"
              id={id}
              data={subsData}
            />
          ),
        },
      ];
      break;
    case invoicesSetting?.invoice_2 === true:
      subscriptionTabs = [
        {
          key: "2",
          title: "Theme1",
          component: (
            <SubscriptionAllInvoiceDefault
              name="Subscription"
              id={id}
              data={subsData}
            />
          ),
        },
      ];
      break;
    case invoicesSetting?.invoice_3 === true:
      subscriptionTabs = [
        {
          key: "3",
          title: "Theme2",
          component: (
            <SubscriptionAllInvoiceGST
              name="Subscription"
              id={id}
              data={subsData}
            />
          ),
        },
      ];
      break;
    default:
      subscriptionTabs = [
        {
          key: "1",
          title: "Default",
          component: (
            <SubscriptionAllInvoiceStylish
              name="Subscription"
              id={id}
              data={location}
            />
          ),
        },
      ];
      break;
  }

  const componentRef = useRef();

  const DownloadPdf = () => {
    html2canvas(componentRef.current, {
      scale: 2,
      useCORS: true,
      allowTaint: true,
      logging: true,
    }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png", 0.8);
      const pdf = new jsPDF("p", "mm", "a4", true);

      let selectedLayout;
      if (invoicesSetting && invoicesSetting.invoice_1) {
        selectedLayout = subscriptionTabs.find((tab) => tab.key === "1");
      } else if (invoicesSetting && invoicesSetting.invoice_2) {
        selectedLayout = subscriptionTabs.find((tab) => tab.key === "2");
      } else if (invoicesSetting && invoicesSetting.invoice_3) {
        selectedLayout = subscriptionTabs.find((tab) => tab.key === "3");
      } else {
        selectedLayout = subscriptionTabs[0];
      }

      pdf.addImage(imgData, "PNG", 10, 10, 150, 0);

      const timeStamp = Date.now();
      pdf.save(`subscription_invoice_${timeStamp}`);
    });
  };

  const fileName = Date.now();

  const handlePrint = () => {
    html2canvas(componentRef.current, {
      scale: 2,
      useCORS: true,
      allowTaint: true,
      logging: true,
    }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png", 1.0);
      const pdf = new jsPDF("p", "mm", "a4", true);

      const imgWidth = 210;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;

      pdf.addImage(imgData, "PNG", 0, 0, imgWidth, imgHeight);
      const timeStamp = Date.now();
      const filename = `sales_invoice_${timeStamp}.pdf`;
      const pdfBlob = pdf.output("blob");
      const blobUrl = URL.createObjectURL(pdfBlob);
      window.open(blobUrl);
    });
  };

  const handleItemClick = (data) => {
    navigate("/subscription/membership/view/" + data);
  };

  const handleReturnToGrid = () => {
    navigate("/subscription/invoices");
  };

  const header = (
    <TwoStageLayoutHeader
      // currentTitle={
      //   // placeholderText("Invoice for Party ID") +
      //   // " " +
      //   // subsData?.party?.id +
      //   "#" +
      //   subsData?.voucher_number
      // }
      ItemActions={
        <Stack
          gap={3}
          direction="horizontal"
          className="mt-2 mb-2 ml-3 float-end"
        >
          <Dropdown as={ButtonGroup}>
            <Dropdown.Toggle variant="outline-primary" id="dropdown-custom" onClick={toggleReceivedAmountModal}>
              {i18n("Payments")}
            </Dropdown.Toggle>

            {/* <Dropdown.Item
                onClick={() => {
                  setShowPaymentModel(!showPaymentModel);
                }}
              > */}
          </Dropdown>
          {/* <div>
            <Button
              className=""
              variant="outline-secondary"
              onClick={DownloadPdf}
            >
              <FontAwesomeIcon className="mx-2 mb-1" icon={faDownload} />
              {i18n("Download PDF")}
            </Button>
          </div> */}
          <div>
            <Button
              className=""
              variant="outline-secondary"
              onClick={handlePrint}
            >
              <UilPrint className="mx-2 mb-1" size="16" />
              {i18n("Print Pdf")}
            </Button>
          </div>
        </Stack>
      }
      returnToGrid={handleReturnToGrid}
      adjustment="subscription"
    />
  );

  return (
    <>
      <ToastContainer />
      <Layout>
        <Header
          currentTitle={`#${subsData?.voucher_number}`}
          ItemActions={
            <Stack
              gap={3}
              direction="horizontal"
              className="my-2 justify-content-end"
            >
              <ShortcutKeys />
            </Stack>
          }
        />
        <Container fluid>
          <SubscriptionReceivedAmount
            showReceivedAmountModal={showReceivedAmountModal}
            handleClose={handleClose}
            data={subsData}
          />
          <InvoiceLayout
            tabs={subscriptionTabs}
            handleItemClick={handleItemClick}
            currentItemId={id}
            returnToGrid={handleReturnToGrid}
            print={componentRef}
            header={header}
          />
        </Container>
      </Layout>
    </>
  );
};

export default SubscriptionAllInvoiceView;
