import { faInr, faUsers } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Layout from "../../shared/components/Layout";
import PartyHeader from "../components/PartyHeader";
import { Container, Col, Row, Form } from "react-bootstrap";
import SummaryChartIndex from "../components/chart/summary/SummaryChartIndex";
import { selectAllparty } from "../store/getPartySlice";
import { fetchPartyOverview } from "../store/partyOverviewSlice";
import PartyCategoryFilterChartIndex from "../components/chart/party_category_filter_chart/PartyCategoryFilterChartIndex";
import Header from "../../shared/components/Header";
import { i18n } from "../../shared/helpers/sharedMethods";

const PartyOverView = () => {
  const dispatch = useDispatch();
  const allPartys = useSelector(selectAllparty);
  const partyOverview = useSelector((state) => state.partyOverview.reportItems);
  const currencySymbol  = useSelector((state) => state.getSetting?.dataSetting.currency);
  ;
  useEffect(() => {
    dispatch(fetchPartyOverview());
  }, []);
  return (
    <Layout >
      <Header currentTitle={i18n("Overview")} />
      <Container fluid className="px-5">
      <div className="border" style={{ background : "white" }}>

        <PartyHeader />
        <div className="d-flex justify-content-evenly px-8 mt-4">
          <Col
            lg={4}
            className="mx-2 p-3 d-flex justify-content-between align-items-center border rounded-1"
          >
            <div
              className="d-flex align-items-center"
              style={{ lineHeight: "30px" }}
            >
              <div>
                <FontAwesomeIcon className="mx-2 mb-1 fs-4" icon={faUsers} />
              </div>
              <Form.Label className="fs-4">{i18n("All Parties")}</Form.Label>
            </div>
            <Form.Label className="mx-2 fs-4 pt-1" >
              {allPartys.length}
            </Form.Label>
          </Col>
          <Col
            lg={4}
            className="mx-2 p-3 d-flex justify-content-between align-items-center border rounded-1"
          >
            <div
              className="d-flex align-items-center text-collect fs-4"
             
            >
              <FontAwesomeIcon className="mx-2" icon={faInr} />
              {i18n("To Collect")}
            </div>
            {Array.isArray(partyOverview?.getSales) &&
            partyOverview?.getSales.length > 0 ? (
              partyOverview.getSales.map((value, index) => (
                <Form.Group className=" mx-2  d-flex flex-direction-row justify-content-end">
                  <Form.Text
                    className="fs-4 text-black"
                    
                  >
                    {currencySymbol}{" "}
                    {value?.paidAmount
                      ? parseFloat(value.paidAmount).toFixed(2)
                      : 0}
                  </Form.Text>
                  <Form.Text
                    className="fs-6 text-black"
                  
                  >
                    {" "}
                    ({value?.totalCount ? value.totalCount : 0})
                  </Form.Text>
                </Form.Group>
              ))
            ) : (
              <label>0 </label>
            )}
          </Col>
          <Col
            lg={4}
            className="mx-2 p-3 d-flex justify-content-between align-items-center border rounded-1"
          >
            <div
              className="d-flex align-items-center text-pay fs-4"
            >
              <FontAwesomeIcon className="mx-2 " icon={faInr} />
            {i18n("To Pay")}
            </div>
            {Array.isArray(partyOverview?.getPurchase) &&
            partyOverview?.getPurchase.length > 0 ? (
              partyOverview.getPurchase.map((value, index) => (
                <Form.Group className=" mx-2  d-flex flex-direction-row justify-content-end">
                  <Form.Text
                    className="fs-4 text-black"
                    
                  >
                    {currencySymbol}{" "}
                    {value?.paidAmount
                      ? parseFloat(value.paidAmount).toFixed(2)
                      : 0}
                  </Form.Text>
                  <Form.Text
                    className="fs-6 text-black"
                   
                  >
                    {" "}
                    ({value?.totalCount ? value.totalCount : 0})
                  </Form.Text>
                </Form.Group>
              ))
            ) : (
              <label>0 </label>
            )}
          </Col>
        </div>
        <Row className="px-4 mb-2 mt-4">
          <Col xl={8} md={8} sm={8}>
            <SummaryChartIndex />
          </Col>
          <Col xl={4} md={4} sm={4}>
            <PartyCategoryFilterChartIndex />
          </Col>
        </Row>
        </div>
      </Container>
    </Layout>
  );
};

export default PartyOverView;
