import {
    createSlice,
    createAsyncThunk,
  } from "@reduxjs/toolkit";
  import { i18n } from "../../shared/helpers/sharedMethods";
  import { axiosRequest } from "../../shared/helpers/axiosHelper";

  const initialState = {
    status:false,
    reportItems:[],
    error:false,
    errorMessage:'',
    loading:false,
  };

export const fetchPurchaseSummaryReport = createAsyncThunk(
    "purchaseSummaryReport/fetch",
    async ({ token, tenant_id, startDate, endDate }) => {
      try{
      const PurchaseSummaryResponse = await axiosRequest({
        method: "GET",
        url: `get-purchase-summary-report`,
        params: { startDate, endDate },
        headers: { Authorization: `Bearer ${token}`, "X-Tenant": tenant_id },
      });
      return PurchaseSummaryResponse.data;
    }catch(err){
      await window.log.logToFile({message: "Error while fetching Purchase summary", level: "error" });
      throw new Error("Error while fetching Purchase summary report")
    }
    }
  );

const purchaseSummaryReportSlice = createSlice({
    name: "purchaseSummaryReport",
    initialState,
    reducers: {},
    extraReducers(builder) {
      builder
      .addCase(fetchPurchaseSummaryReport.pending, (state) => {
        state.loading = true;
    })
        .addCase(fetchPurchaseSummaryReport.fulfilled, (state, action) => {
            state.status = true;
            state.loading = false;
            state.reportItems = action.payload;
        })
        .addCase(fetchPurchaseSummaryReport.rejected, (state, action) => {
          state.error = true; 
          state.loading = false;
          state.errorMessage= (i18n("Error while fetching Purchase summary report. Please try again later."));
          state.status = false;
        })
    },
});

export default purchaseSummaryReportSlice.reducer;
