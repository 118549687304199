import React, { useState, useEffect } from "react";
import { Button, Row, Col, Stack, Container,Image } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Layout from "../../shared/components/Layout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowDown,
  faArrowLeft,
  faArrowUp,
  faChevronLeft,
  faDownload,
} from "@fortawesome/free-solid-svg-icons";
import { downloadCsvFile, handleExportClick } from "../../shared/export";
import DataGrid from "../../shared/components/DataGrid";
import { format } from "date-fns";
import ReportPeriod from "../../shared/components/ReportPeroid";
import Header from "../../shared/components/Header";
import { fetchGSTPurchaseReport } from "../store/gstPurchaseReportSlice";
import GSTNavbar from "../components/GSTNavbar";
import { i18n } from "../../shared/helpers/sharedMethods";
import ShortcutKeys from "../../shared/components/ShortcutKeys";
import DatePicker from "../../shared/components/date_range/DatePicker";
import ReportDateRange from "../../shared/components/date_range/ReportDateRange";
import { CurrentWeekDateRange } from "../../shared/components/DateRangeYAxis";
import ReportHeader from "../components/ReportHeader";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import CustomeSortIcon from "../../shared/components/CustomeSortIcon";
import { UilImport } from "@iconscout/react-unicons";
import LoadingSpinner from '../../shared/components/LoadingSpinner';
import GlobalCurrency from "../../shared/components/GlobalCurrency";


const GSTPurchaseReport = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const accessToken = useSelector((state) => state?.auth?.accessToken?.access_token);
  const selectedBusiness = useSelector((state) => state?.auth?.selectedBusiness);
  const tenantId = selectedBusiness?.pivot?.tenant_id;
  const [reportPeriod, setReportPeriod] = useState("current-week");
  const [csvData, setCsvData] = useState("");
  const [startDate, setStartDate] = useState(null); // State for start date
  const [endDate, setEndDate] = useState(null);
  const [sortField, setSortField] = useState(null);
  const [sortOrder, setSortOrder] = useState(1);

   const handleReset = () => {
     const salesDateLimits = ReportDateRange({
       reportPeriod: reportPeriod,
     });
     setStartDate(salesDateLimits.startDate);
     setEndDate(salesDateLimits.endDate);
     setReportPeriod("current-week");
   };
  

  useEffect(() => {
    dispatch(fetchGSTPurchaseReport({startDate: startDate, endDate: endDate,token:accessToken,tenant_id:tenantId})); 
  }, [startDate, endDate]);
  
useEffect(() => {
  const dateLimits = ReportDateRange({ reportPeriod });
  setStartDate(dateLimits.startDate);
  setEndDate(dateLimits.endDate);
}, [reportPeriod]);
  const reportItems = useSelector((state) => state.gstPurchaseReport.reportItems);
  const status = useSelector((state) => state.gstPurchaseReport.status);
  const currencySymbol  = useSelector((state) => state.getSetting?.dataSetting.currency);
  const loading = useSelector((state) => state.gstPurchaseReport.loading);

  const dateTimeString = new Date()
  .toLocaleString()
  .replace(/[-]/g, " ")
  .replace(/:/g, "-");
const fileName = `GST_purchase_report_${dateTimeString}`;

  const handleExportButtonClick = () => {
    const exportData =
      reportItems &&
      Array.isArray(reportItems) &&
      reportItems.map((val) => ({
        "DATE": val.voucher_date ? val.voucher_date : "-",
        "INVOICE NO.": val.voucher_number ? val.voucher_number : "-",
        "PARTY NAME": val.partyName ? val.partyName : "-",
        "GSTIN": val.gstin ? val.gstin : "-",
        "ITEM NAME": val.product_name ? val.product_name : "-",
        "HSN CODE.": val.hsn ? val.hsn : "-",
        "QTY": val.quantity ? val.quantity : "-",
        "PRICE": val?.cost_price ? parseFloat(val.cost_price).toFixed(2) : "0",
        "SGST": val.sgst ? val.sgst : "0",
        "CGST": val.cgst ? val.cgst : "0",
        "IGST": val.igst ? val.igst : "0",
        " AMOUNT": val.amount
          ? parseFloat(val.amount).toFixed(2)
          : "0",
      }));
    handleExportClick(
      exportData,
      setCsvData,
      downloadCsvFile,
      fileName
    );
  };
  const customSort = (field) => {
    setSortField(field);
    setSortOrder(sortOrder === 1 ? -1 : 1);
  };

  const sortedData = [...reportItems].sort((a, b) => {
    if (sortField) {
      const compareValueA = a[sortField];
      const compareValueB = b[sortField];
      if (compareValueA < compareValueB) {
        return sortOrder;
      }
      if (compareValueA > compareValueB) {
        return -sortOrder;
      }
    }
    return 0;
  });
  const headerStyle = {
    backgroundColor: "white",
  };
  const headerCellStyle = {
    fontWeight: "bold",
    fontSize: "11px",
  };
  return (
    <Layout>
         {loading && <LoadingSpinner />}
      <Header
        currentTitle={i18n("GST Purchase (With HSN)")}
        ItemActions={
          <Stack gap={3} direction="horizontal" className="mt-1 mb-1 ">
            <ShortcutKeys />
            </Stack>
        }
        />

      <Container fluid className="px-5">
             <div className="border " style={{ background: "white" }}>
            <GSTNavbar />
            <ReportHeader
            ItemActions={
              <Stack gap={2} direction="horizontal" className="pt-3 px-3 mb-3">
                <Stack direction="horizontal">
                  <DatePicker
                    startDate={startDate}
                    setStartDate={setStartDate}
                    endDate={endDate}
                    setEndDate={setEndDate}
                  />
                </Stack>
                <Stack className=" ">
                  <ReportPeriod
                    reportPeriod={reportPeriod}
                    setReportPeriod={setReportPeriod}
                  />
                </Stack>
                <Stack
                  gap={1}
                  direction="horizontal"
                  style={{ marginLeft: "1px" }}
                >
                  <Button
                    className="rounded-0"
                    variant="outline-danger"
                    onClick={handleReset}
                  >
                      {i18n("Reset")}
                  </Button>
                </Stack>
                <Button
                  className="fw-bolder d-flex align-items-center rounded-0"
                  onClick={handleExportButtonClick}
                >
                  <UilImport
                    size="18"
                    className="mx-2"
                    style={{ cursor: "pointer" }}
                  />
                  {i18n("Export")}
                </Button>
              </Stack>
            }
          />
              {/* <label className="d-flex flex-direction-row p-4 fs-3">
          Net Amount :{" "}
          {reportStatus === "red" ? (
            <label className=" fw-bolder fs-2  " style={{ color: "red" }}>
              {" "}
              <FontAwesomeIcon
                className="px-1 "
                icon={faArrowDown}
                style={{ color: "red", width: "16px", paddingTop: "3px" }}
              />{" "}
              {totalProfit < 0 ? "-" : ""} {currencySymbol}{" "}
              {Math.abs(totalProfit) || 0}
            </label>
          ) : reportStatus === "green" ? (
            <label className="fw-bolder fs-2 " style={{ color: "green" }}>
              {" "}
              <FontAwesomeIcon
                className="px-1"
                icon={faArrowUp}
                style={{ color: "green", width: "16px", paddingTop: "3px" }}
              />
              {currencySymbol} {totalProfit || 0}
            </label>
          ) : (
            <label className="fw-bolder fs-2 "> {totalProfit || 0}</label>
          )}
        </label> */}
              {/* {status ? (
                <DataGrid
                  allData={reportItems}
                  data={reportItems}
                  columns={gridColumns}
                  isPaginated={true}
                  title="Report"
                />
              ) : (
                <p className="d-flex justify-content-center p-2 fs-3 fw-bolder">
                  {i18n("Error while fetching GST Purchase report")}
                </p>
              )} */}
                        <Container fluid className="px-3 mb-4">
            <div className="bordered-container">
              {status && reportItems ? (
                <DataTable
                  value={sortedData}
                  headerStyle={headerStyle}
                  paginator
                  rows={10}
                  dataKey="id"
                  emptyMessage="No GST Purchase (With HSN) found."
                  className="p-datatable-gridlines full-width-table custom-datatable p-component"
                  scrollable
                  scrollHeight="400px"
                  sortMode="multiple"
                  removableSort
                >
                  <Column
  field="voucher_date"
  header={() => (
    <div
      className={`custom-header-${sortField === "voucher_date" ? (sortOrder === 1 ? "up" : "down") : ""}`}
      onClick={() => customSort("voucher_date")}
      style={{
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        fontSize: "11px",
        lineHeight: "11px",
      }}
    >
      {i18n("DATE")}
      <CustomeSortIcon
        sortOrder={sortField === "voucher_date" ? sortOrder : 0}
        style={{ marginLeft: "4px", verticalAlign: "middle" }}
      />
    </div>
  )}
  body={(row) => format(new Date(row.voucher_date), "dd/MM/yyyy")}
  sortable
  headerStyle={headerCellStyle}
/>

<Column
  field="voucher_number"
  header={() => (
    <div
      className={`custom-header-${sortField === "voucher_number" ? (sortOrder === 1 ? "up" : "down") : ""}`}
      onClick={() => customSort("voucher_number")}
      style={{
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        fontSize: "11px",
        lineHeight: "11px",
      }}
    >
      {i18n("INVOICE NO.")}
      <CustomeSortIcon
        sortOrder={sortField === "voucher_number" ? sortOrder : 0}
        style={{ marginLeft: "4px", verticalAlign: "middle" }}
      />
    </div>
  )}
  body={(row) => row.voucher_number}
  sortable
  headerStyle={headerCellStyle}
  style={{ width: "120px" }}
/>

<Column
  field="partyName"
  header={() => (
    <div
      className={`custom-header-${sortField === "partyName" ? (sortOrder === 1 ? "up" : "down") : ""}`}
      onClick={() => customSort("partyName")}
      style={{
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        fontSize: "11px",
        lineHeight: "11px",
      }}
    >
      {i18n("PARTY NAME")}
      <CustomeSortIcon
        sortOrder={sortField === "partyName" ? sortOrder : 0}
        style={{ marginLeft: "4px", verticalAlign: "middle" }}
      />
    </div>
  )}
  body={(row) => row.partyName}
  sortable
  headerStyle={headerCellStyle}
  style={{ width: "120px" }}
/>

<Column
  field="gstin"
  header={() => (
    <div
      className={`custom-header-${sortField === "gstin" ? (sortOrder === 1 ? "up" : "down") : ""}`}
      onClick={() => customSort("gstin")}
      style={{
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        fontSize: "11px",
        lineHeight: "11px",
      }}
    >
      {i18n("GSTIN")}
      <CustomeSortIcon
        sortOrder={sortField === "gstin" ? sortOrder : 0}
        style={{ marginLeft: "4px", verticalAlign: "middle" }}
      />
    </div>
  )}
  body={(row) => (row.gstin ? row.gstin : "-")}
  sortable
  headerStyle={headerCellStyle}
/>

<Column
  field="product_name"
  header={() => (
    <div
      className={`custom-header-${sortField === "product_name" ? (sortOrder === 1 ? "up" : "down") : ""}`}
      onClick={() => customSort("product_name")}
      style={{
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        fontSize: "11px",
        lineHeight: "11px",
      }}
    >
      {i18n("ITEM NAME")}
      <CustomeSortIcon
        sortOrder={sortField === "product_name" ? sortOrder : 0}
        style={{ marginLeft: "4px", verticalAlign: "middle" }}
      />
    </div>
  )}
  body={(row) => row.product_name}
  sortable
  headerStyle={headerCellStyle}
/>

<Column
  field="hsn"
  header={() => (
    <div
      className={`custom-header-${sortField === "hsn" ? (sortOrder === 1 ? "up" : "down") : ""}`}
      onClick={() => customSort("hsn")}
      style={{
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        fontSize: "11px",
        lineHeight: "11px",
      }}
    >
      {i18n("HSN CODE")}
      <CustomeSortIcon
        sortOrder={sortField === "hsn" ? sortOrder : 0}
        style={{ marginLeft: "4px", verticalAlign: "middle" }}
      />
    </div>
  )}
  body={(row) => (row.hsn ? row.hsn : "-")}
  sortable
  headerStyle={headerCellStyle}
/>

<Column
  field="quantity"
  header={() => (
    <div
      className={`custom-header-${sortField === "quantity" ? (sortOrder === 1 ? "up" : "down") : ""}`}
      onClick={() => customSort("quantity")}
      style={{
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        fontSize: "11px",
        lineHeight: "11px",
      }}
    >
      {i18n("QTY")}
      <CustomeSortIcon
        sortOrder={sortField === "quantity" ? sortOrder : 0}
        style={{ marginLeft: "4px", verticalAlign: "middle" }}
      />
    </div>
  )}
  body={(row) => (row.quantity ? row.quantity : "-") + " " + (row.unit || "")}
  sortable
  headerStyle={headerCellStyle}
/>

<Column
  field="cost_price"
  header={() => (
    <div
      className={`custom-header-${sortField === "cost_price" ? (sortOrder === 1 ? "up" : "down") : ""}`}
      onClick={() => customSort("cost_price")}
      style={{
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        fontSize: "11px",
        lineHeight: "11px",
      }}
    >
      {i18n("PRICE/UNIT")}
      <CustomeSortIcon
        sortOrder={sortField === "cost_price" ? sortOrder : 0}
        style={{ marginLeft: "4px", verticalAlign: "middle" }}
      />
    </div>
  )}
  body={(row) =>
    <span>
      <GlobalCurrency price={row.cost_price} />
    </span>
    }  
  sortable
  headerStyle={headerCellStyle}
/>

<Column
  field="sgst"
  header={() => (
    <div
      className={`custom-header-${sortField === "sgst" ? (sortOrder === 1 ? "up" : "down") : ""}`}
      onClick={() => customSort("sgst")}
      style={{
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        fontSize: "11px",
        lineHeight: "11px",
      }}
    >
      {i18n("SGST")}
      <CustomeSortIcon
        sortOrder={sortField === "sgst" ? sortOrder : 0}
        style={{ marginLeft: "4px", verticalAlign: "middle" }}
      />
    </div>
  )}
  body={(row) =>
    row.sgst ? (
      <GlobalCurrency price={row.sgst} />
    ) : (
      "-"
      )}
  sortable
  headerStyle={headerCellStyle}
/>

<Column
  field="cgst"
  header={() => (
    <div
      className={`custom-header-${sortField === "cgst" ? (sortOrder === 1 ? "up" : "down") : ""}`}
      onClick={() => customSort("cgst")}
      style={{
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        fontSize: "11px",
        lineHeight: "11px",
      }}
    >
      {i18n("CGST")}
      <CustomeSortIcon
        sortOrder={sortField === "cgst" ? sortOrder : 0}
        style={{ marginLeft: "4px", verticalAlign: "middle" }}
      />
    </div>
  )}
  body={(row) =>
    row.cgst ? (
      <GlobalCurrency price={row.cgst} />
    ) : (
      "-"
      )}  sortable
  headerStyle={headerCellStyle}
/>

<Column
  field="igst"
  header={() => (
    <div
      className={`custom-header-${sortField === "igst" ? (sortOrder === 1 ? "up" : "down") : ""}`}
      onClick={() => customSort("igst")}
      style={{
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        fontSize: "11px",
        lineHeight: "11px",
      }}
    >
      {i18n("IGST")}
      <CustomeSortIcon
        sortOrder={sortField === "igst" ? sortOrder : 0}
        style={{ marginLeft: "4px", verticalAlign: "middle" }}
      />
    </div>
  )}
  body={(row) =>
    row.igst ? (
      <GlobalCurrency price={row.igst} />
    ) : (
      "-"
      )}  sortable
  headerStyle={headerCellStyle}
/>

<Column
  field="amount"
  header={() => (
    <div
      className={`custom-header-${sortField === "amount" ? (sortOrder === 1 ? "up" : "down") : ""}`}
      onClick={() => customSort("amount")}
      style={{
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        fontSize: "11px",
        lineHeight: "11px",
      }}
    >
      {i18n("AMOUNT")}
      <CustomeSortIcon
        sortOrder={sortField === "amount" ? sortOrder : 0}
        style={{ marginLeft: "4px", verticalAlign: "middle" }}
      />
    </div>
  )}
  body={(row) =>
    <span>
    <GlobalCurrency price={row.amount} />
    </span>
  }
  sortable
  headerStyle={headerCellStyle}
/>

</DataTable>
              ) : (
                <p>{i18n("Loading")}</p>
              )}
            </div>
          </Container>
        </div>
      </Container>
    </Layout>
  );
};

export default GSTPurchaseReport;
