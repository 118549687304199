import { Form } from "react-bootstrap";
import React from "react";
import { placeholderText } from "../../../shared/helpers/sharedMethods";

const PartyTypeFilter = (props) => {
  const { reportType, setReportType } = props;
  return (
    <Form.Select
      className="rounded-0"
      value={reportType}
      onChange={(e) => setReportType(e.target.value)}
      style={{ cursor: "pointer" }}
    >
      <option value="customer">{placeholderText("Customers")}</option>
      <option value="supplier">{placeholderText("Suppliers")}</option>
    </Form.Select>
  );
};

export default PartyTypeFilter;
