import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, Row, Table, Col, Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { i18n } from "../../../shared/helpers/sharedMethods";
import GlobalCurrency from "../../../shared/components/GlobalCurrency";
import { fetchB2CSReport } from "../../store/B2CSSlice";
import { placeOfSupplyList } from "../../../shared/config/placeOfSupply";
import { FaQuestionCircle } from "react-icons/fa";

const B2CS = ({ startDate, endDate }) => {
  const dispatch = useDispatch();
  const accessToken = useSelector((state) => state?.auth?.accessToken?.access_token);
  const selectedBusiness = useSelector((state) => state?.auth?.selectedBusiness);
  const tenantId = selectedBusiness?.pivot?.tenant_id;
  const getTransaction = useSelector(
    (state) => state.b2CSSalesReport.reportItems
  );

  useEffect(() => {
    dispatch(fetchB2CSReport({startDate: startDate, endDate: endDate,token:accessToken,tenant_id:tenantId})); 
  }, [startDate, endDate]);
  const totalInvValue = getTransaction.reduce((sum, item) => {
    return sum + (item.invoiceValue || 0);
  }, 0);
  const taxableValue = getTransaction.reduce((sum, item) => {
    return sum + (item.taxable_value || 0);
  }, 0);
  const cess = getTransaction.reduce((sum, item) => {
    return sum + (item.cess || 0);
  }, 0);

  const toolTip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
     Supplies made to consumers and unregistered persons of the following nature
      a) Intra-State: any value
      b) Inter-State: Invoice value Rs 2.5 lakh or less
    </Tooltip>
  );

  return (
    <Row className="p-5 scrollable-table">
      <Card.Body className="">
      <Row
          className="pb-4 d-flex justify-content-between align-items-center"
          style={{ borderColor: "#39313d" }}
        >
          <Col>
          <div className="d-flex flex-wrap">
            <Button className="px-4 me-4" variant="outline-secondary">
              <span style={{ color: "#898989" }}>
                Total Invoice Value:{" "}
                <b>
                  <GlobalCurrency price={totalInvValue} />
                </b>
              </span>
            </Button>
            <Button className="px-4 me-4" variant="outline-secondary">
              <span style={{ color: "#898989" }}>
                Total Taxable Value:{" "}
                <b>
                  <GlobalCurrency price={taxableValue} />
                </b>
              </span>
            </Button>
            <Button className="px-4 me-4" variant="outline-secondary">
              <span style={{ color: "#898989" }}>
                Total Cess:{" "}
                <b>
                  <GlobalCurrency price={cess} />
                </b>
              </span>
            </Button>
            </div>
            </Col>
            <Col
            xs="auto"
            className="d-flex justify-content-end align-items-center"
          >
            <OverlayTrigger placement="bottom" overlay={toolTip}>
              <Button
                variant="outline-secondary"
                style={{
                  color: "#898989",
                  padding: "9px 8px", 
                  lineHeight: 1, 
                }}
              >
                <FaQuestionCircle size={15} />
              </Button>
            </OverlayTrigger>
          </Col>
        </Row>
        <Table bordered hover responsive>
          <thead>
            <tr>
              <th className="px-2">{i18n("Type")}</th>
              <th className="px-2">{i18n("Place Of Supply")}</th>
              <th className="px-2">{i18n("Applicable Tax %")}</th>
              <th className="px-2">{i18n("Rate")}</th>
              <th className="px-2">{i18n("Taxable Value")}</th>
              <th className="px-2">{i18n("CESS")}</th>
              <th className="px-2">{i18n("Ecommerce GSTIN")}</th>
            </tr>
          </thead>
          <tbody>
            {getTransaction &&
              getTransaction.length > 0 &&
              getTransaction.map((data, index) => (
                <tr key={index}>
                  <td className="p-1 px-2 m-1 mx-3">{"OE" ? "OE" : ""}</td>
                  <td className="p-1 px-2 m-1 mx-3">
                    <span style={{ fontSize: "12px" }}>
                      {data?.place_of_supply
                        ? placeOfSupplyList.find(
                            (item) => item.value == data?.place_of_supply
                          )?.label
                        : ""}
                    </span>
                  </td>

                  <td className="p-1 px-2 m-1 mx-3">
                    <span style={{ fontSize: "12px" }}>
                      {""}
                    </span>
                  </td>
                  <td className="p-1 px-2 m-1 mx-3">
                    <span style={{ fontSize: "12px" }}>
                    {data?.taxRate ? data?.taxRate : ""}
                    </span>
                  </td>
                  <td className="p-1 px-2 m-1 mx-3">
                    <span style={{ fontSize: "12px" }}>
                    <GlobalCurrency price={data?.taxable_value ? data?.taxable_value : ""} />
                    </span>
                  </td>
                  <td className="p-1 px-2 m-1 mx-3">
                    <span style={{ fontSize: "12px" }}>
                    <GlobalCurrency price={data?.cess ? data?.cess : ""} />
                    </span>
                  </td>
                  <td className="p-1 px-2 m-1 mx-3">
                    <span style={{ fontSize: "12px" }}>
                      {data?.gstin ? data?.gstin : ""}
                    </span>
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
      </Card.Body>
    </Row>
  );
};

export default B2CS;
