import React from "react";
import { useDispatch } from "react-redux";
import { addPartyType } from "../../store/partyDeletedSlice";
import { Form } from 'react-bootstrap';
import { placeholderText } from "../../../shared/helpers/sharedMethods";

const PartyType = () => {
  const dispatch =useDispatch()

 const handlePartyTypeChange =(value)=>{
  

  dispatch(addPartyType(value));
 }

return (
<Form.Select onChange={(e) =>{handlePartyTypeChange(e.target.value)}} defaultValue="">
    <option  value="All">{placeholderText("All")}</option>
    <option  value="supplier">{placeholderText("Supplier")}</option>
    <option  value="customer">{placeholderText("Customer")}</option>   
    
</Form.Select>
 )
}
export default PartyType;