import { customerRoutes } from "./routes/customerRoutes";
import { partiesRoutes } from "./routes/partiesRoutes";
import { supplierRoutes } from "./routes/supplierRoutes";


export const partyRoutes =  [
   ...partiesRoutes,
   ...customerRoutes,
   ...supplierRoutes,

]