import { setUPIPayment } from "../store/settingSlice";
import { Button, Card, Col, Form, Image, Row } from "react-bootstrap";
import { i18n, placeholderText } from "../../shared/helpers/sharedMethods";
import { useDispatch } from "react-redux";
import QRCode from "react-qr-code";

const PaymentBlock = ({ handleChange, values }) => {
  const dispatch = useDispatch();

  const checkPattern = (input) => {
    const regex = /[a-zA-Z0-9.\-_]{2,256}@[a-zA-Z]{2,64}/;
    return regex.test(input);
  };

  const generateQRCode = (value) => {
    const upiLink = `upi://pay?pa=${value}`;
    ;
    return (
      <QRCode
        value={upiLink}
        size={100}
        bgColor={"#ffffff"}
        fgColor={"#000000"}
        level={"L"}
      />
    );
  };
  return (
    <Card>
      <Row className="">
        <Col lg={12}>
          <Form.Group className="">
            <Form.Label className="fs-2">
              <span
                className="pb-2"
                style={{
                  borderBottom: "3px solid #1a237e",
                  fontWeight: 600,
                  fontSize: "15px",
                }}
              >
                {i18n("Payment Details")}
              </span>
            </Form.Label>
          </Form.Group>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col lg={8}>
          <Form.Group>
            <Form.Label>{i18n("Payment UPI ID")}</Form.Label>
            <Form.Control
              value={values?.upi_payment}
              name="upi_payment"
              type="text"
              style={{ box: "20px" }}
              pattern="[a-zA-Z0-9.\-_]{2,256}@[a-zA-Z]{2,64}"
              placeholder={placeholderText("Enter UPI ID")}
              onChange={(e) => {
                handleChange(e);
                dispatch(setUPIPayment(e.target.value));
              }}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col lg={12}>
          <Form.Group style={{ gap: "100px" }}>
            <Form.Label>{i18n("Preview")}</Form.Label>
            <br />
            {values?.upi_payment &&
              checkPattern(values?.upi_payment) &&
              generateQRCode(values?.upi_payment)}
          </Form.Group>
        </Col>
      </Row>
    </Card>
  );
};
export default PaymentBlock;
