import React from "react";
import { format } from "date-fns";
import { useSelector } from "react-redux";
import { selectSalesById } from "../../../voucher/store/voucherSlice";
import { Container, Row, Col, Table, Form, Image } from "react-bootstrap";
import { ToWords } from "to-words";
import { selectSelectedImage } from "../../../settings/store/settingSlice";
import "../css/invoice.css";
import { calculateTotalQuantity } from "../../helpers/invoiceHelper";
import logo from "../../../../assets/images/bill.png";
import { i18n } from "../../helpers/sharedMethods";
import { QRCode } from "react-qr-code";
import { placeOfSupplyList } from "../../config/placeOfSupply";
import { formatAmount } from "../../../pos/helpers/cartHelper";
import GlobalCurrency from "../GlobalCurrency";

const StyleOne = ({ voucherId }) => {
  const viewSale = useSelector((state) => selectSalesById(state, voucherId));
  console.log('viewSale',viewSale);

  const selectedBusiness = useSelector(
    (state) => state?.auth?.selectedBusiness
  );
  const termsAndCondition = useSelector(
    (state) => state?.setting?.billing_settings?.terms_and_conditions
  );
  const selectImage = useSelector(selectSelectedImage);
  const fetchData = useSelector((state) => state?.getSetting?.dataSetting);


  const grandTotal = viewSale?.grand_total ? viewSale?.grand_total : "0.00";
  const balanceAmount = formatAmount(
    viewSale?.grand_total - viewSale?.paid_amount
  );
  const type = viewSale?.type;
  const words = new ToWords().convert(grandTotal, { currency: true });
  const totalQuantity = calculateTotalQuantity(viewSale?.items);
  const state = placeOfSupplyList.find(
    (option) => option.value == fetchData?.state
  );
  const customerState = placeOfSupplyList.find(
    (option) => option.value == viewSale?.addresses?.billing?.state
  );

  const bankDetailsCheck =
    fetchData?.card_holder_name ||
    fetchData?.account_number ||
    fetchData?.ifsc_code ||
    fetchData?.bank ||
    fetchData?.branch_name;

  const generateQRCode = (UPIPayment) => {
    const upiLink = `upi://pay?pa=${UPIPayment}`;
    return (
      <QRCode
        value={upiLink}
        size={90}
        bgColor={"#ffffff"}
        fgColor={"#000000"}
        level={"L"}
      />
    );
  };

  return (
    <>
      <Container className="pb-5" fluid style={{ backgroundColor: "white" }}>
        <Row>
          <Col>
            <Row>
              {viewSale?.type === "sales_invoice" ? (
                <Col className="border-bottom p-2 m-0">
                  <p style={{ fontWeight: "bolder" }}>
                    {i18n("TAX INVOICE")}
                    <span
                      className="border border-secondary px-2 mx-2"
                      style={{ fontWeight: 500 }}
                    >
                      {i18n("ORIGINAL FOR RECIPIENT")}
                    </span>
                  </p>
                </Col>
              ) : (
                <Col className="border-bottom p-2 m-0">
                  <p style={{ fontWeight: 600 }}>
                    {viewSale?.type === "sales_return" && i18n("SALES RETURN")}
                    {viewSale?.type === "credit_note" && i18n("CREDIT NOTE")}
                    {viewSale?.type === "quote" && i18n("QUOTATION")}
                    {viewSale?.type === "purchase_invoice" && i18n("PURCHASE")}
                    {viewSale?.type === "purchase_order" &&
                      i18n("PURCHASE ORDER")}
                    {viewSale?.type === "debit_note" && i18n("DEBIT NOTE")}
                    {viewSale?.type === "purchase_return" &&
                      i18n("PURCHASE RETURN")}
                  </p>
                </Col>
              )}
            </Row>
          </Col>
        </Row>
        <Row>
          <Col lg={6} className="border-left border-bottom p-2 m-0">
            <Row>
              <Col
                lg={4}
                className="d-flex align-items-center justify-content-center"
              >
                <img
                  src={selectImage ? selectImage : logo}
                  alt="Logo"
                  style={{
                    objectFit: "contain",
                    maxWidth: "100%",
                    height: "auto",
                  }}
                />
              </Col>
              <Col lg={8}>
                <Row className="mt-3">
                  <Row>
                    <Col lg={12}>
                      <Form.Label
                        className=""
                        style={{ fontSize: "22px", fontWeight: 600 }}
                      >
                        {fetchData?.business_name}
                      </Form.Label>
                      <Form.Group>
                        <div className="mb-0">
                          {fetchData?.business_address
                            ? `${fetchData?.business_address} ,`
                            : ""}
                          &nbsp;{" "}
                          {fetchData?.city ? `${fetchData?.city} , ` : ""}
                          &nbsp; {state?.label ? `${state?.label}  -` : ""}
                          &nbsp; {fetchData?.pincode}
                        </div>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    {fetchData?.business_phone && (
                      <Col lg={12}>
                        <Form.Label className="mb-0">
                          {i18n("Phone")} :{" "}
                        </Form.Label>{" "}
                        &nbsp;{" "}
                        <Form.Label className="mb-0">
                          {" "}
                          {fetchData?.business_phone
                            ? fetchData?.business_phone
                            : ""}
                        </Form.Label>
                      </Col>
                    )}
                  </Row>
                  <Row>
                    {fetchData?.gstin && (
                      <Col lg={12}>
                        <Form.Label className="mb-0">
                          {i18n("GSTIN")} :
                        </Form.Label>{" "}
                        <Form.Label className="mb-0">
                          {" "}
                          &nbsp; {fetchData?.gstin ? fetchData?.gstin : ""}
                        </Form.Label>
                      </Col>
                    )}
                    {fetchData?.pan && (
                      <Col lg={12}>
                        <Form.Label className="mb-0">
                          {i18n("PAN Number")} :{" "}
                        </Form.Label>{" "}
                        &nbsp; {fetchData?.pan ? fetchData?.pan : ""}
                      </Col>
                    )}
                  </Row>
                </Row>
              </Col>
            </Row>
          </Col>
          <Col
            lg={6}
            className="border-left border-right border-bottom p-3 m-0"
          >
            <Row className="mt-3" style={{ fontSize: "14px" }}>
              <Col lg={4} style={{ flex: 1 }}>
                <Form.Label
                  className="mb-0"
                  style={{ fontSize: "13px", fontWeight: 600 }}
                >
                  {viewSale?.type === "sales_return" && i18n("Return No")}{" "}
                  {viewSale?.type === "sales_invoice" && i18n("Invoice No")}
                  {viewSale?.type === "quote" && i18n("Quotation No")}{" "}
                  {viewSale?.type === "credit_note" && i18n("Credit Note No")}
                  {viewSale?.type === "purchase_invoice" &&
                    i18n("Purchase No")}{" "}
                  {viewSale?.type === "purchase_return" && i18n("Return No")}
                  {viewSale?.type === "purchase_order" && i18n("PO No")}{" "}
                  {viewSale?.type === "debit_note" && i18n("Debit Note No")}
                </Form.Label>
              </Col>
              <Col lg={4} style={{ flex: 1 }}>
                <Form.Label
                  className="mb-0"
                  style={{ fontSize: "13px", fontWeight: 600 }}
                >
                  {viewSale?.type === "sales_return" && i18n("Return Date")}{" "}
                  {viewSale?.type === "sales_invoice" && i18n("Invoice Date")}
                  {viewSale?.type === "quote" && i18n("Quotation Date")}{" "}
                  {viewSale?.type === "credit_note" && i18n("Credit Note Date")}
                  {viewSale?.type === "purchase_invoice" &&
                    i18n("Purchase Date")}{" "}
                  {viewSale?.type === "purchase_return" && i18n("Return Date")}
                  {viewSale?.type === "purchase_order" && i18n("PO Date")}{" "}
                  {viewSale?.type === "debit_note" && i18n("Debit Note Date")}
                </Form.Label>
              </Col>
              {(balanceAmount >= 1 && type === "sales_invoice") ||
              type === "quote" ||
              type === "proforma_invoice" ||
              type === "purchase_invoice" ||
              type === "purchase_order" ? (
                <>
                  {viewSale?.due_date && (
                    <Col lg={4} style={{ flex: 1 }}>
                      <Form.Label
                        className="mb-0"
                        style={{ fontSize: "13px", fontWeight: 600 }}
                      >
                        {viewSale?.type === "sales_invoice" && i18n("Due Date")}{" "}
                        {viewSale?.type === "quote" && i18n("Expiry Date")}
                        {viewSale?.type === "purchase_invoice" &&
                          i18n("Due Date")}{" "}
                        {viewSale?.type === "purchase_order" &&
                          i18n("Expiry Date")}
                      </Form.Label>
                    </Col>
                  )}
                </>
              ) : null}
            </Row>

            <Row>
              <Col lg={4} style={{ flex: 1 }}>
                {viewSale?.voucher_number}
              </Col>
              <Col lg={4} style={{ flex: 1 }}>
                {viewSale?.voucher_date
                  ? format(new Date(viewSale?.voucher_date), "dd-MM-yyyy")
                  : "-"}
              </Col>
              {(balanceAmount >= 1 && type === "sales_invoice") ||
              type === "quote" ||
              type === "proforma_invoice" ||
              type === "purchase_invoice" ||
              type === "purchase_order" ? (
                <>
                  {viewSale?.due_date && (
                    <Col lg={4} style={{ flex: 1 }}>
                      {viewSale?.due_date
                        ? format(new Date(viewSale?.due_date), "dd-MM-yyyy")
                        : "-"}
                    </Col>
                  )}
                </>
              ) : null}
            </Row>
          </Col>
        </Row>
        <Row>
          <Col lg={6} className=" border-left p-2 m-0">
            <Row>
              <Col
                style={{
                  fontSize: "13px",
                  fontWeight: "500",
                  marginBottom: "5px",
                }}
              >
                {viewSale?.type === "purchase_invoice" ||
                viewSale?.type === "purchase_order"
                  ? i18n("BILL FROM")
                  : i18n("BILL TO")}
              </Col>
            </Row>
            <Row>
              <Col style={{ fontWeight: "600", fontSize: "14px" }}>
              {viewSale?.addresses?.billing?.name
              ? viewSale.addresses.billing.name
                          : viewSale?.party?.name}
              </Col>
            </Row>
            <Row>
              <Col>
                {viewSale?.addresses?.billing?.address && (
                  <Form.Group>
                    <Form.Label className="mb-0">
                      {viewSale?.addresses?.billing?.address}, &nbsp;{" "}
                      {viewSale?.addresses?.billing?.city}, &nbsp;{" "}
                      {customerState?.label} &nbsp;- &nbsp;{" "}
                      {viewSale?.addresses?.billing?.pincode}
                    </Form.Label>
                  </Form.Group>
                )}
              </Col>
            </Row>
            <Row>
              <Col>
                {viewSale?.party?.phone_number && (
                  <Form.Group>
                    <Form.Label className="mb-0">{i18n("Phone")} :</Form.Label>
                    &nbsp; {viewSale?.party?.phone_number}
                  </Form.Group>
                )}
              </Col>
            </Row>
            <Row>
              <Col>
                {viewSale?.party?.gstin && (
                  <Form.Group className="">
                    <Form.Label className="mb-0">{i18n("GSTIN")} :</Form.Label>
                    &nbsp; {viewSale?.party?.gstin}
                  </Form.Group>
                )}
              </Col>
            </Row>
            <Row>
              <Col>
                {viewSale?.party?.pan && (
                  <Form.Group>
                    <Form.Label className="mb-0">
                      {i18n("PAN Number")} :
                    </Form.Label>
                    &nbsp; {viewSale?.party?.pan}
                  </Form.Group>
                )}
              </Col>
            </Row>
          </Col>
          <Col lg={6} className=" border-left border-right p-2 m-0">
            <Row>
              <Col
                style={{
                  fontSize: "13px",
                  fontWeight: "500",
                  marginBottom: "5px",
                }}
              >
                {viewSale?.type === "purchase_invoice" ||
                viewSale?.type === "purchase_order"
                  ? i18n("SHIP FROM")
                  : i18n("SHIP TO")}
              </Col>
            </Row>
            <Row>
              <Col style={{ fontWeight: "600", fontSize: "14px" }}>
                {viewSale?.addresses?.shipping?.name
                  ? viewSale?.addresses?.shipping?.name
                  : viewSale?.party?.name}
              </Col>
            </Row>
            <Row>
              <Col>
                {viewSale?.addresses?.shipping?.address && (
                  <Form.Group>
                    <Form.Label className="mb-0">
                      {viewSale?.addresses?.shipping?.address}, &nbsp;{" "}
                      {viewSale?.addresses?.shipping?.city}, &nbsp;{" "}
                      {customerState?.label} &nbsp; - &nbsp;{" "}
                      {viewSale?.addresses?.shipping?.pincode}
                    </Form.Label>
                  </Form.Group>
                )}
              </Col>
            </Row>
            <Row>
              <Col>
                {viewSale?.party?.phone_number && (
                  <Form.Group>
                    <Form.Label>{i18n("Phone")} :</Form.Label>
                    &nbsp; {viewSale?.party?.phone_number}
                  </Form.Group>
                )}
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col className="border-col p-0 m-0">
            <Table className="table table-borderless">
              <thead>
                <tr
                  style={{
                    fontSize: "13px",
                    fontWeight: 500,
                    textAlign: "center",
                  }}
                >
                  <th className="table-border-head">{i18n("S.No")}</th>
                  <th className="table-border-head">{i18n("ITEMS")}</th>
                  <th className="table-border-head">{i18n("HSN")}</th>
                  <th className="table-border-head">{i18n("QTY")}</th>
                  <th className="table-border-head">{i18n("RATE")}</th>
                  {viewSale?.discount_amount > 0 && (
                    <th className="table-border-head">{i18n("DISC")}</th>
                  )}
                  {viewSale?.tax_total > 0 && (
                    <th className="table-border-head">{i18n("TAX")}</th>
                  )}
                  {viewSale?.cess_total > 0 && (
                    <th className="table-border-head">{i18n("CESS")}</th>
                  )}
                  <th className="table-border-last">{i18n("AMOUNT")}</th>
                </tr>
              </thead>
              <tbody>
              {(viewSale?.items?.length > 0 || viewSale?.voucher_items?.length > 0) && 
                (viewSale?.items || viewSale?.voucher_items)?.map((data, index) => (
                    <tr key={index}>
                      <td
                        className="border-black"
                        style={{
                          fontSize: "13px",
                          fontWeight: 400,
                          textAlign: "center",
                        }}
                      >
                        {index + 1}
                      </td>
                      <td
                        className="border-black"
                        style={{
                          fontSize: "13px",
                          fontWeight: 400,
                          textAlign: "Left",
                        }}
                      >
                        {data?.product_name}
                      </td>
                      <td
                        className="border-black"
                        style={{
                          fontSize: "13px",
                          fontWeight: 400,
                          textAlign: "center",
                        }}
                      >
                        {data?.hsn ? data?.hsn : "-"}
                      </td>
                      <td
                        className="border-black"
                        style={{
                          fontSize: "13px",
                          fontWeight: 400,
                          textAlign: "right",
                        }}
                      >
                        {data?.quantity} {data?.unit}
                      </td>
                      <td
                        className="border-black"
                        style={{
                          fontSize: "13px",
                          fontWeight: 400,
                          textAlign: "right",
                        }}
                      >
                        <GlobalCurrency price={data?.item_price} />
                      </td>
                      {viewSale?.discount_amount > 0 && (
                        <td
                          className="border-black"
                          style={{
                            fontSize: "13px",
                            fontWeight: 400,
                            textAlign: "right",
                          }}
                        >
                          <GlobalCurrency price={data?.item_discount_amount} />
                          <span style={{ fontSize: "13px", color: "grey" }}>
                            &nbsp;
                            {data?.item_discount
                              ? `(${data?.item_discount}%)`
                              : "(0%)"}
                          </span>
                        </td>
                      )}
                      {viewSale?.tax_total > 0 && (
                        <td
                          className="border-black"
                          style={{
                            fontSize: "13px",
                            fontWeight: 400,
                            textAlign: "right",
                          }}
                        >
                          <GlobalCurrency price={data?.tax_total} />
                          <span style={{ fontSize: "13px", color: "grey" }}>
                            &nbsp;
                            {data?.tax_rate ? `(${data?.tax_rate}%)` : "(0%)"}
                          </span>
                        </td>
                      )}
                      {viewSale?.cess_total > 0 && (
                        <td
                          className="border-black"
                          style={{
                            fontSize: "13px",
                            fontWeight: 400,
                            textAlign: "right",
                          }}
                        >
                          <GlobalCurrency price={data?.cess} />
                          <span style={{ fontSize: "13px", color: "grey" }}>
                            &nbsp;
                            {data?.cess_rate ? `(${data?.cess_rate}%)` : "(0%)"}
                          </span>
                        </td>
                      )}
                      <td
                        style={{
                          fontSize: "13px",
                          fontWeight: 400,
                          textAlign: "right",
                        }}
                      >
                        <GlobalCurrency price={data?.grand_total} />
                      </td>
                    </tr>
                  ))}
              </tbody>
              <tfoot>
                <tr>
                  <td className="border-black">&nbsp;</td>
                  <td className="border-black"></td>
                  <td className="border-black"></td>
                  <td className="border-black"></td>
                  <td className="border-black"></td>
                  {viewSale?.discount_amount > 0 && (
                    <td className="border-black"></td>
                  )}
                  {viewSale?.tax_total > 0 && (
                    <td className="border-black"></td>
                  )}
                  {viewSale?.cess_total > 0 && (
                    <td className="border-black"></td>
                  )}
                  <td></td>
                </tr>{" "}
                <tr>
                  <td className="border-black">&nbsp;</td>
                  <td className="border-black"></td>
                  <td className="border-black"></td>
                  <td className="border-black"></td>
                  <td className="border-black"></td>
                  {viewSale?.discount_amount > 0 && (
                    <td className="border-black"></td>
                  )}
                  {viewSale?.tax_total > 0 && (
                    <td className="border-black"></td>
                  )}
                  {viewSale?.cess_total > 0 && (
                    <td className="border-black"></td>
                  )}
                  <td></td>
                </tr>{" "}
                <tr>
                  <td className="border-black">&nbsp;</td>
                  <td className="border-black"></td>
                  <td className="border-black"></td>
                  <td className="border-black"></td>
                  <td className="border-black"></td>
                  {viewSale?.discount_amount > 0 && (
                    <td className="border-black"></td>
                  )}
                  {viewSale?.tax_total > 0 && (
                    <td className="border-black"></td>
                  )}
                  {viewSale?.cess_total > 0 && (
                    <td className="border-black"></td>
                  )}
                  <td></td>
                </tr>{" "}
                <tr>
                  <td className="border-black">&nbsp;</td>
                  <td className="border-black"></td>
                  <td className="border-black"></td>
                  <td className="border-black"></td>
                  <td className="border-black"></td>
                  {viewSale?.discount_amount > 0 && (
                    <td className="border-black"></td>
                  )}
                  {viewSale?.tax_total > 0 && (
                    <td className="border-black"></td>
                  )}
                  {viewSale?.cess_total > 0 && (
                    <td className="border-black"></td>
                  )}
                  <td></td>
                </tr>
                <tr>
                  <td className="border-black">&nbsp;</td>
                  <td className="border-black"></td>
                  <td className="border-black"></td>
                  <td className="border-black"></td>
                  <td className="border-black"></td>
                  {viewSale?.discount_amount > 0 && (
                    <td className="border-black"></td>
                  )}
                  {viewSale?.tax_total > 0 && (
                    <td className="border-black"></td>
                  )}
                  {viewSale?.cess_total > 0 && (
                    <td className="border-black"></td>
                  )}
                  <td></td>
                </tr>
                {viewSale?.charges &&
                  viewSale?.charges.length > 0 &&
                  viewSale?.charges.map((data, index) => (
                    <tr key={index}>
                      <td className="border-black px-1"></td>
                      <td
                        className="border-black bold px-3"
                        align="right"
                        style={{ fontSize: "13px" }}
                      >
                        <i>{data?.charge_type}</i>
                      </td>
                      <td className="border-black">-</td>
                      <td className="border-black">-</td>
                      <td className="border-black">-</td>
                      {viewSale?.discount_amount > 0 ? (
                        <td className="border-black"></td>
                      ) : (
                        ""
                      )}
                      {viewSale?.tax_total > 0 ? (
                        <td className="border-black">-</td>
                      ) : (
                        ""
                      )}
                      {viewSale?.cess_total > 0 ? (
                        <td className="border-black">-</td>
                      ) : (
                        ""
                      )}
                      {viewSale?.charges ? (
                        <td>
                          <GlobalCurrency price={data?.charge_value} />
                        </td>
                      ) : (
                        ""
                      )}
                    </tr>
                  ))}
                {viewSale?.roundoff !== 0 && (
                  <tr>
                    <td className="border-roundoff"></td>
                    <td
                      align="right"
                      className="border-roundoff px-3"
                      style={{ fontFamily: "Arial", fontStyle: "italic" }}
                    >
                      {i18n("Round off")}{" "}
                    </td>
                    <td
                      className="border-roundoff"
                      style={{ textAlign: "right" }}
                    ></td>
                    <td
                      className="border-roundoff"
                      style={{ textAlign: "right" }}
                    >
                      -
                    </td>
                    <td
                      className="border-roundoff"
                      style={{ textAlign: "right" }}
                    >
                      -
                    </td>
                    {viewSale?.discount_amount > 0 && (
                      <td
                        className="border-roundoff"
                        style={{ textAlign: "right" }}
                      >
                        -
                      </td>
                    )}
                    {viewSale?.tax_total > 0 && (
                      <td
                        className="border-roundoff"
                        style={{ textAlign: "right" }}
                      ></td>
                    )}
                    {viewSale?.cess_total > 0 && (
                      <td
                        className="border-roundoff"
                        style={{ textAlign: "right" }}
                      >
                        -
                      </td>
                    )}
                    <td
                      className="border-bottom"
                      style={{ textAlign: "right" }}
                    >
                      <GlobalCurrency price={viewSale?.roundoff} />
                    </td>
                  </tr>
                )}
                <tr>
                  <td
                    className="table-border-head"
                    style={{ background: "#F8F9FA" }}
                  ></td>
                  <td
                    align="right"
                    className="table-border-head bold px-3"
                    style={{ background: "#F8F9FA" }}
                  >
                    {i18n("TOTAL")}
                  </td>
                  <td
                    className="table-border-head bold"
                    style={{ background: "#F8F9FA" }}
                  ></td>
                  <td
                    className="table-border-head bold"
                    style={{ background: "#F8F9FA", textAlign: "right" }}
                  >
                    {totalQuantity}
                  </td>
                  <td
                    className="table-border-head bold"
                    style={{ background: "#F8F9FA" }}
                  ></td>
                  {viewSale?.discount_amount > 0 && (
                    <td
                      className="table-border-head bold"
                      style={{ background: "#F8F9FA", textAlign: "right" }}
                    >
                      <GlobalCurrency price={viewSale?.discount_amount} />
                    </td>
                  )}
                  {viewSale?.tax_total > 0 && (
                    <td
                      className="table-border-head bold"
                      style={{ background: "#F8F9FA", textAlign: "right" }}
                    >
                      <GlobalCurrency price={viewSale?.tax_total} />
                    </td>
                  )}
                  {viewSale?.cess_total > 0 && (
                    <td
                      className="table-border-head bold"
                      style={{ background: "#F8F9FA", textAlign: "right" }}
                    >
                      <GlobalCurrency price={viewSale?.cess_total} />
                    </td>
                  )}
                  <td
                    className="table-border-last bold"
                    style={{ background: "#F8F9FA", textAlign: "right" }}
                  >
                    <GlobalCurrency price={grandTotal} />
                  </td>
                </tr>
                <tr style={{ fontWeight: 500 }}>
                  <td className="border-for-received"></td>
                  <td align="right" className="border-for-received bold">
                    {i18n("RECEIVED AMOUNT")}{" "}
                  </td>
                  <td className="border-for-received bold"></td>
                  <td className="border-for-received"></td>
                  <td className="border-for-received"></td>
                  {viewSale?.discount_amount > 0 ? (
                    <td
                      className="border-for-received"
                      style={{ textAlign: "center" }}
                    ></td>
                  ) : null}{" "}
                  {viewSale?.tax_total > 0 ? (
                    <th
                      className="border-for-received"
                      style={{ textAlign: "center" }}
                    ></th>
                  ) : null}{" "}
                  {viewSale?.cess_total > 0 ? (
                    <th
                      className="border-for-received"
                      style={{ textAlign: "center" }}
                    ></th>
                  ) : null}{" "}
                  <td
                    className=" bold"
                    style={{
                      borderBottom: "1px solid black",
                      textAlign: "right",
                    }}
                  >
                    <GlobalCurrency price={viewSale?.paid_amount} />
                  </td>
                </tr>
                {viewSale?.paid_amount ? (
                  <tr>
                    <td className="border-for-received"></td>
                    <td align="right" className="border-for-received bold">
                      {i18n("BALANCE AMOUNT")}
                    </td>
                    <td className="border-for-received"></td>
                    <td className="border-for-received"></td>
                    <td className="border-for-received"></td>
                    {viewSale?.discount_amount > 0 ? (
                      <td
                        className="border-for-received"
                        style={{ textAlign: "center" }}
                      ></td>
                    ) : null}{" "}
                    {viewSale?.tax_total > 0 ? (
                      <td
                        className="border-for-received"
                        style={{ textAlign: "center" }}
                      ></td>
                    ) : null}{" "}
                    {viewSale?.cess_total > 0 ? (
                      <td
                        className="border-for-received"
                        style={{ textAlign: "center" }}
                      ></td>
                    ) : null}{" "}
                    <td
                      className=" bold"
                      style={{
                        borderBottom: "1px solid black",
                        textAlign: "right",
                      }}
                    >
                      <GlobalCurrency price={balanceAmount} />
                    </td>
                  </tr>
                ) : (
                  ""
                )}
              </tfoot>
            </Table>
          </Col>
        </Row>
        <Row>
          <Col className="border-right border-left p-0 m-0">
            <Table className="table table-borderless mt-2">
              <thead>
                <tr
                  align="center"
                  style={{ fontSize: "13px", fontWeight: 400 }}
                >
                  <th className="table-border-head" rowSpan="2">
                    {i18n("HSN/SAC")}
                  </th>
                  <th className="table-border-head" rowSpan="2">
                    {i18n("TAXABLE VALUE")}
                  </th>
                  {viewSale?.cgst_total && viewSale?.sgst_total > 0 ? (
                    <>
                      <th className="table-border-head" colSpan="2">
                        {i18n("CGST")}
                      </th>
                      <th className="table-border-head" colSpan="2">
                        {i18n("SGST")}
                      </th>
                    </>
                  ) : (
                    <th className="table-border-head" colSpan="2">
                      {i18n("IGST")}
                    </th>
                  )}
                  {viewSale?.cess_total > 0 && (
                    <th className="table-border-head" rowSpan="2">
                      {i18n("Cess")}
                    </th>
                  )}
                  <th className="table-border-last" rowSpan="2">
                    {i18n("TOTAL TAX AMOUNT")}
                  </th>
                </tr>
                <tr align="center">
                  {viewSale?.cgst_total && viewSale?.sgst_total > 0 ? (
                    <>
                      <th className="border-tax">{i18n("RATE")}</th>
                      <th className="border-tax">{i18n("AMOUNT")}</th>
                      <th className="border-tax">{i18n("RATE")}</th>
                      <th className="border-tax">{i18n("AMOUNT")}</th>
                    </>
                  ) : (
                    <>
                      <th className="border-tax">{i18n("RATE")}</th>
                      <th className="border-tax">{i18n("AMOUNT")}</th>
                    </>
                  )}
                </tr>
              </thead>
              <tbody>
              {(viewSale?.items?.length > 0 || viewSale?.voucher_items?.length > 0) && 
                (viewSale?.items || viewSale?.voucher_items)?.map((data, index) => (
                    <tr
                      align="center"
                      className="p-2"
                      key={index}
                      style={{ borderBottom: "1px solid black" }}
                    >
                      <td className="border-tax">
                        {data?.hsn ? data?.hsn : "-"}
                      </td>
                      <td className="border-tax">
                        <GlobalCurrency price={data?.taxable_value} />
                      </td>

                      {data?.cgst && data?.sgst ? (
                        <>
                          <td className="border-tax">
                            {data?.tax_rate ? `${formatAmount(parseFloat(data.tax_rate)) / 2}%` : "-"}
                          </td>
                          <td className="border-tax">
                            <GlobalCurrency price={data?.cgst} />
                          </td>
                          <td className="border-tax">
                          {data?.tax_rate ? `${formatAmount(parseFloat(data.tax_rate)) / 2}%` : "-"}
                          </td>
                          <td className="border-tax">
                            <GlobalCurrency price={data?.sgst} />
                          </td>
                        </>
                      ) : (
                        <>
                          <td className="border-tax">
                            {data?.tax_rate ? `${data.tax_rate}%` : "N/A"}
                          </td>
                          <td className="border-tax">
                            <GlobalCurrency price={data?.igst} />
                          </td>
                        </>
                      )}

                      {viewSale?.cess_total > 0 && (
                        <td className="border-tax">
                          <GlobalCurrency price={data?.cess} />
                        </td>
                      )}
                      <td
                        className="border-bottom"
                        style={{ textAlign: "center" }}
                      >
                        <GlobalCurrency price={data?.tax_total + data?.cess} />
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </Col>
        </Row>

        <Row>
          <Col lg={12} className="border-col border-bottom p-2 m-0">
            <b>{i18n("TOTAL AMOUNT (IN WORDS)")}</b> <br />
            {words ? `${words.toUpperCase()}` : "ZERO RUPEES"}
          </Col>
        </Row>

        <Row
          style={{
            borderRight: "1px solid black",
            borderBottom:
              fetchData?.upi_payment || bankDetailsCheck
                ? "1px solid black"
                : "",
            borderLeft: "1px solid black",
          }}
        >
          {bankDetailsCheck && (
            <Col lg={6} className=" p-2 mb-0">
              <Form.Group>
                <Form.Label
                  className=""
                  style={{ fontSize: "13px", fontWeight: 600 }}
                >
                  {i18n("BANK DETAILS")}
                </Form.Label>{" "}
                <br />
                {fetchData?.card_holder_name && (
                  <Form.Label className="mb-1">
                    {i18n("Name")}:
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    {fetchData?.card_holder_name}{" "}
                  </Form.Label>
                )}
                <br />
                {fetchData?.ifsc_code && (
                  <Form.Label className="mb-1">
                    {i18n("IFSC Code")}:
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    {fetchData?.ifsc_code}{" "}
                  </Form.Label>
                )}
                <br />
                {fetchData?.account_number && (
                  <Form.Label className="mb-1">
                    {i18n("Account No")}: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    {fetchData?.account_number}{" "}
                  </Form.Label>
                )}
                <br />
                {fetchData?.bank || fetchData?.branch_name ? (
                  <Form.Label className="mb-1">
                    {i18n("Bank")}:
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    {fetchData?.bank}, {fetchData?.branch_name}{" "}
                  </Form.Label>
                ) : (
                  ""
                )}
                <br />
              </Form.Group>
            </Col>
          )}

          {fetchData?.upi_payment && (
            <Col
              lg={6}
              className=" p-2 mb-0"
              style={{
                borderLeft: bankDetailsCheck ? "1px solid black" : "",
              }}
            >
              <Row>
                <Col lg={6} className="pr-lg-2">
                  <Form.Group>
                    <Form.Label style={{ fontSize: "13px", fontWeight: 600 }}>
                      {i18n("PAYMENT QR CODE")}
                    </Form.Label>
                    <p>
                      {i18n("UPI ID")}:<br />
                      {fetchData?.upi_payment}
                    </p>
                  </Form.Group>
                </Col>
                <Col lg={6} className="pl-lg-2 px-16">
                  {fetchData?.upi_payment &&
                    generateQRCode(fetchData?.upi_payment)}
                </Col>
              </Row>
            </Col>
          )}
        </Row>

        <Row
          className=""
          style={{
            borderBottom: "1px solid black",
            borderLeft: "1px solid black",
            borderRight: "1px solid black",
          }}
        >
          {viewSale?.tnc ||
            termsAndCondition ? (
              <Col lg={6} className=" p-2">
                <Form.Label
                  className="mb-0"
                  style={{ fontSize: "13px", fontWeight: 600 }}
                >
                  {i18n("TERMS AND CONDITIONS")}
                </Form.Label>{" "}
                <br />
                <p>{viewSale?.tnc ? viewSale?.tnc : termsAndCondition} </p>
              </Col>
            ) : ""}
          <Col
            lg={6}
            className=" p-2"
            style={{
              borderLeft:
                viewSale?.tnc || termsAndCondition ? "1px solid black" : "",
            }}
          >
            <p
              className=""
              style={{
                fontSize: "13px",
                fontWeight: 600,
                textAlign:
                  viewSale?.tnc || termsAndCondition ? "start" : "start",
              }}
            >
              {i18n("Authorized Signatory For")}
              <br />
              <Form.Label className="mb-0" style={{ fontSize: "13px" }}>
                {selectedBusiness?.business_name}
              </Form.Label>
            </p>
            {!fetchData?.signature && (
              <Col lg={11} className="border " style={{ height: "60px" }}></Col>
            )}
            <Col lg={11} className=" d-flex justify-content-start">
              {fetchData?.signature && (
                <Image
                  src={fetchData?.signature}
                  alt="signature"
                  style={{ width: "200px", height: "60px" }}
                />
              )}
            </Col>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default StyleOne;
