import {
  createSlice,
  createAsyncThunk,
} from "@reduxjs/toolkit";
import { i18n } from "../../shared/helpers/sharedMethods";
import { axiosRequest } from "../../shared/helpers/axiosHelper";

const initialState = {
  status: false,
  reportItems: [],
  error: false,
  errorMessage: ''
};

export const fetchPartySalesTotal = createAsyncThunk(
  "partySalesTotal/fetch",
  async (param) => {
    try{
      const token = param.token;
      const tenantId = param.tenant_id;
      const id = param.id;
      
      const partySalesTotalResponse = await axiosRequest({
        method: "GET",
        url: `get-party-sales-total`,
        params: {
          party_id: id,
        },
        headers: {
          Authorization: `Bearer ${token}`,
          "X-Tenant": tenantId,
        },
      });
      return partySalesTotalResponse.data;
    } catch (err) {
      await window.log.logToFile({ message: "Error while fetching Total Party sales", level: "error" });
      throw new Error("Error while fetching party sales total")
    }
  }
);

const partySalesTotalSlice = createSlice({
  name: "partySalesTotal",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchPartySalesTotal.fulfilled, (state, action) => {
        state.status = true;
        state.reportItems = action.payload;
      })
      .addCase(fetchPartySalesTotal.rejected, (state, action) => {
        state.error = true;
        state.errorMessage = (i18n("Error while fetching party sales total. Please try again later."));
        state.status = false;
      });
  },

});

export default partySalesTotalSlice.reducer;

