import { i18n, placeholderText } from "../../../shared/helpers/sharedMethods";
import {
  markAsPaidPaymentUpdate,
  markAsPaidStatus,
  updatePayment,
  roundOffGrandTotal,
  updateTotals,
} from "../../store/voucherEntitySlice";
import { useDispatch, useSelector } from "react-redux";
import {
  Col,
  InputGroup,
  Form,
  Card,
  Row,
  FormControl,
  Stack,
  Button,
} from "react-bootstrap";
import {
  defaultPaymentMode,
  paymentModes,
} from "../../../shared/config/defaults";
import { useEffect, useState } from "react";
import { numValidate } from "../../../shared/helpers/sharedMethods";
import ReceivedPaymentModel from "../ReceivedPaymentModel";
import { toast, ToastContainer } from "react-toastify";

const AmountReceived = ({ currencySymbol, isEdit = false }) => {
  const {
    paid_amount,
    payment,
    grand_total,
    subtotal,
    processing_paid_amount,
    roundoff,
  } = useSelector((state) => state.voucherEntity);

  const { voucherPayment, totalReceivedAmount, markAsPaid, items } =
    useSelector((state) => state.voucherEntity);

  const balanceAmount = isNaN(grand_total - totalReceivedAmount)
    ? 0
    : parseFloat(grand_total - totalReceivedAmount);
  const [paymentMode, setPaymentMode] = useState(defaultPaymentMode);

  const [showPaymentModel, setShowPaymentModel] = useState(false);
  const [roundOff, setRoundOff] = useState(false);
  const dispatch = useDispatch();

  const handleReceivedAmountChange = (e) => {
    if (e.target.value == "") {
      dispatch(
        updatePayment({ amount: 0, mode: paymentMode, totalReceivedAmount })
      );
    } else if (parseInt(e.target.value) <= balanceAmount) {
      dispatch(
        updatePayment({
          amount: parseInt(e.target.value),
          mode: paymentMode,
          totalReceivedAmount,
        })
      );
    } else {
      alert("warning- please enter the balance amount only");
    }
  };
  useEffect(() => {
    dispatch(
      markAsPaidPaymentUpdate({
        totalReceivedAmount,
        grand_total,
        markAsPaid,
        paid_amount,
      })
    );
    // if(markAsPaid){
    //     dispatch(updatePayment({ amount: grand_total - paid_amount , mode: paymentMode }))
    // }
    // else{
    //      dispatch(updatePayment({ amount:grand_total, mode: paymentMode }))
    // }
  }, [markAsPaid]);

  useEffect(() => {
    if (roundOff) {
      dispatch(roundOffGrandTotal(true));
    } else {
      dispatch(updateTotals(items));
    }
  }, [roundOff, items]);

  useEffect(() => {
    if (roundoff) {
      setRoundOff(true);
    }
  }, [roundoff]);

  const handleRoundOff = (e) => {
    setRoundOff(e.target.checked);
  };

  return (
    <>
      <ToastContainer />
      <ReceivedPaymentModel
        showModel={showPaymentModel}
        setShowPaymentModel={setShowPaymentModel}
      />

      <Row>
        <Col style={{ padding: "inherit" }}  lg={3}>
          <Row>
            <Form.Label
              lg={4}
              xxl={5}
              className="me-5 bold"
              style={{ color: "#4682B4", fontSize: "20px", marginTop:"7px" }}
            >
             {i18n("Payment")} {":"} 
            </Form.Label>

            {voucherPayment && voucherPayment.length > 0 && (
              <Col style={{ padding: "revert" }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <Button
                    size="sm"
                    variant="outline-info"
                    className="mx-2 mt-2"
                    onClick={() => {
                      setShowPaymentModel(!showPaymentModel);
                    }}
                  >
                    {`RECEIVED (${voucherPayment.length}) - `}
                    {isEdit && totalReceivedAmount}
                    {currencySymbol}
                    {totalReceivedAmount}
                  </Button>
                  <Col className="my-4"></Col>
                </div>
              </Col>
            )}
          </Row>
        </Col>

        <Col lg={9}>
          <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
            <Col md={5}>
              <Form.Check
                inline
                label={i18n("Mark as Paid")}
                name="mark_as_paid"
                type="checkbox"
                id="mark_as_paid_check"
                onChange={(e) => {
                  dispatch(markAsPaidStatus(!markAsPaid));
                }}
                style={{fontSize:"10px"}}
              />
  
              {/* <Form.Check
                inline
                label={placeholderText("Round Off")}
                name="round_off"
                type="checkbox"
                id="round_off_check"
                className="float-end mx-0"
                onChange={handleRoundOff}
                checked={roundOff}
              /> */}
            </Col>
            
            <Col md={7}>
              <Row className="g-0"> 
                <Col md={7} className=""> 
                  <InputGroup className="ms-auto" >
                    <InputGroup.Text>{currencySymbol}</InputGroup.Text>
                    <Form.Control
                      type="text"
                      name="name"
                      className="text-end"
                      value={isNaN(processing_paid_amount) ? 0 : processing_paid_amount}
                      onKeyPress={(event) => numValidate(event)}
                      onChange={(e) => handleReceivedAmountChange(e)}
                      disabled={markAsPaid}
                      style={{borderTopRightRadius:0,borderBottomRightRadius:0}}                    />
                  </InputGroup>
                </Col>
                <Col md={5} className="">
                  <Form.Select
                  style={{borderTopLeftRadius:0,borderBottomLeftRadius:0}}                   
                    onChange={(e) => {
                      setPaymentMode(e.target.value);
                    }}
                  >
                    {paymentModes && paymentModes.length ? (
                      paymentModes.map((paymentMode, index) => (
                        <option
                          key={index}
                          value={paymentMode.value}
                          selected={payment?.mode === paymentMode.value}
                        >
                          {paymentMode.label}
                        </option>
                      ))
                    ) : (
                      <option value="">No payment mode</option>
                    )}
                  </Form.Select>
                </Col>
              </Row>
            </Col>
          </div>
      </Col>

      </Row>
    </>

  );
};

export default AmountReceived;
