import ManageAccountDetails from "./screens/ManageAccountDetails"

const rootPath = 'ManageAccounts'


export const AccountRoutes = [
    {
        path: rootPath + '/',
        element: <ManageAccountDetails />,
        isProtected: true
    },
]