import React from "react";
import { useSelector } from "react-redux";
import { Card, Stack, Table } from "react-bootstrap";
import { i18n } from "../../shared/helpers/sharedMethods";
import { UilPackage } from '@iconscout/react-unicons'
import GlobalCurrency from "../../shared/components/GlobalCurrency";

const TopSellingProducts = () => {

  const reportItems = useSelector(
    (state) => state.topSellingProducts.reportItems);
    const currency = useSelector((state) => state.getSetting?.dataSetting?.currency);

console.log('reportItes',reportItems);
  return (
    <Card className="p-3  border shadow-sm" style={{ minHeight: "330px" }}>
      <Card.Header className="p-1">
        <span className="h4 fw-bolder px-2">
          {i18n("Top Selling Products")}
        </span>
      </Card.Header>
      {!reportItems.length > 0 && (
        <Stack
          className=" d-flex justify-content-center align-items-center "
          style={{ height: "253px" }}
        >
          <UilPackage size="45" color="gray" />
          <p className="mt-2" style={{ fontSize: "12px", color: 'gray' }}>
            {i18n("No top selling products yet.")}
          </p>
        </Stack>
      )}
      {reportItems.length > 0 && (
        <>
          <Card.Body className="p-2">
            <Table hover responsive>
              <thead>
                <tr className="">
                  <th className=" p-2">{i18n("Name")}</th>
                  <th className="">{i18n("Code")}</th>
                  <th className="">{i18n("Quantity")}</th>

                  <th className="">{i18n("Total Sale")}</th>
                </tr>
              </thead>
              <tbody>
                {reportItems &&
                  reportItems?.map((value, index) => (
                    <tr key={index}>
                      <td className=" fs-6 p-3">
                        {value.ProductName ? value.ProductName : "-"}
                      </td>

                      <td className=" fs-6">{value.code ? value.code : "-"}</td>
                      <td className=" fs-6">
                        {value.saleQuantity ? value?.saleQuantity : "-"}
                      </td>
                      <td className=" fs-6">
                         {currency} {value?.totalSalesAmount}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </Card.Body>
        </>
      )}
    </Card>
  );
};

export default TopSellingProducts;
