import React, { useEffect, useState } from "react";
import { ListGroup } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  logout,
  resetAuth,
} from "../../auth/store/authSlice";
import LogoutModal from "../../shared/components/LogoutModal";
import { useDispatch, useSelector } from "react-redux";
import { i18n } from "../../shared/helpers/sharedMethods";

const ImportNavbar = () => {
  const { backUpStatus } = useSelector((state) => state.auth);
  const { accessToken, isLoggedIn } = useSelector((state) => state?.auth);
  const selectedBusiness = useSelector(
    (state) => state?.auth?.selectedBusiness
  );
  const terminalNumber = useSelector((state) => state?.auth?.terminalNumber);

  const tenantId = selectedBusiness?.pivot?.tenant_id;
  const userId = selectedBusiness?.pivot?.user_id;

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [show, setShow] = useState(false);
  const globalPlanFeatures = useSelector(
    (state) => state?.getSetting?.features
  );
  const featureName = "subscription";

  const handleLogoutModal = () => {
    setShow(!show);
  };

  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const handleLogout = () => {
    dispatch(logout());
    dispatch(resetAuth());
  };
  const MenuStyle = {
    color: "black",
    "&:hover": {
      color: "black",
      borderLeft: "4px solid #1a237e",
    },
  };
  return (
    <>
      <div
        className="col-lg-12 "
        style={{ height: "90vh", borderRight: "1px solid lightgray" }}
      >
        <ListGroup className="custom-listgroup-css " variant="flush">
          <ListGroup.Item
            className={
              location.pathname == "/setting/import-export/products"
                ? "active-highlight"
                : ""
            }
            onClick={() => {
              navigate("/setting/import-export/products");
            }}
          >
            <Link className="d-flex " to="/setting/import-export/products">
              {" "}
              {i18n("Product")}
            </Link>
          </ListGroup.Item>
          <ListGroup.Item
            className={
              location.pathname == "/setting/import-export/parties"
                ? "active-highlight"
                : ""
            }
            onClick={() => {
              navigate("/setting/import-export/parties");
            }}
          >
            <Link className="d-flex " to="/setting/import-export/parties">
              {" "}
              {i18n("Party")}
            </Link>
          </ListGroup.Item>

          <ListGroup.Item
            className={
              location.pathname == "/setting/import-export/sales"
                ? "active-highlight"
                : ""
            }
            onClick={() => {
              navigate("/setting/import-export/sales");
            }}
          >
            <Link className="d-flex " to="/setting/import-export/sales">
              {" "}
              {i18n("Sales")}
            </Link>
          </ListGroup.Item>
        </ListGroup>
        {show && <LogoutModal onConfirm={handleLogout} onCancel={setShow} />}
      </div>
    </>
  );
};

export default ImportNavbar;
