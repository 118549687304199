import { Col, Form, FormControl, Row } from "react-bootstrap";

import { useDispatch } from "react-redux";
import { ErrorMessage } from "formik";
import { changeCode } from "../../../store/subscription_item_store/SetupFeesEntitySlice";
import { i18n } from "../../../../shared/helpers/sharedMethods";
import { changePlanExpiresAfter } from "../../../store/subscription_item_store/PlanEntitySlice";

const PlanExpiresAfter = ({ values, handleChange, touched, errors }) => {
  const dispatch = useDispatch();
  return (
    <Row className="py-4">
      <Col lg={3}></Col>
      <Col lg={4} className="">
        <FormControl
          type="number"
          name="expires_after"
          value={values.expires_after||''}
          onChange={(e) => {
            handleChange(e);
            dispatch(changePlanExpiresAfter(e.target.value));
          }}
        />
      </Col>
      <Col lg={5} className="text-color">
        {i18n(
          "Number of times the customer will be billed for a subscription of this plan."
        )}
      </Col>
    </Row>
  );
};

export default PlanExpiresAfter;
