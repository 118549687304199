import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import "../../../assets/css/frontend.css";
import {
  deletesales,
  selectAllSales,
  getAllVoucher,
} from "../store/voucherSlice";
import { useNavigate } from "react-router-dom";
import DeleteModel from "../../shared/components/DeleteModel";
import DataGridHeader from "../../shared/components/DataGridHeader";
// import PartyImport from "./PartyImport";
import { classNames } from 'primereact/utils';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { IconField } from 'primereact/iconfield';
import { InputIcon } from 'primereact/inputicon';
import { Dropdown } from 'primereact/dropdown';
import { InputNumber } from 'primereact/inputnumber';
//import { Button } from 'primereact/button';
import { ProgressBar } from 'primereact/progressbar';
import { Calendar } from 'primereact/calendar';
import { MultiSelect } from 'primereact/multiselect';
import { Slider } from 'primereact/slider';
import { Tag } from 'primereact/tag';
import { downloadCsvFile, handleExportClick } from "../../shared/export";
import {
  selectSalesByType,
  selectFilteredVoucher,
} from "../store/voucherSlice";
import Badge from "react-bootstrap/Badge";
import { format } from "date-fns";
import VoucherHeader from "../components/VoucherHeader";
import Layout from "../../shared/components/Layout";
import { selectAllparty } from "../../party/store/getPartySlice";
import { resetOrder } from "../store/voucherEntitySlice";
import { resetVoucherItem } from "../store/voucherItemsSlice";
import { resetCharges } from "../store/additionalChargesSlice";
import { exportPreProcessor } from "../helpers/VouchersHelper";
import DatePicker from "../components/DatePicker";
import {
  Container,
  OverlayTrigger,
  Stack,
  Tooltip,
  Image,
  Button
} from "react-bootstrap";
import Header from "../../shared/components/Header";
import { i18n } from "../../shared/helpers/sharedMethods";
import VoucherImport from "./VoucherImport";
import { ToastContainer, toast } from "react-toastify";
import { changeToggleShow } from "../../shared/store/sharedSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faKeyboard } from "@fortawesome/free-solid-svg-icons";
import ShortcutKeys from "../../shared/components/ShortcutKeys";
import { useHotkeys } from "react-hotkeys-hook";
import { faFileInvoice } from "@fortawesome/free-solid-svg-icons";
import { UilFilePlusAlt } from "@iconscout/react-unicons";
import StatusFilter from "../components/filters/StatusFilter";
import { UilEdit, UilTrashAlt } from "@iconscout/react-unicons";
import { resetPartyAddress } from "../../party/store/partyAddressSlice";
import { getLocalizedDate } from "../../shared/helpers/localizationHelper";
import { resetSales } from "../store/voucherSlice";
import { fetchProducts, resetProduct } from "../../catalog/store/productsSlice";
import { formatAmount } from "../../pos/helpers/cartHelper";
import { setStatusFilter } from "../store/voucherSlice";
import CustomeSortIcon from "../../shared/components/CustomeSortIcon";
import GlobalCurrency from "../../shared/components/GlobalCurrency";
import { resetPayment } from "../../payment/store/paymentEntitySlice";
import { resetPartyPayment } from "../../payment/store/partyPaymentsSlice";
import LoadingSpinner from '../../shared/components/LoadingSpinner';
import SyncStatus from "../../shared/helpers/syncStatus";
import SyncStatusFilter from "../../catalog/helper/syncStatusHelper";

const VoucherIndex = (props) => {
  const { title, type, voucherType, button, path, rootPath } = props;
console.log('type',path);
console.log('voucherType',voucherType);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const allParty = useSelector(selectAllparty);
  const allSales = useSelector((state) => selectSalesByType(state, type));

  const loading = useSelector((state) => state.voucher.loading);
  const [show, setShow] = useState(false);
  const [deleteId, setDeleteId] = useState();
  const [csvData, setCsvData] = useState("");
  const [displayedSales, setDisplayedSales] = useState(allSales);
  const [exportData, setExportData] = useState(allSales);
  const [salesKey, setSalesKey] = useState(Date.now());
  const [balanceFrozen, setBalanceFrozen] = useState(false);
  const [globalFilter, setGlobalFilter] = useState('');
  const [globalFilterValue, setGlobalFilterValue] = useState('');
  const [statusOptions, setStatusOptions] = useState([]);
  const [selectedstatus, setSelectedStatus] = useState([]);
  const [filters, setFilters] = useState(null);
  const [sortField, setSortField] = useState("created_at");
  const [sortOrder, setSortOrder] = useState(-1);
    const { accessToken} = useSelector((state) => state?.auth);
  const selectedBusiness = useSelector((state) => state?.auth?.selectedBusiness);
console.log("sortField",sortField)
  useEffect(() => {
    dispatch(getAllVoucher({token: accessToken.access_token, tenant_id: selectedBusiness?.pivot?.tenant_id , type:type,  pagination: true}))
  }, [type, voucherType, accessToken, selectedBusiness])

  useEffect(() => {
    setDisplayedSales(allSales)

  }, [allSales])


  useEffect(() => {
    if (allSales.length > 0) {
      setDisplayedSales(allSales);
    }
  }, [allSales]);

  useEffect(() => {
    const options = [
      { name: 'Paid', label: 'Paid' },
      { name: 'Partially Paid', label: 'Partially Paid' },
      { name: 'Unpaid', label: 'Unpaid' },
    ];
    setStatusOptions(options);
  }, []);

  const bulkAction = () => {
    navigate(`/${path}/import`)
  };

  const getSeverity = (status) => {
    switch (status) {
      case 'Paid':
        return 'custom-tag-success';
      case 'Partially Paid':
        return 'custom-tag-warning';
      case 'Unpaid':
        return 'custom-tag-danger';
      default:
        return '';
    }
  };

const handleRefreshSales = () => {
  dispatch(getAllVoucher({token: accessToken.access_token, tenant_id: selectedBusiness?.pivot?.tenant_id , type:type}))
  dispatch(fetchProducts());
  dispatch(resetSales());
  dispatch(resetProduct());
  setSalesKey(Date.now());
};  

  const handleClearFilters = () => {
    setSelectedStatus([]);
    setFilters({
      voucher_date: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
      voucher_number: { value: null, matchMode: FilterMatchMode.CONTAINS },
      party: { value: null, matchMode: FilterMatchMode.CONTAINS },
      grand_total: { value: null, matchMode: FilterMatchMode.CONTAINS },
      created_at: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
      paid_amount: { value: null, matchMode: FilterMatchMode.EQUALS },
      status: { value: null, matchMode: 'in' },
    });
    setGlobalFilter('');
    setGlobalFilterValue('');
    setDisplayedSales(allSales);
  };

  const handleClick = () => {
    dispatch(resetOrder());
    dispatch(resetVoucherItem());
    dispatch(resetCharges());
    dispatch(resetPartyAddress());
    dispatch(changeToggleShow(true));

    navigate(`/${path}/create`);
  };

  const handleEdit = (data) => {
    if (data.grand_total - data.paid_amount == 0) {
      toast.error(i18n("You cannot edit a paid/closed order"));
      return;
    }
    if (data.grand_total - data.totalPaidAmount == 0) {
      toast.error(i18n("You cannot edit a paid/closed order"));
      return;
    }
    dispatch(resetOrder());
    dispatch(resetVoucherItem());
    dispatch(resetCharges());
    dispatch(changeToggleShow(true));
    ;
    navigate(`/${path}/edit/` + data.id);
  };
  const handleView = (data) => {
    ;
    navigate(`/${path}/view/` + data.id);
  };
  const handleDelete = async (data) => {
    setShow(!show);
    setDeleteId(data.id);
  };

  const onDelete = () => {
    dispatch(deletesales(deleteId));
    setShow(false);
    navigate(rootPath);
  };
  const dateTimeString = new Date()
    .toLocaleString()
    .replace(/[-]/g, " ")
    .replace(/:/g, "-");

  const handleExportSales = () => {
    const processedExportData = exportPreProcessor(allSales);
    setExportData(exportData);
    handleExportClick(
      processedExportData,
      (exportData) => {
        setCsvData(exportData);
      },
      downloadCsvFile,
      `${type}_${dateTimeString}`
    );
  };

  ;

  useHotkeys("alt+s", (event) => {
    event.preventDefault();
    dispatch(resetOrder());
    dispatch(resetVoucherItem());
    dispatch(resetCharges());
    dispatch(resetPartyAddress());
    dispatch(changeToggleShow(true));
    navigate("voucher/sales-invoice/create");
  });
  useHotkeys("alt+c", (event) => {
    event.preventDefault();
    dispatch(resetOrder());
    dispatch(resetVoucherItem());
    dispatch(resetCharges());
    dispatch(resetPartyAddress());
    dispatch(changeToggleShow(true));
    navigate("voucher/sales-return/create");
  });
  useHotkeys("alt+p", (event) => {
    event.preventDefault();
    dispatch(resetOrder());
    dispatch(resetVoucherItem());
    dispatch(resetCharges());
    dispatch(resetPartyAddress());
    dispatch(changeToggleShow(true));
    navigate("voucher/purchase-invoice/create");
  });
  useHotkeys("alt+r", (event) => {
    event.preventDefault();
    dispatch(resetOrder());
    dispatch(resetVoucherItem());
    dispatch(resetCharges());
    dispatch(resetPartyAddress());
    dispatch(changeToggleShow(true));
    navigate("voucher/purchase-return/create");
  });
  useHotkeys("alt+q", (event) => {
    event.preventDefault();
    dispatch(resetOrder());
    dispatch(resetVoucherItem());
    dispatch(resetCharges());
    dispatch(resetPartyAddress());
    dispatch(changeToggleShow(true));
    navigate("voucher/quotation/create");
  });

  useHotkeys("alt+o", (event) => {
    event.preventDefault();
    dispatch(resetPayment());
    dispatch(resetPartyPayment());
    dispatch(changeToggleShow(true));
    navigate('/voucher/payment-out/create')
  });

  useHotkeys("alt+i", (event) => {
    event.preventDefault();
    dispatch(resetPayment());
    dispatch(resetPartyPayment());
    dispatch(changeToggleShow(true));
    navigate('/voucher/payment-in/create')
  });


  const actionBodyTemplate = (row) => {
    return (
      <div className="d-flex justify-content-evenly align-items-center">
        <UilEdit
          className="mx-2 mb-1"
          size="15"
          style={{ cursor: "pointer" }}
          onClick={(e) => {
            e.stopPropagation(); // Prevents row click from firing
            handleEdit(row);
          }}
        />
        <UilTrashAlt
          className="mx-2 mb-1"
          size="15"
          style={{ cursor: "pointer" }}
          onClick={(e) => {
            e.stopPropagation(); // Prevents row click from firing
            handleDelete(row);
          }}
        />
      </div>
    );
  };

  const handleStatusChange = (e) => {
    setSelectedStatus(e.value);

    if (!Array.isArray(e.value)) {
      console.error('Expected e.value to be an array, but got:', e.value);
      return;
    }

    if (e.value.length === 0) {
      setDisplayedSales(allSales);
    } else {
      const filteredStatus = allSales.filter(sales =>
        e.value.some(status => sales.status.toLowerCase() === status.name.toLowerCase())
      );
      setDisplayedSales(filteredStatus);
    }
  };

  const statusItemTemplate = (option) => {
    const severityClass = getSeverity(option.name);
    return (
      <div className={`d-flex align-items-center ${severityClass}`}>
        <Tag value={option.label} className={severityClass} />
      </div>
    );
  };

  const statusBodyTemplate = (rowData) => {
    const severityClass = getSeverity(rowData.status);
    return <Tag value={rowData.status} className={severityClass} />;
  };

  const statusFilterTemplate = (options) => {
    return (
      <MultiSelect
        value={selectedstatus}
        options={statusOptions}
        optionLabel="label"
        itemTemplate={statusItemTemplate}
        onChange={handleStatusChange}
        className="p-column-filter custom-multiselect"
        style={{ fontSize: '11px' }}
      />
    );
  };

  const headerStyle = {
    backgroundColor: 'white',
  };

  const headerCellStyle = {
    fontWeight: 'bold',
    fontSize: '11px',
  };

  const dueDateBodyTemplate = (rowData) => {
    return format(new Date(rowData.voucher_date), 'dd/MM/yyyy');
  };
  const handleDateFilterChange = (value, field, filterCallback) => {
    filterCallback(value);

    if (value) {
      const filtered = allSales.filter((sale) => {
        const saleDate = new Date(sale[field]);
        const selectedDate = new Date(value);
        return saleDate.toDateString() === selectedDate.toDateString();
      });
      setDisplayedSales(filtered);
    } else {
      setDisplayedSales(allSales);
    }
  };
  console.log("all sales", allSales)
  const dueDateFilterTemplate = (options) => {
    return (
      <Calendar
        value={options.value}
        onChange={(e) => handleDateFilterChange(e.value, 'voucher_date', options.filterCallback)}
        dateFormat="dd-mm-yy"
        mask="99-99-9999"
      />
    );
  };

  const dateBodyTemplate = (rowData) => {
    return getLocalizedDate(rowData.created_at);
  };

  const handleCreatedAtDateFilterChange = (value, filterCallback) => {
    filterCallback(value);

    if (value) {
      const filtered = allSales.filter((item) => {
        const createdAtDate = new Date(item.created_at);
        const selectedDate = new Date(value);
        return createdAtDate.toDateString() === selectedDate.toDateString();
      });
      setDisplayedSales(filtered);
    } else {
      setDisplayedSales(allSales);
    }
  };

  const dateFilterTemplate = (options) => {
    return (
      <Calendar
        value={options.value}
        onChange={(e) => handleCreatedAtDateFilterChange(e.value, options.filterCallback)}
        dateFormat="dd/mm/yy"
        mask="99/99/9999"
      />
    );
  };

  const customSort = (field) => {
    if (field === 'created_at') {
      setSortOrder(-1); 
    } else {
      setSortField(field);
      setSortOrder(-1);
    }
  };

  const sortedData = [...displayedSales].sort((a, b) => {
    const dateA = new Date(a.created_at);
    const dateB = new Date(b.created_at);
    
    return dateB - dateA; 
  });

  return (
    <div>
      <Layout>
        {loading && <LoadingSpinner />}
        <ToastContainer />
        <Header
          type={type}
          currentTitle={title}
          ItemActions={
            <Stack
              gap={3}
              direction="horizontal"
              className="my-2 justify-content-end"
            >
              <ShortcutKeys />
            </Stack>
          }
        />
       
        <Container fluid className="px-5">
          <div className="bordered-container container-spacing">
            <VoucherHeader type={voucherType} />
          
            <DataGridHeader
              handleRefreshSales={handleRefreshSales}
              statusFilter={null}
              clearFilter={handleClearFilters}
              allData={allSales}
              data={displayedSales}
              addFilteredData={setDisplayedSales}
              handleCreate={handleClick}
              handleExportClick={handleExportSales}
              datas={exportData}
              isAllData={"All Sales"}
              button={button}
              type={type}
              bulkAction={bulkAction}
              icons={<UilFilePlusAlt size="50" color="gray" />}
              ItemActions={
                <Stack
                  gap={3}
                  direction="horizontal"
                  className=" justify-content-end"
                >
                  {type == "all_sales" || type == "all_purchase" ? (
                    <></>
                  ) : (
                    <Button  onClick={handleClick}>{button}</Button>
                  )}
                </Stack>
              }
            />
        
            <Container fluid className="px-4 mb-4">
              <div className="bordered-container">

                <DataTable
                  value={sortedData}
                  headerStyle={headerStyle}
                  isAllData={"All Sales"}
                  button={i18n("Create Sale")}
                  title="Sales"
                  bulkAction={bulkAction}
                  paginator
                  globalFilter={globalFilter}
                  filterDisplay="row"
                  // globalFilterFields={['due_date', 'voucher_number', 'status', 'party', 'grand_total', 'created_at', 'paid_amount']}
                  rows={10}
                  dataKey="id"
                  filters={filters}
                  emptyMessage={i18n("Create your First Transaction to Start Seeing your Data")}
                  onFilter={(e) => setFilters(e.filters)}
                  className="p-datatable-gridlines full-width-table custom-datatable p-component"
                  onRowClick={(e) => handleView(e.data)}
                  checked={balanceFrozen}
                  defaultSortField="created_at"
                  defaultSortAsc={false}
                  scrollable
                  sortMode="multiple"
                  scrollHeight="400px"
                  removableSort
                  style={{ cursor: "pointer" }}
                >
                  <Column
                    field="voucher_date"
                    header={() => (
                      <div
                        className={`custom-header-${sortField === 'voucher_date' ? (sortOrder === 1 ? 'up' : 'down') : ''}`}
                        onClick={() => customSort('voucher_date')}
                        style={{
                          cursor: 'pointer',
                          display: 'flex',
                          alignItems: 'center',
                          fontSize: '11px',
                          lineHeight: '11px',
                        }}
                      >
                        {i18n("INV Date")}
                        <CustomeSortIcon sortOrder={sortField === 'voucher_date' ? sortOrder : 0}
                          style={{ marginLeft: '4px', verticalAlign: 'middle' }} />
                      </div>
                    )}
                    sortable
                    body={dueDateBodyTemplate}
                    filter
                    filterElement={dueDateFilterTemplate}
                    filterPlaceholder=""
                    headerStyle={headerCellStyle}
                    showFilterMatchModes={false}
                    showClearButton={false}
                    showFilterOperator={false}
                    showAddButton={false}
                    filterMatchMode={FilterMatchMode.CONTAINS}
                    // showApplyButton={false}
                    showFilterMenu={false}
                  />
                  <Column field="voucher_number"
                    header={() => (
                      <div
                        className={`custom-header-${sortField === 'v' ? (sortOrder === 1 ? 'up' : 'down') : ''}`}
                        onClick={() => customSort('v')}
                        style={{
                          cursor: 'pointer',
                          display: 'flex',
                          alignItems: 'center',
                          fontSize: '11px',
                          lineHeight: '11px',
                        }}
                      >
                        {i18n("TXN No")}
                        <CustomeSortIcon sortOrder={sortField === 'v' ? sortOrder : 0}
                          style={{ marginLeft: '4px', verticalAlign: 'middle' }} />
                      </div>
                    )} sortable filter filterPlaceholder=""
                    headerStyle={headerCellStyle}
                    showFilterMatchModes={false}
                    showClearButton={false}
                    showFilterOperator={false}
                    showAddButton={false}
                    filterMatchMode={FilterMatchMode.CONTAINS}
                    showApplyButton={false}
                    showFilterMenu={false}
                  />
                  <Column field="party"
                    header={() => (
                      <div
                        className={`custom-header-${sortField === 'party' ? (sortOrder === 1 ? 'up' : 'down') : ''}`}
                        onClick={() => customSort('party')}
                        style={{
                          cursor: 'pointer',
                          display: 'flex',
                          alignItems: 'center',
                          fontSize: '11px',
                          lineHeight: '11px',
                        }}
                      >
                        {i18n("Party")}
                        <CustomeSortIcon sortOrder={sortField === 'party' ? sortOrder : 0}
                          style={{ marginLeft: '4px', verticalAlign: 'middle' }} />
                      </div>
                    )} sortable filter filterPlaceholder=""
                    headerStyle={headerCellStyle}
                    showFilterMatchModes={false}
                    showClearButton={false}
                    showFilterOperator={false}
                    showAddButton={false}
                    filterMatchMode={FilterMatchMode.CONTAINS}
                    showApplyButton={false}
                    showFilterMenu={false}
                  />
                  <Column field="grand_total"
                    header={() => (
                      <div
                        className={`custom-header-${sortField === 'grand_total' ? (sortOrder === 1 ? 'up' : 'down') : ''}`}
                        onClick={() => customSort('grand_total')}
                        style={{
                          cursor: 'pointer',
                          display: 'flex',
                          alignItems: 'center',
                          fontSize: '11px',
                          lineHeight: '11px',
                        }}
                      >
                        {i18n("Grand Total")}
                        <CustomeSortIcon sortOrder={sortField === 'grand_total' ? sortOrder : 0}
                          style={{ marginLeft: '4px', verticalAlign: 'middle' }} />
                      </div>
                    )} sortable filter filterPlaceholder=""
                    headerStyle={headerCellStyle}
                    showFilterMatchModes={false}
                    showClearButton={false}
                    showFilterOperator={false}
                    showAddButton={false}
                    filterMatchMode={FilterMatchMode.CONTAINS}
                    showApplyButton={false}
                    showFilterMenu={false}
                    body={(rowData) => (
                      <span>
                        <GlobalCurrency price={rowData?.grand_total} />
                      </span>
                    )}
                  />

                  <Column field="paid_amount"
                    header={() => (
                      <div
                        className={`custom-header-${sortField === 'paid_amount' ? (sortOrder === 1 ? 'up' : 'down') : ''}`}
                        onClick={() => customSort('paid_amount')}
                        style={{
                          cursor: 'pointer',
                          display: 'flex',
                          alignItems: 'center',
                          fontSize: '11px',
                          lineHeight: '11px',
                        }}
                      >
                        {i18n("Paid Amount")}
                        <CustomeSortIcon sortOrder={sortField === 'paid_amount' ? sortOrder : 0}
                          style={{ marginLeft: '4px', verticalAlign: 'middle' }} />
                      </div>
                    )} sortable filter filterPlaceholder=""
                    headerStyle={headerCellStyle}
                    showFilterMatchModes={false}
                    showClearButton={false}
                    showFilterOperator={false}
                    showAddButton={false}
                    filterMatchMode={FilterMatchMode.CONTAINS}
                    showApplyButton={false}
                    showFilterMenu={false}
                    body={(rowData) => (
                      <span>
                        <GlobalCurrency price={rowData?.paid_amount} />
                      </span>
                    )}
                  />

                  <Column
                    field="status"
                    header={() => (
                      <div
                        className={`custom-header-${sortField === 'status' ? (sortOrder === 1 ? 'up' : 'down') : ''}`}
                        onClick={() => customSort('status')}
                        style={{
                          cursor: 'pointer',
                          display: 'flex',
                          alignItems: 'center',
                          fontSize: '11px',
                          lineHeight: '11px',
                        }}
                      >
                        {i18n("Status")}
                        <CustomeSortIcon sortOrder={sortField === 'status' ? sortOrder : 0}
                          style={{ marginLeft: '4px', verticalAlign: 'middle' }} />
                      </div>
                    )}
                    sortable
                    body={statusBodyTemplate}
                    filter
                    filterElement={statusFilterTemplate}
                    filterField={(rowData) => rowData.status ? rowData.status : "-"}
                    headerStyle={headerCellStyle}
                    showFilterMatchModes={false}
                    showClearButton={false}
                    showFilterOperator={false}
                    showAddButton={false}
                    filterMatchMode={FilterMatchMode.CONTAINS}
                    showApplyButton={false}
                    showFilterMenu={false}
                  />

                  <Column
                    field="created_at"
                    header={() => (
                      <div
                        className={`custom-header-${sortField === 'created_at' ? (sortOrder === 1 ? 'up' : 'down') : ''}`}
                        onClick={() => customSort('created_at')}
                        style={{
                          cursor: 'pointer',
                          display: 'flex',
                          alignItems: 'center',
                          fontSize: '11px',
                          lineHeight: '11px',
                        }}
                      >
                        {i18n("Txn Time")}
                        <CustomeSortIcon sortOrder={sortField === 'created_at' ? sortOrder : 0}
                          style={{ marginLeft: '4px', verticalAlign: 'middle' }} />
                      </div>
                    )}
                    sortable
                    body={dateBodyTemplate}
                    filter
                    filterElement={dateFilterTemplate}
                    filterPlaceholder=""
                    headerStyle={headerCellStyle}
                    showFilterMatchModes={false}
                    showClearButton={false}
                    showFilterOperator={false}
                    showAddButton={false}
                    filterMatchMode={FilterMatchMode.DATE_IS}
                    // showApplyButton={false}
                    showFilterMenu={false}
                    bodyStyle={{ width: '15%' }}
                  />
                  <Column field="sync_status"
                    header={() => (
                      <div
                        className={`custom-header-${sortField === 'sync_status' ? (sortOrder === 1 ? 'up' : 'down') : ''}`}
                        onClick={() => customSort('sync_status')}
                        style={{
                          cursor: 'pointer',
                          display: 'flex',
                          alignItems: 'center',
                          fontSize: '11px',
                          lineHeight: '11px',
                        }}

                      >
                        {i18n("Sync Status")}
                        <CustomeSortIcon sortOrder={sortField === 'sync_status' ? sortOrder : 0}
                          style={{ marginLeft: '4px', verticalAlign: 'middle' }} />
                      </div>
                    )}
                    sortable
                    body={(rowData) => {
                      const { sync_status, syncstamp } = rowData;

                      return (
                        <>
                          <SyncStatus status={sync_status} syncstamp={syncstamp} />
                        </>
                      );
                    }}
                    headerStyle={{ 
                      display: 'flex', 
                      justifyContent: 'center', 
                      alignItems: 'center', 
                      textAlign: 'center',
                      width: 'auto',
                    }}
                    bodyStyle={{ width: '9.7%' }}
                    showFilterMatchModes={false}
                    showClearButton={false}
                    showFilterOperator={false}
                    showAddButton={false}
                    filterMatchMode={FilterMatchMode.CONTAINS}
                    showApplyButton={false}
                    showFilterMenu={false}
                  />
                  { /*not removed for reference <Column header={i18n("Actions")} body={actionBodyTemplate}
                    headerStyle={headerCellStyle} /> */}
                </DataTable>
              </div>
            </Container>
            {show && (
              <DeleteModel
                deleteUserClick={onDelete}
                onClickDeleteModel={setShow}
                name={title}
              />
            )}
          </div>
        </Container>
      </Layout>
    </div>
  );
};

export default VoucherIndex;
