import React, { useEffect, useState } from "react";
import Layout from "../../../shared/components/Layout";
import { useNavigate, useParams } from "react-router";
import {
  UpdateSubscriptionData,
  selectSubscriptionDataById,
} from "../../store/SubscriptionSlice";
import { useDispatch, useSelector } from "react-redux";
import { Formik } from "formik";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Row,
  Stack,
} from "react-bootstrap";
import CustomerDetailsForm from "../../components/subscription_form/CustomerDetailsForm";
import ItemDetailsForm from "../../components/subscription_form/ItemDetailsForm";
import PaymentSectionForm from "../../components/subscription_form/PaymentSectionForm";
import { i18n } from "../../../shared/helpers/sharedMethods";
import * as Yup from "yup";
import ModelFooter from "../../../shared/components/modelFooter";
import {
  Editsubscription,
  changeAddonShow,
  initEditSubscriptionProduct,
  updateTotals,
} from "../../store/CreateSubscriptionSlice";
import { cloneDeep, filter } from "lodash";
import moment from "moment";
import Header from "../../../shared/components/Header";
import PackageDetailsForm from "../../components/subscription_form/PackageDetailsForm";
import { changeToggleShow } from "../../../shared/store/sharedSlice";
import {
  addNew,
  initEditSubscriptionItems,
  resetSubscriptionItem,
  selectAllSubscriptionPlanItems,
} from "../../store/subscriptionProductEntitySlice";
import { subscriptionProcessCartItem } from "../../../pos/helpers/cartHelper";
import {
  unDoCessSubscription,
  unDoDiscounts,
  unDoDiscountsSubscription,
} from "../../../voucher/helpers/VouchersHelper";
import { SubscriptionProcess } from "../../components/helpers/subscriptionHelpers";
import { planData } from "../../store/subscription_item_store/GetPackagesItemSlice";
import ShortcutKeys from "../../../shared/components/ShortcutKeys";

const EditSubscription = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  let isEdit = true;
  const editSubscription = useSelector((state) =>
    selectSubscriptionDataById(state, id)
  );
  const subscriptionPlanItems = useSelector(selectAllSubscriptionPlanItems);
  ;
  const { accessToken, isLoggedIn } = useSelector((state) => state?.auth);
  const terminalNumber = useSelector((state) => state?.auth?.terminalNumber);
  const status = useSelector((state) => state.createSubscription.status);
  const error = useSelector((state) => state.createSubscription.error);
  const { addonShow } = useSelector((state) => state.createSubscription);

  const selectedBusiness = useSelector(
    (state) => state?.auth?.selectedBusiness
  );
  const tenantId = selectedBusiness?.pivot?.tenant_id;
  const subscription = useSelector((state) => state.createSubscription);
  const savedEntity = useSelector(
    (state) => state.createSubscription.savedEntity
  );

  const [discountEnabled, setDiscountEnabled] = useState(
    editSubscription?.discount > 0 ? true : false
  );
  const [cessEnabled, setCessEnabled] = useState(
    editSubscription?.cess_total > 0 ? true : false
  );
  const [packageSelected, setPackageSelected] = useState(false);
  useEffect(() => {
    dispatch(
      planData({ token: accessToken.access_token, tenant_id: tenantId })
    );
  }, []);

  useEffect(() => {
    if (editSubscription) {
      if (editSubscription?.discount && editSubscription?.discount > 0) {
        setDiscountEnabled(true);
      }
      dispatch(initEditSubscriptionProduct(editSubscription));
      let iterateItems = [];
      const iterateVoucherItems = editSubscription?.subscription_items.forEach(
        (data) => {
          iterateItems.push(subscriptionProcessCartItem(data));
        }
      );
      ;
      dispatch(initEditSubscriptionItems(cloneDeep(iterateItems)));
    }
  }, [editSubscription]);
  const handleProductSelect = (product) => {
    console.log("Product selected:", product);
    setPackageSelected(!!product);
  };

  useEffect(() => {
    const subscriptionPlanItems = editSubscription?.subscription_items;
    ;
    if (!discountEnabled && subscriptionPlanItems?.length > 0) {
      dispatch(resetSubscriptionItem());
      Array(1)
        .fill(1)
        .map((el, i) => dispatch(addNew({ type: "plan", index: i + 1 })));
      const iteratedDiscountValue = unDoDiscountsSubscription({
        subscriptionPlanItems,
        discountEnabled,
      });
      ;
      dispatch(initEditSubscriptionItems(cloneDeep(iteratedDiscountValue)));
    }
  }, [discountEnabled]);


  useEffect(() => {
    const subscriptionPlanItems = editSubscription?.subscription_items;
    ;
    if (!cessEnabled && subscriptionPlanItems?.length > 0) {
      dispatch(resetSubscriptionItem());
      Array(1)
        .fill(1)
        .map((el, i) => dispatch(addNew({ type: "plan", index: i + 1 })));
      const iteratedDiscountValue = unDoCessSubscription({
        subscriptionPlanItems,
        cessEnabled,
      });
      ;
      dispatch(initEditSubscriptionItems(cloneDeep(iteratedDiscountValue)));
    }
  }, [cessEnabled]);

  useEffect(() => {
    const validItems =
      subscriptionPlanItems &&
      filter(subscriptionPlanItems, function (o) {
        return o.product_id;
      });

    dispatch(updateTotals(validItems));
  }, [subscriptionPlanItems]);

  useEffect(() => {
    if (status && savedEntity) {
      UpdateSubscriptionData(savedEntity);
      navigate("/subscription/membership");
    }
  }, [status, savedEntity]);

  const toggleCess = () => {
    setCessEnabled(!cessEnabled);
  };
  const toggleDiscount = () => {
    setDiscountEnabled(!discountEnabled);
  };

  const handleAddonShow = () => {
    dispatch(changeAddonShow(true));
  };

  const handleCancel = () => {
    dispatch(changeAddonShow(false));
    dispatch(changeToggleShow(false));
  };

  const handleBack = () => {
    dispatch(resetSubscriptionItem());
    navigate("/subscription/membership");
  };

  return (
    <Formik
      initialValues={subscription}
      enableReinitialize={true}
      validationSchema={Yup.object().shape({
        party_id: Yup.string().required(i18n("Please select party")),
        product_id: Yup.string().required(i18n("Please select product")),
        start_date: Yup.date().required(i18n("Start date is required")),
      })}
      onSubmit={async (values) => {
        try {
          let formData = cloneDeep(values);
          ;

          formData = SubscriptionProcess(values);
          ;

          dispatch(
            Editsubscription({
              data: formData,
              token: accessToken.access_token,
              id: id,
              tenant_id: tenantId,
              terminalNumber: terminalNumber,
            })
          );
          dispatch(changeToggleShow(false));
        } catch (error) {
          ;
          dispatch(changeToggleShow(true));
        }
      }}
    >
      {({
        values,
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue,
        isSubmitting,
        touched,
      }) => (
        <Layout>
          <Header
            ItemActions={
              <Stack
                gap={3}
                direction="horizontal"
                className="mt-2 mb-2 justify-content-end"
              >
                <ShortcutKeys />
                <Button className="" variant="primary" onClick={handleSubmit}>
                  {i18n("save")}
                </Button>
                <Button
                  className=""
                  variant="outline-secondary"
                  onClick={handleBack}
                >
                  {i18n("cancel")}
                </Button>
              </Stack>
            }
            currentTitle={i18n("Edit Membership")}
          />
          <Container fluid className="p-10 pt-0 px-1">
            <Card className="mx-4">
              <div
                className="border"
                style={{
                  background: "white",
                  height: "90vh",
                  overflow: "auto",
                  overflowX: "hidden",
                }}
              >
                <Row className=" py-4">
                  <Col className="px-10" lg={12}>
                    <CustomerDetailsForm
                      handleChange={handleChange}
                      values={values}
                      errors={errors}
                      setFieldValue={setFieldValue}
                      touched={touched}
                      isEdit={isEdit}
                    />
                  </Col>
                  <Col className="px-10">
                    <PackageDetailsForm
                      values={values}
                      onProductSelect={handleProductSelect}
                      errors={errors}
                      handleChange={handleChange}
                      isEdit={isEdit}
                    />
                  </Col>
                  <Col className="py-4 px-13" lg={12}>
                    <ItemDetailsForm
                      handleChange={handleChange}
                      values={values}
                      errors={errors}
                      setFieldValue={setFieldValue}
                      touched={touched}
                      handleAddonShow={handleAddonShow}
                      handleCancel={handleCancel}
                      discountEnabled={discountEnabled}
                      toggleDiscount={toggleDiscount}
                      cessEnabled={cessEnabled}
                      toggleCess={toggleCess}
                      isEdit={isEdit}
                      item={subscriptionPlanItems}
                    />
                  </Col>
                  <Col className="py-8 px-12" lg={12}>
                    <PaymentSectionForm
                      handleChange={handleChange}
                      values={values}
                      errors={errors}
                      setFieldValue={setFieldValue}
                      touched={touched}
                      isEdit={isEdit}
                    />
                  </Col>
                </Row>
              </div>
            </Card>
          </Container>
        </Layout>
      )}
    </Formik>
  );
};

export default EditSubscription;
