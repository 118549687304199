import {
  Modal,
  Button,
  Row,
  Col,
  Form,
  InputGroup,
  Container,
  Card,
  ProgressBar,
  Stack,
  Collapse,
  Alert,
  Spinner,
} from "react-bootstrap";
import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Layout from "../../shared/components/Layout";
import Papa from "papaparse";
import {
  importVoucher,
  updateVoucherImport,
  getAllVoucher,
  undoVoucherImportError,
  voucherImportError,
  handleVoucherOneByOne,
  resetSales,
} from "../store/voucherSlice";
import {
  fetchProducts,
  selectAllProducts,
} from "../../catalog/store/productsSlice";
import { fetchparty, selectAllparty } from "../../party/store/getPartySlice";
import { i18n } from "../../shared/helpers/sharedMethods";
import {
  taxCodeFinder,
  taxCodeFinderWithTaxRate,
} from "../../catalog/helper/catalogHelper";
import { ConfirmPopup } from "primereact/confirmpopup";
import { getUtcDate } from "../../shared/helpers/localizationHelper";
import LoadingSpinner from "../../shared/components/LoadingSpinner";
import Header from "../../shared/components/Header";
import ShortcutKeys from "../../shared/components/ShortcutKeys";
import { Navigate } from "react-router";
import { GiConsoleController } from "react-icons/gi";

const VoucherImport = (props) => {
  const { title, setShowModal, type, path, voucherType } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const products = useSelector(selectAllProducts);
  const partys = useSelector(selectAllparty);
  const { status } = useSelector((state) => state.voucher);

  const selectedBusiness = useSelector(
    (state) => state?.auth?.selectedBusiness
  );
  const papaparseOptions = {
    header: true,
    skipEmptyLines: true,
  };
  const bulkImport = useSelector((state) => state.voucher.bulkImport);
  const [selectedFileName, setSelectedFileName] = useState("");

  const [showPage, setShowPage] = useState(false);
  const [validCount, setValidCount] = useState(0);
  const [invalidCount, setInvalidCount] = useState(0);
  const [errors, setErrors] = useState([]);
  const [importing, setImporting] = useState(false);
  const [importSuccess, setImportSuccess] = useState(false);
  const [progress, setProgress] = useState(0);
  const [recordsProcessed, setRecordsProcessed] = useState(0);
  const [errorsVisible, setErrorsVisible] = useState(false);
  const [confirmVisible, setConfirmVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const fileInputRef = useRef(null);
  const buttonRef = useRef(null);
  const { accessToken } = useSelector((state) => state?.auth);
  const tenantId = selectedBusiness?.pivot?.tenant_id;
  const handleToggleErrors = () => {
    setErrorsVisible(!errorsVisible);
  };

  useEffect(() => {
    if (status) {
      dispatch(fetchparty());
      dispatch(fetchProducts());
    }
  }, [status]);
  const is_paid = bulkImport.is_paid == "true" ? true : false;

  const handleFileLoaded = (data, fileInfo) => {
    const iterateBulkImport = data.map((data) => ({
      voucher_date: data?.date,
      voucher_number: data["voucher number"],
      voucher_type: type,
      party_name: data["party name"],
      party_phone: data["party phone"],
      product_code: data["product code"],
      product_name: data["product name"],
      quantity: data["quantity"],
      item_price: data["item price"],
      paid_amount: data["paid amount"],
      tax_percentage: data["tax percentage"],
      discount_percentage: data["discount percentage"],
      is_paid: is_paid,
    }));

    dispatch(updateVoucherImport(iterateBulkImport));
    setSelectedFileName(fileInfo.name);
  };
  const handleDownloadSample = () => {
    const sampleColumns = [
      "date",
      "voucher number",
      "party name",
      "party phone",
      "product code",
      "product name",
      "quantity",
      "item price",
      "paid amount",
      "tax percentage",
      "paid",
      "discount percentage",
    ];

    const sampleData = [
      Object.fromEntries(sampleColumns.map((col) => [col, ""])),
    ];
    const csv = Papa.unparse(sampleData, {
      header: true,
    });
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.setAttribute("href", url);
    const timeStamp = Date.now();
    link.setAttribute("download", `sample_invoice_${timeStamp}.csv`);

    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  const handleValidation = () => {
    const newErrors = [];
    let localValidCount = 0;
    let localInvalidCount = 0;
  
    const numericRegex = /^\d+$/;
    const phoneRegex = /^\d{10}$/;
    const dateRegex = /^\d{4}-\d{2}-\d{2}$/;
    const idPresent = bulkImport && bulkImport.some((item) => item.id);
  
    const expectedColumns = [
      "voucher_date",
      "voucher_number",
      "voucher_type",
      "party_name",
      "party_phone",
      "product_code",
      "product_name",
      "quantity",
      "item_price",
      "paid_amount",
      "tax_percentage",
      "discount_percentage",
      "is_paid",
    ];
    
    const columnNamesMatch =
      bulkImport &&
      bulkImport.length > 0 &&
      expectedColumns.every((columnName) =>
        Object.keys(bulkImport[0]).includes(columnName)
      );
  
    if (idPresent || !columnNamesMatch) {
      alert(
        `Error: ${
          idPresent ? "ID should not be present" : "Column names do not match"
        } in the bulk data!`
      );
      return false;
    }

    const validItems = [];
  
    bulkImport &&
      bulkImport.length > 0 &&
      bulkImport.forEach((item, index) => {
        const lineNumber = index + 1;
        let itemIsValid = true;
  
        if (!numericRegex.test(item.voucher_number)) {
          newErrors.push({
            Line: lineNumber,
            Field: "voucher_number",
            Message: "Voucher Number should only contain numeric values!",
          });
          itemIsValid = false;
        }
  
        if (
          typeof item.party_name !== "string" ||
          item.party_name.trim() === ""
        ) {
          newErrors.push({
            Line: lineNumber,
            Field: "party_name",
            Message: "Party Name field should only contain letters!",
          });
          itemIsValid = false;
        }
  
        if (
          typeof item.product_name !== "string" ||
          item.product_name.trim() === ""
        ) {
          newErrors.push({
            Line: lineNumber,
            Field: "product_name",
            Message: "Product Name field should only contain letters!",
          });
          itemIsValid = false;
        }
  
        if (!phoneRegex.test(item.party_phone)) {
          newErrors.push({
            Line: lineNumber,
            Field: "party_phone",
            Message: "Party Phone Number should be a valid 10-digit number!",
          });
          itemIsValid = false;
        }
  
        if (!numericRegex.test(item.quantity)) {
          newErrors.push({
            Line: lineNumber,
            Field: "quantity",
            Message: "Quantity should only contain numeric values!",
          });
          itemIsValid = false;
        }
  
        if (!numericRegex.test(item.item_price)) {
          newErrors.push({
            Line: lineNumber,
            Field: "item_price",
            Message: "Item Price should only contain numeric values!",
          });
          itemIsValid = false;
        }
  
        if (!numericRegex.test(item.paid_amount)) {
          newErrors.push({
            Line: lineNumber,
            Field: "paid_amount",
            Message: "Paid amount should only contain numeric values!",
          });
          itemIsValid = false;
        }
  
        if (!numericRegex.test(item.tax_percentage)) {
          newErrors.push({
            Line: lineNumber,
            Field: "tax_percentage",
            Message: "Tax Percentage should only contain numeric values!",
          });
          itemIsValid = false;
        }
  
        if (!numericRegex.test(item.discount_percentage)) {
          newErrors.push({
            Line: lineNumber,
            Field: "discount_percentage",
            Message: "Discount Percentage should only contain numeric values!",
          });
          itemIsValid = false;
        }
  
        if (typeof item.is_paid !== "boolean") {
          newErrors.push({
            Line: lineNumber,
            Field: "is_paid",
            Message: "Paid must be a boolean value!",
          });
          itemIsValid = false;
        }
        
        if (!dateRegex.test(item.voucher_date)) {
          newErrors.push({
            Line: lineNumber,
            Field: "voucher_date",
            Message: "Date must be in the format YYYY-MM-DD",
          });
          itemIsValid = false;
        }
  
        if (itemIsValid) {
          validItems.push(item);
          localValidCount++;
        } else {
          localInvalidCount++;
        }
      });
  
    dispatch(voucherImportError(newErrors));
    setErrors(newErrors);
    setValidCount(localValidCount);
    setInvalidCount(localInvalidCount);
    
    return { isValid: localInvalidCount === 0, validItems };
  };

  const clickImport = () => {
    handleValidation();
    setLoading(true);
  
    setTimeout(() => {
      setLoading(false);
      setShowPage(true); 
    }, 2000);
  };
  const clearField = () => {
    navigate(`/${path}`);
    dispatch(undoVoucherImportError());
  };

  const handleImport = async () => {
    setImporting(true);
    setImportSuccess(false);
    setProgress(0);
    setRecordsProcessed(0);

    const totalRecords = bulkImport ? bulkImport.length : 0;
    const size = 10;
    const totalChunks = Math.ceil(totalRecords / size);

    let recordsProcessed = 0;

    const updateProgress = (chunk) => {
      const newProgress = ((chunk + 1) / totalChunks) * 100;
      const recordsInCurrentChunk = Math.min(
        size,
        totalRecords - recordsProcessed
      );
      setRecordsProcessed((prev) => prev + recordsInCurrentChunk);

      setProgress(newProgress);
    };

    for (let chunk = 0; chunk < totalChunks; chunk++) {
      await new Promise((resolve) => {
        setTimeout(() => {
          updateProgress(chunk);
          resolve();
        }, chunk * 1000);
      });

      if (chunk === totalChunks - 1) {
        setImporting(false);
        setImportSuccess(true);
        dispatch(updateVoucherImport([]));

        if (onSubmit) {
          onSubmit();
        }
      }
    }
  };

  const acceptImport = () => {
    handleImport();
    setConfirmVisible(false);
  };

  const rejectImport = () => {
    setConfirmVisible(false);
  };

  const onSubmit = async () => {
    const { validItems } = handleValidation();

    try {
      if (validItems) {
        for (let i = 0; i < validItems.length; i++) {
          const item = validItems[i];
        
          const taxValue = item?.tax_percentage
            ? taxCodeFinderWithTaxRate(item?.tax_percentage)
            : { tax_code: "GST_NONE", tax_rate: 0 };
          const voucher = {
            ...item,
            is_paid,
            tax_value: taxValue,
            created_by: selectedBusiness?.pivot?.user_id,
            updated_by: selectedBusiness?.pivot?.user_id,
            created_at: getUtcDate(),
            updated_at: getUtcDate(),
          };

          await dispatch(importVoucher({data:voucher,token: accessToken.access_token,
            tenant_id: tenantId}));
          await dispatch(handleVoucherOneByOne(voucher));
        }
        setTimeout(() => {
          dispatch(getAllVoucher());
          navigate(`/${path}`);
        }, 2000);
      }
    } catch (error) {
      console.error("error",error);
    }
  };

  return (
    <>
    <Layout>
    <Header
          type={type}
          currentTitle={voucherType}
          ItemActions={
            <Stack
              gap={3}
              direction="horizontal"
              className="my-2 justify-content-end"
            >
              <ShortcutKeys />
            </Stack>
          }
        />
      <Container
        className="px-5"
        style={{ width: "100%", border: "none" }}
        fluid
      >
         <Card style={{ height: "330px", position: "relative" }}>
          <Card.Header className="px-6 py-2">
            <h2 className="px-3 py-1">{title}</h2>
            <Button
              variant="outline-secondary"
              className="justify-content-end"
              onClick={clearField}
            >
              {i18n("Back")}
            </Button>
          </Card.Header>
          <Card.Body className="align-items-center justify-content-center pb-7">
            <Row className="nb-4">
              <Col lg={12}>
                <p style={{ color: "red" }}>
                  {i18n(
                    "The first line in downloaded csv file should remain as it is."
                  )}{" "}
                  {i18n("Please do not change the order of columns.")}
                </p>
              </Col>
            </Row>
            <Row>
              <Col lg={6}>
                {i18n("The Correct Column Order is,")}{" "}
                <p
                  style={{
                    color: "grey",
                    textAlign: "justify",
                    fontSize: "13px",
                  }}
                >
                  (Voucher Date, Invoice Number, PartyName, Paid Amount, Item,
                  TaxableValue, TaxRate, IGST, CGST, SGST, CESS )
                </p>
              </Col>
              <Col lg={6} className="">
                <Button
                  variant="outline-secondary"
                  style={{
                    width: "144px",
                    height: "50px",
                    backgroundColor: "#F5F6FC",
                    borderRadius: "5px",
                    display: "flex",
                    alignItems: "center",
                  }}
                  onClick={handleDownloadSample}
                >
                  <span>
                    <i
                      className="fas fa-download mx-2"
                      style={{ color: "grey" }}
                    ></i>
                    {i18n("Download Sample File")}
                  </span>
                </Button>
              </Col>
            </Row>
            <Row>
              <Col lg={12}>
                <Form.Label>
                  <b>
                    {i18n("Upload file")}
                    <span style={{ color: "red" }}>*</span>
                  </b>
                </Form.Label>
                <InputGroup style={{ width: "62%" }}>
                  <Form.Control type="text" value={selectedFileName} readOnly />
                  <label htmlFor="fileInput" className="btn btn-primary m-0">
                    {i18n("Browse")}
                  </label>
                  <Form.Control
                    type="file"
                    id="fileInput"
                    ref={fileInputRef}
                    onChange={(e) => {
                      const file = e.target.files[0];
                      if (file) {
                        Papa.parse(file, {
                          ...papaparseOptions,
                          complete: (results) =>
                            handleFileLoaded(results.data, file),
                        });
                      }
                    }}
                    style={{ display: "none" }}
                  />
                </InputGroup>
              </Col>
            </Row>
            <Row className="mt-6">
              <Col lg={12} className="d-flex justify-content-start p-3">
                {!loading && (
                  <Button variant="primary" onClick={clickImport}>
                    {i18n("Import")}
                  </Button>
                )}
                {loading && <LoadingSpinner />}
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Container>

      {showPage && (
        <Container
          style={{ backgroundColor: "#F6F8FA", height: "15px" }}
          fluid
        ></Container>
      )}
      {showPage && (
        <Container className="px-5" style={{ marginTop: "15px" }} fluid>
          <Card style={{ height: "330px" }}>
            <Card.Body>
              <Col className="mb-4">
                <Row lg={4}>
                  <Stack direction="horizontal" gap={10}>
                    <h4 className="pt-1">Total Items</h4>
                    <span className="mb-1">
                      {bulkImport ? bulkImport.length : 0}
                    </span>
                  </Stack>
                </Row>
                <Row lg={4}>
                  <Stack direction="horizontal" gap={10}>
                    <h4 className="pt-1">Valid Items</h4>
                    <span className="mb-1">{validCount ? validCount : 0}</span>
                  </Stack>
                </Row>
                <Row lg={4}>
                  <Stack direction="horizontal" gap={10}>
                    <h4 className="mb-1">Invalid Items</h4>
                    <Button
                      variant="link"
                      className="pt-1"
                      style={{ marginLeft: "-12%" }}
                      onClick={handleToggleErrors}
                    >
                      {invalidCount ? invalidCount : 0} Export Errors
                    </Button>
                  </Stack>
                </Row>
              </Col>

              {errorsVisible && (
                <Collapse
                  className="d-flex justify-content-start mb-2"
                  in={errorsVisible}
                >
                  <div>
                    {errors.length > 0 && (
                     <Alert className="p-2"
                     style={{
                      maxHeight: "50px",
                       overflow: "auto",
                       width:"40%",
                       backgroundColor:"#ffe2d2",
                       borderColor:"#FFCFB3"
                     }}
                      >
                        <ul
                          style={{
                            listStyleType: "disc",
                            paddingLeft: "15px",
                          }}
                        >
                          {errors.map((error, index) => (
                            <li key={index}>
                              Row {error.Line}: {error.Field} - {error.Message}
                            </li>
                          ))}
                        </ul>
                      </Alert>
                    )}
                  </div>
                </Collapse>
              )}
              <Row className="pb-4">
                <Col className="d-flex justify-content-start px-2">
                  <Button
                    variant="primary"
                    ref={buttonRef}
                    onClick={() => setConfirmVisible(true)}
                  >
                    {i18n("Proceed to Import")}
                  </Button>
                </Col>
              </Row>
              <Row>
                <Col className="d-flex justify-content-start px-2">
                  <p className="text-muted">
                    <b>Note:</b> Only Valid Items will be Imported.
                  </p>
                </Col>
              </Row>

              <ConfirmPopup
                target={buttonRef.current}
                visible={confirmVisible}
                onHide={() => setConfirmVisible(false)}
                message="Are you sure you want to proceed?"
                icon="pi pi-exclamation-triangle"
                accept={acceptImport}
                reject={rejectImport}
              />

              {importing && (
                <div className="mt-8">
                  <ProgressBar
                    style={{ width: "63%" }}
                    variant="success"
                    now={progress}
                    label={`${Math.round(progress)}%`}
                  />
                  {progress < 100 && (
                    <p>
                      Importing {recordsProcessed} / {bulkImport.length} records
                    </p>
                  )}
                </div>
              )}
              {importSuccess && progress === 100 && (
                <Alert variant="success" style={{ width: "63%" }}>
                  <i
                    className="bi bi-check-circle-fill"
                    style={{ marginRight: "8px" }}
                  ></i>
                  Import successfully completed!
                </Alert>
              )}
              {importSuccess && <p>You will be redirected in 2 seconds...</p>}
            </Card.Body>
          </Card>
        </Container>
      )}
      </Layout>
    </>
  );
};

export default VoucherImport;
