import React from "react";
import { FaSort, FaSortUp, FaSortDown } from 'react-icons/fa';

const CustomeSortIcon = ({ sortOrder }) => {
  if (sortOrder === 1) {
    return <FaSortUp />;
  }
  if (sortOrder === -1) {
    return <FaSortDown />;
  }
  return <FaSort />;
};

export default CustomeSortIcon;