import { Col, Form, Modal, Row, Button, Container, Card, Badge } from "react-bootstrap";
import TwoStageLayoutHeader from "../../shared/components/TwoStatgeLayoutHeader";
import { Stack } from "immutable";
import { i18n } from "../../shared/helpers/sharedMethods";
import { getLocalizedDate } from "../../shared/helpers/localizationHelper";

const EnquiryDeails = (props) => {
    const { show, setShow, details, header } = props
    const closeModel = () => {
        setShow(false);
    };
    const leadStatusLabel = {
        'new': 'New',
        'open': 'Open',
        'interested': 'Interested',
        'not_interested': 'Not Interested',
        'converted': 'Converted',
        'closed': 'Closed'
    }
    const fontStyle = { fontSize: '13px', fontWeight: 600, color: "#687E96" }
    const dataStyle = { fontSize: '13px', fontWeight: 400, color: "black" }
    console.log("details", details)
    return (
        <Modal className="modal-lg modal-dialog-centered"
            show={show}
            onHide={closeModel}
            keyboard={true}>
            
            <Modal.Body >
                <Row>
                    <Col lg={12}>{header}</Col>
                </Row>
                <Card className="border mt-3">
                    <Card.Body className="" >
                        <Row className="">
                            <Col lg={4} >
                                <Form.Label style={fontStyle}>{i18n("Product")}</Form.Label>
                            </Col>
                            <Col lg={4} >
                                <Form.Label style={fontStyle}>{i18n("Phone Number")}</Form.Label>
                            </Col>
                            <Col lg={4} >
                                <Form.Label style={fontStyle}> {i18n("Follow-up date")}</Form.Label>
                            </Col>
                        </Row>
                        <Row className="mt-1">
                            <Col lg={4} >
                                <Form.Label style={dataStyle}>{details?.product?.name}</Form.Label>
                            </Col>
                            <Col lg={4} >
                                <Form.Label style={dataStyle}>{details?.mobile}</Form.Label>
                            </Col>
                            <Col lg={4} >
                                <Form.Label style={dataStyle}>{getLocalizedDate(details?.followup_date, 'en-IN').split(',')[0]}</Form.Label>
                            </Col>
                        </Row>
                    </Card.Body>
                    {details.notes && details.notes.trim() !== "" && (
                        <Card.Footer className="py-3" style={{ borderTop: '1px solid lightgray' }}>
                            <Row className="">
                                <Col md={12}>
                                    <Form.Group
                                        className="p-2"
                                        style={{
                                            backgroundColor: "#f8f9fc",
                                            fontSize: "13px",
                                        }}
                                    >
                                        <Col className="p-2">
                                            <span>{i18n("Note")} : </span>
                                            {details.notes}
                                        </Col>
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Card.Footer>
                    )}
                </Card>
            </Modal.Body>
            {/* <Modal.Footer className="py-2" style={{ borderTop: '1px solid lightgray' }}>
                <div className="mx-5" >
                    <Button variant="outline-secondary" onClick={() => setShow(false)}>Close</Button>
                </div>

            </Modal.Footer> */}
        </Modal>
    )

}

export default EnquiryDeails;