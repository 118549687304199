import React, { useState } from "react";
import { Modal, Button, Container, Row, Col, Form } from "react-bootstrap";
import { useNavigate } from "react-router";
import ShortCutKeys from "../../settings/screens/ShortCutKeys";
import ViewLogs from "./ViewLogs";
import SyncQueue from "./SyncQueue";
import SyncLogsHeader from "./SyncLogsHeader";
import SyncLogsSidebar from "./SyncLogsSidebar";

const SyncLogsDetails = ({ children }) => {
  const [syncShow, setSyncShow] = useState(true);
  const [logShow, setLogShow] = useState(false);
  const navigate = useNavigate();

  const handleClose = () => {
    // setShow(false);
  };

  return (

    <div className="border  bg-white" style={{ height: "calc(100vh - 10px)" }}>
      <Row className=" ">
        <Col lg={12}>
          <SyncLogsHeader />
        </Col>
        <Row>
          <Col md={2}>
            <SyncLogsSidebar />
          </Col>
          <Col lg={10}>
            {children}
          </Col>
        </Row>
      </Row>
    </div>
  );
};

export default SyncLogsDetails;
