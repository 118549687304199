import React, { useEffect, useState } from "react";
import { Button, Card, Col, Container, Row, Spinner, Stack } from "react-bootstrap";
import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import {
  addNewProduct,
  planData,
  selectAllPlanData,
  selectPlanDataById,
} from "../../store/subscription_item_store/GetPackagesItemSlice";
import * as Yup from "yup";
import {
  AddItem,
  resetPackage,
} from "../../store/subscription_item_store/CreatePackageSlice";
import { i18n } from "../../../shared/helpers/sharedMethods";
import { fetchSetting } from "../../../settings/store/getSettingSlice";
import Layout from "../../../shared/components/Layout";
import PackageLayout from "../../components/PackageLayout/PackageLayout";
import PlanName from "../../components/packages_fields/plan_fields/PlanName";
import PackageHeader from "../../components/PackageHeader";
import Header from "../../../shared/components/Header";
import PlanCode from "../../components/packages_fields/plan_fields/PlanCode";
import PlanUnits from "../../components/packages_fields/plan_fields/PlanUnits";
import PlanPrice from "../../components/packages_fields/plan_fields/PlanPrice";
import BillEvery from "../../components/packages_fields/plan_fields/BillEvery";
import PlanIntervalType from "../../components/packages_fields/plan_fields/PlanIntervalType";
import PlanExpiresAfter from "../../components/packages_fields/plan_fields/PlanExpiresAfter";
import PlanTaxCode from "../../components/packages_fields/plan_fields/PlanTaxCode";
import TrialDays from "../../components/packages_fields/plan_fields/TrialDays";
import PlanType from "../../components/packages_fields/plan_fields/PlanType";
import { cloneDeep } from "lodash";
import { useLocation, useNavigate } from "react-router";
import { resetPlan } from "../../store/subscription_item_store/PlanEntitySlice";
import ShortcutKeys from "../../../shared/components/ShortcutKeys";
import { ISO_8601 } from "moment";
import { capitalizeMethod } from "../../../shared/helpers/captilize";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


const CreatePlan = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { id } = location?.state;
  const [loading, setLoading] = useState(false);
  const { accessToken, isLoggedIn } = useSelector((state) => state?.auth);
  const selectedBusiness = useSelector(
    (state) => state?.auth?.selectedBusiness
  );
  const tenantId = selectedBusiness?.pivot?.tenant_id;
  const status = useSelector((state) => state.createPackage.status);
  const savedEntity = useSelector((state) => state.createPackage.savedEntity);
  const plan = useSelector((state) => state.plan);
  const parentProduct = useSelector((state) =>
    selectPlanDataById(state, id)
  );

  useEffect(() => {
    dispatch(
      fetchSetting({
        accessToken: accessToken.access_token,
        tenant_id: selectedBusiness?.pivot?.tenant_id,
      })
    );
  }, []);

  useEffect(() => {
    if (status && savedEntity) {
      toast.success("Plan created successfully")
      dispatch(addNewProduct(savedEntity));
      dispatch(
        planData({ token: accessToken.access_token, tenant_id: tenantId })
      );

      dispatch(resetPlan());
      navigate("/subscription/packages/" + id, {
        state: { success: true },
      });
    }
  }, [status, savedEntity]);

  return (
    <>
      <ToastContainer />
      <Formik
        initialValues={plan}
        enableReinitialize={true}
        validationSchema={Yup.object().shape({
          name: Yup.string().required(i18n("Plan name is required")),
          code: Yup.string().required(i18n("Plan code is required")),
          sale_price: Yup.string().required(i18n("Plan Price is required")),
          interval: Yup.number().required(i18n("Bill Every is required")),
        })}
        onSubmit={async (values) => {
          try {
            setLoading(true);
            let formData = values;
            formData = { ...formData, parent_product: id };
            formData.plan = {
              interval: formData.interval,
              interval_unit: formData.interval_unit,
              expires_after: formData.expires_after,
              free_trial_days: formData.free_trial_days,
              setup_fee: formData.setup_fee,
            };
            delete formData.interval;
            delete formData.interval_unit;
            delete formData.expires_after;
            delete formData.free_trial_days;
            delete formData.setup_fee;
            delete formData.is_value;


            dispatch(
              AddItem({
                data: formData,
                token: accessToken.access_token,
                tenant_id: tenantId,
              })
            );
          } catch (error) {
            setLoading(false);
          }
        }}
      >
        {({
          values,
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue,
          isSubmitting,
          touched,
        }) => (
          <Layout currentTitle={i18n("Packages")}>
            <Header
              currentTitle={i18n("Manage Packages")}
              ItemActions={
                <Stack
                  gap={3}
                  direction="horizontal"
                  className="my-2 justify-content-end"
                >
                  <ShortcutKeys />
                </Stack>
              }
            />
            <PackageLayout currentItemId={id}>
              <PackageHeader
                currentTitle={parentProduct ? (
                  <nav style={{ '--bs-breadcrumb-divider': "'>'", fontWeight: 'bold', fontSize: "15px", height: "2vh" }} aria-label="breadcrumb">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item" style={{ color: '#4169E1', fontSize: "15px" }}>{capitalizeMethod(parentProduct?.name)}</li>
                      <li className="breadcrumb-item active" aria-current="page" style={{ color: '#36454F', fontSize: "15px", }}>{i18n("Create Plan")}</li>
                    </ol>
                  </nav>
                ) : i18n("Create Plan")}
                ItemActions={
                  <Stack
                    gap={3}
                    direction="horizontal"
                    className="mt-2 mb-2 float-end"
                  >
                    {!loading ? (
                      <Button
                        id="submitButton"
                        variant="primary"
                        onClick={handleSubmit}
                        disabled={loading}
                      >
                        {i18n("Save")}
                      </Button>
                    ) : (
                      <div className="d-flex align-items-center mx-8">
                        <Spinner animation="border" size="md" />
                      </div>
                    )}
                    <div>
                      <Button
                        className=""
                        variant="outline-secondary"
                        onClick={() => navigate("/subscription/packages/" + id, {
                          state: { success: true },
                        })}
                      >
                        {i18n("Cancel")}
                      </Button>
                    </div>
                  </Stack>
                }
              />
              <Container
                fluid
                className="px-5 "
                style={{
                  height: "calc(100vh - 150px",
                  overflow: "auto",
                  overflowX: "hidden",
                }}
              >
                <Row>
                  <Col lg={12}>
                    <PlanName
                      values={values}
                      handleChange={handleChange}
                      errors={errors}
                      touched={touched}
                    />
                  </Col>
                  <Col lg={12}>
                    <PlanCode
                      values={values}
                      handleChange={handleChange}
                      errors={errors}
                      touched={touched}
                    />
                  </Col>
                  <Col lg={12}>
                    <PlanUnits
                      values={values}
                      handleChange={handleChange}
                      errors={errors}
                      touched={touched}
                      setFieldValue={setFieldValue}
                    />
                  </Col>
                  <Col lg={12}>
                    <PlanPrice
                      values={values}
                      handleChange={handleChange}
                      errors={errors}
                      touched={touched}
                    />
                  </Col>
                  <Col lg={12}>
                    <BillEvery
                      values={values}
                      handleChange={handleChange}
                      errors={errors}
                      touched={touched}
                      setFieldValue={setFieldValue}
                    />
                  </Col>
                  <Col lg={12}>
                    <PlanIntervalType
                      values={values}
                      handleChange={handleChange}
                      errors={errors}
                      touched={touched}
                    />
                  </Col>
                  {values?.is_value == 1 && (
                    <Col lg={12}>
                      <PlanExpiresAfter
                        values={values}
                        handleChange={handleChange}
                        errors={errors}
                        touched={touched}
                      />
                    </Col>
                  )}
                  <Col lg={12}>
                    <PlanTaxCode
                      values={values}
                      handleChange={handleChange}
                      errors={errors}
                      touched={touched}
                    />
                  </Col>
                  <Col lg={12}>
                    <TrialDays
                      values={values}
                      handleChange={handleChange}
                      errors={errors}
                      touched={touched}
                    />
                  </Col>
                  <Col lg={12}>
                    <PlanType
                      values={values}
                      handleChange={handleChange}
                      errors={errors}
                      touched={touched}
                    />
                  </Col>
                </Row>
              </Container>
            </PackageLayout>
          </Layout>
        )}
      </Formik>
    </>
  );
};

export default CreatePlan;
