import React, { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import { placeholderText } from "../../../../shared/helpers/sharedMethods";

const Chart = (props) => {
  const { reportItems, reportPeriod} = props;
  ;
  const [dateRangeX, setDateRangeX] = useState(Array.from({ length: 24 }, (_, i) => (i + 1).toString().padStart(2, '0')));
  const [salesAmount, setSalesAmount]  = useState(Array(24).fill(0));
  const [salesCount, setSalesCount]  = useState(Array(24).fill(0));
  const [state, setState] = useState([]);
  const [option, setOption] = useState({});
  const textTitle = placeholderText("Hourly Sales")

  useEffect(() => {
    const dates = Array.from({ length: 24 }, (_, i) => (i + 1).toString().padStart(2, '0'));

    if (reportItems && reportItems.length > 0) {
        const graphAmountData = Array(dates.length).fill(0);
        const graphCountData = Array(dates.length).fill(0);

        reportItems.forEach((dataItem) => {
            const voucherHour = dataItem.group_hour;
            const subtotal = dataItem.total_value;
            const totalCount = dataItem.count;

            const dateIndex = dates.findIndex(date => date === voucherHour);

            if (dateIndex !== -1) {
                graphAmountData[dateIndex] += subtotal;
                graphCountData[dateIndex] += totalCount;
            }
        });

        setSalesAmount(graphAmountData);
        setSalesCount(graphCountData);
    } else {
        setSalesAmount(Array(dates.length).fill(0));
        setSalesCount(Array(dates.length).fill(0));
    }
}, [reportItems, reportPeriod]);

 
useEffect(() => {
  setState([
      {
          name: 'Hourly Sales Amount',
          data: salesAmount,
      },
  ]);
}, [salesAmount]);

useEffect(() => {
  setOption({
      chart: {
          type: 'area',
          stacked: false,
          height: 350,
          zoom: {
              type: 'x',
              enabled: true,
              autoScaleYaxis: true
          },
          toolbar: {
              show: false,
              autoSelected: 'zoom'
          }
      },
      colors: ["#D3BBDD"],
      dataLabels: {
          enabled: false
      },
      markers: {
          size: 0,
      },
      title: {
          text: textTitle,
          align: 'left'
      },

      fill: {
          type: 'gradient',
          gradient: {
              shadeIntensity: 1,
              inverseColors: false,
              opacityFrom: 1.5,
              opacityTo: 1,
              stops: [0, 90, 100]
          },
      },

      xaxis: {
          type: 'datetime',
          categories: dateRangeX,
          labels: {
              show: true,
              rotate: 0,
              trim: false,
              minHeight: undefined,
              maxHeight: 120,
              style: {
                  fontSize: "12px",
              },
          },
      },
      tooltip: {
        y: {
          formatter: function (val, { seriesIndex, dataPointIndex, w }) {
            const count = salesCount[dataPointIndex]; // Count from the salesCount array
            return "₹" + val + " (" + count + ")";
          },
        },
      },
  });
}, [dateRangeX,salesCount]);
  
  return (
    <>
      <ReactApexChart options={option} series={state} type="area" height={350}  />
    </>
  );
};
export default Chart;
