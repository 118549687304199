import { i18n } from "../../shared/helpers/sharedMethods"
import CreateParty from "../screens/CreateParty"
import EditParty from "../screens/EditParty"
import PartyDetail from "../screens/PartyDetails"
import PartyImport from "../screens/PartyImport"
import PartyIndex from "../screens/PartyIndex"

const rootPath = 'parties';
const featureName = "parties";

export const supplierRoutes =  [

    {
        path: rootPath + '/supplier',
        element: <PartyIndex title={i18n('Create Supplier')} type="supplier" path={rootPath+"/supplier" } featureName={featureName}/>,
        isProtected: true
    },
    
    {
        path: rootPath+ '/supplier/create',
        element: <CreateParty title={i18n('Create Party')} type="supplier" path={rootPath+"/supplier" }/>,
        isProtected: true
    },
    {
        path: rootPath+ '/supplier/edit/:partyId',
        element: <EditParty title={i18n('Edit Party')} type="supplier" path={rootPath+"/supplier" }/>,
        isProtected: true
    },
    {
        path: rootPath+ '/supplier/view/:partyId',
        element: <PartyDetail type="supplier" path={rootPath+"/supplier" }/>,
        isProtected: true
    },
    {
        path: rootPath+ '/supplier/import',
       element: <PartyImport type="supplier" title={"Import Parties"} path={rootPath+"/supplier"} partyType="Parties"/>,
       isProtected: true
   },

    

]