import React, { useRef } from "react";
import { Form, Button, Stack } from "react-bootstrap";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { UilPrint, UilImport, UilUser } from "@iconscout/react-unicons";
import QRCode from "react-qr-code";
import logo from "../../../../../assets/images/bill.png";
import { useDispatch, useSelector } from "react-redux";
import { selectSelectedImage } from "../../../../settings/store/settingSlice";
import { placeOfSupplyList } from "../../../../shared/config/placeOfSupply";

const AttendanceQrCode = ({ qrCodeItemData, memberId, memberName }) => {
  const componentRef = useRef();
  const fetchData = useSelector((state) => state.getSetting?.dataSetting);
  const color1 = useSelector((state) => state.setting?.billing_settings?.color1);
  const color2 = useSelector((state) => state.setting?.billing_settings?.color2);
  const textColor = useSelector((state) => state.setting?.billing_settings?.textColor);

  const state = placeOfSupplyList.find(
    (option) => option.value == fetchData?.state
  );
  const DownloadPdf = () => {
    if (!componentRef.current) {
      console.error("Component reference is not set.");
      return;
    }

    html2canvas(componentRef.current, {
      scale: 2,
      useCORS: true,
      allowTaint: true,
      logging: true,
    }).then((canvas) => {
      const pdf = new jsPDF("p", "mm", "a4");

      let xPos = 5;
      let yPos = 5;

      canvas.toDataURL("image/png", 1.0);

      pdf.addImage(canvas, "PNG", xPos, yPos, 40, 0, "", "FAST");

      pdf.save(`memberCard_${Date.now()}.pdf`);
    });
  };

  const handlePrint = () => {
    if (!componentRef.current) {
      console.error("Component reference is not set.");
      return;
    }

    html2canvas(componentRef.current, {
      scale: 2,
      useCORS: true,
      allowTaint: true,
      logging: true,
    }).then((canvas) => {
      const imageData = canvas.toDataURL("image/png");

      const doc = new jsPDF("p", "mm", "a4");

      let xPos = 5;
      let yPos = 5;

      doc.addImage(imageData, "PNG", xPos, yPos, 40, 0, "", "FAST");
      doc.autoPrint();
      window.open(doc.output("bloburl"), "_blank");
    });
  };

  const selectImage = useSelector(selectSelectedImage);
  const backgroundStyle = () => {
    if (color1 && color2) {
      return {
        backgroundImage: `linear-gradient(107.7deg, ${color1} 8.4%, ${color2} 90.3%)`,
      };
    } else if (color1) {
      return {
        backgroundColor: color1,
      };
    } else if (color2) {
      return {
        backgroundColor: color2,
      };
    }
    return {};
  };

  return (
    <>
      <div
        ref={componentRef}
        className="d-flex flex-column align-items-center"
        style={{
          border: "1px solid #ccc",
          padding: "15px",
          borderRadius: "8px",
          textAlign: "center",
          boxShadow: "0 4px 8px rgba(0,0,0,0.2)",
          ...backgroundStyle(), 
          color:textColor
        }}
      >
              <span style={{
          position: 'relative',
          top: '-8px',
          width: '55px', 
          height: '12px',
          borderRadius: '10px', 
          backgroundColor: '#fff',
          border:'2px inset darkgrey'
        }}></span>

        <div
          className="d-flex align-items-center"
          style={{
            padding: "0px 0px",
            width: "100%",
            borderRadius: "8px",
            minHeight:'30px',
          }}
        >
          <img
            src={selectImage ? selectImage : logo}
            alt="Logo"
            style={{
              objectFit: "contain",
              maxWidth: "80px",
              height: "auto",
              marginRight: "15px", 
            }}
          />

          <div className="text-start">
          <h4
            className="fw-bold mb-1"
            style={{ fontSize: fetchData?.business_name && fetchData.business_name.length <= 23? "18px" : "14px" }}
          >
            {fetchData?.business_name ? fetchData.business_name : ''}
          </h4>
            <p style={{ marginBottom: "2px", fontSize: "12px" }}>
            {fetchData?.business_address ? fetchData?.business_address + ',' : ''}&nbsp; {fetchData?.city ? fetchData?.city + ',' : ""}
            &nbsp; {state?.label ? state?.label + ' -' : ''}&nbsp;&nbsp;{" "}
            {fetchData?.pincode ? fetchData?.pincode : ''}
            </p>
        {fetchData?.business_phone ? (
            <p style={{ fontSize: "12px", marginBottom: "0px" }}>
              <span style={{ fontWeight: 600 }}>Contact :</span> {fetchData?.business_phone}
            </p>
          ) : (
            ""
          )}
          </div>
        </div>
        <div
          style={{
            width: "100%",
            borderTop: "1px solid #000",
            margin: "5px 0",
          }}
        ></div>
      <div style={{width: "100%"}}>
        <h4 className="fw-bold text-center mb-1 mt-2" style={{ fontSize: "18px" }}>
          Membership Card
        </h4>

        <div className="mt-3">
          <div
            style={{
              backgroundColor: "white",
              padding: "10px",
              display: "inline-block",
              borderRadius: "5px",
              boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
            }}
          >
            {/* QR Code */}
            <QRCode
              value={qrCodeItemData || ""}
              size={130}
              level={"H"}
              includeMargin={true}
            />
          </div>
          <Form.Label className="d-flex justify-content-center p-3">
            {/* Scan this QR to check-in / out */}
          </Form.Label>
        </div>

        <h4 style={{ fontSize: "20px", fontWeight: "bold" }}>
          {/* <UilUser className="mx-2 mb-1" size="18" /> */}
          {memberName || "John Doe Martin King"}
        </h4>
        <h4 style={{ fontSize: "18px", fontWeight: 500, }}>
          {memberId || "John Doe"}
        </h4>
        </div>
      </div>

      <Stack direction="vertical" gap={4} className="ms-auto p-2 d-flex justify-content-center mt-4">
        <Button variant="outline-primary" onClick={DownloadPdf}>
          Download Card
          <UilImport className="mx-2 mb-1" size="16" />
        </Button>

        <Button variant="outline-secondary" id="button-addon1" onClick={handlePrint}>
          Print Card
          <UilPrint className="mx-2 mb-1" size="16" />
        </Button>
      </Stack>
    </>
  );
};

export default AttendanceQrCode;
