import {
  startOfWeek,
  startOfMonth,
  startOfYear,
  subWeeks,
  subMonths,
  subYears,
  endOfWeek,
  endOfMonth,
  endOfYear,
} from "date-fns";
export const TodayDateRange = () => {
  const date = new Date();
  const start = new Date(date.getFullYear(), date.getMonth(), date.getDate());
  const end = new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    23,
    59,
    59,
    999
  );
  return { start, end };
};

export const YesterdayDateRange = () => {
  const date = new Date();
  date.setDate(date.getDate() - 1);
  const start = new Date(date.getFullYear(), date.getMonth(), date.getDate());
  const end = new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    23,
    59,
    59,
    999
  );
  return { start, end };
};

export const CurrentMonthDateRange = () => {
  const date = new Date();
  const currentYear = date.getFullYear();
  const currentMonth = date.getMonth();
  const start = new Date(currentYear, currentMonth, 1);
  const end = new Date(currentYear, currentMonth + 1, 0);
  return { start, end };
};

export const PreviousMonthDateRange = () => {
  const date = new Date();
  const currentYear = date.getFullYear();
  const currentMonth = date.getMonth();
  const firstDayOfPreviousMonth = new Date(currentYear, currentMonth - 1, 1);
  const lastDayOfPreviousMonth = new Date(currentYear, currentMonth, 0);
  return { start: firstDayOfPreviousMonth, end: lastDayOfPreviousMonth };
};

export const CurrentWeekDateRange = () => {
  const date = new Date();
  const currentYear = date.getFullYear();
  const currentMonth = date.getMonth();
  const firstDayOfWeek =
    date.getDate() - date.getDay() + (date.getDay() === 0 ? -6 : 1);
  const lastDayOfWeek = firstDayOfWeek + 6;
  const start = new Date(currentYear, currentMonth, firstDayOfWeek);
  const end = new Date(currentYear, currentMonth, lastDayOfWeek);
  return { start, end };
};

export const PreviousWeekDateRange = () => {
  const date = new Date();
  const currentYear = date.getFullYear();
  const currentMonth = date.getMonth();
  const firstDayOfPreviousWeek = date.getDate() - date.getDay() - 6;
  const lastDayOfPreviousWeek = firstDayOfPreviousWeek + 6;
  const start = new Date(currentYear, currentMonth, firstDayOfPreviousWeek);
  const end = new Date(currentYear, currentMonth, lastDayOfPreviousWeek);
  return { start, end };
};

export const getFormattedDate = (date) => {
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Zero padding for month
  return `${year}-${month}`;
};

export const LastThreeAndSixMonths = (months) => {
  const date = new Date();
  const lastMonths = [];
  for (let i = 0; i < months; i++) {
    date.setMonth(date.getMonth() - 1); // Move back one month at a time
    lastMonths.unshift(getFormattedDate(date)); // Add to the beginning of the array
  }
  return lastMonths;
};

export const CurrentAndLastYearMonths = (startYear, startMonth, months) => {
  const lastMonths = [];
  const currentDate = new Date(startYear, startMonth, 1); // Start from the specified start year and start month

  for (let i = 0; i < months; i++) {
    lastMonths.push(getFormattedDate(currentDate)); // Push formatted date to the array
    currentDate.setMonth(currentDate.getMonth() + 1); // Move to the next month
  }

  return lastMonths;
};

export const isLastWeek = (date) => {
  ;
  const lastWeekStart = startOfWeek(subWeeks(new Date(), 1));
  const lastWeekEnd = endOfWeek(subWeeks(new Date(), 1));
  return date >= lastWeekStart && date <= lastWeekEnd;
};

export const isLastMonth = (date) => {
  const lastMonthStart = startOfMonth(subMonths(new Date(), 1));
  const lastMonthEnd = endOfMonth(subMonths(new Date(), 1));
  return date >= lastMonthStart && date <= lastMonthEnd;
};

export const isLastYear = (date) => {
  const lastYearStart = startOfYear(subYears(new Date(), 1));
  const lastYearEnd = endOfYear(subYears(new Date(), 1));
  return date >= lastYearStart && date <= lastYearEnd;
};
