import { isEmpty } from "lodash";

export const getUtcDate = (inputDate) => {
    let now = inputDate ? new Date(inputDate) : new Date();
    let utcYear = now.getUTCFullYear();
    let utcMonth = String(now.getUTCMonth() + 1).padStart(2, '0');
    let utcDay = String(now.getUTCDate()).padStart(2, '0');
    let utcHours = String(now.getUTCHours()).padStart(2, '0');
    let utcMinutes = String(now.getUTCMinutes()).padStart(2, '0');
    let utcSeconds = String(now.getUTCSeconds()).padStart(2, '0');

    let utcDate = `${utcYear}-${utcMonth}-${utcDay} ${utcHours}:${utcMinutes}:${utcSeconds}`;
    ; // Output: "YYYY-MM-DD HH:MM:SS"
    return utcDate;
};

export const getLocalizedDate = (isoDateString, locale = 'en-IN') => {
    // Parse the ISO date string to a Date object
    try {
        if(isEmpty(isoDateString)){
            return '';
        }
        const date = new Date(isoDateString);
        return date.toLocaleString(locale, {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            hour12: true
        });
        
    } catch (error) {
        console.error("Invalid date string:", error);
        return null;
    }
};

export const getDate = (isoDateString, locale = 'en-IN') => {
    // Parse the ISO date string to a Date object
    try {
        if(isEmpty(isoDateString)){
            return '';
        }
        const date = new Date(isoDateString);
        return date.toLocaleString(locale, {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric'
            // hour: '2-digit',
            // minute: '2-digit',
            // second: '2-digit',
            // hour12: true
        });
        
    } catch (error) {
        console.error("Invalid date string:", error);
        return null;
    }
};
  