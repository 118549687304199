import React from "react";
import { useSelector } from "react-redux";
import { Col, Container, Row, Table, Form, Image } from "react-bootstrap";
import { selectSelectedImage } from "../../../settings/store/settingSlice";
import logo from "../../../../assets/images/bill.png";
import "../css/invoice.css";
import { i18n } from "../../helpers/sharedMethods";
import { QRCode } from "react-qr-code";
import { placeOfSupplyList } from "../../config/placeOfSupply";
const ThemeOne = ({ pdf }) => {
  const selectImage = useSelector(selectSelectedImage);
  const currencySymbol = useSelector(
    (state) => state.getSetting?.dataSetting.currency
  );
  ;
  const selectedBusiness = useSelector(
    (state) => state?.auth?.selectedBusiness
  );
  const termsAndCondition = useSelector(
    (state) => state.setting?.billing_settings?.terms_and_conditions
  );
  ;
  const BankDetails = useSelector((state) => state.setting?.billing_settings);
  const fetchData = useSelector((state) => state.getSetting?.dataSetting);
  ;
  const UPIPayment = useSelector(
    (state) => state.setting?.billing_settings?.upi_payment
  );

  const bankDetailsCheck =
    fetchData?.card_holder_name ||
    fetchData?.account_number ||
    fetchData?.ifsc_code || 
    fetchData?.bank || 
    fetchData?.branch_name;

  const generateQRCode = (UPIPayment) => {
    const upiLink = `upi://pay?pa=${UPIPayment}`;
    ;
    return (
      <QRCode
        value={upiLink}
        size={90}
        bgColor={"#ffffff"}
        fgColor={"#000000"}
        level={"L"}
      />
    );
  };
  

  const state = placeOfSupplyList.find(
    (option) => option.value == fetchData?.state
  );

  return (
    <>
      <Row className="p-4" ref={pdf} style={{ backgroundColor: "white" }}>
        <Row>
          <Col>
            <Row>
              <Col className="border-bottom p-2 m-0">
                <p style={{ fontWeight: "bolder" }}>
                  {i18n("TAX INVOICE")}
                  <span
                    className="border border-secondary px-2 mx-2"
                    style={{ fontWeight: 500 }}
                  >
                    {i18n("ORIGINAL FOR RECIPIENT")}
                  </span>
                </p>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col lg={6} className="border-left border-bottom p-2 m-0">
            <Row>
              <Col lg={4} className="d-flex align-items-center justify-content-center">
                <img
                  src={selectImage ? selectImage : logo}
                  width={100}
                  height={80}
                  alt="Logo"
                  style={{
                    objectFit: "contain",
                    maxWidth: "100%",
                    height: "auto",
                  }}
                />
              </Col>
              <Col lg={8}>
                <Row className="mt-3">
                  <Row>
                    <Col>
                      <Form.Label
                        className=""
                        style={{ fontSize: "22px", fontWeight: 600 }}
                      >
                        {fetchData?.business_name}
                      </Form.Label>
                      <Form.Group>
                        <div className="mb-0">
                          {fetchData?.business_address},&nbsp; {fetchData?.city}
                          ,&nbsp; {state?.label}&nbsp;-&nbsp;{" "}
                          {fetchData?.pincode}
                        </div>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    {fetchData?.business_phone && (
                      <Col lg={12}>
                        <Form.Label className="mb-0">
                          {i18n("Phone")} :{" "}
                        </Form.Label>{" "}
                        &nbsp;{" "}
                        <Form.Label className="mb-0">
                          {" "}
                          {fetchData?.business_phone
                            ? fetchData?.business_phone
                            : ""}
                        </Form.Label>
                      </Col>
                    )}
                  </Row>
                  <Row>
                  {fetchData?.gstin && (
                    <Col lg={12}>
                      <Form.Label className="mb-0">
                        {i18n("GSTIN")} :
                      </Form.Label>{" "}
                      <Form.Label className="mb-0">
                        {" "}
                        &nbsp; {fetchData?.gstin ? fetchData?.gstin : ""}
                      </Form.Label>
                    </Col>
                  )}
                  {fetchData?.pan && (
                    <Col lg={12}>
                      <Form.Label className="mb-0">
                        {i18n("PAN Number")} :{" "}
                      </Form.Label>{" "}
                      &nbsp; {fetchData?.pan ? fetchData?.pan : ""}
                    </Col>
                  )}
                  </Row>
                </Row>
              </Col>
            </Row>
          </Col>
          <Col
            lg={6}
            className="border-right border-bottom border-left p-2 m-0"
          >
            <Row className="mt-3" style={{ fontSize: "14px" }}>
              <Col lg={4} style={{ flex: 1 }}>
                <Form.Label
                  className="mb-0"
                  style={{ fontSize: "13px", fontWeight: 600 }}
                >
                  {i18n("Invoice No")}
                </Form.Label>
              </Col>
              <Col lg={4} style={{ flex: 1 }}>
                <Form.Label
                  className="mb-0"
                  style={{ fontSize: "13px", fontWeight: 600 }}
                >
                  {i18n("Invoice Date")}
                </Form.Label>
              </Col>

              <Col lg={4} style={{ flex: 1 }}>
                <Form.Label
                  className="mb-0"
                  style={{ fontSize: "13px", fontWeight: 600 }}
                >
                  {i18n("Due Date")}
                </Form.Label>
              </Col>
            </Row>
            <Row>
              <Col lg={4}>1234</Col>
              <Col lg={4}>09-02-2024</Col>
              <Col lg={4}>09-03-2024</Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col lg={6} className="border-left p-2 m-0">
            <Row>
              <Col
                style={{
                  fontSize: "13px",
                  fontWeight: "500",
                  marginBottom: "5px",
                }}
              >
                {i18n("BILL TO")}
              </Col>
            </Row>
            <Row>
              <Col style={{ fontWeight: "600", fontSize: "14px" }}>
              {i18n("Sample Party")}{" "}
              </Col>
            </Row>
            <Row>
              <Col>144,East street,</Col>
            </Row>
            <Row>
              <Col>{i18n("Phone")} : 9789524432</Col>
            </Row>
            <Row>
              <Col>{i18n("GSTIN")} : 29GGGGG1314R9Z6</Col>
            </Row>
            <Row>
              <Col>{i18n("PAN Number")} : ABCTY1234D</Col>
            </Row>
          </Col>
          <Col lg={6} className="border-right border-left p-2 m-0">
            <Row>
              <Col
                style={{
                  fontSize: "13px",
                  fontWeight: "500",
                  marginBottom: "5px",
                }}
              >
                {i18n("SHIP TO")}
              </Col>
            </Row>
            <Row>
              <Col style={{ fontWeight: "600", fontSize: "14px" }}>
              {i18n("Sample Party")}
              </Col>
            </Row>
            <Row>
              <Col>117,North Street</Col>
            </Row>
            <Row>
              <Col>{i18n("Phone")} : 9753424432</Col>
            </Row>  
          </Col>
        </Row>
        <Row>
          <Col className="border-col p-0 m-0">
            <Table className="table table-borderless">
              <thead>
                <tr
                  style={{
                    textAlign: "center",
                    borderTop: "1px solid black",
                    borderBottom: "1px solid black",
                  }}
                >
                  <th className="border-black">{i18n("S.No")}</th>
                  <th className="border-black">{i18n("ITEMS")}</th>
                  <th className="border-black">{i18n("HSN")}</th>
                  <th className="border-black">{i18n("QTY")}</th>
                  <th className="border-black">{i18n("RATE")}</th>
                  <th className="border-black">{i18n("DISC")}</th>
                  <th className="border-black">{i18n("TAX")}</th>
                  <th>{i18n("AMOUNT")}</th>
                </tr>
              </thead>
              <tbody>
                <tr style={{ textAlign: "center" }}>
                  <td className="border-black">1</td>
                  <td className="border-black">Monitor</td>
                  <td className="border-black">12345</td>
                  <td className="border-black">20 PCS</td>
                  <td className="border-black">{currencySymbol} 10.00</td>
                  <td className="border-black">{currencySymbol} 100.00</td>
                  <td className="border-black">{currencySymbol} 45.00 (18%)</td>
                  <td>{currencySymbol} 1,145.00</td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <td className="border-black">&nbsp;</td>
                  <td className="border-black"></td>
                  <td className="border-black"></td>
                  <td className="border-black"></td>
                  <td className="border-black"></td>
                  <td className="border-black"></td>
                  <td className="border-black"></td>
                  <td></td>
                </tr>
                <tr>
                  <td className="border-black">&nbsp;</td>
                  <td className="border-black"></td>
                  <td className="border-black"></td>
                  <td className="border-black"></td>
                  <td className="border-black"></td>
                  <td className="border-black"></td>
                  <td className="border-black"></td>
                  <td></td>
                </tr>
                <tr>
                  <td className="border-black">&nbsp; </td>
                  <td align="right" className="border-black"></td>
                  <td className="border-black"></td>
                  <td className="border-black"></td>
                  <td className="border-black"></td>
                  <td className="border-black"></td>
                  <td className="border-black"></td>
                  <td></td>
                </tr>
                <tr>
                  <td className="border-black">&nbsp;</td>
                  <td align="right" className="border-black "></td>
                  <td className="border-black"></td>
                  <td className="border-black"></td>
                  <td className="border-black"></td>
                  <td className="border-black"></td>
                  <td className="border-black"></td>
                  <td></td>
                </tr>
                <tr>
                  <td className="border-black"></td>
                  <td className="border-black"></td>
                  <td className="border-black"></td>
                  <td className="border-black"></td>
                  <td className="border-black"></td>
                  <td className="border-black"></td>
                  <td className="border-black"></td>
                  <td></td>
                </tr>
                <tr>
                  <td className="border-black"></td>
                  <td className="border-black"></td>
                  <td className="border-black"></td>
                  <td className="border-black"></td>
                  <td className="border-black"></td>
                  <td className="border-black"></td>
                  <td className="border-black"></td>
                  <td></td>
                </tr>
                <tr>
                <td className="border-black"></td>
                  <td
                    align="right"
                    className="border-black"
                  >
                    {i18n("Round Off")}</td>
                    <td className="border-black"></td>
                    <td className="border-black" align="center">-</td>
                    <td className="border-black" align="center">-</td>
                  <td className="border-black"></td>
                  <td className="border-black"></td>
                  <td align="center">{currencySymbol} 0</td>
                </tr>
                
                <tr
                  style={{
                    borderTop: "1px solid black",
                    borderBottom: "1px solid black",
                  }}
                >
                  <td
                    className="border-black"
                    style={{ background: "#F8F9FA" }}
                  ></td>
                  <td
                    align="right"
                    className="border-black bold"
                    style={{ background: "#F8F9FA" }}
                  >
                    {i18n("TOTAL")}
                  </td>
                  <td
                    className="border-black"
                    style={{ background: "#F8F9FA" }}
                  ></td>
                  <td
                    className="border-black"
                    style={{ background: "#F8F9FA" }}
                  ></td>
                  <td
                    className="border-black"
                    style={{ background: "#F8F9FA" }}
                  ></td>
                  <td
                    className="border-black bold"
                    style={{ background: "#F8F9FA", textAlign: "center" }}
                  >
                    {currencySymbol} 100.00
                  </td>
                  <td
                    className="border-black bold"
                    style={{ background: "#F8F9FA", textAlign: "center" }}
                  >
                    {currencySymbol} 45.00
                  </td>
                  <td
                    className="bold"
                    style={{ background: "#F8F9FA", textAlign: "center" }}
                  >
                    {currencySymbol} 1,145.00
                  </td>
                </tr>
                <tr
                  style={{
                    borderTop: "1px solid black",
                    borderBottom: "1px solid black",
                    fontWeight: 500,
                  }}
                >
                  <td className="border-black"></td>
                  <td align="right" className="border-black bold">
                    {i18n("RECEIVED AMOUNT")}{" "}
                  </td>
                  <td className="border-black bold"></td>
                  <td className="border-black"></td>
                  <td className="border-black"></td>
                  <td className="border-black"></td>
                  <th className="border-black"></th>
                  <td className="bold" style={{ textAlign: "center" }}>{currencySymbol} 100.00</td>
                </tr>

                <tr
                  style={{
                    borderTop: "1px solid black",
                    borderBottom: "1px solid black",
                  }}
                >
                  <td className="border-black"></td>
                  <td align="right" className="border-black bold">
                    {i18n("BALANCE AMOUNT")}
                  </td>
                  <td className="border-black"></td>
                  <td className="border-black"></td>
                  <td className="border-black"></td>
                  <td className="border-black"></td>
                  <th className="border-black"></th>
                  <td className="bold" style={{ textAlign: "center" }}>{currencySymbol} 1,045.00</td>
                </tr>
              </tfoot>
            </Table>
          </Col>
        </Row>
        <Row>
          <Col className="border-col p-0 m-0">
            <Table className="table table-borderless mt-2">
              <thead>
                <tr
                  align="center"
                  style={{
                    borderTop: "1px solid black",
                    borderBottom: "1px solid black",
                  }}
                >
                  <th className="border-black" rowSpan="2">
                    {i18n("HSN/SAC")}
                  </th>
                  <th className="border-black" rowSpan="2">
                    {i18n("TAXABLE VALUE")}
                  </th>
                  <th className="border-black" colSpan="2">
                    {i18n("CGST")}
                  </th>
                  <th className="border-black" colSpan="2">
                    {i18n("SGST")}
                  </th>
                  <th rowSpan="2">{i18n("TOTAL TAX AMOUNT")}</th>
                </tr>
                <tr
                  align="center"
                  style={{
                    borderTop: "1px solid black",
                    borderBottom: "1px solid black",
                  }}
                >
                  <th className="border-black">{i18n("RATE")}</th>
                  <th className="border-black">{i18n("AMOUNT")}</th>
                  <th className="border-black">{i18n("RATE")}</th>
                  <th className="border-black">{i18n("AMOUNT")}</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  align="center"
                  className="p-2"
                  style={{ borderBottom: "1px solid black" }}
                >
                  <td className="border-black">1234</td>
                  <td className="border-black">{currencySymbol} 1,000.00</td>
                  <td className="border-black">1.5%</td>
                  <td className="border-black">{currencySymbol} 22.5</td>
                  <td className="border-black">1.5%</td>
                  <td className="border-black">{currencySymbol} 22.5</td>
                  <td style={{ textAlign: "center" }}>
                    {currencySymbol} 45.00
                  </td>
                </tr>
              </tbody>
            </Table>
          </Col>
        </Row>
        <Row>
          <Col lg={12} className="border-col border-bottom p-2 m-0">
            <b>{i18n("TOTAL AMOUNT (IN WORDS)")}</b> <br />
            {i18n("ONE THOUSAND ONE HUNDRED AND FIVE RUPEES ONLY")}
          </Col>
        </Row>
        <Row>
          { bankDetailsCheck && (
          <Col lg={fetchData?.upi_payment ? 6 : 12} className="border-col border-bottom p-2 mb-0"
        >
            <Form.Group>
              <Form.Label style={{ fontSize: "13px", fontWeight: 600 }}>
                {i18n("BANK DETAILS")}
              </Form.Label>
              <br />
              {fetchData?.card_holder_name && (
                  <Form.Label className="mb-1">
                    {i18n("Name")}: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{fetchData?.card_holder_name}{" "}
                  </Form.Label>
                )}
                <br />
                {fetchData?.ifsc_code && (
                  <Form.Label className="mb-1">
                    {i18n("IFSC Code")}: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{fetchData?.ifsc_code}{" "}
                  </Form.Label>
                )}
                <br />
                {fetchData?.account_number && (
                  <Form.Label className="mb-1">
                    {i18n("Account No")}: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{fetchData?.account_number}{" "}
                  </Form.Label>
                )}
                  <br />
                {fetchData?.bank || fetchData?.branch_name ? (
                  <Form.Label className="mb-1">
                    {i18n("Bank")}: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{fetchData?.bank}, {fetchData?.branch_name}{" "}
                  </Form.Label>
                ): ''}
            </Form.Group>
          </Col>
          )}
             {fetchData?.upi_payment && (
          <Col lg={bankDetailsCheck ? 6 :12} className="border-bottom border-right p-2 mb-0"
          style = {{ borderLeft:bankDetailsCheck ? '' : '1px solid #000'}} >
            <Row>
              <Col lg={bankDetailsCheck ? 6 : 3} className="pr-lg-2">
                <Form.Group>
                  <Form.Label style={{ fontSize: "13px", fontWeight: 600 }}>
                    {i18n("PAYMENT QR CODE")}
                  </Form.Label>
                  <p>
                    {i18n("UPI ID")} : <br />
                    {UPIPayment}
                  </p>
                </Form.Group>
              </Col>
              <Col lg={6} className="pl-lg-2 px-16">
                {UPIPayment && generateQRCode(UPIPayment)}
              </Col>
            </Row>
          </Col>
             )}
        </Row>
        <Row>
          <Col
            lg={6}
            className="border-bottom border-right border-left p-2"
            style={{ fontSize: "12px" }}
          >
            <Form.Label
              className="mb-0"
              style={{ fontSize: "13px", fontWeight: 600 }}
            >
              {i18n("TERMS AND CONDITIONS")}{" "}
            </Form.Label>{" "}
            <br />
            <p>
              {termsAndCondition
                ? termsAndCondition
                : i18n(
                    "Goods once sold will not be taken back or exchanged"
                  )}{" "}
            </p>
          </Col>
          <Col style={{fontSize: "13px",
                fontWeight: 600,}}
            lg={6}
            className="border-bottom border-right py-2 d-flex flex-column align-items-start"
          >
            {i18n("Authorized Signatory For")} <br />{" "}
            <Form.Label className="mb-0" style={{ fontSize: "13px" }}>
                {selectedBusiness?.business_name}
              </Form.Label>
              {!fetchData?.signature && (
              <Col lg={11} className="border " style={{ height: "60px" }}></Col>
            )}
            <Col lg={11} className=" d-flex justify-content-start">
              {fetchData?.signature && (
                <Image
                  src={fetchData?.signature}
                  alt="signature"
                  style={{ width: "200px", height: "60px" }}
                />
              )}
            </Col>
          </Col>
        </Row>
      </Row>
    </>
  );
};

export default ThemeOne;
