import { salesRoutes } from "./routes/salesRoutes";
import { salesReturnRoutes } from "./routes/saleReturnRoutes";
import { creditNoteRoutes } from "./routes/creditNoteRoutes";
import { quotationRoutes } from "./routes/quotationRoutes";
import { purchaseRoutes } from "./routes/purchaseRoutes";
import { purchaseReturnRoutes } from "./routes/purchaseReturnRoutes";
import { purchaseOrderRoutes } from "./routes/purchaseOrderRoutes";
import { debitNoteRoutes } from "./routes/debitNoteRoute";
import { proformaInvoiceRoutes } from "./routes/proformaInvoiceRoutes";

export const voucherRoutes = [
    ...salesRoutes,
    ...salesReturnRoutes,
    ...creditNoteRoutes,
    ...quotationRoutes,
    ...proformaInvoiceRoutes,
    ...purchaseRoutes,
    ...purchaseReturnRoutes,
    ...purchaseOrderRoutes,
    ...debitNoteRoutes
];
