import { taxList } from "../../../shared/config/taxConfig";

export const preprocessorCategory = (category) => {
  const iterateCategory = category.map((data) => ({
    id: data.id,
    name: data.name,
    type: data.type,
  }));
  return iterateCategory;
}

export const filterCategory = (categorys, types) => {
  return categorys && categorys
    .filter(category => types.includes(category?.type))
    .map(category => ({
      id: category.id,
      name: category.name,
      type: category.type
    }));
}

export const filterTaxList = (taxList, taxCode) => {
  return taxList.filter((tax) => taxCode.includes(tax.code));
}

export const exportPreProcessor = (data) => {
  const categoryData = data.map((category) => ({
    id: category.id,
    name: category.name,
    type: category.type,

  }));
  return categoryData
}
export const exportLogs = (data) => {
  const logsData = data.map((logs) => ({
    timestamp: logs.timestamp,
    level: logs.level,
    message: logs.message,

  }));
  return logsData;
}

export const exportSync = (data) => {
  
  const syncData = data.map((sync) => ({
    id: sync.id,
    operation: sync.operation,
    resource_id: sync.resource_id,
    resource_type: sync.resource_type,

  }));
  return syncData;
}