import SubscriptionMember from "../screens/subscription_screen/SubscriptionMember.js"
import CreateMember from "../screens/subscription_screen/CreateMember.js"
import EditMember from "../screens/subscription_screen/EditMember.js";

const rootPath = 'subscription'
const featureName = 'subscription';


export const MemberRoutes = [
    {
        path: rootPath + '/members',
        element: <SubscriptionMember featureName={featureName}/>,
        isProtected: true
    },
    {
        path: rootPath + '/createMember',
        element: <CreateMember />,
        isProtected: true
    },
    {
        path: rootPath + '/editMember/:id',
        element: <EditMember />,
        isProtected: true
    },
]