export const taxList = [
    { 'code': 'GST_NONE', 'label': 'None', 'rate': 0, 'cess_rate': null },
    { 'code': 'GST_EXEMPT', 'label': 'Exempt', 'rate': 0, 'cess_rate': null },
    { 'code': 'GST_ZERO', 'label': 'GST @ 0%', 'rate': 0, 'cess_rate': null },
    { 'code': 'GST_0_1', 'label': 'GST @ 0.1%', 'rate': 0.1, 'cess_rate': null },
    { 'code': 'GST_0_25', 'label': 'GST @ 0.25%', 'rate': 0.25, 'cess_rate': null },
    { 'code': 'GST_1_5', 'label': 'GST @ 1.25%', 'rate': 1.25, 'cess_rate': null },
    { 'code': 'GST_3', 'label': 'GST @ 3%', 'rate': 3, 'cess_rate': null },
    { 'code': 'GST_5', 'label': 'GST @ 5%', 'rate': 5, 'cess_rate': null },
    { 'code': 'GST_6', 'label': 'GST @ 6%', 'rate': 6, 'cess_rate': null },
    { 'code': 'GST_12', 'label': 'GST @ 12%', 'rate': 12, 'cess_rate': null },
    { 'code': 'GST_13_8', 'label': 'GST @ 13.8%', 'rate': 13.8, 'cess_rate': null },
    { 'code': 'GST_18', 'label': 'GST @ 18%', 'rate': 18, 'cess_rate': null },
    { 'code': 'GST_28', 'label': 'GST @ 28%', 'rate': 28, 'cess_rate': null },
    // { 'code': 'GST_14_CESS_12', 'label': 'GST @ 14% + cess @ 12%', 'rate': 14, 'cess_rate': 12 },
    // { 'code': 'GST_28_CESS_12', 'label': 'GST @ 28% + cess @ 12%', 'rate': 28, 'cess_rate': 12 }
];

export const taxListShort = [
    { 'code': 'GST_NONE', 'label': 'None', 'rate': 0, 'cess_rate': null },
    { 'code': 'GST_EXEMPT', 'label': 'Exempt', 'rate': 0, 'cess_rate': null },
    { 'code': 'GST_ZERO', 'label': '0%', 'rate': 0, 'cess_rate': null },
    { 'code': 'GST_0_1', 'label': '0.1%', 'rate': 0.1, 'cess_rate': null },
    { 'code': 'GST_0_25', 'label': '0.25%', 'rate': 0.25, 'cess_rate': null },
    { 'code': 'GST_1_5', 'label': '1.25%', 'rate': 1.25, 'cess_rate': null },
    { 'code': 'GST_3', 'label': '3%', 'rate': 3, 'cess_rate': null },
    { 'code': 'GST_5', 'label': '5%', 'rate': 5, 'cess_rate': null },
    { 'code': 'GST_6', 'label': '6%', 'rate': 6, 'cess_rate': null },
    { 'code': 'GST_12', 'label': '12%', 'rate': 12, 'cess_rate': null },
    { 'code': 'GST_13_8', 'label': '13.8%', 'rate': 13.8, 'cess_rate': null },
    { 'code': 'GST_18', 'label': '18%', 'rate': 18, 'cess_rate': null },
    { 'code': 'GST_28', 'label': '28%', 'rate': 28, 'cess_rate': null },
    // { 'code': 'GST_14_CESS_12', 'label': 'GST @ 14% + cess @ 12%', 'rate': 14, 'cess_rate': 12 },
    // { 'code': 'GST_28_CESS_12', 'label': 'GST @ 28% + cess @ 12%', 'rate': 28, 'cess_rate': 12 }
];