export const handleExportClick = (exportData, setCsvData, downloadCsvFile, fileName) => {
  console.log(
    "handleExportClick",
    exportData,
    setCsvData,
    downloadCsvFile,
    fileName
  );
  
  try {
    ;
    const header = Object.keys(exportData[0]).join(',');
    const rows = exportData.map((sales) => Object.values(sales).join(','));
    const downloadData = `${header}\n${rows.join('\n')}`;
    setCsvData(downloadData);
    downloadCsvFile(downloadData, `${fileName}.csv`);
  } catch (error) {
    console.error('Error during export:', error);
  }
};

export const downloadCsvFile = (data, fileName) => {
  const csvBlob = new Blob([data], { type: 'text/csv;charset=utf-8' });
  const csvUrl = URL.createObjectURL(csvBlob);

  const a = document.createElement('a');
  a.href = csvUrl;
  a.download = fileName;

  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};
