import { ErrorMessage } from "formik";
import { Col, Form, FormControl, Row } from "react-bootstrap";
import { i18n } from "../../../shared/helpers/sharedMethods";
import { changePackageType } from "../../store/subscription_item_store/PackageEntitySlice";
import { useDispatch, useSelector } from "react-redux";

const PackageType = ({ values, handleChange, touched, errors }) => {
  const dispatch = useDispatch();
  const { type } = useSelector((state) => state.packageEntity);
  return (
    <Row className="mb-3">
      <Form.Group>
        <Form.Label>{i18n("Product Type")} </Form.Label>
        <span className="required" />
      </Form.Group>
      <Col lg={4}>
        <Form.Check
          type="radio"
          name="type"
          id="inlineRadio2"
          value="service"
          label={i18n("Service")}
          checked={type == "service"}
          onChange={(e) => {
            handleChange(e);
            dispatch(changePackageType(e.target.value));
          }}
        />
      </Col>
      <Col lg={4}>
        <Form.Check
          type="radio"
          name="type"
          id="inlineRadio1"
          value="goods"
          label={i18n("Goods")}
          checked={type == "goods"}
          onChange={(e) => {
            handleChange(e);
            dispatch(changePackageType(e.target.value));
          }}
        />
      </Col>
    </Row>
  );
};

export default PackageType;
