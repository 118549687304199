import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReactSearchAutocomplete } from 'react-search-autocomplete';
import { selectAllProducts } from '../../../../../catalog/store/productsSlice';
import { addNew } from '../../../../store/cartItemsSlice';
import { placeholderText } from '../../../../../shared/helpers/sharedMethods';
import { Col } from 'react-bootstrap';

const ProductSearch = () => {

  const allProducts = useSelector(selectAllProducts);
  
  const [searchString, setSearchString] = useState('');
  const dispatch = useDispatch();

  const removeSearchClass = () => {
    const html = document.getElementsByClassName(`search-bar`)[0].firstChild.firstChild.lastChild;
    html.style.display = 'none'
  }

  const handleOnSearch = (string, results) => {
    setSearchString(string)
    
  };

  const handleOnSelect = (item) => {
    // the item selected
    ;
    dispatch(addNew(item));
    setSearchString('');
  }

  const formatResult = (item) => {
    return <span style={{ display: 'block', textAlign: 'left' }}> {item.code} ({item.name})</span>
  };

  return (

    <Col className='position-relative search-bar col-xxl-8 col-lg-12 col-12'>
      <ReactSearchAutocomplete
        placeholder={placeholderText("Search by Item Name/Code or Scan Barcode [F1]")}
        inputSearchString={searchString}
        items={allProducts}
        onSearch={handleOnSearch}
        fuseOptions={{ keys: ['name', 'code'], inputDebounce: 10000 }}
        resultStringKeyName='code'
        onSelect={handleOnSelect}
        formatResult={formatResult}
        showIcon={false}
        showClear={false}
        autoFocus
      />
        <i className="bi bi-search fs-2 react-search-icon position-absolute top-0 bottom-0 d-flex align-items-center ms-2"/>
    </Col>



  )
}

export default ProductSearch;