import React, { useState } from 'react';
import { Container, Row, Stack, Button,Col } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from "react-router";
import Layout from '../../shared/components/Layout';
import { fetchparty } from '../store/getPartySlice';
import TabLayout from '../../shared/components/item-detail/TabLayout';
import PartyOverView from '../screens/PartyOverView';
import PartyIndex from '../screens/PartyIndex';
import { i18n } from '../../shared/helpers/sharedMethods';

const PartyHeader = () => {
    const navigate =useNavigate()
    const dispatch = useDispatch();
    
    React.useEffect(() => {
        ;
        dispatch(fetchparty());
      }, [])
     
      const partyTabs = [
  
        // {
        //   key: 'overView',
        //   title: (i18n('Overview')),
        //   component: <PartyOverView />
    
        // },
        // {
        //   key: 'Allparties',
        //   title: (i18n('All Parties')),
        //    component:  <PartyIndex  />
          
          
        // },
        {
          key: 'customer',
          title: (i18n('Customers')),
         component:  <PartyIndex  />
        },
        {
          key: 'supplier',
          title: (i18n('Suppliers')),
          component:  <PartyIndex  />
        },
    
       
      ]

    return (
   
       <TabLayout type="parties" tabs={partyTabs} route="parties" title="Parties" />
    
    )
}

export default PartyHeader;