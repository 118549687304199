import {
  createSlice,
  createAsyncThunk,
} from "@reduxjs/toolkit";
import { i18n } from "../../shared/helpers/sharedMethods";
import { axiosRequest } from "../../shared/helpers/axiosHelper";

const initialState = {
  status: false,
  reportItems: [],
  error: false,
  errorMessage: '',
  loading:false,
};

export const fetchItemSaleAndPurchaseReport = createAsyncThunk(
  "saleSummaryReport/fetch",
  async ({ token, tenant_id, startDate, endDate, categoryData }) => {
    try {
      const saleAndPurchaseSummaryResponse =
        await axiosRequest({
          method: "GET",
          url: `get-item-sales-purchase-summary-report`,
          params: { startDate, endDate, categoryData },
          headers: { Authorization: `Bearer ${token}`, "X-Tenant": tenant_id },
        });
      return saleAndPurchaseSummaryResponse.data;
    } catch (err) {
      await window.log.logToFile({ message: "Error while fetching sale summary report", level: "error" });
      throw new Error("Error while fetching Item sale and purchase summary")
    }
  }
);

const itemSaleAndPurchaseReportSlice = createSlice({
  name: "itemSaleAndPurchaseReport",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
    .addCase(fetchItemSaleAndPurchaseReport.pending, (state) => {
      state.loading = true;
    })
      .addCase(fetchItemSaleAndPurchaseReport.fulfilled, (state, action) => {
        state.status = true;
        state.loading = false;
        state.reportItems = action.payload;
      })
      .addCase(fetchItemSaleAndPurchaseReport.rejected, (state, action) => {
        state.error = true;
        state.loading = false;
        state.errorMessage = (i18n("Error while fetching Item sale and purchase summary. Please try again later."));
        state.status = false;
      })
  },
});

export default itemSaleAndPurchaseReportSlice.reducer;
