import VoucherIndex from "../screens/VoucherIndex";
import CreateVoucher from "../screens/CreateVoucher";
import EditVoucher from "../screens/EditVoucher";
import VoucherDetail from "../components/VoucherDetails";
import Overview from "../components/OverView";
import SaleModal from "../components/SaleModal";
import { i18n } from "../../shared/helpers/sharedMethods";
import VoucherInvoiceView from "../components/VoucherInvoiceView";
import VoucherImport from "../screens/VoucherImport";

const rootPath = "voucher";

export const salesRoutes = [
  {
    path: rootPath + "/sales-overview",
    element: <Overview type="Sales" />,
    isProtected: true,
  },
  {
    path: rootPath + "/all-sales",
    element: (
      <VoucherIndex
        title={i18n("All Sales")}
        type="all_sales"
        button={i18n("Create Sales")}
        voucherType="Sales"
        path={rootPath + "/all-sales"}
      />
    ),
    isProtected: true,
  },

  {
    path: rootPath + "/all-sales/edit/:id",
    element: (
      <EditVoucher 
      path={rootPath + "/all-sales"} 
      title={i18n("Edit Sales")} 
      type="sales_invoice"
      />
    ),
    isProtected: true,
  },
  {
    path: rootPath + "/sales-invoice",
    element: (
      <VoucherIndex
        title={i18n("Sales")}
        type="sales_invoice"
        button={i18n("Create Sales")}
        voucherType="Sales"
        path={rootPath + "/sales-invoice"}
      />
    ),
    isProtected: true,
  },
  {
    path: rootPath + "/sales-invoice/create",
    element: (
      <CreateVoucher
        title={i18n("Create Sales")}
        path={rootPath + "/sales-invoice"}
        type="sales_invoice"
        no="Sales Invoice No"
        date="Sales Invoice Date"
        voucherType="Sales"
      />
    ),
    isProtected: true,
  },
  {
    path: rootPath + "/sales-invoice/edit/:id",
    element: (
      <EditVoucher
        path={rootPath + "/sales-invoice"}
        title={i18n("Edit Sales")}
        voucherType="Sales"
        type="sales_invoice"
      />
    ),
    isProtected: true,
  },
  // {
  //   path: rootPath + "/sales-invoice/view/:id",
  //   element: (
  //     <VoucherDetail
  //       path={rootPath + "/sales-invoice"}
  //       button={i18n("Create Sales")}
  //       type="sales_invoice"
  //       title={i18n("Manage Sales")}
  //     />
  //   ),
  //   isProtected: true,
  // },
  {
    path: rootPath + "/sales-invoice/view/:id",
    element: (
      <VoucherInvoiceView
        path={rootPath + "/sales-invoice"}
        button={i18n("Create Sales")}
        type="sales_invoice"
        title={i18n("Manage Sales")}
      />
    ),
    isProtected: true,
  },
  {
    path: rootPath + "/all-sales/view/:id",
    element: (
      <VoucherDetail
        path={rootPath + "/all_sales"}
        title={i18n("Manage All Sales")}
        button={i18n("Create Sales")}
        type="all_sales"
      />
    ),
    isProtected: true,
  },
  {
    path: rootPath + "/all_sales/create",
    element: (
      <CreateVoucher
        title="Create Sales"
        path={rootPath + "/all_sales"}
        type="sales_invoice"
        no="Sales Invoice No"
        date="Sales Invoice Date"
        voucherType="Sales"
      />
    ),
    isProtected: true,
  },
  {
    path: rootPath + "/sales-invoice/import",
    element: (
      <VoucherImport
      path={rootPath + "/sales-invoice"}
      button={i18n("Create Sales")}
      type="sales_invoice"
      title={i18n("Import Sales")}
      voucherType="Sales"
      />
    ),
    isProtected: true,
  },
];
