import { Button, Row, Stack } from "react-bootstrap";
import { downloadCsvFile, handleExportClick } from "../../shared/export";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { UilImport } from "@iconscout/react-unicons";
import { i18n } from "../../shared/helpers/sharedMethods";
import { fetchparty, selectPartyByType } from "../../party/store/getPartySlice";
import { exportPreProcessor } from "../../party/helpers/partyHelper";

const PartyExport = (props) => {
  const { type } = props;
  const dispatch = useDispatch();
  const allPartys = useSelector((state) => selectPartyByType(state, type));
  const [parties, setParties] = useState(allPartys);
  const [exportData, setExportData] = useState([]);
  const accessToken = useSelector((state) => state?.auth?.accessToken?.access_token);
  const selectedBusiness = useSelector((state) => state?.auth?.selectedBusiness);
  const tenantId = selectedBusiness?.pivot?.tenant_id;
  const [csvData, setCsvData] = useState("");

  useEffect(() => {
    if (allPartys.length > 0) {
      setParties(allPartys);
    }
  }, [allPartys]);

  useEffect(() => {
    dispatch(fetchparty({ token: accessToken, tenant_id: tenantId }));
  }, [type]);

  const dateTimeString = new Date()
    .toLocaleString()
    .replace(/[-]/g, " ")
    .replace(/:/g, "-");
  const fileName = `party_${dateTimeString}`;

  const handleExportParty = () => {
    const exportData = exportPreProcessor(parties);
    setExportData(exportData);
    handleExportClick(
      exportData,
      (exportData) => {
        setCsvData(exportData);
      },
      downloadCsvFile,
      fileName
    );
  };
  return (
    <>
      <Stack
        gap={2}
        direction="horizontal"
        className="pt-3 px-3 mb-3 d-flex align-items-center justify-content-center"
      >
        <Button
          className="fw-bolder d-flex align-items-center rounded-0"
          onClick={handleExportParty}
        >
          <UilImport size="18" className="mx-2" style={{ cursor: "pointer" }} />
          {i18n("Export")}
        </Button>
      </Stack>
    </>
  );
};

export default PartyExport;
