import { ErrorMessage } from "formik";
import { Col, Form, FormControl, Row } from "react-bootstrap";
import { i18n } from "../../../shared/helpers/sharedMethods";
import { changePackageName } from "../../store/subscription_item_store/PackageEntitySlice";
import { useDispatch } from "react-redux";

const PackageName = ({ values, handleChange, touched, errors }) => {
    const dispatch = useDispatch();
  return (
    <Row className="mb-3">
      <Col>
        <Form.Group>
          <Form.Label>{i18n("Name")} </Form.Label>
          <span className="required" />
        </Form.Group>
        <FormControl
        autoFocus
          className={`form-control ${
            touched.name && errors.name ? "is-invalid" : ""
          }`}
          type="text"
          name="name"
          value={values.name}
          onChange={(e) => {
            handleChange(e);
            dispatch(changePackageName(e.target.value));
          }}
        />
        <ErrorMessage
          name="name"
          component="span"
          className="text-danger d-block fw-400 fs-small mt-2"
        />
      </Col>
    </Row>
  );
};

export default PackageName;
