import React from "react";
import { Button, Card, Col, Form, InputGroup, Row } from "react-bootstrap";
import { ErrorMessage } from "formik";
import {
  changeParty,
} from "../store/PartyEntitySlice";
import Select from "react-select";
import { useDispatch } from "react-redux";

const GeneralDetails = (props) => {
  const {
    handleChange,
    i18n,
    placeholderText,
    errors,
    values,
    numValidate,
    touched,
    handleBlur,
    handleCategory,
    filterData,
  } = props;
  const dispatch = useDispatch();

  const options = filterData.map((survey) => ({
    value: survey.id,
    label: survey.name,
  }));

  const handleSelectChange = (selectedOption) => {
    const event = {
      target: {
        name: 'category_id',
        value: selectedOption ? selectedOption.value : '',
      },
    };
    handleChange(event);
    dispatch(changeParty({ value: event.target.value, name: event.target.name }));
  };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      width: '280px'
    }),
  };

  return (
    <Card className="px-4">
      <Row className="py-2">
        <Col lg={6}>
          <Form.Group>
            <Form.Label>{i18n("Party Name")}</Form.Label>
            <span className="required" />
          </Form.Group>
          <Form.Control
            type="text"
            name="name"
            onBlur={handleBlur}
            placeholder={placeholderText("Enter the Name")}
            className="form-control"
            onChange={(e) => {
              handleChange(e);
              const{value,name} = e.target
              dispatch(changeParty({value,name}));
            }}
            isInvalid={touched.name && errors.name}
            value={values.name}
            autoFocus={true}
          />
          <Form.Text className="text-danger d-block fw-400 fs-small mt-2">
            {touched.name && errors.name}
          </Form.Text>
        </Col>
        <Col lg={6}>
          <Form.Group>
            <Form.Label>{i18n("Mobile Number")}</Form.Label>
            <span className="required" />
          </Form.Group>
          <Form.Control
            type="text"
            name="phone_number"
            placeholder={placeholderText("Enter the Phone Number")}
            className="form-control"
            pattern="[0-9]*"
            minLength={10}
            maxLength={10}
            onBlur={handleBlur}
            isInvalid={touched.phone_number && errors.phone_number}
            onKeyPress={(event) => numValidate(event)}
            onChange={(e) => {
              handleChange(e);
              const{value,name} = e.target
              dispatch(changeParty({value,name}));
            }}
            value={values.phone_number}
          />
          <ErrorMessage
            name="phone_number"
            component="span"
            className="text-danger d-block fw-400 fs-small mt-2"
          />
        </Col>
      </Row>

      <Row className="py-2">
        <Col lg={6}>
          <Form.Group>
            <Form.Label>{i18n("Email")}</Form.Label>
          </Form.Group>
          <Form.Control
            type="email"
            name="email"
            placeholder={placeholderText("Enter Email")}
            className={`${
              errors["email"] ? "error-highlight" : "form-control"
            }`}
            onChange={(e) => {
              handleChange(e);
              const{value,name} = e.target
              dispatch(changeParty({value,name}));
            }}
            value={values.email}
          />
          {errors.email && (
            <span className="" style={{ color: "#EE5B50" }}>
              {errors.email}
            </span>
          )}
        </Col>
        <Col lg={6}>
          <Form.Group>
            <Form.Label className="form-label-item mb-2">
              {i18n("Category")}
            </Form.Label>
          </Form.Group>
          <InputGroup>
          <Select
              aria-label="category"
              name="category_id"
              value={options.find(option => option.value === values.category_id)}
              onChange={handleSelectChange}
              options={options}
              placeholder={placeholderText("None")}
              isClearable
              styles={customStyles}
            />
    
            <Button
              variant="outline-primary"
              id="button-addon1"
              onClick={handleCategory}
            >
              {i18n("Add")}
            </Button>
          </InputGroup>
        </Col>
      </Row>
    </Card>
  );
};

export default GeneralDetails;
