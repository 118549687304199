import { Form } from "react-bootstrap";
import React from "react";
import { placeholderText } from "../../../shared/helpers/sharedMethods";

const SalesTypeFilter = (props) => {
   
    const {reportType,setReportType} = props;
    return (
        <Form.Select
            className="rounded-0"
            value={reportType}
            onChange={(e) => setReportType(e.target.value)}
            style={{ cursor: "pointer" }}
          >
           
            <option value="sales_invoice">{placeholderText("Sales Invoice")}</option>
            <option value="sales_return">{placeholderText("Sales Return")}</option>
            <option value="credit_note">{placeholderText("Credit Note")}</option>
            <option value="proforma_invoice">{placeholderText("Proforma Invoice")}</option>
            <option value="quote">{placeholderText("Quotation")}</option>

          </Form.Select>
    )

}

export default SalesTypeFilter;