import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { i18n, placeholderText } from "../../../shared/helpers/sharedMethods";
import "../../../../assets/css/custom.css";
import { Menu, MenuItem, Typeahead } from "react-bootstrap-typeahead";
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { useEffect } from "react";
import { cloneDeep, isEmpty } from 'lodash';
import { Button, Col, Form, InputGroup } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faXmark } from "@fortawesome/free-solid-svg-icons";
import { selectAllparty } from "../../../party/store/getPartySlice";
import { toggleEditPartyModal, updateParty } from "../../store/voucherEntitySlice";
import CreateParty from "../../../party/screens/CreateParty";
import EditParty from "../../../party/screens/EditParty";
import { useHotkeys } from "react-hotkeys-hook";


const PartyTypeAhead = ({ setIsCustomOption }) => {
  const currentParty = useSelector((state) => state.voucherEntity.party);
  const showEditPartyModal = useSelector((state) => state.voucherEntity.status.showEditPartyModal);
  const activeEditPartyTab = useSelector((state) => state.voucherEntity.status.activeEditPartyTab);
  const selectedBusiness = useSelector(
    (state) => state?.auth?.selectedBusiness
  );
  const allParties = useSelector(selectAllparty);
  const [selectedItem, setSelectedItem] = useState([]);
  const [partyChanged, setPartyChanged] = useState(false);
  const [showCreatePartyModal, setShowCreatePartyModal] = useState(false);
  const [autoFill, setAutoFill] = useState("");
  const [showPartyBlock, setShowPartyBlock] = useState(false);
  const dispatch = useDispatch();

  const handleClick = () => {
    setShowPartyBlock(true);
  };

  useEffect(() => {
    if (currentParty && !isEmpty(currentParty)) {
      setSelectedItem([currentParty]);
    }
  }, [currentParty])

  useEffect(() => {
    
    if (selectedItem && partyChanged) {
      if (selectedItem.length > 0) {
        let item = cloneDeep(selectedItem[0]); //getting the first one from array of selections, since selected will always be an array
        item.created_by = selectedBusiness?.pivot?.user_id;
        item.updated_by = selectedBusiness?.pivot?.user_id; 
        dispatch(updateParty(item));
        setPartyChanged(false);
      } else {
        
        dispatch(updateParty({}));
        setIsCustomOption(false);
        setPartyChanged(false);
      }
    }
  }, [selectedItem, partyChanged])

  // useEffect(() => {
  //   if(!showEditPartyModal && activeEditPartyTab != 'general'){
  //     resetEditPartyActiveTab();
  //   }
  // },[showEditPartyModal])

  const handlePartySelect = (selectedParty) => {
    setPartyChanged(true);
    setSelectedItem(selectedParty)
  }
  const typeaheadRef = useRef(null);
  
  useHotkeys('shift+y', (event) => {
    event.preventDefault();
    handleClick();
    typeaheadRef.current.focus();
  });

  const filterByFields = ['name', 'phone_number'];
  const ref = React.createRef();

  const setShowEditPartyModal = (status) => {
    dispatch(toggleEditPartyModal({
      toggle: status,
      activeEditPartyTab : 'general'
    }))
  }

  const resetEditPartyActiveTab = () => {
    dispatch(resetEditPartyActiveTab());
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      if (selectedItem && selectedItem.length > 0) {
        setShowPartyBlock(true);
      } else {
        setShowCreatePartyModal(true);
        setAutoFill(typeaheadRef.current.getInput().value);
      }
    }
  };
  


  return (
    <Form.Group as={Col} lg={12}>
      {currentParty && showEditPartyModal && (
        <EditParty
          show={showEditPartyModal}
          setShowEditParty={setShowEditPartyModal}
          partyId={currentParty?.id}
          isTriggeredFromVoucher={true}
          voucherQuickEdit={handlePartySelect}
          activeEditPartyTab={activeEditPartyTab}
          isEdit = {true}
        />
      )}
      {showCreatePartyModal && (
        <CreateParty
          show={showCreatePartyModal}
          setShowPartyCreate={setShowCreatePartyModal}
          isTriggeredFromVoucher={true}
          voucherQuickCreate={handlePartySelect}
          autoFill={autoFill}
        />
      )}
  
      <InputGroup>
        {allParties && <Typeahead
          ref={typeaheadRef}
          allowNew
          renderMenu={(results, menuProps) => (
            <Menu {...menuProps}>
              {results.map((result, position) => {
                if (result.customOption) {
                  setIsCustomOption(true);
                }

                return (
                  <MenuItem key={position} option={result} position={position}>
                    {result.customOption ? (<div onClick={(e) => {
                      setShowCreatePartyModal(true);
                      setAutoFill(result.name)
                    }}>
                      Add "{result.name}"
                    </div>) : (
                      <div>
                        {result.name}
                        <div>
                          {result.phone_number ? <small><b>Phone:</b> {result.phone_number}</small> : ''}
                        </div>
                      </div>
                    )
                    }

                  </MenuItem>
                );
              })}
            </Menu>
          )}
          id={"party-typeahead"}
          newSelectionPrefix="Add a new party : "
          options={allParties}
          placeholder={placeholderText("Search by Name / Phone number"
          )}
          onChange={handlePartySelect}
          filterBy={filterByFields}
          selected={selectedItem}
          labelKey='name'
          autoFocus
          onFocus={handleClick}
          onKeyDown={handleKeyDown}

        />
        }
        {selectedItem && selectedItem.length > 0 ?
          <>
            {/* <InputGroup.Text className="cursor-pointer fs-2" style={{ background: "none" }} onClick={() => {
          setSelectedItem([]);
          setPartyChanged(true);
        }}><FontAwesomeIcon icon={faEdit} title="Edit" className="text-default" /></InputGroup.Text> */}
            <InputGroup.Text className="cursor-pointer fs-2" style={{ background: "none" }} onClick={() => {
              setSelectedItem([]);
              setPartyChanged(true);
            }}><FontAwesomeIcon icon={faXmark} title="Clear" className="text-danger" /></InputGroup.Text>
          </>
          : ''}
      </InputGroup>
    </Form.Group>



  );
};

export default PartyTypeAhead;
