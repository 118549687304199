import { Card, Col, Form, Row } from "react-bootstrap";
import { placeOfSupplyList } from "../../shared/config/placeOfSupply";
import {
  i18n,
  numValidate,
  placeholderText,
} from "../../shared/helpers/sharedMethods";
import {
  changeAddress,
  changeCity,
  changePincode,
  changeState,
} from "../store/settingSlice";
import Select from "react-select";
import { useDispatch } from "react-redux";

const BusinessAddress = ({ handleChange, values,setFieldValue,errors }) => {
  const dispatch = useDispatch();

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      border: "1px solid #ccc",
      borderRadius: "4px",
      boxShadow: state.isFocused ? "0 0 7px rgb(77,144,254,0.5)" : null,
      height: "40px",
    }),
  };
  return (
    <Card>
      <Row className="mt-3">
        <Col lg={12}>
          <Form.Group className="">
            <Form.Label className="fs-2">
              <span
                className="pb-2"
                style={{
                  borderBottom: "3px solid #1a237e",
                  fontWeight: 600,
                  fontSize: "15px",
                }}
              >
                {i18n("Address Details")}
              </span>
            </Form.Label>
          </Form.Group>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col lg={10}>
          <label className="form-label-item mb-2">
            {i18n("Business Address")}
          </label>
          <Form.Control
            as="textarea"
            placeholder={placeholderText("Enter business address")}
            name="business_address"
            className="form-control"
            value={values.business_address|| ""}
            onChange={(e) => {
              handleChange(e);
              dispatch(changeAddress(e.target.value));
            }}
            rows={2}
          />
        </Col>
      </Row>
      <Row className="mt-5">
        <Col lg={10}>
          <Form.Group>
            <Form.Label>{i18n("City")}</Form.Label>
          </Form.Group>
          <Form.Control
            name="city"
            placeholder={placeholderText("Enter City")}
            value={values.city|| ""}
            onChange={(e) => {
              handleChange(e);
              dispatch(changeCity(e.target.value));
            }}
          />
        </Col>
      </Row>
      <Row className="mt-3">
        <Col lg={10}>
          <Form.Group>
            <Form.Label>{i18n("Pincode")}</Form.Label>
          </Form.Group>
          <Form.Control
            name="pincode"
            placeholder={placeholderText("Enter Pincode")}
            value={values.pincode|| ""}
            maxLength={6}
            onChange={(e) => {
              handleChange(e);
              dispatch(changePincode(e.target.value));
            }}
            onKeyPress={(event) => numValidate(event)}
          />
        </Col>
      </Row>
      <Row className="mt-3">
        <Col lg={10}>
          <Form.Label>{i18n("State")}</Form.Label>
          <Select
            name="state"
            options={placeOfSupplyList}
            styles={customStyles}
            value={placeOfSupplyList.find(
              (option) => option.value == values.state
            )}
            onChange={(selectedOption) => {
              setFieldValue("state", selectedOption);
              dispatch(changeState(selectedOption.value));
            }}
          />
        </Col>
      </Row>
    </Card>
  );
};

export default BusinessAddress;
