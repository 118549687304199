import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import DataGrid from "../../shared/components/DataGrid";
import Layout from "../../shared/components/Layout";
import {
  deleteUser,
  fetchInviteUser,
  resetStatusAndError,
} from "../store/inviteUserSlice";
import InviteUser from "../components/InviteUserModal";
import { Button, Container, Stack,Image } from "react-bootstrap";
import Header from "../../shared/components/Header";
import { i18n, placeholderText } from "../../shared/helpers/sharedMethods";
import { UilUserArrows } from "@iconscout/react-unicons";
import { find } from "lodash";
import { rolesList } from "../../shared/config/rolesConfig";
import { toast, ToastContainer } from "react-toastify";
import EditUserModal from "../components/EditUserModal";
import DeleteModel from "../../shared/components/DeleteModel";
import ShortcutKeys from "../../shared/components/ShortcutKeys";
import LoadingSpinner from '../../shared/components/LoadingSpinner';
import { fetchSetting } from "../../settings/store/getSettingSlice";

const InviteUserStack = ({ featureName }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showUser, setShowUser] = useState(false);
  const [showEditUser, setShowEditUser] = useState(false);
  const [selectedUser, setSelectedUser] = useState({});
  const [userData, setUserData] = useState("");
  const [user, setUser] = useState();
  const [showDelete, setShowDelete] = useState(false);
  const [deleteId, setDeleteId] = useState();
  const [deleteItemName, setDeleteItemName] = useState();
  const [refreshKey, setRefreshKey] = useState(0);
  const selectedBusiness = useSelector(
    (state) => state?.auth?.selectedBusiness
  );
  const tenant_id = selectedBusiness?.pivot?.tenant_id;
  const currentUserId = selectedBusiness?.pivot?.user_id;
  const { status, fetchStatus, usersList, error,deleteStatus } = useSelector((state) => state.inviteUser);
  const loading = useSelector((state) => state.inviteUser.loading);
  const { accessToken } = useSelector((state) => state?.auth);

  const subscriptionFeature = "subscription";

  const filterData =
    usersList &&
    usersList.length > 0 &&
    usersList.map((value) => {
      return value
    });

  const handleRefresh = () => {

    dispatch(fetchInviteUser({
      tenant_id: tenant_id,
      token: accessToken?.access_token,
      currentUserId: currentUserId,
    }));
    dispatch(resetStatusAndError());
    setRefreshKey((prevKey) => prevKey + 1);
  };

  const globalPlanFeatures = useSelector(
    (state) => state?.globalPlan?.features
  );
  useEffect(() => {
    dispatch(
      fetchSetting({
        accessToken: accessToken.access_token,
        tenant_id: selectedBusiness?.pivot?.tenant_id,
      })
    );
  }, [status]);

  useEffect(() => {
    if (!globalPlanFeatures.includes(featureName)) {
      navigate("/setting/not-in-plan");
    }
  }, [])

  useEffect(() => {
    dispatch(fetchInviteUser({
      tenant_id: tenant_id,
      token: accessToken?.access_token,
      currentUserId: currentUserId
    }));
  }, []);


  useEffect(() => {
    if (usersList) {
      setUser(filterData);
    }
  }, [usersList]);

  useEffect(() => {
    if (!fetchStatus && error) {
      toast.error(error);
      dispatch(resetStatusAndError());
    }
  }, [fetchStatus, error]);
  const handleClick = () => {
    setShowUser(!showUser);
  };
  const handleClose = () => {
    setShowUser(false);
  };

  const handleEdit = (user) => {
    setSelectedUser(user);
    setShowEditUser(true);
  };

  const handleEditClose = () => {
    setShowEditUser(false);
  };

  const handleDelete = (delRow) => {
    setShowDelete(!showDelete);
    setDeleteId(delRow.id);
    setDeleteItemName("user");
  };

  const onDelete = () => {
    dispatch(
      deleteUser({
        id: deleteId,
        token: accessToken.access_token,
        tenant_id: tenant_id,
      })
    );
    setShowDelete(false);
    dispatch(
      fetchInviteUser({
        tenant_id: tenant_id,
        token: accessToken?.access_token,
        currentUserId: currentUserId,
      })
    );
  };

  useEffect(() => {
    if (deleteStatus) {
        dispatch(fetchInviteUser({
            tenant_id: tenant_id,
            token: accessToken?.access_token,
            currentUserId: currentUserId
        }));
        toast.success("User deleted successfully..!")
        dispatch(resetStatusAndError());
    }
}, [deleteStatus]);



  const gridColumns = [
    {
      id: "id",
      name: "#",
      selector: (row) => row?.id,
      width: "150px",
    },
    {
      id: "name",
      name: i18n("User Name"),
      selector: (row) => row?.name,
    },
    {
      id: "phone",
      name: i18n("Phone Number"),
      selector: (row) => row?.phone,
    },
    {
      id: "role",
      name: i18n("Role"),
      selector: (row) => {
        let role = find(rolesList, { id: row?.role_id });
        return role ? role["label"] : "";
      },
    },
    {
      id: "actions",
      name: i18n("Actions"),
      cell: (row) => {
        return (
          <div className="d-flex justify-content-evenly align-items-center">
            <i
              className="fa fa-edit mx-1"
              aria-hidden="true"
              title={placeholderText("Edit")}
              style={{ cursor: "pointer" }}
              onClick={() => handleEdit(row)}
            ></i>
            &nbsp;
            <i
              className="fa fa-trash mx-2"
              aria-hidden="true"
              title={placeholderText("Delete")}
              style={{ cursor: "pointer" }}
              onClick={() => handleDelete(row)}
            ></i>
          </div>
        );
      },
    },
  ];

  return (
    <Layout>
      <ToastContainer />
      {loading && <LoadingSpinner />}
      <Header
        currentTitle={i18n("Manage Users")}
        ItemActions={
          <Stack
            gap={3}
            direction="horizontal"
            className="my-2 justify-content-end"
          >
            <ShortcutKeys />
          </Stack>
        }
      />
      <Container fluid className="px-5">
        <div className="border" style={{ background: "white" }}>
          <DataGrid
            key={refreshKey}
            data={user}
            allData={usersList}
            columns={gridColumns}
            addFilteredData={setUserData}
            isPaginated={true}
            handleCreate={handleClick}
            refreshHandler={handleRefresh}
            button="Invite User"
            title="Invite User"
            icons={<UilUserArrows size="50" color="gray" />}
            message={i18n("No users found. Please invite new users.")}
            ItemActions={
              <Stack
                gap={3}
                direction="horizontal"
                className=" justify-content-end"
              >
                <Button onClick={handleClick}>{i18n("Add User")}</Button>
              </Stack>
            }
          />
        </div>
        {showUser && (
          <InviteUser show={setShowUser} handleClose={handleClose} featureNames = {subscriptionFeature} />
        )}
        {showEditUser && (
          <EditUserModal
            show={setShowEditUser}
            handleClose={handleEditClose}
            selectedUser={selectedUser}
          />
        )}
        {showDelete && (
          <DeleteModel
            name={deleteItemName}
            deleteUserClick={onDelete}
            onClickDeleteModel={setShowDelete}
          />
        )}
      </Container>
    </Layout>
  );
};

export default InviteUserStack;
