import {
    createSlice,
    createAsyncThunk,
  } from "@reduxjs/toolkit";
import { axiosRequest } from "../../shared/helpers/axiosHelper";

  const initialState = {
    status:false,
    reportItems:[],
    error:false,
    errorMessage:'',
    loading:false,
  };

export const fetchReceivableAgeing = createAsyncThunk(
    "receivableAgeing/fetch",
    async ({ token, tenant_id }) => {
      try{
      const receivableAgeingResponse = await axiosRequest({
        method: "GET",
        url: `get-receivable-ageing-report`,
        headers: { Authorization: `Bearer ${token}`, "X-Tenant": tenant_id },
      });
      return receivableAgeingResponse.data;
    }catch(err){
      await window.log.logToFile({message: "Error while fetching receivable ageing", level: "error" });
      throw new Error("Error while fetching Receivable Ageing Report. Please try again later.")
    }
    }
  );

const receivableAgeingReportSlice = createSlice({
    name: "receivableAgeing",
    initialState,
    reducers: {},
    extraReducers(builder) {
      builder
      .addCase(fetchReceivableAgeing.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchReceivableAgeing.fulfilled, (state, action) => {
        state.status = true;
        state.loading = false;
        state.reportItems = action.payload;
      })
      .addCase(fetchReceivableAgeing.rejected, (state, action) => {
        state.error = true; 
        state.loading = false;
          state.errorMessage=action.error.message;
          state.status = false;
      })
    },
});

export default receivableAgeingReportSlice.reducer;
