import React, { useState, useEffect } from "react";
import { Button, Row, Col, Stack, Container ,Image} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Layout from "../../shared/components/Layout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faArrowUp,
  faChevronLeft,
  faDownload,
} from "@fortawesome/free-solid-svg-icons";
import { Form } from "react-bootstrap";
import { downloadCsvFile, handleExportClick } from "../../shared/export";
import DataGrid from "../../shared/components/DataGrid";
import { format } from "date-fns";
import ReportPeriod from "../../shared/components/ReportPeroid";
import { fetchPartyLedgerReport } from "../store/partyLedgerReportSlice";
import PartyFilter from "../components/Filters/PartyFilter";
import Header from "../../shared/components/Header";
import { i18n } from "../../shared/helpers/sharedMethods";
import PartyNavbar from "../components/PartyNavbar";
import ShortcutKeys from "../../shared/components/ShortcutKeys";
import ReportDateRange from "../../shared/components/date_range/ReportDateRange";
import DatePicker from "../../shared/components/date_range/DatePicker";
import { CurrentWeekDateRange } from "../../shared/components/DateRangeYAxis";
import ReportHeader from "../components/ReportHeader";
import { Column } from "primereact/column";
import CustomeSortIcon from "../../shared/components/CustomeSortIcon";
import { DataTable } from "primereact/datatable";
import { UilAngleLeft, UilImport } from "@iconscout/react-unicons";
import LoadingSpinner from '../../shared/components/LoadingSpinner';
import GlobalCurrency from "../../shared/components/GlobalCurrency";
import {   fetchparty,
  selectpartyById } from "../../party/store/getPartySlice";

const PartyLedgerReport = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const accessToken = useSelector((state) => state?.auth?.accessToken?.access_token);
  const selectedBusiness = useSelector((state) => state?.auth?.selectedBusiness);
  const tenantId = selectedBusiness?.pivot?.tenant_id;
  const [reportPeriod, setReportPeriod] = useState("current-week");
  const [partyData, setPartyData] = useState("");
  const [csvData, setCsvData] = useState("");
  const [startDate, setStartDate] = useState(null); // State for start date
  const [endDate, setEndDate] = useState(null);
  const [sortField, setSortField] = useState(null);
  const [sortOrder, setSortOrder] = useState(1);

 
  const handleReset = () => {
    const salesDateLimits = ReportDateRange({
      reportPeriod: reportPeriod,
    });
    setStartDate(salesDateLimits.startDate);
    setEndDate(salesDateLimits.endDate);
    setReportPeriod("current-week");
    setPartyData("");
  };
  useEffect(() => {
    dispatch(fetchPartyLedgerReport({ startDate:startDate, endDate:endDate, partyData:partyData, token:accessToken,tenant_id:tenantId }));
  }, [startDate, endDate, partyData]);
  useEffect(() => {
    dispatch(fetchparty({token: accessToken, tenant_id: selectedBusiness?.pivot?.tenant_id }));
  }, [accessToken, selectedBusiness])
    useEffect(() => {
      const dateLimits = ReportDateRange({ reportPeriod });
      setStartDate(dateLimits.startDate);
      setEndDate(dateLimits.endDate);
    }, [reportPeriod]);

  const reportItems = useSelector(
    (state) => state.partyLedgerReport.reportItems
  );
  const balance = useSelector(
    (state) => state.partyLedgerReport.openingBalance
  );
  console.log('balance',balance)
  const openingBalanceById = useSelector((state) => partyData ? selectpartyById(state, partyData) : null);
  const totalCredit = reportItems.reduce((acc, item) => acc + (item.credit || 0), 0);
  const totalDebit = reportItems.reduce((acc, item) => acc + (item.debit || 0), 0);
  const openingBalance = (openingBalanceById?.opening_balance > 0) ? openingBalanceById.opening_balance : balance;
  const closingBalance = openingBalance + totalDebit - totalCredit;
  const augmentedData = [
    { id: 'opening-balance', voucher_date: '-', voucher_id: '', voucherType: 'Opening Balance', credit: openingBalance, debit: '-' },
    ...reportItems,
    { id: 'closing-balance', voucher_date: '-', voucher_id: '', voucherType: 'Closing Balance', credit: closingBalance, debit: '-' },
  ];
  const status = useSelector((state) => state.partyLedgerReport.status);
  const loading = useSelector((state) => state.partyLedgerReport.loading);
  const errorMessage = useSelector(
    (state) => state.purchaseSummaryReport.errorMessage
  );
  const currencySymbol  = useSelector((state) => state.getSetting?.dataSetting.currency);
  ;

  const dateTimeString = new Date()
  .toLocaleString()
  .replace(/[-]/g, " ")
  .replace(/:/g, "-");
const fileName = `Party_Ledger_${dateTimeString}`;

  const handleExportButtonClick = () => {
    const exportData =
      reportItems &&
      Array.isArray(reportItems) &&
      reportItems.map((val) => ({
        "DATE": val.voucher_date
          ? format(new Date(val.voucher_date), "dd-MM-yyyy ")
          : "-",
        "Inv NO.": val.voucher_id ? val.voucher_id : "-",
        "TYPE": val.voucherType
          ? val.voucherType
              .replace(/_/g, " ")
              .replace(/\b\w/g, (match) => match.toUpperCase())
          : "-",
        "CREDIT": val.credit ? val.credit : "0",
        "DEBIT": val.debit ? val.debit : "0",
      }));
    handleExportClick(exportData, setCsvData, downloadCsvFile, fileName);
  };
  const customSort = (field) => {
    setSortField(field);
    setSortOrder(sortOrder === 1 ? -1 : 1);
  };

  const sortedData = [...augmentedData].sort((a, b) => {
    if (sortField) {
      const compareValueA = a[sortField];
      const compareValueB = b[sortField];
      if (compareValueA < compareValueB) {
        return sortOrder;
      }
      if (compareValueA > compareValueB) {
        return -sortOrder;
      }
    }
    return 0;
  });
  const headerStyle = {
    backgroundColor: "white",
  };
  const headerCellStyle = {
    fontWeight: "bold",
    fontSize: "11px",
  };

  return (
    <Layout>
        {loading && <LoadingSpinner />}
      <Header
        currentTitle={i18n("Party Ledger Report")}
        ItemActions={
          <Stack gap={3} direction="horizontal" className="mt-1 mb-1 float-end">
            <ShortcutKeys />
          </Stack>
        }
      />
      <Container fluid className="px-5">
        <div className="border" style={{ background: "white" }}>
          <PartyNavbar />
          <ReportHeader
            ItemActions={
              <Stack gap={2} direction="horizontal" className="pt-3 px-3 mb-3">
                <Stack direction="horizontal">
                  <DatePicker
                    startDate={startDate}
                    setStartDate={setStartDate}
                    endDate={endDate}
                    setEndDate={setEndDate}
                    />
                </Stack>
                <Stack>
                  <ReportPeriod
                    reportPeriod={reportPeriod}
                    setReportPeriod={setReportPeriod}
                  />
                </Stack>
                <Stack>
                  <PartyFilter partyData={partyData} setPartyData={setPartyData} />
                </Stack>
                <Stack
                  gap={1}
                  direction="horizontal"
                  style={{ marginLeft: "1px" }}
                >
                  <Button
                    className="rounded-0"
                    variant="outline-danger"
                    onClick={handleReset}
                  >
                      {i18n("Reset")}
                  </Button>
                </Stack>
                <Button
                  className="fw-bolder d-flex align-items-center rounded-0"
                  onClick={handleExportButtonClick}
                >
                  <UilImport
                    size="18"
                    className="mx-2"
                    style={{ cursor: "pointer" }}
                  />
                  {i18n("Export")}
                </Button>
                </Stack>
            }
          />
   <label className="d-flex flex-direction-column p-2 px-4  fs-3">
            {" "}
            {i18n("Opening Balance")} {" "}&nbsp;:&nbsp;
            <label
              className=" fw-bolder fs-2 px-2 "
            >
              {" "}
              <GlobalCurrency price={openingBalance} />
            </label>{" "}
          </label>
          <label className="d-flex flex-direction-column px-4 fs-3 mb-1 ">
            {" "}
            {i18n("Closing Balance")} {" "}&nbsp;&nbsp;&nbsp;:&nbsp;
            <label
              className=" fw-bolder fs-2 px-2 "
            >
              {" "}
              <GlobalCurrency price={closingBalance} />
            </label>{" "}
          </label>
  <Container fluid className="px-3 mb-4 mt-4">
      <div className="bordered-container">
      {partyData ? (

              status && reportItems ? (
                <DataTable
                  value={sortedData}
                  headerStyle={headerStyle}
                  paginator
                  rows={10}
                  dataKey="id"
                  emptyMessage={i18n("No Records Found.")}
                  className="p-datatable-gridlines full-width-table custom-datatable p-component"
                  scrollable
                  scrollHeight="400px"
                  sortMode="multiple"
                  removableSort
                >
        <Column
  field="voucher_date"
  header={() => (
    <div
      className={`custom-header-${sortField === "voucher_date" ? (sortOrder === 1 ? "up" : "down") : ""}`}
      onClick={() => customSort("voucher_date")}
      style={{
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        fontSize: "11px",
        lineHeight: "11px",
      }}
    >
      {i18n("DATE")}
      <CustomeSortIcon
        sortOrder={sortField === "voucher_date" ? sortOrder : 0}
        style={{ marginLeft: "4px", verticalAlign: "middle" }}
      />
    </div>
  )}
    body={(row) => {
      let formattedDate;
      try {
        const date = new Date(row.voucher_date);
        if (!isNaN(date)) {
          formattedDate = format(date, "dd/MM/yyyy ");
        } else {
          formattedDate = "-"; 
        }
      } catch (error) {
        console.error("Date formatting error:", error);
        formattedDate = "-";
      }
      return formattedDate;
    }}
    sortable
    headerStyle={headerCellStyle}
  />
<Column
  field="voucherType"
  header={() => (
    <div
      className={`custom-header-${sortField === "voucherType" ? (sortOrder === 1 ? "up" : "down") : ""}`}
      onClick={() => customSort("voucherType")}
      style={{
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        fontSize: "11px",
        lineHeight: "11px",
      }}
    >
      {i18n("Transaction Type")}
      <CustomeSortIcon
        sortOrder={sortField === "voucherType" ? sortOrder : 0}
        style={{ marginLeft: "4px", verticalAlign: "middle" }}
      />
    </div>
  )}
  body={(row) =>
    row.voucherType
      .replace(/_/g, " ")
      .replace(/\b\w/g, (match) => match.toUpperCase())
  }
  sortable
  headerStyle={headerCellStyle}
/>
<Column
  field="voucher_number"
  header={() => (
    <div
      className={`custom-header-${sortField === "voucher_number" ? (sortOrder === 1 ? "up" : "down") : ""}`}
      onClick={() => customSort("voucher_number")}
      style={{
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        fontSize: "11px",
        lineHeight: "11px",
      }}
    >
      {i18n("INVOICE NO")}
      <CustomeSortIcon
        sortOrder={sortField === "voucher_id" ? sortOrder : 0}
        style={{ marginLeft: "4px", verticalAlign: "middle" }}
      />
    </div>
  )}
  sortable
  headerStyle={headerCellStyle}
/>



<Column
  field="credit"
  header={() => (
    <div
      className={`custom-header-${sortField === "credit" ? (sortOrder === 1 ? "up" : "down") : ""}`}
      onClick={() => customSort("credit")}
      style={{
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        fontSize: "11px",
        lineHeight: "11px",
      }}
    >
      {i18n("CREDIT")}
      <CustomeSortIcon
        sortOrder={sortField === "credit" ? sortOrder : 0}
        style={{ marginLeft: "4px", verticalAlign: "middle" }}
      />
    </div>
  )}
  body={(row) =>
    <span>
      <GlobalCurrency price={row.credit} />
      </span>
    }
  sortable
  headerStyle={headerCellStyle}
/>

<Column
  field="debit"
  header={() => (
    <div
      className={`custom-header-${sortField === "debit" ? (sortOrder === 1 ? "up" : "down") : ""}`}
      onClick={() => customSort("debit")}
      style={{
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        fontSize: "11px",
        lineHeight: "11px",
      }}
    >
      {i18n("DEBIT")}
      <CustomeSortIcon
        sortOrder={sortField === "debit" ? sortOrder : 0}
        style={{ marginLeft: "4px", verticalAlign: "middle" }}
      />
    </div>
  )}
  body={(row) => {
    if (row.id === 'opening-balance' || row.id === 'closing-balance') {
      return <span>-</span>; 
    }
    return (
      <span>
        {row.debit < 0 ? "-" : ""}
        <GlobalCurrency price={Math.abs(row.debit || 0)} />
      </span>
    );
  }}
  sortable
  headerStyle={headerCellStyle}
/>

        {/* {status ? (
          <DataGrid
            allData={reportItems}
            data={reportItems}
            columns={gridColumns}
            isPaginated={true}
            title="Report"
          />
        ) : (
          <p className="d-flex justify-content-center p-2 fs-3 fw-bolder">
            {i18n("Error while fetching Party ledger report")}
          </p>
        )} */}
 </DataTable>
              ) : (
                <p>{i18n("Loading")}</p>
              ))
               : (
                <p className="text-center fs-4 mt-4" style={{color:"#495057"}}>{i18n("Please select a party to view the report.")}</p>
              )}
            </div>
            </Container>
            </div>
      </Container>
    </Layout>
  );
};

export default PartyLedgerReport;
