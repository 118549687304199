import React, { useState, useEffect } from "react";
import { Button, Row, Col, Stack, Container,Image } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchRateListReport } from "../store/rateListReportSlice";
import Layout from "../../shared/components/Layout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faChevronLeft,
  faDownload,
} from "@fortawesome/free-solid-svg-icons";
import { downloadCsvFile, handleExportClick } from "../../shared/export";
import DataGrid from "../../shared/components/DataGrid";
import Header from "../../shared/components/Header";
import { i18n } from "../../shared/helpers/sharedMethods";
import ItemNavbar from "../components/ItemNavbar";
import ShortcutKeys from "../../shared/components/ShortcutKeys";
import ReportHeader from "../components/ReportHeader";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import CustomeSortIcon from "../../shared/components/CustomeSortIcon";
import { UilImport } from "@iconscout/react-unicons";
import { formatAmount } from "../../pos/helpers/cartHelper";
import LoadingSpinner from '../../shared/components/LoadingSpinner';
import GlobalCurrency from "../../shared/components/GlobalCurrency";
const RateListReport = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const accessToken = useSelector((state) => state?.auth?.accessToken?.access_token);
  const selectedBusiness = useSelector((state) => state?.auth?.selectedBusiness);
  const tenantId = selectedBusiness?.pivot?.tenant_id;
  const [csvData, setCsvData] = useState("");
  const [sortField, setSortField] = useState(null);
  const [sortOrder, setSortOrder] = useState(1);

  useEffect(() => {
    dispatch(fetchRateListReport({token:accessToken,tenant_id:tenantId}));
  }, []);
  const reportItems = useSelector((state) => state.rateListReport.reportItems);
  const status = useSelector((state) => state.rateListReport.status);
  const loading = useSelector((state) => state.rateListReport.loading);
  const errorMessage = useSelector(
    (state) => state.rateListReport.errorMessage
  );
  const currencySymbol  = useSelector((state) => state.getSetting?.dataSetting.currency);
  ;

  const dateTimeString = new Date()
  .toLocaleString()
  .replace(/[-]/g, " ")
  .replace(/:/g, "-");
const fileName = `Rate_list_report_${dateTimeString}`;

  const handleExportButtonClick = () => {
    const exportData =
      reportItems &&
      Array.isArray(reportItems) &&
      reportItems.map((val) => ({
        "Name": val.name ? val.name : "",
        "Item Code": val.code ? val.code : "",
        "MRP": val.sale_price ? val.sale_price : "",
        "Selling Price": val.sale_price ? val.sale_price : "",
      }));
    handleExportClick(
      exportData,
      setCsvData,
      downloadCsvFile,
      fileName
    );
  };
 const customSort = (field) => {
   setSortField(field);
   setSortOrder(sortOrder === 1 ? -1 : 1);
 };
 const sortedData = [...reportItems].sort((a, b) => {
   if (sortField) {
     const compareValueA = a[sortField];
     const compareValueB = b[sortField];
     if (compareValueA < compareValueB) {
       return sortOrder;
     }
     if (compareValueA > compareValueB) {
       return -sortOrder;
     }
   }
   return 0;
 });
 const headerStyle = {
   backgroundColor: "white",
 };
 const headerCellStyle = {
   fontWeight: "bold",
   fontSize: "11px",
 };
  return (
    <Layout>
        {loading && <LoadingSpinner />}
      <Header
        currentTitle={i18n("Rate List Report")}
        ItemActions={
          <Stack
            gap={3}
            direction="horizontal"
            className="mt-2 mb-2 justify-content-end"
          >
            <ShortcutKeys />
          </Stack>
        }
      />
      <Container fluid className="px-5">
        <div className="border " style={{ background: "white" }}>
          <ItemNavbar />
          <ReportHeader
            ItemActions={
              <Stack gap={2} direction="horizontal" className="pt-3 px-3 mb-3">
                <Button
                  className="fw-bolder d-flex align-items-center rounded-0"
                  onClick={handleExportButtonClick}
                >
                  <UilImport
                    size="18"
                    className="mx-2"
                    style={{ cursor: "pointer" }}
                  />
                  {i18n("Export")}
                </Button>
              </Stack>
            }
          />
          <Container fluid className="px-3 mb-4">
            <div className="bordered-container">
              {status && reportItems ? (
                <DataTable
                  value={sortedData}
                  headerStyle={headerStyle}
                  paginator
                  rows={10}
                  dataKey="id"
                  emptyMessage="No Rate List Report found."
                  className="p-datatable-gridlines full-width-table custom-datatable p-component"
                  scrollable
                  scrollHeight="400px"
                  sortMode="multiple"
                  removableSort
                >
                  <Column
                    field="name"
                    header={() => (
                      <div
                        className={`custom-header-${
                          sortField === "name"
                            ? sortOrder === 1
                              ? "up"
                              : "down"
                            : ""
                        }`}
                        onClick={() => customSort("name")}
                        style={{
                          cursor: "pointer",
                          display: "flex",
                          alignItems: "center",
                          fontSize: "11px",
                          lineHeight: "11px",
                        }}
                      >
                        {i18n("ITEM NAME")}
                        <CustomeSortIcon
                          sortOrder={sortField === "name" ? sortOrder : 0}
                          style={{ marginLeft: "4px", verticalAlign: "middle" }}
                        />
                      </div>
                    )}
                    sortable
                    headerStyle={headerCellStyle}
                  />
                  <Column
                    field="code"
                    header={() => (
                      <div
                        className={`custom-header-${
                          sortField === "code"
                            ? sortOrder === 1
                              ? "up"
                              : "down"
                            : ""
                        }`}
                        onClick={() => customSort("code")}
                        style={{
                          cursor: "pointer",
                          display: "flex",
                          alignItems: "center",
                          fontSize: "11px",
                          lineHeight: "11px",
                        }}
                      >
                        {i18n("ITEM CODE")}
                        <CustomeSortIcon
                          sortOrder={sortField === "code" ? sortOrder : 0}
                          style={{ marginLeft: "4px", verticalAlign: "middle" }}
                        />
                      </div>
                    )}
                    sortable
                    headerStyle={headerCellStyle}
                  />

                  <Column
                    field="sale_price"
                    header={() => (
                      <div
                        className={`custom-header-${
                          sortField === "sale_price"
                            ? sortOrder === 1
                              ? "up"
                              : "down"
                            : ""
                        }`}
                        onClick={() => customSort("sale_price")}
                        style={{
                          cursor: "pointer",
                          display: "flex",
                          alignItems: "center",
                          fontSize: "11px",
                          lineHeight: "11px",
                        }}
                      >
                        {i18n("MRP")}
                        <CustomeSortIcon
                          sortOrder={sortField === "sale_price" ? sortOrder : 0}
                          style={{ marginLeft: "4px", verticalAlign: "middle" }}
                        />
                      </div>
                    )}
                    body={(row) => (
                      <span>
                        {row.sale_price ? (
                          <GlobalCurrency price={(row.sale_price)} />
                        ) : (
                          <GlobalCurrency price={0} />
                        )}
                      </span>
                    )}
                    sortable
                    headerStyle={headerCellStyle}
                  />

                  <Column
                    field="sale_price"
                    header={() => (
                      <div
                        className={`custom-header-${
                          sortField === "sale_price"
                            ? sortOrder === 1
                              ? "up"
                              : "down"
                            : ""
                        }`}
                        onClick={() => customSort("sale_price")}
                        style={{
                          cursor: "pointer",
                          display: "flex",
                          alignItems: "center",
                          fontSize: "11px",
                          lineHeight: "11px",
                        }}
                      >
                        {i18n("SELLING PRICE")}
                        <CustomeSortIcon
                          sortOrder={sortField === "sale_price" ? sortOrder : 0}
                          style={{ marginLeft: "4px", verticalAlign: "middle" }}
                        />
                      </div>
                    )}
                    body={(row) => (
                      <span>
                        {row.sale_price ? (
                          <GlobalCurrency price={(row.sale_price)} />
                        ) : (
                          <GlobalCurrency price={0} />
                        )}
                      </span>
                    )}
                    sortable
                    headerStyle={headerCellStyle}
                  />
                 
                </DataTable>
              ) : (
                <p>{i18n("Loading")}</p>
              )}
            </div>
          </Container>
        </div>
      </Container>
    </Layout>
  );
};

export default RateListReport;
