export const rolesList = [
    {
        id : 1,
        label : 'Admin'
    },
    {
        id : 2,
        label : 'Manager'
    },
    {
        id : 3,
        label : 'Sales'
    },
    {
        id : 4,
        label : 'Trainer'
    },
]