import React, { useState, useEffect } from "react";
import { Form, Card, Col, Row } from "react-bootstrap";
import { placeOfSupplyList } from "../../shared/config/placeOfSupply";
import Select from "react-select";
import { useDispatch } from "react-redux";
import { numValidate } from "../../shared/helpers/sharedMethods";
import { updateBillingAddress } from "../store/partyAddressSlice";
import pincodeData from "../../../assets/indiaPincode.json";

const BillingAddress = (props) => {
  const { i18n, placeholderText, id, values } = props;

  const dispatch = useDispatch();
  const [formData, setFormData] = useState(values);

  useEffect(() => {
    setFormData(values);
  }, [values]);

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      border: "1px solid #ccc",
      borderRadius: "4px",
      boxShadow: state.isFocused ? "0 0 7px rgb(77,144,254,0.5)" : null,
      height: "40px",
    }),
  };

  const handleChangeAddress = (e) => {
    const { name, value } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: value,
    }));
    dispatch(updateBillingAddress({ fieldName: name, fieldValue: value, id }));
  };

  const handlePincodeChange = (e) => {
    const { value } = e.target;
    handleChangeAddress(e);

    if (value.length === 6) {
      const pincodeEntry = pincodeData.find(p => p.pincode == value);
      if (pincodeEntry) {
        const stateOption = placeOfSupplyList.find(option => option.label.toUpperCase() == pincodeEntry.statename);
        setFormData(prevData => ({
          ...prevData,
          city: pincodeEntry.Districtname,
          state: stateOption ? stateOption.value : ""
        }));
        dispatch(updateBillingAddress({ fieldName: "city", fieldValue: pincodeEntry.Districtname, id }));
        dispatch(updateBillingAddress({ fieldName: "state", fieldValue: stateOption ? stateOption.value : "", id }));
      }
    }
  };

  const selectedStateOption = placeOfSupplyList.find(option => option.value === formData?.state);


  return (
    <Card className="px-4">
      <Row className="py-2">
        <Col lg={12}>
          <label className="form-label-item mb-2">
            {i18n("Address")}
          </label>
          <Form.Control
            as="textarea"
            placeholder={placeholderText("Enter billing address")}
            name="address"
            className="form-control"
            value={formData?.address || ""}
            onChange={handleChangeAddress}
            rows={2}
          />
        </Col>
      </Row>
      <Row className="py-2">
        <Col lg={4}>
          <Form.Group>
            <Form.Label>{i18n("Pincode")}</Form.Label>
          </Form.Group>
          <Form.Control
            name="pincode"
            placeholder={placeholderText("Enter Pincode")}
            value={formData?.pincode || ""}
            maxLength={6}
            onChange={handlePincodeChange}
            onKeyPress={(event) => numValidate(event)}
          />
        </Col>

        <Col lg={4}>
          <Form.Group>
            <Form.Label>{i18n("City")}</Form.Label>
          </Form.Group>
          <Form.Control
            name="city"
            placeholder={placeholderText("Enter City")}
            value={formData?.city || ""}
            onChange={handleChangeAddress}
          />
        </Col>
        <Col lg={4}>
          <Form.Label>{i18n("State")}</Form.Label>
          <Select
            name="state"
            options={placeOfSupplyList}
            styles={customStyles}
            value={selectedStateOption || null}
            onChange={(selectedOption) => {
              const data = {
                fieldName: "state",
                fieldValue: selectedOption.value,
                id,
              };
              setFormData(prevData => ({
                ...prevData,
                state: selectedOption.value
              }));
              dispatch(updateBillingAddress(data));
            }}
          />
        </Col>
      </Row>
    </Card>
  );
};

export default BillingAddress;
