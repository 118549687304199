import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { calculateTotals, formatAmount } from "../../pos/helpers/cartHelper";
import {
  defaultParty,
  defaultPaymentMode,
  defaultPlaceOfSupply,
} from "../../shared/config/defaults";
import { updateAddlTotal } from "../helpers/VouchersHelper";
import { i18n } from "../../shared/helpers/sharedMethods";
import { filter, isEmpty } from "lodash";
import { getUtcDate } from "../../shared/helpers/localizationHelper";
import { axiosRequest } from "../../shared/helpers/axiosHelper";
import { getPaymentNumber } from '../../payment/store/paymentEntitySlice'; // Adjust the import path accordingly
import { getPaymentId } from '../../payment/store/paymentEntitySlice'; // Adjust the import path accordingly

const initialState = {
  id: "",
  type: "sales_invoice",
  voucher_number: "",
  voucher_date: new Date().toISOString(),
  payment_terms: null,
  due_date: new Date().toISOString(),
  subtotal: 0,
  taxable_value: 0,
  grand_total: 0,
  paid_amount: 0,
  processing_paid_amount: 0,
  is_paid: false,
  roundoff: 0,
  is_closed: 0,
  tax_total: 0,
  cgst_total: 0,
  sgst_total: 0,
  igst_total: 0,
  cess_total: 0,
  notes: null,
  reference: null,
  tnc: null,
  created_by: null,
  updated_by: null,
  items: [],
  payment: {
    payment_number: null,
    type: "payment_in",
    party_id: defaultParty.id,
    amount: 0,
    mode: defaultPaymentMode,
    created_by: null,
  },
  voucherPayment: [],
  charges: [],
  addresses: {
    billing: {
      name: "",
      phone_number: "",
      address: "",
      city: "",
      state: "",
      pincode: "",
      created_by: "",
      updated_by: null,
    },
    shipping: {
      name: "",
      phone_number: "",
      address: "",
      city: "",
      state: "",
      pincode: "",
      created_by: "",
      updated_by: null,
    },
  },
  party: {},
  total_quantity: 0,
  received_amount: 0,
  totalReceivedAmount: 0,
  change_return: 0,
  status: {
    currentId: "",
    isOrdered: false,
    errorStatus: false,
    error: {},
    showEditPartyModal: false,
    activeEditPartyTab: "general",
    convertTypeStatus: false,
  },
  totalAddlCharges: 0,
  gstin: "",
  place_of_supply: "",
  total_discount: 0,
  copiedGrandTotal: 0,
  currentAddress: {},
  markAsPaid: false,
  // updatedPaidAmount:[],
  updatePaidAmountStatus: false,
  deletePaidAmountStatus: false,
};

export const addVoucher = createAsyncThunk(
  "voucherEntity/addVoucher",
  async (args, { dispatch }) => {
    try {
      console.log('args', args);
      const token = args.token;
      const tenantId = args.tenant_id;
      const resourceType = 'voucher';
      const terminalNumber = args.terminal_id;


      function isAddressNonEmpty(address) {
        return Object.values(address).some((value) => value !== "" && value !== null);
      }

      const addresses = args.data.addresses;
      const hasBillingData = isAddressNonEmpty(addresses.billing);
      const hasShippingData = isAddressNonEmpty(addresses.shipping);


      const transformedData = {
        ...args.data,
        voucher_items: args.data.items,
        voucher_address: hasBillingData || hasShippingData ? addresses : [],
        voucher_charges: [],
        voucher_payments: [],
      };


      if (args?.data?.paid_amount > 0) {
        let paymentType;
        if (
          args.data.type === "sales_invoice" ||
          args.data.type === "sales_return" ||
          args.data.type === "credit_note" ||
          args.data.type === "quote"
        ) {
          paymentType = "payment_in";
        } else {
          paymentType = "payment_out";
        }

        if (args?.data?.paid_amount > 0) {
          let paymentType;
          if (
            args.data.type === "sales_invoice" ||
            args.data.type === "sales_return" ||
            args.data.type === "credit_note" ||
            args.data.type === "quote"
          ) {
            paymentType = "payment_in";
          } else {
            paymentType = "payment_out";
          }

          const paymentNumberResponse = await dispatch(getPaymentNumber({
            token,
            tenant_id: args.tenant_id,
            data: paymentType
          })).unwrap()



          const paymentIdResponse = await dispatch(getPaymentId({
            token,
            tenant_id: args.tenant_id,
            terminal_id: args.terminal_id,
            data: 'payment'
          })).unwrap()

          const payment = {
            id: paymentIdResponse,
            party_id: args.data.party_id,
            payment_number: paymentNumberResponse,
            type: paymentType,
            amount: args.data.paid_amount,
            balance: args.data.grand_total - args.data.paid_amount,
            mode: "cash",
            status: "open",
            created_by: args.data.created_by,
            updated_by: args.data.updated_by,
            created_at: args.data.created_at,
            updated_at: args.data.updated_at
          };
          transformedData.voucher_payments = [
            {
              payment_id: paymentIdResponse,
              amount: args.data.paid_amount,
              created_by: args.data.created_by,
              updated_by: args.data.updated_by,
              created_at: args.data.created_at,
              updated_at: args.data.updated_at,
              payment: payment
            }];
        }
      }
        delete transformedData?.payment;
        delete transformedData?.addresses;
        delete transformedData?.charges;
        delete transformedData?.items;
        delete transformedData?.party;

        // Add the payment object to transformedData

        console.log("transformedData", transformedData)

        const saveSales = await axiosRequest({
          method: "POST",
          url: `resource/${resourceType}/create/${terminalNumber}`,
          data: transformedData,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            "X-Tenant": tenantId,
          },
        });

        if (saveSales.success) {
          return saveSales.data;
        } else {
          const errorMessage =
            saveSales && saveSales?.data?.map((d) => d.message).join(", ");
          throw errorMessage;
        }
      
    } catch (err) {
      await window.log.logToFile({
        message: "Error while creating voucher",
        level: "error",
      });
      throw new Error(err);
    }
  }
);

export const editVoucher = createAsyncThunk(
  "voucherEntity/editVoucher",
  async (args, { dispatch }) => {
    try {
      const token = args.token;
      const data = args.data;
      const tenantId = args.tenant_id;
      const resourceType = 'voucher';
      const terminalNumber = args.terminal_id;


      function isAddressNonEmpty(address) {
        return Object.values(address).some((value) => value !== "" && value !== null);
      }

      const addresses = args.data.addresses;
      const hasBillingData = isAddressNonEmpty(addresses.billing);
      const hasShippingData = isAddressNonEmpty(addresses.shipping);


      const transformedData = {
        ...args.data,
        voucher_items: args.data.items,
        voucher_address: hasBillingData || hasShippingData ? addresses : [],
        voucher_charges: [],
        voucher_payments: args?.data?.voucherPayment?.length > 0 ? args?.data?.voucherPayment : [],
      };


      if (args?.data?.amount > 0) {
        let paymentType;
        if (
          args.data.type === "sales_invoice" ||
          args.data.type === "sales_return" ||
          args.data.type === "credit_note" ||
          args.data.type === "quote"
        ) {
          paymentType = "payment_in";
        } else {
          paymentType = "payment_out";
        }

        const paymentNumberResponse = await dispatch(getPaymentNumber({
          token,
          tenant_id: args.tenant_id,
          data: paymentType
        })).unwrap()

        const paymentIdResponse = await dispatch(getPaymentId({
          token,
          tenant_id: args.tenant_id,
          terminal_id: args.terminal_id,
          data: 'payment'
        })).unwrap()

        const payment = {
          id: paymentIdResponse,
          party_id: args.data.party_id,
          payment_number: paymentNumberResponse,
          type: paymentType,
          amount: args.data.amount,
          balance: args.data.grand_total - args.data.paid_amount,
          mode: "cash",
          status: "open",
          created_by: args.data.created_by,
          updated_by: args.data.updated_by,
          created_at: args.data.created_at,
          updated_at: args.data.updated_at
        };
        transformedData.voucher_payments = [
          ...args?.data?.voucherPayment,
          {
            payment_id: paymentIdResponse,
            amount: args.data.amount,
            voucher_id: args?.data?.id,
            created_by: args.data.created_by,
            updated_by: args.data.updated_by,
            created_at: args.data.created_at,
            updated_at: args.data.updated_at,
            payment: payment
          }];
      }
      delete transformedData?.payment;
      delete transformedData?.addresses;
      delete transformedData?.charges;
      delete transformedData?.items;
      delete transformedData?.party;
      delete transformedData?.voucherPayment;
      delete transformedData?.amount;


      // Add the payment object to transformedData

      console.log("transformedData", transformedData)



      const editSales = await axiosRequest({
        method: "POST",
        url: `resource/${resourceType}/create/${terminalNumber}`,
        data: transformedData,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "X-Tenant": tenantId,
        },
      });

      if (editSales.success) {
        return editSales.data;
      } else {
        const errorMessage =
          editSales && editSales?.data?.map((d) => d.message).join(", ");
        throw errorMessage;
      }
    } catch (err) {
      console.log("transformedData", err)
      await window.log.logToFile({
        message: "Error while updating voucher",
        level: "error",
      });
      throw new Error(err);
    }
  }
);

export const getVoucherNumber = createAsyncThunk(
  "voucherEntity/getVoucherNumber",
  async (args) => {
    try {
      console.log('get', args);
      const token = args.token;
      const tenantId = args.tenant_id;
      const resourceType = args.data;
      const voucherNumberResponse = await axiosRequest({
        method: "POST",
        url: `get-doc-number/${resourceType}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "X-Tenant": tenantId,
        },
      });
      if (voucherNumberResponse.success) {
        return voucherNumberResponse.data;
      } else {
        throw new Error("Unable to get voucher number" + voucherNumberResponse.data);
      }
    } catch (err) {
      await window.log.logToFile({
        message: "Error while getting voucherNumber",
        level: "error",
      });
      throw new Error("Error while getting voucherNumber : " + err);
    }
  }
);

export const updatePartialPayment = createAsyncThunk(
  "voucherEntity/updatePartialPayment",
  async (args) => {
    console.log('triggered up');
    try {
      const token = args.token;
      const data = args.data;
      const tenantId = args.tenant_id;
      const updatePaymentResponse = await axiosRequest({
        method: "PUT",
        url: `voucher-payment/update-payment`,
        data: data,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "X-Tenant": tenantId,
        },
      });

      if (updatePaymentResponse.success) {
        return updatePaymentResponse.data;
      }
    } catch (err) {
      await window.log.logToFile({
        message: "Error while update payment",
        level: "error",
      });
      throw new Error("Error while update payment : " + err);
    }
  }
);

export const deletePartialPayment = createAsyncThunk(
  "voucherEntity/deletePartialPayment",
  async (row) => {
    try {
      const deletePaymentResponse = await window.api.deleteVoucherPayment(row);

      if (deletePaymentResponse.success) {
        return deletePaymentResponse.data;
      }
    } catch (err) {
      await window.log.logToFile({
        message: "Error while update payment",
        level: "error",
      });
      throw new Error("Error while update payment : " + err);
    }
  }
);

const voucherEntitySlice = createSlice({
  name: "voucherEntity",
  initialState,
  reducers: {
    updateTotals(state, action) {
      let items = action.payload;
      state.items = items;
      let {
        subtotal,
        taxable_value,
        tax_total,
        net_tax,
        cgst_total,
        sgst_total,
        igst_total,
        cess_total,
        total_quantity,
        discount,
        discount_amount,
        net_total,
        grand_total,
      } = calculateTotals(items);
      state.total_quantity = total_quantity;
      state.subtotal = subtotal.toFixed(2);
      state.taxable_value = taxable_value.toFixed(2);
      state.tax_total = tax_total.toFixed(2);
      state.cgst_total = cgst_total.toFixed(2);
      state.sgst_total = sgst_total.toFixed(2);
      state.igst_total = igst_total.toFixed(2);
      state.cess_total = cess_total.toFixed(2);
      state.discount = discount; state.discount_amount = discount_amount;
      state.total_discount = discount_amount;
      state.grand_total = formatAmount(grand_total);
      state.copiedGrandTotal = formatAmount(grand_total);
    },
    updatePayment(state, action) {
      let payment = action.payload;
      state.change_return = payment.change_return;
      state.received_amount = payment.amount;
      delete payment.change_return;
      state.payment = payment;
      state.paid_amount =
        payment.amount +
        (payment.totalReceivedAmount !== undefined
          ? payment.totalReceivedAmount
          : 0);
      state.processing_paid_amount = payment.amount;
      state.is_paid = state.grand_total - state.paid_amount == 0 ? true : false;
    },
    markAsPaidStatus(state, action) {
      state.markAsPaid = action.payload;
    },
    undoStatus(state, action) {
      state.updatePaidAmountStatus = false;
    },
    undoDelStatus(state, action) {
      state.deletePaidAmountStatus = false;
    },
    markAsPaidPaymentUpdate(state, action) {
      const { totalReceivedAmount, grand_total, markAsPaid, paid_amount } =
        action.payload;

      const balanceAmount = grand_total - paid_amount;
      if (markAsPaid) {
        state.paid_amount = grand_total;
        state.processing_paid_amount =
          totalReceivedAmount == 0 ? grand_total : balanceAmount;
      } else {
        state.processing_paid_amount = 0;
        state.paid_amount = totalReceivedAmount;
      }
    },
    updateParty(state, action) {
      let temp = action.payload;
      state.party = action.payload;
      state.payment.party_id = action.payload.id;
      state.place_of_supply = temp.place_of_supply;
      let addresses = action.payload.party_addresses;
      if (Array.isArray(addresses)) {
        const defaultAddress = addresses.find(
          (address) => address.id === state.party.default_address_id
        );
        if (defaultAddress) {
          const formattedAddress = {
            billing: {
              name: defaultAddress?.name,
              phone_number: defaultAddress?.phone_number,
              address: defaultAddress.address,
              city: defaultAddress.city,
              state: defaultAddress.state,
              pincode: defaultAddress.pincode,
              created_by: temp?.created_by,
              updated_by: temp?.updated_by,
            },
            shipping: {
              name: defaultAddress?.name,
              phone_number: defaultAddress?.phone_number,
              address: defaultAddress.address,
              city: defaultAddress.city,
              state: defaultAddress.state,
              pincode: defaultAddress.pincode,
              created_by: temp?.created_by,
              updated_by: temp?.updated_by,
            },
          };
          state.currentAddress = formattedAddress;
          state.addresses.billing = formattedAddress.billing;
          state.addresses.shipping = formattedAddress.shipping;

        }

      }
    },
    updatePartyPhone(state, action) {
      state.party.phone_number = action.payload;
    },
    updateNotes(state, action) {
      state.notes = action.payload;
    },
    updateReference(state, action) {
      state.reference = action.payload;
    },
    addVoucherNumber(state, action) {
      state.voucher_number = action.payload;
    },
    addVoucherDate(state, action) {
      state.voucher_date = action.payload;
    },
    addDueDate(state, action) {
      state.due_date = action.payload;
    },
    updatePlaceOfSupply(state, action) {
      state.place_of_supply = action.payload;
    },
    addCharges(state, action) {
      const charges = action.payload;
      state.charges = charges ? charges : [];
      if (action.payload.length > 0) {
        const { chargesTotal } = updateAddlTotal(action.payload);
        if (!isNaN(chargesTotal)) {
          state.totalAddlCharges = chargesTotal;
        }
      } else {
        state.totalAddlCharges = 0;
      }
    },
    initEditVoucher(state, action) {
      const { editSale, data } = action.payload;
      state.id = editSale?.id;
      state.party = editSale?.party;
      state.payment.party_id = editSale?.party_id;
      state.charges = editSale?.charges;
      state.paid_amount = editSale.paid_amount;
      state.processing_paid_amount = 0; state.roundoff = editSale?.roundoff;
      state.grand_total = editSale?.grand_total;
      state.voucher_number = editSale?.voucher_number;
      state.voucher_date = editSale?.voucher_date;
      state.due_date = editSale?.due_date;
      state.notes = editSale?.notes;
      state.tnc = editSale?.tnc;
      state.reference = editSale?.reference;
      state.gstin = editSale?.gstin;
      state.place_of_supply = editSale?.place_of_supply;
      const addresses = editSale?.address;

      if (addresses) {
        let address = {
          billing: {
            name: addresses[0]?.name,
            phone_number: addresses[0]?.phone_number,
            address: addresses[0]?.address,
            city: addresses[0]?.city,
            pincode: addresses[0]?.pincode,
            state: addresses[0]?.state,
            created_by: data.created_by,
            updated_by: data.updated_by,
          },
          shipping: {
            name: addresses[1]?.name,
            phone_number: addresses[1]?.phone_number,
            address: addresses[1]?.address,
            city: addresses[1]?.city,
            pincode: addresses[1]?.pincode,
            state: addresses[1]?.state,
            created_by: data.created_by,
            updated_by: data.updated_by,
          },
        };
        state.currentAddress = address;
        state.addresses.billing = address.billing;
        state.addresses.shipping = address.shipping;
      }
    },
    updateVoucherPayment(state, action) {
      state.voucherPayment = action.payload;
    },

    updateTnc(state, action) {
      state.tnc = action.payload;
    },
    updateGstIn(state, action) {
      state.gstin = action.payload;
    },
    updateBillingAddressField(state, action) {
      state.addresses.billing = action.payload;
    },
    updateShippingAddressField(state, action) {
      state.addresses.shipping = action.payload;
    },
    copyBillingToShippingAddress(state, action) {
      state.addresses.shipping = state.addresses.billing;
    },
    updateTotalReceivedAmount(state, action) {
      state.totalReceivedAmount = action.payload == isNaN ? 0 : action.payload;
    },
    roundOffGrandTotal(state, action) {
      if (action.payload) {
        const roundedAmount = Math.round(state.grand_total);
        state.roundoff = (roundedAmount - state.grand_total).toFixed(2);
        state.grand_total = roundedAmount;
      } else {
        state.grand_total = state.copiedGrandTotal;
        state.roundoff = 0;
      }
    },

    toggleEditPartyModal(state, action) {
      state.status.showEditPartyModal = action.payload.toggle;
      if (action.payload.activeEditPartyTab) {
        state.status.activeEditPartyTab = action.payload.activeEditPartyTab;
      }
    },
    voucherisOrdered(state, action) {
      state.status.isOrdered = false;
      state.status.convertTypeStatus = false;
    },
    undoVoucherError(state, action) {
      state.status.errorStatus = false;
      state.status.error = null;
      state.status.isOrdered = false;
      state.status.convertTypeStatus = false;
    },
    convertTypeStatus(state, action) {
      state.status.convertTypeStatus = true;
    },
    resetOrder: () => initialState,
  },
  extraReducers(builder) {
    builder
      .addCase(addVoucher.fulfilled, (state, action) => {
        state.status.isOrdered = true;
        state.status.currentId = action.payload;
      })
      .addCase(addVoucher.rejected, (state, action) => {
        state.status.errorStatus = true;
        state.status.error = action?.error?.message;
        state.status.isOrdered = false;
      })
      .addCase(editVoucher.fulfilled, (state, action) => {
        state.status.isOrdered = true;
        state.status.currentId = action.payload;
      })
      .addCase(editVoucher.rejected, (state, action) => {
        state.status.errorStatus = true;
        state.status.error = action?.error?.message;
        state.status.isOrdered = false;
      })
      .addCase(getVoucherNumber.fulfilled, (state, action) => {
        state.voucher_number = action.payload;
      })
      .addCase(getVoucherNumber.rejected, (state, action) => {
        state.status.errMessage = i18n(
          "Error while getting voucher number. Please try again later."
        );
      })
      .addCase(updatePartialPayment.fulfilled, (state, action) => {
        //state.updatedPaidAmount = action.payload
        state.voucherPayment = action.payload.payments;
        state.totalReceivedAmount = action.payload.totalPaidAmount;
        state.paid_amount = action.payload.totalPaidAmount;
        state.updatePaidAmountStatus = true;
      })
      .addCase(updatePartialPayment.rejected, (state, action) => {
        state.status.errMessage = "Error whileupdate payment.";
      })
      .addCase(deletePartialPayment.fulfilled, (state, action) => {
        //state.updatedPaidAmount = action.payload
        state.voucherPayment = action.payload.payments;
        state.totalReceivedAmount = action.payload.totalPaidAmount;
        state.paid_amount = action.payload.totalPaidAmount;
        state.deletePaidAmountStatus = true;
      })
      .addCase(deletePartialPayment.rejected, (state, action) => {
        state.status.errMessage = "Error while delete payment.";
      });
  },
});

export default voucherEntitySlice.reducer;

export const {
  updateTotals,
  updatePayment,
  updateParty,
  updateTnc,
  updateNotes,
  updateReference,
  addAddlCharges,
  resetOrder,
  updateAddress,
  addDueDate,
  addVoucherNumber,
  addVoucherDate,
  initEditVoucher,
  updateVoucherPayment,
  addCharges,
  updateGstIn,
  updatePlaceOfSupply,
  updateBillingAddressField,
  updateShippingAddressField,
  roundOffGrandTotal,
  updateTotalReceivedAmount,
  updatePartyPhone,
  copyBillingToShippingAddress,
  toggleEditPartyModal,
  undoVoucherError,
  voucherisOrdered,
  convertTypeStatus,
  markAsPaidPaymentUpdate,
  undoStatus,
  undoDelStatus,
  markAsPaidStatus,
} = voucherEntitySlice.actions;