import { Card, Col, Form, Row } from "react-bootstrap";
import { i18n, placeholderText } from "../../shared/helpers/sharedMethods";
import {
  setAccountNumber,
  setBankName,
  setBranchName,
  setCardHolderName,
  setIFSCCode,
  setNotes,
} from "../store/settingSlice";
import { ErrorMessage } from "formik";
import { useDispatch } from "react-redux";

const AccountDetailsBlock = ({ values, handleChange, errors }) => {
  
  const dispatch = useDispatch();
  return (
    <Card>
      <Row>
        <Col lg={12}>
          <Form.Group className="">
            <Form.Label className="fs-2">
              <span
                className="pb-2"
                style={{
                  borderBottom: "3px solid #1a237e",
                  fontWeight: 600,
                  fontSize: "15px",
                }}
              >
                {i18n("Bank Details")}
              </span>
            </Form.Label>
          </Form.Group>
        </Col>
        <Col lg={10}>
          <Row className="mt-3">
            <Col lg={12}>
              <Form.Group controlId="exampleForm.ControlInput1">
                <Form.Label>{i18n("Account Number")}</Form.Label>
                <Form.Control
                  type="text"
                  
                  minLength={8}
                  maxLength={17}
                  className={`form-control`}
                  value={values?.account_number}
                  placeholder={placeholderText("Enter your account number")}
                  name="account_number"
                  onChange={(e) => {
                    handleChange(e);
                    dispatch(setAccountNumber(e.target.value));
                  }}
                />
                <ErrorMessage
                  name="account_number"
                  component="span"
                  className="text-danger d-block fw-400 fs-small mt-2"
                />
              </Form.Group>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col lg={12}>
              <Form.Group className="">
                <Form.Group controlId="exampleForm.ControlInput1">
                  <Form.Label>{i18n("Account Holders Name")}</Form.Label>
                  <Form.Control
                    value={values?.card_holder_name}
                    name="card_holder_name"
                   
                    placeholder={placeholderText("Enter your name")}
                    onChange={(e) => {
                      handleChange(e);
                      dispatch(setCardHolderName(e.target.value));
                    }}
                  />
                  <ErrorMessage
                    name="card_holder_name"
                    component="span"
                    className="text-danger d-block fw-400 fs-small mt-2"
                  />
                </Form.Group>
              </Form.Group>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col lg={12}>
              <Form.Group controlId="exampleForm.ControlInput1">
                <Form.Label>{i18n("IFSC Code")}</Form.Label>
                <Form.Control
                  type="text"
                  minLength={11}
                  maxLength={11}
                  pattern="^[A-Z]{4}[0-9]{7}$"
                  name="ifsc_code"
                  placeholder={placeholderText("Enter IFSC Code")}
                  value={values?.ifsc_code}
                  onChange={(e) => {
                    handleChange(e);
                    dispatch(setIFSCCode(e.target.value.toUpperCase()));
                  }}
                />
                <ErrorMessage
                  name="ifsc_code"
                  component="span"
                  className="text-danger d-block fw-400 fs-small mt-2"
                />
              </Form.Group>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col lg={12}>
              <Form.Group controlId="exampleForm.ControlInput1">
                <Form.Label>{i18n("Bank")}</Form.Label>
                <Form.Control
                  type="text"
                  name="bank"
                  placeholder={placeholderText("Enter Bank name")}
                  value={values?.bank}
                  onChange={(e) => {
                    handleChange(e);
                    dispatch(setBankName(e.target.value));
                  }}
                />
                <ErrorMessage
                  name="bank"
                  component="span"
                  className="text-danger d-block fw-400 fs-small mt-2"
                />
              </Form.Group>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col lg={12}>
              <Form.Group controlId="exampleForm.ControlInput1">
                <Form.Label>{i18n("Branch Name")}</Form.Label>
                <Form.Control
                  type="text"
                  name="branch_name"
                  placeholder={placeholderText("Enter Branch name")}
                  value={values?.branch_name}
                  onChange={(e) => {
                    handleChange(e);
                    dispatch(setBranchName(e.target.value));
                  }}
                />
                <ErrorMessage
                  name="branch_name"
                  component="span"
                  className="text-danger d-block fw-400 fs-small mt-2"
                />
              </Form.Group>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col lg={12}>
              <Form.Group className="">
                <Form.Group controlId="exampleForm.ControlInput1">
                  <Form.Label>{i18n("Note")}</Form.Label>
                  <Form.Control
                    as="textarea"
                    value={values?.notes}
                    name="notes"
                    placeholder={placeholderText("Enter your note")}
                    onChange={(e) => {
                      handleChange(e);
                      dispatch(setNotes(e.target.value));
                    }}
                  />
                </Form.Group>
              </Form.Group>
            </Col>
          </Row>
        </Col>
      </Row>
    </Card>
  );
};

export default AccountDetailsBlock;
