import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Container, Stack, Table } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faBarcode,
    faMagnifyingGlass
} from '@fortawesome/free-solid-svg-icons';
import { i18n} from '../../../../../shared/helpers/sharedMethods';
import { selectCartItemIds } from '../../../../store/cartItemsSlice';
import CartItem from './CartItem';

const ItemsStack = () => {

    const allCartItemIds = useSelector(selectCartItemIds);
    ;


    return (
        <Container className="item-stack-wrapper mt-2" style={{ backgroundColor: 'white' }}>
            <Table responsive borderless hover>
                <thead className='bottom-splitter'>
                    <tr className='item-stack-header'>
                        <th >{i18n("#")}</th>
                        <th className='w-50'>{i18n("items")}</th>
                        <th>{i18n("item code")}</th>
                        <th>{i18n("price")}</th>
                        <th>{i18n("quantity")}</th>
                        <th>{i18n("amount")}</th>
                        <th>{i18n("actions")}</th>
                    </tr>
                </thead>
                <tbody className='border-0'>
                    {allCartItemIds && allCartItemIds.length ? allCartItemIds.map((cartItemId,index) => {
                        return <CartItem key={index+1} index={index} cartItemId={cartItemId} />
                    }) : <tr>
                        <td colSpan={8} className='custom-text-center text-gray-900 py-15'>
                            <Stack className='shortcut-color align-middle h3'>
                                <span className='mb-3'>
                                <FontAwesomeIcon icon={faMagnifyingGlass} />&nbsp; {i18n('Add items by searching item name or item code')}
                                </span>
                                <span>{i18n('Or')}</span>
                                <span className='mt-3'>
                                <FontAwesomeIcon icon={faBarcode} /> &nbsp; {i18n('Simply scan barcode to add items')}
                                </span>
                            </Stack> 
                            </td>
                    </tr>}
                </tbody>
            </Table>
        </Container>


    )
}

export default ItemsStack;