import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from "@reduxjs/toolkit";
import { axiosRequest } from "../../shared/helpers/axiosHelper";
import { i18n } from "../../shared/helpers/sharedMethods";

const stockReportAdapter = createEntityAdapter({
});

const initialState = stockReportAdapter.getInitialState({
  status: false,
  error: null,
});

export const getStockReport = createAsyncThunk(
  "stockReport/get",
  async (args) => {
    try {
      const token = args.token;
      const tenantId = args.tenantId;

      const startDate = args.startDate ? args.startDate : '';
      const endDate = args.endDate ? args.endDate : '';
      const response = await axiosRequest({
        method: "GET",
        url: `catalog/stocklog?product_id=${args.id}&start_date=${startDate}&end_date=${endDate}`,
        headers: {
          Authorization: `Bearer ${token}`,
          "X-Tenant": tenantId,
        },
      });
      return response.data;
    } catch (error) {
      await window.log.logToFile({message: "Error while fetching stock report", level: "error" });
      throw error;
    }
  }
);

const stockReportSlice = createSlice({
  name: "stockReport",
  initialState,
  reducers: {
    // resetStockData: () => initialState,
    resetStockData: (state) => {
      stockReportAdapter.removeAll(state);
      state.status = false;
      state.error = null;
    },

  },
  extraReducers(builder) {
    builder
      .addCase(getStockReport.fulfilled, (state, action) => {
        state.status = true;
        stockReportAdapter.upsertMany(state, action.payload);
      })
      .addCase(getStockReport.rejected, (state, action) => {
        state.status = false;
        state.error = i18n("Unable to retrieve stock report. Please try again later.");
      });
  },
});

export default stockReportSlice.reducer;
export const { stockData,resetStockData } = stockReportSlice.actions;

export const {
  selectAll: selectAllStockReportData,
  selectById: selectStockReportDataById,
  selectIds: selectStockReportDataIds,
} = stockReportAdapter.getSelectors((state) => state.stockReport);
