import React,{ useEffect }  from "react";
import { ListGroup } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { i18n } from "../../shared/helpers/sharedMethods";
import TabLayout from "../../shared/components/item-detail/TabLayout";
import HSNWiseSaleSummaryReport from "../screens/HSNWiseSaleSummary";
import GSTR2PurchaseReport from "../screens/GSTR2PurchaseReport";
import GSTPurchaseReport from "../screens/GSTPurchaseReport";
import GSTSalesReport from "../screens/GSTSalesReport";
import GSTR1SalesReport from "../screens/GSTR1SalesReport";
import { useSelector } from "react-redux";
import { fetchSetting } from "../../settings/store/getSettingSlice";

const featureName = "reports_advanced";
const GSTNavbar = () => {
  const navigate = useNavigate();
  const location = useLocation();
 
  const globalPlanFeatures = useSelector(
    (state) => state?.globalPlan?.features
  );
  const status = useSelector((state) => state.setting.status);

  useEffect(() => {
    if (!globalPlanFeatures.includes(featureName)) {
      navigate("/setting/not-in-plan");
    }
  }, [])

const reportTabs = [
  {
    key: "gst/sales",
    title: i18n("GSTR-1 (Sales)"),
    component: <GSTR1SalesReport />,
  },
  {
    key: "gst/purchase",
    title: i18n("GSTR-2 (Purchase)"),
    component: <GSTR2PurchaseReport />,
  },
  {
    key: "gst/purchase-with-hsn",
    title: i18n("GST Purchase (With HSN)"),
    component: <GSTPurchaseReport />,
  },
  {
    key: "gst/sales-with-hsn",
    title: i18n("GST Sales (With HSN)"),
    component: <GSTSalesReport />,
  },
  {
    key: "gst/hsn-wise-sales-summary",
    title: i18n("HSN wise sales summary"),
    component: <HSNWiseSaleSummaryReport />,
  },
];
  return (
    <>
      {/* <div
        className="col-lg-12 "
        style={{ height: "90vh", borderRight: "1px solid lightgray" }}
      >
        <ListGroup className="custom-listgroup-css py-2" variant="flush">
        <ListGroup.Item className={location.pathname == "/reports/gstr1Sales" ? "active-highlight" : ""}
            onClick={() => {
              navigate("/reports/gstr1Sales");
            }}
          >
            <Link className="d-flex " to="/reports/gstr1Sales">
            {i18n("GSTR-1 (Sales)")}
            </Link>
          </ListGroup.Item>

          <ListGroup.Item className={location.pathname == "/reports/gstr2Purchase" ? "active-highlight" : ""}
            onClick={() => {
              navigate("/reports/gstr2Purchase");
            }}
          >
            <Link className="d-flex " to="/reports/gstr2Purchase">
            {i18n("GSTR-2 (Purchase)")}
            </Link>
          </ListGroup.Item>
         
          <ListGroup.Item   className={location.pathname == "/reports/gstPurchase" ? "active-highlight" : ""}
            onClick={() => {
              navigate("/reports/gstPurchase");
            }}
          >
            <Link className="d-flex " to="/reports/gstPurchase">
            {i18n("GST Purchase (With HSN)")}
            </Link>
          </ListGroup.Item>

          <ListGroup.Item
            className={location.pathname == "/reports/gstSales" ? "active-highlight" : ""}
            onClick={() => {
              navigate("/reports/gstSales");
            }}
          >
            <Link className="d-flex " to="/reports/gstSales">
              {" "}

              {i18n("GST Sales (With HSN)")}
            </Link>
          </ListGroup.Item>

          <ListGroup.Item className={location.pathname == "/reports/hsnWiseSale" ? "active-highlight" : ""}
            onClick={() => {
              navigate("/reports/hsnWiseSale");
            }}
          >
            <Link className="d-flex " to="/reports/hsnWiseSale">
            {i18n("HSN wise sales summary")}
            </Link>
          </ListGroup.Item>
         

        </ListGroup>
      </div> */}
      <TabLayout type="reports" tabs={reportTabs} route="reports" title="Report" />
    </>
  );
};

export default GSTNavbar;
