import {
    createAsyncThunk,
    createSlice
} from '@reduxjs/toolkit';
import { calculateTotals, formatAmount } from '../helpers/cartHelper';
import { defaultParty, defaultPaymentMode } from '../../shared/config/defaults';
import { i18n } from '../../shared/helpers/sharedMethods';

const initialState = {
    type: 'sales_invoice',
    voucher_number: null,
    voucher_date: null,
    payment_terms: null,
    due_date: null,
    subtotal: 0,
    taxable_value: 0,
    grand_total: 0,
    paid_amount: 0,
    is_paid: false,
    is_closed: 0,
    tax_total: 0,
    cgst_total: 0,
    sgst_total: 0,
    igst_total: 0,
    cess_total: 0,
    notes: null,
    is_tnc: false,
    created_by: null,
    updated_by: null,
    items: [],
    payment: {
        payment_number: null,
        type: 'payment_in', //receiving payment
        party_id: defaultParty.id,
        amount: 0,
        mode: defaultPaymentMode,
        created_by: null
    },
    charges: {
        voucher_id: null,
        charge_type: 'Additional charges',
        charge_value: 0,
        tax_code: null,
        tax_rate: null,
        cgst: null,
        sgst: null,
        igst: null,
        cess_rate: null,
        cess: null,
        tax_rate: null,
        tax_total: 0,
        net_total: 0
    },
    party: defaultParty,
    total_quantity: 0,
    received_amount: 0,
    change_return: 0,
    status: {
        isOrderPlaced: false,
        errMessage: ''
    }
}

export const addNew = createAsyncThunk(
    'posOrder/addNew',
    async (order) => {
        try {
            await window.api.savePosOrder({
                posOrder: order
            });
            return true;
        } catch (err) {
            await window.log.logToFile({ message: "Error while fetching Pos Order", level: "error" });
            return err.message;
        }

    }
)

const posOrderSlice = createSlice({
    name: 'posOrder',
    initialState,
    reducers: {
        updateTotals(state, action) {
            let items = action.payload.items;
            state.items = items;
            let { subtotal, taxable_value, tax_total, net_tax, cgst_total, sgst_total, igst_total, cess_total, total_quantity } = calculateTotals(items);
            state.total_quantity = total_quantity;
            state.subtotal = subtotal.toFixed(2);
            state.taxable_value = taxable_value.toFixed(2);
            state.tax_total = tax_total.toFixed(2);
            state.cgst_total = cgst_total.toFixed(2);
            state.sgst_total = sgst_total.toFixed(2);
            state.igst_total = igst_total.toFixed(2);
            state.cess_total = cess_total.toFixed(2);
            state.grand_total = formatAmount(subtotal + tax_total + net_tax);
        },
        updatePayment(state, action) {
            let payment = action.payload;
            state.change_return = payment.change_return;
            state.received_amount = payment.amount
            delete payment.change_return;
            state.payment = payment;
            state.paid_amount = payment.amount;
            state.is_paid = (state.grand_total - state.paid_amount == 0) ? true : false;
        },
        updateParty(state, action) {
            state.party = action.payload;
            state.payment.party_id = action.payload.id;
        },
        updateNotes(state, action) {
            state.notes = action.payload;
        },
        addAddlCharges(state, action) {
            let charge = action.payload
            charge.net_total = charge.charge_value; //add tax calc later
            state.grand_total = parseFloat(state.grand_total) + parseFloat(charge.net_total);
            state.charges = charge
        },
        resetOrder: () => initialState
    },
    extraReducers(builder) {
        builder
            .addCase(addNew.fulfilled, (state, action) => {
                state.status.isOrderPlaced = true;
            })
            .addCase(addNew.rejected, (state, action) => {
                state.status.errMessage = (i18n(action.payload));
            })
    }
});

export default posOrderSlice.reducer;

export const { updateTotals, updatePayment, updateParty, updateNotes, addAddlCharges, resetOrder } = posOrderSlice.actions;
