import React, { useState, useEffect } from "react";
import {
  FormControl,
  Dropdown,
  InputGroup,
  Container,
  Stack,
  Button,
  Spinner,
  Form,
  Row,
  Col
} from "react-bootstrap";
import DataTable from "react-data-table-component";
import CreateButton from "./CreateButton";
import GlobalSearch from "./globalSearch";
import GlobalAction from "./globalAction";
import { i18n, placeholderText } from "../../shared/helpers/sharedMethods";

import DatePicker from "../../voucher/components/DatePicker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCubes } from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import { fetchProducts } from "../../catalog/store/productsSlice";
import { fetchPartyDetails } from "../../party/store/PartyDetails";
import { getAllVoucher } from "../../voucher/store/voucherSlice";
import { fetchparty } from "../../party/store/getPartySlice";
import { fetchCategories } from "../../settings/store/categorysSlice";
import { partyData } from "../../subscription/store/LivePartyGetsSlice";
import { fetchInviteUser } from "../../settings/store/inviteUserSlice";
import { format, parseISO } from 'date-fns';
import { UilRefresh } from '@iconscout/react-unicons'
import { SubscriptionData } from "../../subscription/store/SubscriptionSlice";
import { getEnquiry } from "../../enquiry/store/EnquirySlice";
import ReactDatePicker from "react-datepicker";

const DataGrid = ({
  category,
  filterType,
  stockFilter,
  statusFilter,
  type,
  allData,
  data,
  columns,
  isAllData,
  isPaginated = true,
  handleRowClick,
  handleCreate,
  titles,
  paymentTitle,
  addFilteredData,
  handleExportClick,
  button,
  title,
  payment,
  bulkAction,
  subscription,
  icons,
  message,
  defaultSortAsc,
  defaultSortField,
  ItemActions,
  refreshHandler,
  handleDateFilter,
  filterDate,
  fileList
}) => {


  const [filteredData, setFilteredData] = useState(data ? data : []);
  const [loading, setLoading] = useState(false);


  const { accessToken } = useSelector((state) => state?.auth);


  ;
  const selectedBusiness = useSelector(
    (state) => state?.auth?.selectedBusiness
  );

  const tenantId = selectedBusiness?.pivot?.tenant_id;
  const currentUserId = selectedBusiness?.pivot?.user_id;

  useEffect(() => {
    if (data) {
      setFilteredData(data);
    }
  }, [data]);
  const dispatch = useDispatch();
  const handleRefresh = () => {
    setLoading(true);
    setTimeout(() => {
      dispatch(fetchProducts());
      dispatch(fetchProducts());
      dispatch(getAllVoucher());
      dispatch(fetchparty());
      dispatch(fetchCategories());
      dispatch(partyData());
      dispatch(SubscriptionData({
        token: accessToken.access_token,
        tenant_id: tenantId,
      }));
      dispatch(getEnquiry({
        token: accessToken.access_token,
        tenant_id: tenantId,
      }));
      if (refreshHandler) {
        refreshHandler();
      }
      setLoading(false);
    }, 300);
  };

  return (
    <Container
      fluid
      className="px-2"
      style={{
        height: "calc(100vh - 120px)",
        overflow: "auto",
        overflowX: "hidden",
      }}
    >
      {title === "View Logs" || title === "Info Logs" || title == 'Sync Queue' ?
        <></> :
        <div className="d-flex justify-content-between">
          <div className="">
            <Stack direction="horizontal" className="mt-3 p-2" gap={2}>
              {titles === "Payment In" || paymentTitle === "Payment Out" ? (
                <>
                  {!loading && (
                    <Button variant="outline-info" onClick={handleRefresh}>
                      {i18n("Refresh")}
                    </Button>
                  )}
                  {loading && <Spinner animation="border" />}
                </>
              ) : (
                <div>
                  <InputGroup>
                    {title === "Report" || title === "Item Wise Report" || title === "View Logs" || title === "Info Logs" ? (
                      <></>
                    ) : (
                      <GlobalSearch
                        addFilteredData={addFilteredData}
                        setFilteredData={setFilteredData}
                        allData={allData}
                        filteredData={filteredData}
                      />
                    )}
                    {title === "Parties" ||
                      title === "Products" ||
                      title === "Category" ||
                      title === "Report" ||
                      title === "Invite User" ||
                      title == "Item Wise Report" ||
                      title === "Enquiry" ||
                      title === "Sync Queue" ||
                      title === "View Logs" || title === "Info Logs" ? (
                      <>
                      </>
                    ) : (
                      <>
                        <DatePicker
                          addFilteredData={addFilteredData}
                          setFilteredData={setFilteredData}
                          data={data}
                          allData={allData}
                        />
                        {title === "payment" ||
                          title === "subscription" ||
                          title === "Manage Invoices" ||
                          title === "Memberships" ||
                          title === "Enquiry" ? (
                          <></>
                        ) : (
                          <div style={{ marginLeft: "10px", width: "200px" }}>
                            {statusFilter}
                          </div>
                        )}
                      </>
                    )}
                    {isAllData === "All Parties" && (
                      <div style={{ width: "200px" }}>{category}</div>
                    )}
                    {title === "Products" && (
                      <div style={{ width: "200px" }}>{category}</div>
                    )}
                  </InputGroup>
                </div>
              )}
              <>
                {title === "Products" && (
                  <div style={{ width: "200px" }}>{stockFilter}</div>
                )}
                {isAllData === "All Parties" && <div>{filterType}</div>}
                {title === "Category" && <div>{filterType}</div>}
                {title === "Manage Invoices" && (
                  <div style={{ height: "46px", width: "200px" }}>
                    {filterType}
                  </div>
                )}

                {payment ||
                  title == "Item Wise Report" ||
                  title === "Report" ||
                  type === "all_sales" ||
                  type === "all_purchase" ||
                  subscription == 'subscription' || title === "View Logs" || title === "Info Logs"
                  ? (
                    <div></div>
                  ) : (
                    <CreateButton handleCreate={handleCreate} title={button} />
                  )}
              </>

              {titles === "Payment In" ||
                paymentTitle === "Payment Out" ||
                title === "Report" ||
                title === "Item Wise Report" ||
                subscription == "subscription" || title === "View Logs" || title === "Info Logs" ? (
                <></>
              ) : (
                <>
                  {!loading && (
                    <Button variant="outline-info" onClick={handleRefresh}>
                      {i18n("🗘 Refresh")}
                    </Button>
                  )}
                  {loading && <Spinner animation="border" />}

                </>
              )}
            </Stack>
          </div>
          <div className="ms-auto mt-0.5 p-1 flex-row">

            <GlobalAction
              bulkAction={bulkAction}
              handleExportClick={handleExportClick}
              title={title}
              type={type}
            />
            <div className="ms-2">
              {ItemActions}
            </div>
          </div>
        </div>}
      {title == "View Logs" || title == "Info Logs" || title == "Sync Queue" ? <Row className=" p-0 mt-6">
        <Col lg={9} className="px-0">
          <div className="d-flex justify-content-start">
            {fileList && fileList.length > 0 &&
              <Form.Select className="mx-3 w-25" onChange={(e) => { handleDateFilter(e.target.value) }}>


                {fileList.length > 0 && fileList.map((date, index) => {
                  const parsedDate = parseISO(date);
                  return (<option key={index} value={date}>
                    {format(parsedDate, 'dd-MM-yyyy')}
                  </option>)
                })}
              </Form.Select>}

            {!loading && (
              <Button variant="outline-info " onClick={refreshHandler}>
                {i18n("Refresh")}
              </Button>
              // <UilRefresh size="30" className="mt-2" color="rgb(33,37,41)" />
            )}
            {loading && <Spinner animation="border" />}

          </div>
        </Col>
        <Col lg={3} className="px-1">
          <div className="d-flex justify-content-end">
            <GlobalSearch
              addFilteredData={addFilteredData}
              setFilteredData={setFilteredData}
              allData={allData}
              filteredData={filteredData}
            />
          </div>

        </Col>
      </Row> : <></>}
      <div
        className=""
        style={
          {
            // height: "90vh",
            // overflow: "auto",
          }
        }
      >
        {filteredData && filteredData.length == 0 ? (
          <div
            className="col d-flex justify-content-center align-items-center "
            style={{ height: "75vh" }}
          >
            <div style={{ textAlign: "center" }}>
              {icons}
              <p className="mt-2" style={{ fontSize: "12px", color: "gray" }}>
                {message}
              </p>
            </div>
          </div>
        ) : (
          <div className="border mt-6">
            <DataTable
              data={filteredData}
              columns={columns}
              pagination={true}
              paginationPerPage={30}
              // paginationRowsPerPageOptions={[50, 100, 150, 200, 250]}
              paginationTotalRows={filteredData && filteredData.length}
              onRowClicked={handleRowClick}
              responsive
              highlightOnHover
              pointerOnHover
              handleSearch
              defaultSortAsc={defaultSortAsc}
              defaultSortFieldId={defaultSortField}
            />
          </div>
        )}
      </div>
    </Container >
  );
};

export default DataGrid;
