import React, { useState, useEffect } from "react";
import { Button, Row, Col, Stack, Container ,Image} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Layout from "../../shared/components/Layout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faDownload,
} from "@fortawesome/free-solid-svg-icons";
import { downloadCsvFile, handleExportClick } from "../../shared/export";
import ReportPeriod from "../../shared/components/ReportPeroid";
import Header from "../../shared/components/Header";
import { fetchHSNWiseSaleSummary } from "../store/hsnWiseSalesSummarySlice";
import DataGrid from "../../shared/components/DataGrid";
import GSTNavbar from "../components/GSTNavbar";
import { i18n } from "../../shared/helpers/sharedMethods";
import ShortcutKeys from "../../shared/components/ShortcutKeys";
import ReportDateRange from "../../shared/components/date_range/ReportDateRange";
import DatePicker from "../../shared/components/date_range/DatePicker";
import { CurrentWeekDateRange } from "../../shared/components/DateRangeYAxis";
import ReportHeader from "../components/ReportHeader";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import CustomeSortIcon from "../../shared/components/CustomeSortIcon";
import { UilImport } from "@iconscout/react-unicons";
import LoadingSpinner from '../../shared/components/LoadingSpinner';
import GlobalCurrency from "../../shared/components/GlobalCurrency";
import { formatVoucherType } from "../../shared/helpers/typeHelper";
const HSNWiseSaleSummaryReport = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const accessToken = useSelector((state) => state?.auth?.accessToken?.access_token);
  const selectedBusiness = useSelector((state) => state?.auth?.selectedBusiness);
  const tenantId = selectedBusiness?.pivot?.tenant_id;
  const [reportPeriod, setReportPeriod] = useState("current-week");
  const [csvData, setCsvData] = useState("");
  const [startDate, setStartDate] = useState(null); // State for start date
  const [endDate, setEndDate] = useState(null);
  const [sortField, setSortField] = useState(null);
  const [sortOrder, setSortOrder] = useState(1);


    const handleReset = () => {
      const salesDateLimits = ReportDateRange({
        reportPeriod: reportPeriod,
      });
      setStartDate(salesDateLimits.startDate);
      setEndDate(salesDateLimits.endDate);
      setReportPeriod("current-week");
    };
  


  useEffect(() => {
    dispatch(fetchHSNWiseSaleSummary({startDate: startDate, endDate: endDate,token:accessToken,tenant_id:tenantId})); 
  }, [startDate, endDate]);

    useEffect(() => {
      const dateLimits = ReportDateRange({ reportPeriod });
      setStartDate(dateLimits.startDate);
      setEndDate(dateLimits.endDate);
    }, [reportPeriod]);

  const reportItems = useSelector((state) => state.hsnWiseSaleSummary.reportItems);
  console.log('report',reportItems);
  const status = useSelector((state) => state.hsnWiseSaleSummary.status);
  const currencySymbol  = useSelector((state) => state.getSetting?.dataSetting.currency);
  const loading = useSelector((state) => state.hsnWiseSaleSummary.loading);

  const dateTimeString = new Date()
  .toLocaleString()
  .replace(/[-]/g, " ")
  .replace(/:/g, "-");
const fileName = `HSN_wise_sale_summary_${dateTimeString}`;

  const handleExportButtonClick = () => {
    const exportData =
      reportItems &&
      Array.isArray(reportItems) &&
      reportItems.map((val) => {
        const isReturn = val.voucher_type === "sales_return" || val.voucher_type === "credit_note";
        return {
        "HSN CODE.": val.hsn ? val.hsn : "-",
        "ITEM NAME": val.product_name ? val.product_name : "-",     
        "TOTAL QTY": isReturn ? (val.quantity ? -val.quantity : "-") : (val.quantity || "-"),
        "TOTAL VALUE": isReturn ? (val.amount ? (-parseFloat(val.amount)).toFixed(2) : "0") : (val.amount ? parseFloat(val.amount).toFixed(2) : "0"),
        "TAXABLE VALUE": isReturn ? (val.taxable_value ? (-parseFloat(val.taxable_value)).toFixed(2) : "0") : (val.taxable_value ? parseFloat(val.taxable_value).toFixed(2) : "0"),
        "IGST": isReturn ? (val.igst ? -val.igst : "0") : (val.igst || "0"),
        "CGST": isReturn ? (val.cgst ? -val.cgst : "0") : (val.cgst || "0"),
        "SGST": isReturn ? (val.sgst ? -val.sgst : "0") : (val.sgst || "0"),
        "CESS": isReturn ? (val.cess ? -val.cess : "0") : (val.cess || "0"),
        "TOTAL TAX AMOUNT": isReturn ? (val.tax_total ? (-parseFloat(val.tax_total)).toFixed(2) : "0") : (val.tax_total ? parseFloat(val.tax_total).toFixed(2) : "0"),
        "VOUCHER TYPE": val.voucher_type ? formatVoucherType(val.voucher_type) : "-",
      };
    });
    handleExportClick(
      exportData,
      setCsvData,
      downloadCsvFile,
      fileName
    );
  };
  const customSort = (field) => {
    setSortField(field);
    setSortOrder(sortOrder === 1 ? -1 : 1);
  };


  const sortedData = [...reportItems].sort((a, b) => {
    if (sortField) {
      const compareValueA = a[sortField];
      const compareValueB = b[sortField];
      if (compareValueA < compareValueB) {
        return sortOrder;
      }
      if (compareValueA > compareValueB) {
        return -sortOrder;
      }
    }
    return 0;
  });
  const headerStyle = {
    backgroundColor: "white",
  };
  const headerCellStyle = {
    fontWeight: "bold",
    fontSize: "11px",
  };
  return (
    <Layout>
         {loading && <LoadingSpinner />}
      <Header
        currentTitle={i18n("HSN wise sales summary")}
        ItemActions={
          <Stack gap={3} direction="horizontal" className="mt-1 mb-1 ">
            <ShortcutKeys />
            </Stack>
        }
            />

<Container fluid className="px-5">
             <div className="border " style={{ background: "white" }}>
            <GSTNavbar />
            <ReportHeader
            ItemActions={
              <Stack gap={2} direction="horizontal" className="pt-3 px-3 mb-3">
                <Stack direction="horizontal">
                  <DatePicker
                    startDate={startDate}
                    setStartDate={setStartDate}
                    endDate={endDate}
                    setEndDate={setEndDate}
                  />
                </Stack>
                <Stack className=" ">
                  <ReportPeriod
                    reportPeriod={reportPeriod}
                    setReportPeriod={setReportPeriod}
                  />
                </Stack>
                <Stack
                  gap={1}
                  direction="horizontal"
                  style={{ marginLeft: "1px" }}
                >
                  <Button
                    className="rounded-0"
                    variant="outline-danger"
                    onClick={handleReset}
                  >
                    {i18n("Reset")}
                    </Button>
                </Stack>
                <Button
                  className="fw-bolder d-flex align-items-center rounded-0"
                  onClick={handleExportButtonClick}
                >
                  <UilImport
                    size="18"
                    className="mx-2"
                    style={{ cursor: "pointer" }}
                  />
                  {i18n("Export")}
                </Button>
              </Stack>
            }
          />
            <Container fluid className="px-3 mb-4">
            <div className="bordered-container">
              {status && reportItems ? (
                <DataTable
                  value={sortedData}
                  headerStyle={headerStyle}
                  paginator
                  rows={10}
                  dataKey="id"
                  emptyMessage={i18n("No HSN Wise Sales Summary found.")}
                  className="p-datatable-gridlines full-width-table custom-datatable p-component"
                  scrollable
                  scrollHeight="400px"
                  sortMode="multiple"
                  removableSort
                >

          <Column
  field="hsn"
  header={() => (
    <div
      className={`custom-header-${sortField === "hsn" ? (sortOrder === 1 ? "up" : "down") : ""}`}
      onClick={() => customSort("hsn")}
      style={{
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        fontSize: "11px",
        lineHeight: "11px",
      }}
    >
      {i18n("HSN Code")}
      <CustomeSortIcon
        sortOrder={sortField === "hsn" ? sortOrder : 0}
        style={{ marginLeft: "4px", verticalAlign: "middle" }}
      />
    </div>
  )}
  body={(row) => (row.hsn ? row.hsn : "-")}
  sortable
  headerStyle={headerCellStyle}
/>

<Column
  field="product_name"
  header={() => (
    <div
      className={`custom-header-${sortField === "product_name" ? (sortOrder === 1 ? "up" : "down") : ""}`}
      onClick={() => customSort("product_name")}
      style={{
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        fontSize: "11px",
        lineHeight: "11px",
      }}
    >
      {i18n("ITEM NAME")}
      <CustomeSortIcon
        sortOrder={sortField === "product_name" ? sortOrder : 0}
        style={{ marginLeft: "4px", verticalAlign: "middle" }}
      />
    </div>
  )}
  body={(row) => row.product_name}
  sortable
  headerStyle={headerCellStyle}
/>
<Column
  field="voucher_type"
  header={() => (
    <div
      className={`custom-header-${sortField === "voucher_type" ? (sortOrder === 1 ? "up" : "down") : ""}`}
      onClick={() => customSort("voucher_type")}
      style={{
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        fontSize: "11px",
        lineHeight: "11px",
      }}
    >
      {i18n("VOUCHER TYPE")}
      <CustomeSortIcon
        sortOrder={sortField === "voucher_type" ? sortOrder : 0}
        style={{ marginLeft: "4px", verticalAlign: "middle" }}
      />
    </div>
  )}
  body={(row) => (row.voucher_type ? formatVoucherType(row.voucher_type) : "-")}
  sortable
  headerStyle={headerCellStyle}
/>

<Column
  field="quantity"
  header={() => (
    <div
      className={`custom-header-${sortField === "quantity" ? (sortOrder === 1 ? "up" : "down") : ""}`}
      onClick={() => customSort("quantity")}
      style={{
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        fontSize: "11px",
        lineHeight: "11px",
      }}
    >
      {i18n("QTY")}
      <CustomeSortIcon
        sortOrder={sortField === "quantity" ? sortOrder : 0}
        style={{ marginLeft: "4px", verticalAlign: "middle" }}
      />
    </div>
  )}
  body={(row) => {
    const isReturn = row.voucher_type === "sales_return" || row.voucher_type === "credit_note";
    return isReturn ? -row.quantity : row.quantity || "-";
  }}
  
  sortable
  headerStyle={headerCellStyle}
/>

<Column
  field="amount"
  header={() => (
    <div
      className={`custom-header-${sortField === "amount" ? (sortOrder === 1 ? "up" : "down") : ""}`}
      onClick={() => customSort("amount")}
      style={{
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        fontSize: "11px",
        lineHeight: "11px",
      }}
    >
      {i18n("Total Value")}
      <CustomeSortIcon
        sortOrder={sortField === "amount" ? sortOrder : 0}
        style={{ marginLeft: "4px", verticalAlign: "middle" }}
      />
    </div>
  )}
body={(row) => {
    const isReturn = row.voucher_type === "sales_return" || row.voucher_type === "credit_note";
    return (
      <span>
        <GlobalCurrency price={isReturn ? -row.amount : row.amount} />
      </span>
    );
  }}
  sortable
  headerStyle={headerCellStyle}
/>

<Column
  field="taxable_value"
  header={() => (
    <div
      className={`custom-header-${sortField === "taxable_value" ? (sortOrder === 1 ? "up" : "down") : ""}`}
      onClick={() => customSort("taxable_value")}
      style={{
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        fontSize: "11px",
        lineHeight: "11px",
      }}
    >
      {i18n("TAXABLE VALUE")}
      <CustomeSortIcon
        sortOrder={sortField === "taxable_value" ? sortOrder : 0}
        style={{ marginLeft: "4px", verticalAlign: "middle" }}
      />
    </div>
  )}
  body={(row) => {
    const isReturn = row.voucher_type === "sales_return" || row.voucher_type === "credit_note";
    return (
      <span>
        <GlobalCurrency price={isReturn ? -row.taxable_value : row.taxable_value} />
          </span>
  );
  }}
  sortable
  headerStyle={headerCellStyle}
  style={{ width: "120px" }}
/>

<Column
  field="igst"
  header={() => (
    <div
      className={`custom-header-${sortField === "igst" ? (sortOrder === 1 ? "up" : "down") : ""}`}
      onClick={() => customSort("igst")}
      style={{
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        fontSize: "11px",
        lineHeight: "11px",
      }}
    >
      {i18n("IGST")}
      <CustomeSortIcon
        sortOrder={sortField === "igst" ? sortOrder : 0}
        style={{ marginLeft: "4px", verticalAlign: "middle" }}
      />
    </div>
  )}
  body={(row) => {
    const isReturn = row.voucher_type === "sales_return" || row.voucher_type === "credit_note";
    return <GlobalCurrency price={isReturn ? -row.igst : row.igst} />
  }} sortable
  headerStyle={headerCellStyle}
/>

<Column
  field="cgst"
  header={() => (
    <div
      className={`custom-header-${sortField === "cgst" ? (sortOrder === 1 ? "up" : "down") : ""}`}
      onClick={() => customSort("cgst")}
      style={{
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        fontSize: "11px",
        lineHeight: "11px",
      }}
    >
      {i18n("CGST")}
      <CustomeSortIcon
        sortOrder={sortField === "cgst" ? sortOrder : 0}
        style={{ marginLeft: "4px", verticalAlign: "middle" }}
      />
    </div>
  )}
  body={(row) => {
    const isReturn = row.voucher_type === "sales_return" || row.voucher_type === "credit_note";
    return <GlobalCurrency price={isReturn ? -row.cgst : row.cgst} />
  }} sortable
  headerStyle={headerCellStyle}
/>

<Column
  field="sgst"
  header={() => (
    <div
      className={`custom-header-${sortField === "sgst" ? (sortOrder === 1 ? "up" : "down") : ""}`}
      onClick={() => customSort("sgst")}
      style={{
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        fontSize: "11px",
        lineHeight: "11px",
      }}
    >
      {i18n("SGST")}
      <CustomeSortIcon
        sortOrder={sortField === "sgst" ? sortOrder : 0}
        style={{ marginLeft: "4px", verticalAlign: "middle" }}
      />
    </div>
  )}
  body={(row) => {
    const isReturn = row.voucher_type === "sales_return" || row.voucher_type === "credit_note";
    return <GlobalCurrency price={isReturn ? -row.sgst : row.sgst} />
  }}  sortable
  headerStyle={headerCellStyle}
/>

<Column
  field="cess"
  header={() => (
    <div
      className={`custom-header-${sortField === "cess" ? (sortOrder === 1 ? "up" : "down") : ""}`}
      onClick={() => customSort("cess")}
      style={{
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        fontSize: "11px",
        lineHeight: "11px",
      }}
    >
      {i18n("cess")}
      <CustomeSortIcon
        sortOrder={sortField === "cess" ? sortOrder : 0}
        style={{ marginLeft: "4px", verticalAlign: "middle" }}
      />
    </div>
  )}
  body={(row) => {
    const isReturn = row.voucher_type === "sales_return" || row.voucher_type === "credit_note";
    return <GlobalCurrency price={isReturn ? -row.cess : row.cess} />
  }}  sortable
  headerStyle={headerCellStyle}
/>

<Column
  field="tax_total"
  header={() => (
    <div
      className={`custom-header-${sortField === "tax_total" ? (sortOrder === 1 ? "up" : "down") : ""}`}
      onClick={() => customSort("tax_total")}
      style={{
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        fontSize: "11px",
        lineHeight: "11px",
      }}
    >
      {i18n("TOTAL TAX AMOUNT")}
      <CustomeSortIcon
        sortOrder={sortField === "tax_total" ? sortOrder : 0}
        style={{ marginLeft: "4px", verticalAlign: "middle" }}
      />
    </div>
  )}
  body={(row) => {
    const isReturn = row.voucher_type === "sales_return" || row.voucher_type === "credit_note";
    return (
      <span>
        <GlobalCurrency price={isReturn ? -row.tax_total : row.tax_total} />
    </span>
  );
  }}
  sortable
  headerStyle={headerCellStyle}
  style={{ width: "170px" }}
/>

              {/* {status ? (
                <DataGrid
                  allData={reportItems}
                  data={reportItems}
                  columns={gridColumns}
                  isPaginated={true}
                  title="Report"
                />
              ) : (
                <p className="d-flex justify-content-center p-2 fs-3 fw-bolder">
                  {i18n("Error while fetching HSN Wise Sales Summary")}
                </p>
              )} */}
</DataTable>
              ) : (
                <p>{i18n("Loading")}</p>
              )}
            </div>
          </Container>
        </div>
      </Container>
    </Layout>
  );
};

export default HSNWiseSaleSummaryReport;
