import { 
    eachDayOfInterval, 
    startOfMonth,
    endOfMonth,
    addDays,
    format ,
    addMonths,
    subDays,
    subYears
  } from 'date-fns';

  export const Today = () => {
    return [format(new Date(), 'yyyy-MM-dd')];  
  };
  
  export const Yesterday = () => {
    return [format(subDays(new Date(), 1), 'yyyy-MM-dd')];  
  };

export const CurrentMonthDates = () => {
    return eachDayOfInterval({
      start: startOfMonth(new Date()),
      end: endOfMonth(new Date())
    }).map(date => format(date, 'd MMM'));
  }

 export const LastMonthDates = () => {
    const today = new Date();
    const lastMonth = new Date(today.getFullYear(), today.getMonth() - 1, 1);
    const daysInLastMonth = new Date(
      today.getFullYear(),
      today.getMonth(),
      0
    ).getDate();
  
    return Array.from(
      { length: daysInLastMonth },
      (_, index) => format(addDays(lastMonth, index), 'd MMM')
    );
  };

  export const Weekdays = (weekDays) => {
    
      return [ weekDays.s, weekDays.m, weekDays.t, weekDays.w, weekDays.th, weekDays.f, weekDays.st ];
    };

  export const PreviousMonthDates = (months) => {
    const today = new Date();
    const startDate = addMonths(today, -months); // Start date is three months ago from today
    const endDate = new Date(); // End date is today
  
    const startYear = startDate.getFullYear();
    const startMonth = startDate.getMonth();
  
    const names = [];
  
    for (let i = 0; i < months; i++) {
      const currentMonth = startMonth + i;
      const currentYear = startYear + Math.floor(currentMonth / 12); // Adjust year if current month exceeds December
      const monthName = format(new Date(currentYear, currentMonth % 12, 1), 'MMMM');
      names.push(monthName);
    }
  
    return names;
  };


  export const YearOfMonthName = (startYear, startMonth, months) => {
    const names = [];

    for (let i = 0; i < months; i++) {
        const currentMonth = startMonth + i;
        const currentYear = startYear + Math.floor(currentMonth / 12); // Adjust year if current month exceeds December
        const monthName = format(new Date(currentYear, currentMonth % 12, 1), 'MMMM');
        names.push(monthName);
    }

    return names;
};