import React, { useState, useEffect } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Chart from "./Chart";
import ReportPeriod from "../../../../shared/components/ReportPeroid";
import ProductFliter from "./ProductFliter";
import { fetchTopTenPurchaseItem } from "../../../store/purchase_chart/topTenPurchaseItemSlice";


function TopTenPurchaseItemIndex() {
  const dispatch = useDispatch();
  const [reportPeriod, setReportPeriod] = useState("current-week");
  const [labelName, setLabelName] = useState([]);
  const [purchaseAmount, setPurchaseAmount] = useState([]);
  const [productFilter, setProductFilter] = useState("amount");

  useEffect(() => {
    dispatch(fetchTopTenPurchaseItem({reportPeriod,productFilter}));
  }, [reportPeriod,productFilter]);


  const reportItems = useSelector((state) => state.topTenPurchaseItem.reportItems);

  useEffect(() => {
  if (Array.isArray(reportItems)) {
      const productNames = reportItems && reportItems.map(item => item.ProductName);
      setLabelName(productNames);
      const PurchaseAmounts = reportItems && reportItems.map(item => item.purchaseAmount);
      setPurchaseAmount(PurchaseAmounts);
  }
}, [reportPeriod,reportItems]);



  return (
    <Card id="chart" className="border mb-3">
      <Card.Header className="border-0 pt-0"></Card.Header>
      <Card.Body className="py-0">
        <Row className="row px-0">
          <Col xl={7} md={7} sm={12}>
            <Chart 
             labelName= {labelName} 
             purchaseAmount={purchaseAmount}
             reportPeriod={reportPeriod} 
             productFilter={productFilter}
            />
          </Col>
          <Col xl={5} md={5} sm={12} className="">
          <Row className="" style={{ width: "120%" }}>
          <Col xl={6} md={6} sm={12} >
            <ReportPeriod
              reportPeriod={reportPeriod}
              setReportPeriod={setReportPeriod}
            />
            </Col>
            <Col xl={6} md={6} sm={12} className="">
               <ProductFliter 
            productFilter={productFilter} 
            setProductFilter={setProductFilter} 
          />
          </Col>
          </Row>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
}

export default TopTenPurchaseItemIndex;
