import { Button, Col, Form, ListGroup, Stack } from "react-bootstrap";
import { i18n } from "../../../shared/helpers/sharedMethods";
import { useSelector } from "react-redux";

const BankDetails = () => {
  const fetchData = useSelector((state) => state?.getSetting?.dataSetting);
  
  const bankDetailsCheck =
    fetchData?.card_holder_name ||
    fetchData?.account_number ||
    fetchData?.ifsc_code || 
    fetchData?.bank ||
    fetchData?.branch_name;

  return (
    <>
      {bankDetailsCheck && (
        <Stack direction='vertical' className='ms-auto my-4' gap={2}>
          <Form.Label style={{ color: "#4682B4" }}>
            <span className='bold pb-2' >{i18n('BANK DETAILS')} : </span>
          </Form.Label>
          {fetchData?.account_number && (
            <Stack direction="horizontal" gap={2}>
              <p className='text-black-50 bold'>{i18n('Account Number')} : </p>
              <p>{fetchData?.account_number}</p>
            </Stack>
          )}
          {fetchData?.ifsc_code && (
            <Stack direction="horizontal" gap={2}>
              <p className='text-black-50 bold'>{i18n('IFSC Code')} : </p>
              <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{fetchData?.ifsc_code}</p>
            </Stack>
          )}
          {fetchData?.card_holder_name && (
            <Stack direction="horizontal" gap={2}>
              <p className='text-black-50 bold'>{i18n('Holder Name')} : </p>
              <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{fetchData?.card_holder_name}</p>
            </Stack>
          )}
          {fetchData?.bank || fetchData?.branch_name ? (
            <Stack direction="horizontal" gap={2}>
              <p className='text-black-50 bold'>{i18n('Bank')}:</p>
              <p>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                {fetchData?.bank}, {fetchData?.branch_name}
              </p>
            </Stack>
          ) : null}

        </Stack>
      )}
    </>
  );
};
export default BankDetails;
