import {
  createSlice,
  createAsyncThunk,
} from "@reduxjs/toolkit";
import { i18n } from "../../shared/helpers/sharedMethods";
import { axiosRequest } from "../../shared/helpers/axiosHelper";

const initialState = {
  status: false,
  reportItems: [],
  error: false,
  errorMessage: {},
  loading: false,
};

export const fetchTopSellingProducts = createAsyncThunk('topSellingProducts/fetch', async (param) => {
  try {
    const token = param.token;
    const tenantId = param.tenant_id;
    const startDate = param.startDate;
    const endDate = param.endDate;
    const response = await axiosRequest({
      method: "GET",
      url: `top-selling-product`,
      params: {
        start_date: startDate,
        end_date: endDate,
      },
      headers: {
        Authorization: `Bearer ${token}`,
        "X-Tenant": tenantId,
      },
    });
    return response?.data;
  } catch (err) {
    await window.log.logToFile({ message: "Error while fetching Top Selling Product", level: "error" });
    throw new Error("Error while fetching Top Selling Products report")
  }
});

const topSellingProductsSlice = createSlice({
  name: "topSellingProducts",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchTopSellingProducts.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchTopSellingProducts.fulfilled, (state, action) => {
        state.status = true
        state.loading = false
        state.reportItems = action.payload
      })
      .addCase(fetchTopSellingProducts.rejected, (state, action) => {
        state.error = true;
        state.loading = false
        state.errorMessage = `${i18n("Error while fetching Top Selling Products. Please try again later.")}`;
        state.status = false;
      })

  },
});

export default topSellingProductsSlice.reducer;

