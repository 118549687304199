import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';


function ErrorModal(props) {
    const{show,header,handleClose,error} =props
    return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header  closeButton>
          <Modal.Title style={{color:'red'}}>{header}</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{color:'red'}}>{error}</Modal.Body>
        
      </Modal>
    </>
  );
}

export default ErrorModal;