import { Card, Col, Image, Row, Spinner } from "react-bootstrap";
import { UilSetting, UilCheck, UilBag } from "@iconscout/react-unicons";
import bill from "../../../assets/logo/logo6-small.png";
import { environment } from "../../shared/config/environment";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import { resetAccount } from "../store/manageAccountSlice";
import {
  createAppConfig,
  fetchAllTenants,
  logout,
  logoutInServer,
  logOutSyncStatus,
  resetAllStatus,
  resetAuth,
  setCurrentTenantLocal,
  setSelectedBusiness,
} from "../../auth/store/authSlice";
import { resetProduct } from "../../catalog/store/productsSlice";
import { resetSales } from "../../voucher/store/voucherSlice";
import { resetPayment } from "../../payment/store/paymentEntitySlice";
import { resetCategory } from "../../settings/store/categorysSlice";
import { resetCartVoucherPayment } from "../../payment/store/paymentSlice";
import { resetParty } from "../../party/store/getPartySlice";
import { resetDashboard } from "../../dashboard/store/LatestTransactionSlice";
import { resetSubscription } from "../../subscription/store/SubscriptionSlice";
import { resetSubscriptionParty } from "../../subscription/store/LivePartyGetsSlice";
import { resetSubscriptionItem } from "../../subscription/store/subscription_item_store/GetPackagesItemSlice";
import { resetSetting } from "../../settings/store/getSettingSlice";
import { resetEditSetting } from "../../settings/store/settingSlice";
import { resetSubscriptionVoucher } from "../../subscription/store/getAllSubscriptionVoucherSlice";
import {
  initInstall,
  resetInitInstall,
} from "../../auth/store/initInstallSlice";
import { resetEnquiry } from "../../enquiry/store/EnquirySlice";
import { useNavigate } from "react-router";
import { aboutDetails, } from "../../shared/store/sharedSlice";
import LoadingSpinner from '../../shared/components/LoadingSpinner';

const OrganizationDetails = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state?.manageAccount?.userData?.user);
  const error = useSelector((state) => state?.manageAccount?.error);
  const {
    createConfigStatus,
    tenants,
    deviceId,
    businessSelectedStatus,
    setTenantStatus,
  } = useSelector((state) => state?.auth);
  const { backUpStatus } = useSelector((state) => state.auth);
  const accessToken = useSelector(
    (state) => state?.auth?.accessToken?.access_token
  );
  const terminalNumber = useSelector((state) => state?.auth?.terminalNumber);
  const selectedBusiness = useSelector(
    (state) => state?.auth?.selectedBusiness
  );

  const { status } = useSelector((state) => state?.sharedSlice);

  const { terminalId, terminalCode, initInstallStatus, initInstallError, initInstallErrorMessage } = useSelector(
    (state) => state?.initInstall
  );
  ;
  const successMessage = useSelector(
    (state) => state?.manageAccount?.successMessage
  );
  const { syncCount } = useSelector((state) => state?.auth)

  const [selectedUser, setSelectedUser] = useState();
  const [showIframe, setShowIframe] = useState(false);
  const [loading, setLoading] = useState(false);
  const [logOut, setLogOut] = useState(false);
  const url = `${environment.MY_ACC_APP_URL}?id=${user?.id}&name=${encodeURIComponent(
    user?.name
  )}&phone=${user?.phone}&code=${user?.authenticate_code}&currentTenant=${selectedBusiness?.id
    }&email=${user?.email}`;





  useEffect(() => {
    if (successMessage) {
      toast.success(successMessage);
    }
  }, [successMessage]);

  useEffect(() => {
    if (error) {
      toast.error(error);
      dispatch(resetAccount());
    }
  }, [error]);

  const handleDeletion = (deletedTenantId) => {
    if (deletedTenantId?.status) {
      const updateTenant =
        tenants &&
        tenants.length > 0 &&
        tenants.filter((value) => value?.id !== deletedTenantId?.tenantId?.id);

      const defaultId = updateTenant[0];
      setSelectedUser(defaultId);

    }
  };

  useEffect(() => {
    if (selectedUser) {
      const checkTerminalData = {
        user_id: selectedUser?.pivot?.user_id,
        location: selectedUser?.business_address,
        device_id: deviceId,
      };
      dispatch(
        initInstall({
          tenant_id: selectedUser?.pivot?.tenant_id,
          installData: checkTerminalData,
        })
      );
    }

  }, [selectedUser])

  useEffect(() => {
    const handleMessage = (event) => {
      ;
      if (event.data.action == "tenant_modified") {
        dispatch(fetchAllTenants(event.data.userId));
      } else if (event.data.action == "tenant_delete") {
        dispatch(fetchAllTenants(event.data.userId));
        handleDeletion({
          userId: event.data.userId,
          tenantId: event.data?.tenantId,
          status: event.data?.is_active,
        });
      } else if (event.data.action == "tenant_switch") {
        setLoading(true);
        dispatch(resetAllStatus());
        dispatch(resetInitInstall());
        setSelectedUser(event?.data?.tenant_id);
      }
    };

    window.addEventListener("message", handleMessage);

    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);

  useEffect(() => {
    if (initInstallStatus) {
      dispatch(
        setCurrentTenantLocal({
          businessId: selectedUser?.business_id,
          userId: selectedUser?.pivot?.user_id,
        })
      );
    }
  }, [initInstallStatus]);

  useEffect(() => {
    if (setTenantStatus) {
      const appConfigData = {
        terminal_id: terminalId,
        terminal_code: terminalCode,
        token: accessToken,
        tenant_id: selectedUser?.pivot?.tenant_id,
        is_installed: 1,
        user: selectedUser?.pivot?.user_id,
        id: 1,
      };

      dispatch(createAppConfig(appConfigData));
    }
  }, [setTenantStatus, terminalId]);

  useEffect(() => {
    if (initInstallError) {
      let toastErrorMsg = (initInstallErrorMessage != '') ? initInstallErrorMessage : "Failed to login! Access might be forbidden!";
      toast.error(toastErrorMsg);
      dispatch(resetInitInstall());
      dispatch(resetAuth());
      setTimeout(() => {
        navigate(0)
      }, 1000);
    }
  }, [initInstallError])

  useEffect(() => {
    if (createConfigStatus) {
      dispatch(setSelectedBusiness(selectedUser));
    }
  }, [createConfigStatus]);

  useEffect(() => {
    if (businessSelectedStatus) {

      const fetchAppversion = async () => {
        try {
          const appVersion = await window.ele.getVersion();
          const data = {
            isAbout: false,
            version: appVersion,
          };

          dispatch(aboutDetails(data));
        } catch (error) {
          console.error("Failed to get app version:", error);
        }
      }
      fetchAppversion();
    }
  }, [businessSelectedStatus]);

  useEffect(() => {
    if (businessSelectedStatus) {
      dispatch(resetProduct());
      dispatch(resetSales());
      dispatch(resetPayment());
      dispatch(resetCategory());
      dispatch(resetCartVoucherPayment());
      dispatch(resetParty());
      dispatch(resetDashboard());
      dispatch(resetSubscription());
      dispatch(resetSubscriptionParty());
      dispatch(resetSubscriptionItem());
      dispatch(resetAuth());
      dispatch(resetSetting());
      dispatch(resetEditSetting());
      dispatch(resetSubscriptionVoucher());
      dispatch(resetInitInstall());
      dispatch(resetEnquiry());
      dispatch(resetAccount());
      setLoading(false);
      navigate("/dashboard/sales");
    }
  }, [businessSelectedStatus]);

  useEffect(() => {
    if (backUpStatus) {
      dispatch(logout());
      dispatch(resetCategory());
      dispatch(resetProduct());
      dispatch(resetSales());
      dispatch(resetPayment());
      dispatch(resetAuth());
      dispatch(resetInitInstall());
      dispatch(resetCartVoucherPayment());
      dispatch(resetParty());
      dispatch(resetSetting());
      dispatch(resetEditSetting());
      dispatch(resetSubscriptionVoucher());
      dispatch(resetDashboard());
      dispatch(resetSubscription());
      dispatch(resetSubscriptionItem());
      dispatch(resetAccount());
    }
  }, [backUpStatus]);




  useEffect(() => {
    const handleMessage = (event) => {

      if (event.data.action == "logout") {


        dispatch(
          logOutSyncStatus()
        )

        dispatch(resetCategory());
        dispatch(resetProduct());
        dispatch(resetSales());
        dispatch(resetPayment());
        dispatch(resetAuth());
        dispatch(resetInitInstall());
        dispatch(resetCartVoucherPayment());
        dispatch(resetParty());
        dispatch(resetSetting());
        dispatch(resetEditSetting());
        dispatch(resetSubscriptionVoucher());
        dispatch(resetDashboard());
        dispatch(resetSubscription());
        dispatch(resetSubscriptionItem());
        dispatch(resetEnquiry());
        dispatch(resetAccount());
        const logoutData = {
          user_id: selectedBusiness?.pivot?.user_id,
          location: selectedBusiness?.business_address,
          device_id: deviceId,
        };
        dispatch(
          logoutInServer({
            tenant_id: selectedBusiness?.pivot?.tenant_id,
            logoutData,
          })
        );
      }
    }

    window.addEventListener("message", handleMessage);

    return () => {
      window.removeEventListener("message", handleMessage);
    };

  }, [])


  return (
    <Card className=" ">
      <ToastContainer />
      <Row className=" ">
        {!loading && (
          <Col
            lg={12}
            className="  "
          //style={{ height: "70vh" }}
          >
            {user?.authenticate_code && (
              <iframe
                src={url}
                style={{ height: "calc(100vh - 70px)", width: "100%" }}
              ></iframe>
            )}
            {!showIframe && (
              <div
                className="d-flex justify-content-center align-items-center"
                style={{ height: "calc(100vh - 70px)", width: "100%" }}
              >

              </div>
            )}
          </Col>
        )}
        {loading && <LoadingSpinner />}
      </Row>

    </Card>
  );
};

export default OrganizationDetails;
