import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Card,
  Row,
  Table,
  Col,
  Button,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { i18n } from "../../../shared/helpers/sharedMethods";
import GlobalCurrency from "../../../shared/components/GlobalCurrency";
import { fetchHSNReport } from "../../store/HSNSlice";
import {
  FaInfoCircle,
  FaQuestion,
  FaQuestionCircle,
  FaSearch,
} from "react-icons/fa";

const HSN = ({ startDate, endDate }) => {
  const dispatch = useDispatch();
  const accessToken = useSelector((state) => state?.auth?.accessToken?.access_token);
  const selectedBusiness = useSelector((state) => state?.auth?.selectedBusiness);
  const tenantId = selectedBusiness?.pivot?.tenant_id;
  const getTransaction = useSelector(
    (state) => state.HSNSalesReport.reportItems
  );

  useEffect(() => {
    dispatch(fetchHSNReport({startDate: startDate, endDate: endDate,token:accessToken,tenant_id:tenantId})); 
  }, [startDate, endDate]);

  const totalValueSum = getTransaction.reduce((sum, item) => {
    return sum + (item.totalValue || 0);
  }, 0);
  const taxableValue = getTransaction.reduce((sum, item) => {
    return sum + (item.totalTaxableValue || 0);
  }, 0);
  const totalCGST = getTransaction.reduce((sum, item) => {
    return sum + (item.totalCGST || 0);
  }, 0);
  const totalSGST = getTransaction.reduce((sum, item) => {
    return sum + (item.totalSGST || 0);
  }, 0);
  const totalIGST = getTransaction.reduce((sum, item) => {
    return sum + (item.totalIGST || 0);
  }, 0);
  const totalCess = getTransaction.reduce((sum, item) => {
    return sum + (item.totalCess || 0);
  }, 0);

  const toolTip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      HSN wise summary of goods /services supplied during the tax period
    </Tooltip>
  );

  return (
    <Row className="p-5 scrollable-table">
      <Card.Body className="">
        <Row
          className="pb-4 d-flex justify-content-between align-items-center"
          style={{ borderColor: "#39313d" }}
        >
          <Col>
            <div className="d-flex flex-wrap">
              <Button className="px-4 me-4" variant="outline-secondary">
                <span style={{ color: "#898989" }}>
                  No.of HSN: <b>{getTransaction.length}</b>
                </span>
              </Button>
              <Button className="px-4 me-4" variant="outline-secondary">
                <span style={{ color: "#898989" }}>
                  Total Value:{" "}
                  <b>
                    <GlobalCurrency price={totalValueSum} />
                  </b>
                </span>
              </Button>
              <Button className="px-4 me-4" variant="outline-secondary">
                <span style={{ color: "#898989" }}>
                  Total Taxable Value:{" "}
                  <b>
                    <GlobalCurrency price={taxableValue} />
                  </b>
                </span>
              </Button>
              <Button className="px-4 me-4" variant="outline-secondary">
                <span style={{ color: "#898989" }}>
                  Total Integrated Tax:{" "}
                  <b>
                    <GlobalCurrency price={totalIGST} />
                  </b>
                </span>
              </Button>
              <Button className="px-4 me-4" variant="outline-secondary">
                <span style={{ color: "#898989" }}>
                  Total Central Tax:{" "}
                  <b>
                    <GlobalCurrency price={totalCGST} />
                  </b>
                </span>
              </Button>
              <Button className="px-4 me-4" variant="outline-secondary">
                <span style={{ color: "#898989" }}>
                  Total State/UT Tax:{" "}
                  <b>
                    <GlobalCurrency price={totalSGST} />
                  </b>
                </span>
              </Button>
              <Button className="px-4 me-4" variant="outline-secondary">
                <span style={{ color: "#898989" }}>
                  Total Cess:{" "}
                  <b>
                    <GlobalCurrency price={totalCess} />
                  </b>
                </span>
              </Button>
            </div>
          </Col>
          <Col
            xs="auto"
            className="d-flex justify-content-end align-items-center"
          >
            <OverlayTrigger placement="bottom" overlay={toolTip}>
              <Button
                variant="outline-secondary"
                style={{
                  color: "#898989",
                  padding: "9px 8px", 
                  lineHeight: 1, 
                }}
              >
                <FaQuestionCircle size={15} />
              </Button>
            </OverlayTrigger>
          </Col>
        </Row>

        <Table bordered hover responsive>
          <thead>
            <tr>
              <th className="px-2">{i18n("HSN")}</th>
              <th className="px-2">{i18n("Description")}</th>
              <th className="px-2">{i18n("UQC")}</th>
              <th className="px-2">{i18n("Total Quantity")}</th>
              <th className="px-2">{i18n("Total Value")}</th>
              <th className="px-2">{i18n("Rate")}</th>
              <th className="px-2">{i18n("Taxable Value")}</th>
              <th className="px-2">{i18n("Integrated Tax Amount")}</th>
              <th className="px-2">{i18n("Central Tax amount")}</th>
              <th className="px-2">{i18n("Total state/ UT tax")}</th>
              <th className="px-2">{i18n("Cess Amount")}</th>
            </tr>
          </thead>
          <tbody>
            {getTransaction &&
              getTransaction.length > 0 &&
              getTransaction.map((data, index) => (
                <tr key={index}>
                  <td className="p-1 px-2 m-1 mx-3">
                    {data.hsn ? data.hsn : " "}
                  </td>
                  <td className="p-1 px-2 m-1 mx-3">
                    {data?.description ? data?.description : ""}
                  </td>
                  <td className="p-1 px-2 m-1 mx-3">
                    {data.unit ? data.unit : ""}
                  </td>
                  <td className="p-1 px-2 m-1 mx-3">
                    {data.totalQuantity ? data.totalQuantity : ""}
                  </td>
                  <td className="p-1 px-2 m-1 mx-3">
                    <GlobalCurrency
                      price={data.totalValue ? data.totalValue : ""}
                    />
                  </td>
                  <td className="p-1 px-2 m-1 mx-3">
                  {data?.taxRate ? data?.taxRate : ""}
                  </td>
                  <td className="p-1 px-2 m-1 mx-3">
                    <GlobalCurrency
                      price={
                        data.totalTaxableValue ? data.totalTaxableValue : ""
                      }
                    />
                  </td>
                  <td className="p-1 px-2 m-1 mx-3">
                    <GlobalCurrency price={data.totalIGST ? data.totalIGST : ""} />
                  </td>
                  <td className="p-1 px-2 m-1 mx-3">
                    <GlobalCurrency
                      price={data.totalCGST ? data.totalCGST : ""}
                    />
                  </td>
                  <td className="p-1 px-2 m-1 mx-3">
                    <GlobalCurrency
                      price={data.totalSGST ? data.totalSGST : ""}
                    />
                  </td>
                  <td className="p-1 px-2 m-1 mx-3">
                    <GlobalCurrency
                      price={data.totalCess ? data.totalCess : ""}
                    />
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
      </Card.Body>
    </Row>
  );
};

export default HSN;
