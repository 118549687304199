import React from "react";
import { useSelector } from "react-redux";
import { selectVoucherItemById } from "../store/voucherItemsSlice";
import ItemTypeSelect from "./fields/ItemTypeSelect";
import Hsn from "./fields/Hsn";
import Quantity from "./fields/Quantity";
import Discount from "./fields/Discount";
import ItemPrice from "./fields/ItemPrice";
import TaxRateSelect from "./fields/TaxRateSelect";
import ProductTypeAhead from "./fields/ProductTypeAhead";
import Cess from "./fields/Cess";
import LineIndex from "./fields/LineIndex";
import { Stack } from "react-bootstrap";
import GlobalCurrency from "../../shared/components/GlobalCurrency";

const CartItemRow = ({ index, serial, voucherType, cessEnabled, discountEnabled, is_same_state }) => {

  const cartItem = useSelector((state) => selectVoucherItemById(state, index))
  return (
    <tr className="text-center" >
      <LineIndex index={index} serial={serial} item={cartItem} />
      <td colSpan={5} className="" style={{ width: (!discountEnabled && !cessEnabled) ? "28%" : "20%" }}><ProductTypeAhead index={index} item={cartItem} voucherType={voucherType} is_same_state={is_same_state}/></td>
      <td style={{width:"8%"}}><ItemTypeSelect index={index} is_same_state={is_same_state} /></td>
      <td style={{width:"8%"}}><Hsn index={index} item={cartItem} is_same_state={is_same_state} /></td>
      <td><Quantity index={index} item={cartItem} is_same_state={is_same_state} /></td>
      <td>{cartItem && cartItem.unit ? cartItem.unit : ''}</td>
      <td style={{width:"6%"}}><ItemPrice index={index} item={cartItem} is_same_state={is_same_state} /> </td>
      {discountEnabled && (<> <Discount index={index} item={cartItem} discountEnabled={discountEnabled} is_same_state={is_same_state}  /></>)}
      <td><Stack direction="horizontal" className="text-end" gap={3}>
        <TaxRateSelect index={index} item={cartItem}  discountEnabled={ discountEnabled} is_same_state={is_same_state} />
        <> <GlobalCurrency price = {cartItem && cartItem.tax_total ? cartItem.tax_total : ''}/></>
      </Stack>
      </td>

      {cessEnabled && (
        <>
          <Cess index={index} item={cartItem} cessEnabled={cessEnabled} discountEnabled={discountEnabled} is_same_state={is_same_state}/>
        </>
      )}
      <td className="text-end">
        <GlobalCurrency price = {cartItem && cartItem.net_total ? cartItem.net_total : ''} />
      </td>

    </tr>
  );
};

export default CartItemRow;
