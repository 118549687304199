import React, { useState, useEffect } from "react";
import { Button, Row, Col, Stack, Container, Image } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchStockSummary } from "../store/stockSummarySlice";
import Layout from "../../shared/components/Layout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faChevronLeft,
  faDownload,
} from "@fortawesome/free-solid-svg-icons";
import { downloadCsvFile, handleExportClick } from "../../shared/export";
import DataGrid from "../../shared/components/DataGrid";
import Header from "../../shared/components/Header";
import ItemNavbar from "../components/ItemNavbar";
import { i18n } from "../../shared/helpers/sharedMethods";
import ShortcutKeys from "../../shared/components/ShortcutKeys";
import ReportHeader from "../components/ReportHeader";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import CustomeSortIcon from "../../shared/components/CustomeSortIcon";
import { UilImport } from "@iconscout/react-unicons";
import { formatAmount } from "../../pos/helpers/cartHelper";
import LoadingSpinner from '../../shared/components/LoadingSpinner';
import GlobalCurrency from "../../shared/components/GlobalCurrency";

const StockSummary = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const accessToken = useSelector((state) => state?.auth?.accessToken?.access_token);
  const selectedBusiness = useSelector((state) => state?.auth?.selectedBusiness);
  const tenantId = selectedBusiness?.pivot?.tenant_id;
  const [csvData, setCsvData] = useState("");
  const [sortField, setSortField] = useState(null);
  const [sortOrder, setSortOrder] = useState(1);

  useEffect(() => {
    if (accessToken && tenantId) {
      dispatch(fetchStockSummary({ token: accessToken, tenant_id: tenantId }));
    }
  }, [accessToken, tenantId, dispatch]);

  const reportItems = useSelector((state) => state.stockSummary.reportItems);
  const status = useSelector((state) => state.stockSummary.status);
  const loading = useSelector((state) => state.stockSummary.loading);
  const errorMessage = useSelector((state) => state.stockSummary.errorMessage);
  const currencySymbol = useSelector((state) => state.getSetting?.dataSetting.currency);
  ;

  let totalValue = 0;

  if (status && Array.isArray(reportItems)) {
    for (const item of reportItems) {
      totalValue += item.total_cost || 0;
    }
  }
  const dateTimeString = new Date()
    .toLocaleString()
    .replace(/[-]/g, " ")
    .replace(/:/g, "-");
  const fileName = `Stock_summary_${dateTimeString}`;

  const handleExportButtonClick = () => {
    const exportData =
      reportItems &&
      Array.isArray(reportItems) &&
      reportItems.map((val) => ({
        "ITEM NAME": val.name ? val.name : "-",
        "ITEM CODE": val.code ? val.code : "-",
        "PURCHASE AMOUNT": val.cost_price ? val.cost_price : "0",
        "STOCK QUANTITY": val?.quantity ? val?.quantity : "0",
        "UNIT": val.unit ? val.unit : "-",
        "STOCK VALUE": val.total_cost ? val.total_cost : "0",
      }));
    handleExportClick(exportData, setCsvData, downloadCsvFile, fileName);
  };
  const customSort = (field) => {
    setSortField(field);
    setSortOrder(sortOrder === 1 ? -1 : 1);
  };
  const sortedData = [...reportItems].sort((a, b) => {
    if (sortField) {
      const compareValueA = a[sortField];
      const compareValueB = b[sortField];
      if (compareValueA < compareValueB) {
        return sortOrder;
      }
      if (compareValueA > compareValueB) {
        return -sortOrder;
      }
    }
    return 0;
  });
  const headerStyle = {
    backgroundColor: "white",
  };
  const headerCellStyle = {
    fontWeight: "bold",
    fontSize: "11px",
  };

  return (
    <Layout>
      {loading && <LoadingSpinner />}
      <Header
        currentTitle={i18n("Stock Summary")}
        ItemActions={
          <Stack
            gap={3}
            direction="horizontal"
            className="mt-2 mb-2 justify-content-end"
          >
            <ShortcutKeys />
          </Stack>
        }
      />
      <Container fluid className="px-5">
        <div className="border " style={{ background: "white" }}>
          <ItemNavbar />
          <label className="d-flex flex-direction-row pt-3 px-3 fs-3">
            {i18n("Total Stock Value")}&nbsp;:&nbsp;
            <label className="fw-bolder fs-2 px-2">
              {totalValue ? (
                <GlobalCurrency price={totalValue} />
              ) : (
                <GlobalCurrency price={0} />
              )}
            </label>
          </label>
          <ReportHeader
            ItemActions={
              <Stack gap={2} direction="horizontal" className="pt-3 px-3 mb-3">
                <Button
                  className="fw-bolder d-flex align-items-center rounded-0"
                  onClick={handleExportButtonClick}
                >
                  <UilImport
                    size="18"
                    className="mx-2"
                    style={{ cursor: "pointer" }}
                  />
                  {i18n("Export")}
                </Button>
              </Stack>
            }
          />
          <Container fluid className="px-3 mb-4">
            <div className="bordered-container">
              {status && reportItems ? (
                <DataTable
                  value={sortedData}
                  headerStyle={headerStyle}
                  paginator
                  rows={10}
                  dataKey="id"
                  emptyMessage="No Rate List Report found."
                  className="p-datatable-gridlines full-width-table custom-datatable p-component"
                  scrollable
                  scrollHeight="400px"
                  sortMode="multiple"
                  removableSort
                >
                  <Column
                    field="name"
                    header={() => (
                      <div
                        className={`custom-header-${sortField === "name"
                            ? sortOrder === 1
                              ? "up"
                              : "down"
                            : ""
                          }`}
                        onClick={() => customSort("name")}
                        style={{
                          cursor: "pointer",
                          display: "flex",
                          alignItems: "center",
                          fontSize: "11px",
                          lineHeight: "11px",
                        }}
                      >
                        {i18n("ITEM NAME")}
                        <CustomeSortIcon
                          sortOrder={sortField === "name" ? sortOrder : 0}
                          style={{ marginLeft: "4px", verticalAlign: "middle" }}
                        />
                      </div>
                    )}
                    sortable
                    headerStyle={headerCellStyle}
                  />
                  <Column
                    field="code"
                    header={() => (
                      <div
                        className={`custom-header-${sortField === "code"
                            ? sortOrder === 1
                              ? "up"
                              : "down"
                            : ""
                          }`}
                        onClick={() => customSort("code")}
                        style={{
                          cursor: "pointer",
                          display: "flex",
                          alignItems: "center",
                          fontSize: "11px",
                          lineHeight: "11px",
                        }}
                      >
                        {i18n("ITEM CODE")}
                        <CustomeSortIcon
                          sortOrder={sortField === "code" ? sortOrder : 0}
                          style={{ marginLeft: "4px", verticalAlign: "middle" }}
                        />
                      </div>
                    )}
                    sortable
                    headerStyle={headerCellStyle}
                  />

                  <Column
                    field="cost_price"
                    header={() => (
                      <div
                        className={`custom-header-${sortField === "cost_price"
                            ? sortOrder === 1
                              ? "up"
                              : "down"
                            : ""
                          }`}
                        onClick={() => customSort("cost_price")}
                        style={{
                          cursor: "pointer",
                          display: "flex",
                          alignItems: "center",
                          fontSize: "11px",
                          lineHeight: "11px",
                        }}
                      >
                        {i18n("PURCHASE AMOUNT")}
                        <CustomeSortIcon
                          sortOrder={sortField === "cost_price" ? sortOrder : 0}
                          style={{ marginLeft: "4px", verticalAlign: "middle" }}
                        />
                      </div>
                    )}
                    body={(row) => (
                      <span>
                        {row.cost_price ? (
                          <GlobalCurrency price={(row.cost_price)} />
                        ) : (
                          <GlobalCurrency price={0} />
                        )}
                      </span>
                    )}
                    sortable
                    headerStyle={headerCellStyle}
                  />

                  <Column
                    field="sale_price"
                    header={() => (
                      <div
                        className={`custom-header-${sortField === "sale_price"
                            ? sortOrder === 1
                              ? "up"
                              : "down"
                            : ""
                          }`}
                        onClick={() => customSort("sale_price")}
                        style={{
                          cursor: "pointer",
                          display: "flex",
                          alignItems: "center",
                          fontSize: "11px",
                          lineHeight: "11px",
                        }}
                      >
                        {i18n("SELLING PRICE")}
                        <CustomeSortIcon
                          sortOrder={sortField === "sale_price" ? sortOrder : 0}
                          style={{ marginLeft: "4px", verticalAlign: "middle" }}
                        />
                      </div>
                    )}
                    body={(row) => (
                      <span>
                        {row.sale_price ? (
                          <GlobalCurrency price={(row.sale_price)} />
                        ) : (
                          <GlobalCurrency price={0} />
                        )}
                      </span>
                    )}
                    sortable
                    headerStyle={headerCellStyle}
                  />
                  <Column
                    field="quantity"
                    header={() => (
                      <div
                        className={`custom-header-${sortField === "quantity"
                            ? sortOrder === 1
                              ? "up"
                              : "down"
                            : ""
                          }`}
                        onClick={() => customSort("quantity")}
                        style={{
                          cursor: "pointer",
                          display: "flex",
                          alignItems: "center",
                          fontSize: "11px",
                          lineHeight: "11px",
                        }}
                      >
                        {i18n("STOCK QUANTITY")}
                        <CustomeSortIcon
                          sortOrder={sortField === "quantity" ? sortOrder : 0}
                          style={{
                            marginLeft: "4px",
                            verticalAlign: "middle",
                          }}
                        />
                      </div>
                    )}
                    body={(row) =>
                      row.quantity ? <span>{row.quantity}</span> : "-"
                    }
                    sortable
                    headerStyle={headerCellStyle}
                  />
                  <Column
                    field="unit"
                    header={() => (
                      <div
                        className={`custom-header-${sortField === "unit"
                            ? sortOrder === 1
                              ? "up"
                              : "down"
                            : ""
                          }`}
                        onClick={() => customSort("unit")}
                        style={{
                          cursor: "pointer",
                          display: "flex",
                          alignItems: "center",
                          fontSize: "11px",
                          lineHeight: "11px",
                        }}
                      >
                        {i18n("UNIT")}
                        <CustomeSortIcon
                          sortOrder={sortField === "unit" ? sortOrder : 0}
                          style={{ marginLeft: "4px", verticalAlign: "middle" }}
                        />
                      </div>
                    )}
                    body={(row) => (row.unit ? <span>{row.unit}</span> : "-")}
                    sortable
                    headerStyle={headerCellStyle}
                  />
                  <Column
                    field="total_cost"
                    header={() => (
                      <div
                        className={`custom-header-${sortField === "total_cost"
                            ? sortOrder === 1
                              ? "up"
                              : "down"
                            : ""
                          }`}
                        onClick={() => customSort("total_cost")}
                        style={{
                          cursor: "pointer",
                          display: "flex",
                          alignItems: "center",
                          fontSize: "11px",
                          lineHeight: "11px",
                        }}
                      >
                        {i18n("STOCK VALUE")}
                        <CustomeSortIcon
                          sortOrder={sortField === "total_cost" ? sortOrder : 0}
                          style={{ marginLeft: "4px", verticalAlign: "middle" }}
                        />
                      </div>
                    )}
                    body={(row) => (
                      <span>
                        {row.total_cost ? (
                          <GlobalCurrency price={(row.total_cost)} />
                        ) : (
                          <GlobalCurrency price={0} />
                        )}
                      </span>
                    )}
                    sortable
                    headerStyle={headerCellStyle}
                  />
                </DataTable>
              ) : (
                <p className="d-flex justify-content-center p-2 fs-3 fw-bolder">
                  {i18n("Error while fetching Stock summary report")}
                </p>
              )}
            </div>
          </Container>
        </div>
      </Container>
    </Layout>
  );
};

export default StockSummary;
