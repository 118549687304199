import {
    createSlice,
    createAsyncThunk,
  } from "@reduxjs/toolkit";
import { axiosRequest } from "../../shared/helpers/axiosHelper";


const initialState = {
  status:false,
  reportItems:[],
  error:false,
  errorMessage:'',
  loading:false,
};

export const fetchHSNWiseSaleSummary = createAsyncThunk(
    "hsnWiseSaleSummary/fetch",
    async ({ token, tenant_id, startDate, endDate }) => {
      try{
      const hsnWiseSaleSummaryResponse = await axiosRequest({
        method: "GET",
        url: `get-hsn-wise-sale-summary-report`,
        params: { startDate, endDate },
        headers: { Authorization: `Bearer ${token}`, "X-Tenant": tenant_id },
      });
      return hsnWiseSaleSummaryResponse.data;
    }catch(err){
      await window.log.logToFile({message: "Error while fetching HSN wise sale summary", level: "error" });
      throw new Error("Error while fetching hsn wise sales report")
    }
    }
  );

const hsnWiseSaleSummarySlice = createSlice({
    name: "hsnWiseSaleSummary",
    initialState,
    reducers: {},
    extraReducers(builder) {
      builder
      .addCase(fetchHSNWiseSaleSummary.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchHSNWiseSaleSummary.fulfilled, (state, action) => {
        state.status = true;
        state.loading = false;
        state.reportItems = action.payload;
      })
      .addCase(fetchHSNWiseSaleSummary.rejected, (state, action) => {
          state.error = true; 
          state.loading = false;
          state.errorMessage = action.error.message
          state.status = false;
      })
    },
});

export default hsnWiseSaleSummarySlice.reducer;
