import React, { useState, useEffect } from "react";
import { Container, Row, Col, Table, Form, Image } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { ToWords } from "to-words";
import { i18n } from "../../../../shared/helpers/sharedMethods";
import {
  getSubscriptionVoucher,
  selectSubscriptionVoucherById,
} from "../../../store/getAllSubscriptionVoucherSlice";
import { calculateTotalQuantity } from "../../../../shared/helpers/invoiceHelper";
import { placeOfSupplyList } from "../../../../shared/config/placeOfSupply";
import QRCode from "react-qr-code";
import { selectSelectedImage } from "../../../../settings/store/settingSlice";
import { format } from "date-fns";
import { formatAmount } from "../../../../pos/helpers/cartHelper";
import GlobalCurrency from "../../../../shared/components/GlobalCurrency";
import logo from "../../../../.././assets/images/bill.png";

const SubscriptionAllInvoiceGST = ({ pdf, id, data }) => {
  const dispatch = useDispatch();
  const selectedBusiness = useSelector(
    (state) => state?.auth?.selectedBusiness
  );
  const accessToken = useSelector(
    (state) => state?.auth?.accessToken?.access_token
  );
  const tenantId = selectedBusiness?.pivot?.tenant_id;

  const subData = useSelector(
    (state) => state.subscriptionVoucher.subscriptionVoucherById
  );
  const [subsData, setSubsData] = useState(subData);
  const updateStatus = useSelector(
    (state) => state.subscriptionReceivedAmount.status
  );
console.log("subsdata1", subsData)
  useEffect(() => {
    if (subData) {
      setSubsData(subData[0]);
    }
  }, [subData]);

  useEffect(
    () => {
      dispatch(
        getSubscriptionVoucher({
          id: id,
          tenantId: tenantId,
          token: accessToken,
        })
      );
    },
    { id, updateStatus }
  );

  const totalQuantity = calculateTotalQuantity(subsData?.voucher_items);
  const grandTotal = formatAmount(subsData?.grand_total) ?? 0;
  const words = new ToWords().convert(grandTotal, { currency: true });
  const subscriptionTotal = subsData?.voucher_subscription?.subscription;
  const currencySymbol = useSelector(
    (state) => state.getSetting?.dataSetting.currency
  );
  const termsAndCondition = useSelector(
    (state) => state.setting?.billing_settings?.terms_and_conditions
  );
  const fetchData = useSelector((state) => state.getSetting?.dataSetting);
  const selectImage = useSelector(selectSelectedImage);
  const bankDetailsCheck =
    fetchData?.card_holder_name ||
    fetchData?.account_number ||
    fetchData?.ifsc_code ||
    fetchData?.bank ||
    fetchData?.branch_name;

  const customerState = placeOfSupplyList.find(
    (option) => option.value == subsData?.party?.party_addresses?.[0]?.state
  );
  const generateQRCode = (UPIPayment) => {
    const upiLink = `upi://pay?pa=${UPIPayment}`;
    return (
      <QRCode
        value={upiLink}
        size={90}
        bgColor={"#ffffff"}
        fgColor={"#000000"}
        level={"L"}
      />
    );
  };

  const state = placeOfSupplyList.find(
    (option) => option.value == fetchData?.state
  );
  return (
    <>
      <Container
        className="pb-5"
        fluid
        style={{ backgroundColor: "white" }}
        ref={pdf}
      >
        <Row>
          <Col>
            <Row>
              <Col className="border-bottom p-2 m-0">
                <p style={{ fontWeight: "bolder" }}>
                  {i18n("TAX INVOICE")}
                  <span
                    className="border border-secondary px-2 mx-2"
                    style={{ fontWeight: 500 }}
                  >
                    {i18n("ORIGINAL FOR RECIPIENT")}
                  </span>
                </p>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col lg={6} className="border-left border-bottom p-2 m-0">
            <Row>
              <Col
                lg={4}
                className="d-flex align-items-center justify-content-center"
              >
                <img
                  src={selectImage ? selectImage : logo}
                  width={100}
                  height={80}
                  alt="Logo"
                  style={{
                    objectFit: "contain",
                  }}
                />
              </Col>
              <Col lg={8}>
                <Row className="mt-3">
                  <Row>
                    <Col lg={12}>
                      <Form.Label
                        className=""
                        style={{ fontSize: "22px", fontWeight: 600 }}
                      >
                        {fetchData?.business_name}
                      </Form.Label>
                      <Form.Group>
                        <Form.Label className="mb-0">
                          {fetchData?.business_address
                            ? fetchData?.business_address + ","
                            : ""}
                          &nbsp; {fetchData?.city ? fetchData?.city + "," : ""}
                          &nbsp; {state?.label ? state?.label + "-" : ""}
                          &nbsp;&nbsp; {fetchData?.pincode}
                        </Form.Label>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    {fetchData?.business_phone && (
                      <Col lg={12}>
                        <Form.Label className="mb-0">
                          {i18n("Phone")} :{" "}
                        </Form.Label>{" "}
                        &nbsp;{" "}
                        <Form.Label className="mb-0">
                          {" "}
                          {fetchData?.business_phone
                            ? fetchData?.business_phone
                            : ""}
                        </Form.Label>
                      </Col>
                    )}
                  </Row>
                  <Row>
                    {fetchData?.business_phone && (
                      <Col lg={12}>
                        <Form.Label className="mb-0">
                          {i18n("GSTIN")} :
                        </Form.Label>{" "}
                        <Form.Label className="mb-0">
                          {" "}
                          &nbsp; {fetchData?.gstin ? fetchData?.gstin : ""}
                        </Form.Label>
                      </Col>
                    )}
                    {fetchData?.business_phone && (
                      <Col lg={12}>
                        <Form.Label className="mb-0">
                          {i18n("PAN Number")} :{" "}
                        </Form.Label>{" "}
                        &nbsp; {fetchData?.pan ? fetchData?.pan : ""}
                      </Col>
                    )}
                  </Row>
                </Row>
              </Col>
            </Row>
          </Col>
          <Col
            lg={6}
            className="border-right border-left border-bottom p-2 m-0"
          >
            <Row className="mt-1" style={{ fontSize: "14px" }}>
              <Col lg={4} style={{ flex: 1 }}>
                <Form.Label
                  className="mb-0"
                  style={{ fontSize: "13px", fontWeight: 600 }}
                >
                  {i18n("Invoice No")}
                </Form.Label>
              </Col>
              <Col lg={4} style={{ flex: 1 }}>
                <Form.Label
                  className="mb-0"
                  style={{ fontSize: "13px", fontWeight: 600 }}
                >
                  {i18n("Subscription No")}
                </Form.Label>
              </Col>
            </Row>

            <Row className="mb-3">
              <Col lg={4} style={{ flex: 1 }}>
                {subsData?.voucher_number}
              </Col>
              <Col lg={4} style={{ flex: 1 }}>
                {
                  subsData?.voucher_subscription?.subscription
                    ?.subscription_number
                }
              </Col>
            </Row>

            <Row className="mb-0">
              <Col lg={4} style={{ flex: 1 }}>
                <Form.Label
                  className="mb-0"
                  style={{ fontSize: "13px", fontWeight: 600 }}
                >
                  {i18n("Invoice Date")}
                </Form.Label>
              </Col>
              {subsData?.voucher_subscription?.bill_date && (
                <Col lg={4} style={{ flex: 1 }}>
                  <Form.Label
                    className="mb-0"
                    style={{ fontSize: "13px", fontWeight: 600 }}
                  >
                    {i18n("Due Date")}
                  </Form.Label>
                </Col>
              )}
            </Row>

            <Row>
              <Col lg={4} style={{ flex: 1 }}>
                {subsData?.voucher_date
                  ? format(new Date(subsData?.voucher_date), "dd-MM-yyyy")
                  : "-"}
              </Col>
              {subsData?.voucher_subscription?.bill_date && (
                <Col lg={4} style={{ flex: 1 }}>
                  {subsData?.voucher_subscription?.bill_date
                    ? format(
                      new Date(subsData?.voucher_subscription?.bill_date),
                      "dd-MM-yyyy"
                    )
                    : "-"}
                </Col>
              )}
            </Row>
          </Col>
        </Row>
        <Row>
          <Col md={subsData?.voucher_subscription?.subscription?.member ? 5 : 6} className="border-left p-2 m-0">
            <Row>
              <Col
                style={{
                  fontSize: "13px",
                  fontWeight: "500",
                  marginBottom: "5px",
                }}
              >
                {i18n("BILL TO")}
              </Col>
            </Row>
            <Row>
              <Col style={{ fontWeight: "600", fontSize: "14px" }}>
                {subsData?.party?.name ? subsData?.party?.name : "-"}
              </Col>
            </Row>
            <Row>
              <Col>
                {subsData?.party?.party_addresses?.[0]?.address && (
                  <Form.Group>
                    <Form.Label className="mb-0">
                      {subsData?.party?.party_addresses?.[0]?.address}, &nbsp;{" "}
                      {subsData?.party?.party_addresses?.[0]?.city}, &nbsp;{" "}
                      {customerState?.label} &nbsp;- &nbsp;{" "}
                      {subsData?.party?.party_addresses?.[0]?.pincode}
                    </Form.Label>
                  </Form.Group>
                )}
              </Col>
            </Row>
            <Row>
              <Col>
                {subsData?.party?.phone_number && (
                  <Form.Group>
                    <Form.Label className="mb-0">{i18n("Phone")} :</Form.Label>
                    &nbsp; {subsData?.party?.phone_number}
                  </Form.Group>
                )}
              </Col>
            </Row>
            <Row>
              <Col>
                {subsData?.party?.gstin && (
                  <Form.Group className="">
                    <Form.Label className="mb-0">{i18n("GSTIN")} :</Form.Label>
                    &nbsp; {subsData?.party?.gstin}
                  </Form.Group>
                )}
              </Col>
            </Row>
            <Row>
              <Col>
                {subsData?.party?.pan && (
                  <Form.Group>
                    <Form.Label className="mb-0">
                      {i18n("PAN Number")} :
                    </Form.Label>
                    &nbsp; {subsData?.party?.pan}
                  </Form.Group>
                )}
              </Col>
            </Row>
          </Col>
          <Col md={subsData?.voucher_subscription?.subscription?.member ? 5 : 6} 
           className={subsData?.voucher_subscription?.subscription?.member ? "border-left p-2 m-0" : "border-right border-left p-2 m-0"}>
            <Row>
              <Col
                style={{
                  fontSize: "13px",
                  fontWeight: "500",
                  marginBottom: "5px",
                }}
              >
                {i18n("SHIP TO")}
              </Col>
            </Row>
            <Row>
              <Col style={{ fontWeight: "600", fontSize: "14px" }}>
                {subsData?.party?.name ? subsData?.party?.name : "-"}
              </Col>
            </Row>
            <Row>
              <Col>
                {subsData?.party?.party_addresses?.[0]?.address && (
                  <Form.Group>
                    <Form.Label className="mb-0">
                      {subsData?.party?.party_addresses?.[0]?.address}, &nbsp;{" "}
                      {subsData?.party?.party_addresses?.[0]?.city}, &nbsp;{" "}
                      {customerState?.label} &nbsp;- &nbsp;{" "}
                      {subsData?.party?.party_addresses?.[0]?.pincode}
                    </Form.Label>
                  </Form.Group>
                )}
              </Col>
            </Row>
            <Row>
              <Col>
                {subsData?.party?.phone_number && (
                  <Form.Group>
                    <Form.Label className="mb-0">{i18n("Phone")} :</Form.Label>
                    &nbsp; {subsData?.party?.phone_number}
                  </Form.Group>
                )}
              </Col>
            </Row>
          </Col>
          {subsData?.voucher_subscription?.subscription?.member && (
            <Col className="border-right border-left p-2 m-0">
              <Row>
                  <Col
                    style={{
                      fontSize: "13px",
                      fontWeight: "500",
                      marginBottom: "5px",
                    }}
                  >
                    {i18n("Member")}
                  </Col>
              </Row>
              <Row>
                  <Col style={{ fontWeight: "600", fontSize: "14px" }}>
                    {subsData?.voucher_subscription?.subscription?.member?.member_name ? subsData?.voucher_subscription?.subscription?.member?.member_name : "-"}
                  </Col>
              </Row>
              <Row>
                <Col>
                    <Form.Group>
                      <Form.Label className="mb-0">{i18n("Phone")} :</Form.Label>
                      &nbsp; {subsData?.voucher_subscription?.subscription?.member?.mobile }
                    </Form.Group>
                </Col>
              </Row>
            </Col>
          )}
        </Row>
        <Row>
          <Col className="border-col p-0 m-0">
            <Table className="table table-borderless">
              <thead>
                <tr style={{ textAlign: "center" }}>
                  <th className="table-border-head">{i18n("S.No")}</th>
                  <th className="table-border-head">{i18n("ITEMS")}</th>
                  <th className="table-border-head">{i18n("QTY")}</th>
                  <th className="table-border-head">{i18n("RATE")}</th>
                  {subsData?.discount_amount > 0 && (
                    <th className="table-border-head">{i18n("DISC")}</th>
                  )}
                  {subsData?.tax_total > 0 && (
                    <th className="table-border-head">{i18n("TAX")}</th>
                  )}
                   {subsData?.cess_total > 0 && (
                    <th className="table-border-head">{i18n("CESS")}</th>
                  )}
                  <th className="table-border-last">{i18n("AMOUNT")}</th>
                </tr>
              </thead>
              <tbody>
                {subsData?.voucher_items &&
                  subsData?.voucher_items?.length > 0 &&
                  subsData?.voucher_items.map((data, index) => (
                    <tr key={index} style={{ textAlign: "center" }}>
                      <td className="border-black">{index + 1}</td>
                      <td className="border-black">{data?.product_name}</td>
                      <td className="border-black">
                        {data?.quantity} {data?.unit}
                      </td>
                      <td className="border-black">
                        <GlobalCurrency price={data?.taxable_value} />
                      </td>
                      {subsData?.discount_amount  > 0 && (
                        <td
                          className="border-black"
                          style={{ fontSize: "13px", fontWeight: 400,  textAlign: "right", }}
                        >
                          <GlobalCurrency price={data?.item_discount_amount} />
                          <span style={{ fontSize: "13px", color: "grey" }}>
                            &nbsp;
                            {data?.item_discount
                              ? `(${data?.item_discount}%)`
                              : "(0%)"}
                          </span>
                        </td>
                      )}
                      {subsData?.tax_total > 0 && (
                      <td
                        className="border-black"
                        style={{ fontSize: "13px", fontWeight: 400, textAlign: "right", }}
                      >
                        <GlobalCurrency price={data?.tax_total} />
                        <span style={{ fontSize: "13px", color: "grey" }}>
                          &nbsp;
                          {data?.tax_rate ? `(${data?.tax_rate}%)` : "(0%)"}
                        </span>
                      </td>
                      )}
                       {subsData?.cess_total  > 0 && (
                        <td
                          className="border-black"
                          style={{ fontSize: "13px", fontWeight: 400, textAlign: "right", }}
                        >
                          <GlobalCurrency price={data?.cess} />
                          <span style={{ fontSize: "13px", color: "grey" }}>
                            &nbsp;
                            {data?.cess_rate ? `(${data?.cess_rate}%)` : "(0%)"}
                          </span>
                        </td>
                       )}
                      <td style={{ fontSize: "13px", fontWeight: 400, textAlign: "right",}}>
                        <GlobalCurrency price={data?.net_total} />
                      </td>
                    </tr>
                  ))}
              </tbody>
              <tfoot>
                <tr>
                  <td className="border-black">&nbsp;</td>
                  <td className="border-black"></td>
                  <td className="border-black"></td>
                  <td className="border-black"></td>
                  {subsData?.discount_amount > 0 && (
                    <td className="border-black"></td>
                  )}
                  {subsData?.tax_total > 0 && (
                    <td className="border-black"></td>
                  )}
                  {subsData?.cess_total > 0 && (
                    <td className="border-black"></td>
                  )}
                   <td></td>
                </tr>
                <tr>
                  <td className="border-black">&nbsp;</td>
                  <td className="border-black"></td>
                  <td className="border-black"></td>
                  <td className="border-black"></td>
                  {subsData?.discount_amount > 0 && (
                    <td className="border-black"></td>
                  )}
                  {subsData?.tax_total > 0 && (
                    <td className="border-black"></td>
                  )}
                  {subsData?.cess_total > 0 && (
                    <td className="border-black"></td>
                  )}
                  <td></td>
                </tr>
                <tr>
                  <td className="border-black">&nbsp;</td>
                  <td className="border-black"></td>
                  <td className="border-black"></td>
                  <td className="border-black"></td>
                  {subsData?.discount_amount > 0 && (
                    <td className="border-black"></td>
                  )}
                  {subsData?.tax_total > 0 && (
                    <td className="border-black"></td>
                  )}
                  {subsData?.cess_total > 0 && (
                    <td className="border-black"></td>
                  )}
                  <td></td>
                </tr>
                <tr>
                  <td className="border-black">&nbsp;</td>
                  <td className="border-black"></td>
                  <td className="border-black"></td>
                  <td className="border-black"></td>
                  {subsData?.discount_amount > 0 && (
                    <td className="border-black"></td>
                  )}
                  {subsData?.tax_total > 0 && (
                    <td className="border-black"></td>
                  )}
                  {subsData?.cess_total > 0 && (
                    <td className="border-black"></td>
                  )}
                  <td></td>
                </tr>
                <tr>
                  <td className="border-black">&nbsp;</td>
                  <td className="border-black"></td>
                  <td className="border-black"></td>
                  <td className="border-black"></td>
                  {subsData?.discount_amount > 0 && (
                    <td className="border-black"></td>
                  )}
                  {subsData?.tax_total > 0 && (
                    <td className="border-black"></td>
                  )}
                  {subsData?.cess_total > 0 && (
                    <td className="border-black"></td>
                  )}
                  <td></td>
                </tr>
                {subsData?.charges &&
                  subsData?.charges.length > 0 &&
                  subsData?.charges.map((data, index) => (
                    <tr key={index}>
                      <td className="border-black px-1"></td>
                      <td
                        className="border-black bold px-3"
                        align="right"
                        style={{ fontSize: "13px" }}
                      >
                        <i>{data?.charge_type}</i>
                      </td>
                      <td className="border-black"></td>
                      <td className="border-black"></td>
                      <td className="border-black"></td>

                      <td>
                        <GlobalCurrency price={data?.charge_value} />
                      </td>
                    </tr>
                  ))}
                {subsData &&
                  subsData?.voucher_items &&
                  subsData?.voucher_items?.length > 0 &&
                  subsData?.voucher_items?.map((taxRow, index) => (
                    <React.Fragment key={index}>
                      <tr>
                        <td className="border-black">&nbsp;</td>
                        
                        <td align="right" className="border-black px-3">
                          <i>
                            {i18n("CGST")} @{taxRow?.tax_rate / 2}%
                          </i>
                        </td>
                        <td className="border-black"></td>
                        <td className="border-black"></td>
                        <td className="border-black"></td>
                        {subsData?.discount_amount > 0 && <td className="border-black" style={{ textAlign: "right" }}>-</td>}
                        {subsData?.tax_total > 0 && <td className="border-black" style={{ textAlign: "right" }}>-</td>}

                        <td style={{ textAlign: "right" }}>
                          <GlobalCurrency price={taxRow?.cgst} />
                        </td>
                      </tr>
                      <tr>
                        <td className="border-black">&nbsp;</td>
                        <td align="right" className="border-black px-3">
                          <i>
                            {i18n("SGST")} @{taxRow?.tax_rate / 2}%
                          </i>
                        </td>
                        <td className="border-black"></td>
                        <td className="border-black"></td>
                        <td className="border-black"></td>
                        {subsData?.discount_amount > 0 && <td className="border-black" style={{ textAlign: "right" }}>-</td>}
                      {subsData?.tax_total > 0 && <td className="border-black" style={{ textAlign: "right" }}>-</td>}

                        <td style={{ textAlign: "right" }}>
                          <GlobalCurrency price={taxRow?.sgst} />
                        </td>
                      </tr>
                    </React.Fragment>
                  ))}
                  {subsData?.voucher_items?.map((taxRow, index) => {
                  if (taxRow.cess > 0) {
                    return (
                      <React.Fragment key={index}>
                        <tr>
                          <td className="border-black">&nbsp;</td>
                          <td align="right" className="border-black px-3">
                            <i>
                              {i18n("Cess")} @{taxRow.cess_rate}%
                            </i>
                          </td>
                          <td className="border-black"></td>
                          <td className="border-black"></td>
                          <td className="border-black"></td>
                          {subsData?.discount_amount > 0 && <td className="border-black" style={{ textAlign: "right" }}>-</td>}
                          {subsData?.tax_total > 0 && <td className="border-black" style={{ textAlign: "right" }}>-</td>}

                          <td className="" style={{ textAlign: "right" }}>
                            <GlobalCurrency price={taxRow.cess / 2} />

                          </td>
                        </tr>
                      </React.Fragment>
                    )
                  }
                })}
                <tr>
                  <td
                    className="table-border-head"
                    style={{ background: "#F8F9FA" }}
                  ></td>
                  <td
                    align="right"
                    className="table-border-head bold px-3"
                    style={{ background: "#F8F9FA" }}
                  >
                    {i18n("TOTAL")}
                  </td>
                  <td
                    className="table-border-head bold"
                    style={{ background: "#F8F9FA", textAlign: "center" }}
                  >
                    {totalQuantity}
                  </td>

                  <td
                    className="table-border-head"
                    style={{ background: "#F8F9FA" }}
                  ></td>
                   {subsData?.discount_amount > 0 && (
                    <td
                      className="table-border-head bold"
                      style={{ background: "#F8F9FA", textAlign: "right" }}
                    >
                      <GlobalCurrency price={subscriptionTotal?.discount_amount} />
                    </td>
                  )}
                  {subsData?.tax_total > 0 && (
                    <td
                      className="table-border-head bold"
                      style={{ background: "#F8F9FA", textAlign: "right" }}
                    >
                      <GlobalCurrency price={subscriptionTotal?.tax_total} />
                    </td>
                  )}
                  {subsData?.cess_total > 0 && (
                    <td
                      className="table-border-head bold"
                      style={{ background: "#F8F9FA", textAlign: "right" }}
                    >
                      <GlobalCurrency price={subscriptionTotal?.cess_total} />
                    </td>
                  )}

                  <td
                    className="table-border-last bold"
                    style={{ background: "#F8F9FA", textAlign: "right" }}
                  >
                    <GlobalCurrency price={subscriptionTotal?.grand_total} />
                  </td>
                </tr>
              </tfoot>
            </Table>
          </Col>
        </Row>
        <Row>
          <Col className="border-col p-0 m-0">
            <Table className="table table-borderless mt-2">
              <thead>
                <tr align="center">
                  <th className="table-border-head" rowSpan="2">
                    {i18n("HSN/SAC")}
                  </th>
                  <th className="table-border-head" rowSpan="2">
                    {i18n("TAXABLE VALUE")}
                  </th>
                  <th className="table-border-head" colSpan="2">
                    {i18n("CGST")}
                  </th>
                  <th className="table-border-head" colSpan="2">
                    {i18n("SGST")}
                  </th>
                  <th className="table-border-last" rowSpan="2">
                    {i18n("TOTAL TAX AMOUNT")}
                  </th>
                </tr>
                <tr align="center">
                  <th className="border-tax">{i18n("RATE")}</th>
                  <th className="border-tax">{i18n("AMOUNT")}</th>
                  <th className="border-tax">{i18n("RATE")}</th>
                  <th className="border-tax">{i18n("AMOUNT")}</th>
                </tr>
              </thead>
              <tbody>
                {subsData?.voucher_items &&
                  subsData?.voucher_items?.length > 0 &&
                  subsData?.voucher_items.map((data, index) => (
                    <tr
                      align="right"
                      className="p-2"
                      key={index}
                      style={{
                        borderBottom: "1px solid black",
                        textAlign: "center",
                      }}
                    >
                      <td className="border-tax">
                        {data?.hsn ? data?.hsn : "-"}
                      </td>
                      <td className="border-tax">
                        <GlobalCurrency price={data?.taxable_value} />
                      </td>
                      <td className="border-tax">
                        {data?.tax_rate ? `${data.tax_rate / 2}%` : "-"}
                      </td>
                      <td className="border-tax">
                        <GlobalCurrency price={data?.cgst} />
                      </td>
                      <td className="border-tax">
                        {data?.tax_rate ? `${data.tax_rate / 2}%` : "-"}
                      </td>
                      <td className="border-tax">
                        <GlobalCurrency price={data?.sgst} />
                      </td>
                      <td
                        className="border-bottom"
                        style={{ textAlign: "center" }}
                      >
                        <GlobalCurrency price={data?.tax_total} />
                      </td>
                    </tr>
                  ))}
              </tbody>
              <tfoot>
                <tr
                  align="right"
                  className="p-2"
                  style={{ borderBottom: "1px solid black" }}
                >
                  <td
                    className="border-for-received bold"
                    style={{ textAlign: "center" }}
                  >
                    {i18n("Total")}
                  </td>
                  <td className="border-for-received"></td>
                  <td className="border-for-received"> </td>
                  <td className="border-for-received"></td>
                  <td className="border-for-received"> </td>
                  <td className="border-for-received"></td>
                  <td
                    className="border-bottom bold"
                    style={{ textAlign: "center" }}
                  >
                    <GlobalCurrency price={subsData?.tax_total} />
                  </td>
                </tr>
              </tfoot>
            </Table>
          </Col>
        </Row>
        <Row>
          <Col lg={12} className="border-col border-bottom p-2 m-0">
            <b>{i18n("TOTAL AMOUNT (IN WORDS)")}</b> <br />
            {words ? `${words.toUpperCase()}` : "ZERO RUPEES"}
          </Col>
        </Row>
        <Row
          style={{
            borderRight: "1px solid black",
            borderBottom:
              fetchData?.upi_payment || bankDetailsCheck
                ? "1px solid black"
                : "",
            borderLeft: "1px solid black",
          }}
        >
          {bankDetailsCheck && (
            <Col lg={6} className=" p-2 mb-0">
              <Form.Group>
                <Form.Label
                  className=""
                  style={{ fontSize: "13px", fontWeight: 600 }}
                >
                  {i18n("BANK DETAILS")}
                </Form.Label>{" "}
                <br />
                {fetchData?.card_holder_name && (
                  <Form.Label className="mb-1">
                    {i18n("Name")}:
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    {fetchData?.card_holder_name}{" "}
                  </Form.Label>
                )}
                <br />
                {fetchData?.ifsc_code && (
                  <Form.Label className="mb-1">
                    {i18n("IFSC Code")}:
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    {fetchData?.ifsc_code}{" "}
                  </Form.Label>
                )}
                <br />
                {fetchData?.account_number && (
                  <Form.Label className="mb-1">
                    {i18n("Account No")}: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    {fetchData?.account_number}{" "}
                  </Form.Label>
                )}
                <br />
                {fetchData?.bank || fetchData?.branch_name ? (
                  <Form.Label className="mb-1">
                    {i18n("Bank")}:
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    {fetchData?.bank}, {fetchData?.branch_name}{" "}
                  </Form.Label>
                ) : (
                  ""
                )}
                <br />
              </Form.Group>
            </Col>
          )}

          {fetchData?.upi_payment && (
            <Col
              lg={6}
              className=" p-2 mb-0"
              style={{
                borderLeft: bankDetailsCheck ? "1px solid black" : "",
              }}
            >
              <Row>
                <Col lg={6} className="pr-lg-2">
                  <Form.Group>
                    <Form.Label style={{ fontSize: "13px", fontWeight: 600 }}>
                      {i18n("PAYMENT QR CODE")}
                    </Form.Label>
                    <p>
                      {i18n("UPI ID")}:<br />
                      {fetchData?.upi_payment}
                    </p>
                  </Form.Group>
                </Col>
                <Col lg={6} className="pl-lg-2 px-16">
                  {fetchData?.upi_payment &&
                    generateQRCode(fetchData?.upi_payment)}
                </Col>
              </Row>
            </Col>
          )}
        </Row>
        <Row
          className=""
          style={{
            borderBottom: "1px solid black",
            borderLeft: "1px solid black",
            borderRight: "1px solid black",
          }}
        >
          {subsData?.tnc ||
            (termsAndCondition && (
              <Col lg={6} className=" p-2">
                <Form.Label
                  className="mb-0"
                  style={{ fontSize: "13px", fontWeight: 600 }}
                >
                  {i18n("TERMS AND CONDITIONS")}
                </Form.Label>{" "}
                <br />
                <p>{subsData?.tnc ? subsData?.tnc : termsAndCondition} </p>
              </Col>
            ))}
          <Col
            lg={6}
            className=" p-2"
            style={{
              borderLeft:
                subsData?.tnc || termsAndCondition ? "1px solid black" : "",
            }}
          >
            <p
              style={{
                fontSize: "13px",
                fontWeight: 600,
                textAlign:
                  subsData?.tnc || termsAndCondition ? "start" : "start",
              }}
            >
              {i18n("Authorized Signatory For")}
              <br />
              <Form.Label className="mb-0" style={{ fontSize: "13px" }}>
                {selectedBusiness?.business_name}
              </Form.Label>
            </p>
            {!fetchData?.signature && (
              <Col lg={11} className="border " style={{ height: "60px" }}></Col>
            )}
            <Col lg={11} className=" d-flex justify-content-start">
              {fetchData?.signature && (
                <Image
                  src={fetchData?.signature}
                  alt="signature"
                  style={{ width: "200px", height: "60px" }}
                />
              )}
            </Col>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default SubscriptionAllInvoiceGST;
