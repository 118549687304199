import React from "react";
import { ListGroup } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { i18n } from "../../shared/helpers/sharedMethods";
import TabLayout from "../../shared/components/item-detail/TabLayout";
import ItemReportByParty from "../screens/ItemReportByParty";
import ItemSaleAndPurchaseSummary from "../screens/ItemSaleAndPurchaseSummary";
import RateListReport from "../screens/RateListReport";
import StockDetailReport from "../screens/StockDetailReport";
import StockSummary from "../screens/StockSummary";
import ProductsImport from "../../settings/screens/ProductsImport";
import ProductExport from "../../settings/screens/ProductExport";


const ItemNavbar = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const reportTabs = [
    {
      key: "item/item-report-by-party",
      title: i18n("Item Report By Party"),
      component: <ItemReportByParty />,
    },
    {
      key: "item/sales-and-purchase-summary",
      title: i18n("Item Sales and Purchase Summary"),
      component: <ItemSaleAndPurchaseSummary />,
    },
    {
      key: "item/rate-list",
      title: i18n("Rate List"),
      component: <RateListReport />,
    },
    {
      key: "item/stock-detail",
      title: i18n("Stock Detail Report"),
      component: <StockDetailReport />,
    },
    {
      key: "item/stock-summary",
      title: i18n("Stock Summary"),
      component: <StockSummary />,
    },
    // {
    //   key: "productImport ",
    //   title: "Product Import",
    //   component: <ProductsImport />,
    // },
    // {
    //   key: "productExport",
    //   title: "Product Export",
    //   component: <ProductExport />,
    // },
  ];


  return (
    <>
      {/* <div
        className="col-lg-12 "
        style={{ height: "90vh", borderRight: "1px solid lightgray" }}
      >
        <ListGroup className="custom-listgroup-css py-2" variant="flush">
        <ListGroup.Item className={location.pathname == "/reports/itemReportByParty" ? "active-highlight" : ""}
            onClick={() => {
              navigate("/reports/itemReportByParty");
            }}
          >
            <Link className="d-flex " to="/reports/itemReportByParty">
            {i18n("Item Report By Party")}
            </Link>
          </ListGroup.Item>

          <ListGroup.Item className={location.pathname == "/reports/itemSaleAndPurchase" ? "active-highlight" : ""}
            onClick={() => {
              navigate("/reports/itemSaleAndPurchase");
            }}
          >
            <Link className="d-flex " to="/reports/itemSaleAndPurchase">
            {i18n("Item Sales and Purchase Summary")}
            </Link>
          </ListGroup.Item>
         
          <ListGroup.Item   className={location.pathname == "/reports/rateList" ? "active-highlight" : ""}
            onClick={() => {
              navigate("/reports/rateList");
            }}
          >
            <Link className="d-flex " to="/reports/rateList">
            {i18n("Rate List")}
            </Link>
          </ListGroup.Item>

          <ListGroup.Item
            className={location.pathname == "/reports/stockDetail" ? "active-highlight" : ""}
            onClick={() => {
              navigate("/reports/stockDetail");
            }}
          >
            <Link className="d-flex " to="/reports/stockDetail">
              {" "}

              {i18n("Stock Detail Report")}
            </Link>
          </ListGroup.Item>

          <ListGroup.Item className={location.pathname == "/reports/stockSummary" ? "active-highlight" : ""}
            onClick={() => {
              navigate("/reports/stockSummary");
            }}
          >
            <Link className="d-flex " to="/reports/stockSummary">
            {i18n("Stock Summary")}
            </Link>
          </ListGroup.Item>
         

        </ListGroup>
      </div> */}
      <TabLayout type="reports" tabs={reportTabs} route="reports" title="Report" />
    </>
  );
};

export default ItemNavbar;
