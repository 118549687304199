import { Button, Col, Form, ListGroup, Stack } from "react-bootstrap";
import { i18n, placeholderText } from "../../../../shared/helpers/sharedMethods";
import { useDispatch, useSelector } from "react-redux";


const SubscriptionNotes = ({ values, handleChange }) => {
  const dispatch = useDispatch();

  return (
    <Stack direction="vertical" className="ms-auto mt-3" gap={3}>
      <Form.Label>
        <span
          className="bold pb-2"
          style={{ borderBottom: "3px solid #1a237e" }}
        >
          {i18n("Notes")}
        </span>
      </Form.Label>

      <Form.Control
        as="textarea"
        type="text"
        name="notes"
        placeholder={placeholderText("Please Enter Your Note.")}
       // value={values.notes}
        onChange={(e) => {
          handleChange(e);
          //dispatch(changeNotes(e.target.value));
        }}
      />
    </Stack>
  );
};
export default SubscriptionNotes;
