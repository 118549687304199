import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectAllProducts,
  selectProductAsVoucher,
  selectProductAsVoucherItems,
} from "../../../catalog/store/productsSlice";
import { placeholderText } from "../../../shared/helpers/sharedMethods";
import "../../../../assets/css/custom.css";
import {
  setProductForItem,
  unsetProductForItem,
} from "../../store/voucherItemsSlice";
import { Menu, MenuItem, Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { useEffect } from "react";
import { cloneDeep } from "lodash";
import { Form, InputGroup } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { useHotkeys } from "react-hotkeys-hook";

const ProductTypeAhead = ({ index, item, voucherType,is_same_state }) => {
  ;
  const dispatch = useDispatch();
  const allProducts = useSelector((state) =>
    selectProductAsVoucher(state, voucherType)
  );

  //const selectedItem = item ? [item] : [];
  const [selectedItem, setSelectedItem] = useState([item]);
  const [showProduct, setShowProduct] = useState(false);
  const [focusIndex, setFocusIndex] = useState();
  const typeaheadRef = useRef(null);
  const handleClick = () => {
    setShowProduct(true);
    setFocusIndex(1);
  };

 ;

  useEffect(() => {
    ;
    if (selectedItem) {
      if (selectedItem.length > 0) {
        let item = cloneDeep(selectedItem[0]); //getting the first one from array of selections, since selected will always be an array
        item.voucherType = voucherType;

        let selectedData = {
          index,
          item,
          is_same_state        
        };
        dispatch(setProductForItem(selectedData));
      } else {
        dispatch(unsetProductForItem(index));
      }
    }
  }, [selectedItem]);

  const filterByFields = ["product_name", "product_code"];
  const ref = React.createRef();

  useHotkeys("shift+m", (event) => {
    event.preventDefault();
    handleClick();
  });

  useEffect(() => {
    if (focusIndex == index && typeaheadRef.current) {
      typeaheadRef.current.focus();
    }
  }, [focusIndex]);

  return (
    <>
      <Form.Group>
        <InputGroup>
          {allProducts && (
            <Typeahead
              ref={typeaheadRef}
              allowNew
              renderMenu={(results, menuProps) => (
                <Menu {...menuProps}>
                  {results.map((result, position) => {
                    if (result.customOption) {
                      result.product_name = result.label;
                      result.product_code = "";
                      result.id = null;
                      result.product_id = null;
                      result.unit = "UNT";
                      result.type = "goods";
                      result.sale_price = 0;
                      result.cost_price = 0;
                      result.hsn = "";
                      result.tax_code = "GST_NONE";
                      result.is_tax_included = true;
                    }

                    return (
                      <MenuItem
                        key={position}
                        option={result}
                        position={position}
                      >
                        <div>
                          {result.product_name}
                          <div>
                            {result.product_code ? (
                              <small>
                                <b>code:</b> {result.product_code}
                              </small>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </MenuItem>
                    );
                  })}
                </Menu>
              )}
              id={"product-typeahead-" + index}
              newSelectionPrefix="Add a new item : "
              options={allProducts}
              placeholder={placeholderText("Search by Name / Code")}
              onChange={setSelectedItem}
              filterBy={filterByFields}
              selected={selectedItem}
              selectHint={(shouldSelect, event) =>
                event.key === "Enter" || shouldSelect
              }
              labelKey={(option) =>
                `${option?.product_name} ${
                  option?.product_code ? option?.product_code : ""
                }`
              }
            />
          )}
          {selectedItem && selectedItem.length > 0 ? (
            <InputGroup.Text
              className="cursor-pointer fs-2"
              style={{ background: "none" }}
              onClick={() => {
                setSelectedItem([]);
              }}
            >
              <FontAwesomeIcon
                icon={faXmark}
                title="Clear Item"
                className="text-danger"
              />
            </InputGroup.Text>
          ) : (
            ""
          )}
        </InputGroup>
      </Form.Group>
    </>
  );
};

export default ProductTypeAhead;
