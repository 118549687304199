import React, { useEffect, useState } from "react";
import ProductSearch from "../components/fields/ProductSearch";
import VoucherItemsRow from "../components/VoucherItemsRow";
import { useDispatch, useSelector } from "react-redux";
import {
  i18n,
  numValidate,
  placeholderText,
} from "../../shared/helpers/sharedMethods";
import ModelFooter from "../components/ModelFooter";
import { useNavigate } from "react-router-dom";
import AddressModel from "../components/AddressModel";
import PartyModel from "../components/PartyModel";
import SubCalculationProduct from "../components/PaymentSection";
import "../../voucher/assets/css/sale.css";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Layout from "../../shared/components/Layout";
import TermsAndCondition from "../components/fields/TermsAndCondition";
import VoucherDate from "../components/VoucherDates";
import PartyAndAddress from "../components/PartyAndAddress";
import AdditionalCharges from "../components/AdditionalCharges";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Row,
  Stack,
  Table, Spinner
} from "react-bootstrap";
import {
  addNew,
  initEditVoucherItems,
  resetVoucherItem,
  selectAllVoucherItems,
  updateTaxSummary,
  updateVoucherItem,
} from "../store/voucherItemsSlice";
import {
  addCharges,
  addDueDate,
  addVoucher,
  convertTypeStatus,
  editVoucher,
  getVoucherNumber,
  undoVoucherError,
  updateTotalReceivedAmount,
  updateTotals,
  updateVoucherPayment,
  voucherisOrdered,
} from "../store/voucherEntitySlice";
import {
  addAdditionalCharge,
  initEditCharges,
  updateCharges,
} from "../store/additionalChargesSlice";
import { cloneDeep, filter, isEmpty } from "lodash";
import { useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";
import {
  pushNewSale,
  selectSalesById,
  updateSale,
} from "../store/voucherSlice";
import { initEditVoucher } from "../store/voucherEntitySlice";
import {
  editProcesses,
  unDoCessVoucher,
  unDoDiscounts,
} from "../helpers/VouchersHelper";
import { resetCartVoucherPayment } from "../../payment/store/paymentSlice";
import VoucherTotalsRow from "../components/VoucherTotalsRow";
import VoucherSummary from "../components/VoucherSummary";
import AmountReceived from "../components/fields/AmountReceived";
import AddlCharges from "../components/AddlCharge";
import VoucherNoAndDates from "../components/VoucherNoAndDates"
import Header from "../../shared/components/Header";
import BusinessBlock from "../components/BusinessBlock";
import PartyBlock from "../components/PartyBlock";
import BillingAddressBlock from "../components/BillingAddressBlock";
import ShippingAddressBlock from "../components/ShippingAddressBlock";
import CartBlock from "../components/CartBlock";
import Notes from "../components/fields/Notes";
import BankDetails from "../components/fields/BankDetails";
import SignBlock from "../components/SignBlock";
import { changeToggleShow } from "../../shared/store/sharedSlice";
import QuickGlance from "../components/QuickGlance";
import { useHotkeys } from "react-hotkeys-hook";
import ShortcutKeys from "../../shared/components/ShortcutKeys";
import AlertMessage from "../../shared/components/AlertMessage";
import {
  processCartItem,
  processCartItemForEdit,
} from "../../pos/helpers/cartHelper";
import { selectpartyById } from "../../party/store/getPartySlice";
import { getUtcDate } from "../../shared/helpers/localizationHelper";
import TaxSummary from "../components/fields/TaxSummary";
import { fetchProducts } from "../../catalog/store/productsSlice";
import { fetchparty } from "../../party/store/getPartySlice";




const EditVoucher = (props) => {
  const { title, path, type, voucherType } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { convertType } = location.state || {};
  const { id } = useParams();
  const { updatePaidAmountStatus, } = useSelector((state) => state.voucherEntity);
  const { items } = useSelector((state) => state.voucherEntity);

  const editSale = useSelector((state) => selectSalesById(state, id));
  console.log('editSale', editSale);
  const party = useSelector((state) => selectpartyById(state, editSale?.party_id));
  const { totalReceivedAmount, markAsPaid } = useSelector((state) => state.voucherEntity);
  const accessToken = useSelector(
    (state) => state?.auth?.accessToken?.access_token
  );
  const [showInput, setShowInput] = useState(false);
  const [dueDate, setDueDate] = useState(true);
  const [is_same_state, setIsSameState] = useState(false);
  const currencySymbol = useSelector(
    (state) => state.getSetting?.dataSetting.currency
  );
  ;
  const [discountEnabled, setDiscountEnabled] = useState(
    editSale?.discount > 0 ? true : false
  );
  const [cessEnabled, setCessEnabled] = useState(
    editSale?.cess_total > 0 ? true : false
  );
  const businessPOS = useSelector((state) => state.getSetting?.dataSetting?.place_of_supply);
  const voucherPOS = useSelector((state) => state.voucherEntity?.place_of_supply);
  const grand_total = useSelector((state) => state.voucherEntity.grand_total);


  useEffect(() => {
    if (!voucherPOS || businessPOS == voucherPOS) {
      dispatch(updateTaxSummary({ items: items, is_same_state: true }))
      setIsSameState(true);
    } else {
      dispatch(updateTaxSummary({ items: items, is_same_state: false }))
      setIsSameState(false);
    }
  }, [businessPOS, voucherPOS]);

  useEffect(() => {
    if (editSale) {

      if (editSale?.discount && editSale?.discount > 0) {
        setDiscountEnabled(true);
      }
      const data = {
        created_by: selectedBusiness?.pivot?.user_id,
        updated_by: selectedBusiness?.pivot?.user_id,
        created_at: getUtcDate(),
        updated_at: getUtcDate(),
      };
      dispatch(initEditVoucher({ editSale: editSale, data: data }));
      dispatch(updateVoucherPayment(editSale.voucher_payments))
      dispatch(updateTotalReceivedAmount(editSale.paid_amount))
      let iterateItems = [];
      editSale?.voucher_items?.forEach((data) => {
        iterateItems.push(processCartItem(data, "", is_same_state));
      });


      //processCartItemForEdit(editSale.items)
      dispatch(initEditVoucherItems(cloneDeep(iterateItems)));
      dispatch(initEditCharges(editSale.charges));
      if (editSale?.charges?.length > 0) {
        setShowInput(true);
      }
      if (editSale?.due_date) {
        dispatch(addDueDate(editSale?.due_date));
        setDueDate(false);
      }

      if (convertType === "quote" || convertType === "proforma_invoice") {
        let convertionType = "sales_invoice";
        dispatch(getVoucherNumber({ data: convertionType, token: accessToken, tenant_id: tenantId, terminal_id: terminalId }));

      } else if (convertType === "purchase_order") {
        let convertionType = "purchase_invoice";
        dispatch(getVoucherNumber({ data: convertionType, token: accessToken, tenant_id: tenantId, terminal_id: terminalId }));

      }
    }
  }, [editSale]);

  const voucherItems = useSelector(selectAllVoucherItems);
  console.log('voucherItems', voucherItems);
  const selectedBusiness = useSelector(
    (state) => state?.auth?.selectedBusiness
  );
  const tenantId = selectedBusiness?.pivot?.tenant_id;
  const { terminalId } = useSelector((state) => state.initInstall);

  const additionalCharges = useSelector(
    (state) => state.additionalCharges.charges
  );

  const totalCharges = useSelector(
    (state) => state.voucherEntity.totalAddlCharges
  );

  const partyValue = useSelector((state) => state.voucherEntity.party);

  const status = useSelector((state) => state.voucherEntity?.status?.isOrdered);
  const convertStatus = useSelector(
    (state) => state.voucherEntity?.status?.convertTypeStatus
  );
  const { errorStatus, error } = useSelector(
    (state) => state.voucherEntity.status
  );
  ;

  const currentId = useSelector(
    (state) => state.voucherEntity?.status?.currentId
  );

  ;

  const voucherOrder = useSelector((state) => state.voucherEntity);

  const [addressModel, setAddressModel] = useState(false);
  const [showPartyModel, setShowPartyModel] = useState(false);
  const [loading, setLoading] = useState(false);


  useEffect(() => {
    if (errorStatus) {
      setLoading(false)
    }
  }, [errorStatus])


  useEffect(() => {
    let voucherItems = editSale?.items;
    if (!discountEnabled && voucherItems?.length > 0) {
      dispatch(resetVoucherItem());
      Array(3)
        .fill(1)
        .map((el, i) => dispatch(addNew(i + 1)));

      const iteratedDiscountValue = unDoDiscounts({
        voucherItems,
        discountEnabled,
      });
      ;

      dispatch(initEditVoucherItems(cloneDeep(iteratedDiscountValue)));
    }
  }, [discountEnabled]);

  useEffect(() => {
    const validItems =
      voucherItems &&
      filter(voucherItems, function (o) {
        return o.product_id;
      });
    ;
    dispatch(updateTotals(validItems));
  }, [voucherItems]);

  useEffect(() => {
    if (additionalCharges) {
      dispatch(addCharges(additionalCharges));
    }
  }, [additionalCharges]);

  useEffect(() => {
    if (status) {
      if (convertStatus) {
        toast.success(i18n("Sales Created Successfully"));
        dispatch(pushNewSale(currentId));
        dispatch(fetchProducts());
        navigate(`/${path}/invoice/` + currentId.id);
        dispatch(voucherisOrdered());
      } else {
        toast.success(i18n("Sales updated Successfully"));
        dispatch(updateSale(currentId));
        navigate(`/${path}/view/` + id);
        dispatch(voucherisOrdered());
      }
    }
  }, [status]);

  const addAdditionalChargesField = () => {
    dispatch(addAdditionalCharge());
    setShowInput(true);
  };
  useEffect(() => {
    dispatch(
      fetchProducts({
        token: accessToken,
        tenant_id: selectedBusiness?.pivot?.tenant_id,
      })
    );
  }, []);
  useEffect(() => {
    dispatch(
      fetchparty({
        token: accessToken,
        tenant_id: selectedBusiness?.pivot?.tenant_id,
      })
    );
  }, []);
  const handleValidation = () => {
    let isValid = false;
    const validItems =
      voucherItems &&
      filter(voucherItems, function (o) {
        return o.product_id;
      });
    const zeroQtyItems =
      voucherItems &&
      filter(voucherItems, function (o) {
        return !o.quantity;
      });
    const isWalkIn = partyValue && partyValue.id === 1;

    if (isEmpty(partyValue)) {
      toast.error(i18n("Please select a party!"));
    } else if (!isWalkIn && !partyValue.phone_number) {
      toast.error(i18n("Please enter phone number!"));
    } else if (validItems.length < 1) {
      toast.error(i18n("Please select at least one product!"));
    } else if (zeroQtyItems.length > 0) {
      toast.error(i18n("Please Select quantity of the product!"));
    } else {
      isValid = true;
    }
    return isValid;
  };
  const toggleCess = () => {
    setCessEnabled(!cessEnabled);
  };
  const toggleDiscount = () => {
    setDiscountEnabled(!discountEnabled);
  };

  const onSubmit = async () => {
    try {
      const validation = handleValidation();
      if (validation) {
        setLoading(true)
        let saleOrder = editProcesses(
          cloneDeep(voucherOrder),
          partyValue,
          selectedBusiness,
          totalCharges,
          editSale.type,
          convertType,
          totalReceivedAmount,
          markAsPaid
        );
        saleOrder.created_at = getUtcDate();
        saleOrder.updated_at = getUtcDate();
        ;
        if (saleOrder?.paid_amount > saleOrder?.grand_total) {
          setLoading(false)
          toast.error(
            "Received payment amount cannot be greater than the total invoice amount"
          );
          return;
        }
        saleOrder?.items?.forEach((item) => {
          item.id = item.product_id;
          item.voucher_id = saleOrder?.id
        });
        saleOrder.amount = saleOrder?.paid_amount
        saleOrder.paid_amount = saleOrder?.amount > 0 ? saleOrder?.voucherPayment?.reduce((sum, item) => sum + item?.payment?.amount, 0) + Number(saleOrder?.amount) : saleOrder?.voucherPayment?.reduce((sum, item) => sum + item?.payment?.amount, 0)
        console.log("sale order", saleOrder)
        delete saleOrder.currentAddress;
        delete saleOrder.addresses?.label;
        if (saleOrder.isConvertType) {
          delete saleOrder.isConvertType;
          delete saleOrder.id;

          //delete saleOrder.voucher_number
          dispatch(addVoucher(saleOrder));
          dispatch(changeToggleShow(true));
          dispatch(convertTypeStatus());
        } else {
          dispatch(editVoucher({
            data: saleOrder, token: accessToken,
            tenant_id: tenantId, terminal_id: terminalId
          }));
        }
      }
    } catch (error) {
      setLoading(false)
    }
  };
  const handleCancel = () => {
    dispatch(resetCartVoucherPayment());
    dispatch(changeToggleShow(false));
    navigate(`/${path}`);
  };
  const handleClose = () => {
    setShowPartyModel(false);
    setAddressModel(false);
  };
  const handleAlertClose = () => {
    setLoading(false)
    dispatch(undoVoucherError());
  };

  useEffect(() => {
    if (errorStatus) {
      setTimeout(() => {
        handleAlertClose();
      }, [1000])
    }
  }, [errorStatus])

  useHotkeys("alt+enter", (event) => {
    event.preventDefault();
    onSubmit();
  });

  useHotkeys("esc", (event) => {
    event.preventDefault();
    handleCancel();
  });

  useHotkeys("esc", (event) => {
    event.preventDefault();
    handleCancel();
  });
  return (
    <>
      <ToastContainer />
      <Layout currentTitle={title}>
        {showPartyModel && (
          <PartyModel
            show={showPartyModel}
            handleClose={handleClose}
            numValidate={numValidate}
          />
        )}
        {addressModel && (
          <AddressModel show={addressModel} handleClose={handleClose} />
        )}
        <Header
          currentTitle={title}
          ItemActions={
            <Stack
              gap={3}
              direction="horizontal"
              className="mt-2 mb-2 justify-content-end"
            >
              <ShortcutKeys />
              {!loading ? (
                <Button
                  id="submitButton"
                  variant="primary"
                  onClick={onSubmit}
                  disabled={loading}
                >
                  {i18n("Save")}
                </Button>
              ) : (
                <div className="d-flex align-items-center mx-6">
                  <Spinner animation="border" size="md" />
                </div>
              )}
              <Button
                className=""
                variant="outline-secondary"
                onClick={handleCancel}
              >
                {i18n("Cancel")}
              </Button>
            </Stack>
          }
        />
        <Container fluid className="px-5">
          <div
            className="border"
            style={{ background: "white", height: "95vh", overflow: "scroll" }}
          >
            {errorStatus && (
              <AlertMessage
                errorStatus={errorStatus}
                error={error}
                handleAlertClose={handleAlertClose}
              />
            )}
            <Row
              className="mx-2"
              style={{ minHeight: "170px", borderBottom: "1px solid #ccc" }}
            >
              <Col lg={3}>
                <VoucherNoAndDates types={type} />
              </Col>
              <Col lg={9} className="px-0" style={{ borderLeft: "1px solid lightgrey", minHeight: "168px" }}>
                <Row
                  className=""
                  style={{ minHeight: "170px" }}
                >
                  <Col lg={6}>
                    <PartyBlock />
                  </Col>
                  <Col lg={6} style={{ borderLeft: "1px solid lightgrey", minHeight: "168px" }}>
                    {!isEmpty(partyValue) && <BillingAddressBlock />}
                    {!isEmpty(partyValue) && <ShippingAddressBlock />}
                  </Col>
                </Row>
              </Col>
            </Row>


            <Row className="p-1">
              <Col lg={12} className="">
                <Col className="d-flex justify-content-end">
                  <Form.Switch
                    id="toggle-discount"
                    label={
                      discountEnabled
                        ? i18n("Discount Enabled")
                        : i18n("Discount")
                    }
                    checked={discountEnabled}
                    onChange={toggleDiscount}
                    style={{ marginRight: "10px" }}
                  />
                  <Form.Switch
                    id="toggle-cess"
                    label={cessEnabled ? i18n("CESS Enabled") : i18n("CESS")}
                    checked={cessEnabled}
                    onChange={toggleCess}
                    style={{ marginLeft: "15px", marginRight: "10px" }}
                  />
                </Col>
                <CartBlock
                  voucherType={voucherType}
                  cessEnabled={cessEnabled}
                  discountEnabled={discountEnabled}
                  is_same_state={is_same_state}
                />
              </Col>
            </Row>

            <Row className="p-2 mx-2">
              <Col lg={6} style={{ borderRight: "1px solid #ccc" }}>
                <Row>
                  <Col lg={6}>
                    <Notes />
                    <BankDetails />
                  </Col>
                  <Col lg={6}>
                    <TermsAndCondition />
                  </Col>
                </Row>
              </Col>
              <Col lg={6}>
                <Stack direction="vertical" className=" p-2">
                  {/* {!(
                    type === "proforma_invoice" ||
                    type === "quote" ||
                    type === "purchase_order"
                  ) ? (
                    <AmountReceived currencySymbol={currencySymbol} isEdit={true} />
                  ) : null} */}
                  <VoucherSummary type={type} is_same_state={is_same_state} />
                  <Row style={{ borderTop: "1px solid #ccc" }}>
                    <Col lg={9}></Col>
                    <Col md={3}>
                      <SignBlock />
                    </Col>
                  </Row>
                </Stack>
              </Col>
            </Row>
          </div>
        </Container>
      </Layout>
    </>
  );
};
export default EditVoucher;
