import React, { useState, useEffect, useRef } from "react";
import {
  placeholderText,
  i18n,
  numValidate,
} from "../../shared/helpers/sharedMethods";
import { useNavigate } from "react-router-dom";
import { fetchparty, selectpartyById, updatePartyDatas } from "../store/getPartySlice";
import {
  initPartyEdit,
  removeAddress,
  resetCreateParty,
  undoError,
  updateParty,
} from "../store/PartyEntitySlice";
import { useDispatch, useSelector } from "react-redux";
import CreateGeneralDetails from "../components/GeneralDetails";
import CreateCategory from "../../settings/components/CreateCategoryModel";
//import { toast, ToastContainer } from "react-toastify";
import { Formik } from "formik";
import * as Yup from "yup";
import CreateBusinessDetails from "../components/BusinessDetails";
import { selectAllCategories } from "../../settings/store/categorysSlice";
import { cloneDeep } from "lodash";
import Address from "../components/Address";
import {
  Button,
  Col,
  Modal,
  Row,
  Tab,
  Tabs,
} from "react-bootstrap";
import { useHotkeys } from "react-hotkeys-hook";
import AlertMessage from "../../shared/components/AlertMessage";
import {
  addNewAddress,
  deletePartyAddress,
  initEdit,
  initEditPartyAddress,
  resetAddressStatus,
  selectAllPartyAddress,
  selectPartyAddressIds,
} from "../store/partyAddressSlice";
import MultipleAddress from "../components/MultipleAddress";
import { preparePartyData } from "../helpers/partyHelper";
import { toast, ToastContainer } from "react-toastify";

const EditParty = (props) => {
  const {
    title,
    path,
    show,
    isEdit = false,
    partyId,
    setShowEditParty,
    isTriggeredFromVoucher,
    voucherQuickEdit,
    activeEditPartyTab,
    type,
  } = props;
  //  const { partyId } = useParams();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const saveButtonRef = useRef(null);
  const allAddress = useSelector(selectAllPartyAddress);

  const partyState = useSelector((state) => state.partyEntity.party);
  const editParty = useSelector((state) => selectpartyById(state, partyId));
  const status = useSelector((state) => state.partyEntity.editStatus);
  const updateEntity = useSelector(
    (state) => state.partyEntity.savedPartyEntity
  );
  const selectedBusiness = useSelector(
    (state) => state?.auth?.selectedBusiness
  );
  const { errorStatus, error } = useSelector((state) => state.partyEntity);
  const index = useSelector(selectPartyAddressIds);

  const fetchCategory = useSelector(selectAllCategories);
  const filterData = fetchCategory.filter((item) => item?.type === "party");
  const { addAddressStatus } = useSelector((state) => state.partyAddress);
  const { party_address } = useSelector((state) => state.partyEntity.party);
  const [showCategory, setShowCategory] = useState(false);
  const [key, setKey] = useState(activeEditPartyTab);
  const [click, setClick] = useState(false);
  const [showAddress, setShowAddress] = useState(false);
  const [addressId, setAddressId] = useState("");
  const [selectedId, setSelectedId] = useState();
  const [loading, setLoading] = useState(false);
  const { accessToken } = useSelector((state) => state?.auth);
  const tenantId = selectedBusiness?.pivot?.tenant_id;
  const { terminalId } = useSelector((state) => state.initInstall);

  useEffect(() => {
    if (errorStatus) {
      setLoading(false)
    }
  }, [errorStatus])


  useEffect(() => {
    if (editParty) {
      let partyAddresses = editParty.party_addresses;

      dispatch(initPartyEdit(editParty));
      dispatch(initEditPartyAddress(partyAddresses));
    }
  }, []);

  useEffect(() => {
    if (addAddressStatus) {
      let getAddressDetail = allAddress[allAddress.length - 1];

      setShowAddress(true);
      setAddressId(getAddressDetail.id);
      dispatch(resetAddressStatus());
    }
  }, [addAddressStatus]);

  useEffect(() => {
    if (status && updateEntity) {
      toast.success("Party updated Successfully");
      dispatch(updateParty({
        data: updateEntity, token: accessToken.access_token,
        tenant_id: tenantId, terminal_id: terminalId
      }));
      dispatch(fetchparty({ token: accessToken.access_token, tenant_id: selectedBusiness?.pivot?.tenant_id }));
      setShowEditParty(false);

      if (isTriggeredFromVoucher) {
        voucherQuickEdit([updateEntity]);
      }
      dispatch(resetCreateParty());
    }
  }, [status, updateEntity]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Enter") {
        event.preventDefault();
        const saveButton = document.getElementById("submitButton");
        if (saveButton) {
          saveButton.click();
        }
      }
    };

    if (!showCategory && !showAddress) {
      document.addEventListener("keydown", handleKeyDown);
    }
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [showCategory, showAddress]);

  useHotkeys("esc", (event) => {
    event.preventDefault();
    handleCancel();
  });

  const gstRegex =
    /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[0-9]{1}[A-Z]{1}[0-9A-Z]{1}$/;
  const panRegex = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  const handleMultipleAddressClick = () => {
    dispatch(addNewAddress(index.length + 1));
    setShowAddress(true);
  };

  const handleCategory = () => {
    setShowCategory(true);
  };

  const handleCancel = () => {
    dispatch(resetCreateParty());
    setShowEditParty(false);
  };
  const handleAlertClose = () => {
    setLoading(false)
    dispatch(undoError());
  };

  const handleEdit = (args) => {
    dispatch(initEdit({ addressData: args?.value, id: args?.id }));
    setAddressId(args?.id);
    setShowAddress(true);
  };

  const handleDelete = (i) => {
    dispatch(deletePartyAddress(i));
    dispatch(removeAddress(i));
  };

  return (
    <>
      <ToastContainer />

      <Formik
        initialValues={partyState}
        enableReinitialize={true}
        validationSchema={Yup.object().shape({
          name: Yup.string().required(i18n("Please enter party name"))
            .min(2, "Please enter at least 2 characters"),
          phone_number: Yup.string()
            .required(i18n("Please enter party phone number"))
            .min(10, "Phone number must be 10 digits")
            .matches(/^[0-9]*$/, i18n("Invalid phone number")),
          gstin: Yup.string().matches(
            gstRegex,
            "Invalid GSTIN"
          ).nullable(),
          pan: Yup.string().matches(
            panRegex,
            "Invalid PAN number"
          ).nullable(),
          email: Yup.string().nullable().matches(emailRegex, "Invalid Email"),
        })}
        onSubmit={async (values) => {
          try {
            setLoading(true);
            preparePartyData(partyState, selectedBusiness, dispatch, accessToken, tenantId, terminalId);
          } catch (error) {
            setLoading(false);
            console.error("Error updating party:", error);
          }
        }}

      >
        {({
          values,
          errors,
          handleChange,
          handleSubmit,
          setFieldValue,
          touched,
        }) => (
          <>
            <Modal
              show={show}
              keyboard={true}
              onHide={handleCancel}
              size="lg"
              dialogClassName="modal-dialog modal-dialog-centered"
            >
              <div className="" style={{ borderRadius: '5px', boxShadow: '0 0 500px 500px rgba(0, 0, 0, 0.3)' }}>
                <Modal.Header closeButton>
                  <h2 className="bold">{i18n("Edit Party")}</h2>
                </Modal.Header>
                {errorStatus && (
                  <AlertMessage
                    errorStatus={errorStatus}
                    error={error}
                    handleAlertClose={handleAlertClose}
                  />
                )}
                <Modal.Body className="py-2" style={{ height: "500px" }}>
                  <Tabs
                    id="controlled-tab-example"
                    activeKey={key}
                    onSelect={(k) => setKey(k)}
                    className="mb-3 custom-nav-css"
                    variant="underline"
                    style={{
                      borderBottom: "1px solid lightgray",
                      lineHeight: "30px",
                      gap: "30px",
                    }}
                  >
                    <Tab
                      className=""
                      eventKey="general"
                      title={
                        <div>
                          {i18n("General Details")} <span className="required" />
                        </div>
                      }
                    >
                      <CreateGeneralDetails
                        handleChange={handleChange}
                        i18n={i18n}
                        placeholderText={placeholderText}
                        errors={errors}
                        touched={touched}
                        values={values}
                        numValidate={numValidate}
                        filterData={filterData}
                        handleCategory={handleCategory}
                      />
                      <CreateBusinessDetails
                        handleChange={handleChange}
                        i18n={i18n}
                        placeholderText={placeholderText}
                        errors={errors}
                        values={values}
                        filterData={filterData}
                        handleCategory={handleCategory}
                        setFieldValue={setFieldValue}
                        editParty={editParty}
                        type={type}
                      />
                    </Tab>
                    <Tab eventKey="address" title={i18n("Address Details")}>
                      <Col className="px-0 text-end">
                        <Button
                          className="px-4"
                          variant="link"
                          onClick={handleMultipleAddressClick}
                          style={{ fontSize: "15px" }}
                        >
                          {i18n("+ Add")}
                        </Button>
                      </Col>

                      {!party_address && (
                        <Col className="d-flex justify-content-center align-items-center">
                          No Address
                        </Col>
                      )}

                      {party_address.length > 0 && (
                        <MultipleAddress
                          handleEdit={handleEdit}
                          handleDelete={handleDelete}
                        />
                      )}
                    </Tab>
                  </Tabs>
                </Modal.Body>
                <Modal.Footer style={{ borderTop: "1px solid lightgray" }}>
                  <Row className="py-1">
                    {values && (
                      <Col className="d-flex justify-content-end">
                        <Button
                          id="submitButton"
                          ref={saveButtonRef}
                          variant="primary mx-2"
                          onClick={handleSubmit}
                        >
                          {i18n("Save")}
                        </Button>
                        <Button
                          variant="outline-secondary mx-2"
                          onClick={handleCancel}
                        >
                          {i18n("Cancel")}
                        </Button>
                      </Col>
                    )}
                  </Row>
                </Modal.Footer>
              </div>
            </Modal>
            <CreateCategory
              show={showCategory}
              handleClose={() => setShowCategory(false)}
              type="party"
              hideTypeDropdown={true}
              caller="part1"
            />
            {showAddress && (
              <Address
                show={showAddress}
                setShow={setShowAddress}
                id={addressId}
                click={click}
                handleChange={handleChange}
                setClick={setClick}
                i18n={i18n}
                placeholderText={placeholderText}
                setFieldValue={setFieldValue}
                isEdit={isEdit}
              />
            )}
          </>
        )}
      </Formik>
    </>
  );
};

export default EditParty;
