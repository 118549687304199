import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

import CartIndex from './sections/cart/CartIndex';
import SummaryIndex from './sections/summary/SummaryIndex';
import Options from './sections/header/Options';
import BillTabs from './sections/header/BillTabs';


const PosIndex = () => {

  return (
    <Container className='p-0' fluid>
      {/* <Row>
        <Options />
      </Row> */}
      <Row>
        <BillTabs />
      </Row>
      <Row>
      <Col className='p-0' lg={7} xxl={7} xs={7} md={8}>
        <CartIndex />
        </Col>
        <Col className='p-0' lg={5} xxl={5} xs={5} md={4}>
          <SummaryIndex />
        </Col>
      </Row>
    </Container>
  )
}

export default PosIndex;
