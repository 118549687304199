import React from 'react';
import { Modal, Row, Col } from 'react-bootstrap'; 
import { Link } from "react-router-dom";
import '../css/sale.css'
import { i18n } from '../../shared/helpers/sharedMethods';
const SaleModal = ({ show, setShow }) => {
  return (
    <Modal className="modal-sm" show={show} onHide={() => setShow(false)}>
      <Modal.Header closeButton>
        <Modal.Title>{i18n("Please Select a Sale type")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <Col>
        <Row className='py-2'>
          <Link to="/voucher/sales-invoice/create" className="sale-link">{i18n("Sales Invoice")}</Link>
        </Row>
        <Row className='py-2'>
          <Link to="/voucher/sales-return/create" className="sale-link">{i18n("Sales Return")}</Link>
        </Row>
        <Row className='py-2'>
          <Link to="/voucher/credit-note/create" className="sale-link">{i18n("Credit Note")}</Link>
        </Row>
        <Row className='py-2'>
          <Link to="/voucher/quotation/create" className="sale-link">{i18n("Quotation")}</Link>
        </Row>
      </Col>
    </Modal.Body>
    </Modal>
  );
};

export default SaleModal;
