import {
  Button,
  Card,
  Col,
  Form,
  InputGroup,
  Modal,
  Row,
} from "react-bootstrap";
import {
  i18n,
  placeholderText,
  numValidate,
} from "../../shared/helpers/sharedMethods";
import { Formik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import {
  EditEnquiryReq,
  resetCreateEnquiry,
} from "../store/CreateEnquirySlice";
import { useEffect, useState } from "react";
import { MenuItem, Typeahead } from "react-bootstrap-typeahead";
import { Menu } from "react-pro-sidebar";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquareXmark } from "@fortawesome/free-solid-svg-icons";
import DatePicker, { registerLocale } from "react-datepicker";
import moment from "moment";
import { getEnquiry, updateEnquiry } from "../store/EnquirySlice";
import { isEmpty } from "lodash";
import { selectAllPlanData } from "../../subscription/store/subscription_item_store/GetPackagesItemSlice";
import { storeCurrentProduct, storeProduct } from "../../subscription/store/GetParentProductEntiry";

const EditEnquiry = (props) => {
  const { show, setShowEnquiryEdit, propInitialValues } = props;
  const [selectedItem, setSelectedItem] = useState([]);
  const [itemChanged, setItemChanged] = useState(false);
  const [followupDate, setFollowupDate] = useState(null);
  const [leadStatus, setLeadStatus] = useState('');
  const dispatch = useDispatch();
  const { parentProduct, party, currentProduct } = useSelector(
    (state) => state.getProduct
  );

  const { accessToken, isLoggedIn } = useSelector((state) => state?.auth);

  const selectedBusiness = useSelector(
    (state) => state?.auth?.selectedBusiness
  );
  const tenantId = selectedBusiness?.pivot?.tenant_id;
  const status = useSelector((state) => state.createEnquiry.isEdit);
  const updateEntity = useSelector((state) => state.createEnquiry.updateEntity);
  ;
  const subscription = useSelector(selectAllPlanData);

  const currentData = parentProduct;

  useEffect(() => {
    if (subscription) {
      dispatch(storeProduct(subscription));
    }
  }, []);

  useEffect(() => {
    if (currentProduct) {
      setSelectedItem(currentProduct);
    }
  }, [currentProduct]);

  useEffect(() => {
    dispatch(
      storeCurrentProduct({
        id: propInitialValues,
        product: parentProduct,
      })
    );
  }, []);

  useEffect(() => {
    if (status && updateEntity) {
      dispatch(updateEnquiry(updateEntity));
      toast.success("Enquiry Updated Successfully");
      setShowEnquiryEdit(false);
      dispatch(resetCreateEnquiry());
      dispatch(
        getEnquiry({
          token: accessToken.access_token,
          tenant_id: tenantId,
        })
      );
    }
  }, [status, updateEntity]);

  const handleCancel = () => {
    setShowEnquiryEdit(false);
  };
  const [initialValues, setInitialValues] = useState({
    name: "",
    mobile: "",
    product_id: null,
    notes: "",
    lead_status:"",
    status: true,
    created_by: 1,
  });

  useEffect(() => {
    ;
    setInitialValues({
      name: propInitialValues.name,
      mobile: propInitialValues.mobile ?? "",
      product_id: propInitialValues.product_id,
      notes: propInitialValues.notes,
      lead_status:propInitialValues.lead_status,
      status: propInitialValues.status == 1 ? true : false,
      created_by: propInitialValues.created_by,
      updated_by: propInitialValues?.created_by,
    });
    setFollowupDate(propInitialValues.followup_date);
    setLeadStatus(propInitialValues.lead_status);
  }, [propInitialValues]);
  const handleProductSelect = (item) => {
    ;
    setSelectedItem(item);
  };
  const filterByFields = ["name", "code"];
  const handleFollowupDateChanegs = (date) => {
    var formattedDate = moment(date).format("YYYY-MM-DD");
    setFollowupDate(formattedDate);
  };
  const handleLeadStatusChange = (value) => {
    setLeadStatus(value);
  }

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Enter") {
        event.preventDefault();
        document.getElementById("submitButton").click();
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={true}
      validationSchema={Yup.object().shape({
        name: Yup.string().required(i18n("Please enter Product name")),
        mobile: Yup.string().matches(/^[0-9]*$/, i18n("Invalid phone number")),
      })}
      onSubmit={(values) => {
        if (selectedItem.length != 0) {
          values["product_id"] = selectedItem[0]["id"];
        } else {
          values["product_id"] = null;
        }
        values["followup_date"] = followupDate;
        values["lead_status"] = leadStatus;
        ;
        dispatch(
          EditEnquiryReq({
            data: values,
            token: accessToken.access_token,
            tenant_id: tenantId,
            id: propInitialValues.id,
          })
        );
      }}
    >
      {({
        values,
        errors,
        handleChange,
        handleSubmit,
        touched,
        setFieldValue,
        handleBlur,
      }) => (
        <>
          <ToastContainer />
          <Modal
            show={show}
            keyboard={true}
            onHide={handleCancel}
            size="md"
            dialogClassName="modal-dialog modal-dialog-centered"
          >
            <Modal.Header closeButton>
              <h2 className="bold">{i18n("Edit Enquiry")}</h2>
            </Modal.Header>
            <Modal.Body className="py-2">
              <Card className="px-4">
                <Row className="py-2">
                  <Col lg={12}>
                    <Form.Group>
                      <Form.Label>{i18n("Name")}</Form.Label>
                      <span className="required" />
                    </Form.Group>
                    <Form.Control
                      name="name"
                      placeholder={placeholderText("Enter name")}
                      isInvalid={touched.name && errors.name}
                      value={values.name||''}
                      onBlur={handleBlur}
                      autoFocus={true}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                    />
                    <Form.Text className="text-danger d-block fw-400 fs-small mt-2">
                      {touched.name && errors.name}
                    </Form.Text>
                  </Col>
                </Row>
                <Row>
                  <Col lg={12}>
                    <Form.Group>
                      <Form.Label>{i18n("Mobile")}</Form.Label>
                    </Form.Group>
                    <Form.Control
                      name="mobile"
                      placeholder={placeholderText("Enter mobile number")}
                      value={values.mobile||''}
                      pattern="[0-9]*"
                      min={0}
                      maxLength="10"
                      isInvalid={touched.mobile && errors.mobile}
                      onKeyPress={(event) => numValidate(event)}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                    />
                    <Form.Text className="text-danger d-block fw-400 fs-small mt-2">
                      {touched.mobile && errors.mobile}
                    </Form.Text>
                  </Col>
                </Row>
                <Row>
                  <Col lg={12}>
                    <Form.Group>
                      <Form.Label>{i18n("Select Product")}</Form.Label>
                      <InputGroup>
                        {parentProduct && (
                          <Typeahead
                            newSelectionPrefix="Add a new party : "
                            options={parentProduct}
                            placeholder={placeholderText("Search by Name")}
                            onChange={handleProductSelect}
                            filterBy={filterByFields}
                            selected={selectedItem}
                            labelKey="name"
                            isInvalid={errors.product_id}
                            disabled={!parentProduct ? true : false}
                          />
                        )}

                        {selectedItem && selectedItem.length > 0 ? (
                          <InputGroup.Text
                            className="cursor-pointer fs-2"
                            style={{ background: "none" }}
                            onClick={() => {
                              setSelectedItem([]);
                              // setItemChanged(true);
                            }}
                          >
                            <FontAwesomeIcon
                              icon={faSquareXmark}
                              title="Clear Item"
                              className="text-danger"
                            />
                          </InputGroup.Text>
                        ) : (
                          ""
                        )}
                      </InputGroup>
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col lg={12}>
                    <Form.Label>{i18n("Follow-up Date")}</Form.Label>
                  </Col>
                  <Col lg={12}>
                    <Form.Group>
                      <DatePicker
                        className="form-control date-picker-no-border"
                        placeholderText={placeholderText("Select Date")}
                        dateFormat="dd/MM/yyyy"
                        onChange={handleFollowupDateChanegs}
                        selected={followupDate}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col lg={12}>
                    <Form.Label>{i18n("Lead Status")}</Form.Label>
                  </Col>
                  <Col lg={12}>
                    <Form.Select
                      name="lead_status"
                      value = {leadStatus}
                      onChange={(e) => {
                        handleLeadStatusChange(e.target.value)}}
                    >
                      <option value="new">{placeholderText("New")}</option>
                      <option value="open">{placeholderText("Open")}</option>
                      <option value="interested">{placeholderText("Interested")}</option>
                      <option value="not_interested">{placeholderText("Not Interested")}</option>
                      <option value="converted">{placeholderText("Converted")}</option>
                      <option value="closed">{placeholderText("Closed")}</option>
                    </Form.Select>

                  </Col>
                </Row>
                <Row>
                  <Col lg={12} className="py-3">
                    <Form.Group>
                      <Form.Label>{i18n("Notes")}</Form.Label>
                    </Form.Group>
                    <Form.Control
                      name="notes"
                      as="textarea"
                      placeholder={placeholderText("Enter notes")}
                      className="form-control"
                      value={values.notes}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                    />
                  </Col>
                </Row>
              </Card>
            </Modal.Body>
            <Modal.Footer style={{ borderTop: "1px solid lightgray" }}>
              <Row className="py-1">
                <Col className="d-flex justify-content-end">
                  <Button variant="primary mx-2" id="submitButton" onClick={handleSubmit}>
                    {i18n("Save")}
                  </Button>
                  <Button
                    variant="outline-secondary mx-2"
                    onClick={handleCancel}
                  >
                    {i18n("Cancel")}
                  </Button>
                </Col>
              </Row>
            </Modal.Footer>
          </Modal>{" "}
        </>
      )}
    </Formik>
  );
};

export default EditEnquiry;
