import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import "../../../assets/css/frontend.css";
import {
  selectpartyById,
} from "../../party/store/getPartySlice";
import { useNavigate } from "react-router";
import PermanentDeleteModel from "../../shared/components/PermanentDeleteModel";
import DataGridHeader from "../../shared/components/DataGridHeader";
import { classNames } from 'primereact/utils';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { downloadCsvFile, handleExportClick } from "../../shared/export";
import Layout from "../../shared/components/Layout";
import DeletedRecordHeader from "../components/DeletedRecordHeader";
import { deleteParty } from "../../party/store/getPartySlice";
import { Container, Form, Stack } from "react-bootstrap";
import Header from "../../shared/components/Header";
import { i18n } from "../../shared/helpers/sharedMethods";
import TwoStageLayoutHeader from "../../shared/components/TwoStatgeLayoutHeader";
import { UilUsersAlt } from "@iconscout/react-unicons";
import { UilEdit, UilTrashAlt, UilHistory } from "@iconscout/react-unicons";
import { toast, ToastContainer } from "react-toastify";
import ShortcutKeys from "../../shared/components/ShortcutKeys";
import CustomeSortIcon from "../../shared/components/CustomeSortIcon";
import { getLocalizedDate } from "../../shared/helpers/localizationHelper";
import { selectAllDeletedPartys, getAllDeletedPartys,deletedPartyRestore,deletePartyPermanently, resetDeletedParty, selectPartyType } from "../../party/store/partyDeletedSlice";
import PartyType from "../../party/components/category/PartyType";
import RestoreModel from "../../shared/components/RestoreModel";

const DeletedPartyIndex = (props) => {
  const { type, path, title } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  
  const partyType = useSelector((state) => state.partysDeletedRecords.partyType);
  const partyByType = useSelector((state) => selectPartyType(state, partyType));

  const allPartys = useSelector(selectAllDeletedPartys);
  const status = useSelector((state) => state.partysDeletedRecords.status);
  const [balanceFrozen, setBalanceFrozen] = useState(false);
  const [globalFilter, setGlobalFilter] = useState('');
  const [globalFilterValue, setGlobalFilterValue] = useState('');
  const [show, setShow] = useState(false);
  const [deleteId, setDeleteId] = useState();
  const [deleteName, setDeleteName] = useState();
  const [exportData, setExportData] = useState([]);
  const [csvData, setCsvData] = useState("");
  const [parties, setParties] = useState([]);
  const [showDetails, setShowDetails] = useState(false);
  const [id, setId] = useState();
  const [restoreShow, setShowRestore] = useState(false);
  const [restoreId, setRestoreId] = useState();
  const [restoreName, setRestoreName] = useState();
  
  const selectedBusiness = useSelector(
    (state) => state?.auth?.selectedBusiness
  );

  const tenantId = selectedBusiness?.pivot?.tenant_id;
  const accessToken = useSelector(
    (state) => state?.auth?.accessToken?.access_token
  );

  const [filters, setFilters] = useState(null);
  const [sortField, setSortField] = useState(null);
  const [sortOrder, setSortOrder] = useState(1);
  const selectedParty = useSelector((state) => selectpartyById(state, id));

  const handleClearFilters = () => {
    setFilters({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      name: { value: null, matchMode: FilterMatchMode.CONTAINS },
      phone_number: { value: null, matchMode: FilterMatchMode.CONTAINS },
      created_at: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
      deleted_at: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },

    });
    setGlobalFilter('');
    setGlobalFilterValue('');
    setParties(allPartys);
  };
  
  useEffect(() => {
    dispatch(getAllDeletedPartys({ token: accessToken, tenantId: tenantId }));
  }, [dispatch]);

  useEffect(() => {
    if (partyByType) {
      setParties(partyByType);
    }
  }, [partyByType]);

  useEffect(() => {
    if (allPartys.length > 0) {
      setParties(allPartys);
      handleClose();
    }
  }, [allPartys]);
  const handleClose = () => {
    setShow(false);
  };
  const handleRefreshParty = () => {
    setParties([]);
    dispatch(getAllDeletedPartys({ token: accessToken, tenantId: tenantId }));
    dispatch(resetDeletedParty());
  };
  useEffect(() => {
    if (!type === "supplier" || !type === "customer") {
      setParties([]);
    } else {
      setParties(allPartys);
    }
  }, [type, allPartys]);

  const handleParmanentDelete = async () => {
    try {
        await dispatch(deletePartyPermanently({ id: deleteId, token: accessToken, tenantId: tenantId }));
        setShow(false);
        setParties(parties.filter(party => party.id !== restoreId));
        navigate(`/setting/deleted-items/parties`);
        toast.success(i18n("Party deleted permanently"));
    } catch (error) {
        toast.error(i18n("Error deleting party"));
    }
  };

const handleRestore = async () => {
    try {
        await dispatch(deletedPartyRestore({ id: restoreId, token: accessToken, tenantId: tenantId }));
        setShowRestore(false);
        setParties(parties.filter(party => party.id !== restoreId));
        navigate(`/setting/deleted-items/parties`);
        toast.success(i18n("Party restored successfully"));
    } catch (error) {
        toast.error(i18n("Error restoring party"));
    }
  };

  const onDelete = (row) => {
    setDeleteId(row.id);
    setDeleteName(row.name);
    setShow(!show);
    navigate(`/setting/deleted-items/parties`);
  };

  const onRestore = (row) => {
    setRestoreId(row.id);
    setRestoreName(row.name);
    setShowRestore(!restoreShow);
    navigate(`/setting/deleted-items/parties`);
  };

  const actionBodyTemplate = (row) => {
    return (
      <div className="d-flex justify-content-evenly align-items-center">
        <div title="Restore">
          <UilHistory
            className="mx-2 mb-1"
            size="15"
            style={{ cursor: "pointer" }}
            onClick={(e) => onRestore(row)}
          />
        </div>
        <div title="Delete">
          <UilTrashAlt
            className="mx-2 mb-1"
            size="15"
            style={{ cursor: "pointer" }}
            onClick={() => onDelete(row)}
          />
        </div>
      </div>
    );
  };

  const handleReturnToGrid = () => {
    setShowDetails(false);
  };

  const header = (
    <TwoStageLayoutHeader
      currentTitle={
        <>
          {selectedParty?.name}
          {selectedParty?.type == "customer" ? (
            <Form.Label className="mx-2 badge bg-primary bg-gradient">
              {i18n("Customer")}
            </Form.Label>
          ) : (
            <Form.Label className="mx-2 badge bg-info bg-gradient">
              {i18n("Supplier")}
            </Form.Label>
          )}
        </>
      }
      returnToGrid={handleReturnToGrid}
    />
  );

  const headerStyle = {
    backgroundColor: 'white',
  };

  const headerCellStyle = {
    fontWeight: 'bold',
    fontSize: '11px',
  };

  const dateBodyTemplate = (rowData) => {
    return getLocalizedDate(rowData?.created_at);
  };

  const handleCreatedAtDateFilterChange = (value, filterCallback) => {
    filterCallback(value);

    if (value) {
      const filtered = allPartys.filter((item) => {
        const createdAtDate = new Date(item.created_at);
        const selectedDate = new Date(value);
        return createdAtDate.toDateString() === selectedDate.toDateString();
      });
      setParties(filtered);
    } else {
      setParties(allPartys);
    }
  };

  const dateFilterTemplate = (options) => {
    return (
      <Calendar value={options.value} onChange={(e) => handleCreatedAtDateFilterChange(e.value, options.filterCallback)} dateFormat="dd/mm/yy" mask="99/99/9999" />
    );
  };

  const deletedDateBodyTemplate = (rowData) => {
    return getLocalizedDate(rowData?.deleted_at);
  };

  const handleDeletedAtDateFilterChange = (value, filterCallback) => {
    filterCallback(value);

    if (value) {
      const filtered = allPartys.filter((item) => {
        const deletedAtDate = new Date(item.deleted_at);
        const selectedDate = new Date(value);
        return deletedAtDate.toDateString() === selectedDate.toDateString();
      });
      setParties(filtered);
    } else {
      setParties(allPartys);
    }
  };

  const deletedDateFilterTemplate = (options) => {
    return (
      <Calendar value={options.value} onChange={(e) => handleDeletedAtDateFilterChange(e.value, options.filterCallback)} dateFormat="dd/mm/yy" mask="99/99/9999" />
    );
  };

  const customSort = (field) => {
    setSortField(field);
    setSortOrder(sortOrder === 1 ? -1 : 1);
  };

  const sortedData = [...parties].sort((a, b) => {
    if (sortField) {
      const compareValueA = a[sortField];
      const compareValueB = b[sortField];
      if (compareValueA < compareValueB) {
        return sortOrder;
      }
      if (compareValueA > compareValueB) {
        return -sortOrder;
      }
    }
    return 0;
  });

  return (
    <div>
      <ToastContainer />
      <Layout>
        <Header
          currentTitle={i18n("Parties")}
          ItemActions={
            <Stack
              gap={3}
              direction="horizontal"
              className="my-2 justify-content-end"
            >
              <ShortcutKeys />
            </Stack>
          }
        />
        <Container fluid className="px-5">
          <div className="border" style={{ background: "white" }}>
            <DeletedRecordHeader />
            <div>

              <DataGridHeader
                handleRefreshParty={handleRefreshParty}
                statusFilter={null}
                clearFilter={handleClearFilters}
                data={parties}
                allData={allPartys}
                addFilteredData={setParties}
                // handleCreate={handleCreate}
                // handleExportClick={handleExportParty}
                datas={exportData}
                title="Parties"
                icons={<UilUsersAlt size="50" color="gray" />}
                FilterActions={
                  <Stack
                    gap={3}
                    direction="horizontal"
                    className=" justify-content-end"
                  >
                      <PartyType />
                  </Stack>
                }
              />

              <Container fluid className="px-4 mb-4">
                <div className="bordered-container">

                    <DataTable
                      value={sortedData}
                      // headerStyle={headerStyle}
                      paginator
                      globalFilter={globalFilter}
                      // globalFilterFields={['name', 'phone_number','category.name','gstin', 'pincode', 'customer_since']}
                      filterDisplay="row"
                      rows={10}
                      dataKey="id"
                      filters={filters}
                      emptyMessage={i18n("No deleted parties found.")}
                      onFilter={(e) => setFilters(e.filters)}
                      className="p-datatable-gridlines full-width-table custom-datatable p-component"
                      // onRowClick={(e) => handlePartyRowClick(e.data)}
                      checked={balanceFrozen}
                      scrollable
                      scrollHeight="400px"
                      sortMode="multiple"
                      removableSort
                      style={{ cursor: "pointer" }}
                    >
                      <Column
                        field="name"
                        header={() => (
                          <div
                            className={`custom-header-${sortField === 'name' ? (sortOrder === 1 ? 'up' : 'down') : ''}`}
                            onClick={() => customSort('name')}
                            style={{
                              cursor: 'pointer',
                              display: 'flex',
                              alignItems: 'center',
                              fontSize: '11px',
                              lineHeight: '11px',
                            }}
                          >
                            {i18n("Name")}
                            <CustomeSortIcon sortOrder={sortField === 'name' ? sortOrder : 0}
                              style={{ marginLeft: '4px', verticalAlign: 'middle' }} />
                          </div>
                        )}
                        sortable
                        filter
                        filterPlaceholder=""
                        headerStyle={headerCellStyle}
                        showFilterMatchModes={false}
                        showClearButton={false}
                        showFilterOperator={false}
                        showAddButton={false}
                        filterMatchMode={FilterMatchMode.CONTAINS}
                        showApplyButton={false}
                        showFilterMenu={false}
                      />
                      <Column
                        field="phone_number"
                        header={() => (
                          <div
                            className={`custom-header-${sortField === 'phone_number' ? (sortOrder === 1 ? 'up' : 'down') : ''}`}
                            onClick={() => customSort('phone_number')}
                            style={{
                              cursor: 'pointer',
                              display: 'flex',
                              alignItems: 'center',
                              fontSize: '11px',
                              lineHeight: '11px',
                            }}
                          >
                            {i18n("Phone No")}
                            <CustomeSortIcon sortOrder={sortField === 'phone_number' ? sortOrder : 0}
                              style={{ marginLeft: '4px', verticalAlign: 'middle' }} />
                          </div>
                        )}
                        sortable
                        filter
                        filterPlaceholder=""
                        headerStyle={headerCellStyle}
                        showFilterMatchModes={false}
                        showClearButton={false}
                        showFilterOperator={false}
                        showAddButton={false}
                        filterMatchMode={FilterMatchMode.CONTAINS}
                        showApplyButton={false}
                        showFilterMenu={false}
                      />
                      <Column
                        field="created_at"
                        header={() => (
                          <div
                            className={`custom-header-${sortField === 'created_at' ? (sortOrder === 1 ? 'up' : 'down') : ''}`}
                            onClick={() => customSort('created_at')}
                            style={{
                              cursor: 'pointer',
                              display: 'flex',
                              alignItems: 'center',
                              fontSize: '11px',
                              lineHeight: '11px',
                            }}
                          >
                            {i18n("Customer Since")}
                            <CustomeSortIcon sortOrder={sortField === 'created_at' ? sortOrder : 0}
                              style={{ marginLeft: '4px', verticalAlign: 'middle' }} />
                          </div>
                        )}
                        sortable
                        body={dateBodyTemplate}
                        filter
                        filterElement={dateFilterTemplate}
                        filterPlaceholder=""
                        headerStyle={headerCellStyle}
                        showFilterMatchModes={false}
                        showClearButton={false}
                        showFilterOperator={false}
                        showAddButton={false}
                        filterMatchMode={FilterMatchMode.DATE_IS}
                        // showApplyButton={false}
                        showFilterMenu={false}
                      />
                      <Column
                        field="deleted_at"
                        header={() => (
                          <div
                            className={`custom-header-${sortField === 'deleted_at' ? (sortOrder === 1 ? 'up' : 'down') : ''}`}
                            onClick={() => customSort('deleted_at')}
                            style={{
                              cursor: 'pointer',
                              display: 'flex',
                              alignItems: 'center',
                              fontSize: '11px',
                              lineHeight: '11px',
                            }}
                          >
                            {i18n("Deleted At")}
                            <CustomeSortIcon sortOrder={sortField === 'deleted_at' ? sortOrder : 0}
                              style={{ marginLeft: '4px', verticalAlign: 'middle' }} />
                          </div>
                        )}
                        sortable
                        body={deletedDateBodyTemplate}
                        filter
                        filterElement={deletedDateFilterTemplate}
                        filterPlaceholder=""
                        headerStyle={headerCellStyle}
                        showFilterMatchModes={false}
                        showClearButton={false}
                        showFilterOperator={false}
                        showAddButton={false}
                        filterMatchMode={FilterMatchMode.DATE_IS}
                        // showApplyButton={false}
                        showFilterMenu={false}
                      />
                      <Column
                        header={i18n("Actions")}
                        body={actionBodyTemplate}
                        headerStyle={headerCellStyle}
                      />
                    </DataTable>
                </div>
              </Container>
              {show && (
                <PermanentDeleteModel
                  onClickDeleteModel={() => setShow(false)}
                  deleteUserClick={handleParmanentDelete}
                  name={deleteName}
                  show={show}

                />
              )}
              {restoreShow && (
                <RestoreModel
                  onClickRestoreModel={() => setShowRestore(false)}
                  restoreUserClick={handleRestore}
                  name={restoreName}
                  show={restoreShow}

                />
              )}
            </div>
          </div>
        </Container>
      </Layout>
    </div>
  );
};

export default DeletedPartyIndex;
