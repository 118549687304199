import { useNavigate } from "react-router";

import "../../../../assets/css/frontend.css";
import {
  DeleteSubscription,
  SubscriptionData,
  resetSubscription,
  selectAllSubscriptionData,
  selectSubscriptionDataById,
} from "../../store/SubscriptionSlice";

import { DataTable } from "primereact/datatable";
import { classNames } from "primereact/utils";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { IconField } from "primereact/iconfield";
import { InputIcon } from "primereact/inputicon";
import { Dropdown } from "react-bootstrap";
import { InputNumber } from "primereact/inputnumber";
//import { Button } from "primereact/button";
import { ProgressBar } from "primereact/progressbar";
import { Calendar } from "primereact/calendar";
import { MultiSelect } from "primereact/multiselect";
import { Slider } from "primereact/slider";
import { Tag } from "primereact/tag";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import DeleteModel from "../../../shared/components/DeleteModel";
import Layout from "../../../shared/components/Layout";
import { resetCreateSubscription } from "../../store/CreateSubscriptionSlice";
import {
  resetAddon,
  resetProductDropdown,
} from "../../store/GetParentProductEntiry";
import { resetSubscriptionParty } from "../../../subscription/store/LivePartyGetsSlice";
import { Badge, Container, Stack, Image, Button, InputGroup, ButtonGroup } from "react-bootstrap";
import Header from "../../../shared/components/Header";
import { i18n } from "../../../shared/helpers/sharedMethods";
import { changeToggleShow } from "../../../shared/store/sharedSlice";
import { faFileInvoice } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { UilUserPlus } from "@iconscout/react-unicons";
import DetailsModal from "../../../shared/components/DetailsModal";
import TwoStageLayoutHeader from "../../../shared/components/TwoStatgeLayoutHeader";
import OverViewSubscription from "../../components/subscription_order/tabs/Overview";
import InvoiceHistory from "../../components/subscription_order/tabs/InvoiceHistory";
import Attendance from "../../components/subscription_order/tabs/Attendance";
import { SubscriptionCancell } from "../../store/SubscriptionCancellSlice";
import { subscriptionReactive } from "../../store/SubscriptionReactiveSlice";
import {
  faCancel,
  faCirclePlay,
  faPenToSquare,
} from "@fortawesome/free-solid-svg-icons";
import SubscriptionItemHighlights from "../../components/SubscriptionItemHighlights";
import { UilEdit, UilTrashAlt, UilAngleDown, UilBan } from "@iconscout/react-unicons";
//import CancelSubscription from "../../components/CancelSubscription";
//import { resetSubscriptionItem } from "../../store/subscriptionProductEntitySlice";
import { getLocalizedDate } from "../../../shared/helpers/localizationHelper";
import ShortcutKeys from "../../../shared/components/ShortcutKeys";
import ReactiveSubscription from "../../components/ReactiveSubscription";
import CancelSubscription from "../../components/CancelSubscription";
import { resetSubscriptionItem } from "../../store/subscriptionProductEntitySlice";
import { fetchSetting } from "../../../settings/store/getSettingSlice";
import { UilUsersAlt } from "@iconscout/react-unicons";
import DataGridHeader from "../../../shared/components/DataGridHeader";
import CustomeSortIcon from "../../../shared/components/CustomeSortIcon";
import LoadingSpinner from '../../../shared/components/LoadingSpinner';
import GlobalCurrency from "../../../shared/components/GlobalCurrency";
import { handleExportClick, downloadCsvFile } from "../../../shared/export";
import { format } from "date-fns";
const SubscriptionStack = ({ featureName }) => {
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const [show, setShow] = useState(false);
  const [id, setId] = useState();
  const [name, setName] = useState();
  const [showDetails, setShowDetails] = useState(false);
  const alldata = useSelector(selectAllSubscriptionData);
  console.log("alldata",alldata);
  const [subscriptionData, setSubscriptionData] = useState(alldata);
  const [showCancel, setShowCancel] = useState(false);
  const [cancelId, setCancelId] = useState(null);
  const [showReactive, setShowReactive] = useState(false);
  const [refreshKey, setRefreshKey] = useState(0);
  const [globalFilter, setGlobalFilter] = useState("");
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [filters, setFilters] = useState(null);
  const [balanceFrozen, setBalanceFrozen] = useState(false);
  const [statusOptions, setStatusOptions] = useState([]);
  const [selectedstatus, setSelectedStatus] = useState([]);
  const [sortField, setSortField] = useState(null);
  const [sortOrder, setSortOrder] = useState(1);

  const [csvData, setCsvData] = useState('');
  // const globalPlanFeatures = useSelector(
  //   (state) => state?.globalPlan?.features
  // );
  const globalPlanFeatures = useSelector(
      (state) => state?.globalPlan?.features
  );


  useEffect(() => {
    if (!globalPlanFeatures.includes(featureName)) {
      navigate("/setting/not-in-plan");
    }
  }, []);

  const { accessToken, isLoggedIn } = useSelector((state) => state?.auth);

  const currencySymbol = useSelector(
    (state) => state.getSetting?.dataSetting.currency
  );
  const selectedBusiness = useSelector(
    (state) => state?.auth?.selectedBusiness
  );

  const tenantId = selectedBusiness?.pivot?.tenant_id;
  const subsData = useSelector((state) =>
    selectSubscriptionDataById(state, id)
  );
  const subscriptionCancell = useSelector(
    (state) => state.subscriptionCancell.cancell
  );
  const loading = useSelector((state) => state.subscription.loading);
  const reactivation = useSelector(
    (state) => state.subscriptionReactive.reactive
  );
  const filterData =
    alldata &&
    alldata.length > 0 &&
    alldata.filter((item) => item != undefined);
  useEffect(() => {
    setSubscriptionData(alldata);
  }, [alldata]);

  const handleRefreshMembership = () => {
    dispatch(SubscriptionData({
      token: accessToken.access_token,
      tenant_id: tenantId,
    }));
    dispatch(resetSubscription());
    setRefreshKey((prevKey) => prevKey + 1);
  };

  useEffect(() => {
    const handleOnlineStatusChange = () => {
      setIsOnline(navigator.onLine);
    };

    window.addEventListener("online", handleOnlineStatusChange);
    window.addEventListener("offline", handleOnlineStatusChange);

    return () => {
      window.removeEventListener("online", handleOnlineStatusChange);
      window.removeEventListener("offline", handleOnlineStatusChange);
    };
  }, []);

  useEffect(() => {
    if (!isOnline) {
      navigate("/dashboard/sales", { state: true });
    }
  }, [isOnline]);

  const handleClick = () => {
    dispatch(resetSubscriptionItem());
    dispatch(resetCreateSubscription());
    dispatch(resetSubscriptionParty());
    dispatch(resetProductDropdown());
    dispatch(changeToggleShow(true));
    navigate("/subscription/membership/create");
  };

  useEffect(() => {
    dispatch(
      SubscriptionData({
        token: accessToken.access_token,
        tenant_id: tenantId,
      })
    );
  }, [tenantId, subscriptionCancell, reactivation]);

  const handleEdit = (data) => {
    dispatch(resetSubscriptionItem());
    dispatch(resetCreateSubscription());
    dispatch(resetProductDropdown());
    //dispatch(resetAddon());
    dispatch(changeToggleShow(true));
    navigate("/subscription/editSubscription/" + data.id);
    setShowDetails(false);
  };

  const handleView = (row) => {

    // navigate("/subscription/membership/view/" + data.id);
    setShowDetails(!showDetails);
    setId(row.id);
  };

  const handleDelete = async (data) => {
    setShow(!show);
    setId(data.id);
    setName(data.name);
    setShowDetails(false);
  };

  const onDelete = () => {
    dispatch(
      DeleteSubscription({
        id: id,
        token: accessToken.access_token,
        tenant_id: tenantId,
      })
    );
    setShow(false);
    navigate("/subscription/membership");
  };

  const handleCancel = (id) => {
    setShowCancel(true);
    setCancelId(id);
  };

  const confirmCancel = () => {
    dispatch(
      SubscriptionCancell({
        id: cancelId,
        token: accessToken.access_token,
        tenantId: tenantId,
      })
    );
    setShowCancel(false);
  };

  const handleReactive = (id) => {
    setShowReactive(true);
    setId(id);
  };
  const confirmReactivate = () => {
    dispatch(
      subscriptionReactive({
        id: id,
        token: accessToken.access_token,
        tenantId: tenantId,
      })
    );
    setShowReactive(false);
  };
  const handleReturnToGrid = () => {
    setShowDetails(false);
  };
  const prepareExportData = () => {
    return alldata.map(subscription => ({
      ID: subscription.id,
      Member: subscription.member?.member_name,

      Plan: subscription.subscription_items[0]?.product?.name,
      TxnNo: subscription.subscription_number,
      StartDate: subscription.bill_queue.start_date
      ? format(new Date( subscription.start_date), "dd-MM-yyyy")
      : "-",
      NextBill:subscription.bill_queue.next_bill_date? format(new Date( subscription.bill_queue.next_bill_date), "dd-MM-yyyy")
      : "-",
      GrandTotal: subscription.grand_total,
      CreatedAt: subscription.created_at,
      Status: subscription.subscription_vouchers[0]?.status || 'N/A',
    }));
  };

  const handleExport = () => {
    const exportData = prepareExportData();
    const fileName = 'Memberships';
    handleExportClick(exportData, setCsvData, downloadCsvFile, fileName);
  };
   /* eslint-disable no-restricted-globals */

  useEffect(() => {
    dispatch(
      fetchSetting({
        accessToken: accessToken.access_token,
        tenant_id: selectedBusiness?.pivot?.tenant_id,
      })
    );
  }, [status]);
   /* eslint-enable no-restricted-globals */

  const subscriptionTabs = [
    {
      key: "overView",
      title: i18n("Overview"),
      component: <OverViewSubscription data={subsData} />,
    },
    {
      key: "invoice history",
      title: i18n("Invoice History"),
      component: <InvoiceHistory data={subsData} />,
    },
    {
      key: "attendance",
      title: i18n("Attendance"),
      component: <Attendance data={subsData} />
    }
  ];

  const header = (
    <TwoStageLayoutHeader
      ItemActions={
        <Stack
          gap={3}
          direction="horizontal"
          className="mt-2 mb-2 float-end"
        >
          {subsData?.is_active === 0 ? (
            <Button
              className=""
              variant="outline-info"
              onClick={() => handleReactive(id)}
            >
              <FontAwesomeIcon className="mx-2 mb-1" icon={faCirclePlay} />
              {i18n("Reactive")}
            </Button>
          ) : null}

          <Button
            className=""
            variant="outline-danger"
            onClick={() => handleCancel(id)}
          >
            <UilBan
              className="mx-2 mb-1"
              size="16"
              style={{ cursor: "pointer" }}
            />
            {i18n("Cancel Subscription")}
          </Button>

          {/* <Button
            className=""
            variant="outline-secondary"
            onClick={() => handleEdit(subsData)}
          >
            <UilEdit
              className="mx-2 mb-1"
              size="16"
              style={{ cursor: "pointer" }}
            />
            {i18n("Edit")}
          </Button> */}
        </Stack>
      }
      currentTitle={
        <>
          {/* {subsData?.party?.name}  */}# {subsData?.subscription_number}
          <Badge
            className="mx-2 mb-1 bold"
            bg={subsData?.is_active === 1 ? "success" : "danger"}
          >
            {subsData?.is_active === 1 ? "Active" : " Cancel"}
          </Badge>
        </>
      }
      returnToGrid={handleReturnToGrid}
    />
  );



  const actionBodyTemplate = (row) => {
    return (
      <div className="d-flex justify-content-evenly align-items-center">
        <UilEdit
          className="mx-2 mb-1"
          size="15"
          style={{ cursor: "pointer" }}
          onClick={(e) => {
            e.stopPropagation(); // Prevents row click from firing
            handleEdit(row);
          }}
        />
        &nbsp;
        {/* <i className="fa fa-trash mx-2 " aria-hidden="true"></i> */}
        <UilTrashAlt
          className="mx-2 mb-1"
          size="15"
          style={{ cursor: "pointer" }}
          onClick={(e) => {
            e.stopPropagation(); // Prevents row click from firing
            handleDelete(row);
          }}
        />
      </div>
    );
  };

  const handleClearFilters = () => {
    setSelectedStatus([]);
    setFilters({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      "member.member_name": { value: null, matchMode: FilterMatchMode.CONTAINS },
      "subscription_items[0]?.product?.name": {
        value: null,
        matchMode: FilterMatchMode.CONTAINS,
      },
      subscription_number: { value: null, matchMode: FilterMatchMode.CONTAINS },
      start_date: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
      },
      "bill_queue.next_bill_date": {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
      },
      grand_total: { value: null, matchMode: FilterMatchMode.EQUALS },
      created_at: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
      },
      is_active: { value: null, matchMode: "in" },
    });
    setGlobalFilter("");
    setGlobalFilterValue("");
    setSubscriptionData(alldata);
  };

  const nextDateBodyTemplate = (rowData) => {
    return getLocalizedDate(rowData.bill_queue.next_bill_date);
  };

  const dateBodyTemplate = (rowData) => {
    return getLocalizedDate(rowData.start_date);
  };

  const handleDateFilterChange = (value, field, filterCallback) => {
    filterCallback(value);

    if (value) {
      const filtered = alldata.filter((item) => {
        const memberDate = new Date(item[field]);
        const selectedDate = new Date(value);
        return memberDate.toDateString() === selectedDate.toDateString();
      });
      setSubscriptionData(filtered);
    } else {
      setSubscriptionData(alldata);
    }
  };

  const handleNextBillDateFilterChange = (value, filterCallback) => {
    filterCallback(value);

    if (value) {
      const filtered = alldata.filter((item) => {
        const billDate = new Date(item.bill_queue.next_bill_date);
        const selectedDate = new Date(value);
        return billDate.toDateString() === selectedDate.toDateString();
      });
      setSubscriptionData(filtered);
    } else {
      setSubscriptionData(alldata);
    }
  };

  const nextDateFilterTemplate = (options) => {
    return (
      <Calendar
        value={options.value}
        onChange={(e) =>
          handleNextBillDateFilterChange(e.value, options.filterCallback)
        }
        dateFormat="dd-mm-yy"
        mask="99-99-9999"
      />
    );
  };

  const dateFilterTemplate = (options) => {
    return (
      <Calendar
        value={options.value}
        onChange={(e) =>
          handleDateFilterChange(e.value, "start_date", options.filterCallback)
        }
        dateFormat="dd-mm-yy"
        mask="99-99-9999"
      />
    );
  };

  const dateCreatedAtBodyTemplate = (rowData) => {
    return getLocalizedDate(rowData.created_at);
  };

  const handleCreatedAtDateFilterChange = (value, filterCallback) => {
    filterCallback(value);

    if (value) {
      const filtered = alldata.filter((item) => {
        const createdAtDate = new Date(item.created_at);
        const selectedDate = new Date(value);
        return createdAtDate.toDateString() === selectedDate.toDateString();
      });
      setSubscriptionData(filtered);
    } else {
      setSubscriptionData(alldata);
    }
  };

  const dateCreatedAtFilterTemplate = (options) => {
    return (
      <Calendar
        value={options.value}
        onChange={(e) =>
          handleCreatedAtDateFilterChange(e.value, options.filterCallback)
        }
        dateFormat="dd/mm/yy"
        mask="99/99/9999"
      />
    );
  };

  const handleStatusChange = (e) => {
    setSelectedStatus(e.value);

    if (!Array.isArray(e.value)) {
      console.error("Expected e.value to be an array, but got:", e.value);
      return;
    }

    const selectedStatuses = e.value.map((option) => option.name.toLowerCase());

    if (selectedStatuses.length === 0) {
      setSubscriptionData(alldata);
    } else {
      const filteredStatus = alldata.filter((item) => {
        const status = item.is_active === 1 ? "active" : "cancel";
        return selectedStatuses.includes(status);
      });
      setSubscriptionData(filteredStatus);
    }
  };

  const statusLabel = {
    '1': 'Active',
    '0': 'Cancelled'
  }

  const statusBodyTemplate = (rowData) => {
    const status = rowData.is_active === 1 ? "Active" : "Cancel";
    const severityClass = getSeverity(status);
    const statusMessage = statusLabel[rowData.is_active]
    return <Tag value={statusMessage} className={severityClass} />;
  };

  const getSeverity = (status) => {
    switch (status) {
      case "Active":
        return "custom-tag-success";
      case "Cancel":
        return "custom-tag-danger";
      default:
        return null;
    }
  };

  useEffect(() => {
    const options = [
      { name: "Active", label: "Active" },
      { name: "Cancel", label: "Cancelled" },
    ];
    setStatusOptions(options);
  }, []);

  const statusItemTemplate = (option) => {
    const severityClass = getSeverity(option.name);
    return (
      <div className={`d-flex align-items-center ${severityClass}`}>
        <Tag value={option.label} className={severityClass} />
      </div>
    );
  };

  const statusFilterTemplate = (options) => {
    return (
      <MultiSelect
        value={selectedstatus}
        options={statusOptions}
        optionLabel="label"
        itemTemplate={statusItemTemplate}
        onChange={handleStatusChange}
        className="p-column-filter custom-multiselect"
        style={{ fontSize: "11px" }}
      />
    );
  };

  const headerCellStyle = {
    fontWeight: "bold",
    fontSize: "11px",
  };

  const customSort = (field) => {
    if (field === 'created_at') {
      setSortOrder(-1);
    } else {
      setSortField(field);
      setSortOrder(-1);
    }
  };

  const sortedData = [...subscriptionData].sort((a, b) => {
    const dateA = new Date(a.created_at);
    const dateB = new Date(b.created_at);

    return dateB - dateA;
  });


  return (
    <Layout>
      {loading && <LoadingSpinner />}
      <Header
        currentTitle={i18n("Memberships")}
        ItemActions={
          <Stack
            gap={3}
            direction="horizontal"
            className="my-2 justify-content-end"
          >
            <ShortcutKeys />
          </Stack>
        }
      />
      <Container fluid className="px-5">
        <div className="border" style={{ background: "white" }}>
          <DataGridHeader
            handleRefreshMembership={handleRefreshMembership}
            allData={alldata}
            data={alldata}
            addFilteredData={setSubscriptionData}
            clearFilter={handleClearFilters}
            handleExportClick={handleExport}
            title="Memberships"
            icons={<UilUserPlus size="50" color="gray" />}
            ItemActions={
              <Stack
                gap={3}
                direction="horizontal"
                className="justify-content-end"
              >
                <Button
                  onClick={handleClick}
                >
                  {i18n("Create Membership")}
                </Button>
              </Stack>
            }
          />

          <Container fluid className="px-4 mb-4">
            <div className="bordered-container">
              <DataTable
                value={sortedData}
                // isAllData={"All Members"}
                button="Create Membership"
                title="Memberships"
                paginator
                globalFilter={globalFilter}
                // globalFilterFields={['id', 'name','subscription_number','start_date', 'next_bill_date', 'grand_total','is_active']}
                filterDisplay="row"
                rows={10}
                dataKey="id"
                filters={filters}
                emptyMessage={i18n(
                  "No memberships found. Please create a new membership."
                )}
                onFilter={(e) => setFilters(e.filters)}
                className="p-datatable-gridlines full-width-table custom-datatable p-component"
                onRowClick={(e) => handleView(e.data)}
                checked={balanceFrozen}
                scrollable
                sortMode="multiple"
                scrollHeight="400px"
                removableSort
                style={{ cursor: "pointer" }}
              >
                <Column
                  field="member.member_name"
                  header={() => (
                    <div
                      className={`custom-header-${sortField === "member.member_name"
                        ? sortOrder === 1
                          ? "up"
                          : "down"
                        : ""
                        }`}
                      onClick={() => customSort("member.member_name")}
                      style={{
                        cursor: "pointer",
                        display: "flex",
                        alignItems: "center",
                        fontSize: "11px",
                        lineHeight: "11px",
                      }}
                    >
                      {i18n("Member")}
                      <CustomeSortIcon
                        sortOrder={sortField === "member.member_name" ? sortOrder : 0}
                        style={{ marginLeft: "4px", verticalAlign: "middle" }}
                      />
                    </div>
                  )}
                  sortable
                  filter
                  filterPlaceholder=""
                  headerStyle={headerCellStyle}
                  showFilterMatchModes={false}
                  showClearButton={false}
                  showFilterOperator={false}
                  showAddButton={false}
                  filterMatchMode={FilterMatchMode.CONTAINS}
                  showApplyButton={false}
                  showFilterMenu={false}
                />
                <Column
                  field="subscription_items?.product?.name"
                  header={() => (
                    <div
                      className={`custom-header-${sortField === "subscription_items?.product?.name"
                        ? sortOrder === 1
                          ? "up"
                          : "down"
                        : ""
                        }`}
                      onClick={() =>
                        customSort("subscription_items?.product?.name")
                      }
                      style={{
                        cursor: "pointer",
                        display: "flex",
                        alignItems: "center",
                        fontSize: "11px",
                        lineHeight: "11px",
                      }}
                    >
                      {i18n("Plan")}
                      <CustomeSortIcon
                        sortOrder={
                          sortField === "subscription_items?.product?.name"
                            ? sortOrder
                            : 0
                        }
                        style={{ marginLeft: "4px", verticalAlign: "middle" }}
                      />
                    </div>
                  )}
                  body={(rowData) => {
                    const parentProductName =
                      rowData.subscription_items[0]?.product.parent_product
                        ?.name || "-";
                    const productName =
                      rowData.subscription_items[0]?.product?.name || "-";
                    return (
                      <div style={{ textAlign: "center" }}>
                        {parentProductName} - {productName}
                      </div>
                    );
                  }}
                  sortable
                  filter
                  filterPlaceholder=""
                  headerStyle={headerCellStyle}
                  showFilterMatchModes={false}
                  showClearButton={false}
                  showFilterOperator={false}
                  showAddButton={false}
                  filterMatchMode={FilterMatchMode.CONTAINS}
                  showApplyButton={false}
                  showFilterMenu={false}
                />

                <Column
                  field="subscription_number"
                  header={() => (
                    <div
                      className={`custom-header-${sortField === "subscription_number"
                        ? sortOrder === 1
                          ? "up"
                          : "down"
                        : ""
                        }`}
                      onClick={() => customSort("subscription_number")}
                      style={{
                        cursor: "pointer",
                        display: "flex",
                        alignItems: "center",
                        fontSize: "11px",
                        lineHeight: "11px",
                      }}
                    >
                      {i18n("TXN NO")}
                      <CustomeSortIcon
                        sortOrder={
                          sortField === "subscription_number" ? sortOrder : 0
                        }
                        style={{ marginLeft: "4px", verticalAlign: "middle" }}
                      />
                    </div>
                  )}
                  sortable
                  filter
                  filterPlaceholder=""
                  headerStyle={headerCellStyle}
                  showFilterMatchModes={false}
                  showClearButton={false}
                  showFilterOperator={false}
                  showAddButton={false}
                  filterMatchMode={FilterMatchMode.CONTAINS}
                  showApplyButton={false}
                  showFilterMenu={false}
                />

                <Column
                  field="start_date"
                  header={() => (
                    <div
                      className={`custom-header-${sortField === "start_date"
                        ? sortOrder === 1
                          ? "up"
                          : "down"
                        : ""
                        }`}
                      onClick={() => customSort("start_date")}
                      style={{
                        cursor: "pointer",
                        display: "flex",
                        alignItems: "center",
                        fontSize: "11px",
                        lineHeight: "11px",
                      }}
                    >
                      {i18n("start date")}
                      <CustomeSortIcon
                        sortOrder={sortField === "start_date" ? sortOrder : 0}
                        style={{ marginLeft: "4px", verticalAlign: "middle" }}
                      />
                    </div>
                  )}
                  body={(row) =>
                    row.start_date ? (
                      <span>{getLocalizedDate(row.start_date, 'en-IN').split(',')[0]}</span>
                    ) : (
                      "-"
                    )
                  }
                  sortable
                  //body={dateBodyTemplate}
                  filter
                  dataType="date"
                  filterElement={dateFilterTemplate}
                  filterPlaceholder=""
                  headerStyle={headerCellStyle}
                  showFilterMatchModes={false}
                  showClearButton={false}
                  showFilterOperator={false}
                  showAddButton={false}
                  filterMatchMode={FilterMatchMode.DATE_IS}
                  showApplyButton={false}
                  showFilterMenu={false}
                />

                <Column
                  field="bill_queue.next_bill_date"
                  header={() => (
                    <div
                      className={`custom-header-${sortField === "bill_queue.next_bill_date"
                        ? sortOrder === 1
                          ? "up"
                          : "down"
                        : ""
                        }`}
                      onClick={() => customSort("bill_queue.next_bill_date")}
                      style={{
                        cursor: "pointer",
                        display: "flex",
                        alignItems: "center",
                        fontSize: "11px",
                        lineHeight: "11px",
                      }}
                    >
                      {i18n("next bill")}
                      <CustomeSortIcon
                        sortOrder={
                          sortField === "bill_queue.next_bill_date"
                            ? sortOrder
                            : 0
                        }
                        style={{ marginLeft: "4px", verticalAlign: "middle" }}
                      />
                    </div>
                  )}
                  body={(row) =>
                    row?.bill_queue?.next_bill_date ? (
                      <span>{getLocalizedDate(row?.bill_queue?.next_bill_date, 'en-IN').split(',')[0]}</span>
                    ) : (
                      "-"
                    )
                  }
                  sortable
                  //body={nextDateBodyTemplate}
                  filter
                  filterPlaceholder=""
                  filterElement={nextDateFilterTemplate}
                  headerStyle={headerCellStyle}
                  showFilterMatchModes={false}
                  showClearButton={false}
                  showFilterOperator={false}
                  showAddButton={false}
                  filterMatchMode={FilterMatchMode.DATE_IS}
                  showApplyButton={false}
                  showFilterMenu={false}
                />
                <Column
                  field="grand_total"
                  header={() => (
                    <div
                      className={`custom-header-${sortField === "grand_total"
                        ? sortOrder === 1
                          ? "up"
                          : "down"
                        : ""
                        }`}
                      onClick={() => customSort("grand_total")}
                      style={{
                        cursor: "pointer",
                        display: "flex",
                        alignItems: "center",
                        fontSize: "11px",
                        lineHeight: "11px",
                      }}
                    >
                      {i18n("grand total")}
                      <CustomeSortIcon
                        sortOrder={sortField === "grand_total" ? sortOrder : 0}
                        style={{ marginLeft: "4px", verticalAlign: "middle" }}
                      />
                    </div>
                  )}
                  sortable
                  filter
                  filterPlaceholder=""
                  headerStyle={headerCellStyle}
                  showFilterMatchModes={false}
                  showClearButton={false}
                  showFilterOperator={false}
                  showAddButton={false}
                  filterMatchMode={FilterMatchMode.CONTAINS}
                  showApplyButton={false}
                  showFilterMenu={false}
                  body={(rowData) => (
                    <span>
                      <GlobalCurrency price={rowData.grand_total
                        ? rowData.grand_total
                        : "0"} />
                    </span>
                  )}
                />

                <Column
                  field="created_at"
                  header={() => (
                    <div
                      className={`custom-header-${sortField === "created_at"
                        ? sortOrder === 1
                          ? "up"
                          : "down"
                        : ""
                        }`}
                      onClick={() => customSort("created_at")}
                      style={{
                        cursor: "pointer",
                        display: "flex",
                        alignItems: "center",
                        fontSize: "11px",
                        lineHeight: "11px",
                      }}
                    >
                      {i18n("Created At")}
                      <CustomeSortIcon
                        sortOrder={sortField === "created_at" ? sortOrder : 0}
                        style={{ marginLeft: "4px", verticalAlign: "middle" }}
                      />
                    </div>
                  )}
                  sortable
                  body={dateCreatedAtBodyTemplate}
                  filter
                  filterElement={dateCreatedAtFilterTemplate}
                  filterPlaceholder=""
                  headerStyle={headerCellStyle}
                  showFilterMatchModes={false}
                  showClearButton={false}
                  showFilterOperator={false}
                  showAddButton={false}
                  filterMatchMode={FilterMatchMode.DATE_IS}
                  // showApplyButton={false}
                  showFilterMenu={false}
                />
                <Column
                  field="is_active"
                  header={() => (
                    <div
                      className={`custom-header-${sortField === "is_active"
                        ? sortOrder === 1
                          ? "up"
                          : "down"
                        : ""
                        }`}
                      onClick={() => customSort("is_active")}
                      style={{
                        cursor: "pointer",
                        display: "flex",
                        alignItems: "center",
                        fontSize: "11px",
                        lineHeight: "11px",
                      }}
                    >
                      {i18n("Status")}
                      <CustomeSortIcon
                        sortOrder={sortField === "is_active" ? sortOrder : 0}
                        style={{ marginLeft: "4px", verticalAlign: "middle" }}
                      />
                    </div>
                  )}
                  sortable
                  filter
                  filterPlaceholder=""
                  body={statusBodyTemplate}
                  filterElement={statusFilterTemplate}
                  headerStyle={headerCellStyle}
                  showFilterMatchModes={false}
                  showClearButton={false}
                  showFilterOperator={false}
                  showAddButton={false}
                  filterMatchMode={FilterMatchMode.CONTAINS}
                  showApplyButton={false}
                  showFilterMenu={false}
                />
              </DataTable>
            </div>
          </Container>
          {showDetails && (
            <DetailsModal
              show={showDetails}
              title={header}
              tabs={subscriptionTabs}
              currentItemId={id}
              ItemTitle="Products"
              header={header}
              setShow={setShowDetails}
            />
          )}
          {show && (
            <DeleteModel
              deleteUserClick={onDelete}
              onClickDeleteModel={setShow}
            />
          )}
          <CancelSubscription
            handleClose={confirmCancel}
            show={showCancel}
            setShow={setShowCancel}
          />
          <ReactiveSubscription
            show={showReactive}
            handleClose={() => setShowReactive(false)}
            handleReactivate={confirmReactivate}
          />
        </div>
      </Container>
    </Layout>
  );
};

export default SubscriptionStack;
