import {
    createSlice,
    createAsyncThunk,
  } from "@reduxjs/toolkit";
import { i18n } from "../../../shared/helpers/sharedMethods";

  const initialState = {
    status:false,
    reportItems:[],
    error:false,
    errorMessage:{}
  };

export const fetchNumberOfPurchaseChart = createAsyncThunk('numberOfPurchaseChart/fetch', async (param) => {
  try{
    const numberOfPurchaseChartResponse  = await window.api.numberOfPurchaseChart(param);
    return numberOfPurchaseChartResponse.data;
  }catch(err){
    await window.log.logToFile({message: "Error while fetching No of Purchase chart", level: "error" });
    throw new Error("Error while fetching Number Of Purchase Chart")
  }
});

const numberOfPurchaseChartSlice = createSlice({
    name: "numberOfPurchaseChart",
    initialState,
    reducers: {},
    extraReducers(builder) {
      builder
      .addCase(fetchNumberOfPurchaseChart.fulfilled, (state, action) => {
        state.status = true;
        state.reportItems =action.payload;
      })
      .addCase(fetchNumberOfPurchaseChart.rejected, (state, action) => {
        state.error = true; 
        state.errorMessage= (i18n("Error while fetching No Of Purchase Chart. Please try again later."));
        state.status = false;
      })

    },
});

export default numberOfPurchaseChartSlice.reducer;
