import { createSlice, createAsyncThunk, createEntityAdapter } from "@reduxjs/toolkit";
import { axiosRequest } from "../../shared/helpers/axiosHelper";

const subscriptionVoucherAdapter = createEntityAdapter({});

const initialState = subscriptionVoucherAdapter.getInitialState({

    status: false,
    error: null,
    subscriptionVoucherById:{},
    loading:false,
});

export const getAllSubscriptionVoucher = createAsyncThunk(
    'subscriptionVoucher/fetch',
    async ({ token, tenantId }) => {
        try {
            const response = await axiosRequest({
                method: "GET",
                url: 'subscription-vouchers',
                headers: {
                    Authorization: `Bearer ${token}`,
                    "X-Tenant": tenantId,
                },
            });
            return response.data;
        } catch (error) {
            await window.log.logToFile({ message: "Error while fetching all subscription voucher", level: "error" });
            throw new Error('Error submitting subscription');
        }
    }
);

export const getSubscriptionVoucher = createAsyncThunk(
    'subscriptionVoucher/fetchbyid',
    async ({ id, token, tenantId }) => {
        try {
            const response = await axiosRequest({
                method: "GET",
                url: `subscription-vouchers/${id}`,
                headers: {
                    Authorization: `Bearer ${token}`,
                    "X-Tenant": tenantId,
                },
            });
            
            return response.data;

        } catch (error) {
            await window.log.logToFile({ message: "Error while fetching subscription voucher with ID", level: "error" });
            throw new Error('Error submitting subscription');
        }
    }
);

const getAllSubscriptionVoucherSlice = createSlice({
    name: "subscriptionVoucher",
    initialState,
    reducers: {
        resetSubscriptionVoucher: () => initialState,
    },

    extraReducers(builder) {
        builder
        .addCase(getAllSubscriptionVoucher.pending, (state) => {
            state.loading=true;
           })
            .addCase(getAllSubscriptionVoucher.fulfilled, (state, action) => {
                state.status = true;
                state.loading = false;
                subscriptionVoucherAdapter.upsertMany(state, action.payload);
            })
            .addCase(getAllSubscriptionVoucher.rejected, (state, action) => {
                state.status = false;
                state.error = action.error;
                state.loading = false;
            })
            .addCase(getSubscriptionVoucher.fulfilled, (state, action) => {
                //state.status = true;
            
                state.subscriptionVoucherById = action.payload;
                subscriptionVoucherAdapter.upsertMany(state, action.payload);
            })

    },
});

export default getAllSubscriptionVoucherSlice.reducer;

export const {
    resetSubscriptionVoucher,
} = getAllSubscriptionVoucherSlice.actions;
export const {
    selectAll: selectAllSubscriptionVoucher,
    selectById: selectSubscriptionVoucherById,
    selectIds: selectSubscriptionVoucherIds,
} = subscriptionVoucherAdapter.getSelectors((state) => state.subscriptionVoucher);