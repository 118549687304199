import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Modal, Row, Form, Button, Col, InputGroup, Stack } from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { defaultPaymentMode, paymentModes } from "../../shared/config/defaults";
import { i18n, numValidate } from "../../shared/helpers/sharedMethods";
import {
  updateReceivedAmount,
  resetSubscription,
  editSubscriptionPayment,
  createPaymentForSubscription,
  getPaymentNumberForSubscription,
} from "../store/CreateSubscriptionReceivedAmount";
import { getSubscriptionVoucher } from "../store/getAllSubscriptionVoucherSlice";
import GlobalCurrency from "../../shared/components/GlobalCurrency";
import PaymentModelForSubscription from "./PaymentModelForSubscription";
import LoadingSpinner from "../../shared/components/LoadingSpinner";
import PaymentModelSubscription from "./PaymentModalSubscription";

const SubscriptionReceivedAmount = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  
  const { handleClose, showReceivedAmountModal, data } = props;
  const voucher_id = data?.id;
  const party_id = data?.party_id;
  const grand_total = data?.grand_total;
  const paidAmount = data?.paid_amount;
  const balanceAmount = grand_total - paidAmount;
  const selectedBusiness = useSelector(
    (state) => state?.auth?.selectedBusiness
  );
  const accessToken = useSelector(
    (state) => state?.auth?.accessToken?.access_token
  );
  const tenantId = selectedBusiness?.pivot?.tenant_id;
  const created_by = selectedBusiness?.pivot?.user_id;
  const paid_amount = useSelector(
    (state) => state.subscriptionReceivedAmount.paid_amount
  );
  const { payment_number } = useSelector(
    (state) => state.subscriptionReceivedAmount
  );

  const [paymentMode, setPaymentMode] = useState(defaultPaymentMode);
  const [markAsReceived, setMarkAsReceived] = useState(false);
  const currencySymbol = useSelector(
    (state) => state.getSetting?.dataSetting.currency
  );
  const [showPaymentModel, setShowPaymentModel] = useState(false);
  const [showModalBody, setShowModalBody] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
      dispatch(
        getPaymentNumberForSubscription({
          token: accessToken,
          tenant_id: tenantId,
        })
      );
    
  }, []);

  const handleReceivedAmountChange = (e) => {
    const newAmount = parseFloat(e.target.value) || 0;
    if (newAmount > balanceAmount) {
      toast.error("Amount exceeds the remaining balance.");
      e.target.value = ''; 
      return;
    }
    dispatch(updateReceivedAmount(newAmount));
  };
  
 

  const onSubmit = async () => {
    try {
      setLoading(true);
      const data = {
        voucher_id: voucher_id,
        party_id: party_id,
        amount: paid_amount,
        payment_number: payment_number,
        type: "payment_in",
        mode: paymentMode, 
        created_by: created_by,
        updated_by: created_by
      }
      if (isNaN(data.amount) || data.amount <= 0) {
        toast.error("Please enter a valid amount");
        setLoading(false);
        return;
      }
      await dispatch(createPaymentForSubscription({
        data: data, token: accessToken, tenant_id: tenantId}));
     await dispatch(
        editSubscriptionPayment({
          paid_amount: paidAmount + paid_amount,
          id: voucher_id,
          tenantId: tenantId,
          token: accessToken,
        })
     );
    
        await dispatch(
          getSubscriptionVoucher({
            id: voucher_id,
            tenantId: tenantId,
            token: accessToken,
          })
        );
        setTimeout(() => {
          setLoading(false);
        }, 100);
        setShowModalBody(false);
      
      toast.success("Paid amount updated successfully");
      // handleClose();
    } catch (error) {
      setLoading(false);
      console.error("Error submitting subscription:", error);
    }
  };

  useEffect(() => {
    if (markAsReceived) {
      dispatch(updateReceivedAmount(balanceAmount));
    } else {
      dispatch(updateReceivedAmount(0));
    }
  }, [markAsReceived, balanceAmount]);

  const clearField = () => {
    handleClose();
  };
  const handleNewPaymentClick = () => {
    if (balanceAmount > 0) {
      setShowModalBody(true);
    } else {
      toast.warning("No balance amount available for new payment.");
    }
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Enter") {
        event.preventDefault();
      const submitButton = document.getElementById("submitButton");
      if (submitButton) {
        submitButton.click();
      }
      }
    };
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  return (
    <>
      <ToastContainer />
      <Modal
        show={showReceivedAmountModal}
        dialogClassName="modal-dialog modal-dialog-centered mw-700px"
        style={{ zIndex: 2500 }}
        onHide={handleClose}
        keyboard={true}
      >
        <div style={{ borderRadius: "5px" }}>
          <Modal.Header closeButton className="px-7">
            <Modal.Title>Payments For {data?.voucher_number}</Modal.Title>
          </Modal.Header>
          
          <Stack className="col-md-12 mt-4 px-4 mb-0 d-flex " gap={3} direction="horizontal">

            <Stack className="px-2" direction="vertical" gap={1}>
              <span className="fs-responsive mb-2" style={{ color: "#687E96",fontSize: "14px" }}>
                <b>{i18n("Total Amount")}</b>
              </span>
              <span className="" style={{ color: "black" }}>
                {" "}
                {currencySymbol}{" "}
                {grand_total
                  ? grand_total
                  : "0"}
              </span>
            </Stack>

            <Stack className="px-2" direction="vertical" gap={1}>
              <span className="fs-responsive mb-2" style={{ color: "#687E96",fontSize: "14px" }}>
                <b>{i18n("Paid Amount")}</b>
              </span>
              <span className="" style={{ color: "black" }}>
                {" "}
                {currencySymbol}{" "}
                {paidAmount
                  ? paidAmount
                  : "0"}
              </span>
            </Stack>

            <Stack className="px-2" direction="vertical" gap={1}>
              <span className="fs-responsive mb-2" style={{ color: "green",fontSize: "14px" }}>
                <b>{i18n("Balance Amount")}</b>
              </span>
              <span className="" style={{ color: "green" }}>
                {" "}
                {currencySymbol}{" "}
                {balanceAmount
                  ? balanceAmount
                  : "0"}
              </span>
            </Stack>
          </Stack>
          <Modal.Body>
          {balanceAmount > 0 && !showModalBody && (
              <div
                className="d-flex justify-content-center p-2 my-3 cursor-pointer"
                style={{ border: "1px dashed lightgray" }}
              >
                <Button variant="link" onClick={handleNewPaymentClick}>
                  {i18n("+ Add Payment")}
                </Button>
              </div>
            )}
            {showModalBody && (
              <>
                <Row className="row">
                  <Col className="col-md-12 mb-2 px-3">
                    <Form.Check
                      inline
                      label="Mark as received"
                      name="mark_as_received"
                      type="checkbox"
                      id="mark_as_received_check"
                      onChange={() => {
                        setMarkAsReceived(!markAsReceived);
                      }}
                    />
                  </Col>
                  <Col>
                    <InputGroup className="col-md-12 mb-2">
                      <InputGroup.Text>{currencySymbol}</InputGroup.Text>
                      <Form.Control
                        type="text"
                        name="paid_amount"
                        className="text-end"
                        onKeyPress={(event) => numValidate(event)}
                        value={paid_amount}
                        onChange={handleReceivedAmountChange}
                        autoFocus={true}
                      />
                      <Form.Select
                        onChange={(e) => setPaymentMode(e.target.value)}
                        value={paymentMode}
                      >
                        {paymentModes && paymentModes.length ? (
                          paymentModes.map((paymentMode, index) => (
                            <option key={index} value={paymentMode.value}>
                              {paymentMode.label}
                            </option>
                          ))
                        ) : (
                          <option value="">No payment mode</option>
                        )}
                      </Form.Select>
                      <Button id='submitButton' variant="primary mx-1" onClick={onSubmit}>
                      {i18n("Add Payment")}
                      </Button>
                    </InputGroup>

                  </Col>
                </Row>
              </>
            )}
            {loading && <LoadingSpinner />}
          </Modal.Body>
          { data && data?.payment != "" && (
            <Modal.Footer style={{ borderTop: "1px solid lightgray" }}>
              <PaymentModelSubscription
                Id={data?.id}
              />
            </Modal.Footer>
          )}
        </div>
      </Modal>
    </>
  );
};

export default SubscriptionReceivedAmount;
