import { createSlice, createAsyncThunk, createEntityAdapter, createSelector } from '@reduxjs/toolkit';
import { filterCategory, preprocessorCategory } from '../components/helpers/settingHelpers';
import { i18n } from '../../shared/helpers/sharedMethods';
import { axiosRequest } from "../../shared/helpers/axiosHelper";

const categoriesAdapter = createEntityAdapter();

const initialState = categoriesAdapter.getInitialState({
  status: false,
  error: null,
  categoryType: [],
  loading:false,
});

export const fetchCategories = createAsyncThunk('category/fetchCategories', async (args) => {
  try {
    const token = args.token;
    const tenantId = args.tenant_id; 
    const categoriesResponse =await axiosRequest({
      method: "GET",
      url: `category`,
      headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "X-Tenant": tenantId,
      },
  });
    return categoriesResponse.data;
  } catch (error) {
    await window.log.logToFile({ message: "Error while fetching category", level: "error" });
    throw error;
  }
});

export const deleteCategory = createAsyncThunk('category/deleteCategory', async (args) => {
  console.log('args cat', args);
  try {
    const token = args.token;
    const data = args.data;
    const tenantId = args.tenant_id;
    const resourceType = 'category';
    const terminalNumber = args.terminal_id;  
    const categoryId = args.data.id;

    if (!categoryId) {
      throw new Error('Category ID is required for deletion');
    }

    const categoryResponse = await axiosRequest({
      method: "DELETE",
      url: `resource/${resourceType}/delete/${terminalNumber}`,
      data: data,
      headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "X-Tenant": tenantId,
      },
    });

    return categoryResponse.data;
  } catch (error) {
    await window.log.logToFile({ message: "Error while deleting category", level: "error" });
    throw error;
  }
});

const categorysSlice = createSlice({
  name: 'categories',
  initialState,
  reducers: {
    addCategoryType(state, action) {
      state.categoryType = action.payload
    },
    resetCategoryType(state) {
      state.categoryType = null
    },
    resetCategory: () => initialState,
    pushNewCategory: categoriesAdapter.addOne,
    updateCategory(state, action) {
      const { id } = action.payload;
      state.entities[id] = action.payload;
    },
    addManyData(state, action) {
      categoriesAdapter.upsertMany(action.payload);
    },
  },
  extraReducers(builder) {
    builder
    .addCase(fetchCategories.pending, (state) => {
      state.loading = true; 
    })
      .addCase(fetchCategories.fulfilled, (state, action) => {
        state.status = true;
        state.loading = false; 
        
        
        if (action?.payload) {
          categoriesAdapter.upsertMany(state, action.payload);
        }
      })
      .addCase(fetchCategories.rejected, (state, action) => {
        state.status = false;
        state.loading = false; 
        state.error = (i18n("Unable to retrieve category. Please try again later"));
      })
      .addCase(deleteCategory.fulfilled, categoriesAdapter.removeOne)
      .addCase(deleteCategory.rejected, (state, action) => {
        state.status = 'Update Failed'
        state.error = (i18n("Unable to delete category. Please try again later"));
      })
  },
});

export default categorysSlice.reducer;
export const { resetCart, resetCategory, addCategoryType, pushNewCategory, updateCategory, addManyData, resetCategoryType } = categorysSlice.actions;
export const {
  selectAll: selectAllCategories,
  selectById: selectCategoryById,
  selectIds: selectCategoryIds,
} = categoriesAdapter.getSelectors((state) => state.categories);

export const selectCategoryType = createSelector(
  [selectAllCategories, (state, type) => type],
  (categories, type) => {
    if (!type) {
      return [];
    }
    if (type === 'All') {
      const processedCategory = preprocessorCategory(categories);
      return processedCategory;
    } else {
      const filteredCategories = filterCategory(categories, type);
      const processedCategory = preprocessorCategory(filteredCategories);
      return processedCategory;
    }
  }
);


