import {
    createSlice,
    createAsyncThunk,
  } from "@reduxjs/toolkit";
import { i18n } from "../../shared/helpers/sharedMethods";

  const initialState = {
    status:false,
    reportItems:[],
    error:false,
    errorMessage:{}
  };

export const fetchPartySummaryChart = createAsyncThunk('partySummaryChart/fetch', async (param) => {
  try{
    const partySummaryChartResponse  = await window.api.partySummaryChart(param);
    return partySummaryChartResponse.data;
  }catch(err){
    await window.log.logToFile({message: "Error while fetching Party summary", level: "error" });
    throw new Error("Error while fetching Sales summary chart")
  }
});

const partySummaryChartSlice = createSlice({
    name: "partySummaryChart",
    initialState,
    reducers: {},
    extraReducers(builder) {
      builder
      .addCase(fetchPartySummaryChart.fulfilled, (state, action) => {
        state.status = true;
        state.reportItems =action.payload;
      })
      .addCase(fetchPartySummaryChart.rejected, (state, action) => {
        state.error = true; 
        state.errorMessage=(i18n("Error while fetching party summary chart. Please try again later."));
        state.status = false;
      })

    },
});

export default partySummaryChartSlice.reducer;
