import { Button, Col, Modal, Row, Stack } from "react-bootstrap";
import { useSelector } from "react-redux";
import { i18n } from "../../../shared/helpers/sharedMethods";
import GlobalCurrency from "../../../shared/components/GlobalCurrency";

const TaxSummary = ({ show, handleClose, is_same_state }) => {
  const { items } = useSelector((state) => state.voucherEntity);

  const taxSummary = items?.reduce(
    (acc, item) => {
      if (item.igst) {
        acc.igstTotal += item.igst;
        acc.igstRate = item.tax_rate;
      } else {
        acc.cgstTotal += item.cgst;
        acc.sgstTotal += item.sgst;
        acc.cgstRate = item.tax_rate;
        acc.sgstRate = item.tax_rate;
      }
      acc.totalTax += item.tax_total;
      return acc;
    },
    {
      igstTotal: 0,
      cgstTotal: 0,
      sgstTotal: 0,
      igstRate: 0,
      cgstRate: 0,
      sgstRate: 0,
      totalTax: 0,
    }
  );

  return (
    <Modal
      show={show}
      style={{ zIndex: 2500 }}
      dialogClassName="modal-dialog modal-dialog-centered modal-sm"
      onHide={handleClose}
      keyboard={true}
    >
      <div>
        <Modal.Header closeButton>
          <Modal.Title>{i18n("Tax Summary")}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="px-3 py-1">
          {items?.length > 0 ? (
            <Stack direction="vertical" className="my-3" gap={2}>
              <Row
                className="me-5"
                style={{
                  maxHeight: "100px",
                  overflow: "auto",
                  padding: "10px",
                }}
              >
                <Col className="mt-2" lg={12}>
                  <table className="table p-0">
                    <thead>
                      <tr>
                        <th className="">Tax Type</th>
                        <th className="px-0">Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      {is_same_state ? (
                        <>
                          <tr>
                            <td>
                              <span
                                style={{
                                  color: "#898989",
                                  fontWeight: "bold",
                                }}
                              >
                                CGST @{" "}
                                {taxSummary?.cgstRate}{" "}%
                              </span>
                            </td>
                            <td>
                              <GlobalCurrency price={taxSummary?.cgstTotal} />
                            </td>
                          </tr>

                          <tr>
                            <td>
                              <span
                                style={{
                                  color: "#898989",
                                  fontWeight: "bold",
                                }}
                              >
                                SGST @{" "}
                                {taxSummary?.sgstRate}{" "}%
                              </span>
                            </td>
                            <td>
                             <GlobalCurrency price={taxSummary?.sgstTotal} />
                            </td>
                          </tr>
                        </>
                      ) : (
                        <tr>
                          <td>
                            <span
                              style={{ color: "#898989", fontWeight: "bold" }}
                            >
                              IGST @{" "}
                              {taxSummary.igstRate}{" "}%
                            </span>
                          </td>
                          <td>
                           <GlobalCurrency price={taxSummary.igstTotal} />
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </Col>
              </Row>
            </Stack>
          ) : (
            <p>No tax information available.</p>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </div>
    </Modal>
  );
};

export default TaxSummary;
