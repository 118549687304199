import {
    createSlice,
    createAsyncThunk,
  } from "@reduxjs/toolkit";
import { i18n } from "../../shared/helpers/sharedMethods";
import { axiosRequest } from "../../shared/helpers/axiosHelper";

const initialState = {
  status:false,
  reportItems:[],
  error:false,
  errorMessage:'',
  loading:false,
};

export const fetchBillWiseReport = createAsyncThunk(
  "billWiseReport/fetch",
  async ({ token, tenant_id, startDate, endDate }) => {
    try{
    const billWiseReportResponse = await axiosRequest({
      method: "GET",
      url: `get-bill-wise-report`,
      params: { startDate, endDate },
      headers: { Authorization: `Bearer ${token}`, "X-Tenant": tenant_id },
    });
    return billWiseReportResponse.data;
    }catch(err){
      await window.log.logToFile({message: "Unable to fetch bill Wise report", level: "error" });
      throw new Error("Unable to fetch bill Wise report")
    }
  }
);

const billWiseReportSlice = createSlice({
    name: "billWiseReport",
    initialState,
    reducers: {},
    extraReducers(builder) {
      builder
      .addCase(fetchBillWiseReport.pending, (state) => {
        state.loading = true;
      })
        .addCase(fetchBillWiseReport.fulfilled, (state, action) => {
          state.status = true
          state.loading = false;
          state.reportItems = action.payload;
        })
        .addCase(fetchBillWiseReport.rejected, (state, action) => {
          state.error = true; 
          state.loading = false;
          state.errorMessage=(i18n("Error while fetching billWise report. Please try again later."));
          state.status = false;
        })
    },
});

export default billWiseReportSlice.reducer;

