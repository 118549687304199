import { Col, Form, Row } from "react-bootstrap";
import React, { useEffect } from "react";
import { taxList } from "../../../../shared/config/taxConfig";
import { useDispatch, useSelector } from "react-redux";
import { filterTaxList } from "../../../../settings/components/helpers/settingHelpers";
import { changesTaxCode } from "../../../store/subscription_item_store/SetupFeesEntitySlice";
import { i18n } from "../../../../shared/helpers/sharedMethods";
import { changeAddonTaxCode } from "../../../store/subscription_item_store/AddonEntitySlice";

const AddonTaxCode = ({ values, handleChange, touched, errors }) => {
  
  const dispatch = useDispatch();
  const getSetting = useSelector((state) => state.getSetting?.dataSetting);
  ;

  const getTax = JSON.parse(getSetting?.tax);
  const TaxList = filterTaxList(taxList, getTax);
  const defaultTax = getSetting?.default_tax;

  useEffect(() => {
    dispatch(changeAddonTaxCode(defaultTax));
  },[])

  return (
    <Row className="py-4">
      <Col lg={3}>{i18n("Tax Rate")}</Col>
      <Col lg={4}>
        <Form.Select
          name="tax_code"
          aria-label="Tax Rate"
          className="form-control small-text"
          onChange={(e) => {
            handleChange(e);
            dispatch(changeAddonTaxCode(e.target.value));
          }}
          value={values.tax_code}
        >
          {TaxList && TaxList.length ? (
            TaxList.map((tax, index) => {
              return (
                <option key={tax.code}value={tax.code}>
                  {tax.label}
                </option>
              );
            })
          ) : (
            <option value="">No Tax options</option>
          )}
        </Form.Select>
      </Col>
    </Row>
  );
};

export default AddonTaxCode;
