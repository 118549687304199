import { Col, Row, Stack,Image } from "react-bootstrap";
import ActiveSubscription from "./blocks/ActiveSubscription";
import ChurnRate from "./blocks/ChurnRate";
import ARPU from "./blocks/ARPU";
import MRR from "./blocks/MRR";
import SubscriptionSummaryChart from "./Subscription_Summary_chart/SubscriptionSummaryChart";
import { i18n } from "../../shared/helpers/sharedMethods";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import NotInPlanAlert from "../../shared/components/NotInPlanAlert";
import { SubscriptionDashboard } from "../store/SubscriptionDashboardSlice";
import LoadingSpinner from '../../shared/components/LoadingSpinner';
import { fetchSetting } from "../../settings/store/getSettingSlice";

const SubscriptionTab = ({ reportPeriod, ItemActions, featureName, startDate, endDate, businessSwitch }) => {
  const dispatch = useDispatch();
  const accessToken = useSelector((state) => state?.auth?.accessToken?.access_token);
  const selectedBusiness = useSelector((state) => state?.auth?.selectedBusiness);
  const tenantId = selectedBusiness?.pivot?.tenant_id;
  const userRole = useSelector((state) => state?.auth?.userRole); 
  const admin = selectedBusiness?.pivot?.is_owner;
  const [ isSubscriptionTabAvailable, setIsSubscriptionTabAvailable ] = useState(true);
    const status = useSelector((state) => state.setting.status);

  const globalPlanFeatures = useSelector(
      (state) => state?.globalPlan?.features
  );
  const { loading } = useSelector((state) => state.subscriptionDashboard);
  useEffect(() => {
    setIsSubscriptionTabAvailable(admin && globalPlanFeatures.includes(featureName));
  }, [admin, globalPlanFeatures, featureName]);

  useEffect(() => {
    dispatch(
      fetchSetting({
        accessToken: accessToken,
        tenant_id: selectedBusiness?.pivot?.tenant_id,
      })
    );
  }, [status]);

  useEffect(() => {
    dispatch(
      SubscriptionDashboard({
        token: accessToken,
        tenantId: tenantId,
        //period: reportPeriod,
        startDate: startDate,
        endDate: endDate,
      })
    );
  }, [reportPeriod, startDate, endDate, businessSwitch]);
  return (

    <>

{loading && <LoadingSpinner />}
      {isSubscriptionTabAvailable ? (
        <>
          <Row>
            <Col lg={12}>{ItemActions}</Col>
          </Row>
          <Row className="mb-1">
            <Col xs={6} md={2} className="">
              <Stack direction="vertical" gap={6}>
                <ActiveSubscription />
                <ChurnRate />
              </Stack>
            </Col>
            <Col xs={6} md={2}>
              <Stack direction="vertical" gap={6}>
                <ARPU />
                <MRR />
              </Stack>
            </Col>
            <Col xs={12} md={8}>
              <SubscriptionSummaryChart
                reportPeriod={reportPeriod}
                startDate={startDate}
                endDate={endDate}
              />
            </Col>
          </Row>
        </>
      ) : (<NotInPlanAlert noLayout={true} isAdmin={userRole === 'admin'} />
      )}
    </>
  );
};

export default SubscriptionTab;
