import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { axiosRequest } from "../../shared/helpers/axiosHelper";

const initialState = {
  paid_amount: 0,
  status: false,
  payment_number: "",
  error: {},
  savedEntity: {},
  updateAmountStatus: false,
  deleteAmountStatus: false
};

export const editSubscriptionPayment = createAsyncThunk(
  "subscriptionReceivedAmount/editSubscriptionPayment",
  async ({ paid_amount, id, token, tenantId }) => {
    try {
      const response = await axiosRequest({
        method: "PUT",
        url: `voucher/payment/${id}`,
        data: { paid_amount: paid_amount },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "X-Tenant": tenantId,
        },
      });
      return response;
    } catch (error) {
      await window.log.logToFile({
        message: "Error while updating subscription payment",
        level: "error",
      });
      throw new Error("Error submitting subscription");
    }
  }
);

export const createPaymentForSubscription = createAsyncThunk(
  "subscriptionReceivedAmount/createPaymentForSubscription",
  async (args) => {
    try {
      const token = args.token;
      const data = args.data;
      const tenantId = args.tenant_id;

      const response = await axiosRequest({
        method: "POST",
        url: "subscriptions/payment",
        data: data,
        headers: {
          Authorization: `Bearer ${token}`,
          "X-Tenant": tenantId,
        },
      });
      return response;
    } catch (error) {
      await window.log.logToFile({
        message: "Error while updating subscription payment",
        level: "error",
      });
      throw new Error("Error submitting subscription");
    }
  }
);

export const editPaymentForSubscription = createAsyncThunk(
  "subscriptionReceivedAmount/editPaymentForSubscription",
  async (args) => {
    try {
      const token = args.token;
      const data = args.data;
      
      const tenantId = args.tenantId;

      const response = await axiosRequest({
        method: "PUT",
        url: `subscriptions/payment/${data?.id}`,
        data: data,
        headers: {
          Authorization: `Bearer ${token}`,
          "X-Tenant": tenantId,
        },
      });
      return response;
    } catch (error) {
      await window.log.logToFile({
        message: "Error while updating subscription payment",
        level: "error",
      });
      throw new Error("Error submitting subscription");
    }
  }
);

export const deletePayment = createAsyncThunk(
  "subscriptionReceivedAmount/deletePayment",
  async (args) => {
    const data = args.data;
    const token = args.token;
    const tenantId = args.tenantId;

    try {

      const response = await axiosRequest({
        method: "DELETE",
        url: `subscriptions/payments/${data?.id}`,
        headers: {
          Authorization: `Bearer ${token}`,
          "X-Tenant": tenantId,
        },
      });
      return response;
    } catch (err) {
      await window.log.logToFile({ message: "Error while deleting subscription", level: "error" });
      throw err;
    }
  }
);

export const getPaymentNumberForSubscription = createAsyncThunk(
  "subscriptionReceivedAmount/getPaymentNumber",
  async ({ token, tenant_id }) => {
    try {
      const response = await axiosRequest({
        method: "GET",
        url: 'subscriptions/next-payment-number',
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "X-Tenant": tenant_id,
        },
      });
      return response.data;
    } catch (error) {
      await window.log.logToFile({
        message: "Error while fetching payment number",
        level: "error",
      });
      throw error;
    }
  }
);

const createSubscriptionReceivedAmountSlice = createSlice({
  name: "subscriptionReceivedAmount",
  initialState,
  reducers: {
    updateReceivedAmount(state, action) {
      state.paid_amount = action.payload;
    },
    undoStatus(state, action) {
      state.updateAmountStatus = false
    },
    undoDelStatus(state, action) {
      state.deleteAmountStatus = false
    },

    resetSubscription: () => initialState,
  },

  extraReducers(builder) {
    builder
      .addCase(editSubscriptionPayment.fulfilled, (state, action) => {
        state.status = true;
        state.savedEntity = action.payload.message;
        state.error = action.error;
      })
      .addCase(editSubscriptionPayment.rejected, (state, action) => {
        state.status = false;
        state.error = action.error.message;
      })
      .addCase(createPaymentForSubscription.fulfilled, (state, action) => {
        state.status = true;
        state.savedEntity = action.payload.message;
        state.error = action.error;
      })
      .addCase(createPaymentForSubscription.rejected, (state, action) => {
        state.status = false;
        state.error = action.error.message;
      })
      .addCase(editPaymentForSubscription.fulfilled, (state, action) => {
        state.updateAmountStatus = true;
        state.savedEntity = action.payload.message;
        state.error = action.error;
      })
      .addCase(editPaymentForSubscription.rejected, (state, action) => {
        state.error = action.error.message;
      })
      .addCase(deletePayment.fulfilled, (state, action) => {
        state.deleteAmountStatus = true;
        state.savedEntity = action.payload;
        state.error = action.error;
      })
      .addCase(deletePayment.rejected, (state, action) => {
        state.error = action.error.message;
      })
      .addCase(getPaymentNumberForSubscription.fulfilled, (state, action) => {
        state.payment_number = action.payload;
      });
  },
});

export default createSubscriptionReceivedAmountSlice.reducer;

export const { updateReceivedAmount, undoStatus, undoDelStatus, resetSubscription } =
  createSubscriptionReceivedAmountSlice.actions;
