import React, { useState, useEffect } from "react";
import { Button, Row, Col, Stack, Container ,Image} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Layout from "../../shared/components/Layout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faChevronLeft,
  faDownload,
} from "@fortawesome/free-solid-svg-icons";
import { downloadCsvFile, handleExportClick } from "../../shared/export";
import DataGrid from "../../shared/components/DataGrid";
import { fetchPartyWiseOutstanding } from "../store/partyWiseOutstandingSlice";
import ReportPeriod from "../../shared/components/ReportPeroid";
import CategoryFilter from "../components/Filters/CategoryFilter";
import Header from "../../shared/components/Header";
import { i18n } from "../../shared/helpers/sharedMethods";
import PartyNavbar from "../components/PartyNavbar";
import ShortcutKeys from "../../shared/components/ShortcutKeys";
import ReportDateRange from "../../shared/components/date_range/ReportDateRange";
import DatePicker from "../../shared/components/date_range/DatePicker";
import { CurrentWeekDateRange } from "../../shared/components/DateRangeYAxis";
import ReportHeader from "../components/ReportHeader";
import { Column } from "primereact/column";
import CustomeSortIcon from "../../shared/components/CustomeSortIcon";
import { DataTable } from "primereact/datatable";
import { UilAngleLeft, UilImport } from "@iconscout/react-unicons";
import GlobalCurrency from "../../shared/components/GlobalCurrency";
import LoadingSpinner from '../../shared/components/LoadingSpinner';
import { fetchCategories } from "../../settings/store/categorysSlice";


const PartyWiseOutstanding = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const accessToken = useSelector((state) => state?.auth?.accessToken?.access_token);
  const selectedBusiness = useSelector((state) => state?.auth?.selectedBusiness);
  const tenantId = selectedBusiness?.pivot?.tenant_id;
  const [reportPeriod, setReportPeriod] = useState("current-week");
  const [categoryData, setCategoryData] = useState("");
  const [csvData, setCsvData] = useState("");
  const [startDate, setStartDate] = useState(null); // State for start date
  const [endDate, setEndDate] = useState(null);
  const [sortField, setSortField] = useState(null);
  const [sortOrder, setSortOrder] = useState(1);


    const handleReset = () => {
      const salesDateLimits = ReportDateRange({
        reportPeriod: reportPeriod,
      });
      setStartDate(salesDateLimits.startDate);
      setEndDate(salesDateLimits.endDate);
      setReportPeriod("current-week");
      setCategoryData("");
    };

    useEffect(() => {
      dispatch(fetchCategories({ token: accessToken, tenant_id: tenantId }));
  }, [accessToken, tenantId]);
  
  useEffect(() => {
    dispatch(fetchPartyWiseOutstanding({startDate: startDate, endDate: endDate,token:accessToken,tenant_id:tenantId, categoryData:categoryData})); 
  }, [startDate, endDate, categoryData]);

  useEffect(() => {
    const dateLimits = ReportDateRange({ reportPeriod });
    setStartDate(dateLimits.startDate);
    setEndDate(dateLimits.endDate);
  }, [reportPeriod]);

  const reportItems = useSelector(
    (state) => state.partyWiseOutstanding.reportItems
  );
  const status = useSelector((state) => state.partyWiseOutstanding.status);
  const loading = useSelector((state) => state.partyWiseOutstanding.loading);
  const errorMessage = useSelector(
    (state) => state.partyWiseOutstanding.errorMessage
  );
  const currencySymbol = useSelector((state) => state.getSetting?.dataSetting.currency);
  ;
  let totalPay = 0;
  let totalCollect = 0;
  if (status && Array.isArray(reportItems)) {
    for (const item of reportItems) {
      totalCollect += item.toCollect || 0;
    }
  }
  if (status && Array.isArray(reportItems)) {
    for (const item of reportItems) {
      totalPay += item.toPay || 0;
    }
  }

  const dateTimeString = new Date()
    .toLocaleString()
    .replace(/[-]/g, " ")
    .replace(/:/g, "-");
  const fileName = `Party_wise_${dateTimeString}`;

  const handleExportButtonClick = () => {
    const exportData =
      reportItems &&
      Array.isArray(reportItems) &&
      reportItems.map((val) => ({
        " NAME": val.PartyName ? val.PartyName : "-",
        "CATEGORY": val.category ? val.category : "-",
        "CONTACT NUMBER": val?.PhoneNo ? val?.PhoneNo : "-",
        "COLLECT": val.toCollect ? val?.toCollect : "0",
        "PAY": val.toPay ? val?.toPay : '0',
        "AMOUNT": val.amount ? val.amount : "0",
      }));
    handleExportClick(exportData, setCsvData, downloadCsvFile, fileName);
  };

  const customSort = (field) => {
    setSortField(field);
    setSortOrder(sortOrder === 1 ? -1 : 1);
  };

  const sortedData = [...reportItems].sort((a, b) => {
    if (sortField) {
      const compareValueA = a[sortField];
      const compareValueB = b[sortField];
      if (compareValueA < compareValueB) {
        return sortOrder;
      }
      if (compareValueA > compareValueB) {
        return -sortOrder;
      }
    }
    return 0;
  });
  const headerStyle = {
    backgroundColor: "white",
  };
  const headerCellStyle = {
    fontWeight: "bold",
    fontSize: "11px",
  };
  return (
    <Layout>
       {loading && <LoadingSpinner />}
      <Header
        currentTitle={i18n("Party Wise Outstanding")}
        ItemActions={
          <Stack
            gap={2}
            direction="horizontal"
            className="mt-1 mb-1 float-end ms-auto"
          >
            <ShortcutKeys />
          </Stack>
        }
      />

      <Container fluid className="px-5">
        <div className="border " style={{ background: "white" }}>
          <PartyNavbar />
          <label className="d-flex flex-direction-column p-2 px-3  fs-3">
            {" "}
            {i18n("To Collect")} {" "}&nbsp;:&nbsp;
            <label
              className=" fw-bolder fs-2 px-2 "
              style={{ color: "Green" }}
            >
              {" "}
              <GlobalCurrency price={totalCollect} />
            </label>{" "}
          </label>
          <label className="d-flex flex-direction-column px-3 fs-3">
            {" "}
            {i18n("To Pay")} {" "}&nbsp;:&nbsp;
            <label
              className=" fw-bolder fs-2 px-2 "
              style={{ color: "red" }}
            >
              {" "}
              <GlobalCurrency price={totalPay} />
            </label>{" "}
          </label>
          <ReportHeader
            ItemActions={
              <Stack gap={2} direction="horizontal" className="pt-3 px-4 mb-3">
                <Stack direction="horizontal">
                  <DatePicker
                    startDate={startDate}
                    setStartDate={setStartDate}
                    endDate={endDate}
                    setEndDate={setEndDate}
                  />
                </Stack>
                <Stack className=" ">
                  <ReportPeriod
                    reportPeriod={reportPeriod}
                    setReportPeriod={setReportPeriod}
                  />
                </Stack>
                <Stack className=" ">
                  <CategoryFilter
                    categoryData={categoryData}
                    setCategoryData={setCategoryData}
                    title="party"
                  />
                </Stack>
                <Stack
                  gap={1}
                  direction="horizontal"
                  style={{ marginLeft: "1px" }}
                >
                  <Button
                    className="rounded-0"
                    variant="outline-danger"
                    onClick={handleReset}
                  >
                     {i18n("Reset")}
                  </Button>
                </Stack>
                <Button
                  className="fw-bolder d-flex align-items-center rounded-0"
                  onClick={handleExportButtonClick}
                >
                  <UilImport
                    size="18"
                    className="mx-2"
                    style={{ cursor: "pointer" }}
                  />
                  {i18n("Export")}
                </Button>
              </Stack>
            }
          />

          <Container fluid className="px-4 mb-4">
            <div className="bordered-container">
              {status && reportItems ? (
                <DataTable
                  value={sortedData}
                  headerStyle={headerStyle}
                  paginator
                  rows={10}
                  dataKey="id"
                  emptyMessage={i18n("No Records Found.")}
                  className="p-datatable-gridlines full-width-table custom-datatable p-component"
                  scrollable
                  scrollHeight="400px"
                  sortMode="multiple"
                  removableSort
                >
                  <Column
                    field="PartyName"
                    header={() => (
                      <div
                        className={`custom-header-${sortField === "PartyName" ? (sortOrder === 1 ? "up" : "down") : ""}`}
                        onClick={() => customSort("PartyName")}
                        style={{
                          cursor: "pointer",
                          display: "flex",
                          alignItems: "center",
                          fontSize: "11px",
                          lineHeight: "11px",
                        }}
                      >
                        {i18n("NAME")}
                        <CustomeSortIcon
                          sortOrder={sortField === "PartyName" ? sortOrder : 0}
                          style={{ marginLeft: "4px", verticalAlign: "middle" }}
                        />
                      </div>
                    )}
                    body={(row) => row.PartyName}
                    sortable
                    headerStyle={headerCellStyle}
                  />

                  <Column
                    field="category"
                    header={() => (
                      <div
                        className={`custom-header-${sortField === "category" ? (sortOrder === 1 ? "up" : "down") : ""}`}
                        onClick={() => customSort("category")}
                        style={{
                          cursor: "pointer",
                          display: "flex",
                          alignItems: "center",
                          fontSize: "11px",
                          lineHeight: "11px",
                        }}
                      >
                        {i18n("CATEGORY")}
                        <CustomeSortIcon
                          sortOrder={sortField === "category" ? sortOrder : 0}
                          style={{ marginLeft: "4px", verticalAlign: "middle" }}
                        />
                      </div>
                    )}
                    body={(row) => (row.category ? row.category : "-")}
                    sortable
                    headerStyle={headerCellStyle}
                  />

                  <Column
                    field="PhoneNo"
                    header={() => (
                      <div
                        className={`custom-header-${sortField === "PhoneNo" ? (sortOrder === 1 ? "up" : "down") : ""}`}
                        onClick={() => customSort("PhoneNo")}
                        style={{
                          cursor: "pointer",
                          display: "flex",
                          alignItems: "center",
                          fontSize: "11px",
                          lineHeight: "11px",
                        }}
                      >
                        {i18n("CONTACT NUMBER")}
                        <CustomeSortIcon
                          sortOrder={sortField === "PhoneNo" ? sortOrder : 0}
                          style={{ marginLeft: "4px", verticalAlign: "middle" }}
                        />
                      </div>
                    )}
                    body={(row) => row.PhoneNo}
                    sortable
                    headerStyle={headerCellStyle}
                  />

                  <Column
                    field="toCollect"
                    header={() => (
                      <div
                        className={`custom-header-${sortField === "toCollect" ? (sortOrder === 1 ? "up" : "down") : ""}`}
                        onClick={() => customSort("toCollect")}
                        style={{
                          cursor: "pointer",
                          display: "flex",
                          alignItems: "center",
                          fontSize: "11px",
                          lineHeight: "11px",
                        }}
                      >
                        {i18n("To Collect")}
                        <CustomeSortIcon
                          sortOrder={sortField === "toCollect" ? sortOrder : 0}
                          style={{ marginLeft: "4px", verticalAlign: "middle" }}
                        />
                      </div>
                    )}
                    body={(row) => (
                      <span>
                        {row.toCollect < 0 ? "-" : ""}
                        <GlobalCurrency price={Math.abs(row.toCollect || 0)} />
                      </span>
                    )}
                    sortable
                    headerStyle={headerCellStyle}
                  />

                  <Column
                    field="toPay"
                    header={() => (
                      <div
                        className={`custom-header-${sortField === "toPay" ? (sortOrder === 1 ? "up" : "down") : ""}`}
                        onClick={() => customSort("toPay")}
                        style={{
                          cursor: "pointer",
                          display: "flex",
                          alignItems: "center",
                          fontSize: "11px",
                          lineHeight: "11px",
                        }}
                      >
                        {i18n("To Pay")}
                        <CustomeSortIcon
                          sortOrder={sortField === "toPay" ? sortOrder : 0}
                          style={{ marginLeft: "4px", verticalAlign: "middle" }}
                        />
                      </div>
                    )}
                    body={(row) => (
                      <span>
                        {row.toPay < 0 ? "-" : ""}
                        <GlobalCurrency price={Math.abs(row.toPay || 0)} />
                      </span>
                    )}
                    sortable
                    headerStyle={headerCellStyle}
                  />

                  <Column
                    field="amount"
                    header={() => (
                      <div
                        className={`custom-header-${sortField === "amount" ? (sortOrder === 1 ? "up" : "down") : ""}`}
                        onClick={() => customSort("amount")}
                        style={{
                          cursor: "pointer",
                          display: "flex",
                          alignItems: "center",
                          fontSize: "11px",
                          lineHeight: "11px",
                        }}
                      >
                        {i18n("AMOUNT")}
                        <CustomeSortIcon
                          sortOrder={sortField === "amount" ? sortOrder : 0}
                          style={{ marginLeft: "4px", verticalAlign: "middle" }}
                        />
                      </div>
                    )}
                    body={(row) => (
                      <span>
                        {row.amount < 0 ? "-" : ""}
                        <GlobalCurrency price={Math.abs(row.amount || 0)} />
                      </span>
                    )}
                    sortable
                    headerStyle={headerCellStyle}
                  />

                  {/* {status ? (
                <DataGrid
                  allData={reportItems}
                  data={reportItems}
                  columns={gridColumns}
                  isPaginated={true}
                  title="Report"
                />
              ) : (
                <p className="d-flex justify-content-center p-2 fs-3 fw-bolder">
                  {i18n("Error while fetching Party wise outstanding report")}
                </p>
              )} */}
                </DataTable>
              ) : (
                <p>{i18n("Loading")}</p>
              )}
            </div>
          </Container>
        </div>
      </Container>
    </Layout>
  );
};

export default PartyWiseOutstanding;
