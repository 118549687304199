import React, { useEffect } from "react";
import { Row, Table } from "react-bootstrap";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import {
  getStockReport,
  selectAllStockReportData,
  selectStockReportDataById,
} from "../../../store/stockReportSlice";
import { i18n } from "../../../../shared/helpers/sharedMethods";
import { getLocalizedDate } from "../../../../shared/helpers/localizationHelper";
import { formatVoucherType } from "../../../../shared/helpers/typeHelper";
const ItemStockReport = ({ productId}) => {

  const dispatch = useDispatch();
  const { accessToken } = useSelector((state) => state?.auth);
  const selectedBusiness = useSelector((state) => state?.auth?.selectedBusiness);
  const tenantId = selectedBusiness?.pivot?.tenant_id;
  const allStockReport = useSelector(selectAllStockReportData);
  const stockReport = useSelector((state) => selectStockReportDataById(state, productId));
  useEffect(() => {
    if (productId) {
      dispatch(getStockReport({ id: productId, token: accessToken.access_token, tenantId: tenantId, }))
    }
  }, [productId])

 const fetchData = allStockReport && allStockReport.length > 0 ? allStockReport.filter(item => item !== undefined) : [];

  return (
    <>
      <Row className=" scrollable-table">
        {fetchData ? (
          <Table
            className="mt-5"
            hover
            bordered
            responsive

          >
            <thead>
              <tr style={{color:'#495057'}}>
                <th className="px-2" style={{ backgroundColor: '#F9FAFB' }}>{i18n("Date")}</th>
                <th className="px-2" style={{ backgroundColor: '#F9FAFB' }}>{i18n("Voucher Type")}</th> 
                <th className="px-2" style={{ backgroundColor: '#F9FAFB' }}>
                  {i18n("Quantity")}
                </th>
                <th className="px-2" style={{ backgroundColor: '#F9FAFB' }}>{i18n("Remarks")}</th>
              </tr>
            </thead>
            {Object.values(fetchData).map((item, index) => {
              const date = item.created_at;
              const change = moment(date).format("DD-MM-YYYY");
              return (
                <tbody>
                  <tr key={item.id}>
                    <td className="py-2 px-2 m-1 mx-3">{change}</td>
                    <td className="py-2 px-2 m-1 mx-3">{formatVoucherType(item.type) || "-"}</td>
                    <td className="py-2 px-2 m-1 mx-3">{item?.quantity ? item?.quantity : "0"}{ }</td>
                    <td className="py-2 px-2 m-1 mx-3">{item.remarks}</td>
                  </tr>
                </tbody>
              );
            })}
          </Table>
        ) : (
          <Table className="mt-5 cursor-pointer" bordered>
            <thead>
              <tr>
                <th className="px-6"  >{i18n("Date")}</th>
                <th className="px-2">{i18n("Voucher Type")}</th> 
                <th className="px-2"  >
                  {i18n("Quantity")}
                </th>
                <th className="px-2"  >{i18n("Remarks")}</th>
              </tr>
            </thead>
          </Table>
        )}
        {!fetchData && (
          <div className="d-flex flex-column justify-content-center align-items-center w-100 h-100">
            <p> {i18n("No stock report for this product..")} </p>
          </div>
        )}
      </Row>
    </>
  );
};

export default ItemStockReport;