import React, { useEffect, useState } from "react";
import DataGrid from "../../../../shared/components/DataGrid";
import { useNavigate } from "react-router";
import { format } from "date-fns";
import { i18n } from "../../../../shared/helpers/sharedMethods";
import {
  selectAllSubscriptionData,
  selectSubscriptionDataById,
} from "../../../store/SubscriptionSlice";
import { useDispatch, useSelector } from "react-redux";
import { Badge } from "react-bootstrap";
import { placeholderText } from "../../../../shared/helpers/sharedMethods";
import {
  getAllSubscriptionVoucher,
  selectSubscriptionVoucherById,
} from "../../../store/getAllSubscriptionVoucherSlice";
import { getLocalizedDate } from "../../../../shared/helpers/localizationHelper";
import GlobalCurrency from "../../../../shared/components/GlobalCurrency";

const InvoiceHistory = ({ tabs, data }) => {
  ;
  const subsData = useSelector((state) =>
    selectSubscriptionVoucherById(
      state,
      data?.subscription_vouchers[0]?.voucher_id
    )
  );
  ;
  const currencySymbol = useSelector(
    (state) => state.getSetting?.dataSetting.currency
  );
  ;
  const subData = useSelector(selectAllSubscriptionData);
  ;
  const [state, setState] = useState();
  const [filterData, setFilterData] = useState("");

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { accessToken, isLoggedIn } = useSelector((state) => state?.auth);
  const selectedBusiness = useSelector(
    (state) => state?.auth?.selectedBusiness
  );

  const tenantId = selectedBusiness?.pivot?.tenant_id;
  useEffect(() => {
    dispatch(
      getAllSubscriptionVoucher({ token: accessToken, tenantId: tenantId })
    );
  }, [dispatch]);

  useEffect(() => {
    setState([subsData]);
  }, [data]);

  const handleView = (data) => {
    ;
    navigate("/subscription/membership/view/" + data?.id);
  };

  const gridColumns = [
    // {
    //   id: "id",
    //   name: "#",
    //   selector: (row) => row.id,
    //   sortable: true,
    //   width: "120px",
    // },
    {
      id: "voucher_date",
      name: i18n("Date"),
      selector: (row) => format(new Date(row.voucher_date), "dd-MM-yyyy "),
      sortable: true,
    },
    {
      id: "subscription_number",
      name: placeholderText("Voucher Number"),
      selector: (row) => row?.voucher_number,
      sortable: true,
    },
    {
      id: "grand_total",
      name: placeholderText("grand total"),
      selector: (row) => row?.grand_total,
      sortable: true,
      cell: (row) =>
        <span>
          <GlobalCurrency price={row?.grand_total} />
        </span>
    },
    {
      id: "created_at",
      name: i18n("Created At"),
      selector: (row) => row?.created_at,
      cell: (row) => {
        const isoDateString = row.created_at;
        return getLocalizedDate(isoDateString);
      },
      sortable: true,
    },
    {
      id: "paid_amount",
      name: placeholderText("Paid Amount"),
      selector: (row) => row?.paid_amount,
      sortable: true,
      cell: (row) =>
        <span>
          <GlobalCurrency price={row?.paid_amount} />
        </span>
    },
    {
      id: "status",
      name: placeholderText("Status"),
      selector: (row) => {
        const status =
          row?.paid_amount === row?.grand_total
            ? "Paid"
            : row?.paid_amount > row?.grand_total
              ? "Partially Paid"
              : "Unpaid";

        return (
          <Badge
            bg={
              status === "Paid"
                ? "success"
                : status === "Partially Paid"
                  ? "warning"
                  : "danger"
            }
            style={{
              padding: "5px",
              borderRadius: "5px",
              color:
                status === "Paid"
                  ? "white"
                  : status === "Partially Paid"
                    ? "black"
                    : "white",
            }}
          >
            {status}
          </Badge>
        );
      },
      sortable: true,
    },
  ];

  return (
    <>
      <DataGrid
        data={subsData ? [subsData] : []}
        allData={[subsData]}
        columns={gridColumns}
        addFilteredData={setFilterData}
        isPaginated={true}
        handleRowClick={handleView}
        subscription="subscription"
      />
    </>
  );
};

export default InvoiceHistory;
