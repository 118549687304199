import {
  createSlice,
  createAsyncThunk,
} from "@reduxjs/toolkit";
import { i18n } from "../../shared/helpers/sharedMethods";
import { axiosRequest } from "../../shared/helpers/axiosHelper";

const initialState = {
  status: false,
  reportItems: [],
  error: false,
  errorMessage: '',
  loading:false,
};

export const fetchSalesSummary = createAsyncThunk(
  "salesSummary/fetch",
  async ({ token, tenant_id, startDate, endDate }) => {
    try {
      const salesSummaryResponse =await axiosRequest({
        method: "GET",
        url: `get-sales-summary-report`,
        params: { startDate, endDate },
        headers: { Authorization: `Bearer ${token}`, "X-Tenant": tenant_id },
      });
      return salesSummaryResponse.data;
    } catch (err) {
      await window.log.logToFile({ message: "Error while fetching Sales Summary", level: "error" });
      throw new Error("Error while fetching Sales summary report")
    }
  }
);

const saleSummarySlice = createSlice({
  name: "saleSummary",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
    .addCase(fetchSalesSummary.pending, (state) => {
      state.loading = true;
    })
      .addCase(fetchSalesSummary.fulfilled, (state, action) => {
        state.status = true;
        state.loading = false;
        state.reportItems = action.payload;
      })
      .addCase(fetchSalesSummary.rejected, (state, action) => {
        state.error = true;
        state.loading = false;
        state.errorMessage = (i18n("Error while fetching Sales summary report. Please try again later."));
        state.status = false;
      })
  },
});

export default saleSummarySlice.reducer;
