import VoucherIndex from "../screens/VoucherIndex";
import CreateVoucher from "../screens/CreateVoucher";
import EditVoucher from "../screens/EditVoucher";
import VoucherDetail from "../components/VoucherDetails";
import { i18n } from "../../shared/helpers/sharedMethods";
import VoucherInvoiceView from "../components/VoucherInvoiceView";
import VoucherImport from "../screens/VoucherImport";

const rootPath = "voucher";

export const proformaInvoiceRoutes = [
  {
    path: rootPath + "/proforma-invoice",
    element: (
      <VoucherIndex
        title={i18n("Proforma Invoice")}
        type="proforma_invoice"
        button={i18n("Create Proforma Invoice")}
        voucherType="Sales"
        path={rootPath + "/proforma-invoice"}
      />
    ),
    isProtected: true,
  },
  {
    path: rootPath + "/proforma-invoice/create",
    element: (
      <CreateVoucher
        voucherType="Sales"
        title={i18n("Create Proforma Invoice")}
        noLabel={true}
        type="proforma_invoice"
        path={rootPath + "/proforma-invoice"}
        no="Proforma Invoice No"
        date="Proforma Invoice Date"
      />
    ),
    isProtected: true,
  },
  {
    path: rootPath + "/proforma-invoice/edit/:id",
    element: (
      <EditVoucher
        path={rootPath + "/proforma-invoice"}
        title={i18n("Edit Proforma Invoice")}
        type="proforma_invoice"
      />
    ),
    isProtected: true,
  },
  // {
  //   path: rootPath + "/proforma-invoice/view/:id",
  //   element: (
  //     <VoucherDetail
  //       path={rootPath + "/proforma-invoice"}
  //       button={i18n("Create Proforma Invoice")}
  //       type="proforma_invoice"
  //       title={i18n("Manage Proforma Invoice")}
  //     />
  //   ),
  //   isProtected: true,
  // },
  {
    path: rootPath + "/proforma-invoice/invoice/:id",
    element: (
      <VoucherInvoiceView
        path={rootPath + "/proforma-invoice"}
        button={i18n("Create Proforma Invoice")}
        type="proforma_invoice"
        title={i18n("Manage Proforma Invoice")}
      />
    ),
    isProtected: true,
  },
  {
    path: rootPath + "/proforma-invoice/import",
    element: (
      <VoucherImport
      path={rootPath + "/proforma-invoice"}
      type="proforma_invoice"
      title={i18n("Import Proforma Invoice")}
      voucherType="Sales"
      />
    ),
    isProtected: true,
  },
];
