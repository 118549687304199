import { Button, Col, Form, InputGroup } from "react-bootstrap";
import { Menu, MenuItem, Typeahead } from "react-bootstrap-typeahead";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useRef, useState } from "react";
import { cloneDeep, isEmpty } from "lodash";
import { ErrorMessage } from "formik";
import {
  changeMember,
  toggleEditMemberModal,
} from "../../../../store/CreateSubscriptionSlice";
import {
  i18n,
  placeholderText,
} from "../../../../../shared/helpers/sharedMethods";
import { useHotkeys } from "react-hotkeys-hook";
import { UilMultiply } from '@iconscout/react-unicons'
import { AddMember, EditMemberById, fetchMemberByMobileNo, selectAllMemberData } from "../../../../store/MemberSlice";
import EditMember from "../../../../screens/subscription_screen/EditMember";
import CreateMember from "../../../../screens/subscription_screen/CreateMember";

const SubscriptionMemberAhead = ({ isEdit, errors, partyData, setMemberId }) => {
  const dispatch = useDispatch();
  const allMembers = useSelector(selectAllMemberData);
    
  const { member } = useSelector((state) => state.createSubscription);
  const showEditMemberModal = useSelector(
    (state) => state?.createSubscription?.memberstatus?.showEditMemberModal
  );
  const activeEditMemberTab = useSelector(
    (state) => state?.createSubscription?.memberstatus?.activeEditMemberTab
  );
  ;
  const typeaheadRef = useRef(null);

  const [memberChanged, setMemberChanged] = useState(false);
  const [selectedItem, setSelectedItem] = useState([]);
  const [showCreateMemberModal, setShowCreateMemberModal] = useState(false);
  const [autoFill, setAutoFill] = useState("");
  const [showMemberBlock, setShowMemberBlock] = useState(false);
  const [isCustomOption, setIsCustomOption] = useState(false);

  const { accessToken, isLoggedIn } = useSelector((state) => state?.auth);
  const selectedBusiness = useSelector((state) => state?.auth?.selectedBusiness);
  const tenantId = selectedBusiness?.pivot?.tenant_id;
  const terminalNumber = useSelector((state) => state?.auth?.terminalNumber);
  const memberState = useSelector((state) => state.members);
  const memberStates = useSelector((state) => state.members.member);

  const [isSameAsMember, setIsSameAsMember] = useState(true);
  const [mobileNumber, setMobileNumber] = useState(true);

  useEffect(() => {
    if (isEdit && member && !isEmpty(member)) {
      setSelectedItem([member]);
    }
  }, [member]);

  useEffect(() => {
    if (isEdit && selectedItem && selectedItem.length > 0) {
      const selectedPhoneNumber = selectedItem[0]?.mobile;
      
      // Check if partyData's phone number matches the selectedItem's mobile number
      if (partyData.phone_number !== selectedPhoneNumber) {
        setIsSameAsMember(false);  // Set to false if phone numbers do not match
      }else {
        setIsSameAsMember(true);
      }
    }
  }, [partyData.phone_number, selectedItem, isEdit]);

  useEffect(() => {
    if (selectedItem && memberChanged) {
      if (selectedItem.length > 0) {
        dispatch(changeMember(selectedItem[0]));
        setMemberChanged(false);
      } else {
        dispatch(changeMember({}));
        setMemberChanged(false);
      }
    }

    setMemberId(selectedItem[0]);
  }, [selectedItem, memberChanged, dispatch]);

  const handleMemberSelect = (selectedMember) => {
    setMemberChanged(true);
    setSelectedItem(selectedMember);
    if (!isSameAsMember) {
      setMemberId(selectedMember[0]);
    }
  };
  const resetEditMemberActiveTab = () => {
    dispatch(resetEditMemberActiveTab());
  };

  const handleClick = () => {
    setShowMemberBlock(true);
  };

  const setShowEditMemberModal = (status) => {
    dispatch(
        toggleEditMemberModal({
        toggle: status,
        activeEditMemberTab: "general",
      })
    );
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter" && selectedItem.length === 0 && !isSameAsMember) {
      setShowCreateMemberModal(true);
      setAutoFill(typeaheadRef.current.getInput().value);
    }
  };
  

  ;
  useEffect(() => {
    if (isSameAsMember && partyData?.phone_number === member?.mobile) {
      const memberData = {
        member_name: partyData?.name,  
        mobile: member?.mobile,
        member_id: member?.member_id,
      };
      
      dispatch(EditMemberById({
        id: member?.id,
        data: memberData,
        token: accessToken.access_token,
        tenant_id: tenantId,
      }));
    }
  }, [partyData?.phone_number, partyData?.name, member?.mobile]);
  
  const handleSameAsMemberToggle = (e) => {
    const checked = e.target.checked;
    setIsSameAsMember(checked);  // Update isSameAsMember state
    
    if (!checked) {
      // Clear member data when toggling to false
      setSelectedItem([]);
      dispatch(changeMember({}));
      setMemberId(null);
    } else if (partyData?.phone_number) {
      // Assign party data to member if toggled to true
      const phoneNumbers = allMembers.map((member) => member.mobile);
      if (phoneNumbers.includes(partyData.phone_number)) {
        setMobileNumber(partyData.phone_number);
      }
    }
  };  

  useEffect(() => {
    if (isSameAsMember && partyData?.phone_number) {
      const phoneNumbers = allMembers.map((member) => member.mobile);
      if (phoneNumbers.includes(partyData.phone_number)) {
        setMobileNumber(partyData.phone_number);
      } else {
  
        setMobileNumber(partyData.phone_number);
      }
    } else {
      setMobileNumber('');
    }
  }, [partyData.phone_number, isSameAsMember]);  
  
  useEffect(() => {
    if (isSameAsMember && mobileNumber) {
      dispatch(fetchMemberByMobileNo({
        no: mobileNumber,
        token: accessToken.access_token,
        tenant_id: tenantId,
      }));
    }
  }, [isSameAsMember, mobileNumber]);
  
  const memberMobile = useSelector((state) => state.members.memberMobile);

  useEffect(() => {
    if (isSameAsMember && memberMobile) {
      if (memberMobile.length > 0) {
        dispatch(changeMember(memberMobile[0]));
        setMemberId(memberMobile[0]);
      } else {
        dispatch(changeMember({}));
      }
    }
  }, [memberMobile, dispatch]);
  
  const filterByFields = ["member_name", "mobile"];
  return (
    <>
      {isSameAsMember ? (
        <Form.Group as={Col} lg={12}>
          <div>
          <Form.Label>{i18n("Member")}</Form.Label>
          <span className="required" />

          <Form.Check
              type="checkbox"
              id="same-as-billing"
              className="pb-0"
              style={{ fontWeight: "normal", marginLeft: '56%', marginTop: '-24px' }}
              label={i18n("Same As Party")}
              checked={isSameAsMember}
              onChange={handleSameAsMemberToggle}
            />
            <Form.Label
              htmlFor="same-as-billing"
              style={{ fontWeight: "normal", cursor: "pointer" }}
            >
            </Form.Label>
            </div>
          {isEdit && selectedItem && selectedItem.length > 0 ? (
              <Form.Control
                type="text"
                name="member_name"
                className={`${errors["age"] ? "error-highlight" : "form-control"}`}
                value={selectedItem[0]?.member_name || ""}
                style={{ marginTop: '-18px'}}
                onChange={(e) => {
                  const { value, name } = e.target
                  dispatch(changeMember({ value, name }));
                }}
                readOnly
              />
            ): (
              <>
              <Form.Control
                type="text"
                name="member_name"
                className={`${errors["age"] ? "error-highlight" : "form-control"}`}
                value={partyData?.name || ""}
                style={{ marginTop: '-18px'}}
                onChange={(e) => {
                  const { value, name } = e.target
                  dispatch(changeMember({ value, name }));
                }}
                readOnly
              />

              </>
            )}
        </Form.Group>
      ) : (
        <>
          {member && showEditMemberModal && (
            <EditMember
              show={showEditMemberModal}
              setShowEditMember={setShowEditMemberModal}
              memberId={member?.id}
              isTriggeredFromMember={true}
              memberQuickEdit={handleMemberSelect}
              activeEditMemberTab={activeEditMemberTab}
            />
          )}
          {showCreateMemberModal && (
            <CreateMember
              show={showCreateMemberModal}
              setShowMemberCreate={setShowCreateMemberModal}
              isTriggeredFromMember={true}
              memberQuickCreate={handleMemberSelect}
              autoFill={autoFill}
              isSubscriptionLive={true}
            />
          )}

          <Form.Group as={Col} lg={12}>
            <div className="d-flex justify-content-between">
              <div>
                <Form.Label>{i18n("Member")}</Form.Label>
                <span className="required" />
              </div>

              <Form.Check
                type="checkbox"
                id="same-as-billing"
                className="pb-0"
                style={{ fontWeight: "normal", marginLeft: '32%' }}
                label={i18n("Same As Party")}
                checked={isSameAsMember}
                onChange={handleSameAsMemberToggle}
              />
              <Form.Label
                htmlFor="same-as-billing"
                style={{ fontWeight: "normal", cursor: "pointer" }}
              >
              </Form.Label>

              {!isEmpty(member) && (
                <Button
                  variant="link float-end pb-0"
                  size="sm"
                  onClick={() => setShowEditMemberModal(true)}
                >
                  {i18n("Edit")}
                </Button>
              )}
            </div>

            <InputGroup>
              {allMembers && (
                <Typeahead
                  ref={typeaheadRef}
                  allowNew
                  renderMenu={(results, menuProps) => (
                    <Menu {...menuProps}>
                      {results.map((result, position) => {
                        if (result.customOption) {
                          setIsCustomOption(true);
                        }

                        return (
                          <MenuItem
                            key={position}
                            option={result}
                            position={position}
                          >
                            {result.customOption ? (
                              <div onClick={(e) => {
                                setShowCreateMemberModal(true);
                                setAutoFill(result.member_name);
                              }}>
                                Add "{result.member_name}"
                              </div>
                            ) : (
                              <div>
                                {result.member_name}
                                <div>
                                  {result.mobile ? (
                                    <small>
                                      <b>Mobile:</b> {result.mobile}
                                    </small>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                            )}
                          </MenuItem>
                        );
                      })}
                    </Menu>
                  )}
                  id={"member-typeahead"}
                  newSelectionPrefix="Add a new member : "
                  options={allMembers}
                  placeholder={placeholderText("Search by Name / Mobile number")}
                  onChange={handleMemberSelect}
                  filterBy={filterByFields}
                  selected={selectedItem}
                  labelKey="member_name"
                  isInvalid={errors.member_id}
                  disabled={isEdit || isSameAsMember}  // Disable the input if it's in edit mode or isSameAsMember is true
                  onFocus={handleClick}
                  onKeyDown={handleKeyDown}
                />
              )}
              {!isEdit && selectedItem && selectedItem.length > 0 ? (
                <InputGroup.Text
                  className="cursor-pointer fs-2"
                  style={{ background: "none" }}
                  onClick={() => {
                    setSelectedItem([]);
                    setMemberChanged(true);
                  }}
                >
                  <UilMultiply size='14' title="Clear Item" className="text-white" style={{ backgroundColor: 'rgb(238,91,80)', borderRadius: '3px' }} />
                </InputGroup.Text>
              ) : (
                ""
              )}
            </InputGroup>
            <ErrorMessage
              name="member_id"
              component="span"
              className="text-danger d-block fw-400 fs-small mt-2"
            />
          </Form.Group>
        </>
      )}
    </>
  );
};

export default SubscriptionMemberAhead;