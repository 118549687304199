import { useNavigate } from "react-router";

import "../../../../assets/css/frontend.css";
import {
  DeleteMember,
  MemberAllData,
  MemberData,
  resetMember,
  resetMemberState,
  selectAllMemberData,
  selectMemberDataById,
} from "../../store/MemberSlice";

import { DataTable } from "primereact/datatable";
import { classNames } from "primereact/utils";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { IconField } from "primereact/iconfield";
import { InputIcon } from "primereact/inputicon";
import { InputNumber } from "primereact/inputnumber";
import { Dropdown } from "react-bootstrap";
//import { Button } from "primereact/button";
import { ProgressBar } from "primereact/progressbar";
import { Calendar } from "primereact/calendar";
import { MultiSelect } from "primereact/multiselect";
import { Slider } from "primereact/slider";
import { Tag } from "primereact/tag";
import { useDispatch, useSelector } from "react-redux";
import { useCallback, useEffect, useMemo, useState } from "react";
import DeleteModel from "../../../shared/components/DeleteModel";
import Layout from "../../../shared/components/Layout";
import { resetCreateSubscription } from "../../store/CreateSubscriptionSlice";
import {
  resetAddon,
  resetProductDropdown,
} from "../../store/GetParentProductEntiry";
import { resetSubscriptionParty } from "../../../subscription/store/LivePartyGetsSlice";
import { Badge, Container, Form, Stack, Button, InputGroup, ButtonGroup } from "react-bootstrap";
import Header from "../../../shared/components/Header";
import { i18n } from "../../../shared/helpers/sharedMethods";
import { changeToggleShow } from "../../../shared/store/sharedSlice";
import { faFileInvoice } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { UilUserPlus } from "@iconscout/react-unicons";
import DetailsModal from "../../../shared/components/DetailsModal";
import TwoStageLayoutHeader from "../../../shared/components/TwoStatgeLayoutHeader";
import OverViewSubscription from "../../components/subscription_order/tabs/Overview";
import InvoiceHistory from "../../components/subscription_order/tabs/InvoiceHistory";
import { subscriptionReactive } from "../../store/SubscriptionReactiveSlice";
import {
  faCancel,
  faCirclePlay,
  faPenToSquare,
} from "@fortawesome/free-solid-svg-icons";
import { UilEdit, UilTrashAlt, UilBan, UilAngleDown } from "@iconscout/react-unicons";
//import CancelSubscription from "../../components/CancelSubscription";
//import { resetSubscriptionItem } from "../../store/subscriptionProductEntitySlice";
import { getLocalizedDate } from "../../../shared/helpers/localizationHelper";
import ShortcutKeys from "../../../shared/components/ShortcutKeys";
import ReactiveSubscription from "../../components/ReactiveSubscription";
import CancelSubscription from "../../components/CancelSubscription";
import { resetSubscriptionItem } from "../../store/subscriptionProductEntitySlice";

import { UilUsersAlt } from "@iconscout/react-unicons";
import DataGridHeader from "../../../shared/components/DataGridHeader";
import CustomeSortIcon from "../../../shared/components/CustomeSortIcon";
import CreateMember from "./CreateMember";
import EditMember from "./EditMember";
import { FaTrash } from "react-icons/fa";
import MemberProfileInfo from "../../components/member_view/MemberProfileInfo";
import { selectSubscriptionDataById } from "../../store/SubscriptionSlice";
import SubscriptionList from "../../components/member_view/tabs/SubscriptionList";
import { HeightWeightExtention } from "../../../shared/config/defaults";
import MemberAttendance from "../../components/member_view/tabs/MemberAttendance";
import { fetchSetting } from "../../../settings/store/getSettingSlice";
import { handleExportClick, downloadCsvFile } from "../../../shared/export";
const SubscriptionMember = ({ featureName }) => {
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const [show, setShow] = useState(false);
  const [showEditMember, setShowEditMember] = useState(false);
  const [id, setId] = useState(false);
  const [memberId, setMemberId] = useState(false);
  const [name, setName] = useState();
  const [showDetails, setShowDetails] = useState(false);
  const alldata = useSelector(selectAllMemberData);
  const [memberData, setMemberData] = useState("");
  const [showCancel, setShowCancel] = useState(false);
  const [cancelId, setCancelId] = useState(null);
  const [showReactive, setShowReactive] = useState(false);
  const [refreshKey, setRefreshKey] = useState(0);
  const [globalFilter, setGlobalFilter] = useState("");
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [filters, setFilters] = useState(null);
  const [balanceFrozen, setBalanceFrozen] = useState(false);
  const [sortField, setSortField] = useState("created_at");
  const [sortOrder, setSortOrder] = useState(-1);
  const [showMemberCreate, setShowMemberCreate] = useState(false);
  const selectedMember = useSelector((state) => selectMemberDataById(state, id));
  const [csvData, setCsvData] = useState('');
  // const globalPlanFeatures = useSelector(
  //   (state) => state?.globalPlan?.features
  // );
  const globalPlanFeatures = useSelector(
      (state) => state?.globalPlan?.features
  );


  useEffect(() => {
    if (!globalPlanFeatures.includes(featureName)) {
      navigate("/setting/not-in-plan");
    }
  }, []);

  const { accessToken, isLoggedIn } = useSelector((state) => state?.auth);

  const selectedBusiness = useSelector(
    (state) => state?.auth?.selectedBusiness
  );

  const tenantId = selectedBusiness?.pivot?.tenant_id;
  const reactivation = useSelector(
    (state) => state.subscriptionReactive.reactive
  );
  const admin = selectedBusiness?.pivot?.is_owner;
  const trainer = useSelector((state) => state?.initInstall?.role?.name);
  const trainerId = useSelector((state) => state?.initInstall?.role?.id);

  const allMembers = useMemo(() => {
    if (admin === 1) {
      return alldata; // Admin gets all data
    }
    if (trainer === "trainer") {
      // Trainer only gets their assigned members
      return alldata.filter((member) => member.trainer_id === trainerId);
    }
    return [];
  }, [alldata, admin, trainer, trainerId]);

  /* eslint-disable no-restricted-globals */

  useEffect(() => {
    dispatch(
      fetchSetting({
        accessToken: accessToken.access_token,
        tenant_id: selectedBusiness?.pivot?.tenant_id,
      })
    );
  }, [status]);
 /* eslint-enable no-restricted-globals */

  useEffect(() => {
    if (tenantId && accessToken?.access_token && admin === 1) {
      dispatch(MemberAllData({ token: accessToken.access_token, tenant_id: tenantId, isAdmin: true }));
    }
    if (tenantId && accessToken?.access_token && trainer === "trainer") {
      dispatch(MemberAllData({ token: accessToken.access_token, tenant_id: tenantId, isAdmin: false, trainer_id: trainerId }))
    }
  }, [reactivation]);
  useEffect(() => {
    setMemberData(allMembers);
  }, [allMembers]);

  const handleRefreshMembers = useCallback(() => {
    if (admin === 1) {
      dispatch(MemberAllData({ token: accessToken.access_token, tenant_id: tenantId, isAdmin: true }));
    }
    if (trainer === "trainer") {
      dispatch(MemberAllData({ token: accessToken.access_token, tenant_id: tenantId, isAdmin: false, trainer_id: trainerId }))
    }
    dispatch(resetMemberState());
    setRefreshKey((prevKey) => prevKey + 1);
    setMemberData(allMembers);
  }, [dispatch]);

  useEffect(() => {
    const handleOnlineStatusChange = () => {
      setIsOnline(navigator.onLine);
    };

    window.addEventListener("online", handleOnlineStatusChange);
    window.addEventListener("offline", handleOnlineStatusChange);

    return () => {
      window.removeEventListener("online", handleOnlineStatusChange);
      window.removeEventListener("offline", handleOnlineStatusChange);
    };
  }, []);

  useEffect(() => {
    if (!isOnline) {
      navigate("/dashboard/sales", { state: true });
    }
  }, [isOnline]);

  const handleClick = () => {
    dispatch(resetMemberState());
    //dispatch(resetMember());
    setShowMemberCreate(true);
  };

  const handleEdit = (data) => {
    dispatch(resetMemberState());
    // dispatch(resetMember());
    setShowEditMember(true);
    setShowDetails(false);
    setMemberId(data.id);
  };

  const handleView = (row) => {
    setShowDetails(!showDetails);
    setId(row.id);
  };

  const handleDelete = async (data) => {
    setShow(!show);
    setId(data.id);
    setName(data.name);
    setShowDetails(false);
  };

  const onDelete = () => {
    dispatch(DeleteMember({ id: id, token: accessToken.access_token, tenant_id: tenantId }));
    setShow(false);
    navigate("/subscription/members");
  };

  const handleCancel = (id) => {
    setShowCancel(true);
    setCancelId(id);
  };

  const handleReactive = (id) => {
    setShowReactive(true);
    setId(id);
  };
  const confirmReactivate = () => {
    dispatch(
      subscriptionReactive({
        id: id,
        token: accessToken.access_token,
        tenantId: tenantId,
      })
    );
    setShowReactive(false);
  };
  const handleReturnToGrid = () => {
    setShowDetails(false);
  };
  const prepareExportData = () => {
    return alldata.map(member => ({
      ID: member.id,
      Name: member.member_name,
      Mobile: member.mobile,
      Trainer: member.trainer_name,
      Age: member.age,
      MemberNo: member.member_no,
      Weight: member.weight,
      Height: member.height,
      CreatedAt: member.created_at,
    }));
  };

  const handleExport = () => {
    const exportData = prepareExportData();
    console.log('dataa',exportData);
    const fileName = 'members';
    handleExportClick(exportData, setCsvData, downloadCsvFile, fileName);
  };

  const header = (
    <TwoStageLayoutHeader
      ItemActions={
        <Stack
          gap={3}
          direction="horizontal"
          className="mt-2 mb-2 justify-content-end"
        >
          <InputGroup className="me-3 custom-input">
            <Button
              variant="outline-secondary"
              onClick={() => handleEdit(selectedMember)}
              size="sm"
              style={{ padding: '0.5rem', background: 'none' }}
            >
              <UilEdit className="mx-2 mb-1" size="16" />
              {i18n("Edit")}
            </Button>

            <Dropdown as={ButtonGroup}>
              <Dropdown.Toggle
                variant="outline-secondary"
                id="dropdown-basic"
                size="sm"
                style={{ height: '36px' }}
              >
                <UilAngleDown size="16" />
              </Dropdown.Toggle>

              <Dropdown.Menu className="custom-dropdown-menu">
                <Dropdown.Item
                  as="button"
                  onClick={() => handleDelete(selectedMember)}
                  style={{ color: 'red' }}
                >
                  <span className="d-flex align-items-center">
                    <UilTrashAlt className="mx-2 mb-1" size="16" style={{ color: 'red' }} />
                    {i18n("Delete")}
                  </span>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </InputGroup>
        </Stack>
      }
      currentTitle={
        <>
          {selectedMember?.member_name}
          <Form.Label className="mx-2 badge bg-success bg-gradient">
            {i18n("Member")}
          </Form.Label>
        </>
      }
      returnToGrid={handleReturnToGrid}
    />
  );

  const memberTabs = [
    {
      key: "profile",
      title: i18n("Details"),
      component: <MemberProfileInfo selectedMember={selectedMember} memberId={id} />,
    },
    {
      key: "subscription",
      title: i18n("Subscription"),
      component: <SubscriptionList memberId={id} />,
    },
    {
      key: "attendance",
      title: i18n("Attendance"),
      component: <MemberAttendance data={selectedMember?.subscription} memberId={id} />
    }
  ];

  const actionBodyTemplate = (row) => {
    return (
      <div className="d-flex justify-content-evenly align-items-center">
        <UilEdit
          className="mx-2 mb-1"
          size="15"
          style={{ cursor: "pointer" }}
          onClick={(e) => {
            e.stopPropagation(); // Prevents row click from firing
            handleEdit(row);
          }}
        />
        &nbsp;
        {/* <i className="fa fa-trash mx-2 " aria-hidden="true"></i> */}
        <UilTrashAlt
          className="mx-2 mb-1"
          size="15"
          style={{ cursor: "pointer" }}
          onClick={(e) => {
            e.stopPropagation(); // Prevents row click from firing
            handleDelete(row);
          }}
        />
      </div>
    );
  };

  const handleClearFilters = () => {
    setFilters({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      member_name: { value: null, matchMode: FilterMatchMode.CONTAINS },
      mobile: { value: null, matchMode: FilterMatchMode.CONTAINS },
      ...(admin === 1 && {
        "trainers.name": { value: null, matchMode: FilterMatchMode.CONTAINS },
      }),
      age: { value: null, matchMode: FilterMatchMode.CONTAINS },
      member_id: { value: null, matchMode: FilterMatchMode.EQUALS },
      weight: { value: null, matchMode: FilterMatchMode.CONTAINS },
      height: { value: null, matchMode: FilterMatchMode.EQUALS },
      created_at: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
      },
    });
    setGlobalFilter("");
    setGlobalFilterValue("");
    setMemberData(allMembers);
  };

  const dateCreatedAtBodyTemplate = (rowData) => {
    return getLocalizedDate(rowData.created_at);
  };

  const handleCreatedAtDateFilterChange = (value, filterCallback) => {
    filterCallback(value);

    if (value) {
      const filtered = allMembers.filter((item) => {
        const createdAtDate = new Date(item.created_at);
        const selectedDate = new Date(value);
        return createdAtDate.toDateString() === selectedDate.toDateString();
      });
      setMemberData(filtered);
    } else {
      setMemberData(allMembers);
    }
  };

  const dateCreatedAtFilterTemplate = (options) => {
    return (
      <Calendar
        value={options.value}
        onChange={(e) =>
          handleCreatedAtDateFilterChange(e.value, options.filterCallback)
        }
        dateFormat="dd/mm/yy"
        mask="99/99/9999"
      />
    );
  };

  const headerCellStyle = {
    fontWeight: "bold",
    fontSize: "11px",
  };

  const customSort = (field) => {
    if (field === 'created_at') {
      setSortOrder(-1);
    } else {
      setSortField(field);
      setSortOrder(-1);
    }
  };

  const sortedData = [...memberData].sort((a, b) => {
    const dateA = new Date(a.created_at);
    const dateB = new Date(b.created_at);

    return dateB - dateA;
  });
  const heightTemplate = (rowData) => {
    return rowData.height ? `${rowData.height} ${HeightWeightExtention.height}` : null;
  };

  const weightTemplate = (rowData) => {
    return rowData.weight ? `${rowData.weight} ${HeightWeightExtention.weight}` : null;
  };

  return (
    <Layout>
      <Header
        currentTitle={i18n("Members")}
        ItemActions={
          <Stack
            gap={3}
            direction="horizontal"
            className="my-2 justify-content-end"
          >
            <ShortcutKeys />
          </Stack>
        }
      />
      <Container fluid className="px-5">
        <div className="border" style={{ background: "white" }}>
          <DataGridHeader
            handleRefreshMembers={handleRefreshMembers}
            allData={allMembers}
            data={allMembers}
            clearFilter={handleClearFilters}
            handleExportClick={handleExport}
            title="Members"
            icons={<UilUserPlus size="50" color="gray" />}
            ItemActions={
              <Stack
                gap={3}
                direction="horizontal"
                className="justify-content-end"
              >
                <Button
                  onClick={handleClick}
                >
                  {i18n("Create Member")}
                </Button>
              </Stack>
            }
          />

          <Container fluid className="px-4 mb-4">
            <div className="bordered-container">
              <DataTable
                value={sortedData}
                // isAllData={"All Members"}
                button="Create Member"
                title="Members"
                paginator
                globalFilter={globalFilter}
                filterDisplay="row"
                rows={10}
                dataKey="id"
                filters={filters}
                emptyMessage={i18n("No members found. Please create new members.")}
                onFilter={(e) => setFilters(e.filters)}
                className="p-datatable-gridlines full-width-table custom-datatable p-component"
                onRowClick={(e) => handleView(e.data)}
                checked={balanceFrozen}
                scrollable
                sortMode="multiple"
                scrollHeight="400px"
                removableSort
                style={{ cursor: "pointer" }}
              >
                <Column
                  field="member_name"
                  header={() => (
                    <div
                      className={`custom-header-${sortField === "member_name"
                        ? sortOrder === 1
                          ? "up"
                          : "down"
                        : ""
                        }`}
                      onClick={() => customSort("member_name")}
                      style={{
                        cursor: "pointer",
                        display: "flex",
                        alignItems: "center",
                        fontSize: "11px",
                        lineHeight: "11px",
                      }}
                    >
                      {i18n("Member")}
                      <CustomeSortIcon
                        sortOrder={sortField === "member_name" ? sortOrder : 0}
                        style={{ marginLeft: "4px", verticalAlign: "middle" }}
                      />
                    </div>
                  )}
                  sortable
                  filter
                  filterPlaceholder=""
                  headerStyle={headerCellStyle}
                  showFilterMatchModes={false}
                  showClearButton={false}
                  showFilterOperator={false}
                  showAddButton={false}
                  filterMatchMode={FilterMatchMode.CONTAINS}
                  showApplyButton={false}
                  showFilterMenu={false}
                />
                <Column
                  field="mobile"
                  header={() => (
                    <div
                      className={`custom-header-${sortField === "mobile"
                        ? sortOrder === 1
                          ? "up"
                          : "down"
                        : ""
                        }`}
                      onClick={() =>
                        customSort("mobile")
                      }
                      style={{
                        cursor: "pointer",
                        display: "flex",
                        alignItems: "center",
                        fontSize: "11px",
                        lineHeight: "11px",
                      }}
                    >
                      {i18n("Mobile")}
                      <CustomeSortIcon
                        sortOrder={
                          sortField === "mobile"
                            ? sortOrder
                            : 0
                        }
                        style={{ marginLeft: "4px", verticalAlign: "middle" }}
                      />
                    </div>
                  )}
                  sortable
                  filter
                  filterPlaceholder=""
                  headerStyle={headerCellStyle}
                  showFilterMatchModes={false}
                  showClearButton={false}
                  showFilterOperator={false}
                  showAddButton={false}
                  filterMatchMode={FilterMatchMode.CONTAINS}
                  showApplyButton={false}
                  showFilterMenu={false}
                />

                {admin === 1 && (

                  <Column
                    field="trainers.name"
                    header={() => (
                      <div
                        className={`custom-header-${sortField === "trainers.name"
                            ? sortOrder === 1
                              ? "up"
                              : "down"
                            : ""
                          }`}
                        onClick={() => customSort("trainers.name")}
                        style={{
                          cursor: "pointer",
                          display: "flex",
                          alignItems: "center",
                          fontSize: "11px",
                          lineHeight: "11px",
                        }}
                      >
                        {i18n("Trainer")}
                        <CustomeSortIcon
                          sortOrder={sortField === "trainers.name" ? sortOrder : 0}
                          style={{ marginLeft: "4px", verticalAlign: "middle" }}
                        />
                      </div>
                    )}
                    sortable
                    filter
                    filterPlaceholder=""
                    headerStyle={headerCellStyle}
                    showFilterMatchModes={false}
                    showClearButton={false}
                    showFilterOperator={false}
                    showAddButton={false}
                    filterMatchMode={FilterMatchMode.CONTAINS}
                    showApplyButton={false}
                    showFilterMenu={false}
                  />
                )}
                <Column
                  field="age"
                  header={() => (
                    <div
                      className={`custom-header-${sortField === "age"
                        ? sortOrder === 1
                          ? "up"
                          : "down"
                        : ""
                        }`}
                      onClick={() => customSort("age")}
                      style={{
                        cursor: "pointer",
                        display: "flex",
                        alignItems: "center",
                        fontSize: "11px",
                        lineHeight: "11px",
                      }}
                    >
                      {i18n("age")}
                      <CustomeSortIcon
                        sortOrder={
                          sortField === "age" ? sortOrder : 0
                        }
                        style={{ marginLeft: "4px", verticalAlign: "middle" }}
                      />
                    </div>
                  )}
                  sortable
                  filter
                  filterPlaceholder=""
                  headerStyle={headerCellStyle}
                  showFilterMatchModes={false}
                  showClearButton={false}
                  showFilterOperator={false}
                  showAddButton={false}
                  filterMatchMode={FilterMatchMode.CONTAINS}
                  showApplyButton={false}
                  showFilterMenu={false}
                />

                <Column
                  field="member_id"
                  header={() => (
                    <div
                      className={`custom-header-${sortField === "member_id"
                        ? sortOrder === 1
                          ? "up"
                          : "down"
                        : ""
                        }`}
                      onClick={() => customSort("member_id")}
                      style={{
                        cursor: "pointer",
                        display: "flex",
                        alignItems: "center",
                        fontSize: "11px",
                        lineHeight: "11px",
                      }}
                    >
                      {i18n("MEM NO")}
                      <CustomeSortIcon
                        sortOrder={sortField === "member_id" ? sortOrder : 0}
                        style={{ marginLeft: "4px", verticalAlign: "middle" }}
                      />
                    </div>
                  )}
                  sortable
                  filter
                  filterPlaceholder=""
                  headerStyle={headerCellStyle}
                  showFilterMatchModes={false}
                  showClearButton={false}
                  showFilterOperator={false}
                  showAddButton={false}
                  filterMatchMode={FilterMatchMode.CONTAINS}
                  showApplyButton={false}
                  showFilterMenu={false}
                />

                <Column
                  field="weight"
                  header={() => (
                    <div
                      className={`custom-header-${sortField === "weight"
                        ? sortOrder === 1
                          ? "up"
                          : "down"
                        : ""
                        }`}
                      onClick={() => customSort("weight")}
                      style={{
                        cursor: "pointer",
                        display: "flex",
                        alignItems: "center",
                        fontSize: "11px",
                        lineHeight: "11px",
                      }}
                    >
                      {i18n("weight")}
                      <CustomeSortIcon
                        sortOrder={
                          sortField === "weight" ? sortOrder : 0
                        }
                        style={{ marginLeft: "4px", verticalAlign: "middle" }}
                      />
                    </div>
                  )}
                  body={weightTemplate} // Use the custom template for weight
                  sortable
                  filter
                  filterPlaceholder=""
                  headerStyle={headerCellStyle}
                  showFilterMatchModes={false}
                  showClearButton={false}
                  showFilterOperator={false}
                  showAddButton={false}
                  filterMatchMode={FilterMatchMode.CONTAINS}
                  showApplyButton={false}
                  showFilterMenu={false}
                />

                <Column
                  field="height"
                  header={() => (
                    <div
                      className={`custom-header-${sortField === "height"
                        ? sortOrder === 1
                          ? "up"
                          : "down"
                        : ""
                        }`}
                      onClick={() => customSort("height")}
                      style={{
                        cursor: "pointer",
                        display: "flex",
                        alignItems: "center",
                        fontSize: "11px",
                        lineHeight: "11px",
                      }}
                    >
                      {i18n("height")}
                      <CustomeSortIcon
                        sortOrder={
                          sortField === "height" ? sortOrder : 0
                        }
                        style={{ marginLeft: "4px", verticalAlign: "middle" }}
                      />
                    </div>
                  )}
                  body={heightTemplate} // Use the custom template for height
                  sortable
                  filter
                  filterPlaceholder=""
                  headerStyle={headerCellStyle}
                  showFilterMatchModes={false}
                  showClearButton={false}
                  showFilterOperator={false}
                  showAddButton={false}
                  filterMatchMode={FilterMatchMode.CONTAINS}
                  showApplyButton={false}
                  showFilterMenu={false}
                />

                <Column
                  field="created_at"
                  header={() => (
                    <div
                      className={`custom-header-${sortField === "created_at"
                        ? "up"

                        : ""
                        }`}
                      onClick={() => customSort("created_at")}
                      style={{
                        cursor: "pointer",
                        display: "flex",
                        alignItems: "center",
                        fontSize: "11px",
                        lineHeight: "11px",
                      }}
                    >
                      {i18n("Created At")}
                      <CustomeSortIcon
                        sortOrder={sortField === "created_at" ? sortOrder : 0}
                        style={{ marginLeft: "4px", verticalAlign: "middle" }}
                      />
                    </div>
                  )}
                  sortable
                  body={dateCreatedAtBodyTemplate}
                  filter
                  filterElement={dateCreatedAtFilterTemplate}
                  filterPlaceholder=""
                  headerStyle={headerCellStyle}
                  showFilterMatchModes={false}
                  showClearButton={false}
                  showFilterOperator={false}
                  showAddButton={false}
                  filterMatchMode={FilterMatchMode.DATE_IS}
                  // showApplyButton={false}
                  showFilterMenu={false}
                />
              </DataTable>
            </div>
          </Container>
          {showDetails && (
            <DetailsModal
              show={showDetails}
              title={header}
              currentItemId={id}
              ItemTitle="Members"
              header={header}
              tabs={memberTabs}
              setShow={setShowDetails}
              id={id}
            />
          )}
          {showMemberCreate && (
            <CreateMember
              show={showMemberCreate}
              setShowMemberCreate={setShowMemberCreate}
            />
          )}
          {showEditMember && (
            <EditMember
              show={showEditMember}
              memberId={memberId}
              setShowEditMember={setShowEditMember}
            />
          )}
          {show && (
            <DeleteModel
              deleteUserClick={onDelete}
              onClickDeleteModel={setShow}
            />
          )}
        </div>
      </Container>
    </Layout>
  );
};

export default SubscriptionMember;
