import React from "react";
import { ListGroup } from "react-bootstrap";
import { i18n } from "../helpers/sharedMethods";
import { useLocation, useNavigate } from "react-router";
import { Link } from "react-router-dom";


const SyncLogsSidebar = ({ }) => {
    const navigate = useNavigate();
    const location = useLocation();

    return (
        <>
            <div
                className="col-lg-12 "
                style={{ height: "90vh", borderRight: "1px solid lightgray" }}
            >
                <ListGroup className="custom-listgroup-css py-2" variant="flush">

                    <ListGroup.Item className={
                        location.pathname == "/shared/queue" ? "active-highlight" : ""
                    }

                        onClick={() => {
                            navigate('/shared/queue')
                        }}
                    >
                        <Link className="d-flex " to="/shared/queue">
                            {i18n("Sync Queue")}
                        </Link>
                    </ListGroup.Item>
                    <ListGroup.Item className={
                        location.pathname == "/shared/logs" ? "active-highlight" : ""
                    }

                        onClick={() => {
                            navigate('/shared/logs')
                        }}
                    >
                        <Link className="d-flex " to="/shared/logs">
                            {i18n("Error Logs")}
                        </Link>

                    </ListGroup.Item>
                    <ListGroup.Item className={
                        location.pathname == "/shared/info" ? "active-highlight" : ""
                    }

                        onClick={() => {
                            navigate('/shared/info')
                        }}
                    >
                        <Link className="d-flex " to="/shared/info">
                            {i18n("Info Logs")}
                        </Link>

                    </ListGroup.Item>

                </ListGroup>
            </div>
        </>
    );
};

export default SyncLogsSidebar;
