import {
  createAsyncThunk,
  createSlice,
  isRejectedWithValue,
} from "@reduxjs/toolkit";
import { axiosRequest } from "../../shared/helpers/axiosHelper";
import { i18n } from "../../shared/helpers/sharedMethods";
import { environment } from "../../shared/config/environment";
import axios from "axios";
import { faListSquares } from "@fortawesome/free-solid-svg-icons";
import os from 'os';

const initialState = {
  accessToken: {},
  isUserRegistered: false,
  isOtpVerify: false,
  isLoggedIn: false,
  setTenantStatus: false,
  tenants: [],
  currentTenant: {},
  businessStatus: false,
  createConfigStatus: false,
  loading: false,
  error: false,
  errorMessage: {},
  user: {},
  selectedBusiness: {},
  businessSelectedStatus: false,
  deviceId: {},
  phone: "",
  backUpStatus: false,
  isBackupedResources: false,
  saveSettingsStatus: false,
  settings: {},
  permissions: {},
  features: {},
  getStatus: false,
  terminalNumber: null,
  otpErrorMessage: {},
  syncCount: 0,
  otpStatus: '',
  pin: ''
};

export const register = createAsyncThunk("auth/addNew", async (loginCreds) => {
  try {
    const last10Digits = loginCreds.phone;

    const response = await axiosRequest({
      method: "POST",
      url: "auth/login",
      data: { phone: last10Digits },
      headers: {
        "Content-Type": "application/json",
      },
    });

    return last10Digits;
  } catch (error) {
    await window.log.logToFile({
      message: "Unable to register auth",
      level: "error",
    });
    throw error;
  }
});

export const otpVerify = createAsyncThunk("auth/otpVerify", async (data) => {
  try {
    const result = {
      phone: data.phone,
      otp: data.otp,
    };
    const response = await axiosRequest({
      method: "POST",
      url: "auth/verify-otp",
      data: result,
      headers: {
        "Content-Type": "application/json",
      },
    });

    return response;
  } catch (error) {
    await window.log.logToFile({
      message: "Unable to verify OTP",
      level: "error",
    });
    throw new Error(error);
  }
});

export const setCurrentTenantLocal = createAsyncThunk(
  "auth/setCurrentTenantLocal",
  async (data) => {
    try {
      const response = await window.api.setCurrentTenant({
        businessId: data.businessId,
        userId: data.userId,
      });
      return response;
    } catch (error) {
      await window.log.logToFile({
        message: "Unable to set current tenant",
        level: "error",
      });
      throw new Error(error);
    }
  }
);

export const getTerminalNumber = createAsyncThunk(
  "auth/getTerminalNumber",
  async () => {
    try {
      const response = await window.api.getTerminalNumber();
      return response.data;
    } catch (error) {
      await window.log.logToFile({
        message: "Unable to get terminal number",
        level: "error",
      });
      throw new Error(error);
    }
  }
);

export const createBuisnessDetails = createAsyncThunk(
  "auth/createBuisnessDetails",
  async (data) => {
    try {
      const response = await axiosRequest({
        method: "POST",
        url: "tenants",
        data: data,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          //'Authorization': 'Bearer '+data?.accessToken
        },
      });

      return response;
    } catch (error) {
      await window.log.logToFile({
        message: "Unable to create business details",
        level: "error",
      });
      throw new Error(error);
    }
  }
);

export const createAppConfig = createAsyncThunk(
  "auth/createAppConfig",
  async (data) => {
    try {
      const response = await window.api.saveUserDetail(data);
      return response;
    } catch (error) {
      await window.log.logToFile({
        message: "Unable to save user detail",
        level: "error",
      });
      throw new Error(error);
    }
  }
);

export const fetchDeviceId = createAsyncThunk(
  "fetchDeviceId/fetchDevice",
  async () => {
    try {
      const response = await fetch('https://api.ipify.org?format=json');
      const data = await response.json();
      console.log('ipAddresss',data.ip)
      return data.ip; // Returning the IP address
    } catch (error) {
      await window.log.logToFile({
        message: "Unable to fetch IP address",
        level: "error",
      });
      throw new Error(error);
    }
  }
);




export const addResourcesBackUp = createAsyncThunk(
  "auth/addResourcesBackUp",
  async (args) => {
    try {

      const getResources = await window.api.getResources();

      const token = args.token;
      const tenantId = args.tenant_id;
      const terminalNumber = args.user_id;
      if (getResources) {
        const response = await axiosRequest({
          method: "put",
          url: `terminals/update-resources/${terminalNumber}`,
          data: {
            sequence: getResources?.resourceSequences,
            document_sequence: getResources?.documentSequences,
          },
          headers: {
            Authorization: `Bearer ${token}`,
            "X-Tenant": tenantId,
          },
        });
      }
      return getResources;
    } catch (error) {
      await window.log.logToFile({
        message: "Unable to get resource backup",
        level: "error",
      });
      throw new Error(error);
    }
  }
);

export const logOutSyncStatus = createAsyncThunk(
  "auth/logOutSyncStatus",
  async () => {
    try {
      const response = await window.api.getLogOutSyncStatus();
      if (response.success) {
        return response.data;
      }
    } catch (error) {
      throw new Error(error);
    }
  }
);

export const fetchAllTenants = createAsyncThunk(
  "auth/fetchTenant",
  async (id, { rejectWithValue }) => {
    try {
      var config = {
        method: "GET",
        url: environment.URL + `authentication/get-tenants/${id}`,
        headers: {
          "Content-Type": "application/json",
        },
      };
      const response = await axios(config);
      return response.data;
    } catch (error) {
      await window.log.logToFile({
        message: "unable to fetch all tenants",
        level: "error",
      });
      return rejectWithValue(error);
    }
  }
);

export const getResources = createAsyncThunk(
  "auth/getResources",
  async (args) => {
    try {
      const token = args.token;
      const tenantId = args.tenantId;
      const terminalNumber = args.terminalNumber;

      const response = await axiosRequest({
        method: "GET",
        url: `terminals/resources/${terminalNumber}`,
        headers: {
          Authorization: `Bearer ${token}`,
          "X-Tenant": tenantId,
        },
      });
      const resourcesData = {
        resourceSequence: response?.resource_sequence,
        documentSequence: response?.document_sequence,
      };

      if (resourcesData) {
        const response = await window.api.updateResources(resourcesData);
      }

      return response.data;
    } catch (error) {
      throw new Error(error?.response.message);
    }
  }
);

export const logoutInServer = createAsyncThunk(
  "auth/logoutInServer",
  async (data) => {
    try {
      const sendData = data.logoutData;
      sendData.device_type = "desktop";

      await axiosRequest({
        method: "POST",
        url: "auth/logout",
        data: sendData,
        headers: {
          "Content-Type": "application/json",
          "X-Tenant": data.tenant_id,
        },
      });

      return true;
    } catch (error) {
      await window.log.logToFile({
        message: "Error logging out",
        level: "error",
      });
      throw error;
    }
  }
);

export const saveSettingsConfig = createAsyncThunk(
  "auth/saveAppConfig",
  async (data) => {
    try {
      const response = await window.api.saveSettingsConfig(data);
      return response;
    } catch (error) {
      await window.log.logToFile({
        message: "Unable to save user detail",
        level: "error",
      });
      throw new Error(error);
    }
  }
);



const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logout: () => initialState,
    resetAuth: (state, action) => {
      state.isUserRegistered = false;
      state.isOtpVerify = false;
      state.setTenantStatus = false;
        state.businessStatus = false;
        state.createConfigStatus = false;
        state.loading = false;
      state.error = false;
      state.errorMessage = {};
      state.businessSelectedStatus = false;
      state.otpErrorMessage = {};
    },
    resetBackUpStatus: () => {
      // state.isBackupedResources = false;
    },
    setSelectedBusiness: (state, action) => {
      if (action.payload) {
        state.selectedBusiness = action.payload;
        state.businessSelectedStatus = true;
      }
    },
    resetAllStatus(state, action) {
      state.backUpStatus = false;
      state.businessSelectedStatus = false;
      state.businessStatus = false;
      state.createConfigStatus = false;
      state.setTenantStatus = false;
    },
    updateTenants: (state, action) => {
      state.tenants = action.payload;
    },
    setPin: (state, action) => {
      state.pin = action.payload;
    },
    resetConfigStatus(state, action) {
       state.saveSettingsStatus = false;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(register.fulfilled, (state, action) => {
        state.phone = action.payload;
        // (state.isUserRegistered = true), (state.status = true);
        state.otpStatus = "sent";
      })
      .addCase(register.rejected, (state, action) => {
        state.status = false;
        state.error = i18n("Registration failed. Please try again later");
        state.otpStatus = "error";
      })
      .addCase(otpVerify.fulfilled, (state, action) => {
        state.isOtpVerify = true;
        state.accessToken = action.payload;
        state.isLoggedIn = true;
        state.tenants = action.payload?.tenants;
        state.user = action.payload?.user;
        state.otpStatus = "verified";
      })
      .addCase(otpVerify.rejected, (state, action) => {
        state.isLoggedIn = false;
        state.error = true;
        state.otpErrorMessage = i18n(
          "OTP verification failed. Please try again later."
        );
        state.otpStatus = "error";
      })
      .addCase(createBuisnessDetails.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createBuisnessDetails.fulfilled, (state, action) => {
        state.user = action.payload?.user;
        state.tenants = action.payload?.user?.tenants;
        state.isUserRegistered = true;
        state.loading = false;
        state.businessStatus = true;
        state.currentTenant = action.payload?.tenant;
        state.selectedBusiness =
          action.payload?.user?.tenants[
          action.payload?.user?.tenants?.length - 1
          ];
      })
      .addCase(createBuisnessDetails.rejected, (state, action) => {
        state.error = true;
        state.errorMessage = i18n(
          "Could not create business details. Please try again later."
        );
        state.status = false;
        state.loading = false;
      })
      .addCase(setCurrentTenantLocal.fulfilled, (state, action) => {
        state.setTenantStatus = true;
        //state.currentTenant = action.payload
      })
      .addCase(createAppConfig.fulfilled, (state, action) => {
        state.createConfigStatus = true;
      })
      .addCase(fetchDeviceId.fulfilled, (state, action) => {
        state.deviceId = action.payload;
        state.status = true;
      })
      .addCase(fetchDeviceId.rejected, (state, action) => {
        state.error = true;
        state.errorMessage = i18n(
          "Unable to get device ID. Please try again later."
        );
        state.status = false;
      })
      .addCase(addResourcesBackUp.fulfilled, (state, action) => {
        state.backUpStatus = true;
      })
      .addCase(addResourcesBackUp.rejected, (state, action) => {
        state.error = true;
        state.errorMessage = i18n(
          "Unable to get device ID. Please try again later."
        );
        state.status = false;
      })
      .addCase(getResources.fulfilled, (state, action) => {
        state.isBackupedResources = true;
      })
      .addCase(getTerminalNumber.fulfilled, (state, action) => {
        state.terminalNumber = action.payload;
      })
      .addCase(fetchAllTenants.fulfilled, (state, action) => {
        state.tenants = action.payload?.tenants;
      })
      .addCase(fetchAllTenants.rejected, (state, action) => {})
      .addCase(logOutSyncStatus.fulfilled, (state, action) => {
        state.syncCount = action.payload;
      })
      .addCase(saveSettingsConfig.fulfilled, (state, action) => {
        state.saveSettingsStatus = true;
      })

      .addCase(logOutSyncStatus.rejected, (state, action) => {})
      .addCase(logoutInServer.fulfilled, (state, action) => initialState)
      .addCase(logoutInServer.rejected, (state, action) => initialState);
  },
});

export default authSlice.reducer;

export const {
  logout,
  resetAuth,
  setSelectedBusiness,
  updateTenants,
  setPin,
  resetAllStatus,
  resetConfigStatus,
} = authSlice.actions;
