import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";

const initialState = {
  name: "",
  code: "",
  parent_product: null,
  is_subscribable: true,
  is_active: true,
  sub_type: "",
  is_tax_included: 1,
  is_refundable: 0,
  created_by: 1,
  sale_price: "",
  tax_code: "GST_NONE",
  setup_fee: {},
};

const setupFeesEntitySlice = createSlice({
  name: "setupFees",
  initialState,
  reducers: {
    changeSetupFeesName(state, action) {
      state.name = action.payload;
    },
    changeSetupFeesCode(state, action) {
      state.code = action.payload;
    },
    changeSetupFeesType(state, action) {
      state.type = action.payload;
    },
    changeSetupFeesSalePrice(state, action) {
      state.sale_price = action.payload;
    },
    changeSetupFeesIsRefundable(state, action) {
      state.is_refundable = action.payload;
    },
    changeSetupFeessTaxCode(state, action) {
      state.tax_code = action.payload;
    },
    changeIsTaxIncluded(state, action) {
      state.is_tax_included = action.payload;
    },
    initEditSetupFee(state, action) {
      const item = action.payload;
      state.name = item?.name;
      state.code = item?.code;
      state.sale_price = item?.sale_price;
      state.is_refundable = item?.setupFee?.is_refundable;
      state.product_id = item?.setupFee?.product_id;
      state.id = item?.id;
      state.tax_code = item?.tax_code;
      state.is_tax_included = item.is_tax_included;
    },
    resetSetupFee: () => initialState,
  },
  extraReducers(builder) {
  
  },
});

export default setupFeesEntitySlice.reducer;

export const {
  changeSetupFeesName,
  changeSetupFeesCode,
  changeSetupFeesSalePrice,
  changeSetupFeesIsRefundable,
  changeSetupFeessTaxCode,
  resetSetupFee,
  initEditSetupFee,
  changeIsTaxIncluded
} = setupFeesEntitySlice.actions;
