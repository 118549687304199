import React, { useState, useEffect } from "react";
import { Button, Row, Col, Stack, Container ,Image} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Layout from "../../shared/components/Layout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faChevronLeft,
  faDownload,
} from "@fortawesome/free-solid-svg-icons";
import { downloadCsvFile, handleExportClick } from "../../shared/export";
import DataGrid from "../../shared/components/DataGrid";
import { format } from "date-fns";
import { fetchBillWiseReport } from "../store/billWiseReportSlice";
import ReportPeriod from "../../shared/components/ReportPeroid";
import Header from "../../shared/components/Header";
import { i18n } from "../../shared/helpers/sharedMethods";
import TransactionNavbar from "../components/TransactionNavbar";
import { UilAngleLeft, UilImport } from "@iconscout/react-unicons";
import ShortcutKeys from "../../shared/components/ShortcutKeys";
import { formatAmount } from "../../pos/helpers/cartHelper";
import ReportDateRange from "../../shared/components/date_range/ReportDateRange";
import DatePicker from "../../shared/components/date_range/DatePicker";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import CustomeSortIcon from "../../shared/components/CustomeSortIcon";
import { CurrentWeekDateRange } from "../../shared/components/DateRangeYAxis";
import ReportHeader from "../components/ReportHeader";
import { getLocalizedDate } from '../../shared/helpers/localizationHelper';
import LoadingSpinner from '../../shared/components/LoadingSpinner';
import GlobalCurrency from "../../shared/components/GlobalCurrency";
const BillWiseReport = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const accessToken = useSelector((state) => state?.auth?.accessToken?.access_token);
  const selectedBusiness = useSelector((state) => state?.auth?.selectedBusiness);
  const tenantId = selectedBusiness?.pivot?.tenant_id;
  const [reportPeriod, setReportPeriod] = useState("current-week");
  const [reportStatus, setReportStatus] = useState();
  const [csvData, setCsvData] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  console.log("startDate", startDate, endDate);
  
  const [filters, setFilters] = useState(null);
  const [sortField, setSortField] = useState(null);
  const [sortOrder, setSortOrder] = useState(1);
  const handleReset = () => {
    const salesDateLimits = ReportDateRange({
      reportPeriod: reportPeriod,
    });
    setStartDate(salesDateLimits.startDate);
    setEndDate(salesDateLimits.endDate);
    setReportPeriod('current-week')
      ;
  }


  const handleGoBack = () => {
    navigate("/dashboard/sales");
  };
  useEffect(() => {
    dispatch(fetchBillWiseReport({startDate: startDate, endDate: endDate,token:accessToken,tenant_id:tenantId})); 
  }, [startDate, endDate]);

  const reportItems = useSelector((state) => state.billWiseReport.reportItems);
  const status = useSelector((state) => state.billWiseReport.status);
  const loading = useSelector((state) => state.billWiseReport.loading);
  const errorMessage = useSelector(
    (state) => state.billWiseReport.errorMessage
  );
  const currencySymbol = useSelector(
    (state) => state.getSetting?.dataSetting.currency
  );
  ;

  useEffect(() => {
    const dateLimits = ReportDateRange({ reportPeriod });
    setStartDate(dateLimits.startDate);
    setEndDate(dateLimits.endDate);
  }, [reportPeriod]);

  const [totalProfit, setTotalProfit] = useState(0);
  useEffect(() => {
    if (status && reportItems && Array.isArray(reportItems)) {
      const newTotalProfit = reportItems.reduce(
        (sum, item) => sum + item.profit,
        0
      );
      setTotalProfit(newTotalProfit);
    } else {
      setTotalProfit(0);
    }
  }, [status]);
  

  useEffect(() => {
    if (totalProfit < 0) {
      setReportStatus("red");
    } else if (totalProfit > 0) {
      setReportStatus("green");
    } else {
      setReportStatus("black");
    }
  }, [totalProfit]);


  const dateTimeString = new Date()
    .toLocaleString()
    .replace(/[-]/g, " ")
    .replace(/:/g, "-");
  const fileName = `Bill_wise_report_${dateTimeString}`;

  const handleExportButtonClick = () => {
    const exportData =
      reportItems &&
      Array.isArray(reportItems) &&
      reportItems.map((val) => ({
        DATE: val.date ? format(new Date(val.date), "dd-MM-yyyy ") : "-",
        "INVOICE NO.": val.invNo ? val.invNo : "-",
        "PARTY NAME": val.partyName ? val.partyName : "-",
        "INVOICE AMOUNT": val?.InvoiceVal ? val?.InvoiceVal : "0",
        "SALES AMOUNT": val.sale_total ? val.sale_total : "0",
        "PURCHASE AMOUNT": val.cost_total ? val.cost_total : "0",
        PROFIT: val.profit ? val.profit : "0",
        "PROFIT PERCENTAGE (%)": val.profitPercentage
          ? formatAmount(parseFloat(val.profitPercentage))
          : "0",
      }));
    handleExportClick(exportData, setCsvData, downloadCsvFile, fileName);
  };

  const customSort = (field) => {
    setSortField(field);
    setSortOrder(sortOrder === 1 ? -1 : 1);
  };

  const sortedData = [...reportItems].sort((a, b) => {
    if (sortField) {
      const compareValueA = a[sortField];
      const compareValueB = b[sortField];
      if (compareValueA < compareValueB) {
        return sortOrder;
      }
      if (compareValueA > compareValueB) {
        return -sortOrder;
      }
    }
    return 0;
  });
  const headerStyle = {
    backgroundColor: "white",
  };
  const headerCellStyle = {
    fontWeight: "bold",
    fontSize: "11px",
  };
  return (
    <Layout>
     {loading && <LoadingSpinner />}
      <Header
        currentTitle={i18n("Bill Wise Profit")}
        ItemActions={
          <Stack
            gap={2}
            direction="horizontal"
            className="mt-1 mb-1 float-end ms-auto"
          >
            <ShortcutKeys />
          </Stack>
        }
      />
      <Container fluid className="px-5">
        <div className="border " style={{ background: "white" }}>
          <TransactionNavbar />
          <label className="d-flex flex-direction-row p-4 fs-3">
            {i18n("Net Profit")}  {" "}&nbsp;:&nbsp;
            {reportStatus === "red" ? (
              <label className="fw-bolder fs-2" style={{ color: "red" }}>
                {totalProfit < 0 ? "-" : ""}
                <GlobalCurrency price={Math.abs(totalProfit)} />
              </label>
            ) : (
              <label
                className="fw-bolder fs-2"
                style={{ color: reportStatus === "green" ? "green" : "inherit" }}
              >
                <GlobalCurrency price={totalProfit} />
              </label>
            )}
          </label>

          <ReportHeader
            ItemActions={
              <Stack gap={2} direction="horizontal" className="pt-3 px-4 mb-3">
                <Stack direction="horizontal">
                  <DatePicker
                    startDate={startDate}
                    setStartDate={setStartDate}
                    endDate={endDate}
                    setEndDate={setEndDate}
                  />
                </Stack>
                <Stack className=" ">
                  <ReportPeriod
                    reportPeriod={reportPeriod}
                    setReportPeriod={setReportPeriod}
                  />
                </Stack>
                <Stack
                  gap={1}
                  direction="horizontal"
                  style={{ marginLeft: "1px" }}
                >
                  <Button
                    className="rounded-0"
                    variant="outline-danger"
                    onClick={handleReset}
                  >
                    {i18n("Reset")}
                  </Button>
                </Stack>
                <Button
                  className="fw-bolder d-flex align-items-center rounded-0"
                  onClick={handleExportButtonClick}
                >
                  <UilImport
                    size="18"
                    className="mx-2"
                    style={{ cursor: "pointer" }}
                  />
                  {i18n("Export")}
                </Button>
              </Stack>
            }
          />
          <Container fluid className="px-4 mb-4">
            <div className="bordered-container">
              {status && reportItems ? (
                <DataTable
                  value={sortedData}
                  headerStyle={headerStyle}
                  paginator
                  rows={10}
                  dataKey="id"
                  emptyMessage={i18n("No Records Found.")}
                  className="p-datatable-gridlines full-width-table custom-datatable p-component"
                  scrollable
                  scrollHeight="400px"
                  sortMode="multiple"
                  removableSort
                >
                  <Column
                    field="date"
                    header={() => (
                      <div
                        className={`custom-header-${sortField === "date"
                            ? sortOrder === 1
                              ? "up"
                              : "down"
                            : ""
                          }`}
                        onClick={() => customSort("date")}
                        style={{
                          cursor: "pointer",
                          display: "flex",
                          alignItems: "center",
                          fontSize: "11px",
                          lineHeight: "11px",
                        }}
                      >
                        {i18n("Date")}
                        <CustomeSortIcon
                          sortOrder={sortField === "date" ? sortOrder : 0}
                          style={{ marginLeft: "4px", verticalAlign: "middle" }}
                        />
                      </div>
                    )}
                    body={(row) =>
                      row.date ? (
                        <span>{getLocalizedDate(row.date, 'en-IN').split(',')[0]}</span>
                        ) : (
                        "-"
                      )
                    }
                    sortable
                    headerStyle={headerCellStyle}
                  />
                  <Column
                    field="invNo"
                    header={() => (
                      <div
                        className={`custom-header-${sortField === "invNo"
                            ? sortOrder === 1
                              ? "up"
                              : "down"
                            : ""
                          }`}
                        onClick={() => customSort("invNo")}
                        style={{
                          cursor: "pointer",
                          display: "flex",
                          alignItems: "center",
                          fontSize: "11px",
                          lineHeight: "11px",
                        }}
                      >
                        {i18n("Invoice No")}
                        <CustomeSortIcon
                          sortOrder={sortField === "invNo" ? sortOrder : 0}
                          style={{ marginLeft: "4px", verticalAlign: "middle" }}
                        />
                      </div>
                    )}
                    sortable
                    headerStyle={headerCellStyle}
                  />
                  <Column
                    field="partyName"
                    header={() => (
                      <div
                        className={`custom-header-${sortField === "partyName"
                            ? sortOrder === 1
                              ? "up"
                              : "down"
                            : ""
                          }`}
                        onClick={() => customSort("partyName")}
                        style={{
                          cursor: "pointer",
                          display: "flex",
                          alignItems: "center",
                          fontSize: "11px",
                          lineHeight: "11px",
                        }}
                      >
                        {i18n("Party Name")}
                        <CustomeSortIcon
                          sortOrder={sortField === "partyName" ? sortOrder : 0}
                          style={{ marginLeft: "4px", verticalAlign: "middle" }}
                        />
                      </div>
                    )}
                    sortable
                    headerStyle={headerCellStyle}
                  />
                  <Column
                    field="InvoiceVal"
                    header={() => (
                      <div
                        className={`custom-header-${sortField === "InvoiceVal"
                            ? sortOrder === 1
                              ? "up"
                              : "down"
                            : ""
                          }`}
                        onClick={() => customSort("InvoiceVal")}
                        style={{
                          cursor: "pointer",
                          display: "flex",
                          alignItems: "center",
                          fontSize: "11px",
                          lineHeight: "11px",
                        }}
                      >
                        {i18n("INVOICE AMOUNT")}
                        <CustomeSortIcon
                          sortOrder={sortField === "InvoiceVal" ? sortOrder : 0}
                          style={{ marginLeft: "4px", verticalAlign: "middle" }}
                        />
                      </div>
                    )}
                    body={(row) =>
                      row.InvoiceVal ? (
                        <GlobalCurrency price={row.InvoiceVal} />
                      ) : (
                        <GlobalCurrency price={0} />
                    )}                   
                    sortable
                    headerStyle={headerCellStyle}
                  />
                  <Column
                    field="sale_total"
                    header={() => (
                      <div
                        className={`custom-header-${sortField === "sale_total"
                            ? sortOrder === 1
                              ? "up"
                              : "down"
                            : ""
                          }`}
                        onClick={() => customSort("sale_total")}
                        style={{
                          cursor: "pointer",
                          display: "flex",
                          alignItems: "center",
                          fontSize: "11px",
                          lineHeight: "11px",
                        }}
                      >
                        {i18n("SALES AMOUNT")}
                        <CustomeSortIcon
                          sortOrder={sortField === "sale_total" ? sortOrder : 0}
                          style={{ marginLeft: "4px", verticalAlign: "middle" }}
                        />
                      </div>
                    )}
                    body={(row) =>
                      row.sale_total ? (
                        <GlobalCurrency price={row.sale_total} />
                      ) : (
                        "0.00"
                    )}
                    sortable
                    headerStyle={headerCellStyle}
                  />
                  <Column
                    field="cost_total"
                    header={() => (
                      <div
                        className={`custom-header-${sortField === "cost_total"
                            ? sortOrder === 1
                              ? "up"
                              : "down"
                            : ""
                          }`}
                        onClick={() => customSort("cost_total")}
                        style={{
                          cursor: "pointer",
                          display: "flex",
                          alignItems: "center",
                          fontSize: "11px",
                          lineHeight: "11px",
                        }}
                      >
                        {i18n("PURCHASE AMOUNT")}
                        <CustomeSortIcon
                          sortOrder={sortField === "cost_total" ? sortOrder : 0}
                          style={{ marginLeft: "4px", verticalAlign: "middle" }}
                        />
                      </div>
                    )}
                    body={(row) =>
                      row.cost_total ? (
                        <GlobalCurrency price={row.cost_total} />
                      ) : (
                        "0.00"
                    )}
                    sortable
                    headerStyle={headerCellStyle}
                  />
                  <Column
                    field="combinedProfit"
                    header={() => (
                      <div
                        className={`custom-header-${sortField === "combinedProfit"
                            ? sortOrder === 1
                              ? "up"
                              : "down"
                            : ""
                          }`}
                        onClick={() => customSort("combinedProfit")}
                        style={{
                          cursor: "pointer",
                          display: "flex",
                          alignItems: "center",
                          fontSize: "11px",
                          lineHeight: "11px",
                        }}
                      >
                        {i18n("PROFIT")}
                        <CustomeSortIcon
                          sortOrder={
                            sortField === "combinedProfit" ? sortOrder : 0
                          }
                          style={{ marginLeft: "4px", verticalAlign: "middle" }}
                        />
                      </div>
                    )}
                    body={(row) => (
                      <span style={{ color: "green" }}>
                        {row.profit ? (
                          <>
                            {row.profit < 0 ? "-" : ""}
                            <GlobalCurrency price={Math.abs(row.profit)} />
                          </>
                        ) : (
                          "0.00"
                        )}
                        (
                        {row.profitPercentage
                          ? formatAmount(parseFloat(row.profitPercentage))
                          : "0"}
                        %)
                      </span>
                    )}
                    sortable
                    headerStyle={headerCellStyle}
                  />
                </DataTable>
              ) : (
                <p>{i18n("Loading")}</p>
              )}
            </div>
          </Container>
        </div>
      </Container>
    </Layout>
  );
};

export default BillWiseReport;