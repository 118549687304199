import {
    createSlice,
    createAsyncThunk,
  } from "@reduxjs/toolkit";
import { i18n } from "../../../shared/helpers/sharedMethods";

  const initialState = {
    status:false,
    reportItems:[],
    error:false,
    errorMessage:{}
  };

export const fetchTopTenPurchaseItem = createAsyncThunk('topTenPurchaseItem/fetch', async (param) => {
  try{
    const topTenPurchaseItemResponse  = await window.api.topTenPurchaseItemChart(param);
    return topTenPurchaseItemResponse.data;
  }catch(err){
    await window.log.logToFile({message: "Error while fetching Top Ten Purchase items", level: "error" });
    throw new Error("Error while fetching Top Ten Purchase Item")
  }
});

const topTenPurchaseItemSlice = createSlice({
    name: "topTenPurchaseItem",
    initialState,
    reducers: {},
    extraReducers(builder) {
      builder
      .addCase(fetchTopTenPurchaseItem.fulfilled, (state, action) => {
        state.status = true;
        state.reportItems =action.payload;
      })
      .addCase(fetchTopTenPurchaseItem.rejected, (state, action) => {
        state.error = true; 
        state.errorMessage= (i18n("Error while fetching Top Ten Purchase Item. Please try again later."));
        state.status = false;
      })

    },
});

export default topTenPurchaseItemSlice.reducer;
