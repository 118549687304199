import {
  Button,
  Col,
  Modal,
  Row,
  Stack,
  Form,
  InputGroup,
} from "react-bootstrap";
import PaymentTypes from "./PaymentTypes";
import PaymentAmount from "./PaymentAmount";
import {
  addPaymentDate,
  addPaymentNumber,
  addPaymentType,
  createPaymentParty,
  getPaymentNumber,
  resetPayment,
} from "../../../payment/store/paymentEntitySlice";
import {
  addInitPayment,
  processingPayment,
  resetPartyPayment,
  resetSelectedPayment,
  resetStatusError,
  undoPayments,
} from "../../../payment/store/partyPaymentsSlice";
import { addPartyInPayment } from "../../../payment/store/paymentEntitySlice";
import { fetchSalesbyParty } from "../../../payment/store/paymentEntitySlice";
import { selectAllparty } from "../../store/getPartySlice";
import {
  i18n,
  numValidate,
  placeholderText,
} from "../../../shared/helpers/sharedMethods";
import { addPartyPayments } from "../../../payment/store/partyPaymentsSlice";
import { iteratePartyPayment } from "../../../payment/helpers/paymentHelper";
import { useDispatch, useSelector } from "react-redux";
import { getUtcDate } from "../../../shared/helpers/localizationHelper";
import { useEffect, useState } from "react";
import { fetchPartyTransaction } from "../../store/PartyTransactionSlice";

const PartyPaymentModal = (props) => {
  const { show, setShow, type, title, name, partyId } = props;
  const dispatch = useDispatch();
  const { paymentType, paymentDate, paymentNumber } = useSelector(
    (state) => state.paymentEntity
  );
  const selectedBusiness = useSelector(
    (state) => state?.auth?.selectedBusiness
  );
  const accessToken = useSelector(
    (state) => state?.auth?.accessToken?.access_token
  );
  const tenantId = selectedBusiness?.pivot?.tenant_id;
  const { terminalId } = useSelector((state) => state.initInstall);
  const currency = useSelector((state) => state.getSetting?.dataSetting?.currency);
  useEffect(() => {
    if (type == "payment_in") {
      dispatch(getPaymentNumber({ data: "payment_in", token: accessToken, tenant_id: tenantId, terminal_id: terminalId }));
    } else {
      dispatch(getPaymentNumber({ data: "payment_out", token: accessToken, tenant_id: tenantId, terminal_id: terminalId }));
    }
  }, [type, accessToken, tenantId, terminalId, dispatch])
  const { processPayment, copyPayment, checkedItems, statusError } =
    useSelector((state) => state.partyPayments);
  const partyPayments = useSelector((state) => state.partyPayments);
  ;
  const { party, status } = useSelector((state) => state.paymentEntity);

  const [errorMessage, setErrorMessage] = useState("");


  const handleChange = (e) => {

    if (copyPayment.length > 0 && Object.keys(checkedItems).length > 0) {
      dispatch(undoPayments(copyPayment));
    }
    dispatch(addInitPayment(e.target.value));
    dispatch(processingPayment(e.target.value));
    dispatch(resetSelectedPayment());
    dispatch(resetStatusError());
    setErrorMessage("");
  };
  const clearField = () => {
    setShow(false);
    setErrorMessage("");
  };
  const paymentValue = [
    { value: "upi", label: placeholderText("UPI") },
    { value: "card", label: placeholderText("CARD") },
    { value: "cash", label: placeholderText("CASH") },
  ];
  useEffect(() => {
    if (status) {
      setShow(false);
      dispatch(fetchPartyTransaction({id:partyId,token:accessToken,tenant_id:tenantId}));
      dispatch(resetPayment());

      dispatch(resetPartyPayment());
    }
  }, [status]);
  const handlePaymentChange = (value) => {
    dispatch(addPaymentType(value));
    setErrorMessage("");
  };
  const onSubmit = async (event) => {
    // const valid = handleValidation();
    event.preventDefault();

    if (!processPayment || processPayment <= 0) {
      setErrorMessage(i18n("Please enter amount"));
      return;
    }

    try {
      // if (valid) {
      const addPayment = {
        payment_number: paymentNumber,
        type: type,
        amount: partyPayments?.processPayment,
        mode: paymentType,
        selectedVoucher: partyPayments?.selectedPayment,
        created_by: selectedBusiness?.pivot?.user_id,
        updated_by: selectedBusiness?.pivot?.user_id,
        created_at: getUtcDate(),
        updated_at: getUtcDate(),
        party_id: partyId,
      };
      ;
      dispatch(createPaymentParty({
        data: addPayment, token: accessToken,
        tenant_id: tenantId, terminal_id: terminalId
      }));
    } catch (error) {
      ;
    }
  };
  return (
    <Modal
      className="modal-md modal-dialog-centered py-20"
      show={show}
      onHide={clearField}
      style={{ zIndex: 2500 }}
      keyboard={true}
    >
      {" "}
      <div
        className=""
        style={{
          borderRadius: "5px",
          boxShadow: "0 0 500px 500px rgba(0, 0, 0, 0.3)",
        }}
      >
        <Modal.Header className="pt-3" closeButton>
          <Modal.Title>
            {title} {paymentNumber}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ height: "125px" }}>
          {/* <Stack direction="horizontal" gap={5}> */}
          <Row>
            <Col lg={6}>
              <Form.Label  style={{ color: "#838587" }}>
                {i18n("Payment Mode")}
              </Form.Label>

              <Form.Select
                value={paymentType}
                onChange={(e) => {
                  handlePaymentChange(e.target.value);
                }}
                defaultValue=""
              >
                {paymentValue &&
                  paymentValue.length > 0 && [
                    ...paymentValue.map((item, index) => (
                      <option key={index} value={item.value}>
                        {item.label}
                      </option>
                    )),
                  ]}
              </Form.Select>
            </Col>
            <Col lg={6}>
              <Form.Group>
                <Form.Label style={{ color: "#838587" }}>
                  {i18n("Payment Amount")}
                </Form.Label>
              </Form.Group>
              <InputGroup>
                <InputGroup.Text>{currency}</InputGroup.Text>
                <input
                  name="voucher_charges"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  aria-label="Dollar amount (with dot and two decimal places)"
                  onKeyPress={(event) => numValidate(event)}
                  className={`${"form-control"}`}
                  placeholder="0"
                  autoFocus
                  type="text"
                  value={processPayment}
                />
                
              </InputGroup>
              {errorMessage && (
                <span className="text-danger">{errorMessage}</span>
              )}
            </Col>
            </Row>
            {/* <PaymentAmount type={type} /> */}
          {/* </Stack> */}
          <Stack direction="horizontal">{/* <PaymentTypes /> */}</Stack>
        </Modal.Body>
        <Modal.Footer style={{ borderTop: "1px solid lightgray" }}>
          <Row className="py-3">
            <Col className="d-flex justify-content-end">
              <Button variant="primary mx-2" className="" id="submitButton" onClick={onSubmit}>
                {i18n("Submit")}
              </Button>
              <Button variant="outline-secondary mx-2" onClick={clearField}>
                {i18n("Cancel")}
              </Button>
            </Col>
          </Row>
        </Modal.Footer>
      </div>
    </Modal>
  );
};

export default PartyPaymentModal;