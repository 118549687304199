import React, { useEffect, useState } from 'react';
import { Modal, Button, Form, FormControl, FormLabel } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { updatePayment } from '../../../../store/posOrderSlice';
import { defaultPaymentMode } from '../../../../../shared/config/defaults';
import { placeholderText,i18n } from '../../../../../shared/helpers/sharedMethods';

const EditReceivedAmountModal = (props) => {

    const { grand_total, party } = useSelector(state => state.posOrder);
    const { receivedAmountModalShow, toggleReceivedAmountModal } = props;
    const [receivedAmount, setReceivedAmount] = useState('');
    const [changeReturn, setChangeReturn] = useState('');
    const [mode, setMode] = useState(defaultPaymentMode);
    const dispatch = useDispatch();


    useEffect(() => {
        if(receivedAmount > 0){
            setChangeReturn(receivedAmount - grand_total);
        }
    }, [receivedAmount, grand_total])

    const handleReceivedAmountSave = () => {


        if (receivedAmount == '') {
            alert('Please enter amount');
            return;
        }

        let payload = {
            payment_number: Math.floor(Math.random() * 1000),
            type: 'payment_in', //receiving payment
            party_id: party.id,
            amount: receivedAmount,
            mode: mode,
            created_by: null,
            change_return: changeReturn
        }

        dispatch(updatePayment(payload));
        toggleReceivedAmountModal(false);
    }

    return (
        <Modal show={receivedAmountModalShow} onHide={() => { toggleReceivedAmountModal(false) }}>
            <Modal.Header closeButton>
                <Modal.Title>{i18n("Receive Amount")}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form.Group className='calculation__filed-grp mb-2'>
                    <FormLabel>
                    {i18n("Amount to Receive")}
                    </FormLabel>
                    <FormControl type='text' id='tax' name='tax' min='0' step='.01' placeholder={placeholderText("Amount")}
                        disabled
                        value={grand_total}
                        className='rounded-1 pe-8'
                    />

                </Form.Group>
                <Form.Group className='calculation__filed-grp mb-2'>
                    <FormLabel>
                    {i18n("Received")}
                    </FormLabel>
                    <FormControl type='text' id='tax' name='tax' min='0' step='.01' placeholder={placeholderText("Received Amount")}
                        onChange={(e) => { setReceivedAmount(e.target.value) }}
                        value={receivedAmount}
                        className='rounded-1 pe-8'
                        autoFocus
                    />

                </Form.Group>
                <Form.Group className='calculation__filed-grp mb-2'>

                    <Form.Select aria-label="Payment-mode" onChange={(e) => { setMode(e.target.value) }}>
                        <option value="cash">{placeholderText("Cash")}</option>
                        <option value="card">{placeholderText("Card")}</option>
                        <option value="upi">{placeholderText("UPI")}</option>
                    </Form.Select>

                </Form.Group>
                <Form.Group className='calculation__filed-grp mb-2'>
                    <FormLabel>
                    {i18n("Change to return")}
                    </FormLabel>
                    <FormControl type='text' id='tax' name='tax' min='0' step='.01' placeholder={placeholderText("Amount")}
                        disabled
                        value={changeReturn}
                        className='rounded-1 pe-8'
                    />
                </Form.Group>

            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" type='submit' onClick={handleReceivedAmountSave}>
                {i18n("Save Changes")}
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default EditReceivedAmountModal;