import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Form, Row } from "react-bootstrap";
import SubscriptionTaxSelected from "./fields/SubscriptionTaxSelected";
import SubscriptionQuantity from "./fields/SubscriptionQuantity";
import SubscriptionPrice from "./fields/SubscriptionPrice";
import SubscriptionDiscount from "./fields/SubscriptionDiscount";
import SubscriptionCess from "./fields/SubscriptionCess";
import { selectSubscriptionPlanItemById } from "../../../store/subscriptionProductEntitySlice";
import SubscriptionItemLineIndex from "./fields/SubscriptionItemLineIndex";
import SubscriptionItemTypeahead from "./fields/SubscriptionItemTypeahead";

const SubscriptionCartRow = ({
  cessEnabled,
  discountEnabled,

  index,
  serial,
}) => {
  const dispatch = useDispatch();
  const { addonShow } = useSelector((state) => state.createSubscription);

  const cartItem = useSelector((state) =>
    selectSubscriptionPlanItemById(state, index)
  );

  return (
    <>
      <tr className="text-center">
        <SubscriptionItemLineIndex
          index={index}
          serial={serial}
          item={cartItem}
        />
        <td colSpan={3} className="">
          <SubscriptionItemTypeahead index={index} item={cartItem} />
        </td>
        <td>
          <SubscriptionQuantity index={index} item={cartItem} />
        </td>
        <td>
          <SubscriptionPrice index={index} item={cartItem} />
        </td>
        {discountEnabled && (
          <>
            {" "}
            <SubscriptionDiscount
              index={index}
              item={cartItem}
              discountEnabled={discountEnabled}
            />
          </>
        )}
        <td className="">
          <Row className="d-flex  align-items-center">
            <Col lg={7} className="">
              <SubscriptionTaxSelected index={index} item={cartItem} discountEnabled={discountEnabled} />
            </Col>
            <Col
              lg={5}
              className="d-flex justify-content-end "
              style={{ borderLeft: "1px solid lightgray" }}
            >
              <Form.Label className="mb-0 ">
                {cartItem && cartItem.tax_total ? cartItem.tax_total : "0.00"}
              </Form.Label>
            </Col>
          </Row>
        </td>

        {cessEnabled && (
          <>
            <SubscriptionCess
              index={index}
              item={cartItem}
              cessEnabled={cessEnabled}
              discountEnabled={discountEnabled}
            />
          </>
        )}
        <td className="text-end">
          {cartItem && cartItem?.net_total ? cartItem?.net_total : ""}
        </td>
      </tr>
    </>
  );
};

export default SubscriptionCartRow;
