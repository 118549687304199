import {
    createSlice,
    createAsyncThunk,
  } from "@reduxjs/toolkit";
import { i18n } from "../../shared/helpers/sharedMethods";

  const initialState = {
    status:false,
    reportItems:[],
    error:false,
    errorMessage:{}
  };

export const fetchProfitAndLossChart = createAsyncThunk('profitAndLossChart/fetch', async (param) => {
  try{
    const profitAndLossChartResponse  = await window.api.dashboardProfitAndLossChart(param);
    
    return profitAndLossChartResponse?.data;
  }catch(err){
    await window.log.logToFile({message: "Error while fetching Profit and Loss", level: "error" });
    throw new Error("Error while fetching Profit and loss chart")
  }
});

const profitAndLossChartSlice = createSlice({
    name: "profitAndLossChart",
    initialState,
    reducers: {},
    extraReducers(builder) {
      builder
      .addCase(fetchProfitAndLossChart.fulfilled, (state, action) => {
        state.status = true
        state.reportItems =action.payload
      })
      .addCase(fetchProfitAndLossChart.rejected, (state, action) => {
        state.error = true; 
        state.errorMessage=(i18n("Unable to retrieve Profit and Loss chart. Please try again later."));
        state.status = false;
      })

    },
});

export default profitAndLossChartSlice.reducer;
