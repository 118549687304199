import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Layout from "../../shared/components/Layout";
import { fetchProducts, resetProduct } from "../../catalog/store/productsSlice";
import { fetchparty, resetParty } from "../../party/store/getPartySlice";
import { fetchLatestTransaction } from "../store/LatestTransactionSlice";
import {
  fetchSetting,
  getSettingsConfig,
} from "../../settings/store/getSettingSlice";
import { fetchCategories } from "../../settings/store/categorysSlice";
import {
  Row,
  Col,
  Alert,
  Container,
  Stack,
  Button,
  Spinner,
} from "react-bootstrap";
import { partyData } from "../../subscription/store/LivePartyGetsSlice";
import { useLocation, useNavigate } from "react-router";
import Header from "../../shared/components/Header";
import { i18n } from "../../shared/helpers/sharedMethods";
import { aboutDetails, resetSubmenu } from "../../shared/store/sharedSlice";
import { useHotkeys } from "react-hotkeys-hook";
import { resetCreateParty } from "../../party/store/PartyEntitySlice";
import CreateParty from "../../party/screens/CreateParty";
import CreateProduct from "../../catalog/screens/CreateProduct";
import { resetCreateProduct } from "../../catalog/store/productEntitySlice";
import ShortcutKeys from "../../shared/components/ShortcutKeys";
import {
  getResources,
  getTerminalNumber,
  resetAllStatus,
  resetConfigStatus,
  saveSettingsConfig,
  resetAuth,
  fetchDeviceId,
} from "../../auth/store/authSlice";
import { getEnquiry } from "../../enquiry/store/EnquirySlice";
import LatestTransaction from "../components/latestTransaction";
import { resetConfig } from "../../auth/store/configSlice";
import { resetSales } from "../../voucher/store/voucherSlice";
import { resetCartVoucherPayment } from "../../payment/store/paymentSlice";
import DashBoardHeaderTab from "../components/DashboardHeaderTab";
import SalesTab from "../components/SalesTab";
import SubscriptionTab from "../components/SubscriptionTab";
import { fetchPermission } from "../../auth/store/initInstallSlice";
import { fetchFeaturesForTenant } from "../../auth/store/globalPlanSlice";
import { planData } from "../../subscription/store/subscription_item_store/GetPackagesItemSlice";
import DatePicker from "../../shared/components/date_range/DatePicker";
import ReportDateRange from "../../shared/components/date_range/ReportDateRange";
import { CurrentWeekDateRange } from "../../shared/components/DateRangeYAxis";
import ReportPeriod from "../../shared/components/ReportPeroid";
import { fetchOrderAndRevenue } from "../store/OrderAndRevenueSlice";
import { fetchNewCustomer } from "../store/NewCustomerSlice";
import { fetchProfitBlock } from "../store/ProfitBlockSlice";
import { fetchRepeatCustomer } from "../store/RepeatCustomerSlice";
import { fetchSalesSummaryChart } from "../store/SalesSummaryChartSlice";
import { fetchTopSellingProducts } from "../store/TopSellingProductsSlice";
import { fetchProductCategory } from "../store/productCategoryChartSlice";
import { SubscriptionDashboard } from "../store/SubscriptionDashboardSlice";
import SettingsOfflineModel from "../components/SettingsOfflineModel";


const DashboardIndex = ({ type, featureName, startDate, endDate }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const businessSwitch = location?.state?.businessSwitch;
  const selectedBusiness = useSelector(
    (state) => state?.auth?.selectedBusiness
  );
  const isInstalled = useSelector((state) => state.auth?.isInstalled);
  const isMenuCollapse = useSelector((state) => state.sharedSlice.isToggleShow);
  const { deviceId,saveSettingsStatus} = useSelector((state) => state?.auth);
  const accessToken = useSelector(
    (state) => state?.auth?.accessToken?.access_token
  );
  const tenantId = selectedBusiness?.pivot?.tenant_id;
  const userId = selectedBusiness?.pivot?.user_id;
  const isBackupResources = useSelector(
    (state) => state?.auth?.isBackupedResources
  );
  const { permissions } = useSelector((state) => state?.initInstall);
  const { getSettingStatus } = useSelector((state) => state.getSetting);
  const globalPlanFeatures = useSelector(
    (state) => state?.globalPlan?.features
  );
  const setting = useSelector((state) => state?.getSetting?.dataSetting);
  const  permissionStatus  = useSelector((state) => state?.initInstall?.permissionStatus);
  const { featureStatus } = useSelector((state) => state.globalPlan);
  const { settingStatus } = useSelector((state) => state.getSetting);

  const permission = useSelector((state) => state?.getSetting?.permissions);
  const features = useSelector((state) => state.getSetting?.features);
  const [loading, setLoading] = useState(false);
  const [reportPeriod, setReportPeriod] = useState("current-week");
  const [period, setPeriod] = useState("current-week");
  const [show, setShow] = useState(location.state);
  const [showCreate, setShowCreate] = useState(false);
  const [showPartyCreate, setShowPartyCreate] = useState(false);
  const terminalNumber = useSelector((state) => state?.auth?.terminalNumber);
  const [salesStartDate, setSalesStartDate] = useState(null);
  const [salesEndDate, setSalesEndDate] = useState(null);
  const [salesReportPeriod, setSalesReportPeriod] = useState("current-week");
  const [subscriptionStartDate, setSubscriptionStartDate] = useState(null);
  const [subscriptionEndDate, setSubscriptionEndDate] = useState(null);
  const [subscriptionReportPeriod, setSubscriptionReportPeriod] =
    useState("current-week");
  const [emptyModel, setEmptyModel] = useState(false);
  const isOnline = navigator.onLine;
  const [selectedUser, setSelectedUser] = useState();

  useEffect(() => {
    dispatch(getTerminalNumber());
    dispatch(resetConfigStatus())
  }, []);

  // useEffect(async () => {
  //   try {
  //     const appVersion = await window.ele.getVersion();
  //     console.error("appVersion:", appVersion);
  //     const data = { isAbout: false, version: appVersion };
  //     dispatch(aboutDetails(data));
  //   } catch (error) {
  //     console.error("Failed to get app version:", error);
  //   }
  // }, [location]);

  useEffect(() => {
    const salesDateLimits = ReportDateRange({
      reportPeriod: salesReportPeriod,
    });
    setSalesStartDate(salesDateLimits.startDate);
    setSalesEndDate(salesDateLimits.endDate);
  }, [salesReportPeriod]);

  useEffect(() => {
    const subscriptionDateLimits = ReportDateRange({
      reportPeriod: subscriptionReportPeriod,
    });
    setSubscriptionStartDate(subscriptionDateLimits.startDate);
    setSubscriptionEndDate(subscriptionDateLimits.endDate);
  }, [subscriptionReportPeriod]);

  useEffect(() => {
    if (!isBackupResources && terminalNumber) {
      dispatch(
        getResources({
          token: accessToken,
          tenantId: tenantId,
          terminalNumber: terminalNumber,
        })
      );
    }
  }, [terminalNumber, location]);
useEffect(() => {
  dispatch(
    fetchFeaturesForTenant({
      token: accessToken,
      tenantId: tenantId,
    })
  );
}, [accessToken, tenantId, dispatch]);

useEffect(() => {
  dispatch(
    fetchSetting({
      accessToken: accessToken,
      tenant_id: selectedBusiness?.pivot?.tenant_id,
    })
  );
}, [accessToken, selectedBusiness, dispatch]);
useEffect(()=>{
  dispatch(fetchDeviceId());
 // dispatch(getTerminalNumber());
},[])

useEffect(() => {
  dispatch(
    fetchPermission({
      token: accessToken,
      tenant_id: selectedBusiness?.pivot?.tenant_id,
      device_id: deviceId,
      user_id: selectedBusiness?.pivot?.user_id,
    })
  );
}, [accessToken, selectedBusiness, dispatch]);

  
  
  useEffect(() => {
    if(permissionStatus && settingStatus && featureStatus){
      const appConfigData = {
        id: 1,
        settings: setting,
        permissions: JSON.stringify(permissions),
        features: JSON.stringify(globalPlanFeatures),
      }
      dispatch(saveSettingsConfig(appConfigData));
    }
  },[permissionStatus, settingStatus, featureStatus, setting, permissions, globalPlanFeatures])

console.log('globalPlanFeatures',globalPlanFeatures);
  useEffect(() => {
    if (saveSettingsStatus) {
      dispatch(getSettingsConfig()); 
    }
  }, [saveSettingsStatus]);

  useEffect(() => {
    if (!isOnline) {
      if (getSettingStatus) {
      
      if (!permission || !features || !setting) {
        setEmptyModel(true); 
      }
    }
    } else {
      setEmptyModel(false); 
    }
  }, []);

  useEffect(() => {
    dispatch(planData({ token: accessToken, tenant_id: tenantId }));
  }, [location]);

  const handleSalesRefresh = () => {
    setLoading(true);
    setTimeout(() => {
      
    dispatch(fetchOrderAndRevenue({ startDate: salesStartDate, endDate: salesEndDate }));
    dispatch(fetchNewCustomer({ startDate: salesStartDate, endDate: salesEndDate }));
    dispatch(fetchProfitBlock({ startDate: salesStartDate, endDate: salesEndDate }));
    dispatch(fetchRepeatCustomer({ startDate: salesStartDate, endDate: salesEndDate }));
    dispatch(fetchSalesSummaryChart({ startDate: salesStartDate, endDate: salesEndDate}));
    dispatch(fetchTopSellingProducts({ startDate: salesStartDate, endDate: salesEndDate }));
    dispatch(fetchProductCategory({ startDate: salesStartDate, endDate: salesEndDate}));
      setLoading(false);
      const salesDateLimits = ReportDateRange({
        reportPeriod: salesReportPeriod,
      });
      setSalesStartDate(salesDateLimits.startDate);
      setSalesEndDate(salesDateLimits.endDate);
      setSalesReportPeriod("current-week");
    }, 300);
  };

  const handleSubscriptionRefresh = () => {
    setLoading(true);
    setTimeout(() => {
      dispatch(
        SubscriptionDashboard({
          token: accessToken,
          tenantId: tenantId,
          startDate: subscriptionStartDate,
          endDate: subscriptionEndDate,
        })
      );
      setLoading(false);
      const subscriptionDateLimits = ReportDateRange({
        reportPeriod: subscriptionReportPeriod,
      });
      setSubscriptionStartDate(subscriptionDateLimits.startDate);
      setSubscriptionEndDate(subscriptionDateLimits.endDate);
      setSubscriptionReportPeriod("current-week");
    }, 300);
  };

  const handleSalesReset = () => {
    const salesDateLimits = ReportDateRange({
      reportPeriod: salesReportPeriod,
    });
    setSalesStartDate(salesDateLimits.startDate);
    setSalesEndDate(salesDateLimits.endDate);
    
    setSalesReportPeriod("current-week");

  };
  const handleSubscriptionReset = () => {
     const subscriptionDateLimits = ReportDateRange({
       reportPeriod: subscriptionReportPeriod,
     });
     setSubscriptionStartDate(subscriptionDateLimits.startDate);
     setSubscriptionEndDate(subscriptionDateLimits.endDate);
    setSubscriptionReportPeriod("current-week");
  };

  const handleParty = () => {
    dispatch(resetCreateParty());
    setShowPartyCreate(true);
  };

  const handleCreate = () => {
    dispatch(resetCreateProduct());
    setShowCreate(true);
  };

  useHotkeys("alt+y", (event) => {
    event.preventDefault();
    handleParty();
  });

  useHotkeys("alt+m", (event) => {
    event.preventDefault();
    handleParty();
  });

  return (
    <>
      <Layout>
        <Header
          currentTitle={i18n("Dashboard")}
          ItemActions={
            <Stack
              gap={3}
              direction="horizontal"
              className="my-2 justify-content-end"
            >
              <ShortcutKeys />
            </Stack>
          }
        />

        <div className="px-6" style={{ background: "" }}>
          <Row>
            <Col
              className=""
            >
              {emptyModel && (
                <SettingsOfflineModel
                  emptyModel={emptyModel}
                  setEmptyModel={setEmptyModel}
                />
              )}
              <DashBoardHeaderTab reportPeriod={reportPeriod} />
              {type == "sales" && (
                <Col
                  lg={12}
                  className=""
                  style={{
                    height: "calc(100vh - 100px)",
                    overflow: "auto",
                    overflowX: "hidden",
                    // paddingRight:'20px'
                  }}
                >
                  <SalesTab
                    businessSwitch={location}
                    ItemActions={
                      <>
                        {" "}
                        <Stack
                          gap={3}
                          direction="horizontal"
                          className="py-4  float-end"
                        >
                          {!loading && (
                            <Button
                              className="rounded-0"
                              variant="outline-primary"
                              onClick={handleSalesRefresh}
                            >
                              Refresh
                            </Button>
                          )}
                          {loading && <Spinner animation="border" />}
                          <Stack direction="horizontal">
                            <DatePicker
                              startDate={salesStartDate}
                              setStartDate={setSalesStartDate}
                              endDate={salesEndDate}
                              setEndDate={setSalesEndDate}
                            />
                          </Stack>

                          <Stack className=" ">
                            <ReportPeriod
                              reportPeriod={salesReportPeriod}
                              setReportPeriod={setSalesReportPeriod}
                            />
                          </Stack>
                          <Stack gap={1} direction="horizontal">
                            <Button
                              className="rounded-0"
                              variant="outline-danger"
                              onClick={handleSalesReset}
                            >
                              Reset
                            </Button>
                          </Stack>
                        </Stack>
                      </>
                    }
                    reportPeriod={salesReportPeriod}
                    startDate={salesStartDate}
                    endDate={salesEndDate}
                  />
                </Col>
              )}
              {type == "subscription" && (
                <Col
                  lg={12}
                  className="py-2"
                  style={{
                    height: "calc(100vh - 70px)",
                    overflow: "auto",
                    overflowX: "hidden",
                  }}
                >
                  <SubscriptionTab
                    businessSwitch={location}
                    featureName={featureName}
                    ItemActions={
                      <>
                        {" "}
                        <Stack
                          gap={3}
                          direction="horizontal"
                          className="py-2  float-end"
                        >
                          {" "}
                          {!loading && (
                            <Button
                              className="rounded-0"
                              variant="outline-primary"
                              onClick={handleSubscriptionRefresh}
                            >
                              Refresh
                            </Button>
                          )}
                          {loading && <Spinner animation="border" />}
                          <Stack direction="horizontal">
                            <DatePicker
                              startDate={subscriptionStartDate}
                              setStartDate={setSubscriptionStartDate}
                              endDate={subscriptionEndDate}
                              setEndDate={setSubscriptionEndDate}
                            />
                          </Stack>
                          <Stack className=" ">
                            <ReportPeriod
                              reportPeriod={subscriptionReportPeriod}
                              setReportPeriod={setSubscriptionReportPeriod}
                            />
                          </Stack>
                          <Stack gap={1} direction="horizontal">
                            <Button
                              className="rounded-0"
                              variant="outline-danger"
                              onClick={handleSubscriptionReset}
                            >
                              Reset
                            </Button>
                          </Stack>
                        </Stack>
                      </>
                    }
                    reportPeriod={subscriptionReportPeriod}
                    startDate={subscriptionStartDate}
                    endDate={subscriptionEndDate}
                  />
                </Col>
              )}
            </Col>
            <LatestTransaction />
          </Row>
        </div>
        {show == true ? (
          <div className="d-flex col-lg-12 justify-content-center">
            <Alert
              className="align-items-center"
              variant="danger"
              dismissible
              style={{ width: "500px" }}
            >
              {i18n("Please turn on your network connection..!")}
            </Alert>
          </div>
        ) : (
          <></>
        )}

        {/* <hr /> */}
        {/* </Container> */}
      </Layout>
      {showCreate && (
        <CreateProduct
          show={showCreate}
          // handleClose={handleCeateClose}
          setShowCreate={setShowCreate}
        />
      )}

      {showPartyCreate && (
        <CreateParty
          show={showPartyCreate}
          setShowPartyCreate={setShowPartyCreate}
        />
      )}
    </>
  );
};

export default DashboardIndex;
