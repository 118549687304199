import { Col, Form, Row } from "react-bootstrap";

import { taxList } from "../../../../shared/config/taxConfig";
import { useDispatch, useSelector } from "react-redux";
import { filterTaxList } from "../../../../settings/components/helpers/settingHelpers";
import { changesTaxCode } from "../../../store/subscription_item_store/SetupFeesEntitySlice";
import { i18n } from "../../../../shared/helpers/sharedMethods";
import { changeAddonAddonType, changeAddonIntervalUnit } from "../../../store/subscription_item_store/AddonEntitySlice";

const AddonIntervalType = ({ values, handleChange, touched, errors }) => {
  const dispatch = useDispatch();
  // const getSetting = useSelector((state) => state.getSetting?.dataSetting);
  // ;

  // const getTax = JSON.parse(getSetting?.tax);
  // const TaxList = filterTaxList(taxList, getTax);
  return (
    <Row className="d-flex py-4 justify-content-start align-items-center  col-lg-12">
    <Col lg={3} className="">
      <Form.Group>
        <Form.Label>{i18n("Addon Type")} </Form.Label>
        <span className="required" />
      </Form.Group>
    </Col>
    <Col lg={4}>
      <Row>
        <Col lg={6}>
          <Form.Check
            type="radio"
            name="addontype"
            id="inlineRadio1"
            value="one-time"
            label={i18n("One-time")}
            checked={values.addOnType == "one-time"}
            onChange={(e) => {
              handleChange(e);
              dispatch(changeAddonIntervalUnit("week"));
              dispatch(changeAddonAddonType(e.target.value));
            }}
          />
        </Col>
        <Col lg={6}>
          <Form.Check
            type="radio"
            name="addontype"
            id="inlineRadio2"
            value="recurring"
            label={i18n("Recurring")}
            checked={values?.addOnType == "recurring"}
            onChange={(e) => {
              handleChange(e);
              dispatch(changeAddonAddonType(e.target.value));
            }}
          />
        </Col>
      </Row>
    </Col>
  </Row>
  );
};

export default AddonIntervalType;
