import { Button, Col, Form, InputGroup } from "react-bootstrap";
import { Menu, MenuItem, Typeahead } from "react-bootstrap-typeahead";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useRef, useState } from "react";
import { cloneDeep, isEmpty } from "lodash";
import { ErrorMessage } from "formik";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquareXmark } from "@fortawesome/free-solid-svg-icons";
import {
  changeParty,
  toggleEditPartyModal,
} from "../../../../store/CreateSubscriptionSlice";
import { selectPartyByType } from "../../../../store/LivePartyGetsSlice";
import {
  i18n,
  placeholderText,
} from "../../../../../shared/helpers/sharedMethods";
import { useHotkeys } from "react-hotkeys-hook";
import CreateParty from "../../../../../party/screens/CreateParty";
import EditParty from "../../../../../party/screens/EditParty";
import SubscriptionMemberAhead from "./SubscriptionMemberAhead";
import { AddMember, getMemberNumber, MemberAllData, selectAllMemberData } from "../../../../store/MemberSlice";

const SubscriptionPartyTypeAhead = ({ isEdit, errors, setPartyData }) => {
  const dispatch = useDispatch();

  const type = "All";
  const allParties = useSelector((state) => selectPartyByType(state, type));

  const partyData = allParties.filter(element => element.id !== 1);

  const { party } = useSelector((state) => state.createSubscription);
  const showEditPartyModal = useSelector(
    (state) => state?.createSubscription?.partystatus?.showEditPartyModal
  );
  const activeEditPartyTab = useSelector(
    (state) => state?.createSubscription?.partystatus?.activeEditPartyTab
  );

  const typeaheadRef = useRef(null);

  const [partyChanged, setPartyChanged] = useState(false);
  const [selectedItem, setSelectedItem] = useState([]);
  const [showCreatePartyModal, setShowCreatePartyModal] = useState(false);
  const [autoFill, setAutoFill] = useState("");
  const [showPartyBlock, setShowPartyBlock] = useState(false);
  const [isCustomOption, setIsCustomOption] = useState(false);

  const { accessToken, isLoggedIn } = useSelector((state) => state?.auth);
  const selectedBusiness = useSelector((state) => state?.auth?.selectedBusiness);
  const tenantId = selectedBusiness?.pivot?.tenant_id;

  useEffect(() => {
    if (isEdit && party && !isEmpty(party)) {
      setSelectedItem([party]);
    }
  }, [party]);

  useEffect(() => {
    if (selectedItem && partyChanged) {
      if (selectedItem.length > 0) {
        dispatch(changeParty(selectedItem[0]));
        setPartyChanged(false);
      } else {
        dispatch(changeParty({}));
        setPartyChanged(false);
      }
    }
    if (selectedItem.length > 0) {
      setPartyData(selectedItem[0]);
    }
  }, [selectedItem, partyChanged]);
  const handlePartySelect = (selectedParty) => {
    setPartyChanged(true);
    setSelectedItem(selectedParty);
    setPartyData(selectedParty[0]);
  };

  const resetEditPartyActiveTab = () => {
    dispatch(resetEditPartyActiveTab());
  };

  const handleClick = () => {
    setShowPartyBlock(true);
  };

  const setShowEditPartyModal = (status) => {
    dispatch(
      toggleEditPartyModal({
        toggle: status,
        activeEditPartyTab: "general",
      })
    );
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter" && selectedItem.length === 0) {
      setShowCreatePartyModal(true);
      setAutoFill(typeaheadRef.current.getInput().value);
    }
  };

  useHotkeys("shift+y", (event) => {
    event.preventDefault();
    handleClick();
    typeaheadRef.current.focus();
  });
  ;
  const filterByFields = ["name", "phone_number"];

  useEffect(() => {
    if (tenantId && accessToken?.access_token) {
      dispatch(MemberAllData({ token: accessToken.access_token, tenant_id: tenantId, isAdmin: true }));
    }
  }, []);

  useEffect(() => {
    if (accessToken && selectedBusiness?.pivot?.tenant_id) {
      dispatch(
        getMemberNumber({
          token: accessToken.access_token,
          tenant_id: selectedBusiness?.pivot?.tenant_id,
        })
      );
    }
  }, [accessToken, selectedBusiness?.pivot?.tenant_id, dispatch]);
  
  return (
    <>
      {party && showEditPartyModal && (
        <EditParty
          show={showEditPartyModal}
          setShowEditParty={setShowEditPartyModal}
          partyId={party?.id}
          isTriggeredFromVoucher={true}
          voucherQuickEdit={handlePartySelect}
          activeEditPartyTab={activeEditPartyTab}
        />
      )}
      {showCreatePartyModal && (
        <CreateParty
          show={showCreatePartyModal}
          setShowPartyCreate={setShowCreatePartyModal}
          isTriggeredFromVoucher={true}
          voucherQuickCreate={handlePartySelect}
          autoFill={autoFill}
          isSubscriptionLive={true}
        />
      )}
      <Form.Group as={Col} lg={12}>
        <div className="d-flex justify-content-between">
          <div>
            <Form.Label>{i18n("Party")}</Form.Label>
            <span className="required" />
          </div>

          {!isEmpty(party) && (
            <Button
              variant="link float-end pb-0"
              size="sm"
              onClick={() => {
                dispatch(
                  toggleEditPartyModal({
                    toggle: true,
                  })
                );
              }}
            >
              {i18n("Edit")}
            </Button>
          )}
        </div>

        <InputGroup>
          {allParties && (
            <Typeahead
              ref={typeaheadRef}
              allowNew
              renderMenu={(results, menuProps) => (
                <Menu {...menuProps}>
                  {results.map((result, position) => {
                    if (result.customOption) {
                      setIsCustomOption(true);
                    }

                    return (
                      <MenuItem
                        key={position}
                        option={result}
                        position={position}
                      >
                        {result.customOption ? (
                          <div
                            onClick={(e) => {
                              setShowCreatePartyModal(true);
                              setAutoFill(result.name);
                            }}
                          >
                            Add "{result.name}"
                          </div>
                        ) : (
                          <div>
                            {result.name}
                            <div>
                              {result.phone_number ? (
                                <small>
                                  <b>Phone:</b> {result.phone_number}
                                </small>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        )}
                      </MenuItem>
                    );
                  })}
                </Menu>
              )}
              id={"party-typeahead"}
              newSelectionPrefix="Add a new party : "
              options={partyData}
              placeholder={placeholderText("Search by Name / Phone number")}
              onChange={handlePartySelect}
              filterBy={filterByFields}
              selected={selectedItem}
              autoFocus
              labelKey="name"
              isInvalid={errors.party_id}
              disabled={isEdit ? true : false}
              onFocus={handleClick}
              onKeyDown={handleKeyDown}
            />
          )}
          {!isEdit && selectedItem && selectedItem.length > 0 ? (
            <InputGroup.Text
              className="cursor-pointer fs-2"
              style={{ background: "none" }}
              onClick={() => {
                setSelectedItem([]);
                setPartyChanged(true);
              }}
            >
              <FontAwesomeIcon
                icon={faSquareXmark}
                title="Clear Item"
                className="text-danger"
              />
            </InputGroup.Text>
          ) : (
            ""
          )}
        </InputGroup>
        <ErrorMessage
          name="party_id"
          component="span"
          className="text-danger d-block fw-400 fs-small mt-2"
        />
      </Form.Group>
    </>
  );
};

export default SubscriptionPartyTypeAhead;
