import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from '@reduxjs/toolkit';
import axios from 'axios';
import { environment } from '../../shared/config/environment';
import { i18n } from '../../shared/helpers/sharedMethods';

const initialState = {
  status: false,
  error: false,
  dataSetting: {},
  settingData: {},
  permissions: [],
  features: [],
  loading: false,
  getSettingStatus: false,
  settingStatus: false,
  is_thermal_print: '',
};

export const fetchSetting = createAsyncThunk('setting/fetchSetting', async (data) => {
  
  try {
    var config = {
      method: 'GET',
      url:'setting',
      headers: {
        Authorization: `Bearer ${data.accessToken}`,
        "x-tenant": data.tenant_id
      },
    };
    const settingData = axios(config).then((res) => {
      return res.data.data
    })
    
    return settingData;
  } catch (err) {
    await window.log.logToFile({ message: "Error while fetching settings", level: "error" });
    throw err;
  }
});
export const getSettingsConfig = createAsyncThunk(
  "setting/getAppConfig",
  async () => {
    ;
    try {
      const response = await window.api.getSettingsConfig();
      ;
      console.log('settings res',response.data);
      return response.data;
    } catch (error) {
      await window.log.logToFile({
        message: "Unable to save user detail",
        level: "error",
      });
      throw new Error(error);
    }
  }
);

const getSettingSlice = createSlice({
  name: 'getSetting',
  initialState,
  reducers: {
    resetStatus: (state, action) => {
      state.status = false
    },
    resetSetting: () => initialState,
    resetLoading: (state) => {
      state.loading = false;
    },
   
  },
  extraReducers(builder) {
    builder
    .addCase(fetchSetting.pending, (state) => {
      state.loading = true;  
    })
      .addCase(fetchSetting.fulfilled, (state, action) => {
        state.loading = false;  
        state.status = true;
        state.settingStatus = true;
        ;
        if (action.payload.length > 0) {
          const settingValue = action.payload.reduce((acc, item) => {
            acc[item.key] = item.value;
            return acc;
          }, {});
          state.dataSetting = settingValue;

        }
      })

      .addCase(fetchSetting.rejected, (state, action) => {
        state.loading = false;
        state.status = false;
        state.settingStatus = false;
        state.error = `${i18n("Unable to get settings. Please try again later.")}`;
      })
     
      .addCase(getSettingsConfig.fulfilled, (state, action) => {
        state.getSettingStatus = true;
        ;
        if (action.payload.length > 0) {
          state.dataSetting = JSON.parse(action.payload[0].settings);
          state.permissions = JSON.parse(action.payload[0].permissions);
          state.features = JSON.parse(action.payload[0].features);
        }
      })
      .addCase(getSettingsConfig.rejected, (state, action) => {
        state.getSettingStatus = false;
        state.error = i18n("Unable to get settings. Please try again later.");
      })
  }
});
export const { resetStatus, resetSetting ,resetLoading} = getSettingSlice.actions;
export default getSettingSlice.reducer;
