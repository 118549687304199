import { cloneDeep } from "lodash";
import { updateParty } from "../store/PartyEntitySlice";
import { getUtcDate } from "../../shared/helpers/localizationHelper";

export const preprocessorParty = (party)=>{
  console.log("partys get",party)
    
    const iterateParty = party.map((data)=>({
        id: data?.id,
        name: data?.name,
        category: data?.category,
        type: data?.type,
        phone_number: data?.phone_number,
        email: data?.email,
        gstin: data?.gstin,
        pincode: data?.pincode,
        credit_limit: data?.credit_limit,
        created_at: data?.created_at,
        deleted_at: data?.deleted_at,
        sync_status:data?.sync_status,
        syncstamp:data?.syncstamp
   }));
   
   return iterateParty;
   } 
export const filterParty = (partys, types) => {
    console.log("partys",partys)
    return partys && partys
        .filter(party => types.includes(party?.type))
        .map(party => ({
            id: party?.id,
            name: party?.name,
           category: party?.category,
           type: party?.type,
           phone_number: party?.phone_number,
           email: party?.email,
           gstin: party?.gstin,
           pincode: party?.party_addresses ? party?.party_addresses[0]?.pincode : "",
           credit_limit: party?.credit_limit,
           created_at: party?.created_at,
           deleted_at: party?.deleted_at,
           sync_status:party?.sync_status,
           syncstamp: party?.syncstamp
        }))
}

export const filterPartyCategory = (partys, categorys) => {
    return partys && partys
        .filter(party => categorys.includes(party?.category?.name))
        .map(party => ({
            id: party?.id,
            name: party?.name,
           category: party?.category,
           type: party?.type,
           phone_number: party?.phone_number,
           email: party?.email,
           credit_limit: party?.credit_limit,
           created_at: party?.created_at,
           deleted_at: party?.deleted_at,
           sync_status:party?.sync_status,
           syncstamp: party?.syncstamp
        }))
}

export const exportPreProcessor =(data)=>{
    
    const partyData = data.map((sales) => ({
         id: sales.id,
         name: sales.name,
         phone_number: sales.phone_number,
         email: sales.email,
         type: sales.type,
         credit_limit: sales.credit_limit,
         category: sales?.category?.name,
         created_at: sales.created_at,
         gstin: sales.gstin
     }));
     return partyData
 }

 export const preparePartyData = (partyState, selectedBusiness, dispatch, accessToken, tenantId, terminalId) => {

    let formData = cloneDeep(partyState);
  
    let balance = formData.opening_balance < 0 ? formData.opening_balance * -1 : formData.opening_balance;
  
    if (formData.ledger_type === 'toCollect') {
      formData.opening_balance = balance;
    } else {
      formData.opening_balance = -balance;
    }
  
    formData.created_by = selectedBusiness?.pivot?.user_id;
    formData.updated_by = selectedBusiness?.pivot?.user_id;
    formData.created_at = getUtcDate();
    formData.updated_at = getUtcDate();
  
    if (formData.party_address) {
      formData.party_address.forEach((address) => {
        delete address.id;
      });
    }
    delete formData.shippingAddressBackup;
  console.log('tiger',formData.party_address);
  dispatch(updateParty({ data: formData, token: accessToken.access_token, tenant_id: tenantId, terminal_id: terminalId }));
  };
  

 export const prepareNewAddress = (index) => {
    return {
        id: index,
        name: "",
        phone_number: "",
        label: "",
        address: "",
        city: "",
        state: "",
        pincode: "",
        status: false,
    };
 };

 export const preprocessorDeletedParty = (party)=>{
    
    const iterateParty = party.map((data)=>({
        id: data?.id,
        name: data?.name,
        type: data?.type,
        created_at: data?.created_at,
        deleted_at: data?.deleted_at,
   }));
   
   return iterateParty;
   } 
export const filterDeletedParty = (partys, types) => {
    
    return partys && partys
        .filter(party => types.includes(party?.type))
        .map(party => ({
            id: party?.id,
            name: party?.name,
            type: party?.type,
           created_at: party?.created_at,
           deleted_at: party?.deleted_at,
        }))
}