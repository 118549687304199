import { ErrorMessage } from "formik";
import { Button, Form } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { useDispatch, useSelector } from "react-redux";
import { useRef } from "react";
import { i18n, placeholderText } from "../../../../../shared/helpers/sharedMethods";
import { changeStartDate } from "../../../../store/CreateSubscriptionSlice";


const SubscriptionStartDate = ({ errors, setFieldValue, values }) => {
  const dispatch = useDispatch();

  const { start_date } = useSelector((state) => state.createSubscription);

  const datePickerRef = useRef(null);

  const handleTodayClick = () => {
    const today = new Date();
    dispatch(changeStartDate(today));
    if (datePickerRef.current) {
      datePickerRef.current.setOpen(false);
    }
  };
  return (
    <>
      <Form.Group>
        <Form.Label>{i18n("Start Date")}</Form.Label>
        <span className="required" />
      </Form.Group>
      <div className={`form-control ${errors.start_date ? "is-invalid" : ""}`}>
        <DatePicker
          ref={datePickerRef}
          className={` today-option-add  ${
            errors.start_date ? " change-error" : "change-width"
          }`}
          placeholderText={placeholderText("Select Date")}
          dateFormat="dd/MM/yyyy"
          name="start_date"
          selected={start_date}
          minDate={new Date()}
          onChange={(date) => {
            dispatch(changeStartDate(date));
          }}
          openToDate={start_date || new Date()}
        >
          <Button
            variant="link p-0 mt-1"
            onClick={handleTodayClick}
            style={{
              textDecoration: "none",
              position: "relative",
              left: "80px",
            }}
          >
            {i18n("Today")}
          </Button>
        </DatePicker>
      </div>
      <ErrorMessage
        name="start_date"
        component="span"
        className="text-danger d-block fw-400 fs-small mt-2"
      />
    </>
  );
};

export default SubscriptionStartDate;
