import {
  createSlice,
  createAsyncThunk,
} from '@reduxjs/toolkit';
import { i18n } from '../../shared/helpers/sharedMethods';

const initialState = {
  status: false,
  reportItems: [],
  purchaseReportItems: [],
  error: false,
  errorMessage: {}
};

export const fetchSalesOverviewBlocks = createAsyncThunk('salesOverviewBlocks/fetch', async () => {
  try {
    const salesOverviewBlocksResponse = await window.api.salesOverviewBlocks();
    return salesOverviewBlocksResponse.data;
  } catch (err) {
    await window.log.logToFile({ message: "Error while fetching Sales Overview Block", level: "error" });
    throw new Error("Error while fetching sales Overview Blocks ")
  }
});

export const fetchPurchaseOverviewBlocks = createAsyncThunk('purchaseOverviewBlocks/fetch', async () => {
  try {
    const purchaseOverviewBlocksResponse = await window.api.purchaseOverviewBlocks();
    return purchaseOverviewBlocksResponse.data;
  } catch (err) {
    await window.log.logToFile({ message: "Error while fetching Purchase Overview block", level: "error" });
    throw new Error("Error while fetching purchase Overview Blocks ")
  }
});

const salesOverviewBlocksSlice = createSlice({
  name: 'salesOverviewBlocks',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchSalesOverviewBlocks.fulfilled, (state, action) => {
        state.status = true
        state.reportItems = action.payload
      })
      .addCase(fetchSalesOverviewBlocks.rejected, (state, action) => {
        state.error = true;
        state.errorMessage = (i18n("Error while fetching sales Overview Blocks. Please try again later."));
        state.status = false;
      })
      .addCase(fetchPurchaseOverviewBlocks.fulfilled, (state, action) => {
        state.status = true
        state.purchaseReportItems = action.payload
      })
      .addCase(fetchPurchaseOverviewBlocks.rejected, (state, action) => {
        state.error = true;
        state.errorMessage = (i18n("Error while fetching purchase Overview Blocks. Please try again later."));
        state.status = false;
      })
  }
});

export default salesOverviewBlocksSlice.reducer;


