import {
    createSlice,
    createAsyncThunk,
  } from "@reduxjs/toolkit";
import { i18n } from "../../shared/helpers/sharedMethods";
import { axiosRequest } from "../../shared/helpers/axiosHelper";

const initialState = {
  status:false,
  reportItems:[],
  error:false,
  errorMessage:''
};

export const fetchPartyTransaction = createAsyncThunk(
  "partyTransaction/fetch",
  async (param) => {
 
    try{
      const token = param.token;
      const tenantId = param.tenant_id;
      const id = param.id;
        const partyTransaction =  await axiosRequest({
          method: "GET",
          url: `get-party-transaction`,
          params: {
            party_id: id,
          },
          headers: {
            Authorization: `Bearer ${token}`,
            "X-Tenant": tenantId,
          },
        });
        return partyTransaction.data;
    }catch(err){
      await window.log.logToFile({message: "Error while fetching Party Transaction", level: "error" });
      throw new Error("Error while fetching party Transacion report")
    }
  }
);

const partyTransactionSlice = createSlice({
    name: "partyTransaction",
    initialState,
    reducers: {},
    extraReducers(builder) {
      builder
        .addCase(fetchPartyTransaction.fulfilled, (state, action) => {
          state.status = true
          state.reportItems = action.payload;
        })
        .addCase(fetchPartyTransaction.rejected, (state, action) => {
          state.error = true; 
          state.errorMessage=(i18n("Error while fetching party Transaction report. Please try again later"));
          state.status = false;
        })
    },
});

export default partyTransactionSlice.reducer;

