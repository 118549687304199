import React, { useState } from "react";
import { Card, CardBody, Col, Nav, Row, Tab, Tabs } from "react-bootstrap";

const InvoiceTab = ({ tabs, print, pdf }) => {
  ;

  let defaultKey;

  if (tabs[0].key) {
    defaultKey = tabs[0].key;
  } else if (tabs[1].key) {
    defaultKey = tabs[1].key;
  } else if (tabs[2].key) {
    defaultKey = tabs[2].key;
  }

  const [key, setKey] = useState(defaultKey);

  return (
    <Card
      className=""
      style={{
        borderRadius: "5px",
        backgroundColor: "#F2F2F2",
        height: "calc(100vh - 130px)",
        overflow: "auto",
        overflowX: "hidden",
      }}
    >
      <Tab.Container defaultActiveKey={key}>
        <Nav className="d-none">
          {tabs &&
            tabs.map((tabItem, index) => {
              return (
                <Nav.Item>
                  <Nav.Link
                    className="item-nav-link"
                    key={index}
                    eventKey={tabItem.key}
                    onSelect={(k) => setKey(k)}
                  >
                    {tabItem.icon} {tabItem.title}
                  </Nav.Link>
                </Nav.Item>
              );
            })}
        </Nav>
        <Tab.Content className="py-2  justify-content-center ">
          {tabs &&
            tabs.map((tabItem, index) => {
              return (
                // <Row>
                //   <Col md={3}></Col>
                //   <Col md={6}>
                //     {" "}
                //     <Tab.Pane
                //       ref={print ? print : pdf}
                //       key={index}
                //       eventKey={tabItem.key}
                //       onClick={() => tabItem.key}
                //     >
                //       {tabItem.component}
                //     </Tab.Pane>
                //   </Col>
                //   <Col md={6}></Col>
                // </Row>
                <div className="d-flex justify-content-center">
                  <div
                    style={{
                      width: "750px",
                      boxShadow: "0 2px 5px rgba(0,0,0,0.1)",
                    }}
                  >
                    <Tab.Pane
                      ref={print ? print : pdf}
                      key={index}
                      eventKey={tabItem.key}
                      onClick={() => tabItem.key}
                      
                     
                    >
                      {tabItem.component}
                    </Tab.Pane>
                  </div>
                </div>
              );
            })}
        </Tab.Content>
      </Tab.Container>
    </Card>
  );
};

export default InvoiceTab;
