import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { axiosRequest } from "../../shared/helpers/axiosHelper";
import { cloneDeep } from "lodash";
import moment from "moment";
import { i18n } from "../../shared/helpers/sharedMethods";
import {
  calculateTotals,
  formatAmount,
  processCartItem,
  subscriptionProcessCart,
  subscriptionProcessCartItem,
} from "../../pos/helpers/cartHelper";

const initialState = {
  savedEntity: {},
  isEdit: {},
  error: {},
  status: false,
  addonShow: false,
  party_id: "",
  member_id: "",
  product_id: "",
  start_date: "",
  grand_total: "",
  subscription_number: "",
  expires_after: "",
  payment_terms: "",
  created_by: 1,
  subscription_items: [],
  tax_total: 0,
  discount: 0,
  discount_amount: 0,
  cess_total: "",
  roundoff: 0,
  party: {},
  product: {},
  copiedGrandTotal: 0,
  trial_days: "",
  setup_fee: "",
  partystatus: {
    currentId: "",
    isOrdered: false,
    errorStatus: false,
    error: {},
    showEditPartyModal: false,
    activeEditPartyTab: "general",
    convertTypeStatus: false,
  },
  memberstatus: {
    currentId: "",
    isOrdered: false,
    errorStatus: false,
    error: {},
    showEditMemberModal: false,
    activeEditMemberTab: "general",
    convertTypeStatus: false,
  },
  member: {},
};

export const AddSubscription = createAsyncThunk(
  "createSubscription/create",
  async (args) => {
    ;
    try {
      const token = args.token;
      const data = args.data;
      const tenantId = args.tenant_id;
      const terminalNumber = args.terminalNumber;
      const response = await axiosRequest({
        method: "POST",
        url: "subscriptions",
        data: data,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "X-Tenant": tenantId,
          terminal_number: terminalNumber,
        },
      });
      return response.data;
    } catch (error) {
      await window.log.logToFile({
        message: "Error while creating subscription",
        level: "error",
      });
      throw error;
    }
  }
);

export const Editsubscription = createAsyncThunk(
  "createSubscription/edit",
  async (args) => {
    try {
      const token = args.token;
      const data = args.data;
      const id = args.id;
      const tenantId = args.tenant_id;
      const response = await axiosRequest({
        method: "PUT",
        url: `subscriptions/${id}`,
        data: data,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "X-Tenant": tenantId,
        },
      });
      ;
      return response.data;
    } catch (error) {
      await window.log.logToFile({
        message: "Error while updating subscription",
        level: "error",
      });
      throw error;
    }
  }
);

export const getSubscriptionNumber = createAsyncThunk(
  "createSubscription/getSubscriptionNumber",
  async (args) => {
    try {
      const token = args.token;
      const tenantId = args.tenant_id;
      const response = await axiosRequest({
        method: "GET",
        url: `subscriptions/next-subscription-number`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "X-Tenant": tenantId,
        },
      });
      return response.data;
    } catch (error) {
      await window.log.logToFile({
        message: "Error while fetching subscription number",
        level: "error",
      });
      throw error;
    }
  }
);

const createSubscriptionSlice = createSlice({
  name: "createSubscription",
  initialState,
  reducers: {
    updateTotals(state, action) {
      let items = action?.payload;
      ;
      state.subscription_items = items;

     
      let {
        subtotal,
        taxable_value,
        tax_total,
        net_tax,
        cgst_total,
        sgst_total,
        igst_total,
        cess_total,
        total_quantity,
        discount,
        discount_amount,
        net_total,
        grand_total,
      } = calculateTotals(items);

      state.setup_fee = items[0]?.setup_fee;
      state.trial_days = items[0]?.trial_days;
      state.total_quantity = total_quantity;
      state.subtotal = subtotal.toFixed(2);
      state.taxable_value = taxable_value.toFixed(2);
      state.tax_total = tax_total.toFixed(2);
      state.cess_total = cess_total.toFixed(2);
      state.discount = discount; state.discount_amount = discount_amount;
      state.grand_total = formatAmount(grand_total);
    },
    AddNewSubscription(state, action) {
      state.subscription = action.payload;
      state.data = true;
    },
    changeParty(state, action) {
      state.party = action.payload;
      state.party_id = action?.payload?.id;
    },
    changeMember(state, action) {
      state.member = action.payload;
      state.member_id = action?.payload?.id;
    },
    changeParentProduct(state, action) {
      state.product_id = action.payload;
    },
    changeAddonShow(state, action) {
      state.addonShow = action.payload;
    },
    changeSubscriptionNumber(state, action) {
      state.subscription_number = action.payload;
    },
    changeStartDate(state, action) {
      state.start_date = action.payload;
    },
    changeExpiresAfter(state, action) {
      state.expires_after = action.payload;
    },
    changePaymentTerms(state, action) {
      state.payment_terms = action.payload;
    },
    changeNotes(state, action) {
      state.notes = action.payload;
    },
    changePaidAmount(state, action) {
      state.paid_amount = action.payload;
    },
    roundOffGrandTotal(state, action) {
      state.copiedGrandTotal = state.grand_total;
      const roundedAmount = Math.round(state.grand_total);
      state.roundoff = (roundedAmount - state.grand_total).toFixed(2);
      state.grand_total = roundedAmount;
    },
    unroundOffGrandTotal(state, action) {
      state.grand_total = state.copiedGrandTotal;
      state.roundoff = 0;
    },
    initEditSubscriptionProduct(state, action) {
      ;
      const items = action.payload;
      state.id = items.id;
      state.product_id = items.product_id;
      state.party = items.party;
      state.product = state.party_id = items.party_id;
      state.paid_amount = items.paid_amount;
      state.subscription_number = items.subscription_number;
      state.tax_total = items.tax_total;
      state.start_date = items.start_date;
      state.cess_total = items.cess_total;
      state.discount = items.discount;
      state.discount_amount = items.discount_amount;
      state.addonShow = items.subscription_items.length > 1 ? true : false;
      state.expires_after = items.expires_after;
      state.subscription_items = items.subscription_items;
      state.member = items.member;
      state.member_id = items.member_id
    },
    toggleEditPartyModal(state, action) {
      state.partystatus.showEditPartyModal = action.payload.toggle;
      if (action.payload.activeEditPartyTab) {
        state.partystatus.activeEditPartyTab =
          action.payload.activeEditPartyTab;
      }
    },
    toggleEditMemberModal(state, action) {
      state.memberstatus.showEditMemberModal = action.payload.toggle;
      if (action.payload.activeEditMemberTab) {
        state.memberstatus.activeEditMemberTab =
          action.payload.activeEditMemberTab;
      }
    },
    resetCreateSubscription: () => initialState,
  },
  extraReducers(builder) {
    builder

      .addCase(AddSubscription.fulfilled, (state, action) => {
        state.status = true;

        state.savedEntity = action.payload;
      })
      .addCase(AddSubscription.rejected, (state, action) => {
        state.status = false;
        state.error = action.error?.message;
      })
      .addCase(Editsubscription.fulfilled, (state, action) => {
        state.status = true;
      })
      .addCase(Editsubscription.rejected, (state, action) => {
        state.status = false;
        state.error = i18n(
          "Subscription update failed. . Please try again later."
        );
      })
      .addCase(getSubscriptionNumber.fulfilled, (state, action) => {
        state.subscription_number = action.payload;
      })
      .addCase(getSubscriptionNumber.rejected, (state, action) => {
        state.error = i18n(
          "Error while getting subscription number. Please try again later."
        );
      });
  },
});

export default createSubscriptionSlice.reducer;

export const {
  AddNewSubscription,
  resetCreateSubscription,
  changeParty,
  changeParentProduct,
  changeSubscriptionNumber,
  changeStartDate,
  changeExpiresAfter,
  changePaymentTerms,
  changeAddonShow,
  changeNotes,
  updateTotals,
  initEditSubscriptionProduct,
  roundOffGrandTotal,
  unroundOffGrandTotal,
  toggleEditPartyModal,
  changeMember,
  toggleEditMemberModal,
} = createSubscriptionSlice.actions;
