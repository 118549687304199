import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";

const initialState = {
  name: "",
  code: "",
  parent_product: null,
  type: "service",
  sale_price: 0,
  cost_price: 0,
  is_subscribable: true,
  is_active: true,
  sub_type: null,
  is_tax_included: false,
  created_by: 1,
};

const packageEntitySlice = createSlice({
  name: "packageEntity",
  initialState,
  reducers: {
    changePackageName(state, action) {
      state.name = action.payload;
    },
    changePackageCode(state, action) {
      state.code = action.payload;
    },
    changePackageType(state, action) {
      state.type = action.payload;
    },
    changePackageSalePrice(state, action) {
      state.sale_price = action.payload;
    },
    changePackageCostPrice(state, action) {
      state.cost_price = action.payload;
    },
    initEditParentProduct(state, action) {
      ;
      let tempData = action.payload;
      const {
        name,
        code,
        parent_product,
        type,
        sale_price,
        cost_price,
        is_subscribable,
        is_active,
        sub_type,
        is_tax_included,
        created_by,
      } = tempData;

      state.name = name;
      state.code = code;
      state.parent_product = parent_product;
      state.type = type;
      state.sale_price = sale_price;
      state.cost_price = cost_price;
      state.is_subscribable = is_subscribable;
      state.is_active = is_active;
      state.sub_type = sub_type;
      state.is_tax_included = is_tax_included;
      state.created_by = created_by;
    },
    resetParentProduct: () => initialState,
  },
  extraReducers(builder) {
  },
});

export default packageEntitySlice.reducer;

export const {
  changePackageName,
  changePackageCode,
  changePackageSalePrice,
  changePackageType,
  changePackageCostPrice,
  initEditParentProduct,
  resetParentProduct,
} = packageEntitySlice.actions;
