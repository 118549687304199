import React, { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import {
  format,
} from "date-fns";
import {
  CurrentMonthDates,
  LastMonthDates,
  Weekdays,
} from "../../../../shared/components/DateRangeXAxis";
import { CurrentMonthDateRange, CurrentWeekDateRange, PreviousMonthDateRange, PreviousWeekDateRange } from "../../../../shared/components/DateRangeYAxis";
import { placeholderText } from "../../../../shared/helpers/sharedMethods";


const Chart = (props) => {
  const { reportItems, reportPeriod, setDayFrom, setDayTo } = props;
  const [dateRangeX, setDateRangeX] = useState([]);
  const [salesAmount, setSalesAmount] = useState([]);
  const weekDays = {
    's': placeholderText('Sun'),
    'm': placeholderText('Mon'),
    't': placeholderText('Tue'),
    'w': placeholderText('Wed'),
    'th': placeholderText('Thu'),
    'f': placeholderText('Fri'),
    'st': placeholderText('Sat')
  };
  
  const textTitle = placeholderText("Total Tax")

  useEffect(() => {
    let itemDatas;

    switch (reportPeriod) {
      case "current-month":
        itemDatas = CurrentMonthDates();
        break;

      case "previous-month":
        itemDatas = LastMonthDates();
        break;

      case "current-week":
      case "previous-week":
        itemDatas = Weekdays(weekDays);
        break;

      default:
        itemDatas = [];
        break;
    }

    setDateRangeX(itemDatas);
  }, [reportPeriod]);

  useEffect(() => {

    let start, end;

    switch (reportPeriod) {
      case "current-week":
        ({ start, end } = CurrentWeekDateRange());
        break;

      case "current-month":
        ({ start, end } = CurrentMonthDateRange());
        break;

      case "previous-week":
        ({ start, end } = PreviousWeekDateRange());
        break;
      case "previous-month":
        ({ start, end } = PreviousMonthDateRange());
        break;

      default:
        start = new Date();
        end = new Date();
        break;
    }

    setDayFrom(format(start, "dd MMM yyyy"));
    setDayTo(format(end, "dd MMM yyyy"));

    const dates = [];
    const currentDate = new Date(start);

    while (currentDate <= end) {
      let formattedDate;
      if (reportPeriod === "current-week" || reportPeriod === "previous-week") {
        formattedDate = currentDate.toISOString().split("T")[0];
      } else {
        formattedDate = format(currentDate, "yyyy-MM-dd");
      }
      dates.push(formattedDate);
      currentDate.setDate(currentDate.getDate() + 1);
    }

    const graphAmountData = Array(dates.length).fill(0);
    if (reportItems && reportItems) {
      reportItems.forEach((dataItem) => {
        const voucherDate = dataItem.group_day;
        const totalTax = dataItem.taxTotal;

        const dateIndex = dates.findIndex(
          (date) => date === voucherDate
        );
        if (dateIndex !== -1) {
          graphAmountData[dateIndex] = totalTax;
        }
      });
    }
    setSalesAmount(graphAmountData);
  }, [reportItems, reportPeriod]);

  const [state, setState] = useState([]);
  const [option, setOption] = useState({});
  useEffect(() => {

    setState([
      {
        name: "Tax Amount ",
        data: salesAmount,
      },
    ]);
  }, [salesAmount]);
  useEffect(() => {
    setOption({
      chart: {
        type: "bar",
        height: 350,
        toolbar: {
          show: false,
        },
      },
      colors: ["#88B2CC"],
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "75%",
          endingShape: "rounded",
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
        colors: "#25b97b",
      },
      xaxis: {
        categories: dateRangeX,
        labels: {
          show: true, // Set to true to show the labels
          rotate: 0, // You can adjust the rotation angle of the labels
          trim: false, // Trim the labels if they overflow
          minHeight: undefined, // Minimum height for labels
          maxHeight: 120, // Maximum height for labels
          style: {
            fontSize: "12px", // Set the font size for labels
          },
        },
      },
      fill: {
        opacity: 1,
      },
      title: {
        text: textTitle,
        style: {
          color: "#444",
        },
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return  val ;
          },
        },
      },
    });
  }, [dateRangeX]);

  return (
    <>
      <ReactApexChart options={option} series={state} type="bar" height={350} />
    </>
  );
};

export default Chart;
