import React, { useState } from 'react';
import {  Stack, Button } from 'react-bootstrap';
import EditAddlCharges from '../modals/EditAddlCharges';
import { i18n } from '../../../../../shared/helpers/sharedMethods';
const SummaryTopActions = () => {

    const [addlChargeModalShow, setAddlChargeModalShow] = useState(false);

    const toggleAddlChargeModal = (visibility) => {
        
        setAddlChargeModalShow(visibility)
    };
    return (
        <>
        <Stack gap={3} direction="horizontal" className="mt-5 mb-5">
            <Button variant="custom ms-auto" size='sm' onClick={() => { toggleAddlChargeModal(true) }} >{i18n("Add Additional Charges")}&nbsp;&nbsp;&nbsp;&nbsp; <span className='shortcut-color'>{i18n("[F3]")}</span></Button>
        </Stack>
    
        <EditAddlCharges addlChargeModalShow={addlChargeModalShow} toggleAddlChargeModal={toggleAddlChargeModal}  />
        </>
    )
}

export default SummaryTopActions;