import {
  createSlice,
  createAsyncThunk,
} from "@reduxjs/toolkit";


const initialState = {
  status: false,
  reportItems: [],
  error: false,
  errorMessage: '',
  loading:false,
};

export const fetchStockDetailReport = createAsyncThunk(
  "stockDetailReport/fetch",
  async (param) => {
    try {
      const stockDetailReportResponse = await window.api.getStockDetailReport(param);
      return stockDetailReportResponse.data;
    } catch (err) {
      await window.log.logToFile({ message: "Error while fetching stock detail", level: "error" });
      throw new Error("Error while fetching Stock Detail report")
    }
  }
);

const stockDetailReportSlice = createSlice({
  name: "stockDetailReport",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
    .addCase(fetchStockDetailReport.pending, (state) => {
      state.loading = true;

    })
      .addCase(fetchStockDetailReport.fulfilled, (state, action) => {
        state.status = true;
        state.loading = false;
        state.reportItems = action.payload;
      })
      .addCase(fetchStockDetailReport.rejected, (state, action) => {
        state.error = true;
        state.loading = false;
        state.errorMessage = action.error.message
        state.status = false;
      })
  },
});

export default stockDetailReportSlice.reducer;
