import { Card, Col, Form } from "react-bootstrap";
import { i18n } from "../../shared/helpers/sharedMethods";

const NotesBlock = () => {
  return (
    <Card>
      <Col lg={11} className="mt-3">
        <Form.Group
          className="p-2"
          style={{
            backgroundColor: "#f8f9fc",
            fontSize: "13px",
          }}
        >
          <Col className="p-2">
            <span style={{ fontWeight: "bold" }}>{i18n("Note")} : </span>
            {i18n(
              "The below values will be shown on your invoices"
            )}
          </Col>
        </Form.Group>
      </Col>
    </Card>
  );
};

export default NotesBlock;
