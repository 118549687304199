import {
    createSlice,
    createAsyncThunk,
  } from "@reduxjs/toolkit";
import { i18n } from "../../shared/helpers/sharedMethods";
import { axiosRequest } from "../../shared/helpers/axiosHelper";

const initialState = {
  status:false,
  reportItems:[],
  error:false,
  errorMessage:'',
  loading:false,
};

export const fetchItemReportByParty = createAsyncThunk(
    "ItemReportByParty/fetch",
    async ({ token, tenant_id, startDate, endDate, partyData, categoryData }) => {
      try{
      const itemReportByPartyResponse = await axiosRequest({
        method: "GET",
        url: `get-item-report-by-party-report`,
        params: { startDate, endDate, partyData, categoryData  },
        headers: { Authorization: `Bearer ${token}`, "X-Tenant": tenant_id },
      });
      return itemReportByPartyResponse.data;
    }catch(err){
      await window.log.logToFile({message: "Error while fetching Item report by Party", level: "error" });
      throw new Error("Error while fetching Item report by Party")
    }
    }
  );

const itemReportByPartySlice = createSlice({
    name: "itemReportByParty",
    initialState,
    reducers: {},
    extraReducers(builder) {
      builder
      .addCase(fetchItemReportByParty.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchItemReportByParty.fulfilled, (state, action) => {
        state.status = true;
        state.loading = false;
        state.reportItems = action.payload;
      })
      .addCase(fetchItemReportByParty.rejected, (state, action) => {
        state.error = true;
        state.loading = false; 
        state.errorMessage= (i18n("Error while fetching Item report by Party. Please try again later."));
        state.status = false;
      })
    },
});

export default itemReportByPartySlice.reducer;

