import { Button, Col, Form, Modal, } from "react-bootstrap";
import { i18n } from "../../shared/helpers/sharedMethods";
import { useDispatch, useSelector } from "react-redux";
import DataGrid from "../../shared/components/DataGrid";
import { format } from "date-fns";
import DataTable from "react-data-table-component";
import { useEffect, useState } from "react";
import {
  resetOrder,
  undoDelStatus,
  undoStatus,
  updateVoucherPayment,
} from "../store/voucherEntitySlice";
import {
  UilEdit,
  UilTrashAlt,
  UilExport,
  UilImport,
  UilCheck,
} from "@iconscout/react-unicons";
import { BsUiChecks } from "react-icons/bs";
import { toast, ToastContainer } from "react-toastify";
import LoadingSpinner from "../../shared/components/LoadingSpinner";
import { createPayment, deleteVoucherPartialPayment, resetPartialStatus, selectSalesById, updateReceivedAmount, updateVoucherPartialPayment } from "../store/voucherSlice";
import { current } from "@reduxjs/toolkit";
import { ro } from "date-fns/locale";
import { cloneDeep } from "lodash";
import { deletePayment, resetDeleteStatus } from "../../payment/store/paymentSlice";


const ReceivedPayment = (props) => {
  const {
    voucherPayment,
    updatePaidAmountStatus,
    deletePaidAmountStatus,
    totalReceivedAmount,
  } = useSelector((state) => state.voucherEntity);
  const dispatch = useDispatch();
  const { saleId } = props;
  const selectedBusiness = useSelector(
    (state) => state?.auth?.selectedBusiness
  );
  const accessToken = useSelector(
    (state) => state?.auth?.accessToken?.access_token
  );
  const tenantId = selectedBusiness?.pivot?.tenant_id;
  const { terminalId } = useSelector((state) => state.initInstall);
  const selectedSale = useSelector((state) => selectSalesById(state, saleId));
  console.log('voucherPayment', selectedSale);
  const grand_total = selectedSale?.grand_total;
  const paymentData = [];


  const rearrangedVoucherPayments = selectedSale.voucher_payments.map(payment => {

    const { amount } = payment?.payment;
    return {
      ...payment,
      id: payment?.id,
      amount,
      payment: {
        ...payment?.payment,
        party_id: selectedSale?.party_id
      }
    };
  });

  console.log("rearrangedVoucherPayments", rearrangedVoucherPayments)

  if (selectedSale?.voucher_payments.length > 0
  ) {
    selectedSale?.voucher_payments?.forEach((data) => {
      paymentData.push(data?.payment);
    });
  }

  console.log('paymentData', paymentData);

  const balanceAmount = isNaN(grand_total - totalReceivedAmount)
    ? 0
    : parseFloat(grand_total - totalReceivedAmount);
  const [voucherAmount, setVoucherAmount] = useState(paymentData);
  const [submitId, setSubmitId] = useState(0);
  const [sumOfValue, setSumOfValue] = useState(0);
  const [loading, setLoading] = useState(false);
  const [sumDelOfValue, setDelSumOfValue] = useState(0);
  const [deleteId, setDeleteId] = useState();
  const { invoicePartialPayment } = useSelector((state) => state.voucher);
  const { deleteStatus } = useSelector((state) => state.payment);
  //console.log("deleteStatus", deleteStatus)


  useEffect(() => {
    if (deleteStatus) {
      // console.log("selectedSale payment", selectedSale)
      // console.log("selectedSale payment", deleteId)
      const updatedData = selectedSale?.voucher_payments.filter(item => item.payment_id !== deleteId);

      let paymentData = cloneDeep(selectedSale);

      delete paymentData.voucher_payments;

      paymentData.voucher_payments = updatedData

      paymentData.paid_amount = updatedData.length > 0 ? updatedData.reduce((sum, item) => sum + item.payment.amount, 0) : 0;

      //console.log("selectedSale payment", paymentData);

      dispatch(updateVoucherPartialPayment({ data: paymentData, token: accessToken, tenant_id: tenantId, terminal_id: terminalId }));
      dispatch(resetDeleteStatus())
    }
  }, [deleteStatus])

  useEffect(() => {
    if (invoicePartialPayment) {
      // setShow(false)
      dispatch(resetPartialStatus());
    }
  }, [invoicePartialPayment]);

  const handleEdit = (row) => {
    setSubmitId(row.id);
  };

  useEffect(() => {
    if (paymentData) {
      setVoucherAmount(paymentData);
    }
  }, [voucherPayment]);

  // useEffect(() => {
  //   if (selectedSale && voucherPayment) {
  //     setVoucherAmount(voucherPayment);
  //   }
  // }, [selectedSale, voucherPayment]); 

  useEffect(() => {
    if (voucherAmount) {
      const sum = voucherAmount.reduce((partialSum, a) => partialSum + a.amount, 0);

      setSumOfValue(sum)
    }
  }, [voucherAmount])
  const handlePaidAmout = (() => {
    dispatch(updateReceivedAmount({
      id: selectedSale.id,
      paid_total: sumOfValue
    }));
  });
  const handlePaidAmoutDelete = (() => {
    dispatch(updateReceivedAmount({
      id: selectedSale.id,
      paid_total: sumDelOfValue
    }));
  });

  useEffect(() => {
    if (updatePaidAmountStatus) {
      setSubmitId(0);
      dispatch(undoStatus());
    }
  }, [updatePaidAmountStatus]);

  useEffect(() => {
    if (deletePaidAmountStatus) {
      // setSubmitId(0)
      dispatch(undoDelStatus());
    }
  }, [deletePaidAmountStatus]);

  const handleDelete = (row) => {
    setLoading(true);
    console.log("Dispatching updateVoucherPartialPayment:", row);
    setDeleteId(row?.id)
    dispatch(deletePayment({
      data: { id: row?.id },
      token: accessToken,
      tenant_id: tenantId,
      terminal_id: terminalId
    }));
    const updatedSum = sumOfValue - row.amount;
    console.log(updatedSum, "update");

    // setDelSumOfValue(updatedSum);
    // handlePaidAmoutDelete()
    setTimeout(() => {
      setLoading(false);
    }, 100);
  };

  const [updatePayment, setUpdatePayment] = useState([])
  console.log("updatePayment", updatePayment)

  const handleAmountChange = (e, id) => {
    console.log("id", id)
    const { value } = e.target;
    const parsedValue = parseInt(value);

    setVoucherAmount((data) =>
      data.map((amount) =>
        amount.id === id
          ? { ...amount, amount: parsedValue }
          : amount
      )
    );

    const updatedVoucherPayments = rearrangedVoucherPayments.map((item) => {
      if (item?.payment?.id === id) {
        return {
          ...item,
          //  id: id,
          amount: parsedValue,
          payment: {
            ...item.payment,
            amount: parsedValue,
          },
        };
      }
      return item;
    });

    setUpdatePayment(updatedVoucherPayments)

    console.log("updatedVoucherPayments", updatedVoucherPayments)
  };

  const handleSubmit = (row, index) => {
    setLoading(true);
    const currentAmount = voucherAmount[index]?.amount || 0;

    let paymentData = cloneDeep(selectedSale);

    if (isNaN(currentAmount) || currentAmount <= 0) {
      toast.error('Please enter a valid amount greater than zero');
      setLoading(false);
      return;
    }

    // Calculate the new sum of values
    const newSumOfValue = sumOfValue - (voucherAmount[index]?.amount || 0) + currentAmount;

    if (newSumOfValue > grand_total) {
      toast.error('Total amount cannot exceed grand total');
      setLoading(false);
      return;
    }

    delete paymentData.voucher_payments;
    delete paymentData.amount;

    paymentData?.voucher_items?.forEach((item) => {
      item.id = item.product_id;
    });
    paymentData.voucher_payments = updatePayment;
    paymentData.paid_amount = updatePayment.reduce((sum, item) => sum + item.amount, 0);

    console.log("payment data fetch", paymentData)
    dispatch(updateVoucherPartialPayment({ data: paymentData, token: accessToken, tenant_id: tenantId, terminal_id: terminalId }));
    // dispatch(
    //   updateReceivedAmount({
    //     id: selectedSale.id,
    //     paid_total: voucherAmount[index]?.amount,
    //   })
    // );
    // handlePaidAmout();
    setSubmitId(0);
    setTimeout(() => {
      setLoading(false);
    }, 100);
  };


  const handleKeyDown = (event, row, index) => {
    if (event.key === "Enter") {

      if (row.id == submitId) {
        handleSubmit(row, index);
      }
      // setShowCreatePartyModal(true);
      // setAutoFill(typeaheadRef.current.getInput().value);
    }
  };
  const columns = [
    {
      id: "payment_date",
      name: i18n("DATE"),
      selector: (row) => row.created_at ? format(new Date(row.created_at), "dd-MM-yyyy", { locale: ro }) : '',
      sortable: true,
    },
    {
      id: "payment_number",
      name: "Payment#",
      width: "120px",
      sortable: true,
      selector: (row) => row?.payment_number,
    },
    {
      id: "amount",
      name: "Amount",
      width: "120px",
      sortable: true,
      selector: (row, index) => {
        return (
          <Form.Control
            size="sm"
            type="number"
            value={voucherAmount[index]?.amount}
            readOnly={submitId === row.id ? false : true}
            onChange={(e) => {
              handleAmountChange(e, row.id);
            }}
            onKeyDown={(event) => {
              handleKeyDown(event, row, index);
            }}
          />
        );
      },
    },
    {
      id: "actions",
      name: i18n("Actions"),
      cell: (row, index) => {
        return (
          <div className="d-flex justify-content-evenly align-items-center gap-4">
            {submitId === row.id ? (
              <UilCheck
                size={"20px"}
                onClick={() => handleSubmit(row, index)}
              />
            ) : (
              <UilEdit size={"20px"} onClick={() => handleEdit(row)} />
            )}
            <UilTrashAlt size={"20px"} onClick={() => handleDelete(row)} />

            {/* <i
                        className="fa fa-trash mx-2"
                        aria-hidden="true"
                        style={{ cursor: "pointer" }}
                        onClick={() => handleDelete(row)}
                    ></i> */}
          </div>
        );
      },
    },
  ];
  return (
    <>
      <div className="col-md-12 mb-5">
        {loading && <LoadingSpinner />}
        <DataTable
          data={paymentData}
          columns={columns}
          // pagination={true}
          // paginationPerPage={50}
          paginationRowsPerPageOptions={[50, 100, 150, 200, 250]}
          paginationTotalRows={paymentData && paymentData.length}
          //onRowClicked={handleRowClick}
          responsive
          highlightOnHover
          pointerOnHover
          handleSearch
        // defaultSortAsc={defaultSortAsc}
        // defaultSortFieldId={defaultSortField}
        />
      </div>
    </>
  );
};
export default ReceivedPayment;
