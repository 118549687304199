import { i18n } from "../../../shared/helpers/sharedMethods";
import { ErrorMessage } from "formik";
import { Button, Card, Col, Form, InputGroup, Row } from "react-bootstrap";
import { placeholderText } from "../../../shared/helpers/sharedMethods";
import { useDispatch, useSelector } from "react-redux";
import {
  changeCategory,
  changeName,
  changeType,
} from "../../store/productEntitySlice";
import Select from "react-select";

const ProductDetailsForm = (props) => {
  const {
    touched,
    handleChange,
    values,
    filterData,
    errors,
    handleCategory,
    handleBlur,
  } = props;
  const Type = useSelector((state) => state.productEntity.product.type);
  const dispatch = useDispatch();

  const options = filterData.map((survey) => ({
    value: survey.id,
    label: survey.name,
  }));

  const handleSelectChange = (selectedOption) => {
    const event = {
      target: {
        name: "category_id",
        value: selectedOption ? selectedOption.value : "",
      },
    };
    handleChange(event);
    dispatch(changeCategory(event.target.value));
  };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      width: "280px",
    }),
  };

  return (
    <Card className="px-2">
      <Row className="py-2">
        <Col lg={6}>
          <Form.Group className="">
            <Form.Label>{i18n("Product Type")} </Form.Label>
            <span className="required" />
          </Form.Group>
          <Row className="px-3 py-1">
            <Col lg={5}>
              <Form.Check
                type="radio"
                label={i18n("Goods")}
                id="inlineRadio1"
                name="type"
                value="goods"
                checked={Type == "goods"}
                onChange={(e) => {
                  handleChange(e);
                  dispatch(changeType(e.target.value));
                }}
              />
            </Col>
            <Col lg={5}>
              <Form.Check
                type="radio"
                label={i18n("Service")}
                id="inlineRadio2"
                name="type"
                value="service"
                checked={Type == "service"}
                onChange={(e) => {
                  handleChange(e);
                  dispatch(changeType(e.target.value));
                }}
                onBlur={handleBlur}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Form.Control.Feedback
        type="invalid"
        className="d-block fw-400 fs-small mt-2"
      >
        <ErrorMessage name="product_type" />
      </Form.Control.Feedback>

      <Row className="py-2">
        <Col lg={6} md={5} sm={12} className="mb-2">
          <Form.Label className="form-label">
            {i18n("Product Name")} <span style={{ color: "#EE5B50" }}>*</span>
          </Form.Label>

          <Form.Control
            type="text"
            name="name"
            value={values.name}
            placeholder={placeholderText("Enter Product Name")}
            autoFocus={true}
            isInvalid={touched.name && errors.name}
            onChange={(e) => {
              handleChange(e);
              dispatch(changeName(e.target.value));
            }}
            onBlur={handleBlur}
          />
          <Form.Text className="text-danger d-block fw-400 fs-small mt-2">
            {touched.name && errors.name}
          </Form.Text>
        </Col>
        <Col md={6}>
          <Form.Label className="form-label-item mb-2">
            {i18n("Product Category")}
          </Form.Label>
          <InputGroup>
            <Select
              aria-label="category"
              name="category_id"
              value={options.find(
                (option) => option.value === values.category_id
              )}
              onChange={handleSelectChange}
              options={options}
              placeholder="None"
              isClearable
              styles={customStyles}
            />
            <Button
              variant="outline-primary"
              id="button-addon1"
              onClick={handleCategory}
            >
              {i18n("Add")}
            </Button>
          </InputGroup>
        </Col>
      </Row>
    </Card>
  );
};
export default ProductDetailsForm;
