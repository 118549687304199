import React, { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import { i18n,placeholderText } from "../../../../shared/helpers/sharedMethods";


const Chart = (props) => {
  const { labelName, salesAmount,productFilter} = props;

  const [state, setState] = useState([]);
  const [option, setOption] = useState({});

  const titleText = placeholderText("Top 10 Product (Sales Amount)");
  useEffect(() => {
    setState([
      {
        name: productFilter==='amount'?'Product Amount':'Product Quantity',
        data: salesAmount,
      },
    ]);
  }, [salesAmount]);
  useEffect(() => {
    setOption({
      chart: {
        type: "bar",
        height: 300,
        toolbar: {
          show: false,
        },
      },
      colors: ["#B7CFDC"],
      plotOptions: {
        bar: {
          horizontal: true,
        //  columnWidth: "30%",
          borderRadius: 4,
          // endingShape: "rounded",
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
        colors: "#25b97b",
      },
      xaxis: {
        categories: labelName,
        labels: {
          show: true, // Set to true to show the labels
          rotate: 0, // You can adjust the rotation angle of the labels
          trim: false, // Trim the labels if they overflow
          minHeight: undefined, // Minimum height for labels
          maxHeight: 120, // Maximum height for labels
          style: {
            fontSize: "12px", // Set the font size for labels
          },
        },
      },
      yaxis: {
        title: {
          // text: '$ (thousands)'
        },
      },
      fill: {
        opacity: 1,
      },
      title: {
        text: titleText,
        style: {
          color: "#444",
          fontWeight : 400
        },
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return productFilter === 'amount' ? '₹ ' + val : val;
          },
        },
      },
    });
  }, [labelName]);

  return (
    <>
      <ReactApexChart 
      options={option} 
      series={state} 
      type="bar" 
      height={300} 
      />
    </>
  );
};

export default Chart;
