import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Formik } from "formik";
import * as Yup from "yup";
import { Button, Col, Modal, Row, Tab, Tabs } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import {
  initMemberEdit,
  fetchMemberById,
  EditMemberById,
  resetMemberState,
  selectMemberDataById,
  undoError,
} from "../../store/MemberSlice";
import AlertMessage from "../../../shared/components/AlertMessage";
import MemberDetails from "../../components/MemberDetails";
import { i18n } from "../../../shared/helpers/sharedMethods";
import { fetchInviteTrainer } from "../../../settings/store/inviteUserSlice";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const EditMember = ({ memberId, setShowEditMember, activeEditMemberTab, isTriggeredFromMember, memberQuickEdit, }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const selectedBusiness = useSelector(
    (state) => state?.auth?.selectedBusiness
  );

  const accessToken = useSelector((state) => state.auth.accessToken);
  const tenantId = selectedBusiness?.pivot?.tenant_id;

  const member = useSelector((state) =>
    selectMemberDataById(state, memberId)
  );
  const memberStates = useSelector((state) => state.members.member);

  const { status, savedEntity, error, errorStatus } = useSelector((state) => state.members);

  const [show, setShow] = useState(true);
  const [key, setKey] = useState(activeEditMemberTab);

  useEffect(() => {
    if (member) {
      dispatch(initMemberEdit(member));
    }
  }, []);

  useEffect(() => {
    if (status === "succeeded" && savedEntity) {
      toast.success("Member updated successfully")
      if (isTriggeredFromMember) {
        memberQuickEdit([savedEntity]);
        setShow(false);
        setShowEditMember(false);
      }
      dispatch(resetMemberState());
    }
  }, [status, savedEntity]);

  useEffect(() => {
    if (memberId) {
      dispatch(fetchMemberById({
        id: memberId,
        token: accessToken.access_token,
        tenant_id: tenantId,
      }));
    }
  }, [dispatch, memberId, accessToken.access_token, tenantId]);

  const { fetchTrainerStatus, trainersList } = useSelector((state) => state.inviteUser);
  useEffect(() => {
    dispatch(fetchInviteTrainer({
      tenant_id: tenantId,
      token: accessToken?.access_token,
    }));
  }, []);

  const filterData = Array.isArray(trainersList) ? trainersList.map((value) => value) : [];

  const handleCancel = () => {
    // dispatch(resetMemberState());
    setShow(false);
    setShowEditMember(false); // Close modal from parent
  };


  const handleSubmit = async (values) => {
    try {
      await dispatch(EditMemberById({
        id: memberId,
        data: values,
        token: accessToken.access_token,
        tenant_id: tenantId,
      })).unwrap(); // Unwrap the result to catch errors
      // Close modal on successful save
      setShowEditMember(false);
    } catch (error) {
      console.error("Failed to save member:", error);
    }
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.altKey && event.key === "Enter") {
        event.preventDefault();
        document.getElementById("submitButton").click();
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const initialValues = member || { member_name: "", mobile: "" };

  const handleAlertClose = () => {
    dispatch(undoError());
  };

  return (
    <>
      <ToastContainer />
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        validationSchema={Yup.object({
          member_name: Yup.string()
            .required(i18n("Please enter member name"))
            .min(2, "Please enter at least 2 characters"),
          mobile: Yup.string()
            .required(i18n("Please enter member mobile number"))
            .min(10, "Mobile number must be 10 digits")
            .matches(/^[0-9]*$/, "Invalid mobile number"),
        })}
        onSubmit={handleSubmit} // Pass the handleSubmit function here
      >
        {({ values, errors, touched, handleChange, handleSubmit }) => (
          <Modal
            show={show}
            keyboard={true}
            onHide={handleCancel}
            style={{ zIndex: 2500 }}
            size="lg"
            dialogClassName="modal-dialog modal-dialog-centered"
          >
            <div className="" style={{ borderRadius: '5px', boxShadow: '0 0 500px 500px rgba(0, 0, 0, 0.3)' }}>
              <Modal.Header closeButton>
                <h2>Edit Member</h2>
              </Modal.Header>
              {errorStatus && (
                <AlertMessage
                  errorStatus={errorStatus}
                  error={error}
                  handleAlertClose={handleAlertClose}
                />
              )}
              <Modal.Body className="py-2" style={{ height: "500px" }}>
                <Tabs
                  id="controlled-tab-example"
                  activeKey={key}
                  onSelect={(k) => setKey(k)}
                  className="mb-3 custom-nav-css"
                  variant="underline"
                  style={{
                    borderBottom: "1px solid lightgray",
                    lineHeight: "30px",
                    gap: "30px",
                  }}
                >
                  <Tab eventKey="general" title={<div>{i18n("General Details")}</div>}>
                    <MemberDetails
                      handleChange={handleChange}
                      errors={errors}
                      values={values}
                      touched={touched}
                      filterData={filterData}
                    />
                  </Tab>
                </Tabs>
              </Modal.Body>
              <Modal.Footer>
                <Row>
                  <Col className="d-flex justify-content-end">
                    <Button
                      id="submitButton"
                      variant="primary mx-2"
                      onClick={handleSubmit} // Call Formik's handleSubmit
                    >
                      Save
                    </Button>
                    <Button variant="outline-secondary mx-2" onClick={handleCancel}>
                      Cancel
                    </Button>
                  </Col>
                </Row>
              </Modal.Footer>
            </div>
          </Modal>
        )}
      </Formik>
    </>
  );
};

export default EditMember;
