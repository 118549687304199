import React, { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import {
  format,
} from "date-fns";
import {
  CurrentMonthDates,
  LastMonthDates,
  Weekdays,
} from "../../../../shared/components/DateRangeXAxis";
import { CurrentMonthDateRange, CurrentWeekDateRange, PreviousMonthDateRange, PreviousWeekDateRange } from "../../../../shared/components/DateRangeYAxis";
import { placeholderText } from "../../../../shared/helpers/sharedMethods";


const Chart = (props) => {
  const { reportItems, reportPeriod,title} = props;
  const [dateRangeX, setDateRangeX] = useState([]);
  const [salesCount, setSalesCount] = useState([]);
  const weekDays = {
    's': placeholderText('Sun'),
    'm': placeholderText('Mon'),
    't': placeholderText('Tue'),
    'w': placeholderText('Wed'),
    'th': placeholderText('Thu'),
    'f': placeholderText('Fri'),
    'st': placeholderText('Sat')
  };
  

  useEffect(() => {
    let itemDatas;

    switch (reportPeriod) {
      case "current-month":
        itemDatas = CurrentMonthDates();
        break;

      case "previous-month":
        itemDatas = LastMonthDates();
        break;

      case "current-week":
      case "previous-week":
        itemDatas = Weekdays(weekDays);
        break;

      default:
        itemDatas = [];
        break;
    }

    setDateRangeX(itemDatas);
  }, [reportPeriod]);

  useEffect(() => {

    let start, end;

    switch (reportPeriod) {
      case "current-week":
        ({ start, end } = CurrentWeekDateRange());
        break;

      case "current-month":
        ({ start, end } = CurrentMonthDateRange());
        break;

      case "previous-week":
        ({ start, end } = PreviousWeekDateRange());
        break;
      case "previous-month":
        ({ start, end } = PreviousMonthDateRange());
        break;

      default:
        start = new Date();
        end = new Date();
        break;
    }

    const dates = [];
    const currentDate = new Date(start);

    while (currentDate <= end) {
      let formattedDate;
      if (reportPeriod === "current-week" || reportPeriod === "previous-week") {
        formattedDate = currentDate.toISOString().split("T")[0];
      } else {
        formattedDate = format(currentDate, "yyyy-MM-dd");
      }
      dates.push(formattedDate);
      currentDate.setDate(currentDate.getDate() + 1);
    }

    const graphAmountData = Array(dates.length).fill(0);
    if (reportItems && reportItems) {
      reportItems.forEach((dataItem) => {
        const voucherDate = dataItem.group_day;
        const totalCount = dataItem.count;

        const dateIndex = dates.findIndex(
          (date) => date === voucherDate
        );
        if (dateIndex !== -1) {
          graphAmountData[dateIndex] = totalCount;
        }
      });
    }
    setSalesCount(graphAmountData);
  }, [reportItems, reportPeriod]);

  const [state, setState] = useState([]);
  const [option, setOption] = useState({});
  useEffect(() => {
    setState([
      {
        name: title,
        data: salesCount,
      },
    ]);
  }, [salesCount]);
  
  useEffect(() => {
    setOption({
      chart: {
        type: 'area',
        stacked: false,
        height: 350,
        zoom: {
          type: 'x',
          enabled: true,
          autoScaleYaxis: true
        },
        toolbar: {
          show: false,
          autoSelected: 'zoom'
        }
      },
      colors: ["#FFC2C7"],
      dataLabels: {
        enabled: false
      },
      markers: {
        size: 0,
      },
      title: {
        text: title,
        align: 'left'
      },

      fill: {
        type: 'gradient',
        gradient: {
          shadeIntensity: 1,
          inverseColors: false,
          opacityFrom: 1.5,
          opacityTo: 1,
          stops: [0, 90, 100]
        },
      },
    
      xaxis: {
        type: 'datetime', 
        categories: dateRangeX, 
        labels: {
          show: true, 
          rotate: 0, 
          trim: false,
          minHeight: undefined,
          maxHeight: 120, 
          style: {
            fontSize: "12px", 
          },
        },
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return val ;
          },
        },
      },
    });
  }, [dateRangeX]);
  
  return (
    <>
      <ReactApexChart options={option} series={state} type="area" height={350} />
    </>
  );
};
export default Chart;
