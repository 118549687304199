import { processCartItem } from "../../pos/helpers/cartHelper";
import { taxList, taxListShort } from "../../shared/config/taxConfig";
import { subscriptionProcessCartItem } from "../../pos/helpers/cartHelper";
import { format } from "date-fns";
import { getUtcDate } from "../../shared/helpers/localizationHelper";

export const ProcessVoucherEdit = (
  editVoucher,
  PartyValue,
  balanceDetails,
  updateProductDetails,
  addressValue,
  dueDateValue,
  voucherType
) => {
  ;
  ;

  const editSale = {
    type: voucherType,
    editVoucher: editVoucher,
    partyValue: PartyValue,
    balanceDetails: balanceDetails,
    dueDateValue: dueDateValue,
    productDetails: updateProductDetails,
    addressValue: addressValue,
  };
  // const salesData = editSales(editSale)
  ;
  // return salesData;
};
export const createProcess = (
  inVoiceNumber,
  PartyValue,
  balanceDetails,
  dueDateValue,
  productDetails,
  addressValue,
  type,
  dispatch
) => {
  ;
  const createSale = {
    type: type,
    inVoiceNumber: inVoiceNumber,
    partyValue: PartyValue,
    balanceDetails: balanceDetails,
    dueDateValue: dueDateValue ? dueDateValue : null,
    productDetails: productDetails,
    addressValue: addressValue,
  };
  // const salesData =addSales(createSale)
  ;
  // return salesData;
};

export const additionalChargesProcess = (
  additionalCharges,
  additionChargesTotal
) => {
  const iterateAdditionalCharges = additionalCharges.map((data) => ({
    charge_type: data.key,
    charge_value: parseInt(data.value),
    tax_total: 0,
    net_total: 0,
  }));
  return iterateAdditionalCharges;
};

export const generateRandomNumber = () => {
  let randomNumber = Math.floor(Math.random() * 900000) + 100000;

  return randomNumber;
};

export const taxValueFinder = (singleProduct) => {
  let selectedTaxList;

  taxList.forEach((tax) => {
    ;
    if (tax.code === singleProduct.tax_code) {
      selectedTaxList = tax.rate;
    }
  });

  ;
  return selectedTaxList;
};

export const filterTaxListShort = (taxListShort, taxCode) => {
  return taxListShort.filter((tax) => taxCode.includes(tax.code));
};

export const createProcesses = (
  saleOrder,
  partyValue,
  selectedBusiness,
  totalCharges,
  type,
  totalReceivedAmount,
  markAsPaid
) => {
  ;
  // saleOrder.party_id =partyValue?.id
  saleOrder.created_by = selectedBusiness?.pivot?.user_id;
  saleOrder.updated_by = selectedBusiness?.pivot?.user_id;
  saleOrder.type = type;
  saleOrder.grand_total += totalCharges;
  saleOrder.paid_amount =saleOrder.processing_paid_amount
  // tempOrder.payment.created_by = selectedBusiness.id;
  saleOrder.voucher_date = getUtcDate(saleOrder.voucher_date);
  // saleOrder.due_date = new Date(saleOrder.due_date).toISOString();
  if (saleOrder?.grand_total - saleOrder?.paid_amount === 0) {
    saleOrder.due_date = null;
  } else if (
    type === "sales_invoice" ||
    type === "quote" ||
    type === "proforma_invoice" ||
    type === "purchase_invoice" ||
    type === "purchase_order"
  ) {
    if (saleOrder.due_date) {
      saleOrder.due_date = getUtcDate(saleOrder.due_date);
    } else {
      saleOrder.due_date = null;
    }
  } else {
    saleOrder.due_date = null;
  }

  delete saleOrder.id;
  delete saleOrder.status;
  delete saleOrder.errorStatus;
  delete saleOrder.total_quantity;
  delete saleOrder.received_amount;
  delete saleOrder.change_return;
  // delete saleOrder.party
  delete saleOrder.totalAddlCharges;
  delete saleOrder.total_discount;
  delete saleOrder.copiedGrandTotal;
  delete saleOrder.voucherPayment;
  delete saleOrder.totalReceivedAmount
  delete saleOrder.processing_paid_amount
  delete saleOrder.markAsPaid
  delete saleOrder.updatePaidAmountStatus
  delete saleOrder.deletePaidAmountStatus
  return saleOrder;
};

export const editProcesses = (
  saleOrder,
  partyValue,
  selectedBusiness,
  totalCharges,
  type,
  convertType,
  totalReceivedAmount,
  markAsPaid
) => {
  ;
  
  //saleOrder.party_id =partyValue?.id
  saleOrder.created_by = selectedBusiness?.pivot?.user_id;
  saleOrder.updated_by = selectedBusiness?.pivot?.user_id;
  saleOrder.voucher_date = getUtcDate(saleOrder.voucher_date);
  saleOrder.paid_amount = saleOrder.processing_paid_amount
  // tempOrder.payment.created_by = selectedBusiness.id;
  if (saleOrder?.grand_total - saleOrder?.paid_amount === 0) {
    saleOrder.due_date = null;
  } else if (
    type === "sales_invoice" ||
    type === "quote" ||
    type === "proforma_invoice" ||
    type === "purchase_invoice" ||
    type === "purchase_order"
  ) {
    if (saleOrder.due_date) {
      saleOrder.due_date = getUtcDate(saleOrder.due_date);
    } else {
      saleOrder.due_date = null;
    }
  } else {
    saleOrder.due_date = null;
  }

  if (convertType === "quote" || convertType === "proforma_invoice") {
    saleOrder.type = "sales_invoice";
    saleOrder.isConvertType = true;
  } else if (convertType === "purchase_order") {
    saleOrder.type = "purchase_invoice";
    saleOrder.isConvertType = true;
  } else {
    saleOrder.type = type;
  }

  saleOrder.grand_total += totalCharges;

  delete saleOrder.status;
  delete saleOrder.total_quantity;
  delete saleOrder.received_amount;
  delete saleOrder.change_return;
  //delete saleOrder.party
  delete saleOrder.totalAddlCharges;
  delete saleOrder.total_discount;
  delete saleOrder.copiedGrandTotal;
  //delete saleOrder.voucherPayment;
  delete saleOrder.totalReceivedAmount
  delete saleOrder.processing_paid_amount
  delete saleOrder.markAsPaid
  delete saleOrder.updatePaidAmountStatus
  delete saleOrder.deletePaidAmountStatus

  return saleOrder;
};
export const updateAddlTotal = (addlCharges) => {
  let chargesTotal = 0;

  addlCharges.forEach((elememt) => {
    chargesTotal += parseInt(elememt.charge_value);
  });

  return { chargesTotal };
};

export const filterVouchers = (vouchers, types) => {
  return (
    vouchers &&
    vouchers
      .filter((voucher) => types.includes(voucher?.type))
      .map((voucher) => ({
        id: voucher.id,
        voucher_date: voucher.voucher_date,
        voucher_number: voucher.voucher_number,
        party: voucher?.party?.name,
        due_date: voucher?.due_date,
        paid_amount: voucher?.paid_amount,
        grand_total: voucher.grand_total,
        status:
          voucher.paid_amount === voucher.grand_total
            ? "Paid"
            : voucher.paid_amount > 0
            ? "Partially Paid"
            : "Unpaid",
        type: voucher.type,
        voucher_items: voucher.items,
        created_at: voucher?.created_at,
      }))
  );
};

export const exportPreProcessor = (data) => {
  ;
  const salesData = data.map((sales) => ({
    Id: sales?.id,
    Date: sales?.voucher_date
      ? format(new Date(sales?.voucher_date), "dd-MM-yyyy")
      : "-",
    Voucher_Number: sales?.voucher_number,
    Party: sales?.party,
    Grand_Total: sales?.grand_total,
    Paid_Amount: sales?.paid_amount,
    Status: sales?.status,
    Type: sales?.type,
  }));
  return salesData;
};

export const isValidPinCode = (str) => {
  alert(str);

  let regex = new RegExp(/^[1-9]{1}[0-9]{2}\s{0,1}[0-9]{3}$/);

  if (!str) {
    return "true";
  }

  if (regex.test(str) == true) {
    return "true";
  } else {
    return "false";
  }
};

export const unDoDiscounts = (props) => {
  const { voucherItems, discountEnabled } = props;

  ;
  ;

  // if (!discountEnabled || !voucherItems || voucherItems.length === 0) {
  //   return [];
  // }
  if (!discountEnabled) {
    let unDoDiscount = [];
    const iteratedItem =
      voucherItems &&
      voucherItems.map((item) => {
        if (item.product_name) {
          // item.item_discount = 0; // Reset item_discount

          let removeDiscount = { ...item };

          // Set discount amount to 0
          removeDiscount.item_discount = 0;
          removeDiscount.item_discount_amount = 0;
          ;
          unDoDiscount.push(
            processCartItem(removeDiscount, true, discountEnabled)
          ); // Assuming processCartItem modifies item and returns something
          ;
          return item;
        }
      });

    ;
    return unDoDiscount;
  }
  // Return the array of processed items
};

export const unDoCessVoucher = (props) => {
  const { voucherItems, cessEnabled } = props;

  ;
  ;

  // if (!discountEnabled || !voucherItems || voucherItems.length === 0) {
  //   return [];
  // }
  if (!cessEnabled) {
    let unDocess = [];
    const iteratedItem =
      voucherItems &&
      voucherItems.map((item) => {
        if (item.product_name) {
          // item.item_discount = 0; // Reset item_discount

          let removeCess = { ...item };

          // Set discount amount to 0
          removeCess.cess = 0;
          removeCess.cess_rate = 0;
          ;
          ;
          unDocess.push(processCartItem(removeCess, true, cessEnabled)); // Assuming processCartItem modifies item and returns something
          ;
          return item;
        }
      });

    ;
    return unDocess;
  }
  // Return the array of processed items
};

export const unDoDiscountsSubscription = (props) => {
  const { subscriptionPlanItems, discountEnabled } = props;

  ;
  ;

  // if (!discountEnabled || !voucherItems || voucherItems.length === 0) {
  //   return [];
  // }
  if (!discountEnabled) {
    let unDoDiscount = [];
    const iteratedItem =
      subscriptionPlanItems &&
      subscriptionPlanItems.map((item) => {
        if (item?.product?.name || item.product_name) {
          // item.item_discount = 0; // Reset item_discount
          let removeDiscount = { ...item };

          // Set discount amount to 0
          removeDiscount.item_discount = 0;
          removeDiscount.item_discount_amount = 0;
          ;
          unDoDiscount.push(
            subscriptionProcessCartItem(removeDiscount, true, discountEnabled)
          ); // Assuming processCartItem modifies item and returns something
          ;
          return item;
        }
      });

    ;
    return unDoDiscount;
  }
  // Return the array of processed items
};

export const unDoCessSubscription = (props) => {
  const { subscriptionPlanItems, cessEnabled } = props;

  ;
  ;

  if (!cessEnabled) {
    let unDocess = [];
    const iteratedItem =
      subscriptionPlanItems &&
      subscriptionPlanItems.length > 0 &&
      subscriptionPlanItems.map((item) => {
        if (item?.product?.name || item.product_name) {
          // item.item_discount = 0; // Reset item_discount
          let removeCess = { ...item };

          // Set discount amount to 0
          removeCess.cess = 0;
          removeCess.cess_rate = 0;
          ;
          unDocess.push(
            subscriptionProcessCartItem(removeCess, true, cessEnabled)
          ); // Assuming processCartItem modifies item and returns something
          ;
          return item;
        }
      });

    ;
    return unDocess;
  }
  // Return the array of processed items
};

export const preprocessorDeletedSales = (vouchers)=>{
    
  const iterateParty = vouchers.map((data)=>({
      id: data?.id,
      voucher_number: data.voucher_number,
      party: data?.party?.name,
      paid_amount: data?.paid_amount,
      type: data?.type,
      created_at: data?.created_at,
      deleted_at: data?.deleted_at,
 }));
 
 return iterateParty;
 }
 export const filterDeletedSales = (vouchers, types) => {
  return vouchers && vouchers
    .filter(voucher => types.includes(voucher?.type))
    .map(voucher => ({
      id: voucher?.id,
      voucher_number: voucher?.voucher_number,
      party: voucher?.party?.name || '-',
      paid_amount: voucher?.paid_amount,
      type: voucher?.type,
      created_at: voucher?.created_at,
      deleted_at: voucher?.deleted_at,
    }));
}


export const preprocessorDeletedSalesType = (vouchers)=>{
  console.log(vouchers, "deleted_voucher");
    
  const iterateParty = vouchers.map((data)=>({
      id: data?.id,
      voucher_number: data.voucher_number,
      party: data?.party,
      paid_amount: data?.paid_amount,
      type: data?.type,
      created_at: data?.created_at,
      deleted_at: data?.deleted_at,
 }));
 
 return iterateParty;
 }
