import {
  startOfWeek,
  endOfWeek,
  startOfMonth,
  endOfMonth,
  subWeeks,
  subMonths,
  startOfYear,
  endOfYear,
  subYears,
  format,
} from "date-fns";

const ReportDateRange = (props) => {
  const { reportPeriod } = props;
  var dateLimits = {};
  const dateFormatStr = "yyyy-MM-dd HH:mm:ss";

  switch (reportPeriod) {
    case "today":
      const todayStart = new Date();
      todayStart.setHours(0, 0, 0, 0); // Set to beginning of the day
      const todayEnd = new Date();
      todayEnd.setHours(23, 59, 59, 999); // Set to end of the day

      dateLimits.startDate = todayStart.toISOString();
      dateLimits.endDate = todayEnd.toISOString();
      break;

    case "yesterday":
      const yesterdayStart = new Date();
      yesterdayStart.setDate(yesterdayStart.getDate() - 1); // Set to yesterday
      yesterdayStart.setHours(0, 0, 0, 0); // Set to beginning of the day

      const yesterdayEnd = new Date();
      yesterdayEnd.setDate(yesterdayEnd.getDate() - 1); // Set to yesterday
      yesterdayEnd.setHours(23, 59, 59, 999); // Set to end of the day

      dateLimits.startDate = yesterdayStart.toISOString();
      dateLimits.endDate = yesterdayEnd.toISOString();
      break;

    case "current-week":
      dateLimits.startDate = format(startOfWeek(new Date()), dateFormatStr);
      dateLimits.endDate = format(endOfWeek(new Date()), dateFormatStr);
      break;

    case "previous-week":
      dateLimits.startDate = format(
        startOfWeek(subWeeks(new Date(), 1)),
        dateFormatStr
      );
      dateLimits.endDate = format(
        endOfWeek(subWeeks(new Date(), 1)),
        dateFormatStr
      );
      break;

    case "current-month":
      dateLimits.startDate = format(startOfMonth(new Date()), dateFormatStr);
      dateLimits.endDate = format(endOfMonth(new Date()), dateFormatStr);
      break;

    case "previous-month":
      dateLimits.startDate = format(
        startOfMonth(subMonths(new Date(), 1)),
        dateFormatStr
      );
      dateLimits.endDate = format(
        endOfMonth(subMonths(new Date(), 1)),
        dateFormatStr
      );
      break;
    case "current-year":
      dateLimits.startDate = format(startOfYear(new Date()), dateFormatStr);
      dateLimits.endDate = format(endOfYear(new Date()), dateFormatStr);
      break;

    case "previous-year":
      dateLimits.startDate = format(
        startOfYear(subYears(new Date(), 1)),
        dateFormatStr
      );
      dateLimits.endDate = format(
        endOfYear(subYears(new Date(), 1)),
        dateFormatStr
      );
      break;
    default:
      throw Error(
        "Time Interval , should be - today,yesterday,current-week, previous-week, current-month, previous-month,current-year,previous-year"
      );
  }
  return dateLimits;
};
export default ReportDateRange;