
import LockScreen from '../settings/screens/LockScreen'
import UnLockScreen from '../settings/screens/UnLockScreen'
import CreateBusiness from './screens/CreateBusiness'
import InstallingScreen from './screens/InstallingScreen'
import MultipleTenant from './screens/MultiTenants'
import RegisterScreen from './screens/RegisterScreen'


const rootPath = 'auth'

export const authRoutes =  [
    {
        path: rootPath + '/register',
        element: <RegisterScreen />,
        isProtected: false
    },
    {
        path: rootPath + '/create-business',
        element: <CreateBusiness />,
        isProtected: true
    },
    {
        path: rootPath + '/install',
        element: <InstallingScreen />,
        isProtected: true
    },
    {
        path: rootPath + '/multiple-tenant',
        element: <MultipleTenant />,
        isProtected: true
    },
    {
        path: rootPath + "/lock-screen",
          element: <LockScreen />,
          isProtected: true,
       },
       {
        path: rootPath + "/unlock-screen",
          element: <UnLockScreen />,
          isProtected: true,
       }
  
]