import React, { useState, useEffect } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Chart from "./Chart";
import SummaryReportPeriod from "../../../../shared/components/SummaryReportPeriod";
import { fetchNumOfSalesCountChart } from "../../../store/sales_chart/numberOfSalesChartSlice";
import { fetchNumberOfPurchaseChart } from "../../../store/purchase_chart/numberOfPurchaseChartSlice";
import { placeholderText } from "../../../../shared/helpers/sharedMethods";

function NumberOfCountChart(props) {
  const {type} = props;
  const dispatch = useDispatch();
 
  const [reportPeriod, setReportPeriod] = useState("current-week");
 const salesTitle = placeholderText("Number Of Sales");
 const purchaseTitle = placeholderText("Number Of Purchase")
 
  useEffect(() => {
    if (type === "Sales") {
    dispatch(fetchNumOfSalesCountChart(reportPeriod));
    }else{
      dispatch(fetchNumberOfPurchaseChart(reportPeriod));
    }
  }, [reportPeriod,type]);
  const reportItems = useSelector((state) => state.numOfSalesCountChart.reportItems);
  const purchaseReportItems = useSelector((state) => state.numberOfPurchaseChart.reportItems);

  return (
    <Card id="chart" className="border mb-3">
      <Card.Header className="border-0 pt-0"></Card.Header>
      <Card.Body className="py-0">
        <Row className="row">
        <Col xl={9} md={9} >
            <Chart 
             reportItems= {type==='Sales'?reportItems:purchaseReportItems} 
             reportPeriod={reportPeriod} 
             title= {type==='Sales'? salesTitle : purchaseTitle} 
            />
          </Col>
          <Col xl={3} md={3} className="d-flex justify-content-end">
            <Col >

            <SummaryReportPeriod
            reportPeriod={reportPeriod}
            setReportPeriod={setReportPeriod}
          />
             </Col>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
}

export default NumberOfCountChart;
