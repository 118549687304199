import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { Col, Row, Card, Form, Container, Button, Spinner, Stack, Image } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { initSettingEdit, resetError, setCurrency, setLanguage } from "../store/settingSlice";
import { Formik } from "formik";
import Layout from "../../shared/components/Layout";
import NavbarSetting from "../screens/NavbarSettings";
import { cloneDeep } from "lodash";
import { editSetting, resetStatus } from "../store/settingSlice";
import { toast, ToastContainer } from "react-toastify";
import Header from "../../shared/components/Header";
import { i18n, placeholderText } from "../../shared/helpers/sharedMethods";
import ShortcutKeys from "../../shared/components/ShortcutKeys";
import { fetchSetting } from "../store/getSettingSlice";
import SettingsLayout from "./SettingsLayout";
import LoadingSpinner from '../../shared/components/LoadingSpinner';
const CurrencyDetails = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const selectedBusiness = useSelector(
    (state) => state?.auth?.selectedBusiness
  );
  const { accessToken } = useSelector((state) => state?.auth);


  const { billing_settings, error } = useSelector((state) => state.setting);
  const status = useSelector((state) => state.setting.status);
  const loading = useSelector((state) => state.getSetting.loading)
  const [selectedLanguage, setSelectedLanguage] = useState("");
  const isOnline = navigator.onLine;

  const getSetting = useSelector((state) => state.getSetting?.dataSetting);
  useEffect(() => {
    if (getSetting) {
      dispatch(initSettingEdit(getSetting));
    }
  }, [getSetting]);


  useEffect(() => {
    dispatch(
      fetchSetting({
        accessToken: accessToken.access_token,
        tenant_id: selectedBusiness?.pivot?.tenant_id,
      })
    );
  }, [status]);

  useEffect(() => {
    if (error) {
      toast.error(error?.props?.id);
      dispatch(resetError());
    }
  }, [error]);

  useEffect(() => {
    if (status && isOnline) {
      toast.success(i18n("Language and Currency Updated Successfully"));
      dispatch(resetStatus());
    }
  }, [status, isOnline]);

  const options = [
    { label: "₹", value: "₹" },
    { label: "$", value: "$" },
  ];

  const langOptions = [
    { label: placeholderText("English"), value: "en" },
    { label: placeholderText("Tamil"), value: "ta" },
    { label: placeholderText("Hindi"), value: 'hi' }
  ];

  const handleCancel = () => {
    navigate('/dashboard/sales')
  }
  return (
    <Formik
      initialValues={billing_settings}
      enableReinitialize={true}
      onSubmit={async (values) => {
        try {
          const formData = cloneDeep(values);
          formData.created_by = selectedBusiness?.pivot?.user_id;
          formData.tenant_id = selectedBusiness?.pivot?.tenant_id;
          formData.accessToken = accessToken.access_token;
          delete formData.id;
          
          if (!isOnline) {
            toast.error(i18n("You are Offline. Please Connect to the Internet"));
            return;
          }
          dispatch(setLanguage(selectedLanguage));
          dispatch(editSetting(formData));


        } catch (error) {
        }
      }}
    >
      {({ values, handleSubmit, handleChange }) => (
        <>
          <ToastContainer />
          <SettingsLayout title="Language and Currency"
            onSave={handleSubmit}
            onCancel={handleCancel}>
             {loading && <LoadingSpinner />}
            <Row>
              <Col md={3} className="mt-3">
                <Form.Label>{i18n("Language")}</Form.Label>
                <Form.Select
                  value={values?.language}
                  name="language"
                  onChange={(e) => {
                    handleChange(e);
                    setSelectedLanguage(e.target.value);
                  }}
                >
                  {langOptions &&
                    langOptions.length &&
                    langOptions.map((item, index) => (
                      <option key={index} value={item.value}>
                        {item.label}
                      </option>
                    ))}
                </Form.Select>
              </Col>
              <Col md={3} className="mt-3">
                <Form.Label>{i18n("Currency")}</Form.Label>
                <Form.Select
                  value={values?.currency}
                  name="currency"
                  onChange={(e) => {
                    handleChange(e);
                    dispatch(setCurrency(e.target.value));
                  }}
                >
                  {options &&
                    options.length > 0 &&
                    options.map((item, index) => (
                      <option key={index} value={item.value}>
                        {item.label}
                      </option>
                    ))}
                </Form.Select>

              </Col>
            </Row>
          </SettingsLayout>
        </>
      )}
    </Formik>
  );
};

export default CurrencyDetails;
