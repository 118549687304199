export const calculateTotalQuantity = (items) => {
  if (!items || items.length === 0) {
    return 0;
  }

  let totalQuantity = 0;
  items.forEach((item) => {
    if (item.quantity) {
      totalQuantity += item.quantity;
    }
  });

  return totalQuantity;
};

export const calculateTotalTaxAmount = (items) => {
  if (!items || items.length === 0) {
    return 0;
  }

  let totalTaxAmount = 0;
  items.forEach((item) => {
    if (item.tax_total) {
      totalTaxAmount += item.tax_total;
    }
  });

  return totalTaxAmount;
};

export const calculateGrandTotalTax = (items) => {
  let grandTotalTax = 0;
  items.forEach((data) => {
    grandTotalTax += data.tax_total ? data.tax_total / 2 : 0;
  });
  return grandTotalTax;
};


export const calculateTotalTaxableAmount = (items) => {
  if (!items || items.length === 0) {
    return 0;
  }

  let totalTaxableAmount = 0;
  items.forEach((item) => {
    if (item.item_price && item.quantity) {
      totalTaxableAmount += item.item_price * item.quantity;
    }
  });

  return totalTaxableAmount;
};

export const calculateTaxAmountWithSameTaxRate = (items) => {
  if (!items || items.length === 0) {
    return [];
  }

  const taxMap = new Map();

  items.forEach((item) => {
    if (item.tax_rate) {
      const taxRate = item.tax_rate;
      const taxTotal = item.tax_total || 0;

      if (taxMap.has(taxRate)) {
        taxMap.set(taxRate, taxMap.get(taxRate) + taxTotal);
      } else {
        taxMap.set(taxRate, taxTotal);
      }
    }
  });

  const taxRows = [];
  for (let [taxRate, total] of taxMap) {
    const cgstRate = taxRate / 2;
    const sgstRate = taxRate / 2;
    const taxRow = {
      CGST: cgstRate,
      SGST: sgstRate,
      Amount: total,
      
    };
    taxRows.push(taxRow);
  }

  return taxRows;
};
