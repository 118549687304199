import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Modal, Row, Form, Button, Col, Alert, Stack, ToastContainer, Toast } from 'react-bootstrap';
import { placeholderText, i18n } from '../../shared/helpers/sharedMethods';
import ReactSelect from '../../shared/components/ReactSelect';
import { selectCategoryById } from '../store/categorysSlice';
import { editCategory, initCategoryEdit, undoCategoryError } from '../store/categoryEntitySlice';
import { updateCategory } from '../store/categorysSlice';
import {
    changeType,
    changeName,
} from "../store/categoryEntitySlice";
import { cloneDeep } from "lodash";
import { faCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
const EditCategory = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { handleClose, showEdit, edit } = props;
    const categoryId = edit?.id
    const selectedCategory = useSelector((state) => selectCategoryById(state, categoryId));
    const { errorStatus, error } = useSelector((state) => state?.categoryEntity);
    const selectedBusiness = useSelector(
        (state) => state?.auth?.selectedBusiness
    );

    const getCategory = useSelector(state => state.categoryEntity.category)

    const { type, name } = useSelector(state => state.categoryEntity.category)
    const { accessToken } = useSelector((state) => state?.auth);
    const tenantId = selectedBusiness?.pivot?.tenant_id;
    const { terminalId } = useSelector((state) => state.initInstall);

    const [loading, setLoading] = useState(false);


    const handleAlertClose = () => {
        setLoading(false)
        dispatch(undoCategoryError())
    };
    useEffect(() => {
        if (selectedCategory) {
            dispatch(initCategoryEdit(selectedCategory));
        }
    }, []);
    const status = useSelector(state => state.categoryEntity.editStatus)

    const updatedEntity = useSelector(state => state.categoryEntity.savedEntity)


    useEffect(() => {
        if (errorStatus) {
            setLoading(false)
        }
    }, [errorStatus])

    useEffect(() => {
        if (status && updatedEntity) {
            dispatch(updateCategory(updatedEntity));
            handleClose();
        }
    }, [status, updatedEntity]);

    const typeOptions = [
        { value: 'product', label: 'Product' },
        { value: 'party', label: 'Party' },
    ];


    const onTypeChange = (e) => {
        e.preventDefault();
        dispatch(changeType(e.target.value));
    };
    const onChangeInput = (e) => {
        e.preventDefault();
        dispatch(changeName(e.target.value));
    };

    const onSubmit = () => {

        let formData = cloneDeep(getCategory);
        formData.id = selectedCategory.id;
        formData.created_by = selectedBusiness?.pivot?.user_id;
        formData.updated_by = selectedBusiness?.pivot?.user_id;
        console.log("form data category", formData)

        dispatch(editCategory({
            data: formData, token: accessToken.access_token,
            tenant_id: tenantId, terminal_id: terminalId
        }));
    };


    const clearField = () => {
        handleClose()
    }
    return (
        <>

            <Modal show={showEdit} dialogClassName='modal-dialog modal-dialog-centered mw-700px'
                //    onHide={clearField}
                onHide={handleClose}
                keyboard={true}
            >

                <Modal.Header closeButton>
                    <Modal.Title>{i18n("Edit Category")}</Modal.Title>
                </Modal.Header>
                {errorStatus &&
                    <Stack
                        className="pt-3 "
                        direction="horizontial"
                        style={{ paddingLeft: "30px", width: "90%" }}
                    >
                        <Alert
                            show={errorStatus}
                            onClose={handleAlertClose}
                            dismissible
                            size="md"
                            variant="danger"
                        >
                            <Alert.Heading className="fw-bolder text-black">Errors</Alert.Heading>
                            <Stack
                                gap={1}
                                className="d-flex justify-content-start"
                                direction="horizontal"
                            >
                                <FontAwesomeIcon
                                    icon={faCircle}
                                    className="px-1 "
                                    style={{ fontSize: "7px" }}
                                />
                                {error ? error : ''}
                            </Stack>
                        </Alert>
                    </Stack>
                }
                <Modal.Body>
                    <Row className='row'>
                        <Form.Group className='col-md-12 mb-2'>
                            <Form.Label className='form-label-item mb-2'>{i18n("Category Type")}</Form.Label>
                            <Form.Select
                                aria-label="type"
                                name="type"
                                value={type}
                                onChange={(e) => {
                                    onTypeChange(e);
                                }}
                            >
                                {typeOptions && typeOptions.length ? (
                                    typeOptions.map((term, index) => {
                                        return <option key={term.value} value={term.value}>{term.label}</option>;
                                    })
                                ) : (
                                    <option value="">No option</option>
                                )}
                            </Form.Select>
                        </Form.Group>
                        <br />
                        <Form className='col-md-12 mb-2'>
                            <Form.Label
                                className='form-label'>{i18n("Category Name")}: </Form.Label>
                            <span className='required' />
                            <Form.Control type='text' name='name' autoComplete='off'
                                autoFocus
                                placeholder={placeholderText('Enter Category Name')}
                                className='form-control'
                                onChange={(e) => onChangeInput(e)}
                                value={name}
                            />

                        </Form>
                    </Row>

                </Modal.Body>

                <Col className='d-flex justify-content-end px-5 mb-5 mt-3 mx-2' style={{ columnGap: 10 }}>
                    <Button onClick={onSubmit} className='btn btn-primary fw-bold' style={{ color: 'white' }}>{i18n("Submit")}</Button>
                    <Button variant="outline-secondary mx-2" onClick={clearField} >{i18n("Cancel")}</Button>
                </Col>
            </Modal>
        </>
    )
};

export default (EditCategory);