import { Stack, Alert } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/free-solid-svg-icons";

const AlertMessage = ({ errorStatus, error, handleAlertClose }) => {
  return (
    <>
      <Stack
        className="pt-3 "
        direction="horizontial"
        style={{ paddingLeft: "140px", width: "80%" }}
      >
        <Alert
          show={errorStatus}
          onClose={handleAlertClose}
          dismissible
          size="md"
          variant="danger"
        >
          <Alert.Heading className="fw-bolder text-black">Errors</Alert.Heading>
          {error.split(",").map((err, index) => (
            <Stack
              gap={1}
              className="d-flex justify-content-start"
              direction="horizontal"
            >
              <FontAwesomeIcon
                icon={faCircle}
                className="px-1 "
                style={{ fontSize: "7px" }}
              />
              {err.trim()}
            </Stack>
          ))}
        </Alert>
      </Stack>
    </>
  );
};

export default AlertMessage;
