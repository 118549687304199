import { useDispatch, useSelector } from "react-redux";
import { addPaymentDate, addPaymentNumber, addPaymentType } from "../store/paymentEntitySlice";
import { Col, Row, Form } from "react-bootstrap";
import DatePicker, { registerLocale } from "react-datepicker";
import { useEffect, useState } from "react";
import { i18n, placeholderText } from "../../shared/helpers/sharedMethods";

const PaymentTypes = (props) => {
  const dispatch = useDispatch();
  const { paymentType, paymentDate,paymentNumber } = useSelector(
    (state) => state.paymentEntity
  );

  useEffect(() => {
      let  randomNumber = Math.floor(Math.random() * 900000) + 100000;
        dispatch(addPaymentNumber(randomNumber))
        // Update the set of generated numbers
  }, []);

  const handleDateChange = (date) => {
    dispatch(addPaymentDate(date));
  };

  const paymentValue = [
    { value: "upi", label: placeholderText("UPI") },
    { value: "card", label: placeholderText("CARD") },
    { value: "Cash", label: placeholderText("CASH") },
  ];

  const handlePaymentChange = (value) => {
    dispatch(addPaymentType(value));
  };

  
  const onChangeInput = () => {};

  return (
    <Col lg={12} className="mt-4">
      <Row className="row">
       
        <Col>
          <h5 style={{ color: "#838587" }}>{i18n("Payment in Number")}</h5>

          <Form.Control
            type="text"
            name="paymentNum"
            placeholder={placeholderText("Enter Payment No")}
            className="form-control point-cursor"
            onChange={(e) => onChangeInput(e)}
            value={paymentNumber}
          />
        </Col>
        <Col>
          <Form style={{ display: "flex", flexDirection: "column" }}>
            <h5 style={{ color: "#838587" }}>{i18n("Payment Date")}</h5>
            <DatePicker
              selected={new Date(paymentDate)}
              onChange={handleDateChange}
              className="form-control"
              placeholderText="Select Date"
              dateFormat="dd/MM/yyyy"
            />
          </Form>
        </Col>
        <Col>
          <h5 className="required" style={{ color: "#838587" }}>
            {i18n("Payment Mode")}
          </h5>

          <Form.Select
            value={paymentType}
            onChange={(e) => {
              handlePaymentChange(e.target.value);
            }}
            defaultValue=""
          >
            {/* <option disabled value="">
            {i18n("payment type")}
            </option> */}
            {paymentValue &&
              paymentValue.length > 0 && [
                ...paymentValue.map((item, index) => (
                  <option key={index} value={item.value}>
                    {item.label}
                  </option>
                )),
              ]}
          </Form.Select>
        </Col>
      </Row>
    </Col>
  );
};
export default PaymentTypes;
