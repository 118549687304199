import {
    createSlice,
    createAsyncThunk,
  } from "@reduxjs/toolkit";
import { i18n } from "../../shared/helpers/sharedMethods";

  const initialState = {
    status:false,
    reportItems:[],
    error:false,
    errorMessage:{}
  };

export const fetchProductChart = createAsyncThunk('productSummaryChart/fetch', async (param) => {
  try{
    const response  = await window.api.productSummaryChart(param);
    return response?.data;
  }catch(err){
    await window.log.logToFile({message: "Error while fetching product chart", level: "error" });
    throw new Error("Error while fetching product Chart")
  }
});

const productChartSlice = createSlice({
    name: "productChart",
    initialState,
    reducers: {},
    extraReducers(builder) {
      builder
      .addCase(fetchProductChart.fulfilled, (state, action) => {
        state.status = true;
        state.reportItems =action.payload;
      })
      .addCase(fetchProductChart.rejected, (state, action) => {
        state.error = true; 
        state.errorMessage = i18n('Error while fetching product chart. Please try again later.');
        state.status = false;
      })

    },
});

export default productChartSlice.reducer;
