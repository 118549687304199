import React, { useEffect, useState } from "react";
import Layout from "../../../shared/components/Layout";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Row,
  Spinner,
  Stack,
} from "react-bootstrap";
import { Formik } from "formik";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import CustomerDetailsForm from "../../components/subscription_form/CustomerDetailsForm";
import ItemDetailsForm from "../../components/subscription_form/ItemDetailsForm";
import {
  AddSubscription,
  changeAddonShow,
  changeMember,
  getSubscriptionNumber,
  resetCreateSubscription,
  updateTotals,
} from "../../store/CreateSubscriptionSlice";
import PaymentSectionForm from "../../components/subscription_form/PaymentSectionForm";
import { pushNewSubscription } from "../../store/SubscriptionSlice";
import { cloneDeep, filter, isEmpty } from "lodash";
import moment from "moment";
import Header from "../../../shared/components/Header";
import { i18n, placeholderText } from "../../../shared/helpers/sharedMethods";
import PackageDetailsForm from "../../components/subscription_form/PackageDetailsForm";
import { changeToggleShow } from "../../../shared/store/sharedSlice";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  addNew,
  initEditSubscriptionItems,
  resetSubscriptionItem,
  selectAllSubscriptionPlanItems,
} from "../../store/subscriptionProductEntitySlice";
import {
  unDoCessSubscription,
  unDoDiscounts,
  unDoDiscountsSubscription,
} from "../../../voucher/helpers/VouchersHelper";
import { partyData } from "../../store/LivePartyGetsSlice";
import { SubscriptionProcess } from "../../components/helpers/subscriptionHelpers";
import { planData } from "../../store/subscription_item_store/GetPackagesItemSlice";
import ShortcutKeys from "../../../shared/components/ShortcutKeys";
import {
  AddMember,
  EditMemberById,
  MemberAllData,
  resetMemberStatus,
  selectAllMemberData,
} from "../../store/MemberSlice";

const CreateSubscription = ({ editData }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { accessToken, isLoggedIn } = useSelector((state) => state?.auth);
  const subscriptionPlanItems = useSelector(selectAllSubscriptionPlanItems);
  const terminalNumber = useSelector((state) => state?.auth?.terminalNumber);
  const allMembers = useSelector(selectAllMemberData);
  const selectedBusiness = useSelector(
    (state) => state?.auth?.selectedBusiness
  );
  const tenantId = selectedBusiness?.pivot?.tenant_id;
  const subscription = useSelector((state) => state.createSubscription);
  const { addonShow } = useSelector((state) => state.createSubscription);
  const { status, error, savedEntity } = useSelector(
    (state) => state.createSubscription
  );
  const { member, party } = useSelector((state) => state.createSubscription);
  const createdEntity = useSelector((state) => state.members?.savedEntity);
  const memberStatus = useSelector((state) => state.members?.status);
  const memberState = useSelector((state) => state.members);
  const [cessEnabled, setCessEnabled] = useState(false);
  const [discountEnabled, setDiscountEnabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [packageSelected, setPackageSelected] = useState(false);
  const [formData, setFormData] = useState(null);

  useEffect(() => {
    if (!discountEnabled) {
      dispatch(resetSubscriptionItem());
      Array(1)
        .fill(1)
        .map((el, i) => dispatch(addNew({ type: "plan", index: i + 1 })));
      const iteratedDiscountValue = unDoDiscountsSubscription({
        subscriptionPlanItems,
        discountEnabled,
      });
      dispatch(initEditSubscriptionItems(cloneDeep(iteratedDiscountValue)));
    }
  }, [discountEnabled]);

  useEffect(() => {
    if (!cessEnabled) {
      dispatch(resetSubscriptionItem());
      Array(1)
        .fill(1)
        .map((el, i) => dispatch(addNew({ type: "plan", index: i + 1 })));
      const iteratedDiscountValue = unDoCessSubscription({
        subscriptionPlanItems,
        cessEnabled,
      });
      dispatch(initEditSubscriptionItems(cloneDeep(iteratedDiscountValue)));
    }
  }, [cessEnabled]);

  useEffect(() => {
    {
      Array(1)
        .fill(1)
        .map((el, i) => dispatch(addNew({ type: "plan", index: i + 1 })));
    }
  }, []);

  useEffect(() => {
    const validItems =
      subscriptionPlanItems &&
      filter(subscriptionPlanItems, function (o) {
        return o.product_id;
      });
    dispatch(updateTotals(validItems));
  }, [subscriptionPlanItems]);
  const toggleCess = () => {
    setCessEnabled(!cessEnabled);
  };
  const toggleDiscount = () => {
    setDiscountEnabled(!discountEnabled);
  };
  useEffect(() => {
    if (memberStatus === true && createdEntity) {
      const updatedFormData = { ...formData, member_id: memberState?.memberId };
      const updatedData = SubscriptionProcess(updatedFormData);
      dispatch(
        AddSubscription({
          data: updatedData,
          token: accessToken.access_token,
          tenant_id: tenantId,
          terminalNumber: terminalNumber,
        })
      );
      dispatch(resetMemberStatus());
    }
  }, [memberStatus, createdEntity]);

  useEffect(() => {
    dispatch(
      partyData({ token: accessToken.access_token, tenant_id: tenantId })
    );
  }, []);

  useEffect(() => {
    dispatch(
      getSubscriptionNumber({
        token: accessToken.access_token,
        tenant_id: selectedBusiness?.pivot?.tenant_id,
      })
    );
  }, []);

  useEffect(() => {
    dispatch(
      planData({ token: accessToken.access_token, tenant_id: tenantId })
    );
  }, []);

  useEffect(() => {
    dispatch(
      MemberAllData({ token: accessToken.access_token, tenant_id: tenantId })
    );
  }, []);

  const handleBack = () => {
    dispatch(changeToggleShow(false));
    navigate("/subscription/membership");
  };

  useEffect(() => {
    console.log("sub status", status);
    console.log("savedEntity", savedEntity);
    if (status && savedEntity) {
      toast.success("Subscription created successfully!");
      let tempVoucher = {
        voucher: savedEntity?.subscription_vouchers[0]?.voucher,
        party: savedEntity?.party,
        member: savedEntity?.member,
      };
      dispatch(pushNewSubscription(savedEntity));
     // dispatch(resetCreateSubscription());
      navigate("/subscription/invoice/view/" + tempVoucher?.voucher?.id);
    }
  }, [status, savedEntity]);

  const handleProductSelect = (product) => {
    setPackageSelected(!!product);
  };


  useEffect(() => {
    if (!status && error) {
      toast.error(error);
    }
  }, [status, error]);

  return (
    <>
    <ToastContainer />
    <Formik
      initialValues={subscription}
      enableReinitialize={true}
      validationSchema={Yup.object().shape({
        party_id: Yup.string().required(i18n("Please select party")),
        product_id: Yup.string().required(i18n("Please select package")),
        start_date: Yup.date().required(i18n("Start date is required")),
      })}
      onSubmit={async (values) => {
        try {
          setLoading(true)
          let data = cloneDeep(values);
          const processedFormData = SubscriptionProcess(data);
          setFormData(data);
          const phoneNumbers = allMembers.map((member) => member.mobile);

          if (data?.subscription_items?.length === 0) {
            toast.error("Please Select a Plan");

          } else if (!phoneNumbers.includes(data.party.phone_number)) {
            const partyData = data.party;
            const formDataToAddMember = {
              member_name: partyData.name,
              mobile: partyData.phone_number,
              member_id: memberState?.member_id,
              created_by: selectedBusiness?.pivot?.user_id,
            };

            dispatch(
              AddMember({
                data: formDataToAddMember,
                token: accessToken.access_token,
                tenant_id: tenantId,
                terminalNumber,
              })
            );
          } else {
            setLoading(true);
            dispatch(
              AddSubscription({
                data: processedFormData,
                token: accessToken.access_token,
                tenant_id: tenantId,
                terminalNumber: terminalNumber,
              })
            );
          }

          dispatch(changeToggleShow(false));
        } catch (error) {
          setLoading(false)
          dispatch(changeToggleShow(true));
        }
      }}
    >
      {({
        values,
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue,
        isSubmitting,
        touched,
      }) => (
        <Layout>
          <Header
            ItemActions={
              <Stack
                gap={3}
                direction="horizontal"
                className="mt-2 mb-2 justify-content-end"
              >
                <ShortcutKeys />
                {!loading ? (
                <Button
                  id="submitButton"
                  variant="primary"
                  onClick={handleSubmit}
                  disabled={loading}
                >
                  {i18n("Save")}
                </Button>
              ) : (
                <div className="d-flex align-items-center mx-8">
                  <Spinner animation="border" size="md" />
                </div>
              )}
                <Button
                  className=""
                  variant="outline-secondary"
                  onClick={handleBack}
                >
                  {i18n("Cancel")}
                </Button>
              </Stack>
            }
            currentTitle={i18n("Create Membership")}
          />
          <Container fluid className="p-10 pt-0 px-1">
            <Card className="mx-4">
              <div
                className="border"
                style={{
                  background: "white",
                  height: "90vh",
                  overflow: "auto",
                  position: "relative",
                }}
              >
                <Row>
                  <Col className="px-10" lg={12}>
                    <CustomerDetailsForm
                      handleChange={handleChange}
                      values={values}
                      errors={errors}
                      setFieldValue={setFieldValue}
                      touched={touched}
                    />
                  </Col>
                  <Col className="px-10">
                    <PackageDetailsForm
                      onProductSelect={handleProductSelect}
                      values={values}
                      errors={errors}
                    />
                  </Col>
                  {!packageSelected && (
                    <Col
                      className="py-4 px-10"
                      lg={12}
                      style={{
                        textAlign: "center",
                        position: "absolute",
                        top: "40%",
                        left: "0",
                        right: "0",
                        zIndex: 1,
                      }}
                    >
                      <div style={{ color: "red", fontSize: "15px" }}>
                        Please select a package first.
                      </div>
                    </Col>
                  )}
                  <Col
                    className="py-4 px-10"
                    lg={12}
                    style={{
                      filter: packageSelected ? "none" : "blur(6px)",
                      pointerEvents: packageSelected ? "auto" : "none",
                      userSelect: "none",
                    }}
                  >
                    <ItemDetailsForm
                      handleChange={handleChange}
                      values={values}
                      errors={errors}
                      setFieldValue={setFieldValue}
                      touched={touched}
                      discountEnabled={discountEnabled}
                      toggleDiscount={toggleDiscount}
                      cessEnabled={cessEnabled}
                      toggleCess={toggleCess}
                    />
                  </Col>
                  <Col
                    className="py-8 px-10"
                    lg={12}
                    style={{
                      filter: packageSelected ? "none" : "blur(6px)",
                      pointerEvents: packageSelected ? "auto" : "none",
                      userSelect: "none",
                    }}
                  >
                    <PaymentSectionForm
                      handleChange={handleChange}
                      values={values}
                      errors={errors}
                      setFieldValue={setFieldValue}
                      touched={touched}
                    />
                  </Col>
                </Row>
              </div>
            </Card>
          </Container>
        </Layout>
      )}
    </Formik>
    </>
  );
};

export default CreateSubscription;
