import {
    createSlice,
    createAsyncThunk,
  } from "@reduxjs/toolkit";
  import { i18n } from "../../shared/helpers/sharedMethods";
  import { axiosRequest } from "../../shared/helpers/axiosHelper";

  const initialState = {
    status:false,
    reportItems:[],
    error:false,
    errorMessage:'',
    loading:false,
  };

export const fetchStockSummary = createAsyncThunk(
    "stockSummary/fetch",
    async ({ token, tenant_id }) => {
      try{
      const stockSummaryResponse = await axiosRequest({
        method: "GET",
        url: `get-stock-summary-report`,
        headers: { Authorization: `Bearer ${token}`, "X-Tenant": tenant_id },
      });
      return stockSummaryResponse.data;
    }catch(err){
      await window.log.logToFile({message: "Error while fetching Stock Summary", level: "error" });
      throw new Error("Error while fetching Stock summary report")
    }
    }
  );

const stockSummarySlice = createSlice({
    name: "stockSummary",
    initialState,
    reducers: {},
    extraReducers(builder) {
      builder
      .addCase(fetchStockSummary.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchStockSummary.fulfilled, (state, action) => {
        state.status = true;
        state.loading = false;
        state.reportItems = action.payload;
      })
      .addCase(fetchStockSummary.rejected, (state, action) => {
          state.error = true; 
          state.loading = false;
          state.errorMessage= (i18n("Error while fetching Stock summary report. Please try again later."));
          state.status = false;
      })
    },
});

export default stockSummarySlice.reducer;
