import { Form } from "react-bootstrap";
import { updateItemField } from "../../store/voucherItemsSlice";
import { useDispatch } from "react-redux";
import { placeholderText } from '../../../shared/helpers/sharedMethods';

const ItemTypeSelect = ({index,item,is_same_state}) => {
    const dispatch = useDispatch();

    return (
        <Form.Select aria-label="Item Type" className="form-control" onChange={(e) => {
            let data = {
              fieldName: <span style={{ color :"#4682B4"}}>type</span>,
              fieldValue: e.target.value,
              index,
              is_same_state
            }
            dispatch(updateItemField(data));
          }}>
            <option value="goods" selected={item?.type == 'goods'}>{placeholderText("Goods")}</option>
            <option value="service" selected={item?.type == 'service'}>{placeholderText("Service")}</option>
        </Form.Select>
    );
};

export default ItemTypeSelect;