import { Form } from "react-bootstrap";
import { updateItemField } from "../../store/voucherItemsSlice";
import { useDispatch } from "react-redux";
import { formatAmount } from "../../../pos/helpers/cartHelper";

const ItemPrice = ({ index, item, is_same_state }) => {
  ;
  const dispatch = useDispatch();
  let per_item_taxable =
    item?.taxable_value && item?.quantity && item?.is_tax_included
      ? (item?.taxable_value - item?.cess) / item?.quantity
      : item?.taxable_value / item?.quantity;
  ;
  
  return (
    <Form.Control
      type="number"
      name="item_price"
      placeholder="0.00"
      className="text-end"
      min="0"
      value={per_item_taxable ? formatAmount(parseFloat(per_item_taxable)) : ""}
      onChange={(e) => {
        let data = {
          fieldName: "item_price",
          fieldValue: formatAmount(parseFloat(e.target.value)),
          index,
        };
        dispatch(updateItemField(data));

        let isTaxIncluded = {
          fieldName: "is_tax_included",
          fieldValue: false,
          index,
          is_same_state
        };
        dispatch(updateItemField(isTaxIncluded));
      }}
    />
  );
};

export default ItemPrice;
