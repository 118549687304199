import React, { useState } from "react";
import { Container, Row, Stack, Button, Col } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router";

const ReportHeader = ({ ItemActions }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  return (
    <>
      <Stack direction="horizontal" className="">
        {ItemActions}
      </Stack>
    </>
  );
};

export default ReportHeader;
