import React from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDollyFlatbed,
} from "@fortawesome/free-solid-svg-icons";
import { Card } from "react-bootstrap";
import { i18n } from "../../shared/helpers/sharedMethods";

const ItemReportList = () => {
    const navigate = useNavigate();

    const handleRateList = () => {
        navigate("/reports/rateList");
      };

      const handleItemSale = () => {
        navigate("/reports/itemSaleAndPurchase");
      };

      const handleStockSummary = () => {
        navigate("/reports/stockSummary");
      };
      const handleStockDetail = () => {
        navigate("/reports/stockDetail");
      };
      const handleItemReportByParty = () => {
        navigate("/reports/itemReportByParty");
      };

    return(
    <>
        <span
              className="section-headings p-2 d-flex flex-direction-row px-4 mt-1"
              style={{ borderBottom: "1px solid lightgray" }}
            >
              <FontAwesomeIcon className="px-1 fs-4" icon={faDollyFlatbed} />{" "}
              <h4>Item</h4>
            </span>
            <Card.Body className=" py-5">
             
              <h6
                className="pt-5 fs-4"
                onClick={handleItemReportByParty}
                style={{ cursor: "pointer" }}
              >
              {i18n("Item Report By Party")}
              </h6>
              <h6
                className="pt-5 fs-4"
                onClick={handleItemSale}
                style={{ cursor: "pointer" }}
              >
              {i18n("Item Sales and Purchase Summary")}
              </h6>
              <h6
                className="pt-5 fs-4"
                onClick={handleRateList}
                style={{ cursor: "pointer" }}
              >
              {i18n("Rate List")}
              </h6>
              <h6
                className="pt-5 fs-4"
                onClick={handleStockDetail}
                style={{ cursor: "pointer" }}
              >
                {i18n("Stock Detail Report")}
              </h6>
              <h6
                className="pt-5 fs-4"
                onClick={handleStockSummary}
                style={{ cursor: "pointer" }}
              >
                {i18n("Stock Summary")}
              </h6>
            </Card.Body>

    </>
    );

};

export default ItemReportList;