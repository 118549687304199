import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../../voucher/assets/css/sale.css";
import { useDispatch, useSelector } from "react-redux";
import { addNew, resetVoucherItem } from "../store/voucherItemsSlice";
import { faBan } from "@fortawesome/free-solid-svg-icons";
import { Button } from "react-bootstrap";
import { i18n } from "../../shared/helpers/sharedMethods";
import GlobalCurrency from "../../shared/components/GlobalCurrency";
import TaxSummary from "./fields/TaxSummary";
import { useEffect, useState } from "react";

const CartItemTotals = ({ noOfItems, cessEnabled, discountEnabled, is_same_state }) => {
    const dispatch = useDispatch();

    const {
        tax_total,
        total_quantity,
        subtotal,
        cess_total,
        grand_total,
        taxable_value,
        total_discount,
        items,
    } = useSelector((state) => state.voucherEntity);



    const currencySymbol = useSelector(
        (state) => state.getSetting?.dataSetting?.currency
    );
    ;

    let fontSize;
    if (cessEnabled && discountEnabled) {
        fontSize = '12px'; 
    } else if (cessEnabled || discountEnabled) {
        fontSize = '14px';
    } else {
        fontSize = '16px'; 
    }
    return (
        <>
        <tfoot className="text-end fs-4" style={{ borderTop: "1px solid #ddd" }}>
            <tr>
                <th colSpan={5}>
                    <Button
                        variant="link p-2 float-center"
                        size="md"
                        onClick={() => {
                            dispatch(addNew(noOfItems + 1));
                        }}
                    >
                        {i18n("+ New Line Item")}
                    </Button>
                </th>
                <th colSpan={3}>
                    <text className="float-end bold" style={{ fontSize, fontWeight: 'bold' }}>{i18n("Totals")}</text>
                </th>
                <th colSpan={2}>
                    <text className="float-end" style={{ fontSize, fontWeight: 'bold' }}>{total_quantity}</text>
                </th>
                <th>

                </th>
                {discountEnabled && (
                    <th colSpan={2}>
                    <text style={{ fontSize, fontWeight: 'bold' }}>
                    <GlobalCurrency price ={total_discount} />
                        </text>
                    </th>
                )}

                <th colSpan={1}>
                        <text style={{ fontSize, fontWeight: 'bold' }}>
                            <GlobalCurrency price={tax_total} />
                        </text>
                </th>

                {cessEnabled && (
                    <>
                        <th colSpan={2}>
                        <text style={{ fontSize, fontWeight: 'bold' }}>
                        <GlobalCurrency price ={cess_total} />
                            </text>
                        </th>{" "}
                    </>
                )}
                <th>
                <text style={{ fontSize, fontWeight: 'bold' }}>
                <GlobalCurrency price ={grand_total} />
                    </text>
                </th>
            </tr>
        </tfoot>
        </>
    );
};
export default CartItemTotals;
