import { Col, Form, FormControl, InputGroup, Row } from "react-bootstrap";
import { ErrorMessage } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { changeSalePrice } from "../../../store/subscription_item_store/SetupFeesEntitySlice";
import { i18n,placeholderText} from "../../../../shared/helpers/sharedMethods";
import { changePlanIsTaxInclude, changePlanSalePrice } from "../../../store/subscription_item_store/PlanEntitySlice";
import { formatAmount } from "../../../../pos/helpers/cartHelper";

const PlanPrice = ({ values, handleChange, touched, errors }) => {
    const dispatch = useDispatch()
    const currency = useSelector((state) => state.getSetting?.dataSetting?.currency);

  return (
    <Row className="py-4">
        <Col lg={3}>
          <Form.Group>
            <Form.Label className="form-text-font">{i18n("Price")} </Form.Label>
            <span className="required" />
          </Form.Group>
        </Col>
        <Col lg={4}>
          <InputGroup>
            <InputGroup.Text>{currency}</InputGroup.Text>
            <FormControl
              type="number"
              name="sale_price"
              value={formatAmount(parseFloat(values.sale_price) || 0)}
              onChange={(e) => {
                handleChange(e);
                dispatch(changePlanSalePrice(e.target.value));
              }}
              className={` ${
                touched.sale_price && errors.sale_price ? "is-invalid" : ""
              }`}
            />
            <Form.Select
             value={values.is_tax_included||1}
              aria-label="is_tax_included"
              name="is_tax_included"
              onChange={(e) => {
                handleChange(e);
                dispatch(changePlanIsTaxInclude(e.target.value));
                ;
              }}
            >
              <option value={1}>{placeholderText("With Tax")}</option>
              <option value={0}>{placeholderText("Without Tax")}</option>
            </Form.Select>
          </InputGroup>

          <ErrorMessage
            name="sale_price"
            component="span"
            className="text-danger d-block fw-400 fs-small mt-2"
          />
        </Col>
      </Row>
  );
};

export default PlanPrice;