import { Form } from "react-bootstrap";
import React from "react";
import { placeholderText } from "../helpers/sharedMethods";


const SummaryReportPeriod = (props) => {
   
    const {reportPeriod,setReportPeriod} = props;
    return (
        <Form.Select
        className="fs-6 p-2"
        value={reportPeriod}
        onChange={(e) => setReportPeriod(e.target.value)}
        style={{ cursor: "pointer" }}
      >
              
               <option value="current-week">{placeholderText("This Week")}</option>
                <option value="previous-week">{placeholderText("Last Week")}</option>
                <option value="current-month">{placeholderText("This Month")}</option>
                <option value="previous-month">{placeholderText("Last Month")}</option>

      </Form.Select>
    )

}

export default SummaryReportPeriod;