import {
    createSlice,
    createAsyncThunk,
  } from "@reduxjs/toolkit";
  import { axiosRequest } from "../../shared/helpers/axiosHelper";


const initialState = {
  status:false,
  reportItems:[],
  error:false,
  errorMessage:'',
  loading:false,
};

export const fetchGSTR1SalesReport = createAsyncThunk(
  "gstr1SalesReport/fetch",
  async ({ token, tenant_id, startDate, endDate, reportType }) => {
    try {
      const response = await axiosRequest({
        method: "GET",
        url: `get-gstr-1-sales-report`,
        params: { startDate, endDate, reportType },
        headers: { Authorization: `Bearer ${token}`, "X-Tenant": tenant_id },
      });
      return response.data;
    } catch (err) {
      await window.log.logToFile({ message: "Error while fetching GSTR1 sales report", level: "error" });
      throw new Error("Error while fetching gstr-1 sales report");
    }
  }
);

const gstr1SalesReportSlice = createSlice({
    name: "gstr1SalesReport",
    initialState,
    reducers: {},
    extraReducers(builder) {
      builder
      .addCase(fetchGSTR1SalesReport.pending, (state) => {
        state.loading=true;
      })
      .addCase(fetchGSTR1SalesReport.fulfilled, (state, action) => {
        state.status = true;
        state.loading = false;
        state.reportItems = action.payload;
      })
      .addCase(fetchGSTR1SalesReport.rejected, (state, action) => {
          state.error = true;
          state.loading = false; 
          state.errorMessage = action.error.message
          state.status = false;
      })
    },
});

export default gstr1SalesReportSlice.reducer;
