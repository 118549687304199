import React, { useState, useEffect } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Chart from "./Chart";
import { fetchHourlySalesChart } from "../../../store/sales_chart/hourlySalesChartSlice";
import DatePicker from "react-datepicker";

function HourlySalesChart() {
  const dispatch = useDispatch();
 
  const [reportPeriod, setReportPeriod] = useState(new Date());
  useEffect(() => {
   
      dispatch(fetchHourlySalesChart(reportPeriod));
  }, [reportPeriod]);
  const reportItems = useSelector((state) => state.hourlyChart.reportItems);
  ;
  return (
    <Card id="chart" className="border mb-3">
      <Card.Header className="border-0 pt-0"></Card.Header>
      <Card.Body className="py-0">
        <Row className="row px-1">
          <Col xl={9} md={9}>
            <Chart 
             reportItems= {reportItems} 
             reportPeriod={reportPeriod} 
            />
          </Col>
          <Col xl={3} md={3} className="d-flex justify-content-end">
            <DatePicker
              showIcon
              selected={reportPeriod}
              onChange={(date) => setReportPeriod(date)}
              icon="fa fa-calendar"
            />
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
}

export default HourlySalesChart;
