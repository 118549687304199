import { faBoxes, faCubes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ProductHeader from "../components/ProductHeader";
import Layout from "../../shared/components/Layout";
import { fetchTotalQuantity, selectAllProducts } from "../store/productsSlice";
import { Card, Col, Container, Form, Row } from "react-bootstrap";
import ProductSummaryChartIndex from "../components/chart/product_summary/ProductSummaryChartIndex";
import ProductCategoryFliterChartIndex from "../components/chart/product_category_filter_chart/ProductCategoryFliterChartIndex";
import Header from "../../shared/components/Header";
import { i18n } from "../../shared/helpers/sharedMethods";

const ProductOverview = () => {
  const dispatch = useDispatch();
  const allProducts = useSelector(selectAllProducts);
  useEffect(() => {
    dispatch(fetchTotalQuantity());
  }, []);
  const total = useSelector((state) => state.products.totalQuantityData);
  ;
  const quantity = total?.totalQuantity;

  return (
    <Layout currentTitle={i18n("Overview")}>
      <Header currentTitle={i18n("Overview")} />

      <Container fluid className="px-5">
      <div className="border" style={{ background : "white" }}>
        <ProductHeader />
        <Row className="mt-4 px-4">
          <Col lg={4}>
            <Row className="p-5 mx-1 border rounded-1 text-info">
              <Col lg={10}>
                <FontAwesomeIcon className="mx-2 fs-2" icon={faCubes} />
               {i18n("All stock")}
              </Col>
              <Col md={2} className="text-black">{quantity ? quantity : "0"}</Col>
            </Row>
          </Col>
          <Col lg={4}>
            <Row className="border rounded-1 p-5 mx-1 text-collect">
              <Col lg={10}>
                <FontAwesomeIcon className="mx-2 fs-2" icon={faCubes} />
              {i18n("In stock")}
              </Col>
              <Col md={2} className="text-black">{quantity ? quantity : "0"}</Col>
            </Row>
          </Col>
          <Col lg={4}>
            <Row className="border rounded-1 p-5 mx-1 text-pay">
              <Col lg={10}>
                <FontAwesomeIcon className="mx-2 fs-2" icon={faCubes} />
              {i18n("Out of stock")}
              </Col>
              <Col md={2} className="text-black">0</Col>
            </Row>
          </Col>
        </Row>
        <Row className="px-4 mb-2 mt-4">
          <Col xl={8} md={8} sm={8}>
            <ProductSummaryChartIndex />
          </Col>
          <Col xl={4} md={4} sm={4}>
            <ProductCategoryFliterChartIndex />
          </Col>
        </Row>
        </div>
      </Container>
    </Layout>
  );
};

export default ProductOverview;
