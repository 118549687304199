import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  ListGroup,
  FormControl,
  Row,
  Form,
  Col,
  InputGroup,
  Button,
  Tooltip,
  OverlayTrigger,
} from "react-bootstrap";
import { useParams } from "react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faChevronLeft,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import { i18n,placeholderText } from "../../../helpers/sharedMethods";

const ItemStack = ({
  items,
  handleItemClick,
  currentItemId,
  handleCreate,
  returnToGrid,
}) => {
  const [searchString, setSearchString] = useState("");
  const [filteredItems, setFilteredItems] = useState();
  useEffect(() => {
    if(items){
      setFilteredItems(items);
    }
      }, [items]);
  const handleSearch = (searchParam) => {
    const searchLowercase = searchParam.toLowerCase();
    setSearchString(searchParam);
    const filtered = items.filter(
      (datum) =>
        datum.name.toLowerCase().includes(searchLowercase) ||
        datum.code.toLowerCase().includes(searchLowercase)
    );
    setFilteredItems(filtered);
  };
  const currencySymbol  = useSelector((state) => state.getSetting?.dataSetting.currency);
  ;

  const Back = <Tooltip id="button-tooltip">{i18n("Back")}</Tooltip>;
  const Search = <Tooltip id="button-tooltip">{i18n("Search")}</Tooltip>;
  const Create = <Tooltip id="button-tooltip">{i18n("Create")}</Tooltip>;

  return (
    <>
      <Row className="">
        <InputGroup size="">
          
          <OverlayTrigger placement="bottom" overlay={Search}>
            <Form.Control
              size=""
              type="text"
              id="search"
              name="search"
              placeholder={placeholderText("Search by Name")}
              onChange={(e) => {
                handleSearch(e.target.value);
              }}
              value={searchString}
              className=""
              style={{ borderTop: "none", borderBottom: "none",borderLeft:'none' }}
            />
          </OverlayTrigger>
          <OverlayTrigger placement="bottom" overlay={Create}>
            <Button
              size="sm"
              className=""
              variant="outline-primary"
              style={{ border: "none" }}
              onClick={handleCreate}
            >
              <FontAwesomeIcon className="mb-1" icon={faPlus} />{i18n("Create")}
            </Button>
          </OverlayTrigger>
        </InputGroup>
      </Row>

      <ListGroup
        className="px-3"
        style={{
          height: "85vh",
          overflow: "auto",
          gap: "3px",
          overflowX: "hidden",
          borderTop: "1px solid lightgray",
        }}
      >
        {filteredItems &&
          filteredItems.map((item, index) => {
            // ;
            // ;
            return (
              <>
                <ListGroup.Item
                  className="mt-2 voucherlist cursor-pointer"
                  active={item.id == currentItemId}
                  onClick={() => {
                    handleItemClick(item.id);
                  }}
                >
                  <Row className="">
                    <Col>
                      <Form.Group>
                        <Form.Label className="" style={{color:'black'}}>
                          {item.name ? item.name : "-"}
                        </Form.Label>
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row className="">
                    <Col className="">
                      <Form.Group>
                        <Form.Text className="">
                          {i18n("Stock Value")}: {currencySymbol}{" "}
                          {item.inventories[0]?.quantity == 0
                            ? "-"
                            : item.cost_price
                            ? item.inventories[0]?.quantity * item.cost_price
                            : " 0"}
                        </Form.Text>
                      </Form.Group>
                    </Col>
                    <Col className="" style={{ textAlign: "end" }}>
                      <Form.Group>
                        <Form.Text className="text-value">
                          {" "}
                          {item?.inventories && item.inventories[0]?.quantity
                            ? item.inventories[0]?.quantity
                            : "0"}{" "}
                          {item.unit ? item.unit : "PCS"}
                        </Form.Text>
                      </Form.Group>
                    </Col>
                  </Row>
                </ListGroup.Item>
              </>
            );
          })}
      </ListGroup>
    </>
  );
};

export default ItemStack;
