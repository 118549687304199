import { Form } from "react-bootstrap";
import React from "react";
import { useSelector } from "react-redux";
import { selectAllProducts } from "../../../catalog/store/productsSlice";
import { placeholderText } from "../../../shared/helpers/sharedMethods";

const ProductFilter = (props) => {
  const { productData, setProductData, title } = props;

  const filteredProducts = useSelector(selectAllProducts);

  return (
    <>
      {title ? (
        <Form.Select
          className="rounded-0"
          value={productData}
          onChange={(e) => setProductData(e.target.value)}
          style={{ cursor: "pointer" }}
        >
          <option value="" disabled selected hidden>
            {placeholderText("Select Product")}
          </option>
          {filteredProducts.length > 0 ? (
            filteredProducts.map((survey) => (
              <option key={survey.name} value={survey.id}>
                {survey.name}
              </option>
            ))
          ) : (
            <option disabled>{placeholderText("No product available")}</option>
          )}
        </Form.Select>
      ) : (
        <Form.Select
          className="rounded-0"
          value={productData}
          onChange={(e) => setProductData(e.target.value)}
          style={{ cursor: "pointer" }}
        >
          <option value="" disabled selected hidden>
            {placeholderText("Select Product")}
          </option>
          <option value="">{placeholderText("All Products")}</option>
          {filteredProducts.length > 0 ? (
            filteredProducts.map((survey) => (
              <option key={survey.name} value={survey.id}>
                {survey.name}
              </option>
            ))
          ) : (
            <option disabled>{placeholderText("No product available.")}</option>
          )}
        </Form.Select>
      )}
    </>
  );
};

export default ProductFilter;
