import { Field } from "formik";
import { Col, Form, Row } from "react-bootstrap";
import { i18n } from "../../../../shared/helpers/sharedMethods";
import { changeAddonIntervalUnit } from "../../../store/subscription_item_store/AddonEntitySlice";
import { useDispatch } from "react-redux";

const AddonInterval = ({ values, handleChange, touched, errors,setFieldValue }) => {
const dispatch = useDispatch()
  const PricingIntervals = [
    {
      value: "week",
      label: "Week(s)",
    },
    {
      value: "month",
      label: "Month(s)",
    },
    {
      value: "year",
      label: "Year(s)",
    },
  ];
  return (
    <>
      {/* {addOnType == "recurring" && ( */}
        <Row className="py-4">
          <Col lg={3} className="">
            <Form.Group>
              <Form.Label> {i18n("Pricing Interval")} </Form.Label>
              <span className="required" />
            </Form.Group>
          </Col>
          <Col lg={4} className="">
            <Form.Group as={Col} md={11}>
              <Field name="interval_unit">
                {({ field, form }) => (
                  <Form.Select
                    {...field}
                    onChange={(e) => {
                      const value = e.target.value;
                      form.setFieldValue("interval_unit", value ? value : "");
                      dispatch(changeAddonIntervalUnit(value));
                    }}
                    value={values.interval_unit}
                  >
                    {PricingIntervals.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </Form.Select>
                )}
              </Field>
            </Form.Group>
          </Col>
          <Col lg={5} className="text-color">
            {i18n(
              " Addons are charged based on the subscribed plan's billing frequency."
            )}
          </Col>
        </Row>
      {/* )} */}
    </>
  );
};

export default AddonInterval;
