import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
} from "@reduxjs/toolkit";
import {
  prepareAndProcessCartItem,
  prepareCartShellItem,
  processCartItem,
} from "../../pos/helpers/cartHelper";

const voucherItemsAdapter = createEntityAdapter();

const initialState = voucherItemsAdapter.getInitialState({
  status: "idle",
  error: null,
});

export const addNew = createAsyncThunk("voucherItems/addNew", async (index) => {
  return prepareCartShellItem(index);
});

const voucherItemsSlice = createSlice({
  name: "voucherItems",
  initialState,
  reducers: {
    quantityUpdated(state, action) {
      const { cartItemId, quantity } = action.payload;

      const existingItem = state.entities[cartItemId];

      if (existingItem && existingItem.quantity != quantity) {
        existingItem.quantity = quantity;
        state.entities[cartItemId] = processCartItem(existingItem);
      }
    },
    setProductForItem(state, action) {
      ;
      const { index, item, is_same_state } = action.payload;
      state.entities[index] = item?.customOption
        ? prepareAndProcessCartItem(item)
        : processCartItem(item,"",is_same_state);
    },
    unsetProductForItem(state, action) {
      let index = action.payload;
      state.entities[index] = prepareCartShellItem(index);
    },
    updateItemField(state, action) {
      
      const { index, fieldName, fieldValue, discountEnabled, is_same_state} = action.payload;
      const item = state.entities[index];
      item[fieldName] = fieldValue;
      state.entities[index] = processCartItem(item, discountEnabled, is_same_state);
    },
    updateTaxSummary(state, action) {
      const { items, is_same_state } = action.payload;
      const voucherItems = items;
      
      if (voucherItems) {
        voucherItems && voucherItems.map((element,index) => {
          const id = index + 1;
         state.entities[id] = processCartItem(element,"", is_same_state);
        });
      }
    },      
    updateVoucherItem: voucherItemsAdapter.addMany,
    initEditVoucherItems(state, action) {
      let items = action.payload;
      ;
      if (items && Array.isArray(items) && items.length > 0) {
        items.map((element, index) => {
          element.id = index + 1;
          voucherItemsAdapter.addOne(state, element);
        });
      }
    },
    deleteVoucherItem: voucherItemsAdapter.removeOne,
    resetVoucherItem: () => initialState,
  },
  extraReducers(builder) {
    builder.addCase(addNew.fulfilled, (state, action) => {
      voucherItemsAdapter.addOne(state, action.payload);
    });
  },
});

export default voucherItemsSlice.reducer;

export const {
  quantityUpdated,
  deleteVoucherItem,
  resetVoucherItem,
  updateVoucherItem,
  updateTaxSummary,
  setProductForItem,
  unsetProductForItem,
  updateItemField,
  initEditVoucherItems,
} = voucherItemsSlice.actions;

export const {
  selectAll: selectAllVoucherItems,
  selectById: selectVoucherItemById,
  selectIds: selectVoucherItemIds,
} = voucherItemsAdapter.getSelectors((state) => state.voucherItems);


