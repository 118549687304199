import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Modal, Row, Form, Button, Col, Alert, Stack, Spinner } from "react-bootstrap";
import {
  placeholderText,
  i18n,
} from "../../shared/helpers/sharedMethods";
import { pushNewCategory } from "../store/categorysSlice";
import { addCategory, resetCreateCategory, resetCategoryStatus, undoCategoryError } from "../store/categoryEntitySlice";
//import { toast, ToastContainer } from "react-toastify";
//import "react-toastify/dist/ReactToastify.css";
import { changeType, changeName } from "../store/categoryEntitySlice";
import { cloneDeep } from "lodash";
import { faCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { selectAllCategories } from "../../settings/store/categorysSlice";

const CreateCategory = (props) => {

  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { handleClose, show, type, setShow, hideTypeDropdown, caller } = props; // Add caller

  const [errors, setErrors] = useState({
    name: "",
  });

  const selectedBusiness = useSelector(
    (state) => state?.auth?.selectedBusiness
  );

  const getCategory = useSelector((state) => state.categoryEntity.category);
  const { name } = useSelector((state) => state.categoryEntity.category);
  const { categoryType } = useSelector(
    (state) => state.categoryEntity.category
  );
  const allCategories = useSelector(selectAllCategories);
  const { errorStatus, error } = useSelector((state) => state?.categoryEntity);
  const categoryStatus = useSelector(
    (state) => state.categoryEntity.status
  );
  const savedEntity = useSelector(
    (state) => state.categoryEntity.savedEntity
  );
  const { accessToken } = useSelector((state) => state?.auth);
  const tenantId = selectedBusiness?.pivot?.tenant_id;
  const { terminalId } = useSelector((state) => state.initInstall);


  useEffect(() => {
    if (errorStatus) {
      setLoading(false)
    }
  }, [errorStatus])

  useEffect(() => {
    if (categoryStatus) {
      dispatch(pushNewCategory(savedEntity));
      dispatch(resetCategoryStatus());
      handleClose();
    }
  }, [categoryStatus]);

  useEffect(() => {
    dispatch(resetCreateCategory());
  }, [dispatch]);

  const handleAlertClose = () => {
    setLoading(false)
    dispatch(undoCategoryError());
  };

  const typeOptions = [
    { value: "product", label: "Product" },
    { value: "party", label: "Party" },
  ];

  const onTypeChange = (e) => {
    e.preventDefault();
    dispatch(changeType(e.target.value));
  };

  const onChangeInput = (e) => {
    e.preventDefault();
    dispatch(changeName(e.target.value));
  };

  const handleValidation = () => {
    let errorss = {};
    let isValid = false;

    if (!name) {
      errorss["name"] = i18n("Please Enter Category Name");
    } else {
      isValid = true;
    }

    setErrors(errorss);
    return isValid;
  };

  const onSubmit = () => {
    const valid = handleValidation();
    if (valid) {
      setLoading(true);
      let formData = cloneDeep(getCategory);
      if (type) {
        formData.type = type;
      }
      formData.created_by = selectedBusiness?.pivot?.user_id;
      formData.updated_by = selectedBusiness?.pivot?.user_id;
      delete formData.id;
      dispatch(addCategory({data:formData,token: accessToken.access_token,
        tenant_id: tenantId, terminal_id:terminalId }));
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      onSubmit();
    }
  };

  const clearField = () => {
    handleClose();
  };

  const renderMessageBasedOnCaller = () => {
    if (caller === "part1") {
      return i18n("Category Type     : Party");
    } else if (caller === "part2") {
      return i18n("Category Type     : Product");
    }
  };

  return (
    <>
      <Modal
        show={show}
        dialogClassName="modal-dialog modal-dialog-centered mw-700px"
        style={{ zIndex: 2500 }}
        onHide={handleClose}
        keyboard={true}
      >
        <div className="" style={{ borderRadius: "5px", boxShadow: "0 0 500px 500px rgba(0, 0, 0, 0.3)" }}>
          <Modal.Header className="" closeButton>
            <Modal.Title>{i18n("Create New Category")}</Modal.Title>
          </Modal.Header>

          {errorStatus && (
            <Stack
              className="pt-3"
              direction="horizontial"
              style={{ paddingLeft: "30px", width: "90%" }}
            >
              <Alert
                show={errorStatus}
                onClose={handleAlertClose}
                dismissible
                size="md"
                variant="danger"
              >
                <Alert.Heading className="fw-bolder text-black">Errors</Alert.Heading>
                <Stack
                  gap={1}
                  className="d-flex justify-content-start"
                  direction="horizontal"
                >
                  <FontAwesomeIcon
                    icon={faCircle}
                    className="px-1"
                    style={{ fontSize: "7px" }}
                  />
                  {error ? error : ''}
                </Stack>
              </Alert>
            </Stack>
          )}

          <Modal.Body>
            <Row className="row">
              {!hideTypeDropdown ? (
                <Form.Group className="col-md-12 mb-2">
                  <Form.Label className="form-label-item mb-2">
                    {i18n("Category Type")}
                  </Form.Label>
                  <Form.Select
                    aria-label="type"
                    name="type"
                    value={type ? type : categoryType?.type}
                    onChange={onTypeChange}
                  >
                    {typeOptions && typeOptions.length ? (
                      typeOptions.map((term, index) => {
                        return <option key={index} value={term.value}>{term.label}</option>;
                      })
                    ) : (
                      <option value="">{placeholderText("None")}</option>
                    )}
                  </Form.Select>
                </Form.Group>
              ) : (
                <div className="col-md-12 mb-2">
                  <p>{renderMessageBasedOnCaller()}</p>
                </div>
              )}

              <Form className="col-md-12 mb-2">
                <Form.Label className="form-label">
                  {i18n("Category Name")}:{" "}
                </Form.Label>
                <span className="required" />
                <Form.Control
                  autoFocus
                  type="text"
                  name="name"
                  autoComplete="off"
                  placeholder={placeholderText("Enter Category Name")}
                  className="form-control"
                  onChange={onChangeInput}
                  value={name}
                  onKeyDown={handleKeyDown}
                />
                <span className="text-danger d-block fw-400 fs-small mt-2">
                  {errors["name"] ? errors["name"] : null}
                </span>
              </Form>
            </Row>
          </Modal.Body>

          <Modal.Footer style={{ borderTop: '1px solid lightgray' }}>
            <Row className="py-1">
              <Col className="d-flex justify-content-end">
              {!loading ? (
                        <Button
                          id="submitButton"
                          variant="primary mx-2"
                          onClick={onSubmit}
                          disabled={loading}
                        >
                          {i18n("Save")}
                        </Button>
                      ) : (
                        <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          marginRight: "25%",
                        }}
                      >
                        <Spinner animation="border" size="md" />
                      </div>
                      )}
                <Button variant="outline-secondary mx-2" onClick={clearField}>
                  {i18n("Cancel")}
                </Button>
              </Col>
            </Row>
          </Modal.Footer>
        </div>
      </Modal>
    </>
  );
};

export default CreateCategory;
