import { combineReducers } from "redux";
import productsReducer from "./modules/catalog/store/productsSlice";
import cartItemsReducer from "./modules/pos/store/cartItemsSlice";
import posOrderReducer from "./modules/pos/store/posOrderSlice";
import voucherSlice from "./modules/voucher/store/voucherSlice";
import categoryReducer from "./modules/settings/store/categorysSlice";
import paymentReducer from "./modules/payment/store/paymentSlice";
import settingReducer from "./modules/settings/store/settingSlice";
import stockReportReducer from "./modules/catalog/store/stockReportSlice";
import partyDataReducer from "./modules/catalog/store/partyWiseReportSlice";
import itemReportDataReducer from "./modules/party/store/itemReportSlice";
import registerSlice from "./modules/auth/store/authSlice";
import partyGetSlice from "./modules/subscription/store/LivePartyGetsSlice";
import subscriptionReducer from "./modules/subscription/store/SubscriptionSlice";
//import PaymentInitialStateSlice from "./modules/payment/store/paymentEntitySlice";
import initInstallSlice from "./modules/auth/store/initInstallSlice";
import getPartySlice from "./modules/party/store/getPartySlice";
import voucherEntitySlice from "./modules/voucher/store/voucherEntitySlice";
import voucherItemsSlice from "./modules/voucher/store/voucherItemsSlice";
import additionalChargesSlice from "./modules/voucher/store/additionalChargesSlice";
import categoryEntitySlice from "./modules/settings/store/categoryEntitySlice";
import billWiseReportSlice from "./modules/reports/store/billWiseReportSlice";
import dayBookReportSlice from "./modules/reports/store/dayBookReportSlice";
import itemReportByPartySlice from "./modules/reports/store/itemReportByPartySlice";
import itemSaleAndPurchaseReportSlice from "./modules/reports/store/itemSaleAndPurchaseReportSlice";
import partyReportByItemSlice from "./modules/reports/store/partyReportByItemSlice";
import partyWiseOutstandingSlice from "./modules/reports/store/partyWiseOutstandingSlice";
import profitAndLossSlice from "./modules/reports/store/profitAndLossSlice";
import purchaseSummaryReportSlice from "./modules/reports/store/purchaseSummaryReportSlice";
import rateListReportSlice from "./modules/reports/store/rateListReportSlice";
import SaleSummarySlice from "./modules/reports/store/SaleSummarySlice";
import stockSummarySlice from "./modules/reports/store/stockSummarySlice";
import DailyChartSlice from "./modules/dashboard/store/DailyChartSlice";
import SalesSummaryChartSlice from "./modules/dashboard/store/SalesSummaryChartSlice";
import ProfitAndLossChartSlice from "./modules/dashboard/store/ProfitAndLossChartSlice";
import LatestTransactionSlice from "./modules/dashboard/store/LatestTransactionSlice";
import productEntity from "./modules/catalog/store/productEntitySlice";
import createSubscriptionSlice from "./modules/subscription/store/CreateSubscriptionSlice";
import getProductSlice from "./modules/subscription/store/GetParentProductEntiry";
import PartySummaryChartSlice from "./modules/party/store/PartySummaryChartSlice";
import PartyEntitySlice from "./modules/party/store/PartyEntitySlice";
import PartyProfileInfoSlice from "./modules/party/store/PartyProfileInfoSlice";
import PartyTransactionSlice from "./modules/party/store/PartyTransactionSlice";
import PartyLedgerSlice from "./modules/party/store/PartyLedgerSlice";
import partyDetailsSlice from "./modules/party/store/PartyDetails";
import PartySalesTotalSlice from "./modules/party/store/PartySalesTotalSlice";
import PartySalesChartSlice from "./modules/party/store/PartySalesChartSlice";
import partyLedgerReportSlice from "./modules/reports/store/partyLedgerReportSlice";
import partyAddressSlice from "./modules/party/store/partyAddressSlice";
import partyOverviewSlice from "./modules/party/store/partyOverviewSlice";
import productCategoryFilterChartSlice from "./modules/catalog/store/productCategoryFilterChartSlice";
import productSummaryChartSlice from "./modules/catalog/store/productSummaryChartSlice";
import productTotalSoldQuantitySlice from "./modules/catalog/store/ProductTotalSoldQuantitySlice";
import productTotalSoldQuantityChartSlice from "./modules/catalog/store/ProductTotalSoldQuantityChartSlice";
import inviteUserSlice from "./modules/settings/store/inviteUserSlice";
import averageSalesChartSlice from "./modules/voucher/store/sales_chart/averageSaleChartSlice";
import numberOfSalesChartSlice from "./modules/voucher/store/sales_chart/numberOfSalesChartSlice";
import salesTaxChartSlice from "./modules/voucher/store/sales_chart/salesTaxChartSlice";
import salesOverviewBlocksSlice from "./modules/voucher/store/salesOverviewBlocksSlice";
import numberOfPurchaseChartSlice from "./modules/voucher/store/purchase_chart/numberOfPurchaseChartSlice";
import averagePurchaseChartSlice from "./modules/voucher/store/purchase_chart/averagePurchaseChartSlice";
import topTenPurchasePartySlice from "./modules/voucher/store/purchase_chart/topTenPurchasePartySlice";
import topTenPurchaseItemSlice from "./modules/voucher/store/purchase_chart/topTenPurchaseItemSlice";
import getSettingSlice from "./modules/settings/store/getSettingSlice";
import hourlySalesChartSlice from "./modules/voucher/store/sales_chart/hourlySalesChartSlice";
import paymentEntitySlice from "./modules/payment/store/paymentEntitySlice";
import partyPaymentsSlice from "./modules/payment/store/partyPaymentsSlice";
import gstPurchaseReportSlice from "./modules/reports/store/gstPurchaseReportSlice";
import gstSalesReportSlice from "./modules/reports/store/gstSalesReportSlice";
import hsnWiseSalesSummarySlice from "./modules/reports/store/hsnWiseSalesSummarySlice";
import stockDetailReportSlice from "./modules/reports/store/stockDetailReportSlice";
import gstr2PurchaseReportSlice from "./modules/reports/store/gstr2PurchaseReportSlice";
import gstr1SalesReportSlice from "./modules/reports/store/gstr1SalesReportSlice";
import saleSummaryCategoryWiseSlice from "./modules/reports/store/saleSummaryCategoryWiseSlice";
import partyCategoryFilterChartSlice from "./modules/party/store/partyCategoryFilterChartSlice";
import receivableAgeingReportSlice from "./modules/reports/store/receivableAgeingReportSlice";
import createSubscriptionReceivedAmountSlice from "./modules/subscription/store/CreateSubscriptionReceivedAmount";
import SharedSlice from './modules/shared/store/sharedSlice'
import getAllSubscriptionVoucherSlice from "./modules/subscription/store/getAllSubscriptionVoucherSlice";
import OrderAndRevenueSlice from "./modules/dashboard/store/OrderAndRevenueSlice";
import ProfitBlockSlice from "./modules/dashboard/store/ProfitBlockSlice";
import NewCustomerSlice from "./modules/dashboard/store/NewCustomerSlice";
import RepeatCustomerSlice from "./modules/dashboard/store/RepeatCustomerSlice";
import productChartSlice from "./modules/dashboard/store/productChartSlice";
import productCategoryChartSlice from "./modules/dashboard/store/productCategoryChartSlice";
import TopSellingProductsSlice from "./modules/dashboard/store/TopSellingProductsSlice";
import EnquirySlice from "./modules/enquiry/store/EnquirySlice";
import CreateEnquirySlice from "./modules/enquiry/store/CreateEnquirySlice";
import SubscriptionDashboardSlice from "./modules/dashboard/store/SubscriptionDashboardSlice";
import SubscriptionCancellSlice from "./modules/subscription/store/SubscriptionCancellSlice";
import SubscriptionReactiveSlice from "./modules/subscription/store/SubscriptionReactiveSlice";
import configSlice from "./modules/auth/store/configSlice";
import stockEntitySlice from "./modules/catalog/store/stockEntitySlice";
import manageAccountSlice from "./modules/manageAccount/store/manageAccountSlice";
import subscriptionPlanItemsSlice from "./modules/subscription/store/subscriptionProductEntitySlice"
import globalPlanSlice from "./modules/auth/store/globalPlanSlice";
import AddonEntitySlice from "./modules/subscription/store/subscription_item_store/AddonEntitySlice";
import PlanEntitySlice from "./modules/subscription/store/subscription_item_store/PlanEntitySlice";
import CreatePackageSlice from "./modules/subscription/store/subscription_item_store/CreatePackageSlice";
import PackageEntitySlice from "./modules/subscription/store/subscription_item_store/PackageEntitySlice";
import GetPackagesItemSlice from "./modules/subscription/store/subscription_item_store/GetPackagesItemSlice";
import SetupFeesEntitySlice from "./modules/subscription/store/subscription_item_store/SetupFeesEntitySlice";
import subscriptionAttendanceSlice from "./modules/subscription/store/SubscriptionAttendanceSlice";
import partyDeletedSlice from './modules/party/store/partyDeletedSlice';
import productDeletedSlice from "./modules/catalog/store/productDeletedSlice";
import voucherDeletedSlice from "./modules/voucher/store/voucherDeletedSlice";
import MemberSlice from "./modules/subscription/store/MemberSlice";
import B2BSalesReportSlice from "./modules/reports/store/B2BSlice";
import b2CLSalesReportSlice from "./modules/reports/store/B2CLSlice";
import b2CSSalesReportSlice from "./modules/reports/store/B2CSSlice";
import CDNRSalesReportSlice from "./modules/reports/store/CDNRSlice";
import CDNURSalesReportSlice from "./modules/reports/store/CDNURSlice";
import HSNSalesReportSlice from "./modules/reports/store/HSNSlice";
import ExempSalesReportSlice from "./modules/reports/store/ExempSlice";
import syncLogsSlice from "./modules/shared/store/syncLogsSlice";

export const rootReducer = combineReducers({
  products: productsReducer,
  productEntity: productEntity,
  posOrder: posOrderReducer,
  cartItems: cartItemsReducer,
  voucher: voucherSlice,
  categories: categoryReducer,
  setting: settingReducer,
  stockReport: stockReportReducer,
  partyWiseReport: partyDataReducer,
  itemWiseReport: itemReportDataReducer,
  auth: registerSlice,
  paymentEntity: paymentEntitySlice,
  payment: paymentReducer,
  partyGet: partyGetSlice,
  initInstall: initInstallSlice,
  getParty: getPartySlice,
  partyDetails: partyDetailsSlice,
  partyEntity: PartyEntitySlice,
  partySummaryChart: PartySummaryChartSlice,
  partyCategoryChart: partyCategoryFilterChartSlice,
  voucherEntity: voucherEntitySlice,
  voucherItems: voucherItemsSlice,
  additionalCharges: additionalChargesSlice,
  billWiseReport: billWiseReportSlice,
  dayBookReport: dayBookReportSlice,
  itemReportByParty: itemReportByPartySlice,
  itemSaleAndPurchaseReport: itemSaleAndPurchaseReportSlice,
  partyReportByItem: partyReportByItemSlice,
  partyWiseOutstanding: partyWiseOutstandingSlice,
  profitAndLoss: profitAndLossSlice,
  purchaseSummaryReport: purchaseSummaryReportSlice,
  rateListReport: rateListReportSlice,
  saleSummary: SaleSummarySlice,
  stockSummary: stockSummarySlice,
  partyLedgerReport: partyLedgerReportSlice,
  latestTransaction: LatestTransactionSlice,
  saleSummaryChart: SalesSummaryChartSlice,
  dailyChart: DailyChartSlice,
  profitAndLossChart: ProfitAndLossChartSlice,
  categoryEntity: categoryEntitySlice,
  addon: AddonEntitySlice,
  plan: PlanEntitySlice,
  subscription: subscriptionReducer,
  packageEntity: PackageEntitySlice,
  createSubscription: createSubscriptionSlice,
  getProduct: getProductSlice,
  createPackage: CreatePackageSlice,
  getPackage: GetPackagesItemSlice,
  partyProfileInfo: PartyProfileInfoSlice,
  partyTransaction: PartyTransactionSlice,
  partyLedger: PartyLedgerSlice,
  partySalesTotal: PartySalesTotalSlice,
  partySalesChart: PartySalesChartSlice,
  partyAddress: partyAddressSlice,
  partyOverview: partyOverviewSlice,
  productCategoryFilterChart: productCategoryFilterChartSlice,
  productSummaryChart: productSummaryChartSlice,
  productTotalSoldQuantity: productTotalSoldQuantitySlice,
  productTotalSoldQuantityChart: productTotalSoldQuantityChartSlice,
  inviteUser: inviteUserSlice,
  avgSalesChart: averageSalesChartSlice,
  numOfSalesCountChart: numberOfSalesChartSlice,
  salesTaxChart: salesTaxChartSlice,
  salesOverviewBlocks: salesOverviewBlocksSlice,
  numberOfPurchaseChart: numberOfPurchaseChartSlice,
  topTenPurchaseParty: topTenPurchasePartySlice,
  avgPurchaseChart: averagePurchaseChartSlice,
  topTenPurchaseItem: topTenPurchaseItemSlice,
  getSetting: getSettingSlice,
  hourlyChart: hourlySalesChartSlice,
  partyPayments: partyPaymentsSlice,
  gstPurchaseReport: gstPurchaseReportSlice,
  gstSalesReport: gstSalesReportSlice,
  hsnWiseSaleSummary: hsnWiseSalesSummarySlice,
  stockDetailReport: stockDetailReportSlice,
  gstr2PurchaseReport: gstr2PurchaseReportSlice,
  gstr1SalesReport: gstr1SalesReportSlice,
  saleCategoryWise: saleSummaryCategoryWiseSlice,
  receivableAgeing: receivableAgeingReportSlice,
  subscriptionReceivedAmount:createSubscriptionReceivedAmountSlice,
  sharedSlice:SharedSlice,
  subscriptionVoucher:getAllSubscriptionVoucherSlice,
  orderAndRevenue:OrderAndRevenueSlice,
  profitBlock:ProfitBlockSlice,
  newCustomer:NewCustomerSlice,
  repeatCustomer:RepeatCustomerSlice,
  productChart:productChartSlice,
  productCategoryChart:productCategoryChartSlice,
  topSellingProducts:TopSellingProductsSlice,
  enquiry:EnquirySlice,
  createEnquiry:CreateEnquirySlice,
  subscriptionDashboard:SubscriptionDashboardSlice,
  subscriptionCancell:SubscriptionCancellSlice,
  subscriptionReactive:SubscriptionReactiveSlice,
  config:configSlice,
  stockEntity:stockEntitySlice,
  manageAccount:manageAccountSlice,
  subscriptionPlanItems:subscriptionPlanItemsSlice,
  globalPlan : globalPlanSlice,
  setupFees: SetupFeesEntitySlice,
  subscriptionAttendance: subscriptionAttendanceSlice,

  partysDeletedRecords: partyDeletedSlice,
  ProductDeletedRecords: productDeletedSlice,
  VoucherDeletedRecords: voucherDeletedSlice,
  members: MemberSlice,
  b2bSalesReport: B2BSalesReportSlice,
  b2CLSalesReport: b2CLSalesReportSlice,
  b2CSSalesReport: b2CSSalesReportSlice,
  CDNRSalesReport: CDNRSalesReportSlice,
  CDNURSalesReport: CDNURSalesReportSlice,
  HSNSalesReport: HSNSalesReportSlice,
  ExempSalesReport: ExempSalesReportSlice,
  syncLogs:syncLogsSlice
});
