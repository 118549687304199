import {
  Button,
  Col,
  Modal,
  Row,
  Stack,
  Form,
  InputGroup,
  Card,
} from "react-bootstrap";
import PaymentTypes from "./PaymentTypes";
import PaymentAmount from "./PaymentAmount";
import {
  addPaymentDate,
  addPaymentNumber,
  addPaymentType,
  getPaymentNumber,
  resetPayment,
} from "../../../payment/store/paymentEntitySlice";
import {
  createPayment,
  fetchTotalAmount,
  getAllVoucher,
  resetPartialStatus,
  resetSales,
  selectSalesById,
  updateReceivedAmount,
} from "../../../voucher/store/voucherSlice";
import {
  addInitPayment,
  processingPayment,
  resetPartyPayment,
  resetSelectedPayment,
  resetStatusError,
  undoPayments,
} from "../../../payment/store/partyPaymentsSlice";
import { addPartyInPayment } from "../../../payment/store/paymentEntitySlice";
import { fetchSalesbyParty } from "../../../payment/store/paymentEntitySlice";
import { selectAllparty } from "../../store/getPartySlice";
import {
  i18n,
  numValidate,
  placeholderText,
} from "../../../shared/helpers/sharedMethods";
import { addPartyPayments } from "../../../payment/store/partyPaymentsSlice";
import { iteratePartyPayment } from "../../../payment/helpers/paymentHelper";
import { useDispatch, useSelector } from "react-redux";
import { getUtcDate } from "../../../shared/helpers/localizationHelper";
import { useState, useEffect } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { cloneDeep } from "lodash";
import { toast } from "react-toastify";
import GlobalCurrency from "../../../shared/components/GlobalCurrency";
import LoadingSpinner from "../../../shared/components/LoadingSpinner";
import ReceivedPayment from "../../../voucher/components/ReceivedPayment";

const PaymentModal = (props) => {
  const { show, setShow, type, title, name, partyId, Id } = props;
  const [sumOfValue, setSumOfValue] = useState(0);
  const [showModalBody, setShowModalBody] = useState(false);
  const [inputValue, setInputValue] = useState("0");
  const dispatch = useDispatch();
  const selectedSale = useSelector((state) => selectSalesById(state, Id));
  const { paymentType, paymentDate, paymentNumber } = useSelector(
    (state) => state.paymentEntity
  );
  const selectedBusiness = useSelector(
    (state) => state?.auth?.selectedBusiness
  );
  const accessToken = useSelector(
    (state) => state?.auth?.accessToken?.access_token
  );
  const tenantId = selectedBusiness?.pivot?.tenant_id;
  const { terminalId } = useSelector((state) => state.initInstall);
  useEffect(() => {
    dispatch(getPaymentNumber({data:"payment_in", token: accessToken, tenant_id: tenantId, terminal_id: terminalId}));
  },[])
  const { processPayment, copyPayment, checkedItems, statusError } =
    useSelector((state) => state.partyPayments);
  const partyPayments = useSelector((state) => state.partyPayments);
  const { party, status } = useSelector((state) => state.paymentEntity);
  const { invoicePartialPayment } = useSelector((state) => state.voucher);
  const currencySymbol = useSelector(
    (state) => state.getSetting?.dataSetting.currency
  );
  const [markAsReceived, setMarkAsReceived] = useState(false);
  const [loading, setLoading] = useState(false);

  const grand_total = selectedSale?.grand_total;
  const paidAmount = selectedSale?.paid_amount;
  const balanceAmount = grand_total - paidAmount;

  useEffect(() => {
    if (invoicePartialPayment) {
      // setShow(false)
      dispatch(resetPartialStatus());
    }
  }, [invoicePartialPayment]);
  useEffect(() => {
    if (selectedSale?.payments) {
      const sum = selectedSale?.payments.reduce(
        (partialSum, a) => partialSum + a.amount,
        0
      );

      setSumOfValue(sum);
    }
  }, [selectedSale]);
  console.log("PMNT", sumOfValue);

  // const paidAmountView = sumOfValue;
  // const balanceAmountView = grand_total - paidAmountView;

  const handleChange = (e) => {
    if (copyPayment.length > 0 && Object.keys(checkedItems).length > 0) {
      dispatch(undoPayments(copyPayment));
    }
    dispatch(addInitPayment(e.target.value));
    dispatch(processingPayment(e.target.value));
    dispatch(resetSelectedPayment());
    dispatch(resetStatusError());
  };

  const paymentValue = [
    { value: "upi", label: placeholderText("UPI") },
    { value: "card", label: placeholderText("CARD") },
    { value: "cash", label: placeholderText("CASH") },
  ];
  useEffect(() => {
    if (status == true) {
      dispatch(resetPayment());

      dispatch(resetPartyPayment());
    }
  }, [status]);

  const handleNewPaymentClick = () => {
    setShowModalBody(!showModalBody);
    setInputValue("0");
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Enter") {
        event.preventDefault();
      const submitButton = document.getElementById("submitButton")
      if (submitButton) {
        submitButton.click();
      }
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const onSubmit = async (event) => {
    event.preventDefault(); // Prevent default form submission behavior
    let paymentData = cloneDeep(selectedSale);
    setLoading(true);
    // Normal payment creation
    const amount = parseInt(inputValue) || 0;
    let final_amount = sumOfValue + amount;
    if (isNaN(amount) || amount <= 0) {
      toast.error("Please enter a valid amount");
      setLoading(false);
      return;
    }

    if (final_amount > selectedSale?.grand_total) {
      toast.error("Amount exceeds the total for this invoice!");
      setLoading(false);
      return;
    }
    paymentData?.voucher_items?.forEach((item) => {
      item.id = item.product_id; 
    });
    paymentData.paid_amount += amount;
    paymentData.amount = amount
    console.log('final amt',paymentData)
    // Only dispatch createPayment if the payment is valid
    dispatch(createPayment({data:paymentData, token: accessToken, tenant_id: tenantId, terminal_id: terminalId}));
    dispatch(
      updateReceivedAmount({
        id: selectedSale.id,
        paid_total: final_amount,
      })
    );

    // Reset state and close modal after the submission
    dispatch(resetPayment());
    dispatch(resetPartyPayment());
    setMarkAsReceived(false);
    setShowModalBody(false);
    setTimeout(() => {
      setLoading(false);
    }, 100);
  };
  useEffect(() => {
    if (markAsReceived) {
      setInputValue(balanceAmount);
    } else {
      setInputValue("0");
    }
  }, [markAsReceived, balanceAmount]);

  const handlePaymentChange = (value) => {
    dispatch(addPaymentType(value));
  };

  return (
    <Modal
      className="modal-md modal-dialog-centered py-20"
      show={show}
      onHide={() => setShow(false)}
      style={{ zIndex: 2500 }}
      keyboard={true}
    >
      {" "}
      <div
        className=""
        style={{
          borderRadius: "5px",
          boxShadow: "0 0 500px 500px rgba(0, 0, 0, 0.3)",
        }}
      >
        <Modal.Header className="pt-3" closeButton>
          <Modal.Title>
            {title} {selectedSale?.voucher_number}
          </Modal.Title>
        </Modal.Header>

        <Stack
          className="col-md-12 mt-4 px-4 mb-0 d-flex "
          gap={3}
          direction="horizontal"
        >
          <Stack className="px-2" direction="vertical" gap={1}>
            <span
              className="fs-responsive mb-2"
              style={{ color: "", fontSize: "14px" }}
            >
              <b>{i18n("Total Amount")}</b>
            </span>
            <span className="" style={{ color: "black" }}>
              {" "}
              {currencySymbol} {grand_total ? grand_total : "0"}
            </span>
          </Stack>

          <Stack className="px-2" direction="vertical" gap={1}>
            <span
              className="fs-responsive mb-2"
              style={{ color: "#687E96", fontSize: "14px" }}
            >
              <b>{i18n("Paid Amount")}</b>
            </span>
            <span className="" style={{ color: "#687E96" }}>
              {" "}
              {currencySymbol} {paidAmount ? paidAmount : "0"}
            </span>
          </Stack>

          <Stack className="px-2" direction="vertical" gap={1}>
            <span
              className="fs-responsive mb-2"
              style={{ color: "green", fontSize: "14px" }}
            >
              <b>{i18n("Balance Amount")}</b>
            </span>
            <span className="" style={{ color: "green" }}>
              {" "}
              <GlobalCurrency price={Math.abs(balanceAmount ? balanceAmount : "0")} />
            </span>
          </Stack>
        </Stack>

        <Modal.Body>
          {!showModalBody && balanceAmount != 0 && (
            <div
              className="d-flex justify-content-center p-2 my-3 cursor-pointer"
              style={{ border: "1px dashed lightgray" }}
            >
              <Button variant="link" onClick={handleNewPaymentClick}>
                {i18n("+ Add Payment")}
              </Button>
            </div>
          )}
          {showModalBody && (
            <>
              <Row className="row">
                <Col className="col-md-12 mb-2 px-3">
                  <Form.Check
                    inline
                    label="Mark as received"
                    name="mark_as_received"
                    type="checkbox"
                    id="mark_as_received_check"
                    checked={markAsReceived}
                    onChange={() => {
                      setMarkAsReceived(!markAsReceived);
                    }}
                  />
                </Col>
                <Col>
                  <InputGroup className="col-md-12 mb-2">
                    <InputGroup.Text>{currencySymbol}</InputGroup.Text>
                    <Form.Control
                      type="text"
                      name="voucher_charges"
                      className="text-end"
                      autoFocus={true}
                      onKeyPress={(event) => numValidate(event)}
                      value={inputValue}
                      onChange={(e) => {
                        setInputValue(e.target.value);
                      }}
                    />
                    <Form.Select
                      onChange={(e) => {
                        handlePaymentChange(e.target.value);
                      }}
                      value={paymentType}
                    >
                      {paymentValue &&
                        paymentValue.length > 0 && [
                          ...paymentValue.map((item, index) => (
                            <option key={index} value={item.value}>
                              {item.label}
                            </option>
                          )),
                        ]}
                    </Form.Select>
                    <Button id='submitButton' variant="primary mx-1" onClick={onSubmit}>
                      {i18n("Add Payment")}
                    </Button>
                  </InputGroup>
                </Col>
              </Row>
            </>
          )}

          {loading && <LoadingSpinner />}
        </Modal.Body>
        <span className="px-4" style={{ color: "#687E96", fontSize: "14px" }}>
          <b>{i18n("Received Payments")}</b>
        </span>
        <Card className="px-4">
          <ReceivedPayment saleId={Id} />
        </Card>
      </div>
    </Modal>
  );
};

export default PaymentModal;
