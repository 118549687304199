import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { addVoucherType } from "../store/voucherDeletedSlice";
import { Form } from 'react-bootstrap';
import { placeholderText } from "../../shared/helpers/sharedMethods";

const SalesType = () => {
  const dispatch =useDispatch()
  const [selectedType, setSelectedType] = useState("All_sales");

 const handleSalesTypeChange =(value)=>{
  setSelectedType(value);
  dispatch(addVoucherType(value));
 }

return (
<Form.Select onChange={(e) =>{handleSalesTypeChange(e.target.value)}} value={selectedType}>
    <option  value="All_sales">{placeholderText("All Sales")}</option>
    <option  value="sales_invoice">{placeholderText("Sales Invoice")}</option>
    <option  value="sales_return">{placeholderText("Sales Return")}</option>
    <option  value="credit_note">{placeholderText("Credit Note")}</option>
    <option  value="quote">{placeholderText("Quote")}</option>
    <option  value="proforma_invoice">{placeholderText("Proforma Invoice")}</option>
    
</Form.Select>
 )
}
export default SalesType;