import React, { useState, useEffect } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Chart from "./Chart";
import { fetchProductSummaryChart } from "../../../store/productSummaryChartSlice";
import ReportPeriod from "../../../../shared/components/ReportPeroid";
import ProductSummaryFliter from "./ProductSummaryFliter";


function ProductSummaryChartIndex() {
  const dispatch = useDispatch();
  const [reportPeriod, setReportPeriod] = useState("current-week");
  const [labelName, setLabelName] = useState([]);
  const [salesAmount, setSalesAmount] = useState([]);
  const [productFilter, setProductFilter] = useState("amount");

  useEffect(() => {
    dispatch(fetchProductSummaryChart({reportPeriod,productFilter}));
  }, [reportPeriod,productFilter]);


  const reportItems = useSelector((state) => state.productSummaryChart.reportItems);

  useEffect(() => {
  if (Array.isArray(reportItems)) {
      const productNames = reportItems && reportItems.map(item => item.ProductName);
      setLabelName(productNames);
      const saleAmounts = reportItems && reportItems.map(item => item.saleAmount);
      setSalesAmount(saleAmounts);
  }
}, [reportPeriod,reportItems]);



  return (
    <Card id="chart" className="border mb-3">
     
      <Card.Body className="p-2">
        <Row >
          <Col xl={7} md={7} sm={7}>
            <Chart 
             labelName= {labelName} 
             salesAmount={salesAmount}
             reportPeriod={reportPeriod} 
             productFilter={productFilter}
            />
          </Col>
          <Col xl={5} md={5} sm={12} className="">

          <Row className="">
          <Col xl={7} md={7} sm={7} >
            <ReportPeriod
              reportPeriod={reportPeriod}
              setReportPeriod={setReportPeriod}
            />
            </Col>
            <Col xl={5} md={5} sm={5} className="">
               <ProductSummaryFliter 
            productFilter={productFilter} 
            setProductFilter={setProductFilter} 
          />
          </Col>
          </Row>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
}

export default ProductSummaryChartIndex;
