import React from "react";
import { Col, Row } from "react-bootstrap";
import { taxList } from "../../../../../shared/config/taxConfig";
import { useSelector } from "react-redux";
import { i18n } from "../../../../../shared/helpers/sharedMethods";
import GlobalCurrency from "../../../../../shared/components/GlobalCurrency";


const PricingDetails = ({ selectedProduct }) => {

  const gst = taxList.find((value) => value.code == selectedProduct.tax_code);
  const currencySymbol = useSelector((state) => state.getSetting?.dataSetting.currency);
  ;

  return (
    <>
      <Row className=" p-1 px-4 mx-3 ">
        <Col lg={5} style={{ fontWeight: 600 }}>
          {i18n("Pricing Details")}
        </Col>
      </Row>
      <Row

        className=" mt-1 p-1 px-4 w-150 mx-3"
        style={{
          borderBottom: "1px solid rgba(10, 10, 10, 0.1)",
          fontSize: "13px",
          color: "#76797C",

        }}
      >
        <Col lg={6}>{i18n("Sales Price")}</Col>
        <Col lg={6} style={{ color: "black" }}>
          <GlobalCurrency price={selectedProduct?.sale_price} /> &nbsp;
          <span style={{ marginLeft: "4px", color: selectedProduct?.is_tax_included === 0 ? "#76797C" : "#76797C", }}
          >
            {selectedProduct?.is_tax_included === 0 ? i18n("Without Tax") : i18n("With Tax")}
          </span>
        </Col>
      </Row>
      <Row className=" mt-1 p-1 px-4 mx-3" style={{ borderBottom: "1px solid rgba(10, 10, 10, 0.1)", fontSize: "13px", color: "#76797C", }}
      >
        <Col lg={6}>{i18n("Purchase Price")}</Col>
        <Col lg={6} style={{ color: "black" }}>
          <GlobalCurrency price={selectedProduct?.cost_price} /> &nbsp;
          <span style={{ marginLeft: "4px", color: selectedProduct?.is_tax_included_for_purchase === 0 ? "#76797C" : "#76797C", }}>
            {selectedProduct?.is_tax_included_for_purchase === 0 ? i18n("Without Tax") : i18n("With Tax")}
          </span>
        </Col>
      </Row>
      <Row
        className=" mt-1 p-1 px-4 mx-3 "
        style={{
          borderBottom: "1px solid rgba(10, 10, 10, 0.1)",
          fontSize: "13px",
          color: "#76797C",


        }}
      >
        <Col lg={6}>{i18n("HSN")}</Col>
        <Col lg={6} style={{ color: "black" }}>
          {selectedProduct.hsn ? selectedProduct.hsn : "-"}
        </Col>
      </Row>
      <Row
        className=" mt-1 p-1 px-4 mx-3 "
        style={{
          fontSize: "13px", color: "#76797C",
        }}
      >
        <Col lg={6}>{i18n("Tax Rate")}</Col>
        <Col lg={6} style={{ color: "black" }}>
          {selectedProduct.tax_code ? gst.label : "None"}
        </Col>
      </Row>
    </>
  );
};

export default PricingDetails;
