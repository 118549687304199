import { useDispatch, useSelector } from "react-redux";
import { Row, Col, Stack, Form, Button, OverlayTrigger, Tooltip } from "react-bootstrap"; 
import {
  toggleEditPartyModal,
  updateGstIn,
  updateParty,
  updatePartyPhone,
  updatePlaceOfSupply,
} from "../store/voucherEntitySlice";
import { selectAllparty } from "../../party/store/getPartySlice";
import { ReactSearchAutocomplete } from "react-search-autocomplete";
import {
  i18n,
  numValidate,
  placeholderText,
} from "../../shared/helpers/sharedMethods";
import PartyTypeAhead from "./fields/PartyTypeAhead";
import { placeOfSupplyList } from "../../shared/config/placeOfSupply";
import { useEffect, useState } from "react";
import { isEmpty } from "lodash";
import { format } from "date-fns";
import Select from "react-select";
import { fetchPartyProfileInfo } from "../../party/store/PartyProfileInfoSlice";
import { useHotkeys } from "react-hotkeys-hook";
import { UilInfoCircle } from "@iconscout/react-unicons";
import QuickGlance from "../components/QuickGlance";
import "../../voucher/assets/css/sale.css";

const PartyBlock = () => {
  const dispatch = useDispatch();
  const accessToken = useSelector((state) => state?.auth?.accessToken?.access_token);
  const selectedBusiness = useSelector((state) => state?.auth?.selectedBusiness);
  const tenantId = selectedBusiness?.pivot?.tenant_id;
  const currentParty = useSelector((state) => state.voucherEntity.party);
  const gstin = useSelector((state) => state.voucherEntity.gstin);
  const { place_of_supply } = useSelector((state) => state.voucherEntity);
  const partyProfileInfo = useSelector(
    (state) => state.partyProfileInfo.reportItems
  );
  const currencySymbol = useSelector(
    (state) => state.getSetting?.dataSetting?.currency
  );
  const [isCustomOption, setIsCustomOption] = useState(false);
  useEffect(() => {
    if (!isEmpty(currentParty)) {
      dispatch(fetchPartyProfileInfo({id:currentParty?.id,token:accessToken,tenant_id:tenantId}));
    }
  }, [currentParty]);

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      border: "1px solid #ccc",
      borderRadius: "4px",
      boxShadow: state.isFocused ? "0 0 7px rgb(77,144,254,0.5)" : null,
      height: "20px",
    }),
  };

  return (
    <>
        <Stack direction="vertical" className="p-1" gap={1}>
        <Row className="align-items-center mx-0">
          <Col className="d-flex align-items-center">
            <Form.Label className="m-0">
              <span className="bold" style={{ color: "#4682B4", paddingTop: "5px" }}>
                {i18n("PARTY")}{" "}
                <OverlayTrigger
                  placement="right-start"
                  overlay={
                    <Tooltip id="tooltip-overglance">
                      <QuickGlance />
                    </Tooltip>
                  }
                >
                  <span style={{ cursor: 'pointer' }}>
                    <UilInfoCircle size="14" color="#000000" />
                  </span>
                </OverlayTrigger>
              </span>
            </Form.Label>
          </Col>

          <Col className="text-end">
            {!isEmpty(currentParty) && (
              <Button
                variant="link"
                size="sm"
                onClick={() => {
                  dispatch(
                    toggleEditPartyModal({
                      toggle: true,
                    })
                  );
                }}
              >
                {i18n("Edit")}
              </Button>
            )}
          </Col>
        </Row>
        <Row className="px-2">
          <PartyTypeAhead setIsCustomOption={setIsCustomOption} />
        </Row>
        {!isEmpty(currentParty) && (
          <Row className="p-2" style={{ fontSize: "12px" }}>
            <Form.Group as={Col} lg={6} className="billing-info">
              <div className="info-section">
                <span className="info-label text-black-50 bold">{i18n("PHONE : ")}</span>
                <span className="info-value">{currentParty?.phone_number || "--"}</span>
              </div>

              <div className="info-section">
                <span className="info-label text-black-50 bold">{i18n("GSTIN : ")}</span>
                <span className="info-value">{currentParty?.gstin || "--"}</span>
              </div>

              <div className="info-section">
                <span className="info-label text-black-50 bold">{i18n("SINCE : ")}</span>
                <span className="info-value">
                  {currentParty?.created_at &&
                    format(new Date(currentParty.created_at), "dd/MM/yyyy")}
                </span>
              </div>
            </Form.Group>

            <Form.Group as={Col} lg={6}>
              <Stack direction="vertical" className="ms-auto">
                <Form.Label className="text-black-50 bold" style={{ color: "#4682B4" }}>
                  {i18n("PLACE OF SUPPLY")}
                </Form.Label>
                <Select
                  name="place_of_supply"
                  options={placeOfSupplyList}
                  styles={customStyles}
                  value={placeOfSupplyList.find((option) => option.value == place_of_supply)}
                  onChange={(selectedOption) => {
                    dispatch(updatePlaceOfSupply(selectedOption.value));
                  }}
                />
              </Stack>
            </Form.Group>
          </Row>
        )}
        </Stack>

    </>
  );
};

export default PartyBlock;
