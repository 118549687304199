import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch, useSelector } from "react-redux";
import { faBan } from "@fortawesome/free-solid-svg-icons";
import { Button, Col, Row } from "react-bootstrap";
import { i18n } from "../../../../shared/helpers/sharedMethods";
import { changeAddonShow } from "../../../store/CreateSubscriptionSlice";
import { resetAddon } from "../../../store/GetParentProductEntiry";
import {
  addNew,
  selectAllSubscriptionPlanItems,
  selectSubscriptionPlanItemById,
} from "../../../store/subscriptionProductEntitySlice";
import { useEffect, useState } from "react";
import GlobalCurrency from "../../../../shared/components/GlobalCurrency";

const SubscriptionTotal = ({ noOfItems, discountEnabled, cessEnabled }) => {
  const dispatch = useDispatch();

  const cartItem = useSelector(selectAllSubscriptionPlanItems);

  const currencySymbol = useSelector(
    (state) => state.getSetting?.dataSetting.currency
  );
  ;

  const {
    tax_total,
    grand_total,
    discount_amount,
    total_quantity,
    cess_total,
    addonShow,
    setup_fee,
    taxable_value,
    subscription_items
  } = useSelector((state) => state.createSubscription);

  const [hasAddon, setHasAddon] = useState(false);
  const [hasSetupFee, setHasSetupFee] = useState(false);



  useEffect(() => {
    let addon = false;
    let setupFee = false;

    cartItem.forEach((item) => {
      if (item.sub_type === "addon") addon = true;
      if (item.sub_type === "setup_fee") setupFee = true;
    });

    setHasAddon(addon);
    setHasSetupFee(setupFee);
  }, [cartItem]);

  return (
    <tfoot
      className=" fs-4"
      style={{ borderTop: "1px solid #ddd", height: "46px" }}
    >
      <tr style={{}}>
        <th colSpan={3}>
          {/* Show "Add Addon" button if there's no addon */}
          {!hasAddon && (
            <Button
              variant="link p-2 float-center"
              size="md"
              onClick={() =>
                dispatch(addNew({ type: "addon", index: noOfItems + 1 }))
              }
            >
              {i18n("+ Add Addon")}
            </Button>
          )}

          {/* Show "Add Setup Fees" button if there's no setup fee */}
          {!hasSetupFee && (
            <Button
              variant="link p-2 float-center"
              size="md"
              onClick={() =>
                dispatch(addNew({ type: "setup_fee", index: noOfItems + 1 }))
              }
            >
              {i18n("+ Add Setup Fees")}
            </Button>
          )}
        </th>
        <th>
          <text
            className="float-end "
            style={{ fontWeight: "bold", fontSize: "0.875rem" }}
          >
            {i18n("Totals")}
          </text>
        </th>
        <th>
          <text className="float-end ">{total_quantity}</text>
        </th>
        <th className="">
        </th>
        {discountEnabled && (
          <th colSpan={2}>
            <div className="d-flex justify-content-end ">
              <text className="">
                <GlobalCurrency price ={discount_amount} />
              </text>
            </div>
          </th>
        )}

        <th colSpan={1}>
          <text className="float-end"><GlobalCurrency price = {tax_total ? tax_total : "0.00"} /></text>
        </th>
        {cessEnabled && (
          <>
            <th colSpan={2}>
              <div className="d-flex justify-content-end ">
                <text className="">
                  <GlobalCurrency price={cess_total} />
                </text>
              </div>
            </th>{" "}
          </>
        )}
        <th colSpan={1}>
          <text className="float-end"><GlobalCurrency price ={grand_total}/></text>
        </th>
      </tr>
    </tfoot>
  );
};
export default SubscriptionTotal;
