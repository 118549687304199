import React, { useEffect } from "react";
import { createBuisnessDetails, resetAuth } from "../store/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { useLocation } from "react-router";
import business from "../../../assets/images/business.jpg";
import { resetProduct } from "../../catalog/store/productsSlice";
import { resetSales } from "../../voucher/store/voucherSlice";
import { resetPayment } from "../../payment/store/paymentEntitySlice";
import { resetCategory } from "../../settings/store/categorysSlice";
import { resetParty } from "../../party/store/getPartySlice";
import { resetDashboard } from "../../dashboard/store/LatestTransactionSlice";
import { resetSubscription } from "../../subscription/store/SubscriptionSlice";
import { resetSubscriptionParty } from "../../subscription/store/LivePartyGetsSlice";
import {
  Button,
  Form,
  Col,
  Row,
  InputGroup,
  Alert,
  Spinner,
  Card,
  Image,
  Container,
} from "react-bootstrap";
import { useFormik } from "formik";
import * as yup from "yup";
import { placeOfSupplyList } from "../../shared/config/placeOfSupply";
import pincodeData from "../../../assets/indiaPincode.json";
import { resetCartVoucherPayment } from "../../payment/store/paymentSlice";
import {
  i18n,
  numValidate,
  placeholderText,
} from "../../shared/helpers/sharedMethods";
import { resetSubscriptionItem } from "../../subscription/store/subscription_item_store/GetPackagesItemSlice";
import { resetSetting } from "../../settings/store/getSettingSlice";
import { resetSubscriptionVoucher } from "../../subscription/store/getAllSubscriptionVoucherSlice";
import Select from "react-select";
import bill from "../../../assets/logo/logo6-small.png";
import backgroundSvg from "../../../assets/logo/dweb-botton-vector.svg";

const CreateBusiness = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { phone } = useSelector((state) => state?.auth);
  const accessToken = useSelector(
    (state) => state?.auth?.accessToken?.access_token
  );
  const { errorMessage, error, loading, businessStatus } = useSelector(
    (state) => state.auth
  );
  ;
  const location = useLocation();
  const existingUser = location.state?.value;


  useEffect(() => {
    if (businessStatus) {
      dispatch(resetProduct());
      dispatch(resetSales());
      dispatch(resetPayment());
      dispatch(resetCartVoucherPayment());
      dispatch(resetCategory());
      dispatch(resetParty());
      dispatch(resetDashboard());
      dispatch(resetSubscription());
      dispatch(resetSubscriptionParty());
      dispatch(resetAuth());
      dispatch(resetSetting());
      dispatch(resetSubscriptionVoucher());
      dispatch(resetSubscriptionItem());
      navigate("/auth/install");
    }
  }, [businessStatus]);

  const validationSchema = yup.object().shape({
    // name: yup.string().required(i18n("Please enter the name")),
    business_name: yup
      .string()
      .required(i18n("Please enter the business name")),
    business_address: yup
      .string()
      .required(i18n("Please enter the address"))
      .min(10, i18n("Business address must be atleast 10 characters")),
    //  business_phone: yup
    //      .string()
    //      .required(i18n("Please enter the business phone"))
    //      .min(10, i18n("Business phone must be at least 10 characters")),
    gstin: yup
      .string()
      .matches(
        /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[0-9]{1}[A-Z]{1}[0-9A-Z]{1}$/,
        { message: i18n("Invalid GST") }
      ),
      pincode: yup
      .string()
      .matches(/^[1-9][0-9]{2}\s?[0-9]{3}$/, { message: i18n("Invalid Postal Code") })
      .required(i18n("Postal code is required")),

  });

  const {
    handleChange,
    handleBlur,
    values,
    errors,
    touched,
    setFieldValue,
    handleSubmit,
  } = useFormik({
    initialValues: {
      name: "",
      business_name: "",
      // business_phone: "",
      business_address: "",
      city: "",
      pincode: "",
      state: "",
      gstin: "",
      isGSTRegistered: false,
    },
    validationSchema,
    onSubmit: (values) => {

      delete values.isGSTRegistered;
      values.phone = phone;
      values.accessToken = accessToken;
      if (!values.gstin) {
        delete values.gstin;
      }
      ;
      if (existingUser) {
        delete values.name
      }
      dispatch(createBuisnessDetails(values));
    },
  });

  const handleCheck = () => {
    setFieldValue("isGSTRegistered", !values.isGSTRegistered);
  };
  const handleBack = () => {
    navigate(-1);
  };
  const handleGstChange = (e) => {
    const upperCaseValue = e.target.value.toUpperCase();
    handleChange({
      ...e,
      target: {
        ...e.target,
        value: upperCaseValue,
        name: "gstin",
      },
    });
  };

  const handlePincodeChange = (e) => {
    const { value } = e.target;
    handleChange(e);

    if (value.length === 6) {
      const pincodeEntry = pincodeData.find(p => p.pincode == value);
      if (pincodeEntry) {
        const stateOption = placeOfSupplyList.find(option => option.label.toUpperCase() == pincodeEntry.statename);
        setFieldValue("city", pincodeEntry.Districtname);
        setFieldValue("state", stateOption ? stateOption.value : "");
      }
    }
  };

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      border: "1px solid #ccc",
      borderRadius: "4px",
      boxShadow: state.isFocused ? "0 0 7px rgb(77,144,254,0.5)" : null,
      height: "40px",
    }),
  };


  return (
    <Container fluid className="bg-white"
      style={{
        height: "calc(100vh - 10px)",
        overflowX: "hidden",
        overflowY: "auto",
        background: `url(${backgroundSvg})`,
        backgroundRepeat: "repeat",
        backgroundSize: "auto",
      }}>
      <div className="d-flex justify-content-center align-items-center " style={{ height: 'calc(100vh - 10px)' }}>
        {error && (
          <Row>
            <Col lg={3}></Col>
            <Col lg={6} className="d-flex justify-content-center">
              <Alert variant="danger" className="mt-2">
                <Alert.Heading>
                  {errorMessage ? errorMessage : " error in creationchol"}
                </Alert.Heading>
              </Alert>
            </Col>
            <Col lg={3}></Col>
          </Row>
        )}

        <Card
          className=""
          style={{
            boxShadow: "1.0px 3.0px 8.0px hsl(0deg 0% 0% / 0.38)",
            //borderRadius: "20px",
            width: '50%'
          }}
        >
          <div className=" py-5 d-flex justify-content-between align-items-center" style={{ paddingLeft: "2.5rem", paddingRight: '2.5rem' }}>
            <div>
              <Form.Label
                className=""
                style={{ fontSize: "18px", color: "#353935" }}
              >
                {i18n(
                  "Please fill in your business details"
                )}
              </Form.Label>
            </div>
            <div>
              <Image src={bill} height={50} />
            </div>
          </div>

          <Card.Body className={`${existingUser ? 'py-0' : 'py-3'}`}>

            {existingUser && <br />}
            {!existingUser && <Row className=" mb-5">
              <Col lg={12}>
                <Form.Group controlId="name">
                  <Form.Label>
                    {i18n("Name")}
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="name"
                    value={values.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder={placeholderText("Enter your name")}
                    autoFocus={true}

                  />

                </Form.Group>
              </Col>
            </Row>}
            <Row className="mb-5 ">
              <Col lg={12}>
                <Form.Group controlId="business_name">
                  <Form.Label>
                    {i18n("Business Name")}
                    <span style={{ color: "#EE5B50" }}>*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="business_name"
                    value={values.business_name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder={placeholderText("Enter your business name")}
                    style={{
                      borderColor:
                        touched.business_name &&
                        errors.business_name &&
                        "red",
                      outline: "none",
                    }}
                  />
                  {touched.business_name && errors.business_name && (
                    <div className="text-danger">{errors.business_name}</div>
                  )}
                </Form.Group>
              </Col>
            </Row>

            <Row className="mb-5 ">
              <Col lg={12}>
                <Form.Group controlId="address">
                  <Form.Label>
                    {i18n("Business Address")}
                    <span style={{ color: "#EE5B50" }}>*</span>
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={2}

                    name="business_address"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.business_address}
                    style={{
                      borderColor: touched.business_address && errors.business_address && "red",
                      outline: "none",
                    }}
                  />
                  {touched.business_address && errors.business_address && (
                    <div className="text-danger">
                      {errors.business_address}
                    </div>
                  )}
                </Form.Group>
              </Col>
            </Row>
            <Row className="mb-5 ">
              <Col lg={4}>
                <Form.Group controlId="pincode">
                  <Form.Label>
                    {i18n("Postal code")}
                    <span style={{ color: "#EE5B50" }}>*</span>
                  </Form.Label>
                  <Form.Control
                    name="pincode"
                    value={values.pincode}
                    onChange={handlePincodeChange}
                    onBlur={handleBlur}
                    placeholder={placeholderText("Enter your pincode")}
                    maxLength={6}
                    style={{ borderColor: touched.pincode && errors.pincode && "red", outline: "none" }}
                    onKeyPress={numValidate}
                  />
                  {touched.pincode && errors.pincode && <div className="text-danger">{errors.pincode}</div>}
                </Form.Group>
              </Col>
              <Col lg={4}>
                <Form.Group controlId="city">
                  <Form.Label>
                    {i18n("City")}
                  </Form.Label>
                  <Form.Control
                    name="city"
                    value={values.city}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder={placeholderText("Enter your city")}
                    style={{ borderColor: touched.city && errors.city && "red", outline: "none" }}
                  />
                  {touched.city && errors.city && <div className="text-danger">{errors.city}</div>}
                </Form.Group>
              </Col>
              <Col lg={4}>
                <Form.Group controlId="state">
                  <Form.Label>{i18n("State")}
                  </Form.Label>
                  <Select
                    name="state"
                    options={placeOfSupplyList}
                    styles={customStyles} 
                    value={placeOfSupplyList.find(option => option.value === values.state) || null}
                    onChange={(selectedOption) => setFieldValue("state", selectedOption ? selectedOption.value : "")}
                    onBlur={handleBlur}
                    menuPlacement="top"
                  />
                  {touched.state && errors.state && (
                    <div className="text-danger">{errors.state}</div>
                  )}
                </Form.Group>
              </Col>
            </Row>
            <Row className="">
              <Col lg={10}>
                <Form.Group controlId="gstSwitch">
                  <Form.Check
                    onChange={handleCheck}
                    type="switch"
                    id="custom-switch"
                    label={i18n("Are you GST Registered ?")}
                  />
                </Form.Group>
              </Col>
              <Col className="d-flex justify-content-end">
                <Form.Label className="" style={{ color: 'grey' }}>
                  *Required fields
                </Form.Label>
              </Col>
              {values.isGSTRegistered && (
                <Col lg={12} className="mt-3">
                  <Form.Group controlId="gstin">
                    <Form.Label>{i18n("GSTIN")}</Form.Label>
                    <Form.Control
                      type="text"
                      name="gstin"
                      placeholder={placeholderText("Enter your GST")}
                      className={`${errors["gstin"] ? "error-highlight" : "form-control"
                        }`}
                      onChange={handleGstChange}
                      onBlur={handleBlur}
                      value={values.gstin}
                      maxLength={16}
                      pattern="^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[0-9]{1}[A-Z]{1}[0-9A-Z]{1}$"
                      style={{
                        borderColor: touched.gstin && errors.gstin && "red",
                        outline: "none",
                      }}
                    />
                    {touched.gstin && errors.gstin && (
                      <div className="text-danger">{errors.gstin}</div>
                    )}
                  </Form.Group>
                </Col>
              )}
            </Row>

          </Card.Body>
          <Card.Footer style={{ paddingLeft: '2.5rem', paddingRight: '2.5rem' }}>

            <div className="d-flex justify-content-between align-items-center ">
              {!loading ? (
                <Button className="" onClick={handleSubmit}>{i18n("Create business")}</Button>
              ) : (
                <Spinner animation="border" size="sm" />
              )}
              {existingUser && (
                <Button variant="outline-secondary " onClick={handleBack}>
                  {i18n("Back")}
                </Button>
              )}
            </div>
          </Card.Footer>

        </Card>


      </div>
    </Container>
  );
};
export default CreateBusiness;
