import React from "react";
import { Container, Row, Col, Table, Badge, Form } from "react-bootstrap";
import { useSelector } from "react-redux";
import { selectSalesById } from "../../../voucher/store/voucherSlice";
import { format } from "date-fns";
import { ToWords } from "to-words";
import { selectSelectedImage } from "../../../settings/store/settingSlice";
import "../css/invoice.css";
import {
  calculateTotalQuantity,
  calculateTotalTaxAmount,
  calculateTaxAmountWithSameTaxRate,
} from "../../helpers/invoiceHelper";
import logo from "../../../../assets/images/bill.png";
import { i18n } from "../../helpers/sharedMethods";
import { QRCode } from 'react-qr-code';


const ThermalInvoice = ({ pdf, voucherId}) => {
  const viewSale = useSelector((state) => selectSalesById(state, voucherId));
  ;
  const grandTotal = parseFloat(viewSale?.grand_total).toFixed(2);
  const balanceAmount = (viewSale?.grand_total - viewSale?.paid_amount).toFixed(
    2
  );
  const words = new ToWords().convert(grandTotal);
  const totalQuantity = calculateTotalQuantity(viewSale?.items);
  const totalTaxAmount = calculateTotalTaxAmount(viewSale?.items);
  const selectImage = useSelector(selectSelectedImage);
  const selectedBusiness = useSelector(
    (state) => state?.auth?.selectedBusiness
  );
  ;
  const currencySymbol  = useSelector((state) => state.getSetting?.dataSetting.currency);
  ;
    
    const termsAndCondition  = useSelector(
      (state) => state.setting?.billing_settings?.terms_and_conditions);
    
    const BankDetails  = useSelector(
      (state) => state.setting?.billing_settings);
    const UPIPayment = useSelector(
      (state) => state.setting?.billing_settings?.upi_payment
    );
    const taxRows = calculateTaxAmountWithSameTaxRate(viewSale?.items);
    const generateQRCode = (UPIPayment) => {
      const upiLink = `upi://pay?pa=${UPIPayment}`;
      
      return (
        <QRCode
          value={upiLink}
          size={90}
          bgColor={"#ffffff"}
          fgColor={"#000000"}
          level={"L"}
        />
      );
    };
  return (
    <div ref={pdf} style={{ backgroundColor: "white", padding: "10px", maxWidth: "320px" }}>
      <Row>
        <Col>
          <p style={{ fontWeight: "bolder", textAlign: "center", fontSize:"16px",marginBottom:"6px",marginTop:'10px'}}>
            {i18n("TAX INVOICE")}
            
          </p>
        </Col>
      </Row>
      <Row style={{ textAlign: "center" ,padding:'0px'}}>
        <Col>
          <h1 style={{ fontSize: "14px", fontWeight: "700", marginBottom:"6px" }}>
            {selectedBusiness?.business_name}
          </h1>   
          <p style={{marginBottom:"6px",fontSize: "12px"}}> {selectedBusiness?.business_address}</p>
            <p style={{ marginBottom: "6px",fontSize: "12px" }}><b>{i18n("Mobile")}: </b>8976543210</p>
            <p style={{ marginBottom: "6px" }}><b>{i18n("GSTIN")}: </b>33CUAPS0799A3ER</p>
        </Col>
      </Row>
      <hr  style={{borderTop:"2px solid #000000"}}></hr>
      <Row style={{ marginTop: "10px" }}>
        <Col>
          <p style={{ marginBottom: "5px" }}>{i18n("Invoice No")}:{viewSale?.voucher_number}</p>
          <p style={{ marginBottom: "5px" }}>{i18n("Invoice Date")}:    {viewSale?.voucher_date
                  ? format(new Date(viewSale?.voucher_date), "dd-MM-yyyy")
                  : "-"}</p>
          <Row>
            <Col xs={3}>
          <p style={{  }}>{i18n("Bill To :")}</p>
          </Col>
          <Col xs={9}>
          <p style={{  }}>{viewSale?.party?.name ? viewSale?.party?.name : "-"}</p>
          </Col>
          </Row>
        </Col>
      </Row>
        <Row className="">
          <Col>
          <hr style={{borderTop:"2px dashed black"}}></hr>
          <Table className="table table-borderless">
            <thead>
              <tr  style={{borderBottom:"2px dashed black", color:'black',width:'5%'}}>
              <th>{i18n("S.No")}</th>
                <th style={{width:'60%'}} >{i18n("ITEMS")}</th>
                <th>{i18n("QTY")}</th>
                <th>{i18n("RATE")}</th>
                <th >{i18n("AMOUNT")}</th>
              </tr>
            </thead>
            <tbody>
            {viewSale?.items &&
              viewSale?.items.length > 0 &&
              viewSale?.items.map((data, index) => (
                <tr key={index} style={{borderBottom: index === viewSale.items.length - 1 ? 'none' : '1px dashed black'}}>
                  <td className="" >{index + 1}</td>
                  <td className="" style={{width: "60%"}}>
                    {data?.product_name}
                  </td>
                  <td className="" >
                  {data?.quantity}
                  </td>
                  <td className="">{data?.item_price}</td>
                  {viewSale?.discount_amount ? (
                    <td className="">{data?.item_discount}</td>
                  ) : null}
                 
                  <td>&nbsp;{data?.subtotal}</td>
                </tr>
              ))}
          </tbody>

            <tfoot>
              <Col style={{ minHeight: "10px" }}> </Col>
              <tr className="">
                <td className="border-top-bottom" style={{fontSize:"10px",width:'60%'}}>{i18n("SUB TOTAL")}</td>
                <td className="border-top-bottom"></td>
                <td className="border-top-bottom">{totalQuantity}</td>
                <td className="border-top-bottom"></td>
                <td className="border-top-bottom">
                  {currencySymbol} {viewSale?.grand_total}
                  
                </td>
              </tr>
              <tr className="">
                <td className="border-top-bottom" style={{fontSize:"10px"}}>{i18n("TOTAL AMOUNT")}</td>
                <td className="border-top-bottom"></td>
                <td className="border-top-bottom"></td>
                <td className="border-top-bottom"></td>
                <td className="border-top-bottom">
                {currencySymbol} {viewSale?.grand_total}
                </td>
              </tr>
              <tr className="">
                <td className="border-top-bottom" style={{fontSize:"10px"}}>{i18n("PAID AMOUNT")}</td>
                <td className="border-top-bottom"></td>
                <td className="border-top-bottom"></td>
                <td className="border-top-bottom"></td>
                <td>
                {currencySymbol} {viewSale?.paid_amount}
                </td>
              </tr>
              <tr className="">
              <td className="border-top-bottom" style={{fontSize:"10px"}}>{i18n("BALANCE AMOUNT")}</td>
              <td className="border-top-bottom"></td>
              <td className="border-top-bottom"></td>
              <td className="border-top-bottom"></td>
              <td className="border-top-bottom">
              {balanceAmount < 0 ? "-" : ""} {currencySymbol}{" "}
                  {Math.abs(balanceAmount)}              </td>
              </tr>
            </tfoot>
          </Table>
          </Col>
        </Row>
    </div>
  );
};

export default ThermalInvoice;
