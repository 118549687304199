export const Unit = [
  { value: "PCS", label: "PIECES" },
  { value: "UNT", label: "UNITS" },
  { value: "KGS", label: "KILOGRAMS" },
  { value: "GMS", label: "GRAMMES" },
  { value: "PAC", label: "PACKS" },
  { value: "LTR", label: "LITRES" },
  { value: "MLR", label: "MILLI LITRES" },
  { value: "BOX", label: "BOX" },
  { value: "BAG", label: "BAGS" },
  { value: "BAL", label: "BALE" },
  { value: "BDL", label: "BUNDLES" },
  { value: "BKL", label: "BUCKLES" },
  { value: "BOU", label: "BILLION OF UNITS" },
  { value: "BTL", label: "BOTTLES" },
  { value: "BUN", label: "BUNCHES" },
  { value: "CAN", label: "CANS" },
  { value: "CCM", label: "CUBIC CENTIMETERS" },
  { value: "CMS", label: "CENTIMETERS" },
  { value: "CBM", label: "CUBIC METERS" },
  { value: "CTN", label: "CARTONS" },
  { value: "DOZ", label: "DOZENS" },
  { value: "DRM", label: "DRUMS" },
  { value: "GGK", label: "GREAT GROSS" },
  { value: "GRS", label: "GROSS" },
  { value: "GYD", label: "GROSS YARDS" },
  { value: "KLR", label: "KILOLITRE" },
  { value: "KME", label: "KILOMETRE" },
  { value: "MLT", label: "MILILITRE" },
  { value: "MTR", label: "METERS" },
  { value: "MTS", label: "METRIC TON" },
  { value: "NOS", label: "NUMBERS" },
  { value: "OTH", label: "OTHERS" },
  { value: "PRS", label: "PAIRS" },
  { value: "SET", label: "SETS" },
  { value: "ROL", label: "ROLLS" },
  { value: "SQF", label: "SQUARE FEET" },
  { value: "SQM", label: "SQUARE METERS" },
  { value: "SQY", label: "SQUARE YARDS" },
  { value: "TBS", label: "TABLETS" },
  { value: "TGM", label: "TEN GROSS" },
  { value: "THD", label: "THOUSANDS" },
  { value: "TON", label: "TONNES" },
  { value: "TUB", label: "TUBES" },
  { value: "UGS", label: "US GALLONS" },
  { value: "YDS", label: "YARDS" },
];
