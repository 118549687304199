import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import "../../../assets/css/frontend.css";
import DataGridHeader from "../../shared/components/DataGridHeader";
import CustomeSortIcon from "../../shared/components/CustomeSortIcon";
import { FaSort, FaSortUp, FaSortDown } from 'react-icons/fa';
import { DataTable } from 'primereact/datatable';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { Column } from 'primereact/column';
import { Calendar } from 'primereact/calendar';
import Layout from "../../shared/components/Layout";
import { Container, Form, Stack } from "react-bootstrap";
import Header from "../../shared/components/Header";
import { i18n } from "../../shared/helpers/sharedMethods";
import { UilEdit, UilTrashAlt, UilHistory } from "@iconscout/react-unicons";
import { toast, ToastContainer } from "react-toastify";
import { getLocalizedDate } from "../../shared/helpers/localizationHelper";
import ShortcutKeys from "../../shared/components/ShortcutKeys";
import { UilUsersAlt } from "@iconscout/react-unicons";
import GlobalCurrency from "../../shared/components/GlobalCurrency";
import { selectAllDeletedProduct, getAllDeletedProduct,deletedProductRestore,deleteProductPermanently, resetDeletedProduct } from "../../catalog/store/productDeletedSlice";
import PermanentDeleteModel from "../../shared/components/PermanentDeleteModel";
import DeletedRecordHeader from "../components/DeletedRecordHeader";
import RestoreModel from "../../shared/components/RestoreModel";

const DeletedProductIndex = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const allProducts = useSelector(selectAllDeletedProduct);
  const currencySymbol = useSelector((state) => state.getSetting?.dataSetting.currency);
  const status = useSelector((state) => state.products.status);

  const [show, setShow] = useState(false);
  const [exportData, setExportData] = useState([]);
  const [deleteId, setDeleteId] = useState();
  const [deleteName, setDeleteName] = useState();
  const [product, setProducts] = useState(allProducts);
  const [globalFilter, setGlobalFilter] = useState('');
  const [globalFilterValue, setGlobalFilterValue] = useState('');
  const [filters, setFilters] = useState(null);
  const [balanceFrozen, setBalanceFrozen] = useState(false);
  const [sortField, setSortField] = useState(null);
  const [sortOrder, setSortOrder] = useState(1);
  const [restoreShow, setShowRestore] = useState(false);
  const [restoreId, setRestoreId] = useState();
  const [restoreName, setRestoreName] = useState();
  

  const handleClearFilters = () => {
    setFilters({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      name: { value: null, matchMode: FilterMatchMode.CONTAINS },
      code: { value: null, matchMode: FilterMatchMode.CONTAINS },
      type: { value: null, matchMode: FilterMatchMode.CONTAINS },
      price: { value: null, matchMode: FilterMatchMode.EQUALS },
      created_at: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
      deleted_at: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
    });
    setGlobalFilter('');
    setGlobalFilterValue('');
    setProducts(allProducts);
  };
  
  const selectedBusiness = useSelector(
    (state) => state?.auth?.selectedBusiness
  );

  const tenantId = selectedBusiness?.pivot?.tenant_id;
  const accessToken = useSelector(
    (state) => state?.auth?.accessToken?.access_token
  );

  useEffect(() => {
    dispatch(getAllDeletedProduct({ token: accessToken, tenantId: tenantId }));
  }, [dispatch]);

  useEffect(() => {
    if (allProducts.length > 0) {
        setProducts(allProducts);
        handleClose();
    }
  }, [allProducts]);  

  const handleRefreshProducts = () => {
    setProducts([]);
    dispatch(getAllDeletedProduct({ token: accessToken, tenantId: tenantId }));
    dispatch(resetDeletedProduct());
  };

  const handleClose = () => {
    setShow(false);
    setShowRestore(false);
  };

  const handleParmanentDelete = async () => {
    try {
        await dispatch(deleteProductPermanently({ id: deleteId, token: accessToken, tenantId: tenantId }));
        setShow(false);
        setProducts((prevProducts) => prevProducts.filter(product => product.id !== deleteId));
        navigate(`/setting/deleted-items/products`);
        toast.success(i18n("Product deleted permanently"));
    } catch (error) {
        toast.error(i18n("Error deleting product"));
    }
  };

const handleRestore = async () => {
    try {
        await dispatch(deletedProductRestore({ id: restoreId, token: accessToken, tenantId: tenantId }));
        setProducts((prevProducts) => prevProducts.filter(product => product.id !== restoreId));
        setShowRestore(false);
        navigate("/setting/deleted-items/products");
        toast.success(i18n("Product restored successfully"));
    } catch (error) {
        toast.error(i18n("Error restoring product"));
    }
  };

  const onDelete = (row) => {
    setDeleteId(row.id);
    setDeleteName(row.name);
    setShow(!show);
    navigate("/setting/deleted-items/products");
  };

  const onRestore = (row) => {
    setRestoreId(row.id);
    setRestoreName(row.name);
    setShowRestore(!restoreShow);
    navigate(`/setting/deleted-items/products`);
  };

  const actionBodyTemplate = (row) => {
    return (
      <div className="d-flex justify-content-evenly align-items-center">
        <div title="Restore">
          <UilHistory
            className="mx-2 mb-1"
            size="15"
            style={{ cursor: "pointer" }}
            onClick={(e) => onRestore(row)}
          />
        </div>
        <div title="Delete">
          <UilTrashAlt
            className="mx-2 mb-1"
            size="15"
            style={{ cursor: "pointer" }}
            onClick={() => onDelete(row)}
          />
        </div>
      </div>
    );
  };

  const headerCellStyle = {
    fontWeight: 'bold',
    fontSize: '11px',
  };

  const dateBodyTemplate = (rowData) => {
    return getLocalizedDate(rowData.created_at);
  };

  const handleCreatedAtDateFilterChange = (value, filterCallback) => {
    filterCallback(value);

    if (value) {
      const filtered = allProducts.filter((item) => {
        const createdAtDate = new Date(item.created_at);
        const selectedDate = new Date(value);
        return createdAtDate.toDateString() === selectedDate.toDateString();
      });
      setProducts(filtered);
    } else {
      setProducts(allProducts);
    }
  };

  const dateFilterTemplate = (options) => {
    return (
      <Calendar
        value={options.value}
        onChange={(e) => handleCreatedAtDateFilterChange(e.value, options.filterCallback)}
        dateFormat="dd/mm/yy"
        mask="99/99/9999"
      />
    );
  };

  const deletedDateBodyTemplate = (rowData) => {
    return getLocalizedDate(rowData?.deleted_at);
  };

  const handleDeletedAtDateFilterChange = (value, filterCallback) => {
    filterCallback(value);

    if (value) {
      const filtered = allProducts.filter((item) => {
        const deletedAtDate = new Date(item.deleted_at);
        const selectedDate = new Date(value);
        return deletedAtDate.toDateString() === selectedDate.toDateString();
      });
        setProducts(filtered);
    } else {
        setProducts(allProducts);
    }
  };

  const deletedDateFilterTemplate = (options) => {
    return (
      <Calendar value={options.value} onChange={(e) => handleDeletedAtDateFilterChange(e.value, options.filterCallback)} dateFormat="dd/mm/yy" mask="99/99/9999" />
    );
  };

  const customSort = (field) => {
    setSortField(field);
    setSortOrder(sortOrder === 1 ? -1 : 1);
  };

  const sortedData = [...product].sort((a, b) => {
    if (sortField) {
      const compareValueA = a[sortField];
      const compareValueB = b[sortField];
      if (compareValueA < compareValueB) {
        return sortOrder;
      }
      if (compareValueA > compareValueB) {
        return -sortOrder;
      }
    }
    return 0;
  });

  return (
    <>
      <ToastContainer />
      <Layout>
        <Header
          currentTitle={i18n("Products")}
          ItemActions={
            <Stack
              gap={3}
              direction="horizontal"
              className="my-2 justify-content-end"
            >
              <ShortcutKeys />
            </Stack>
          }
        />
        <Container fluid className="px-5">
          <div className="bordered-container">
          <DeletedRecordHeader />

            <DataGridHeader
              stockFilter={null}
              statusFilter={null}
              allData={allProducts}
              sortField={sortField}
              sortOrder={sortOrder}
              clearFilter={handleClearFilters}
              data={product}
              addFilteredData={setProducts}
            //   handleCreate={handleCreate}
              handleRefreshProducts={handleRefreshProducts}
              datas={exportData}
              title="Products"
              icons={<UilUsersAlt size="50" color="gray" />}
            />
            <Container fluid className="px-4 mb-4">
              <div className="bordered-container">

                  <DataTable
                    value={sortedData}
                    paginator
                    globalFilter={globalFilter}
                    rows={10}
                    filterDisplay="row"
                    dataKey="id"
                    filters={filters}
                    emptyMessage={i18n("No deleted products found.")}
                    onFilter={(e) => setFilters(e.filters)}
                    className="p-datatable-gridlines full-width-table custom-datatable p-component"
                    // onRowClick={(e) => handleProductRowClick(e.data)}
                    checked={balanceFrozen}
                    scrollable
                    sortMode="multiple"
                    scrollHeight="400px"
                    removableSort
                    style={{ cursor: "pointer" }}
                  >
                    <Column field="name"
                      header={() => (
                        <div
                          className={`custom-header-${sortField === 'name' ? (sortOrder === 1 ? 'up' : 'down') : ''}`}
                          onClick={() => customSort('name')}
                          style={{
                            cursor: 'pointer',
                            display: 'flex',
                            alignItems: 'center',
                            fontSize: '11px',
                            lineHeight: '11px',
                          }}

                        >
                          {i18n("Name")}
                          <CustomeSortIcon sortOrder={sortField === 'name' ? sortOrder : 0}
                            style={{ marginLeft: '4px', verticalAlign: 'middle' }} />
                        </div>
                      )}
                      sortable
                      filter
                      filterPlaceholder=""
                      headerStyle={headerCellStyle}
                      showFilterMatchModes={false}
                      showClearButton={false}
                      showFilterOperator={false}
                      showAddButton={false}
                      filterMatchMode={FilterMatchMode.CONTAINS}
                      showApplyButton={false}
                      showFilterMenu={false}
                    /><Column field="code"
                    header={() => (
                      <div
                        className={`custom-header-${sortField === 'code' ? (sortOrder === 1 ? 'up' : 'down') : ''}`}
                        onClick={() => customSort('code')}
                        style={{
                          cursor: 'pointer',
                          display: 'flex',
                          alignItems: 'center',
                          fontSize: '11px',
                          lineHeight: '11px',
                        }}
                      >
                        {i18n("Code")}
                        <CustomeSortIcon sortOrder={sortField === 'code' ? sortOrder : 0}
                          style={{ marginLeft: '4px', verticalAlign: 'middle' }} />
                      </div>
                    )}
                    sortable
                    filter
                    filterPlaceholder=""
                    headerStyle={headerCellStyle}
                    showFilterMatchModes={false}
                    showClearButton={false}
                    showFilterOperator={false}
                    showAddButton={false}
                    filterMatchMode={FilterMatchMode.CONTAINS}
                    showApplyButton={false}
                    showFilterMenu={false}
                  />

                  <Column field="type"
                    header={() => (
                      <div
                        className={`custom-header-${sortField === 'type' ? (sortOrder === 1 ? 'up' : 'down') : ''}`}
                        onClick={() => customSort('type')}
                        style={{
                          cursor: 'pointer',
                          display: 'flex',
                          alignItems: 'center',
                          fontSize: '11px',
                          lineHeight: '11px',
                        }}
                      >
                        {i18n("Type")}
                        <CustomeSortIcon sortOrder={sortField === 'type' ? sortOrder : 0}
                          style={{ marginLeft: '4px', verticalAlign: 'middle' }} />
                      </div>
                    )}
                    sortable
                    filter filterPlaceholder=""
                    headerStyle={headerCellStyle}
                    showFilterMatchModes={false}
                    showClearButton={false}
                    showFilterOperator={false}
                    showAddButton={false}
                    filterMatchMode={FilterMatchMode.CONTAINS}
                    showApplyButton={false}
                    showFilterMenu={false}
                  />

                  <Column
                    field="sale_price"
                    header={() => (
                      <div
                        className={`custom-header-${sortField === 'sale_price' ? (sortOrder === 1 ? 'up' : 'down') : ''}`}
                        onClick={() => customSort('sale_price')}
                        style={{
                          cursor: 'pointer',
                          display: 'flex',
                          alignItems: 'center',
                          fontSize: '11px',
                          lineHeight: '11px',
                        }}
                      >
                        {i18n("Price")}
                        <CustomeSortIcon
                          sortOrder={sortField === 'sale_price' ? sortOrder : 0}
                          style={{ marginLeft: '4px', verticalAlign: 'middle' }}
                        />
                      </div>
                    )}
                    sortable
                    filter
                    filterPlaceholder=""
                    headerStyle={headerCellStyle}
                    showFilterMatchModes={false}
                    showClearButton={false}
                    showFilterOperator={false}
                    showAddButton={false}
                    filterMatchMode={FilterMatchMode.CONTAINS}
                    showApplyButton={false}
                    showFilterMenu={false}
                    body={(rowData) => (
                      <span>
                        <GlobalCurrency price={rowData?.sale_price} />
                      </span>
                    )}
                  />

                    <Column
                      field="created_at"
                      header={() => (
                        <div
                          className={`custom-header-${sortField === 'created_at' ? (sortOrder === 1 ? 'up' : 'down') : ''}`}
                          onClick={() => customSort('created_at')}
                          style={{
                            cursor: 'pointer',
                            display: 'flex',
                            alignItems: 'center',
                            fontSize: '11px',
                            lineHeight: '11px',
                          }}
                        >
                          {i18n("Created At")}
                          <CustomeSortIcon sortOrder={sortField === 'created_at' ? sortOrder : 0}
                            style={{ marginLeft: '4px', verticalAlign: 'middle' }} />
                        </div>
                      )}
                      sortable
                      body={dateBodyTemplate}
                      filter
                      filterElement={dateFilterTemplate}
                      filterPlaceholder=""
                      headerStyle={headerCellStyle}
                      showFilterMatchModes={false}
                      showClearButton={false}
                      showFilterOperator={false}
                      showAddButton={false}
                      filterMatchMode={FilterMatchMode.DATE_IS}
                      showApplyButton={false}
                      showFilterMenu={false}
                    />

                    <Column
                      field="deleted_at"
                      header={() => (
                        <div
                          className={`custom-header-${sortField === 'deleted_at' ? (sortOrder === 1 ? 'up' : 'down') : ''}`}
                          onClick={() => customSort('deleted_at')}
                          style={{
                            cursor: 'pointer',
                            display: 'flex',
                            alignItems: 'center',
                            fontSize: '11px',
                            lineHeight: '11px',
                          }}
                        >
                          {i18n("Deleted At")}
                          <CustomeSortIcon sortOrder={sortField === 'deleted_at' ? sortOrder : 0}
                            style={{ marginLeft: '4px', verticalAlign: 'middle' }} />
                        </div>
                      )}
                      sortable
                      body={deletedDateBodyTemplate}
                      filter
                      filterElement={deletedDateFilterTemplate}
                      filterPlaceholder=""
                      headerStyle={headerCellStyle}
                      showFilterMatchModes={false}
                      showClearButton={false}
                      showFilterOperator={false}
                      showAddButton={false}
                      filterMatchMode={FilterMatchMode.DATE_IS}
                      // showApplyButton={false}
                      showFilterMenu={false}
                    />

                    <Column header={i18n("Actions")} body={actionBodyTemplate}
                      headerStyle={headerCellStyle} />
                  </DataTable>
              </div>
            </Container>
            {show && (
                <PermanentDeleteModel
                  onClickDeleteModel={() => setShow(false)}
                  deleteUserClick={handleParmanentDelete}
                  name={deleteName}
                  show={show}

                />
            )}
            {restoreShow && (
                <RestoreModel
                  onClickRestoreModel={() => setShowRestore(false)}
                  restoreUserClick={handleRestore}
                  name={restoreName}
                  show={restoreShow}

                />
            )}
          </div>
        </Container>
      </Layout>
    </>
  );
};
export default DeletedProductIndex;
