import { useDispatch } from "react-redux";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { deleteSubscriptionItem } from "../../../../store/subscriptionProductEntitySlice";

const SubscriptionItemLineIndex = ({ index, serial, item }) => {
  const dispatch = useDispatch();
  const [isHovered, setIsHovered] = useState(false);
  let lineIndex = serial + 1;

  return (
    <td
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {!isHovered || index === 1 ? (
        lineIndex
      ) : (
        <FontAwesomeIcon
          className="text-danger cursor-pointer"
          icon={faTrash}
          title="Delete Line Item"
          onClick={() => {
            let rowCheck = true;
            if (item && item.product_id !== "") {
              // rowCheck = confirm("Are you sure to clear the line item?");
            }
            if (rowCheck) {
              dispatch(deleteSubscriptionItem(index));
            }
          }}
        />
      )}
    </td>
  );
};

export default SubscriptionItemLineIndex;
