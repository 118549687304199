import { Form } from "react-bootstrap";
import { selectVoucherItemById, updateItemField } from "../../store/voucherItemsSlice";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { numValidate } from "../../../shared/helpers/sharedMethods";

const Hsn = ({index,item,is_same_state }) => {
    const dispatch = useDispatch();

    return (
        <Form.Control
            type="text"
            name="hsn"
            placeholder=""
            className=""
            value={item && item?.hsn ? item?.hsn : ''}
            onKeyPress={(event) => numValidate(event)}
            onChange={(e) => {
                let data = {
                    fieldName : "hsn",
                    fieldValue : e.target.value,
                    index,
                    is_same_state
                }
                dispatch(updateItemField(data));
            }}
        />
    );
};

export default Hsn;