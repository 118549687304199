import React, { useEffect, useState } from "react";
import "../../payment/assets/css/payment.css";
import { useSelector, useDispatch } from "react-redux";
import {
  addPaymentDate,
  addPaymentNumber,
  addPaymentType,
  createPayment,
  editPayment,
  fetchSalesbyId,
  sumOfAmount,
  undoPaymentError,
} from "../store/paymentEntitySlice";
import { useLocation, useNavigate, useParams } from "react-router";
import {
  addPartyInPayment,
  // paymentDetail,
  resetPayment,
} from "../store/paymentEntitySlice";
import Layout from "../../shared/components/Layout";
import { ToastContainer, Stack, Button } from "react-bootstrap";
import { Container, Row, Col, Form } from "react-bootstrap";
import PaymentDetails from "../components/PaymentDetails";
import PaymentTypes from "../components/PaymentTypes";
import PaymentAmount from "../components/PaymentAmount";
import PaymentStack from "../components/PaymentStack";
import { i18n, placeholderText } from "../../shared/helpers/sharedMethods";
import {
  addInitPayment,
  resetPartyPayment,
  processingPayment,
  selectAllPartyPaymentIds,
  updatePartyPayment,
  updateCheckedItem,
} from "../store/partyPaymentsSlice";
import {
  addPaymentPreproceesor,
  defaultSelectedPayment,
  iteratePartyPayment,
  totalGetPaidAmount,
} from "../helpers/paymentHelper";
import Header from "../../shared/components/Header";
import { useHotkeys } from "react-hotkeys-hook";
import { changeToggleShow } from "../../shared/store/sharedSlice";
import AlertMessage from "../../shared/components/AlertMessage";
import { getUtcDate } from "../../shared/helpers/localizationHelper";
import ShortcutKeys from "../../shared/components/ShortcutKeys";
import BalanceAmount from "../components/PaymentBalance";
import { selectpartyById } from "../../party/store/getPartySlice";
import { selectVoucherPaymentById } from "../store/paymentSlice";

const EditPayment = (props) => {
  const { type, path, title } = props;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const { id } = useParams();
  console.log("id", id);

  let { editData, voucher_ids } = location.state;
  ;
  console.log('edit', editData);
  console.log('edit', voucher_ids);

  const selectedPaymentData = useSelector((state) => selectVoucherPaymentById(state, id));
  const selectedParty = useSelector((state) => selectpartyById(state, selectedPaymentData?.party_id))
  console.log("selectedParty", selectedPaymentData);
  console.log("selectedParty", selectedParty);

  const voucherId = [];

  selectedPaymentData?.vouchers?.length > 0 && selectedPaymentData?.vouchers.forEach(element => {
    voucherId.push(element.voucher_id)
  })

console.log("voucherId", voucherId);

  const selectVoucherById = useSelector(
    (state) => state.paymentEntity.getVoucherDataByid
  );


  const editPaidAmount = useSelector(
    (state) => state.partyPayments.initialPayment
  );


  const { copyPayment } = useSelector((state) => state.partyPayments);


  const { initialPayment, selectedPayment, processPayment } = useSelector(
    (state) => state.partyPayments
  );
  const { errorStatus, error, totalAmount } = useSelector((state) => state.paymentEntity);
  const partyDetails = useSelector((state) => state.paymentEntity);
  const partyPayments = useSelector((state) => state.partyPayments);
  const selectedBusiness = useSelector(
    (state) => state?.auth?.selectedBusiness
  );

  ;
  const accessToken = useSelector(
    (state) => state?.auth?.accessToken?.access_token
  );
  const tenantId = selectedBusiness?.pivot?.tenant_id;
  const { terminalId } = useSelector((state) => state.initInstall);

  useEffect(() => {
    if (selectedPaymentData) {
      dispatch(addPartyInPayment(selectedPaymentData?.payment_party));

      const totalAmount = selectedPaymentData?.vouchers?.reduce(
        (acc, payment) => acc + payment.amount,
        0
      );
      ;
      dispatch(
        addInitPayment(
          selectedPaymentData.amount - totalAmount >= 0
            ? selectedPaymentData.amount - totalAmount
            : totalAmount - selectedPaymentData.amount


        )
      );
      dispatch(processingPayment(selectedPaymentData?.amount));
      dispatch(addPaymentNumber(selectedPaymentData?.payment_number));
      dispatch(addPaymentDate(selectedPaymentData?.created_at));
      dispatch(addPaymentType(selectedPaymentData?.mode));
      const data = {
        ids: voucherId,
        party_id: selectedPaymentData.payment_party?.id,
        payment_type: selectedPaymentData?.type
      };

      ;
      dispatch(fetchSalesbyId({ tenant_id: tenantId, token: accessToken, data: data }))
    }
  }, [selectedPaymentData]);

  useEffect(() => {
    if (selectedPaymentData?.vouchers?.length > 0) {
      const updatedCheckedItems = {};
      selectedPaymentData?.vouchers.forEach((data) => {
        console.log("data find", data?.voucher_id)
        updatedCheckedItems[data?.voucher_id] = true;
      });
      dispatch(updateCheckedItem(updatedCheckedItems));
    }
  }, [selectedPaymentData]);

  // useEffect(() => {
  //   if (voucher_ids) {
  //     const updatedCheckedItems = {};
  //     voucher_ids.forEach((data) => {
  //       updatedCheckedItems[data] = true;
  //     });
  //     dispatch(updateCheckedItem(updatedCheckedItems));
  //   }
  // }, [voucher_ids]);

  useEffect(() => {
    if (selectVoucherById) {
      const iteratePayments = iteratePartyPayment(selectVoucherById);
      ;
      const amount = selectedPaymentData?.amount;
      const previousSelectedVoucher = editData?.voucher_payment;
      dispatch(
        updatePartyPayment({
          iteratePayments,
          amount,
          voucherId,
          previousSelectedVoucher,
          selectedBusiness,
        })
      );
      console.log('select voucher by id ', selectVoucherById)
      if (selectVoucherById.length > 0) {
        dispatch(sumOfAmount({ payments: selectVoucherById }))
      }

    }
  }, [selectVoucherById]);

  const [errors, setErrors] = useState({
    payment_error: false,
    party_error: false,
  });

  const { party, editStatus } = useSelector((state) => state.paymentEntity);

  useEffect(() => {
    if (editStatus) {
      navigate(`${path}`);
      dispatch(resetPayment());
      dispatch(resetPartyPayment());
    }
  }, [editStatus]);

  ;
  ;

  const handleValidation = () => {
    let valid = true;

    if (!party.id) {
      valid = false;
      setErrors((pre) => ({
        ...pre,
        ["party_error"]: true,
      }));
    } else if (!processPayment > 0) {
      valid = false;
      setErrors((pre) => ({
        ...pre,
        ["payment_error"]: true,
      }));
    }

    return valid;
  };
  const onSubmit = async (event) => {
    const valid = handleValidation();

    try {
      if (valid) {
        const editPaymentData = addPaymentPreproceesor({
          partyDetails,
          partyPayments,
          type,
          totalAmount
        });
        editPaymentData.id = id;
        editPaymentData.previousSelectedVoucher =
          partyPayments?.previousSelectedVoucher;
        // if(!editPaymentData.selectedVoucher.length>0){
        //   let selectedPayments = partyPayments?.copySelectedVoucher
        //   editPaymentData.selectedVoucher = defaultSelectedPayment(selectedPayments,selectedBusiness)
        // }
        editPaymentData.created_by = selectedBusiness?.pivot?.user_id;
        editPaymentData.updated_by = selectedBusiness?.pivot?.user_id;
        editPaymentData.created_at = getUtcDate();
        editPaymentData.updated_at = getUtcDate();
        editPaymentData.party_id = party?.id;
        ;
        dispatch(editPayment({
          data: editPaymentData, token: accessToken,
          tenant_id: tenantId, terminal_id: terminalId
        }));
      }
    } catch (error) {
      ;
    }
  };
  const handleCancel = () => {
    dispatch(changeToggleShow(false));
    navigate(`${path}`);
    dispatch(resetPayment());
    dispatch(resetPartyPayment());
  };
  const handleAlertClose = () => {
    dispatch(undoPaymentError());
  };
  useHotkeys("alt+enter", (event) => {
    event.preventDefault();
    onSubmit();
  });

  useHotkeys("esc", (event) => {
    event.preventDefault();
    handleCancel();
  });

  return (
    <>
      <ToastContainer />
      <Layout currentTitle={title}>
        <Header
          currentTitle={title}
          ItemActions={
            <Stack
              gap={3}
              direction="horizontal"
              className="mt-2 mb-2 justify-content-end"
            >
              <ShortcutKeys />

              <Button className="" variant="primary" onClick={onSubmit}>
                {i18n("Save")}
              </Button>
              <Button
                className=""
                variant="outline-secondary"
                onClick={handleCancel}
              >
                {i18n("Cancel")}
              </Button>
            </Stack>
          }
        />
        {/* <Col className="p-4">
          <Row className="row p-4" style={{ display: "flex", gap: "20px" }}>
            <Col className="position-relative my-3 search-bar col-xxl-8 col-lg-12 col-12">
              <PaymentDetails error={error} setError={setError}  edit={true}/>
            </Col>
            <PaymentTypes error={error} setError={setError} />
          </Row>

          <PaymentAmount error={error} setError={setError} />

          <Col className="p-4">
            <PaymentSelection />
          </Col>
          <PaymentStack edit={true} editData={editData} />
         
        </Col> */}

        <Container className="px-5" fluid>
          <div
            className="border"
            style={{
              background: "white",
              height: "95vh",
              overflow: "auto",
              overflowX: "hidden",
            }}
          >
            {errorStatus && (
              <AlertMessage
                errorStatus={errorStatus}
                error={error}
                handleAlertClose={handleAlertClose}
              />
            )}
            <Row className="px-4">
              <Col lg={3}>
                <PaymentDetails
                  error={errors}
                  setError={setErrors}
                  edit={true}
                  editData={selectedPaymentData}
                />
              </Col>
              <Col lg={6}>
                <PaymentTypes error={errors} setError={setErrors} />
              </Col>
              <Col className="mt-3" lg={3}>
                <PaymentAmount error={errors} setError={setErrors} />
              </Col>
              <Col className="p-4">
                <BalanceAmount />
              </Col>
              <Col lg={12}>
                <PaymentStack
                  edit={true}
                  editData={selectedPaymentData}
                  type={
                    type == "payment_in" ? "sales_invoice" : "purchase_invoice"
                  }
                />
              </Col>
            </Row>
          </div>
        </Container>
      </Layout>
    </>
  );
};
export default EditPayment;
