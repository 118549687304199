import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router";
import ItemInfo from "../components/product_view/tabs/ItemInfo";
import PartywiseReport from "../components/product_view/tabs/PartywiseReport";
import { Button, Container, Form, Stack, ROw } from "react-bootstrap";
import ItemStockReport from "../components/product_view/tabs/ItemStockReport";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare, faTrash } from "@fortawesome/free-solid-svg-icons";
import {
  deleteProduct,
  selectAllProducts,
  selectProductById,
} from "../store/productsSlice";
import {
  getStockReport,
  resetStockData,
  selectStockReportDataById,
} from "../store/stockReportSlice";
import Layout from "../../shared/components/Layout";
import DeleteModel from "../../shared/components/DeleteModel";
import ItemDetailLayout from "../../shared/components/item-detail/ItemDetailLayout";
import { resetCreateProduct } from "../store/productEntitySlice";
import ItemStack from "../../shared/components/item-detail/sections/ItemStack";
import ProductSummary from "../components/product_view/ProductSummary";
import { fetchPartyReport } from "../store/partyWiseReportSlice";
import ItemHeader from "../../shared/components/item-detail/sections/ItemHeader";
import TwoStageLayoutHeader from "../../shared/components/TwoStatgeLayoutHeader";
import Header from "../../shared/components/Header";
import ProductHighlights from "../components/product_view/ProductHighlights";
import { i18n } from "../../shared/helpers/sharedMethods";
import CreateProduct from "./CreateProduct";
import EditProduct from "./EditProduct";

const ProductView = () => {
  const dispatch = useDispatch();
  let { productId } = useParams();
  const navigate = useNavigate();
  const currencySymbol  = useSelector((state) => state.getSetting?.dataSetting.currency);
    ;
  const allProducts = useSelector(selectAllProducts);
  const selectedProduct = useSelector((state) =>
    selectProductById(state, productId)
  );
  const quantity = selectedProduct?.inventories
    ? selectedProduct.inventories[0]?.quantity
    : "0";

  const { accessToken } = useSelector((state) => state?.auth);
  const { terminalId } = useSelector((state) => state.initInstall);
  const selectedBusiness = useSelector(
    (state) => state?.auth?.selectedBusiness
  );
  const tenantId = selectedBusiness?.pivot?.tenant_id;
  const stockReport = useSelector((state) =>
    selectStockReportDataById(state, productId)
  );

  ;
  const [show, setShow] = useState(false);
  const [deleteId, setDeleteId] = useState();
  const [deleteName, setDeleteName] = useState();
  const [showCreate, setShowCreate] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [editProductId, setEditProductId] = useState();

  useEffect(() => {
    if (productId) {
      dispatch(resetStockData());
      dispatch(
        getStockReport({
          id: productId,
          token: accessToken.access_token,
          tenantId: tenantId,
        })
      );
      dispatch(fetchPartyReport({ product_id: productId }));
    }
  }, [productId]);

  const handleCreate = () => {
    dispatch(resetCreateProduct());
    setShowCreate(true);
    // navigate("/catalog/products/create");
  };

  const handleDelete = (delRow) => {
    setShow(!show);
    setDeleteId(delRow.id);
    setDeleteName(delRow.name);
  };
  const handleEdit = (row) => {
    dispatch(resetCreateProduct());
    setShowEdit(true);
    setEditProductId(row.id);
    //navigate(`/catalog/products/edit/${row.id}`, { state: row });
  };
  const handleCreateClose = () => {
    setShowCreate(false);
  };
    const onDelete = () => {
      console.log('triggered');
      if (deleteId) {
        dispatch(deleteProduct({ 
          data: [{ id: deleteId }, { id: deleteId }], 
          token: accessToken.access_token, 
          tenant_id: tenantId, 
          terminal_id: terminalId 
        }));
          setShow(false);
        navigate("/catalog/products");
      } else {
        console.log('deleteId is not defined');
      }
    };
  const handleProductClick = (selectedId) => {
    navigate("/catalog/products/view/" + selectedId);
  };

  const handleReturnToGrid = () => {
    navigate("/catalog/products");
  };

  const productTabs = [
    {
      key: "info",
      title: (i18n("Details")),
      component: <ItemInfo selectedProduct={selectedProduct} />,
    },
    {
      key: "stock-info",
      title: (i18n("Stock Report")),
      component: <ItemStockReport productId={productId} />,
    },
    {
      key: "partywise-report",
      title: (i18n("Partywise Report")),
      component: <PartywiseReport />,
    },
  ];

  const ItemActions = (
    <Stack gap={3} direction="horizontal" className="mt-2 mb-2 float-end">
      <div>
        <Button
          className=""
          variant="outline-danger"
          onClick={() => handleDelete(selectedProduct)}
        >
          <FontAwesomeIcon className="mx-2" icon={faTrash} />
        {i18n("Delete")}
        </Button>
      </div>
      <div>
        <Button
          className=""
          variant="outline-secondary"
          onClick={() => handleEdit(selectedProduct)}
        >
          <FontAwesomeIcon className="mx-2" icon={faPenToSquare} />
        {i18n("Edit")}
        </Button>
      </div>
      <div>
        <Button className="btn-success" onClick={() => handleCreate()}>
        {i18n("Create Product")}
        </Button>
      </div>
    </Stack>
  );

  const itemStack = (
    <Stack>
      <ItemStack
        items={allProducts}
        handleItemClick={handleProductClick}
        currentItemId={productId}
        handleCreate={handleCreate}
        returnToGrid={handleReturnToGrid}
      />
    </Stack>
  );

  // const productSummary = (
  //   <Stack>
  //     <ProductSummary
  //       ItemTitle={selectedProduct.name}
  //       selectedProduct={selectedProduct}
  //     />
  //   </Stack>
  // );

  const productSummary = (
    <>
      <ProductHighlights selectedProduct={selectedProduct} />
    </>
  );

  const header = (
    <TwoStageLayoutHeader
      ItemActions={
        <Stack
          gap={3}
          direction="horizontal"
          className="mt-2 mb-2 justify-content-end"
        >
          <Button
            className=""
            variant="outline-danger"
            onClick={() => handleDelete(selectedProduct)}
          >
            <FontAwesomeIcon className="mx-2 mb-1" icon={faTrash} />
          {i18n("Delete")}
          </Button>

          <Button
            className=""
            variant="outline-secondary"
            onClick={() => handleEdit(selectedProduct)}
          >
            <FontAwesomeIcon className="mx-2 mb-1" icon={faPenToSquare} />
           {i18n("Edit")}
          </Button>
        </Stack>
      }
      currentTitle={
        <>
          {selectedProduct.name}
          {quantity > 0 ? (
            <Form.Label className="mx-2 badge bg-success bg-gradient">{i18n("In Stock")}</Form.Label>
          ) : (
            <Form.Label className="mx-2 badge bg-danger bg-gradient">
            {i18n("Out of Stock")}
            </Form.Label>
          )}
        </>
      }
      returnToGrid={handleReturnToGrid}
    />
  );

  return (
    <Layout>
      <Header
        currentTitle={i18n("Manage Products")}
      />
      <Container fluid>
        <ItemDetailLayout
          summary={productSummary}
          itemStack={itemStack}
          tabs={productTabs}
          type="Product"
          handleItemClick={handleProductClick}
          currentItemId={productId}
          currencySymbol={currencySymbol}
          header={header}
        />

        {show && (
          <DeleteModel
            deleteUserClick={onDelete}
            onClickDeleteModel={setShow}
            name={deleteName}
          />
        )}

        {showCreate && (
          <CreateProduct
            show={showCreate}
            handleClose={handleCreateClose}
            setShowCreate={setShowCreate}
          />
        )}
        {showEdit && (
          <EditProduct
            productId={editProductId}
            show={showEdit}
            setShowEdit={setShowEdit}
          />
        )}
      </Container>
    </Layout>
  );
};

export default ProductView;
