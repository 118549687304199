import React from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { Card } from "react-bootstrap";
import { i18n } from "../../shared/helpers/sharedMethods";

const PartyReportList = () => {
    const navigate = useNavigate();

    const handlePartyReportByItem = () => {
        navigate("/reports/partyReportByItem");
      };

      const handlePartyWise = () => {
        navigate("/reports/partyWise");
      };
      const handlePartyLedger = () => {
        navigate("/reports/partyLedger");
      };
      const handleSaleSummaryCategoryWise = () => {
        navigate("/reports/saleSummaryCategoryWise");
      };
      const handleReceivableAgeing = () => {
        navigate("/reports/receivableAgeing");
      };
    return(
    <>
    <span
              className="section-headings d-flex flex-direction-row p-2 px-4 mt-1"
              style={{ borderBottom: "1px solid lightgray" }}
            >
              <FontAwesomeIcon className="px-1 fs-4" icon={faUser} />{" "}
              <h4>{i18n("Party")}</h4>
            </span>
            <Card.Body className=" py-5">
            <h6
                className="pt-5 fs-4"
                onClick={handleReceivableAgeing}
                style={{ cursor: "pointer" }}
              >
                {i18n("Receivable Ageing Report")}
              </h6>
              <h6
                className="pt-5 fs-4"
                onClick={handlePartyReportByItem}
                style={{ cursor: "pointer" }}
              >
                {i18n("Party Report By Item")}
              </h6>
              <h6
               className="pt-5 fs-4"
               onClick={handlePartyLedger}
               style={{ cursor: "pointer" }}
              >
                {i18n("Party Statement (Ledger)")}
                </h6>
              <h6
                className="pt-5 fs-4"
                onClick={handlePartyWise}
                style={{ cursor: "pointer" }}
              >
              {i18n("Party Wise Outstanding")}
              </h6>
              <h6
                className="pt-5 fs-4"
                onClick={handleSaleSummaryCategoryWise}
                style={{ cursor: "pointer" }}
              >
                Sales Summary - Category Wise
              </h6>
            </Card.Body>
    </>
    );

};

export default PartyReportList;