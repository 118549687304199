import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { i18n } from "../../shared/helpers/sharedMethods";

const TwoStageLayoutHeader = ({ currentTitle, ItemActions, returnToGrid,adjustment,backButtonRemove }) => {
  return (
  
    <div className="d-flex align-items-center  justify-content-between">
      <div className="d-flex  align-items-center">
      {!backButtonRemove && <button onClick={returnToGrid} style={{ border: 'none', background: 'none', cursor: 'pointer' }}>
        <FontAwesomeIcon
          icon={faChevronLeft}
        /> <span className="mt-2 mx-2">{i18n("Back")}</span>
      </button>}

        <h3 className="mt-5 mx-3" style={{ fontWeight: 550 }}>
           {currentTitle}
        </h3>
      </div>
      {ItemActions}
    </div>
   
  );
};

export default TwoStageLayoutHeader;
