import React, { useState, useEffect } from "react";
import {
  Button,
  Row,
  Col,
  Stack,
  Container,
  Badge,
  Table,
  Image,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Layout from "../../shared/components/Layout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faDownload } from "@fortawesome/free-solid-svg-icons";
import { downloadCsvFile, handleExportClick } from "../../shared/export";
import Header from "../../shared/components/Header";
import { fetchReceivableAgeing } from "../store/receivableAgeingReportSlice";
import PartyNavbar from "../components/PartyNavbar";
import { i18n } from "../../shared/helpers/sharedMethods";
import ShortcutKeys from "../../shared/components/ShortcutKeys";
import ReportHeader from "../components/ReportHeader";
import { UilAngleLeft, UilImport } from "@iconscout/react-unicons";
import LoadingSpinner from '../../shared/components/LoadingSpinner';
import GlobalCurrency from "../../shared/components/GlobalCurrency";


const ReceivableAgeingReport = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const accessToken = useSelector((state) => state?.auth?.accessToken?.access_token);
  const selectedBusiness = useSelector((state) => state?.auth?.selectedBusiness);
  const tenantId = selectedBusiness?.pivot?.tenant_id;
  const [csvData, setCsvData] = useState("");
  useEffect(() => {
  if (accessToken && tenantId) {
    dispatch(fetchReceivableAgeing({ token: accessToken, tenant_id: tenantId }));
  }
}, [accessToken, tenantId, dispatch]);

  const reportItems = useSelector(
    (state) => state.receivableAgeing.reportItems
  );
  const status = useSelector((state) => state.receivableAgeing.status);
  const loading = useSelector((state) => state.receivableAgeing.loading);
  const currencySymbol = useSelector(
    (state) => state.getSetting?.dataSetting.currency
  );
  ;
  const [profit1to15Days, setProfit1to15Days] = useState(0);
  const [profit16to30Days, setProfit16to30Days] = useState(0);
  const [profit31to45Days, setProfit31to45Days] = useState(0);
  const [profitMoreThen45Days, setProfitMoreThen45Days] = useState(0);
  const [totalProfit, setTotalProfit] = useState(0);
  useEffect(() => {
    if (status && reportItems && Array.isArray(reportItems)) {
      const last1To15Days = reportItems.reduce(
        (sum, item) => sum + item.last1To15Days,
        0
      );
      const last16To30Days = reportItems.reduce(
        (sum, item) => sum + item.last16To30Days,
        0
      );
      const last31To45Days = reportItems.reduce(
        (sum, item) => sum + item.last31To45Days,
        0
      );
      const moreThen45Days = reportItems.reduce(
        (sum, item) => sum + item.moreThan45Days,
        0
      );
      const totalAmount = reportItems.reduce(
        (sum, item) => sum + parseFloat(item.totalAmount), 0
      );
      setProfit1to15Days(last1To15Days);
      setProfit16to30Days(last16To30Days);
      setProfit31to45Days(last31To45Days);
      setProfitMoreThen45Days(moreThen45Days);
      setTotalProfit(totalAmount);
    }
  }, [status, reportItems]);

  const dateTimeString = new Date()
    .toLocaleString()
    .replace(/[-]/g, " ")
    .replace(/:/g, "-");
  const fileName = `Receivable_ageing_report_${dateTimeString}`;

  const handleExportButtonClick = () => {
    const exportData =
      reportItems &&
      Array.isArray(reportItems) &&
      reportItems.map((val) => ({
        "CUSTOMER NAME": val.partyName ? val.partyName : "-",
        "1-15 DAYS": val?.last1To15Days
          ? parseFloat(val.last1To15Days).toFixed(2)
          : "0",
        "16-30 DAYS": val?.last16To30Days
          ? parseFloat(val.last16To30Days).toFixed(2)
          : "0",
        "31-45 DAYS": val?.last31To45Days
          ? parseFloat(val.last31To45Days).toFixed(2)
          : "0",
        "MORE THAN 45 DAYS": val?.moreThan45Days
          ? parseFloat(val.moreThan45Days).toFixed(2)
          : "0",
        TOTAL: val?.totalAmount ? parseFloat(val.totalAmount).toFixed(2) : "0",
      }));
    handleExportClick(exportData, setCsvData, downloadCsvFile, fileName);
  };

  return (
    <Layout>
      {loading && <LoadingSpinner />}
      <Header
        currentTitle={i18n("Receivable Ageing Report")}
        ItemActions={
          <Stack gap={3} direction="horizontal" className="mt-2 mb-2 float-end">
            <ShortcutKeys />
          </Stack>
        }
      />
      <Container fluid className="px-5">

        <div className="border" style={{ background: "white" }}>
          <PartyNavbar />
          <ReportHeader

            ItemActions={
              <Stack gap={2} direction="horizontal" className="pt-3 px-3 mb-3">

                <Button
                  className="fw-bolder d-flex align-items-center rounded-0"
                  onClick={handleExportButtonClick}
                >
                  <UilImport
                    size="18"
                    className="mx-2"
                    style={{ cursor: "pointer", }}
                  />
                  {i18n("Export")}
                </Button>
              </Stack>
            }
          />

          {status ? (
            <>
              <div className=" px-3 pt-0">
                <Table hover bordered responsive>
                  <thead>
                    <tr className="text-center" style={{color:'#495057'}}>
                      <th style={{ backgroundColor: 'rgb(249, 250, 251)' }}>{i18n("CUSTOMER NAME")}</th>
                      {/* <th>TODAY</th> */}
                      <th style={{ backgroundColor: 'rgb(249, 250, 251)' }}>{i18n("1-15 DAYS")}</th>
                      <th style={{ backgroundColor: 'rgb(249, 250, 251)' }}>{i18n("16-30 DAYS")}</th>
                      <th style={{ backgroundColor: 'rgb(249, 250, 251)' }}>{i18n("31-45 DAYS")}</th>
                      <th style={{ backgroundColor: 'rgb(249, 250, 251)' }}>{i18n("MORE THAN 45 DAYS")}</th>
                      <th style={{ backgroundColor: 'rgb(249, 250, 251)' }}>{i18n("TOTAL")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {reportItems &&
                      reportItems.map((value, index) => (
                        <tr className="text-center" key={index}>
                          <td>{value.partyName ? value.partyName : "-"}</td>
                          {/* <td>{currencySymbol}{value.todayTo1Day ? parseFloat(value.todayTo1Day).toFixed(2) : 0}</td> */}
                          <td>
                            {value.last1To15Days ? (
                              <GlobalCurrency price={value.last1To15Days} />
                            ) : (
                              <GlobalCurrency price={0} />
                            )}
                          </td>
                          <td>
                            {value.last16To30Days ? (
                              <GlobalCurrency price={value.last16To30Days} />
                            ) : (
                              <GlobalCurrency price={0} />
                            )}
                          </td>
                          <td>
                            {value.last31To45Days ? (
                              <GlobalCurrency price={value.last31To45Days} />
                            ) : (
                              <GlobalCurrency price={0} />
                            )}
                          </td>
                          <td>
                            {value.moreThan45Days ? (
                              <GlobalCurrency price={value.moreThan45Days} />
                            ) : (
                              <GlobalCurrency price={0} />
                            )}
                          </td>
                          <td>
                          {value.totalAmount ? (
                                <GlobalCurrency price={parseFloat(value.totalAmount)} />
                              ) : (
                                <GlobalCurrency price={0} />
                              )}
                          </td>
                        </tr>
                      ))}
                    <tr className="text-center">
                      <th className="fw-bolder fs-4 p-2">{i18n("Total")}</th>
                      {/* <th className="fw-bolder fs-4 p-2"></th> */}
                      <th className="fw-bolder fs-4 p-2">
                        {profit1to15Days ? (
                          <GlobalCurrency price={profit1to15Days} />
                        ) : (
                          <GlobalCurrency price={0} />
                        )}
                      </th>
                      <th className="fw-bolder fs-4 p-2">
                        {profit16to30Days ? (
                          <GlobalCurrency price={profit16to30Days} />
                        ) : (
                          <GlobalCurrency price={0} />
                        )}
                      </th>
                      <th className="fw-bolder fs-4 p-2">
                        {profit31to45Days ? (
                          <GlobalCurrency price={profit31to45Days} />
                        ) : (
                          <GlobalCurrency price={0} />
                        )}
                      </th>
                      <th className="fw-bolder fs-4 p-2">
                        {profitMoreThen45Days ? (
                          <GlobalCurrency price={profitMoreThen45Days} />
                        ) : (
                          <GlobalCurrency price={0} />
                        )}
                      </th>
                      <th className="fw-bolder fs-4 p-2">
                        {totalProfit ? (
                          <GlobalCurrency price={parseFloat(totalProfit)} />
                        ) : (
                          <GlobalCurrency price={0} />
                        )}
                      </th>
                    </tr>
                  </tbody>

                </Table>
              </div>
            </>
          ) : (
            <p className="d-flex justify-content-center p-2 fs-3 fw-bolder">
              {i18n("Error while fetching Receivable Ageing Report. Please try again later.")}
            </p>
          )}
        </div>

      </Container>
    </Layout>
  );
};

export default ReceivableAgeingReport;
