import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, Row, Table, Col, Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { i18n } from "../../../shared/helpers/sharedMethods";
import GlobalCurrency from "../../../shared/components/GlobalCurrency";
import { fetchB2BReport } from "../../store/B2BSlice";
import { placeOfSupplyList } from "../../../shared/config/placeOfSupply";
import { FaQuestionCircle } from "react-icons/fa";
import { formatVoucherType } from "../../../shared/helpers/typeHelper";

const B2B = ({ startDate, endDate }) => {
  const dispatch = useDispatch();
  const accessToken = useSelector((state) => state?.auth?.accessToken?.access_token);
  const selectedBusiness = useSelector((state) => state?.auth?.selectedBusiness);
  const tenantId = selectedBusiness?.pivot?.tenant_id;
  const getTransaction = useSelector(
    (state) => state.b2bSalesReport?.reportItems
  );

  useEffect(() => {
    dispatch(fetchB2BReport({startDate: startDate, endDate: endDate,token:accessToken,tenant_id:tenantId})); 
  }, [startDate, endDate]);


  const totalInvValue = getTransaction.reduce((sum, item) => {
    return sum + (item.invoiceValue || 0);
  }, 0);
  const taxableValue = getTransaction.reduce((sum, item) => {
    return sum + (item.taxable_value || 0);
  }, 0);
  const cess = getTransaction.reduce((sum, item) => {
    return sum + (item.cess || 0);
  }, 0);
  const uniquePartyNames = new Set(
    getTransaction.map((item) => item.partyName).filter((name) => name)
  ).size;


  const toolTip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Details of invoices of Taxable supplies made to other registered taxpayers
    </Tooltip>
  );

  return (
    <Row className="p-5 scrollable-table">
      <Card.Body className="">
        <Row  className="pb-4 d-flex justify-content-between align-items-center"
          style={{ borderColor: "#39313d" }}>
          <Col>
          <div className="d-flex flex-wrap">
            <Button className="px-4 me-4" variant="outline-secondary">
              <span style={{ color: "#898989" }}>
                Number of Recipients: <b>{uniquePartyNames}</b>
              </span>
            </Button>
            <Button className="px-4 me-4" variant="outline-secondary">
              <span style={{ color: "#898989" }}>
                Number of Invoices: <b>{getTransaction.length}</b>
              </span>
            </Button>
            <Button className="px-4 me-4" variant="outline-secondary">
              <span style={{ color: "#898989" }}>
                Total Invoice Value:{" "}
                <b>
                  <GlobalCurrency price={totalInvValue} />
                </b>
              </span>
            </Button>
            <Button className="px-4 me-4" variant="outline-secondary">
              <span style={{ color: "#898989" }}>
                Total Taxable Value:{" "}
                <b>
                  <GlobalCurrency price={taxableValue} />
                </b>
              </span>
            </Button>
            <Button className="px-4 me-4" variant="outline-secondary">
              <span style={{ color: "#898989" }}>
                Total Cess:{" "}
                <b>
                  <GlobalCurrency price={cess} />
                </b>
              </span>
            </Button>
            </div>
            </Col>
            <Col
            xs="auto"
            className="d-flex justify-content-end align-items-center"
          >
            <OverlayTrigger placement="bottom" overlay={toolTip}>
              <Button
                variant="outline-secondary"
                style={{
                  color: "#898989",
                  padding: "9px 8px", 
                  lineHeight: 1, 
                }}
              >
                <FaQuestionCircle size={15} />
              </Button>
            </OverlayTrigger>
          </Col>
        </Row>
        <Table bordered hover responsive>
          <thead>
            <tr>
              <th className="px-2">{i18n("GSTIN/UIN of Recipient")}</th>
              <th className="px-2">{i18n("Receivers Name")}</th>
              <th className="px-2">{i18n("Invoice No.")}</th>
              <th className="px-2">{i18n("Invoice date")}</th>
              <th className="px-2">{i18n("Invoice Value")}</th>
              <th className="px-2">{i18n("Place Of Supply")}</th>
              <th className="px-2">{i18n("Applicable Tax %")}</th>
              <th className="px-2">{i18n("Invoice Type")}</th>
              <th className="px-2">{i18n("Rate")}</th>
              <th className="px-2">{i18n("Taxable Value")}</th>
              <th className="px-2">{i18n("CESS")}</th>
              <th className="px-2">{i18n("Ecommerce GSTIN")}</th>
            </tr>
          </thead>
          <tbody>
            {getTransaction &&
              getTransaction.length > 0 &&
              getTransaction.map((data, index) => (
                <tr key={index}>
                  <td className="p-1 px-2 m-1 mx-3">
                    {data.gstin ? data.gstin : " "}
                  </td>
                  <td className="p-1 px-2 m-1 mx-3">
                    {data?.partyName ? data?.partyName : ""}
                  </td>
                  <td className="p-1 px-2 m-1 mx-3">
                    {data.invNo ? data.invNo : ""}
                  </td>

                  <td className="p-1 px-2 m-1 mx-3">
                    <span style={{ fontSize: "12px" }}>
                      {data?.invDate ? data?.invDate : ""}
                    </span>
                  </td>

                  <td className="p-1 px-2 m-1 mx-3">
                    <span style={{ fontSize: "12px" }}>
                      <GlobalCurrency price={data?.invoiceValue ? data?.invoiceValue : ""} />
                    </span>
                  </td>
                  <td className="p-1 px-2 m-1 mx-3">
                    <span style={{ fontSize: "12px" }}>
                      {data?.place_of_supply
                        ? placeOfSupplyList.find(
                            (item) => item.value == data?.place_of_supply
                          )?.label
                        : ""}
                    </span>
                  </td>
                  <td className="p-1 px-2 m-1 mx-3">
                    <span style={{ fontSize: "12px" }}>
                      {""}
                    </span>
                  </td>
                  <td className="p-1 px-2 m-1 mx-3">
                    <span style={{ fontSize: "12px" }}>
                    {formatVoucherType(data.invType) || ""}
                    </span>
                  </td>
                  <td className="p-1 px-2 m-1 mx-3">
                    <span style={{ fontSize: "12px" }}>
                    {data?.taxRate ? data?.taxRate : ""}
                    </span>
                  </td>
                  <td className="p-1 px-2 m-1 mx-3">
                    <span style={{ fontSize: "12px" }}>
                      <GlobalCurrency price={data?.taxable_value ? data?.taxable_value : ""} />
                    </span>
                  </td>
                  <td className="p-1 px-2 m-1 mx-3">
                    <span style={{ fontSize: "12px" }}>
                    <GlobalCurrency price={data?.cess ? data?.cess : ""} />
                    </span>
                  </td>
                  <td className="p-1 px-2 m-1 mx-3">
                    <span style={{ fontSize: "12px" }}>
                      {data?.gstin ? data?.gstin : ""}
                    </span>
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
      </Card.Body>
    </Row>
  );
};

export default B2B;
