import {
  faBriefcase,
  faPalette,
  faSignOut,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Card, Form, ListGroup } from "react-bootstrap";
import { Menu, MenuItem, Sidebar } from "react-pro-sidebar";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { addResourcesBackUp, logout, resetAuth } from "../../auth/store/authSlice";
import { resetCategory } from "../store/categorysSlice";
import { resetProduct } from "../../catalog/store/productsSlice";
import { resetSales } from "../../voucher/store/voucherSlice";
import { resetPayment } from "../../payment/store/paymentEntitySlice";
import { resetInitInstall } from "../../auth/store/initInstallSlice";
import { resetCartVoucherPayment } from "../../payment/store/paymentSlice";
import { resetParty } from "../../party/store/getPartySlice";
import { resetDashboard } from "../../dashboard/store/LatestTransactionSlice";
import { resetSubscription } from "../../subscription/store/SubscriptionSlice";
import { resetSubscriptionItem } from "../../subscription/store/subscription_item_store/GetPackagesItemSlice";
import LogoutModal from "../../shared/components/LogoutModal";
import { useDispatch, useSelector } from "react-redux";
import { resetSetting } from "../../settings/store/getSettingSlice";
import { i18n } from "../../shared/helpers/sharedMethods";
import { resetSubscriptionVoucher } from "../../subscription/store/getAllSubscriptionVoucherSlice";
import { resetEnquiry } from "../../enquiry/store/EnquirySlice";
import { resetEditSetting } from "../store/settingSlice";


const NavbarSetting = () => {
  const { backUpStatus } = useSelector((state) => state.auth)
  const { accessToken, isLoggedIn } = useSelector((state) => state?.auth);
  const selectedBusiness = useSelector(
    (state) => state?.auth?.selectedBusiness
  );
  const terminalNumber = useSelector(
    (state) => state?.auth?.terminalNumber
  );
  
  const tenantId = selectedBusiness?.pivot?.tenant_id;
  const userId = selectedBusiness?.pivot?.user_id;
  
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [show, setShow] = useState(false);
  const globalPlanFeatures = useSelector(
      (state) => state?.globalPlan?.features
  );
  const featureName="subscription"

  const handleLogoutModal = () => {
    setShow(!show);
  };

  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const handleLogout = () => {

    dispatch(logout());
    dispatch(resetAuth());
  };
  const MenuStyle = {
    // backgroundColor: "rgb(40,40,40)",
    color: "black",
    "&:hover": {
      //   backgroundColor: "rgb(40,40,40)",
      color: "black",
      borderLeft: "4px solid #1a237e",
    },
  };
  return (
    <>
      <div
        className="col-lg-12 "
        style={{ height: "90vh", borderRight: "1px solid lightgray" }}
      >
        <ListGroup className="custom-listgroup-css " variant="flush">
          <ListGroup.Item
            className={
              location.pathname == "/setting/business" ? "active-highlight" : ""
            }
            onClick={() => {
              navigate("/setting/business");
            }}
          >
            <Link className="d-flex " to="/setting/business">
              {" "}
              {/* <FontAwesomeIcon
              className="px-2 "
              icon={faBriefcase}
              size="md"
            /> */}
              {i18n("Business Settings")}
            </Link>
          </ListGroup.Item>
          <ListGroup.Item
            className={
              location.pathname == "/setting/invoice"
                ? "active-highlight"
                : ""
            }
            onClick={() => {
              navigate("/setting/invoice");
            }}
          >
            <Link className="d-flex " to="/setting/invoice">
              {/* <FontAwesomeIcon className="mx-2 mt-1" icon={faPalette} size="md" /> */}
              {i18n("Invoice Settings")}
            </Link>
          </ListGroup.Item>
         

          <ListGroup.Item
            className={
              location.pathname == "/setting/invoice-themes" ? "active-highlight" : ""
            }
            onClick={() => {
              navigate("/setting/invoice-themes");
            }}
          >
            <Link className="d-flex " to="/setting/invoice-themes">
              {/* <FontAwesomeIcon className="mx-2 mt-1" icon={faPalette} size="md" /> */}
              {i18n("Invoice Themes")}
            </Link>
          </ListGroup.Item>
          {isOnline && globalPlanFeatures.includes(featureName) && (
          <ListGroup.Item
            className={
              location.pathname == "/setting/membership-card" ? "active-highlight" : ""
            }
            onClick={() => {
              navigate("/setting/membership-card");
            }}
          >
            <Link className="d-flex " to="/setting/membership-card">
              {/* <FontAwesomeIcon className="mx-2 mt-1" icon={faPalette} size="md" /> */}
              {i18n("Membership Card")}
            </Link>
          </ListGroup.Item>
          )}
          <ListGroup.Item
            className={
              location.pathname == "/setting/language-and-currency" ? "active-highlight" : ""
            }
            onClick={() => {
              navigate("/setting/language-and-currency");
            }}
          >
            <Link className="d-flex " to="/setting/language-and-currency">
              {/* <FontAwesomeIcon className="mx-2 mt-1" icon={faPalette} size="md" /> */}
              {i18n("Language and Currency")}
            </Link>
          </ListGroup.Item>

          <ListGroup.Item
            className={
              location.pathname == "/setting/units" ? "active-highlight" : ""
            }
            onClick={() => {
              navigate("/setting/units");
            }}
          >
            <Link className="d-flex " to="/setting/units">
              {i18n("Units")}
            </Link>
          </ListGroup.Item>

          <ListGroup.Item
            className={
              location.pathname == "/setting/tax" ? "active-highlight" : ""
            }
            onClick={() => {
              navigate("/setting/tax");
            }}
          >
            <Link className="d-flex " to="/setting/tax">
              {i18n("Tax")}
            </Link>
          </ListGroup.Item>


          {/* <ListGroup.Item
            className={
              location.pathname == "/setting/advance-settings" ? "active-highlight" : ""
            }
            onClick={() => {
              navigate("/setting/advance-settings");
            }}
          >
            <Link className="d-flex " to="/setting/advance-settings">
              {i18n("Advance settings")}
            </Link>
          </ListGroup.Item> */}
          {/* <ListGroup.Item onClick={handleLogoutModal}>
            <Link className="d-flex ">
              <FontAwesomeIcon
                className=" mt-1 text-danger"
                icon={faSignOut}
                size="md"
              />
              <span className="text-danger mx-2">{i18n("Logout")}</span>
            </Link>
          </ListGroup.Item> */}
        </ListGroup>
        {show && <LogoutModal onConfirm={handleLogout} onCancel={setShow} />}
      </div>
    </>
  );
};

export default NavbarSetting;
