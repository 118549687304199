import React from 'react'
import { ToastContainer } from "react-toastify";
import { Form, Row, Col } from "react-bootstrap";
import SettingsLayout from './SettingsLayout';
import QRCode from "react-qr-code";
import logo from "../../../assets/images/bill.png";
import {  useSelector } from "react-redux";
import { selectSelectedImage } from "../store/settingSlice";
import { placeOfSupplyList } from "../../shared/config/placeOfSupply";
import LoadingSpinner from '../../shared/components/LoadingSpinner';
import { i18n, placeholderText } from "../../shared/helpers/sharedMethods";

const MembershipCardPreview = () => {
  const fetchData = useSelector((state) => state.getSetting?.dataSetting);
  const color1 = useSelector((state) => state.setting?.billing_settings?.color1);
  const color2 = useSelector((state) => state.setting?.billing_settings?.color2);
  const textColor = useSelector((state) => state.setting?.billing_settings?.textColor);
  const qrCodeItemData = "Sample QR code";
  const membershipCardLoading = useSelector((state) => state.getSetting.loading)
  const state = placeOfSupplyList.find(
    (option) => option.value == fetchData?.state
  );

  const selectImage = useSelector(selectSelectedImage);
  const backgroundStyle = () => {
    if (color1 && color2) {
      return {
        backgroundImage: `linear-gradient(107.7deg, ${color1} 8.4%, ${color2} 90.3%)`,
      };
    } else if (color1) {
      return {
        backgroundColor: color1,
      };
    } else if (color2) {
      return {
        backgroundColor: color2,
      };
    }
    return {};
  };

  return (
    <>
      <ToastContainer />
      <SettingsLayout title="Membership Card Preview">
      {membershipCardLoading ? <LoadingSpinner /> : null}
        <div className="d-flex justify-content-end">
          <Col lg={4}></Col>
          <Col lg={4} className='mt-10'>
            <div
              className="d-flex flex-column align-items-center"
              style={{
                border: "1px solid #ccc",
                padding: "15px",
                borderRadius: "8px",
                textAlign: "center",
                boxShadow: "0 4px 8px rgba(0,0,0,0.2)",
                ...backgroundStyle(),
                color: textColor,
                minHeight: "360px",
                maxWidth:"315px"
              }}
            >
              <span style={{
                position: 'relative',
                top: '-8px',
                width: '55px',
                height: '12px',
                borderRadius: '10px',
                backgroundColor: '#fff',
                border: '2px inset darkgrey'
              }}></span>

              <div
                className="d-flex align-items-center"
                style={{
                  padding: "0px 0px",
                  width: "100%",
                  borderRadius: "8px",
                  minHeight: '30px',
                }}
              >
                <img
                  src={selectImage ? selectImage : logo}
                  alt="Logo"
                  style={{
                    objectFit: "contain",
                    maxWidth: "80px",
                    height: "auto",
                    marginRight: "15px",
                  }}
                />

                <div className="text-start">
                  <h4
                    className="fw-bold mb-1"
                    style={{ fontSize: fetchData?.business_name && fetchData.business_name.length <= 23 ? "18px" : "14px" }}
                  >
                    {fetchData?.business_name ? fetchData.business_name : ''}
                  </h4>
                  <p style={{ marginBottom: "2px", fontSize: "12px" }}>
                    {fetchData?.business_address ? fetchData?.business_address + ',' : ''}&nbsp; {fetchData?.city ? fetchData?.city + ',' : ""}
                    &nbsp; {state?.label ? state?.label + ' -' : ''}&nbsp;&nbsp;{" "}
                    {fetchData?.pincode ? fetchData?.pincode : ''}
                  </p>
                  {fetchData?.business_phone ? (
                    <p style={{ fontSize: "12px", marginBottom: "0px" }}>
                      <span style={{ fontWeight: 600 }}>Contact :</span> {fetchData?.business_phone}
                    </p>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div
                style={{
                  width: "100%",
                  borderTop: "1px solid #000",
                  margin: "5px 0",
                }}
              ></div>
              <div style={{ width: "100%" }}>
                <h4 className="fw-bold text-center mb-1 mt-2" style={{ fontSize: "18px" }}>
                  Membership Card
                </h4>

                <div className="mt-3">
                  <div
                    style={{
                      backgroundColor: "white",
                      padding: "10px",
                      display: "inline-block",
                      borderRadius: "5px",
                      boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
                    }}
                  >
                    {/* QR Code */}
                    <QRCode
                      value={qrCodeItemData || ""}
                      size={130}
                      level={"H"}
                      includeMargin={true}
                    />
                  </div>
                  <Form.Label className="d-flex justify-content-center p-3">
                    {/* Scan this QR to check-in / out */}
                  </Form.Label>
                </div>

                <h4 style={{ fontSize: "20px", fontWeight: "bold" }}>
                  {/* <UilUser className="mx-2 mb-1" size="18" /> */}
                  UserName
                </h4>
                <h4 style={{ fontSize: "18px", fontWeight: 500, }}>
                  MEM/001        </h4>
              </div>
            </div>
          </Col>
          <Col lg={4}></Col>

        </div>
      </SettingsLayout>
    </>
  )
}

export default MembershipCardPreview;