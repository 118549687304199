import { Badge, Card, Col, Form, Row } from "react-bootstrap";
import { i18n } from "../../../../../shared/helpers/sharedMethods";
import { taxList } from "../../../../../shared/config/taxConfig";
import { formatAmount } from "../../../../../pos/helpers/cartHelper";
import GlobalCurrency from "../../../../../shared/components/GlobalCurrency";

const PlanProductDetails = ({ data }) => {
  ;
  const taxLabel = taxList.filter((value) => value.code == data?.tax_code);
  ;
  const productType = data?.product?.type || "-";
  const formattedType =
    productType.charAt(0).toUpperCase() + productType.slice(1);

  return (
    <Card className="border px-3" style={{ borderRadius: "5px" }}>
      <Row
        className=" mb-3"
        style={{
          borderBottom: "1px solid lightgray",
          backgroundColor: "#f8f9fa",
          borderTopLeftRadius: "5px",
          borderTopRightRadius: "5px",
        }}
      >
        <Col lg={6} className="py-2">
          <Form.Label
            className="mb-0"
            style={{ fontWeight: 600, fontSize: "13px" }}
          >
            {" "}
            {i18n("Plan")}
          </Form.Label>
        </Col>
        {/* <Col lg={6} className="py-2 d-flex justify-content-end">
          <Badge > {data?.product ? data?.product?.type : "-"}</Badge>
        </Col> */}
      </Row>
      <Row>
        <Col lg={6}>
          {" "}
          <Form.Label
            className=""
            style={{ fontWeight: 500, fontSize: "12px" }}
          >
            {" "}
            {i18n("Name")}
          </Form.Label>
        </Col>
        <Col lg={6}>
          <Form.Label
            className=""
            style={{ fontWeight: 400, fontSize: "12px" }}
          >
            {data?.product ? data?.product?.name : "-"}
          </Form.Label>
        </Col>
      </Row>
      <Row>
        <Col lg={6}>
          {" "}
          <Form.Label
            className=""
            style={{ fontWeight: 500, fontSize: "12px" }}
          >
            {" "}
            {i18n("Code")}
          </Form.Label>
        </Col>
        <Col lg={6}>
          <Form.Label
            className=""
            style={{ fontWeight: 400, fontSize: "12px" }}
          >
            {data?.product ? data?.product?.code : "-"}
          </Form.Label>
        </Col>
      </Row>
      <Row>
        <Col lg={6}>
          {" "}
          <Form.Label
            className=""
            style={{ fontWeight: 500, fontSize: "12px" }}
          >
            {" "}
            {i18n("Type")}
          </Form.Label>
        </Col>
        <Col lg={6}>
          <Badge bg={data?.product?.type == "service" ? "info" : "success"}>
            {" "}
            {formattedType}
          </Badge>
        </Col>
      </Row>
      <Row>
        <Col lg={6}>
          {" "}
          <Form.Label
            className=""
            style={{ fontWeight: 500, fontSize: "12px" }}
          >
            {" "}
            {i18n("Quantity")}
          </Form.Label>
        </Col>
        <Col lg={6}>
          <Form.Label
            className=""
            style={{ fontWeight: 400, fontSize: "12px" }}
          >
            {data?.quantity ? data?.quantity : "-"}
          </Form.Label>
        </Col>
      </Row>
      <Row>
        <Col lg={6}>
          {" "}
          <Form.Label
            className=""
            style={{ fontWeight: 500, fontSize: "12px" }}
          >
            {" "}
            {i18n("Price")}
          </Form.Label>
        </Col>
        <Col lg={6}>
          <Form.Label
            className=""
            style={{ fontWeight: 400, fontSize: "12px" }}
          >
            <GlobalCurrency price={data?.price} />
          </Form.Label>
        </Col>
      </Row>
      <Row>
        <Col lg={6}>
          {" "}
          <Form.Label
            className=""
            style={{ fontWeight: 500, fontSize: "12px" }}
          >
            {" "}
            {i18n("Tax Code")}
          </Form.Label>
        </Col>
        <Col lg={6}>
          <Form.Label
            className=""
            style={{ fontWeight: 400, fontSize: "12px" }}
          >
            {taxLabel ? taxLabel[0]?.label : "-"}
          </Form.Label>
        </Col>
      </Row>
    </Card>
  );
};

export default PlanProductDetails;
