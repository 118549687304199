import { Button, Row, Stack } from "react-bootstrap";
import { exportPreProcessor } from "../../catalog/helper/catalogHelper";
import { downloadCsvFile, handleExportClick } from "../../shared/export";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { fetchProducts, selectAllProducts } from "../../catalog/store/productsSlice";
import { UilImport } from "@iconscout/react-unicons";
import { i18n } from "../../shared/helpers/sharedMethods";


const ProductExport = () => {
  const dispatch = useDispatch();
  const allProducts = useSelector(selectAllProducts);
  const [product, setProducts] = useState(allProducts);
  const [exportData, setExportData] = useState([]);
  const accessToken = useSelector((state) => state?.auth?.accessToken?.access_token);
  const selectedBusiness = useSelector((state) => state?.auth?.selectedBusiness);
  const tenantId = selectedBusiness?.pivot?.tenant_id;
  const [csvData, setCsvData] = useState("");
  useEffect(() => {
    if (allProducts.length > 0) {
      setProducts(allProducts);
    }
  }, [allProducts]);
    useEffect(() => {
      dispatch(fetchProducts({ token: accessToken, tenant_id: tenantId }));
    }, []);
 const dateTimeString = new Date()
   .toLocaleString()
   .replace(/[-]/g, " ")
   .replace(/:/g, "-");
 const fileName = `product_${dateTimeString}`;

 const handleExportProduct = () => {    
   const exportData = exportPreProcessor(product);
   setExportData(exportData);
   handleExportClick(
     exportData,
     (exportData) => {
       setCsvData(exportData);
     },
     downloadCsvFile,
     fileName
   );
 };
  return (
    <>
      {/* <Row>
        <h2 className="px-3 py-1">{"Export Product"}</h2>
      </Row> */}
      <Stack
        gap={2}
        direction="horizontal"
        className="pt-3 px-3 mb-3 d-flex  justify-content-center align-items-center"
      >
        <Button
          className="fw-bolder d-flex align-items-center rounded-0"
          onClick={handleExportProduct}
        >
          <UilImport size="18" className="mx-2" style={{ cursor: "pointer" }} />
          {i18n("Export")}
        </Button>
      </Stack>
    </>
  );
};

export default ProductExport;
