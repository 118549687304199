import React, { useState } from "react";
import { useSelector } from "react-redux";
import {
  ListGroup,
  FormControl,
  InputGroup,
  Row,
  Form,
  Col,
  Button,
  Tooltip,
  OverlayTrigger,
} from "react-bootstrap";
import {
  faArrowLeft,
  faChevronLeft,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SaleModal from "../../../../voucher/components/SaleModal";
import PurchaseModal from "../../../../voucher/components/PurchaseModal";
import { i18n, placeholderText } from "../../../helpers/sharedMethods";

const VoucherItemStack = ({
  items,
  handleItemClick,
  currentItemId,
  handleCreate,
  returnToGrid,
  type,
}) => {
  ;
  const [searchString, setSearchString] = useState("");
  const [filteredItems, setFilteredItems] = useState(items);
  const [show, setShow] = useState(false);
  const [showPurchases, setShowPurchase] = useState(false);

  const handleSearch = (searchParam) => {
    setSearchString(searchParam);
    const filtered = items.filter((datum) =>
      datum.voucher_number.includes(searchParam)
    );
    setFilteredItems(filtered);
  };
  const currencySymbol  = useSelector((state) => state.getSetting?.dataSetting.currency);
  ;

  const Back = <Tooltip id="button-tooltip">{i18n("Back")}</Tooltip>;
  const Search = <Tooltip id="button-tooltip">{i18n("Search")}</Tooltip>;
  const Create = <Tooltip id="button-tooltip">{i18n("Create")}</Tooltip>;

  return (
    <>
      <Row className="">
        <InputGroup size="">
          <OverlayTrigger placement="bottom" overlay={Search}>
            <Form.Control
              type="text"
              id="search"
              name="search"
              placeholder={placeholderText("Search by Name")}
              onChange={(e) => {
                handleSearch(e.target.value);
              }}
              value={searchString}
              className=""
              style={{ borderTop: "none", borderBottom: "none",borderLeft:'none' }}
            />
          </OverlayTrigger>
          {type == "all_sales" && (
            <OverlayTrigger placement="bottom" overlay={Create}>
              <Button
                variant="outline-primary"
                onClick={() => {
                  setShow(true);
                  setShowPurchase(false);
                }}
                style={{ border: "none" }}
              >
                <FontAwesomeIcon className="mb-1" icon={faPlus} /> {i18n("Create")}
              </Button>
            </OverlayTrigger>
          )}
          {type == "all_purchase" && (
            <OverlayTrigger placement="bottom" overlay={Create}>
              <Button
                variant="outline-primary"
                onClick={() => {
                  setShow(false);
                  setShowPurchase(true);
                }}
                style={{ border: "none" }}
              >
                <FontAwesomeIcon className="mb-1" icon={faPlus} /> {i18n("Create")}
              </Button>
            </OverlayTrigger>
          )}
          {type != "all_purchase" && type != "all_sales" && (
            <OverlayTrigger placement="bottom" overlay={Create}>
              <Button
                variant="outline-primary"
                onClick={handleCreate}
                style={{ border: "none" }}
              >
                <FontAwesomeIcon className="mb-1" icon={faPlus} /> {i18n("Create")}
              </Button>
            </OverlayTrigger>
          )}
        </InputGroup>
      </Row>

      <ListGroup
        className="px-2"
        style={{
          height: "85vh",
          overflow: "auto",
          overflowX: "hidden",
          borderTop: "1px solid lightgray",
        }}
      >
        {filteredItems &&
          filteredItems.length > 0 &&
          filteredItems.map((item, index) => {
            ;
            return (
              <>
                <ListGroup.Item
                  key={index}
                  className="mt-3 voucherlist cursor-pointer"
                  active={item.id == currentItemId}
                  onClick={() => {
                    handleItemClick(item.id);
                  }}
                >
                  {/* #{item.voucher_number} */}
                  <Row className=" ">
                    <Col lg={6}>
                      <Form.Group>
                        <Form.Label style={{ fontSize: "12px" }}>
                          {i18n("#")}{item.voucher_number ? item.voucher_number : "-"}
                        </Form.Label>
                      </Form.Group>
                    </Col>
                    <Col lg={6} style={{ textAlign: "end" }}>
                      <Form.Group>
                        <Form.Label style={{ fontSize: "12px" }}>
                          {item.voucher_items
                            ? item.voucher_items?.length
                            : "-"}{" "}
                          {i18n("item")}
                        </Form.Label>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className=" twostage-sidebar-list">
                    <Col lg={6}>
                      <Form.Group>
                        <Form.Label style={{ fontSize: "12px" }}>
                          {item.party ? item.party : "-"}
                        </Form.Label>
                      </Form.Group>
                    </Col>
                    <Col lg={6} style={{ textAlign: "end" }}>
                      <Form.Group>
                        <Form.Label
                          className=""
                          style={{ fontSize: "13px",color:'black' }}
                        >
                          {currencySymbol}{" "}
                          {item.grand_total ? item.grand_total : "-"}
                        </Form.Label>
                      </Form.Group>
                    </Col>
                  </Row>
                </ListGroup.Item>
              </>
            );
          })}
      </ListGroup>
      <SaleModal show={show} setShow={setShow} />
      <PurchaseModal show={showPurchases} setShow={setShowPurchase} />
    </>
  );
};

export default VoucherItemStack;
