import { Col, Form, FormControl, Row } from "react-bootstrap";

import { useDispatch } from "react-redux";
import { changeName } from "../../../store/subscription_item_store/SetupFeesEntitySlice";
import { i18n } from "../../../../shared/helpers/sharedMethods";
import { changeAddonDescription } from "../../../store/subscription_item_store/AddonEntitySlice";

const AddonDescription = ({ values, handleChange, touched, errors }) => {
  const dispatch = useDispatch();
  return (
    <Row className="py-4">
          <Col lg={3} className="">
            <Form.Group>
              <Form.Label>{i18n("Addon Description")} </Form.Label>
            </Form.Group>
          </Col>
          <Col lg={4} className="">
            <Form.Group>
              <Form.Control
                as="textarea"
                name="description"
               value={values.description}
                onChange={(e) => {
                  handleChange(e);
                  dispatch(changeAddonDescription(e.target.value));
                }}
              />
            </Form.Group>
          </Col>
        </Row>
  );
};

export default AddonDescription;