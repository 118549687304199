import React, { useEffect } from "react";
import { Row, Col, Spinner } from "react-bootstrap";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { createTenantLocal, initInstall } from "../store/initInstallSlice";
import { fetchDeviceId, getTerminalNumber, resetAuth } from "../store/authSlice";
import { createAppConfig } from "../store/authSlice";
import { setSelectedBusiness } from "../store/authSlice";
import { resetInitInstall } from "../store/initInstallSlice";
import { i18n } from "../../shared/helpers/sharedMethods";
import { toast, ToastContainer } from "react-toastify";

const InstallingScreen = () => {

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {user,error,currentTenant,selectedBusiness,businessStatus} = useSelector((state) => state?.auth);
  
  const {deviceId,createConfigStatus} = useSelector((state) => state?.auth);

  const deviceIdError = useSelector((state) => state?.auth?.error);

  const initInstallSliceError = useSelector((state) => state?.initInstall?.error);

  const {tenantLocalStatus,initInstallStatus,terminalId, terminalCode, initInstallError, initInstallErrorMessage} = useSelector((state) => state?.initInstall)

  const accessToken = useSelector((state) => state?.auth?.accessToken?.access_token);

  useEffect(()=>{
    dispatch(fetchDeviceId());
   // dispatch(getTerminalNumber());
  },[])

  useEffect(() => {
    if (error || deviceIdError ||initInstallSliceError) {
      navigate("/auth/userDetail");
    }
  }, [error, deviceIdError, initInstallSliceError]);

  

  useEffect(() => {
    if (initInstallStatus) {
      dispatch(setSelectedBusiness(user.tenant));
      dispatch(
        createTenantLocal({
          businessId: currentTenant?.business_id,
          userId: user?.id,
        })
      ); 
    }
  }, [initInstallStatus]);

  useEffect(()=>{

    

    dispatch(setSelectedBusiness(selectedBusiness));

  },[businessStatus])

  useEffect(() => {
    if (user) {
      const installData = {
        user_id: user?.id,
        location: currentTenant?.business_address,
        device_id: deviceId,
      };
      dispatch(initInstall({ tenant_id: currentTenant?.id, installData: installData }));
    }
  }, [user]);

  useEffect(() => {
    if (tenantLocalStatus) {
      const appConfigData = {
        id :1,
        terminal_id: terminalId,
        terminal_code: terminalCode,
        token: accessToken,
        tenant_id: currentTenant?.id,
        is_installed: 1,
        user: user?.id,
      };
       //setTimeout(() => {
      dispatch(createAppConfig(appConfigData));
     
    //}, 5000);
    }
  }, [tenantLocalStatus]);

  useEffect(() => {
    if(initInstallError){
      let toastErrorMsg = (initInstallErrorMessage != '') ? initInstallErrorMessage : "Failed to login! Access might be forbidden!";
      toast.error(toastErrorMsg);
      dispatch(resetInitInstall());
      dispatch(resetAuth());
      setTimeout(() => {
        navigate(0)
      }, 1000);
    }
  },[initInstallError])
  navigate("/dashboard/sales");

  useEffect(()=>{
    if(createConfigStatus){
      console.log('createConfigStatus',createConfigStatus);
      dispatch(resetInitInstall())
      dispatch(resetAuth())
      window.sync.startProcesses();
    }

  },[createConfigStatus])

  const handleBack = () => {
    navigate("/auth/userDetail");
  };

  return (
    
      <Row className="border d-flex flex-column justify-content-center align-items-center h-100 w-100">
        <ToastContainer autoClose={3000} />
        <Col className="d-flex flex-column justify-content-center align-items-center h-100 w-100">
          <Col
            className="d-flex flex-column justify-content-center align-items-center"
            style={{ height: "70%", width: "40%", borderRadius: "5px" }}
          >
            <div>
              <h2>{i18n("Please wait while we set up things for you...!")}</h2>
            </div>
            <div className="mb-5 col-lg-7">
              <div className="d-flex justify-content-center">
                <Spinner animation="border" role="status">
                  <span className="sr-only">{i18n("Loading")}</span>
                </Spinner>
              </div>
            </div>
          </Col>
        </Col>
      </Row>
   
  );
};

export default InstallingScreen;
