import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle, } from "@fortawesome/free-solid-svg-icons";
import { useSelector } from "react-redux";
import { i18n } from "../../../../shared/helpers/sharedMethods";
const IncomeAndExpenseAmount = (props) => {

  const { salesAmount, purchaseAmount } = props;
  const currency = useSelector((state) => state.setting.currency);

  return (
    <>
      <span
        className=" px-4 pt-3 d-flex justify-content-end "
        style={{ color: "#76B947" }}
      >
        <FontAwesomeIcon
          className="px-1"
          icon={faCircle}
          style={{ color: "#76B947", width: "12px", paddingTop: "3px" }}
        />
        {i18n("Collect")}
      </span>
      <h6 className="fw-bolder pt-2 fs-4 d-flex justify-content-end ">
        {currency ? currency : "₹"} {salesAmount ? parseFloat(salesAmount).toFixed(2) :  0}
      </h6>
      <span
        className=" px-8 pt-1 d-flex justify-content-end "
        style={{ color: "#E42256" }}
      >
        <FontAwesomeIcon
          className="px-1"
          icon={faCircle}
          style={{ color: "#E42256", width: "12px", paddingTop: "3px" }}
        />
        {i18n("Pay")}
      </span>
      <h6 className="fw-bolder fs-4 pt-2 d-flex justify-content-end ">
        {currency ? currency : "₹"} {purchaseAmount ? parseFloat(purchaseAmount).toFixed(2) : 0}
      </h6>
    </>
  );
};

export default IncomeAndExpenseAmount;
