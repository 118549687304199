import React, { useState, useEffect } from "react";
import {
  CurrentMonthRange,
  CurrentWeekRange,
  CurrentYearRange,
  PreviousMonthRange,
  PreviousWeekRange,
  PreviousYearRange,
  TodayRange,
  YesterdayRange,
} from "./DataRangeHelper";
import { Stack } from "react-bootstrap";
import { i18n } from "../../helpers/sharedMethods";
import { format } from "date-fns";

const DateRangeIndex = (props) => {
  const { reportPeriod, startDate, endDate } = props;

  const [dateRange, setDateRange] = useState("");
  const today = new Date();
  const [todayDateRange, setTodayDateRange] = useState(format(today, "eeee"));
   useEffect(() => {
    
   }, [startDate, endDate]);
  useEffect(() => {
    if (startDate && endDate) {
      const formattedStartDate = format(new Date(startDate), "dd MMMM yyyy");
      const formattedEndDate = format(new Date(endDate), "dd MMMM yyyy");
      setDateRange(`${formattedStartDate} - ${formattedEndDate}`);
    } else {
      switch (reportPeriod) {
        case "today":
          setDateRange(TodayRange());
          break;
        case "yesterday":
          setDateRange(YesterdayRange());
          break;
        case "current-month":
          setDateRange(CurrentMonthRange());
          break;
        case "previous-month":
          setDateRange(PreviousMonthRange());
          break;
        case "current-week":
          setDateRange(CurrentWeekRange());
          break;
        case "previous-week":
          setDateRange(PreviousWeekRange());
          break;
        case "current-year":
          setDateRange(CurrentYearRange());
          break;
        case "previous-year":
          setDateRange(PreviousYearRange());
          break;
        default:
          setDateRange("");
          break;
      }
    }
  }, [reportPeriod, startDate, endDate]);

  return (
    <Stack direction="horizontal" gap={1}>
      <span
        className="fs-responsive "
        style={{ color: "#767676" }}
      >
        {" "}
        {dateRange ? dateRange : "-"}{" "}
      </span>
    </Stack>

    // <Stack gap={5} direction="horizontal" className="mt-1 d-flex justify-content-between">
    //   <span className="fs-4 " style={{ fontSize: "16px", color: "GrayText" }}>
    //     {i18n("Today ")} -
    //     <span className="fs-3" style={{ color: "black" ,fontSize: "16px"}}>
    //       {" "}
    //      {todayDateRange ? todayDateRange : "-"}{" "}
    //     </span>
    //   </span>
    //   <span className=" fs-4  px-1"style={{ fontSize: "16px" ,color: "GrayText"}}>
    //     {i18n("Report Period")} -

    //     <span className=" px-1 fs-3" style={{ color: "black" ,fontSize: "16px",}}>
    //       {" "}
    //       {dateRange ? dateRange : "-"}{" "}
    //     </span>
    //   </span>
    //   <h3 className="mt-2 "style={{color: "GrayText"}}>Filter By</h3>

    // </Stack>
  );
};

export default DateRangeIndex;
