import { Col, Form, Row } from "react-bootstrap";
import { updateItemField } from "../../store/voucherItemsSlice";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { formatAmount } from "../../../pos/helpers/cartHelper";

const Cess = ({ index, item, cessEnabled, discountEnabled, is_same_state }) => {
    const dispatch = useDispatch();
    let taxableValue = item?.taxable_value;
    let [ itemCess, setItemCess ] = useState(item?.cess);
    let [ itemCessRate, setItemCessRate ] = useState(item?.cess_rate);

    const handleCessRateChange = (event) => {
        let cessRate = event?.target?.value;
        if(cessRate >= 100){
            return false;
        }
        setItemCessRate(cessRate);
        setItemCess(taxableValue * (cessRate/ 100))
    }

    const handleCessChange = (event) => {
        let  cess = event?.target?.value;

        if(cess >= taxableValue){
            return false;
        }
        setItemCess(cess);
        setItemCessRate((cess / taxableValue) * 100);
    }

    useEffect(() => {
        if(!cessEnabled){
            setItemCess(0);
            setItemCessRate(0);
        }
    },[cessEnabled])


    useEffect(() => {
        let cessRateData = {
            fieldName: "cess_rate",
            fieldValue: itemCessRate,
            index,
            discountEnabled,
            is_same_state
        }
        dispatch(updateItemField(cessRateData));

        let cessAmountData = {
            fieldName: "cess",
            fieldValue: itemCess,
            index,
            discountEnabled,
            is_same_state
        }
        dispatch(updateItemField(cessAmountData));

    },[itemCess, itemCessRate])

    // useEffect(() => {
    //     setItemCessRate(itemCessRate);
    //     setItemCess(taxableValue * (itemCessRate / 100));
    //   },[taxableValue])

    return (
        <>
            <td style={{width:"4%"}}>
                <Form.Control 
                    type="number"
                    name="cess_rate"
                    placeholder="%"
                    className="text-end"
                    value={item?.cess_rate ? item?.cess_rate : ''}
                    onChange={handleCessRateChange}
                />
            </td>
            <td>
                <Form.Control 
                    type="number"
                    name="cess"
                    placeholder="0.00"
                    className="text-end"
                    value={item?.cess ? item?.cess : ''}
                    onChange={handleCessChange}
                />
            </td>
        </>

    );
};

export default Cess;