import VoucherIndex from '../screens/VoucherIndex'
import CreateVoucher from '../screens/CreateVoucher'
import EditVoucher from '../screens/EditVoucher'
import VoucherDetail from '../components/VoucherDetails'
import Overview from '../components/OverView'
import { i18n } from '../../shared/helpers/sharedMethods'
import VoucherInvoiceView from '../components/VoucherInvoiceView'
import VoucherImport from '../screens/VoucherImport'

const rootPath = 'voucher'

export const purchaseRoutes =[
    {
        path: rootPath+ '/purchase-overview',
        element:  <Overview  type="purchase"/>,
        isProtected: true
    },
    {
        path: rootPath+'/all-purchase',
        element:<VoucherIndex title={i18n("All Purchase")} type="all_purchase" voucherType ="Purchase" path={rootPath+'/all-purchase'}/>,
        isProtected: true
    },
    {
        path: rootPath+ '/all-purchase/edit/:id',
        element:  <EditVoucher  path={rootPath+'/all-purchase'} title={i18n("Edit Purchase")} type='purchase-invoice' voucherType ="Purchase" />,
        isProtected: true
    },
    {
        path: rootPath+ '/all-purchase/create',
        element: <CreateVoucher purchase={true}  type='purchase-invoice' path={rootPath+'/purchase-invoice'} no='Purchase Inv No' date='Purchase Inv Date' voucherType="Purchase" title={i18n("Create Purchase")} />,
        isProtected: true
    },
    {
        path: rootPath+'/purchase-invoice',
        element:  <VoucherIndex title={i18n("Purchase")} type="purchase_invoice" button={i18n("Create Purchase")}  voucherType ="Purchase" path={rootPath+'/purchase-invoice'} />,
        isProtected: true
    },
    {
        path: rootPath+ '/purchase-invoice/create',
        element: <CreateVoucher purchase={true}  type='purchase_invoice' path={rootPath+'/purchase-invoice'} no='Purchase Inv No' date='Purchase Inv Date' voucherType="Purchase" title={i18n("Create Purchase")} />,
        isProtected: true
    },
    {
        path: rootPath+ '/purchase-invoice/edit/:id',
        element:  <EditVoucher  path={rootPath+'/purchase-invoice'} type='purchase_invoice' title={i18n("Edit Purchase")} />,
        isProtected: true
    },
    // {
    //     path: rootPath+ '/purchaseinvoice/view/:id',
    //     element: <VoucherDetail path={rootPath+'/purchase-invoice'} button={i18n("Create Purchase")} type="purchase_invoice" title={i18n("Manage Purchase")} />,
    //     isProtected: true
    // },
    // {
    //     path: rootPath+ '/all_purchase/view/:id',
    //     element: <VoucherDetail path={rootPath+'/all_purchase'} button={i18n("Create Purchase")} title={i18n("Manage All Purchase")} type="all_purchase"/>,
    //     isProtected: true
    // },
    {
        path: rootPath + "/purchase-invoice/view/:id",
        element: (
          <VoucherInvoiceView
            path={rootPath + "/purchase-invoice"}
            button={i18n("Create Purchase")}
            type="purchase_invoice"
            title={i18n("Manage Purchase")}
          />
        ),
        isProtected: true,
      },
      {
        path: rootPath + "/purchase-invoice/import",
        element: (
          <VoucherImport
          path={rootPath + "/purchase-invoice"}
          type="purchase_invoice"
          title={i18n("Import Purchase Invoice")}
          voucherType="Sales"
          />
        ),
        isProtected: true,
      },
]