import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import bill from "../../../assets/images/bill.png";
import { setCurrentTenantLocal,createAppConfig,setSelectedBusiness } from "../store/authSlice";
import { initInstall, resetInitInstall } from "../store/initInstallSlice";
import { resetProduct } from "../../catalog/store/productsSlice";
import { resetPayment } from "../../payment/store/paymentEntitySlice";
import { resetCategory } from "../../settings/store/categorysSlice";
import { resetParty } from "../../party/store/getPartySlice";
import { resetDashboard } from "../../dashboard/store/LatestTransactionSlice";
import { resetSales } from "../../voucher/store/voucherSlice";
import { resetSubscription } from "../../subscription/store/SubscriptionSlice";
import { resetSubscriptionParty } from "../../subscription/store/LivePartyGetsSlice";
import { resetAuth } from "../store/authSlice";
import {
  Modal,
  Button,
  Form,
  InputGroup,
  Image,
  Col,
  Row,
} from "react-bootstrap";
import { resetCartVoucherPayment } from "../../payment/store/paymentSlice";
import { resetSubscriptionItem } from "../../subscription/store/subscription_item_store/GetPackagesItemSlice";
import { resetSetting } from "../../settings/store/getSettingSlice";
import { i18n, placeholderText } from "../../shared/helpers/sharedMethods";
import { resetSubscriptionVoucher } from "../../subscription/store/getAllSubscriptionVoucherSlice";
import { resetEnquiry } from "../../enquiry/store/EnquirySlice";
import { ToastContainer, toast } from "react-toastify";
import LoadingSpinner from '../../shared/components/LoadingSpinner';

const BusinessSwitchScreen = ({ show, setShow }) => {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {terminalId, terminalCode} = useSelector((state) => state?.initInstall);
  
  const[visibleButton,setVisibleButton]=useState(false)
  const accessToken = useSelector((state) => state?.auth?.accessToken?.access_token);
  const {createConfigStatus,selectedBusiness,tenants,deviceId,businessSelectedStatus,setTenantStatus} = useSelector((state) => state?.auth);
  

  

  const [searchTerm, setSearchTerm] = useState("");
  const [selectedUser, setSelectedUser] = useState();
  
  const [loading, setLoading] = useState(false);
  const [filterData, setFilterData] = useState();
  const {initInstallStatus,initInstallError,initInstallErrorMessage} = useSelector((state) => state?.initInstall)

 

  useEffect(() => {
    if (searchTerm.length == 0) {
      setFilterData(tenants);
    } else {
      setFilterData(filterData);
    }
  }, [searchTerm]);


  useEffect(() => {
    if (initInstallStatus) {
      dispatch(
        setCurrentTenantLocal({
          businessId:selectedUser?.business_id,
          userId: selectedUser?.pivot?.user_id,
        })
      );
    } 
  }, [initInstallStatus]);

  useEffect(() => {
    if (setTenantStatus) {
      const appConfigData = {
        id :1,
        terminal_id: terminalId,
        terminal_code : terminalCode,
        token: accessToken,
        tenant_id: selectedUser?.pivot?.tenant_id,
        is_installed: 1,
        user: selectedUser?.pivot?.user_id,
      };
      
   
      dispatch(createAppConfig(appConfigData));
    }
  }, [setTenantStatus]);

  useEffect(() => {
    if(initInstallError){
      let toastErrorMsg = (initInstallErrorMessage != '') ? initInstallErrorMessage : "Failed to login! Access might be forbidden!";
      toast.error(toastErrorMsg);
      dispatch(resetInitInstall());
      dispatch(resetAuth());
      setTimeout(() => {
        navigate(0)
      }, 1000);
    }
  },[initInstallError])

  useEffect(()=>{
    if(createConfigStatus){ 
      dispatch(setSelectedBusiness(selectedUser));
     
    }
  },[createConfigStatus])

  useEffect(()=>{    
    if(businessSelectedStatus){
      dispatch(resetProduct());
      dispatch(resetSales());
      dispatch(resetPayment());
      dispatch(resetCategory());
      dispatch(resetCartVoucherPayment())
      dispatch(resetParty());
      dispatch(resetDashboard());
      dispatch(resetSubscription());
      dispatch(resetSubscriptionParty());
      dispatch(resetSubscriptionItem())
      setShow(false);
      navigate("/dashboard/sales");
      dispatch(resetAuth())
      dispatch(resetSetting());
      dispatch(resetSubscriptionVoucher());
      dispatch(resetInitInstall())
      dispatch(resetEnquiry())
    }
    },[businessSelectedStatus])

  const handleLogin = () => {
    setLoading(true);
   
    const checkTerminalData = {
      user_id: selectedUser?.pivot?.user_id,
      location: selectedUser?.business_address,
      device_id: deviceId,
    };
    dispatch(
      initInstall({ tenant_id: selectedUser?.pivot?.tenant_id, installData: checkTerminalData })
    ); 
   
  };

  const clearField = () => {
    setShow(!show);
  };

  const handleSearch = (searchparam) => {
    const lowerCaseSearchParam = searchparam.toLowerCase();

    setSearchTerm(searchparam);
    const filteredTenants = tenants.filter((datum) => {
      return (
        datum.business_name &&
        datum.business_name.toLowerCase().includes(lowerCaseSearchParam)
      );
    });
    setFilterData(filteredTenants);
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleLogin();
    }
  };

  const handleClick = () => {
    dispatch(resetAuth())
    navigate("/auth/create-business");
  };

  return (
    <>
      <ToastContainer />
    <Modal className="modal-xl" show={show} onHide={clearField} keyboard={true}>
      <Modal.Header closeButton>
        <Modal.Title>{i18n("Switch Business")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {!loading && (
          <Row className="p-2 h-100 w-100">
            <Col className="" style={{ borderRadius: "5px" }}>
              <Row className="col-lg-12 d-flex" style={{ borderRadius: "5px" }}>
                <Col
                  className="col-lg-6 p-4 d-flex flex-column"
                  style={{
                    borderTopLeftRadius: "5px",
                    borderBottomLeftRadius: "5px",
                    borderLeft: "1px solid lightgray",
                    borderBottom: "1px solid lightgray",
                    borderTop: "1px solid lightgray",
                  }}
                >
                  <Col className="col-lg-8 mb-5">
                    <Form.Label>{i18n("Search")}</Form.Label>
                    <InputGroup>
                      <InputGroup.Text>
                        <i className="fas fa-search"></i>
                      </InputGroup.Text>
                      <Form.Control
                        type="text"
                        placeholder={placeholderText("Search business")}
                        onChange={(e) => {
                          handleSearch(e.target.value);
                        }}
                        value={searchTerm}
                      />
                    </InputGroup>
                  </Col>
                  <h3 className="mb-5">{i18n("Select your business")}</h3>
                  <div
                    className="px-2"
                    style={{ height: "40vh", overflow: "scroll" }}
                  >
                    {filterData && filterData.length > 0 ? (
                      filterData.map((data, index) => (
                        <div key={index}>
                          <div
                            className="d-flex col-lg-12 justify-content-start mb-5 border p-2"
                            style={{
                              borderRadius: "10px",
                              backgroundColor: "whitesmoke",
                            }}
                          >
                            <div className="form-check">
                              <input
                                className="form-check-input cursor-pointer"
                                type="radio"
                                name="flexRadioDefault"
                                id={`flexRadioDefault${index}`}
                                defaultChecked={
                                  selectedBusiness?.business_name ===
                                  data?.business_name
                                }
                                onChange={() =>{ 
                                  setVisibleButton(true)
                                  setSelectedUser(data)
                                }}
                                onKeyPress={handleKeyPress}
                              />
                              <label
                                className="form-check-label cursor-pointer"
                                htmlFor={`flexRadioDefault${index}`}
                              >
                                {data?.business_name}
                              </label>
                            </div>
                          </div>
                        </div>
                      ))
                    ) : (
                      <p>{i18n("No matching business found.")}</p>
                    )}
                  </div>
                  <Button onClick={handleLogin} style={{ display: !visibleButton ? 'none' : 'block' }}>{i18n("Continue")}</Button>
                </Col>
                <Col
                  className="col-lg-6 border"
                  style={{
                    borderTopRightRadius: "5px",
                    borderBottomRightRadius: "5px",
                  }}
                >
                  <Col
                    className="col-lg-12 d-flex flex-column justify-content-center align-items-center h-75 w-100 cursor-pointer mb-5 p-2"
                    onClick={handleClick}
                  >
                    <Col className="mb-5 mt-2 d-flex justify-content-center align-items-center h-100 w-100 col-lg-12">
                      <Image
                        src={bill}
                        alt="Bill Now"
                        style={{ height: "50px" }}
                      />
                    </Col>
                    <Col
                      className="d-flex p-2 col-lg-8 justify-content-center align-items-center border"
                      style={{
                        borderRadius: "10px",
                        backgroundColor: "whitesmoke",
                      }}
                    >
                      {i18n("Create New Business")}
                    </Col>
                  </Col>
                </Col>
              </Row>
            </Col>
          </Row>
        )}
        <Row className="p-2 col-lg-12 ">
          {loading && (
            <Col className="col-lg-12 d-flex flex-column justify-content-center align-items-center h-100 w-100">
              <Col className="mb-5 py-5 d-flex justify-content-center align-items-center h-100 w-100 col-lg-12">
                <Image src={bill} alt="Bill Now" style={{ height: "50px" }} />
              </Col>
              <Col className="py-5">
                <h2>{i18n("Please wait while we switch your business..")}</h2>
              </Col>
              <Col className="py-5">
              {loading && <LoadingSpinner />}
              </Col>
            </Col>
          )}
        </Row>
      </Modal.Body>
    </Modal>
    </>
  );
};

export default BusinessSwitchScreen;
