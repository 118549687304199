import React from "react";
import { Container, Row, Col, Table, Form, Image } from "react-bootstrap";
import { useSelector } from "react-redux";
import { selectSalesById } from "../../../voucher/store/voucherSlice";
import { format } from "date-fns";
import { ToWords } from "to-words";
import { selectSelectedImage } from "../../../settings/store/settingSlice";
import "../css/invoice.css";
import {
  calculateTotalQuantity,
  calculateTaxAmountWithSameTaxRate,
} from "../../helpers/invoiceHelper";
import logo from "../../../../assets/images/bill.png";
import { i18n } from "../../helpers/sharedMethods";
import { QRCode } from "react-qr-code";
import { placeOfSupplyList } from "../../config/placeOfSupply";
import { formatAmount } from "../../../pos/helpers/cartHelper";
import GlobalCurrency from "../GlobalCurrency";

const DefaultStyle = ({ pdf, voucherId }) => {
  const viewSale = useSelector((state) => selectSalesById(state, voucherId));
  console.log('viewSale', viewSale);
  const selectImage = useSelector(selectSelectedImage);
  const selectedBusiness = useSelector(
    (state) => state?.auth?.selectedBusiness
  );
  const fetchData = useSelector((state) => state?.getSetting?.dataSetting);
  const termsAndCondition = useSelector(
    (state) => state?.setting?.billing_settings?.terms_and_conditions
  );

  const grandTotal = viewSale?.grand_total ? viewSale?.grand_total : "0.00";
  const balanceAmount = formatAmount(
    viewSale?.grand_total - viewSale?.paid_amount
  );
  const type = viewSale?.type;
  const words = new ToWords().convert(grandTotal, { currency: true });
  const totalQuantity = calculateTotalQuantity(viewSale?.items);
  //const taxRows = calculateTaxAmountWithSameTaxRate(viewSale?.items);
  const state = placeOfSupplyList.find(
    (option) => option.value == fetchData?.state
  );
  const customerState = placeOfSupplyList.find(
    (option) => option.value == viewSale?.addresses?.billing?.state
  );

  const bankDetailsCheck =
    fetchData?.card_holder_name ||
    fetchData?.account_number ||
    fetchData?.ifsc_code ||
    fetchData?.bank ||
    fetchData?.branch_name;

  const generateQRCode = (UPIPayment) => {
    const upiLink = `upi://pay?pa=${UPIPayment}`;
    return (
      <QRCode
        value={upiLink}
        size={60}
        bgColor={"#ffffff"}
        fgColor={"#000000"}
        level={"L"}
      />
    );
  };

  return (
    <>
      <Container
        fluid
        className="pb-5"
        ref={pdf}
        style={{ backgroundColor: "white" }}
      >
        <Row>
          {viewSale?.type === "sales_invoice" ? (
            <Col className=" p-2 m-0">
              <p style={{ fontWeight: "bolder" }}>
                {i18n("TAX INVOICE")}
                <span
                  className="border border-secondary px-2 mx-2"
                  style={{ fontWeight: 500 }}
                >
                  {i18n("ORIGINAL FOR RECIPIENT")}
                </span>
              </p>
            </Col>
          ) : (
            <Col className="p-2 m-0">
              <p style={{ fontWeight: 600 }}>
                {viewSale?.type === "sales_return" && i18n("SALES RETURN")}
                {viewSale?.type === "credit_note" && i18n("CREDIT NOTE")}
                {viewSale?.type === "quote" && i18n("QUOTATION")}
                {viewSale?.type === "purchase_invoice" && i18n("PURCHASE")}
                {viewSale?.type === "purchase_order" && i18n("PURCHASE ORDER")}
                {viewSale?.type === "debit_note" && i18n("DEBIT NOTE")}
                {viewSale?.type === "purchase_return" &&
                  i18n("PURCHASE RETURN")}
              </p>
            </Col>
          )}
        </Row>
        <Row>
          <Col
            lg={3}
            className="px-0 d-flex align-items-center justify-content-center"
          >
            <img
              src={selectImage ? selectImage : logo}
              width={120}
              height={80}
              alt="Logo"
              style={{
                objectFit: "contain",
              }}
            />
          </Col>
          <Col lg={9}>
            <Row className="mt-5">
              <Row>
                <Col>
                  <Form.Label
                    className=""
                    style={{ fontSize: "28px", fontWeight: 600 }}
                  >
                    {fetchData?.business_name}
                  </Form.Label>
                  <Form.Group>
                    <div className="mb-0">
                      {fetchData?.business_address
                        ? `${fetchData?.business_address} ,`
                        : ""}
                      &nbsp; {fetchData?.city ? `${fetchData?.city} , ` : ""}
                      &nbsp; {state?.label
                        ? `${state?.label}  -`
                        : ""}&nbsp; {fetchData?.pincode}
                    </div>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                {fetchData?.business_phone && (
                  <Col lg={4}>
                    <Form.Label style={{ fontWeight: 600 }} className="mb-0">
                      {i18n("Phone")} :{" "}
                    </Form.Label>{" "}
                    &nbsp;{" "}
                    <Form.Label>
                      {" "}
                      {fetchData?.business_phone
                        ? fetchData?.business_phone
                        : ""}
                    </Form.Label>
                  </Col>
                )}
                {fetchData?.gstin && (
                  <Col lg={4}>
                    <Form.Label className="mb-0" style={{ fontWeight: 600 }}>
                      {i18n("GSTIN")} :
                    </Form.Label>{" "}
                    <Form.Label>
                      {" "}
                      &nbsp; {fetchData?.gstin ? fetchData?.gstin : ""}
                    </Form.Label>
                  </Col>
                )}
                {fetchData?.pan && (
                  <Col lg={4}>
                    <Form.Label className="mb-0" style={{ fontWeight: 600 }}>
                      {i18n("PAN Number")} :{" "}
                    </Form.Label>{" "}
                    &nbsp; {fetchData?.pan ? fetchData?.pan : ""}
                  </Col>
                )}
              </Row>
            </Row>
          </Col>
        </Row>
        <Row className="mt-5">
          <Col>
            <Row>
              <Col
                style={{
                  height: "6px",
                  width: "100%",
                  backgroundColor: "black",
                }}
              ></Col>
            </Row>
            <Row
              style={{
                height: "40px",
                backgroundColor: "#E2E2E2",
                alignItems: "center",
                justifyContent: "space-between",
                fontSize: "14px",
              }}
            >
              <Col>
                <b>
                  {" "}
                  {viewSale?.type === "sales_return" && i18n("Return No")}{" "}
                  {viewSale?.type === "sales_invoice" && i18n("Invoice No")}
                  {viewSale?.type === "quote" && i18n("Quotation No")}{" "}
                  {viewSale?.type === "credit_note" && i18n("Credit Note No")}
                  {viewSale?.type === "purchase_invoice" &&
                    i18n("Purchase No")}{" "}
                  {viewSale?.type === "purchase_return" && i18n("Return No")}
                  {viewSale?.type === "purchase_order" && i18n("PO No")}{" "}
                  {viewSale?.type === "debit_note" && i18n("Debit Note No")} :
                </b>{" "}
                {viewSale?.voucher_number}
              </Col>
              <Col>
                <b>
                  {" "}
                  {viewSale?.type === "sales_return" &&
                    i18n("Return Date")}{" "}
                  {viewSale?.type === "sales_invoice" && i18n("Invoice Date")}
                  {viewSale?.type === "quote" && i18n("Quotation Date")}{" "}
                  {viewSale?.type === "credit_note" && i18n("Credit Note Date")}
                  {viewSale?.type === "purchase_invoice" &&
                    i18n("Purchase Date")}{" "}
                  {viewSale?.type === "purchase_return" && i18n("Return Date")}
                  {viewSale?.type === "purchase_order" && i18n("PO Date")}{" "}
                  {viewSale?.type === "debit_note" && i18n("Debit Note Date")} :
                </b>{" "}
                {viewSale?.voucher_date
                  ? format(new Date(viewSale?.voucher_date), "dd-MM-yyyy")
                  : "-"}
              </Col>

              <Col>
                {(balanceAmount >= 1 && type === "sales_invoice") ||
                  type === "quote" ||
                  type === "proforma_invoice" ||
                  type === "purchase_invoice" ||
                  type === "purchase_order" ? (
                  <>
                    <Form.Label
                      className="mb-0"
                      style={{ fontSize: "13px", fontWeight: 600 }}
                    >
                      <b>
                        {viewSale?.type === "sales_invoice" && i18n("Due Date")}
                        {viewSale?.type === "quote" && i18n("Expiry Date")}
                        {viewSale?.type === "purchase_invoice" &&
                          i18n("Due Date")}
                        {viewSale?.type === "purchase_order" &&
                          i18n("Expiry Date")}{" "}
                        :{" "}
                      </b>
                      {viewSale?.due_date
                        ? format(new Date(viewSale?.due_date), "dd-MM-yyyy")
                        : "-"}
                    </Form.Label>
                  </>
                ) : null}
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="mt-2">
          <Col lg={6} py={2} className="px-0">
            <Row>
              <Col
                style={{
                  fontSize: "13px",
                  fontWeight: "500",
                  marginBottom: "5px",
                }}
              >
                {viewSale?.type === "purchase_invoice" ||
                  viewSale?.type === "purchase_order"
                  ? i18n("BILL FROM")
                  : i18n("BILL TO")}
              </Col>
            </Row>
            <Row>
              <Col style={{ fontWeight: "600", fontSize: "14px" }}>
                {viewSale?.addresses?.billing?.name
                  ? viewSale?.addresses?.billing?.name
                  : viewSale?.party?.name}
              </Col>
            </Row>
            <Row>
              <Col>
                {viewSale?.addresses?.billing?.address && (
                  <Form.Group>
                    <Form.Label className="mb-0">
                      {viewSale?.addresses?.billing?.address}, &nbsp;{" "}
                      {viewSale?.addresses?.billing?.city}, &nbsp;{" "}
                      {customerState?.label} &nbsp;- &nbsp;{" "}
                      {viewSale?.addresses?.billing?.pincode}
                    </Form.Label>
                  </Form.Group>
                )}
              </Col>
            </Row>
            <Row>
              <Col>
                {viewSale?.party?.phone_number && (
                  <Form.Group>
                    <Form.Label className="mb-0">{i18n("Phone")} :</Form.Label>
                    &nbsp; {viewSale?.party?.phone_number}
                  </Form.Group>
                )}
              </Col>
            </Row>
            <Row>
              <Col>
                {viewSale?.party?.gstin && (
                  <Form.Group className="">
                    <Form.Label className="mb-0">{i18n("GSTIN")} :</Form.Label>
                    &nbsp; {viewSale?.party?.gstin}
                  </Form.Group>
                )}
              </Col>
            </Row>
            <Row>
              <Col>
                {viewSale?.party?.pan && (
                  <Form.Group>
                    <Form.Label className="mb-0">
                      {i18n("PAN Number")} :
                    </Form.Label>
                    &nbsp; {viewSale?.party?.pan}
                  </Form.Group>
                )}
              </Col>
            </Row>
          </Col>
          <Col lg={6} py={2} className="px-0">
            <Col
              style={{
                fontSize: "13px",
                fontWeight: "500",
                marginBottom: "5px",
              }}
            >
              {viewSale?.type === "purchase_invoice" ||
                viewSale?.type === "purchase_order"
                ? i18n("SHIP FROM")
                : i18n("SHIP TO")}
            </Col>
            <Row>
              <Col style={{ fontWeight: "600", fontSize: "14px" }}>
                {viewSale?.addresses?.shipping?.name
                  ? viewSale?.addresses?.shipping?.name
                  : viewSale?.party?.name}
              </Col>
            </Row>
            <Row>
              <Col>
                {viewSale?.addresses?.shipping?.address && (
                  <Form.Group>
                    <Form.Label className="mb-0">
                      {viewSale?.addresses?.shipping?.address}, &nbsp;{" "}
                      {viewSale?.address?.shipping?.city}, &nbsp;{" "}
                      {customerState?.label} &nbsp; - &nbsp;{" "}
                      {viewSale?.address?.[1]?.pincode}
                    </Form.Label>
                  </Form.Group>
                )}
              </Col>
            </Row>
            <Row>
              <Col>
                {viewSale?.party?.phone_number && (
                  <Form.Group>
                    <Form.Label>{i18n("Phone")} :</Form.Label>
                    &nbsp; {viewSale?.party?.phone_number}
                  </Form.Group>
                )}
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="mt-5">
          <Table className="table table-borderless">
            <thead>
              <tr
                style={{
                  backgroundColor: "#E2E2E2",
                  borderBottom: "1px solid black",
                  borderTop: "1px solid black",
                }}
              >
                <th
                  style={{
                    fontSize: "13px",
                    fontWeight: 500,
                    textAlign: "left",
                  }}
                >
                  {i18n("S.No")}
                </th>
                <th
                  style={{
                    fontSize: "13px",
                    fontWeight: 500,
                    textAlign: "center",
                  }}
                >
                  {i18n("ITEMS")}
                </th>
                <th
                  style={{
                    textAlign: "center",
                    fontSize: "13px",
                    fontWeight: 500,
                  }}
                >
                  {i18n("HSN")}
                </th>
                <th
                  style={{
                    textAlign: "center",
                    fontSize: "13px",
                    fontWeight: 500,
                  }}
                >
                  {i18n("QTY")}
                </th>
                <th
                  style={{
                    textAlign: "center",
                    fontSize: "13px",
                    fontWeight: 500,
                  }}
                >
                  {i18n("RATE")}
                </th>
                {viewSale?.discount_amount > 0 && (
                  <th
                    style={{
                      textAlign: "center",
                      fontSize: "13px",
                      fontWeight: 500,
                    }}
                  >
                    {i18n("DISC")}
                  </th>
                )}
                {viewSale?.tax_total > 0 && (
                  <th
                    style={{
                      textAlign: "center",
                      fontSize: "13px",
                      fontWeight: 500,
                    }}
                  >
                    {i18n("TAX")}
                  </th>
                )}
                {viewSale?.cess_total > 0 && (
                  <th
                    style={{
                      textAlign: "center",
                      fontSize: "13px",
                      fontWeight: 500,
                    }}
                  >
                    {i18n("CESS")}
                  </th>
                )}
                <th
                  style={{
                    textAlign: "end",
                    fontSize: "13px",
                    fontWeight: 500,
                  }}
                >
                  {i18n("AMOUNT")}
                </th>
              </tr>
            </thead>
            <tbody>
              {viewSale?.voucher_items &&
                viewSale?.voucher_items.length > 0 &&
                viewSale?.voucher_items.map((data, index) => (
                  <tr key={index}>
                    <td
                      className=""
                      style={{
                        fontSize: "13px",
                        fontWeight: 400,
                        borderBottom: "1px solid #E2E2E2",
                        textAlign: "left",
                      }}
                    >
                      {index + 1}
                    </td>
                    <td
                      className=""
                      style={{
                        fontSize: "13px",
                        fontWeight: 400,
                        borderBottom: "1px solid #E2E2E2",
                        textAlign: "center",
                      }}
                    >
                      {data?.product_name}
                    </td>
                    <td
                      className=""
                      style={{
                        textAlign: "center",
                        fontSize: "13px",
                        fontWeight: 400,
                        borderBottom: "1px solid #E2E2E2",
                      }}
                    >
                      {data?.hsn ? data?.hsn : "-"}
                    </td>
                    <td
                      className=""
                      style={{
                        textAlign: "center",
                        fontSize: "13px",
                        fontWeight: 400,
                        borderBottom: "1px solid #E2E2E2",
                      }}
                    >
                      {data?.quantity}
                    </td>
                    <td
                      className=""
                      style={{
                        textAlign: "center",
                        fontSize: "13px",
                        fontWeight: 400,
                        borderBottom: "1px solid #E2E2E2",
                      }}
                    >
                      <GlobalCurrency price={data?.item_price} />
                    </td>
                    {viewSale?.discount_amount > 0 && (
                      <td
                        className=""
                        style={{
                          textAlign: "right",
                          fontSize: "13px",
                          fontWeight: 400,
                          borderBottom: "1px solid #E2E2E2",
                        }}
                      >
                        <span style={{ marginTop: "0px" }}>
                          {data?.item_discount_amount
                            ? formatAmount(
                              parseFloat(data?.item_discount_amount)
                            )
                            : "0"}{" "}
                        </span>
                        <span style={{ fontSize: "11px", color: "#6c757d" }}>
                          {data?.item_discount
                            ? `(${data?.item_discount}%)`
                            : "(0%) "}
                        </span>
                      </td>
                    )}

                    {viewSale?.tax_total > 0 && (
                      <td
                        className=""
                        style={{
                          textAlign: "center",
                          fontSize: "13px",
                          fontWeight: 400,
                          borderBottom: "1px solid #E2E2E2",
                        }}
                      >
                        <span style={{ marginTop: "0px" }}>
                          <GlobalCurrency price={data?.tax_total} />
                        </span>
                        <span style={{ fontSize: "11px", color: "#6c757d" }}>
                          {data?.tax_rate ? `(${data?.tax_rate}%)` : "(0%) "}
                        </span>
                      </td>
                    )}

                    {viewSale?.cess_total > 0 && (
                      <td
                        className=""
                        style={{
                          textAlign: "right",
                          fontSize: "13px",
                          fontWeight: 400,
                          borderBottom: "1px solid #E2E2E2",
                        }}
                      >
                        <span style={{ marginTop: "0px" }}>
                          <GlobalCurrency price={data?.cess} />
                        </span>
                        <span style={{ fontSize: "11px", color: "#6c757d" }}>
                          {data?.cess_rate ? `(${data?.cess_rate}%)` : "(0%) "}
                        </span>
                      </td>
                    )}

                    <td
                      className=""
                      style={{
                        textAlign: "end",
                        borderBottom: "13px",
                        fontWeight: 400,
                        borderBottom: "1px solid #E2E2E2",
                      }}
                    >
                      <GlobalCurrency price={data?.net_total} />
                    </td>
                  </tr>
                ))}
            </tbody>
            <tfoot>
              <tr className="bold">
                <td
                  className="border-top-bottom"
                  style={{ fontSize: "13px", fontWeight: 600 }}
                >
                  {i18n("SUB TOTAL")}
                </td>
                <td
                  className="border-top-bottom"
                  style={{ fontSize: "13px", fontWeight: 600 }}
                ></td>
                <td
                  className="border-top-bottom"
                  style={{ fontSize: "13px", fontWeight: 600 }}
                ></td>
                <td
                  className="border-top-bottom"
                  style={{
                    fontSize: "13px",
                    fontWeight: 600,
                    textAlign: "center",
                  }}
                >
                  {totalQuantity}
                </td>
                <td
                  className="border-top-bottom"
                  style={{ fontSize: "13px", fontWeight: 600 }}
                ></td>
                {viewSale?.discount_amount > 0 && (
                  <td
                    className="border-top-bottom"
                    style={{
                      fontSize: "13px",
                      fontWeight: 600,
                      textAlign: "center",
                    }}
                  >
                    <GlobalCurrency price={viewSale?.discount_amount} />
                  </td>
                )}
                {viewSale?.tax_total > 0 && (
                  <td
                    className="border-top-bottom"
                    style={{
                      fontSize: "13px",
                      fontWeight: 600,
                      textAlign: "center",
                    }}
                  >
                    <GlobalCurrency price={viewSale?.tax_total} />
                  </td>
                )}

                {viewSale?.cess_total > 0 && (
                  <td
                    className="border-top-bottom"
                    style={{
                      fontSize: "13px",
                      fontWeight: 600,
                      textAlign: "center",
                    }}
                  >
                    <GlobalCurrency price={viewSale?.cess_total} />
                  </td>
                )}

                <td
                  className="border-top-bottom"
                  style={{
                    fontSize: "13px",
                    fontWeight: 600,
                    textAlign: "end",
                  }}
                >
                  <GlobalCurrency price={viewSale?.grand_total} />
                </td>
              </tr>
            </tfoot>
          </Table>
        </Row>

        <Row classname="">
          <Col lg={6} className="px-0">
            {bankDetailsCheck && (
              <Col className="mb-3">
                <Form.Label
                  className=""
                  style={{ fontSize: "13px", fontWeight: 600 }}
                >
                  {i18n("BANK DETAILS")}
                </Form.Label>
                <br />
                <Form.Label className="mb-1">
                  {i18n("Name")}:
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  {fetchData?.card_holder_name}
                </Form.Label>
                <br />
                <Form.Label className="mb-1">
                  {i18n("IFSC Code")}:
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  {fetchData?.ifsc_code}
                </Form.Label>
                <br />
                <Form.Label className="mb-1">
                  {i18n("Account No")}: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  {fetchData?.account_number}
                </Form.Label>
                <br />
                {fetchData?.bank || fetchData?.branch_name ? (
                  <Form.Label className="mb-1">
                    {i18n("Bank")}:
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    {fetchData?.bank}, {fetchData?.branch_name}{" "}
                  </Form.Label>
                ) : (
                  ""
                )}
                <br />
              </Col>
            )}
            {fetchData?.upi_payment && (
              <Col className={`${bankDetailsCheck ? "" : "mb-3"}`}>
                <Row lg={12}>
                  <Col lg={6}>
                    <Form.Group>
                      <Form.Label style={{ fontSize: "13px", fontWeight: 600 }}>
                        {i18n("PAYMENT QR CODE")}
                      </Form.Label>
                      <p>
                        {i18n("UPI ID")}: <br /> {fetchData?.upi_payment}{" "}
                      </p>
                    </Form.Group>
                  </Col>
                  <Col lg={6}>
                    {fetchData?.upi_payment &&
                      generateQRCode(fetchData?.upi_payment)}
                  </Col>
                </Row>
              </Col>
            )}
            {viewSale?.tnc ||
              termsAndCondition ? (
              <Col
                className={`${bankDetailsCheck || fetchData?.upi_payment ? "" : ""
                  }`}
              >
                <Form.Label style={{ fontSize: "13px", fontWeight: 600 }}>
                  {i18n("TERMS AND CONDITIONS")}
                </Form.Label>
                <p>{viewSale?.tnc ? viewSale?.tnc : termsAndCondition} </p>
              </Col>
            ) : ""}
          </Col>
          <Col lg={6} style={{ fontSize: "12px" }}>
            {/* {viewSale?.voucher_charges?.length > 0 &&
            viewSale.voucher_charges.map((data, index) => (
              <Row className="p-0" key={index}>
                <Col lg={7} className="px-0">
                  {data?.charge_type}
                </Col>
                <Col lg={5}>
                  <GlobalCurrency price={data?.charge_value} />
                </Col>
              </Row>
            ))} */}


            <Row className="p-0">
              <Col lg={7} className="px-0" style={{ textAlign: "start" }}>
                {i18n("TAXABLE AMOUNT")}
              </Col>
              <Col lg={5} className="p-0" style={{ textAlign: "end" }}>
                <GlobalCurrency price={viewSale?.taxable_value} />
              </Col>
            </Row>
            {viewSale?.voucher_items && viewSale?.voucher_items.length > 0 && viewSale?.voucher_items.map((taxRow, index) => (
              <React.Fragment key={index}>
                {taxRow?.cgst && taxRow?.sgst > 0 ? (
                  <React.Fragment>
                    <Row className="px-1">
                      <Col
                        lg={7}
                        className="px-0"
                        style={{ textAlign: "start" }}
                      >
                        {i18n("CGST")} @{taxRow.tax_rate / 2}%
                      </Col>
                      <Col lg={5} className="p-0" style={{ textAlign: "end" }}>
                        <GlobalCurrency price={taxRow.cgst} />
                      </Col>
                    </Row>
                    <Row className="px-1">
                      <Col
                        lg={7}
                        className="px-0"
                        style={{ textAlign: "start" }}
                      >
                        {i18n("SGST")} @{taxRow.tax_rate / 2}%
                      </Col>
                      <Col className="p-0" lg={5} style={{ textAlign: "end" }}>
                        <GlobalCurrency price={taxRow.sgst} />
                      </Col>
                    </Row>
                  </React.Fragment>
                ) : (
                  <Row className="px-1">
                    <Col lg={7} className="px-0" style={{ textAlign: "start" }}>
                      {i18n("IGST")} @{taxRow.tax_rate}%
                    </Col>
                    <Col lg={5} className="p-0" style={{ textAlign: "end" }}>
                      <GlobalCurrency price={taxRow.igst} />
                    </Col>
                  </Row>
                )}
              </React.Fragment>
            ))}
            {viewSale?.items && viewSale?.items.length > 0 && Array.isArray(viewSale?.items) && viewSale?.items.map((taxRow, index) => {
              if (taxRow.cess > 0) {
                return (
                  <Row key={`cess-${index}`} className="px-1">
                    <Col lg={7} className="px-0" style={{ textAlign: "start" }}>
                      {i18n("Cess")} @{taxRow.cess_rate}%
                    </Col>
                    <Col className="p-0" lg={5} style={{ textAlign: "end" }}>
                      <GlobalCurrency price={taxRow.cess} />
                    </Col>
                  </Row>
                );
              }
            })}

            {viewSale?.roundoff !== 0 && (
              <Row className="px-1">
                <Col lg={7} className="px-0" style={{ textAlign: "start" }}>
                  {i18n("Round Off")}
                </Col>
                <Col lg={5} className="p-0" style={{ textAlign: "end" }}>
                  <GlobalCurrency price={viewSale?.roundoff} />
                </Col>
              </Row>
            )}

            <Row
              className="px-1 py-2 mt-2 bold"
              style={{
                borderTop: "1px solid black",
                borderBottom: "1px solid black",
              }}
            >
              <Col lg={7} className="px-0 " style={{ textAlign: "start" }}>
                {i18n("TOTAL AMOUNT")}{" "}
              </Col>
              <Col
                className="p-0 p"
                style={{ fontWeight: "bold", textAlign: "end" }}
                lg={5}
              >
                <GlobalCurrency price={viewSale?.grand_total} />
              </Col>
            </Row>
            <Row className="px-1 mt-2">
              <Col lg={7} className="px-0" style={{ textAlign: "start" }}>
                {i18n("Recieved Amount")}
              </Col>
              <Col lg={5} className="p-0" style={{ textAlign: "end" }}>
                <GlobalCurrency price={viewSale?.paid_amount} />
              </Col>
            </Row>
            {viewSale?.paid_amount ? (
              <Row className="px-1">
                <Col className="px-0" lg={7} style={{ textAlign: "start" }}>
                  {i18n("Balance Amount")}
                </Col>
                <Col lg={5} className="p-0" style={{ textAlign: "end" }}>
                  <GlobalCurrency price={balanceAmount} />
                </Col>
              </Row>
            ) : (
              ""
            )}
            <Row>
              <Col
                style={{ textAlign: "start" }}
                lg={12}
                className="px-0 py-1 mt-2"
              >
                <Form.Label style={{ fontSize: "13px", fontWeight: 500 }}>
                  {i18n("TOTAL AMOUNT (IN WORDS)")}
                </Form.Label>{" "}
                <br />
                {words ? `${words.toUpperCase()}` : "ZERO RUPEES"}
              </Col>
            </Row>
            <Row className="mt-3">
              {!fetchData?.signature && (
                <Col
                  lg={12}
                  className="border  d-flex justify-content-start"
                  style={{ height: "60px" }}
                ></Col>
              )}
              {fetchData?.signature && (
                <Col lg={12} className="  d-flex justify-content-start">
                  <Image
                    src={fetchData?.signature}
                    alt="signature"
                    style={{ width: "200px", height: "60px" }}
                  />
                </Col>
              )}
            </Row>
            <Row className="mt-2">
              <Col
                lg={12}
                className=" px-0  d-flex flex-column align-items-start"
                height={100}
              >
                <Form.Label
                  className="mb-0"
                  style={{
                    fontSize: "13px",
                    fontWeight: 600,
                  }}
                >
                  {i18n("Authorized Signatory For")}
                </Form.Label>

                <Form.Label style={{ fontSize: "13px" }}>
                  {selectedBusiness?.business_name}{" "}
                </Form.Label>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default DefaultStyle;
