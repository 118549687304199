import React from 'react';
import ReactDOM from 'react-dom';
import { HashRouter } from "react-router-dom";
import App from './App';
import { configureStore } from "@reduxjs/toolkit";
import { Provider } from 'react-redux'
import { persistReducer, persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react'
import localForage from 'localforage';
import { rootReducer } from './rootReducer';
import '@fontsource-variable/nunito-sans';
import './index.css';


const persistConfig = {
  key: 'root',
  storage: localForage,
  whitelist:'auth'
};


const persistedReducer = persistReducer(persistConfig, rootReducer)

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
  getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: ['persist/PERSIST', 'persist/REHYDRATE'],
      ignoredPaths: ['persist'],
    },
  }),
});

const persistor = persistStore(store)

ReactDOM.render(
  <Provider store={store}>
     <PersistGate persistor={persistor}>
          <HashRouter>
              <App />
          </HashRouter>
          </PersistGate>
  </Provider>,
  document.getElementById('root')
);