import {
  Button,
  Card,
  Col,
  Form,
  InputGroup,
  ListGroup,
  Row,
  Stack,
} from "react-bootstrap";
import { i18n, placeholderText } from "../../../shared/helpers/sharedMethods";
import { useDispatch, useSelector } from "react-redux";
import { updateNotes, updateReference } from "../../store/voucherEntitySlice";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCancel,
  faSquareXmark,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";

const Notes = () => {
  const dispatch = useDispatch();

  const { notes, reference } = useSelector((state) => state.voucherEntity);
  const { items } = useSelector((state) => state.voucherEntity);

  const [show, setShow] = useState(false);
  const [referenceShow, setReferenceShow] = useState(false);

  const handleShow = () => {
    setShow(true);
  };
  const handleReferenceShow = () => {
    setReferenceShow(true);
  };
  const handleCancel = () => {
    setShow(false);
  };
  const handleReferenceCancel = () => {
    setReferenceShow(false);
  };

  return (
    <>
      <Stack direction="vertical" className="ms-auto mt-3" gap={2} >
        <Row>
          <Col lg={12}>
            {!show && (
              <div
                className="d-flex justify-content-center p-2 cursor-pointer me-3"
                style={{ border: "1px dashed lightgray" }}
              >
                <Button variant="link" onClick={handleShow}>
                  {i18n("+ Add Notes")}
                </Button>
              </div>
            )}

            {show && (
              <>
                <Row>
                  <Col lg={11}>
                    <Form.Label>
                      <span className="bold" style={{ color: "#4682B4" }}>
                        {i18n("NOTES")}
                      </span>
                    </Form.Label>
                  </Col>
                  <Col lg={1} style={{ paddingLeft: "0px" }}>
                    <InputGroup.Text
                      className="cursor-pointer fs-2 py-0"
                      style={{ background: "none", border: "none" }}
                      onClick={handleCancel}
                    >
                      <FontAwesomeIcon
                        icon={faSquareXmark}
                        title="Clear Item"
                        className="text-danger"
                      />
                    </InputGroup.Text>
                  </Col>
                </Row>

                <Form.Control
                  as="textarea"
                  type="text"
                  name="notes"
                  placeholder={placeholderText("Please Enter Your Note.")}
                  value={notes}
                  onChange={(e) => {
                    dispatch(updateNotes(e.target.value));
                  }}
                />
              </>
            )}
          </Col>
        </Row>

        <div className="my-2" />

        <Row>
          <Col lg={12}>
            {!referenceShow && (
              <div
                className="d-flex justify-content-center p-2 cursor-pointer me-3"
                style={{ border: "1px dashed lightgray" }}
              >
                <Button variant="link" onClick={handleReferenceShow}>
                  {i18n("+ Add Reference")}
                </Button>
              </div>
            )}

            {referenceShow && (
              <>
                <Row>
                  <Col lg={11}>
                    <Form.Label>
                      <span className="bold" style={{ color: "#4682B4" }}>
                        {i18n("REFERENCE")}
                      </span>
                    </Form.Label>
                  </Col>
                  <Col lg={1} style={{ paddingLeft: "0px" }}>
                    <InputGroup.Text
                      className="cursor-pointer fs-2 py-0"
                      style={{ background: "none", border: "none" }}
                      onClick={handleReferenceCancel}
                    >
                      <FontAwesomeIcon
                        icon={faSquareXmark}
                        title="Clear Item"
                        className="text-danger"
                      />
                    </InputGroup.Text>
                  </Col>
                </Row>

                <Form.Control
                  as="textarea"
                  type="text"
                  name="reference"
                  placeholder={placeholderText("Please enter your reference")}
                  value={reference}
                  onChange={(e) => {
                    dispatch(updateReference(e.target.value));
                  }}
                />
              </>
            )}
          </Col>

        </Row>
      </Stack>
    </>
  );
};

export default Notes;
