import React, { useState, useEffect } from "react";
import { Button, Row, Col, Stack, Container ,Image} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Layout from "../../shared/components/Layout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faChevronLeft,
  faDownload,
} from "@fortawesome/free-solid-svg-icons";
import { downloadCsvFile, handleExportClick } from "../../shared/export";
import DataGrid from "../../shared/components/DataGrid";
import { fetchItemSaleAndPurchaseReport } from "../store/itemSaleAndPurchaseReportSlice";
import ReportPeriod from "../../shared/components/ReportPeroid";
import CategoryFilter from "../components/Filters/CategoryFilter";
import Header from "../../shared/components/Header";
import { i18n } from "../../shared/helpers/sharedMethods";
import ItemNavbar from "../components/ItemNavbar";
import ShortcutKeys from "../../shared/components/ShortcutKeys";
import ReportDateRange from "../../shared/components/date_range/ReportDateRange";
import DatePicker from "../../shared/components/date_range/DatePicker";
import { CurrentWeekDateRange } from "../../shared/components/DateRangeYAxis";
import ReportHeader from "../components/ReportHeader";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import CustomeSortIcon from "../../shared/components/CustomeSortIcon";
import { UilImport } from "@iconscout/react-unicons";
import LoadingSpinner from '../../shared/components/LoadingSpinner';
import { fetchCategories } from "../../settings/store/categorysSlice";
const ItemSaleAndPurchaseSummary = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const accessToken = useSelector((state) => state?.auth?.accessToken?.access_token);
  const selectedBusiness = useSelector((state) => state?.auth?.selectedBusiness);
  const tenantId = selectedBusiness?.pivot?.tenant_id;
  const [reportPeriod, setReportPeriod] = useState("current-week");
  const [csvData, setCsvData] = useState("");
  const [categoryData, setCategoryData] = useState("");
  const [startDate, setStartDate] = useState(null); // State for start date
  const [endDate, setEndDate] = useState(null);
  const [sortField, setSortField] = useState(null);
  const [sortOrder, setSortOrder] = useState(1);

  const handleReset = () => {
    const salesDateLimits = ReportDateRange({
      reportPeriod: reportPeriod,
    });
    setStartDate(salesDateLimits.startDate);
    setEndDate(salesDateLimits.endDate);
    setReportPeriod("current-week");
    setCategoryData("");
  };
  useEffect(() => {
    dispatch(fetchCategories());
  }, [dispatch]);

  useEffect(() => {
    dispatch(
      fetchItemSaleAndPurchaseReport({ startDate:startDate, endDate:endDate, categoryData:categoryData, token:accessToken, tenant_id:tenantId })
    );
  }, [startDate, endDate, categoryData]);

    useEffect(() => {
      const dateLimits = ReportDateRange({ reportPeriod });
      setStartDate(dateLimits.startDate);
      setEndDate(dateLimits.endDate);
    }, [reportPeriod]);

  const reportItems = useSelector(
    (state) => state.itemSaleAndPurchaseReport.reportItems
  );
  
  const loading = useSelector((state) => state.itemSaleAndPurchaseReport.loading);
  const status = useSelector((state) => state.itemSaleAndPurchaseReport.status);
  const errorMessage = useSelector(
    (state) => state.itemSaleAndPurchaseReport.errorMessage
  );

  let totalPurchaseQuantity = 0;
  let totalSalesQuantity = 0;
  if (status && Array.isArray(reportItems)) {
    for (const item of reportItems) {
      totalPurchaseQuantity += item.purchaseQuantity || 0;
    }
  }
  if (status && Array.isArray(reportItems)) {
    for (const item of reportItems) {
      totalSalesQuantity += item.saleQuantity || 0;
    }
  }

  const dateTimeString = new Date()
  .toLocaleString()
  .replace(/[-]/g, " ")
  .replace(/:/g, "-");
const fileName = `Item_sale_purchase_summary_${dateTimeString}`;

  const handleExportButtonClick = () => {
    const exportData =
      reportItems &&
      Array.isArray(reportItems) &&
      reportItems.map((val) => ({
        "PRODUCT NAME.": val.product_Name ? val.product_Name : "-",
        "PRODUCT CODE": val.product_code ? val.product_code : "-",
        "PURCHASE QUANTITY": val.purchaseQuantity ? val.purchaseQuantity : "-",
        "SALES QUANTITY": val.saleQuantity ? val.saleQuantity : "-",
      }));
    handleExportClick(
      exportData,
      setCsvData,
      downloadCsvFile,
      fileName
    );
  };
   const customSort = (field) => {
     setSortField(field);
     setSortOrder(sortOrder === 1 ? -1 : 1);
   };
   const sortedData = [...reportItems].sort((a, b) => {
     if (sortField) {
       const compareValueA = a[sortField];
       const compareValueB = b[sortField];
       if (compareValueA < compareValueB) {
         return sortOrder;
       }
       if (compareValueA > compareValueB) {
         return -sortOrder;
       }
     }
     return 0;
   });
   const headerStyle = {
     backgroundColor: "white",
   };
   const headerCellStyle = {
     fontWeight: "bold",
     fontSize: "11px",
   };

   const quantityBodyTemplate = (rowData) => {
     const quantity = rowData?.purchaseQuantity && rowData?.purchaseQuantity;
     const units = rowData?.unit;
     return quantity
       ? `${quantity} ${units ? units : ""}`:0;
   };

   const quantitySalesBodyTemplate = (rowData) => {
     const quantity = rowData?.saleQuantity && rowData?.saleQuantity;
     const units = rowData?.unit;
     return quantity
       ? `${quantity} ${units ? units : ""}`:0;
   };
   
  return (
    <Layout>
        {loading && <LoadingSpinner />}
      <Header
        currentTitle={i18n("Item Sales and Purchase Summary")}
        ItemActions={
          <Stack gap={3} direction="horizontal" className="mt-1 mb-1 float-end">
            <ShortcutKeys />
          </Stack>
        }
      />
      <Container fluid className="px-5">
        <div className="border " style={{ background: "white" }}>
          <ItemNavbar />
          <label className="d-flex flex-direction-column p-3 fs-3">
            {" "}
            {i18n("Total Purchase Quantity")}{" "}&nbsp;:&nbsp;
            <label className=" fw-bolder fs-2 px-2 " style={{ color: "Green" }}>
              {" "}
              {totalPurchaseQuantity ? totalPurchaseQuantity : 0}
            </label>{" "}
          </label>
          <label className="d-flex flex-direction-column px-3 fs-3">
            {" "}
            {i18n("Total Sales Quantity")}{" "}&nbsp;:&nbsp;
            <label className=" fw-bolder fs-2 px-2 " style={{ color: "red" }}>
              {" "}
              {totalSalesQuantity ? totalSalesQuantity : 0}
            </label>{" "}
          </label>
          <ReportHeader
            ItemActions={
              <Stack gap={2} direction="horizontal" className="pt-3 px-3 mb-3">
                <Stack direction="horizontal">
                  <DatePicker
                    startDate={startDate}
                    setStartDate={setStartDate}
                    endDate={endDate}
                    setEndDate={setEndDate}
                  />
                </Stack>
                <Stack className=" ">
                  <ReportPeriod
                    reportPeriod={reportPeriod}
                    setReportPeriod={setReportPeriod}
                  />
                </Stack>
                <Stack className=" ">
                  <CategoryFilter
                    categoryData={categoryData}
                    setCategoryData={setCategoryData}
                    title="product"
                  />
                </Stack>
                <Stack
                  gap={1}
                  direction="horizontal"
                  style={{ marginLeft: "1px" }}
                >
                  <Button
                    className="rounded-0"
                    variant="outline-danger"
                    onClick={handleReset}
                  >
                    {i18n("Reset")}
                  </Button>
                </Stack>
                <Button
                  className="fw-bolder d-flex align-items-center rounded-0"
                  onClick={handleExportButtonClick}
                >
                  <UilImport
                    size="18"
                    className="mx-2"
                    style={{ cursor: "pointer" }}
                  />
                  {i18n("Export")}
                </Button>
              </Stack>
            }
          />
          <Container fluid className="px-3 mb-4">
            <div className="bordered-container">
              {status && reportItems ? (
                <DataTable
                  value={sortedData}
                  headerStyle={headerStyle}
                  paginator
                  rows={10}
                  dataKey="id"
                  emptyMessage={i18n("No Item Sales and Purchase Summary found.")}
                  className="p-datatable-gridlines full-width-table custom-datatable p-component"
                  scrollable
                  scrollHeight="400px"
                  sortMode="multiple"
                  removableSort
                >
                  <Column
                    field="product_Name"
                    header={() => (
                      <div
                        className={`custom-header-${
                          sortField === "product_Name"
                            ? sortOrder === 1
                              ? "up"
                              : "down"
                            : ""
                        }`}
                        onClick={() => customSort("product_Name")}
                        style={{
                          cursor: "pointer",
                          display: "flex",
                          alignItems: "center",
                          fontSize: "11px",
                          lineHeight: "11px",
                        }}
                      >
                        {i18n("PRODUCT NAME")}
                        <CustomeSortIcon
                          sortOrder={
                            sortField === "product_Name" ? sortOrder : 0
                          }
                          style={{ marginLeft: "4px", verticalAlign: "middle" }}
                        />
                      </div>
                    )}
                    sortable
                    headerStyle={headerCellStyle}
                  />
                  <Column
                    field="product_code"
                    header={() => (
                      <div
                        className={`custom-header-${
                          sortField === "product_code"
                            ? sortOrder === 1
                              ? "up"
                              : "down"
                            : ""
                        }`}
                        onClick={() => customSort("product_code")}
                        style={{
                          cursor: "pointer",
                          display: "flex",
                          alignItems: "center",
                          fontSize: "11px",
                          lineHeight: "11px",
                        }}
                      >
                        {i18n("PRODUCT CODE")}
                        <CustomeSortIcon
                          sortOrder={
                            sortField === "product_code" ? sortOrder : 0
                          }
                          style={{ marginLeft: "4px", verticalAlign: "middle" }}
                        />
                      </div>
                    )}
                    sortable
                    headerStyle={headerCellStyle}
                  />

                  <Column
                    field="purchaseQuantity"
                    header={() => (
                      <div
                        className={`custom-header-${
                          sortField === "purchaseQuantity"
                            ? sortOrder === 1
                              ? "up"
                              : "down"
                            : ""
                        }`}
                        onClick={() => customSort("purchaseQuantity")}
                        style={{
                          cursor: "pointer",
                          display: "flex",
                          alignItems: "center",
                          fontSize: "11px",
                          lineHeight: "11px",
                        }}
                      >
                        {i18n("PURCHASE QUANTITY")}
                        <CustomeSortIcon
                          sortOrder={
                            sortField === "purchaseQuantity" ? sortOrder : 0
                          }
                          style={{ marginLeft: "4px", verticalAlign: "middle" }}
                        />
                      </div>
                    )}
                    body={quantityBodyTemplate}
                    sortable
                    headerStyle={headerCellStyle}
                  />

                  <Column
                    field="saleQuantity"
                    header={() => (
                      <div
                        className={`custom-header-${
                          sortField === "saleQuantity"
                            ? sortOrder === 1
                              ? "up"
                              : "down"
                            : ""
                        }`}
                        onClick={() => customSort("saleQuantity")}
                        style={{
                          cursor: "pointer",
                          display: "flex",
                          alignItems: "center",
                          fontSize: "11px",
                          lineHeight: "11px",
                        }}
                      >
                        {i18n("SALES QUANTITY")}
                        <CustomeSortIcon
                          sortOrder={
                            sortField === "saleQuantity" ? sortOrder : 0
                          }
                          style={{ marginLeft: "4px", verticalAlign: "middle" }}
                        />
                      </div>
                    )}
                    body={quantitySalesBodyTemplate}
                    sortable
                    headerStyle={headerCellStyle}
                  />
                </DataTable>
              ) : (
                <p>{i18n("Loading")}</p>
              )}
            </div>
          </Container>
          {/* {status ? (
                <DataGrid
                  allData={reportItems}
                  data={reportItems}
                  columns={gridColumns}
                  isPaginated={true}
                  title="Report"
                />
              ) : (
                <p className="d-flex justify-content-center p-2 fs-3 fw-bolder">
                  {i18n("Error while fetching Item sale and purchase summary")}
                </p>
              )} */}
        </div>
      </Container>
    </Layout>
  );
};

export default ItemSaleAndPurchaseSummary;
