import React, { useState, useEffect } from "react";
import { Stack } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { format } from "date-fns";
import { fetchProductTotalSoldQuantity, lifeTimeSales, productUnitsReturnedQuantity, unitPerDay } from "../../store/ProductTotalSoldQuantitySlice";
import { i18n } from "../../../shared/helpers/sharedMethods";
import { UilHistory, UilHistoryAlt, UilRepeat, UilMoneyBill, UilShoppingCartAlt } from '@iconscout/react-unicons'
import GlobalCurrency from "../../../shared/components/GlobalCurrency";

const ProductHighlights = ({ selectedProduct }) => {
    const dispatch = useDispatch();
    const currencySymbol = useSelector((state) => state.getSetting?.dataSetting.currency);
    ;
    const getTotalSoldQuantity = useSelector(
        (state) => state.productTotalSoldQuantity.productUnitSold
    );

    const accessToken = useSelector((state) => state?.auth?.accessToken?.access_token);
    const selectedBusiness = useSelector((state) => state?.auth?.selectedBusiness);
    const tenantId = selectedBusiness?.pivot?.tenant_id;
    const getUnitReturnQuantity = useSelector(
        (state) => state.productTotalSoldQuantity.productUnitsReturned);

    const lifeTimeValue = useSelector(
        (state) => state.productTotalSoldQuantity.lifeTimeSales
    );
    const unitValue = useSelector(
        (state) => state.productTotalSoldQuantity.unitPerDay
    );


    const profitAmount =
        selectedProduct?.sale_price - selectedProduct?.cost_price;
    const totalProfitAmount = getTotalSoldQuantity * profitAmount;


    useEffect(() => {
        dispatch(fetchProductTotalSoldQuantity({id:selectedProduct?.id, token:accessToken,tenant_id:tenantId}));
        dispatch(productUnitsReturnedQuantity({id:selectedProduct?.id, token:accessToken,tenant_id:tenantId}));
        dispatch(lifeTimeSales({id:selectedProduct?.id, token:accessToken,tenant_id:tenantId}));
        dispatch(unitPerDay({id:selectedProduct?.id, token:accessToken,tenant_id:tenantId}));
    }, [selectedProduct]);


    return (
        <Stack direction="horizontal" gap={2} className="ms-auto border rounded p-4 px-2">
            <Stack gap={2} className="text-center" style={{ borderRight: "1px solid lightgray" }}>
                {/* Order Frequency */}
                <span
                    className="text-black  fw-normal px-3"
                    style={{ fontSize: "15px" }}>

                    {lifeTimeValue}
                </span>
                <span className="text-info h4"> <UilRepeat className="px-1" />{'Life Time Sales'}</span>
            </Stack>
            <Stack gap={2} className="text-center" style={{ borderRight: "1px solid lightgray" }}>
                {/* Units sold */}
                <span
                    className="text-black  fw-normal px-3"
                    style={{ fontSize: "15px" }}>
                    {getTotalSoldQuantity
                        ? parseFloat(getTotalSoldQuantity).toFixed(2)
                        : "0"}{" "}


                </span>
                <span className="text-primary h4"> <UilShoppingCartAlt className="px-1" />{i18n("Units Sold")}</span>
            </Stack>
            <Stack gap={2} className="text-center" style={{ borderRight: "1px solid lightgray" }}>
                {/* Total Profit */}
                <span className="text-black  fw-normal px-3" style={{ fontSize: "15px" }}>
                    <GlobalCurrency price={totalProfitAmount} />
                </span>
                <span className="text-collect h4"> <UilMoneyBill className="px-1" />{i18n("Total Profit")}</span>
            </Stack>





            <Stack gap={2} className="text-center" style={{ borderRight: "1px solid lightgray" }}>
                {/* Return Frequency */}
                <span
                    className="text-black  fw-normal px-3"
                    style={{ fontSize: "15px" }}>

                    {unitValue ? unitValue.toFixed(2) : '0.00'}

                </span>
                <span className="h4" style={{ color: "orange" }}><UilHistoryAlt className="px-1" />{'Units Per Day'}</span>
            </Stack>

            <Stack gap={2} className="text-center" >
                {/* Units Returned */}
                <span
                    className="text-black  fw-normal px-3"
                    style={{ fontSize: "15px" }}>

                    {getUnitReturnQuantity
                        ? parseFloat(getUnitReturnQuantity).toFixed(2)
                        : "0"}{" "}
                </span>
                <span className="text-pay h4"> <UilHistory className="px-1" />{i18n("Units Returned")}</span>
            </Stack>

        </Stack>
    )
}

export default ProductHighlights;