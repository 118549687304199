import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
} from "@reduxjs/toolkit";
import { axiosRequest } from "../../../shared/helpers/axiosHelper";
import { i18n } from "../../../shared/helpers/sharedMethods";
import { cloneDeep } from "lodash";
import { prepareSubscriptionItem } from "../../../pos/helpers/cartHelper";

const planAdapter = createEntityAdapter({});

const initialState = planAdapter.getInitialState({
  status: false,
  error: null,
  data: {},
  showError: false,
  count: {},
  errorMessage:'',
  loading:false,
});

export const planData = createAsyncThunk("plan/fetchPlan", async (args) => {
  ;
  try {
    const token = args.token;
    const tenantId = args.tenant_id;
    const response = await axiosRequest({
      method: "GET",
      url: "subscriptions/product",
      headers: {
        Authorization: `Bearer ${token}`,
        "X-Tenant": tenantId,
      },
    });
    ;
    return response.data;
  } catch (err) {
    await window.log.logToFile({
      message: "Error while fetching plan",
      level: "error",
    });
    throw err;
  }
});

export const currentSubscriptionCount = createAsyncThunk(
  "plan/fetchCount",
  async (args) => {
    try {
      ;
      const token = args.token;
      const tenantId = args.tenant_id;
      const response = await axiosRequest({
        method: "GET",
        url: `subscription-count`,
        headers: {
          Authorization: `Bearer ${token}`,
          "X-Tenant": tenantId,
        },
      });
      ;
      return response;
    } catch (error) {
      await window.log.logToFile({
        message: "Error while fetching subscription count",
        level: "error",
      });
      throw error;
    }
  }
);

export const planIdData = createAsyncThunk("plan/fetchid", async (args) => {
  try {
    const token = args.token;
    const tenantId = args.tenant_id;
    const response = await axiosRequest({
      method: "GET",
      url: `subscriptions/product/${args.id}`,
      headers: {
        Authorization: `Bearer ${token}`,
        "X-Tenant": tenantId,
      },
    });
    return response.data.data;
  } catch (err) {
    await window.log.logToFile({
      message: "Error while fetching subscription product Id",
      level: "error",
    });
    throw err;
  }
});

export const Deleteplan = createAsyncThunk("plan/delete", async (args) => {
  try {
    const token = args.token;
    const id = args.id;
    const tenantId = args.tenant_id;
    const response = await axiosRequest({
      method: "DELETE",
      url: `subscriptions/product/${id}`,
      headers: {
        Authorization: `Bearer ${token}`,
        "X-Tenant": tenantId,
      },
    });
    return id;
  } catch (error) {
    await window.log.logToFile({
      message: "Error while deleting plan",
      level: "error",
    });
    throw error;
  }
});

const getPackageItemSlice = createSlice({
  name: "getPackage",
  initialState,
  reducers: {
    addNewProduct: planAdapter.addOne,
    updateProduct(state, action) {
      const { id } = action.payload;
      state.entities[id] = action.payload;
    },

    resetSubscriptionItem: () => initialState,
    resetErrorField: (state) => {
      state.error = null; state.showError = false;
    },
    resetStatus(state, action) {
      state.status = false;
      state.errorMessage='';
    },
  },
  extraReducers(builder) {
    builder
    .addCase(planData.pending, (state, action) => {
      state.loading=true;
     })
      .addCase(planData.fulfilled, (state, action) => {
        state.status = true;
        state.loading=false;
        planAdapter.upsertMany(state, action.payload);
      })
      .addCase(planData.rejected, (state, action) => {
        state.status = false;
        state.loading=false;
        state.error = i18n("Error while fetching plan. Please try again later");
      })

      .addCase(planIdData.fulfilled, (state, action) => {
        state.status = true;
        state.data = action.payload;
      })
      .addCase(planIdData.rejected, (state, action) => {
        state.status = false;
        state.error = i18n(
          "Unable to retrieve plan ID. Please try again later."
        );
      })
      .addCase(currentSubscriptionCount.fulfilled, (state, action) => {
        ;
        state.status = true;
        state.count = action.payload;
      })
      .addCase(currentSubscriptionCount.rejected, (state, action) => {
        state.status = false;
        state.error = i18n(
          "Unable to retrieve plan ID. Please try again later."
        );
      })
      .addCase(Deleteplan.fulfilled, planAdapter.removeOne)
      .addCase(Deleteplan.rejected, (state, action) => {
        ;
        state.status = false;
        state.errorMessage=action.error.message;
        state.error = i18n(
          "Unable to delete plan. Please try again later."
        );
        state.showError = true;
      });
  },
});

export default getPackageItemSlice.reducer;

export const {
  resetPlan,
  addNewProduct,
  updateProduct,
  resetErrorField,
  resetSubscriptionItem,
  resetStatus,
} = getPackageItemSlice.actions;
export const {
  selectAll: selectAllPlanData,
  selectById: selectPlanDataById,
  selectIds: selectPlanDataIds,
} = planAdapter.getSelectors((state) => state.getPackage);

export const selectProductAsSubscriptionItems = createSelector(
  [selectAllPlanData, (state, type) => type],
  (products, type) => {
    ;
    let tempItems = [];

    products.forEach((element) => {
      if (element.parent_product === null) {
        let clonedEl = cloneDeep(element);
        tempItems.push(clonedEl);
      }
    });
    return tempItems;
  }
);

export const currentProductAsSubscriptionItems = createSelector(
  [selectAllPlanData, (state, id) => id],
  (products, id) => {
    ;
    let tempItems = [];

    products.forEach((element) => {
      if (element.parent_product == null && element.id == id) {
        let clonedEl = cloneDeep(element);
        tempItems.push(clonedEl);
      }
    });
    return tempItems;
  }
);

export const selectProductAsSubscriptionPlans = createSelector(
  [selectAllPlanData, (state, id) => id],
  (products, id) => {
    if (!products) {
      return [];
    }
    ;
    let tempItems = [];

    products.forEach((element) => {
      if (element.child_products && element.child_products.length > 0) {
        element.child_products.forEach((childProduct) => {
          if (
            childProduct.sub_type == "plan" &&
            childProduct?.parent_product == id
          ) {
            let clonedChild = cloneDeep(childProduct);
            ;
            tempItems.push(prepareSubscriptionItem(clonedChild));
          }
        });
      }
    });
    ;
    return tempItems;
  }
);

export const selectProductAsSubscriptionAddon = createSelector(
  [selectAllPlanData, (state, id) => id],
  (products, id) => {
    if (!products) {
      return [];
    }
    ;
    let tempItems = [];
    products.forEach((element) => {
      if (element.child_products && element.child_products.length > 0) {
        element.child_products.forEach((childProduct) => {
          if (
            childProduct.sub_type == "addon" &&
            childProduct?.parent_product == id
          ) {
            let clonedChild = cloneDeep(childProduct);
            tempItems.push(prepareSubscriptionItem(clonedChild));
            ;
          }
        });
      }
    });

    return tempItems;
  }
);

export const selectProductAsSubscriptionSetupFee = createSelector(
  [selectAllPlanData, (state, id) => id],
  (products, id) => {
    if (!products) {
      return [];
    }
    ;
    let tempItems = [];
    products.forEach((element) => {
      if (element.child_products && element.child_products.length > 0) {
        element.child_products.forEach((childProduct) => {
          if (
            childProduct.sub_type == "setup_fee" &&
            childProduct?.parent_product == id
          ) {
            let clonedChild = cloneDeep(childProduct);
            tempItems.push(prepareSubscriptionItem(clonedChild));
            ;
          }
        });
      }
    });

    return tempItems;
  }
);

export const selectAllPackagePlan = createSelector(
  [selectAllPlanData, (state, id) => id],
  (products, id) => {
    if (!products) {
      return [];
    }
    ;
    let tempItems = [];

    products.forEach((element) => {
      if (element.child_products && element.child_products.length > 0) {
        element.child_products.forEach((childProduct) => {
          if (childProduct?.sub_type == "plan" && childProduct?.id == id) {
            let clonedChild = cloneDeep(childProduct);
            ;
            tempItems.push(clonedChild);
          }
        });
      }
    });

    return tempItems;
  }
);

export const selectAllPackageAddon = createSelector(
  [selectAllPlanData, (state, id) => id],
  (products, id) => {
    if (!products) {
      return [];
    }
    ;
    let tempItems = [];

    products.forEach((element) => {
      if (element.child_products && element.child_products.length > 0) {
        element.child_products.forEach((childProduct) => {
          if (childProduct?.sub_type == "addon" && childProduct?.id == id) {
            let clonedChild = cloneDeep(childProduct);
            ;
            tempItems.push(clonedChild);
          }
        });
      }
    });

    return tempItems;
  }
);

export const selectAllSetupFees = createSelector(
  [selectAllPlanData, (state, id) => id],
  (products, id) => {
    if (!products) {
      return [];
    }
    ;
    let tempItems = [];
    products.forEach((element) => {
      if (element.child_products && element.child_products.length > 0) {
        element.child_products.forEach((childProduct) => {
          if (childProduct?.sub_type == "setup_fee" && childProduct?.id == id) {
            let clonedChild = cloneDeep(childProduct);
            ;
            tempItems.push(clonedChild);
          }
        });
      }
    });

    return tempItems;
  }
);
