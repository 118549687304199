import React from "react";
import { Button } from "react-bootstrap";

const CreateButton = ({ handleCreate, title, handleExportClick }) => {
  return (
    <>
      <Button className='btn-primary' onClick={handleExportClick || handleCreate}>
        {title}
      </Button>
    </>
  );
};

export default CreateButton;
