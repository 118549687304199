import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import { subscriptionPlanCartItem } from "../../../pos/helpers/cartHelper";

const initialState = {
  name: "",
  code: "",
  parent_product: null,
  type: "service",
  unit: "UNT",
  sale_price: "",
  is_subscribable: true,
  is_active: true,
  sub_type: "plan",
  is_tax_included: 1,
  created_by: 1,
  interval: "",
  interval_unit: "month",
  expires_after: 0,
  free_trial_days: 0,
  setup_fee: 0,
  tax_code: "GST_NONE",
  plan: {},
  is_value: 0,
};

const planEntitySlice = createSlice({
  name: "plan",
  initialState,
  reducers: {
    changePlanName(state, action) {
      state.name = action.payload;
    },
    changePlanCode(state, action) {
      state.code = action.payload;
    },
    changePlanType(state, action) {
      state.type = action.payload;
    },
    changePlanParentProduct(state, action) {
      state.parent_product = action.payload;
    },
    changePlanUnit(state, action) {
      state.unit = action.payload;
    },
    changePlanDescription(state, action) {
      state.description = action.payload;
    },
    changePlanSalePrice(state, action) {
      state.sale_price = action.payload;
    },
    changePlanIntervalUnit(state, action) {
      state.interval_unit = action.payload;
    },
    changePlanInterval(state, action) {
      state.interval = action.payload;
    },
    changePlanExpiresAfter(state, action) {
      state.expires_after = action.payload;
    },
    changePlanFreetrialDays(state, action) {
      state.free_trial_days = action.payload;
    },
    changePlanSetupFees(state, action) {
      state.setup_fee = action.payload;
    },
    changePlanTaxCode(state, action) {
      state.tax_code = action.payload;
    },
    changePlanRadioButtonvalue(state, action) {
      state.is_value = action.payload;
    },
    changePlanIsTaxInclude(state, action) {
      state.is_tax_included = action.payload;
    },
    initEditPlan(state, action) {
      ;

      let tempData = action.payload;

      state.name = tempData.name;
      state.code = tempData.code;
      state.parent_product = tempData.parent_product;
      state.type = tempData.type;
      state.unit = tempData.unit;
      state.description = tempData.description;
      state.sale_price = tempData.sale_price;
      state.is_subscribable = tempData.is_subscribable;
      state.is_active = tempData.is_active;
      state.sub_type = tempData.sub_type;
      state.is_tax_included = tempData.is_tax_included;
      state.created_by = tempData.created_by;
      state.interval_unit = tempData.plan.interval_unit;
      state.interval = tempData.plan.interval;
      state.setup_fee = tempData.plan.setup_fee;
      state.free_trial_days = tempData.plan.free_trial_days;
      state.expires_after = tempData.plan.expires_after;
      state.tax_code = tempData.tax_code;
      state.is_value = tempData.plan.expires_after > 0 ? 1 : 0
    },
    resetPlan: () => initialState,
  },
  extraReducers(builder) {

  },
});

export default planEntitySlice.reducer;

export const {
  changePlanName,
  changePlanCode,
  changePlanParentProduct,
  changePlanUnit,
  changePlanDescription,
  changePlanSalePrice,
  changePlanType,
  changePlanIntervalUnit,
  changePlanInterval,
  resetAddon,
  initEditPlan,
  changePlanExpiresAfter,
  changePlanFreetrialDays,
  changePlanSetupFees,
  changePlanIsTaxInclude,
  resetPlan,
  changePlanTaxCode,
  changePlanRadioButtonvalue,
} = planEntitySlice.actions;
