import {
    createSlice,
    createAsyncThunk,
  } from "@reduxjs/toolkit";
import { i18n } from "../../../shared/helpers/sharedMethods";

  const initialState = {
    status:false,
    reportItems:[],
    error:false,
    errorMessage:{}
  };

export const fetchTopTenPurchaseParty = createAsyncThunk('topTenPurchaseParty/fetch', async (param) => {
  try{
    const topTenPurchasePartyResponse  = await window.api.topTenPurchasePartyChart(param);
    return topTenPurchasePartyResponse.data;
  }catch(err){
    await window.log.logToFile({message: "Error while fetching Top ten Purchase party", level: "error" });
    throw new Error("Error while fetching Top Ten Purchase Party")
  }
});

const topTenPurchasePartySlice = createSlice({
    name: "topTenPurchaseParty",
    initialState,
    reducers: {},
    extraReducers(builder) {
      builder
      .addCase(fetchTopTenPurchaseParty.fulfilled, (state, action) => {
        state.status = true;
        state.reportItems =action.payload;
      })
      .addCase(fetchTopTenPurchaseParty.rejected, (state, action) => {
        state.error = true; 
        state.errorMessage= (i18n("Error while fetching Top Ten Purchase Party. Please try again later."));
        state.status = false;
      })

    },
});

export default topTenPurchasePartySlice.reducer;
