import { Col, Form, FormControl, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { ErrorMessage } from "formik";
import { i18n } from "../../../../shared/helpers/sharedMethods";
import { changeSetupFeesCode } from "../../../store/subscription_item_store/SetupFeesEntitySlice";

const ProductCode = ({ values, handleChange, touched, errors }) => {
  const dispatch = useDispatch();
  return (
    <Row className="py-4">
      <Col lg={3}>
        <Form.Group>
          <Form.Label className="form-text-font">{i18n(" Code")} </Form.Label>
          <span className="required" />
        </Form.Group>
      </Col>
      <Col lg={4}>
        <FormControl
          type="text"
          name="code"
          value={values.code}
          onChange={(e) => {
            handleChange(e);
            dispatch(changeSetupFeesCode(e.target.value));
          }}
          className={`form-control ${
            touched.code && errors.code ? "is-invalid" : ""
          }`}
        />

        <ErrorMessage
            name="code"
            component="span"
            className="text-danger d-block fw-400 fs-small mt-2"
          />
      </Col>
    </Row>
  );
};

export default ProductCode;