import { Form } from "react-bootstrap";
import React from "react";
import { useSelector } from "react-redux";
import { selectAllCategories } from "../../../settings/store/categorysSlice";
import { placeholderText } from "../../../shared/helpers/sharedMethods";


const CategoryFilter = (props) => {
   
    const {categoryData,setCategoryData,title} = props;

    const fetchCategory = useSelector(selectAllCategories);
   
    const filteredCategories = fetchCategory.filter(item => item.type === title);

    return (
      <Form.Select
        className="rounded-0"
        value={categoryData}
        onChange={(e) => setCategoryData(e.target.value)}
        style={{ cursor: "pointer" }}
      >
        <option value="" disabled selected hidden>
          {placeholderText("Select Category")}
        </option>
        <option value="">{placeholderText("All Category")}</option>
        {filteredCategories.length > 0 ? (
          filteredCategories.map((survey) => (
            <option key={survey.id} value={survey.id}>
              {survey.name}
            </option>
          ))
        ) : (
          <option disabled>{placeholderText("No categories available")}</option>
        )}
      </Form.Select>
    );

}

export default CategoryFilter;