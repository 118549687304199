import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  deleteCategory,
  selectAllCategories,
  fetchCategories,
  resetCategory,
} from "../store/categorysSlice";
import { resetCart, resetCreateCategory } from "../store/categoryEntitySlice";
import CreateCategory from "../components/CreateCategoryModel";
import { useNavigate } from "react-router-dom";
import DeleteModel from "../../shared/components/DeleteModel";
import DataGrid from "../../shared/components/DataGrid";
import Layout from "../../shared/components/Layout";
import { downloadCsvFile, handleExportClick } from "../../shared/export";
import { addCategoryType, selectCategoryType } from "../store/categorysSlice";
import EditCategory from "../components/EditCategoryModel";
import { exportPreProcessor } from "../components/helpers/settingHelpers";
import CategoryType from "../components/category/CategoryType";
import { Button, Container, Stack, Image, } from "react-bootstrap";
import Header from "../../shared/components/Header";
import { i18n } from "../../shared/helpers/sharedMethods";
import { UilSitemap, UilEdit, UilTrashAlt } from "@iconscout/react-unicons";
import { resetCategoryStatus } from "../../settings/store/categoryEntitySlice";
import ShortcutKeys from "../../shared/components/ShortcutKeys";
import LoadingSpinner from '../../shared/components/LoadingSpinner';
import SyncStatus from "../../shared/helpers/syncStatus";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { getLocalizedDate } from "../../shared/helpers/localizationHelper";
import CustomeSortIcon from "../../shared/components/CustomeSortIcon";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import DataGridHeader from "../../shared/components/DataGridHeader";
import { MultiSelect } from "primereact/multiselect";
import CategoryFilter from "../../shared/helpers/catergoryFilter";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


const CategoryStack = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const allCategories = useSelector(selectAllCategories);

  const isUpdateStatus = useSelector((state) => state.categoryEntity.isUpdate);

  const status = useSelector((state) => state.categories.status);
  const loading = useSelector((state) => state.categories.loading);




  const [show, setShow] = useState(false);
  const [showDel, setShowDel] = useState(false);
  const [id, setDeleteId] = useState("");
  const [name, setName] = useState("");
  const [edit, setEdit] = useState("");
  const [showEdit, setShowEdit] = useState(false);
  const [csvData, setCsvData] = useState("");
  const [exportData, setExportData] = useState([]);
  const [category, setCategory] = useState(allCategories);
  const [sortField, setSortField] = useState(null);
  const [sortOrder, setSortOrder] = useState(1);
  const [showModal, setShowModal] = useState(false);
  const [globalFilter, setGlobalFilter] = useState('');
  const [filters, setFilters] = useState(null);
  const [balanceFrozen, setBalanceFrozen] = useState(false);
  const [globalFilterValue, setGlobalFilterValue] = useState('');
  const [categoryOptions, setCategoryOptions] = useState([]);
  const categoryType = useSelector((state) => state.categories.categoryType);
  const [type, setType] = useState([])
  const selectedBusiness = useSelector((state) => state?.auth?.selectedBusiness);
  const { accessToken } = useSelector((state) => state?.auth);
  const tenantId = selectedBusiness?.pivot?.tenant_id;
  const { terminalId } = useSelector((state) => state.initInstall);
  const categoryByType = useSelector((state) =>
    selectCategoryType(state, categoryType)
  );
  const manageCategoryStatus = useSelector(
    (state) => state.categoryEntity.status
  );
  const editStatus = useSelector((state) => state.categoryEntity.editStatus)
  useEffect(() => {
    if (manageCategoryStatus) {
      toast.success("Category created successfully");
      dispatch(resetCategoryStatus);
    }
  }, [manageCategoryStatus]);

  useEffect(() => {
    if (editStatus) {
      toast.success("Category updated successfully");
      dispatch(resetCategoryStatus);
    }
  }, [editStatus]);
  useEffect(() => {
    if (categoryByType) {
      setCategory(categoryByType);
    }
  }, [categoryByType]);

  useEffect(() => {
    dispatch(fetchCategories({ token: accessToken.access_token, tenant_id: tenantId }));
  }, [accessToken, tenantId, dispatch]);


  useEffect(() => {
    if (allCategories.length > 0) {
      setCategory(allCategories);
    }
  }, [allCategories]);

  const handleRefresh = () => {
    setCategory([]);
    dispatch(fetchCategories({ token: accessToken.access_token, tenant_id: tenantId }));
    dispatch(resetCategory());
  };
  useEffect(() => {
    if (isUpdateStatus) {
      dispatch(fetchCategories({ token: accessToken.access_token, tenant_id: tenantId }));
    }
  }, [isUpdateStatus]);



  const handleEdit = (data) => {
    dispatch(resetCreateCategory());
    dispatch(resetCart());
    setShowEdit(!showEdit);
    setEdit(data);
  };

  const handleClick = async () => {
    dispatch(resetCreateCategory());
    setShow(!show);
  };
  const handleDelete = async (data) => {
    setShowDel(!showDel);
    setDeleteId(data.id);
    setName(data.name);
  };

  const handleClose = () => {
    setShow(false);
    setShowEdit(false);
  };

  const onDelete = () => {
    dispatch(deleteCategory({
      data: { id: id },
      token: accessToken.access_token,
      tenant_id: tenantId,
      terminal_id: terminalId
    }));
    setShowDel(false);
    //dispatch(fetchCategories());
    //navigate("/setting/categories");
  };

  const dateTimeString = new Date()
    .toLocaleString()
    .replace(/[-]/g, " ")
    .replace(/:/g, "-");
  const fileName = `category_${dateTimeString}`;

  const handleExportCategory = () => {
    const exportData = exportPreProcessor(category);
    setExportData(exportData);
    handleExportClick(
      exportData,
      (exportData) => {
        setCsvData(exportData);
      },
      downloadCsvFile,
      fileName
    );
  };

  const CategoryTypes = (
    <>
      <CategoryType />
    </>
  );

  const bulkAction = () => {
    setShowModal(true);
  };

  const customSort = (field) => {
    setSortField(field);
    setSortOrder(sortOrder === 1 ? -1 : 1);
  };

  const sortedData = [...category].sort((a, b) => {
    if (sortField) {
      const compareValueA = a[sortField];
      const compareValueB = b[sortField];
      if (compareValueA < compareValueB) {
        return sortOrder;
      }
      if (compareValueA > compareValueB) {
        return -sortOrder;
      }
    }
    return 0;
  });

  const headerStyle = {
    backgroundColor: 'white',
  };

  const headerCellStyle = {
    fontWeight: 'bold',
    fontSize: '11px',
  };


  // Category filter logic

  const leadStatusLabel = {
    'party': 'Party',
    'product': 'Product'
  }

  const categorytypetemplate = (allData) => {
    const statusMessage = leadStatusLabel[allData.type]
    return statusMessage;
  };

  const categoryTypefilter = [
    { label: "Party", value: 'party' },
    { label: "Product", value: 'product' },
  ];

  const handleCategoryChange = (e) => {
    setType(e.value);
    if (e.value.length === 0) {
      setCategory(allCategories);
    } else {
      const CategoryNames = e.value.map(item => item);
      const filteredCategories = allCategories.filter(party => CategoryNames.includes(party.type));
      setCategory(filteredCategories);
    }
  };

  const representativesItemTemplate = (option) => {
    return (
      <div className="flex align-items-center gap-2">
        <span>{option.label}</span>
      </div>
    );
  };

  const CategoryFilterTemplate = (options) => {
    return (
      <>
        <MultiSelect
          placeholder='Choose a Category Type'
          value={type}
          options={categoryTypefilter}
          itemTemplate={representativesItemTemplate}
          onChange={handleCategoryChange}
          optionLabel="label"
          className="p-column-filter custom-multiselect-category-type"
          style={{ fontSize: '11px' }} />
      </>
    );
  };

  //Category filter logic Ends here

  const handleClearFilters = () => {
    setCategory([]);
    setFilters({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      'name': { value: null, matchMode: FilterMatchMode.CONTAINS },
      'type': { value: null, matchMode: FilterMatchMode.CONTAINS },

    });
    setType([])
    setGlobalFilter('');
    setGlobalFilterValue('');
    setCategory(allCategories)
  };

  const actionBodyTemplate = (row) => {
    return (
      <div className="d-flex justify-content-evenly align-items-center">
        <UilEdit className="mx-2 mb-1" size="15" style={{ cursor: row.name !== "Walk-in" ? "pointer" : "not-allowed" }} onClick={(e) => { e.stopPropagation(); handleEdit(row); }}
        />
        <UilTrashAlt className="mx-2 mb-1" size="15" style={{ cursor: row.name !== "Walk-in" ? "pointer" : "not-allowed" }} onClick={(e) => { e.stopPropagation(); handleDelete(row); }} />
      </div>
    );
  };



  return (
    <div>
      <ToastContainer />
      <Layout>
        {loading && <LoadingSpinner />}
        <Header
          currentTitle={i18n("Categories")}
          ItemActions={
            <Stack
              gap={3}
              direction="horizontal"
              className="my-2 justify-content-end"
            >
              <ShortcutKeys />
            </Stack>
          }
        />
        <Container fluid className="px-5">
          <div className="border" style={{ background: "white" }}>
            <DataGridHeader
              handleRefreshParties={handleRefresh}
              statusFilter={null}
              clearFilter={handleClearFilters}
              data={category}
              allData={allCategories}
              addFilteredData={setCategory}
              handleCreate={handleClick}
              handleExportClick={handleExportCategory}
              datas={exportData}
              isAllData={"All Categories"}
              button={i18n("Create Category")}
              title="Category"
              bulkAction={bulkAction}

              ItemActions={
                <Stack
                  gap={3}
                  direction="horizontal"
                  className=" justify-content-end"
                >

                  <Button severity="primary" style={{ borderRadius: '5px' }} onClick={handleClick}>Create Category</Button>

                </Stack>
              }
            />

            <Container fluid className="px-4 mb-4">
              <div className="bordered-container">

                {status && allCategories ? (
                  <DataTable
                    value={sortedData}
                    // headerStyle={headerStyle}
                    // isAllData={"All Categories"}
                    button={i18n("Create Category")}
                    title="Category"

                    paginator
                    globalFilter={globalFilter}
                    globalFilterFields={['name', 'type']}
                    filterDisplay="row"
                    rows={10}
                    dataKey="id"
                    filters={filters}
                    emptyMessage={i18n("No Records Found.")}
                    onFilter={(e) => setFilters(e.filters)}
                    className="p-datatable-gridlines full-width-table custom-datatable p-component"
                    checked={balanceFrozen}
                    scrollable
                    scrollHeight="400px"
                    sortMode="multiple"
                    removableSort
                    style={{ cursor: "pointer" }}
                  >
                    <Column
                      field="name"
                      header={() => (
                        <div
                          className={`custom-header-${sortField === 'name' ? (sortOrder === 1 ? 'up' : 'down') : ''}`}
                          onClick={() => customSort('name')}
                          style={{
                            cursor: 'pointer',
                            display: 'flex',
                            alignItems: 'center',
                            fontSize: '11px',
                            lineHeight: '11px',
                          }}
                        >
                          {i18n("Name")}
                          <CustomeSortIcon sortOrder={sortField === 'name' ? sortOrder : 0}
                            style={{ marginLeft: '4px', verticalAlign: 'middle' }} />
                        </div>
                      )}
                      sortable
                      filter
                      filterPlaceholder="Enter a Category Name"
                      headerStyle={{ headerCellStyle, width: '570px' }}
                      bodyStyle={{ width: '45%' }}
                      showFilterMatchModes={false}
                      showClearButton={false}
                      showFilterOperator={false}
                      showAddButton={false}
                      filterMatchMode={FilterMatchMode.CONTAINS}
                      showApplyButton={false}
                      showFilterMenu={false}
                    />
                    <Column
                      field="type"
                      body={categorytypetemplate}
                      header={() => (
                        <div
                          className={`custom-header-${sortField === 'type' ? (sortOrder === 1 ? 'up' : 'down') : ''}`}
                          onClick={() => customSort('type')}
                          style={{
                            cursor: 'pointer',
                            display: 'flex',
                            alignItems: 'center',
                            fontSize: '11px',
                            lineHeight: '11px',
                          }}
                        >
                          {i18n("Category Type")}
                          <CustomeSortIcon sortOrder={sortField === 'type' ? sortOrder : 0}
                            style={{ marginLeft: '4px', verticalAlign: 'middle' }} />
                        </div>
                      )}
                      sortable
                      filter
                      filterElement={<CategoryFilterTemplate setData={setCategory} />}
                      headerStyle={{ headerCellStyle }}
                      showFilterMatchModes={false}
                      showClearButton={false}
                      showFilterOperator={false}
                      showAddButton={false}
                      filterMatchMode={FilterMatchMode.CONTAINS}
                      showApplyButton={false}
                      showFilterMenu={false}
                    />
                    <Column
                      header={i18n("Actions")}
                      body={actionBodyTemplate}
                      headerStyle={{ headerCellStyle, width: '80px' }}
                    />
                    <Column field="sync_status"
                      header={() => (
                        <div
                          className={`custom-header-${sortField === 'sync_status' ? (sortOrder === 1 ? 'up' : 'down') : ''}`}
                          onClick={() => customSort('sync_status')}
                          style={{
                            paddingLeft: "1.70rem",
                            cursor: 'pointer',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            fontSize: '11px',
                            lineHeight: '11px',
                          }}

                        >
                          {i18n("Sync Status")}
                          <CustomeSortIcon sortOrder={sortField === 'sync_status' ? sortOrder : 0}
                            style={{ marginLeft: '4px', verticalAlign: 'middle' }} />
                        </div>
                      )}
                      // filterElement={<SyncStatusFilter setData={setParties} allData={allPartys} />}
                      body={(rowData) => {
                        const { sync_status, syncstamp } = rowData;

                        return (
                          <>
                            <SyncStatus status={sync_status} syncstamp={syncstamp} />
                          </>
                        );
                      }}
                      headerStyle={{ headerCellStyle, width: '150px' }}
                      bodyStyle={{ width: '150px' }}
                      showFilterMatchModes={false}
                      showClearButton={false}
                      showFilterOperator={false}
                      showAddButton={false}
                      filterMatchMode={FilterMatchMode.CONTAINS}
                      showApplyButton={false}
                      showFilterMenu={false}
                    />
                  </DataTable>

                ) : (
                  <p>{i18n("Loading")}</p>
                )}
              </div>
            </Container>
          </div>
          {showDel && (
            <DeleteModel
              deleteUserClick={onDelete}
              onClickDeleteModel={() => setShowDel(false)}
              name={name}
            />
          )}
          {show && (
            <CreateCategory
              show={show}
              setShow={setShow}
              handleClose={handleClose}
            />
          )}
          {showEdit && (
            <EditCategory
              showEdit={setShowEdit}
              edit={edit}
              handleClose={handleClose}
            />
          )}
        </Container>
      </Layout>
    </div>
  );
};

export default CategoryStack;
