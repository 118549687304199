import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import "../../../assets/css/frontend.css";
import { deleteProduct, selectAllProducts, selectProductById, resetProduct, selectProduct, } from "../store/productsSlice";
import DeleteModel from "../../shared/components/DeleteModel";
import DataGridHeader from "../../shared/components/DataGridHeader";
import CustomeSortIcon from "../../shared/components/CustomeSortIcon";
import { FaSort, FaSortUp, FaSortDown } from 'react-icons/fa';
import { DataTable } from 'primereact/datatable';
import { classNames } from 'primereact/utils';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { IconField } from 'primereact/iconfield';
import { InputIcon } from 'primereact/inputicon';
import { ButtonGroup, Dropdown, InputGroup } from "react-bootstrap";
import { InputNumber } from 'primereact/inputnumber';
// import { Button } from 'primereact/button';
import { ProgressBar } from 'primereact/progressbar';
import { Calendar } from 'primereact/calendar';
import { MultiSelect } from 'primereact/multiselect';
import { Slider } from 'primereact/slider';
import { Tag } from 'primereact/tag';
import { resetCreateProduct } from "../store/productEntitySlice";
import ProductImport from "./ProductImport";
import {
  selectProductByCategory,
  selectFilteredProducts,
} from "../store/productsSlice";
import { downloadCsvFile, handleExportClick } from "../../shared/export";
import Layout from "../../shared/components/Layout";
import { exportPreProcessor } from "../helper/catalogHelper";
import ProductCategory from "../components/category/ProductCategory";
import { Container, Form, Stack, Image, Button } from "react-bootstrap";
import Header from "../../shared/components/Header";
import { i18n } from "../../shared/helpers/sharedMethods";
import CreateProduct from "./CreateProduct";
import EditProduct from "./EditProduct";
import { faBarcode, faCheck, faCheckDouble } from "@fortawesome/free-solid-svg-icons";
import TwoStageLayoutHeader from "../../shared/components/TwoStatgeLayoutHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DetailsModal from "../../shared/components/DetailsModal";
import ProductHighlights from "../components/product_view/ProductHighlights";
import ItemInfo from "../components/product_view/tabs/ItemInfo";
import ItemStockReport from "../components/product_view/tabs/ItemStockReport";
import PartywiseReport from "../components/product_view/tabs/PartywiseReport";
import ViewBarCode from "../components/ViewBarCode";
import { useHotkeys } from "react-hotkeys-hook";
import StockFilter from "../components/filters/StockFilter";
import { resetStockData } from "../store/stockReportSlice";
import { UilEdit, UilTrashAlt, UilUsersAlt } from "@iconscout/react-unicons";
import { toast, ToastContainer } from "react-toastify";
import { resetCategoryStatus } from "../../settings/store/categoryEntitySlice";
import { fetchProducts } from "../../catalog/store/productsSlice";
import { getLocalizedDate } from "../../shared/helpers/localizationHelper";
import { selectCategoryType, fetchCategories } from "../../settings/store/categorysSlice";
import ShortcutKeys from "../../shared/components/ShortcutKeys";
import GlobalCurrency from "../../shared/components/GlobalCurrency";
import LoadingSpinner from '../../shared/components/LoadingSpinner';
import { UilPauseCircle, UilExclamationTriangle, UilTimesCircle, UilCheckCircle, UilAngleDown } from '@iconscout/react-unicons'
import SyncStatus from "../../shared/helpers/syncStatus";
import SyncStatusFilter from "../helper/syncStatusHelper";


const ProductIndex = ({ featureName }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { accessToken, isLoggedIn } = useSelector((state) => state?.auth);
  const filteredProducts = useSelector(selectFilteredProducts);
  const allProducts = useSelector(selectProduct);
  const currencySymbol = useSelector((state) => state.getSetting?.dataSetting.currency);;
  const status = useSelector((state) => state.products.status);
  const productCategory = useSelector((state) => state.products.productCategory);
  const selectedCategory = useSelector((state) => selectProductByCategory(state, productCategory));
  const categoryProductStatus = useSelector((state) => state.categoryEntity.status);

  const [show, setShow] = useState(false);
  const [exportData, setExportData] = useState([]);
  const [deleteId, setDeleteId] = useState();
  const [deleteName, setDeleteName] = useState();
  const [csvData, setCsvData] = useState("");
  const [product, setProducts] = useState(allProducts);
  const [showCreate, setShowCreate] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [productId, setProductId] = useState();
  const [showDetails, setShowDetails] = useState(false);
  const [id, setId] = useState();
  const [showViewCode, setShowViewCode] = useState(false);
  const [barCodeItemData, setBarCodeItemData] = useState("");
  const [globalFilter, setGlobalFilter] = useState('');
  const [globalFilterValue, setGlobalFilterValue] = useState('');
  const [filters, setFilters] = useState(null);
  const [balanceFrozen, setBalanceFrozen] = useState(false);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [sortField, setSortField] = useState("created_at");
  const [sortOrder, setSortOrder] = useState(-1);
  const loading = useSelector((state) => state.products.loading);
  const { terminalId } = useSelector((state) => state.initInstall);
  const selectedBusiness = useSelector(
    (state) => state?.auth?.selectedBusiness
  );
  const tenantId = selectedBusiness?.pivot?.tenant_id;
  const selectedProduct = useSelector((state) => selectProductById(state, id));
  const quantity = selectedProduct?.inventories ? selectedProduct.inventories[0]?.quantity : "0";

  const handleClearFilters = () => {
    setSelectedCategories([]);
    setFilters({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      name: { value: null, matchMode: FilterMatchMode.CONTAINS },
      code: { value: null, matchMode: FilterMatchMode.CONTAINS },
      type: { value: null, matchMode: FilterMatchMode.CONTAINS },
      cost: { value: null, matchMode: FilterMatchMode.EQUALS },
      price: { value: null, matchMode: FilterMatchMode.EQUALS },
      category: { value: null, matchMode: 'in' },
      quantity: { value: null, matchMode: FilterMatchMode.EQUALS },
      created_at: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
    });
    setGlobalFilter('');
    setGlobalFilterValue('');
    setProducts(allProducts);
  };

  const globalPlanFeatures = useSelector(
    (state) => state?.globalPlan?.features
  );

  useEffect(() => {
    if (!globalPlanFeatures.includes(featureName)) {
      navigate("/setting/not-in-plan");
    }
  }, [])


  useEffect(() => {
    if (categoryProductStatus) {
      toast.success(i18n("Category created successfully"));
      dispatch(resetCategoryStatus);
    }
  }, [categoryProductStatus]);
  useEffect(() => {
    if (selectedCategory) {
      setProducts(selectedCategory);
    }
  }, [selectedCategory]);

  useEffect(() => {
    setProducts(filteredProducts);
  }, [filteredProducts]);

  useEffect(() => {
    dispatch(fetchProducts({ token: accessToken.access_token, tenant_id: selectedBusiness?.pivot?.tenant_id }));
  }, [accessToken, selectedBusiness])
  useEffect(() => {
    dispatch(fetchCategories({ token: accessToken.access_token, tenant_id: selectedBusiness?.pivot?.tenant_id }));
  }, [accessToken, selectedBusiness])

  const handleCreate = () => {
    dispatch(resetCreateProduct());
    setShowCreate(true);
  };

  const handleCeateClose = () => {
    setShowCreate(false);
  };

  const handleEdit = (row) => {
    dispatch(resetCreateProduct());
    setShowEdit(true);
    setProductId(row.id);
    setShowDetails(false);
  };

  const handleDelete = (delRow) => {
    setShow(!show);
    setDeleteId(delRow.id);
    setDeleteName(delRow.name);
    setShowDetails(false);
  };

  const onDelete = () => {
    dispatch(deleteProduct({
      data: { id: deleteId },
      token: accessToken.access_token,
      tenant_id: tenantId,
      terminal_id: terminalId
    }));
    setShow(false);
    navigate("/catalog/products");
  };
  const handleViewCode = (row) => {
    setBarCodeItemData(row);
    setShowViewCode(true);
  };
  const handleProductRowClick = (row) => {
    dispatch(resetStockData());
    setShowDetails(!showDetails);
    setId(row.id);
  };

  useEffect(() => {
    if (allProducts.length > 0) {
      setProducts(allProducts);
    }
  }, [allProducts]);

  const handleRefreshProduct = () => {
    dispatch(fetchProducts({ token: accessToken.access_token, tenant_id: selectedBusiness?.pivot?.tenant_id }));
    dispatch(resetProduct());
  };


  const bulkAction = () => {
    navigate("/catalog/products/import")
  };

  const dateTimeString = new Date()
    .toLocaleString()
    .replace(/[-]/g, " ")
    .replace(/:/g, "-");
  const fileName = `product_${dateTimeString}`;

  const handleExportProduct = () => {
    const exportData = exportPreProcessor(product);
    setExportData(exportData);
    handleExportClick(
      exportData,
      (exportData) => {
        setCsvData(exportData);
      },
      downloadCsvFile,
      fileName
    );
  };

  useHotkeys("alt+m", (event) => {
    event.preventDefault();
    handleCreate();
  });

  const handleReturnToGrid = () => {
    setShowDetails(false);
  };

  const actionBodyTemplate = (row) => {
    return (
      <div className="d-flex justify-content-evenly align-items-center">
        <UilEdit
          className="mx-2 mb-1"
          size="15"
          style={{ cursor: "pointer" }}
          onClick={(e) => {
            e.stopPropagation(); // Prevents row click from firing
            handleEdit(row);
          }}
        />
        &nbsp;
        {/* <i className="fa fa-trash mx-2 " aria-hidden="true"></i> */}
        <UilTrashAlt
          className="mx-2 mb-1"
          size="15"
          style={{ cursor: "pointer" }}
          onClick={(e) => {
            e.stopPropagation(); // Prevents row click from firing
            handleDelete(row);
          }}
        />
      </div>
    );
  };

  const header = (
    <TwoStageLayoutHeader
      ItemActions={
        <Stack
          gap={3}
          direction="horizontal"
          className="mt-2 mb-2 justify-content-end"
        >
          <Button
            variant="outline-info"
            onClick={() => handleViewCode(selectedProduct)}
          >
            <FontAwesomeIcon className="mx-2 mb-1" icon={faBarcode} />
            {i18n("BarCode")}
          </Button>
          <InputGroup className="me-3 custom-input">
            <Button
              variant="outline-secondary"
              onClick={() => handleEdit(selectedProduct)}
              size="sm"
              style={{ padding: '0.5rem', background: 'none' }}
            >
              <UilEdit className="mx-2 mb-1" size="16" />
              {i18n("Edit")}
            </Button>

            <Dropdown as={ButtonGroup}>
              <Dropdown.Toggle
                variant="outline-secondary"
                id="dropdown-custom"
                size="sm"
                style={{ height: '36px' }}
              >
                <UilAngleDown size="16" />
              </Dropdown.Toggle>

              <Dropdown.Menu className="custom-dropdown-menu">
                <Dropdown.Item
                  className="invoice-dropdown-item"
                  onClick={() => handleDelete(selectedProduct)}
                  style={{ color: 'red' }}
                >
                  <UilTrashAlt className="mx-2 mb-1" size="16" style={{ color: 'red' }} />
                  {i18n("Delete")}
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </InputGroup>
        </Stack>
      }
      currentTitle={
        <>
          {selectedProduct?.name}
          {quantity > 0 ? (
            <Form.Label className="mx-2 badge bg-success bg-gradient">
              {i18n("In Stock")}
            </Form.Label>
          ) : (
            <Form.Label className="mx-2 badge bg-danger bg-gradient">
              {i18n("Out of Stock")}
            </Form.Label>
          )}
        </>
      }
      returnToGrid={handleReturnToGrid}
    />
  );
  const productTabs = [
    {
      key: "info",
      title: i18n("Details"),
      component: <ItemInfo selectedProduct={selectedProduct} />,
    },
    {
      key: "stock-info",
      title: i18n("Stock Report"),
      component: <ItemStockReport productId={id} />,
    },
    {
      key: "partywise-report",
      title: i18n("Partywise Report"),
      component: <PartywiseReport productId={id} />,
    },
  ];

  const productSummary = (
    <>
      <ProductHighlights selectedProduct={selectedProduct} />
    </>
  );
  const allCategories = useSelector((state) => selectCategoryType(state, 'product'));

  useEffect(() => {
    if (allCategories) {
      setCategoryOptions(allCategories);
    }
  }, [allCategories]);
  useEffect(() => {
    dispatch(fetchCategories());
  }, [dispatch]);

  const handleCategoryChange = (e) => {
    setSelectedCategories(e.value);

    if (e.value.length === 0) {
      setProducts(allProducts);
    } else {
      const filteredProduct = allProducts.filter(product =>
        e.value.some(category => product.category_product?.name === category.name)
      );
      setProducts(filteredProduct);
    }
  };



  const representativesItemTemplate = (option) => {
    return (
      <div className="flex align-items-center gap-2">
        <span>{option.name}</span>
      </div>
    );
  };

  const representativeRowFilterTemplate = (options) => {
    return (
      <MultiSelect
        value={selectedCategories}
        options={categoryOptions}
        itemTemplate={representativesItemTemplate}
        onChange={handleCategoryChange}
        optionLabel="name"
        className="p-column-filter custom-multiselect"
        style={{ fontSize: '11px' }}
      />
    );
  };



  const headerCellStyle = {
    fontWeight: 'bold',
    fontSize: '11px',
  };

  const quantityBodyTemplate = (rowData) => {
    const quantity = rowData?.inventories && rowData?.inventories[0]?.quantity;
    const units = rowData?.unit;
    const stock = rowData?.stock;
    return quantity
      ? `${quantity} ${units ? units : ""}`
      : `${stock ? stock : 0}`;
  };

  const formatDateCreatedAt = (date) => {
    if (!date) return '-';
    return new Date(date).toLocaleString('en-GB', {
      day: '2-digit',
      month: '2-digit',
      year: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
    }).replace(',', ',');
  };

  const dateBodyTemplate = (rowData) => {
    return getLocalizedDate(rowData.created_at);
  };

  const handleCreatedAtDateFilterChange = (value, filterCallback) => {
    filterCallback(value);

    if (value) {
      const filtered = allProducts.filter((item) => {
        const createdAtDate = new Date(item.created_at);
        const selectedDate = new Date(value);
        return createdAtDate.toDateString() === selectedDate.toDateString();
      });
      setProducts(filtered);
    } else {
      setProducts(allProducts);
    }
  };

  const dateFilterTemplate = (options) => {
    return (
      <Calendar
        value={options.value}
        onChange={(e) => handleCreatedAtDateFilterChange(e.value, options.filterCallback)}
        dateFormat="dd/mm/yy"
        mask="99/99/9999"
      />
    );
  };

  const customSort = (field) => {
    if (field === 'created_at') {
      setSortOrder(-1);
    } else {
      setSortField(field);
      setSortOrder(-1);
    }
  };

  const sortedData = [...product].sort((a, b) => {
    const dateA = new Date(a.created_at);
    const dateB = new Date(b.created_at);

    return dateB - dateA;
  });

  return (
    <>
      <ToastContainer />
      {loading && <LoadingSpinner />}
      <Layout>
        <Header
          currentTitle={i18n("Products")}
          ItemActions={
            <Stack
              gap={3}
              direction="horizontal"
              className="my-2 justify-content-end"
            >
              <ShortcutKeys />
            </Stack>
          }
        />
        <Container fluid className="px-5">
          <div className="bordered-container">

            <DataGridHeader
              handleRefreshProduct={handleRefreshProduct}
              statusFilter={null}
              allData={allProducts}
              sortField={sortField}
              sortOrder={sortOrder}
              clearFilter={handleClearFilters}
              data={product}
              addFilteredData={setProducts}
              handleCreate={handleCreate}
              handleExportClick={handleExportProduct}
              datas={exportData}
              button="Create Product"
              title="Products"
              bulkAction={bulkAction}
              icons={<UilUsersAlt size="50" color="gray" />}
              message={i18n("No products found. Please create a new product.")}
              ItemActions={
                <Stack
                  gap={3}
                  direction="horizontal"
                  className=" justify-content-end"
                >
                  {/* <Button severity="primary" style={{ borderRadius: '5px' }} onClick={handleCreate}>
                    {i18n("Create Product")}
                  </Button> */}
                  <Button onClick={handleCreate}>{i18n("Create Product")}</Button>
                </Stack>
              }
            />

            <Container fluid className="px-4 mb-4">
              <div className="bordered-container">


                <DataTable
                  value={sortedData}
                  paginator
                  globalFilter={globalFilter}
                  // isAllData={"All Products"}
                  button="Create Product"
                  title="Products"
                  // bulkAction={bulkAction}
                  rows={10}
                  filterDisplay="row"
                  dataKey="id"
                  filters={filters}
                  emptyMessage={i18n("No Products found. Please create a new Product.")}
                  onFilter={(e) => setFilters(e.filters)}
                  className="p-datatable-gridlines full-width-table custom-datatable p-component"
                  onRowClick={(e) => handleProductRowClick(e.data)}
                  checked={balanceFrozen}
                  scrollable
                  sortMode="multiple"
                  scrollHeight="400px"
                  removableSort
                  style={{ cursor: "pointer" }}
                >
                  <Column field="name"
                    header={() => (
                      <div
                        className={`custom-header-${sortField === 'name' ? (sortOrder === 1 ? 'up' : 'down') : ''}`}
                        onClick={() => customSort('name')}
                        style={{
                          cursor: 'pointer',
                          display: 'flex',
                          alignItems: 'center',
                          fontSize: '11px',
                          lineHeight: '11px',
                        }}

                      >
                        {i18n("Name")}
                        <CustomeSortIcon sortOrder={sortField === 'name' ? sortOrder : 0}
                          style={{ marginLeft: '4px', verticalAlign: 'middle' }} />
                      </div>
                    )}
                    sortable
                    filter
                    filterPlaceholder=""
                    headerStyle={headerCellStyle}
                    showFilterMatchModes={false}
                    showClearButton={false}
                    showFilterOperator={false}
                    showAddButton={false}
                    filterMatchMode={FilterMatchMode.CONTAINS}
                    showApplyButton={false}
                    showFilterMenu={false}
                  />
                  <Column field="code"
                    header={() => (
                      <div
                        className={`custom-header-${sortField === 'code' ? (sortOrder === 1 ? 'up' : 'down') : ''}`}
                        onClick={() => customSort('code')}
                        style={{
                          cursor: 'pointer',
                          display: 'flex',
                          alignItems: 'center',
                          fontSize: '11px',
                          lineHeight: '11px',
                        }}
                      >
                        {i18n("Code")}
                        <CustomeSortIcon sortOrder={sortField === 'code' ? sortOrder : 0}
                          style={{ marginLeft: '4px', verticalAlign: 'middle' }} />
                      </div>
                    )}
                    sortable
                    filter
                    filterPlaceholder=""
                    headerStyle={headerCellStyle}
                    showFilterMatchModes={false}
                    showClearButton={false}
                    showFilterOperator={false}
                    showAddButton={false}
                    filterMatchMode={FilterMatchMode.CONTAINS}
                    showApplyButton={false}
                    showFilterMenu={false}
                  />

                  <Column field="type"
                    header={() => (
                      <div
                        className={`custom-header-${sortField === 'type' ? (sortOrder === 1 ? 'up' : 'down') : ''}`}
                        onClick={() => customSort('type')}
                        style={{
                          cursor: 'pointer',
                          display: 'flex',
                          alignItems: 'center',
                          fontSize: '11px',
                          lineHeight: '11px',
                        }}
                      >
                        {i18n("Type")}
                        <CustomeSortIcon sortOrder={sortField === 'type' ? sortOrder : 0}
                          style={{ marginLeft: '4px', verticalAlign: 'middle' }} />
                      </div>
                    )}
                    sortable
                    filter filterPlaceholder=""
                    headerStyle={headerCellStyle}
                    showFilterMatchModes={false}
                    showClearButton={false}
                    showFilterOperator={false}
                    showAddButton={false}
                    filterMatchMode={FilterMatchMode.CONTAINS}
                    showApplyButton={false}
                    showFilterMenu={false}
                  />
                  <Column
                    field="cost_price"
                    header={() => (
                      <div
                        className={`custom-header-${sortField === 'cost_price' ? (sortOrder === 1 ? 'up' : 'down') : ''}`}
                        onClick={() => customSort('cost_price')}
                        style={{
                          cursor: 'pointer',
                          display: 'flex',
                          alignItems: 'center',
                          fontSize: '11px',
                          lineHeight: '11px',
                        }}
                      >
                        {i18n("Cost")}
                        <CustomeSortIcon
                          sortOrder={sortField === 'cost_price' ? sortOrder : 0}
                          style={{ marginLeft: '4px', verticalAlign: 'middle' }}
                        />
                      </div>
                    )}
                    sortable
                    filter
                    filterPlaceholder=""
                    headerStyle={headerCellStyle}
                    showFilterMatchModes={false}
                    showClearButton={false}
                    showFilterOperator={false}
                    showAddButton={false}
                    filterMatchMode={FilterMatchMode.CONTAINS}
                    showApplyButton={false}
                    showFilterMenu={false}
                    body={(rowData) => (
                      <span>
                        <GlobalCurrency price={rowData?.cost_price} />
                      </span>
                    )}
                  />

                  <Column
                    field="sale_price"
                    header={() => (
                      <div
                        className={`custom-header-${sortField === 'sale_price' ? (sortOrder === 1 ? 'up' : 'down') : ''}`}
                        onClick={() => customSort('sale_price')}
                        style={{
                          cursor: 'pointer',
                          display: 'flex',
                          alignItems: 'center',
                          fontSize: '11px',
                          lineHeight: '11px',
                        }}
                      >
                        {i18n("Price")}
                        <CustomeSortIcon
                          sortOrder={sortField === 'sale_price' ? sortOrder : 0}
                          style={{ marginLeft: '4px', verticalAlign: 'middle' }}
                        />
                      </div>
                    )}
                    sortable
                    filter
                    filterPlaceholder=""
                    headerStyle={headerCellStyle}
                    showFilterMatchModes={false}
                    showClearButton={false}
                    showFilterOperator={false}
                    showAddButton={false}
                    filterMatchMode={FilterMatchMode.CONTAINS}
                    showApplyButton={false}
                    showFilterMenu={false}
                    body={(rowData) => (
                      <span>
                        <GlobalCurrency price={rowData?.sale_price} />
                      </span>
                    )}
                  />

                  <Column
                    field="category_product.name"
                    header={() => (
                      <div
                        className={`custom-header-${sortField === 'category_product.name' ? (sortOrder === 1 ? 'up' : 'down') : ''}`}
                        onClick={() => customSort('category_product.name')}
                        style={{
                          cursor: 'pointer',
                          display: 'flex',
                          alignItems: 'center',
                          fontSize: '11px',
                          lineHeight: '11px',
                        }}
                      >
                        {i18n("Category")}
                        <CustomeSortIcon sortOrder={sortField === 'category_product.name' ? sortOrder : 0}
                          style={{ marginLeft: '4px', verticalAlign: 'middle' }} />
                      </div>
                    )}
                    sortable
                    body={(rowData) => rowData.category_product ? rowData.category_product.name : "-"}
                    filter
                    filterElement={representativeRowFilterTemplate}
                    filterField={(rowData) => rowData.category_product ? rowData.category_product.name : "-"}
                    headerStyle={headerCellStyle}
                    showFilterMatchModes={false}
                    showClearButton={false}
                    showFilterOperator={false}
                    showAddButton={false}
                    filterMatchMode={FilterMatchMode.CONTAINS}
                    showApplyButton={false}
                    showFilterMenu={false}
                    bodyStyle={{ width: '6%' }}
                  />

                  <Column
                    field="quantity"
                    header={() => (
                      <div
                        className={`custom-header-${sortField === 'naquantityme' ? (sortOrder === 1 ? 'up' : 'down') : ''}`}
                        onClick={() => customSort('quantity')}
                        style={{
                          cursor: 'pointer',
                          display: 'flex',
                          alignItems: 'center',
                          fontSize: '11px',
                          lineHeight: '11px',
                        }}
                      >
                        {i18n("Quantity")}
                        <CustomeSortIcon sortOrder={sortField === 'quantity' ? sortOrder : 0}
                          style={{ marginLeft: '4px', verticalAlign: 'middle' }} />
                      </div>
                    )}
                    sortable
                    body={quantityBodyTemplate}
                    filter
                    filterPlaceholder=""
                    headerStyle={headerCellStyle}
                    showFilterMatchModes={false}
                    showClearButton={false}
                    showFilterOperator={false}
                    showAddButton={false}
                    filterMatchMode={FilterMatchMode.CONTAINS}
                    showApplyButton={false}
                    showFilterMenu={false}
                  />

                  <Column
                    field="created_at"
                    header={() => (
                      <div
                        className={`custom-header-${sortField === 'created_at' ? 'up' : ''}`}
                        onClick={() => customSort('created_at')}
                        style={{
                          cursor: 'pointer',
                          display: 'flex',
                          alignItems: 'center',
                          fontSize: '11px',
                          lineHeight: '11px',
                        }}
                      >
                        {i18n("Created")}
                        <CustomeSortIcon sortOrder={sortField === 'created_at' ? sortOrder : 0}
                          style={{ marginLeft: '4px', verticalAlign: 'middle' }} />
                      </div>
                    )}
                    sortable
                    body={dateBodyTemplate}
                    filter
                    filterElement={dateFilterTemplate}
                    filterPlaceholder=""
                    headerStyle={headerCellStyle}
                    showFilterMatchModes={false}
                    showClearButton={false}
                    showFilterOperator={false}
                    showAddButton={false}
                    filterMatchMode={FilterMatchMode.DATE_IS}
                    showApplyButton={false}
                    showFilterMenu={false}
                    bodyStyle={{ width: '15%' }}
                  />
                  <Column field="sync_status"
                    header={() => (
                      <div
                        className={`custom-header-${sortField === 'sync_status' ? (sortOrder === 1 ? 'up' : 'down') : ''}`}
                        onClick={() => customSort('sync_status')}
                        style={{
                          cursor: 'pointer',
                          display: 'flex',
                          alignItems: 'center',
                          fontSize: '11px',
                          lineHeight: '11px',
                        }}

                      >
                        {i18n("Sync Status")}
                        <CustomeSortIcon sortOrder={sortField === 'sync_status' ? sortOrder : 0}
                          style={{ marginLeft: '4px', verticalAlign: 'middle' }} />
                      </div>
                    )}
                    sortable
                    body={(rowData) => {
                      const { sync_status, syncstamp } = rowData;


                      return (
                        <>
                          <SyncStatus status={sync_status} syncstamp={syncstamp} />
                        </>
                      );
                    }}
                    headerStyle={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      textAlign: 'center',
                      width: 'auto',
                    }}
                    bodyStyle={{ width: '9.7%' }}
                    showFilterMatchModes={false}
                    showClearButton={false}
                    showFilterOperator={false}
                    showAddButton={false}
                    filterMatchMode={FilterMatchMode.CONTAINS}
                    showApplyButton={false}
                    showFilterMenu={false}
                  />
                </DataTable>
              </div>
            </Container>
            {show && (
              <DeleteModel
                deleteUserClick={onDelete}
                onClickDeleteModel={setShow}
                name={deleteName}
              />
            )}
            {showCreate && (
              <CreateProduct
                show={showCreate}
                handleClose={handleCeateClose}
                setShowCreate={setShowCreate}
              />
            )}
            {showEdit && (
              <EditProduct
                productId={productId}
                show={showEdit}
                setShowEdit={setShowEdit}
              />
            )}
            {showDetails && (
              <DetailsModal
                show={showDetails}
                title={header}
                tabs={productTabs}
                summary={productSummary}
                currentItemId={id}
                ItemTitle="Products"
                header={header}
                setShow={setShowDetails}
              />
            )}
            {showViewCode && (
              <ViewBarCode
                show={showViewCode}
                barCodeItemData={barCodeItemData}
                handleClose={() => setShowViewCode(false)}
              />
            )}
          </div>
        </Container>
      </Layout>
    </>
  );
};
export default ProductIndex;
