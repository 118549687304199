import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { placeOfSupplyList } from "../../../../shared/config/placeOfSupply";
import { Form } from "react-bootstrap";
import { find } from "lodash";
import { i18n } from "../../../../shared/helpers/sharedMethods";

const AddressesData = ({ selectedParty }) => {
    // This portion is "Addresses" tab in party pop up details. To adjust the container design go to PartyIndex.js under "ProductTabs"
    return (
        <Container className="p-0 mt-5" fluid>
          <Row>
            {selectedParty?.party_addresses?.length === 0 ? (
              <p>{i18n("No Addresses Found for this party.")}</p>
            ) : (
             selectedParty?.party_addresses?.map((value, i) => (
                <Col
                  lg={4}
                  md={4}
                  sm={4}
                  key={i}
                  style={{ marginBottom: '20px' }}
                >
                  <div
                    style={{
                      border: '1px solid #ccc',
                      padding: '10px',
                      borderRadius: '5px',
                      wordWrap: 'break-word',
                      overflow: 'hidden',
                      whiteSpace: 'normal',
                    }}
                  >
                    <Form.Group>
                      <Form.Label style={{ fontSize: '14px' }}>
                        <strong>Address {i + 1}:</strong>
                      </Form.Label>
                      <div style={{ fontSize: '12px' }}>
                        {value?.name ? `${value.name},` : ''}
                      </div>
                      <div style={{ fontSize: '12px' }}>
                        {value?.address ? `${value.address},` : ''}
                      </div>
                      <div style={{ fontSize: '12px' }}>
                        {value?.city ? `${value.city},` : ''}
                      </div>
                      <div style={{ fontSize: '12px' }}>
                        {value?.state
                          ? `${find(placeOfSupplyList, { value: value?.state }).label} -`
                          : ''}
                        {` ${value?.pincode || ''}`}
                      </div>
                      {value?.phone_number && 
                    <div>
                    <span className="text-black-50 bold">{i18n("PHONE :")} </span>
                    {value?.phone_number ? `${value.phone_number}` : ""}</div>}
                    </Form.Group>
                  </div>
                </Col>
              ))
            )}
          </Row>
        </Container>
      )};
export default AddressesData;