import { Col, Row, Stack ,Image} from "react-bootstrap";
import ProfitBlock from "./blocks/ProfitBlock";
import NewCustomerBlock from "./blocks/NewCustomerBlock";
import AvgerageOrderValue from "./blocks/AvgerageOrderValue";
import RepeatCustomerBlock from "./blocks/RepeatCustomerBlock";
import SalesSummaryChartIndex from "./salesSummary/SalesSummaryChartIndex";
import ProductCategoryChartIndex from "./product_category/ProductCategoryChartIndex";
import TopSellingProducts from "./TopSellingProducts";
import { fetchOrderAndRevenue } from "../store/OrderAndRevenueSlice";
import { fetchNewCustomer } from "../store/NewCustomerSlice";
import { fetchProfitBlock } from "../store/ProfitBlockSlice";
import { fetchRepeatCustomer } from "../store/RepeatCustomerSlice";
import { fetchSalesSummaryChart } from "../store/SalesSummaryChartSlice";
import { fetchTopSellingProducts } from "../store/TopSellingProductsSlice";
import { fetchProductCategory } from "../store/productCategoryChartSlice";
import { useEffect } from "react";
import { useDispatch ,useSelector} from "react-redux";
import LoadingSpinner from '../../shared/components/LoadingSpinner';
const SalesTab = ({ reportPeriod, ItemActions, startDate, endDate,businessSwitch }) => {
  const dispatch = useDispatch();
  const accessToken = useSelector(
    (state) => state?.auth?.accessToken?.access_token
  );
  const selectedBusiness = useSelector((state) => state?.auth?.selectedBusiness);
  const tenantId = selectedBusiness?.pivot?.tenant_id;
  const loading = useSelector(state => state.orderAndRevenue.loading); 

  const newCustomerLoading = useSelector(state => state.newCustomer.loading);
  const profitBlockLoading = useSelector(state => state.profitBlock.loading);
  const repeatCustomerLoading = useSelector(state => state.repeatCustomer.loading);
  const salesSummaryLoading = useSelector(state => state.saleSummaryChart.loading);
  const topSellingProductsLoading = useSelector(state => state.topSellingProducts.loading);
  const productCategoryLoading = useSelector(state => state.productCategoryChart.loading);

  const isLoading = loading || newCustomerLoading || profitBlockLoading || repeatCustomerLoading || salesSummaryLoading || topSellingProductsLoading || productCategoryLoading;

  useEffect(() => {

    dispatch(fetchOrderAndRevenue({ startDate: startDate, endDate: endDate, token:accessToken,tenant_id : tenantId }));
    dispatch(fetchNewCustomer({ startDate: startDate, endDate: endDate, token:accessToken,tenant_id : tenantId }));
    dispatch(fetchProfitBlock({ startDate: startDate, endDate: endDate, token:accessToken,tenant_id : tenantId}));
    dispatch(fetchRepeatCustomer({ startDate: startDate, endDate: endDate, token:accessToken,tenant_id : tenantId}));
    dispatch(fetchSalesSummaryChart({ startDate: startDate, endDate: endDate, token:accessToken,tenant_id : tenantId}));
    dispatch(fetchTopSellingProducts({ startDate: startDate, endDate: endDate, token:accessToken,tenant_id : tenantId}));
    dispatch(fetchProductCategory({ startDate: startDate, endDate: endDate, token:accessToken,tenant_id : tenantId}));
  }, [startDate, endDate,businessSwitch]);
  return (
    <>
     {isLoading && <LoadingSpinner />}
      <Row>
        <Col lg={12}>{ItemActions}</Col>
      </Row>

      <Row className="mb-1">
        <Col xs={6} md={2} className="">
          <Stack direction="vertical" gap={6}>
            <ProfitBlock />
            <NewCustomerBlock />
          </Stack>
        </Col>
        <Col xs={6} md={2}>
          <Stack direction="vertical" gap={6}>
            <AvgerageOrderValue />
            <RepeatCustomerBlock />
          </Stack>
        </Col>
        <Col xs={12} md={8}>
          <SalesSummaryChartIndex
            reportPeriod={reportPeriod}
            startDate={startDate}
            endDate={endDate}
          />
        </Col>
      </Row>
      <Row className="mb-1">
        <Col xl={4} md={4} sm={6}>
          <ProductCategoryChartIndex reportPeriod={reportPeriod} />
        </Col>
        <Col xl={8} md={8} sm={6}>
          <TopSellingProducts />
        </Col>
      </Row>
    </>
  );
};

export default SalesTab;
