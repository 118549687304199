import React, { useEffect, useState } from "react";
import { QrReader } from 'react-qr-reader';
import { Card, Col, Row, Button, Stack } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import {
  fetchSubscriptionAttendance,
  selectAllSubscriptionAttendances,
  resetSubscriptionAttendance,
} from "../../../store/SubscriptionAttendanceSlice";
import {
  selectAllSubscriptionData,
} from "../../../store/SubscriptionSlice";
import AttendanceQrCode from "./AttendanceQrCode";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQrcode } from "@fortawesome/free-solid-svg-icons";
import { environment } from "../../../../shared/config/environment";
import SubsAttendanceDetails from "../../member_view/tabs/SubsAttendanceDetails";

const Attendance = ({ data }) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const subData = useSelector(selectAllSubscriptionData);
  const selectedBusiness = useSelector((state) => state?.auth?.selectedBusiness);
  const accessToken = useSelector((state) => state?.auth?.accessToken?.access_token);
  const tenantId = selectedBusiness?.pivot?.tenant_id;
  const memberId = data.member.member_id;
  const memberName = data.member.member_name;
  useEffect(() => {
    dispatch(resetSubscriptionAttendance());
    dispatch(fetchSubscriptionAttendance({
      id: data.member_id,
      token: accessToken,
      tenantId,
    }));
  }, [dispatch, data.member_id, accessToken, tenantId]);

  const attendanceData = useSelector(selectAllSubscriptionAttendances);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return new Intl.DateTimeFormat('en-GB', { day: '2-digit', month: 'short', year: '2-digit' }).format(date);
  };

  const formatTime = (timeString) => {
    const [hours, minutes] = timeString.split(':').map(Number);
    const date = new Date();
    date.setHours(hours);
    date.setMinutes(minutes);
    date.setSeconds(0);

    return new Intl.DateTimeFormat('en-GB', {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    }).format(date);
  };

  const [qrCodeItemData, setQrCodeItemData] = useState(null);

  useEffect(() => {
    const date = new Date();
    const today = date.toISOString().split('T')[0];
    const time = date.toTimeString().split(' ')[0];
    const qrCodeData = {
      member_id: data.member_id,
      subscription_id: data.id,
      date: today,
      time: time
    };
    setQrCodeItemData(JSON.stringify(qrCodeData));
  }, [data]);


  const groupedData = attendanceData.reduce((acc, record) => {
    const date = formatDate(record.attendance_date);
    if (!acc[date]) {
      acc[date] = [];
    }
    acc[date].push(record);
    return acc;
  }, {});

  const sortByCheckInTimeDesc = (a, b) => {
    return new Date(`1970-01-01T${b.time_in}Z`) - new Date(`1970-01-01T${a.time_in}Z`);
  };
  console.log('Attendance Data:', attendanceData);
  console.log('Grouped Data:', groupedData);
  
  return (
    <Card className="p-2" style={{ maxHeight: '700px' }}>
      <Row className='mt-4 px-2 mb-8'>
        <Col lg={6} className="px-0">
          <div className="d-flex justify-content-between">
            <div><h3 className="mx-4">Attendance Records</h3></div>

          </div>
          <Col lg={10} className="mx-4 mt-5" style={{ maxHeight: '445px', overflowY: 'auto' }}>
            {Object.keys(groupedData).length > 0 ? (
              Object.keys(groupedData).map((date) => (
                <div key={date} className="px-3 mb-3" style={{ border: '1px solid #ddd', borderRadius: '4px', padding: '10px' }}>
                  <h3 className="mx-3">{date}</h3>
                  <hr />
                  <div style={{ position: 'relative' }}>
                    {groupedData[date]
                      .sort(sortByCheckInTimeDesc)
                      .map((record, index, arr) => {
                        const isFirst = index === 0;
                        const isLast = index === arr.length - 1;

                        const color = isFirst ? 'red' : (isLast ? 'green' : '#DEDEDE');

                        return (
                          <div key={record.id} style={{ position: 'relative', marginBottom: '10px' }}>
                            <div
                              style={{
                                position: 'absolute',
                                left: '10px',
                                top: '50%',
                                width: '3px',
                                height: '80%',
                                backgroundColor: color,
                                borderRadius: '10%',
                                transform: 'translateY(-50%)'
                              }}
                            />
                            <table style={{ width: '100%', marginLeft: '11px' }}>
                              <thead>
                                <tr style={{ color: 'grey' }}>
                                  <th style={{ padding: '0 12px' }}>Check In</th>
                                  <th style={{ padding: '0 12px' }}>Check Out</th>
                                  <th style={{ padding: '0 12px' }}>Total</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td style={{ fontSize: '16px', padding: '0 12px' }}><strong>{formatTime(record.time_in)}</strong></td>
                                  <td style={{ fontSize: '16px', padding: '0 12px' }}><strong>{record.time_out ? formatTime(record.time_out) : 'N/A'}</strong></td>
                                  <td style={{ fontSize: '16px', padding: '0 12px' }}><strong>{record.hours ? `${record.hours} hr` : ' - '}</strong></td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        );
                      })
                    }
                  </div>
                </div>

              ))
            ) : (
              <p>No attendance records available.</p>
            )}
          </Col>
        </Col>

        <Col lg={6} className="px-0">
          <div className="d-flex justify-content-end">
            <Col lg={5} className="mx-4 " style={{ maxHeight: '445px', overflowY: 'auto' }}>
              <SubsAttendanceDetails data={attendanceData} />
            </Col>
            <Col lg={7} className="mx-4 ">
            <AttendanceQrCode
              qrCodeItemData={qrCodeItemData}
              memberId={memberId}
              memberName = {memberName}
            />        
          </Col>
          </div>
        </Col>
      </Row>
    </Card>
  );
};

export default Attendance;
