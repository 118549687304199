import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { axiosRequest } from "../../shared/helpers/axiosHelper";
import { i18n } from "../../shared/helpers/sharedMethods";

const initialState = {
  data: false,
  status: false,
  cancell: [],
  error: {},
};

export const SubscriptionCancell = createAsyncThunk(
  'subscriptionCancell/delete',
  async ({ id, token, tenantId }) => {
    try {
      const response = await axiosRequest({
        method: "DELETE",
        url: `subscriptions/subscription-cancell/${id}`,
        headers: {
          Authorization: `Bearer ${token}`,
          "X-Tenant": tenantId,
        },
      });
      ;
      return response;
    } catch (error) {
      await window.log.logToFile({ message: "Error while deleting subscription cancel", level: "error" });
      throw new Error('Error submitting subscription');
    }
  }
);

const subscriptionCancellSlice = createSlice({
  name: "subscriptionCancell",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(SubscriptionCancell.fulfilled, (state, action) => {
        state.cancell = action.payload;
        state.status = true;
      })
      .addCase(SubscriptionCancell.rejected, (state, action) => {
        state.status = false;
        state.error = (i18n("Item Creation failed. Please try again later."));
      })
  },
});

export default subscriptionCancellSlice.reducer;
