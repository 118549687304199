import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter
} from "@reduxjs/toolkit";
import { axiosRequest } from "../../shared/helpers/axiosHelper";
import { i18n } from "../../shared/helpers/sharedMethods";

// Initialize entity adapter for members
const memberAdapter = createEntityAdapter();

// Define initial state
const initialState = memberAdapter.getInitialState({
  status: "idle",
  error: null,
  savedEntity: null,
  member_id: null,
  partyName: null, // Initialize partyName if needed
  member: {},
  memberMobile:{},
  memberId:"",
});

// Thunks
export const MemberAllData = createAsyncThunk(
  "members/fetch",
  async (args, { rejectWithValue }) => {
    const { token, tenant_id: tenantId, isAdmin, trainer_id } = args;
    try {
      const url = isAdmin
        ? "subscriptions-members/member"
        : `subscriptions-members/assigned-member/${trainer_id}`; // Trainer gets members based on trainer_id

      const response = await axiosRequest({
        method: "GET",
        url, // Use the conditional URL
        headers: {
          Authorization: `Bearer ${token}`,
          "X-Tenant": tenantId,
        },
      });
      return response.data;
    } catch (err) {
      await window.log.logToFile({
        message: "Error while fetching member",
        level: "error",
      });
      return rejectWithValue(
        i18n("Unable to fetch member data. Please try again later.")
      );
    }
  }
);

export const DeleteMember = createAsyncThunk(
  "members/delete",
  async (args, { rejectWithValue }) => {
    const { token, id, tenant_id: tenantId } = args;
    try {
      await axiosRequest({
        method: "DELETE",
        url: `subscriptions-members/member/${id}`,
        headers: {
          Authorization: `Bearer ${token}`,
          "X-Tenant": tenantId,
        },
      });
      return id;
    } catch (err) {
      await window.log.logToFile({
        message: "Error while deleting member",
        level: "error",
      });
      return rejectWithValue(i18n("Unable to delete member. Please try again later."));
    }
  }
);

export const AddMember = createAsyncThunk(
  "members/add",
  async ({ data, token, tenant_id, terminalNumber }) => {
    try {
      const response = await axiosRequest({
        method: "POST",
        url: `subscriptions-members/member`,
        data,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "X-Tenant": tenant_id,
          terminal_number: terminalNumber,
        },
      });
      return response.data;
    } catch (error) {
      await window.log.logToFile({
        message: "Error while creating member",
        level: "error",
      });
      throw error;
    }
  }
);

export const EditMemberById = createAsyncThunk(
  "members/edit",
  async ({ id, data, token, tenant_id }) => {
    try {
      const response = await axiosRequest({
        method: "PUT",
        url: `subscriptions-members/member/${id}`,
        headers: {
          Authorization: `Bearer ${token}`,
          "X-Tenant": tenant_id,
        },
        data,
      });
      return response.data;
    } catch (error) {
      await window.log.logToFile({
        message: "Error while updating member",
        level: "error",
      });
      throw error;
    }
  }
);

export const fetchMemberById = createAsyncThunk(
  "members/fetchById",
  async ({ id, token, tenant_id }) => {
    try {
      const response = await axiosRequest({
        method: "GET",
        url: `subscriptions-members/member/${id}`,
        headers: {
          Authorization: `Bearer ${token}`,
          "X-Tenant": tenant_id,
        },
      });
      return response.data;
    } catch (err) {
      await window.log.logToFile({ message: "Error while fetching member", level: "error" });
      throw err;
    }
  }
);

export const getMemberNumber = createAsyncThunk(
  "members/getMemberNumber",
  async ({ token, tenant_id }) => {
    try {
      const response = await axiosRequest({
        method: "GET",
        url: `subscriptions-members/next-member-number`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "X-Tenant": tenant_id,
        },
      });
      return response.data;
    } catch (error) {
      await window.log.logToFile({
        message: "Error while fetching member number",
        level: "error",
      });
      throw error;
    }
  }
);

export const fetchMemberByMobileNo = createAsyncThunk(
  "members/fetchByMobileNo",
  async ({ no, token, tenant_id }) => {
    try {
      const response = await axiosRequest({
        method: "GET",
        url: `subscriptions-members/member-mobile/${no}`,
        headers: {
          Authorization: `Bearer ${token}`,
          "X-Tenant": tenant_id,
        },
      });
      return response.data;
    } catch (err) {
      await window.log.logToFile({ message: "Error while fetching member by mobile no", level: "error" });
      throw err;
    }
  }
);

// Slice
const memberSlice = createSlice({
  name: "members",
  initialState,
  reducers: {
    addPartyName(state, action) {
      state.partyName = action.payload;
    },
    UpdateMemberData(state, action) {
      const { id } = action.payload;
      memberAdapter.updateOne(state, {
        id,
        changes: action.payload
      });
    },
    resetMember: () => initialState,
    changeMember(state, action) {
      const { value, name } = action.payload;
      if (name === "member_name") {
        state.member.member_name = value;
      } else if (name === "mobile") {
        state.member.mobile = value;
      } else if (name === "age") {
        state.member.age = value;
      }else if (name === "weight") {
        state.member.weight = value;
      } else if (name === "height") {
        state.member.height	 = value;
      } else if (name === "trainer_id") {
        state.member.trainer_id	 = value;
      }
    },
    changeMemberNumber(state, action) {
      state.member_id = action.payload;      
    },
    resetMemberState(state,action){
      state.status = 'failed';
      state.member_id = null;
      state.member={}
    },
    initMemberEdit(state, action) {
      const tempMember = action.payload;
      if (tempMember) {
        state.member = Object.keys(tempMember).reduce((acc, key) => {
          acc[key] = tempMember[key] || "";
          return acc;
        }, {});
      }
    },
    resetMemberStatus(state, action){
      state.status = false;
    },
    undoError(state, action) {
      state.errorStatus = false;
      state.error = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(MemberAllData.pending, (state) => {
        state.status = "loading";
      })
      .addCase(MemberAllData.fulfilled, (state, action) => {
      
        memberAdapter.upsertMany(state, action.payload);
        state.status = "succeeded";
        state.error = null;
      })
      .addCase(MemberAllData.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload || i18n("Unable to fetch member data. Please try again later.");
      })
      .addCase(DeleteMember.pending, (state) => {
        state.status = "loading";
      })
      .addCase(DeleteMember.fulfilled, (state, action) => {
        memberAdapter.removeOne(state, action.payload);
        state.status = "succeeded";
        state.error = null;
      })
      .addCase(DeleteMember.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload || i18n("Unable to delete member. Please try again later.");
      })
      .addCase(AddMember.pending, (state) => {
        state.status = "loading";
      })
      .addCase(AddMember.fulfilled, (state, action) => {
        memberAdapter.addOne(state, action.payload);
        state.memberId = action.payload.id;
        state.status = true;
        state.savedEntity = action.payload;
      })
      .addCase(AddMember.rejected, (state, action) => {
        state.status = false;
        state.error = action.error.message;
      })
      .addCase(EditMemberById.pending, (state) => {
        state.status = "loading";
      })
      .addCase(EditMemberById.fulfilled, (state, action) => {
        memberAdapter.updateOne(state, {
          id: action.payload.id,
          changes: action.payload
        });
        state.status = "succeeded";
        state.savedEntity = action.payload;
      })
      .addCase(EditMemberById.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(fetchMemberById.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchMemberById.fulfilled, (state, action) => {
        memberAdapter.upsertOne(state, action.payload);
        state.status = "succeeded";
      })
      .addCase(fetchMemberById.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(getMemberNumber.fulfilled, (state, action) => {
        state.member_id = action.payload;
      })
      .addCase(fetchMemberByMobileNo.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchMemberByMobileNo.fulfilled, (state, action) => {
        state.memberMobile = action.payload;
        state.status = "succeeded";
      })
      .addCase(fetchMemberByMobileNo.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  }
});

// Export actions and reducer
export const {
  addPartyName,
  UpdateMemberData,
  resetMember,
  changeMember,
  changeMemberNumber,
  resetMemberState,
  initMemberEdit,
  resetMemberStatus,
  undoError
} = memberSlice.actions;

export default memberSlice.reducer;

// Selectors
export const {
  selectAll: selectAllMemberData,
  selectById: selectMemberDataById
} = memberAdapter.getSelectors((state) => state.members);
