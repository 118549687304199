import React, { useState, useEffect } from "react";
import { Button, Row, Col, Stack, Container,Image } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Layout from "../../shared/components/Layout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faChevronLeft,
  faDownload,
} from "@fortawesome/free-solid-svg-icons";
import { downloadCsvFile, handleExportClick } from "../../shared/export";
import DataGrid from "../../shared/components/DataGrid";
import { fetchItemReportByParty } from "../store/itemReportByPartySlice";
import ReportPeriod from "../../shared/components/ReportPeroid";
import CategoryFilter from "../components/Filters/CategoryFilter";
import PartyFilter from "../components/Filters/PartyFilter";
import Header from "../../shared/components/Header";
import { i18n } from "../../shared/helpers/sharedMethods";
import ItemNavbar from "../components/ItemNavbar";
import ShortcutKeys from "../../shared/components/ShortcutKeys";
import { formatAmount } from "../../pos/helpers/cartHelper";
import ReportDateRange from "../../shared/components/date_range/ReportDateRange";
import DatePicker from "../../shared/components/date_range/DatePicker";
import { CurrentWeekDateRange } from "../../shared/components/DateRangeYAxis";
import ReportHeader from "../components/ReportHeader";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import CustomeSortIcon from "../../shared/components/CustomeSortIcon";
import { UilImport } from "@iconscout/react-unicons";
import LoadingSpinner from '../../shared/components/LoadingSpinner';
import GlobalCurrency from "../../shared/components/GlobalCurrency";
import { fetchparty } from "../../party/store/getPartySlice";
import { fetchCategories } from "../../settings/store/categorysSlice";
const ItemReportByParty = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const accessToken = useSelector((state) => state?.auth?.accessToken?.access_token);
  const selectedBusiness = useSelector((state) => state?.auth?.selectedBusiness);
  const tenantId = selectedBusiness?.pivot?.tenant_id;
  const [reportPeriod, setReportPeriod] = useState("current-week");
  const [partyData, setPartyData] = useState("");
  const [categoryData, setCategoryData] = useState("");
  const [csvData, setCsvData] = useState("");
  const [startDate, setStartDate] = useState(null); // State for start date
  const [endDate, setEndDate] = useState(null);
  const [sortField, setSortField] = useState(null);
  const [sortOrder, setSortOrder] = useState(1);


  const handleReset = () => {
    const salesDateLimits = ReportDateRange({
      reportPeriod: reportPeriod,
    });
    setStartDate(salesDateLimits.startDate);
    setEndDate(salesDateLimits.endDate);
    setReportPeriod("current-week");
    setPartyData("");
    setCategoryData("");
  };

  useEffect(() => {
    dispatch(
      fetchItemReportByParty({ startDate: startDate, endDate:endDate, partyData:partyData, categoryData:categoryData, token:accessToken,tenant_id:tenantId}));
  }, [startDate, endDate, partyData, categoryData]);
useEffect(() => {
  dispatch(fetchparty({ token: accessToken, tenant_id: tenantId }));
}, [accessToken, tenantId, dispatch]);
  useEffect(() => {
    dispatch(fetchCategories({ token: accessToken, tenant_id: tenantId }));
}, [accessToken, tenantId, dispatch]);

  useEffect(() => {
    const dateLimits = ReportDateRange({ reportPeriod });
    setStartDate(dateLimits.startDate);
    setEndDate(dateLimits.endDate);
  }, [reportPeriod]);

  const reportItems = useSelector(
    (state) => state.itemReportByParty.reportItems
  );
  const status = useSelector((state) => state.itemReportByParty.status);
  const errorMessage = useSelector(
    (state) => state.itemReportByParty.errorMessage
  );
  const currencySymbol  = useSelector((state) => state.getSetting?.dataSetting.currency);
  const loading = useSelector((state) => state.itemReportByParty.loading);

  const dateTimeString = new Date()
  .toLocaleString()
  .replace(/[-]/g, " ")
  .replace(/:/g, "-");
const fileName = `Item_report_by_party_${dateTimeString}`;

  const handleExportButtonClick = () => {
    const exportData =
      reportItems &&
      Array.isArray(reportItems) &&
      reportItems.map((val) => ({
        "PRODUCT NAME": val.itemProduct ? val.itemProduct : "-",
        "PRODUCT CODE": val.itemCode ? val.itemCode : "-",
        "PURCHASE AMOUNT": val?.purchaseAmount ? val?.purchaseAmount : 0,
        "PURCHASE QUANTITY": val.purchaseQuantity ? val.purchaseQuantity : "-",
        "SALES AMOUNT": val.saleAmount ? val.saleAmount : 0,
        "SALES QUANTITY": val.saleQuantity ? val.saleQuantity : "-",
      }));
    handleExportClick(
      exportData,
      setCsvData,
      downloadCsvFile,
      fileName
    );
  };
    const customSort = (field) => {
      setSortField(field);
      setSortOrder(sortOrder === 1 ? -1 : 1);
    };
    const sortedData = [...reportItems].sort((a, b) => {
      if (sortField) {
        const compareValueA = a[sortField];
        const compareValueB = b[sortField];
        if (compareValueA < compareValueB) {
          return sortOrder;
        }
        if (compareValueA > compareValueB) {
          return -sortOrder;
        }
      }
      return 0;
    });
    const headerStyle = {
      backgroundColor: "white",
    };
    const headerCellStyle = {
      fontWeight: "bold",
      fontSize: "11px",
    };

    const quantityBodyTemplate = (rowData) => {
      const quantity = rowData?.purchaseQuantity && rowData?.purchaseQuantity;
      const units = rowData?.saleUnit;
      return quantity
        ? `${quantity} ${units ? units : ""}`:0;
    };

    const quantitySalesBodyTemplate = (rowData) => {
      const quantity = rowData?.saleQuantity && rowData?.saleQuantity;
      const units = rowData?.saleUnit;
      return quantity
        ? `${quantity} ${units ? units : ""}`:0;
    };

  return (
    <Layout>
        {loading && <LoadingSpinner />}
      <Header
        currentTitle={i18n("Item Report By Party")}
        ItemActions={
          <Stack gap={3} direction="horizontal" className="mt-1 mb-1 float-end">
            <ShortcutKeys />
          </Stack>
        }
      />
      <Container fluid className="px-5">
        <div className="border " style={{ background: "white" }}>
          <ItemNavbar />
          <ReportHeader
            ItemActions={
              <Stack gap={2} direction="horizontal" className="pt-3 px-3 mb-3">
                <Stack direction="horizontal">
                  <DatePicker
                    startDate={startDate}
                    setStartDate={setStartDate}
                    endDate={endDate}
                    setEndDate={setEndDate}
                  />
                </Stack>
                <Stack className=" ">
                  <ReportPeriod
                    reportPeriod={reportPeriod}
                    setReportPeriod={setReportPeriod}
                  />
                </Stack>
                <Stack className=" ">
                  <PartyFilter
                    partyData={partyData}
                    setPartyData={setPartyData}
                  />
                </Stack>
                <Stack className=" ">
                  <CategoryFilter
                    categoryData={categoryData}
                    setCategoryData={setCategoryData}
                    title="product"
                  />
                </Stack>
                <Stack
                  gap={1}
                  direction="horizontal"
                  style={{ marginLeft: "1px" }}
                >
                  <Button
                    className="rounded-0"
                    variant="outline-danger"
                    onClick={handleReset}
                  >
                    {i18n("Reset")}
                    </Button>
                </Stack>
                <Button
                  className="fw-bolder d-flex align-items-center rounded-0"
                  onClick={handleExportButtonClick}
                >
                  <UilImport
                    size="18"
                    className="mx-2"
                    style={{ cursor: "pointer" }}
                  />
                  {i18n("Export")}
                </Button>
              </Stack>
            }
          />
          {/* {status ? (
                <DataGrid
                  allData={reportItems}
                  data={reportItems}
                  columns={gridColumns}
                  isPaginated={true}
                  title="Report"
                />
              ) : (
                <p className="d-flex justify-content-center p-2 fs-3 fw-bolder">
                  {i18n("Error while fetching Item report by Party")}
                </p>
              )} */}
          <Container fluid className="px-3 mb-4">
            <div className="bordered-container">
              {status && reportItems ? (
                <DataTable
                  value={sortedData}
                  headerStyle={headerStyle}
                  paginator
                  rows={10}
                  dataKey="id"
                  emptyMessage={i18n("No Item Report By Party found.")}
                  className="p-datatable-gridlines full-width-table custom-datatable p-component"
                  scrollable
                  scrollHeight="400px"
                  sortMode="multiple"
                  removableSort
                >
                  <Column
                    field="itemProduct"
                    header={() => (
                      <div
                        className={`custom-header-${
                          sortField === "itemProduct"
                            ? sortOrder === 1
                              ? "up"
                              : "down"
                            : ""
                        }`}
                        onClick={() => customSort("itemProduct")}
                        style={{
                          cursor: "pointer",
                          display: "flex",
                          alignItems: "center",
                          fontSize: "11px",
                          lineHeight: "11px",
                        }}
                      >
                        {i18n("ITEM NAME")}
                        <CustomeSortIcon
                          sortOrder={
                            sortField === "itemProduct" ? sortOrder : 0
                          }
                          style={{ marginLeft: "4px", verticalAlign: "middle" }}
                        />
                      </div>
                    )}
                    sortable
                    headerStyle={headerCellStyle}
                  />
                  <Column
                    field="itemCode"
                    header={() => (
                      <div
                        className={`custom-header-${
                          sortField === "itemCode"
                            ? sortOrder === 1
                              ? "up"
                              : "down"
                            : ""
                        }`}
                        onClick={() => customSort("itemCode")}
                        style={{
                          cursor: "pointer",
                          display: "flex",
                          alignItems: "center",
                          fontSize: "11px",
                          lineHeight: "11px",
                        }}
                      >
                        {i18n("ITEM CODE")}
                        <CustomeSortIcon
                          sortOrder={sortField === "itemCode" ? sortOrder : 0}
                          style={{ marginLeft: "4px", verticalAlign: "middle" }}
                        />
                      </div>
                    )}
                    sortable
                    headerStyle={headerCellStyle}
                  />

                  <Column
                    field="purchaseAmount"
                    header={() => (
                      <div
                        className={`custom-header-${
                          sortField === "purchaseAmount"
                            ? sortOrder === 1
                              ? "up"
                              : "down"
                            : ""
                        }`}
                        onClick={() => customSort("purchaseAmount")}
                        style={{
                          cursor: "pointer",
                          display: "flex",
                          alignItems: "center",
                          fontSize: "11px",
                          lineHeight: "11px",
                        }}
                      >
                        {i18n("PURCHASE AMOUNT")}
                        <CustomeSortIcon
                          sortOrder={
                            sortField === "purchaseAmount" ? sortOrder : 0
                          }
                          style={{ marginLeft: "4px", verticalAlign: "middle" }}
                        />
                      </div>
                    )}
                    body={(row) => (
                      <span>
                        {row.purchaseAmount ? (
                          <GlobalCurrency price={(row.purchaseAmount)} />
                        ) : (
                          <GlobalCurrency price={0} />
                        )}
                      </span>
                    )}
                    sortable
                    headerStyle={headerCellStyle}
                  />
                  <Column
                    field="purchaseQuantity"
                    header={() => (
                      <div
                        className={`custom-header-${
                          sortField === "purchaseQuantity"
                            ? sortOrder === 1
                              ? "up"
                              : "down"
                            : ""
                        }`}
                        onClick={() => customSort("purchaseQuantity")}
                        style={{
                          cursor: "pointer",
                          display: "flex",
                          alignItems: "center",
                          fontSize: "11px",
                          lineHeight: "11px",
                        }}
                      >
                        {i18n("PURCHASE QUANTITY")}
                        <CustomeSortIcon
                          sortOrder={
                            sortField === "purchaseQuantity" ? sortOrder : 0
                          }
                          style={{ marginLeft: "4px", verticalAlign: "middle" }}
                        />
                      </div>
                    )}
                    body={quantityBodyTemplate}
                    sortable
                    headerStyle={headerCellStyle}
                  />

                  <Column
                    field="saleAmount"
                    header={() => (
                      <div
                        className={`custom-header-${
                          sortField === "saleAmount"
                            ? sortOrder === 1
                              ? "up"
                              : "down"
                            : ""
                        }`}
                        onClick={() => customSort("saleAmount")}
                        style={{
                          cursor: "pointer",
                          display: "flex",
                          alignItems: "center",
                          fontSize: "11px",
                          lineHeight: "11px",
                        }}
                      >
                        {i18n("SALES AMOUNT")}
                        <CustomeSortIcon
                          sortOrder={sortField === "saleAmount" ? sortOrder : 0}
                          style={{ marginLeft: "4px", verticalAlign: "middle" }}
                        />
                      </div>
                    )}
                    body={(row) => (
                      <span>
                        {row.saleAmount ? (
                          <GlobalCurrency price={(row.saleAmount)} />
                        ) : (
                          <GlobalCurrency price={0} />
                        )}
                      </span>
                    )}
                    sortable
                    headerStyle={headerCellStyle}
                  />
                  <Column
                    field="saleQuantity"
                    header={() => (
                      <div
                        className={`custom-header-${
                          sortField === "saleQuantity"
                            ? sortOrder === 1
                              ? "up"
                              : "down"
                            : ""
                        }`}
                        onClick={() => customSort("saleQuantity")}
                        style={{
                          cursor: "pointer",
                          display: "flex",
                          alignItems: "center",
                          fontSize: "11px",
                          lineHeight: "11px",
                        }}
                      >
                        {i18n("SALES QUANTITY")}
                        <CustomeSortIcon
                          sortOrder={
                            sortField === "saleQuantity" ? sortOrder : 0
                          }
                          style={{ marginLeft: "4px", verticalAlign: "middle" }}
                        />
                      </div>
                    )}
                    body={quantitySalesBodyTemplate}
                    sortable
                    headerStyle={headerCellStyle}
                  />
                </DataTable>
              ) : (
                <p>{i18n("Loading")}</p>
              )}
            </div>
          </Container>
        </div>
      </Container>
    </Layout>
  );
};

export default ItemReportByParty;
