import React, {useEffect} from "react";
import { useSelector, useDispatch } from "react-redux";
import { Badge, ListGroup, Row, Stack } from "react-bootstrap";
import { format } from "date-fns";
import { i18n } from "../../shared/helpers/sharedMethods";
import { useNavigate } from "react-router";
import { UilFileTimes } from "@iconscout/react-unicons";
import GlobalCurrency from "../../shared/components/GlobalCurrency";
import { fetchLatestTransaction } from "../store/LatestTransactionSlice";

const LatestTransactionTable = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const accessToken = useSelector(
    (state) => state?.auth?.accessToken?.access_token
  );
  const selectedBusiness = useSelector((state) => state?.auth?.selectedBusiness);
  const tenantId = selectedBusiness?.pivot?.tenant_id;
  const reportItems = useSelector(
    (state) => state.latestTransaction.reportItems
  );

  useEffect(() => {
    dispatch(fetchLatestTransaction({
      token:accessToken,
      tenant_id : tenantId
    }));
  },[accessToken,tenantId])

  return (
    <div className="px-0">

      {!reportItems.length > 0 && (
        <div
          className="col d-flex justify-content-center align-items-center "
          style={{ height: "75vh" }}
        >
          <div style={{ textAlign: "center" }}>
            <UilFileTimes size="40" color="gray" />
            <p className="mt-2" style={{ fontSize: "12px", color: "gray" }}>
              {i18n("No Latest Transactions.")}
            </p>
          </div>
        </div>
      )}
      <ListGroup
        className="px-2"
        style={{
          height: "auto",
          overflow: "auto",
          gap: "0px",
          overflowX: "hidden",
        }}
      >
        {reportItems &&
          Array.isArray(reportItems) &&
          reportItems?.map((value, index) => {
            return (
              
                <ListGroup.Item
                key={`${value.voucher_number}-${index}`}
                  className=" cursor-pointer py-2 mt-2 mb-2 border"
                  style={{
                    // borderBottom: "1px solid lightgray",
                    // borderLeft: "none",
                  }}
                >
                  <div className="">
                    <Stack
                      direction="horizontal"
                      className="d-flex justify-content-between"
                      style={{ color: "GrayText" }}
                    >
                      <span className="">
                        # {value.voucher_number ? value?.voucher_number : "-"}
                      </span>
                      <span>
                        {value.items ? value.items?.length : "-"}{" "}
                        {i18n("item(s)")}
                      </span>
                    </Stack>

                    <Stack
                      direction="horizontal"
                      className="d-flex justify-content-between mt-1"
                    >
                      <span className="" style={{ fontSize: "14px" }}>
                        {value.partyName ? value?.partyName : "-"}
                      </span>
                      <span className="" style={{ fontSize: "14px" }}>
                        <GlobalCurrency price={value?.grand_total} />
                      </span>
                    </Stack>

                    <Stack
                      direction="horizontal"
                      className="d-flex justify-content-between mt-1 pb-1"
                    >
                      <span
                        className="lastest-transaction-responsive"
                        style={{ color: "GrayText" }}
                      >
                        {value.created_at
                          ? format(
                            new Date(value.created_at),
                            "yyyy-MM-dd hh:mm a"
                          )
                          : "-"}
                      </span>

                      <Badge
                        bg={
                          value.status === "Paid"
                            ? "success"
                            : value.status === "Unpaid"
                              ? "danger"
                              : "warning"
                        }
                      >
                        <span className="lastest-transaction-responsive">
                          {value.status}
                        </span>
                      </Badge>
                    </Stack>
                  </div>
                </ListGroup.Item>
              
            );
          })}
      </ListGroup>
    </div>
  );
};

export default LatestTransactionTable;
