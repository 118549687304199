import { useDispatch, useSelector } from "react-redux";
import "react-bootstrap-typeahead/css/Typeahead.css";
import "../../../assets/css/custom.css";
import { addPartyInPayment } from "../store/paymentEntitySlice";
import { resetPartyPayment } from "../store/partyPaymentsSlice";
import { fetchSalesbyParty } from "../store/paymentEntitySlice";
import { Col, Row, Form, InputGroup } from "react-bootstrap";
import { selectAllparty } from "../../party/store/getPartySlice";
import { i18n, placeholderText } from "../../shared/helpers/sharedMethods";
import { addPartyPayments } from "../store/partyPaymentsSlice";
import { iteratePartyPayment } from "../helpers/paymentHelper";
import React, { useEffect, useState } from "react";
import { Menu, MenuItem, Typeahead } from "react-bootstrap-typeahead";
import { cloneDeep, isEmpty } from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquareXmark } from "@fortawesome/free-solid-svg-icons";

const PaymentDetails = (props) => {
  const { error, setError, edit, type, editData } = props;

  ;
  ;

  const dispatch = useDispatch();

  const allParty = useSelector(selectAllparty);

  const { party, paymentDate } = useSelector((state) => state.paymentEntity);
  ;

  const [value, setValue] = useState([editData]);
  const [selected, setSelected] = useState([]);
  const [selectedItem, setSelectedItem] = useState([]);
  const [partyChanged, setPartyChanged] = useState(false);
  ;
  const selectedBusiness = useSelector(
    (state) => state?.auth?.selectedBusiness
  );
  const accessToken = useSelector(
    (state) => state?.auth?.accessToken?.access_token
  );
  const tenantId = selectedBusiness?.pivot?.tenant_id;
  useEffect(() => {
    if (edit && party && !isEmpty(party)) {
      setSelected([party]);
    }
  }, [party, edit]);

  useEffect(() => {
    console.log('selected2',selected);
    if (selected && partyChanged) {
      
      if (selected.length > 0) {
        // item.payment_type =selected
        ;
        let item = cloneDeep(selected[0]); //getting the first one from array of selections, since selected will always be an array
        item.payment_type = selected.payment_type;
        dispatch(addPartyInPayment(item));
        setPartyChanged(false);
        dispatch(fetchSalesbyParty({ tenant_id:tenantId, token: accessToken, data:item })).then((res) => {
          if (res) {
            ;
            const payment = res.payload;
            dispatch(addPartyPayments(iteratePartyPayment(payment)));
          }
        });
      } else {
        ;
        dispatch(addPartyInPayment({}));

        setPartyChanged(false);
      }
    }
  }, [selected, partyChanged]);

  const partyPaymentClear = () => {
    ;
    dispatch(resetPartyPayment());
  };

  const handleSelection = (selected) => {
    ;
    selected.payment_type = type;
    ;
    setPartyChanged(true);
    setSelected(selected);
  };

  const filterByFields = ["name", "phone_number"];
  const ref = React.createRef();
  return (
    <>
      <Row>
        <Form.Group className="mt-3 " as={Col} lg={12}>
          <Form.Label style={{ color: "#838587" }}>{i18n("Party")}</Form.Label>
          <InputGroup>
            {allParty && (
              <Typeahead
                className="custom-disable"
                allowNew
                ref={ref}
                renderMenu={(results, menuProps) => (
                  <Menu {...menuProps}>
                    {results.map((result, position) => {
                      return (
                        <MenuItem
                          key={position}
                          option={result}
                          position={position}
                        >
                          <div>
                            {result.name}
                            <div>
                              {result.phone_number ? (
                                <small>
                                  <b>{i18n("Phone")}:</b> {result.phone_number}
                                </small>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </MenuItem>
                      );
                    })}
                  </Menu>
                )}
                labelKey="name"
                options={allParty}
                onChange={handleSelection}
                id={"party-typeahead"}
                placeholder={placeholderText("Search by Name / Phone number")}
                autoFocus
                selected={selected}
                filterBy={filterByFields}
                disabled={edit ? true : false}
              />
            )}
            {!edit && selected && selected.length > 0 ? (
              <InputGroup.Text
                className="cursor-pointer fs-2"
                style={{ background: "none" }}
                onClick={() => {
                  setSelected([]);
                  setPartyChanged(true);
                  partyPaymentClear();
                }}
              >
                <FontAwesomeIcon
                  icon={faSquareXmark}
                  title="Clear Item"
                  className="text-danger"
                />
              </InputGroup.Text>
            ) : (
              ""
            )}
          </InputGroup>
        </Form.Group>
      </Row>
    </>
  );
};
export default PaymentDetails;
