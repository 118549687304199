import { useEffect, useRef, useState } from "react";
import { Button, Card, Col, Form, Image, Row, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { setSignature } from "../store/settingSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faX } from "@fortawesome/free-solid-svg-icons";
import { i18n } from "../../shared/helpers/sharedMethods";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import imageCompression from 'browser-image-compression';

const Signature = () => {
  const signature = useSelector(
    (state) => state.setting?.billing_settings?.signature
  );
  const fileInputRef = useRef(null);
  const [tempSignature, setTempSignature] = useState(null);
  const [signError, setSignError] = useState("");
  // const [imageCropped, setImageCropped] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [imgSrc, setImgSrc] = useState("");
  const [crop, setCrop] = useState({ unit: 'px', x: 0, y: 0, width: 500, height: 200 ,aspect: 2});
  const [completedCrop, setCompletedCrop] = useState(null);
  const [scale, setScale] = useState(1);
  const [rotate, setRotate] = useState(0);
  const imgRef = useRef(null);
  const dispatch = useDispatch();

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleSignFileChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setCrop({ unit: 'px', x: 0, y: 0, width: 400, height: 150 ,aspect:2 }); 
      const reader = new FileReader();
      reader.onload = () => {
        setImgSrc(reader.result?.toString() || "");
        setCompletedCrop(null);
        setShowModal(true);
      
      };
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const onImageLoad = (event) => {
    const { width, height } = event.target; 
    const cropWidth = 350; 
    const cropHeight = 220; 
  
    setCrop({
      unit: 'px',
      x: Math.max(0, (width - cropWidth) / 2), 
      y: Math.max(0, (height - cropHeight) / 2), 
      width: cropWidth,
      height: cropHeight,
      aspect: 2,
    });
  };

  const handleImageUpload = async (blob) => {
    try {
      const options = {
        maxSizeMB: 0.05,
        maxWidthOrHeight: 300,
        initialQuality: 0.1,
        useWebWorker: true,
      };

      const compressedBlob = await imageCompression(blob, options);
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64Image = reader.result;
        dispatch(setSignature(base64Image));
        setShowModal(false);
      };
      reader.readAsDataURL(compressedBlob);
    } catch (error) {
    }
  };

  useEffect(() => {
    setTempSignature(signature);
  }, [signature]);

 

  useEffect(() => {
    if (completedCrop && imgRef.current) {
      const image = imgRef.current;
      const canvas = document.createElement("canvas");
      canvasPreview(image, canvas, completedCrop, scale, rotate);
      setSignError("");
    }
  }, [completedCrop, scale, rotate]);
  const canvasPreview = (image, canvas, crop, scale = 1, rotate = 0, quality = 0.7) => {
    const ctx = canvas.getContext("2d");
    if (!ctx) throw new Error("No 2D context");

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;

    const pixelCrop = {
      x: Math.floor(crop.x * scaleX),
      y: Math.floor(crop.y * scaleY),
      width: Math.floor(crop.width * scaleX),
      height: Math.floor(crop.height * scaleY),
    };
    const rotateRads = rotate * (Math.PI / 180);
    const cropWidth = pixelCrop.width * scale;
    const cropHeight = pixelCrop.height * scale;
    canvas.width = cropWidth;
    canvas.height = cropHeight;

    ctx.clearRect(0, 0, canvas.width, canvas.height);

    ctx.save();

    ctx.translate(canvas.width / 2, canvas.height / 2);
    ctx.rotate(rotateRads);
    ctx.scale(scale, scale);
    ctx.drawImage(
      image,
      pixelCrop.x,
      pixelCrop.y,
      pixelCrop.width,
      pixelCrop.height,
      -pixelCrop.width / 2,
      -pixelCrop.height / 2,
      pixelCrop.width,
      pixelCrop.height
    );
    ctx.restore();
    return canvas.toDataURL('image/png', quality);
  };
  const handleCloseModal = () => {
    setShowModal(false);
    fileInputRef.current.value = ""; 
  };

  return (
    <Card>
      <Row>
        <Col lg={12}>
          <Form.Group className="">
            <Form.Label className="fs-2">
              <span
                className="pb-2"
                style={{
                  borderBottom: "3px solid #1a237e",
                  fontWeight: 600,
                  fontSize: "15px",
                }}
              >
                {i18n("Signature")}
              </span>
            </Form.Label>
          </Form.Group>
        </Col>
        <Col lg={12}>
          <Row className="">
            {tempSignature ? (
              <Col
                className=" mt-2  d-flex"
                md={6}
              // style={{
              //   position: "relative",
              //   display: "inline-block",
              // }}
              >
                <div className="border">
                  <Image
                    src={tempSignature}
                    alt="signature"
                    style={{ width: "200px", height: "100px" }}
                    onClick={() => {
                      setImgSrc(tempSignature);
                      setShowModal(false);
                    //   setImageCropped(true);
                    }}
                  />
                </div>
                <FontAwesomeIcon
                  className=" mx-1 m"
                  icon={faX}
                  fontSize={10}
                  style={{
                    //position: "absolute",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setTempSignature(null);
                    dispatch(setSignature(null));
                    // setImageCropped(false);
                  }}
                />
              </Col>
            ) : (
              <Col md={2} className="mt-2">
                <input
                  name="image"
                  ref={fileInputRef}
                  onChange={handleSignFileChange}
                  className="d-none"
                  type="file"
                  accept="image/*"
                />
                <Button
                  style={{
                    width: "200px",
                    height: "100px",
                    border: "3px dashed #d6d6d6",
                  }}
                  variant="outline-primary"
                  onClick={handleButtonClick}
                ></Button>
              </Col>
            )}
          </Row>
        </Col>
      </Row>
      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        centered
        className="custom-modal-shadow"
      >
        <Modal.Header closeButton>
          <Modal.Title>Crop Image</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {signError && (
            <div className="text-danger">
              {signError}
            </div>
          )}
          {imgSrc && (
            <div
              style={{
                marginTop: "20px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <ReactCrop
                crop={crop}
                onChange={(_, percentCrop) => setCrop(percentCrop)}
                onComplete={(c) => setCompletedCrop(c)}
                locked 
                aspect={2} 
              >
                <img
                  ref={imgRef}
                  alt="Crop me"
                  src={imgSrc}
                  style={{ 
                    height: '400px', width: '400px'
                  }}
                  onLoad={onImageLoad}
                />
              </ReactCrop>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "10px 20px",
          }}
        >
          <Col className='d-flex justify-content-end px-5 mb-5 mt-3 mx-2' style={{ columnGap: 10 }}>
            <Button
              variant="outline-secondary"
              onClick={handleCloseModal}
            >
              Cancel
            </Button>
            <Button
              variant="primary"
              onClick={() => {               

                const canvas = document.createElement("canvas");
                const finalCrop = completedCrop?.width && completedCrop?.height 
                ? completedCrop 
                : crop;
                canvasPreview(imgRef.current, canvas,finalCrop,scale, rotate);
                canvas.toBlob((blob) => {
                    if (blob) {
                      handleImageUpload(blob);
                     
                    }
                  }, "image/png", 0.9); 
                }}
            >
              Save
            </Button>

          </Col>
        </Modal.Footer>
      </Modal>
    </Card>
  );
};

export default Signature;
