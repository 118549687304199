import { Card, Col, Form, Row } from "react-bootstrap";
import { i18n } from "../../../shared/helpers/sharedMethods";
import SubscriptionCartBlock from "./components/SubscriptionCartBlock";

const ItemDetailsForm = (props) => {
  const {
    values,
    handleChange,
    discountEnabled,
    toggleDiscount,
    cessEnabled,
    toggleCess,
    isEdit,
    item
  } = props;

  return (
    <>
      <Card className="mt-4">
        <Row>
          <Col lg={6} className="">
            <Form.Label>
              <span
                className="bold pb-2"
                style={{ borderBottom: "3px solid #1a237e" }}
              >
                {i18n("PLAN")}
              </span>
            </Form.Label>
          </Col>
          <Col className="d-flex justify-content-end ">
            <Form.Switch
              id="toggle-discount"
              label={
                discountEnabled ? i18n("Discount Enabled") : i18n("Discount")
              }
              checked={discountEnabled}
              onChange={toggleDiscount}
              style={{ marginRight: "10px" }}
            />
            <Form.Switch
              id="toggle-cess"
              label={cessEnabled ? i18n("CESS Enabled") : i18n("CESS")}
              checked={cessEnabled}
              onChange={toggleCess}
              style={{ marginLeft: "15px", marginRight: "10px" }}
            />
          </Col>
        </Row>

        <Row className="mt-4">
          <Col lg={12}>
            {" "}
            <SubscriptionCartBlock
              values={values}
              handleChange={handleChange}
              cessEnabled={cessEnabled}
              discountEnabled={discountEnabled}
              isEdit={isEdit}
              item={item}
            />
          </Col>
        </Row>
      </Card>
    </>
  );
};

export default ItemDetailsForm;
