import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import { Badge, Row, Table } from "react-bootstrap";
import { i18n } from "../../shared/helpers/sharedMethods";
import GlobalCurrency from "../../shared/components/GlobalCurrency";

const SalesSummaryDataTables = ({ partyName, filteredReportItems, currencySymbol }) => {

  const [totalProfit, setTotalProfit] = useState(0);
  useEffect(() => {
    if (filteredReportItems && Array.isArray(filteredReportItems)) {
      const totalAmount = filteredReportItems.reduce((sum, item) => sum + item.amount, 0);
      setTotalProfit(totalAmount);
    }

  }, [filteredReportItems]);

  return (
    <Row className="mb-5 border" style={{ background: "white" }}  >
      <h3 className="text-center pt-2">{i18n("Category Name")} - {partyName ? partyName : i18n('No Category')}</h3>
      <Table hover responsive bordered>
        <thead>
          <tr className="text-center" style={{ color: '#495057', backgroundColor: '#F9FAFB' }}>
            <th style={{ backgroundColor: '#F9FAFB' }}>{i18n("DATE")}</th>
            <th style={{ backgroundColor: '#F9FAFB' }}>{i18n("INVOICE NO")}</th>
            <th style={{ backgroundColor: '#F9FAFB' }}>{i18n("PARTY SALE")}</th>
            <th style={{ backgroundColor: '#F9FAFB' }}>{i18n("CREATED BY")}</th>
            <th style={{ backgroundColor: '#F9FAFB' }}>{i18n("DUE DATE")}</th>
            <th style={{ backgroundColor: '#F9FAFB' }}>{i18n("AMOUNT")}</th>
            <th style={{ backgroundColor: '#F9FAFB' }}>{i18n("BALANCE")}</th>
            <th style={{ backgroundColor: '#F9FAFB' }}>{i18n("INVOICE STATUS")}</th>
          </tr>
        </thead>
        <tbody>
          {filteredReportItems && filteredReportItems.map((value, index) => (
            <tr className="text-center" key={index}>
              <td>{value.date ? format(new Date(value.date), "dd-MM-yyyy") : "-"}</td>
              <td>{value.invNo ? value.invNo : "-"}</td>
              <td>{value.partyName ? value.partyName : "-"}</td>
              <td>{value.createdBy ? value.createdBy : "-"}</td>
              <td>{value.dueDate ? format(new Date(value.dueDate), "dd-MM-yyyy") : "-"}</td>
              <td><GlobalCurrency price={value.amount ? parseFloat(value.amount) : 0} /></td>
              <td><GlobalCurrency price={value.balance ? parseFloat(value.balance) : 0} /></td>
              <td> <Badge bg={
                value.reportStatus === 'Paid' ? "success" :
                  value.reportStatus === 'Unpaid' ? "danger" : "warning"
              }>
                {value.reportStatus}
              </Badge></td>

            </tr>
          ))}
          <tr className="text-center">
            <th className="fw-bolder text-center">{i18n("Total")}</th>
            <th></th>
            <th ></th>
            <th></th>
            <th ></th>
            <th className="fw-bolder"><GlobalCurrency price={totalProfit ? parseFloat(totalProfit) : 0} />
            </th>
          </tr>
        </tbody>

      </Table>
    </Row>
  );
};

export default SalesSummaryDataTables;