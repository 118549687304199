import { Col, Stack, Button, Row } from "react-bootstrap";
import { i18n } from "../../shared/helpers/sharedMethods";
import LatestTransactionTable from "./LatestTransactionTable";
import { useNavigate } from "react-router";

const LatestTransaction = () => {
  const navigate = useNavigate();

  return (
    <Col xl={2}
      className="shadow-sm hide-below-1600 border px-0"
      
      style={{
        height: "calc(100vh - 70px)",
        overflow: "auto",
        overflowX: "hidden",
        backgroundColor: "white",
      }}
    >
      <div className="d-flex justify-content-between align-items-center py-2" style={{borderBottom:'1px solid lightgray'}}>
      <h4 className="mt-1 fw-bolder px-2 ">{i18n("Latest Transactions")}</h4>        <Button
          variant="outline-secondary"
          className="mx-2"
          size="sm"
          onClick={() => navigate("/voucher/sales-invoice")}
          style={{ fontSize: "10px", marginLeft: "20px" }}
        >
          {i18n("View all")}
        </Button>
      </div>

      {/* <Stack
        direction="horizontal"
        gap={5}
        className="d-flex justify-content-between p-2"
      >
        <span className="dashboard-header fw-bolder text-center">
          {i18n("Latest Transactions")}
        </span>
        <Button
          variant="outline-secondary"
          className="latest-transaction-responsive d-flex float-end"
          size="sm"
          onClick={() => navigate("/voucher/sales-invoice")}
          style={{ fontSize: "10px" }}
        >
          {i18n("View all")}
        </Button>
      </Stack> */}

      <LatestTransactionTable />
    </Col>
  );
};

export default LatestTransaction;
