import React, { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import { format } from "date-fns";
import {
  differenceInDays,
  addDays,
  addMonths,
  getMonth,
  getYear,
} from "date-fns";

const Chart = (props) => {
  const { reportItems, reportPeriod, startDate, endDate } = props;
  console.log("reportItems", reportItems);
  
  const [signup, setSignup] = useState([]);
  const [cancellation, setCancellation] = useState([]);
  const [reactivation, setReactivation] = useState([]);
  const [labels, setLabels] = useState([]);

  useEffect(() => {
    const start = new Date(startDate);
    const end = new Date(endDate);

    const daysCount = differenceInDays(end, start) + 1;

    let tempList = [];

    if (daysCount <= 31) {
      for (let i = 0; i < daysCount; i++) {
        const currentDay = format(addDays(start, i), "yyyy-MM-dd");
        tempList.push({
          label: currentDay,
          signups: 0,
          deActive: 0,
          reActive: 0,
        });
      }
    } else if (daysCount > 31) {
      let currentMonth = getMonth(start);
      let currentYear = getYear(start);

      let tempStart = start;

      while (tempStart <= end) {
        const label = `${currentYear}-${currentMonth + 1}`;
        tempList.push({
          label: label,
          value: 0,
          signups: 0,
          deActive: 0,
          reActive: 0,
        });
        tempStart = addMonths(tempStart, 1);
        currentMonth = getMonth(tempStart);
        currentYear = getYear(tempStart);
      }
    }

    if (reportItems && reportItems?.subscriptionSummary) {
      const updatedList = tempList.map((item) => {
        const matchingData = reportItems?.subscriptionSummary?.find(
          (data) => data.group_day === item.label
        );
        if (matchingData) {
          return {
            ...item,
            signups: matchingData.totalSignups,
            deActive: matchingData.totalDeactivation,
            reActive: matchingData.totalReactivation,
          };
        }
        return item;
      });

      if (daysCount <= 31) {
        const newLabels = updatedList.map((item) => {
          return format(new Date(item.label), "d MMM");
        });

        setLabels(newLabels);
      } else if (daysCount > 31) {
        const newLabels = updatedList.map((item) => {
          return format(new Date(item.label), " MMM");
        });

        setLabels(newLabels);
      }
      const newSignups = updatedList?.map((item) => item.signups);
      const newDeActive = updatedList?.map((item) => item.totalDeactivation);
      const newReActive = updatedList?.map((item) => item.totalReactivation);

      setSignup(newSignups);
      setCancellation(newDeActive);
      setReactivation(newReActive);
    }
  }, [startDate, endDate, reportItems]);

  const [state, setState] = useState([]);
  const [option, setOption] = useState({});
  useEffect(() => {
    setState([
      {
        name: "Signups",
        data: signup,
      },
      {
        name: "Cancellations",
        data: cancellation,
      },
      {
        name: "Reactivations",
        data: reactivation,
      },
    ]);
  }, [signup, cancellation, reactivation]);
  useEffect(() => {
    setOption({
      chart: {
        type: "area",
        stacked: false,
        height: 350,
        zoom: {
          type: "x",
          enabled: true,
          autoScaleYaxis: true,
        },
        toolbar: {
          show: false,
          autoSelected: "zoom",
        },
      },
      colors: ["#90dfbe", "#f9b9b9", "#8FDDE7"],
      dataLabels: {
        enabled: false,
      },
      markers: {
        size: 0,
      },
      title: {
        //text: titleText,
        align: "left",
      },

      fill: {
        type: "gradient",
        gradient: {
          shadeIntensity: 1,
          inverseColors: false,
          opacityFrom: 1.5,
          opacityTo: 1,
          stops: [0, 90, 100],
        },
      },

      xaxis: {
        type: "datetime",
        categories: labels || [],
        labels: {
          show: true,
          rotate: 0,
          trim: false,
          minHeight: undefined,
          maxHeight: 120,
          style: {
            fontSize: "12px",
          },
        },
      },
      legend: {
        show: false,
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return val;
          },
        },
      },
    });
  }, [labels]);

  return (
    <>
      <ReactApexChart
        options={option}
        series={state}
        type="area"
        height={240}
      />
    </>
  );
};

export default Chart;
