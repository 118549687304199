import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import "../../../assets/css/frontend.css";
import {
  selectPartyByCategory,
  selectPartyType,
  selectPartyByType,
  selectpartyById,
  fetchparty,
  resetParty,
  selectAllparty,
} from "../store/getPartySlice";
import { useNavigate } from "react-router";
import DeleteModel from "../../shared/components/DeleteModel";
import DataGridHeader from "../../shared/components/DataGridHeader";
import PartyImport from "./PartyImport";
import { classNames } from 'primereact/utils';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { IconField } from 'primereact/iconfield';
import { InputIcon } from 'primereact/inputicon';
import { ButtonGroup, Dropdown, InputGroup } from "react-bootstrap";
import { InputNumber } from 'primereact/inputnumber';
//import { Button } from 'primereact/button';
import { ProgressBar } from 'primereact/progressbar';
import { Calendar } from 'primereact/calendar';
import { MultiSelect } from 'primereact/multiselect';
import { Slider } from 'primereact/slider';
import { Tag } from 'primereact/tag';
import { downloadCsvFile, handleExportClick } from "../../shared/export";
import { format } from "date-fns";
import Layout from "../../shared/components/Layout";
import PartyHeader from "../components/PartyHeader";
import { deleteParty } from "../store/getPartySlice";
import { resetCreateParty, resetStatus } from "../store/PartyEntitySlice";
import { exportPreProcessor } from "../helpers/partyHelper";
import PartyCategory from "../components/category/PartyCategory";
import PartyType from "../components/category/PartyType";
import { Container, Form, Stack, Image, Button } from "react-bootstrap";
import Header from "../../shared/components/Header";
import { i18n } from "../../shared/helpers/sharedMethods";
import CreateParty from "./CreateParty";
import EditParty from "./EditParty";
import DetailsModal from "../../shared/components/DetailsModal";
import ProfileInfo from "../components/party_view/ProfileInfo";
import Transactions from "../components/party_view/tabs/Transaction";
import Ledger from "../components/party_view/tabs/Ledger";
import ItemwiseReport from "../components/party_view/tabs/ItemwiseReport";
import TwoStageLayoutHeader from "../../shared/components/TwoStatgeLayoutHeader";
import PartyHighlights from "../components/party_view/PartyHighlights";
import { useHotkeys } from "react-hotkeys-hook";
import { UilUsersAlt } from "@iconscout/react-unicons";
import { UilEdit, UilTrashAlt, UilExport, UilImport, UilAngleDown } from "@iconscout/react-unicons";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { resetCategoryStatus } from "../../settings/store/categoryEntitySlice";
import { resetPartyAddress } from "../store/partyAddressSlice";
import ShortcutKeys from "../../shared/components/ShortcutKeys";
import PartyPaymentModal from "../components/payment/PartyPaymentModal";
import { getPaymentNumber, resetPayment } from "../../payment/store/paymentEntitySlice";
import { resetPartyPayment } from "../../payment/store/partyPaymentsSlice";
import { selectCategoryType, fetchCategories } from "../../settings/store/categorysSlice";
import CustomeSortIcon from "../../shared/components/CustomeSortIcon";
import { getLocalizedDate } from "../../shared/helpers/localizationHelper";
import AddressesData from "../components/party_view/tabs/Addresses"
import LoadingSpinner from '../../shared/components/LoadingSpinner';
import { UilPauseCircle, UilExclamationTriangle, UilTimesCircle, UilCheckCircle } from '@iconscout/react-unicons'
import SyncStatus from "../../shared/helpers/syncStatus";
import SyncStatusFilter from "../../catalog/helper/syncStatusHelper";
import { fetchSetting } from "../../settings/store/getSettingSlice";

const PartyIndex = (props) => {
  const { type, path, title, featureName } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();


  // const partyType = useSelector((state) => state.getParty.partyType);
  // const partyByType = useSelector((state) => selectPartyType(state, partyType));
  const partyByCategory = useSelector((state) => state.getParty.partyByCategory);
  const category = useSelector((state) => selectPartyByCategory(state, partyByCategory));
  const categoryPartyStatus = useSelector((state) => state.categoryEntity.status);
  const createPartyStatus = useSelector((state) => state.partyEntity.createStatus);
  const editPartystatus = useSelector((state) => state.partyEntity.editStatus);
  const loading = useSelector((state) => state.getParty.loading);
  const allPartys = useSelector((state) => selectPartyByType(state, type));
  const status = useSelector((state) => state.getParty.status);
  const [balanceFrozen, setBalanceFrozen] = useState(false);
  const [globalFilter, setGlobalFilter] = useState('');
  const [globalFilterValue, setGlobalFilterValue] = useState('');
  const [categoryOptions, setCategoryOptions] = useState([]);
  console.log("Category Options:", categoryOptions);

  const [selectedCategories, setSelectedCategories] = useState([]);
  const [show, setShow] = useState(false);
  const [stopCategory, setStopCategory] = useState(false);
  const [deleteId, setDeleteId] = useState();
  const [deleteName, setDeleteName] = useState();
  const [exportData, setExportData] = useState([]);
  const [csvData, setCsvData] = useState("");
  const [parties, setParties] = useState(allPartys);
  const [showPartyCreate, setShowPartyCreate] = useState(false);
  const [showEditParty, setShowEditParty] = useState(false);
  const [partyId, setPartyId] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [id, setId] = useState();
  const [showPayment, setShowPayment] = useState(false);
  const [PaymentType, setPaymentType] = useState("");
  const [PaymentId, setPaymentId] = useState();
  const [paymentName, setPaymentName] = useState();
  const [filters, setFilters] = useState(null);
  const [sortField, setSortField] = useState("created_at");
  const [sortOrder, setSortOrder] = useState(-1);
  const selectedParty = useSelector((state) => selectpartyById(state, id));
  const { accessToken } = useSelector((state) => state?.auth);
  const selectedBusiness = useSelector((state) => state?.auth?.selectedBusiness);
  const { terminalId } = useSelector((state) => state.initInstall);
  const tenantId = selectedBusiness?.pivot?.tenant_id;
  const handleClearFilters = () => {
    setSelectedCategories([]);
    setFilters({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      name: { value: null, matchMode: FilterMatchMode.CONTAINS },
      phone_number: { value: null, matchMode: FilterMatchMode.CONTAINS },
      category: { value: null, matchMode: 'in' },
      gstin: { value: null, matchMode: FilterMatchMode.CONTAINS },
      pincode: { value: null, matchMode: FilterMatchMode.EQUALS },
      created_at: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },

    });
    setGlobalFilter('');
    setGlobalFilterValue('');
    setParties(allPartys);
  };


  useEffect(() => {
    dispatch(fetchparty({ token: accessToken.access_token, tenant_id: selectedBusiness?.pivot?.tenant_id }));
  }, [type, accessToken, selectedBusiness])
  const globalPlanFeatures = useSelector(
    (state) => state?.globalPlan?.features
  );


  useEffect(() => {
    dispatch(
      fetchSetting({
        accessToken: accessToken,
        tenant_id: selectedBusiness?.pivot?.tenant_id,
      })
    );
  }, [status]);

  useEffect(() => {
    dispatch(fetchparty())
  }, [type])
  useEffect(() => {
    if (!globalPlanFeatures.includes(featureName)) {
      navigate("/setting/not-in-plan");
    }
  }, [])



  useEffect(() => {
    if (categoryPartyStatus) {
      toast.success(i18n("Category created successfully"));
      dispatch(resetCategoryStatus);
    }
  }, [categoryPartyStatus]);
  useEffect(() => {
    if (category) {
      setStopCategory(true);
      setParties(category);
    }
  }, [category]);

  // useEffect(() => {
  //   if (partyByType) {
  //     setParties(partyByType);
  //   }
  // }, [partyByType]);

  useEffect(() => {
    if (allPartys.length > 0) {
      setParties(allPartys);
    }
  }, [allPartys]);

  const handleRefreshParties = () => {
    dispatch(fetchparty({ token: accessToken.access_token, tenant_id: selectedBusiness?.pivot?.tenant_id }));
    dispatch(resetParty());
  };
  useEffect(() => {
    if (!type === "supplier" || !type === "customer") {
      setParties([]);
    } else {
      setParties(allPartys);
    }
  }, [type, allPartys]);


  const handlePartyRowClick = (row) => {
    ;
    setId(row.id);
    setShowDetails(!showDetails);

  };

  const handleDelete = async (data) => {
    setShow(!show);
    setShowDetails(false);
    setDeleteId(data.id);
    setDeleteName(data.name);
  };
  const handleEdit = (data) => {
    dispatch(resetCreateParty());
    dispatch(resetPartyAddress())
    setShowEditParty(true);
    setShowDetails(false);
    setPartyId(data.id);
  };

  const onDelete = () => {
    console.log('delete 2 triggered');
    dispatch(deleteParty({
      data: { id: deleteId },
      token: accessToken.access_token,
      tenant_id: tenantId,
      terminal_id: terminalId
    }));
    setShow(false);
    navigate(`/${path}`);
  };

  const handleCreate = () => {
    dispatch(resetCreateParty());
    dispatch(resetPartyAddress());
    setShowPartyCreate(true);
  };

  const bulkAction = () => {
    navigate(`/${path}/import`);
  };
  const handlePayIn = (data) => {
    setShowPayment(true);
    setPaymentType("payment_in");
    setPaymentId(data.id);
    setPaymentName(data.name);
    dispatch(getPaymentNumber({ data: "payment_out", token: accessToken, tenant_id: tenantId, terminal_id: terminalId }));
    dispatch(resetPayment());
    dispatch(resetPartyPayment());

  }
  const handlePayOut = (data) => {
    setShowPayment(true);
    setPaymentType("payment_out");
    setPaymentId(data.id);
    setPaymentName(data.name);
    dispatch(getPaymentNumber({ data: "payment_out", token: accessToken, tenant_id: tenantId, terminal_id: terminalId }));
    dispatch(resetPayment());
    dispatch(resetPartyPayment());
  };

  const dateTimeString = new Date()
    .toLocaleString()
    .replace(/[-]/g, " ")
    .replace(/:/g, "-");
  const fileName = `party_${dateTimeString}`;

  const handleExportParty = () => {
    const exportData = exportPreProcessor(parties);
    setExportData(exportData);
    handleExportClick(
      exportData,
      (exportData) => {
        setCsvData(exportData);
      },
      downloadCsvFile,
      fileName
    );
  };

  useHotkeys("alt+y", (event) => {
    event.preventDefault();
    handleCreate();
  });

  const productTabs = [
    {
      key: "profile",
      title: i18n("Details"),
      component: <ProfileInfo selectedParty={selectedParty} />,
    },
    {
      key: "Addresses",
      title: i18n("Addresses"),
      component: (
        <div className="mt-5"
          style={{
            padding: '10px',
            borderRadius: '5px',
            maxHeight: '455px',
            wordWrap: 'break-word',
            overflowY: 'auto',
            overflowX: 'auto',
            whiteSpace: 'normal',
          }}
        >
          <AddressesData selectedParty={selectedParty} />
        </div>),
    },
    {
      key: "transaction",
      title: i18n("Transactions"),
      component: <Transactions partyId={id} />,
    },
    {
      key: "ledger",
      title: i18n("Ledger(Statement)"),
      component: <Ledger partyId={id} />,
    },
    {
      key: "itemwise-report",
      title: i18n("Itemwise Report"),
      component: <ItemwiseReport partyId={id} />,
    },
  ];

  const partySummary = (
    <>
      <PartyHighlights selectedParty={selectedParty} currentItemId={id} />
    </>
  );

  const actionBodyTemplate = (row) => {
    return (
      <div className="d-flex justify-content-evenly align-items-center">
        <UilEdit className="mx-2 mb-1" size="15" style={{ cursor: row.name !== "Walk-in" ? "pointer" : "not-allowed" }} onClick={(e) => { e.stopPropagation(); handleEdit(row); }}
        />
        <UilTrashAlt className="mx-2 mb-1" size="15" style={{ cursor: row.name !== "Walk-in" ? "pointer" : "not-allowed" }} onClick={(e) => { e.stopPropagation(); handleDelete(row); }} />
      </div>
    );
  };

  const handleReturnToGrid = () => {
    setShowDetails(false);
  };

  const header = (
    <TwoStageLayoutHeader
      ItemActions={
        <Stack gap={3} direction="horizontal" className="mt-2 mb-2 float-end">
          <Button
            className=""
            variant="outline-primary"

            onClick={() => handlePayIn(selectedParty)}
            disabled={selectedParty?.name == "Walk-in"}
          >
            <UilImport className="mx-2 mb-1" size="16" />
            {i18n("Pay In")}
          </Button>
          <Button
            className=""
            variant="outline-info"
            onClick={() => handlePayOut(selectedParty)}
            disabled={selectedParty?.name == "Walk-in"}
          >
            <UilExport className="mx-2 mb-1" size="16" />
            {i18n("Pay Out")}
          </Button>
          <InputGroup className="me-3 custom-input">
            <Button
              variant="outline-secondary"
              onClick={() => handleEdit(selectedParty)}
              size="sm"
              style={{ padding: '0.5rem', background: 'none' }}
              disabled={selectedParty?.name === "Walk-in"}
            >
              <UilEdit className="mx-2 mb-1" size="16" />
              {i18n("Edit")}
            </Button>

            <Dropdown as={ButtonGroup}>
              <Dropdown.Toggle
                variant="outline-secondary"
                id="dropdown-custom"
                size="sm"
                style={{ height: '36px' }}
              >
                <UilAngleDown size="16" />
              </Dropdown.Toggle>

              <Dropdown.Menu className="custom-dropdown-menu">
                <Dropdown.Item
                  className="invoice-dropdown-item"
                  onClick={() => handleDelete(selectedParty)}
                  style={{ color: 'red' }}
                  disabled={selectedParty?.name === "Walk-in"}
                >
                  <UilTrashAlt className="mx-2 mb-1" size="16" style={{ color: 'red' }} />
                  {i18n("Delete")}
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </InputGroup>
        </Stack>
      }
      currentTitle={
        <>
          {selectedParty?.name}
          {selectedParty?.type == "customer" ? (
            <Form.Label className="mx-2 badge bg-primary bg-gradient">
              {i18n("Customer")}
            </Form.Label>
          ) : (
            <Form.Label className="mx-2 badge bg-info bg-gradient">
              {i18n("Supplier")}
            </Form.Label>
          )}
        </>
      }
      returnToGrid={handleReturnToGrid}
    />
  );

  const allCategories = useSelector((state) => selectCategoryType(state, 'party'));

  useEffect(() => {
    if (allCategories) {
      setCategoryOptions(allCategories);
    }
  }, [allCategories]);
  useEffect(() => {
    dispatch(fetchCategories());
  }, [dispatch]);
  const handleCategoryChange = (e) => {
    setSelectedCategories(e.value);

    if (e.value.length === 0) {
      setParties(allPartys);
    } else {
      const filteredParties = allPartys.filter(party =>
        e.value.some(category => party.category?.name === category.name)
      );
      setParties(filteredParties);
    }
  };
  const representativesItemTemplate = (option) => {
    return (
      <div className="flex align-items-center gap-2">
        <span>{option.name}</span>
      </div>
    );
  };

  const representativeRowFilterTemplate = (options) => {
    return (
      <MultiSelect value={selectedCategories} options={categoryOptions} itemTemplate={representativesItemTemplate} onChange={handleCategoryChange} optionLabel="name" className="p-column-filter custom-multiselect" style={{ fontSize: '11px' }} />
    );
  };

  const headerStyle = {
    backgroundColor: 'white',
  };

  const headerCellStyle = {
    fontWeight: 'bold',
    fontSize: '11px',
  };

  const dateBodyTemplate = (rowData) => {
    console.log("row party date", rowData)
    return getLocalizedDate(rowData?.created_at);
  };

  const handleCreatedAtDateFilterChange = (value, filterCallback) => {
    filterCallback(value);

    if (value) {
      const filtered = allPartys.filter((item) => {
        const createdAtDate = new Date(item.created_at);
        const selectedDate = new Date(value);
        return createdAtDate.toDateString() === selectedDate.toDateString();
      });
      setParties(filtered);
    } else {
      setParties(allPartys);
    }
  };

  const dateFilterTemplate = (options) => {
    return (
      <Calendar value={options.value} onChange={(e) => handleCreatedAtDateFilterChange(e.value, options.filterCallback)} dateFormat="dd/mm/yy" mask="99/99/9999" />
    );
  };

  const customSort = (field) => {
    if (field === 'created_at') {
      setSortOrder(-1);
    } else {
      setSortField(field);
      setSortOrder(-1);
    }
  };

  const sortedData = [...parties].sort((a, b) => {
    const dateA = new Date(a.created_at);
    const dateB = new Date(b.created_at);

    return dateB - dateA;
  });

  return (
    <div>
      <ToastContainer />
      <Layout>
        {loading && <LoadingSpinner />}
        <Header
          currentTitle={i18n("Parties")}
          ItemActions={
            <Stack
              gap={3}
              direction="horizontal"
              className="my-2 justify-content-end"
            >
              <ShortcutKeys />
            </Stack>
          }
        />
        <Container fluid className="px-5">
          <div className="border" style={{ background: "white" }}>
            <PartyHeader />
            <div>

              <DataGridHeader
                handleRefreshParties={handleRefreshParties}
                statusFilter={null}
                clearFilter={handleClearFilters}
                data={parties}
                allData={allPartys}
                addFilteredData={setParties}
                handleCreate={handleCreate}
                handleExportClick={handleExportParty}
                datas={exportData}
                isAllData={"All Parties"}
                button={i18n("Create Party")}
                title="Parties"
                bulkAction={bulkAction}
                icons={<UilUsersAlt size="50" color="gray" />}
                ItemActions={
                  <Stack
                    gap={3}
                    direction="horizontal"
                    className=" justify-content-end "
                  >
                    {type == "All" ? (
                      <></>
                    ) : (
                      // <Button severity="primary" style={{ borderRadius: '5px' }} onClick={handleCreate}>{title}</Button>
                      <Button onClick={handleCreate}>{title}</Button>
                    )}
                  </Stack>
                }
              />

              <Container fluid className="px-4 mb-4">
                <div className="bordered-container">

                  <DataTable
                    value={sortedData}
                    // headerStyle={headerStyle}
                    // isAllData={"All Parties"}
                    button={i18n("Create Party")}
                    title="Parties"
                    // bulkAction={bulkAction}
                    paginator
                    globalFilter={globalFilter}
                    // globalFilterFields={['name', 'phone_number','category.name','gstin', 'pincode', 'created_at']}
                    filterDisplay="row"
                    rows={10}
                    dataKey="id"
                    filters={filters}
                    emptyMessage="No Records Found."
                    onFilter={(e) => setFilters(e.filters)}
                    className="p-datatable-gridlines full-width-table custom-datatable p-component"
                    onRowClick={(e) => handlePartyRowClick(e.data)}
                    checked={balanceFrozen}
                    scrollable
                    scrollHeight="400px"
                    sortMode="multiple"
                    removableSort
                    style={{ cursor: "pointer" }}
                  >
                    <Column
                      field="name"
                      header={() => (
                        <div
                          className={`custom-header-${sortField === 'name' ? (sortOrder === 1 ? 'up' : 'down') : ''}`}
                          onClick={() => customSort('name')}
                          style={{
                            cursor: 'pointer',
                            display: 'flex',
                            alignItems: 'center',
                            fontSize: '11px',
                            lineHeight: '11px',
                          }}
                        >
                          {i18n("Name")}
                          <CustomeSortIcon sortOrder={sortField === 'name' ? sortOrder : 0}
                            style={{ marginLeft: '4px', verticalAlign: 'middle' }} />
                        </div>
                      )}
                      sortable
                      filter
                      filterPlaceholder=""
                      headerStyle={headerCellStyle}
                      showFilterMatchModes={false}
                      showClearButton={false}
                      showFilterOperator={false}
                      showAddButton={false}
                      filterMatchMode={FilterMatchMode.CONTAINS}
                      showApplyButton={false}
                      showFilterMenu={false}
                    />
                    <Column
                      field="phone_number"
                      header={() => (
                        <div
                          className={`custom-header-${sortField === 'phone_number' ? (sortOrder === 1 ? 'up' : 'down') : ''}`}
                          onClick={() => customSort('phone_number')}
                          style={{
                            cursor: 'pointer',
                            display: 'flex',
                            alignItems: 'center',
                            fontSize: '11px',
                            lineHeight: '11px',
                          }}
                        >
                          {i18n("Phone No")}
                          <CustomeSortIcon sortOrder={sortField === 'phone_number' ? sortOrder : 0}
                            style={{ marginLeft: '4px', verticalAlign: 'middle' }} />
                        </div>
                      )}
                      sortable
                      filter
                      filterPlaceholder=""
                      headerStyle={headerCellStyle}
                      showFilterMatchModes={false}
                      showClearButton={false}
                      showFilterOperator={false}
                      showAddButton={false}
                      filterMatchMode={FilterMatchMode.CONTAINS}
                      showApplyButton={false}
                      showFilterMenu={false}
                    />
                    <Column
                      field="category"
                      header={() => (
                        <div
                          className={`custom-header-${sortField === 'category' ? (sortOrder === 1 ? 'up' : 'down') : ''}`}
                          onClick={() => customSort('category')}
                          style={{
                            cursor: 'pointer',
                            display: 'flex',
                            alignItems: 'center',
                            fontSize: '11px',
                            lineHeight: '11px',
                          }}
                        >
                          {i18n("Category")}
                          <CustomeSortIcon sortOrder={sortField === 'category' ? sortOrder : 0}
                            style={{ marginLeft: '4px', verticalAlign: 'middle' }} />
                        </div>
                      )}
                      sortable
                      body={(rowData) => rowData.category ? rowData.category.name : "-"}
                      filter
                      filterElement={representativeRowFilterTemplate}
                      filterField={(rowData) => rowData.category ? rowData.category.name : "-"}
                      headerStyle={headerCellStyle}
                      showFilterMatchModes={false}
                      showClearButton={false}
                      showFilterOperator={false}
                      showAddButton={false}
                      filterMatchMode={FilterMatchMode.CONTAINS}
                      showApplyButton={false}
                      showFilterMenu={false}
                    />
                    <Column
                      field="gstin"
                      header={() => (
                        <div
                          className={`custom-header-${sortField === 'gstin' ? (sortOrder === 1 ? 'up' : 'down') : ''}`}
                          onClick={() => customSort('gstin')}
                          style={{
                            cursor: 'pointer',
                            display: 'flex',
                            alignItems: 'center',
                            fontSize: '11px',
                            lineHeight: '11px',
                          }}
                        >
                          {i18n("GSTIN")}
                          <CustomeSortIcon sortOrder={sortField === 'gstin' ? sortOrder : 0}
                            style={{ marginLeft: '4px', verticalAlign: 'middle' }} />
                        </div>
                      )}
                      sortable
                      body={(rowData) => rowData.gstin ? rowData.gstin : "-"}
                      filter
                      filterPlaceholder=""
                      headerStyle={headerCellStyle}
                      showFilterMatchModes={false}
                      showClearButton={false}
                      showFilterOperator={false}
                      showAddButton={false}
                      filterMatchMode={FilterMatchMode.CONTAINS}
                      showApplyButton={false}
                      showFilterMenu={false}
                    />
                    <Column
                      field="pincode"
                      header={() => (
                        <div
                          className={`custom-header-${sortField === 'pincode' ? (sortOrder === 1 ? 'up' : 'down') : ''}`}
                          onClick={() => customSort('pincode')}
                          style={{
                            cursor: 'pointer',
                            display: 'flex',
                            alignItems: 'center',
                            fontSize: '11px',
                            lineHeight: '11px',
                          }}
                        >
                          {i18n("Pin Code")}
                          <CustomeSortIcon sortOrder={sortField === 'pincode' ? sortOrder : 0}
                            style={{ marginLeft: '4px', verticalAlign: 'middle' }} />
                        </div>
                      )}
                      sortable
                      body={(rowData) => rowData.pincode ? rowData.pincode : "-"}
                      filter
                      filterPlaceholder=""
                      headerStyle={headerCellStyle}
                      showFilterMatchModes={false}
                      showClearButton={false}
                      showFilterOperator={false}
                      showAddButton={false}
                      filterMatchMode={FilterMatchMode.CONTAINS}
                      showApplyButton={false}
                      showFilterMenu={false}
                    />
                    <Column
                      field="created_at"
                      header={() => (
                        <div
                          className={`custom-header-${sortField === 'created_at' ? 'up' : ''}`}
                          onClick={() => customSort('created_at')}
                          style={{
                            cursor: 'pointer',
                            display: 'flex',
                            alignItems: 'center',
                            fontSize: '11px',
                            lineHeight: '11px',
                          }}
                        >
                          {i18n("Customer Since")}
                          <CustomeSortIcon sortOrder={sortField === 'created_at' ? sortOrder : 0}
                            style={{ marginLeft: '4px', verticalAlign: 'middle' }} />
                        </div>
                      )}
                      sortable
                      body={dateBodyTemplate}
                      filter
                      filterElement={dateFilterTemplate}
                      filterPlaceholder=""
                      headerStyle={headerCellStyle}
                      showFilterMatchModes={false}
                      showClearButton={false}
                      showFilterOperator={false}
                      showAddButton={false}
                      filterMatchMode={FilterMatchMode.DATE_IS}
                      // showApplyButton={false}
                      showFilterMenu={false}
                    />
                    <Column field="sync_status"
                      header={() => (
                        <div
                          className={`custom-header-${sortField === 'sync_status' ? (sortOrder === 1 ? 'up' : 'down') : ''}`}
                          onClick={() => customSort('sync_status')}
                          style={{
                            cursor: 'pointer',
                            display: 'flex',
                            alignItems: 'center',
                            fontSize: '11px',
                            lineHeight: '11px',
                          }}

                        >
                          {i18n("Sync Status")}
                          <CustomeSortIcon sortOrder={sortField === 'sync_status' ? sortOrder : 0}
                            style={{ marginLeft: '4px', verticalAlign: 'middle' }} />
                        </div>
                      )}
                      sortable
                      body={(rowData) => {
                        console.log("rowdata", rowData)
                        const { sync_status, syncstamp } = rowData;

                        return (
                          <>
                            <SyncStatus status={sync_status} syncstamp={syncstamp} />
                          </>
                        );
                      }}
                      headerStyle={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        textAlign: 'center',
                        width: 'auto',
                      }}
                      bodyStyle={{ width: '9.7%' }} showFilterMatchModes={false}
                      showClearButton={false}
                      showFilterOperator={false}
                      showAddButton={false}
                      filterMatchMode={FilterMatchMode.CONTAINS}
                      showApplyButton={false}
                      showFilterMenu={false}
                    />
                  </DataTable>
                </div>
              </Container>
              {show && (
                <DeleteModel
                  deleteUserClick={onDelete}
                  onClickDeleteModel={setShow}
                  name={deleteName}
                />
              )}
            </div>
          </div>
          {showPartyCreate && (
            <CreateParty
              show={showPartyCreate}
              setShowPartyCreate={setShowPartyCreate}
              type={type}
            />
          )}
          {showEditParty && (
            <EditParty
              show={showEditParty}
              partyId={partyId}
              setShowEditParty={setShowEditParty}
              type={type}
            />
          )}
          {showDetails && (
            <DetailsModal
              show={showDetails}
              type={type}
              title="Party Details"
              tabs={productTabs}
              summary={partySummary}
              currentItemId={partyId}
              ItemTitle="Parties"
              header={header}
              setShow={setShowDetails}
            />
          )}
          {showPayment && (
            <PartyPaymentModal
              show={showPayment}
              setShow={setShowPayment}
              type={PaymentType}
              title={PaymentType == "payment_in" ? "Payment In" : "Payment Out"}
              name={paymentName}
              partyId={PaymentId}
            />
          )}
        </Container>
      </Layout>
    </div>
  );
};

export default PartyIndex;