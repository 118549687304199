import {
    createSlice,
    createAsyncThunk,
  } from "@reduxjs/toolkit";
import { axiosRequest } from "../../shared/helpers/axiosHelper";

const initialState = {
  status:false,
  reportItems:[],
  error:false,
  errorMessage:''
};

export const fetchHSNReport = createAsyncThunk(
    "HSNSalesReport/fetch",
    async ({ token, tenant_id, startDate, endDate }) => {
      try{
      const HSNReportResponse = await axiosRequest({
        method: "GET",
        url: `get-hsn-sales-report`,
        params: { startDate, endDate },
        headers: { Authorization: `Bearer ${token}`, "X-Tenant": tenant_id },
      });
      return HSNReportResponse.data;
    }catch(err){
      await window.log.logToFile({message: "Error while fetching GSTR1 sales report", level: "error" });
      throw new Error("Error while fetching gstr-1 sales report")
    }
    }
  );

const HSNSalesReportSlice = createSlice({
    name: "HSNSalesReport",
    initialState,
    reducers: {},
    extraReducers(builder) {
      builder
      .addCase(fetchHSNReport.fulfilled, (state, action) => {
        state.status = true;
        state.reportItems = action.payload;
      })
      .addCase(fetchHSNReport.rejected, (state, action) => {
          state.error = true; 
          state.errorMessage = action.error.message
          state.status = false;
      })
    },
});

export default HSNSalesReportSlice.reducer;
