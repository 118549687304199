import React, { useEffect } from "react";
import { Stack } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { fetchPartyProfileInfo, getLastOrder } from "../../store/PartyProfileInfoSlice";
import { fetchPartySalesTotal } from "../../store/PartySalesTotalSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faArrowDown,
    faArrowUp,
    faMoneyBill,
    faRetweet,
    faGaugeHigh,
} from "@fortawesome/free-solid-svg-icons";
import { selectpartyById } from "../../store/getPartySlice";
import { i18n } from "../../../shared/helpers/sharedMethods";
import GlobalCurrency from "../../../shared/components/GlobalCurrency";

const PartyHighlights = ({ currentItemId }) => {
    const dispatch = useDispatch();
    const accessToken = useSelector((state) => state?.auth?.accessToken?.access_token);
    const selectedBusiness = useSelector((state) => state?.auth?.selectedBusiness);
    const tenantId = selectedBusiness?.pivot?.tenant_id;
    const getSummary = useSelector((state) => state.partyProfileInfo.reportItems);
    const lastOrderDays = useSelector((state) => state.partyProfileInfo.lastOrderDays);
    const selectedParty = useSelector((state) => selectpartyById(state, currentItemId));
    const { totalSalesCount, overallTotal } = useSelector((state) => state.partySalesTotal.reportItems);
    const avgOrderValue = (overallTotal && totalSalesCount) ? (parseFloat(parseFloat(overallTotal) / parseFloat(totalSalesCount)).toFixed(2)) : 0;


    useEffect(() => {
        dispatch(fetchPartyProfileInfo({id:currentItemId,token:accessToken,tenant_id:tenantId}));
        dispatch(fetchPartySalesTotal({id:currentItemId,token:accessToken,tenant_id:tenantId}));
        dispatch(getLastOrder(currentItemId));
    }, [currentItemId]);

    return (
        <Stack direction="horizontal" gap={2} className="ms-auto border rounded p-4 px-2">
            <Stack gap={2} className="text-center" style={{ borderRight: "1px solid lightgray" }}>
                {/* To Collect */}
                <span className="text-black  fw-normal px-3" style={{ fontSize: "15px" }}>
                    <GlobalCurrency price={getSummary?.to_collect} />
                </span>
                <span className="text-collect h4"> <FontAwesomeIcon icon={faArrowDown} className="px-1" />{i18n("To Collect")}</span>
            </Stack>
            <Stack gap={2} className="text-center" style={{ borderRight: "1px solid lightgray" }}>
                {/* To pay */}
                <span
                    className="text-black  fw-normal px-3"
                    style={{ fontSize: "15px" }}>
                    <GlobalCurrency price={getSummary?.to_pay} />
                </span>
                <span className="text-pay h4"> <FontAwesomeIcon icon={faArrowUp} className="px-1" /> {i18n("To Pay")}</span>
            </Stack>
            <Stack gap={2} className="text-center" style={{ borderRight: "1px solid lightgray" }}>
                {/* Total Sales */}
                <span className="text-black  fw-normal px-3" style={{ fontSize: "15px" }}>
                    <GlobalCurrency price={overallTotal} />
                </span>
                <span className="text-primary h4"> <FontAwesomeIcon icon={faMoneyBill} className="px-1" /> {i18n("Lifetime Value")}</span>
            </Stack>

            <Stack gap={2} className="text-center" style={{ borderRight: "1px solid lightgray" }}>
                {/* Avg. order value */}
                <span className="text-black  fw-normal px-3" style={{ fontSize: "15px" }}>
                    <GlobalCurrency price={avgOrderValue} />
                </span>
                <span className="text-info h4"> <FontAwesomeIcon icon={faGaugeHigh} className="px-1" /> {i18n("Avg. Order Value")}</span>
            </Stack>
            <Stack gap={2} className="text-center">
                {/* Order Frequency */}
                <span className="text-black  fw-normal px-3" style={{ fontSize: "15px" }}>
                    {lastOrderDays}
                </span>
                <span className="text-primary h4"> <FontAwesomeIcon icon={faRetweet} className="px-1" /> {'Last Order'}</span>
            </Stack>

        </Stack>
    )
}

export default PartyHighlights;