import {
    createSlice,
    createAsyncThunk,
  } from "@reduxjs/toolkit";
  import { i18n } from "../../shared/helpers/sharedMethods";
  import { axiosRequest } from "../../shared/helpers/axiosHelper";

  const initialState = {
    status:false,
    reportItems:[],
    error:false,
    errorMessage:{},
    loading: false,
  };

export const fetchNewCustomer = createAsyncThunk('newCustomer/fetch', async (param) => {
  try{
    console.log('prod',param);
    const token = param.token;
    const tenantId = param.tenant_id;
    const startDate = param.startDate;
    const endDate = param.endDate;
    const response  = await axiosRequest({
      method: "GET",
      url: `get-new-customers`,
      params: {
        start_date: startDate,
        end_date: endDate,
      },
      headers: {
        Authorization: `Bearer ${token}`,
        "X-Tenant": tenantId,
      },
    });
    return response?.data;
  }catch(err){
    await window.log.logToFile({message: "Error while fetching new customer", level: "error" });
    throw new Error("Error while fetching New Customer")
  }
});

const NewCustomerSlice = createSlice({
    name: "newCustomer",
    initialState,
    reducers: {},
    extraReducers(builder) {
      builder
      .addCase(fetchNewCustomer.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchNewCustomer.fulfilled, (state, action) => {
        state.status = true
        state.reportItems =action.payload
        state.loading = false;
      })
      .addCase(fetchNewCustomer.rejected, (state, action) => {
        state.error = true; 
        state.loading = false;
        state.errorMessage=`${i18n("Error while fetching New Customer. Please try again later.")}`;
        state.status = false;
      })

    },
});

export default NewCustomerSlice.reducer;

