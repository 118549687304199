import { Stack } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faArrowDown,
    faArrowUp,
    faClockRotateLeft,
    faDolly,
    faGaugeHigh,
    faRetweet
} from "@fortawesome/free-solid-svg-icons";
import { i18n } from "../../../shared/helpers/sharedMethods";

const VoucherHighlightsBlock = ({ reportItems }) => {
    const currencySymbol  = useSelector((state) => state.getSetting?.dataSetting.currency);
    ;
    const { totalAmount, totalCount, paidAmount, unpaidAmount, returnAmount, returnCount } = reportItems;
    const avgOrderValue = (totalAmount && totalCount) ? (parseFloat(parseFloat(totalAmount) / parseFloat(totalCount)).toFixed(2)) : 0;


    return (
        <Stack direction="horizontal" gap={2} className="ms-auto border rounded p-3">
            <Stack gap={2} className="text-center" style={{ borderRight: "1px solid lightgray" }}>
                {/* All */}
                <span
                    className="text-black  fw-normal px-3"
                    style={{ fontSize: "16px" }}>

                    {currencySymbol} {totalAmount
                        ? parseFloat(totalAmount).toFixed(2)
                        : 0}
                    ({totalCount ? totalCount : 0})
                </span>
                <span className="text-primary h4"> <FontAwesomeIcon icon={faDolly} className="px-1" /> {i18n("All")}</span>
            </Stack>
            <Stack gap={2} className="text-center" style={{ borderRight: "1px solid lightgray" }}>
                {/* Paid */}
                <span
                    className="text-black  fw-normal px-3"
                    style={{ fontSize: "16px" }}>

                    {currencySymbol} {paidAmount
                        ? parseFloat(paidAmount).toFixed(2)
                        : 0}
                </span>
                <span className="text-collect h4"> <FontAwesomeIcon icon={faArrowDown} className="px-1" /> {i18n("Paid")}</span>
            </Stack>


            <Stack gap={2} className="text-center" style={{ borderRight: "1px solid lightgray" }}>
                {/* Unpaid */}
                <span
                    className="text-black  fw-normal px-3"
                    style={{ fontSize: "16px" }}>
                    {currencySymbol} {unpaidAmount
                        ? parseFloat(unpaidAmount).toFixed(2)
                        : 0}
                </span>
                <span className="h4" style={{ color : "darkorange" }}> <FontAwesomeIcon icon={faArrowUp} className="px-1" />{i18n("Unpaid")}</span>
            </Stack>

            <Stack gap={2} className="text-center" style={{ borderRight: "1px solid lightgray" }}>
                {/* Return */}
                <span
                    className="text-black  fw-normal px-3"
                    style={{ fontSize: "16px" }}>

                    {currencySymbol} {returnAmount
                        ? parseFloat(returnAmount).toFixed(2)
                        : 0}
                    ({returnCount ? returnCount : 0})
                </span>
                <span className="text-pay h4"> <FontAwesomeIcon icon={faClockRotateLeft} className="px-1" /> {i18n("Returned")}</span>
            </Stack>
            <Stack gap={2} className="text-center">
                {/* Order Frequency */}
                <span
                    className="text-black  fw-normal px-3"
                    style={{ fontSize: "16px" }}>

                    {currencySymbol} {avgOrderValue}
                </span>
                <span className="text-info h4"> <FontAwesomeIcon icon={faGaugeHigh} className="px-1" /> {i18n("Avg. Order Value")}</span>
            </Stack>
        </Stack>
    )
}

export default VoucherHighlightsBlock;