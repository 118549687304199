import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { axiosRequest } from "../../shared/helpers/axiosHelper"
import { i18n } from "../../shared/helpers/sharedMethods";

const initialState = {
  status: false,
  fetchStatus: false,
  deleteStatus:false,
  error: '',
  usersList: [],
  loading:false,
  fetchTrainerStatus: false,
  trainersList: []
}

export const createInviteUser = createAsyncThunk('inviteUser/createInviteUser', async (userData) => {
  try {
    const authResponse = await axiosRequest({
      method: 'POST',
      url: 'tenants/invite-user',
      headers: {
        'Content-Type': 'application/json',
        "X-Tenant": userData.tenant_id,
      },
      data: userData,
    });
    return authResponse;
  } catch (error) {
    await window.log.logToFile({ message: "Error while updating company details", level: "error" });
    throw error;
  }
});

export const updateInviteUser = createAsyncThunk('inviteUser/updateInviteUser', async (userData) => {
  try {

    const authResponse = await axiosRequest({
      method: 'PUT',
      url: 'tenant-users/' + userData?.id,
      headers: {
        'Content-Type': 'application/json',
        "X-Tenant": userData.tenant_id,
        'Authorization': 'Bearer ' + userData.token,
      },
      data: {
        role_id: userData?.role_id
      },
    });
    return authResponse;
  } catch (error) {
    await window.log.logToFile({ message: "Error while updating Invite user", level: "error" });
    throw error;
  }
});

export const deleteUser = createAsyncThunk('inviteUser/delete', async (userData) => {
  try {

    const authResponse = await axiosRequest({
      method: 'DELETE',
      url: 'tenant-users/' + userData?.id,
      headers: {
        'Content-Type': 'application/json',
        "X-Tenant": userData.tenant_id,
        'Authorization': 'Bearer ' + userData.token,
      }
    });
    return authResponse;
  } catch (error) {
    await window.log.logToFile({ message: "Error while deleting Invite user", level: "error" });
    throw error;
  }
});

export const fetchInviteUser = createAsyncThunk('inviteUser/fetchInviteUser', async (data) => {
  try {
    const authResponse = await axiosRequest({
      method: 'GET',
      url: `tenant-users/all`,
      headers: {
        'X-tenant': data.tenant_id,
        'Authorization': 'Bearer ' + data.token,
        'currentUserId': data.currentUserId
      },
    });
    
    return authResponse;
  } catch (err) {
    await window.log.logToFile({message: "Error while updating company details", level: "error" });
    throw err;
  }
});

export const fetchInviteTrainer = createAsyncThunk('inviteUser/fetchInviteTrainer', async (data) => {
  try {
    const authResponse = await axiosRequest({
      method: 'GET',
      url: `tenant-users/all-trainers-details`,
      headers: {
        'X-tenant': data.tenant_id,
        'Authorization': 'Bearer ' + data.token,
        'currentUserId': data.currentUserId
      },
    });
    
    return authResponse;
  } catch (err) {
    await window.log.logToFile({message: "Error while updating company details", level: "error" });
    throw err;
  }
});

const inviteUserSlice = createSlice({
  name: 'inviteUser',
  initialState,
  reducers: {
    logout: () => initialState,
    resetStatusAndError(state, action) {
      state.status = false;
      state.error = '';
      state.deleteStatus = false;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(createInviteUser.fulfilled, (state, action) => {
        state.status = true;
        state.data = action.payload;
      })
      .addCase(createInviteUser.rejected, (state, action) => {
        state.status = false;
        state.error = (action.error.message);
      })
      .addCase(fetchInviteUser.pending, (state) => {
        state.loading = true;  
      })
      .addCase(fetchInviteUser.fulfilled, (state, action) => {
        state.fetchStatus = true;
        state.loading = false; 
        state.usersList = action.payload;
      })
      .addCase(fetchInviteUser.rejected, (state, action) => {
        state.fetchStatus = false;
        state.loading = false; 
        state.error = (i18n("Error while fetching users. Please try again later."));
      })
      .addCase(updateInviteUser.fulfilled, (state, action) => {
        state.status = true

      })
      .addCase(updateInviteUser.rejected, (state, action) => {
        state.status = false;
        state.error = (i18n("Error while updating user. Please try again later."));
      })
      .addCase(deleteUser.fulfilled,(state,action)=>{
        state.deleteStatus = true
      })
      .addCase(deleteUser.rejected, (state, action) => {
        state.deleteStatus = false;
        state.error = (i18n("Error while delete user. Please try again later."));
      })
      .addCase(fetchInviteTrainer.fulfilled, (state, action) => {
        state.fetchTrainerStatus = true;
        state.trainersList = action.payload;
      })
      .addCase(fetchInviteTrainer.rejected, (state, action) => {
        state.fetchTrainerStatus = false;
        state.error = (i18n("Error while fetching users. Please try again later."));
      })
  }

});

export default inviteUserSlice.reducer;

export const { logout, resetStatusAndError } = inviteUserSlice.actions;