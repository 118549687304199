import { Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { updateItemFieldSubscription } from "../../../../store/subscriptionProductEntitySlice";
import { formatAmount } from "../../../../../pos/helpers/cartHelper";


const SubscriptionDiscount = ({ index,item, discountEnabled }) => {
  const dispatch = useDispatch();
  let subtotal = item?.subtotal;
  let [ itemDiscount, setItemDiscount ] = useState(item?.item_discount);
  let [ itemDiscountAmount, setItemDiscountAmount ] = useState(item?.item_discount_amount);
 
  const handleDiscountChange = (event) => {
      let discount = event?.target?.value;
      if(discount >= 100){
          return false;
      }
      setItemDiscount(discount);
      setItemDiscountAmount(subtotal * (discount/ 100))
  }

  const handleDiscountAmountChange = (event) => {
      let discountAmount = event?.target?.value;

      if(discountAmount >= subtotal){
          return false;
      }
      setItemDiscountAmount(discountAmount);
      setItemDiscount((discountAmount / subtotal) * 100);
  }

  useEffect(() => {
      if(!discountEnabled){
          setItemDiscount(0);
          setItemDiscountAmount(0);
      }
  },[discountEnabled])

  useEffect(() => {
      let discountData = {
          fieldName: "item_discount",
          fieldValue: itemDiscount,
          index,
          discountEnabled
      }
      dispatch(updateItemFieldSubscription(discountData));

      let amountData = {
          fieldName: "item_discount_amount",
          fieldValue: itemDiscountAmount,
          index,
          discountEnabled
      }
      dispatch(updateItemFieldSubscription(amountData));

  },[itemDiscount, itemDiscountAmount])

  return (
      <>
          <td>
              <Form.Control 
                  type="number"
                  name="item_discount"
                  className="text-end"
                  placeholder="%"
                  value={item?.item_discount ? item?.item_discount : ''}
                  onChange={handleDiscountChange}
              />
          </td>
          <td>
              <Form.Control 
                  type="number"
                  name="item_discount_amount"
                  className="text-end"
                  placeholder="0.00"
                  value={item?.item_discount_amount ? formatAmount(parseFloat(item?.item_discount_amount)) : ''}
                  onChange={handleDiscountAmountChange}
              />
          </td>
      </>

  );
};

export default SubscriptionDiscount;
