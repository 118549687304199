import React from "react";
import { Row, Col, Card, Stack, Button } from "react-bootstrap";
import ItemTabs from "./sections/ItemTabs";


const GSTDetailLayout = ({
  itemStack,
  type,
  tabs,
  ItemTitle,
  ItemActions,
  returnToGrid,
  print,
  pdf,
  summary,
  header,
}) => {
  //;

  return (
    <Row className="px-3"  >
    
      <Col className="border " lg={12} md={9} xs={8}  style={{ background : "white", height:"90vh", overflow: "auto",  overflowX: "hidden", }}>
        {header}
        {summary}
        <ItemTabs print={print} pdf={pdf} tabs={tabs} type={type} title={"Invoice History"}/>
      </Col>
    </Row>
  );
};

export default GSTDetailLayout;
