import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { axiosRequest } from "../../shared/helpers/axiosHelper";
import { i18n } from "../../shared/helpers/sharedMethods";

const initialState = {
  data: false,
  status: false,
  reactive: [],
  error: {},
};

export const subscriptionReactive = createAsyncThunk(
  'subscriptionReactive/put',
  async ({ id, token, tenantId }) => {
    try {
      const response = await axiosRequest({
        method: "PUT",
        url: `subscriptions/subscription-activation/${id}`,
        headers: {
          Authorization: `Bearer ${token}`,
          "X-Tenant": tenantId,
        },
      });
      ;
      return response;
    } catch (error) {
      await window.log.logToFile({ message: "Error while updating subscription reactivation", level: "error" });
      throw new Error('Error submitting subscription');
    }
  }
);

const subscriptionReactiveSlice = createSlice({
  name: "subscriptionReactive",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(subscriptionReactive.fulfilled, (state, action) => {
        state.reactive = action.payload;
        state.status = true;
      })
      .addCase(subscriptionReactive.rejected, (state, action) => {
        state.status = false;
        state.error = (i18n("Item Creation failed. Please try again later."));
      })
  },
});

export default subscriptionReactiveSlice.reducer;
