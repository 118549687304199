import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { format } from "date-fns";
import { fetchPartyTransaction } from "../../../store/PartyTransactionSlice";
import { Badge, Card, Form, Row, Table } from "react-bootstrap";
import "../../../css/party.css";
import { i18n } from "../../../../shared/helpers/sharedMethods";
import { formatAmount } from "../../../../pos/helpers/cartHelper";
import GlobalCurrency from "../../../../shared/components/GlobalCurrency";

const Transactions = (props) => {
  const { partyId } = props;
  const dispatch = useDispatch();
  const accessToken = useSelector((state) => state?.auth?.accessToken?.access_token);
  const selectedBusiness = useSelector((state) => state?.auth?.selectedBusiness);
  const tenantId = selectedBusiness?.pivot?.tenant_id;
  const getTransaction = useSelector((state) => state.partyTransaction.reportItems);

  useEffect(() => {
    dispatch(fetchPartyTransaction({id:partyId,token:accessToken,tenant_id:tenantId}));
  }, [partyId]);

  return (
    <Row className="px-3 mt-5 scrollable-table">
      <Card.Body className="">
        <Table bordered hover responsive>
          <thead>
            <tr style={{color:'#495057'}}>
              <th className="px-2" style={{ backgroundColor: '#F9FAFB' }}>{i18n("Date")}</th>
              <th className="px-2" style={{ backgroundColor: '#F9FAFB' }}>{i18n("Txn")}</th>
              <th className="px-2" style={{ backgroundColor: '#F9FAFB' }}>{i18n("Txn No")}</th>
              <th className="px-2" style={{ backgroundColor: '#F9FAFB' }}>{i18n("Amount")}</th>
              <th className="px-2" style={{ backgroundColor: '#F9FAFB' }}>{i18n("Status")}</th>
            </tr>
          </thead>
          <tbody>
            {getTransaction &&
              getTransaction.length > 0 &&
              getTransaction.map((data, index) => ( 
                <tr key={index}>
                  <td className="p-1 px-2 m-1 mx-3">
                    {data.createdAt
                      ? format(new Date(data.createdAt), "dd-MM-yyyy")
                      : " "}
                  </td>
                  <td className="p-1 px-2 m-1 mx-3">
                    {data?.paymentType
                      ? data?.paymentType
                        .replace(/_/g, " ")
                        .replace(/\b\w/g, (match) => match.toUpperCase())
                      : data?.voucherType
                        ? data?.voucherType
                          .replace(/_/g, " ")
                          .replace(/\b\w/g, (match) => match.toUpperCase())
                        : ""}
                  </td>
                  <td className="p-1 px-2 m-1 mx-3">
                    {data.paymentId ? data.paymentId : data.voucherId}
                  </td>
                  {data.paid_amount == 0 || data.paid_amount === data.total ? (
                    <td className="p-1 px-2 m-1 mx-3">

                      <GlobalCurrency price={data?.total - data?.paid_amount} />
                    </td>
                  ) : (
                    <td className="p-1 px-2 m-1 mx-3">
                      <span style={{ fontSize: "12px" }}>
                        <GlobalCurrency price={data?.total - data?.paid_amount} />
                      </span>
                    </td>
                  )}
                  <td className="p-1 px-2 m-1 mx-3">
                    {data.paymentType === "payment_out" ||
                      data.paymentType === "payment_in" ? (
                      ""
                    ) : data.paid_amount === data.total ? (
                      <Badge className="mx-4 px-4" bg="success">{i18n("Paid")}</Badge>
                    ) : data.paid_amount ? (
                      <Badge className="mx-4" bg="warning"> {i18n("Partially Paid")}</Badge>
                    ) : (
                      <Badge className="mx-4" bg="danger"> {i18n("Unpaid")}</Badge>
                    )}
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
      </Card.Body>
    </Row>
  );
};

export default Transactions;
