import React, { useState, useEffect } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Chart from "./Chart";
import SummaryReportPeriod from "../../../../shared/components/SummaryReportPeriod";
import { fetchSalesTaxChart } from "../../../store/sales_chart/salesTaxChartSlice";

function SalesTaxChart() {
  const dispatch = useDispatch();
 
  const [reportPeriod, setReportPeriod] = useState("current-week");

  useEffect(() => {
    dispatch(fetchSalesTaxChart(reportPeriod));
  }, [reportPeriod]);
  const reportItems = useSelector((state) => state.salesTaxChart.reportItems);

const [dayFrom, setDayFrom] = useState("");
const [dayTo, setDayTo] = useState("");
const currencySymbol  = useSelector((state) => state.getSetting?.dataSetting.currency);
;
  return (
    <Card id="chart" className="border mb-3">
      <Card.Header className="border-0 pt-0"></Card.Header>
      <Card.Body className="py-0">
        <Row className="row ">
        <Col xl={9} md={9} >
            <Chart 
             reportItems= {reportItems} 
             reportPeriod={reportPeriod} 
             setDayFrom={setDayFrom}
             setDayTo={setDayTo}
            />
          </Col>
          <Col xl={3} md={3}  className="d-flex justify-content-end">
            <Col >

            <SummaryReportPeriod
            reportPeriod={reportPeriod}
            setReportPeriod={setReportPeriod}
          />
           </Col>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
}

export default SalesTaxChart;
