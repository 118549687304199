import React, { useState, createRef, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch ,useSelector} from "react-redux";
import { updateAddress } from "../store/voucherEntitySlice";
import { Row, Col, Form, InputGroup } from "react-bootstrap";
import { i18n, placeholderText } from "../../shared/helpers/sharedMethods";

const AddressModel = (props) => {
  const { handleClose, show } = props;
  const dispatch = useDispatch();
  const addressValue = useSelector((state) => state.voucherEntity.address);
  const partyValue = useSelector((state) => state.voucherEntity.party);
  ;
  const clearField = () => {
    handleClose();
  };
  const [addressData, setAddressData] = useState(
    addressValue
      ? addressValue
      : {
          phone_number: partyValue?.phone_number
            ? partyValue?.phone_number
            : "",
          address: "",
          city: "",
          type: "shipping",
        }
  );

  useEffect(() => {
    if (addressValue) {
      setAddressData(addressValue);
    }
  }, [addressValue]);

  ;

  useEffect(() => {
    if (partyValue) {
      setAddressData((inputs) => ({
        ...inputs,
        ["phone_number"]: partyValue.phone_number,
      }));
    }
  }, [partyValue]);

  ;

  const onChangeInput = (e) => {
    e.preventDefault();
    const { value } = e.target;

    ;
    ;

    setAddressData((inputs) => ({
      ...inputs,
      [e.target.name]: value && value,
    }));
  };
  const onSubmit = (event) => {
    ;
    dispatch(updateAddress(addressData));
    handleClose(addressData);
  };

  return (
    <Modal
      show={show}
      dialogClassName="modal-dialog modal-dialog-centered mw-700px"
      onHide={handleClose}
      keyboard={true}
    >
      <Modal.Header closeButton>
        <Modal.Title>{i18n("Change Shipping Address")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {/* <Row>
          <Col md={12} className="mb-2">
            <Form.Label>Phone Number:</Form.Label>
            <InputGroup>
              <Form.Control
                type="text"
                name="phone_number"
                placeholder="Enter the Phone Number"
                pattern="[0-9]*"
                min={0}
                maxLength="10"
                onChange={(e) => onChangeInput(e)}
                value={
                  partyValue.phone_number
                    ? partyValue.phone_number
                    : addressData.phone_number
                }
              />
              <Form.Control.Feedback type="invalid">
                Required
              </Form.Control.Feedback>
            </InputGroup>
          </Col>
        </Row> */}
        <Row>
          <Col md={12} className="mb-2">
            <Form.Label>{i18n("Address")}:</Form.Label>
            <InputGroup>
              <Form.Control
                type="text"
                name="address"
                autoComplete="off"
                placeholder={i18n("Enter your street name")}
                onChange={(e) => onChangeInput(e)}
                value={addressData.address}
              />
              <Form.Control.Feedback type="invalid">
                {i18n("Required")}
              </Form.Control.Feedback>
            </InputGroup>
          </Col>
        </Row>
        <Row>
          <Col md={12} className="mb-2">
            <Form.Label>{i18n("City")}:</Form.Label>
            <InputGroup>
              <Form.Control
                type="text"
                name="city"
                autoComplete="off"
                placeholder={placeholderText("Enter your city")}
                onChange={(e) => onChangeInput(e)}
                value={addressData.city}
              />
            </InputGroup>
          </Col>
        </Row>
      </Modal.Body>

      <div
        className="d-flex justify-content-end px-5 mb-5"
        style={{ columnGap: 10 }}
      >
        <button
          onClick={() => onSubmit()}
          className="btn btn-primary fw-bold"
          style={{ color: "white" }}
        >
          {i18n("Submit")}
        </button>
        <button
          className="btn btn-secondary fw-bold"
          onClick={clearField}
          style={{ color: "white" }}
        >
          {i18n("Cancel")}
        </button>
      </div>
    </Modal>
  );
};
export default AddressModel;
