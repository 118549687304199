import React, { useEffect, useState } from "react";
import { Card, Col, Row, Button, Stack } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import {
  fetchSubscriptionAttendanceByMember,
  selectAllSubscriptionAttendances,
  resetSubscriptionAttendance,
  fetchSubscriptionAttendance,
} from "../../../store/SubscriptionAttendanceSlice";
import { selectAllSubscriptionData } from "../../../store/SubscriptionSlice";
import AttendanceQrCode from "../../subscription_order/tabs/AttendanceQrCode";
import SubscriptionFilter from "../../Filters/SubscriptionFilter";
import MemberTrainerInfo from "../party_profile_info/MemberTrainerInfo";
import SubsAttendanceDetails from "./SubsAttendanceDetails";
import { da } from "date-fns/locale";
import { selectMemberDataById } from "../../../store/MemberSlice";

const MemberAttendance = ({ data, memberId }) => {
  console.log("data", data);
  const { id } = useParams();
  const dispatch = useDispatch();
  const accessToken = useSelector((state) => state?.auth?.accessToken?.access_token);
  const selectedBusiness = useSelector((state) => state?.auth?.selectedBusiness);
  const tenantId = selectedBusiness?.pivot?.tenant_id;
  const selectedMember = useSelector((state) => selectMemberDataById(state, memberId));


  const [subscriptionData, setSubscriptionData] = useState("");
  const subscriptions = useSelector(selectAllSubscriptionData);
  const subscriptionMap = {};
  subscriptions.forEach(sub => {
    subscriptionMap[sub.id] = sub;
  });

  useEffect(() => {
    dispatch(resetSubscriptionAttendance());

    if (subscriptionData === "") {
      // Fetch attendance data for all members
      dispatch(fetchSubscriptionAttendanceByMember({
        id: memberId,
        token: accessToken,
        tenantId,
      }));
    } else {
      // Fetch attendance data based on selected subscription ID
      dispatch(fetchSubscriptionAttendance({
        id: subscriptionData,
        token: accessToken,
        tenantId,
      }));
    }
  }, [dispatch, subscriptionData, accessToken, tenantId]);

  const attendanceData = useSelector(selectAllSubscriptionAttendances);


  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return new Intl.DateTimeFormat('en-GB', { day: '2-digit', month: 'short', year: '2-digit' }).format(date);
  };

  const formatTime = (timeString) => {
    const [hours, minutes] = timeString.split(':').map(Number);
    const date = new Date();
    date.setHours(hours);
    date.setMinutes(minutes);
    date.setSeconds(0);

    return new Intl.DateTimeFormat('en-GB', {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    }).format(date);
  };

  const groupedData = attendanceData.reduce((acc, record) => {
    const date = formatDate(record.attendance_date);
    if (!acc[date]) {
      acc[date] = [];
    }
    acc[date].push(record);
    return acc;
  }, {});

  const sortByCheckInTimeDesc = (a, b) => {
    return new Date(`1970-01-01T${b.time_in}Z`) - new Date(`1970-01-01T${a.time_in}Z`);
  };

  return (
    <Card className="p-2" style={{ maxHeight: '700px' }}>
      <Row className="mt-4 px-2 mb-8">
        <Col lg={6} className="px-0">
          <div className="d-flex justify-content-between">
            <Col lg={4}>
              <Stack className=" ">
                <SubscriptionFilter
                  subscriptionData={subscriptionData}
                  setSubscriptionData={setSubscriptionData}
                />
              </Stack>
            </Col>
          </div>

          <Col lg={12} className="mx-0 mt-5" style={{ maxHeight: '445px', overflowY: 'auto' }}>
            {Object.keys(groupedData).length > 0 ? (
              Object.keys(groupedData).map((date) => (
                <div key={date} className="px-3 mb-3" style={{ border: '1px solid #ddd', borderRadius: '4px', padding: '10px' }}>
                  <h3 className="mx-3">{date}</h3>
                  <hr />
                  <div style={{ position: 'relative' }}>
                    {groupedData[date]
                      .sort(sortByCheckInTimeDesc)
                      .map((record) => {
                        const subscription = subscriptionMap[record.subscription_id];
                        const isFirst = groupedData[date].indexOf(record) === 0;
                        const isLast = groupedData[date].indexOf(record) === groupedData[date].length - 1;
                        const color = isFirst ? 'red' : (isLast ? 'green' : '#DEDEDE');

                        return (
                          <div key={record.id} style={{ position: 'relative', marginBottom: '10px' }}>
                            <div
                              style={{
                                position: 'absolute',
                                left: '10px',
                                top: '50%',
                                width: '3px',
                                height: '80%',
                                backgroundColor: color,
                                borderRadius: '10%',
                                transform: 'translateY(-50%)'
                              }}
                            />
                            <table style={{ width: '100%', marginLeft: '11px' }}>
                              <thead>
                                <tr style={{ color: 'grey' }}>
                                  <th style={{ padding: '0 12px' }}>Subscription No</th>
                                  <th style={{ padding: '0 12px' }}>Check In</th>
                                  <th style={{ padding: '0 12px' }}>Check Out</th>
                                  <th style={{ padding: '0 12px' }}>Total</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td style={{ padding: '0 12px' }}>
                                    <strong>{subscription ? subscription.subscription_number : 'N/A'}</strong>
                                  </td>
                                  <td style={{ padding: '0 12px' }}>
                                    <strong>{formatTime(record.time_in)}</strong>
                                  </td>
                                  <td style={{ padding: '0 12px' }}>
                                    <strong>{record.time_out ? formatTime(record.time_out) : 'N/A'}</strong>
                                  </td>
                                  <td style={{ padding: '0 12px' }}>
                                    <strong>{record.hours ? `${record.hours} hr` : ' - '}</strong>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        );
                      })
                    }
                  </div>
                </div>
              ))
            ) : (
              <p>No attendance records available.</p>
            )}
          </Col>
        </Col>
        <Col lg={6} className="px-0">
          <div className="d-flex justify-content-between">
            <Col lg={4}>
              <Stack className=" ">
              </Stack>
            </Col>
          </div>

          <Col lg={10} className="mx-8 mt-16" style={{ maxHeight: '445px', overflowY: 'auto' }}>
            <SubsAttendanceDetails data={attendanceData} />
          </Col>
        </Col>
      </Row>
    </Card>
  );
};

export default MemberAttendance;
