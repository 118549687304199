import React from "react";
import { Container, Row, Col } from 'react-bootstrap';
import { i18n } from "../../../../shared/helpers/sharedMethods";
import GlobalCurrency from "../../../../shared/components/GlobalCurrency";
import { placeOfSupplyList } from "../../../../shared/config/placeOfSupply";
import { find } from "lodash";
import { format } from "date-fns";

const MemberTrainerInfo = ({ selectedMember }) => {
 
console.log("trainer_info",selectedMember);
  return (

    <>
      <div className="d-flex p-1 px-4 ">
        <Col lg={5} style={{ fontWeight: 600 }}>
          {i18n("Trainer Info")}
        </Col>
      </div>
      <div className=" mt-1 p-1 px-2 mx-2" style={{ borderBottom: '1px solid rgba(10, 10, 10, 0.1)', fontSize: '13px', color: '#76797C' }}>
        <Row>
          <Col lg={6}>
            {i18n("Trainer Name")}
          </Col>
          <Col lg={6} style={{ color: 'black' }}>
            {selectedMember?.trainers?.name ? selectedMember?.trainers?.name : '-'}
          </Col>
        </Row>
      </div>
      <div className=" mt-1 p-1 px-2 mx-2" style={{ borderBottom: '1px solid rgba(10, 10, 10, 0.1)', fontSize: '13px', color: '#76797C' }}>
        <Row>
          <Col lg={6}>
            {i18n("Mobile Number")}
          </Col>
          <Col lg={6} style={{ color: 'black' }}>
            {selectedMember?.trainers?.phone ? selectedMember?.trainers?.phone : '-'}
          </Col>
        </Row>
      </div>
      <div className='mx-2' style={{ borderBottom: '1px solid rgba(10, 10, 10, 0.1)', fontSize: '13px', color: '#76797C' }}>
        <Row className="mt-1 p-1 px-2">
          <Col lg={6}>
            {i18n("Trainer Since")}
          </Col>
          <Col lg={6} style={{ color: 'black' }}>
            {selectedMember?.trainers?.created_at ? 
              format(new Date(selectedMember.trainers.created_at), 'dd/MM/yyyy') : 
              '-'}
          </Col>
        </Row>
      </div>
    </>

  )
}

export default MemberTrainerInfo;