import React from 'react';
import { Row, Stack, Button, Container, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faChevronLeft
} from '@fortawesome/free-solid-svg-icons';
import { i18n } from '../../../../shared/helpers/sharedMethods';

const BillTabs = (props) => {

    return (
        <Container>
            <Row className='ml-0 bottom-splitter' style={{ backgroundColor: 'white', borderTop: '0.5px solid #ddd' }}>
                
                <Stack direction="horizontal">
                <Link to='/'>
                        <Button variant="custom p-2 m-1 ms-auto" size='sm'>   <FontAwesomeIcon icon={faChevronLeft} /> &nbsp; <span className='shortcut-color'>{i18n("[Esc]")}</span></Button>
                    </Link>

                    <Button variant="bill-tab-active p-3" size='sm'> {i18n("Current Bill")} <span className='shortcut-color'>{i18n("[Ctrl + 1]")}</span></Button>

                    <Button variant="bill-tab-holded p-3" size='sm' onClick={() => alert('BillTab')}>
                        {i18n("Bill #1")} <span className='shortcut-color'>{i18n("[Ctrl + 1]")}</span>
                    </Button>

                    <Button variant="bill-tab-new p-3" size='sm' onClick={() => alert('Bill Holded')}>+ <text>{i18n("Hold Bill & Create New")}</text> <span className='shortcut-color'>{i18n("[Ctrl + N]")}</span></Button>
                   
                </Stack>
              
               
               
            </Row>
        </Container>

    )
}


export default BillTabs;