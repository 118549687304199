import React from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMoneyBillTransfer,
} from "@fortawesome/free-solid-svg-icons";
import { Card } from "react-bootstrap";
import { i18n } from "../../shared/helpers/sharedMethods";

const TransactionReportList = () => {
  const navigate = useNavigate();

  const handleBillwise = () => {
    navigate("/reports/billWise");
  };

  const handleDayBook = () => {
    navigate("/reports/dayBook");
  };

  const handlePurchaseSummary = () => {
    navigate("/reports/purchaseSummary");
  };

  const handleProfitAndLoss = () => {
    navigate("/reports/profitAndLoss");
  };
  return (
    <>
      <span
              className="section-headings p-2 px-4 mt-1 d-flex flex-direction-row"
              style={{ borderBottom: "1px solid lightgray" }}
            >
              <FontAwesomeIcon
                className="px-1 fs-4"
                icon={faMoneyBillTransfer}
              />{" "}
              <h4>{i18n("Transactions")}</h4>
            </span>
            <Card.Body className="py-5">
              <h6
                className="pt-5 fs-4"
                style={{ cursor: "pointer" }}
                onClick={handleBillwise}
              >
              {i18n("Bill Wise Profit")}
              </h6>
              
              <h6
                className="pt-5 fs-4"
                onClick={handleDayBook}
                style={{ cursor: "pointer" }}
              >
              {i18n("Daybook")}
              </h6>
  
              <h6
                className="pt-5 fs-4"
                onClick={handleProfitAndLoss}
                style={{ cursor: "pointer" }}
              >
              {i18n("Profit And Loss Report")}
              </h6>
              <h6
                className="pt-5 fs-4"
                onClick={handlePurchaseSummary}
                style={{ cursor: "pointer" }}
              >
              {i18n("Purchase Summary")}
              </h6>
            </Card.Body>
    </>
  )
}

export default TransactionReportList;