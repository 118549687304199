import React, { useEffect, useState } from "react";
import "../../payment/assets/css/payment.css";
import { useSelector, useDispatch } from "react-redux";
// import { createPaymentParty,getPaymentNumber, undoPaymentError } from "../store/paymentEntitySlice";
import { createPayment,getPaymentNumber, sumOfAmount,createPaymentParty, undoPaymentError } from "../store/paymentEntitySlice";
import { useNavigate } from "react-router";
import {
  resetPayment,
} from "../store/paymentEntitySlice";
import Layout from "../../shared/components/Layout";
import { ToastContainer, Stack, Button, Alert } from "react-bootstrap";
import { Container, Row, Col, Form, Spinner } from "react-bootstrap";
import PaymentDetails from "../components/PaymentDetails";
import PaymentTypes from "../components/PaymentTypes";
import PaymentAmount from "../components/PaymentAmount";
import PaymentStack from "../components/PaymentStack";
import { i18n, placeholderText } from "../../shared/helpers/sharedMethods";
import {
  resetPartyPayment,
  resetStatusError,
  selectPartByType,
} from "../store/partyPaymentsSlice";
import { addPaymentPreproceesor } from "../helpers/paymentHelper";
import Header from "../../shared/components/Header";
import { useHotkeys } from "react-hotkeys-hook";
import { changeToggleShow } from "../../shared/store/sharedSlice";
import AlertMessage from "../../shared/components/AlertMessage";
import { getUtcDate } from "../../shared/helpers/localizationHelper";
import ShortcutKeys from "../../shared/components/ShortcutKeys";
import BalanceAmount from "../components/PaymentBalance";

const CreatePayment = (props) => {
  const { type, path, title } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [errors, setErrors] = useState({
    payment_error: false,
    party_error: false,
  });
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);

  
  
  const { party, status,totalAmount } = useSelector((state) => state.paymentEntity);
  const { initialPayment, selectedPayment, processPayment, statusError } =
    useSelector((state) => state.partyPayments);
   const {errorStatus,error}=useSelector((state)=>state.paymentEntity);
  const partyDetails = useSelector((state) => state.paymentEntity);
  
  const partyPayments = useSelector((state) => state.partyPayments);


  const partyPaymentData = useSelector((state)=> selectPartByType(state,type));

 



  
  
  const selectedBusiness = useSelector(
    (state) => state?.auth?.selectedBusiness
  );
  const accessToken = useSelector(
    (state) => state?.auth?.accessToken?.access_token
  );
  const tenantId = selectedBusiness?.pivot?.tenant_id;
  const { terminalId } = useSelector((state) => state.initInstall);
  
  const handleClose = () => {
    dispatch(resetStatusError());
  };
  const handleAlertClose = () => {
    dispatch(undoPaymentError())
    };
  useEffect(() => {
    if (status) {
      navigate(`${path}`);
      dispatch(resetPayment());
      dispatch(resetPartyPayment());
    }
  }, [status]);

  useEffect(() => {
    dispatch(getPaymentNumber({data:type, token: accessToken, tenant_id: tenantId, terminal_id: terminalId}));
  },[])


  
  const handleValidation = () => {
    let valid = true;

    if (!party.id) {
      valid = false;
      setErrors((pre) => ({
        ...pre,
        ["party_error"]: true,
      }));
    } else if (!processPayment > 0) {
      valid = false;
      setErrors((pre) => ({
        ...pre,
        ["payment_error"]: true,
      }));
    }

    return valid;
  };
  const onSubmit = async (event) => {
    const valid = handleValidation();

    try {
      if (valid) {
        setLoading(true);
        const addPayment = addPaymentPreproceesor({
          partyDetails,
          partyPayments,
          type,
          totalAmount
        });
        addPayment.created_by = selectedBusiness?.pivot?.user_id;
        addPayment.updated_by = selectedBusiness?.pivot?.user_id;
        addPayment.created_at = getUtcDate();
        addPayment.updated_at = getUtcDate();
        addPayment.party_id = party?.id;
        ;
        console.log('payment',addPayment);
        dispatch(createPaymentParty({data:addPayment,token: accessToken,
          tenant_id: tenantId, terminal_id:terminalId }));
      }
    } catch (error) {
      setLoading(false);
    }
  };
  const handleCancel = () => {
    dispatch(changeToggleShow(false));
    navigate(path);
  };
  useHotkeys("alt+enter", (event) => {
    event.preventDefault();
    onSubmit();
  });

  useHotkeys("esc", (event) => {
    event.preventDefault();
    handleCancel();
  });


  return (
    <>
      <ToastContainer />
      <Layout currentTitle={title}>
        <Header
          currentTitle={title}
          ItemActions={
            <Stack
              gap={3}
              direction="horizontal"
              className="mt-2 mb-2 justify-content-end"
            >
               <ShortcutKeys />
               {!loading ? (
                <Button
                  id="submitButton"
                  variant="primary mx-2"
                  onClick={onSubmit}
                  disabled={loading}
                >
                  {i18n("Save")}
                </Button>
              ) : (
                <div className="d-flex align-items-center mx-8">
                  <Spinner animation="border" size="md" />
                </div>
              )}
              <Button
                variant="outline-secondary mx-2"
                onClick={handleCancel}
              >
                {i18n("Cancel")}
              </Button>
            </Stack>
          }
        />

        <Container className="px-5" fluid>
          <div
            className="border"
            style={{
              background: "white",
              height: "95vh",
              overflow: "auto",
              overflowX: "hidden",
            }}
          >
             {errorStatus &&
            <AlertMessage 
            errorStatus={errorStatus} 
            error={error} 
            handleAlertClose={handleAlertClose} 
            /> }
            <Row className="px-4">
             
              <Col lg={3}>
                <PaymentDetails
                  error={error}
                  setError={setErrors}
                  edit={false}
                  type={type}
                />
              </Col>
              <Col lg={6}>
                <PaymentTypes error={errors} setError={setErrors} />
              </Col>
              <Col className="mt-3" lg={3}>
                <PaymentAmount error={errors} setError={setErrors} />
              </Col>
              <Col className="p-4">
                <BalanceAmount />  
              </Col>
              <Col lg={12}>
                 <PaymentStack
                  type={
                    type == "payment_in" ? "sales_invoice" : "purchase_invoice"
                  }
                />
              </Col>
            </Row>
          </div>
        </Container>
      </Layout>
    </>
  );
};
export default CreatePayment;
