import React from "react";
import { useSelector } from "react-redux";
import { Card, Stack } from "react-bootstrap";
import { i18n } from "../../../shared/helpers/sharedMethods";
import { UilUserPlus } from '@iconscout/react-unicons'

const NewCustomerBlock = () => {
  const reportItems = useSelector((state) => state.newCustomer?.reportItems);
  return (
    <Card
      className="shadow-sm border"
      style={{ backgroundColor: "", lineHeight: "5px",minHeight: "155px" }}
    >
      <Card.Body className="d-flex justify-content-center text-start flex-column p-md-5 p-3">
        <span className="mb-3 mb-md-5 ">
          
          <UilUserPlus style={{ color: "#61C7EB" }} />
        </span>
        <span
          className=" mb-8 mt-2 fs-responsive"
          style={{ color: 'GrayText', }}
        >
          {" "}
          {i18n("New Customer")}
        </span>
        <Stack direction="vertical" gap={3}>
          <span
            className="fs-responsive-datas fw-bolder mb-3 mb-md-4 "
          >
            {reportItems ? reportItems?.length : 0}
          </span>
        </Stack>
      </Card.Body>
    </Card>
  );
};

export default NewCustomerBlock;
