import DatePicker, { registerLocale } from "react-datepicker";
import { i18n,numValidate, placeholderText } from "../../shared/helpers/sharedMethods";
import {
  addDueDate,
  addVoucherDate,
  addVoucherNumber,
} from "../store/voucherEntitySlice";
import { useDispatch, useSelector } from "react-redux";
import {
  Col,
  InputGroup,
  Form,
  Card,
  Row,
  FormControl,
  Stack,
  FloatingLabel,
  Modal,
  Button,
} from "react-bootstrap";
// import { numValidate } from "../../shared/helpers/sharedMethods";
import { useEffect, useState } from "react";
import { format } from "date-fns";
import { generateRandomNumber } from "../helpers/VouchersHelper";
import { UilEdit } from "@iconscout/react-unicons";
const VoucherNoAndDates = (props) => {
  const { types } = props
  ;
  const dispatch = useDispatch();

  const [dateValue, setDateValue] = useState(0);
  const [isEditable, setIsEditable] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const invoiceNumber = useSelector(
    (state) => state.voucherEntity.voucher_number
  );
  const voucherDate = useSelector((state) => state.voucherEntity.voucher_date);
  const dueDateValue = useSelector((state) => state.voucherEntity.due_date);

  useEffect(() => {
    const number = generateRandomNumber();
    dispatch(addVoucherNumber(number));
  }, []);

  const handleDateChange = (event) => {
    const { value } = event.target;

    if (value === "") {
    } else {
      if (value != 0) {
        const newDate = new Date(voucherDate);
        newDate.setDate(newDate.getDate() + parseInt(value));
        if (newDate >= new Date(voucherDate)) {
          dispatch(addDueDate(newDate));
        }
      }
    }
    setDateValue(value);
  };
  const handleAsOfDateChange = (date) => {
    const currentDate = new Date(voucherDate);
    if (date >= currentDate) {
      dispatch(addDueDate(date));
    
    const timeDifferenceInMilliseconds = date - currentDate;
    const differenceInDays = timeDifferenceInMilliseconds / (1000 * 3600 * 24);
    ;
    if (differenceInDays > 0) {
      setDateValue(Math.round(differenceInDays));
    }
  }
  };

  const handleInvoiceDateChange = (date) => {
    // let formattedInvDate = format(date, 'dd/MM/yyyy');
    // 
    dispatch(addVoucherDate(date));
  };

  const handleEditConfirm = () => {
    setIsEditable(true);
    setShowConfirmationModal(false);
  };

  const handleSave = () => {
    dispatch(addVoucherNumber(invoiceNumber));
    setIsEditable(false);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      setIsEditable(false);
      handleSave();
    }
  };
  return (
    <>
    <Form className="py-3">
    <Row>
        <Form.Group as={Col} md={6}>
          <Stack direction="vertical" className="ms-auto">
            <Form.Label className="bold" style={{ color: "#4682B4" }}>
              {i18n("VOUCHER NO.")}
              <span
                onClick={() => setShowConfirmationModal(true)}
                style={{ cursor: "pointer", textDecoration: "underline", marginLeft: "5px", fontWeight:'normal' }}
              >
                {i18n("Edit")}
              </span>
            </Form.Label>
            <Form.Control
              type="text"
              name="invoice_number"
              placeholder="Enter stock"
              min={0}
              value={invoiceNumber}
              readOnly={!isEditable}
              onChange={(e) => {
                if (isEditable) {
                  dispatch(addVoucherNumber(e.target.value));
                }
              }}
              onKeyDown={handleKeyDown}
            />
       <Modal show={showConfirmationModal} onHide={() => setShowConfirmationModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{i18n("Confirm Voucher Number Edit")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        {i18n("This will affect document uniqueness, Are you sure want to edit?")}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowConfirmationModal(false)}>
            {i18n("No")}
          </Button>
          <Button variant="primary" onClick={handleEditConfirm}>
          {i18n("Yes")}
          </Button>
        </Modal.Footer>
      </Modal>
          </Stack>
        </Form.Group>

        <Form.Group as={Col} md={6}>
          <Stack direction="vertical" className="ms-auto">
            <Form.Label className="bold" style={{ color: "#4682B4" }}>
              {i18n("VOUCHER DATE")}
            </Form.Label>
            <DatePicker
              className="form-control"
              placeholderText={placeholderText("Select Date")}
              dateFormat="dd/MM/yyyy"
              onChange={handleInvoiceDateChange}
              selected={voucherDate}
            />
          </Stack>
        </Form.Group>
      </Row>
      
        {(types === 'sales_invoice' || types === 'quote' || types === 'proforma_invoice' || types === 'purchase_invoice' || types === 'purchase_order') ? (
          <Row className="mt-3">
        <Form.Group as={Col} md={6}>
          <Stack direction="vertical" className="ms-auto">
            <Form.Label className="bold" style={{ color: "#4682B4" }}>
              {i18n("DUE IN")}
            </Form.Label>
            <InputGroup>
              <Form.Control
                type="text"
                placeholder="0"
                onChange={handleDateChange}
                onKeyPress={(event) => numValidate(event)}
                min={0}
                value={dateValue}
                
              />
              <InputGroup.Text>{i18n("days")}</InputGroup.Text>
            </InputGroup>
          </Stack>
        </Form.Group>

        <Form.Group as={Col} md={6}>
          <Stack direction="vertical" className="ms-auto">
            <Form.Label className="bold" style={{ color: "#4682B4" }}>
              {i18n("DUE DATE")}
            </Form.Label>
            <DatePicker
              onChange={handleAsOfDateChange}
              className="form-control"
              placeholderText="Select Date"
              dateFormat="dd/MM/yyyy"
              selected={dueDateValue}
            />
          </Stack>
        </Form.Group>
        </Row>
        ) : (
          null
        )}
      </Form>
    </>
  );
};

export default VoucherNoAndDates;
