import { UilPauseCircle, UilExclamationTriangle, UilTimesCircle, UilCheckCircle } from '@iconscout/react-unicons'

const SyncStatus = ({ status, syncstamp }) => {
    let icon;
    let tooltipText;
    
    if (syncstamp !== null) {
        status = "synced";
    }

    if (status === 'queued') {
        icon = <UilPauseCircle size="20" color="blue" />;
        tooltipText = "Queued";
    } else if (status === "synced") {
        icon = <UilCheckCircle size="20" color="green" />;
        tooltipText = "Synced";
    } else if (status === 'errored') {
        icon = <UilTimesCircle size="20" color="red" />;
        tooltipText = "Errored";
    } else if (status === "validation_failed") {
        icon = <UilExclamationTriangle size="20" color="darkorange" />;
        tooltipText = "Validation failed";
    }

    return (
        <div className="d-flex align-items-center justify-content-center" title={tooltipText}>
            {icon}
        </div>
    )
}

export default SyncStatus;
