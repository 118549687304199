import {createSlice } from '@reduxjs/toolkit';


  const initialState = {
    charges:[]
  }

  const additionalChargesField ={
    voucher_id: null,
    charge_type: '',
    charge_value: 0,
    tax_code: null,
    tax_rate: null,
    cgst: null,
    sgst: null,
    igst: null,
    cess_rate: null,
    cess: null,
    tax_total: 0,
    net_total: 0
  }

  const additionalChargesSlice = createSlice({
    name: 'additionalCharges',
    initialState,
    reducers: {
        addAdditionalCharge: (state,action) => {
            state.charges.push(additionalChargesField);
          },
          cancelAdditionalCharge: (state, action) => {
            const indexToRemove = action.payload;
            if (indexToRemove >= 0 && indexToRemove < state.charges.length) {
              state.charges.splice(indexToRemove, 1);
            }
          },
          updateCharges: (state, action) => {
                   
                    const { i, name, value } = action.payload;
                    
                    if (name === 'charges_name') {
                        state.charges[i].charge_type = value;
                    } else {
                        state.charges[i].charge_value = value;
                    }
            }, 
        initEditCharges:(state,action) =>{
           state.charges  = action.payload
        },
        resetCharges:()=>initialState
       
    },
    extraReducers() {

    }
});

export default additionalChargesSlice.reducer;
export const { addAdditionalCharge,cancelAdditionalCharge,updateCharges,resetCharges,initEditCharges } = additionalChargesSlice.actions;