import { find, isNumber } from "lodash";
import { taxList } from "../../shared/config/taxConfig";

export const formatAmount = (amount) => {
  return amount && isFinite(amount) ? Math.round(amount * 100) / 100 : null;
};
export const prepareCartItem = (selectedProduct) => {
  ;
  return {
    id: selectedProduct.id,
    voucher_id: null,
    product_id: selectedProduct.id,
    warehouse_id: selectedProduct?.warehouse_id
      ? selectedProduct?.warehouse_id
      : 1,
    product_name: selectedProduct?.product_name
      ? selectedProduct?.product_name
      : selectedProduct?.name,
    product_code: selectedProduct?.product_code
      ? selectedProduct?.product_code
      : selectedProduct?.code,
    hsn: selectedProduct?.hsn,
    exp_date: selectedProduct?.exp_date,
    mfg_date: selectedProduct?.mfg_date,
    item_price:
      selectedProduct?.voucherType == "Sales"
        ? selectedProduct?.sale_price
        : selectedProduct?.cost_price,
    quantity: 1,
    item_discount: 0,
    item_discount_amount: 0,
    subtotal: selectedProduct?.sale_price,
    taxable_value: selectedProduct?.sale_price,
    tax_code: selectedProduct?.tax_code,
    is_tax_included: selectedProduct?.is_tax_included,
    tax_rate: null,
    cgst: null,
    sgst: null,
    igst: null,
    cess_rate: null,
    cess: null,
    tax_total: 0,
    net_tax: 0,
    net_total: selectedProduct?.sale_price,
    unit: selectedProduct?.unit,
    type: selectedProduct?.type,
  };
};

export const prepareCartShellItem = (index) => {
  return {
    id: index,
    voucher_id: null,
    product_id: null,
    warehouse_id: 1,
    product_name: "",
    product_code: "",
    hsn: "",
    exp_date: "null",
    mfg_date: "",
    item_price: 0,
    quantity: 1,
    item_discount: 0,
    item_discount_amount: 0,
    subtotal: 0,
    taxable_value: 0,
    tax_code: null,
    is_tax_included: false,
    tax_rate: null,
    cgst: null,
    sgst: null,
    igst: null,
    cess_rate: null,
    cess: null,
    tax_total: 0,
    net_tax: 0,
    net_total: 0,
    unit: "PCS",
    type: "goods",
  };
};

export const prepareCartShellSubscriptionItem = (args) => {
  ;
  return {
    id: args.index,
    product_id: "",
    product_name: "",
    product_code: "",
    hsn: "",
    exp_date: "",
    mfg_date: "",
    item_price: "",
    quantity: 1,
    item_discount: 0,
    item_discount_amount: 0,
    subtotal: 0,
    taxable_value: 0,
    tax_code: "GST_NONE",
    is_tax_included: false,
    tax_rate: null,
    cgst: null,
    sgst: null,
    igst: null,
    cess_rate: null,
    cess: null,
    tax_total: 0,
    net_tax: 0,
    net_total: 0,
    unit: "PCS",
    type: "goods",
    expires_after: "",
    trial_days: "",
    interval: "",
    interval_unit: "",
    setup_fee: "",
    sub_type: args?.type,
  };
};

export const prepareSubscriptionItem = (selectedProduct) => {
  ;
  return {
    id: selectedProduct.id,
    product_id: selectedProduct.id,
    warehouse_id: selectedProduct?.warehouse_id
      ? selectedProduct?.warehouse_id
      : 1,
    parent_product: selectedProduct?.parent_product
      ? selectedProduct?.parent_product
      : null,
    product_name: selectedProduct?.product_name
      ? selectedProduct?.product_name
      : selectedProduct?.name,
    product_code: selectedProduct?.product_code
      ? selectedProduct?.product_code
      : selectedProduct?.code,
    hsn: selectedProduct?.hsn,
    exp_date: selectedProduct?.exp_date,
    mfg_date: selectedProduct?.mfg_date,
    item_price: selectedProduct?.sale_price,
    quantity: 1,
    item_discount: selectedProduct?.item_discount
      ? selectedProduct?.item_discount
      : 0,
    item_discount_amount: selectedProduct?.item_discount_amount
      ? selectedProduct?.item_discount_amount
      : 0,
    subtotal: selectedProduct?.sale_price,
    taxable_value: selectedProduct?.sale_price,
    tax_code: selectedProduct?.tax_code,
    is_tax_included: selectedProduct?.is_tax_included,
    tax_rate: null,
    cgst: null,
    sgst: null,
    igst: null,
    cess_rate: selectedProduct?.cess_rate ? selectedProduct?.cess_rate : null,
    cess: selectedProduct?.cess ? selectedProduct?.cess : null,
    tax_total: 0,
    net_tax: 0,
    net_total: selectedProduct?.sale_price,
    unit: selectedProduct?.unit,
    type: selectedProduct?.type,
    expires_after: selectedProduct?.plan?.expires_after,
    trial_days: selectedProduct?.plan?.free_trial_days,
    interval: selectedProduct?.plan?.interval,
    interval_unit: selectedProduct?.plan?.interval_unit,
    setup_fee: selectedProduct?.plan?.setup_fee,
    addon: selectedProduct?.addon ? selectedProduct?.addon : "",
    plan: selectedProduct?.plan ? selectedProduct?.plan : "",
    setupFee: selectedProduct?.setup_fee ? selectedProduct?.setup_fee : "",
  };
};

export const calculateGstParts = (
  tax_code,
  net_taxable_amount,
  is_tax_included = false,
  is_same_state ,
  cess,
  cess_rate
) => {
  const selectedTax = find(taxList, { code: tax_code });
  // ;
  // ;
  // ;
  // ;
  ;
  ;

  let cgst = null;
  let sgst = null;
  let igst = null;
  let tax_total = 0;
  let tax_rate = selectedTax?.rate;
  let net_tax = 0;

  let taxable_value;
  ;
  ;
  if (tax_code != "GST_NONE" || tax_code != "GST_EXEMPT") {
    tax_total = net_taxable_amount * (tax_rate / 100);
  }
  ;
  if (is_same_state) {
    cgst = sgst = tax_total / 2;
  } else {
    igst = tax_total;
  }

  taxable_value = is_tax_included
    ? net_taxable_amount - Number(tax_total)
    : //?net_taxable_amount
    net_taxable_amount; //doubt

  ;

  return {
    cgst,
    sgst,
    igst,
    cess_rate,
    cess,
    tax_rate,
    tax_total,
    taxable_value,
    is_tax_included,
    net_tax,
  };
};

export const subscriptionProcessCartItem = (
  cartItem,
  discountEnabled = false,
  is_same_state = true
) => {
  ;

  let item_discount = cartItem.item_discount;
  let item_discount_amount = cartItem.item_discount_amount;
  let item_price = cartItem.item_price ? cartItem.item_price : cartItem.price;
  let quantity = cartItem.quantity;
  let subtotal = item_price * quantity;
  ;

  let net_taxable_amount = discountEnabled
    ? subtotal - item_discount_amount
    : subtotal;
  ;
  

  let {
    cgst,
    sgst,
    igst,
    cess_rate,
    cess,
    tax_rate,
    tax_total,
    is_tax_included,
    taxable_value,
    net_tax,
  } = calculateGstParts(
    cartItem?.tax_code,
    net_taxable_amount,
    cartItem?.is_tax_included,
    is_same_state,
    cartItem.cess,
    cartItem.cess_rate
  );


  let net_total = taxable_value + tax_total;
  net_total = cartItem.is_tax_included ? net_taxable_amount : net_total;
  net_total = cartItem.is_tax_included
    ? net_total
    : net_total + formatAmount(cess);

  let grand_total = net_total;
  ;
  grand_total = cartItem?.setup_fee
    ? grand_total + Number(cartItem?.setup_fee)
    : grand_total;

  const discountamount = 0;

  return {
    id: cartItem?.id,
    product_id: cartItem?.product_id,
    product_name: cartItem?.product?.name
      ? cartItem?.product?.name
      : cartItem?.product_name,
    code: cartItem?.product_code ? cartItem?.product_code : cartItem?.code,
    expires_after: cartItem?.expires_after,
    trial_days: cartItem?.trial_days,
    interval: cartItem?.interval,
    unit: cartItem?.unit,
    interval_unit: cartItem?.interval_unit,
    setup_fee: cartItem?.setup_fee,
    item_price,
    quantity,
    item_discount,
    item_discount_amount,
    subtotal,
    tax_code: cartItem?.tax_code,
    tax_rate,
    cgst: formatAmount(cgst),
    sgst: formatAmount(sgst),
    igst: formatAmount(igst),
    cess_rate,
    cess: formatAmount(cess),
    is_tax_included: cartItem?.is_tax_included,
    tax_total: tax_total ? formatAmount(tax_total) : 0,
    net_tax: formatAmount(net_tax),
    net_total: formatAmount(net_total),
    grand_total: formatAmount(grand_total),
    net_taxable_amount,
    discountamount,
    taxable_value,
    sub_type: cartItem?.sub_type ? cartItem?.sub_type : cartItem?.type,
  };
};

export const processCartItem = (
  cartItem,
  discountEnabled = false,
  is_same_state 
) => {
  ;
  let item_discount = cartItem.item_discount;
  let item_discount_amount = cartItem.item_discount_amount;
  let item_price = cartItem.item_price;
  let quantity = cartItem.quantity;
  let subtotal = item_price * quantity;

  let net_taxable_amount = discountEnabled
    ? subtotal - item_discount_amount
    : subtotal;

  let {
    cgst,
    sgst,
    igst,
    cess_rate,
    cess,
    tax_rate,
    tax_total,
    taxable_value,
    net_tax,
  } = calculateGstParts(
    cartItem.tax_code,
    net_taxable_amount,
    cartItem.is_tax_included,
    is_same_state,
    cartItem.cess,
    cartItem.cess_rate
  );

  let net_total = taxable_value + tax_total;
  net_total = cartItem.is_tax_included ? net_taxable_amount : net_total;
  net_total = cartItem.is_tax_included
    ? net_total
    : net_total + formatAmount(cess);

  let grand_total = net_total;

  const returnValue = {
    id: cartItem?.id,
    voucher_id: null,
    product_id: cartItem.product_id ? cartItem.product_id : null,
    warehouse_id: cartItem?.warehouse_id,
    product_name: cartItem?.product_name,
    product_code: cartItem?.product_code,
    hsn: cartItem?.hsn,
    exp_date: cartItem?.exp_date,
    mfg_date: cartItem?.mfg_date,
    item_price,
    quantity,
    is_tax_included: cartItem.is_tax_included,
    item_discount,
    item_discount_amount,
    subtotal,
    grand_total,
    taxable_value,
    tax_code: cartItem?.tax_code,
    tax_rate,
    cgst: formatAmount(cgst),
    sgst: formatAmount(sgst),
    igst: formatAmount(igst),
    cess_rate,
    cess: formatAmount(cess),
    tax_total: tax_total ? formatAmount(tax_total) : 0,
    net_tax: formatAmount(net_tax),
    net_total: formatAmount(net_total),
    unit: cartItem?.unit,
    type: cartItem?.type,
  };
  return returnValue;
};

export const calculateTotals = (cartItems) => {
  ;
  let subtotal = 0;
  let tax_total = 0;
  let total_quantity = 0;
  let net_tax = 0;
  let cgst_total = 0;
  let sgst_total = 0;
  let igst_total = 0;
  let cess_total = 0;
  let taxable_value = 0;
  let total_discount = 0;
  let discount = 0;
  let discount_amount = 0;
  let net_total = 0;
  let grand_total = 0;
  let copiedGrandTotal = 0;

  cartItems.forEach((element) => {
    (subtotal += element.subtotal);
      (taxable_value += element.taxable_value ? element.taxable_value : 0);
      (tax_total += element.tax_total ? element.tax_total : 0);
      (net_tax += element.net_tax ? element.net_tax : 0);
      (cgst_total += element.cgst);
      (sgst_total += element.sgst);
      (igst_total += element.igst);
      (cess_total += element.cess);
      (total_quantity += element.quantity);
    discount += element.item_discount ? parseInt(element.item_discount) : 0;
    discount_amount += element.item_discount_amount
      ? element.item_discount_amount
      : 0;
    //total_discount += element.item_discount_amount?element.item_discount_amount:0;
    net_total += element.net_total;
    grand_total += element.grand_total;
    copiedGrandTotal = grand_total;
  });
  return {
    subtotal,
    taxable_value,
    tax_total,
    net_tax,
    cgst_total,
    sgst_total,
    igst_total,
    cess_total,
    total_quantity,
    discount,
    discount_amount,
    net_total,
    grand_total,
    copiedGrandTotal,
  };
};

export const prepareAndProcessCartItem = (selectedProduct) => {
  let preparedItem = prepareCartItem(selectedProduct);
  ;
  return processCartItem(preparedItem);
};

export const subscriptionProcessCart = (selectProduct) => {
  let preparedItem = prepareSubscriptionItem(selectProduct);
  ;
  return subscriptionProcessCartItem(preparedItem);
};
