import { Form } from "react-bootstrap";
import React from "react";
import { placeholderText } from "../../../shared/helpers/sharedMethods";


const TypeFilter = (props) => {
   
    const {reportType,setReportType} = props;
    return (
        <Form.Select
            className="rounded-0"
            value={reportType}
            onChange={(e) => setReportType(e.target.value)}
            style={{ cursor: "pointer" }}
          >
            <option value="" disabled selected hidden>
              {placeholderText("Select Type")}
            </option>
            <option value="">{placeholderText("All Type")}</option>
            <option value="sales_invoice">{placeholderText("Sales Invoice")}</option>
            <option value="sales_return">{placeholderText("Sales Return")}</option>
            <option value="credit_note">{placeholderText("Credit Note")}</option>
            <option value="quote">{placeholderText("Quote")}</option>
            <option value="purchase_order">{placeholderText("Purchase Order")}</option>
            <option value="purchase_invoice">{placeholderText("Purchase Invoice")}</option>
            <option value="purchase_return">{placeholderText("Purchase Return")}</option>
            <option value="debit_note">{placeholderText("Debit Note")}</option>
            <option value="payment_in">{placeholderText("Payment In")}</option>
            <option value="payment_out">{placeholderText("Payment Out")}</option>
          </Form.Select>
    )

}

export default TypeFilter;