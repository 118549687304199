import { Form, InputGroup } from "react-bootstrap";
import { updateItemField } from "../../store/voucherItemsSlice";
import { useDispatch } from "react-redux";

const Quantity = ({ index, item, is_same_state }) => {
    const dispatch = useDispatch();

    return (

        <Form.Control
            type="number"
            name="quantity"
            placeholder="0"
            className="text-end"
            value={item?.quantity ? item?.quantity : ''}
            onChange={(e) => {
                if(e.target.value <= 0 ){
                    e.target.value = 0
                }
                let data = {
                    fieldName: "quantity",
                    fieldValue: parseFloat(e.target.value),
                    index,
                    is_same_state
                }
                dispatch(updateItemField(data));
            }}
        />
    );
};

export default Quantity;