import React, { useEffect } from "react";
import { Badge, Card,  Row, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { fetchSubscriptionByMemberId } from "../../../store/SubscriptionSlice";
import { format } from "date-fns";
import { i18n } from "../../../../shared/helpers/sharedMethods";
import { selectMemberDataById } from "../../../store/MemberSlice";

const SubscriptionList = ({ memberId }) => {
  const dispatch = useDispatch();

  const { accessToken } = useSelector((state) => state.auth);
  const selectedBusiness = useSelector((state) => state.auth.selectedBusiness);
  const tenantId = selectedBusiness?.pivot?.tenant_id;
  const currencySymbol = useSelector((state) => state.getSetting?.dataSetting.currency);

  const getMemberSubs = useSelector((state) => state.subscription.subscriptionItems); // Retrieve subscription data from state
  const selectedMember = useSelector((state) => selectMemberDataById(state, memberId));
 

  useEffect(() => {
    if (memberId && accessToken && tenantId) {
      dispatch(fetchSubscriptionByMemberId({ token: accessToken.access_token, tenant_id: tenantId, id: memberId }));
    }
  }, [memberId, accessToken, tenantId, dispatch]);

  return (
    <Row className="p-5 scrollable-table">
      <Card.Body className="table-responsive">
        <Table bordered hover responsive>
          <thead>
            <tr>
              <th className="px-2">{i18n("Package")}</th>
              <th className="px-2">{i18n("Plan")}</th>
              <th className="px-2">{i18n("Subscription Number")}</th>
              <th className="px-2">{i18n("Next Bill")}</th>
              <th className="px-2">{i18n("Total Amount")}</th>
              <th className="px-2">{i18n("Status")}</th>
              <th className="px-2">{i18n("Created At")}</th>
            </tr>
          </thead>
          <tbody>
            {getMemberSubs && getMemberSubs.length > 0 ? (
              getMemberSubs.map((data, index) => (

                <tr key={index}>
                  <td className="p-1 px-2 m-1 mx-3">
                    {data.subscription_items[0]?.product?.parent_product?.name || "-"}
                  </td>
                  <td className="p-1 px-2 m-1 mx-3">
                    {data.subscription_items[0]?.product?.name || "-"}
                  </td>
                  <td className="p-1 px-2 m-1 mx-3">
                    {data.subscription_number || "-"}
                  </td>
                  <td className="p-1 px-2 m-1 mx-3">
                    {data.bill_queue?.next_bill_date || "-"}
                  </td>
                  <td className="p-1 px-2 m-1 mx-3">
                     {currencySymbol} {data.grand_total || "-"}
                  </td>
                  <td className="p-1 px-2 m-1 mx-3">
                    {data?.subscription_vouchers[0]?.voucher?.paid_amount === data.grand_total ? (
                      <Badge className="mx-4 px-4" bg="success">{i18n("Paid")}</Badge>
                    ) : data?.subscription_vouchers[0]?.voucher?.paid_amount ? (
                      <Badge className="mx-4" bg="warning"> {i18n("Partially Paid")}</Badge>
                    ) : (
                      <Badge className="mx-4" bg="danger"> {i18n("Unpaid")}</Badge>
                    )}
                  </td>
                  <td className="p-1 px-2 m-1 mx-3">
                    {data.created_at
                      ? format(new Date(data.created_at), "dd-MM-yyyy")
                      : "-"}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="6" className="text-center">
                  {i18n("No subscriptions found.")}
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </Card.Body>
    </Row>
  );
};

export default SubscriptionList;