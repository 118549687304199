import {
  createSlice,
  createAsyncThunk,
} from "@reduxjs/toolkit";
import { i18n } from "../../shared/helpers/sharedMethods";
import { axiosRequest } from "../../shared/helpers/axiosHelper";

const initialState = {
  status: false,
  reportItems: [],
  error: false,
  errorMessage: ''
};

export const fetchPartyLedger = createAsyncThunk(
  "partyLedger/fetch",
  async (param) => {
    try {
      const token = param.token;
      const tenantId = param.tenant_id;
      const id = param.id;
      const partyLedgerResponse = await axiosRequest({
        method: "GET",
        url: `get-party-ledger`,
        params: {
          party_id: id,
        },
        headers: {
          Authorization: `Bearer ${token}`,
          "X-Tenant": tenantId,
        },
      });
      return partyLedgerResponse.data;
    } catch (err) {
      await window.log.logToFile({ message: "Error while fetching Party Ledger", level: "error" });
      throw new Error("Error while fetching party Ledger report")
    }
  }
);

const partyLedgerSlice = createSlice({
  name: "partyLedger",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchPartyLedger.fulfilled, (state, action) => {
        state.status = true
        state.reportItems = action.payload;
      })
      .addCase(fetchPartyLedger.rejected, (state, action) => {
        state.error = true;
        state.errorMessage = (i18n("Unable to retrieve the party ledger report. Please try again later."));
        state.status = false;
      })
  },
});

export default partyLedgerSlice.reducer;

