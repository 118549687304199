import React, { useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Layout from "../../shared/components/Layout";
import { useLocation, useNavigate, useParams } from "react-router";
import { Button, Container, Form, Stack } from "react-bootstrap";
import { i18n } from '../../shared/helpers/sharedMethods';
import DeleteModel from "../../shared/components/DeleteModel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDownload,
  faPenToSquare,
  faPrint,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import {
  selectSalesById,
  deletesales,
  selectSalesByType,
  selectAllSales,
} from "../store/voucherSlice";
import { useReactToPrint } from "react-to-print";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import DefaultStyle from "../../shared/components/invoiceLayouts/default";
import StyleOne from "../../shared/components/invoiceLayouts/Theme1";
import StyleTwo from "../../shared/components/invoiceLayouts/Theme2";
import InvoiceLayout from "../../shared/components/invoiceLayouts/invoiceLayout";
import { resetOrder } from "../store/voucherEntitySlice";
import { resetVoucherItem } from "../store/voucherItemsSlice";
import { resetCharges } from "../store/additionalChargesSlice";
import VoucherItemStack from "../../shared/components/item-detail/sections/VoucherItemStack";
import TwoStageLayoutHeader from "../../shared/components/TwoStatgeLayoutHeader";
import Header from "../../shared/components/Header";
import { ToastContainer, toast } from "react-toastify";

const VoucherDetail = (props) => {
  const targetRef = useRef();
  const componentRef = useRef();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const { type, title, button, path } = props;
  ;

  ;
  const { id } = useParams();
  ;
  const [show, setShow] = useState(false);
  const { state } = useLocation();

  const selectedSale = useSelector((state) => selectSalesById(state, id));


  const allSales = useSelector((state) => selectSalesByType(state, type));
  ;

  const invoicesSetting = useSelector((state) => state.setting.invoicesType);

  let saleTabs;
  switch (true) {
    case invoicesSetting?.invoice_1 === true:
      saleTabs = [
        {
          key: "1",
          title: "Default",
          component: (
            <StyleOne name="Sales Invoice" pdf={targetRef} voucherId={id} />
          ),
        },
      ];
      break;
    case invoicesSetting?.invoice_2 === true:
      saleTabs = [
        {
          key: "2",
          title: "Theme1",
          component: (
            <DefaultStyle name="Sales Invoice" pdf={targetRef} voucherId={id} />
          ),
        },
      ];
      break;
    case invoicesSetting?.invoice_3 === true:
      saleTabs = [
        {
          key: "3",
          title: "Theme2",
          component: (
            <StyleTwo name="Sales Invoice" pdf={targetRef} voucherId={id} />
          ),
        },
      ];
      break;
    default:
      saleTabs = [
        {
          key: "1",
          title: "Default",
          component: (
            <StyleOne name="Sales Invoice" pdf={targetRef} voucherId={id} />
          ),
        },
      ];
      break;
  }

  ;

  const handleClick = () => {
    dispatch(resetOrder());
    dispatch(resetVoucherItem());
    dispatch(resetCharges());
    ;
    navigate(`/${path}/create`);
  };

  const convertToSales = () => {
    navigate("/voucher/edit/" + id, { state: { type: "quotation_to_sale" } });
  };

  const DownloadPdf = () => {
    ;
    ;

    html2canvas(componentRef.current, {
      scale: 2,
      useCORS: true,
      allowTaint: true,
      logging: true,
    }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png",0.8);
      const pdf = new jsPDF("p", "mm", "a4",true);

      let selectedLayout;
      if (invoicesSetting && invoicesSetting.invoice_1) {
        selectedLayout = saleTabs.find((tab) => tab.key === "1");
      } else if (invoicesSetting && invoicesSetting.invoice_2) {
        selectedLayout = saleTabs.find((tab) => tab.key === "2");
      } else if (invoicesSetting && invoicesSetting.invoice_3) {
        selectedLayout = saleTabs.find((tab) => tab.key === "3");
      } else {
        selectedLayout = saleTabs[0];
      }

      pdf.addImage(imgData, "PNG", 10, 10, 150, 0);

      const timeStamp = Date.now();
      pdf.save(`sales_invoice_${timeStamp}`);
    });
  };
  const handleEdit = (data) => {
    if((selectedSale.grand_total - selectedSale.paid_amount) == 0){
      toast.error(i18n('You cannot edit a paid/closed order'));
      return;
    }
    dispatch(resetOrder());
    dispatch(resetVoucherItem());
    dispatch(resetCharges());
    ;
    navigate(`/${path}/edit/` + data);
  };

  const fileName = Date.now();
  ;

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: "",
  });

  ;

  const onDelete = () => {};

  const handleSaleClick = (data) => {
    ;
    navigate(`/${path}/view/` + data);
  };
  const handleReturnToGrid = () => {
    navigate(`/${path}`);
  };

  const voucherStack = (
    <Stack>
      <VoucherItemStack
        items={allSales}
        handleItemClick={handleSaleClick}
        currentItemId={id}
        returnToGrid={handleReturnToGrid}
        handleCreate={handleClick}
        type={type}
      />
    </Stack>
  );

  const header = (
    <TwoStageLayoutHeader
      ItemActions={
        <Stack
          gap={3}
          direction="horizontal"
          className="mt-2 mb-2 ml-3 float-end"
        >
            <Button
            className=""
            variant="outline-danger"
            // onClick={() => handleDelete(id)}
          >
            <FontAwesomeIcon className="mx-2 mb-1" icon={faTrash} />
            {i18n("Delete")}
          </Button>
          <Button
            className=""
            variant="outline-secondary"
            onClick={() => handleEdit(id)}
          >
            <FontAwesomeIcon className="mx-2 mb-1" icon={faPenToSquare} />
            {i18n("Edit")}
          </Button>

          <Button
            className=""
            variant="outline-secondary"
            onClick={DownloadPdf}
          >
            <FontAwesomeIcon className="mx-2 mb-1" icon={faDownload} />
            {i18n('Download PDF')}
          </Button>

          <Button
            className=""
            variant="outline-secondary"
            onClick={handlePrint}
          >
            <FontAwesomeIcon className="mx-2 mb-1" icon={faPrint} />
            {i18n("Print Pdf")}
          </Button>

          <div>
            {!state?.status && type == "quotation" && (
              <Button className="btn-success" onClick={convertToSales}>
              {i18n("Convert to Sales")}
              </Button>
            )}
          </div>
        </Stack>
      }
      currentTitle={`#${selectedSale?.voucher_number}`}
      returnToGrid={handleReturnToGrid}
      adjustment="sales"
    />
  );

  return (
    <Layout>
     <ToastContainer />
      <Header
        currentTitle={title}
      />
       <Container fluid className="px-5">
        <InvoiceLayout
          itemStack={voucherStack}
          tabs={saleTabs}
          print={componentRef}
          type={type}
          ItemTitle=""
          handleItemClick={handleSaleClick}
          currentItemId={id}
          header={header}
        />
        {show && (
          <DeleteModel
            deleteUserClick={onDelete}
            onClickDeleteModel={setShow}
            name="Sale"
          />
        )}
      </Container>
    </Layout>
  );
};

export default VoucherDetail;
