import {
  Button,
  Card,
  Col,
  Form,
  InputGroup,
  Modal,
  Row,
  Spinner,
} from "react-bootstrap";
import {
  i18n,
  placeholderText,
  numValidate,
} from "../../shared/helpers/sharedMethods";
import { Formik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { createEnquiryReq, enquiryStatus, resetCreateEnquiry } from "../store/CreateEnquirySlice";
import { useEffect, useState } from "react";
import { MenuItem, Typeahead } from "react-bootstrap-typeahead";
import { Menu } from "react-pro-sidebar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquareXmark } from "@fortawesome/free-solid-svg-icons";
import DatePicker, { registerLocale } from "react-datepicker";
import moment from "moment";
import { selectAllPlanData } from "../../subscription/store/subscription_item_store/GetPackagesItemSlice";
import { storeProduct } from "../../subscription/store/GetParentProductEntiry";
import { UilTimes } from '@iconscout/react-unicons'
import { getEnquiry, pushNewEnquiry } from "../store/EnquirySlice";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const CreateEnquiry = (props) => {
  const { show, setShowEnquiryCreate, isEdit = false } = props;
  ;
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState([]);
  const [followupDate, setFollowupDate] = useState(null);
  const [leadStatus, setLeadStatus] = useState('new');
  const dispatch = useDispatch();
  const { accessToken, isLoggedIn } = useSelector((state) => state?.auth);
  const { parentProduct, party, currentProduct } = useSelector(
    (state) => state.getProduct
  );
  const subscription = useSelector(selectAllPlanData);

  const [selectedItem, setSelectedItem] = useState([]);

  const selectedBusiness = useSelector(
    (state) => state?.auth?.selectedBusiness
  );
  const tenantId = selectedBusiness?.pivot?.tenant_id;
  const status = useSelector((state) => state.createEnquiry.status);
  const createdEnquiry = useSelector((state) => state.createEnquiry?.savedEntity);
;
  useEffect(() => {
    if (subscription) {
      dispatch(storeProduct(subscription));
    }
  }, []);
  useEffect(() => {
    if (status && createdEnquiry) {
      ;
      setShowEnquiryCreate(false);
      toast.success("Enquiry created Successfully");
      dispatch(pushNewEnquiry(createdEnquiry))
      dispatch(enquiryStatus());
 
    }
  }, [status,createdEnquiry]);
  const handleCancel = () => {
    setShowEnquiryCreate(false);
  };
  const handleProductSelect = (item) => {
    setSelectedItem(item);
  };

  const handleFollowupDateChanegs = (date) => {
    var formattedDate = moment(date).format("YYYY-MM-DD");
    setFollowupDate(formattedDate);
  };

  const handleLeadStatusChange = (value) => {
    setLeadStatus(value);
  }


  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Enter") {
        event.preventDefault();
        const saveButton = document.getElementById("submitButton");
        if (saveButton) {
          saveButton.click();
        }
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const filterByFields = ["name", "code"];
  return (
    <Formik
      initialValues={{
        name: "",
        mobile: "",
        product_id: null,
        notes: "",
        lead_status: "",
        status: true,
        created_by: 1,
      }}
      enableReinitialize={true}
      validationSchema={Yup.object().shape({
        name: Yup.string().required(i18n("Please Enter A Name")),
        mobile: Yup.string().matches( /^[0-9]{10}$/,  "  Phone number must be 10 digits"),
      })}
      onSubmit={(values) => {
        if (selectedItem.length != 0) {
          values["product_id"] = selectedItem[0]["id"];
        } else {
          values["product_id"] = null;
        }
        setLoading(true);
        values["followup_date"] = followupDate;
        values["lead_status"] = leadStatus;
        ;
        dispatch(
          createEnquiryReq({
            data: values,
            token: accessToken.access_token,
            tenant_id: tenantId,
          })
        );
        dispatch(
          getEnquiry({
            token: accessToken.access_token,
            tenant_id: tenantId,
          })
        );
      }}
    >
      {({
        values,
        errors,
        handleChange,
        handleSubmit,
        touched,
        setFieldValue,
        handleBlur,
      }) => (
        <>
          <ToastContainer />
          <Modal
            show={show}
            keyboard={true}
            onHide={handleCancel}
            size="md"
            dialogClassName="modal-dialog modal-dialog-centered"
          >
            <Modal.Header closeButton>
              <h2 className="bold">{i18n("Create Enquiry")}</h2>
            </Modal.Header>
            <Modal.Body className="py-2">
              <Card className="px-4">
                <Row className="py-2">
                  <Col lg={12}>
                    <Form.Group>
                      <Form.Label>{i18n("Name")}</Form.Label>
                      <span className="required" />
                    </Form.Group>
                    <Form.Control
                      name="name"
                      placeholder={placeholderText("Enter name")}
                      isInvalid={touched.name && errors.name}
                      value={values.name}
                      onBlur={handleBlur}
                      autoFocus={true}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                    />
                    <Form.Text className="text-danger d-block fw-400 fs-small mt-2">
                      {touched.name && errors.name}
                    </Form.Text>
                  </Col>
                </Row>
                <Row>
                  <Col lg={12}>
                    <Form.Group>
                      <Form.Label>{i18n("Mobile")}</Form.Label>
                    </Form.Group>
                    <Form.Control
                      name="mobile"
                      placeholder={placeholderText("Enter mobile number")}
                      value={values.mobile}
                      pattern="[0-9]*"
                      min={0}
                      maxLength="10"
                      isInvalid={touched.mobile && errors.mobile}
                      onKeyPress={(event) => numValidate(event)}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                    />
                    <Form.Text className="text-danger d-block fw-400 fs-small mt-2">
                      {touched.mobile && errors.mobile}
                    </Form.Text>
                  </Col>
                </Row>
                <Row>
                  <Col lg={12}>
                    <Form.Group>
                      <Form.Label>{i18n("Select Product")}</Form.Label>
                      <InputGroup>
                       
                          <Typeahead
                            newSelectionPrefix="Add a new party : "
                            options={parentProduct}
                            placeholder={placeholderText("Search by Name")}
                            onChange={handleProductSelect}
                            filterBy={filterByFields}
                            selected={selectedItem}
                            labelKey="name"
                            isInvalid={errors.product_id}
                            disabled={!parentProduct ? true : false}
                          />
                       

                        {selectedItem && selectedItem.length > 0 ? (
                          <InputGroup.Text
                            className="cursor-pointer fs-2"
                            style={{ background: "none" }}
                            onClick={() => {
                              setSelectedItem([]);
                              // setItemChanged(true);
                            }}
                          >
                            <FontAwesomeIcon
                              icon={faSquareXmark}
                              title="Clear Item"
                              className="text-danger"
                            />
                          </InputGroup.Text>
                        ) : (
                          ""
                        )}
                      </InputGroup>
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col lg={12}>
                    <Form.Label>{i18n("Follow-up Date")}</Form.Label>
                  </Col>
                  <Col lg={12}>
                    <Form.Group className="">
                      <DatePicker
                        className="form-control date-picker-no-border"
                        placeholderText={placeholderText("Select Date")}
                        dateFormat="dd/MM/yyyy"
                        onChange={handleFollowupDateChanegs}
                        selected={followupDate}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col lg={12}>
                    <Form.Label>{i18n("Lead Status")}</Form.Label>
                  </Col>
                  <Col lg={12}>
                    <Form.Select
                      name="lead_status"
                      onChange={(e) => {
                        handleLeadStatusChange(e.target.value)}}
                    >
                      <option value="new">{placeholderText("New")}</option>
                      <option value="open">{placeholderText("Open")}</option>
                      <option value="interested">{placeholderText("Interested")}</option>
                      <option value="not_interested">{placeholderText("Not Interested")}</option>
                      <option value="converted">{placeholderText("Converted")}</option>
                      <option value="closed">{placeholderText("Closed")}</option>
                    </Form.Select>

                  </Col>
                </Row>
                <Row>
                  <Col lg={12} className="py-5">
                    <Form.Group>
                      <Form.Label>{i18n("Notes")}</Form.Label>
                    </Form.Group>
                    <Form.Control
                      name="notes"
                      as="textarea"
                      placeholder={placeholderText("Enter notes")}
                      className="form-control"
                      value={values.notes}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                    />
                  </Col>
                </Row>
              </Card>
            </Modal.Body>
            <Modal.Footer style={{ borderTop: "1px solid lightgray" }}>
              <Row className="py-1">
                <Col className="d-flex justify-content-end">
                {!loading ? (
                        <Button
                          id="submitButton"
                          variant="primary mx-2"
                          onClick={handleSubmit}
                          disabled={loading}
                        >
                          {i18n("Save")}
                        </Button>
                      ) : (
                        <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          marginRight: "25%",
                        }}
                      >
                        <Spinner animation="border" size="md" />
                      </div>
                      )}
                  <Button
                    variant="outline-secondary mx-2"
                    onClick={handleCancel}
                  >
                    {i18n("Cancel")}
                  </Button>
                </Col>
              </Row>
            </Modal.Footer>
          </Modal>{" "}
        </>
      )}
    </Formik>
  );
};

export default CreateEnquiry;
