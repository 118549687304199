import React from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileInvoice, faStar } from "@fortawesome/free-solid-svg-icons";
import Layout from "../../shared/components/Layout";
import { Card, Col, Container, Row } from "react-bootstrap";
import TransactionReportList from "../components/TransactionReportList";
import ItemReportList from "../components/ItemReportList";
import PartyReportList from "../components/PartyReportList";
import Header from "../../shared/components/Header";
import GSTReportList from "../components/GSTReportList";
import { i18n } from "../../shared/helpers/sharedMethods";

const ReportList = () => {
  const navigate = useNavigate();

  const handleSalesSummary = () => {
    navigate("/reports/salesSummary");
  };

  return (
    <>
      <Layout >
        <Header currentTitle="Reports"/>
        <Container fluid className="px-5">
          <Card.Body className=" ">
            <Card.Header className="  pt-4">
              <Row
                className=" align-items-start d-flex flex-direction-row "
                style={{ color: "#687E96", fontSize: "16px" }}
              >
                <span className=" fw-bolder  ">{i18n("Filter By")}</span>
              </Row>
            </Card.Header>
          </Card.Body>
          <Card className=" mb-4 px-3">
            <Row className="  border">
              <Col
                xl={4}
                md={4}
                sm={4}
                style={{
                  borderRight: "1px solid lightgray",
                  borderBottom: "1px solid lightgray",
                }}
              >
                <span
                  className="section-headings p-2 px-4 d-flex flex-direction-row mt-1"
                  style={{ borderBottom: "1px solid lightgray", width: "100%" }}
                >
                  <FontAwesomeIcon className="px-1 fs-4" icon={faStar} />
                  <h4>{i18n("Favourite")}</h4>
                </span>
                <Card.Body className="py-5">
                  <h6
                    className="pt-5 fs-4"
                    onClick={handleSalesSummary}
                    style={{ cursor: "pointer" }}
                  >
                  {i18n("Sales Summary")}
                  </h6>
                </Card.Body>
              </Col>
              <Col
                xl={4}
                md={4}
                sm={4}
                style={{
                  borderBottom: "1px solid lightgray",
                  borderRight: "1px solid lightgray",
                }}
              >
                <GSTReportList/>
              </Col>

              <Col
                xl={4}
                md={4}
                sm={4}
                style={{ borderBottom: "1px solid lightgray" }}
              >
                <TransactionReportList />
              </Col>

              <Col
                xl={4}
                md={4}
                sm={4}
                style={{ borderRight: "1px solid lightgray" }}
              >
                <ItemReportList />
              </Col>

              <Col
                xl={4}
                md={4}
                sm={4}
                style={{ borderRight: "1px solid lightgray" }}
              >
                <PartyReportList />
              </Col>

              <Col xl={4} md={4} sm={4}>
                <h6
                  className="section-headings p-4 px-4 mt-1"
                  style={{ borderBottom: "1px solid lightgray" }}
                >
                  <h4></h4>
                </h6>
                <Card.Body className="py-5"></Card.Body>
              </Col>
            </Row>
          </Card>
        </Container>
      </Layout>
    </>
  );
};
export default ReportList;
