import React, { useEffect } from "react";
import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import {
  EditItem,
  resetPackage,
} from "../../store/subscription_item_store/CreatePackageSlice";
import {
  currentProductAsSubscriptionItems,
  planData,
  updateProduct,
} from "../../store/subscription_item_store/GetPackagesItemSlice";
import * as Yup from "yup";
import { i18n } from "../../../shared/helpers/sharedMethods";
import {
  Button,
  Col,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "react-bootstrap";
import PackageName from "../../components/packages_fields/PackageName";
import PackageCode from "../../components/packages_fields/PackageCode";
import PackageType from "../../components/packages_fields/PackageType";
import { cloneDeep } from "lodash";
import {
  initEditParentProduct,
  resetParentProduct,
} from "../../store/subscription_item_store/PackageEntitySlice";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


const EditPackage = ({ show, setShow, id }) => {
  const { accessToken, isLoggedIn } = useSelector((state) => state?.auth);

  const selectPackage = useSelector((state) =>
    currentProductAsSubscriptionItems(state, id)
  );
  ;

  const selectedBusiness = useSelector(
    (state) => state?.auth?.selectedBusiness
  );

  const tenantId = selectedBusiness?.pivot?.tenant_id;

  const dispatch = useDispatch();

  const Package = useSelector((state) => state.packageEntity);
  const status = useSelector((state) => state.createPackage.status);
  const savedEntity = useSelector((state) => state.createPackage.savedEntity);
  ;

  useEffect(() => {
    dispatch(initEditParentProduct(selectPackage[0]));
  }, []);

  useEffect(() => {
    if (status && savedEntity) {
      toast.success("Package updated successfully")
      dispatch(updateProduct(savedEntity));
      dispatch(resetParentProduct());
      setShow(false);
    }
  }, [status, savedEntity]);

  const clearField = () => {
    setShow(false);
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Enter") {
        event.preventDefault();
      const submitButton = document.getElementById("submitButton");
      if(submitButton) {
        submitButton.click();
      }
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  return (
    <>
    <ToastContainer />
    <Formik
      initialValues={Package}
      enableReinitialize={true}
      validationSchema={Yup.object().shape({
        name: Yup.string().required(i18n("Product name is required")),
        code: Yup.string().required(i18n("Product code is required")),
        sale_price: Yup.string().required(i18n("Sale price is required")),
        //cost_price: Yup.string().required(i18n("Cost price is required")),
      })}
      onSubmit={async (values) => {
        try {
          let formData = cloneDeep(values);

          ;
          dispatch(
            EditItem({
              data: formData,
              token: accessToken.access_token,
              tenant_id: tenantId,
              id: id,
            })
          );
        } catch (error) {}
      }}
    >
      {({
        values,
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue,
        isSubmitting,
        touched,
      }) => (
        <Modal
          size="md"
          dialogClassName="modal-dialog modal-dialog-centered"
          show={show}
          onHide={clearField}
          keyboard={true}
        >
          <ModalHeader closeButton>
            <Modal.Title>Edit Package</Modal.Title>
          </ModalHeader>
          <ModalBody style={{ borderBottom: "1px solid lightgray" }}>
            <Row>
              <Col lg={12}>
                <PackageName
                  values={values}
                  handleChange={handleChange}
                  errors={errors}
                  touched={touched}
                />
              </Col>
              <Col lg={12}>
                <PackageCode
                  values={values}
                  handleChange={handleChange}
                  errors={errors}
                  touched={touched}
                />
              </Col>
              <Col lg={12}>
                <PackageType
                  values={values}
                  handleChange={handleChange}
                  errors={errors}
                  touched={touched}
                />
              </Col>
            </Row>
          </ModalBody>

          <Row className="  p-2">
            <Col md={12} className="d-flex justify-content-end ">
              <Button id="submitButton" className="btn-primary mx-3" onClick={handleSubmit}>
                {i18n("Save")}
              </Button>
              <Button className="btn-secondary mx-1" onClick={clearField}>
                {i18n("Cancel")}
              </Button>
            </Col>
            <Col md={3}></Col>
          </Row>
        </Modal>
      )}
    </Formik>
    </>
  );
};

export default EditPackage;
