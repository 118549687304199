import React from "react";
import {  Col, Row } from "react-bootstrap";
import { i18n } from "../../../../../shared/helpers/sharedMethods";

const GeneralDetails = ({ selectedProduct }) => {
    

    return (
        <>
        <Row className="p-1 px-4 ">
          <Col lg={5} style={{ fontWeight: 600 }}>
           {i18n("General Details")}
          </Col>
        </Row>
        <Row className=" mt-1 p-1 px-4 " style={{ borderBottom: '1px solid rgba(10, 10, 10, 0.1)', fontSize: '13px', color: '#76797C' }}>
          <Col lg={6}>
            {i18n("Item Name")}
          </Col>
          <Col lg={6} style={{ color: 'black' }}>
            {selectedProduct?.name}
          </Col>
        </Row>
        <Row className=" mt-1 p-1 px-4 " style={{ borderBottom: '1px solid rgba(10, 10, 10, 0.1)', fontSize: '13px', color: '#76797C' }}>
          <Col lg={6}>
            {i18n("Item Code")}
          </Col>
          <Col lg={6} style={{ color: 'black' }}>
            {selectedProduct?.code}
          </Col>
        </Row>
        <Row className=" mt-1 p-1 px-4 " style={{ borderBottom: '1px solid rgba(10, 10, 10, 0.1)', fontSize: '13px', color: '#76797C' }}>
          <Col lg={6}>
            {i18n("Category")}
          </Col>
          <Col lg={6} style={{ color: 'black' }}>
            {selectedProduct.category_product?.name ? selectedProduct.category_product?.name : '-'}
          </Col>
        </Row>
        <Row className=" mt-1 p-1 px-4 " style={{ borderBottom: '1px solid rgba(10, 10, 10, 0.1)', fontSize: '13px', color: '#76797C' }}>
          <Col lg={6}>
           {i18n("Current Stock (Units)")}
          </Col>
          <Col lg={6} style={{ color: 'black' }}>
            {selectedProduct?.inventories?.[0]?.quantity || '0'} {selectedProduct?.unit || 'PCS'}
          </Col>
        </Row>
        <Row className=" mt-1 p-1 px-4 " style={{ fontSize: '13px', color: '#76797C' }}>
          <Col lg={6}>
           {i18n("Item Description")}
          </Col>
          <Col lg={6} style={{ color: 'black' }}>
            {selectedProduct.description ? selectedProduct.description : '-'}
          </Col>
        </Row>
      </>
    )
}

export default GeneralDetails;