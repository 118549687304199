import {
    createSlice,
    createAsyncThunk,
    createEntityAdapter,
} from "@reduxjs/toolkit";
import axios from "axios";
import { environment } from "../../shared/config/environment";
import { i18n } from "../../shared/helpers/sharedMethods";

const syncAdapter = createEntityAdapter();

const initialState = {
    fetchSync: [],
    fetchLogs: [],
    fetchInfoLogs: [],
    status: false,
    error: "",
    infoFileList: [],
    errorFileList: []
};



export const fetchSyncQueue = createAsyncThunk(
    "setting/fetchSyncQueue",
    async () => {
        try {
            const syncResponse = await window.api.getSyncQueue();
            ;
            if (syncResponse.success) {
                return syncResponse.data;
            } else {
                throw new Error(syncResponse.message);
            }
        } catch (error) {
            await window.log.logToFile({ message: "Error while fetching sync queue", level: "error" });
            throw error;
        }
    }
);

export const fetchLogAction = createAsyncThunk(
    "setting/fetchLogs",
    async (currentDate) => {
        console.log("log type", currentDate)
        try {
            const logResponse = await window.log.readLogFile(currentDate);
            ;
            if (logResponse.success) {
                return logResponse.data;
            } else {
                throw new Error(logResponse.message);
            }
        } catch (error) {
            await window.log.logToFile({ message: "Error while fetching Logs", level: "error" });
            throw error;
        }
    }
);

export const fetchInfoLogAction = createAsyncThunk(
    "setting/infoFetchLogs",
    async (currentDate) => {
        console.log("log type", currentDate)
        try {
            const logResponse = await window.log.infoLogFile(currentDate);
            ;
            if (logResponse.success) {
                return logResponse.data;
            } else {
                throw new Error(logResponse.message);
            }
        } catch (error) {
            await window.log.logToFile({ message: "Error while fetching Logs", level: "error" });
            throw error;
        }
    }
);

export const fetchInfoFileList = createAsyncThunk(
    "setting/infoFileList",
    async (currentDate) => {
        console.log("log type", currentDate)
        try {
            const logResponse = await window.log.infoFileList(currentDate);
            console.log("response file list",logResponse)
            if (logResponse.success) {
                return logResponse.data;
            } else {
                throw new Error(logResponse.message);
            }
        } catch (error) {
            await window.log.logToFile({ message: "Error while fetching Logs", level: "error" });
            throw error;
        }
    }
);

export const fetchErrorFileList = createAsyncThunk(
    "setting/errorFileList",
    async (currentDate) => {
        console.log("log type", currentDate)
        try {
            const logResponse = await window.log.errorFileList(currentDate);
            console.log("response file list",logResponse)
            if (logResponse.success) {
                return logResponse.data;
            } else {
                throw new Error(logResponse.message);
            }
        } catch (error) {
            await window.log.logToFile({ message: "Error while fetching Logs", level: "error" });
            throw error;
        }
    }
);


//api ther

const syncLogsSlice = createSlice({
    name: "syncLogs",
    initialState,

    extraReducers(builder) {
        builder
            .addCase(fetchSyncQueue.fulfilled, (state, action) => {
                state.fetchSync = action.payload;
            })
            .addCase(fetchSyncQueue.rejected, (state, action) => {
                state.error = i18n(
                    "Error while fetching sync queue. Please try again later."
                );
            })
            .addCase(fetchLogAction.fulfilled, (state, action) => {
                state.fetchLogs = action.payload;
            })
            .addCase(fetchLogAction.rejected, (state, action) => {
                state.error = i18n(
                    "Error while fetching sync queue. Please try again later."
                );
                state.fetchLogs = []
            })
            .addCase(fetchInfoLogAction.fulfilled, (state, action) => {
                state.fetchInfoLogs = action.payload;
            })
            .addCase(fetchInfoLogAction.rejected, (state, action) => {
                state.error = i18n(
                    "Error while fetching sync queue. Please try again later."
                );
                state.fetchInfoLogs = [];
            })
            .addCase(fetchInfoFileList.fulfilled, (state, action) => {
                console.log("action payload",action.payload)
                state.infoFileList = action.payload;
            })
            .addCase(fetchInfoFileList.rejected, (state, action) => {
                state.error = i18n(
                    "Error while fetching sync queue. Please try again later."
                );
                state.infoFileList = [];
            })
            .addCase(fetchErrorFileList.fulfilled, (state, action) => {
                console.log("action payload",action.payload)
                state.errorFileList = action.payload;
            })
            .addCase(fetchErrorFileList.rejected, (state, action) => {
                state.error = i18n(
                    "Error while fetching sync queue. Please try again later."
                );
                state.errorFileList = [];
            })


    },
});



export default syncLogsSlice.reducer;

export const {
    selectAll: selectAllSyncLogs,
    selectById: selectSyncLogsById,
    selectIds: selectSyncLogsIds,
} = syncAdapter.getSelectors((state) => state.syncLogs);
