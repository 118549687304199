import { Card, Col, Form, Row } from "react-bootstrap";
import { i18n, placeholderText } from "../../shared/helpers/sharedMethods";
import { setTermsAndConditions } from "../store/settingSlice";
import { useDispatch } from "react-redux";

const TermsAndCondition = ({ values, handleChange }) => {
    const dispatch = useDispatch()
  return (
    <Card>
      <Row>
        <Col lg={12}>
          <Form.Group className="">
            <Form.Label className="fs-2">
              <span
                className="pb-2"
                style={{
                  borderBottom: "3px solid #1a237e",
                  fontWeight: 600,
                  fontSize: "15px",
                }}
              >
                {i18n("Terms and Conditions")}
              </span>
            </Form.Label>
          </Form.Group>
        </Col>
      
        <Col lg={10} className="mt-4">
          <Form.Control
            name="Terms_and_Condition"
            as="textarea"
            placeholder={placeholderText("Enter Terms and Conditions")}
            value={values?.terms_and_conditions}
            rows={3}
            onChange={(e) => {
              handleChange(e);
              dispatch(setTermsAndConditions(e.target.value));
            }}
          />
        </Col>
      </Row>
    </Card>
  );
};

export default TermsAndCondition;
