import { Button, Card, Col, Form, Image, Row, Stack } from "react-bootstrap";
import { UilAngleLeft, UilArrowLeft } from "@iconscout/react-unicons";
import { useNavigate } from "react-router";
import { i18n, placeholderText } from "../../shared/helpers/sharedMethods";
import { useSelector } from "react-redux";

const SyncLogsHeader = () => {
  const navigate = useNavigate();
  const selectedBusiness = useSelector(
    (state) => state?.auth?.selectedBusiness
  );
  const admin = selectedBusiness?.pivot?.is_owner;

  const handleClick = () => {
    navigate("/auth/create-business",{state: { value: true}});
  };

  const handleBack = () => {
    navigate('/dashboard/sales')
  }
  return (
    <>
      <Row className=" px-5 bg-white" style={{borderBottom:'1px solid lightgray'}}>
        <Col lg={6} className="d-flex align-items-center px-2 py-3">
          <Button
            variant="outline-secondary "
           onClick={handleBack}
          >
            <UilArrowLeft size="22" className="m-0 p-0 " style={{}} />
            {i18n("Back")}
          </Button>
        </Col>
        <Col lg={6} className="py-3 d-flex align-items-center ">
         <Form.Label style={{fontSize:'20px',fontWeight:'bold'}}>Dev Mode</Form.Label>
        </Col>
      </Row>
    </>
  );
};

export default SyncLogsHeader;
