import { taxList } from "../../shared/config/taxConfig";

export const preprocessorProduct = (product) => {
  const iterateProduct = product?.map((data) => ({
    id: data?.id,
    name: data.name,
    code: data.code,
    type: data.type,
    cost_price: data.cost_price,
    sale_price: data.sale_price,
    category: data.category || data.category_product?.name,
    stock: `${
      data?.inventories?.[0]?.quantity
        ? `${data?.inventories?.[0]?.quantity} ${data.unit}`  
        : data?.stock
        ? `${data.stock} `
        : 0
    }`,
  }));
  ;
  return iterateProduct;
};

export const filterProductCategory = (products, categorys) => {
  return (
    products &&
    products?.filter((product) => categorys?.includes(product?.category_product?.name))?.map((product) => ({
        id: product?.id,
        name: product.name,
        code: product.code,
        type: product.type,
        cost_price: product.cost_price,
        sale_price: product.sale_price,
        category: product.category_product?.name,
        stock: `${
          product?.inventories?.[0]?.quantity 
            ? `${product?.inventories?.[0]?.quantity} ${product?.unit}`
            : 0
        }`,
       
      }))
  );
};

export const exportPreProcessor = (data) => {
  const productData = data?.map((sales) => ({
    id: sales?.id,
    name: sales.name,
    code: sales.code,
    type: sales.type,
    cost_price: sales.cost_price,
    sale_price: sales.sale_price,
    category: sales.category_product?.name,
    stock: `${
      sales?.inventories?.[0]?.quantity
        ? sales?.inventories?.[0]?.quantity
        : "0"
    } `,
  }));
  return productData;
};

export const taxCodeFinder=(taxPercentage)=>{
  const gst = taxList.find((value) => value.rate == taxPercentage);
  
  return gst.code
}

export const taxCodeFinderWithTaxRate =(taxPercentage)=>{
  const gst = taxList.find((value) => value.rate == taxPercentage);
  
 if(gst){
   return{
     tax_code:gst?.code,
     tax_rate:gst?.rate
   }
 }else{
    return{
      tax_code:'GST_NONE',
      tax_rate:0
    }
  }
}