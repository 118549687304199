import React from "react";
import {  Row, Col } from 'react-bootstrap';
import { useSelector } from "react-redux";
import { format } from "date-fns";
import { i18n } from "../../../../shared/helpers/sharedMethods";
import GlobalCurrency from "../../../../shared/components/GlobalCurrency";
import { HeightWeightExtention } from "../../../../shared/config/defaults";

const MemberGeneralInfo = ({ selectedMember }) => {

  return (
    <>
      <div className="d-flex p-1 px-4 ">
        <Col lg={5} style={{ fontWeight: 600 }}>
          {i18n("Member Info")}
        </Col>
      </div>
      <div className='mx-2' style={{ borderBottom: '1px solid rgba(10, 10, 10, 0.1)', fontSize: '13px', color: '#76797C' }}>
        <Row className="mt-1 p-1 px-2">
          <Col lg={6}>
            {i18n("Member Name")}
          </Col>
          <Col lg={6} style={{ color: 'black' }}>
            {selectedMember?.member_name}
          </Col>
        </Row>
      </div>
      <div className='mx-2' style={{ borderBottom: '1px solid rgba(10, 10, 10, 0.1)', fontSize: '13px', color: '#76797C' }}>
        <Row className="mt-1 p-1 px-2">
          <Col lg={6}>
            {i18n("Mobile Number")}
          </Col>
          <Col lg={6} style={{ color: 'black' }}>
            {selectedMember?.mobile ? selectedMember?.mobile : '-'}
          </Col>
        </Row>
      </div>
      <div className='mx-2' style={{ borderBottom: '1px solid rgba(10, 10, 10, 0.1)', fontSize: '13px', color: '#76797C' }}>
        <Row className="mt-1 p-1 px-2">
          <Col lg={6}>
            {i18n("Age")}
          </Col>
          <Col lg={6} style={{ color: 'black' }}>
            {selectedMember?.age ? selectedMember?.age : '-'}
          </Col>
        </Row>
      </div>
      <div className='mx-2' style={{ borderBottom: '1px solid rgba(10, 10, 10, 0.1)', fontSize: '13px', color: '#76797C' }}>
        <Row className="mt-1 p-1 px-2">
          <Col lg={6}>
            {i18n("MEM Number")}
          </Col>
          <Col lg={6} style={{ color: 'black' }}>
            {selectedMember?.member_id ? selectedMember?.member_id : '-'}
          </Col>
        </Row>
      </div>
      <div className='mx-2' style={{ borderBottom: '1px solid rgba(10, 10, 10, 0.1)', fontSize: '13px', color: '#76797C' }}>
        <Row className="mt-1 p-1 px-2">
          <Col lg={6}>
            {i18n("Weight")}
          </Col>
          <Col lg={6} style={{ color: 'black' }}>
            {selectedMember?.weight ? `${selectedMember?.weight} ${HeightWeightExtention?.weight}` : '-'}
          </Col>
        </Row>
      </div>
      <div className='mx-2' style={{ borderBottom: '1px solid rgba(10, 10, 10, 0.1)', fontSize: '13px', color: '#76797C' }}>
        <Row className="mt-1 p-1 px-2">
          <Col lg={6}>
            {i18n("Height")}
          </Col>
          <Col lg={6} style={{ color: 'black' }}>
            {selectedMember?.height ? `${selectedMember?.height} ${HeightWeightExtention?.height}` : '-'}
          </Col>
        </Row>
      </div>
      <div className='mx-2' style={{ borderBottom: '1px solid rgba(10, 10, 10, 0.1)', fontSize: '13px', color: '#76797C' }}>
        <Row className="mt-1 p-1 px-2">
          <Col lg={6}>
            {i18n("Member Since")}
          </Col>
          <Col lg={6} style={{ color: 'black' }}>
          {selectedMember?.created_at ? (format(new Date(selectedMember.created_at), 'dd/MM/yyyy')
           ) : ""}{" "}
          </Col>
        </Row>
      </div>
    </>

  )
}

export default MemberGeneralInfo;