import moment from "moment";
import { changeMember } from "../../store/CreateSubscriptionSlice";

export const filteredParty = (partyData, name) => {
  return partyData.filter((item) => item?.party?.name === name);
};

export const preProcessorParty = (partyData) => {
  return partyData.map((data) => ({
    id: data.id,
    voucher_date: data?.voucher?.voucher_date,
    name: data?.party?.name,
    voucher_number: data?.voucher?.voucher_number,
    grand_total: data?.voucher?.grand_total,
    paid_amount: data?.voucher?.paid_amount,
  }));
};

export function processSubscriptions(subscriptions) {
  let SubData = [];
  if (subscriptions && subscriptions.length > 0) {
    SubData = subscriptions.map((subscription) => {
      if (
        subscription.subscription_vouchers &&
        subscription.subscription_vouchers.length > 0
      ) {
        const modifiedVouchers = subscription.subscription_vouchers.map(
          (voucher) => {
            return {
              ...voucher,
              party: subscription.party,
            };
          }
        );
        return {
          ...subscription,
          subscription_vouchers: modifiedVouchers,
        };
      } else {
        return subscription;
      }
    });
  }
  return SubData;
}

export const SubscriptionProcess = (items) => {
  ;
  let subscriptionItems = [];

  items?.subscription_items?.forEach((item, index) => {
    const subscriptionItem = {
      type: item.sub_type,
      product_id: item.product_id,
      member_id: item.member_id,
      product_code: item.code,
      price: item.item_price,
      quantity: item.quantity,
      unit: item.unit,
      tax_code: item.tax_code,
      is_tax_included: item.is_tax_included,
      tax_total: item.tax_total,
      item_discount: item.item_discount,
      item_discount_amount: item.item_discount_amount,
      cess: item.cess,
      cess_rate: item.cess_rate,
    };
    subscriptionItems.push(subscriptionItem);
  });

  const subscriptionData = {
    party_id: items?.party_id,
    member_id: items?.member_id,
    product_id: items?.product_id,
    start_date: items?.start_date
      ? moment(items.start_date).format("YYYY-MM-DD")
      : null,
    tax_total: items?.tax_total,
    cess_total: items?.cess_total,
    discount: items?.discount,
    discount_amount: items?.discount_amount,
    grand_total: items?.grand_total,
    subscription_number: items?.subscription_number,
    subscription_items: subscriptionItems,
    party: items?.party,
    member: items?.member,
    created_by: 1,
  };
  ;
  return subscriptionData;

 
};
