import BillWiseReport from "../screens/BillWiseReport"
import DayBookReport from "../screens/DayBookReport"
import ProfitAndLoss from "../screens/ProfitAndLoss"
import PurchaseSummary from "../screens/PurchaseSummary"
import SalesSummary from "../screens/SalesSummary"


const rootPath = 'reports'


export const transactionreportRoutes = [
    {
        path: rootPath + '/transaction/bill-wise-profit',
        element: <BillWiseReport />,
        isProtected: true

    },
    {
        path: rootPath + '/transaction/day-book',
        element: <DayBookReport />,
        isProtected: true

    },
    {
        path: rootPath + '/transaction/profit-and-loss',
        element: <ProfitAndLoss />,
        isProtected: true

    },
    {
        path: rootPath + '/transaction/sales-summary',
        element: <SalesSummary />,
        isProtected: true

    },
    {
        path: rootPath + '/transaction/purchase-summary',
        element: <PurchaseSummary />,
        isProtected: true

    },


]