import { Form } from "react-bootstrap";
import React from "react";
import { placeholderText } from "../../../../shared/helpers/sharedMethods";


const ProductFliter = (props) => {
   
    const {productFilter,setProductFilter} = props;
    return (
        <Form.Select
            className=""
            value={productFilter}
            onChange={(e) => setProductFilter(e.target.value)}
            style={{ cursor: "pointer" }}
          >

            <option value="amount">{placeholderText("Amount")}</option>
            <option value="quantity">{placeholderText("Quantity")}</option>

          </Form.Select>
    )

}

export default ProductFliter;