import React, { useState, useRef, useEffect } from "react";
import {
  Container,
  Card,
  Image,
  Col,
  Row,
  Form,
  Button,
  Modal,
} from "react-bootstrap";
import defaultLogo from "../../../assets/images/bill.png";
import { useDispatch, useSelector } from "react-redux";
import {
  numValidate,
  placeholderText,
} from "../../shared/helpers/sharedMethods";
import { useNavigate } from "react-router";
import {
  changeName,
  changeAddress,
  changePhone,
  changeGst,
  setCurrency,
  setTermsAndConditions,
  setSignature,
  setLogo,
  setUPIPayment,
  changePan,
  setDefaultTax,
  setPlaceOfSupply,
  setSecondCardColor,
  setFirstCardColor,
  setTextColor
} from "../store/settingSlice";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faX } from "@fortawesome/free-solid-svg-icons";
import { i18n } from "../../shared/helpers/sharedMethods";
import { QRCode } from "react-qr-code";
import { taxList } from "../../shared/config/taxConfig";
import { filterTaxList } from "./helpers/settingHelpers";
import { ErrorMessage } from "formik";
import ReactCrop, { centerCrop, makeAspectCrop } from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { placeOfSupplyList } from "../../shared/config/placeOfSupply";
import imageCompression from 'browser-image-compression';
function canvasPreview(image, canvas, crop, scale = 1, rotate = 0, quality = 0.7) {
  const ctx = canvas.getContext("2d");
  if (!ctx) throw new Error("No 2D context");

  const scaleX = image.naturalWidth / image.width;
  const scaleY = image.naturalHeight / image.height;

  const pixelCrop = {
    x: Math.floor(crop.x * scaleX),
    y: Math.floor(crop.y * scaleY),
    width: Math.floor(crop.width * scaleX),
    height: Math.floor(crop.height * scaleY),
  };
  const rotateRads = rotate * (Math.PI / 180);
  const cropWidth = pixelCrop.width * scale;
  const cropHeight = pixelCrop.height * scale;
  canvas.width = cropWidth;
  canvas.height = cropHeight;

  ctx.clearRect(0, 0, canvas.width, canvas.height);

  ctx.save();

  ctx.translate(canvas.width / 2, canvas.height / 2);
  ctx.rotate(rotateRads);
  ctx.scale(scale, scale);
  ctx.drawImage(
    image,
    pixelCrop.x,
    pixelCrop.y,
    pixelCrop.width,
    pixelCrop.height,
    -pixelCrop.width / 2,
    -pixelCrop.height / 2,
    pixelCrop.width,
    pixelCrop.height
  );
  ctx.restore();
  return canvas.toDataURL('image/png', quality);
}

const CompanyDetails = (props) => {
  const [showModal, setShowModal] = useState(false);
  const { handleChange, values, errors, setFieldValue } = props;
  const imgRef = useRef(null);

  const [tempLogo, setTempLogo] = useState(null);
  const [imgSrc, setImgSrc] = useState("");
  const [crop, setCrop] = useState({ unit: "px", x: 0, y: 0, width: 350, height: 220, aspect: 2 });
  const [completedCrop, setCompletedCrop] = useState(null);
  const [scale, setScale] = useState(1);
  const [rotate, setRotate] = useState(0);

  const fileInputRef = useRef(null);
  const cropRef = useRef(null);

  const dispatch = useDispatch();
  const [imageError, setImageError] = useState("");

  const logo = useSelector((state) => state.setting?.billing_settings?.logo);
  const getSetting = useSelector((state) => state.getSetting?.dataSetting);
  const signature = useSelector(
    (state) => state.setting?.billing_settings?.signature
  );

  const globalPlanFeatures = useSelector(
      (state) => state?.globalPlan?.features
  );
  const featureName="subscription"
  const [isOnline, setIsOnline] = useState(navigator.onLine);

  const getTax = (getSetting?.tax || []);
  const TaxList = filterTaxList(taxList, getTax);
  useEffect(() => {
    if (logo) {
      setImgSrc(logo);
    }
  }, [logo]);

  useEffect(() => {
    setTempLogo(logo);
  }, [logo]);

  const handleFileChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setCrop({ unit: 'px', x: 0, y: 0, width: 400, height: 150, aspect: 2 });
      const reader = new FileReader();
      reader.onload = () => {
        setImgSrc(reader.result?.toString() || "");
        setCompletedCrop(null);
        setShowModal(true);
      };
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };


  const onImageLoad = (event) => {
    const { width, height } = event.target; 
    const cropWidth = 350; 
    const cropHeight = 220; 
  
    setCrop({
      unit: 'px',
      x: Math.max(0, (width - cropWidth) / 2), 
      y: Math.max(0, (height - cropHeight) / 2), 
      width: cropWidth,
      height: cropHeight,
      aspect: 2,
    });
  };
  


  const handleImageUpload = async (blob) => {
    try {
      const options = {
        maxSizeMB: 0.05,
        maxWidthOrHeight: 300,
        initialQuality: 0.1,
        useWebWorker: true,
      };

      const compressedBlob = await imageCompression(blob, options);
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64Image = reader.result;
              dispatch(setLogo(base64Image));
              setShowModal(false);
            };           
      reader.readAsDataURL(compressedBlob);
    } catch (error) {
    }
  };




  useEffect(() => {
    if (completedCrop && imgRef.current) {
      const image = imgRef.current;
      const canvas = document.createElement("canvas");
      canvasPreview(image, canvas, completedCrop, scale, rotate);
      setImageError("");
    }
  }, [completedCrop, scale, rotate]);

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      border: "1px solid #ccc",
      borderRadius: "4px",
      boxShadow: state.isFocused ? "0 0 7px rgb(77,144,254,0.5)" : null,
      height: "40px",
    }),
  };
  const handleCloseModal = () => {
    setShowModal(false);
    fileInputRef.current.value = "";

  };
  const color1 = useSelector((state) => state.setting?.billing_settings?.color1) || ''; 
  const color2 = useSelector((state) => state.setting?.billing_settings?.color2) || ''; 
  const textColor = useSelector((state) => state.setting?.billing_settings?.textColor) || ''; 


  const handleColorChange = (color, colorNumber) => {
    if (colorNumber === 1) {
      dispatch(setFirstCardColor(color));
    } else if (colorNumber === 2) {
      dispatch(setSecondCardColor(color));
    } else {
      dispatch(setTextColor(color));
    }
  };

  return (
    <Card className="">
      <Row className="mt-3">
        <Col lg={12}>
          <Form.Group className="">
            <Form.Label className="fs-2">
              <span
                className="pb-2"
                style={{
                  borderBottom: "3px solid #1a237e",
                  fontWeight: 600,
                  fontSize: "15px",
                }}
              >
                {i18n("Business Details")}
              </span>
            </Form.Label>
          </Form.Group>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col lg={12}>
          <Form.Label>{i18n("Business Logo")}</Form.Label>
          {tempLogo ? (
            <Col md={2} className="d-flex">
              <div className="border" style={{ borderRadius: "5px" }}>
                <Image
                  src={tempLogo}
                  alt="logo"
                  style={{ width: "125px", height: "75px", objectFit: "contain" }}
                  onClick={() => setShowModal(false)}
                />
              </div>
              <FontAwesomeIcon
                className=" mx-2"
                icon={faX}
                fontSize={10}
                style={{
                  cursor: "pointer",
                }}
                onClick={() => {
                  setTempLogo(null);
                  dispatch(setLogo(null));
                }}
              />
            </Col>
          ) : (
            <Col md={2} className="mt-2">
              <input
                name="image"
                ref={fileInputRef}
                onChange={handleFileChange}
                className="d-none"
                type="file"
                accept="image/*"
              />
              <Button
                style={{ width: "100px", height: "65px" }}
                variant="outline-primary"
                onClick={handleButtonClick}
              >
                +
              </Button>
            </Col>
          )}
        </Col>
      </Row>
      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        centered
        className="custom-modal-shadow"

      >
        <Modal.Header closeButton>
          <Modal.Title>Crop Image</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {imageError && (
            <div className="text-danger">
              {imageError}
            </div>
          )}
          {imgSrc && (
            <div
              ref={cropRef}
              style={{
                marginTop: "20px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <ReactCrop
                crop={crop}
                onChange={(_, percentCrop) => setCrop(percentCrop)}
                onComplete={(c) => setCompletedCrop(c)}
                keepSelection={true}
                locked={true}
                aspect={1}
              >
                <img
                  ref={imgRef}
                  alt="Crop me"
                  src={imgSrc}
                  style={{ 
                    height: '400px', width: '400px'
                  }}
                  onLoad={onImageLoad}

                />
              </ReactCrop>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "10px 20px",
          }}
        >
          <Col className='d-flex justify-content-end px-5 mb-5 mt-3 mx-2' style={{ columnGap: 10 }}>
            <Button
              variant="outline-secondary"
              onClick={handleCloseModal}
            >
              Cancel
            </Button>
            <Button
              variant="primary"
          onClick={() => {
            const canvas = document.createElement("canvas");
            const finalCrop = completedCrop?.width && completedCrop?.height 
              ? completedCrop 
              : crop;

            canvasPreview(imgRef.current, canvas, finalCrop, scale, rotate);
            canvas.toBlob((blob) => {
              if (blob) {
                handleImageUpload(blob);
              }
            }, "image/png", 0.9);
          }}>
          {"Save"}
        </Button>
          </Col>
        </Modal.Footer>
      </Modal>

      <Row>
        <Col md={10} className="mt-3">
          <Form.Label>{i18n("Business Name")}</Form.Label>
          <Form.Control
            value={values?.business_name|| ''}
            name="business_name"
            type="text"
            placeholder={placeholderText("Enter your business name")}
            onChange={(e) => {
              handleChange(e);
              dispatch(changeName(e.target.value));
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col md={10} className="mt-3">
          <Form.Group controlId="exampleForm.ControlInput1">
            <Form.Label>{i18n("Business Phone Number")}</Form.Label>
            <Form.Control
              value={values?.business_phone|| ''}
              type="text"
              pattern="[0-9]*"
              min={0}
              maxLength="10"
              placeholder={placeholderText("Enter your Phone Number")}
              name="business_phone"
              onChange={(e) => {
                handleChange(e);
                dispatch(changePhone(e.target.value));
              }}
              onKeyPress={(event) => numValidate(event)}
            />
          </Form.Group>
          {errors.business_phone && (
            <span className="" style={{ color: "#EE5B50" }}>
              {errors.business_phone}
            </span>
          )}
        </Col>
      </Row>

      <Row>
        <Col md={10} className="mt-3">
          <Form.Group controlId="exampleForm.ControlInput1">
            <Form.Label>{i18n("GSTIN")}</Form.Label>
            <Form.Control
              value={values?.gstin|| ''}
              name="gstin"
              type="text"
              placeholder={placeholderText("Enter your GST Number")}
              onChange={(e) => {
                handleChange(e);
                dispatch(changeGst(e.target.value.toUpperCase()));
              }}
            />
          </Form.Group>
          {errors.gstin && (
            <span className="" style={{ color: "#EE5B50" }}>
              {errors.gstin}
            </span>
          )}
        </Col>
      </Row>
      <Row>
        <Col md={10} className="mt-3">
          <Form.Group controlId="exampleForm.ControlInput1">
            <Form.Label> {i18n("PAN Number")}</Form.Label>

            <Form.Control
              type="text"
              name="pan"
              placeholder={placeholderText("Enter your PAN Number")}
              className="form-control "
              value={values.pan|| ''}
              minLength={10}
              maxLength={10}
              pattern="^[A-Z]{5}[0-9]{4}[A-Z]{1}$"
              onChange={(e) => {
                handleChange(e);
                dispatch(changePan(e.target.value.toUpperCase()));
              }}
            />
          </Form.Group>
          {errors.pan && (
            <span className="" style={{ color: "#EE5B50" }}>
              {errors.pan}
            </span>
          )}
        </Col>
      </Row>
      {
        // Default Tax option moved to TaxSettings.js
      }
      <Row>
        <Col md={10} className="mt-3">
          <Form.Group controlId="exampleForm.ControlInput1">
            <Form.Label> {i18n("PLACE OF SUPPLY")}</Form.Label>
            <Select
              name="place_of_supply"
              options={placeOfSupplyList}
              styles={customStyles}
              value={placeOfSupplyList.find(
                (option) => option.value == values.place_of_supply
              )}
              onChange={(selectedOption) => {
                setFieldValue("place_of_supply", selectedOption);
                dispatch(setPlaceOfSupply(selectedOption.value));
              }}
            />
          </Form.Group>
        </Col>
      </Row>
      {isOnline && globalPlanFeatures.includes(featureName) && (
      <Row className="mt-5">
      <Form.Label> {i18n("Membership Card Colors")}</Form.Label>

        <Col lg={4}>
          <Form.Label className="mt-3">{i18n("Select First Color")}:</Form.Label>
          <Form.Control
            type="color"
            name="color1"
            value={color1}
            onChange={(e) => handleColorChange(e.target.value, 1)}
       />
        </Col>
        <Col lg={4}>
          <Form.Label className="mt-3">{i18n("Select Second Color")}:</Form.Label>
          <Form.Control
            name="color2"
            type="color"
            value={color2}
            onChange={(e) => handleColorChange(e.target.value, 2)}    
            />
        </Col>
        <Col lg={4}>
        <Form.Label className="mt-3">{i18n("Select Text Color")}:</Form.Label>
          <Form.Control
            name="textColor"
            type="color"
            value={textColor} 
            onChange={(e) => handleColorChange(e.target.value, 3)} 
            />
        </Col>
      </Row>)}
    </Card>
  );
};

export default CompanyDetails;

