import React, { useState, useEffect } from "react";
import { Button, Row, Col, Stack, Container, Badge,Image } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchPurchaseSummaryReport } from "../store/purchaseSummaryReportSlice";
import Layout from "../../shared/components/Layout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faArrowUp,
  faChevronLeft,
  faDownload,
} from "@fortawesome/free-solid-svg-icons";
import { Form } from "react-bootstrap";
import { downloadCsvFile, handleExportClick } from "../../shared/export";
import DataGrid from "../../shared/components/DataGrid";
import { format } from "date-fns";
import ReportPeriod from "../../shared/components/ReportPeroid";
import Header from "../../shared/components/Header";
import { i18n } from "../../shared/helpers/sharedMethods";
import TransactionNavbar from "../components/TransactionNavbar";
import ShortcutKeys from "../../shared/components/ShortcutKeys";
import ReportDateRange from "../../shared/components/date_range/ReportDateRange";
import DatePicker from "../../shared/components/date_range/DatePicker";
import { CurrentWeekDateRange } from "../../shared/components/DateRangeYAxis";
import ReportHeader from "../components/ReportHeader";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import CustomeSortIcon from "../../shared/components/CustomeSortIcon";
import { UilImport } from "@iconscout/react-unicons";
import { formatAmount } from "../../pos/helpers/cartHelper";
import { getLocalizedDate } from '../../shared/helpers/localizationHelper';
import LoadingSpinner from '../../shared/components/LoadingSpinner';
import GlobalCurrency from "../../shared/components/GlobalCurrency";
const PurchaseSummary = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const accessToken = useSelector((state) => state?.auth?.accessToken?.access_token);
  const selectedBusiness = useSelector((state) => state?.auth?.selectedBusiness);
  const tenantId = selectedBusiness?.pivot?.tenant_id;
  const [reportPeriod, setReportPeriod] = useState("current-week");
  const [csvData, setCsvData] = useState("");
  const [startDate, setStartDate] = useState(null); // State for start date
  const [endDate, setEndDate] = useState(null);
    const [sortField, setSortField] = useState(null);
    const [sortOrder, setSortOrder] = useState(1);


    const handleReset = () => {
      const salesDateLimits = ReportDateRange({
        reportPeriod: reportPeriod,
      });
      setStartDate(salesDateLimits.startDate);
      setEndDate(salesDateLimits.endDate);
      setReportPeriod("current-week");
    };

  useEffect(() => {
    dispatch(fetchPurchaseSummaryReport({startDate: startDate, endDate: endDate,token:accessToken,tenant_id:tenantId})); 
  }, [startDate, endDate]);

  useEffect(() => {
    const dateLimits = ReportDateRange({ reportPeriod });
    setStartDate(dateLimits.startDate);
    setEndDate(dateLimits.endDate);
  }, [reportPeriod]);

  const reportItems = useSelector(
    (state) => state.purchaseSummaryReport.reportItems
  );
  const status = useSelector((state) => state.purchaseSummaryReport.status);
  const loading = useSelector((state) => state.purchaseSummaryReport.loading);
  const errorMessage = useSelector(
    (state) => state.purchaseSummaryReport.errorMessage
  );
  const currencySymbol  = useSelector((state) => state.getSetting?.dataSetting.currency);
  ;
  const totalProfit = Array.isArray(reportItems)
    ? reportItems.reduce((sum, item) => sum + item.amount, 0)
    : 0;
    const dateTimeString = new Date()
  .toLocaleString()
  .replace(/[-]/g, " ")
  .replace(/:/g, "-");
const fileName = `Purchase_summary_${dateTimeString}`;

  const handleExportButtonClick = () => {
    const exportData =
      reportItems &&
      Array.isArray(reportItems) &&
      reportItems.map((val) => ({
        "DATE": val.date
          ? format(new Date(val.date), "dd-MM-yyyy ")
          : "-",
        "Inv NO.": val.invNo ? val.invNo : "-",
        "PARTY NAME": val.partyName ? val.partyName : "-",
        "DATE DUE": val.dueDate
        ? format(new Date(val.dueDate), "dd-MM-yyyy ")
        : "-",
        "AMOUNT": val.amount ? val.amount : "0",
        "BALANCE": val?.balance ? parseFloat(val.balance).toFixed(2) : "0",
        "INVOICE STATUS": val.reportStatus ? val.reportStatus : "-",
      }));
    handleExportClick(
      exportData,
      setCsvData,
      downloadCsvFile,
      fileName
    );
  };
    const customSort = (field) => {
      setSortField(field);
      setSortOrder(sortOrder === 1 ? -1 : 1);
    };
    const sortedData = [...reportItems].sort((a, b) => {
      if (sortField) {
        const compareValueA = a[sortField];
        const compareValueB = b[sortField];
        if (compareValueA < compareValueB) {
          return sortOrder;
        }
        if (compareValueA > compareValueB) {
          return -sortOrder;
        }
      }
      return 0;
    });
    const headerStyle = {
      backgroundColor: "white",
    };
    const headerCellStyle = {
      fontWeight: "bold",
      fontSize: "11px",
    };

  return (
    <Layout>
        {loading && <LoadingSpinner />}
      <Header
        currentTitle={i18n("Purchase Summary")}
        ItemActions={
          <Stack gap={3} direction="horizontal" className="mt-1 mb-1 float-end">
            <ShortcutKeys />
          </Stack>
        }
      />
      <Container fluid className="px-5">
        <div className="border " style={{ background: "white" }}>
          <TransactionNavbar />

          <label className="d-flex flex-direction-row p-4 fs-3">
            {i18n("Total Purchase")}&nbsp;:&nbsp;
            <label className="fw-bolder fs-2" style={{ color: "green" }}>
              <FontAwesomeIcon
                className="px-1 fs-4"
                icon={faArrowUp}
                style={{ color: "green", width: "16px" }}
              />
              <GlobalCurrency price={totalProfit} />
            </label>
          </label>
          <ReportHeader
            ItemActions={
              <Stack gap={2} direction="horizontal" className="pt-3 px-3 mb-3">
                <Stack direction="horizontal">
                  <DatePicker
                    startDate={startDate}
                    setStartDate={setStartDate}
                    endDate={endDate}
                    setEndDate={setEndDate}
                  />
                </Stack>
                <Stack className=" ">
                  <ReportPeriod
                    reportPeriod={reportPeriod}
                    setReportPeriod={setReportPeriod}
                  />
                </Stack>
                <Stack
                  gap={1}
                  direction="horizontal"
                  style={{ marginLeft: "1px" }}
                >
                  <Button
                    className="rounded-0"
                    variant="outline-danger"
                    onClick={handleReset}
                  >
                      {i18n("Reset")}
                  </Button>
                </Stack>
                <Button
                  className="fw-bolder d-flex align-items-center rounded-0"
                  onClick={handleExportButtonClick}
                >
                  <UilImport
                    size="18"
                    className="mx-2"
                    style={{ cursor: "pointer" }}
                  />
                  {i18n("Export")}
                </Button>
              </Stack>
            }
          />
          {/* {status ? (
            <DataGrid
              allData={reportItems}
              data={reportItems}
              columns={gridColumns}
              isPaginated={true}
              title="Report"
            />
          ) : (
            <p className="d-flex justify-content-center p-2 fs-3 fw-bolder">
              {i18n("Error while fetching Purchase summary report")}
            </p>
          )} */}
          <Container fluid className="px-3 mb-4">
            <div className="bordered-container">
              {status && reportItems ? (
                <DataTable
                  value={sortedData}
                  headerStyle={headerStyle}
                  paginator
                  rows={10}
                  dataKey="id"
                  emptyMessage={i18n("No Purchase Summary found")}
                  className="p-datatable-gridlines full-width-table custom-datatable p-component"
                  scrollable
                  scrollHeight="400px"
                  sortMode="multiple"
                  removableSort
                >
                  <Column
                    field="date"
                    header={() => (
                      <div
                        className={`custom-header-${
                          sortField === "date"
                            ? sortOrder === 1
                              ? "up"
                              : "down"
                            : ""
                        }`}
                        onClick={() => customSort("date")}
                        style={{
                          cursor: "pointer",
                          display: "flex",
                          alignItems: "center",
                          fontSize: "11px",
                          lineHeight: "11px",
                        }}
                      >
                        {i18n("DATE")}
                        <CustomeSortIcon
                          sortOrder={sortField === "date" ? sortOrder : 0}
                          style={{ marginLeft: "4px", verticalAlign: "middle" }}
                        />
                      </div>
                    )}
                    body={(row) =>
                      row.date ? (
                        <span>{getLocalizedDate(row.date, 'en-IN').split(',')[0]}</span>
                        ) : (
                        "-"
                      )
                    }
                    sortable
                    headerStyle={headerCellStyle}
                  />
                  <Column
                    field="invNo"
                    header={() => (
                      <div
                        className={`custom-header-${
                          sortField === "invNo"
                            ? sortOrder === 1
                              ? "up"
                              : "down"
                            : ""
                        }`}
                        onClick={() => customSort("invNo")}
                        style={{
                          cursor: "pointer",
                          display: "flex",
                          alignItems: "center",
                          fontSize: "11px",
                          lineHeight: "11px",
                        }}
                      >
                        {i18n("Invoice No")}
                        <CustomeSortIcon
                          sortOrder={sortField === "invNo" ? sortOrder : 0}
                          style={{ marginLeft: "4px", verticalAlign: "middle" }}
                        />
                      </div>
                    )}
                    sortable
                    headerStyle={headerCellStyle}
                  />
                  <Column
                    field="partyName"
                    header={() => (
                      <div
                        className={`custom-header-${
                          sortField === "partyName"
                            ? sortOrder === 1
                              ? "up"
                              : "down"
                            : ""
                        }`}
                        onClick={() => customSort("partyName")}
                        style={{
                          cursor: "pointer",
                          display: "flex",
                          alignItems: "center",
                          fontSize: "11px",
                          lineHeight: "11px",
                        }}
                      >
                        {i18n("Party Name")}
                        <CustomeSortIcon
                          sortOrder={sortField === "partyName" ? sortOrder : 0}
                          style={{ marginLeft: "4px", verticalAlign: "middle" }}
                        />
                      </div>
                    )}
                    sortable
                    headerStyle={headerCellStyle}
                  />
                  <Column
                    field="dueDate"
                    header={() => (
                      <div
                        className={`custom-header-${
                          sortField === "dueDate"
                            ? sortOrder === 1
                              ? "up"
                              : "down"
                            : ""
                        }`}
                        onClick={() => customSort("dueDate")}
                        style={{
                          cursor: "pointer",
                          display: "flex",
                          alignItems: "center",
                          fontSize: "11px",
                          lineHeight: "11px",
                        }}
                      >
                        {i18n("DATE DUE")}
                        <CustomeSortIcon
                          sortOrder={sortField === "dueDate" ? sortOrder : 0}
                          style={{ marginLeft: "4px", verticalAlign: "middle" }}
                        />
                      </div>
                    )}
                    body={(row) =>
                      row.dueDate ? (
                        <span>{getLocalizedDate(row.dueDate, 'en-IN').split(',')[0]}</span>
                        ) : (
                        "-"
                      )
                    }
                    sortable
                    headerStyle={headerCellStyle}
                  />
                  <Column
                    field="amount"
                    header={() => (
                      <div
                        className={`custom-header-${
                          sortField === "amount"
                            ? sortOrder === 1
                              ? "up"
                              : "down"
                            : ""
                        }`}
                        onClick={() => customSort("amount")}
                        style={{
                          cursor: "pointer",
                          display: "flex",
                          alignItems: "center",
                          fontSize: "11px",
                          lineHeight: "11px",
                        }}
                      >
                        {i18n("AMOUNT")}
                        <CustomeSortIcon
                          sortOrder={sortField === "amount" ? sortOrder : 0}
                          style={{ marginLeft: "4px", verticalAlign: "middle" }}
                        />
                      </div>
                    )}
                    body={(row) => (
                      <span>
                        {row.amount ? (
                          <GlobalCurrency price={parseFloat(row.amount)} />
                        ) : (
                          "0.00"
                        )}
                      </span>
                    )}
                    sortable
                    headerStyle={headerCellStyle}
                  />
                  <Column
                    field="balance"
                    header={() => (
                      <div
                        className={`custom-header-${
                          sortField === "balance"
                            ? sortOrder === 1
                              ? "up"
                              : "down"
                            : ""
                        }`}
                        onClick={() => customSort("balance")}
                        style={{
                          cursor: "pointer",
                          display: "flex",
                          alignItems: "center",
                          fontSize: "11px",
                          lineHeight: "11px",
                        }}
                      >
                        {i18n("BALANCE")}
                        <CustomeSortIcon
                          sortOrder={sortField === "balance" ? sortOrder : 0}
                          style={{ marginLeft: "4px", verticalAlign: "middle" }}
                        />
                      </div>
                    )}
                    body={(row) => (
                      <span>
                        {row.balance ? (
                          <GlobalCurrency price={parseFloat(row.balance)} />
                        ) : (
                          <GlobalCurrency price={0} />
                        )}
                      </span>
                    )}
                    sortable
                    headerStyle={headerCellStyle}
                  />
                  <Column
                    field="reportStatus"
                    header={() => (
                      <div
                        className={`custom-header-${
                          sortField === "reportStatus"
                            ? sortOrder === 1
                              ? "up"
                              : "down"
                            : ""
                        }`}
                        onClick={() => customSort("reportStatus")}
                        style={{
                          cursor: "pointer",
                          display: "flex",
                          alignItems: "center",
                          fontSize: "11px",
                          lineHeight: "11px",
                        }}
                      >
                        {i18n("STATUS")}
                        <CustomeSortIcon
                          sortOrder={
                            sortField === "reportStatus" ? sortOrder : 0
                          }
                          style={{ marginLeft: "4px", verticalAlign: "middle" }}
                        />
                      </div>
                    )}
                    body={(row) => (
                      <Badge
                        bg={
                          row.reportStatus === "Paid"
                            ? "success"
                            : row.reportStatus === "Unpaid"
                            ? "danger"
                            : "warning"
                        }
                      >
                        {row.reportStatus}
                      </Badge>
                    )}
                    sortable
                    headerStyle={headerCellStyle}
                  />
                </DataTable>
              ) : (
                <p>{i18n("Loading")}</p>
              )}
            </div>
          </Container>
        </div>
      </Container>
    </Layout>
  );
};

export default PurchaseSummary;