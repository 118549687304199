import { i18n } from "../../shared/helpers/sharedMethods";
import CreateAddon from "../screens/package_screen/CreateAddon";
import CreatePlan from "../screens/package_screen/CreatePlan";
import CreateSetupFees from "../screens/package_screen/CreateSetupFee";
import EditAddon from "../screens/package_screen/EditAddon";
import EditPlan from "../screens/package_screen/EditPlan";
import EditSetupFees from "../screens/package_screen/EditSetupFee";
import PackageDetails from "../screens/package_screen/PackageDetails";

const rootPath = "subscription";
const featureName = 'subscription';

export const PackageRoutes = [
  {
    path: rootPath + "/packages",
    element: <PackageDetails title={i18n("Packages")} featureName={featureName}/>,
    isProtected: true,
  },
  {
    path: rootPath + "/packages/:id",
    element: <PackageDetails title={i18n("Packages")} />,
    isProtected: true,
  },
  {
    path: rootPath + "/packages/plan/create",
    element: <CreatePlan title={i18n("Packages")} />,
    isProtected: true,
  },
  {
    path: rootPath + "/packages/plan/edit/:id",
    element: <EditPlan title={i18n("Packages")} />,
    isProtected: true,
  },
  {
    path: rootPath + "/packages/addon/create",
    element: <CreateAddon title={i18n("Packages")} />,
    isProtected: true,
  },
  {
    path: rootPath + "/packages/addon/edit/:id",
    element: <EditAddon title={i18n("Packages")} />,
    isProtected: true,
  },
  {
    path: rootPath + "/packages/setup-fees/create",
    element: <CreateSetupFees title={i18n("Packages")} />,
    isProtected: true,
  },
  {
    path: rootPath + "/packages/setup-fees/edit/:id",
    element: <EditSetupFees title={i18n("Packages")} />,
    isProtected: true,
  },
];
