import { Form } from "react-bootstrap";
import React from "react";
import { placeholderText } from "../../../shared/helpers/sharedMethods";

const PurchaseTypeFilter = (props) => {
   
    const {reportType,setReportType} = props;
    return (
        <Form.Select
            className="rounded-0"
            value={reportType}
            onChange={(e) => setReportType(e.target.value)}
            style={{ cursor: "pointer" }}
          >
            <option value="purchase_invoice">{placeholderText("Purchase Invoice")}</option>
            <option value="purchase_return">{placeholderText("Purchase Return")}</option>
            <option value="debit_note">{placeholderText("Debit Note")}</option>
          </Form.Select>
    )

}

export default PurchaseTypeFilter;