import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from "@reduxjs/toolkit";
import axios from "axios";
import { environment } from "../../shared/config/environment";
import { i18n } from "../../shared/helpers/sharedMethods";

const settingAdapter = createEntityAdapter();

const initialState = {
  billing_settings: {
    business_name: "",
    business_address: null,
    state: "",
    city: "",
    pincode: "",
    business_phone: null,
    gstin: "",
    pan: "",
    currency: "",
    language: "",
    logo: null,
    terms_and_conditions: null,
    signature: null,
    upi_payment: null,
    account_number: "",
    ifsc_code: "",
    card_holder_name: "",
    bank: "",
    branch_name: "",
    notes: "",
    default_tax: "",
    is_thermal_print: '',
    place_of_supply: "",
    units: [],
    tax: [],
  },
  bank_details: [],
  fetchSync: [],
  fetchLogs: [],
  fetchInfoLogs: [],
  status: false,
  taxStatus: false,
  settingData: {},
  error: "",
  infoFileList:[],
  errorFileList:[]

};

export const editSetting = createAsyncThunk(
  "setting/editSetting",
  async (data) => {
    ;

    try {
      var config = {
        method: "put",
        url: environment.URL + "update-setting",
        headers: {
          Authorization: `Bearer ${data.accessToken}`,
          "X-Tenant": data.tenant_id,
        },
        data: data,
      };
      const settingData = await axios(config);
      ;
      return settingData.data;
    } catch (error) {
      ;
      await window.log.logToFile({ message: "Error while updating settings", level: "error" });
      throw error?.message;
    }
  }
);

export const invoiceSelection = createAsyncThunk(
  "setting/invoiceSelection",
  async (data) => {
    try {
      ;
      return data;
    } catch (error) {
      await window.log.logToFile({ message: "Error while selecting Invoices", level: "error" });
      throw error;
    }
  }
);

export const updateTaxes = createAsyncThunk(
  "setting/updateTaxes",
  async (data) => {
    ;
    try {
      var config = {
        method: "put",
        url: environment.URL + "update-tax",
        headers: {
          Authorization: `Bearer ${data.accessToken}`,
          "X-Tenant": data.tenant_id,
        },
        data: data.taxData,
      };
      const taxData = await axios(config);
      ;
      return taxData.data;
    } catch (error) {
      ;
      await window.log.logToFile({ message: "Error while updating tax", level: "error" });
      throw error?.message;
    }
  }
);

// export const fetchSyncQueue = createAsyncThunk(
//   "setting/fetchSyncQueue",
//   async () => {
//     try {
//       const syncResponse = await window.api.getSyncQueue();
//       ;
//       if (syncResponse.success) {
//         return syncResponse.data;
//       } else {
//         throw new Error(syncResponse.message);
//       }
//     } catch (error) {
//       await window.log.logToFile({ message: "Error while fetching sync queue", level: "error" });
//       throw error;
//     }
//   }
// );

// export const fetchLogAction = createAsyncThunk(
//   "setting/fetchLogs",
//   async (currentDate) => {
//     console.log("log type", currentDate)
//     try {
//       const logResponse = await window.log.readLogFile(currentDate);
//       ;
//       if (logResponse.success) {
//         return logResponse.data;
//       } else {
//         throw new Error(logResponse.message);
//       }
//     } catch (error) {
//       await window.log.logToFile({ message: "Error while fetching Logs", level: "error" });
//       throw error;
//     }
//   }
// );

// export const fetchInfoLogAction = createAsyncThunk(
//   "setting/infoFetchLogs",
//   async (currentDate) => {
//     console.log("log type", currentDate)
//     try {
//       const logResponse = await window.log.infoLogFile(currentDate);
//       ;
//       if (logResponse.success) {
//         return logResponse.data;
//       } else {
//         throw new Error(logResponse.message);
//       }
//     } catch (error) {
//       await window.log.logToFile({ message: "Error while fetching Logs", level: "error" });
//       throw error;
//     }
//   }
// );

// export const fetchInfoFileList = createAsyncThunk(
//   "setting/infoFileList",
//   async (currentDate) => {
//     console.log("log type", currentDate)
//     try {
//       const logResponse = await window.log.infoFileList(currentDate);
//       ;
//       if (logResponse.success) {
//         return logResponse.data;
//       } else {
//         throw new Error(logResponse.message);
//       }
//     } catch (error) {
//       await window.log.logToFile({ message: "Error while fetching Logs", level: "error" });
//       throw error;
//     }
//   }
// );

// export const fetchErrorFileList = createAsyncThunk(
//   "setting/errorFileList",
//   async (currentDate) => {
//     console.log("log type", currentDate)
//     try {
//       const logResponse = await window.log.errorFileList(currentDate);
//       ;
//       if (logResponse.success) {
//         return logResponse.data;
//       } else {
//         throw new Error(logResponse.message);
//       }
//     } catch (error) {
//       await window.log.logToFile({ message: "Error while fetching Logs", level: "error" });
//       throw error;
//     }
//   }
// );


//api ther

const settingSlice = createSlice({
  name: "setting",
  initialState,
  reducers: {
    changeName(state, action) {
      ;
      state.billing_settings.business_name = action.payload;
    },
    changeAddress(state, action) {
      state.billing_settings.business_address = action.payload;
    },
    changePhone(state, action) {
      state.billing_settings.business_phone = action.payload;
    },
    changeGst(state, action) {
      state.billing_settings.gstin = action.payload;
    },
    changePan(state, action) {
      state.billing_settings.pan = action.payload;
    },
    setCurrency: (state, action) => {
      state.billing_settings.currency = action.payload;
    },
    setLogo: (state, action) => {
      ;
      state.billing_settings.logo = action.payload;
    },
    setTermsAndConditions: (state, action) => {
      state.billing_settings.terms_and_conditions = action.payload;
    },
    setLanguage: (state, action) => {
      ;
      state.billing_settings.language = action.payload;
    },
    setSignature: (state, action) => {
      ;
      state.billing_settings.signature = action.payload;
    },
    setUnits(state, action) {
      ;
      state.billing_settings.units = action.payload;
    },
    setUPIPayment: (state, action) => {
      state.billing_settings.upi_payment = action.payload;
    },
    initSettingEdit(state, action) {
      ;
      state.billing_settings = action.payload;
    },
    changeState(state, action) {
      state.billing_settings.state = action.payload;
    },
    changeCity(state, action) {
      state.billing_settings.city = action.payload;
    },
    changePincode(state, action) {
      state.billing_settings.pincode = action.payload;
    },
    setAccountNumber: (state, action) => {
      state.billing_settings.account_number = action.payload;
    },
    setIFSCCode: (state, action) => {
      state.billing_settings.ifsc_code = action.payload;
    },
    setCardHolderName: (state, action) => {
      state.billing_settings.card_holder_name = action.payload;
    },
    setBankName: (state, action) => {
      state.billing_settings.bank = action.payload;
    },
    setBranchName: (state, action) => {
      state.billing_settings.branch_name = action.payload;
    },
    setNotes: (state, action) => {
      state.billing_settings.notes = action.payload;
    },
    setTax(state, action) {
      ;
      state.billing_settings.tax = action.payload;
    },
    setDefaultTax(state, action) {
      state.billing_settings.default_tax = action.payload;
    },
    setPlaceOfSupply(state, action) {
      state.billing_settings.place_of_supply = action.payload;
    },
    resetStatus: (state, action) => {
      state.status = false;
    },
    resetError: (state, action) => {
      state.error = "";
    },
    resetTaxStatus: (state, action) => {
      state.taxStatus = false;
    },
    IsThermalPrintEnabled: (state, action) => {
      state.billing_settings.is_thermal_print = action.payload;
    },
    setFirstCardColor: (state, action) => {
      state.billing_settings.color1 = action.payload;
    },
    setSecondCardColor: (state, action) => {
      state.billing_settings.color2 = action.payload;
    },
    setTextColor: (state, action) => {
      state.billing_settings.textColor = action.payload;
    },
    resetEditSetting: () => initialState,
  },
  extraReducers(builder) {
    builder

      .addCase(editSetting.fulfilled, (state, action) => {
        state.status = true;
        ;
        if (action.payload.length > 0) {
          const settingValue = action.payload.reduce((acc, item) => {
            acc[item.key] = item.value;
            return acc;
          }, {});


          state.billing_settings = settingValue;

        }
      })
      .addCase(editSetting.rejected, (state, action) => {
        ;
        state.status = false;
        state.error = i18n(
          "Unable to update settings. Please try again later."
        );
      })

      .addCase(invoiceSelection.fulfilled, (state, action) => {
        //state.status = true;
        state.invoicesType = action.payload;
      })

      // .addCase(fetchSyncQueue.fulfilled, (state, action) => {
      //   state.fetchSync = action.payload;
      // })
      // .addCase(fetchSyncQueue.rejected, (state, action) => {
      //   state.error = i18n(
      //     "Error while fetching sync queue. Please try again later."
      //   );
      // })
      // .addCase(fetchLogAction.fulfilled, (state, action) => {
      //   state.fetchLogs = action.payload;
      // })
      // .addCase(fetchLogAction.rejected, (state, action) => {
      //   state.error = i18n(
      //     "Error while fetching sync queue. Please try again later."
      //   );
      //   state.fetchLogs = []
      // })
      // .addCase(fetchInfoLogAction.fulfilled, (state, action) => {
      //   state.fetchInfoLogs = action.payload;
      // })
      // .addCase(fetchInfoLogAction.rejected, (state, action) => {
      //   state.error = i18n(
      //     "Error while fetching sync queue. Please try again later."
      //   );
      //   state.fetchInfoLogs = [];
      // })
      // .addCase(fetchInfoFileList.fulfilled, (state, action) => {
      //   state.infoFileList = action.payload;
      // })
      // .addCase(fetchInfoFileList.rejected, (state, action) => {
      //   state.error = i18n(
      //     "Error while fetching sync queue. Please try again later."
      //   );
      //   state.infoFileList = [];
      // })
      // .addCase(fetchInfoFileList.fulfilled, (state, action) => {
      //   state.infoFileList = action.payload;
      // })
      // .addCase(fetchInfoFileList.rejected, (state, action) => {
      //   state.error = i18n(
      //     "Error while fetching sync queue. Please try again later."
      //   );
      //   state.infoFileList = [];
      // })
      .addCase(updateTaxes.fulfilled, (state) => {
        state.taxStatus = true;
      })
      .addCase(updateTaxes.rejected, (state, action) => {
        state.error = action.error.message;
        state.taxStatus = false;
      });

  },
});
export const {
  setCurrency,
  changeName,
  changeAddress,
  changePhone,
  changeGst,
  setLogo,
  setTermsAndConditions,
  setLanguage,
  setSignature,
  setUPIPayment,
  initSettingEdit,
  resetStatus,
  resetEditSetting,
  setUnits,
  changePan,
  changeCity,
  changePincode,
  changeState,
  setAccountNumber,
  setIFSCCode,
  setCardHolderName,
  setBankName,
  setBranchName,
  setNotes,
  setTax,
  setDefaultTax,
  setPlaceOfSupply,
  resetError,
  resetTaxStatus,
  IsThermalPrintEnabled,
  setFirstCardColor,
  setSecondCardColor,
  setTextColor
} = settingSlice.actions;

export const selectSelectedImage = (state) =>
  state.setting.billing_settings.logo;
export default settingSlice.reducer;

export const {
  selectAll: selectAllSetting,
  selectById: selectSettingById,
  selectIds: selectSettingIds,
} = settingAdapter.getSelectors((state) => state.setting);
