import React, { useEffect, useState } from "react";
import { Button, Card, Col, Container, Row, Spinner, Stack } from "react-bootstrap";
import {
  addNewProduct,
  planData,
  selectPlanDataById,
} from "../../store/subscription_item_store/GetPackagesItemSlice";
import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import {
  AddItem,
  resetPackage,
} from "../../store/subscription_item_store/CreatePackageSlice";
import { i18n } from "../../../shared/helpers/sharedMethods";
import Layout from "../../../shared/components/Layout";
import PackageLayout from "../../components/PackageLayout/PackageLayout";
import PackageHeader from "../../components/PackageHeader";
import AddonName from "../../components/packages_fields/addon_fields/AddonName";
import AddonCode from "../../components/packages_fields/addon_fields/AddonCode";
import AddonDescription from "../../components/packages_fields/addon_fields/AddonDescription";
import AddonPrice from "../../components/packages_fields/addon_fields/AddonPrice";
import AddonUnits from "../../components/packages_fields/addon_fields/AddonUnits";
import AddonTaxCode from "../../components/packages_fields/addon_fields/AddonTaxCode";
import AddonIntervalType from "../../components/packages_fields/addon_fields/AddonIntervalType";
import AddonInterval from "../../components/packages_fields/addon_fields/AddonInterval";
import AddonType from "../../components/packages_fields/addon_fields/AddonType";
import Header from "../../../shared/components/Header";
import { useLocation, useNavigate } from "react-router";
import { resetAddon } from "../../store/subscription_item_store/AddonEntitySlice";
import ShortcutKeys from "../../../shared/components/ShortcutKeys";
import { capitalizeMethod } from "../../../shared/helpers/captilize";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const CreateAddon = ({ setAddonShow, currentItemId }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { id } = location?.state;
  //;
  const [loading, setLoading] = useState(false);
  const { accessToken, isLoggedIn } = useSelector((state) => state?.auth);

  const status = useSelector((state) => state.createPackage.status);
  const savedEntity = useSelector((state) => state.createPackage.savedEntity);
  const addon = useSelector((state) => state.addon);
  const selectedBusiness = useSelector(
    (state) => state?.auth?.selectedBusiness
  );

  const tenantId = selectedBusiness?.pivot?.tenant_id;
  const parentProduct = useSelector((state) =>
    selectPlanDataById(state, id)
  );


  useEffect(() => {
    if (status && savedEntity) {
      toast.success("Addon created successfully")
      dispatch(addNewProduct(savedEntity));
      dispatch(
        planData({ token: accessToken.access_token, tenant_id: tenantId })
      );
      dispatch(resetAddon());
      navigate("/subscription/packages/" + id, {
        state: { success: true },
      });
    }
  }, [status, savedEntity]);

  return (
    <>
      <ToastContainer />
      <Formik
        initialValues={addon}
        enableReinitialize={true}
        validationSchema={Yup.object().shape({
          name: Yup.string().required(i18n("Addon name is required")),
          code: Yup.string().required(i18n("Addon Code is required")),
          sale_price: Yup.number().required(i18n("Addon price is required")),
        })}
        onSubmit={async (values) => {
          try {
            ;
            setLoading(true);
            let formData = values;
            formData = { ...formData, parent_product: id };
            formData.addon = {
              type: formData.addOnType,
              interval_unit: formData.interval_unit,
            };
            delete formData.addOnType;
            delete formData.interval_unit;

            ;
            dispatch(
              AddItem({
                data: formData,
                token: accessToken.access_token,
                tenant_id: tenantId,
              })
            );
          } catch (error) {
            setLoading(false);
          }
        }}
      >
        {({
          values,
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue,
          touched,
        }) => (
          <Layout>
            <Header
              currentTitle={i18n("Manage Packages")}
              ItemActions={
                <Stack
                  gap={3}
                  direction="horizontal"
                  className="my-2 justify-content-end"
                >
                  <ShortcutKeys />
                </Stack>
              }
            />
            <PackageLayout currentItemId={id}>
              <PackageHeader
                currentTitle={parentProduct ? (
                  <nav style={{ '--bs-breadcrumb-divider': "'>'", fontWeight: "bold", fontSize: "15px", height: "2vh" }} aria-label="breadcrumb">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item" style={{ color: '#4169E1', fontSize: "15px" }}>{capitalizeMethod(parentProduct?.name)}</li>
                      <li className="breadcrumb-item active" aria-current="page" style={{ color: '#36454F', fontSize: "15px" }}>{i18n("Create Addon")}</li>
                    </ol>
                  </nav>
                ) : i18n("Create Addon")}
                ItemActions={
                  <Stack
                    gap={3}
                    direction="horizontal"
                    className="mt-2 mb-2 float-end"
                  >
                    <div>
                      {!loading ? (
                        <Button
                          id="submitButton"
                          variant="primary"
                          onClick={handleSubmit}
                          disabled={loading}
                        >
                          {i18n("Save")}
                        </Button>
                      ) : (
                        <div className="d-flex align-items-center mx-8">
                          <Spinner animation="border" size="md" />
                        </div>
                      )}
                    </div>
                    <div>
                      <Button
                        className=""
                        variant="outline-secondary"
                        onClick={() => navigate("/subscription/packages/" + id, {
                          state: { success: true },
                        })}
                      >
                        {i18n("Cancel")}
                      </Button>
                    </div>
                  </Stack>
                }
              />
              <Container
                fluid
                className="px-5 "
                style={{
                  height: "calc(100vh - 150px",
                  overflow: "auto",
                  overflowX: "hidden",
                }}
              >
                <Row>
                  <Col lg={12}>
                    <AddonName
                      values={values}
                      handleChange={handleChange}
                      errors={errors}
                      touched={touched}
                    />
                  </Col>
                  <Col lg={12}>
                    <AddonCode
                      values={values}
                      handleChange={handleChange}
                      errors={errors}
                      touched={touched}
                    />
                  </Col>
                  <Col lg={12}>
                    <AddonDescription
                      values={values}
                      handleChange={handleChange}
                      errors={errors}
                      touched={touched}
                    />
                  </Col>
                  <Col lg={12}>
                    <AddonPrice
                      values={values}
                      handleChange={handleChange}
                      errors={errors}
                      touched={touched}
                    />
                  </Col>
                  <Col lg={12}>
                    <AddonUnits
                      values={values}
                      handleChange={handleChange}
                      errors={errors}
                      touched={touched}
                      setFieldValue={setFieldValue}
                    />
                  </Col>
                  <Col lg={12}>
                    <AddonTaxCode
                      values={values}
                      handleChange={handleChange}
                      errors={errors}
                      touched={touched}
                      setFieldValue={setFieldValue}
                    />
                  </Col>
                  <Col lg={12}>
                    <AddonIntervalType
                      values={values}
                      handleChange={handleChange}
                      errors={errors}
                      touched={touched}
                    />
                  </Col>
                  {values?.addOnType == "recurring" && (
                    <Col lg={12}>
                      <AddonInterval
                        values={values}
                        handleChange={handleChange}
                        errors={errors}
                        touched={touched}
                        setFieldValue={setFieldValue}
                      />
                    </Col>
                  )}
                  <Col lg={12}>
                    <AddonType
                      values={values}
                      handleChange={handleChange}
                      errors={errors}
                      touched={touched}
                    />
                  </Col>
                </Row>
              </Container>
            </PackageLayout>
          </Layout>
        )}
      </Formik>
    </>
  );
};

export default CreateAddon;
