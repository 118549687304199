import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Layout from "../../shared/components/Layout";
import { useNavigate, useParams } from "react-router";
import ItemDetailLayout from "../../shared/components/item-detail/ItemDetailLayout";
import {
  Button,
  Container,
  Form,
  Modal,
  ModalBody,
  Stack,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DeleteModel from "../../shared/components/DeleteModel";
import { faPenToSquare, faTrash } from "@fortawesome/free-solid-svg-icons";
import {
  selectAllparty,
  selectPartyByType,
  selectpartyById,
} from "../store/getPartySlice";
import { deleteParty } from "../store/getPartySlice";
import ProfileInfo from "../components/party_view/ProfileInfo";
import Transactions from "../components/party_view/tabs/Transaction";
import ItemwiseReport from "../components/party_view/tabs/ItemwiseReport";
import Ledger from "../components/party_view/tabs/Ledger";
import { itemReportData } from "../store/itemReportSlice";
import PartyItemStack from "../../shared/components/item-detail/sections/PartyItemStack";
import { fetchPartyLedger } from "../store/PartyLedgerSlice";
import { resetCreateParty } from "../store/PartyEntitySlice";
import TwoStageLayoutHeader from "../../shared/components/TwoStatgeLayoutHeader";
import PartyHighlights from "../components/party_view/PartyHighlights";
import { i18n } from "../../shared/helpers/sharedMethods";
import { fetchPartyDetails } from "../store/PartyDetails";
import EditParty from "./EditParty";
import CreateParty from "./CreateParty";

const PartyDetail = (props) => {
  const { type, path, showDetails } = props;
  const dispatch = useDispatch();
  let { partyId } = useParams();
  useEffect(() => {
    dispatch(fetchPartyDetails());
  }, []);
  const [show, setShow] = useState(false);
  const [deleteId, setDeleteId] = useState();
  const [name, setName] = useState();
  const [showEditParty, setShowEditParty] = useState(false);
  const [editId, setEditId] = useState();
  const [showPartyCreate, setShowPartyCreate] = useState(false);
  const allPartys = useSelector(selectAllparty);
  const selectedParty = useSelector((state) => selectpartyById(state, partyId));
  const accessToken = useSelector((state) => state?.auth?.accessToken?.access_token);
  const selectedBusiness = useSelector((state) => state?.auth?.selectedBusiness);
  const tenantId = selectedBusiness?.pivot?.tenant_id;
  const allParty = useSelector((state) => selectPartyByType(state, type));
  const filteredData = allPartys.filter((item) => item !== undefined);
  const itemwiseReport = useSelector((state) => state.itemWiseReport.data);

  const navigate = useNavigate();

  const data = {
    email: selectedParty.email,
    type: selectedParty.type,
    gst: selectedParty.gstin,
    ph_no: selectedParty.phone_number,
    credit_limit: selectedParty.credit_limit,
  };

  const passId = selectedParty.id;

  useEffect(() => {
    dispatch(itemReportData(partyId));
    dispatch(fetchPartyLedger({id:partyId,token:accessToken,tenant_id:tenantId}));
  }, [passId]);

  const handleCreate = () => {
    dispatch(resetCreateParty());
    setShowPartyCreate(true);
  };

  const handleDelete = async (data) => {
    setShow(!show);
    setDeleteId(data.id);
    setName(data.name);
  };
  const handleEdit = (row) => {
    console.log('triged');
    dispatch(resetCreateParty());
    setShowEditParty(true);
    setEditId(row.id);
  };

  const onDelete = () => {
    dispatch(deleteParty(deleteId));
    setShow(false);
    navigate(`/${path}`);
  };

  const handlePartyClick = (selectedId) => {
    navigate(`/${path}/view/` + selectedId);
  };

  const handleReturnToGrid = () => {
    navigate(`/${path}`);
  };

  const productTabs = [
    {
      key: "profile",
      title: i18n("Details"),
      component: <ProfileInfo selectedParty={selectedParty} />,
    },
    {
      key: "transaction",
      title: i18n("Transactions"),
      component: <Transactions partyId={partyId} />,
    },
    {
      key: "ledger",
      title: i18n("Ledger(Statement)"),
      component: <Ledger partyId={partyId} />,
    },
    {
      key: "itemwise-report",
      title: i18n("Itemwise Report"),
      component: <ItemwiseReport partyId={partyId} />,
    },
  ];

  const partyStack = (
    <Stack>
      <PartyItemStack
        items={allParty}
        handleItemClick={handlePartyClick}
        currentItemId={partyId}
        handleCreate={handleCreate}
        returnToGrid={handleReturnToGrid}
      />
    </Stack>
  );

  // const partySummary = (
  //   <Stack>
  //     <PartyInfo
  //       ItemTitle={selectedParty.name}
  //       data={data}
  //       type={type}
  //       currentItemId={partyId}
  //     />
  //   </Stack>
  // );

  const partySummary = (
    <>
      <PartyHighlights selectedParty={selectedParty} currentItemId={partyId} />
    </>
  );

  const header = (
    <TwoStageLayoutHeader
      ItemActions={
        <Stack gap={3} direction="horizontal" className="mt-2 mb-2 float-end">
          <Button
            className=""
            variant="outline-danger"
            onClick={() => handleDelete(selectedParty)}
          >
            <FontAwesomeIcon className="mx-2 mb-1" icon={faTrash} />
            {i18n("Delete")}
          </Button>

          <Button
            className=""
            variant="outline-secondary"
            onClick={() => handleEdit(selectedParty)}
          >
            <FontAwesomeIcon className="mx-2 mb-1" icon={faPenToSquare} />
            {i18n("Edit")}
          </Button>
        </Stack>
      }
      currentTitle={
        <>
          {selectedParty.name}
          {data.type == "customer" ? (
            <Form.Label className="mx-2 badge bg-primary bg-gradient">
              Customer
            </Form.Label>
          ) : (
            <Form.Label className="mx-2 badge bg-info bg-gradient">
              Supplier
            </Form.Label>
          )}
        </>
      }
      returnToGrid={handleReturnToGrid}
    />
  );

  return (
    <Layout>
      <Modal className="modal-xl" showDetails={showDetails} keyboard={true}>
        <Modal.Header closeButton>
          <Modal.Title>Party Details</Modal.Title>
        </Modal.Header>
        <ModalBody>
          <Container fluid>
            <ItemDetailLayout
              type={type}
              tabs={productTabs}
              summary={partySummary}
              handleItemClick={handlePartyClick}
              currentItemId={partyId}
              data={data}
              ItemTitle="Parties"
              header={header}
            />

            {show && (
              <DeleteModel
                deleteUserClick={onDelete}
                onClickDeleteModel={setShow}
                name={name}
              />
            )}
            {showEditParty && (
              <EditParty
                show={showEditParty}
                setShowEditParty={setShowEditParty}
                partyId={editId}
              />
            )}

            {showPartyCreate && (
              <CreateParty
                show={showPartyCreate}
                setShowPartyCreate={setShowPartyCreate}
              />
            )}
          </Container>
        </ModalBody>
      </Modal>
    </Layout>
  );
};

export default PartyDetail;
