import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from '@reduxjs/toolkit';
import { i18n } from '../../shared/helpers/sharedMethods';
import { axiosRequest } from "../../shared/helpers/axiosHelper";


const itemReportAdapter = createEntityAdapter({

});


const initialState = itemReportAdapter.getInitialState({
  status: false,
  data: [],
  error: false,
  errorMessage: ''

});

export const itemReportData = createAsyncThunk('itemWiseReport/fetchitemReport', async (args) => {
  try {
    const token = args.token;
    const tenantId = args.tenant_id;
    const id = args.id;
    const response = await axiosRequest({
      method: "GET",
      url: `get-party-item-wise-report`,
      params: {
        party_id: id,
      },
      headers: {
        Authorization: `Bearer ${token}`,
        "X-Tenant": tenantId,
      },
    });
    return response?.data
  } catch (err) {
    await window.log.logToFile({ message: "Error while fetching Item Report", level: "error" });
    throw new Error("Error while fetching Item Wise report")
  }
})

const itemReportSlice = createSlice({
  name: 'itemWiseReport',
  initialState,
  reducers: {
  },
  extraReducers(builder) {
    builder
      .addCase(itemReportData.fulfilled, (state, action) => {
        state.status = true
        state.data = action.payload
      })
      .addCase(itemReportData.rejected, (state, action) => {
        state.error = true;
        state.errorMessage = (i18n("Unable to retrieve the item-wise report. Please try again later."));
        state.status = false;
      })
  }
});

export default itemReportSlice.reducer;


