import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Stack, Form, Button, InputGroup } from "react-bootstrap";
import { i18n, placeholderText } from "../../shared/helpers/sharedMethods";
import {
  toggleEditPartyModal,
  updateBillingAddressField,
} from "../store/voucherEntitySlice";
import { placeOfSupplyList } from "../../shared/config/placeOfSupply";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquareXmark } from "@fortawesome/free-solid-svg-icons";
import { find } from "lodash";
import { Menu, MenuItem, Typeahead } from "react-bootstrap-typeahead";

const BillingAddressBlock = () => {
  const dispatch = useDispatch();
  const billingAddress = useSelector(
    (state) => state.voucherEntity.addresses.billing
  );
  const partyAddresses = useSelector(
    (state) => state.voucherEntity.party?.party_addresses || []
  );
 
  const typeaheadRef = useRef(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedItem, setSelectedItem] = useState(null);
  const [showSearch, setShowSearch] = useState(false);

  const handleOnSearch = (string) => {
    setSearchTerm(string);
    setSelectedItem(null);
  };

  const handleOnSelect = (selected) => {
    const item = selected[0];
    if (item) {
      dispatch(updateBillingAddressField(item));
      const formattedAddress = `${item.name || ""}, ${
        item.address || ""
      }, ${item.city || ""}, ${
        item.state
          ? find(placeOfSupplyList, { value: item.state })?.label + "-"
          : ""
      } ${item.pincode || ""}`;
      setSearchTerm(formattedAddress);
      setSelectedItem(item);
      setShowSearch(false);
    }
  };

  const handleChangeButtonClick = () => {
    setSelectedItem(null);
    setSearchTerm("");
    setShowSearch(true);
  };

  const handleClearSelection = () => {
    setSelectedItem(null);
    setSearchTerm("");
    setShowSearch(true);
  };

  const handleEditButtonClick = () => {
    dispatch(
      toggleEditPartyModal({
        toggle: true,
        activeEditPartyTab: "address",
      })
    );
  };

  return (
    <Stack direction="vertical" className="px-1 py-1">
    <Form.Label className="d-flex align-items-center justify-content-between mb-0">
      <span  style={{ color: "#4682B4", fontSize: "12px" , fontWeight:500}}>
        {i18n("BILL TO")}
      </span>
      <div className="d-flex">
        {billingAddress?.address && (
          <Button
            variant="link"
            size="sm"
            className="pb-0 ms-3"
            onClick={handleChangeButtonClick}
          >
            {i18n("Clear")}
          </Button>
        )}
        <Button
          variant="link"
          size="sm"
          className="pb-0 ms-3"
          onClick={handleEditButtonClick}
        >
          {i18n("Edit")}
        </Button>
      </div>
    </Form.Label>
  
    <Form.Group>
      {billingAddress?.address ? (
        showSearch ? (
          <Stack direction="vertical" className="ms-auto mt-2">
            <InputGroup>
              <Typeahead
                ref={typeaheadRef}
                allowNew
                id="address-typeahead"
                options={partyAddresses}
                labelKey="name"
                placeholder={placeholderText("Search for address...")}
                onInputChange={handleOnSearch}
                onChange={handleOnSelect}
                selected={selectedItem ? [selectedItem] : []}
                filterBy={["name", "address", "city", "state"]}
                size="md"
                renderMenu={(results, menuProps) => (
                  <Menu {...menuProps} className="">
                    {results.length > 0 ? (
                      results.map((result, index) => (
                        <MenuItem key={index} option={result} position={index}>
                          <div>
                            <p style={{ fontWeight: "bold", margin: 0 }}>
                              {result.name || ""}
                            </p>
                            <p style={{ margin: 0 }}>{result.phone_number || ""}</p>
                            <p style={{ margin: 0 }}>
                              {result.address || ""}
                              {result.city ? `, ${result.city}` : ""}
                              {result.state
                                ? `, ${
                                    find(placeOfSupplyList, { value: result.state })?.label || ""
                                  }`
                                : ""}
                              {result.pincode ? ` - ${result.pincode}` : ""}
                            </p>
                          </div>
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem disabled>No results found</MenuItem>
                    )}
                  </Menu>
                )}
              />
              {selectedItem && (
                <InputGroup.Text
                  className="cursor-pointer fs-2"
                  style={{ background: "none" }}
                  onClick={handleClearSelection}
                >
                  <FontAwesomeIcon
                    icon={faSquareXmark}
                    title="Clear"
                    className="text-danger"
                  />
                </InputGroup.Text>
              )}
            </InputGroup>
          </Stack>
        ) : (
          <Stack direction="vertical" className="ms-auto">
            <p className="m-0" style={{ fontWeight:600, fontSize: "12px" }}>
              {billingAddress?.name || ""}
            </p>
            <p className="m-0" style={{ fontSize: "12px", color: "#6c757d" }}>
              {billingAddress?.address ? `${billingAddress.address}, ` : ""}
              {billingAddress?.city ? `${billingAddress.city}, ` : ""}
              {billingAddress?.state
                ? `${find(placeOfSupplyList, { value: billingAddress.state })?.label || ""} - `
                : ""}
              {billingAddress?.pincode || ""}
            </p>
            {billingAddress?.phone_number && (
              <p className="m-0" style={{ fontSize: "12px", color: "#6c757d" }}>
                <span className="text-black-50 bold">{i18n("Phone:")} </span>
                {billingAddress?.phone_number}
              </p>
            )}
          </Stack>
        )
      ) : partyAddresses && partyAddresses.length === 0 || partyAddresses == undefined ? (
        <div
          className="d-flex justify-content-center py-1"
          style={{ minHeight: "50px", border: "1px dashed #ccc", paddingTop: "8px" }}
        >
          <Button
            variant="link"
            size="sm"
            onClick={() => {
              dispatch(
                toggleEditPartyModal({
                  toggle: true,
                  activeEditPartyTab: "address",
                })
              );
            }}
          >
            +{i18n("Add Address")}
          </Button>
        </div>
      ) : (
        <Stack direction="vertical" className="ms-auto mt-2">
          <InputGroup>
            <Typeahead
              id="address-typeahead"
              options={partyAddresses}
              labelKey="name"
              placeholder={placeholderText("Search for address...")}
              onInputChange={handleOnSearch}
              onChange={handleOnSelect}
              selected={selectedItem ? [selectedItem] : []}
              filterBy={["name", "address", "city", "state"]}
              size="md"
              renderMenu={(results, menuProps) => (
                <Menu {...menuProps} className="">
                  {results.length > 0 ? (
                    results.map((result, index) => (
                      <MenuItem key={index} option={result} position={index}>
                        <div>
                          <p style={{ fontWeight: "bold", margin: 0 }}>{result.name || ""}</p>
                          <p style={{ margin: 0 }}>{result.phone_number || ""}</p>
                          <p style={{ margin: 0 }}>
                            {result.address || ""}
                            {result.city ? `, ${result.city}` : ""}
                            {result.state
                              ? `, ${
                                  find(placeOfSupplyList, { value: result.state })?.label || ""
                                }`
                              : ""}
                            {result.pincode ? ` - ${result.pincode}` : ""}
                          </p>
                        </div>
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem disabled>No results found</MenuItem>
                  )}
                </Menu>
              )}
            />
          </InputGroup>
        </Stack>
      )}
    </Form.Group>
  </Stack>
  
  );
};

export default BillingAddressBlock;
