import React from "react";
import { Col, Form, Image, InputGroup, Modal, Row } from "react-bootstrap";
import {
  aboutDetails,
  keyboardShortcut,
  updateKeyboard,
} from "../store/sharedSlice";
import { useDispatch, useSelector } from "react-redux";
import bill from "../../../assets/images/bill.png";

const AboutModal = ({ show,setIsAbout }) => {
  const dispatch = useDispatch();
  

  const { isVersion } = useSelector((state) => state.sharedSlice);
  ;

  const clearField = () => {
    const data = {
      isAbout: false,
      version: isVersion,
    };
    dispatch(aboutDetails(data));
  };

  return (
    <>
      <Modal
        className="modal-md"
        show={show}
        onHide={clearField}
        keyboard={true}
        dialogClassName="modal-dialog modal-dialog-centered"
      >
        <Modal.Header closeButton>
          <Modal.Title style={{ fontSize: "14px", fontWeight: 600 }}>
            About
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="">
          <Row className="">
            <Col lg={12} className="d-flex justify-content-center">
              <Image src={bill} height={50} width={100} />
            </Col>
            <Col lg={12} className="mt-2">
              <Form.Group style={{ textAlign: "center" }}>
                <Form.Label>Version {isVersion}</Form.Label>
              </Form.Group>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AboutModal;
