import { InputGroup, Form, Row, Col, Card } from "react-bootstrap";
import { i18n, placeholderText } from "../../../shared/helpers/sharedMethods";
import { taxList } from "../../../shared/config/taxConfig";
import { filterTaxList } from "../../../settings/components/helpers/settingHelpers";
import { useDispatch, useSelector } from "react-redux";
import {
  changeCostPrice,
  changeHsnCode,
  changeIsTaxInclude,
  changeIsTaxIncludePurchase,
  changeSalePrice,
  changeTaxCode,
} from "../../store/productEntitySlice";
import { useEffect } from "react";

const PriceDetailsForm = (props) => {
  const {
    handleChange,
    values,
    errors,
    currencySymbol,
    touched,
    numValidate,
    handleBlur,
  } = props;

  const dispatch = useDispatch();

  const getSetting = useSelector((state) => state.getSetting?.dataSetting);
  const getTax = JSON.parse(getSetting?.tax || "[]");
  const TaxList = filterTaxList(taxList, getTax);

  const defaultTax = getSetting?.default_tax;
  ;


  useEffect(() => {
    dispatch(changeTaxCode(defaultTax));
  },[])

  return (
    <Card className="px-4">
      <Row className="py-2" lg={12}>
        <Col lg={6}>
          <Form.Group>
            <Form.Label>{i18n("Sale Price")}</Form.Label>
            <span className="required" />
          </Form.Group>
          <InputGroup>
            <InputGroup.Text>{currencySymbol}</InputGroup.Text>
            <Form.Control
              autoFocus
              type="number"
              name="sale_price"
              isInvalid={touched.sale_price && errors.sale_price}
              value={values.sale_price|| ''}
              onBlur={handleBlur}
              min={0}
              onChange={(e) => {
                handleChange(e);
                dispatch(changeSalePrice(e.target.value));
              }}
              onKeyPress={(event) => numValidate(event)}
              placeholder={placeholderText("Enter Price")}
            />
            <Form.Select
              value={values.is_tax_included}
              aria-label="is_tax_included"
              name="is_tax_included"
              onChange={(e) => {
                handleChange(e);
                dispatch(changeIsTaxInclude(e.target.value));
              }}
            >
              <option value={1}>{placeholderText("With Tax")}</option>
              <option value={0}>{placeholderText("Without Tax")}</option>
            </Form.Select>
          </InputGroup>
          <Form.Text className="text-danger d-block fw-400 fs-small mt-2">
            {touched.sale_price && errors.sale_price}
          </Form.Text>
        </Col>

        <Col lg={6}>
          <Form.Group>
            <Form.Label>{i18n("Purchase Price")}</Form.Label>
            <span className="required" />
          </Form.Group>
          <InputGroup>
            <InputGroup.Text>{currencySymbol}</InputGroup.Text>
            <Form.Control
              type="number"
              name="cost_price"
              isInvalid={touched.cost_price && errors.cost_price}
              onBlur={handleBlur}
              value={values.cost_price|| ''}
              min={0}
              onChange={(e) => {
                handleChange(e);
                dispatch(changeCostPrice(e.target.value));
              }}
              onKeyPress={(event) => numValidate(event)}
              placeholder={placeholderText("Enter Price")}
            />
            <Form.Select
              value={values?.is_tax_included_for_purchase|| ''}
              aria-label="is_tax_included_for_purchase"
              name="is_tax_included_for_purchase"
              onChange={(e) => {
                handleChange(e);
                dispatch(changeIsTaxIncludePurchase(e.target.value));
              }}
            >
              <option value={1}>{placeholderText("With Tax")}</option>
              <option value={0}>{placeholderText("Without Tax")}</option>
            </Form.Select>
          </InputGroup>
          <Form.Text className="text-danger d-block fw-400 fs-small mt-2">
            {touched.cost_price && errors.cost_price}
          </Form.Text>
        </Col>
      </Row>

      <Row className="py-2">
        <Col lg={6}>
          <Form.Group>
            <Form.Label>{i18n("HSN Code")}</Form.Label>
          </Form.Group>
          <Form.Control
            type="number"
            name="hsn"
            value={values.hsn|| ''}
            min={0}
            onChange={(e) => {
              handleChange(e);
              dispatch(changeHsnCode(e.target.value));
            }}
            onKeyPress={(event) => numValidate(event)}
            placeholder={placeholderText("Enter HSN Code")}
          />
        </Col>
        <Col lg={6}>
          <Form.Group>
            <Form.Label>{i18n("GST Tax Rate")}</Form.Label>
            <Form.Select
              aria-label="Payment-mode"
              name="tax_code"
              value={values.tax_code || defaultTax}
              onChange={(e) => {
                handleChange(e);
                dispatch(changeTaxCode(e.target.value));

              }}
            >
              {TaxList && TaxList.length ? (
                TaxList.map((tax) => (
                  <option key={tax.code} value={tax.code}>
                    {tax.label}
                  </option>
                ))
              ) : (
                <option value="">{placeholderText("No Tax options")}</option>
              )}
            </Form.Select>
          </Form.Group>
        </Col>
      </Row>
    </Card>
  );
};

export default PriceDetailsForm;
