import {
  createSlice,
  createAsyncThunk,
} from "@reduxjs/toolkit";


const initialState = {
  status: false,
  reportItems: [],
  error: false,
  errorMessage: ''
};

export const fetchPartyDetails = createAsyncThunk(
  "partyDeatils/fetch",
  async (param) => {
    try {
      const response = await window.api.getPartyDetails(param);
      return response.data;
    } catch (err) {
      await window.log.logToFile({ message: "Error while fetching Party details", level: "error" });
      throw new Error("Error while fetching party Details report")
    }
  }
);

const partyDetailsSlice = createSlice({
  name: "partyDetails",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchPartyDetails.fulfilled, (state, action) => {
        state.status = true
        state.reportItems = action.payload;
      })
      .addCase(fetchPartyDetails.rejected, (state, action) => {
        state.error = true;
        state.errorMessage = action.error.message;
        state.status = false;
      })
  },
});

export default partyDetailsSlice.reducer;

