export const formatVoucherType = (type) => {
    switch (type) {
      case "sales_invoice":
        return "Sales Invoice";
      case "sales_return":
        return "Sales Return";
      case "credit_note":
        return "Credit Note";
      case "quote":
        return "Quote";
      case "proforma_invoice":
        return "Proforma Invoice";
      case "purchase_invoice":
        return "Purchase Invoice";
      case "purchase_return":
        return "Purchase Return";
      case "debit_note":
        return "Debit Note";
      case "purchase_order":
        return "Purchase Order";
      default:
        return type;
    }
  };