import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Form from "react-bootstrap/Form";
import { Formik } from "formik";
import * as Yup from "yup";
import {
  changeDescription,
  editProduct,
  initProductEdit,
  resetCreateProduct,
  undoProductError,
} from "../store/productEntitySlice";
import { Unit } from "../../shared/config/Units";
import { selectAllCategories } from "../../settings/store/categorysSlice";
import {
  fetchProducts,
  selectProductById,
  updateProduct,
} from "../store/productsSlice";
import { Button, Col, Modal, Row, Spinner, Tab, Tabs } from "react-bootstrap";
import ProductDetailsForm from "../components/product_form/ProductDetailsForm";
import PriceDetailsForm from "../components/product_form/PriceDetailsForm";
import {
  i18n,
  numValidate,
  placeholderText,
} from "../../shared/helpers/sharedMethods";
import { cloneDeep } from "lodash";
import CreateCategory from "../../settings/components/CreateCategoryModel";
import ViewBarCode from "../components/ViewBarCode";
import { useHotkeys } from "react-hotkeys-hook";
import CreateStock from "../components/stock/CreateStock";
import EditStockDetailsForm from "../components/product_form/EditStockDetailsForm";
import ErrorModal from "../../shared/components/ErrorModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/free-solid-svg-icons";
import AlertMessage from "../../shared/components/AlertMessage";
import { getUtcDate } from "../../shared/helpers/localizationHelper";
import { toast, ToastContainer } from "react-toastify";

const EditProduct = ({ setShowEdit, show, productId }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const selectedProduct = useSelector((state) =>
    selectProductById(state, productId)
  );
  const productState = useSelector((state) => state.productEntity.product);
  const status = useSelector((state) => state.productEntity.status);
  const updateEntity = useSelector((state) => state.productEntity.savedEntity);
  const { errorStatus, error } = useSelector((state) => state.productEntity);
  const { currency } = useSelector((state) => state.getSetting?.dataSetting);
  const stockInitialState = useSelector((state) => state.stockEntity.stock);
  const fetchCategory = useSelector(selectAllCategories);
  const filterData = fetchCategory.filter((item) => item.type == "product");
  const selectedBusiness = useSelector(
    (state) => state?.auth?.selectedBusiness
  );
  const [showCategory, setShowCategory] = useState(false);
  const [showViewCode, setShowViewCode] = useState(false);
  const [showStock, setShowStock] = useState(false);
  const [barCodeItemData, setBarCodeItemData] = useState("");
  const [finalQuantity, setFinalQuantity] = useState();
  const [loading, setLoading] = useState(false);
  const { accessToken } = useSelector((state) => state?.auth);
  const tenantId = selectedBusiness?.pivot?.tenant_id;
  const { terminalId } = useSelector((state) => state.initInstall);


  useEffect(() => {
    if (errorStatus) {
      setLoading(false)
    }
  }, [errorStatus])

  useEffect(() => {
    if (productState) {
      setBarCodeItemData(productState);
    }
  }, [productState]);

  
  useEffect(() => {
    if (
      stockInitialState &&
      selectedProduct &&
      selectedProduct?.inventories &&
      selectedProduct?.inventories?.length > 0
    ) {
      const productQuantity = selectedProduct?.inventories[0].quantity;
      const adjustQuantity = stockInitialState.quantity;

      const isAddition = true;
      const newQuantity = isAddition
        ? productQuantity + adjustQuantity
        : productQuantity - adjustQuantity;
      setFinalQuantity(newQuantity);
    } else {
      const adjustQuantity = stockInitialState.quantity;
      const isAddition = true;
      const newQuantity = adjustQuantity;
      setFinalQuantity(newQuantity);
    }
  }, [selectedProduct, stockInitialState]);
  const handleViewCode = () => {
    setShowViewCode(true);
  };
  const handleStock = () => {
    setShowStock(true);
  };
  const getFormattedOptions = (options) => {
    return options.map((option) => ({
      value: option.name,
      label: `${option.name} (${option.description})`,
    }));
  };

  const UnitFilterOptions = getFormattedOptions(Unit);
  const UnitDefaultValue = UnitFilterOptions.map((option) => ({
    value: option.value,
    label: option.label,
  }));

  useEffect(() => {
    if (selectedProduct) {
      dispatch(initProductEdit(selectedProduct));
    }
  }, []);

  useEffect(() => {
    if (status && updateEntity) {
      dispatch(updateProduct(updateEntity));
      dispatch(fetchProducts());
      setShowEdit(false);
      toast.success(i18n("Product updated successfully"));

    }
  }, [status, updateEntity]);

  const handleCategory = () => {
    setShowCategory(true);
  };

  const handleCancel = () => {
    setShowEdit(false);
    dispatch(resetCreateProduct());
  };
  const handleAlertClose = () => {
    setLoading(false)
    dispatch(undoProductError())
    };

    useEffect(() => {
      const handleKeyDown = (event) => {
        if (event.key === "Enter") {
          event.preventDefault();
          const submitButton = document.getElementById("submitButton");
          if (submitButton) {
            submitButton.click();
          }
        }
      };
  
      if (!showCategory && !showStock) {
        document.addEventListener("keydown", handleKeyDown);
      }
  
      return () => {
        document.removeEventListener("keydown", handleKeyDown);
      };
    }, [showCategory,showStock]);

  useHotkeys("esc", (event) => {
    event.preventDefault();
    handleCancel();
  });

  const [key, setKey] = useState("general");
  return (
    <Formik
      initialValues={productState}
      enableReinitialize={true}
      validationSchema={Yup.object().shape({
        name: Yup.string().required(i18n("Please enter Product name")),
        code: Yup.string().required(i18n("Code is required")),
        cost_price: Yup.number().required(i18n("Please enter purchase price")),
        sale_price: Yup.number().required(i18n("Sale price is required")),
        quantity: Yup.number().required(i18n("Stock is required")),
      })}
      onSubmit={async (values) => {
        try {
          setLoading(true);
          let formData = cloneDeep(values);
          formData.updated_by = selectedBusiness?.pivot?.user_id;
          formData.updated_at = getUtcDate();

          // formData.inventories = [
          //   {
          //     quantity: finalQuantity,
          //     warehouse_id: formData.warehouse_id,
          //     created_by: stockInitialState?.created_by,
          //     updated_by: stockInitialState.updated_by,
          //     created_at: selectedProduct?.inventories
          //       ? selectedProduct?.inventories[0]?.created_at
          //       : getUtcDate(),
          //   },
          // ];

          formData.inventories = [
            {
              id: stockInitialState?.id,
              product_id: stockInitialState.product_id,
              warehouse_id: stockInitialState.warehouse_id,
              quantity: stockInitialState?.quantity,
              remarks: stockInitialState?.remarks
                ? stockInitialState?.remarks
                : "Stock Adjuctment",
              created_by: stockInitialState?.created_by,
              updated_by: stockInitialState.updated_by,
            },
          ];
          formData.id = productId;

          delete formData.quantity;
          delete formData.warehouse_id;
          delete formData.queued_for_delete;
          delete formData.product_images;
          dispatch(editProduct({data:formData,token: accessToken.access_token,
            tenant_id: tenantId, terminal_id:terminalId }));
                  } catch (error) {
          setLoading(false);
        }
      }}
    >
      {({
        values,
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue,
        touched,
      }) => (
        <>
          <CreateCategory
            show={showCategory}
            handleClose={() => {
              setShowCategory(false);
            }}
            type="product"
            hideTypeDropdown={true}
            caller="part2"
          />
          <ViewBarCode
            show={showViewCode}
            barCodeItemData={barCodeItemData}
            handleClose={() => setShowViewCode(false)}
          />
          <CreateStock
            show={showStock}
            handleClose={() => setShowStock(false)}
            numValidate={numValidate}
          />
          <Modal
            show={show}
            size="lg"
            keyboard={true}
            dialogClassName="modal-dialog modal-dialog-centered"
            onHide={handleCancel}
          >

            <Modal.Header closeButton>
              <h2 className="bold">Edit Product</h2>
            </Modal.Header>
            {errorStatus &&
            <AlertMessage
             errorStatus={errorStatus} 
             error={error} 
             handleAlertClose={handleAlertClose} 
             />
            }
           
            <Modal.Body className="py-2" style={{ height: "265px" }}>
              {" "}
              <Row className="">
                <Tabs
                  id="controlled-tab-example"
                  activeKey={key}
                  onSelect={(k) => setKey(k)}
                  className="mb-3 custom-nav-css"
                  variant="underline"
                  style={{
                    borderBottom: "1px solid lightgray",
                    lineHeight: "30px",
                    gap: "15px",
                  }}
                >
                  <Tab
                    className=""
                    eventKey="general"
                    title={
                      <div
                        style={{
                          color: `${
                            touched.name && errors.name
                              ? "rgb(246, 41, 71)"
                              : ""
                          }`,
                        }}
                      >
                        {i18n("General Details")} <span className="required" />
                      </div>
                    }
                  >
                    <ProductDetailsForm
                      handleChange={handleChange}
                      values={values}
                      filterData={filterData}
                      errors={errors}
                      touched={touched}
                      handleCategory={handleCategory}
                      handleBlur={handleBlur}
                    />
                  </Tab>
                  <Tab
                    eventKey="stock"
                    title={
                      <div
                        style={{
                          color: `${
                            touched.code && errors.code
                              ? "rgb(246, 41, 71)"
                              : ""
                          }`,
                        }}
                      >
                        {i18n("Stock Details")} <span className="required" />
                      </div>
                    }
                  >
                    <EditStockDetailsForm
                      values={values}
                      handleChange={handleChange}
                      errors={errors}
                      touched={touched}
                      setFieldValue={setFieldValue}
                      UnitDefaultValue={UnitDefaultValue}
                      numValidate={numValidate}
                      handleBlur={handleBlur}
                      handleViewCode={handleViewCode}
                      handleStock={handleStock}
                      finalQuantity={finalQuantity}
                    />
                  </Tab>
                  <Tab
                    eventKey="price"
                    title={
                      <div
                        style={{
                          color: `${
                            (touched.cost_price && errors.cost_price) ||
                            (touched.sale_price && errors.sale_price)
                              ? "rgb(246, 41, 71)"
                              : ""
                          }`,
                        }}
                      >
                        {i18n("Price Details")} <span className="required" />
                      </div>
                    }
                  >
                    <PriceDetailsForm
                      handleChange={handleChange}
                      values={values}
                      errors={errors}
                      touched={touched}
                      currencySymbol={currency}
                      numValidate={numValidate}
                      handleBlur={handleBlur}
                    />
                  </Tab>
                  <Tab eventKey="Description" title={i18n("Description")}>
                    <Row className="py-2 px-2">
                      <Form.Group>
                        <Form.Label>{i18n("Product Description")}</Form.Label>
                      </Form.Group>

                      <Col sm={12} lg={10}>
                        <Form.Control
                          as="textarea"
                          name="description"
                          rows={7}
                          placeholder={placeholderText("Enter Description")}
                          onChange={(e) => {
                            handleChange(e);
                            dispatch(changeDescription(e.target.value));
                          }}
                          value={values.description|| ""}
                          style={{resize:'none'}}
                        />
                      </Col>
                    </Row>
                  </Tab>
                </Tabs>
              </Row>
            </Modal.Body>
            <Modal.Footer style={{ borderTop: "1px solid lightgray" }}>
              <Row className=" py-1">
                {" "}
                <Col className="d-flex justify-content-end">
                  {" "}
                  {!loading ? (
                    <Button
                      id="submitButton"
                      variant="primary mx-2"
                      onClick={handleSubmit}
                      disabled={loading}
                    >
                      {i18n("Save")}
                    </Button>
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginRight: "25%",
                      }}
                    >
                      <Spinner animation="border" size="md" />
                    </div>
                  )}
                  <Button
                    variant="outline-secondary mx-2"
                    onClick={handleCancel}
                  >
                    {i18n("Cancel")}
                  </Button>
                </Col>
              </Row>
            </Modal.Footer>
          </Modal>
        </>
      )}
    </Formik>
  );
};
export default EditProduct;
