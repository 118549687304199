import { Button, Col, Form, Row, Table } from "react-bootstrap";
import { i18n } from "../../../shared/helpers/sharedMethods";
import { useNavigate } from "react-router";
import {
  Deleteplan,
  planData,
  selectProductAsSubscriptionSetupFee,
} from "../../store/subscription_item_store/GetPackagesItemSlice";
import { useDispatch, useSelector } from "react-redux";
import { UilEdit, UilTrashAlt } from "@iconscout/react-unicons";
import { resetSetupFee } from "../../store/subscription_item_store/SetupFeesEntitySlice";
import { useState } from "react";
import DeleteModel from "../../../shared/components/DeleteModel";
import { formatAmount } from "../../../pos/helpers/cartHelper";
import GlobalCurrency from "../../../shared/components/GlobalCurrency";
import { resetPackage } from "../../store/subscription_item_store/CreatePackageSlice";

const SetupFeesTable = ({ id }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const setupFees = useSelector((state) =>
    selectProductAsSubscriptionSetupFee(state, id)
  );

  const { accessToken, isLoggedIn } = useSelector((state) => state?.auth);
  const selectedBusiness = useSelector(
    (state) => state?.auth?.selectedBusiness
  );

  const tenantId = selectedBusiness?.pivot?.tenant_id;

  const [deleteFee, setDeleteFee] = useState(false);
  const [deleteName, setDeleteName] = useState();
  const handleCreate = () => {
    dispatch(resetSetupFee());
    dispatch(resetPackage());
    navigate("/subscription/packages/setup-fees/create", { state: { id: id } });
  };

  const handleEdit = (item) => {
    dispatch(resetSetupFee());
    navigate("/subscription/packages/setup-fees/edit/" + item?.id, {
      state: { currentId: id },
    });
  };

  const handleDelete = (item) => {
    ;
    setDeleteFee(true);
    setDeleteName(item);
  };
  const onDelete = () => {
    dispatch(
      Deleteplan({
        id: deleteName?.id,
        token: accessToken.access_token,
        tenant_id: tenantId,
      })
    ).then((res) => {
      ;
      dispatch(
        planData({ token: accessToken.access_token, tenant_id: tenantId })
      );
    });
    setDeleteFee(false);
  };

  return (
    <Row className="mt-5">
      <Col
        lg={12}
        className="d-flex justify-content-between align-items-center"
      >
        <div>
          <Form.Group className="px-1">
            <Form.Label className="fs-2">
              <span
                className="pb-2"
                style={{
                  borderBottom: "3px solid #1a237e",
                  fontWeight: "bold",
                  fontSize: "15px",
                }}
              >
                {i18n("Setup Fee")}
              </span>
            </Form.Label>
          </Form.Group>
        </div>
        <div>
          {setupFees.length == 0 && (
            <Button variant="link" onClick={handleCreate} className="px-2" style={{ fontSize: '13px' }}>
              <span>{i18n("+Add Setupfee")}</span>
            </Button>
          )}
        </div>
      </Col>
      <Col lg={12}>
        <div className="mt-5 px-1">
          <Table bordered hover responsive>
            <thead>
              <tr className="text-center" style={{ color: '#495057' }}>
                <th className="px-2" style={{ fontSize: '13px', backgroundColor: 'rgb(249, 250, 251)' }}>{i18n("Name")}</th>
                <th className="px-2" style={{ fontSize: '13px', backgroundColor: 'rgb(249, 250, 251)' }}>{i18n("Code")}</th>
                <th className="px-2" style={{ fontSize: '13px', backgroundColor: 'rgb(249, 250, 251)' }}>{i18n("Type")}</th>
                <th className="px-2" style={{ fontSize: '13px', backgroundColor: 'rgb(249, 250, 251)' }}>{i18n("Price")}</th>
                <th className="px-2" style={{ fontSize: '13px', backgroundColor: 'rgb(249, 250, 251)' }}>{i18n("Refundable")}</th>
                <th className="px-2" style={{ fontSize: '13px', backgroundColor: 'rgb(249, 250, 251)' }}>{i18n("Actions")}</th>
              </tr>
            </thead>
            <tbody>
              {setupFees && setupFees.length > 0 ? (
                setupFees.map((item, index) => (
                  <tr className="text-center" key={index} style={{ fontSize: '13px' }}>
                    <td className="px-3" style={{ fontSize: '13px' }}>{item.product_name}</td>
                    <td className="px-5" style={{ fontSize: '13px' }}>{item.product_code}</td>
                    <td className="px-4" style={{ fontSize: '13px' }}>{item?.type}</td>
                    <td className="px-3" style={{ fontSize: '13px' }}><GlobalCurrency price={item?.item_price} /></td>
                    <td className="px-4" style={{ fontSize: '13px' }}>
                      {item?.setupFee?.is_refundable == 1 ? "Yes" : "No"}
                    </td>
                    <td className="px-2">
                      <UilEdit
                        size="16"
                        className=" mx-1"
                        aria-hidden="true"
                        style={{ cursor: "pointer" }}
                        onClick={() => handleEdit(item)}
                      />
                      &nbsp;
                      <UilTrashAlt
                        size="16"
                        className=" mx-1 "
                        aria-hidden="true"
                        style={{ cursor: "pointer" }}
                        onClick={() => handleDelete(item)}
                      />
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td className="text-center px-2" colSpan="6" style={{fontSize:'13px'}}>
                    {/* <UilPackage size="25" color="gray" className="mx-2 mb-1" /> */}
                    {i18n("No SetupFee yet!")}
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>
      </Col>
      {deleteFee && (
        <DeleteModel
          name={deleteName?.product_name}
          deleteUserClick={onDelete}
          onClickDeleteModel={setDeleteFee}
        />
      )}
    </Row>
  );
};

export default SetupFeesTable;
