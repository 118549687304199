import React, { useState, useEffect } from "react";
import { Button, Row, Col, Stack, Container, Badge, Table ,Image} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Layout from "../../shared/components/Layout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faDownload,
} from "@fortawesome/free-solid-svg-icons";
import { downloadCsvFile, handleExportClick } from "../../shared/export";
import ReportPeriod from "../../shared/components/ReportPeroid";
import Header from "../../shared/components/Header";
import { fetchSaleSummaryCategoryWise } from "../store/saleSummaryCategoryWiseSlice";
import SalesSummaryDataTables from "../components/SalesSummaryDataTables";
import PartyNavbar from "../components/PartyNavbar";
import { i18n } from "../../shared/helpers/sharedMethods";
import ShortcutKeys from "../../shared/components/ShortcutKeys";
import ReportDateRange from "../../shared/components/date_range/ReportDateRange";
import DatePicker from "../../shared/components/date_range/DatePicker";
import { CurrentWeekDateRange } from "../../shared/components/DateRangeYAxis";
import ReportHeader from "../components/ReportHeader";
import { UilAngleLeft,UilImport } from "@iconscout/react-unicons";
import LoadingSpinner from '../../shared/components/LoadingSpinner';
const SaleSummaryCategoryWise = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const accessToken = useSelector((state) => state?.auth?.accessToken?.access_token);
  const selectedBusiness = useSelector((state) => state?.auth?.selectedBusiness);
  const tenantId = selectedBusiness?.pivot?.tenant_id;
  const [reportPeriod, setReportPeriod] = useState("current-week");
  const [csvData, setCsvData] = useState("");
  const [startDate, setStartDate] = useState(null); // State for start date
  const [endDate, setEndDate] = useState(null);

    const handleReset = () => {
      const salesDateLimits = ReportDateRange({
        reportPeriod: reportPeriod,
      });
      setStartDate(salesDateLimits.startDate);
      setEndDate(salesDateLimits.endDate);
      setReportPeriod("current-week");
    };
  useEffect(() => {
    dispatch(fetchSaleSummaryCategoryWise({startDate: startDate, endDate: endDate,token:accessToken,tenant_id:tenantId})); 
  }, [startDate, endDate]);
    useEffect(() => {
      const dateLimits = ReportDateRange({ reportPeriod });
      setStartDate(dateLimits.startDate);
      setEndDate(dateLimits.endDate);
    }, [reportPeriod]);

  const reportItems = useSelector((state) => state.saleCategoryWise.reportItems);
  const status = useSelector((state) => state.saleCategoryWise.status);
  const loading = useSelector((state) => state.saleCategoryWise.loading);
  const currencySymbol  = useSelector((state) => state.getSetting?.dataSetting.currency);
  ;
  const uniquePartyNames = reportItems ? [...new Set(reportItems.map((item) => item.category))] : [];

  const dateTimeString = new Date()
  .toLocaleString()
  .replace(/[-]/g, " ")
  .replace(/:/g, "-");
const fileName = `Sale_summary_Category_wise_${dateTimeString}`;

   const handleExportButtonClick = () => {
    const exportData =
      reportItems &&
      Array.isArray(reportItems) &&
      reportItems.map((val) => ({
        "DATE": val.date ? val.date : "-",
        "INVOICE NO.": val.invNo ? val.invNo : "-",      
        "PARTY SALE": val.partyName ? val.partyName : "-",
        "CREATEDBY": val.createdBy ? val.createdBy : "-",
        "DUE DATE": val.dueDate ? val.dueDate : "-",
        "AMOUNT": val?.amount ? parseFloat(val.amount).toFixed(2) : "0",
        "BALANCE": val?.balance ? parseFloat(val.balance).toFixed(2) : "0",
        "INVOICE TYPE":  "-",
        "INVOICE STATUS": val.reportStatus ? val.reportStatus : "-",
        "CATEGORY NAME": val.category ? val.category : "-",
      }));
    handleExportClick(
      exportData,
      setCsvData,
      downloadCsvFile,
      fileName
    );
   };

  return (
    <Layout>
       {loading && <LoadingSpinner />}
      <Header
        currentTitle={i18n("Sales Summary - Category Wise")}
        ItemActions={
          <Stack gap={3} direction="horizontal" className="mt-1 mb-1 float-end">
            <ShortcutKeys />
            </Stack>
        }
      />
      <Container fluid className="px-5" 
      style={{ height: "85vh", overflow: "auto", overflowX: "hidden" }}>
            <div className="border " style={{ background: "white" }}>

            <PartyNavbar />
            <ReportHeader
            ItemActions={
              <Stack gap={2} direction="horizontal" className="pt-3 px-3 mb-3">
                <Stack direction="horizontal">
                  <DatePicker
                    startDate={startDate}
                    setStartDate={setStartDate}
                    endDate={endDate}
                    setEndDate={setEndDate}
                  />
                </Stack>
                <Stack className=" ">
                  <ReportPeriod
                    reportPeriod={reportPeriod}
                    setReportPeriod={setReportPeriod}
                  />
                </Stack>
                <Stack
                  gap={1}
                  direction="horizontal"
                  style={{ marginLeft: "1px" }}
                >
                  <Button
                    className="rounded-0"
                    variant="outline-danger"
                    onClick={handleReset}
                  >
                      {i18n("Reset")}
                  </Button>
                </Stack>
                <Button
                  className="fw-bolder d-flex align-items-center rounded-0"
                  onClick={handleExportButtonClick}
                >
                  <UilImport
                    size="18"
                    className="mx-2"
                    style={{ cursor: "pointer" }}
                  />
                  {i18n("Export")}
                </Button>
              </Stack>
            }
            />

            {status ? (
              <>
                {uniquePartyNames.map((category, index) => {
                  const filteredReportItems = category
                    ? reportItems.filter((item) => item.category === category)
                    : reportItems.filter((item) => !item.category);
                  return (
                   <div className=" px-6 pt-0" style={{ background: "" }}>
                    <SalesSummaryDataTables
                      key={index}
                      partyName={category}
                      filteredReportItems={filteredReportItems}
                      currencySymbol={currencySymbol}
                    />
                    </div>
                  );
                })}
              </>
            ) : (
              <p className="d-flex justify-content-center p-2 fs-3 fw-bolder">
                {i18n("Error while fetching Sales Summary Category Wise")}
              </p>
            )}
        </div>
      </Container>
    </Layout>
  );
};

export default SaleSummaryCategoryWise;
