import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { addVoucherType } from "../store/voucherDeletedSlice";
import { Form } from 'react-bootstrap';
import { placeholderText } from "../../shared/helpers/sharedMethods";

const PurchaseType = () => {
  const dispatch =useDispatch()
  const [selectedType, setSelectedType] = useState("All_purchase");

 const handlePurchaseTypeChange =(value)=>{
    setSelectedType(value);
    dispatch(addVoucherType(value));
 }

return (
<Form.Select onChange={(e) =>{handlePurchaseTypeChange(e.target.value)}} value={selectedType}>
    <option  value="All_purchase">{placeholderText("All Purchases")}</option>
    <option  value="purchase_invoice">{placeholderText("Purchase Invoice")}</option>
    <option  value="purchase_return">{placeholderText("Purchase Return")}</option>
    <option  value="debit_note">{placeholderText("Debit Note")}</option>
    <option  value="purchase_order">{placeholderText("Purchase Order")}</option>
    
</Form.Select>
 )
}
export default PurchaseType;