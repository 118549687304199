import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Modal,
  Row,
  Form,
  Button,
  Col,
  InputGroup,
  Stack,
} from "react-bootstrap";
import { placeholderText, i18n } from "../../../shared/helpers/sharedMethods";
// import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  changeQty,
  changeRemarks,
  changeStockCreatedBy,
  changestockUpdatedBy,
  resetStockAdjust,
} from "../../store/stockEntitySlice";
import { cloneDeep } from "lodash";
import { Formik } from "formik";
import { addStock } from "../../store/stockEntitySlice";
import { toast, ToastContainer } from "react-toastify";

const CreateStock = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { handleClose, show, numValidate } = props;
  const [operation, setOperation] = useState("add");
  const [qty, setQty] = useState(0);
  const [customRemark, setCustomRemark] = useState("");
  const stockState = useSelector((state) => state.stockEntity.stock);

  const selectedBusiness = useSelector(
    (state) => state?.auth?.selectedBusiness
  );

  useEffect(() => {
    if (operation || qty) {
      dispatch(changeQty({ quantity: qty, mode: operation }));
    }
  }, [qty, operation]);
  const handleQuantityChange = (e) => {
    const value = e.target.value;
    setQty(value);
  };
  const handleModeChange = (e) => {
    setOperation(e.target.value);
  };
  const [fieldShow,setFieldShow] = useState(false)
  const handleRemarkChange = (e) => {
    const value = e.target.value;
    if(value == "Others"){
      setCustomRemark(value);
      dispatch(changeRemarks(""));
      setFieldShow(true)

    }
    else{
      setCustomRemark(value);
      dispatch(changeRemarks(e.target.value));
      setFieldShow(false)
    }
    
   
  };
  const clearField = () => {
    handleClose();
    dispatch(resetStockAdjust());
    setCustomRemark("");
  };
  useEffect(() => {
    if (stockState) {
      dispatch(changeStockCreatedBy(selectedBusiness?.pivot?.user_id));
      dispatch(changestockUpdatedBy(selectedBusiness?.pivot?.user_id));
    }
  }, [stockState]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Enter") {
        
        event.preventDefault();
        const saveButton = document.getElementById("saveButton");
       if (saveButton) {
            saveButton.click();
        }
      }
     };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  return (
    <>
      <ToastContainer />
      <Formik
        initialValues={stockState}
        enableReinitialize={true}
        // validationSchema={}
        onSubmit={async (values) => {
          try {
            if (values.quantity == 0) {
              toast.error(i18n("Please enter a valid quantity"));
              return;
            }
            if (values.remarks === "Others" && !customRemark) {
              toast.error(i18n("Please enter a remark"));
              return;
            }
            let formDatas = cloneDeep(values);
            formDatas.created_at = new Date().toISOString();
            formDatas.updated_at = new Date().toISOString();
            formDatas.remarks =  values.remarks;
            dispatch(addStock(formDatas));
            handleClose();
          } catch (error) {
            ;
          }
        }}
      >
        {({
          values,
          errors,
          handleChange,
          handleSubmit,
        }) => (
          <>
            {/* <ToastContainer /> */}
            <Modal
              show={show}
              dialogClassName="modal-dialog modal-dialog-centered mw-700px "
              style={{ zIndex: 2500 }}
              onHide={clearField}
              keyboard={true}
            >
              <div
                className=""
                style={{
                  borderRadius: "5px",
                  boxShadow: "0 0 500px 500px rgba(0, 0, 0, 0.3)",
                }}
              >
                <Modal.Header className="" closeButton>
                  <Modal.Title>{i18n("Stock Adjustment")}</Modal.Title>
                </Modal.Header>
                <Modal.Body className="">
                  <Row className="">
                    <Col md={12}>
                      <Form.Label className="form-label-item mb-2">
                        {i18n("Mode/Quantity")}
                      </Form.Label>
                      <InputGroup>
                        <Form.Select
                          value={operation}
                          onChange={handleModeChange}
                        // onChange={(e) => setOperation(e.target.value)}
                        >
                          <option value="add">
                            {placeholderText("Addition (+)")}
                          </option>
                          <option value="sub">
                            {placeholderText("Subtration (-)")}
                          </option>
                        </Form.Select>

                        <Form.Control
                          type="number"
                          name="quantity"
                          placeholder={placeholderText("Enter Quantity")}
                          value={stockState.quantity ? stockState.quantity : 0}
                          onChange={handleQuantityChange}
                          onKeyPress={(event) => numValidate(event)}
                          autoFocus={true}

                        />
                      </InputGroup>
                    </Col>
                  </Row>
                  <Row className="mt-2">
                    <Col lg={12} md={12} sm={12} className="mb-2">
                      <Form.Label className="form-label">
                        {i18n("Remark")}
                      </Form.Label>

                      <InputGroup>
                        <Form.Select
                          name="remarks"
                          onChange={handleRemarkChange}
                          value={customRemark}
                        >
                          <option value="Opening stock">{placeholderText("Opening stock")}</option>
                          <option value="Stock adjustment">{placeholderText("Stock adjustment")}</option>
                          <option value="Missing stock">{placeholderText("Missing stock")}</option>
                          <option value="Damaged goods">{placeholderText("Damaged goods")}</option>
                          <option value="Others">{placeholderText("Others")}</option>
                        </Form.Select>
                        {fieldShow && (
                          <Form.Control
                            type="text"
                            placeholder={placeholderText("Enter your remark")}
                            value={values.remarks}
                            onChange={(e) => { 
                              dispatch(changeRemarks(e.target.value));
                             }} 
                          />
                        )}
                      </InputGroup>
                    </Col>
                  </Row>
                </Modal.Body>
                <Modal.Footer
                  className="d-flex justify-content-between"
                  style={{ borderTop: "1px solid lightgray" }}
                >
                  <Stack direction="horizontal">
                    <Button
                      className="d-flex "
                      variant="outline-danger mx-0"
                      onClick={clearField}
                    >
                      {i18n("Reset")}
                    </Button>
                  </Stack>
                  <Stack direction="horizontal">
                    <Button
                    id="saveButton"                    
                      variant="primary mx-3"
                      onClick={handleSubmit}
                      className=""
                    >
                      {i18n("Save")}
                    </Button>
                  </Stack>
                </Modal.Footer>
              </div>
            </Modal>
          </>
        )}
      </Formik>
    </>
  );
};

export default CreateStock;
