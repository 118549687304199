import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosRequest } from "../../shared/helpers/axiosHelper";
import { i18n } from "../../shared/helpers/sharedMethods";

const initialState = {
    status: false,
    error: {},
    savedEntity: {},
    updateEntity: {},
    isConvertedToParty:false,
    isEdit:false
}

export const createEnquiryReq = createAsyncThunk(
    "createEnquiry/create",
    async (args) => {
        try {
            const token = args.token;
            const data = args.data;
            const tenantId = args.tenant_id;
            const response = await axiosRequest({
                method: "POST",
                url: "enquiry",
                data: data,
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                    "X-Tenant": tenantId,
                },
            });
            
            return response.data;

        } catch (error) {
            await window.log.logToFile({message: "Error while creating Enquiry", level: "error" });
            return error;
        }
    }
)

export const EditEnquiryReq = createAsyncThunk(
    "createEnquiry/edit",
    async (args) => {
        try {
            const token = args.token;
            const data = args.data;
            const id = args.id;
            const tenantId = args.tenant_id;
            const response = await axiosRequest({
                method: "PUT",
                url: `enquiry/${id}`,
                data: data,
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                    "X-Tenant": tenantId,
                },
            });
            ;
            return response.data;
        } catch (error) {
            ;
            await window.log.logToFile({message: "Error while updating enquiry", level: "error" });
            throw error;
        }
    }
)

export const EnquiryToPartyReq = createAsyncThunk(
    "createEnquiry/convertToParty",
    async (args) => {
        try {
            const token = args.token;
            const data = args.data;
            const tenantId = args.tenant_id;
            const response = await axiosRequest({
                method: "POST",
                url: `enquiry/convert-party`,
                data: data,
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                    "X-Tenant": tenantId,
                },
            });
            ;
            return response.data;
        } catch (error) {
            ;
            await window.log.logToFile({message: "Error while converting party in enquiry", level: "error" });
            throw error;
        }
    }
)

const createEnquirySlice = createSlice({
    name: "createEnquiry",
    initialState: initialState,
    reducers: {
        resetCreateEnquiry: () => initialState,
        enquiryStatus(state, action) {
            state.status = false;
            state.error = ''
          },
    },
    extraReducers(builder) {
        builder

            .addCase(createEnquiryReq.fulfilled, (state, action) => {
                state.status = true;
                
                state.savedEntity = action.payload
                
            })
            .addCase(createEnquiryReq.rejected, (state, action) => {
                state.status = false;
                state.error = i18n(
                    "Error while adding enquiry. Please try again later."
                );
            })
            .addCase(EditEnquiryReq.fulfilled, (state, action) => {
                state.isEdit = true;
                state.updateEntity = action.payload;
            })
            .addCase(EditEnquiryReq.rejected, (state, action) => {
                state.isEdit = false;
                state.error = i18n(
                    "Error while adding enquiry. Please try again later."
                );
            })
            .addCase(EnquiryToPartyReq.fulfilled, (state, action) => {
                state.isConvertedToParty = true;
            })
            .addCase(EnquiryToPartyReq.rejected, (state, action) => {
                state.isConvertedToParty = false;
                state.error =  "Error while converting to party. Please try again later.";
            })


    },

})

export default createEnquirySlice.reducer;

export const { resetCreateEnquiry,enquiryStatus } = createEnquirySlice.actions;


