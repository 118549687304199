import React, { useState, useRef } from "react";
import { useNavigate } from "react-router";
import { Row, Col, Button, Image } from "react-bootstrap";
import OTPInput from "react-otp-input";
import bill from "../../../assets/logo/logo6-small.png";
import { setPin } from "../../auth/store/authSlice";
import { useDispatch } from "react-redux";
import { i18n } from "../../shared/helpers/sharedMethods";

const LockScreen = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [lockPin, setLockPin] = useState("");
  const pinInputRef = useRef(null);

  const handlePinChange = (newPin) => {
    setLockPin(newPin);
  };

  const handleLock = () => {
    if (lockPin.length === 4) {
      dispatch(setPin(lockPin));
      navigate("/auth/unlock-screen");
    } else {
      if (pinInputRef.current) {
        pinInputRef.current.focus();
      }
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleLock();
    }
  };

  return (
    <Row
      className="border d-flex flex-column justify-content-center align-items-center h-100 w-100"
      style={{
        borderRadius: "5px",
        height: "100vh",
        overflow: "scroll",
        overflowX: "hidden",
      }}
    >

      <Col className="mb-5 mt-2 d-flex justify-content-center col-lg-12">
        <Image src={bill} alt="Bill Now" style={{ height: "100px" }} />
      </Col>
      <Col className="col-md-3 mb-5">
        <div className="d-flex flex-column justify-content-center align-items-center me-7">
          <label className="form-label">{i18n("Please Enter 4-digit PIN")}</label>
          <OTPInput
            ref={pinInputRef}
            value={lockPin}
            onChange={handlePinChange}
            numInputs={4}
            renderSeparator={<span></span>}
            shouldAutoFocus
            renderInput={(props) => (
              <input
                {...props}
                onKeyPress={handleKeyPress}
                style={{
                  width: "50px",
                  marginLeft: "5px",
                  height: "40px",
                  border: "1px solid lightgray",
                  borderRadius: "4px",
                  textAlign: "center",
                }}
              />
            )}
          />
        </div>
      </Col>

      {lockPin.length === 4 && (
        <Col className="col-lg-3 d-flex justify-content-center me-5">
          <Button className="w-40" onClick={handleLock}>
            {i18n("Set")}
          </Button>
        </Col>
      )}
    </Row>
  );
};
export default LockScreen;
