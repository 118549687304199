import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, Row, Col } from "react-bootstrap";
import DataGrid from "./DataGrid";
import { i18n } from "../helpers/sharedMethods";
import { UilSyncExclamation } from '@iconscout/react-unicons';
import { exportSync } from "../../settings/components/helpers/settingHelpers";
import { downloadCsvFile, handleExportClick } from "../export";
import { fetchSyncQueue } from "../store/syncLogsSlice";
import SyncLogsDetails from "./SyncLogsDetails";

const SyncQueue = () => {
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(fetchSyncQueue())
    }, [])
    const fetchSync = useSelector((state) => state.syncLogs.fetchSync);
    const fileList = useSelector((state) => state.syncLogs?.infoFileList);
    console.log("file list", fileList)

    const [sync, setSync] = useState('');
    const [csvData, setCsvData] = useState([]);
    const [exportData, setExportData] = useState([]);

    const gridColumns = [
        {
            id: "id",
            name: "#",
            selector: (row) => row.id,
        },
        {
            id: "operation",
            name: i18n("Operation"),
            selector: (row) => row.operation,
        },
        {
            id: "resource_id",
            name: i18n("Resource ID"),
            selector: (row) => row.resource_id,
        },
        {
            id: "resource_type",
            name: i18n("Resource Type"),
            selector: (row) => row.resource_type,
        }
    ];

    const dateTimeString = new Date()
        .toLocaleString()
        .replace(/[-]/g, " ")
        .replace(/:/g, "-");
    const fileName = `sync_${dateTimeString}`;

    const handleExportSync = () => {
        const exportData = exportSync(fetchSync);
        setExportData(exportData);
        handleExportClick(
            exportData,
            (exportData) => {
                setCsvData(exportData);
            },
            downloadCsvFile,
            fileName
        );
    };

    const handleRefresh = () => {

        dispatch(fetchSyncQueue());
    }

    return (
        <SyncLogsDetails>
            <div className="" style={{ background: "white" }}>
                <DataGrid
                    data={fetchSync}
                    allData={fetchSync}
                    columns={gridColumns}
                    addFilteredData={setSync}
                    handleExportClick={handleExportSync}
                    isPaginated={true}
                    button="Sync Queue"
                    title="Sync Queue"
                    icons={<UilSyncExclamation size="50" color="gray" />}
                    message={i18n("No items in queue.")}
                    fileList={fileList}
                    refreshHandler={handleRefresh}
                />
            </div>
        </SyncLogsDetails>

    );
};

export default SyncQueue;
