import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import moment from "moment";
import _ from 'lodash';

export const getAvatarName = (name) => {
    if (name) {
        return name.toLowerCase()
            .split(' ')
            .map((s) => s.charAt(0).toUpperCase())
            .join('');
    }
};

export const numValidate = (event) => {
    if (!/[0-9]/.test(event.key)) {
        event.preventDefault();
    }
};

export const i18n = (id) => {
    return <FormattedMessage id={id} defaultMessage={id} />
};

// export const getFormattedMessageWithIntl = (id) => {
//     // eslint-disable-next-line react-hooks/rules-of-hooks
//     const intl = useIntl();
//     return intl.formatMessage({ id, defaultMessage: id });
// };

export const getFormattedOptions = (options) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const intl = useIntl();
    const copyOptions = _.cloneDeep(options);
    copyOptions.map(option => option.name = intl.formatMessage({
        id: option.name,
        defaultMessage: option.name
    }));
    return copyOptions;
};

export const placeholderText = (label) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const intl = useIntl();
    const placeholderLabel = intl.formatMessage({ id: label ,defaultMessage: label});
    return placeholderLabel
}

export const decimalValidate = (event) => {
    if (!/^\d*\.?\d*$/.test(event.key)) {
        event.preventDefault();
    }
};

export const addRTLSupport = (rtlLang) => {
    const html = document.getElementsByTagName("html")[0];
    const att = document.createAttribute("dir");
    att.value = "rtl";
    if (rtlLang === "ar") {
        html.setAttributeNode(att);
    } else {
        html.removeAttribute("dir");
    }
}

export const onFocusInput = (el) => {
    if (el.target.value === '0.00') {
        el.target.value = '';
    }
};

/* export const ProtectedRoute = (props) => {
    const { children, allConfigData, route } = props;
    const token = localStorage.getItem(Tokens.ADMIN)
    if (!token || token === null) {
        return <Navigate to='/login' replace={true} />;
    } else {
        if (allConfigData?.open_register) {
            if (route === "pos") {
                return <Navigate to='/app/dashboard' replace={true} />
            } else {
                return children;
            }
        } else {
            return children;
        }
    }
};
 */
// export const formatAmount = num => {
//     return Math.abs(num) > 999 ? Math.sign(num) * ((Math.abs(num) / 1000).toFixed(1)) + ' K' : Math.sign(num) * Math.abs(num)
// };

export const formatAmount = (num) => {
    if (num >= 1000000000) {
        return (num / 1000000000).toFixed(1).replace(/\.0$/, '') + 'B';
    }
    if (num >= 1000000) {
        return (num / 1000000).toFixed(1).replace(/\.0$/, '') + 'M';
    }
    if (num >= 1000) {
        return (num / 1000).toFixed(1).replace(/\.0$/, '') + 'K';
    }
    return num;
}

export const currencySymbolHendling = (isRightside, currency, value, is_forment) => {
    if (isRightside?.is_currency_right === 'true') {
        if (is_forment) {
            return formatAmount(value) + ' ' + currency
        } else {
            return parseFloat(value).toFixed(2) + ' ' + currency
        }
    } else {
        if (is_forment) {
            return currency + ' ' + formatAmount(value)
        } else {
            return currency + ' ' + parseFloat(value).toFixed(2)
        }
    }
}

export const getFormattedDate = (date, config) => {
    const format = config && config.date_format
    if (format === "d-m-y") {
        return moment(date).format('DD-MM-YYYY')
    } else if (format === "m-d-y") {
        return moment(date).format("MM-DD-YYYY")
    } else if (format === "y-m-d") {
        return moment(date).format("YYYY-MM-DD")
    } else if (format === "m/d/y") {
        return moment(date).format("MM/DD/YYYY")
    } else if (format === "d/m/y") {
        return moment(date).format("DD/MM/YYYY")
    } else if (format === "y/m/d") {
        return moment(date).format("YYYY/MM/DD")
    } else if (format === "m.d.y") {
        return moment(date).format("MM.DD.YYYY")
    } else if (format === "d.m.y") {
        return moment(date).format("DD.MM.YYYY")
    } else if (format === "y.m.d") {
        return moment(date).format("YYYY.MM.DD")
    } else moment(date).format('YYYY-MM-DD')
}


export const totalPriceCount = (totalPrice) => {
    if (totalPrice.tax_type === '2' || totalPrice.tax_type === 2) {
        totalPrice = +totalPrice.net_unit_cost
    } else if (totalPrice.tax_type === '1' || totalPrice.tax_type === 1) {
        let exclusiveTax = totalPrice.tax_type === '1' || totalPrice.tax_type === 1 ? parseFloat(totalPrice.net_unit_cost).toFixed(2) * Number(totalPrice.tax_value) / Number(100) : 0;
        totalPrice = (+totalPrice.net_unit_cost + (exclusiveTax));
    }
    return (totalPrice).toFixed(2);
};

//count discount on price
export const calculateDiscount = (totalCost) => {
    if (totalCost.discount_value > 0 && totalCost.discount_type === '2' || totalCost.discount_type === 2) {
        totalCost = (+totalCost.net_unit_cost - Number(totalCost.discount_value))
    } else if (totalCost.discount_value > 0 && totalCost.discount_type === '1' || totalCost.discount_type === 1) {
        const percentDiscount = totalCost.discount_type === '1' || totalCost.discount_type === 1 ? parseFloat(totalCost.net_unit_cost).toFixed(2) * Number(totalCost.discount_value) / Number(100) : 0;
        totalCost = (+totalCost.net_unit_cost - (percentDiscount));
    }
    return totalCost;
};

//count tax on price
export const calculateTax = (totalCost, finalCount) => {
    if (totalCost.tax_type === '2' || totalCost.tax_type === 2) {
        totalCost = +finalCount
    } else if (totalCost.tax_type === '1' || totalCost.tax_type === 1) {
        let exclusiveTax = totalCost.tax_type === '1' || totalCost.tax_type === 1 ? parseFloat(finalCount).toFixed(2) * Number(totalCost.tax_value) / Number(100) : 0;
        totalCost = (+finalCount + (exclusiveTax));
    }
    return totalCost;
};

//cart price updated
export const calculateProductCost = (product) => {
    let finalCount = 0;
    finalCount = calculateDiscount(product);
    finalCount = calculateTax(product, finalCount);
    return +finalCount;
};

