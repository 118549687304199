import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, Card, Row, Col } from "react-bootstrap";
import DataGrid from "./DataGrid";
import { fetchInfoFileList, fetchInfoLogAction, fetchLogAction } from "../store/syncLogsSlice";
import { i18n } from "../helpers/sharedMethods";
import { UilFileExclamationAlt } from '@iconscout/react-unicons';
import { downloadCsvFile, handleExportClick } from "../export";
import { exportLogs } from "../../settings/components/helpers/settingHelpers";
import SyncLogsDetails from "./SyncLogsDetails";

const SyncInfoLogs = () => {
    const dispatch = useDispatch();
    const fetchLogs = useSelector((state) => state.syncLogs?.fetchInfoLogs);
    const fileList = useSelector((state) => state.syncLogs?.infoFileList);
    console.log("file list", fileList)

    const [logs, setLogs] = useState('');
    const [exportData, setExportData] = useState([]);
    const [csvData,setCsvData] =useState([]);
    const [filterDate, setFilterDate] = useState(null)

    useEffect(() => {
        dispatch(fetchInfoFileList())
    }, [])

    useEffect(() => {
        const date = new Date();
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const formattedDate = `${year}-${month}-${day}`;
        console.log("formattedDate", formattedDate);
        dispatch(fetchInfoLogAction(formattedDate));
    }, []);

    const handleRefresh = () => {
        const date = new Date();
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const formattedDate = `${year}-${month}-${day}`;
        console.log("formattedDate", formattedDate);
        dispatch(fetchInfoLogAction(formattedDate));
    }

    const gridColumns = [
        {
            id: "timestamp",
            name: i18n("timeStamp"),
            sortable: true,
            selector: (row) => {
                const formatAMPM = (dateStr) => {
                    const cleanedDateStr = dateStr.replace(/\[|\]/g, '').trim();
                    const [datePart, timePart] = cleanedDateStr.split(' ');
                    let [hours, minutes, seconds] = timePart.split(':');
                    const [year, month, day] = datePart.split('-');
                    const formattedDate = `${day}/${month}/${year}`;
                    hours = parseInt(hours, 10);
                    const ampm = hours >= 12 ? 'PM' : 'AM';
                    hours = hours % 12 || 12;
                    return <span>{formattedDate} &nbsp;&nbsp; {hours}:{minutes}:{seconds} {ampm}</span>;
                };

                return formatAMPM(row.timestamp);
            },
        },
        // {
        //     id: "level",
        //     name: i18n("Level"),
        //     selector: (row) => row.level,
        // },
        {
            id: "message",
            name: i18n("Message"),
            selector: (row) => row.message,
        }
    ];

    const dateTimeString = new Date()
        .toLocaleString()
        .replace(/[-]/g, " ")
        .replace(/:/g, "-");
    const fileName = `logs_${dateTimeString}`;

    const handleExportLogs = () => {
        const exportData = exportLogs(fetchLogs);
        setExportData(exportData);
        handleExportClick(
            exportData,
            (exportData) => {
                setCsvData(exportData);
            },
            downloadCsvFile,
            fileName
        );
    };

    const handleDateFilter = (date) => {
        console.log("date", date)
        if (date) {
            setFilterDate(date)
            dispatch(fetchInfoLogAction(date));
        }
    }

    return (

        <SyncLogsDetails>
            <div className="" style={{ background: "white" }}>
                <DataGrid
                    data={fetchLogs}
                    allData={fetchLogs}
                    columns={gridColumns}
                    addFilteredData={setLogs}
                    handleExportClick={handleExportLogs}
                    isPaginated={true}
                    button="Info Logs"
                    title="Info Logs"
                    icons={<UilFileExclamationAlt size="50" color="gray" />}
                    message={i18n("No logs.")}
                    handleDateFilter={handleDateFilter}
                    filterDate={filterDate}
                    fileList={fileList}
                    refreshHandler={handleRefresh}
                />
            </div>
        </SyncLogsDetails>

    );
};

export default SyncInfoLogs;
