import React from "react";
import {  Container, Row, Col } from "react-bootstrap";
import BusinessInfo from "./party_profile_info/BusinessInfo";
import GeneralInfo from "./party_profile_info/GeneralInfo";




const ProfileInfo = ({selectedParty}) => {


    return (
        
        <Container className='p-0 mt-5' fluid>
            
            <Row>
                <Col lg={6} md={6} sm={6}>
                    <GeneralInfo selectedParty={selectedParty} />
                </Col>
                <Col lg={6} md={6} sm={6}>
                   <BusinessInfo selectedParty={selectedParty} />
                </Col>
            </Row>
           
        </Container>
    )
}




export default ProfileInfo;