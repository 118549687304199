import { Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { updateItemFieldSubscription } from "../../../../store/subscriptionProductEntitySlice";


const SubscriptionCess = ({ index, cessEnabled, item, discountEnabled }) => {
  const dispatch = useDispatch();
  let taxTotal = item?.taxable_value;
  let [itemCess, setItemCess] = useState(item?.cess);
  let [itemCessRate, setItemCessRate] = useState(item?.cess_rate);

  const handleCessRateChange = (event) => {
    let cessRate = event?.target?.value;
    if (cessRate >= 100) {
      return false;
    }
    setItemCessRate(cessRate);
    setItemCess(taxTotal * (cessRate / 100));
  };

  const handleCessChange = (event) => {
    let cess = event?.target?.value;

    if (cess >= taxTotal) {
      return false;
    }
    setItemCess(cess);
    setItemCessRate((cess / taxTotal) * 100);
  };

  useEffect(() => {
    if (!cessEnabled) {
      setItemCess(0);
      setItemCessRate(0);
    }
  }, [cessEnabled]);

  useEffect(() => {
    let cessRateData = {
      fieldName: "cess_rate",
      fieldValue: itemCessRate,
      index,
      discountEnabled,
    };
    dispatch(updateItemFieldSubscription(cessRateData));

    let cessAmountData = {
      fieldName: "cess",
      fieldValue: itemCess,
      index,
      discountEnabled,
    };
    dispatch(updateItemFieldSubscription(cessAmountData));
  }, [itemCess, itemCessRate]);

  useEffect(() => {
    setItemCessRate(itemCessRate);
    setItemCess(taxTotal * (itemCessRate / 100));
  }, [taxTotal]);

  return (
    <>
      <td>
        <Form.Control
          type="number"
          name="cess_rate"
          placeholder="%"
          className="text-end"
          value={item?.cess_rate ? item?.cess_rate : ""}
          onChange={handleCessRateChange}
        />
      </td>
      <td>
        <Form.Control
          type="number"
          name="cess"
          placeholder="0.00"
          className="text-end"
          value={item?.cess ? item?.cess : ""}
          onChange={handleCessChange}
        />
      </td>
    </>
  );
};

export default SubscriptionCess;
