import CreateParty from "../screens/CreateParty"
import EditParty from "../screens/EditParty"
import PartyDetail from "../screens/PartyDetails"
import PartyIndex from "../screens/PartyIndex"
import PartyOverView from "../screens/PartyOverView"
import { i18n } from "../../shared/helpers/sharedMethods"
import PartyImport from "../screens/PartyImport"

const rootPath = 'parties'

export const partiesRoutes =  [
    {
        path: rootPath + '/allparties',
        element: <PartyIndex title={i18n('All Parties')}  type="All" path={rootPath+"/allparties"}/>,
        isProtected: true
    },
   
    {
        path: rootPath + '/overView',
        element: <PartyOverView />,
        isProtected: true
    },
    {
        path: rootPath+ '/allParties/create',
        element: <CreateParty title={i18n('Create Party')} type="All" path={rootPath+"/allparties"}/>,
        isProtected: true
    },
    {
        path: rootPath+ '/allParties/edit/:partyId',
        element: <EditParty title={i18n('Edit Party')} type="All" path={rootPath+"/allparties"} />,
        isProtected: true
    },
    {
        path: rootPath+ '/allParties/view/:partyId',
        element: <PartyDetail title={i18n('All Parties')} type="All" path={rootPath+"/allparties"} />,
        isProtected: true
    },
    {
        path: rootPath+ '/allParties/import',
        element: <PartyImport title={i18n('Import Parties')} type="All" path={rootPath+"/allparties"} />,
        isProtected: true
    },
    
]