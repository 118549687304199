import React, { useEffect, useState } from "react";
import {
  Route,
  useLocation,
  Navigate,
  Routes,
  useNavigate,
} from "react-router-dom";
import "./assets/sass/style.react.scss";
import "./assets/css/custom.css";
import "./assets/css/style.css";
import "./assets/css/frontend.css";
import { useDispatch, useSelector } from "react-redux";
import { IntlProvider } from "react-intl";
import { useHotkeys } from "react-hotkeys-hook";
import { resetCreateParty } from "./modules/party/store/PartyEntitySlice";
import CreateParty from "./modules/party/screens/CreateParty";
import {
  aboutDetails,
  changeToggleShow,
  keyboardShortcut,
  updateKeyboard,
} from "./modules/shared/store/sharedSlice";
import KeyboardModal from "./modules/shared/components/KeyboardModal";
import AboutModal from "./modules/shared/components/AboutModal";
import { getFiles } from "./locales/index";
import AppRouter from "./AppRouter";
import packageJson from "../package.json";
import {
  appPackedStatus,
  checkVersion,
  resetUpdateStatus,
  updateApp,
  updateAvailable,
  updateDownloaded,
} from "./modules/auth/store/configSlice";
import LogoutModal from "./modules/shared/components/LogoutModal";
import UpdateAppModal from "./modules/shared/components/UpdateAppModel";
import UpdateDownloaded from "./modules/shared/components/updateDownloaded";
import AppUpdateDownloading from "./modules/shared/components/appUpdateDownloading";
import ShortCutKeys from "./modules/settings/screens/ShortCutKeys";
import SyncQueue from "./modules/shared/components/SyncQueue";
import ViewLogs from "./modules/shared/components/ViewLogs";
import CustomShortCuts from "./modules/shared/components/SyncLogsDetails";
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { logout } from "./modules/auth/store/authSlice";
import { resetOrder } from "./modules/voucher/store/voucherEntitySlice";
import { resetVoucherItem } from "./modules/voucher/store/voucherItemsSlice";
import { resetCharges } from "./modules/voucher/store/additionalChargesSlice";
import { resetPartyAddress } from "./modules/party/store/partyAddressSlice";
import { resetPayment } from "./modules/payment/store/paymentEntitySlice";
import { resetPartyPayment } from "./modules/payment/store/partyPaymentsSlice";
import { resetCreateProduct } from "./modules/catalog/store/productEntitySlice";
import CreateProduct from "./modules/catalog/screens/CreateProduct";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "./ErrorBoundary";
// import 'primereact/resources/themes/saga-blue/theme.css';
// import 'primereact/resources/primereact.min.css';
// import 'primeicons/primeicons.css';
// import 'primeflex/primeflex.css';
function App() {
  //do not remove updateLanguag
  const dispatch = useDispatch();
  const { currentUser } = useSelector((state) => state.auth);
  const { isKeyboard, isAbout } = useSelector((state) => state.sharedSlice);

  const location = useLocation();
  const updateLanguage = "en";
  const updatedLanguage = "en";
  const language = useSelector(
    (state) => state.setting?.billing_settings?.language
  );
  //const { selectedLanguage, config, language } = useSelector( state => state )
  const [allLocales, setAllLocales] = useState({});
  const [messages, setMessages] = useState({});
  const [userEditedMessage, setUserEditedMessage] = useState({});

  const updateLanguag = allLocales[language] || allLocales[updateLanguage];

  const [languageData, setLanguageData] = useState([]);
  const [showUpdate, setShowUpdate] = useState(false);

  const isUpdateRequired = useSelector(
    (state) => state.config.isUpdateRequired
  );
  const isUpdateDownloading = useSelector(
    (state) => state.config.isUpdateDownloading
  );
  const isUpdateDownloaded = useSelector(
    (state) => state.config.isUpdateDownloaded
  );
  const packedtatus = useSelector((state) => state.config.packedStatus);


  const [showUpdateDownloading, setUpdateDownloading] = useState(false);
  const [showUpdateDownloaded, setShowUpdateDownloaded] = useState(false);
  const [isShortCutKeysVisible, setIsShortCutKeysVisible] = useState(false);
  const [showCreate, setShowCreate] = useState(false);
  const [showPartyCreate, setShowPartyCreate] = useState(false);

  const handleCancel = () => {
    navigate('/dashboard/sales')
  }
  const handleCreate = () => {
    dispatch(resetCreateProduct());
    setShowCreate(true);
  };

  const handleCeateClose = () => {
    setShowCreate(false);
  };

  useEffect(() => {
    const getData = getFiles();
    setAllLocales(getData);
  }, [language, updateLanguage?.lang_json_array]);

  useEffect(() => {
    if (updateLanguage?.iso_code === updatedLanguage && languageData) {
      setUserEditedMessage(updateLanguage?.lang_json_array);
    }
  }, [language, languageData]);


  // updated language hendling
  useEffect(() => {
    if (Object.values(userEditedMessage).length !== 0) {
      setMessages(userEditedMessage);
    } else {
      if (updateLanguage?.iso_code === updatedLanguage) {
        const updateLanguages = updateLanguage?.lang_json_array;
        setMessages(updateLanguages);
      } else {
        if (
          updateLanguag === undefined ||
          updateLanguag === null ||
          updateLanguag === ""
        ) {
          const defaultUpdateLanguage = allLocales["en"];
          setMessages(defaultUpdateLanguage);
        } else {
          if (updateLanguag === undefined || updateLanguag === null) {
            const defaultUpdateLanguage = allLocales["en"];
            setMessages(defaultUpdateLanguage);
          } else {
            setMessages(updateLanguag);
          }
        }
      }
    }
  }, [allLocales, updateLanguage?.lang_json_array]);

  useEffect(() => {
    dispatch(appPackedStatus());
  }, []);

  useEffect(() => {
    ;
    if (packedtatus) {
      dispatch(checkVersion(packageJson.version));
    }
  }, [packedtatus]);
  useEffect(() => {
    if (isUpdateRequired) {
      setShowUpdate(isUpdateRequired);
    }
  }, [isUpdateRequired]);

  /*     useEffect( () => {
          selectCSS();
      }, [ location.pathname ] );
  function App() {
  
      //do not remove updateLanguag
      const dispatch = useDispatch();
      const { currentUser } = useSelector(state => state.auth)
      const isUpdateRequired = useSelector(state => state.config.isUpdateRequired)
      const location = useLocation();
      const updateLanguage = 'en'
      const updatedLanguage = 'en'
      const language = useSelector((state) => state.setting?.billing_settings?.language);
      //const { selectedLanguage, config, language } = useSelector( state => state )
      const [allLocales, setAllLocales] = useState({})
      const [messages, setMessages] = useState({})
      const [userEditedMessage, setUserEditedMessage] = useState({})
      const updateLanguag = allLocales[language] || allLocales[updateLanguage];
      
      const [languageData, setLanguageData] = useState([])
      const [show, setShow] = useState(false);
  
      useEffect(() => {
          const getData = getFiles()
          setAllLocales(getData)
      }, [language, updateLanguage?.lang_json_array])
  
      useEffect(() => {
          if (updateLanguage?.iso_code === updatedLanguage && languageData) {
              setUserEditedMessage(updateLanguage?.lang_json_array)
          }
      }, [language, languageData])
  
      // React.useEffect(() => {
      //     ;
  
      //     dispatch(fetchProducts());
      //     dispatch(fetchsales());
  
      //   }, [])
      // updated language hendling
      useEffect(() => {
          if (Object.values(userEditedMessage).length !== 0) {
              setMessages(userEditedMessage)
          } else {
              if (updateLanguage?.iso_code === updatedLanguage) {
                  const updateLanguages = updateLanguage?.lang_json_array;
                  setMessages(updateLanguages)
              } else {
                  if (updateLanguag === undefined || updateLanguag === null || updateLanguag === '') {
                      const defaultUpdateLanguage = allLocales['en'];
                      setMessages(defaultUpdateLanguage)
                  } else {
                      if (updateLanguag === undefined || updateLanguag === null) {
                          const defaultUpdateLanguage = allLocales['en'];
                          setMessages(defaultUpdateLanguage)
                      } else {
                          setMessages(updateLanguag)
                      }
                  }
              }
          }
      }, [allLocales, updateLanguage?.lang_json_array])
  
  
      useEffect(() => {
          
          dispatch(checkVersion(packageJson.version))
      }, [])
  
      useEffect(() => {
          setShow(isUpdateRequired)
      }, [isUpdateRequired])
  
      /*     useEffect( () => {
              selectCSS();
          }, [ location.pathname ] );
      
      
          const selectCSS = () => {
              if ( updatedLanguage === 'ar' ) {
                  require( './assets/css/custom.rtl.css' );
                  require( './assets/css/style.rtl.css' );
                  require( './assets/css/frontend.rtl.css' );
              } else {
                  require( './assets/css/custom.css' );
                  require( './assets/css/style.css' );
                  require( './assets/css/frontend.css' );
              }
          }
  <<<<<<< HEAD
      }
  
      useEffect( () => {
          addRTLSupport( updatedLanguage ? updatedLanguage : selectedLanguage )
      }, [ updatedLanguage, selectedLanguage ] )
   */

  const navigate = useNavigate();
  const handleSales = () => {
    dispatch(resetOrder());
    dispatch(resetVoucherItem());
    dispatch(resetCharges());
    dispatch(resetPartyAddress());
    dispatch(changeToggleShow(true));
    navigate("voucher/sales-invoice/create");
  };
  const handleSalesReturn = () => {
    dispatch(resetOrder());
    dispatch(resetVoucherItem());
    dispatch(resetCharges());
    dispatch(resetPartyAddress());
    dispatch(changeToggleShow(true));
    navigate("voucher/sales-return/create");
  };
  const handlePurchase = () => {
    dispatch(resetOrder());
    dispatch(resetVoucherItem());
    dispatch(resetCharges());
    dispatch(resetPartyAddress());
    dispatch(changeToggleShow(true));
    navigate("voucher/purchase-invoice/create");
  };

  const handleQuotation = () => {
    dispatch(resetOrder());
    dispatch(resetVoucherItem());
    dispatch(resetCharges());
    dispatch(resetPartyAddress());
    dispatch(changeToggleShow(true));
    navigate("voucher/quotation/create");
  };
  const handlePurchaseReturn = () => {
    dispatch(resetOrder());
    dispatch(resetVoucherItem());
    dispatch(resetCharges());
    dispatch(resetPartyAddress());
    dispatch(changeToggleShow(true));
    navigate("voucher/purchase-return/create");
  };
  useHotkeys("alt+s", (event) => {
    event.preventDefault();
    handleSales();
  });
  useHotkeys("alt+c", (event) => {
    event.preventDefault();
    handleSalesReturn();
  });
  useHotkeys("alt+p", (event) => {
    event.preventDefault();
    handlePurchase();
  });
  useHotkeys("alt+r", (event) => {
    event.preventDefault();
    handlePurchaseReturn();
  });
  useHotkeys("alt+q", (event) => {
    ;
    event.preventDefault();
    handleQuotation();
  });
  useHotkeys("alt+shift+d", (event) => {
    event.preventDefault();
    // setIsShortCutKeysVisible(true);
    navigate('/shared/info')
  });

  useHotkeys("alt+o", (event) => {
    event.preventDefault();
    dispatch(resetPayment());
    dispatch(resetPartyPayment());
    navigate('/voucher/payment-out/create')
  });

  useHotkeys("alt+i", (event) => {
    event.preventDefault();
    dispatch(resetPayment());
    dispatch(resetPartyPayment());
    navigate('/voucher/payment-in/create')
  });

  useHotkeys("alt+m", (event) => {
    event.preventDefault();
    handleCreate();
  });

  useHotkeys("alt+y", (event) => {
    event.preventDefault();
    dispatch(resetCreateParty());
    dispatch(resetPartyAddress());
    setShowPartyCreate(true);
  });

  useHotkeys('alt+l', (event) => {
    event.preventDefault();
    navigate('/auth/lock-screen')
  });

  useEffect(() => {
    const fetchVersion = async () => {
      try {
        const appVersion = await window.ele.getVersion();
        const data = {
          isAbout: false,
          version: appVersion,
        };

        dispatch(aboutDetails(data));
      } catch (error) {
        console.error("Failed to get app version:", error);
      }
    }
    fetchVersion()
  }, []);

  // useEffect(() => {
  //   function appUpdateEvents() {
  //     return new Promise((resolve) => {
  //       window.config.updateAvailable(() => {
  //         ;
  //         resolve();
  //         dispatch(updateAvailable());
  //       });
  //       window.config.updateDownloaded(() => {
  //         ;
  //         resolve();
  //         dispatch(updateDownloaded());
  //       });
  //     });
  //   }
  //   appUpdateEvents();
  // }, []);

  const onAppUpdate = () => {
    ;
    dispatch(resetUpdateStatus());
    window.sync.killProcesses();
    dispatch(updateApp());
    //    dispatch(updateAvailable())
    //    setTimeout(()=>{
    //     dispatch (updateDownloaded())
    //    },1000)
    //just for now
    //setShowUpdate(false)
  };



  return (
    <div
      className="d-flex flex-column flex-root"
      style={{ height: "100vh", overflow: "hidden" }}
    >
      <ErrorBoundary FallbackComponent={ErrorFallback} >
        <IntlProvider locale="en" messages={messages}>
          <AppRouter />
          {/* <Toasts language="EN" /> */}

          {isKeyboard && <KeyboardModal show={isKeyboard} />}
          {isAbout && <AboutModal show={isAbout} />}
          {showUpdate && (
            <UpdateAppModal
              show={showUpdate}
              onAppUpdate={onAppUpdate}
              isUpdateDownloading={isUpdateDownloading}
              isUpdateDownloaded={isUpdateDownloaded}
              setShowUpdate={setShowUpdate}
            />
          )}
          {isShortCutKeysVisible && (
            <CustomShortCuts
              show={isShortCutKeysVisible}
              setShow={setIsShortCutKeysVisible}
            />
          )}
          {/* {isUpdateDownloading && <AppUpdateDownloading show={isUpdateDownloading} />} */}
          {/* <UpdateDownloaded show={isUpdateDownloaded} /> */}
          <ConfirmDialog acceptClassName="danger" />
          {showCreate && (
            <CreateProduct
              show={showCreate}
              handleClose={handleCeateClose}
              setShowCreate={setShowCreate}
            />
          )}
          {showPartyCreate && (
            <CreateParty
              show={showPartyCreate}
              setShowPartyCreate={setShowPartyCreate}
              type="customer"
            />
          )}
        </IntlProvider>
      </ErrorBoundary>
    </div>
  );
}

export default App;
