import {
    createSlice,
    createAsyncThunk,
  } from "@reduxjs/toolkit";
import { i18n } from "../../shared/helpers/sharedMethods";

  const initialState = {
    status:false,
    reportItems:[],
    error:false,
    errorMessage:{}
  };

export const fetchProductSummaryChart = createAsyncThunk('productSummaryChart/fetch', async (param) => {
  try{
    const productSummaryChartResponse  = await window.api.productSummaryChart(param);
    return productSummaryChartResponse.data;
  }catch(err){
    await window.log.logToFile({message: "Unable to fetch Product Summary", level: "error" });
    throw new Error("Error while fetching product Summary Chart")
  }
});

const productSummaryChartSlice = createSlice({
    name: "productSummaryChart",
    initialState,
    reducers: {},
    extraReducers(builder) {
      builder
      .addCase(fetchProductSummaryChart.fulfilled, (state, action) => {
        state.status = true;
        state.reportItems =action.payload;
      })
      .addCase(fetchProductSummaryChart.rejected, (state, action) => {
        state.error = true; 
        state.errorMessage = i18n('Unable to retrieve product summary. Please try again.');
        state.status = false;
      })

    },
});

export default productSummaryChartSlice.reducer;
