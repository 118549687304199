import React, { useState } from "react";
import { CardBody, Nav, Tab } from "react-bootstrap";

const ItemTabs = ({ tabs, print, pdf,title }) => {

  let defaultKey;

  if(title){
  if (tabs[0].key) {
    defaultKey = tabs[0].key;
  } else if (tabs[0].key) {
    defaultKey = tabs[0].key;
  } else if (tabs[2].key) {
    defaultKey = tabs[2].key;
  }
  }else {
    if (tabs[0].key) {
      defaultKey = tabs[0].key;
    } else if (tabs[1].key) {
      defaultKey = tabs[1].key;
    } else if (tabs[2].key) {
      defaultKey = tabs[2].key;
    }
  }
  const [key, setKey] = useState(defaultKey);

  return (
    <>
      <CardBody className="fs-6" style={{borderRadius:'5px'}}>
        <Tab.Container defaultActiveKey={key} >
          <Nav className="" variant="underline" style={{borderBottom:'2px solid lightgray', lineHeight: '30px'}}>
            {tabs &&
              tabs.map((tabItem, index) => {
                return (
                  <Nav.Item key={tabItem.key}>
                    <Nav.Link
                      className="nav-link fs-4"
                      // key={index}
                      eventKey={tabItem.key}
                      onSelect={(k) => setKey(k)}
                    >
                      {tabItem.icon} {tabItem.title}
                    </Nav.Link>
                  </Nav.Item>
                );
              })}
          </Nav>
          <Tab.Content
            className=""
             style={{ height: "65vh", overflow: "auto", overflowX: "hidden" }}
          >
            {tabs &&
              tabs.map((tabItem, index) => {
                return (
                  <Tab.Pane
                    ref={print ? print : pdf}
                    key={index}
                    eventKey={tabItem.key}
                    onClick={() => tabItem.key}
                  >
                    {tabItem.component}
                  </Tab.Pane>
                );
              })}
          </Tab.Content>
        </Tab.Container>
      </CardBody>
    </>
  );
};

export default ItemTabs;
