import VoucherIndex from "../screens/VoucherIndex";
import CreateVoucher from "../screens/CreateVoucher";
import EditVoucher from "../screens/EditVoucher";
import VoucherDetail from "../components/VoucherDetails";
import { i18n } from "../../shared/helpers/sharedMethods";
import VoucherInvoiceView from "../components/VoucherInvoiceView";
import VoucherImport from "../screens/VoucherImport";

const rootPath = "voucher";

export const salesReturnRoutes = [
  {
    path: rootPath + "/sales-return",
    element: (
      <VoucherIndex
        title={i18n("Sales Return")}
        type="sales_return"
        button={i18n("Create Sales Return")}
        voucherType="Sales"
        path={rootPath + "/sales-return"}
      />
    ),
    isProtected: true,
  },
  {
    path: rootPath + "/sales-return/create",
    element: (
      <CreateVoucher
        voucherType="Sales"
        title={i18n("Create Sales Return")}
        noLabel={true}
        type="sales_return"
        path={rootPath + "/sales-return"}
        no="Sales Return No"
        date="Sales Return Date"
      />
    ),
    isProtected: true,
  },
  {
    path: rootPath + "/sales-return/edit/:id",
    element: (
      <EditVoucher
        path={rootPath + "/sales-return"}
        title={i18n("Edit Sales Return")}
        type="sales_return"
      />
    ),
    isProtected: true,
  },
  // {
  //   path: rootPath + "/sales-return/view/:id",
  //   element: (
  //     <VoucherDetail
  //       path={rootPath + "/sales-return"}
  //       button={i18n("Create Sales Return")}
  //       type="sales_return"
  //       title={i18n("Manage Sales Return")}
  //     />
  //   ),
  //   isProtected: true,
  // },
  {
    path: rootPath + "/sales-return/view/:id",
    element: (
      <VoucherInvoiceView
        path={rootPath + "/sales-return"}
        button={i18n("Create Sales Return")}
        type="sales_return"
        title={i18n("Manage Sales Return")}
      />
    ),
    isProtected: true,
  },
  {
    path: rootPath + "/sales-return/import",
    element: (
      <VoucherImport
      path={rootPath + "/sales-return"}
      type="sales_return"
      title={i18n("Import Sales Return")}
      voucherType="Sales"
      />
    ),
    isProtected: true,
  },
];
