import React from "react";
import { useSelector } from "react-redux";
import { Card, Stack } from "react-bootstrap";
import { i18n } from "../../../shared/helpers/sharedMethods";
import { UilHistoryAlt } from "@iconscout/react-unicons";

const RepeatCustomerBlock = () => {
  const reportItems = useSelector((state) => state.repeatCustomer?.reportItems);

  return (
    <Card
      className="h-100 shadow-sm border"
      style={{ backgroundColor: "", lineHeight: "5px", minHeight: "155px" }}
    >
      <Card.Body className="d-flex justify-content-center text-start flex-column p-md-5 p-3">
        <span className="mb-3 mb-md-5">
          <UilHistoryAlt style={{ color: "#C3961B" }} />
        </span>
        <span
          className=" fs-responsive  mb-8 mt-2"
          style={{ color: "GrayText" }}
        >
          {i18n("Repeat Customer")}
        </span>
        <Stack direction="vertical" gap={3}>
          <span className="fs-responsive-datas fw-bolder mb-3 mb-md-4">
            {reportItems ? reportItems?.length : 0}
          </span>
        </Stack>
      </Card.Body>
    </Card>
  );
};

export default RepeatCustomerBlock;
