import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from "@reduxjs/toolkit";
import { i18n } from "../../shared/helpers/sharedMethods";
import { cloneDeep } from "lodash";
import { axiosRequest } from "../../shared/helpers/axiosHelper";

const initialState = {
  party: {
    id: "",
    default_address_id: null,
    name: "",
    phone_number: "",
    email: "",
    type: "customer",
    category_id: null,
    gstin: "",
    pan: "",
    opening_balance: 0,
    ledger_type: "toCollect",
    place_of_supply: "",
    created_by: null,
    updated_by: null,
    is_same_as_billing: false,
    party_address: [],
  },

  savedPartyEntity: {},
  status: false,
  errorStatus: false,
  updateStatus: false,
  error: {},
  importData: {},
};

export const createParty = createAsyncThunk(
  "createParty/create",
  async (args) => {
    try {
      const token = args.token;
      const data = args.data;
      const tenantId = args.tenant_id;
      const resourceType = 'party';
      const terminalNumber = args.terminal_id;

      const createPartyResponse = await axiosRequest({
        method: "POST",
        url: `resource/${resourceType}/create/${terminalNumber}`,
        data: data,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "X-Tenant": tenantId,
        },
      });
      if (createPartyResponse.success) {
        return createPartyResponse.data;
      } else {
        const errorMessage = createPartyResponse?.data
          ?.map((d) => d.message)
          .join(", ");
        console.error(errorMessage);
        throw new Error(errorMessage);
      }
    } catch (error) {
      console.error("Error while creating Party:", error);
      await window.log.logToFile({
        message: "Error while creating Party",
        level: "error",
      });
      throw new Error(error);
    }
  }
);

export const updateParty = createAsyncThunk("party/fetch", async (args) => {
  try {
    console.log('args',args);
        const token = args.token;
        const data = args.data;
        const tenantId = args.tenant_id;
        const resourceType = 'party';
        const terminalNumber = args.terminal_id;

    const updatePartyResponse = await axiosRequest({
        method: "POST",
        url: `resource/${resourceType}/create/${terminalNumber}`,
        data: data,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "X-Tenant": tenantId,
        },
      });
    if (updatePartyResponse.success) {
      return updatePartyResponse.data;
    } else {
      const errorMessage = updatePartyResponse?.data
        ?.map((d) => d.message)
        .join(", ");
      throw errorMessage;
    }
  } catch (error) {
    await window.log.logToFile({
      message: "Error while updating Party",
      level: "error",
    });
    throw new Error(error);
  }
});

const partyEntitySlice = createSlice({
  name: "partyEntity",
  initialState,
  reducers: {
    changeParty(state, action) {
      const { value, name } = action.payload;
      if (name === "name") {
        state.party.name = value;
      } else if (name === "phone_number") {
        state.party.phone_number = value;
      } else if (name === "email") {
        state.party.email = value;
      } else if (name === "category_id") {
        state.party.category_id = value;
      } else if (name === "gstin") {
        state.party.gstin = value;
      } else if (name === "pan") {
        state.party.pan = value;
      } else if (name === "type") {
        state.party.type = value;
      } else if (name === "opening_balance") {
        state.party.opening_balance = parseFloat(value);
      } else if (name === "ledger_type") {
        state.party.ledger_type = value;
      } else if (name === "place_of_supply") {
        state.party.place_of_supply = value;
      }
    },
    updatePartyAddress(state, action) {
      const { allAddress, data } = action.payload;
      if (!Array.isArray(state.party.party_address)) {
        state.party.party_address = [];
      }

      const items = allAddress && allAddress?.map((item) => {
        const formattedItem = {
          id: item.id,
          name: item.name,
          phone_number: item.phone_number,
          label: item.label,
          status: item.status,
          address: item.address,
          city: item.city,
          pincode: item.pincode,
          state: item.state,
          created_by: data.created_by,
          updated_by: data.updated_by,
          created_at: data.created_at,
        };

        const existingIndex = (state.party.party_address || []).findIndex(
          (addr) => addr.id === item.id
        );

        if (existingIndex !== -1) {
          state.party.party_address[existingIndex] = {
            ...state.party.party_address[existingIndex],
            ...formattedItem,
          };
        } else {
          state.party.party_address.push(formattedItem);
        }
        state.updateStatus = true;
        return formattedItem;
      });
    },
    updateAddressStatus(state, action) {
      const id = action.payload;

      if (Array.isArray(state?.party?.party_address)) {
        const existingIndex = state.party.party_address.findIndex(
          (addr) => addr.status === true
        );

        if (existingIndex !== -1) {
          state.party.party_address[existingIndex].status = false;
        }

        const newIndex = state.party.party_address.findIndex(
          (addr) => addr.id === id
        );

        if (newIndex !== -1) {
          state.party.party_address[newIndex].status = true;
        }
      }
    },

    initPartyEdit(state, action) {
      let tempParty = action.payload;

      if (tempParty) {
        state.party.id = tempParty?.id ? tempParty?.id : null;
        state.party.name = tempParty?.name ? tempParty?.name : "";
        state.party.phone_number = tempParty?.phone_number
          ? tempParty?.phone_number
          : "";
        state.party.pan = tempParty?.pan ? tempParty?.pan : "";
        state.party.gstin = tempParty?.gstin ? tempParty?.gstin : "";
        state.party.email = tempParty?.email ? tempParty?.email : "";
        state.party.credit_limit = tempParty?.credit_limit
          ? tempParty?.credit_limit
          : 0;
        state.party.credit_period = tempParty?.credit_period
          ? tempParty?.credit_period
          : 0;
        state.party.category_id = tempParty?.category_id
          ? tempParty?.category_id
          : 0;
        state.party.type = tempParty?.type ? tempParty?.type : 0;
        state.party.opening_balance = tempParty?.opening_balance
          ? tempParty?.opening_balance
          : 0;
        state.party.ledger_type =
          tempParty?.opening_balance < 0 ? "toPay" : "toCollect";
        state.party.place_of_supply = tempParty?.place_of_supply
          ? tempParty?.place_of_supply
          : "";
        const items =
          tempParty?.party_addresses?.length > 0 &&
          tempParty?.party_addresses?.map((item, index) => {
            const formattedItem = {
              id: index + 1,
              label: item?.label,
              status: tempParty?.default_address_id == item?.id ? true : false,
              name: item?.name,
              phone_number: item?.phone_number,
              address: item?.address,
              city: item?.city,
              pincode: item?.pincode,
              state: item?.state,
              created_by: item?.created_by,
              updated_by: item?.updated_by,
              created_at: item?.created_at,
            };

            return formattedItem;
          });
        state.party.party_address = items;
      }
    },

    undoError(state, action) {
      state.errorStatus = false;
      state.error = "";
    },
    resetStatus: (state, action) => {
      state.status = false;
    },
    removeAddress(state, action) {
      const id = action.payload;
      const index = state.party.party_address.findIndex(
        (address) => address.id === id
      );
      if (index !== -1) {
        state.party.party_address.splice(index, 1);
      }
    },
    updateAddress(state, action) {
      state.updatePartyAddress = true;
    },
    resetUpdateStatus(state, action) {
      state.updateStatus = false;
    },
    resetCreateParty: () => initialState,
  },
  extraReducers(builder) {
    builder
      .addCase(createParty.fulfilled, (state, action) => {
        state.createStatus = true;
        state.savedPartyEntity = action.payload;
      })
      .addCase(createParty.rejected, (state, action) => {
        state.errorStatus = true;
        state.error = action?.error?.message;
      })
      .addCase(updateParty.fulfilled, (state, action) => {
        state.editStatus = true;
        state.savedPartyEntity = action.payload;
      })
      .addCase(updateParty.rejected, (state, action) => {
        state.errorStatus = true;
        state.error = action?.error?.message;
      });
  },
});

export default partyEntitySlice.reducer;

export const {
  resetCreateParty,
  changeParty,
  initPartyEdit,
  undoError,
  resetStatus,
  updatePartyAddress,
  removeAddress,
  updateAddress,
  resetUpdateStatus,
  updateAddressStatus,
} = partyEntitySlice.actions;
