import { Col, Form, FormControl, Row } from "react-bootstrap";

import { useDispatch } from "react-redux";
import { changeName } from "../../../store/subscription_item_store/SetupFeesEntitySlice";
import { i18n, placeholderText } from "../../../../shared/helpers/sharedMethods";
import { changePlanExpiresAfter, changePlanRadioButtonvalue } from "../../../store/subscription_item_store/PlanEntitySlice";

const PlanIntervalType = ({ values, handleChange, touched, errors }) => {
  const dispatch = useDispatch();
  return (
    <Row className="py-4">
        <Col lg={3} className="">
          <Form.Group>
            <Form.Label>{i18n("Addon Type")} </Form.Label>
            <span className="required" />
          </Form.Group>
        </Col>
        <Col lg={4}>
          <Row>
            <Col className="py-1" lg={12}>
              <Form.Check
                type="radio"
                name="bill_cycle"
                id="inlineRadio1"
                label={placeholderText("Auto-renews until canceled")}
                value={0}
                checked={values?.is_value == 0}
                onChange={(e) => {
                  handleChange(e);
                  dispatch(changePlanExpiresAfter(0));
                  dispatch(changePlanRadioButtonvalue(e.target.value));
                }}
              />
            </Col>
            <Col className="py-1" lg={12}>
              <Form.Check
                type="radio"
                name="bill_cycle"
                id="inlineRadio1"
                value={1}
                label={placeholderText(
                  "Expires after a specified no. of billing cycle"
                )}
                checked={values?.is_value == 1}
                onChange={(e) => {
                  handleChange(e);
                  dispatch(changePlanRadioButtonvalue(e.target.value));
                }}
              />
            </Col>
          </Row>
        </Col>
      </Row>
  );
};

export default PlanIntervalType;