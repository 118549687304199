import { Navigate } from "react-router-dom";

const ProtectedRoute = ({isLoggedIn,permissions,children}) => {

    if (!isLoggedIn || !permissions) {
        return <Navigate to='/' replace />;
    }
    return children;
};

export default ProtectedRoute;
