import CreateSubscription from "../screens/subscription_screen/CreateSubscription"
import EditSubscription from "../screens/subscription_screen/EditSubscription"
import SubscriptionStack from "../screens/subscription_screen/SubscriptionStack"
import SubscriptionView from "../screens/subscription_screen/SubscriptionView"
import SubscriptionInvoiceView from "../components/subscription_order/SubscriptionInvoiceView"
import SubscriptionAllInvoiceView from "../components/SubscriptionAllInvoiceView"
import SubscriptionOverview from "../screens/subscription_screen/SubscriptionOverview"
import SubscriptionInvoice from "../screens/subscription_screen/SubscriptionInvoice"



const rootPath = 'subscription'
const featureName = 'subscription';


export const MembershipRoutes = [
    {
        path: rootPath + '/membership',
        element: <SubscriptionStack featureName={featureName}/>,
        isProtected: true
    },
    {
        path: rootPath + '/membership/create',
        element: <CreateSubscription />,
        isProtected: true
    },
    {
        path: rootPath + '/membership/edit/:id',
        element: <EditSubscription />,
        isProtected: true
    },
    // {
    //     path : rootPath + '/membership/view/:id',
    //     element: <SubscriptionView />,
    //     isProtected: true
    // },
    {
        path : rootPath + '/membership/view/:id',
        element: <SubscriptionInvoiceView />,
        isProtected: true
    },
    {
        path: rootPath + '/overview',
        element: <SubscriptionOverview />,
        isProtected: true
    },
    {
        path: rootPath + '/invoices',
        element: <SubscriptionInvoice featureName={featureName}/>,
        isProtected: true
    },
    {
        path : rootPath + '/invoice/view/:id',
        element: <SubscriptionAllInvoiceView />,
        isProtected: true
    },
]