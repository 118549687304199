import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, Col, Row, Stack } from "react-bootstrap";
import ReportPeriod from "../../../../shared/components/ReportPeroid";
import ReportStatusCollectAndPay from "./ReportStatusCollectAndPay";
import Chart from "./Chart";
import IncomeAndExpenseAmount from "./IncomeAndExpenseAmount";
import { fetchProductCategoryFilterChart } from "../../../store/productCategoryFilterChartSlice";
import DateRangeIndex from "../../../../shared/components/date_range/DateRangeIndex";
import { i18n } from "../../../../shared/helpers/sharedMethods";

function ProductCategoryFliterChartIndex() {
  const dispatch = useDispatch();

  const [reportPeriod, setReportPeriod] = useState("current-week");
  const [labelName, setLabelName] = useState([]);
  const [salesAmount, setSalesAmount] = useState([]);
  useEffect(() => {
    dispatch(fetchProductCategoryFilterChart(reportPeriod));
  }, [reportPeriod]);

  const reportItems = useSelector(
    (state) => state.productCategoryFilterChart.reportItems
  );
  useEffect(() => {
    if (Array.isArray(reportItems)) {
      const categoryName = reportItems && reportItems.map(item => item.categoryName.charAt(0).toUpperCase() + item.categoryName.slice(1));
      setLabelName(categoryName);
      const totalValue = reportItems && reportItems.map(item => item.totalValue);
      setSalesAmount(totalValue);
    }
  }, [reportPeriod, reportItems]);


  return (
    <Card className=" border mb-4" style={{ minHeight: "330px" }}>

      <Card.Body className="p-1">
      <Stack direction="horizontal" className="ms-auto p-2">
          <Col md={7} className="fs-4" style={{ fontSize: "14px" }}>
          {i18n("Top 5 Categories(Product)")}
          </Col>
          <Col md={5} className="">
            <ReportPeriod
              reportPeriod={reportPeriod}
              setReportPeriod={setReportPeriod}
            />
          </Col>
        </Stack>
        <Row className=" ">
          <Col xl={7} md={7} sm={7}>
            <Chart
              labelName={labelName}
              salesAmount={salesAmount}
            />
          </Col>
          <Col xl={5} md={5} sm={5} className="pt-5">
            <DateRangeIndex reportPeriod={reportPeriod} />
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
}

export default ProductCategoryFliterChartIndex;
