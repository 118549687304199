import React from "react";
import { Col, Form, InputGroup, Modal, Row } from "react-bootstrap";
import { keyboardShortcut, updateKeyboard } from "../store/sharedSlice";
import { useDispatch } from "react-redux";
import { i18n } from "../../shared/helpers/sharedMethods";

const KeyboardModal = ({ show }) => {
  const dispatch = useDispatch();
  const clearField = () => {
    dispatch(updateKeyboard(false));
  };

  const createKey = [
    {
      text: "Create",
      items: [
        {
          label: "Sales Invoice",
          key: "Alt + S",
        },
        {
          label: "Purchase Invoice",
          key: "Alt + P",
        },
        {
          label: "Payment In",
          key: "Alt + I",
        },
        {
          label: "Payment Out",
          key: "Alt + O",
        },
        {
          label: "Sales return",
          key: "Alt + C",
        },
        {
          label: "Purchase Return",
          key: "Alt + R",
        },
        {
          label: "Quotations",
          key: "Alt + Q",
        },
        {
          label: "Party",
          key: "Alt + Y",
        },
        {
          label: "Product",
          key: "Alt + M",
        },
      ],
    },
  ];
  const createAction = [
    {
      text: "Create Invoice Form Actions",
      items: [
        {
          label: "Add Party",
          key: "Shift + Y",
        },
        {
          label: "Add Item",
          key: "Shift + M",
        },
        {
          label: "Save",
          key: "Alt + Enter",
        },
        {
          label: "cancel",
          key: "Escape",
        },
        {
          label: "Go to next field",
          key: "Tab",
        },
        {
          label: "Go to prev field",
          key: "Shift + Tab",
        },
      ],
    },
  ];
  return (
    <>
      <Modal
        className="modal-lg"
        show={show}
        onHide={clearField}
        keyboard={true}
        dialogClassName="modal-dialog modal-dialog-centered"
      >
        <Modal.Header closeButton>
          <Modal.Title style={{ fontSize: "14px", fontWeight: 600 }}>
          {i18n("Keyboard Shortcuts")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row style={{ border: "1px solid lightgray" }}>
            {createKey.map((section, index) => (
              <Col lg={6}>
                <Form.Group
                  className="p-2 mb-3"
                  style={{ borderBottom: "1px solid lightgray" }}
                >
                  <Form.Label style={{ fontSize: "14px", fontWeight: 600 }}>
                    {section.text}
                  </Form.Label>
                </Form.Group>
                {section.items.map((item) => (
                  <Row className="p-2 ">
                    <Col lg={8}>
                      <Form.Group>
                        <Form.Label>{item.label}</Form.Label>
                      </Form.Group>
                    </Col>
                    <Col md={4}>
                      <InputGroup.Text
                        className="px-3 py-0"
                        style={{ textAlign: "center" }}
                      >
                        {item.key}
                      </InputGroup.Text>
                    </Col>
                  </Row>
                ))}
              </Col>
            ))}
            {createAction.map((section, index) => (
              <Col lg={6} style={{ borderLeft: "1px solid lightgray" }}>
                <Form.Group
                  className="p-2 mb-3"
                  style={{ borderBottom: "1px solid lightgray" }}
                >
                  <Form.Label style={{ fontSize: "14px", fontWeight: 600 }}>
                    {section.text}
                  </Form.Label>
                </Form.Group>
                {section.items.map((item) => (
                  <Row className="p-2">
                    <Col lg={8}>
                      <Form.Group>
                        <Form.Label>{item.label}</Form.Label>
                      </Form.Group>
                    </Col>
                    <Col md={4}>
                      <InputGroup.Text
                        className="px-3 py-0"
                        style={{ textAlign: "center" }}
                      >
                        {item.key}
                      </InputGroup.Text>
                    </Col>
                  </Row>
                ))}
              </Col>
            ))}
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default KeyboardModal;
