  import { createSlice, createAsyncThunk, createEntityAdapter  } from "@reduxjs/toolkit";
import { axiosRequest } from "../../shared/helpers/axiosHelper";

const subscriptionAttendanceAdapter = createEntityAdapter();

const initialState = subscriptionAttendanceAdapter.getInitialState({
    status: false,
    error: null,
});

export const fetchSubscriptionAttendance = createAsyncThunk(
    'subscriptionAttendance/fetch',
    async ({ id, token, tenantId }) => {
        console.log('id',id)
        try {
            const response = await axiosRequest({
                method: "GET",
                url: `subscriptions/attendance/${id}`,
                headers: {
                    Authorization: `Bearer ${token}`,
                    "X-Tenant": tenantId,
                },
            });
            console.log('Axios attend response:', response);
            return response.data;
        } catch (error) {
            await window.log.logToFile({ message: "Error while fetching subscription attendance", level: "error" });
            throw new Error('Error fetching subscription attendance');
        }
    }
);

export const fetchSubscriptionAttendanceByMember = createAsyncThunk(
    'subscriptionAttendance/fetchAttendance',
    async ({ id, token, tenantId }) => {
        try {
            const response = await axiosRequest({
                method: "GET",
                url: `subscriptions/member-attendance/${id}`,
                headers: {
                    Authorization: `Bearer ${token}`,
                    "X-Tenant": tenantId,
                },
            });
            console.log('Axios attend response:', response);
            return response.data;
        } catch (error) {
            await window.log.logToFile({ message: "Error while fetching subscription attendance by member", level: "error" });
            throw new Error('Error fetching subscription attendance by member');
        }
    }
);

const subscriptionAttendanceSlice = createSlice({
    name: 'subscriptionAttendance',
    initialState,
    reducers: {
        resetSubscriptionAttendance: () => initialState,
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchSubscriptionAttendance.fulfilled, (state, action) => {
                state.status = true;
                subscriptionAttendanceAdapter.upsertMany(state, action.payload);
                console.log('Fetched attendance:', action.payload);
            })
            .addCase(fetchSubscriptionAttendance.rejected, (state, action) => {
                state.status = false;
                state.error = action.error.message;
            })
            .addCase(fetchSubscriptionAttendanceByMember.fulfilled, (state, action) => {
                console.log('state',state.status);
                state.status = true;
                subscriptionAttendanceAdapter.upsertMany(state, action.payload);
                console.log('Fetched member based attendance:', action.payload);
            })
            .addCase(fetchSubscriptionAttendanceByMember.rejected, (state, action) => {
                state.status = false;
                state.error = action.error.message;
            });
    },
});

export default subscriptionAttendanceSlice.reducer;

export const {
    resetSubscriptionAttendance,
} = subscriptionAttendanceSlice.actions;

export const {
    selectAll: selectAllSubscriptionAttendances,
    selectById: selectSubscriptionAttendanceById,
    selectIds: selectSubscriptionAttendanceIds,
} = subscriptionAttendanceAdapter.getSelectors((state) => state.subscriptionAttendance);
