import React from "react";
import { useSelector } from "react-redux";
import { Card, Stack } from "react-bootstrap";
import { i18n } from "../../../shared/helpers/sharedMethods";
import { UilPercentage } from "@iconscout/react-unicons";
import GlobalCurrency from "../../../shared/components/GlobalCurrency";

const AvgerageOrderValue = () => {

  const reportItems = useSelector(
    (state) => state.orderAndRevenue?.reportItems
  );
  return (
    <Card
      className="shadow-sm border"
      style={{ backgroundColor: "", lineHeight: "5px", minHeight: "155px" }}
    >
      <Card.Body className="d-flex justify-content-center text-start flex-column p-md-5 p-3">
        <span className="mb-3 mb-md-5">
          <UilPercentage style={{ color: "rgb(164 147 245)" }} />
        </span>
        <span
          className="fs-responsive  mb-8 mt-2"
          style={{ color: "GrayText" }}
        >
          {i18n("Avg.Order")}
        </span>

        {Array.isArray(reportItems) && reportItems.length > 0 && (
          <Stack direction="vertical" gap={3}>
            {reportItems.map((value, index) => (
              <span key={value.id || index} className=" fs-responsive-datas fw-bolder mb-3 mb-md-4">
                <GlobalCurrency price={value?.avgOrderValue} />
              </span>
            ))}
          </Stack>
        )}
      </Card.Body>
    </Card>
  );
};

export default AvgerageOrderValue;
