import React from "react";
import { Row, Col, Container, Card } from "react-bootstrap";
import ItemHeader from "../item-detail/sections/ItemHeader";
import InvoiceTab from "./invoiceTab";

const InvoiceLayout = ({
  items,
  itemStack,
  type,
  tabs,
  ItemTitle,
  ItemActions,
  returnToGrid,
  print,
  pdf,
  header,
}) => {
  ;
  return (
    <Row className="">
      <Col lg={3} md={3} xs={3}>
        {itemStack &&
        <Card className="border" style={{ borderRadius: "5px" }}>
          {itemStack}
        </Card>
}
      </Col>
      <Col
        className="border"
        lg={itemStack ? 9 : 12}
        md={9}
        xs={8}
        style={{ background: "white" }}
      >
        {header}
        <InvoiceTab print={print} pdf={pdf} tabs={tabs} type={type}/>
      </Col>
    </Row>
  );
};

export default InvoiceLayout;
