import React from "react";
import {  Container, Row, Col } from "react-bootstrap";
import GeneralDetails from "./item_info/GeneralDetails";
import PricingDetails from "./item_info/PricingDetails";




const ItemInfo = ({selectedProduct,currencySymbol}) => {


    return (
        <Container className='p-0 mt-5' fluid>
            <Row>
                <Col lg={6} md={6} sm={6}>
                    <GeneralDetails selectedProduct={selectedProduct} />
                </Col>
                <Col lg={6} md={6} sm={6}>
                   <PricingDetails selectedProduct={selectedProduct}  />

                </Col>
            </Row>
        </Container>
    )
}




export default ItemInfo;