import {
    createSlice,
    createAsyncThunk,
  } from "@reduxjs/toolkit";
import { i18n } from "../../shared/helpers/sharedMethods";
import { axiosRequest } from "../../shared/helpers/axiosHelper";

const initialState = {
  status:false,
  reportItems:[],
  error:false,
  errorMessage:'',
  loading:false,
};

export const fetchDayBookReport = createAsyncThunk(
    "dayBookReport/fetch",
    async ({ token, tenant_id, reportType, startDate, endDate }) => {
      try{
      const dayBookReportResponse = await axiosRequest({
        method: "GET",
        url: `get-day-book-report`,
        params: { startDate, endDate, reportType },
        headers: { Authorization: `Bearer ${token}`, "X-Tenant": tenant_id },
      });
      return dayBookReportResponse.data;
    }catch(err){
      await window.log.logToFile({message: "Error while fetching daybook report", level: "error" });
      throw new Error("Error while fetching Daybook report")
    }
    }
  );

const dayBookReportSlice = createSlice({
    name: "dayBookReport",
    initialState,
    reducers: {},
    extraReducers(builder) {
      builder
      .addCase(fetchDayBookReport.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchDayBookReport.fulfilled, (state, action) => {
        state.status = true;
        state.loading = false;
        state.reportItems = action.payload;
      })
      .addCase(fetchDayBookReport.rejected, (state, action) => {
          state.error = true; 
          state.loading = false;
          state.errorMessage = (i18n("Error while fetching day book report. Please try again later."));
          state.status = false;
      })
    },
});

export default dayBookReportSlice.reducer;
