import {
    createSlice,
    createAsyncThunk,
  } from "@reduxjs/toolkit";
  import { axiosRequest } from "../../shared/helpers/axiosHelper";


const initialState = {
  status:false,
  reportItems:[],
  error:false,
  errorMessage:'',
  loading:false,
};

export const fetchGSTR2PurchaseReport = createAsyncThunk(
    "gstr2PurchaseReport/fetch",
    async ({ token, tenant_id, startDate, endDate, reportType }) => {
      try {
        const response = await axiosRequest({
          method: "GET",
          url: `get-gstr-2-purchase-report`,
          params: { startDate, endDate, reportType },
          headers: { Authorization: `Bearer ${token}`, "X-Tenant": tenant_id },
        });
        return response.data;
      } catch(err){
      await window.log.logToFile({message: "Error while fetching GSTR2 sales report", level: "error" });
      throw new Error("Error while fetching gstr-2 purchase report")
    }
    }
  );

const gstr2PurchaseReportSlice = createSlice({
    name: "gstr2PurchaseReport",
    initialState,
    reducers: {},
    extraReducers(builder) {
      builder
      .addCase(fetchGSTR2PurchaseReport.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchGSTR2PurchaseReport.fulfilled, (state, action) => {
        state.status = true;
        state.loading = false;
        state.reportItems = action.payload;
      })
      .addCase(fetchGSTR2PurchaseReport.rejected, (state, action) => {
          state.error = true; 
          state.loading = false;
          state.errorMessage = action.error.message
          state.status = false;
      })
    },
});

export default gstr2PurchaseReportSlice.reducer;
