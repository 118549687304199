import { Form } from "react-bootstrap";
import React from "react";
import { useSelector } from "react-redux";
import { selectAllparty } from "../../../party/store/getPartySlice";
import { placeholderText } from "../../../shared/helpers/sharedMethods";


const PartyFilter = (props) => {
   
    const {partyData,setPartyData} = props;

    const filteredParties = useSelector(selectAllparty);

    console.log('filteredParties',filteredParties)
    return (
        <Form.Select
            className="rounded-0"
            value={partyData}
            onChange={(e) => setPartyData(e.target.value)}
            style={{ cursor: "pointer" }}
        >
            <option value="" disabled selected hidden>
             {placeholderText("Select Party")}
            </option>
            <option value="">{placeholderText("All Party")}</option>
            {filteredParties && filteredParties.length > 0 ? (
                filteredParties.map(survey => (
                    <option key={survey.name} value={survey.id}>
                        {survey.name}
                    </option>
                ))
            ) : (
                <option disabled>{placeholderText("No party available")}</option>
            )}
        </Form.Select>
    )

}

export default PartyFilter;