import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { initInstall, resetInitInstall } from "../store/initInstallSlice";
import { useLocation, useNavigate } from "react-router";
import bill from "../../../assets/images/bill.png";
import {
  createAppConfig,
  resetAuth,
  setCurrentTenantLocal,
} from "../store/authSlice";
import { setSelectedBusiness } from "../store/authSlice";
import { Row, Col, Button, Image } from "react-bootstrap";
import { i18n } from "../../shared/helpers/sharedMethods";
import { toast, ToastContainer } from "react-toastify";
import LoadingSpinner from '../../shared/components/LoadingSpinner';
const MultiTenants = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const deviceId = useSelector((state) => state?.auth?.deviceId);
  const getTenant = location.state;
  const [selectedUser, setSelectedUser] = useState(getTenant[0]);
  const [loading, setLoading] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const accessToken = useSelector(
    (state) => state?.auth?.accessToken?.access_token
  );
  const { terminalId, terminalCode, tenantLocalStatus, initInstallStatus, initInstallError, initInstallErrorMessage } = useSelector(
    (state) => state?.initInstall
  );
  const { createConfigStatus, setTenantStatus, businessSelectedStatus } =
    useSelector((state) => state.auth);

  ;

  const handleLogin = () => {
    setLoading(true);
    const checkTerminalData = {
      user_id: selectedUser?.pivot?.user_id,
      location: selectedUser?.business_address,
      device_id: deviceId,
    };
    dispatch(
      initInstall({
        tenant_id: selectedUser.pivot?.tenant_id,
        installData: checkTerminalData,
      })
    );

  };

  useEffect(() => {
    if (initInstallStatus) {
      dispatch(
        setCurrentTenantLocal({
          businessId: selectedUser?.business_id,
          userId: selectedUser?.pivot?.user_id,
        })
      );
    }
  }, [initInstallStatus, selectedUser]);

  useEffect(() => {
    if (setTenantStatus) {
      const appConfigData = {
        terminal_id: terminalId,
        terminal_code: terminalCode,
        token: accessToken,
        tenant_id: selectedUser?.pivot?.tenant_id,
        is_installed: 1,
        user: selectedUser?.pivot?.user_id,
        id: 1,
      };
      ;

      dispatch(createAppConfig(appConfigData));
    }
  }, [setTenantStatus]);

  useEffect(() => {
    if (initInstallError) {
      let toastErrorMsg = (initInstallErrorMessage != '') ? initInstallErrorMessage : "Failed to login! Access might be forbidden!";
      toast.error(toastErrorMsg);
      dispatch(resetInitInstall());
      dispatch(resetAuth());
      setTimeout(() => {
        navigate(0)
      }, 1000);
    }
  }, [initInstallError])

  useEffect(() => {
    if (createConfigStatus) {
      dispatch(setSelectedBusiness(selectedUser));
    }
  }, [createConfigStatus, selectedUser]);

  useEffect(() => {
    if (businessSelectedStatus) {
      dispatch(resetInitInstall());
      dispatch(resetAuth());
      window.sync.startProcesses();
      setTimeout(() => {
        navigate("/dashboard/sales");
      }, 3000);
    }
  }, [businessSelectedStatus]);

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === "Enter") {
        event.preventDefault();
        handleLogin();
      } else if (event.key === "ArrowDown") {
        setSelectedIndex((prevIndex) =>
          prevIndex < getTenant.length - 1 ? prevIndex + 1 : prevIndex
        );
      } else if (event.key === "ArrowUp") {
        setSelectedIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : prevIndex));
      }
    };

    window.addEventListener("keydown", handleKeyPress);

    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, [getTenant, handleLogin]);

  useEffect(() => {
    setSelectedUser(getTenant[selectedIndex]);
  }, [selectedIndex, getTenant]);
  return (
    <Row
      className="border d-flex flex-column justify-content-center align-items-center h-100 w-100"
      style={{ borderRadius: "5px" }}
    >
      <ToastContainer autoClose={3000} />

      <Col
        className="border p-5 col-lg-4  d-flex flex-column"
        style={{ borderRadius: "5px" }}
      >
        <Col className="mb-5 d-flex justify-content-center  col-lg-12">
          <Image src={bill} alt="Bill Now" style={{ height: "50px" }} />
        </Col>
        <h3 className="mb-5">{i18n("Choose your business")}</h3>
        <div className="px-2" style={{ height: "50vh", overflow: "scroll" }}>
          {getTenant?.length > 0 &&
            getTenant?.map((data, index) => {
              //const user = JSON.parse(data?.user)
              return (
                <div key={index}>
                  <div
                    className="d-flex col-lg-12   justify-content-start  mb-5 border p-2"
                    style={{
                      borderRadius: "10px",
                      backgroundColor: "whitesmoke",
                    }}
                  >
                    <div className="form-check  ">
                      <input
                        className="form-check-input cursor-pointer"
                        type="radio"
                        name="flexRadioDefault"
                        id={`flexRadioDefault${index}`}
                        // defaultChecked={index == 0}
                        checked={selectedIndex === index}
                        onChange={() => {
                          setSelectedIndex(index);
                          setSelectedUser(data);
                        }}
                      />
                      <label
                        className="form-check-label cursor-pointer"
                        htmlFor={`flexRadioDefault${index}`}
                      >
                        {data.business_name}
                      </label>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
        {!loading && <Button onClick={handleLogin}>{i18n("Continue")}</Button>}

        {loading && <LoadingSpinner />}

      </Col>
    </Row>
  );
};

export default MultiTenants;
