import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import { subscriptionProcessCartItem, calculateAddonPrice } from "../../../pos/helpers/cartHelper";

const initialState = {
  name: "",
  code: "",
  parent_product: "",
  type: "service",
  unit: "UNT",
  description: "",
  sale_price: "",
  is_subscribable: true,
  is_active: true,
  addOnType: "one-time",
  sub_type : 'addon',
  is_tax_included: 1,
  created_by: 1,
  interval_unit: "month",
  tax_code: "",
  addon: {},
};

const addonEntitySlice = createSlice({
  name: "addon",
  initialState,
  reducers: {
    changeAddonName(state, action) {
      state.name = action.payload;
    },
    changeAddonCode(state, action) {
      state.code = action.payload;
    },
    changeAddonType(state, action) {
      state.type = action.payload;
    },
    changeAddonParentProduct(state, action) {
      state.parent_product = action.payload;
    },
    changeAddonUnit(state, action) {
      state.unit = action.payload;
    },
    changeAddonDescription(state, action) {
      state.description = action.payload;
    },
    changeAddonSalePrice(state, action) {
      state.sale_price = action.payload;
    },
    changeAddonAddonType(state, action) {
      state.addOnType = action.payload;
    },
    changeAddonIntervalUnit(state, action) {
      state.interval_unit = action.payload;
    },
    changeAddonTaxCode(state, action) {
      state.tax_code = action.payload;
    },
    changeAddonIsTaxInclude(state,action) {
      state.is_tax_included = action.payload
    },
    initEditAddon(state,action){
        
        
        let tempData = action.payload

        state.name = tempData.name;
        state.code = tempData.code;
        state.parent_product = tempData.parent_product.id;
        state.type = tempData.type;
        state.unit = tempData.unit;
        state.description = tempData.description;
        state.sale_price = tempData.sale_price;
        state.is_subscribable = tempData.is_subscribable;
        state.is_active = tempData.is_active;
        state.addOnType = tempData.addon.type;
        state.sub_type = tempData.sub_type;
        state.is_tax_included = tempData.is_tax_included;
        state.created_by = tempData.created_by;
        state.interval_unit = tempData.addon.interval_unit;
        state.tax_code = tempData.tax_code;

       
        
        
    },
    resetAddon: () => initialState,
  },
  extraReducers: (builder) => {},
});

export default addonEntitySlice.reducer;

export const {
  changeAddonName,
  changeAddonCode,
  changeAddonParentProduct,
  changeAddonUnit,
  changeAddonDescription,
  changeAddonSalePrice,
  changeAddonAddonType,
  changeAddonType,
  changeAddonIsTaxInclude,
  changeAddonIntervalUnit,
  resetAddon,
  initEditAddon,
  changeAddonTaxCode
} = addonEntitySlice.actions;
