import { InputGroup, Col, Form } from "react-bootstrap";
import { numValidate } from "../../shared/helpers/sharedMethods";
import { useDispatch, useSelector } from "react-redux";
import {
  addInitPayment,
  processingPayment,
  resetSelectedPayment,
  resetStatusError,
  undoPayments,
} from "../store/partyPaymentsSlice";
import { i18n } from "../../shared/helpers/sharedMethods";

const PaymentAmount = (props) => {
  const { error, setError } = props;
  const dispatch = useDispatch();
  const { processPayment, copyPayment, checkedItems, statusError } =
    useSelector((state) => state.partyPayments);
    const currency = useSelector((state) => state.getSetting?.dataSetting?.currency);

  const handleChange = (e) => {
    setError((pre) => ({
      ...pre,
      ["payment_error"]: false,
    }));
    if (copyPayment.length > 0 && Object.keys(checkedItems).length > 0) {
      dispatch(undoPayments(copyPayment));
    }
    dispatch(addInitPayment(e.target.value));
    dispatch(processingPayment(e.target.value));
    dispatch(resetSelectedPayment());
    dispatch(resetStatusError());
  };
  return (
    <Col className="" lg={12}>
      <Form.Group>
        <Form.Label style={{ color: "#838587" }}>
          {i18n("Payment Amount")}
        </Form.Label>
      </Form.Group>
      <InputGroup>
        <InputGroup.Text>{currency}</InputGroup.Text>
        <input
          name="voucher_charges"
          onChange={(e) => {
            handleChange(e);
          }}
          aria-label="Dollar amount (with dot and two decimal places)"
          onKeyPress={(event) => numValidate(event)}
          className={`${
            error?.payment_error ? "form-control" : "form-control"
          }`}
          placeholder="0"
          type="number"
          value={processPayment}
        />
      </InputGroup>
      {error?.payment_error ||
        (statusError && (
          <div className="mt-2" style={{ color: "red" }}>
            {i18n("Please enter the amount")}
          </div>
        ))}
    </Col>
  );
};
export default PaymentAmount;
