import { i18n } from "../helpers/sharedMethods";

export const defaultParty = {
    id: 1,
    name: 'Walk-in',
    type: 'customer',
    created_by: 1,
    updated_by: 1,
}

export const defaultPaymentMode = 'cash'

export const paymentModes = [
    { value: i18n("cash"), label: "Cash" },
    { value: "upi", label: "UPI" },
    { value: "card", label: "Card" }
];

export const defaultPlaceOfSupply = 33;

export const HeightWeightExtention = {
    weight: "kg",
    height: "cm",
  };
  