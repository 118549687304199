import React, { useEffect, useState } from "react";
import VoucherItemsRow from "../components/VoucherItemsRow";
import { useDispatch, useSelector } from "react-redux";
import {
  i18n,
  numValidate,
  placeholderText,
} from "../../shared/helpers/sharedMethods";
import ModelFooter from "../components/ModelFooter";
import { useNavigate, Link } from "react-router-dom";
import AddressModel from "../components/AddressModel";
import PartyModel from "../components/PartyModel";
import SubCalculationProduct from "../components/PaymentSection";
import "../../voucher/assets/css/sale.css";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Layout from "../../shared/components/Layout";
import TermsAndCondition from "../components/fields/TermsAndCondition";
import VoucherDate from "../components/VoucherDates";
import PartyAndAddress from "../components/PartyAndAddress";
import AdditionalCharges from "../components/AdditionalCharges";
import { useRef, useLayoutEffect } from 'react';

import {
  Alert,
  Button,
  Card,
  Col,
  Container,
  Form,
  Row,
  Spinner,
  Stack,
  Table,
  ToggleButton,
  ToggleButtonGroup,
} from "react-bootstrap";
import {
  addNew,
  initEditVoucherItems,
  resetVoucherItem,
  selectAllVoucherItems,
  updateTaxSummary,
  updateVoucherItem,
} from "../store/voucherItemsSlice";
import {
  addCharges,
  addVoucher,
  undoVoucherError,
  getVoucherNumber,
  updateParty,
  updateTotals,
  voucherisOrdered,
} from "../store/voucherEntitySlice";
import { cloneDeep, filter, isEmpty } from "lodash";
import {
  createProcesses,
  isValidPinCode,
  unDoCessVoucher,
  unDoDiscounts,
} from "../helpers/VouchersHelper";
import VoucherNoAndDates from "../components/VoucherNoAndDates";
import { resetCartVoucherPayment } from "../../payment/store/paymentSlice";
import VoucherSummary from "../components/VoucherSummary";
import AmountReceived from "../components/fields/AmountReceived";
import Header from "../../shared/components/Header";
import BusinessBlock from "../components/BusinessBlock";
import PartyBlock from "../components/PartyBlock";
import BillingAddressBlock from "../components/BillingAddressBlock";
import ShippingAddressBlock from "../components/ShippingAddressBlock";
import CartBlock from "../components/CartBlock";
import Notes from "../components/fields/Notes";
import SignBlock from "../components/SignBlock";
import BankDetails from "../components/fields/BankDetails";
import { pushNewSale } from "../store/voucherSlice";
import { changeToggleShow } from "../../shared/store/sharedSlice";
import QuickGlance from "../components/QuickGlance";
import { useHotkeys } from "react-hotkeys-hook";
import ShortcutKeys from "../../shared/components/ShortcutKeys";
import { fetchSetting } from "../../settings/store/getSettingSlice";
import { fetchProducts } from "../../catalog/store/productsSlice";
import AlertMessage from "../../shared/components/AlertMessage";
import { resetStatus } from "../../party/store/PartyEntitySlice";
import { resetCategoryStatus } from "../../settings/store/categoryEntitySlice";
import { getUtcDate } from "../../shared/helpers/localizationHelper";
import { fetchparty } from "../../party/store/getPartySlice";

const CreateVoucher = (props) => {
  const { title, path, type, voucherType } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const voucherItems = useSelector(selectAllVoucherItems);
  const selectedBusiness = useSelector(
    (state) => state?.auth?.selectedBusiness
  );
  const currencySymbol = useSelector(
    (state) => state.getSetting?.dataSetting.currency
  );
  const place_of_supply = useSelector(
    (state) => state.getSetting?.dataSetting?.place_of_supply
  );
  const [showAlert, setShowAlert] = useState(false);

  const accessToken = useSelector(
    (state) => state?.auth?.accessToken?.access_token
  );
  console.log()
  const { items } = useSelector((state) => state.voucherEntity);
  const partyValue = useSelector((state) => state.voucherEntity.party);
  const additionalCharges = useSelector(
    (state) => state.additionalCharges.charges
  );
  const totalCharges = useSelector(
    (state) => state.voucherEntity.totalAddlCharges
  );
  const status = useSelector((state) => state.voucherEntity?.status?.isOrdered);
  const { errorStatus, error } = useSelector(
    (state) => state.voucherEntity.status
  );

  const currentId = useSelector(
    (state) => state.voucherEntity?.status?.currentId
  );

  const voucherOrder = useSelector((state) => state.voucherEntity);
  const { totalReceivedAmount, markAsPaid } = useSelector(
    (state) => state.voucherEntity
  );
  const tenantId = selectedBusiness?.pivot?.tenant_id;
  const { terminalId } = useSelector((state) => state.initInstall);
  
  //models state

  //const [addressModel, setAddressModel] = useState(false);
  const [showPartyModel, setShowPartyModel] = useState(false);
  const [cessEnabled, setCessEnabled] = useState(false);
  const [discountEnabled, setDiscountEnabled] = useState(false);
  const [is_same_state, setIsSameState] = useState(false);
  const cartBlockRef = useRef(null);
  const [loading, setLoading] = useState(false);

  useLayoutEffect(() => {
    if (cartBlockRef.current) {
      console.log('CartBlock ref is set:', cartBlockRef.current);
    } else {
      console.log('CartBlock ref is not set yet');
    }
  }, [cartBlockRef]);
  
  const createPartyStatus = useSelector(
    (state) => state.partyEntity.createStatus
  );
  const editPartystatus = useSelector((state) => state.partyEntity.editStatus);
  const categoryPartyStatus = useSelector(
    (state) => state.categoryEntity.status
  );
  const businessPOS = useSelector((state) => state.getSetting?.dataSetting?.place_of_supply);
  const voucherPOS = useSelector((state) => state.voucherEntity?.place_of_supply)
  const grand_total = useSelector((state) => state.voucherEntity.grand_total);


  useEffect(() => {
    if (errorStatus) {
      setLoading(false)
    }
  }, [errorStatus])


  useEffect(() => {
    if (!voucherPOS || businessPOS == voucherPOS) { 
      dispatch(updateTaxSummary({items: items, is_same_state: true}))
        setIsSameState(true);
    } else {
      dispatch(updateTaxSummary({items: items, is_same_state: false}))
        setIsSameState(false);
    }
}, [businessPOS, voucherPOS]);

  useEffect(() => {
    if (!discountEnabled) {
      dispatch(resetVoucherItem());
      Array(3)
        .fill(1)
        .map((el, i) => dispatch(addNew(i + 1)));
      const iteratedDiscountValue = unDoDiscounts({
        voucherItems,
        discountEnabled,
      });

      dispatch(initEditVoucherItems(cloneDeep(iteratedDiscountValue)));
    }
  }, [discountEnabled]);

  useEffect(() => {
    if (!cessEnabled) {
      dispatch(resetVoucherItem());
      Array(3)
        .fill(1)
        .map((el, i) => dispatch(addNew(i + 1)));
      const iteratedDiscountValue = unDoCessVoucher({
        voucherItems,
        cessEnabled,
      });

      dispatch(initEditVoucherItems(cloneDeep(iteratedDiscountValue)));
    }
  }, [cessEnabled]);

  const toggleCess = () => {
    setCessEnabled(!cessEnabled);
  };
  const toggleDiscount = () => {
    setDiscountEnabled(!discountEnabled);
  };
  useEffect(() => {
    if (categoryPartyStatus) {
      toast.success(i18n("Category created successfully"));
      dispatch(resetCategoryStatus);
    }
  }, [categoryPartyStatus]);
  // useEffect(() => {
  //   if (createPartyStatus) {
  //     toast.success(i18n("Party created Successfully"));
  //     dispatch(resetStatus());
  //   }
  // }, [createPartyStatus]);

  // useEffect(() => {
  //   if (editPartystatus) {
  //     toast.success(i18n("Party Updated Successfully"));
  //     dispatch(resetStatus());
  //   }
  // }, [editPartystatus]);
  useEffect(() => {
    {
      Array(3)
        .fill(1)
        .map((el, i) => dispatch(addNew(i + 1)));
    }
  }, []);

  useEffect(() => {
    dispatch(
      fetchSetting({
        accessToken: accessToken,
        tenant_id: selectedBusiness?.pivot?.tenant_id,
      })
    );
  }, []);
  useEffect(() => {
    dispatch(
      fetchProducts({
        token: accessToken,
        tenant_id: selectedBusiness?.pivot?.tenant_id,
      })
    );
  }, []);
  useEffect(() => {
    dispatch(
      fetchparty({
        token: accessToken,
        tenant_id: selectedBusiness?.pivot?.tenant_id,
      })
    );
  }, []);
  useEffect(() => {
    if (!place_of_supply) {
      setShowAlert(true);
    } else {
      setShowAlert(false);
    }
  }, [place_of_supply]);

  useEffect(() => {
    const validItems =
      voucherItems &&
      filter(voucherItems, function (o) {
        return o.product_id;
      });
    dispatch(updateTotals(validItems));
  }, [voucherItems]);

  useEffect(() => {
    if (additionalCharges) {
      dispatch(addCharges(additionalCharges));
    }
  }, [additionalCharges]);
  useEffect(() => {
    dispatch(getVoucherNumber({ 
      data: type, 
      token: accessToken, 
      tenant_id: tenantId, 
      terminal_id: terminalId 
    }));
  }, []);
    useEffect(() => {
    if (status) {
      toast.success(i18n("Sales Created Successfully"));
      dispatch(pushNewSale(currentId));
      dispatch(fetchProducts({ token: accessToken, tenant_id: tenantId, }));
      navigate(`/${path}/view/` + currentId.id);
      dispatch(voucherisOrdered());
      dispatch(getVoucherNumber({ 
        data: type, 
        token: accessToken, 
        tenant_id: tenantId, 
        terminal_id: terminalId 
      })).then((response) => {
        if (response.payload && response.payload.data) {
          console.log('Voucher Number:', response.payload.data);
        } else {
          console.log('Failed to get voucher number');
        }
      });
       dispatch(changeToggleShow(false));
    }
  }, [status]);
  const handleValidation = () => {
    let isValid = false;

    const validItems =
      voucherItems &&
      filter(voucherItems, function (o) {
        return o.product_id;
      });
    const zeroQtyItems =
      voucherItems &&
      filter(voucherItems, function (o) {
        return !o.quantity;
      });

    const isWalkIn = partyValue && partyValue.id === 1;

    if (isEmpty(partyValue)) {
      toast.error(i18n("Please select a party!"));
    } else if (!isWalkIn && !partyValue.phone_number) {
      toast.error(i18n("Please enter phone number!"));
    } else if (validItems.length < 1) {
      toast.error(i18n("Please select at least one product!"));
    } else if (zeroQtyItems.length > 0) {
      toast.error(i18n("Please Select quantity of the product!"));
    } else {
      isValid = true;
    }
    return isValid;
  };

  const onSubmit = async () => {
    try {
      const validation = handleValidation();
      if (validation) {
        setLoading(true);
        let saleOrder = createProcesses(
          cloneDeep(voucherOrder),
          partyValue,
          selectedBusiness,
          totalCharges,
          type,
          totalReceivedAmount,
          markAsPaid
        );

        //saleOrder.is_discount = discountEnabled
        saleOrder.party_id = partyValue?.id || null;
        saleOrder.created_at = getUtcDate();
        saleOrder.updated_at = getUtcDate();
        //saleOrder.isDiscountEnabled = discountEnabled;
        if (saleOrder?.paid_amount > saleOrder?.grand_total) {
          toast.error(
            "Received payment amount cannot be greater than the total invoice amount"
          );
          return;
        }
        delete saleOrder.currentAddress;
        console.log("sale order details", saleOrder)
        dispatch(addVoucher({data:saleOrder,token: accessToken,
          tenant_id: tenantId, terminal_id:terminalId }));
       
      }
    } catch (error) {
      console.log("error for onsubmit",error)
      setLoading(false);
    }
  };
  const handleCancel = () => {
    dispatch(resetCartVoucherPayment());
    dispatch(changeToggleShow(false));
    navigate(`/${path}`);
  };
  const handleClose = () => {
    setShowPartyModel(false);
    //setAddressModel(false);
  };
  const handleAlertClose = () => {
     setLoading(false)
    dispatch(undoVoucherError());
  };

  useEffect(() => {
    if(errorStatus){
      setTimeout(() => {
        handleAlertClose();
      }, [1000])
    }
  }, [errorStatus])
  
  useHotkeys("alt+enter", (event) => {
    event.preventDefault();
    onSubmit();
  });

  useHotkeys("esc", (event) => {
    event.preventDefault();
    handleCancel();
  });

  return (
    <>
      <ToastContainer />
      <Layout currentTitle={title}>
      
        {showPartyModel && (
          <PartyModel
            show={showPartyModel}
            handleClose={handleClose}
            numValidate={numValidate}
          />
        )}
        {/* {addressModel && (
          <AddressModel show={addressModel} handleClose={handleClose} />
        )} */}
        <Header
          ItemActions={
            <Stack
              gap={3}
              direction="horizontal"
              className="mt-2 mb-2 justify-content-end"
            >
              <ShortcutKeys />
              {!loading ? (
                <Button
                  id="submitButton"
                  variant="primary mx-2"
                  onClick={onSubmit}
                  disabled={loading}
                >
                  {i18n("Save")}
                </Button>
              ) : (
                <div className="d-flex align-items-center mx-8">
                  <Spinner animation="border" size="md" />
                </div>
              )}
              <Button
                variant="outline-secondary mx-2"
                onClick={handleCancel}
              >
                {i18n("Cancel")}
              </Button>
            </Stack>
          }
          currentTitle={title}
        />
         
        <Container fluid className="px-5">
          <div
            className="border"
            style={{ background: "white", height: "95vh", overflow: "scroll" }}
          >
            {errorStatus && (
              <AlertMessage
                errorStatus={errorStatus}
                error={error}
                handleAlertClose={handleAlertClose}
              />
            )}
             {showAlert && (
          <Alert variant="danger" className="px-5" style={{marginLeft:"18px", marginRight:"18px", marginTop:"10px"}}>
            Please select Place Of Supply. Kindly add Place Of Supply{" "}
            <Link to="/setting/business">here</Link>.
          </Alert>
        )}
            <Row
              className="mx-2"
              style={{ minHeight: "168px", borderBottom: "1px solid #ccc" }}
            >
              <Col lg={3}>
                <VoucherNoAndDates types={type} />
              </Col>
              <Col lg={9} className="px-0"  style={{borderLeft:"1px solid lightgrey",minHeight: "168px"}}>
              <Row
              className=""
              style={{ minHeight: "170px"}}
               >
              <Col lg={6}>
                <PartyBlock />
              </Col>
              <Col lg={6} style={{borderLeft:"1px solid lightgrey",minHeight: "168px"}}>
                {!isEmpty(partyValue) && <BillingAddressBlock />}
                {!isEmpty(partyValue) && <ShippingAddressBlock />}
              </Col>
            </Row>
              </Col>
            </Row>

           

            <Row className="p-1 mt-2">
              <Col lg={12} className="">
                <Col className="d-flex justify-content-end">
                  <Form.Switch
                    id="toggle-discount"
                    label={
                      discountEnabled
                        ? i18n("Discount Enabled")
                        : i18n("Discount")
                    }
                    checked={discountEnabled}
                    onChange={toggleDiscount}
                    style={{ marginRight: "10px" }}
                  />
                  <Form.Switch
                    id="toggle-cess"
                    label={cessEnabled ? i18n("CESS Enabled") : i18n("CESS")}
                    checked={cessEnabled}
                    onChange={toggleCess}
                    style={{ marginLeft: "15px", marginRight: "10px" }}
                  />
                </Col>

                <CartBlock
                  ref={cartBlockRef}
                  voucherType={voucherType}
                  cessEnabled={cessEnabled}
                  discountEnabled={discountEnabled}
                  is_same_state={is_same_state}
                />
              </Col>
            </Row>
            <Row className="p-2 mx-2"  style={{ minHeight: "200px" }}>
              <Col lg={6} style={{ borderRight: "1px solid #ccc" }}>
                <Row>
                  <Col lg={6}>
                    <Notes />
                    <BankDetails />
                  </Col>
                  <Col lg={6}>
                  <TermsAndCondition />
                  </Col>
                </Row>
                </Col>

              <Col lg={6}>
                <Stack direction="vertical" className="px-2 py-0">
                  <VoucherSummary type={type} is_same_state={is_same_state}/>

                  <Row style={{ borderTop: "1px solid #ccc" }}>
                    <Col lg={9}></Col>
                    <Col md={3}>
                      <SignBlock />
                    </Col>
                  </Row>
                </Stack>
              </Col>
            </Row>
          </div>
        </Container>
      </Layout>
    </>
  );
};
export default CreateVoucher;
