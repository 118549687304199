
export const handleExportInJSON = (
  b2b, b2cs, b2cl, cdnr, cdnur, hsn, gst,
  NilRatedWithReg, ExemptWithReg, NonGSTWithReg, 
  InterNilRatedWithoutGST, ExemptWithoutReg, NonGSTWithoutReg,
  IntraNilRatedWithReg, IntraExemptWithReg, IntraNonGSTWithReg,
  IntraNilRatedWithoutReg, IntraExemptWithoutReg, IntraNonGSTWithoutReg
) => {
  const jsonData = {
    gstin: gst,
    b2b: b2b?.length > 0 ? b2b.map((item) => ({
      ctin: item.gstin,
      inv: [
        {
          val: item.invoiceValue,
          itms: [
            {
              num: item.length,
              itm_det: {
                txval: item.taxable_value,
                rt: item.rate,
                csamt: item.cess,
                iamt: item.igst
              },
            },
          ],
          idt: item.invDate,
          inum: item.invNo,
          inv_typ: item.invType,
          pos: item.place_of_supply,
        },
      ],
    })) : [],

    b2cl: b2cl?.length > 0 ? b2cl.map((item) => ({
      txval: item.taxable_value,
      rt: item.rate,
      csamt: item.cess,
      camt: item.igst,
      samt: item.sgst,
      pos: item.place_of_supply,
      sply_ty: item.supply_type,
    })) : [],

    b2cs: b2cs?.length > 0 ? b2cs.map((item) => ({
      txval: item.taxable_value,
      rt: item.rate,
      csamt: item.cess,
      camt: item.igst,
      samt: item.sgst,
      pos: item.place_of_supply,
      sply_ty: item.supply_type,
      typ: "OE",
    })) : [],

    cdnr: cdnr?.length > 0 ? cdnr.map((item) => ({
      ctin: item.gstin,
      nt: [
        {
          val: item.invoiceValue,
          itms: [
            {
              num: item.length,
              itm_det: {
                txval: item.taxable_value,
                rt: item.rate,
                csamt: item.cess,
                iamt: item.igst
              },
            },
          ],
          ntty: "C",
          nt_dt: item.invDate,
          nt_num: item.invNo,
          inv_typ: item.invType,
          pos: item.place_of_supply,
        },
      ],
    })) : [],

    cdnur: cdnur?.length > 0 ? cdnur.map((item) => ({
      ctin: item.gstin,
      nt: [
        {
          val: item.invoiceValue,
          itms: [
            {
              num: item.length,
              itm_det: {
                txval: item.taxable_value,
                rt: item.rate,
                csamt: item.cess,
                iamt: item.igst
              },
            },
          ],
          nt_dt: item.invDate,
          nt_num: item.invNo,
          inv_typ: item.invType,
          pos: item.place_of_supply,
        },
      ],
    })) : [],

    nil: {
      inv: [
        {
          sply_ty: "INTRB2B",
          nil_amt: NilRatedWithReg || 0,
          expt_amt: ExemptWithReg || 0,
          ngsup_amt: NonGSTWithReg || 0,
        },
        {
          sply_ty: "INTRB2C",
          nil_amt: InterNilRatedWithoutGST || 0,
          expt_amt: ExemptWithoutReg || 0,
          ngsup_amt: NonGSTWithoutReg || 0,
        },
        {
          sply_ty: "INTRAB2B",
          nil_amt: IntraNilRatedWithReg || 0,
          expt_amt: IntraExemptWithReg || 0,
          ngsup_amt: IntraNonGSTWithReg || 0,
        },
        {
          sply_ty: "INTRAB2C",
          nil_amt: IntraNilRatedWithoutReg || 0,
          expt_amt: IntraExemptWithoutReg || 0,
          ngsup_amt: IntraNonGSTWithoutReg || 0,
        },
      ],
    },

    hsn: hsn?.length > 0 ? hsn.map((item) => ({
      data: [
        {
          num: item?.length,
          uqc: item?.unit,
          qty: item?.totalQuantity,
          hsn_sc: item?.hsn,
          rt: item?.rate,
          txval: item?.taxable_value,
          iamt: item?.igst,
          samt: item?.sgst,
          camt: item?.cgst,
          csamt: item?.cess,
        },
      ],
    })) : [],
  };

  const dateTimeString = new Date()
    .toLocaleString()
    .replace(/[-]/g, " ")
    .replace(/:/g, "-");

  const fileName = `GSTR-1_sales_report_JSON_${dateTimeString}`;

  const blob = new Blob([JSON.stringify(jsonData, null, 2)], { type: "application/json" });
  const url = URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = url;
  a.download = fileName;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};
