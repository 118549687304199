import {
  Button,
  Col,
  Container,
  Form,
  Modal,
  Row,
  Spinner,
  Stack,
} from "react-bootstrap";
import { Formik } from "formik";
import { i18n } from "../../../shared/helpers/sharedMethods";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { useEffect, useState } from "react";
import {
  AddItem,
  resetPackage,
} from "../../store/subscription_item_store/CreatePackageSlice";
import {
  addNewProduct,
  planData,
  resetStatus,
  selectPlanDataById,
} from "../../store/subscription_item_store/GetPackagesItemSlice";
import Header from "../../../shared/components/Header";
import Layout from "../../../shared/components/Layout";
import PackageLayout from "../../components/PackageLayout/PackageLayout";
import PackageHeader from "../../components/PackageHeader";
import ProductName from "../../components/packages_fields/setufee_fields/ProductName";
import ProductCode from "../../components/packages_fields/setufee_fields/ProductCode";
import ProductPrice from "../../components/packages_fields/setufee_fields/ProductPrice";
import ProductTaxCode from "../../components/packages_fields/setufee_fields/ProductTaxCode";
import { changeSetupFeesIsRefundable } from "../../store/subscription_item_store/SetupFeesEntitySlice";
import { useLocation, useNavigate } from "react-router";
import ShortcutKeys from "../../../shared/components/ShortcutKeys";
import { capitalizeMethod } from "../../../shared/helpers/captilize";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


const CreateSetupFees = ({ currentItemId, show, setShow }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { id } = location?.state;
  const [loading, setLoading] = useState(false);
  const { accessToken, isLoggedIn } = useSelector((state) => state?.auth);

  const selectedBusiness = useSelector(
    (state) => state?.auth?.selectedBusiness
  );
  const status = useSelector((state) => state.createPackage.status);
  const savedEntity = useSelector((state) => state.createPackage.savedEntity);
  const tenantId = selectedBusiness?.pivot?.tenant_id;
  const setupFees = useSelector((state) => state?.setupFees);


  const parentProduct = useSelector((state) =>
    selectPlanDataById(state, id)
  );

  const [isChecked, setIsChecked] = useState(false);

  useEffect(() => {
    if (status && savedEntity) {
      toast.success("Setup fees created successfully")
      dispatch(addNewProduct(savedEntity));
      dispatch(
        planData({ token: accessToken.access_token, tenant_id: tenantId })
      );
      navigate("/subscription/packages/" + id, {
        state: { success: true },
      });
    }
  }, [status, savedEntity]);

  useEffect(() => {
    if (isChecked) {
      dispatch(changeSetupFeesIsRefundable(1));
    } else {
      dispatch(changeSetupFeesIsRefundable(0));
    }
  }, [isChecked]);

  const handleChecked = (event) => {
    setIsChecked(event.target.checked);
  };

  return (
    <>
     <ToastContainer />
    <Formik
      initialValues={setupFees}
      enableReinitialize={true}
      validationSchema={Yup.object().shape({
        name: Yup.string().required(i18n("Name is required")),
        code: Yup.string().required(i18n("Code is required")),
        sale_price: Yup.string().required(i18n("Price is required")),
        // interval: Yup.number().required(i18n("Bill Every is required")),
      })}
      onSubmit={async (values) => {
      
        try {
          setLoading(true);
          let formData = { ...values };
    

          formData = {
            ...formData,
            parent_product: id,
            sub_type: "setup_fee",
            type: "service",
            setup_fee: {
              is_refundable: formData.is_refundable,
            },
          };
          delete formData.is_refundable;

       
          dispatch(
            AddItem({
              data: formData,
              token: accessToken.access_token,
              tenant_id: tenantId,
            })
          );
        } catch (error) {
          setLoading(false);
        }
      }}
    >
      {({
        values,
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue,
        isSubmitting,
        touched,
      }) => (
        <Layout currentTitle={i18n("Packages")}>
          <Header
            currentTitle={i18n("Manage Packages")}
            ItemActions={
              <Stack
                gap={3}
                direction="horizontal"
                className="my-2 justify-content-end"
              >
                <ShortcutKeys />
              </Stack>
            }
          />
          <PackageLayout currentItemId={id}>
            <PackageHeader
              currentTitle={parentProduct ? (
                <nav style={{ '--bs-breadcrumb-divider': "'>'",fontWeight:"bold",fontSize:"15px",height:"2vh"}} aria-label="breadcrumb">
                 <ol className="breadcrumb">
                   <li className="breadcrumb-item" style={{ color: '#4169E1',fontSize:"15px"}}>{capitalizeMethod(parentProduct?.name)}</li>
                   <li className="breadcrumb-item active" aria-current="page" style={{color: '#36454F',fontSize:"15px"}}>{i18n("Create Setup Fees")}</li>
                </ol>
               </nav>
                ) : i18n("Create Setup Fees")}
              ItemActions={
                <Stack
                  gap={3}
                  direction="horizontal"
                  className="mt-2 mb-2 float-end"
                >
                  <div>
                  {!loading ? (
                <Button
                  id="submitButton"
                  variant="primary"
                  onClick={handleSubmit}
                  disabled={loading}
                >
                  {i18n("Save")}
                </Button>
              ) : (
                <div className="d-flex align-items-center mx-8">
                  <Spinner animation="border" size="md" />
                </div>
              )}
                  </div>
                  <div>
                    <Button
                      className=""
                      variant="outline-secondary"
                      onClick={() => navigate("/subscription/packages/" + id, {
                        state: { success: true },
                      })}
                    >
                      {i18n("Cancel")}
                    </Button>
                  </div>
                </Stack>
              }
            />
            <Container
              fluid
              className="px-5 "
              style={{
                height: "calc(100vh - 150px",
                overflow: "auto",
                overflowX: "hidden",
              }}
            >
              <Row
              // style={{
              //   height: "calc(100vh - 150px",
              //   overflow: "auto",
              //   overflowX: "hidden",
              // }}
              >
                <Col lg={12} className="">
                  <ProductName
                    values={values}
                    handleChange={handleChange}
                    errors={errors}
                    touched={touched}
                  />
                </Col>
                <Col lg={12} className="">
                  <ProductCode
                    values={values}
                    handleChange={handleChange}
                    errors={errors}
                    touched={touched}
                  />
                </Col>
                <Col lg={12} className="">
                  <ProductPrice
                    values={values}
                    handleChange={handleChange}
                    errors={errors}
                    touched={touched}
                  />
                </Col>
                <Col lg={12} className="">
                  <ProductTaxCode
                    values={values}
                    handleChange={handleChange}
                    errors={errors}
                    setFieldValue={setFieldValue}
                    touched={touched}
                  />
                </Col>
                <Col lg={12} className="mt-8 d-flex ">
                  <Col lg={3} className=" d-flex">
                    <Form.Check
                      id="refund-check"
                      aria-label="Is refundable"
                      checked={isChecked}
                      onChange={handleChecked}
                    />
                    <Form.Label htmlFor="refund-check" className="mb-0 px-5 fs-4">
                      {i18n("Is refundable")} ?
                    </Form.Label>
                  </Col>
                  <Col lg={3} className="">
                    <Form.Label
                      className="mb-0 fs-5"
                      style={{
                        color: isChecked ? "green" : "red",
                        fontWeight: 500,
                      }}
                    >
                      {isChecked ?i18n("YES") : i18n("NO")}
                    </Form.Label>
                  </Col>
                </Col>
              </Row>
            </Container>
          </PackageLayout>
        </Layout>
      )}
    </Formik>
    </>
  );
};

export default CreateSetupFees;
