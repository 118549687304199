import React from "react";
import { ListGroup } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { i18n } from "../../shared/helpers/sharedMethods";
import TabLayout from "../../shared/components/item-detail/TabLayout";
import ReceivableAgeingReport from "../screens/ReceivableAgeingReport";
import PartyReportByItem from "../screens/PartyReportByItem";
import PartyLedgerReport from "../screens/PartyLedgerReport";
import PartyWiseOutstanding from "../screens/PartyWiseOutstanding";
import SaleSummaryCategoryWise from "../screens/SaleSummaryCategoryWise";


const PartyNavbar = () => {
  const navigate = useNavigate();
  const location = useLocation();
const reportTabs = [
  {
    key: "party/receivable-agening",
    title: i18n("Receivable Ageing Report"),
    component: <ReceivableAgeingReport />,
  },
  {
    key: "party/party-report-by-item",
    title: i18n("Party Report By Item"),
    component: <PartyReportByItem />,
  },
  {
    key: "party/party-ledger",
    title: i18n("Party Statement (Ledger)"),
    component: <PartyLedgerReport />,
  },
  {
    key: "party/party-wise-outstanding",
    title: i18n("Party Wise Outstanding"),
    component: <PartyWiseOutstanding />,
  },
  {
    key: "party/sales-sumary-category-wise",
    title: i18n("Sales Summary - Category Wise"),
    component: <SaleSummaryCategoryWise />,
  },
];

  return (
    <>
      {/* <div
        className="col-lg-12 "
        style={{ height: "90vh", borderRight: "1px solid lightgray" }}
      >
        <ListGroup className="custom-listgroup-css py-2" variant="flush">
        <ListGroup.Item className={location.pathname == "/reports/receivableAgeing" ? "active-highlight" : ""}
            onClick={() => {
              navigate("/reports/receivableAgeing");
            }}
          >
            <Link className="d-flex " to="/reports/receivableAgeing">
            {i18n("Receivable Ageing Report")}
            </Link>
          </ListGroup.Item>

          <ListGroup.Item className={location.pathname == "/reports/partyReportByItem" ? "active-highlight" : ""}
            onClick={() => {
              navigate("/reports/partyReportByItem");
            }}
          >
            <Link className="d-flex " to="/reports/partyReportByItem">
            {i18n("Party Report By Item")}
            </Link>
          </ListGroup.Item>
         
          <ListGroup.Item   className={location.pathname == "/reports/partyLedger" ? "active-highlight" : ""}
            onClick={() => {
              navigate("/reports/partyLedger");
            }}
          >
            <Link className="d-flex " to="/reports/partyLedger">
            {i18n("Party Statement (Ledger)")}
            </Link>
          </ListGroup.Item>

          <ListGroup.Item
            className={location.pathname == "/reports/partyWise" ? "active-highlight" : ""}
            onClick={() => {
              navigate("/reports/partyWise");
            }}
          >
            <Link className="d-flex " to="/reports/partyWise">
              {" "}

              {i18n("Party Wise Outstanding")}
            </Link>
          </ListGroup.Item>

          <ListGroup.Item className={location.pathname == "/reports/saleSummaryCategoryWise" ? "active-highlight" : ""}
            onClick={() => {
              navigate("/reports/saleSummaryCategoryWise");
            }}
          >
            <Link className="d-flex " to="/reports/saleSummaryCategoryWise">
            {i18n("Sales Summary - Category Wise")}
            </Link>
          </ListGroup.Item>
         

        </ListGroup>
      </div> */}
      <TabLayout type="reports" tabs={reportTabs} route="reports" title="Report" />
    </>
  );
};

export default PartyNavbar;
