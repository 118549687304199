import { faExchange } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  ButtonGroup,
  Dropdown,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { useSelector } from "react-redux";
import BusinessSwitchDropdown from "../BusinessSwitchDropdown";
import { i18n } from "../../helpers/sharedMethods";

const BusinessSwitch = (props) => {
  const { setIsHovered, isHovered, handleSwitch } = props;
  const getSetting = useSelector((state) => state.getSetting?.dataSetting);
  const isMenuCollapse = useSelector((state) => state.sharedSlice.isToggleShow);
  const selectedBusiness = useSelector(
    (state) => state?.auth?.selectedBusiness
  );
const formatBusinessName = (businessName) => {
    if (typeof businessName !== 'string') {
        return ''; // or handle the case as you see fit
    }
    
    if (businessName.length <= 23) {
        return businessName;
    }
    
    return businessName.slice(0, 23) + "...";
};
  return (
    <Dropdown
      // onMouseEnter={() => setIsHovered(true)}
      // onMouseLeave={() => setIsHovered(false)}
      as={ButtonGroup}
      style={{ width: "100%", display: isMenuCollapse ? "none" : "" }}
      className="outline-primary px-3 py-2 "
      size="sm"
    >
      <OverlayTrigger
        placement="right"
        overlay={<Tooltip id="button-tooltip">{i18n("Switch")}</Tooltip>}
      >
        <Dropdown.Toggle
          id="dropdown-basic"
          className="p-0 bg-white text-dark"
          style={{ border: "1px solid #1a237e3b" }}
        >
          <div className="d-flex justify-content-between custom-business-switch">
            <div
              className={`d-flex align-items-center pl-2 p-1${
                !isMenuCollapse ? "" : "d-none"
              }`}
            >
              {" "}
              {getSetting &&
               formatBusinessName(selectedBusiness?.business_name)}
            </div>
            <div>
              <Button
                size="md"
                variant="outline-primary px-3 "
                className="custom-business-switch-btn"
                onClick={handleSwitch}
              >
                <FontAwesomeIcon
                  // style={{ color: isHovered ? "white" : "grey" }}
                  className=""
                  icon={faExchange}
                />
              </Button>
            </div>
          </div>
          <BusinessSwitchDropdown />
        </Dropdown.Toggle>
      </OverlayTrigger>
    </Dropdown>
  );
};

export default BusinessSwitch;
