import { Button, Col, Form, Row, Table } from "react-bootstrap";
import { i18n } from "../../../shared/helpers/sharedMethods";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import {
  Deleteplan,
  planData,
  selectProductAsSubscriptionAddon,
} from "../../store/subscription_item_store/GetPackagesItemSlice";
import DeleteModel from "../../../shared/components/DeleteModel";
import { useState } from "react";
import { UilEdit, UilTrashAlt } from "@iconscout/react-unicons";
import { resetAddon } from "../../store/subscription_item_store/AddonEntitySlice";
import { formatAmount } from "../../../pos/helpers/cartHelper";
import GlobalCurrency from "../../../shared/components/GlobalCurrency";
import { resetPackage } from "../../store/subscription_item_store/CreatePackageSlice";

const AddonTable = ({ id }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { accessToken, isLoggedIn } = useSelector((state) => state?.auth);
  const selectedBusiness = useSelector(
    (state) => state?.auth?.selectedBusiness
  );

  const tenantId = selectedBusiness?.pivot?.tenant_id;
  const addon = useSelector((state) =>
    selectProductAsSubscriptionAddon(state, id)
  );
  ;
  const [deleteAddon, setDeleteAddon] = useState(false);
  const [deleteName, setDeleteName] = useState();

  const handleCreate = () => {
    dispatch(resetAddon());
    dispatch(resetPackage());
    navigate("/subscription/packages/addon/create", { state: { id: id } });
  };
  const handleAddonEdit = (item) => {
    dispatch(resetAddon());
    navigate("/subscription/packages/addon/edit/" + item?.id, {
      state: { currentId: id },
    });
  };

  const handleDelete = (item) => {
    ;
    setDeleteAddon(true);
    setDeleteName(item);
  };
  const onDelete = () => {
    dispatch(
      Deleteplan({
        id: deleteName?.id,
        token: accessToken.access_token,
        tenant_id: tenantId,
      })
    ).then((res) => {
      ;
      dispatch(
        planData({ token: accessToken.access_token, tenant_id: tenantId })
      );
    });
    setDeleteAddon(false);
  };
  return (
    <Row className="mb-6 pt-4">
      <Col
        lg={12}
        className="d-flex justify-content-between align-items-center"
      >
        <div>
          <Form.Group className="px-1">
            <Form.Label className="fs-2">
              <span
                className="pb-2"
                style={{
                  borderBottom: "3px solid #1a237e",
                  fontWeight: "bold",
                  fontSize: "15px",
                }}
              >
                {i18n("Addons")}
              </span>
            </Form.Label>
          </Form.Group>
        </div>
        <div>
          <Button variant="link" onClick={handleCreate} className="px-2" style={{ fontSize: '13px' }}>
            <span>{i18n("+Add Addon")}</span>
          </Button>
        </div>
      </Col>
      <Col lg={12}>
        <div className="mt-5 px-1">

          <Table bordered hover responsive>
            <thead>
              <tr  className="text-center" style={{ color: '#495057' }}>
                <th className="px-2" style={{ fontSize: '13px',backgroundColor: 'rgb(249, 250, 251)' }}>{i18n("Name")}</th>
                <th className="px-2" style={{ fontSize: '13px',backgroundColor: 'rgb(249, 250, 251)' }}>{i18n("Code")}</th>
                <th className="px-2" style={{ fontSize: '13px',backgroundColor: 'rgb(249, 250, 251)' }}>{i18n("Type")}</th>
                <th className="px-2" style={{ fontSize: '13px',backgroundColor: 'rgb(249, 250, 251)' }}>{i18n("Price")}</th>
                <th className="px-2" style={{ fontSize: '13px',backgroundColor: 'rgb(249, 250, 251)' }}>{i18n("Actions")}</th>
              </tr>
            </thead>
            <tbody>
              {addon && addon.length > 0 ? (
                addon.map((item, index) => (
                  <tr className="text-center" key={index}>
                    <td className="px-3" style={{ fontSize: '13px' }}>{item.product_name}</td>
                    <td className="px-5" style={{ fontSize: '13px' }}>{item.product_code}</td>
                    <td className="px-4" style={{ fontSize: '13px' }}>{item.addon?.type}</td>
                    <td className="px-3" style={{ fontSize: '13px' }}><GlobalCurrency price={item?.item_price} /></td>
                    <td className="px-2">
                      <UilEdit
                        size="16"
                        className=" mx-1"
                        aria-hidden="true"
                        style={{ cursor: "pointer" }}
                        onClick={() => handleAddonEdit(item)}
                      />
                      &nbsp;
                      <UilTrashAlt
                        size="16"
                        className=" mx-1 "
                        aria-hidden="true"
                        style={{ cursor: "pointer" }}
                        onClick={() => handleDelete(item)}
                      />
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td className="text-center px-2" colSpan="6" style={{ fontSize: '13px' }}>
                   
                    {i18n("No Addons yet!")}
                  </td>
                </tr>
              )}
            </tbody>
          </Table>

        </div>
      </Col>
      {deleteAddon && (
        <DeleteModel
          name={deleteName?.product_name}
          deleteUserClick={onDelete}
          onClickDeleteModel={setDeleteAddon}
        />
      )}
    </Row>
  );
};

export default AddonTable;
