import { faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button, Card, Col, Form, Row, Stack } from "react-bootstrap";
import { i18n } from "../../../../../shared/helpers/sharedMethods";
import PartyCategory from "../../../../../party/components/category/PartyCategory";
import PartyType from "../../../../../party/components/category/PartyType";
import PartyHighlights from "../../../../../party/components/party_view/PartyHighlights";
import ItemwiseReport from "../../../../../party/components/party_view/tabs/ItemwiseReport";
import ProfileInfo from "../../../../../party/components/party_view/ProfileInfo";
import Transactions from "../../../../../party/components/party_view/tabs/Transaction";
import Ledger from "../../../../../party/components/party_view/tabs/Ledger";
import TwoStageLayoutHeader from "../../../../../shared/components/TwoStatgeLayoutHeader";
import DetailsModal from "../../../../../shared/components/DetailsModal";
import MemberProfileInfo from "../../../member_view/MemberProfileInfo";
import { FaTrash } from "react-icons/fa";
import { UilEdit, UilTrashAlt, UilBan } from "@iconscout/react-unicons";
import SubscriptionList from "../../../member_view/tabs/SubscriptionList";
import { DeleteMember, resetMemberState } from "../../../../store/MemberSlice";
import EditMember from "../../../../screens/subscription_screen/EditMember";
import DeleteModel from "../../../../../shared/components/DeleteModel";
import EditParty from "../../../../../party/screens/EditParty";
import { deleteParty } from "../../../../../party/store/getPartySlice";

const CustomerDetails = ({ data }) => {
  const dispatch = useDispatch();
  const [showDetails, setShowDetails] = useState(false);
  const [show, setShow] = useState(false);
  const [showParty, setShowParty] = useState(false);
  const [showMemberDetails, setShowMemberDetails] = useState(false);
  const [showEditMember, setShowEditMember] = useState(false);
  const [showEditParty, setShowEditParty] = useState(false);
  const [memberId, setMemberId] = useState();
  const [partyId, setPartyId] = useState();
  
  const { accessToken } = useSelector((state) => state?.auth);

  const selectedBusiness = useSelector(
    (state) => state?.auth?.selectedBusiness
  );
  const tenantId = selectedBusiness?.pivot?.tenant_id;

  const handlePartyRowClick = (row) => {
    setPartyId(row?.party_id);
    setShowDetails(!showDetails);
  };

  const handleMemberRowClick = (row) => {
    setMemberId(row?.member_id);
    setShowMemberDetails(!showMemberDetails);
  };

  const handleEdit = (data) => {
    setShowEditMember(true);
    setShowDetails(false);
    setMemberId(data?.id);
  };
  const handleDelete = async (data) => {
    setShow(!show);
    setMemberId(data?.id);
    setShowDetails(false);
  };

  const onDelete = () => {
    dispatch(
      DeleteMember({
        id: memberId,
        token: accessToken.access_token,
        tenant_id: tenantId,
      })
    );
    setShow(false);
  };
  const handleEditParty = (data) => {
  setShowEditParty(true);
  setPartyId(data?.id);
  }

  const handleDeleteParty = async (data) => {
    setShowParty(!showParty);
    setPartyId(data?.id);
  }
  const onDeleteParty = () => {
    dispatch(deleteParty(partyId));
    setShowParty(false);
   
  };
  const productTabs = [
    {
      key: "profile",
      title: i18n("Details"),
      component: <ProfileInfo selectedParty={data?.party} />,
    },
    {
      key: "transaction",
      title: i18n("Transactions"),
      component: <Transactions partyId={partyId} />,
    },
    {
      key: "ledger",
      title: i18n("Ledger(Statement)"),
      component: <Ledger partyId={partyId} />,
    },
    {
      key: "itemwise-report",
      title: i18n("Itemwise Report"),
      component: <ItemwiseReport partyId={partyId} />,
    },
  ];

  const partySummary = (
    <>
      <PartyHighlights selectedParty={data} currentItemId={partyId} />
    </>
  );

  const handleReturnToGrid = () => {
    setShowDetails(false);
  };

  const handleReturnToMemberGrid = () => {
    setShowMemberDetails(false);
  };

  const memberTabs = [
    {
      key: "profile",
      title: i18n("Details"),
      component: <MemberProfileInfo selectedMember={data?.member} />,
    },
    {
      key: "subscription",
      title: i18n("Subscription"),
      component: <SubscriptionList memberId={data?.member?.id} />,
    },
  ];

  const memberHeader = (
    <TwoStageLayoutHeader
      ItemActions={
        <Stack
          gap={3}
          direction="horizontal"
          className="mt-2 mb-2 justify-content-end"
        >
          <Button
            className=""
            variant="outline-danger"
            onClick={() => handleDelete(data?.member)}
          >
            <FontAwesomeIcon className="mx-2 mb-1" icon={FaTrash} />
            {i18n("Delete")}
          </Button>
          <Button
            className=""
            severity="secondary"
            outlined
            style={{ borderRadius: "5px" }}
            onClick={() => handleEdit(data?.member)}
          >
            <UilEdit
              className="mx-2 mb-1"
              size="16"
              style={{ cursor: "pointer" }}
            />
            {i18n("Edit")}
          </Button>
        </Stack>
      }
      currentTitle={
        <>
          {data?.member?.member_name}
          <Form.Label className="mx-2 badge bg-success bg-gradient">
            {i18n("Member")}
          </Form.Label>
        </>
      }
      returnToGrid={handleReturnToMemberGrid}
    />
  );

  const header = (
    <TwoStageLayoutHeader
      ItemActions={
        <Stack gap={3} direction="horizontal" className="mt-2 mb-2 float-end">
          <Button
            className=""
            variant="outline-danger"
            onClick={() => handleDeleteParty(data?.party)}
            disabled={data?.party?.name == "Walk-in"}
          >
            <FontAwesomeIcon className="mx-2 mb-1" icon={FaTrash} />
            {i18n("Delete")}
          </Button>

          <Button
            className=""
            severity="secondary"
            outlined
            onClick={() => handleEditParty(data?.party)}
            disabled={data?.party?.name == "Walk-in"}
          >
              <UilEdit
              className="mx-2 mb-1"
              size="16"
              style={{ cursor: "pointer" }}
            />
            {i18n("Edit")}
          </Button>
        </Stack>
      }
      currentTitle={
        <>
          {data?.party?.name}
          {data?.party?.type == "customer" ? (
            <Form.Label className="mx-2 badge bg-primary bg-gradient">
              {i18n("Customer")}
            </Form.Label>
          ) : (
            <Form.Label className="mx-2 badge bg-info bg-gradient">
              {i18n("Supplier")}
            </Form.Label>
          )}
        </>
      }
      returnToGrid={handleReturnToGrid}
    />
  );

  const Category = (
    <>
      <PartyCategory />
    </>
  );

  const PartyTypes = (
    <>
      <PartyType />
    </>
  );

  return (
    <>
      <Row className="mt-3">
        <Col md={3}>
          <Form.Label
            className=""
            style={{ fontWeight: 500, fontSize: "14px" }}
          >
            {" "}
            {i18n("Party")}
          </Form.Label>{" "}
          <Button
            variant="link px-2 py-0"
            onClick={() => handlePartyRowClick(data)}
          >
            View
          </Button>
          <br />
          <Form.Label
            className=""
            style={{ fontWeight: 400, fontSize: "12px" }}
          >
            {data?.party?.name ? data.party?.name : "-"}
          </Form.Label>
        </Col>

        <Col md={3}>
          <Form.Label
            className=""
            style={{ fontWeight: 500, fontSize: "14px" }}
          >
            {" "}
            {i18n("Member")}
          </Form.Label>{" "}
          <Button
            variant="link px-2 py-0"
            onClick={() => handleMemberRowClick(data)}
          >
            View
          </Button>
          <br />
          <Form.Label
            className=""
            style={{ fontWeight: 400, fontSize: "12px" }}
          >
            {data?.member?.member_name ? data.member?.member_name : "-"}
          </Form.Label>
        </Col>

        {/* <Col md={3}>
          {" "}
          <Form.Label
            className=""
            style={{ fontWeight: 500, fontSize: "14px" }}
          >
            {" "}
            {i18n("Phone number")}
          </Form.Label>
          <br />{" "}
          <Form.Label
            className=""
            style={{ fontWeight: 400, fontSize: "12px" }}
          >
            {data?.party?.phone_number ? data.party?.phone_number : "-"}
          </Form.Label>
        </Col> */}
        <Col md={3}>
          {" "}
          <Form.Label
            className=""
            style={{ fontWeight: 500, fontSize: "14px" }}
          >
            {" "}
            {i18n("Email")}
          </Form.Label>
          <br />{" "}
          <Form.Label
            className=""
            style={{ fontWeight: 400, fontSize: "12px" }}
          >
            {data?.party?.email ? data.party?.email : "-"}
          </Form.Label>
        </Col>
        <Col md={3}>
          {" "}
          <Form.Label
            className=""
            style={{ fontWeight: 500, fontSize: "14px" }}
          >
            {" "}
            {i18n("GSTIN")}
          </Form.Label>
          <br />{" "}
          <Form.Label
            className=""
            style={{ fontWeight: 400, fontSize: "12px" }}
          >
            {data?.party?.gstin ? data.party?.gstin : "-"}
          </Form.Label>
        </Col>
      </Row>
      {showEditMember && (
        <EditMember memberId={memberId} setShowEditMember={setShowEditMember} />
      )}

      {show && (
        <DeleteModel deleteUserClick={onDelete} onClickDeleteModel={setShow} />
      )}

      {showEditParty && (
        <EditParty 
        show={showEditParty}
        setShowEditParty= { setShowEditParty}
        partyId={partyId}
        />
      )}
 {showParty && (
        <DeleteModel deleteUserClick={onDeleteParty} onClickDeleteModel={setShowParty} />
      )}
      {showDetails && (
        <DetailsModal
          show={showDetails}
          type="All"
          title="Party Details"
          tabs={productTabs}
          summary={partySummary}
          currentItemId={data?.party_id}
          ItemTitle="Parties"
          header={header}
          setShow={setShowDetails}
        />
      )}
      {showMemberDetails && (
        <DetailsModal
          show={showMemberDetails}
          title="Member Details"
          tabs={memberTabs}
          currentItemId={data?.member?.member_id}
          ItemTitle="Members"
          header={memberHeader}
          setShow={setShowMemberDetails}
        />
      )}
    </>
  );
};

export default CustomerDetails;
