import React, { useState } from 'react';
import { Modal, Button, Form, FormControl } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { updateParty } from '../../../../store/posOrderSlice';
import { i18n, placeholderText } from '../../../../../shared/helpers/sharedMethods';

const EditPartyModal = (props) => {
    const currentUser = useSelector(state => state?.businessSwitch?.selectedBusiness);
    const selectedBusiness = useSelector(
        (state) => state?.auth?.selectedBusiness
      );
    const { partyModalShow, togglePartyModal } = props;
    const [partyName, setPartyName] = useState('');
    const [partyPhoneNumber, setPartyPhoneNumber] = useState('');
    const dispatch = useDispatch();
    const { accessToken} = useSelector((state) => state?.auth);
    const tenantId = selectedBusiness?.pivot?.tenant_id;
    const { terminalId } = useSelector((state) => state.initInstall);
    const handlePartySave = () => {

        if(partyName == '' || partyPhoneNumber == ''){
            alert('Please enter valid details');
            return;
        }

        let party = {
            name : partyName,
            phone_number : partyPhoneNumber,
            id : Math.floor(Math.random() * 1000),
            type : 'customer',
            created_by : currentUser.id,
            updated_by : currentUser.id
        }

        dispatch(updateParty(party));
        togglePartyModal(false);
    }

    return (
        <Modal show={partyModalShow} onHide={() => {togglePartyModal(false)}}>
            <Modal.Header closeButton>
                <Modal.Title>{i18n("Edit Customer")}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form.Group className='calculation__filed-grp mb-2'>

                    <FormControl type='text' id='tax' name='tax' min='0' step='.01' placeholder={placeholderText("Name")}
                        onChange={(e) => {setPartyName(e.target.value)} }
                        value={partyName}
                        className='rounded-1 pe-8'
                        autoFocus
                    />

                </Form.Group>

                <Form.Group className='calculation__filed-grp mb-2'>

                    <FormControl type='text' id='tax' name='tax' min='0' step='.01' placeholder={placeholderText("Phone Number")}
                        onChange={(e) => {setPartyPhoneNumber(e.target.value)} }
                        pattern='[0-9]*'
                        maxLength="10"
                        value={partyPhoneNumber}
                        className='rounded-1 pe-8'
                    />

                </Form.Group>


            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" type='submit' onClick={handlePartySave}>
                {i18n("Save Changes")}
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default EditPartyModal;