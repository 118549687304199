import { Col, Form, FormControl, Row } from "react-bootstrap";
import { i18n } from "../../../../shared/helpers/sharedMethods";
import { useDispatch } from "react-redux";
import { changeSetupFeesName } from "../../../store/subscription_item_store/SetupFeesEntitySlice";
import { ErrorMessage } from "formik";


const ProductName = ({ values, handleChange, touched, errors }) => {
  const dispatch = useDispatch();
  return (
    <Row className="py-4">
      {" "}
      <Col lg={3}>
        {" "}
        <Form.Group>
          <Form.Label>{i18n("Name")} </Form.Label>
          <span className="required" />
        </Form.Group>
      </Col>
      <Col lg={4}>
        <FormControl
        autoFocus
            className={`form-control ${
              touched.name && errors.name ? "is-invalid" : ""
            }`}
          type="text"
          name="name"
          value={values.name}
          onChange={(e) => {
            handleChange(e);
            dispatch(changeSetupFeesName(e.target.value));
          }}
        />
         <ErrorMessage
            name="name"
            component="span"
            className="text-danger d-block fw-400 fs-small mt-2"
          />
      </Col>
    </Row>
  );
};

export default ProductName;