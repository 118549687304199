import React, { useEffect } from "react";
import { Card, Row, Table } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { itemReportData } from "../../../store/itemReportSlice";
import { i18n } from "../../../../shared/helpers/sharedMethods";
import GlobalCurrency from "../../../../shared/components/GlobalCurrency";

const ItemwiseReport = (props) => {
  const { partyId } = props;
  const dispatch = useDispatch();
  const accessToken = useSelector((state) => state?.auth?.accessToken?.access_token);
  const selectedBusiness = useSelector((state) => state?.auth?.selectedBusiness);
  const tenantId = selectedBusiness?.pivot?.tenant_id;
  useEffect(() => {
    if (partyId) {
      dispatch(itemReportData({ id: partyId, token: accessToken, tenant_id: tenantId }));
    }
  }, []);

  const itemReportDatas = useSelector((state) => state.itemWiseReport?.data);

  return (
    <Row className="px-3 mt-5 scrollable-table">
      <Card.Body>
        <Table
          bordered hover responsive>
          <thead  >
            <tr style={{color:'#495057'}}>
              <th className="px-2" style={{ backgroundColor: '#F9FAFB' }}>{i18n("Item Name")}</th>
              <th className="px-2" style={{ backgroundColor: '#F9FAFB' }}>{i18n("Item Code")}</th>
              <th className="px-2" style={{ backgroundColor: '#F9FAFB' }}>{i18n("Sales Quantity")}</th>
              <th className="px-2" style={{ backgroundColor: '#F9FAFB' }}>{i18n("Sales Amount")}</th>
              <th className="px-2" style={{ backgroundColor: '#F9FAFB' }}>{i18n("Purchase Quantity")}</th>
              <th className="px-2" style={{ backgroundColor: '#F9FAFB' }}>{i18n("Purchase Amount")}</th>
            </tr>
          </thead>
          <tbody>
            {itemReportDatas &&
              itemReportDatas?.length > 0 &&
              itemReportDatas?.map((data, index) => (
                <tr key={index}>
                  <td className="p-1 px-2 m-1 mx-3">{data?.itemProduct ? data.itemProduct : '-'}</td>
                  <td className="p-1 px-2 m-1 mx-3">{data?.itemCode ? data.itemCode : '-'}</td>
                  <td className="p-1 px-2 m-1 mx-3">
                    {data.saleQuantity ? data.saleQuantity : 0}{" "}
                    {data?.saleUnit ? data?.saleUnit : " "}
                  </td>
                  <td className="p-1 px-2 m-1 mx-3">
                    <GlobalCurrency price={data?.saleAmount} />
                  </td>
                  <td className="p-1 px-2 m-1 mx-3">
                    {data.purchaseQuantity ? data.purchaseQuantity : 0}{" "}
                    {data?.saleUnit ? data?.saleUnit : " "}
                  </td>
                  <td className="p-1 px-2 m-1 mx-3">
                    <GlobalCurrency price={data?.purchaseAmount} />
                  </td>
                </tr>
              )
              )}
          </tbody>
        </Table>
      </Card.Body>
    </Row>
  );
};

export default ItemwiseReport;
