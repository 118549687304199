import { createSlice, createAsyncThunk, createEntityAdapter,
    createSelector, } from "@reduxjs/toolkit";
import { axiosRequest } from "../../shared/helpers/axiosHelper";
import { filterDeletedParty, preprocessorDeletedParty } from "../helpers/partyHelper";
import { i18n } from "../../shared/helpers/sharedMethods";

const partyDeletedAdapter = createEntityAdapter();

const initialState = partyDeletedAdapter.getInitialState({
    status: false,
    error: null,
    partyType: [],
});

export const getAllDeletedPartys = createAsyncThunk(
    'partysDeletedRecords/fetch',
    async ({ token, tenantId }) => {
        try {
            const response = await axiosRequest({
                method: "GET",
                url: 'deleted/parties',
                headers: {
                    Authorization: `Bearer ${token}`,
                    "X-Tenant": tenantId,
                },
            });
            console.log('Fetched deleted parties:', response.data);
            return response.data;
        } catch (error) {
            await window.log.logToFile({ message: "Error while fetching all deleted parties", level: "error" });
            throw new Error('Error fetching deleted parties');
        }
    }
);

const partyDeletedSlice = createSlice({
    name: "partysDeletedRecords",
    initialState,
    reducers: {
        resetDeletedParty: () => initialState,
        addPartyType(state, action) {
          state.partyType = action.payload
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getAllDeletedPartys.pending, (state) => {
                state.status = false;
                state.error = null;
            })
            .addCase(getAllDeletedPartys.fulfilled, (state, action) => {
                state.status = true;
                partyDeletedAdapter.upsertMany(state, action.payload);
            })
            .addCase(getAllDeletedPartys.rejected, (state, action) => {
                state.status = false;
                state.error = action.error.message;
            })
            .addCase(deletedPartyRestore.fulfilled, partyDeletedAdapter.removeOne)
            .addCase(deletedPartyRestore.rejected, (state, action) => {
              state.status = false;
              state.error = i18n('Unable to restore the party right now. Please try again later.');
            })
            .addCase(deletePartyPermanently.fulfilled, partyDeletedAdapter.removeOne)
            .addCase(deletePartyPermanently.rejected, (state, action) => {
              state.status = false;
              state.error = i18n('Unable to delete the party right now. Please try again later.');
            })
    },
});

export const deletedPartyRestore = createAsyncThunk(
    'partysDeletedRecords/recreate',
    async ({ id, token, tenantId }) => {
        try {
            const response = await axiosRequest({
                method: "POST",
                url: `restore/parties/${id}`,
                headers: {
                    Authorization: `Bearer ${token}`,
                    "X-Tenant": tenantId,
                },
            });
            return response.data;
        } catch (error) {
            await window.log.logToFile({ message: "Error while restoring deleted party", level: "error" });
            throw new Error('Error restoring party');
        }
    }
);
  
export const deletePartyPermanently = createAsyncThunk(
    'partysDeletedRecords/permanatdelete',
    async ({ id, token, tenantId }) => {
        try {
            const response = await axiosRequest({
                method: "DELETE",
                url: `permanent-delete/parties/${id}`,
                headers: {
                    Authorization: `Bearer ${token}`,
                    "X-Tenant": tenantId,
                },
            });
            return response.data;
        } catch (error) {
            await window.log.logToFile({ message: "Error while deleting party permanently", level: "error" });
            throw new Error('Error deleting party');
        }
    }
);

export default partyDeletedSlice.reducer;

export const { 
    resetDeletedParty,
    addPartyType, } = partyDeletedSlice.actions;

export const { selectAll: selectAllDeletedPartys } = partyDeletedAdapter.getSelectors(
  (state) => state.partysDeletedRecords
);

export const selectPartyType = createSelector(
    [selectAllDeletedPartys, (state, type) => type],
    (partys, type) => {
      if (type === "All") {
        const processedParty = preprocessorDeletedParty(partys);
        return processedParty;
      } else {
        const parties = filterDeletedParty(partys, type);
        const processedParty = preprocessorDeletedParty(parties);
        return processedParty; 
      }
    }
  );
