import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from "@reduxjs/toolkit";
import { prepareNewAddress } from "../helpers/partyHelper";

const partyAddressAdapter = createEntityAdapter();

const initialState = partyAddressAdapter.getInitialState({
  status: false,
  error: null,
  addAddressStatus: false,
});

export const addNewAddress = createAsyncThunk(
  "partyAddress/fetch",
  async (index) => {
    return prepareNewAddress(index);
  }
);

const partyAddressSlice = createSlice({
  name: "partyAddress",
  initialState,
  reducers: {
    updateBillingAddress(state, action) {
      ;
      const { id, fieldName, fieldValue } = action.payload;

      if (fieldName === "status" && fieldValue === true) {
        const currentTrueStatusId = state.ids.find(
          (existingId) => state.entities[existingId].status === true
        );

        if (currentTrueStatusId) {
          state.entities[currentTrueStatusId].status = false;
        }

        const newEntity = state.entities[id];
        if (newEntity) {
          newEntity.status = true;
        }
      } else {
        const item = state.entities[id];
        if (item) {
          item[fieldName] = fieldValue;
        }
      }
    },

    initEdit(state, action) {
      ;
      const { addressData, id } = action.payload;
      const formattedData = {
        label: addressData.label,
        id: id,
        name: addressData.name,
        phone_number: addressData.phone_number,
        status: addressData.status,
        address: addressData.address,
        city: addressData.city,
        state: addressData.state,
        pincode: addressData.pincode,
      };
      state.entities[id] = formattedData;
    },
   
    initEditPartyAddress(state, action) {
      let partyAddress = action.payload;
      ;

      if (
        partyAddress && Array.isArray(partyAddress) &&
        partyAddress.length > 0) {
        partyAddress.forEach((element, i) => {
          const combinedAddress = {
            id: i + 1,
            label: element?.label,
            name: element?.name,
            phone_number: element?.phone_number,
            status: element?.id ,
            address: element?.address,
            city: element?.city,
            state: element?.state,
            pincode: element?.pincode,
          };

          partyAddressAdapter.addOne(state, combinedAddress);
        });
      }
    },
    resetAddressStatus(state) {
      state.addAddressStatus = false;
    },
    deletePartyAddress: partyAddressAdapter.removeOne,
    resetPartyAddress: () => initialState,
  },
  extraReducers(builder) {
    builder.addCase(addNewAddress.fulfilled, (state, action) => {
      partyAddressAdapter.addOne(state, action.payload);
      state.addAddressStatus = true;
    });
  },
});

export default partyAddressSlice.reducer;

export const {
  deletePartyAddress,
  resetPartyAddress,
  updateBillingAddress,
  resetAddressStatus,
  initEditPartyAddress,
  initEdit,
} = partyAddressSlice.actions;

export const {
  selectAll: selectAllPartyAddress,
  selectById: selectPartyAddressById,
  selectIds: selectPartyAddressIds,
} = partyAddressAdapter.getSelectors((state) => state.partyAddress);
