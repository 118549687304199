import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
} from "@reduxjs/toolkit";
import {
  defaultSelectedPayment,
  editCopyPaymentProcessor,
} from "../helpers/paymentHelper";

const partyPaymentAdapter = createEntityAdapter();

const initialState = {
  ...partyPaymentAdapter.getInitialState(),
  status: "idle",
  error: null,
  checkedItems: {},
  copyPayment: [],
  initialPayment: 0,
  processPayment: 0,
  selectedPayment: [],
  previousSelectedVoucher: [],
  // copySelectedVoucher:[]
  statusError: false,
};

export const addPartyPayments = createAsyncThunk(
  "partyPayments/addPartyPayments",
  async (payments) => {
    try {
    ;
    return payments;
    } catch (error) {
      await window.log.logToFile({message: "Error while creating Party Payments", level: "error" });
      throw error;
    }
  }
);

const partyPaymentSlice = createSlice({
  name: "partyPayments",
  initialState,
  reducers: {
    addInitPayment(state, action) {
      state.initialPayment = action.payload;
    },
    processingPayment(state, action) {
      ;
      state.processPayment = action.payload;
    },

    undoPayments(state, action) {
      partyPaymentAdapter.setAll(state, action.payload); // Replace existing payments with new ones
      ;
      state.checkedItems = {};
    },

    updateCheckedItem(state, action) {
      state.checkedItems = action.payload;
    },
    paymentSelected(state, action) {
      const { rowId, index } = action.payload;
      ;
      ;
      ;

      state.initialPayment > 0
        ? (state.checkedItems[rowId] = !state.checkedItems[rowId])
        : (state.checkedItems[rowId] = false);
      const existingItem = state.entities[rowId];
      if (state.processPayment <= 0) {
        state.statusError = true;
      } else {
        if (state.checkedItems[rowId]) {
          if (state.initialPayment > 0) {
            ;
            existingItem.balance_amount =
              state.initialPayment - existingItem.balance_amount >= 0
                ? 0
                : existingItem.balance_amount - state.initialPayment;
            state.initialPayment =
              state.initialPayment - existingItem.balance_amount >= 0
                ? state.initialPayment -
                  Math.abs(
                    existingItem.balance_amount -
                      state.copyPayment[index].balance_amount
                  )
                : 0;

            existingItem.paid_amount =
              existingItem.grand_total - existingItem.balance_amount;
            existingItem.amount = Math.abs(
              existingItem.paid_amount - state.copyPayment[index].paid_amount
            );
            state.selectedPayment = state.selectedPayment || [];
            state.selectedPayment.push(existingItem);
          }
        } else {
          ;
          existingItem.balance_amount = state.copyPayment[index].balance_amount;
          const difference = Math.abs(
            state.copyPayment[index].paid_amount - existingItem.paid_amount
          );
          ;
          state.initialPayment += difference;
          existingItem.paid_amount = state.copyPayment[index].paid_amount;
          state.selectedPayment = (state.selectedPayment || []).filter(item => item.id !== rowId) ;
        }
      }
    },
    editpaymentSelected(state, action) {
      const { rowId, index, foundItem, editData } = action.payload;
      //    state.selectedPayment = state.selectedPayment.filter(item => item.id !== rowId);
      if (state.initialPayment > 0) {
        state.checkedItems[rowId] = !state.checkedItems[rowId];
      } else {
        state.checkedItems[rowId] = false;
      }
      
      const existingItem = state.entities[rowId];
      
      if (state.checkedItems[rowId] && state.initialPayment > 0) {
        existingItem.balance_amount = Math.max(
          existingItem.balance_amount - state.initialPayment,
          0
        );
        existingItem.paid_amount =
          existingItem.grand_total - existingItem.balance_amount;

        if (existingItem.paid_amount === 0) {
          state.initialPayment =
            state.initialPayment -
            Math.abs(
              existingItem.balance_amount -
                state.copyPayment[index].balance_amount
            );
        } else {
          state.initialPayment -= Math.abs(
            state.copyPayment[index].balance_amount -
              existingItem.balance_amount
          );
        }
        existingItem.amount = Math.abs(
          existingItem.paid_amount - state.copyPayment[index].paid_amount
        );
        state.selectedPayment = state.selectedPayment || [];
        state.selectedPayment.push(existingItem);
        } else {
        ;
        existingItem.balance_amount = state.copyPayment[index].balance_amount;
        const difference = Math.abs(
          state.copyPayment[index].paid_amount - existingItem.paid_amount
        );
        ;
        
        state.initialPayment += difference;
        existingItem.paid_amount = state.copyPayment[index].paid_amount;
        state.selectedPayment = (state.selectedPayment || []).filter(item => item.id !== rowId)   
        // state.copySelectedVoucher = state.copySelectedVoucher.filter(item => item.voucher_id !== rowId);
      }
    },
    updatePartyPayment(state, action) {
      const {
        iteratePayments,
        amount,
        voucher_ids,
        previousSelectedVoucher,
        selectedBusiness,
      } = action.payload;
      state.previousSelectedVoucher = previousSelectedVoucher;
      state.selectedPayment = defaultSelectedPayment(
        previousSelectedVoucher,
        selectedBusiness,
        amount
      );
      partyPaymentAdapter.addMany(state, iteratePayments); // Corrected line
      state.copyPayment = editCopyPaymentProcessor(
        iteratePayments,
        amount,
        voucher_ids
      );
    },
    resetSelectedPayment(state, action) {
      state.selectedPayment = []
    },
    resetPartyPayment: () => initialState,
    resetStatusError(state) {
      state.statusError = false;
    },
  },
  extraReducers(builder) {
    builder.addCase(addPartyPayments.fulfilled, (state, action) => {
      partyPaymentAdapter.addMany(state, action.payload);
      state.copyPayment = action.payload;
    });
  },
});

export default partyPaymentSlice.reducer;

export const {
  paymentSelected,
  updatePartyPayment,
  resetPartyPayment,
  addInitPayment,
  processingPayment,
  undoPayments,
  updateCheckedItem,
  editpaymentSelected,
  resetStatusError,
  resetSelectedPayment
} = partyPaymentSlice.actions;

export const {
  selectAll: selectAllPartyPayment,
  selectById: selectAllPartyPaymentById,
  selectIds: selectAllPartyPaymentIds,
} = partyPaymentAdapter.getSelectors((state) => state.partyPayments);

export const selectPartByType = createSelector(
  [selectAllPartyPayment, (state, type) => type],
  (payments, type) => {
    // Log the incoming parameters
    console.log("selectPartByType called with type:", type);

    if (!type) {
      console.error("Type is undefined");
      return [];
    }

    const filteredPayments = payments.filter((payment) => payment.type === type);
    console.log("Filtered Payments:", filteredPayments);
    return filteredPayments;
  }
);
