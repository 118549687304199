import {
    createSlice,
    createAsyncThunk,
  } from "@reduxjs/toolkit";
import { i18n } from "../../shared/helpers/sharedMethods";
import { axiosRequest } from "../../shared/helpers/axiosHelper";

const initialState = {
  status:false,
  reportItems:[],
  lastOrderDays:"",
  error:false,
  errorMessage:''
};

export const fetchPartyProfileInfo = createAsyncThunk(
  "partyProfileInfo/fetch",
  async (param) => {
    try{
      const token = param.token;
      const tenantId = param.tenant_id;
      const id = param.id;
        const partyProfileInfoResponse = await axiosRequest({
          method: "GET",
          url: `get-party-summary`,
          params: {
            party_id: id,
          },
          headers: {
            Authorization: `Bearer ${token}`,
            "X-Tenant": tenantId,
          },
        });
        return partyProfileInfoResponse.data;
    }catch(err){
      await window.log.logToFile({message: "Error while fetching Party Profile Info", level: "error" });
      throw new Error("Error while fetching party Profile Info report")
    }
  }
);

export const getLastOrder = createAsyncThunk("partyProfileInfo/getLastOrder", async (id) => {
  try {
    ;
    const getLastOrderDate = await window.api.getLastOrderDate(id);
    return getLastOrderDate;
  } catch (err) {
    await window.log.logToFile({message: "Error while fetching last Order", level: "error" });
    throw new Error("Error while getting last date of party");
  }
});

const partyProfileInfoSlice = createSlice({
    name: "partyProfileInfo",
    initialState,
    reducers: {},
    extraReducers(builder) {
      builder
        .addCase(fetchPartyProfileInfo.fulfilled, (state, action) => {
          state.status = true
          state.reportItems = action.payload;
        })
        .addCase(fetchPartyProfileInfo.rejected, (state, action) => {
          state.error = true; 
          state.errorMessage=(i18n("Error while fetching party Profile Info report. Please try again later."));
          state.status = false;
        })
        .addCase(getLastOrder.fulfilled, (state, action) => {
         
          state.lastOrderDays = action.payload;
        })
        .addCase(getLastOrder.rejected, (state, action) => {
          state.error = true; 
          state.errorMessage=(i18n("Error while getting days "));
          state.status = false;
        })
    },
});

export default partyProfileInfoSlice.reducer;

