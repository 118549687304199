import React, {  useRef } from "react";
import { Modal,  Form, Button,  Stack } from "react-bootstrap";
import { i18n } from "../../shared/helpers/sharedMethods";
// import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch, useSelector } from "react-redux";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { changeCode } from "../store/productEntitySlice";
import { taxList } from "../../shared/config/taxConfig";
import { UilPrint, UilImport } from "@iconscout/react-unicons";
import Barcode from "react-barcode";
import GlobalCurrency from "../../shared/components/GlobalCurrency";

const ViewBarCode = (props) => {
  const { handleClose, show, barCodeItemData } = props;
  const targetRef = useRef();
  const componentRef = useRef();
  const getSetting = useSelector((state) => state.getSetting?.dataSetting);
  ;
  const gst = taxList.find((value) => value.code == barCodeItemData.tax_code);

  const dispatch = useDispatch();

  const DownloadPdf = () => {
    if (!componentRef.current) {
      console.error("Component reference is not set.");
      return;
    }

    html2canvas(componentRef.current, {
      scale: 2,
      useCORS: true,
      allowTaint: true,
      logging: true,
    }).then((canvas) => {
      const pdf = new jsPDF("p", "mm", "a4");

      let xPos = 5;
      let yPos = 5;

      canvas.toDataURL("image/png", 1.0);
      for (let i = 0; i < 12; i++) {
        for (let j = 0; j < 4; j++) {
          pdf.addImage(canvas, "PNG", xPos, yPos, 40, 0, "", "FAST");
          xPos += 40 + 10; 
        }
        xPos = 5; 
        yPos += 17 + 10; 
      }

      pdf.save(`barcode_${Date.now()}.pdf`);
    });
  };

  const handlePrint = () => {
    if (!componentRef.current) {
      console.error("Component reference is not set.");
      return;
    }

    html2canvas(componentRef.current, {
      scale: 2,
      useCORS: true,
      allowTaint: true,
      logging: true,
    }).then((canvas) => {
      const imageData = canvas.toDataURL("image/png");

      const doc = new jsPDF("p", "mm", "a4");

      let xPos = 5; 
      let yPos = 5; 

      for (let i = 0; i < 12; i++) {
        for (let j = 0; j < 4; j++) {
          doc.addImage(imageData, "PNG", xPos, yPos, 40, 0, "", "FAST");
          xPos += 40 + 10;
        }
        xPos = 5; 
        yPos += 17 + 10;
      }

      doc.autoPrint();
      window.open(doc.output("bloburl"), "_blank");
    });
  };

  const handleDelete = () => {
    dispatch(changeCode(""));
    handleClose();
  };

  return (
    <>
      {/* <ToastContainer /> */}
      <Modal
        show={show}
        dialogClassName="modal-dialog modal-dialog-centered mw-700px"
        onHide={handleClose}
        keyboard={true}
      >
        <div
          className=""
          style={{
            borderRadius: "5px",
            boxShadow: "0 0 500px 500px rgba(0, 0, 0, 0.3)",
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title className="fw-bolder">{i18n("BarCode")}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Stack
              direction="vertical"
              gap={2}
              className="ms-auto border rounded p-2"
              style={{ backgroundColor: "#edf6ef", borderColor: "#b6dbc1" }}
            >
              <span
                className="text-black  fw-normal px-3"
                style={{ fontSize: "13px" }}
              >
                •{" "}
                {i18n(
                  "Print a barcode for quick billing and attach it to your items"
                )}
              </span>
              <span
                className="text-black  fw-normal px-3"
                style={{ fontSize: "13px" }}
              >
                • {i18n("Only A4 printing is supported")}
              </span>
            </Stack>

            <div
              ref={componentRef}
              className="d-flex flex-column align-items-center"
            >
              <Form.Label className="d-flex justify-content-center fw-bolder mt-2">
                {getSetting && getSetting?.business_name}
              </Form.Label>
              <Barcode
                value={barCodeItemData?.code|| ""}
                format="CODE128"
                width={2}
                height={60}
                displayValue={true}
              />
  
              <Form.Label className="d-flex justify-content-center p-1">
                {barCodeItemData ? barCodeItemData?.name : ""}
              </Form.Label>

              <Stack
                direction="horizontal"
                gap={2}
                className="d-flex justify-content-center"
              >
                <Form.Label className="">
                  MRP <GlobalCurrency price={barCodeItemData ? barCodeItemData?.sale_price : ""}/>
                </Form.Label>
              </Stack>
            </div>

            <Stack
              direction="horizontal"
              gap={6}
              className="ms-auto p-2 d-flex justify-content-center"
            >
              <Button
                variant="outline-secondary"
                onClick={DownloadPdf}
              >
                {i18n("Download BarCode")}
                <UilImport className="mx-2 mb-1" size="16" />
              </Button>
 
              <Button
                variant="outline-secondary"
                id="button-addon1"
                onClick={handlePrint}
              >
                {i18n("Print BarCode")}
                <UilPrint className="mx-2 mb-1" size="16" />
              </Button>
            </Stack>
          </Modal.Body>
        </div>
      </Modal>
    </>
  );
};

export default ViewBarCode;