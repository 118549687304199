import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
} from "@reduxjs/toolkit";
import { filterVouchers } from "../helpers/VouchersHelper";
import { i18n } from "../../shared/helpers/sharedMethods";
import { formatVoucherType } from "../../shared/helpers/typeHelper";
import { axiosRequest } from "../../shared/helpers/axiosHelper";
import { getPaymentId, getPaymentNumber } from "../../payment/store/paymentEntitySlice";

const salesAdapter = createEntityAdapter();

const initialState = salesAdapter.getInitialState({
  data: [],
  status: "",
  invoicePartialPayment: false,
  error: null,
  errorStatus: false,
  bulkImport: [],
  importData: null,
  statusFilter: "all",
  loading: false,
});

export const getAllVoucher = createAsyncThunk(
  "voucher/getAllVoucher",
  async ({ token, tenant_id, type, pagination }) => {
    try {
      const params = {
        pagination: pagination ? true : false
      };

      const salesResponse = await axiosRequest({
        method: "GET",
        url: `voucher`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "X-Tenant": tenant_id,
        },
        params
      });

      return salesResponse.data;
    } catch (error) {
      await window.log.logToFile({ message: "Error while fetching voucher", level: "error" });
      console.error("Error fetching vouchers:", error);
      throw error;
    }
  }
);

export const createPayment = createAsyncThunk('payment/createPayment', async (args, { dispatch }) => {
  console.log("VSLICE", args)
  try {


    const token = args.token;
    const data = args.data;
    const tenantId = args.tenant_id;
    const resourceType = 'voucher';
    const terminalNumber = args.terminal_id;



    const transformedData = {
      ...args.data,
    };

    let paymentType;
    if (
      args.data.type === "sales_invoice" ||
      args.data.type === "sales_return" ||
      args.data.type === "credit_note" ||
      args.data.type === "quote"
    ) {
      paymentType = "payment_in";
    } else {
      paymentType = "payment_out";
    }

    const paymentNumberResponse = await dispatch(getPaymentNumber({
      token,
      tenant_id: args.tenant_id,
      data: paymentType
    })).unwrap()

    const paymentIdResponse = await dispatch(getPaymentId({
      token,
      tenant_id: args.tenant_id,
      terminal_id: args.terminal_id,
      data: 'payment'
    })).unwrap()

    const payment = {
      id: paymentIdResponse,
      party_id: args.data.party_id,
      payment_number: paymentNumberResponse,
      type: paymentType,
      amount: args.data.amount,
      balance: args.data.grand_total - args.data.paid_amount,
      mode: "cash",
      status: "open",
      created_by: args.data.created_by,
      updated_by: args.data.updated_by,
      created_at: args.data.created_at,
      updated_at: args.data.updated_at
    };
    transformedData.voucher_payments = [
      ...transformedData.voucher_payments,
      {
        voucher_id: args.data.id,
        payment_id: paymentIdResponse,
        amount: args.data.amount,
        created_by: args.data.created_by,
        updated_by: args.data.updated_by,
        created_at: args.data.created_at,
        updated_at: args.data.updated_at,
        payment: payment
      }];

    console.log("transformedData", transformedData)

    const createPayment = await axiosRequest({
      method: "POST",
      url: `resource/${resourceType}/create/${terminalNumber}`,
      data: transformedData,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "X-Tenant": tenantId,
      },
    });
    if (createPayment.success) {
      return createPayment.data;
    }
    else {
      const errorMessage = createPayment?.data?.map((d) => d.message).join(", ");
      throw errorMessage;
    }
  } catch (error) {
    await window.log.logToFile({ message: "Error while creating Payment", level: "error" });
    throw new Error(error);
  }

});

export const deletesales = createAsyncThunk(
  "voucher/deletesales",
  async (args) => {
    try {
      console.log('datss', args);
      const token = args.token;
      const data = args.data;
      const tenantId = args.tenant_id;
      const resourceType = 'voucher';
      const terminalNumber = args.terminal_id;
      const response = await axiosRequest({
        method: "DELETE",
        url: `resource/${resourceType}/delete/${terminalNumber}`,
        data: data,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "X-Tenant": tenantId,
        },
      });
      return response.data;
    } catch (error) {
      await window.log.logToFile({ message: "Error while deleting voucher", level: "error" });
      console.error("Error deleting sales:", error);
      throw error;
    }
  }
);

export const fetchVoucherDate = createAsyncThunk(
  "voucher/fetchVoucherDate",
  async (dataCreate) => {
    try {
      ;
      const VoucherDateResponse = await window.api.getVoucherDate(dataCreate);
      ;
      return VoucherDateResponse.data; // It seems you returned a variable not defined in this scope; it might be a typo
    } catch (error) {
      await window.log.logToFile({ message: "Error while fetching voucher date", level: "error" });
      console.error("Error fetching voucher date:", error);
      throw error;
    }
  }
);

export const fetchTotalAmount = createAsyncThunk(
  "voucher/fetchTotalAmount",
  async (args) => {
    try {
      ;
      const totalAmountResponse = await window.api.getTotalAmount(args);
      ;
      return totalAmountResponse.data;
    } catch (error) {
      await window.log.logToFile({ message: "Error while fetching total amount", level: "error" });
      throw error;
    }
  }
);

export const importVoucher = createAsyncThunk(
  "importVoucher/fetch",
  async (args) => {
    console.log('args voucher import', args);
    const token = args.token;
    const data = { voucher: args.data };
    const tenantId = args.tenant_id;
    try {
      const importResponse = await axiosRequest({
        method: "POST",
        url: `import-voucher`,
        data: data,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "X-Tenant": tenantId,
        },
      });
      if (importResponse.success) {
        return importResponse.data;
      } else {
        const errorMessage = importResponse?.data?.map((d) => d.message).join(", ");
        throw errorMessage;
      }
    }
    catch (error) {
      await window.log.logToFile({ message: "Error while importing voucher", level: "error" });
      throw new Error(error);
    }
  }
);

export const updateVoucherPartialPayment = createAsyncThunk(
  "payment/updateVoucherPartialPayment",
  async (args, { dispatch }) => {
    console.log("VSLICE", args)
    try {


      const token = args.token;
      const data = args.data;
      const tenantId = args.tenant_id;
      const resourceType = 'voucher';
      const terminalNumber = args.terminal_id;


      const updatePayment = await axiosRequest({
        method: "POST",
        url: `resource/${resourceType}/create/${terminalNumber}`,
        data: data,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "X-Tenant": tenantId,
        },
      });
      if (updatePayment.success) {
        return updatePayment.data;
      }
      else {
        const errorMessage = updatePayment?.data?.map((d) => d.message).join(", ");
        throw errorMessage;
      }
    } catch (error) {
      await window.log.logToFile({ message: "Error while updating Payment", level: "error" });
      throw new Error(error);
    }
  }
);

export const deleteVoucherPartialPayment = createAsyncThunk(
  "payment/deleteVoucherPartialPayment",
  async (row) => {
    try {
      const deletePaymentResponse = await window.api.deleteVoucherPartialPayment(row);

      if (deletePaymentResponse.success) {
        return deletePaymentResponse.data;
      } else {
        throw new Error(deletePaymentResponse.message || "Unknown error occurred");
      }
    } catch (err) {
      await window.log.logToFile({
        message: "Error while updating payment",
        level: "error",
      });
      throw new Error("Error while updating payment: " + err);
    }
  }
);

const voucherSlice = createSlice({
  name: "voucher",
  initialState,
  reducers: {
    pushNewSale: salesAdapter.addOne,
    updateSale(state, action) {
      ;
      const { id } = action.payload;
      state.entities[id] = action.payload;
    },
    exportData(state, action) {
      ;
      ;
      state.salesByType = action.payload;
    },
    setStatusFilter(state, action) {
      ;
      state.statusFilter = action.payload;
    },
    updateVoucherImport(state, action) {
      ;
      state.bulkImport = action.payload;
    },
    handleVoucherOneByOne: (state, action) => {
      if (!Array.isArray(state.bulkImport)) {
        state.bulkImport = [];
      }
      state.bulkImport.push(action.payload);
    },
    voucherImportError(state, action) {
      state.error = action.payload;
      if (Array.isArray(action.payload) && action.payload?.length > 0) {
        state.errorStatus = true;
      }
    },
    resetStatusFilter(state) {
      state.statusFilter = "all";
    },
    resetPartialStatus(state) {
      state.invoicePartialPayment = false
    },
    resetSales: () => initialState,
    undoVoucherImportError(state, action) {
      state.errorStatus = false;
      state.error = ''
    },
    updateReceivedAmount(state, action) {
      const { id, paid_total } = action.payload;
      state.entities[id].paid_amount = paid_total;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getAllVoucher.pending, (state) => {
        state.loading = true;
        state.status = "loading";
      })
      .addCase(getAllVoucher.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.loading = false;
        salesAdapter.upsertMany(state, action.payload);
      })
      .addCase(getAllVoucher.rejected, (state, action) => {
        state.status = "failed";
        state.loading = false;
        state.error = i18n(
          "Unable to retrieve sales. Please try again later."
        );
      })
      .addCase(deletesales.fulfilled, salesAdapter.removeOne)
      .addCase(deletesales.rejected, (state, action) => {
        state.status = "delete Failed";
        state.error = i18n(
          "Error while deleting sales. Please try again later."
        );
      })
      .addCase(createPayment.fulfilled, (state, action) => {
        salesAdapter.updateOne(state, {
          id: action.payload.id,
          changes: action.payload
        });
        state.invoicePartialPayment = true;
        //state.paid_amount = action.payload.paid_amount

        //state.savedEntity = action.payload;
      })
      .addCase(createPayment.rejected, (state, action) => {
        state.status = false;
        state.errorStatus = true;
        state.error = action?.error?.message;
      })
      .addCase(updateVoucherPartialPayment.fulfilled, (state, action) => {
        console.log("Fulfilled Payload: ", action.payload);
        salesAdapter.updateOne(state, {
          id: action.payload.id,
          changes: action.payload
        });
        state.invoicePartialPayment = true;
      })
      .addCase(updateVoucherPartialPayment.rejected, (state, action) => {
        state.status = false;
        state.errorStatus = true;
        state.error = action?.error?.message;
      })
      .addCase(deleteVoucherPartialPayment.fulfilled, (state, action) => {
        console.log("Fulfilled Payload: ", action.payload);
        salesAdapter.updateOne(state, {
          id: action.payload.id,
          changes: action.payload
        });
        state.invoicePartialPayment = true;
      })
      .addCase(deleteVoucherPartialPayment.rejected, (state, action) => {
        state.status = false;
        state.errorStatus = true;
        state.error = action?.error?.message;
      })

      .addCase(fetchTotalAmount.fulfilled, (state, action) => {
        state.status = "succeeded";
        ;
        state.totalAmountData = action.payload;
      })
      .addCase(fetchTotalAmount.rejected, (state, action) => {
        state.status = "failed";
        state.error = i18n(
          "Unable to retrieve total amount. Please try again later."
        );
      })
      .addCase(importVoucher.fulfilled, (state, action) => {
        if (Array.isArray(action.payload)) {
          state.status = true;
          state.importData = action.payload;
          salesAdapter.addMany(state, action.payload);
        }
      })
      .addCase(importVoucher.rejected, (state, action) => {
        state.errorStatus = true;
        state.error = action?.error?.message;
      });
  },
});

export default voucherSlice.reducer;
export const {
  qualityCountCalculation,
  resetSales,
  updateVoucherImport,
  pushNewSale,
  setStatusFilter,
  resetStatusFilter,
  updateSale,
  voucherImportError,
  handleVoucherOneByOne,
  undoVoucherImportError,
  resetPartialStatus,
  updateReceivedAmount,
} = voucherSlice.actions;

export const {
  selectAll: selectAllSales,
  selectById: selectSalesById,
  selectIds: selectSalesIds,
} = salesAdapter.getSelectors((state) => state.voucher);

export const selectSalesByType = createSelector(
  [selectAllSales, (state, type) => type],
  (vouchers, type) => {
    console.log("get vouchers", vouchers)
    if (!type) {
      console.error("Type is undefined");
      return [];
    } else {
      ;
      switch (type) {
        case "all_sales":
          return filterVouchers(vouchers, [
            "sales_invoice",
            "sales_return",
            "credit_note",
            "quote",
            "proforma_invoice",
          ]);
        case "all_purchase":
          return filterVouchers(vouchers, [
            "purchase_invoice",
            "purchase_return",
            "debit_note",
            "purchase_order",
          ]);
        default:
          return (
            vouchers &&
            vouchers
              .filter((voucher) => voucher && voucher.type === type)
              .map((voucher) => ({
                id: voucher.id,
                voucher_date: voucher.voucher_date,
                voucher_number: voucher.voucher_number,
                party: voucher?.party?.name,
                due_date: voucher?.due_date,
                paid_amount: voucher?.paid_amount,
                totalPaidAmount: voucher?.totalPaidAmount,
                grand_total: voucher.grand_total,
                status:
                  voucher.paid_amount === 0
                    ? "Unpaid"
                    : voucher.paid_amount < voucher.grand_total
                      ? "Partially Paid"
                      : "Paid",
                type: formatVoucherType(voucher.type),
                voucher_items: voucher.items,
                voucher_payments: voucher.payments,
                created_at: voucher?.created_at,
                sync_status: voucher?.sync_status,
                syncstamp: voucher?.syncstamp
              }))
          );
      }
    }
  }
);

export const selectFilteredVoucher = createSelector(
  [selectAllSales, (state) => state.voucher.statusFilter, (_, type) => type],
  (vouchers, statusFilter, type) => {
    let filteredVouchers;

    switch (statusFilter) {
      case "partiallypaid":
        filteredVouchers = vouchers.filter(
          (voucher) =>
            voucher.paid_amount > 0 && voucher.paid_amount < voucher.grand_total
        );
        break;
      case "paid":
        filteredVouchers = vouchers.filter(
          (voucher) => voucher.paid_amount === voucher.grand_total
        );
        break;
      case "unpaid":
        filteredVouchers = vouchers.filter(
          (voucher) => voucher.paid_amount === 0
        );
        break;
      case "all":
        filteredVouchers = vouchers;
        break;
      default:
        filteredVouchers = vouchers;
        break;
    }

    if (type) {
      filteredVouchers = filteredVouchers.filter(
        (voucher) => voucher && voucher.type === type
      );
    }

    return filteredVouchers.map((voucher) => ({
      id: voucher.id,
      voucher_date: voucher.voucher_date,
      voucher_number: voucher.voucher_number,
      party: voucher?.party?.name,
      due_date: voucher?.due_date,
      paid_amount: voucher?.paid_amount,
      grand_total: voucher.grand_total,
      status:
        voucher.paid_amount === 0
          ? "Unpaid"
          : voucher.paid_amount < voucher.grand_total
            ? "Partially Paid"
            : "Paid",
      type: formatVoucherType(voucher.type),
      voucher_items: voucher.items,
      created_at: voucher?.created_at,
      sync_status: voucher?.sync_status,
      syncstamp: voucher?.syncstamp
    }));
  }
);












