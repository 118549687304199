import VoucherIndex from "../screens/VoucherIndex";
import CreateVoucher from "../screens/CreateVoucher";
import EditVoucher from "../screens/EditVoucher";
import VoucherDetail from "../components/VoucherDetails";
import { i18n } from "../../shared/helpers/sharedMethods";
import VoucherInvoiceView from "../components/VoucherInvoiceView";
import VoucherImport from "../screens/VoucherImport";

const rootPath = "voucher";

export const quotationRoutes = [
  {
    path: rootPath + "/quotation",
    element: (
      <VoucherIndex
        title={i18n("Quotation")}
        type="quote"
        button={i18n("Create Quotation")}
        voucherType="Sales"
        path={rootPath + "/quotation"}
      />
    ),
    isProtected: true,
  },
  {
    path: rootPath + "/quotation/create",
    element: (
      <CreateVoucher
        voucherType="Sales"
        title={i18n("Create Quotation / Estimate")}
        noLabel={true}
        type="quote"
        no="Quotation No"
        date="Quotation Date"
        path={rootPath + "/quotation"}
      />
    ),
    isProtected: true,
  },
  {
    path: rootPath + "/quotation/edit/:id",
    element: (
      <EditVoucher
        path={rootPath + "/quotation"}
        title={i18n("Edit Quotation")}
        type="quote"
      />
    ),
    isProtected: true,
  },
  // {
  //   path: rootPath + "/quotation/view/:id",
  //   element: (
  //     <VoucherDetail
  //       path={rootPath + "/quotation"}
  //       button={i18n("Create Quotation")}
  //       type="quote"
  //       title={i18n("Manage Quotation")}
  //     />
  //   ),
  //   isProtected: true,
  // },
  {
    path: rootPath + "/quotation/view/:id",
    element: (
      <VoucherInvoiceView
        path={rootPath + "/quotation"}
        button={i18n("Create Quotation")}
        type="quote"
        title={i18n("Manage Quotation")}
      />
    ),
    isProtected: true,
  },
  {
    path: rootPath + "/quotation/import",
    element: (
      <VoucherImport
      path={rootPath + "/quotation"}
      type="quote"
      title={i18n("Import Quotation")}
      voucherType="Sales"
      />
    ),
    isProtected: true,
  },
];
