import { Button, Card, Col, Container, Row, Stack } from "react-bootstrap";
import Header from "../../shared/components/Header";
import Layout from "../../shared/components/Layout";
import { i18n } from "../../shared/helpers/sharedMethods";
import AccountSideBar from "../components/AccountSideBar";
import AccountHeader from "../components/AccountHeader";
import OrganizationDetails from "../components/OrganizationDetails";
import { useEffect } from "react";
import { fetchAccountDetails } from "../store/manageAccountSlice";
import { useDispatch, useSelector } from "react-redux";


const ManageAccountDetails = () => {
  const dispatch = useDispatch();

  const auth = useSelector((state) => state?.auth?.selectedBusiness);




  useEffect(() => {
    dispatch(fetchAccountDetails({ id: auth?.pivot?.user_id }));
  }, []);
  return (
    <div className="border  " style={{ height: "calc(100vh - 10px)" }}>
      <Row className=" ">
        <Col lg={12}>
          <AccountHeader />
        </Col>
        <Col lg={12} className="">
          <OrganizationDetails />
        </Col>
      </Row>
    </div>

    // </Layout>
  );
};

export default ManageAccountDetails;
