import { Row } from "react-bootstrap";

import { ToastContainer } from "react-toastify";
import { useNavigate } from "react-router";
import LoadingSpinner from "../../shared/components/LoadingSpinner";
import ImportLayout from "./ImportLayout";
import { useSelector } from "react-redux";
import SalesImport from "./SalesImports";
import { useState } from "react";
import { i18n } from "../../shared/helpers/sharedMethods";
import SalesExport from "./SalesExport";
import ItemTabs from "../../shared/components/item-detail/sections/ItemTabs";

const SalesImportExport = () => {
  const navigate = useNavigate();
   const loading = useSelector((state) => state.getSetting.loading);
  const [reportType, setReportType] = useState("sales_invoice");

  const handleCancel = () => {
    navigate("/dashboard/sales");
  };
const tabs = [
  {
    key: "salesImport ",
    title: " Import",
    component: <SalesImport type={reportType} />,
  },
  {
    key: "salesExport",
    title: " Export",
    component: <SalesExport type={reportType} />,
  },
];
  return (
    <>
      <ToastContainer />
      <ImportLayout
        title="Sales"
        setReportType={setReportType}
        reportType={reportType}
      >
        {loading && <LoadingSpinner />}
        <Row>
          <SalesImport type={reportType} />
          {/* <ItemTabs
            type="Setting"
            tabs={tabs}
            route="0"
            title="SettingsImport"
          /> */}
        </Row>
      </ImportLayout>
    </>
  );
};

export default SalesImportExport;
