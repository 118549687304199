import React from "react";
import { Container, Row, Col } from 'react-bootstrap';
import { useSelector } from "react-redux";
import { format } from "date-fns";
import { i18n } from "../../../../shared/helpers/sharedMethods";
import GlobalCurrency from "../../../../shared/components/GlobalCurrency";

const GeneralInfo = ({ selectedParty }) => {

  return (
    <>
      <div className="d-flex p-1 px-4 ">
        <Col lg={5} style={{ fontWeight: 600 }}>
          {i18n("General Info")}
        </Col>
      </div>
      <div className='mx-2' style={{ borderBottom: '1px solid rgba(10, 10, 10, 0.1)', fontSize: '13px', color: '#76797C' }}>
        <Row className="mt-1 p-1 px-2">
          <Col lg={6}>
            {i18n("Party Name")}
          </Col>
          <Col lg={6} style={{ color: 'black' }}>
            {selectedParty?.name}
          </Col>
        </Row>
      </div>
      <div className='mx-2' style={{ borderBottom: '1px solid rgba(10, 10, 10, 0.1)', fontSize: '13px', color: '#76797C' }}>
        <Row className="mt-1 p-1 px-2">
          <Col lg={6}>
            {i18n("Mobile Number")}
          </Col>
          <Col lg={6} style={{ color: 'black' }}>
            {selectedParty?.phone_number ? selectedParty?.phone_number : '-'}
          </Col>
        </Row>
      </div>
      <div className='mx-2' style={{ borderBottom: '1px solid rgba(10, 10, 10, 0.1)', fontSize: '13px', color: '#76797C' }}>
        <Row className="mt-1 p-1 px-2">
          <Col lg={6}>
            {i18n("Email")}
          </Col>
          <Col lg={6} style={{ color: 'black' }}>
            {selectedParty?.email ? selectedParty?.email : '-'}
          </Col>
        </Row>
      </div>
      <div className='mx-2' style={{ borderBottom: '1px solid rgba(10, 10, 10, 0.1)', fontSize: '13px', color: '#76797C' }}>
        <Row className="mt-1 p-1 px-2">
          <Col lg={6}>
            {i18n("Customer Since")}
          </Col>
          <Col lg={6} style={{ color: 'black' }}>
            {selectedParty?.created_at ? (format(new Date(selectedParty?.created_at), 'dd/MM/yyyy')): ""}{" "}
          </Col>
        </Row>
      </div>
      <div className='mx-2' style={{ borderBottom: '1px solid rgba(10, 10, 10, 0.1)', fontSize: '13px', color: '#76797C' }}>
        <Row className="mt-1 p-1 px-2">
          <Col lg={6}>
            {i18n("Opening Balance")}
          </Col>
          <Col lg={6} style={{ color: 'black' }}>
            <GlobalCurrency price={selectedParty?.opening_balance} />
          </Col>
        </Row>
      </div>
      <div className='mx-2' style={{ borderBottom: '1px solid rgba(10, 10, 10, 0.09)', fontSize: '13px', color: '#76797C' }}>
        <Row className="mt-1 p-1 px-2">
          <Col lg={6}>
            {i18n("Party Type")}
          </Col>
          <Col lg={6} style={{ color: 'black' }}>
            {selectedParty?.type ? selectedParty?.type : '-'}
          </Col>
        </Row>
      </div>
      <div className='mx-2' style={{ fontSize: '13px', color: '#76797C' }}>
        <Row className="mt-1 p-1 px-2">
          <Col lg={6}>
            {i18n("Party Category")}
          </Col>
          <Col lg={6} style={{ color: 'black' }}>
            {selectedParty?.category?.name ? selectedParty?.category?.name : '-'}
          </Col>
        </Row>
      </div>
    </>

  )
}

export default GeneralInfo;