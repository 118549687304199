import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
} from "@reduxjs/toolkit";
import { filterParty, filterPartyCategory, preprocessorParty } from "../helpers/partyHelper";
import { i18n } from "../../shared/helpers/sharedMethods";
import { axiosRequest } from "../../shared/helpers/axiosHelper";

const partyAdapter = createEntityAdapter();

const initialState = partyAdapter.getInitialState({
  status:false,
  error: null,
  errorStatus:false,
  partyByCategory: [],
  partyType: [],
  bulkImport: [],
  loading: false,
});

export const fetchparty = createAsyncThunk("getParty/fetch", async (args) => {
  try {
    const token = args.token;
    const tenantId = args.tenant_id; 
    const partyResponse = await axiosRequest({
      method: "GET",
      url: `parties`,
      headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "X-Tenant": tenantId,
      },
  });
    return partyResponse.data;
  } catch (error) {
    await window.log.logToFile({message: "Error while fetching party", level: "error" });
    throw error;
  }
});

export const deleteParty = createAsyncThunk(
  "getParty/delete",
  async (args) => {
    try {
      console.log('datss',args);
      const token = args.token;
      const data = args.data;
      const tenantId = args.tenant_id;
      const resourceType = 'party';
      const terminalNumber = args.terminal_id;  
      const response = await axiosRequest({
        method: "DELETE",
        url: `resource/${resourceType}/delete/${terminalNumber}`,
        data: data,
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            "X-Tenant": tenantId,
        },
    });
    return response.data;
    } catch (error) {
      await window.log.logToFile({message: "Error while deleting party", level: "error" });
    }
  }
);

export const importParty = createAsyncThunk('importParty/fetch',async(args)=>{
  try{
    console.log('args import party',args);
    const token = args.token;
    const data = { party: args.data };
    const tenantId = args.tenant_id;
    const importPartyResponse =await axiosRequest({
      method: "POST",
      url: `import-party`,
      data: data,
      headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "X-Tenant": tenantId,
      },
  });
      if (importPartyResponse.success) {
      return importPartyResponse.data;
    } else {
      const errorMessage = importPartyResponse?.data?.map((d) => d.message).join(", ");
      throw errorMessage;
          }
  }
  catch (error){
    await window.log.logToFile({message: "Error while importing Party", level: "error" });
      throw new Error (error) ;
  }

});

const getPartySlice = createSlice({
  name: "getParty",
  initialState,
  reducers: {

    pushNewParty: partyAdapter.addOne,
    addCategory(state, action) {
      state.partyByCategory = action.payload
    },
    addPartyType(state, action) {
      state.partyType = action.payload
    },
    updatePartyDatas(state, action) {
      const { id } = action.payload;
      state.entities[id] = action.payload;
    },
    addManyPartyData(state, action) {
      partyAdapter.upsertMany(action.payload);
    },
    updatePartyImport(state, action){
      state.bulkImport = action.payload;
    },
    handlePartyOneByOne: (state, action) => {
      if (!Array.isArray(state.bulkImport)) {
        state.bulkImport = [];
      }
      state.bulkImport.push(action.payload);
    },
    
    partyImportError(state, action) {
      state.error = action.payload;
      if (Array.isArray(action.payload) && action.payload?.length > 0) {
        state.errorStatus = true;
      } 
    },
    resetParty : () => initialState,
    undoPartyImportError(state, action) {
      state.errorStatus = false;
      state.error = ''
    },
  },
  extraReducers(builder) {
    builder
    .addCase(fetchparty.pending, (state) => {
      state.loading = true; 
    })
      .addCase(fetchparty.fulfilled, (state, action) => {
        state.status = true;
        partyAdapter.upsertMany(state, action.payload);
        state.loading = false;
      })
      .addCase(fetchparty.rejected, (state, action) => {
        state.status = false;
        state.loading = false;
        state.error = (i18n('Error while fetching party. Please try again later.'));;
      })
      .addCase(deleteParty.fulfilled, partyAdapter.removeOne)
      .addCase(deleteParty.rejected, (state, action) => {
        state.status = false;
        state.error = (i18n('Unable to delete the party right now. Please try again later'));
      })
      .addCase(importParty.fulfilled,(state,action)=>{
        if (Array.isArray(action.payload)) {
            state.status = true;
            state.importData = action.payload;
            partyAdapter.addMany(state, action.payload);
          }
      })
      .addCase(importParty.rejected, (state,action) => {
        state.errorStatus = true;
        state.error = action?.error?.message;
    })
  },
});

export default getPartySlice.reducer;
export const { 
  addCategory,
  addPartyType,
  resetParty,
  pushNewParty,
  partyImportError,
  addManyPartyData,
  updatePartyDatas,
  handlePartyOneByOne,
  updatePartyImport,
  undoPartyImportError,
 } = getPartySlice.actions;

export const {
  selectAll: selectAllparty,
  selectById: selectpartyById,
  selectIds: selectpartyIds,
} = partyAdapter.getSelectors((state) => state.getParty);

export const selectPartyByType = createSelector(
  [selectAllparty, (state, type) => type],
  (partys, type) => {
    
      if (type === "All") {
        const processedParty = preprocessorParty(partys);
        return processedParty;
      } else {
        const parties = filterParty(partys, type);
        const processedParty = preprocessorParty(parties);
        return processedParty;
          
      }
    }
);
export const selectPartyType = createSelector(
  [selectAllparty, (state, type) => type],
  (partys, type) => {
    if (type === "All") {
      const processedParty = preprocessorParty(partys);
      return processedParty;
    } else {
      const parties = filterParty(partys, type);
      const processedParty = preprocessorParty(parties);
      return processedParty; 
    }
  }
);
export const selectPartyByCategory = createSelector(
  [selectAllparty, (state, category) => category],
  (partys, category) => {
   
    if (category === "all") {
      const processedParty = preprocessorParty(partys);
        return processedParty;
    } else {
      const parties = filterPartyCategory(partys, category);
      const processedParty = preprocessorParty(parties);
      return processedParty; 
    }
  }
);

