import { Col, Form, FormControl, InputGroup, Row } from "react-bootstrap";
import { ErrorMessage } from "formik";
import { useDispatch } from "react-redux";
import { changeSalePrice } from "../../../store/subscription_item_store/SetupFeesEntitySlice";
import { i18n } from "../../../../shared/helpers/sharedMethods";
import { changePlanType } from "../../../store/subscription_item_store/PlanEntitySlice";

const PlanType = ({ values, handleChange, touched, errors }) => {
    const dispatch = useDispatch()
  return (
    <Row className="py-5" style={{ borderTop: "1px solid lightgray" }}>
    <Col lg={3} className="">
      <Form.Group>
        <Form.Label> {i18n("Product Type")} </Form.Label>
        <span className="required" />
      </Form.Group>
    </Col>

    <Col lg={4} className="">
      <Row>
      <Col lg={6}>
          <Form.Check
            type="radio"
            name="type"
            id="inlineRadio2"
            value="service"
            label={i18n("Service")}
            checked={values.type == "service"}
            onChange={(e) => {
              handleChange(e);
              dispatch(changePlanType(e.target.value));
            }}
          />
        </Col>
        <Col lg={6}>
          <Form.Check
            type="radio"
            name="type"
            id="inlineRadio1"
            value="goods"
            label={i18n("Goods")}
            checked={values.type == "goods"}
            onChange={(e) => {
              handleChange(e);
              dispatch(changePlanType(e.target.value));
            }}
          />
        </Col>
       
      </Row>
    </Col>
  </Row>
  );
};

export default PlanType;