import React, { useEffect, useState } from "react";
import ReactDatePicker from "react-datepicker";
import { placeholderText } from "../../helpers/sharedMethods";
import { format } from "date-fns";
import { Button, InputGroup, Stack } from "react-bootstrap";
import { UilCalender } from "@iconscout/react-unicons";

const DatePicker = ({ startDate, setStartDate, endDate, setEndDate }) => {
  const handleDateRangeChange = (dates) => {
    const [start, end] = dates;

    if (start) {
      const formattedStart = format(new Date(start), "yyyy-MM-dd");
      setStartDate(formattedStart);
    } else {
      setStartDate(null);
    }

    if (end) {
      const formattedEnd = format(new Date(end), "yyyy-MM-dd");
      setEndDate(formattedEnd);
    } else {
      setEndDate(null);
    }
  };

  const today = new Date(); 
//  const handleFilter = () => {}
  return (
    <>
      <div style={{ display: "flex", alignItems: "center" }}>
        <Stack
          style={{
            backgroundColor: "white",
            border: "1px solid #ced4da",
            padding: "0.375rem 0.75rem",
            borderRadius: "0",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <UilCalender size="15" style={{ color: "#828689" }} />
        </Stack>
        <ReactDatePicker
          selected={startDate ? new Date(startDate) : null}
          onChange={(date) => handleDateRangeChange([date, endDate])}
          selectsStart
          startDate={startDate ? new Date(startDate) : null}
          endDate={endDate ? new Date(endDate) : null}
          placeholderText={placeholderText("Start Date")}
          className="form-control rounded-0"
          maxDate={today}
          dateFormat="dd MMMM yyyy"
          style={{
            flex: 1,
            borderTopLeftRadius: "0",
            borderBottomLeftRadius: "0",
          }}
        />
        <ReactDatePicker
          selected={endDate ? new Date(endDate) : null}
          onChange={(date) => handleDateRangeChange([startDate, date])}
          selectsEnd
          startDate={startDate ? new Date(startDate) : null}
          endDate={endDate ? new Date(endDate) : null}
          placeholderText={placeholderText("End Date")}
          className="form-control rounded-0"
          maxDate={today}
          dateFormat="dd MMMM yyyy"
          style={{
            flex: 1,
            borderTopRightRadius: "0.25rem",
            borderBottomRightRadius: "0.25rem",
          }}
        />
      </div>
    </>
  );
};

export default DatePicker;
