import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, Row, Table, Col, Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { i18n } from "../../../shared/helpers/sharedMethods";
import GlobalCurrency from "../../../shared/components/GlobalCurrency";
import { fetchExempReport } from "../../store/ExempSlice";
import { FaQuestionCircle } from "react-icons/fa";

const EXEMP = ({ startDate, endDate }) => {
  const dispatch = useDispatch();
  const accessToken = useSelector((state) => state?.auth?.accessToken?.access_token);
  const selectedBusiness = useSelector((state) => state?.auth?.selectedBusiness);
  const tenantId = selectedBusiness?.pivot?.tenant_id;
  const getExemp = useSelector(
    (state) => state.ExempSalesReport.reportItems
  );
  const withGSTIN = getExemp?.withGSTIN || [];
  const withoutGSTIN = getExemp?.withoutGSTIN || [];
  const intrawithReg = getExemp?.intrawithReg || [];
  const intrawithoutReg = getExemp?.intrawithoutReg || [];
  console.log('exemp',getExemp);

  useEffect(() => {
    dispatch(fetchExempReport({startDate: startDate, endDate: endDate,token:accessToken,tenant_id:tenantId})); 
  }, [startDate, endDate]);
  const NilRatedWithReg = withGSTIN.reduce((sum,item) => {
    return sum+ (item.taxCode === 'GST_ZERO' ? item.totalValue : 0);
  }, 0);
  const ExemptWithReg = withGSTIN.reduce((sum,item) => {
    return sum+ (item.taxCode === 'GST_EXEMPT' ? item.totalValue : 0);
  }, 0);
  const NonGSTWithReg = withGSTIN.reduce((sum,item) => {
    return sum+ (item.taxCode === 'GST_NONE' ? item.totalValue : 0);
  }, 0);

  const InterNilRatedWithoutGST = withoutGSTIN.reduce((sum,item) => {
    return sum+ (item.taxCode === 'GST_ZERO' ? item.totalValue : 0);
  }, 0);
  const ExemptWithoutReg = withoutGSTIN.reduce((sum,item) => {
    return sum+ (item.taxCode === 'GST_EXEMPT' ? item.totalValue : 0);
  }, 0);
  const NonGSTWithoutReg = withoutGSTIN.reduce((sum,item) => {
    return sum+ (item.taxCode === 'GST_NONE' ? item.totalValue : 0);
  }, 0);

  const IntraNilRatedWithReg = intrawithReg.reduce((sum,item) => {
    return sum+ (item.taxCode === 'GST_ZERO' ? item?.totalValue : 0);
  }, 0);
  const IntraExemptWithReg = intrawithReg.reduce((sum,item) => {
    return sum+ (item.taxCode === 'GST_EXEMPT' ? item.totalValue : 0);
  }, 0);
  const IntraNonGSTWithReg = intrawithReg.reduce((sum,item) => {
    return sum+ (item.taxCode === 'GST_NONE' ? item.totalValue : 0);
  }, 0);

  const IntraNilRatedWithoutReg = intrawithoutReg.reduce((sum,item) => {
    return sum+ (item.taxCode === 'GST_ZERO' ? item.totalValue : 0);
  }, 0);
  const IntraExemptWithoutReg = intrawithoutReg.reduce((sum,item) => {
    return sum+ (item.taxCode === 'GST_EXEMPT' ? item.totalValue : 0);
  }, 0);
  const IntraNonGSTWithoutReg = intrawithoutReg.reduce((sum,item) => {
    return sum+ (item.taxCode === 'GST_NONE' ? item.totalValue : 0);
  }, 0);

  const totalNilRatedSupplies =
    NilRatedWithReg +
    InterNilRatedWithoutGST +
    IntraNilRatedWithReg +
    IntraNilRatedWithoutReg;

  const totalExemptedSupplies =
    ExemptWithReg +
    ExemptWithoutReg +
    IntraExemptWithReg +
    IntraExemptWithoutReg;

  const totalNonGSTSupplies =
    NonGSTWithReg +
    NonGSTWithoutReg +
    IntraNonGSTWithReg +
    IntraNonGSTWithoutReg;

    const toolTip = (props) => (
      <Tooltip id="button-tooltip" {...props}>
       Details of Nil Rated, Exempted and Non GST Supplies made during the tax period
      </Tooltip>
    );

  return (
    <Row className="p-5 scrollable-table">
      <Card.Body className="">
      <Row
          className="pb-4 d-flex justify-content-between align-items-center"
          style={{ borderColor: "#39313d" }}
        >
          <Col>
          <div className="d-flex flex-wrap">
            <Button className="px-4 me-4" variant="outline-secondary">
              <span style={{ color: "#898989" }}>
                Total Nil Rated Supplies:{" "}
                <b>
                  <GlobalCurrency price={totalNilRatedSupplies} />
                </b>
              </span>
            </Button>
            <Button className="px-4 me-4" variant="outline-secondary">
              <span style={{ color: "#898989" }}>
                Total Exempted Supplies:{" "}
                <b>
                  <GlobalCurrency price={totalExemptedSupplies} />
                </b>
              </span>
            </Button>
            <Button className="px-4 me-4" variant="outline-secondary">
              <span style={{ color: "#898989" }}>
                Total Non-GST Supplies:{" "}
                <b>
                  <GlobalCurrency price={totalNonGSTSupplies} />
                </b>
              </span>
            </Button>
            </div>
            </Col>
            <Col
            xs="auto"
            className="d-flex justify-content-end align-items-center"
          >
            <OverlayTrigger placement="bottom" overlay={toolTip}>
              <Button
                variant="outline-secondary"
                style={{
                  color: "#898989",
                  padding: "9px 8px", 
                  lineHeight: 1, 
                }}
              >
                <FaQuestionCircle size={15} />
              </Button>
            </OverlayTrigger>
          </Col>
        </Row>

        <Table className="py-5" bordered hover responsive>
          <thead>
            <tr>
              <th className="px-2">{i18n("Description")}</th>
              <th className="px-2">{i18n("Nil rated Supplies")}</th>
              <th className="px-2">
                {i18n("Exempted(other than nil rated/non GST supply)")}
              </th>
              <th className="px-2">{i18n("Non-GST Supplies")}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="p-1 px-2 m-1 mx-3">
                {"Inter-State supplies to registered persons"}
              </td>

              <td className="p-1 px-2 m-1 mx-3">
                <GlobalCurrency price={NilRatedWithReg} />
              </td>
              <td className="p-1 px-2 m-1 mx-3">
                <GlobalCurrency price={ExemptWithReg} />
              </td>

              <td className="p-1 px-2 m-1 mx-3">
                <GlobalCurrency price={NonGSTWithReg} />
              </td>
            </tr>
            <tr>
              <td className="p-1 px-2 m-1 mx-3">
                {"Inter-State supplies to unregistered persons"}
              </td>

              <td className="p-1 px-2 m-1 mx-3">
                <GlobalCurrency price={InterNilRatedWithoutGST} />
              </td>
              <td className="p-1 px-2 m-1 mx-3">
                <GlobalCurrency price={ExemptWithoutReg} />
              </td>

              <td className="p-1 px-2 m-1 mx-3">
                <GlobalCurrency price={NonGSTWithoutReg} />
              </td>
            </tr>
            <tr>
              <td className="p-1 px-2 m-1 mx-3">
                {"Intra-State supplies to registered persons"}
              </td>

              <td className="p-1 px-2 m-1 mx-3">
                <GlobalCurrency price={IntraNilRatedWithReg} />
              </td>
              <td className="p-1 px-2 m-1 mx-3">
                <GlobalCurrency price={IntraExemptWithReg} />
              </td>

              <td className="p-1 px-2 m-1 mx-3">
                <GlobalCurrency price={IntraNonGSTWithReg} />
              </td>
            </tr>
            <tr>
              <td className="p-1 px-2 m-1 mx-3">
                {"Intra-State supplies to unregistered persons"}
              </td>

              <td className="p-1 px-2 m-1 mx-3">
                <GlobalCurrency price={IntraNilRatedWithoutReg} />
              </td>
              <td className="p-1 px-2 m-1 mx-3">
                <GlobalCurrency price={IntraExemptWithoutReg} />
              </td>

              <td className="p-1 px-2 m-1 mx-3">
                <GlobalCurrency price={IntraNonGSTWithoutReg} />
              </td>
            </tr>
          </tbody>
        </Table>
      </Card.Body>
    </Row>
  );
};

export default EXEMP;
