import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
} from "@reduxjs/toolkit";
import { i18n } from "../../shared/helpers/sharedMethods";
import { axiosRequest } from "../../shared/helpers/axiosHelper";

const paymentAdapter = createEntityAdapter();

const initialState = paymentAdapter.getInitialState({
  status: "idle",
  error: null,
  loading: false,
  deleteStatus: false
});

export const fetchSalesbyParty = createAsyncThunk(
  "payment/fetchsalesbyParty",
  async (args) => {
    try {
      const token = args?.token;
      const type = args?.data?.payment_type
      const id = args?.data.id;
      const tenantId = args?.tenant_id;
      console.log('args_payment', args);
      const salesResponse = await await axiosRequest({
        method: "GET",
        url: `get-sales-by-party/${id}/${type}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "X-Tenant": tenantId,
        },
      });
      return salesResponse.data;
    } catch (error) {
      await window.log.logToFile({ message: "Error while fetching Sales by Party", level: "error" });
      throw error;
    }
  }
);

export const fetchSalesbyId = createAsyncThunk(
  "payment/fetchsalesbyId",
  async (id) => {
    try {
      const salesResponse = await window.api.fetchSalesByParty(id);
      ;
      return salesResponse.data;
    } catch (error) {
      await window.log.logToFile({ message: "Error while fetching sales by id", level: "error" });
      throw error;
    }
  }
);

export const getPayment = createAsyncThunk(
  "payment/getPayment",
  async (args) => {
    try {
      const token = args.token;
      const data = args.data;
      const tenantId = args.tenant_id;
      const getPayment = await axiosRequest({
        method: "GET",
        url: `payment`,
        data: data,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "X-Tenant": tenantId,
        },
      });
      return getPayment.data;
    } catch (error) {
      await window.log.logToFile({ message: "Error while fetching Payment", level: "error" });
      throw error;
    }
  }
);

export const deletePayment = createAsyncThunk(
  "payment/deletePayment",
  async (args) => {
    try {
      console.log('datss', args);
      const token = args.token;
      const data = args.data;
      const tenantId = args.tenant_id;
      const resourceType = 'payment';
      const terminalNumber = args.terminal_id;
      const deletePayment = await axiosRequest({
        method: "DELETE",
        url: `resource/${resourceType}/delete/${terminalNumber}`,
        data: data,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "X-Tenant": tenantId,
        },
      });
      return data;
    } catch (error) {
      await window.log.logToFile({ message: "Error while deleting Payment", level: "error" });
      throw error;
    }
  }
);

const paymentSlice = createSlice({
  name: "payment",
  initialState,
  reducers: {
    resetCartVoucherPayment: () => initialState,
    exportData(state, action) {
      ;
      ;
      state.paymentByType = action.payload;
    },
    resetDeleteStatus(state,action){
      state.deleteStatus = false;
    },
    removePaymentData(state,action){
      console.log("action payload id",action.payload)
      //paymentAdapter.removeOne(state, action.payload);
    }

  },
  extraReducers(builder) {
    builder
      .addCase(fetchSalesbyParty.pending, (state) => {
        state.loading = true
      })
      .addCase(fetchSalesbyParty.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.loading = false
        paymentAdapter.upsertMany(state, action.payload);
      })
      .addCase(getPayment.pending, (state) => {
        state.loading = true
      })
      .addCase(getPayment.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.loading = false
        if (action.payload) {
          paymentAdapter.upsertMany(state, action.payload);
        }
      })
      .addCase(fetchSalesbyId.pending, (state) => {
        state.loading = true
      })
      .addCase(fetchSalesbyId.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.loading = false
        state.getVoucherDataByid = action.payload;
      })
      .addCase(getPayment.rejected, (state, action) => {
        state.status = "Error in data insert";
        state.loading = false
        state.error = (i18n("Unable to retrieve payment. Please try again."));
      })

      .addCase(deletePayment.fulfilled, (state, action) => {
        console.log("action payload remove",action.payload)
        const id = action?.payload?.id;
        paymentAdapter.removeOne(state,id);
        state.deleteStatus = true;
      })
      .addCase(deletePayment.rejected, (state, action) => {
        state.deleteStatus = false;
        state.error = action.error.message;
      });
  },
});

export default paymentSlice.reducer;
export const { resetCartVoucherPayment,resetDeleteStatus,removePaymentData } = paymentSlice.actions;
export const {
  selectAll: selectAllVoucherPayment,
  selectById: selectVoucherPaymentById,
  selectIds: selectVoucherPaymentIds,
} = paymentAdapter.getSelectors((state) => state.payment);

export const selectPaymentByType = createSelector(
  [selectAllVoucherPayment, (state, type) => type],
  (payments, type) => {
    if (!type) {
      console.error("Type is undefined");
      return [];
    }
    ;

    return (
      payments && payments.filter((payment) => payment && payment.type == type)
    );
  }
);
