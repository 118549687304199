import { Button, Col, Form, Modal,  } from "react-bootstrap";
import { i18n } from "../../shared/helpers/sharedMethods";
import { useDispatch, useSelector } from "react-redux";
import DataGrid from "../../shared/components/DataGrid";
import { format } from "date-fns";
import DataTable from "react-data-table-component";
import { useEffect, useState } from "react";
import {
  deletePartialPayment,
  undoDelStatus,
  undoStatus,
  updatePartialPayment,
} from "../store/voucherEntitySlice";
import {
  UilEdit,
  UilTrashAlt,
  UilExport,
  UilImport,
  UilCheck,
} from "@iconscout/react-unicons";
import { BsUiChecks } from "react-icons/bs";
import { toast,ToastContainer } from "react-toastify";


const ReceivedPaymentModel = (props) => {
  const {
    voucherPayment,
    updatePaidAmountStatus,
    deletePaidAmountStatus,
    grand_total,
    paid_amount,
    totalReceivedAmount,
  } = useSelector((state) => state.voucherEntity);
  const dispatch = useDispatch();
  const { setShowPaymentModel, showModel } = props;
  const balanceAmount = isNaN(grand_total - totalReceivedAmount)
    ? 0
    : parseFloat(grand_total - totalReceivedAmount);
 

  const [voucherAmount, setVoucherAmount] = useState(voucherPayment);
  const [submitId, setSubmitId] = useState(0);
  const [sumOfValue, setSumOfValue] = useState(0);
  const selectedBusiness = useSelector(
    (state) => state?.auth?.selectedBusiness
  );
  const { accessToken} = useSelector((state) => state?.auth);
  const tenantId = selectedBusiness?.pivot?.tenant_id;
  const handleEdit = (row) => {
    setSubmitId(row.id);
  };

  useEffect(() => {
    if (voucherAmount) {
      setVoucherAmount(voucherPayment);
    }
  }, [props]);

  useEffect(()=>{
    if(voucherAmount){
      const sum = voucherAmount.reduce((partialSum, a) => partialSum + a.amount, 0);
     
      setSumOfValue(sum)
    }

  },[voucherAmount])

  useEffect(() => {
    if (updatePaidAmountStatus) {
      setShowPaymentModel(false);
      setSubmitId(0);
      dispatch(undoStatus());
    }
  }, [updatePaidAmountStatus]);

  useEffect(() => {
    if (deletePaidAmountStatus) {
      setShowPaymentModel(false);
      // setSubmitId(0)
      dispatch(undoDelStatus());
    }
  }, [deletePaidAmountStatus]);

  const handleDelete = (row) => {
    dispatch(deletePartialPayment(row));
  };

   
  const handleAmountChange = (e, id) => {
    let { value, name } = e.target;
  
 
   
      setVoucherAmount((data) => {
        return data.map((amount) => {
          if (amount.id === id) {
            return {
              ...amount,
              amount: parseInt(value),
            };
          }

          return amount;
        });
      });
    
  };

  const handleSubmit = (row, index) => {
  
     if(isNaN(voucherAmount[index].amount)){
      toast.error('please enter the amount');

     }
     else if(voucherAmount[index].amount == 0){
      toast.error('please enter the  valid amount');
     }
     else if (sumOfValue > grand_total) {
      toast.error('Please enter valid balance amount');
   
     }
     else if (sumOfValue <= grand_total) {
      dispatch(updatePartialPayment({data:voucherAmount[index],token: accessToken.access_token,
        tenant_id: tenantId}))
   
     }
     else{
      toast.error('Please enter balance amount only');
     }
  };

  const handleKeyDown = (event, row, index) => {
    if (event.key === "Enter") {
    
      if (row.id == submitId) {
        handleSubmit(row, index);
      }
      // setShowCreatePartyModal(true);
      // setAutoFill(typeaheadRef.current.getInput().value);
    }
  };
  const columns = [
    {
      id: "payment_date",
      name: i18n("DATE"),
      selector: (row) => format(new Date(row.created_at), "dd-MM-yyyy "),
      sortable: true,
    },
    {
      id: "payment_number",
      name: "Payment#",
      width: "120px",
      sortable: true,
      selector: (row) => row?.payment?.payment_number,
    },
    {
      id: "amount",
      name: "Amount",
      width: "120px",
      sortable: true,
      selector: (row, index) => {
        return (
          <Form.Control
            size="sm"
            type="number"
            value={voucherAmount[index]?.amount}
            readOnly={submitId === row.id ? false : true}
            onChange={(e) => {
              handleAmountChange(e, row.id);
            }}
            onKeyDown={(event) => {
              handleKeyDown(event, row, index);
            }}
          />
        );
      },
    },
    {
      id: "actions",
      name: i18n("Actions"),
      cell: (row, index) => {
        return (
          <div className="d-flex justify-content-evenly align-items-center gap-4">
            {submitId === row.id ? (
              <UilCheck
                size={"20px"}
                onClick={() => handleSubmit(row, index)}
              />
            ) : (
              <UilEdit size={"20px"} onClick={() => handleEdit(row, index)} />
            )}
            <UilTrashAlt size={"20px"} onClick={() => handleDelete(row)} />

            {/* <i
                        className="fa fa-trash mx-2"
                        aria-hidden="true"
                        style={{ cursor: "pointer" }}
                        onClick={() => handleDelete(row)}
                    ></i> */}
          </div>
        );
      },
    },
  ];
  return (
    <>
    <ToastContainer />
    <Modal
      show={showModel}
      dialogClassName="modal-dialog modal-dialog-centered "
      onHide={() => {
        setShowPaymentModel(!showModel);
      }}
      keyboard={true}
    >
      <Modal.Header closeButton>
        <Modal.Title>{"Received Payments"}</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ padding: "0px" }}>
        <div className="col-md-12 mb-5">
          <DataTable
            data={voucherPayment}
            columns={columns}
            // pagination={true}
            // paginationPerPage={50}
            paginationRowsPerPageOptions={[50, 100, 150, 200, 250]}
            paginationTotalRows={voucherPayment && voucherPayment.length}
            //onRowClicked={handleRowClick}
            responsive
            highlightOnHover
            pointerOnHover
            handleSearch
            // defaultSortAsc={defaultSortAsc}
            // defaultSortFieldId={defaultSortField}
          />
        </div>
      </Modal.Body>
    </Modal>
    </>
  );
};
export default ReceivedPaymentModel;
