import { format, subDays } from 'date-fns';


 export  const TodayRange = () => {
    const today = new Date();
    //return format(today, "eeee");
    return format(today, "dd MMMM yyyy");
  };

  export  const YesterdayRange = () => {
    const yesterday = subDays(new Date(), 1);
    //return format(yesterday, "eeee");
    return format(yesterday, "dd MMMM yyyy");
  };

  export  const CurrentWeekRange = () => {
    const today = new Date();
    const currentWeekStart = today.getDate() - today.getDay();
    const currentWeekEnd = currentWeekStart + 6;
    const currentWeekStartDate = new Date(today.getFullYear(), today.getMonth(), currentWeekStart);
    const currentWeekEndDate = new Date(today.getFullYear(), today.getMonth(), currentWeekEnd);
    return `${format(currentWeekStartDate, "dd MMMM yyyy")} - ${format(currentWeekEndDate, "dd MMMM yyyy")}`;
  };

  export  const PreviousWeekRange = () => {
    const today = new Date();
    const lastWeekStart = today.getDate() - today.getDay() - 7;
    const lastWeekEnd = lastWeekStart + 6;
    const lastWeekStartDate = new Date(today.getFullYear(), today.getMonth(), lastWeekStart);
    const lastWeekEndDate = new Date(today.getFullYear(), today.getMonth(), lastWeekEnd);
    return `${format(lastWeekStartDate, "dd MMMM yyyy")} - ${format(lastWeekEndDate, "dd MMMM yyyy")} `;
  };

  export const CurrentMonthRange = () => {
    const date = new Date();
    const currentYear = date.getFullYear();
    const currentMonth = date.getMonth();
    const start = new Date(currentYear, currentMonth, 1);
    const end = new Date(currentYear, currentMonth + 1, 0);
    return `${format(start, "dd MMMM yyyy")} - ${format(end, "dd MMMM yyyy")} `;

  };

  export  const PreviousMonthRange = () => {
    const date = new Date();
    const currentYear = date.getFullYear();
    const currentMonth = date.getMonth();
    const firstDayOfPreviousMonth = new Date(currentYear, currentMonth - 1, 1);
    const lastDayOfPreviousMonth = new Date(currentYear, currentMonth, 0);
    return `${format(firstDayOfPreviousMonth, "dd MMMM yyyy")} - ${format(lastDayOfPreviousMonth, "dd MMMM yyyy")} `;
  };

  export  const CurrentYearRange = () => {
    const today = new Date();
    return format(today, "yyyy");
  };

  export  const PreviousYearRange = () => {
    const today = new Date();
    const previousYear = today.getFullYear() - 1;
    return previousYear.toString();
  };