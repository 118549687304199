import React, {useEffect, useState} from "react";
import { Button, Stack, OverlayTrigger, Tooltip, Container, Card, Row, Col } from "react-bootstrap";
import { UilSync,UilWifiSlash } from '@iconscout/react-unicons';
import { i18n } from "../../shared/helpers/sharedMethods";
import Header from "../../shared/components/Header";
import ShortcutKeys from "../../shared/components/ShortcutKeys";
import Layout from "../../shared/components/Layout";
import NavbarSetting from "./NavbarSettings";
import { useDispatch, useSelector } from "react-redux";
import { fetchSetting, resetLoading } from "../store/getSettingSlice";


const SettingsLayout = ({ title, onSave, onCancel, children }) => {
    const dispatch = useDispatch();
    const selectedBusiness = useSelector((state) => state.auth.selectedBusiness);
    const { accessToken } = useSelector((state) => state.auth);
    const loading = useSelector((state) => state.getSetting.loading);
    const status = useSelector((state) => state.getSetting.status);
    const [isOnline, setIsOnline] = useState(navigator.onLine);

    const handleRefresh = () => {
        dispatch(resetLoading());
        dispatch(fetchSetting({
            accessToken: accessToken.access_token,
            tenant_id: selectedBusiness?.pivot?.tenant_id,
        }));
    };

    useEffect(() => {
        const handleOnlineStatusChange = () => {
          setIsOnline(navigator.onLine);
        };
    
        window.addEventListener("online", handleOnlineStatusChange);
        window.addEventListener("offline", handleOnlineStatusChange);
    
        return () => {
          window.removeEventListener("online", handleOnlineStatusChange);
          window.removeEventListener("offline", handleOnlineStatusChange);
        };
      }, []);


    return (
        <Layout>
            <Header
                currentTitle={i18n(title)}
                refresh={ isOnline ? 
                    <OverlayTrigger
                        placement="right"
                        overlay={<Tooltip>{i18n("Refresh")}</Tooltip>}
                    >
                        <Button
                            variant="link"
                            onClick={handleRefresh}
                            disabled={loading}
                            style={{
                                color: '#212529',
                                padding: 0,
                            }}
                        >
                            <UilSync
                                size="18"
                                style={{
                                    animation: loading ? 'spin 0.5s linear infinite' : 'none',
                                    display: 'inline-block',
                                }}
                            />
                        </Button>
                    </OverlayTrigger>
                     :   <OverlayTrigger
                     placement="right"
                     overlay={<Tooltip>{i18n("You are offline")}</Tooltip>}
                 >
                     <div style={{ display: 'flex', alignItems: 'center', fontWeight: "bold", color: '#ADB5BD' }}>
                         <UilWifiSlash size="18" />
                         <span className="ms-2">{i18n("You are offline")}</span>
                     </div>
                 </OverlayTrigger>
                }
        
                ItemActions={
                    <Stack
                        gap={3}
                        direction="horizontal"
                        className="mt-2 mb-2 justify-content-end"
                    >
                        <ShortcutKeys />

                        <Button
                            variant="primary"
                            onClick={onSave}
                        >
                            {i18n("Save")}
                        </Button>
                        <Button
                            variant="outline-secondary"
                            onClick={onCancel}
                        >
                            {i18n("Cancel")}
                        </Button>
                    </Stack>
                }
            />
            <Container className="" fluid>
                <Card
                    className=""
                    style={{
                        border: "1px solid lightgray",
                        borderRadius:'0px'
                    }}
                >

                    <Row className=" ">
                        <Col md={2} className="">
                            <NavbarSetting />
                        </Col>
                        <Col lg={10}>
                            {children}
                        </Col>
                    </Row>
                </Card>
            </Container>
        </Layout>
    );
};

export default SettingsLayout;
