import { Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { updateItemFieldSubscription } from "../../../../store/subscriptionProductEntitySlice";
import { formatAmount } from "../../../../../pos/helpers/cartHelper";

const SubscriptionPrice = ({ index, item, values, handleChange }) => {
  ;
  const dispatch = useDispatch();
  let per_item_taxable =
    item?.taxable_value && item?.quantity && item?.is_tax_included
      ? (item?.taxable_value - item?.cess) / item?.quantity
      : item?.taxable_value / item?.quantity;
  ;
  return (
    <Form.Control
      type="number"
      name="item_price"
      placeholder="0.00"
      className="text-end"
      value={formatAmount(parseFloat(per_item_taxable)||'')}
      onChange={(e) => {
        let data = {
          fieldName: "item_price",
          fieldValue: parseFloat(e.target.value),
          index,
        };
        dispatch(updateItemFieldSubscription(data));

        let isTaxIncluded = {
          fieldName: "is_tax_included",
          fieldValue: false,
          index,
        };
        dispatch(updateItemFieldSubscription(isTaxIncluded));
      }}
    />
  );
};

export default SubscriptionPrice;
