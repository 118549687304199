import React, { useEffect } from 'react';
import { Container, Row, Stack, Button } from 'react-bootstrap';
import ItemsStack from './fragments/ItemsStack';
import ProductSearch from './fragments/ProductSearch';
import { useDispatch, useSelector } from 'react-redux';
import { updateTotals } from '../../../store/posOrderSlice';
import { selectAllCartItems } from '../../../store/cartItemsSlice';

const CartIndex = () => {

    const dispatch = useDispatch();
    const cartItems = useSelector(selectAllCartItems);

    useEffect(() => {
        dispatch(updateTotals({ items : cartItems }))
    },[cartItems])

    return (
        <Container className='bottom-splitter right-splitter' style={{ backgroundColor: 'white' }}>
            
            <Row >
             <ProductSearch />
            </Row>
            <Row className='mx-0'>
              <ItemsStack  />
            </Row>
        </Container>
    )
}

export default CartIndex;