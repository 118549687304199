import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Layout from "../../../shared/components/Layout";
import { Card, Col, Container, Form, Row, Stack } from "react-bootstrap";
import Header from "../../../shared/components/Header";
import { i18n } from "../../../shared/helpers/sharedMethods";
import ReportPeriod from "../../../shared/components/ReportPeroid";
import SubscriptionSummaryBlock from "../../components/blocks.js/SubscriptionSummaryBlock";

const SubscriptionOverview = (props) => {
  const { type } = props;
  const dispatch = useDispatch();
  const [reportPeriod, setReportPeriod] = useState("current-year");
  const currencySymbol  = useSelector((state) => state.getSetting?.dataSetting.currency);
  ;
  const selectedBusiness = useSelector(
    (state) => state?.auth?.selectedBusiness
  );
  const accessToken = useSelector((state) => state?.auth?.accessToken?.access_token);
  const tenantId = selectedBusiness?.pivot?.tenant_id;
 
  // const reportItems = useSelector(
  //   (state) => state.salesOverviewBlocks.reportItems
  // );
  // ;
  // const purchaseReportItems = useSelector(
  //   (state) => state.salesOverviewBlocks.purchaseReportItems
  // );
  // ;
 

    return (
      <Layout>
        <Header
         currentTitle={i18n("Overview")}
         ItemActions={
          <Stack gap={3} direction="horizontal" className="mt-1 ">
            
            
            <ReportPeriod
              reportPeriod={reportPeriod}
              setReportPeriod={setReportPeriod}
            />
          </Stack>
        }
         />
        <Container fluid className="px-5">
          <div className=""
            style={{ background: "", height: "95vh", overflow: "auto", overflowX: "hidden" }}
          >
            
           
          </div>
        </Container>
      </Layout>
    );
  }


export default SubscriptionOverview;
