import { useDispatch, useSelector } from 'react-redux';
import { Stack, Form, Row, Col, Table } from "react-bootstrap";
import { i18n, placeholderText } from '../../shared/helpers/sharedMethods';
import { updateBillingAddress } from '../store/voucherEntitySlice';
import CartItemHeader from './CartItemHeader';
import CartItemRow from './CartItemRow';
import CartItemTotals from './CartItemTotals';
import { times } from 'lodash';
import {  selectVoucherItemIds } from '../store/voucherItemsSlice';

const CartBlock = ({ voucherType, cessEnabled, discountEnabled,is_same_state }) => {
    const dispatch = useDispatch();
    const { rows } = useSelector(
        (state) => state.voucherEntity
    );
    const cartIds = useSelector(selectVoucherItemIds);
    

    return (

        <Table className='my-0 voucher-cart-table' bordered style={{ border: "1px solid #e1e1e1" }}>
            <CartItemHeader  cessEnabled={cessEnabled} discountEnabled={discountEnabled} is_same_state={is_same_state}/>
            <tbody>
                {cartIds.map((value, i) =>
                
                    <CartItemRow index={value} serial={i} voucherType={voucherType} cessEnabled={cessEnabled} discountEnabled={discountEnabled} is_same_state={is_same_state}/>
                )}
            </tbody>


            <CartItemTotals noOfItems={cartIds.length} cessEnabled={cessEnabled} discountEnabled={discountEnabled} is_same_state={is_same_state}/>
        </Table>
    );
}

export default CartBlock; 