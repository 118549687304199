import SyncInfoLogs from "./components/SyncInfoLogs";
import SyncLogsDetails from "./components/SyncLogsDetails";
import SyncQueue from "./components/SyncQueue";
import ViewLogs from "./components/ViewLogs";



const rootPath = "shared";

export const sharedRouter = [
    {
        path: rootPath + "/logs",
        element: <ViewLogs />,
        isProtected: true,
    },
    {
        path: rootPath + "/queue",
        element: <SyncQueue />,
        isProtected: true,
    },
    {
        path: rootPath + "/info",
        element: <SyncInfoLogs />,
        isProtected: true,
    },
];