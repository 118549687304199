import { Col, Modal, Row } from "react-bootstrap";
import ItemTabs from "./item-detail/sections/ItemTabs";

const DetailsModal = (props) => {
  const {
    show,
    type,
    tabs,
    print,
    pdf,
    summary,
    header,
    setShow,
    id,
  } = props;

  const clearField = () => {
    setShow(false);
  };

  return (
    <Modal
      className="modal-xl modal-dialog-centered"
      show={show}
      onHide={clearField}
      keyboard={true}
    >
      <Modal.Body style={{height:'775px'}}>
        <Row>
          <Col lg={12}>{header}</Col>
          <Col className="mt-3" lg={12}>
            {summary ? summary : ''}
          </Col>
          <Col className="mt-3" lg={12}>
            <ItemTabs
              print={print}
              pdf={pdf}
              tabs={tabs}
              type={type}
              title={"Invoice History"}
            />
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default DetailsModal;
