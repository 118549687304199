import { Card, Col, Form, Row, Stack } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { format } from 'date-fns';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faArrowDown,
    faArrowUp,
    faDatabase,
    faPercentage,
} from "@fortawesome/free-solid-svg-icons";
import { fetchPartyProfileInfo } from '../../party/store/PartyProfileInfoSlice';
import { useEffect } from 'react';
import { i18n } from '../../shared/helpers/sharedMethods';
import { isEmpty } from 'lodash';
import { fetchPartySalesTotal } from '../../party/store/PartySalesTotalSlice';
import GlobalCurrency from '../../shared/components/GlobalCurrency';

const QuickGlance = () => {
    const accessToken = useSelector((state) => state?.auth?.accessToken?.access_token);
    const selectedBusiness = useSelector((state) => state?.auth?.selectedBusiness);
    const tenantId = selectedBusiness?.pivot?.tenant_id;
    const currentParty = useSelector((state) => state.voucherEntity.party);
    const getSummary = useSelector((state) => state.partyProfileInfo.reportItems);
    const { totalSalesCount, overallTotal } = useSelector(
        (state) => state.partySalesTotal.reportItems
    );
    const avgOrderValue = (overallTotal && totalSalesCount) ? (parseFloat(overallTotal) / totalSalesCount).toFixed(2) : 0;
    const dispatch = useDispatch();

    useEffect(() => {
        if (!isEmpty(currentParty)) {
            dispatch(fetchPartyProfileInfo({id:currentParty?.id,token:accessToken,tenant_id:tenantId}));

            dispatch(fetchPartySalesTotal({id:currentParty?.id,token:accessToken,tenant_id:tenantId}));
        }
    }, [currentParty]);

    return (
        <div style={{ minWidth: '180px' }}> {/* Set a minimum width for the tooltip */}
            <Form.Label className='pb-2' style={{ borderBottom: "1px solid #4682B4", borderRadius: "0" }}>
                <span className='bold' style={{color: "#4682B4"}}>{i18n("QUICK GLANCE")} </span>

            </Form.Label>
            <Stack gap={2} className='p-2' style={{ border: "1px solid #ccc", borderRadius: "5px" }}>
                <Row>
                    <Col>
                        <Card className='p-2 text-center'>
                            <GlobalCurrency price={getSummary?.to_collect ? parseFloat(getSummary?.to_collect) : 0} />
                          <span className="text-collect h6" style={{ textAlign: "left" }}>
                            <FontAwesomeIcon icon={faArrowDown} className="px-1" /> {i18n("Collect")}
                        </span>
                        </Card>
                    </Col>
                    <Col>
                        <Card className='p-2 text-center'>
                            <GlobalCurrency price={getSummary?.to_pay ? parseFloat(getSummary?.to_pay) : 0} />
                            <span className="text-pay h6">
                                <FontAwesomeIcon icon={faArrowUp} className="px-1" /> {i18n("To Pay")}
                            </span>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Card className='p-2 text-center'>
                            <GlobalCurrency price={overallTotal ? overallTotal : 0} />
                            <span className="text-primary h6">
                                {i18n("Total Sales")}
                            </span>
                        </Card>
                    </Col>
                    <Col>
                        <Card className='p-2 text-center'>
                            <GlobalCurrency price={avgOrderValue} />
                            <span className="text-info h6">
                            {i18n("Avg. Order")}
                            </span>
                        </Card>
                    </Col>
                </Row>
            </Stack>
        </div>
    );
};

export default QuickGlance;
