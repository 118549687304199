import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
} from "@reduxjs/toolkit";
import { filterProductCategory, preprocessorProduct } from "../helper/catalogHelper";
import { i18n } from "../../shared/helpers/sharedMethods";
import { prepareCartItem } from "../../pos/helpers/cartHelper";
import { cloneDeep } from "lodash";
import { axiosRequest } from "../../shared/helpers/axiosHelper";

const LOW_STOCK_THRESHOLD = 5;
const productsAdapter = createEntityAdapter();

const initialState = productsAdapter.getInitialState({
  status: false,
  error: null,
  errorStatus: false,
  productCategory: [],
  stockFilter: 'all',
  loading: false,
});

export const fetchProducts = createAsyncThunk(
  "products/fetchProducts",
  async (args) => {
    try {
      const token = args.token;
      const data = args.data;
      const tenantId = args.tenant_id;
      const productsResponse = await axiosRequest({
        method: "GET",
        url: `catalog/products`,
        data: data,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "X-Tenant": tenantId,
        },
      });
      return productsResponse.data;
    } catch (error) {
      await window.log.logToFile({ message: "Unable to fetch Product", level: "error" });
      throw error;
    }
  }
);
export const deleteProduct = createAsyncThunk(
  "products/deleteProduct",
  async (args) => {
    try {
      console.log('datss', args);
      const token = args.token;
      const data = args.data;
      const tenantId = args.tenant_id;
      const resourceType = 'catalog_product';
      const terminalNumber = args.terminal_id;
      const response = await axiosRequest({
        method: "DELETE",
        url: `resource/${resourceType}/delete/${terminalNumber}`,
        data: data,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "X-Tenant": tenantId,
        },
      });
      return response.data;
    } catch (error) {
      console.error("Unable to delete Product");
      throw error;
    }
  }
);

export const fetchTotalQuantity = createAsyncThunk(
  "products/fetchTotalQuantity",
  async (args) => {
    try {
      const totalQuantityResponse = await window.api.getTotalQuantity(args);
      return totalQuantityResponse.data;
    } catch (error) {
      await window.log.logToFile({ message: "Unable to fetch Product Total quantity", level: "error" });
      throw error;
    }
  }
);

export const importProduct = createAsyncThunk(
  "productEntity/importProduct",
  async (args) => {
    console.log('args import party', args);
    const token = args.token;
    const data = { product: args.data };
    const tenantId = args.tenant_id;
    try {
      const addProductResponse = await axiosRequest({
        method: "POST",
        url: `import-product`,
        data: data,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "X-Tenant": tenantId,
        },
      });
      if (addProductResponse.success) {
        return addProductResponse.data;
      } else {
        const errorMessage = addProductResponse?.data?.map((d) => d.message).join(", ");
        throw errorMessage;
      }
    }
    catch (error) {
      await window.log.logToFile({ message: "Unable to import Product", level: "error" });
      throw new Error(error);
    }
  }
);

const productsSlice = createSlice({
  name: "products",
  initialState,
  reducers: {
    addProductCategory(state, action) {
      state.productCategory = action.payload;
    },
    resetProductCategory(state) {
      state.productCategory = null;
    },
    setStockFilter(state, action) {
      state.stockFilter = action.payload;
    },
    pushNewProduct: productsAdapter.addOne,
    updateProduct(state, action) {
      const { id } = action.payload;
      state.entities[id] = action.payload;
    },
    addManyData(state, action) {
      productsAdapter.upsertMany(action.payload);
    },
    productImportError(state, action) {
      state.error = action.payload;
      if (Array.isArray(action.payload) && action.payload?.length > 0) {
        state.errorStatus = true;
      }
    },
    resetProduct: () => {
      ;
      return initialState;
    },
    undoProductImportError(state, action) {
      state.errorStatus = false;
      state.error = ''
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchProducts.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchProducts.fulfilled, (state, action) => {
        state.status = true;
        state.loading = false;
        productsAdapter.upsertMany(state, action?.payload);
      })
      .addCase(fetchProducts.rejected, (state, action) => {
        state.status = false;
        state.loading = false;
        state.error = i18n('Error while fetching products. Please try again later.');
      })
      .addCase(deleteProduct.fulfilled, productsAdapter.removeOne)
      .addCase(deleteProduct.rejected, (state, action) => {
        state.status = false;
        state.error = i18n('Unable to delete the product right now. Please try again later.');
      })
      .addCase(fetchTotalQuantity.fulfilled, (state, action) => {
        state.status = true;
        state.totalQuantityData = action.payload;
      })
      .addCase(fetchTotalQuantity.rejected, (state, action) => {
        state.status = false;
        state.error = i18n('Unable to retrieve total quantity.Please try again.');
      })
      .addCase(importProduct.fulfilled, (state, action) => {
        if (Array.isArray(action.payload)) {
          state.status = true;
          state.importData = action.payload;
          productsAdapter.addMany(state, action.payload);
        }
      })
      .addCase(importProduct.rejected, (state, action) => {
        state.errorStatus = true;
        state.error = action?.error?.message;
      });
  },
});

export default productsSlice.reducer;
export const {
  addProductCategory,
  resetProduct,
  resetProductCategory,
  updateProduct,
  addManyData,
  pushNewProduct,
  setStockFilter,
  productImportError,
  undoProductImportError,

} = productsSlice.actions;

export const {
  selectAll: selectAllProducts,
  selectById: selectProductById,
  selectIds: selectProductIds,
} = productsAdapter.getSelectors((state) => state.products);


export const selectProduct = createSelector(
  [selectAllProducts],
  (products) => {
    return products.filter((product) => product.is_subscribable == null);
  }
);

export const selectProductByCategory = createSelector(
  [selectAllProducts, (state, category) => category],
  (products, category) => {
    if (category === "all") {
      const processedProduct = preprocessorProduct(products);
      return processedProduct;
    } else {
      const filteredProducts = filterProductCategory(products, category);
      const processedProduct = preprocessorProduct(filteredProducts);
      return processedProduct;
    }
  }
);

export const selectFilteredProducts = createSelector(
  [selectAllProducts, (state) => state.products.stockFilter],
  (products, stockFilter) => {
    switch (stockFilter) {
      case 'allstock':
        return products;
      case 'instock':
        return products.filter(product => product.inventories[0]?.quantity > LOW_STOCK_THRESHOLD);
      case 'lowstock':
        return products.filter(product => product.inventories[0]?.quantity > 0 && product.inventories[0]?.quantity <= LOW_STOCK_THRESHOLD);
      case 'nostock':
        return products.filter(product => product.inventories[0]?.quantity === 0);
      default:
        return products;
    }
  }
);

export const selectProductAsVoucherItems = createSelector(
  [selectAllProducts, (state, voucherType) => voucherType],
  (products, voucherType) => {

    let tempItems = [];
    products.map((element) => {
      let clonedEl = cloneDeep(element);
      clonedEl.voucherType = voucherType;
      tempItems.push(prepareCartItem(clonedEl));
    });
    return tempItems;
  }
);


export const selectProductAsVoucher = createSelector(
  [selectAllProducts, (state, voucherType) => voucherType],
  (products, voucherType) => {

    const filteredProducts = products.filter(product => product.is_subscribable == null);

    let tempItems = [];
    filteredProducts.map((element) => {
      let clonedEl = cloneDeep(element);
      clonedEl.voucherType = voucherType;
      tempItems.push(prepareCartItem(clonedEl));
    });
    return tempItems;


  })
