import CreateParty from "../screens/CreateParty"
import EditParty from "../screens/EditParty"
import PartyDetail from "../screens/PartyDetails"
import PartyIndex from "../screens/PartyIndex"
import { i18n } from "../../shared/helpers/sharedMethods"
import PartyImport from "../screens/PartyImport"

const rootPath = 'parties';
const featureName = "parties";

export const customerRoutes =  [

    {
        path: rootPath + '/customer',
        element: <PartyIndex title={i18n("Create Customers")} type="customer" path={rootPath+"/customer"} featureName={featureName}/>,
        isProtected: true
    },
    
    {
        path: rootPath+ '/customer/create',
        element: <CreateParty title={i18n("Create Party")} type="customer" path={rootPath+"/customer"}/>,
        isProtected: true
    },
    {
        path: rootPath+ '/customer/edit/:partyId',
        element: <EditParty title={i18n('Edit Party')} type="customer" path={rootPath+"/customer"}/>,
        isProtected: true
    },
    {
        path: rootPath+ '/customer/view/:partyId',
        element: <PartyDetail type="customer" path={rootPath+"/customer"}/>,
        isProtected: true
    },
   {
         path: rootPath+ '/customer/import',
        element: <PartyImport type="customer" title={"Import Parties"} path={rootPath+"/customer"} partyType="Parties"/>,
        isProtected: true
    },
    

]